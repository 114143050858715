<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <app-navigation></app-navigation>
            <!-- <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Hostel Management</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div> -->
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAdd()">Create Hostel</button>
                </div>
            </div>
        </div>

        <div class="row row-sm">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div class="card">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Hostels</h4>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="table-responsive border-top userlist-table">
                            <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                                <thead>
                                    <th>
                                        <span>Name</span>
                                    </th>
                                    <th>
                                        <span>Number of Floors</span>
                                    </th>
                                    <th>
                                        <span>Actions</span>
                                    </th>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let hostel of hostelData">
                                        <td>{{ hostel.name }} </td>
                                        <td>{{ hostel?.floors?.length }}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-info mr-2"
                                                (click)="onEdit(hostel)">
                                                <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-danger mr-2"
                                                (click)="onDelete(hostel)">
                                                <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
