<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">OBE Member Management</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" (click)="onAddClick()" class="btn btn-info"> Assign Member </button>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">OBE Member Management</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage OBE Members for programmes</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year
                                        </label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!--                                 <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>

                                    </div>
                                </div>
 -->

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="programme_search_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!member_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="member_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No.</th>
                                        <th style="width: 7%;">Programme Type</th>
                                        <th style="width: 10%;">Enrollment Year</th>
                                        <th>Staff Roll No.</th>
                                        <th>Role</th>
                                        <th>Incharge Departments</th>
                                        <th>Course Code(s)</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let member of member_data_list; let i = index;trackBy:fromDataResolver.identify; ">
                                        <td>{{i+1}}</td>
                                        <td>{{member.programme_type_id}}</td>
                                        <td>{{member.enrolled_year}}</td>
                                        <td>{{member.roll_no}}</td>
                                        <td>{{getRole(member.role)}}</td>
                                        <td>{{getDepartmentNames(member.department_ids)}}</td>
                                        <td>{{member.course_codes}}</td>
                                        <td><button type="button" (click)="onEditClick(member)"
                                                class="btn btn-sm btn-info mr-2">
                                                <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                            </button></td>
                                    </tr>
                                    <ng-container *ngIf="!member_list_loading && member_data_list?.length==0">
                                        <tr>
                                            <td colspan="8" class="text-center">No Records Found</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="member_list_loading && member_data_list?.length==0">
                                        <tr>
                                            <td colspan="8" class="text-center">Loading...</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document" *ngIf="memberassignForm">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title" *ngIf="!this.memberassignForm.value._id">Add Member Assignment
                        </h6>
                        <h6 class="modal-title" *ngIf="this.memberassignForm.value._id">Edit Member Assignment</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="memberassignForm" class="form-horizontal">

                                <!--                                 <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Programme</label>
                                    <select formControlName="programme_id" class="form-control"
                                        (change)="onModalFilterSelectChange('programme_id',$event)">
                                        <option value="">All Programmes</option>
                                        <option
                                            *ngFor="let programme of modal_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="programme.programme_id">{{programme.programme_name}}
                                            {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                    </select>
                                </div>
 -->
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                        year</label>
                                    <select formControlName="enrolled_year" class="form-control">
                                        <option value="">Select Enrollment year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Staff Roll
                                        No.</label>
                                    <input type="text" formControlName="roll_no" placeholder="Enter Member Roll No"
                                        class="form-control">
                                    <div *ngIf="isInvalid('roll_no')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Member Roll No. is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Role</label>
                                    <select formControlName="role" class="form-control">
                                        <option value="">Select Role</option>
                                        <option
                                            *ngFor="let role of member_roles;let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="role.id">{{role.key}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('role')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Role is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="memberassignForm.value.role == 'coe'">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                        Type</label>
                                    <select formControlName="programme_type_id" class="form-control">
                                        <option value="">Select Programme Type</option>
                                        <option
                                            *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="category.programme_type_id">{{category.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group"
                                    *ngIf="memberassignForm.value.role == 'hod' || memberassignForm.value.role == 'obe_member'">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Department(s)</label>
                                    <kendo-multiselect [data]="department_list" valueField="department_id"
                                        textField="department_name" formControlName="department_ids"
                                        placeholder="Select Department(s)"></kendo-multiselect>
                                </div>

                                <div class="form-group"
                                    *ngIf="memberassignForm.value.role == 'obe_member' || memberassignForm.value.role == 'hod'">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                        Codes</label>
                                    <textarea formControlName="course_codes"
                                        placeholder="Enter Course Codes separated by comma. Ex: 20PAC101,20PAC102 etc.,"
                                        class="form-control"></textarea>
                                    <div *ngIf="isInvalid('course_codes')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> At least one course code is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="add-btn-row" style="text-align: center;">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()"
                                        *ngIf="!member_save_loading">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()" *ngIf="!member_save_loading">Save & Close</button>
                                    <button class="btn btn-info mg-r-20" disabled
                                        *ngIf="member_save_loading">Saving...</button>

                                    <ng-container *ngIf="this.memberassignForm.value._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                            *ngIf="!map_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled
                                            *ngIf="map_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Deleting...
                                        </button>
                                    </ng-container>

                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>