import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { Floor } from './../../floor-model';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { Hostel, HostelBody } from './../../hostelModel';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as fonts from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-hostel-floors',
  templateUrl: './hostel-floors.component.html',
  styleUrls: ['./hostel-floors.component.scss']
})
export class HostelFloorsComponent implements OnInit {
  hostel: Hostel = {} as Hostel;
  hostelId: string = '';
  fonts = fonts;
  constructor(private restService: AdminservicesService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.route.params.subscribe(params => {
      this.hostelId = params.hostelId;
      this.navigationService.updateNavigation('Floors', this.router.url);
      this.restService.hostelManagementService.getHostels(params.hostelId).subscribe(hostels => {
        this.hostel = hostels[0];
      });
    });
  }

  public get hostelData(): Hostel {
    return this.hostel;
  }

  getTittle(): string {
    return 'Manage Floors';
  }

  onClose() {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  onAdd(): void {
    this.router.navigate([`admin/hostel/hostel-management/floors/create/${this.hostelId}`]);
  }

  onEdit(floor: Floor): void {
    this.router.navigate([`admin/hostel/hostel-management/floors/edit/${this.hostelId}/${floor.id}`]);
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  onDelete(floor: Floor): void {
    const subURL = `${this.hostel.id}?floorId=${floor.id}`;
    this.restService.hostelManagementService.deleteHostel(subURL).subscribe(data => {
      if (data) {
        this.hostel.floors = this.hostel.floors?.filter(flr => flr.id !== floor.id);
        this.notificationService.setNotifcation({ message: 'Floor deleted successfully', type: 'Success' });
      }
    }, this.errorHandler);
  }
}
