<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">




        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Admission Reports</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Admissions List</p> -->
                    </div>

                    <div class="card-body" *ngIf="admission_search_form">
                        <form [formGroup]="admission_search_form">
                            <div class="row row-sm">
                                <ng-container
                                    *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">


                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                regulation year</label>
                                            <select formControlName="regulation_year" class="form-control"
                                                (change)="onRegulationYearChange()">
                                                <option value="">Select regulation year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Admission</label>
                                        <select formControlName="admission_id" class="form-control"
                                            (change)="onAdmissionChange()">
                                            <option value="">Select admission</option>
                                            <option
                                                *ngFor="let admission of admissions_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="admission._id">{{admission.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="!cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select finance
                                                type</label>
                                            <select formControlName="finance_type" class="form-control"
                                                (change)="onFinanceTypeChange()">
                                                <option value="">Select finance type</option>
                                                <option
                                                    *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </form>
                    </div>
                    <ng-container *ngIf="this.admission_search_form.value.admission_id && programme_overveiw">



                        <div class="card-body">

                            <div class="row">
                                <div class="col-sm-12 col-lg-4 col-xl-3">

                                </div>
                                <div class="col-sm-12 col-lg-4 col-xl-3">
                                    <div class="card" style="cursor: pointer;" (click)="onTotalRegisteredUserClick()">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="">Total Registered Applicants</div>
                                                    <div class="h3 mt-2 mb-2">
                                                        <b>{{programme_overveiw_master.total_users|number}}</b>
                                                    </div>
                                                </div>
                                                <div class="col-auto align-self-center ">
                                                    <div class="feature mt-0 mb-0">
                                                        <i class="ti-user project bg-info-transparent text-info "></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="">Total Applications Issued</div>
                                                    <div class="h3 mt-2 mb-2">
                                                        <b>{{programme_overveiw_master.total_application_issued|number}}</b>
                                                    </div>
                                                    <p><b>Aided - {{programme_overveiw_master.total_application_aided |
                                                            number}};<br>
                                                            SF - {{programme_overveiw_master.total_application_self |
                                                            number}}
                                                        </b></p>
                                                </div>
                                                <div class="col-auto align-self-center ">
                                                    <div class="feature mt-0 mb-0">
                                                        <i
                                                            class="ti-check-box project bg-success-transparent text-success "></i>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4 col-xl-3">

                                </div>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="cookieStore.isRoleExists(['accounts','staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                            <div class="card-body">
                                <form [formGroup]="admission_search_form">
                                    <div class="row row-sm">

                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    finance type</label>
                                                <select formControlName="finance_type" class="form-control"
                                                    (change)="onFinanceTypeChange()">
                                                    <option value="">Select finance type</option>
                                                    <option
                                                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 text-right">
                                            <button class="btn btn-info mg-l-20"
                                                (click)="getMultipleSelectedApplicants()">Multiple Selected
                                                Applicants</button>

                                            <button class="btn btn-success mg-l-20"
                                                (click)="onViewCollectionClick()">View Collection</button>
                                            <button class="btn btn-primary mg-l-20"
                                                (click)="onManagementQuotaClick()">Manage Quotas</button>

                                            <div class="btn-group dropdown">
                                                <button type="button" class="btn btn-warning mg-l-20"
                                                    (click)="onTransferClick('transfer')">Manage Transfer</button>
                                                <button type="button"
                                                    class="btn btn-warning dropdown-toggle dropdown-toggle-split"
                                                    id="dropdownMenuDate" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="sr-only">Toggle Dropdown</span>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right"
                                                    aria-labelledby="dropdownMenuDate" data-x-placement="bottom-end">
                                                    <a class="dropdown-item" (click)="onTransferReportClick()">View
                                                        Transfer Report</a>
                                                </div>
                                            </div>
                                            <button class="btn btn-danger mg-l-20"
                                                (click)="onTransferClick('cancel')">Manage Cancel</button>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Prg. Type Id.</th>
                                            <th>Prg. Name</th>
                                            <th
                                                *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin', 'admission_member'])">
                                                Purchased</th>
                                            <ng-container *ngIf="programme_overveiw.programme_wise.length>0">
                                                <ng-container
                                                    *ngFor="let status_wise of programme_overveiw.programme_wise[0].status_wise;let i=index;trackBy:fromDataResolver.identify;">
                                                    <ng-container
                                                        *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                                                        <th>{{status_wise.status_text}}</th>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="!cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                                                        <ng-container
                                                            *ngIf="status_wise.status =='verification_list' || status_wise.status =='verified' || status_wise.status =='rejected' || status_wise.status =='ready_to_pay'">
                                                            <th>{{status_wise.status_text}}</th>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="programme_overveiw.programme_wise.length>0">


                                            <ng-container
                                                *ngFor="let programme_wise of programme_overveiw.programme_wise;let i=index;trackBy:fromDataResolver.identify;">
                                                <tr>
                                                    <td scope="row">
                                                        {{i+1}}

                                                    </td>
                                                    <td class="uppercase">{{programme_wise.programme_type_id}}</td>
                                                    <td class="uppercase">{{programme_wise.programme_name}}
                                                        {{programme_wise.finance_type=="regular"?"(R)":"(SF)"}}</td>
                                                    <td class="show-eye"
                                                        *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin', 'admission_member'])">

                                                        {{programme_wise.total_application_issued|number}}
                                                        <ng-container
                                                            *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">

                                                            <button type="button" class="btn btn-info btn-sm btn-eye"
                                                                (click)="onVieDetailsClick(programme_wise)">
                                                                <fa-icon [icon]="fonts.faEye"></fa-icon> View
                                                            </button>
                                                        </ng-container>
                                                    </td>
                                                    <ng-container
                                                        *ngFor="let status_wise of programme_wise.status_wise;let i=index;trackBy:fromDataResolver.identify;">


                                                        <ng-container
                                                            *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                                                            <td class="show-eye">
                                                                {{status_wise.count|number}}
                                                                <ng-container *ngIf="status_wise.max_seats">/
                                                                    {{status_wise.max_seats|number}}</ng-container>
                                                                <button type="button"
                                                                    class="btn btn-info btn-sm btn-eye"
                                                                    (click)="onStatusWiseClick(programme_wise,status_wise)">
                                                                    <fa-icon [icon]="fonts.faEye"></fa-icon> View
                                                                </button>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="!cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                                                            <ng-container
                                                                *ngIf="status_wise.status =='verification_list' ||  status_wise.status =='verified' || status_wise.status =='rejected' || status_wise.status =='ready_to_pay'">
                                                                <td class="show-eye">
                                                                    {{status_wise.count|number}}
                                                                    <ng-container *ngIf="status_wise.max_seats">/
                                                                        {{status_wise.max_seats|number}}</ng-container>
                                                                    <ng-container
                                                                        *ngIf="status_wise.status =='verification_list' || status_wise.status =='rejected' || status_wise.status == 'ready_to_pay'">
                                                                        <button type="button"
                                                                            class="btn btn-info btn-sm btn-eye"
                                                                            (click)="onStatusWiseClick(programme_wise,status_wise)">
                                                                            <fa-icon [icon]="fonts.faEye"></fa-icon>
                                                                            View
                                                                        </button>
                                                                    </ng-container>
                                                                </td>
                                                            </ng-container>
                                                        </ng-container>



                                                    </ng-container>


                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td colspan="3" scope="row">


                                                    Total </td>

                                                <td>{{programme_overveiw.total_application_issued|number}}</td>
                                                <ng-container
                                                    *ngFor="let status_wise of programme_overveiw.programme_wise[0].status_wise;let i=index;trackBy:fromDataResolver.identify;">

                                                </ng-container>


                                            </tr>
                                        </ng-container>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>


        </div>

    </div>
    <!-- Large Modal -->
    <div class="modal" id="modal-management-quota">
        <div class="modal-dialog modal-lg big-modal" role="document">
            <div class="modal-content modal-content-demo" *ngIf="management_quota_search_form">
                <div class="modal-header">
                    <h6 class="modal-title">Management Quota</h6>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="mg-b-20"></div>

                        <div class="card-body" *ngIf="management_quota_search_form">
                            <form [formGroup]="management_quota_search_form">
                                <div class="row row-sm">

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Application no</label>
                                            <input type="text" class="form-control" formControlName="application_no">
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">

                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!management_quota_search_form_loading"
                                                (click)="onApplicationSearch()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="management_quota_search_form_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>

                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>

                        <div class="card-body" *ngIf="applicant_details">
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Personal Info</label>
                                <div class="table-responsive">
                                    <table class="table table-bordered mg-b-0 text-md-nowrap">

                                        <tbody>
                                            <ng-container *ngIf="applicant_details.applicant">
                                                <tr>
                                                    <td> <b>Name</b></td>
                                                    <td>{{applicant_details.applicant.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Mobile No.</b></td>
                                                    <td>
                                                        {{applicant_details.applicant.mobile_number}}
                                                        <ng-container
                                                            *ngIf="applicant_details.applicant.mobile_number_2">
                                                            , {{applicant_details.applicant.mobile_number_2}}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="applicant_details.applicant.mobile_number_3">
                                                            , {{applicant_details.applicant.mobile_number_3}}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="applicant_details.applicant.mobile_number_4">
                                                            , {{applicant_details.applicant.mobile_number_4}}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="applicant_details.applicant.mobile_number_5">
                                                            , {{applicant_details.applicant.mobile_number_5}}
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Community</b></td>
                                                    <td>{{applicant_details.applicant.community_id}} ,
                                                        {{applicant_details.applicant.caste}}</td>
                                                </tr>
                                            </ng-container>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="mg-b-20"></div>
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Applications</label>
                                <div class="table-responsive">
                                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Appliction No</th>
                                                <!-- <th>Prg. Type Id.</th>
                                            <th>Prg. Name</th> -->
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="applicant_details.applications.length>0">


                                                <ng-container
                                                    *ngFor="let application of applicant_details.applications;let i=index;trackBy:fromDataResolver.identify;">
                                                    <tr>
                                                        <td scope="row">
                                                            {{i+1}}

                                                        </td>
                                                        <td class="uppercase">{{application.application_no}}</td>
                                                        <!-- <td class="uppercase">{{application.programme_type_id}}</td>
                                                    <td class="uppercase">{{application.programme_name}} {{application.finance_type=="regular"?"(R)":"(SF)"}}</td> -->
                                                        <td class="uppercase">{{application.status}}</td>

                                                        <td>


                                                            <ng-container *ngIf="application.status=='verified'">
                                                                <button type="button" class="btn btn-sm btn-success"
                                                                    (click)="onViewManagementClick(application)">
                                                                    View Details
                                                                </button>
                                                            </ng-container>
                                                            <ng-container *ngIf="application.status!='verified'">
                                                                Not Eligible for Ready to Pay
                                                            </ng-container>

                                                        </td>



                                                    </tr>
                                                    <tr *ngIf="application.show_detials">
                                                        <td colspan="4">
                                                            <div class="card-body" *ngIf="application.details_form">
                                                                <form [formGroup]="application.details_form">
                                                                    <div class="row row-sm">

                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                                    Quota Type</label>
                                                                                <select formControlName="quota_type"
                                                                                    class="form-control">
                                                                                    <option value="management">Mangement
                                                                                    </option>
                                                                                    <option value="sports">Sports
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="row row-sm">

                                                                        <div class="col-lg-4">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Comments</label>
                                                                                <textarea class="form-control" cols="30"
                                                                                    rows="10"
                                                                                    formControlName="comments"></textarea>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="mg-b-20"></div>
                                                                    <div class="text-align-left">
                                                                        <button class="btn ripple btn-success"
                                                                            (click)="onAllowPaymentClick(application)"
                                                                            type="button">Allow For Payment</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                </ng-container>

                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="mg-b-20"></div>
                        <div class="text-align-right">

                            <button class="btn ripple btn-secondary" (click)="onManagementQuotaCloseClick()"
                                type="button">Close</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-transfer">
        <div class="modal-dialog modal-lg big-modal" role="document">
            <div class="modal-content modal-content-demo" *ngIf="transfer_prg_search_form">
                <div class="modal-header">
                    <h6 class="modal-title">
                        <ng-container *ngIf="transfer_type=='transfer'">
                            Transfer Programme
                        </ng-container>
                        <ng-container *ngIf="transfer_type=='cancel'">
                            Cancel Programme
                        </ng-container>
                    </h6>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="mg-b-20"></div>

                        <div class="card-body" *ngIf="transfer_prg_search_form">
                            <form [formGroup]="transfer_prg_search_form">
                                <div class="row row-sm">

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Application no</label>
                                            <input type="text" class="form-control" formControlName="application_no">
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">

                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!transfer_prg_search_form_loading"
                                                (click)="onTransferApplicationSearch()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="transfer_prg_search_form_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>

                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>

                        <div class="card-body" *ngIf="transfer_prg_applicant_details">
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Personal Info</label>
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">

                                        <tbody>
                                            <ng-container *ngIf="transfer_prg_applicant_details.applicant">
                                                <tr>
                                                    <td> <b>Name</b></td>
                                                    <td>{{transfer_prg_applicant_details.applicant.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Mobile No.</b></td>
                                                    <td>
                                                        {{transfer_prg_applicant_details.applicant.mobile_number}}
                                                        <ng-container
                                                            *ngIf="transfer_prg_applicant_details.applicant.mobile_number_2">
                                                            ,
                                                            {{transfer_prg_applicant_details.applicant.mobile_number_2}}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="transfer_prg_applicant_details.applicant.mobile_number_3">
                                                            ,
                                                            {{transfer_prg_applicant_details.applicant.mobile_number_3}}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="transfer_prg_applicant_details.applicant.mobile_number_4">
                                                            ,
                                                            {{transfer_prg_applicant_details.applicant.mobile_number_4}}
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="transfer_prg_applicant_details.applicant.mobile_number_5">
                                                            ,
                                                            {{transfer_prg_applicant_details.applicant.mobile_number_5}}
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Community</b></td>
                                                    <td>{{transfer_prg_applicant_details.applicant.community_id}} ,
                                                        {{transfer_prg_applicant_details.applicant.caste}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Programme Name</b></td>
                                                    <td>{{transfer_prg_applicant_details.current_application.programme_name}}
                                                        {{transfer_prg_applicant_details.current_application.finance_type=="regular"?"
                                                        (R)":" (SF)"}}</td>
                                                </tr>

                                                <tr>
                                                    <td><b>Cancel Programme</b></td>
                                                    <td>
                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                            <button type="button" class="btn btn-sm btn-danger"
                                                                (click)="onCancelPrgramme()">Cancel Programme</button>
                                                        </div>
                                                    </td>
                                                </tr>


                                            </ng-container>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="transfer_type=='transfer'">
                                <div class="mg-b-20"></div>
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Applications</label>
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Appliction No</th>
                                                <!-- <th>Prg. Type Id.</th>-->
                                                <th>Prg. Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="transfer_prg_applicant_details.applications.length>0">


                                                <ng-container
                                                    *ngFor="let application of transfer_prg_applicant_details.applications;let i=index;trackBy:fromDataResolver.identify;">
                                                    <tr>
                                                        <td scope="row">
                                                            {{i+1}}

                                                        </td>
                                                        <td class="uppercase">{{application.application_no}}</td>
                                                        <!-- <td class="uppercase">{{application.programme_type_id}}</td>-->
                                                        <td class="uppercase">{{application.programme_name}}
                                                            {{application.finance_type=="regular"?" (R)":" (SF)"}}</td>
                                                        <td class="uppercase">{{application.status}}</td>

                                                        <td>


                                                            <ng-container *ngIf="application.status=='ready_to_pay'">
                                                                <button type="button" class="btn btn-sm btn-success"
                                                                    (click)="onViewTransferEstimateClick(application)">
                                                                    View Details
                                                                </button>
                                                            </ng-container>
                                                            <ng-container *ngIf="application.status!='ready_to_pay'">
                                                                Not Eligible for Transfer
                                                            </ng-container>

                                                        </td>



                                                    </tr>
                                                    <tr *ngIf="application.show_detials">
                                                        <td colspan="5">
                                                            <div class="card-body" *ngIf="application.details_form">

                                                                <table
                                                                    class="table table-bordered mg-b-0 text-md-nowrap">

                                                                    <tbody>



                                                                        <tr>
                                                                            <td scope="row">
                                                                                Previous Prg. Fee Paid

                                                                            </td>
                                                                            <td class="uppercase">
                                                                                {{application.details_form.old_fee_paid_amount}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">
                                                                                Trasfer Fee
                                                                            </td>
                                                                            <td class="uppercase">
                                                                                {{application.details_form.transfer_fee}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">
                                                                                Total Previous Balance (student account)
                                                                            </td>
                                                                            <td class="uppercase">
                                                                                {{application.details_form.old_paid_balance}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">
                                                                                New Prg. Fee
                                                                            </td>
                                                                            <td class="uppercase">
                                                                                {{application.details_form.new_net_fee_amount}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">
                                                                                New Amount to Pay
                                                                            </td>
                                                                            <td class="uppercase">
                                                                                {{application.details_form.amount_to_pay}}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <ng-container
                                                                                    *ngIf="application.details_form.amount_to_pay>0">
                                                                                    <div class="alert alert-success"
                                                                                        role="alert">

                                                                                        <strong>
                                                                                            <div class="spinner-grow text-success"
                                                                                                role="status">
                                                                                                <span
                                                                                                    class="sr-only">Loading...</span>
                                                                                            </div>
                                                                                        </strong> Student needs to pay :
                                                                                        {{application.details_form.amount_to_pay|currency:'INR':'Rs.
                                                                                        '}}
                                                                                    </div>
                                                                                </ng-container>

                                                                                <ng-container
                                                                                    *ngIf="application.details_form.amount_to_pay<0">
                                                                                    <div class="alert alert-danger"
                                                                                        role="alert">

                                                                                        <strong>
                                                                                            <div class="spinner-grow text-danger"
                                                                                                role="status">
                                                                                                <span
                                                                                                    class="sr-only">Loading...</span>
                                                                                            </div>
                                                                                        </strong> College needs to
                                                                                        refund :
                                                                                        {{application.details_form.amount_to_pay|currency:'INR':'Rs.
                                                                                        '}}
                                                                                    </div>
                                                                                </ng-container>
                                                                            </td>
                                                                        </tr>


                                                                    </tbody>
                                                                </table>
                                                                <div class="mg-b-20"></div>
                                                                <div class="text-align-left">
                                                                    <button class="btn ripple btn-success"
                                                                        (click)="onConfirmTransferClick(application)"
                                                                        type="button">Complete Transfer</button>
                                                                </div>

                                                            </div>
                                                        </td>

                                                    </tr>
                                                </ng-container>

                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="mg-b-20"></div>
                        <div class="text-align-right">

                            <button class="btn ripple btn-secondary" (click)="onTransferCloseClick()"
                                type="button">Close</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->


    <!-- Large Modal -->
    <div class="modal" id="modal-multiple-selection">
        <div class="modal-dialog modal-lg big-modal" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">Applicants Selected in Multiple Programmes</h6>
                </div>
                <div class="modal-body">
                    <div class="card">
                        <div class="mg-b-20"></div>

                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Programme Type</th>
                                            <th>Aadhar No.</th>
                                            <th>Name</th>
                                            <th>Mobile No.</th>
                                            <th>DOB</th>
                                            <th>Community</th>
                                            <th>Selected Programmes & Stage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let applicant of multiple_selection_list; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{applicant.apply_for | uppercase}}</td>
                                            <td>{{applicant.aadhar_card_no}}</td>
                                            <td>{{applicant.name}}</td>
                                            <td>
                                                <span *ngIf="applicant.mobile_number">{{applicant.mobile_number}}</span>
                                                <span *ngIf="applicant.mobile_number_2">,
                                                    {{applicant.mobile_number_2}}</span>
                                                <span *ngIf="applicant.mobile_number_3">,
                                                    {{applicant.mobile_number_3}}</span>
                                            </td>
                                            <td>
                                                {{applicant.birth_date_display}}
                                            </td>
                                            <td>{{applicant.community_id | uppercase}}</td>
                                            <td>
                                                <p *ngFor="let programme of applicant.application_data; let j = index;">
                                                    <span>{{programme.application_no}}</span>
                                                    -<span>{{getProgrammeName(programme.programme_id)}}</span>
                                                    -<span>{{programme.status.split('_').join(' ')}}</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngIf="multiple_selection_list.length == 0 && multiple_selection_list_loading">
                                            <tr>
                                                <td class="text-center" colspan="8">
                                                    <b>Loading...</b>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="multiple_selection_list.length == 0 && !multiple_selection_list_loading">
                                            <tr>
                                                <td class="text-center" colspan="8">
                                                    <b>No records Found</b>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="mg-b-20"></div>
                        <div class="text-align-right">
                            <button class="btn ripple btn-secondary" (click)="closeMultipleSelection()"
                                type="button">Close</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->


</div>
<!-- /main-content -->
<app-footer></app-footer>