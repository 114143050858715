import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-staff-late-entry',
  templateUrl: './staff-late-entry.component.html',
  styleUrls: ['./staff-late-entry.component.scss']
})
export class StaffLateEntryComponent implements OnInit {
  title = 'Students Attendance Late Entry';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;
  public type: string = "date_search";
  table_data: LateEntryResultInterface[] = [];
  table_data_loading: boolean = false;
  assign_save_loading: boolean = false;


  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) {

  }

  ngOnInit(): void {
    this.buildSearchForm();
  }

  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      roll_no: [''],
      course_code: [''],
    });
  }

  isTableDataEmpty() {
    if (JSON.stringify(this.table_data) == '{}' || JSON.stringify(this.table_data) == '[]') {
      return true;
    }
    return false;
  }


  changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.table_data = [];
  }

  /* changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.table_data = [];
  } */
  async onSearchClick() {
    this.table_data = [];
    var search_query: any = {};
    if (this.type == 'roll_no_search') {
      if (!this.filter_form.value.roll_no) {
        alert("Please enter valid Roll No."); return;
      }
      search_query.roll_no = this.filter_form.value.roll_no;
    }
    if (this.type == 'course_code_search') {
      if (!this.filter_form.value.course_code) {
        alert("Please enter valid Course Code"); return;
      }
      search_query.course_code = this.filter_form.value.course_code;
    }

    if (this.type == 'date_search') {
      if (!this.filter_form.value.start_date) {
        alert("Please choose start date"); return;
      }
      if (!this.filter_form.value.end_date) {
        alert("Please choose end date"); return;
      }
      search_query.start_date = this.filter_form.value.start_date;
      search_query.end_date = this.filter_form.value.end_date;
    }
    console.log(search_query);
    this.table_data_loading = true;
    try {
      /* this.table_data = [
        {
          date: "2021-10-31T00:00",
          staff_id: "SF602",
          staff_name: "Ajay Balaji K",
          course_code: "20UAC101",
          course_name: "Programming In C",
          hour_number: 3,
          re_open_request_status: "NA"
        },
        {
          date: "2021-10-31T00:00",
          staff_id: "SF602",
          staff_name: "Ajay Balaji K",
          course_code: "20UAC101",
          course_name: "Programming In C",
          hour_number: 3,
          re_open_request_status: "READY"
        },
        {
          date: "2021-10-31T00:00",
          staff_id: "SF602",
          staff_name: "Ajay Balaji K",
          course_code: "20UAC103",
          course_name: "Digital Fundamentals",
          hour_number: 3,
          re_open_request_status: "SUBMITTED"
        },
        {
          date: "2021-10-31T00:00",
          staff_id: "SF602",
          staff_name: "Ajay Balaji K",
          course_code: "20UAC101",
          course_name: "Programming In C Lab",
          hour_number: 3,
          re_open_request_status: "ACCEPTED"
        },
      ] */
      const service_response = await this.restService.getLateEntryRequests(search_query);
      if (service_response && service_response.success) {
        console.log(service_response);
        this.table_data = service_response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }

  async approveStatus(data: LateEntryResultInterface) {
    try {
      const update_data = {
        "re_open_request_status": "ACCEPTED"
      }
      const service_response = await this.restService.updateLateEntryRequest(data._id, update_data);
      if (service_response && service_response.success) {
        console.log(service_response);
        alert("Request approved succesfully");
        this.onSearchClick();
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }

  async revertStatus(data: LateEntryResultInterface) {
    try {
      const update_data = {
        "re_open_request_status": "REJECTED"
      }
      const service_response = await this.restService.updateLateEntryRequest(data._id, update_data);
      if (service_response && service_response.success) {
        console.log(service_response);
        alert("Request approved succesfully");
        this.onSearchClick();
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }
}


interface LateEntryResultInterface {
  _id: string,
  date: string,
  staff_id: string,
  staff_name: string,
  course_code: string,
  course_name: string,
  reason?: string,
  type?: string,
  hour_number: number,
  re_open_request_status: string
  createdAt: string,
}