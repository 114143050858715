import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as querystring from 'querystring';
import { CookieStore } from '../helpers/CookieStore';
import { ICourses } from './entity/ICourses';
import { ICourseSearchEntity } from './entity/ICourseSearchEntity';
import { HostelManagementService } from './hostel-management.service';
import { MenuItemService } from './menu-item.service';
import { RoomAssetService } from './room-asset.service';
import { StudentRoomMapService } from './student-room-map.service';
@Injectable({
  providedIn: 'root',
})
export class AdminservicesService {
  private base_url = '';
  constructor(
    private httpClient: HttpClient,
    private hostelManagement: HostelManagementService,
    // tslint:disable-next-line:align
    private roomAsset: RoomAssetService,
    // tslint:disable-next-line:align
    private studentRoomMap: StudentRoomMapService,
    // tslint:disable-next-line:align
    private menuItem: MenuItemService) {
    /* if (location.hostname === 'localhost') {
      this.base_url = 'http://localhost:3000';
    } else { */
    this.base_url = 'https://anjac-api.anjaconline.org';
    // this.base_url = 'http://localhost:5002';
    // this.base_url = 'http://192.168.100.47:5001';
    //}
  }

  public get hostelManagementService(): HostelManagementService {
    return this.hostelManagement;
  }

  public get roomAssetService(): RoomAssetService {
    return this.roomAsset;
  }

  public get studentRoomMapService(): StudentRoomMapService {
    return this.studentRoomMap;
  }

  public get menuItemService(): MenuItemService {
    return this.menuItem;
  }

  public initializeServices(): void {
    this.hostelManagement.url = this.base_url;
    this.hostelManagement.headers = this.getHeaderOptions();
    this.roomAsset.url = this.base_url;
    this.roomAsset.headers = this.getHeaderOptions();
    this.studentRoomMap.url = this.base_url;
    this.studentRoomMap.headers = this.getHeaderOptions();
    this.menuItem.url = this.base_url;
    this.menuItem.headers = this.getHeaderOptions();
  }

  public getHeaderOptions(): any {
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const token = CookieStore.getBearerToken();
    if (token) {
      headers.Authorization = token;
    }
    const header_options = {
      headers: new HttpHeaders(headers),
      responceType: 'json',
    };
    return header_options;
  }

  getBaseUrl(): string {
    return this.base_url;
  }

  getLoginUrl(): string {
    return `${this.base_url}/login-srv/login`;
  }

  async getUserInfo(): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/users-srv/users/userinfo`, this.getHeaderOptions())
      .toPromise();
  }

  // Hostel
  // Students
  async getHostelAdmissionStatus(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/hostel-srv/application/student/find`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async applyForHostel(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/hostel-srv/application/apply`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getHostelFeePaymentUrl(): Promise<string> {
    const token = CookieStore.getToken();
    return Promise.resolve(
      `${this.base_url}/hostel-srv/application/student/payonline?access_token=${token}`
    );
  }

  async handleOfflineHostelPayment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/hostel-srv/application/update/offlinepayment`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Staff
  async getAllHostelAppliedList(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/hostel-srv/application/admin/list`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async changeStudentApplicationStatus(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/hostel-srv/application/change/status`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchStaff(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/staffs-srv/staff/utils/search`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async createStaffInfo(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/staffs-srv/staff`, data, this.getHeaderOptions())
      .toPromise();
  }

  async updateStaffInfo(data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/staffs-srv/staff/${data.sub}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getStaffInfo(sub: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/staffs-srv/staff/${sub}`, this.getHeaderOptions())
      .toPromise();
  }

  async findStaffInfo(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/staffs-srv/staff/utils/find`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findStaffBuRollNo(roll_no: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/staffs-srv/staff/utils/find/by/rollno/${roll_no}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Transport
  // Student
  async getTransportRouteList(): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/transport-srv/routes`, this.getHeaderOptions())
      .toPromise();
  }

  async getTransportAdmissionStatus(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/transport-srv/application/student/find`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async applyForTransport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/transport-srv/application/apply`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getTransportFeePaymentUrl(): Promise<string> {
    const token = CookieStore.getToken();
    return Promise.resolve(
      `${this.base_url}/transport-srv/application/student/payonline?access_token=${token}`
    );
  }

  async updateStudentInfoByAdmin(data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/students-srv/student/${data.sub}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateStudentInfo(data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/students-srv/student/me`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async handleOfflineTransportPayment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/transport-srv/application/update/offlinepayment`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Student Payment
  async getStudentPaymentUrl(fee_header_type: string, payment_init_id: string): Promise<string> {
    const token = CookieStore.getToken();
    return Promise.resolve(
      `${this.base_url}/accounts-srv/transactions/online/initiate/${fee_header_type}/${payment_init_id}?access_token=${token}`
    );
  }

  async getStudentPaymentList(): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/accounts-srv/payments/my/payments`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async getMyPaymentList(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/student-srv/payments/fees/coursefee/myfeesdetails`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCourseFeePaymentUrl(id: string): Promise<string> {
    const token = CookieStore.getToken();
    return Promise.resolve(
      `${this.base_url}/student-srv/payments/fees/coursefee/payonline/${id}?access_token=${token}`
    );
  }

  // Student Payment 2
  async getMyPayment2List(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/student-srv/payments2/fees/coursefee/myfeesdetails`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCourseFeePayment2Url(id: string): Promise<string> {
    const token = CookieStore.getToken();
    return Promise.resolve(
      `${this.base_url}/student-srv/payments2/fees/coursefee/payonline/${id}?access_token=${token}&return_url=${encodeURIComponent(location.href)}&payment_date=${new Date().toISOString()}`
    );
  }

  // Staff
  async getAllTransportAppliedList(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/transport-srv/application/admin/list`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async changeTransportStudentApplicationStatus(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/transport-srv/application/change/status`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Admins

  // Admissions
  async createAdmission(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/admission`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateAdmission(programme_type_id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/admission-srv/admission/${programme_type_id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getAdmissionList(): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/admission-srv/admission`, this.getHeaderOptions())
      .toPromise();
  }

  async getAdmissionById(programme_type_id: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/admission-srv/admission/${programme_type_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteAdmissionById(programme_type_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/admission-srv/admission/${programme_type_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchAdmissionList(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/admission/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Admission -> Application Fees
  async createApplicationFeeBulk(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/admission-flow/application/fee/utils/create/bulk`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getSavedApplicationFeeSettings(
    admission_id: string,
    programme_type_id: string,
    finance_type: string
  ): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/admission-srv/admission-flow/application/fee/utils/search/${admission_id}/${programme_type_id}/${finance_type}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Admission -> Programme Fees
  async createProgrammeFeeBulk(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/admission-flow/prg/fee/utils/create`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getSavedProgrammeFeeSettings(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/admission-flow/prg/fee/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async generateProgrammeFees(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/programme-fee/header/utils/generate/programme/fee`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Applicant Reports

  async getApplicantOverallList(admission_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-applicant-srv/reports/overall/${admission_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getEnrolledProgrameWise(admission_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-applicant-srv/reports/overall/programme-wise/${admission_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCommunityWise(admission_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-applicant-srv/reports/overall/community-wise/${admission_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getApplicationCollectionWise(admission_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-applicant-srv/reports/overall/payment/application-wise/${admission_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammeCollectionWise(admission_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-applicant-srv/reports/overall/payment/programme-wise/${admission_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getApplicationsByFilter(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/reports/appicants/issued`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Department Category
  async createDepartmentCategory(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/department-srv/department-category`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateDepartmentCategory(
    department_category_id: string,
    data: any
  ): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/department-srv/department-category/${department_category_id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getDepartmentCategories(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/department-srv/department-category`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getDepartmentCategoryById(
    department_category_id: string
  ): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/department-srv/department-category/${department_category_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteDepartmentCategoryById(
    department_category_id: string
  ): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/department-srv/department-category/${department_category_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Departments
  async createDepartment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/department-srv/department`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateDepartment(department_code: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/department-srv/department/${department_code}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getDepartmentList(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/department-srv/department`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getDepartmentById(department_code: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/department-srv/department/${department_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteDepartmentById(department_code: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/department-srv/department/${department_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchDepartments(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/department-srv/department/utils/search`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Programme Types
  async createProgrammeType(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/programme-type`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateProgrammeType(
    programme_type_id: string,
    data: any
  ): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/programme-srv/programme-type/${programme_type_id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammeTypes(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/programme-srv/programme-type`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getSkillSetUploadSearch(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/non-academic/skillset/upload/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammeTypeById(programme_type_id: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/programme-srv/programme-type/${programme_type_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteProgrammeTypeById(programme_type_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/programme-srv/programme-type/${programme_type_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Programmes
  async createProgramme(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/programme`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateProgramme(programme_code: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/programme-srv/programme/${programme_code}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammeList(): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/programme-srv/programme`, this.getHeaderOptions())
      .toPromise();
  }

  async getProgrammeByCode(programme_code: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/programme-srv/programme/${programme_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteProgrammeById(programme_code: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/programme-srv/programme/${programme_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchProgrammes(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/programme/utils/search`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchProgrammesLite(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/programme/utils/search/lite`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Programmes Section
  async deleteProgrameSectionById(section_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/programme-srv/programme/sections/${section_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Programmes Revisions
  async createProgrameRevision(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/revisions`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateProgrameRevision(
    programme_id: string,
    revision_year: number,
    data: any
  ): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/programme-srv/revisions/${programme_id}/${revision_year}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteProgrameRevisionById(revision_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/programme-srv/revisions/${revision_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findProgrameRevisionList(programme_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/revisions/utils/find/revsions/by/${programme_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // programme scheme header
  async createProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/header`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/header/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchProgrameSchemeHeaderForStaffAssignment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/header/utils/search/for/staff/assignment`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async approveProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/header/utils/approve`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammeById(programme_id: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/programme-srv/programme/utils/search/programme/${programme_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }



  async unApproveProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/header/utils/unapprove`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // common programme scheme header
  async createCommonProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/common-programme-scheme/header`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchCommonProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/common-programme-scheme/header/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async approveCommonProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/common-programme-scheme/header/utils/approve`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async unApproveCommonProgrameSchemeHeader(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/common-programme-scheme/header/utils/unapprove`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteCommonProgrameSchemeById(id: any): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/common-programme-scheme/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Common section
  async getCmnPrgCourseSections(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/cmn-prg-course-section/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async createCmnPrgCourseSectionInBulk(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/cmn-prg-course-section/utils/bulk`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteCmnPrgCourseSection(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/cmn-prg-course-section/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // programme scheme
  async createBulkProgrameScheme(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/scheme/utils/bulk/save`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchProgrameScheme(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-scheme/scheme/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteProgrameScheme(id: any): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/programme-scheme/scheme/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Students
  async searchStudentWithProgrammes(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/student/programme/search`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchAllStudents(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/student/programme/search/all`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getStudentInfo(sub: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/students-srv/student/${sub}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getStudentByEmail(email: string): Promise<any> {
    return this.httpClient
      .get<any>(
        `${this.base_url}/students-srv/student/emailSearch/${email}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getStudentByRollNo(rollNo: string): Promise<any> {
    return this.httpClient
      .get<any>(
        `${this.base_url}/students-srv/student/rollno/${rollNo}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async resetUserPassword(data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/users-srv/users/utils/password/reset/${data.sub}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Students Fees
  async getFeeScheduleList(): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/fee-srv/fee`, this.getHeaderOptions())
      .toPromise();
  }

  async searchStudentFeesReport(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/student-srv/payments/fees/reports/list`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async handleOfflineCoursePayment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/student-srv/payments/fees/reports//offlinepayment`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  getReceiptDownloadURL(): string {
    return `${this.base_url}/student-srv/payments/fees/reports/utils/download/receipt`;
  }

  // Courses
  async searchCouseList(data: ICourseSearchEntity): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/courses-srv/course/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async createCourse(data: ICourses): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/courses-srv/course`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateCourse(data: ICourses): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/courses-srv/course/${data.course_code}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findCourse(course_code: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/courses-srv/course/${course_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteCourse(course_code: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/courses-srv/course/${course_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchCouseListLite(data: ICourseSearchEntity): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/courses-srv/course/utils/search/lite`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // staff programme scheme assign
  async assignStaffWithCourse(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/staffs-srv/course`, data, this.getHeaderOptions())
      .toPromise();
  }

  async getAssignedStaffWithCourse(
    semester: number,
    course_code: string,
    enrolled_year: string,
    section_id: string
  ): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/staffs-srv/course/${semester}/${course_code}/${enrolled_year}/${section_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async unAssignStaffWithCourse(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/staffs-srv/course/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // student programme
  async searchStudentCourse(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/course/utils/programme-scheme/assigned`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async checkAndAssignCourse(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/course/utils/programme-scheme/assign/${data.part}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // obe components
  async createComponent(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateComponent(data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/obe-srv/obe/components/${data._id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteComponent(id: any): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/obe/components/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchComponent(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // obe staff mapping
  async getCoursesByStaff(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/staffs-srv/course/utils/search/courses/by/staff`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCoByCourse(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components/utils/search/by/course`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // obe co components mapping
  async getCouseCoCOM(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components/limits/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async builkUpdateCourseCoCOM(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components/limits/utils/bulk/update`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // obe co components mapping
  async findCOCOMMarksList(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components/students/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async createCourseCoCOMMark(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/components/students`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  bulkCourseCoCOMMark(formData: any, query: string): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/obe-srv/obe/components/students/utils/bulk/upload?${query}`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async getPAMReport(
    programme_id: string,
    regulation_year: number
  ): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/revisions/utils/reports/programme/pam/${programme_id}/${regulation_year}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCASReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-course/reports/utils/attainment/report`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCOSReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/programme-course/reports/utils/consolidate/report`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // assign  students to course
  async getAssignedStudentsForCourse(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/course/utils/programme-scheme/course/search/assigned`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async assignStudentsToCourse(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/course/utils/programme-scheme/course/assign`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async removeStudentsFromCourse(student_course_map_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/students-srv/course/utils/programme-scheme/course/delete/assigned/${student_course_map_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // assign questions
  async createOrUpdateSurveyQuestionsInBulk(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/course/survey/utils/create`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getOBESurveyQuestions(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/course/survey/utils/list`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // assign questions status
  async chanegSurveyStatus(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/course/survey/status/utils/change`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async isSurveyStatusOpen(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/course/survey/status/utils/check`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // accounts
  async createAccount(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/colllege-bank-accounts/accounts`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getAccountList(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/colllege-bank-accounts/accounts`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteAccountById(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/colllege-bank-accounts/accounts/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Admission Settings
  async createAdmissionSettings(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/settings/utils/create/bulk`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getSavedAdmissionSettings(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/settings/utils/search`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // admission reports
  async getOverAllAdmissionCounts(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/reports/dashboard',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async getRegisteredApplicants(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/reports/status/registered',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Schools Subject
  async getSchoolSubjects(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/admission-srv/school/subject`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getApplicantInfo(applicant_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/applicant/info/${applicant_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async saveApplicantInfo(applicant_id: string, data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/applicant/save/${applicant_id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // fileupload
  uploadFile(formData: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/admission-srv/applicant/docs/upload/admin`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  // entrance exam
  async findEntranceExamList(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/utils/find/list',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async createOrUpdateEntranceExam(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // entrance exam patch
  async createOrUpdateEntranceExamPatch(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/patch',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // entrance question
  async findEntranceExamQuestionList(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/question/utils/find/list',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async createOrUpdateEntranceExamQuestion(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/question',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async removeExamQuestionById(question_id: string): Promise<any> {
    return this.httpClient
      .delete(
        this.base_url + '/admission-srv/entrance/question/' + question_id,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async removeExamQuestionBulk(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/question/utils/delete/bulk',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async uploadQuestionFile(formData: any, query_data: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url
          }/admission-srv/entrance/question/upload/questions?${querystring.stringify(
            query_data
          )}`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  // entrance exam exam hall
  async getExamApplicantDetails(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/applicant/exams/admin/details',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async reAllowExam(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url +
        '/admission-srv/entrance/applicant/exams/admin/questions/reallow',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async closeExamPatch(patch_id: string): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/patch/utils/close/' + patch_id,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // entrance exam question category
  async findPrgCatRuleList(
    programme_type_id: string,
    admission_id: string
  ): Promise<any> {
    return this.httpClient
      .post(
        this.base_url +
        '/admission-srv/entrance/question/category/utils/find/list/' +
        programme_type_id +
        '/' +
        admission_id,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async createOrUpdatePrgCatRule(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/entrance/question/category',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // admission status wise
  async getApplicantsWithGivenStatus(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/applicant/reports/status-wise',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async saveApplicantsWithGivenStatus(data: any): Promise<any> {
    return this.httpClient
      .post(
        this.base_url + '/admission-srv/applicant/reports/status-wise/change',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async saveApplicantsWithGivenStatusBulk(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        this.base_url +
        '/admission-srv/applicant/reports/status-wise/change/bulk',
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammesNeedsEntrance(admission_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/entrance/utils/find/programme/needs/entrance/${admission_id}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async clearOfflinePaymentDues(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/applicant/reports/status-wise/payment/offline/clear/due`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async clearOnlinePaymentDues(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/applicant/programme/payment/handle/manual/collection`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Management quota
  async getDetailsByApplicationNo(application_no: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/applicant/management/find/details/${application_no}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // email generation
  async generateBulkEmail(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/entroll/email/create/bulk`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  // generate rollno
  async generateRollNo(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/entroll/rollno/generate/bulk`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Transfer corse
  async checkAndGetCoursesToTransfer(application_no: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/programme/transfer/details/${application_no}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getTransferCourseEstimate(
    from_application_no: string,
    to_application_no: string
  ): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/programme/transfer/estimate/${from_application_no}/${to_application_no}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async transferCourse(
    from_application_no: string,
    to_application_no: string
  ): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/programme/transfer/transfer/${from_application_no}/${to_application_no}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // cancel prgramme
  async cancelCourse(application_no: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/programme/transfer/cancel/${application_no}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Repeat semester student attendance
  async markRepeatSemesterAttendance(data: any): Promise<any> {
    return this.httpClient
    .post(
      `${this.base_url}/timetable-srv/students/repeat/fill/attendance`,
      data,
      this.getHeaderOptions()
    ).toPromise();
  }

  async getRepeatSemesterStudentAttendance(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/timetable-srv/reports/repeat-student/search`,
        data,
        this.getHeaderOptions()
      ).toPromise();
  }

  // attendance
  async getAttendanceStatusReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/attendance/find/students`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateAttendanceStatus(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/attendance`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // accounts fee
  async getFeeCollectionReport(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/accounts-srv/fee/programme/utils/search`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getOfflineDue(query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/applicant/reports/status-wise/offline-due`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // transfer details
  async getTransferList(type: string, query: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/admission-srv/programme/transfer/util/${type}/reports`,
        query,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Student Course Map
  async getCourseListForStudentBySub(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/obe/courses/find`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async requestElectiveCourses(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/obe/courses/request/course`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // Elective Course Manage Admin
  async getAllElectiveCourseRequestList(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/obe/admin/courses/find`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async approveElectiveCourseRequest(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/obe/admin/courses/approve`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async reAllowElectiveCourseRequest(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/obe/admin/courses/reallow`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  // obe calculation component
  async getOBEComponentCalculation(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/com/calculation/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async createComponentBulk(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/obe/com/calculation/utils/create/bulk`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Exam Fees Types API
  async getExamFeesTypeList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/exam-srv/exam-fee/type/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  async createExamFeeType(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam-fee/type`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  async updateExamFeeType(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/exam-fee/type/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteExamFeeType(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/exam-fee/type/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }




  //Exam Fees Settings List API
  async getExamFeesHeaderList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/exam-srv/exam-fee/header/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  async createExamFeesHeader(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam-fee/header`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }



  async updateExamFeesHeader(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/exam-fee/header/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteExamFeeHeader(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/exam-fee/header/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Exam Course Type Fee Map

  async getExamCourseFeeMap(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/exam-srv/exam-fee/course-type-fee-map/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  async createExamCourseFeeMap(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam-fee/course-type-fee-map`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }



  async updateExamCourseFeeMap(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/exam-fee/course-type-fee-map/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteExamCourseFeeMap(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/exam-fee/course-type-fee-map/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getExamCourseFeeBulkUpload(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/exam-srv/exam-fee/course-type-fee-map/utils/import/bulk`, data, this.getHeaderOptions())
      .toPromise();
  }


  //Transaction Report APIs
  async searchTransactions(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/accounts-srv/transactions/online/utils/search/transactions`, data, this.getHeaderOptions())
      .toPromise();
  }

  async viewTransactionById(id: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/accounts-srv/transactions/online/utils/find/transaction/by/${id}`, {}, this.getHeaderOptions())
      .toPromise();
  }

  async searchFees(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/accounts-srv/payments/utils/search/fees`, data, this.getHeaderOptions())
      .toPromise();
  }

  //APply fine for exam fee
  async applyFineExamFee(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/exam-srv/exam-fee/header/utils/apply/fine`, data, this.getHeaderOptions())
      .toPromise();
  }

  async onlineAdjustment(clg_trans_id: any, data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/accounts-srv/transactions/online/utils/adjust/online/${clg_trans_id}`, data, this.getHeaderOptions())
      .toPromise();
  }

  async getStudentFeeByRollNo(roll_no: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/accounts-srv/payments/students/payments/${roll_no}`, {}, this.getHeaderOptions())
      .toPromise();
  }

  async offlineAdjustment(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/accounts-srv/transactions/online/utils/adjust/offline`, data, this.getHeaderOptions())
      .toPromise();
  }


  //Student Count Report
  async getStudentCount(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/students-srv/search/count`, data, this.getHeaderOptions())
      .toPromise();
  }

  //Create Student

  async createStudent(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/students-srv/student`, data, this.getHeaderOptions())
      .toPromise();
  }


  //Programme Fees Settings List API
  async getProgrammeFeesHeaderList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/programme-srv/programme-fee/header/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  async createProgrammeFeesHeader(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/programme-srv/programme-fee/header`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }



  async updateProgrammeFeesHeader(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/programme-srv/programme-fee/header/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteProgrammeFeeHeader(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/programme-srv/programme-fee/header/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Programme Map
  async createProgrammeMap(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/students-srv/programme`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteProgrammeMap(programme_id: string, sub: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/students-srv/programme/${programme_id}/${sub}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //New OBE Components Master
  async createUpdateComponent(data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/components`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getComponentList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/components/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteComponentById(component_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/components/${component_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //New Part Settings Master
  async createUpdatePart(data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/parts`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getPartList(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/parts/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteParttById(part_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/parts/${part_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //New Course Scheme APIs
  async createUpdateCourse(course_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/course-scheme`, course_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async getCourses(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/courses/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async getCourseScheme(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }


  async getCourseDetailsForStaffAssignment(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/courses/utils/search/for/staff-assignment`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteCourseById(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/course-scheme/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async coursesBulkUpload(file: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/utils/upload/bulk`, file, this.getHeaderOptions())
      .toPromise();
  }

  async findCourseV2(course_code: string): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/obe-srv/v2/courses/${course_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getPAMReportV2(
    programme_id: string,
    regulation_year: number
  ): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/courses/utils/reports/programme/pam/${programme_id}/${regulation_year}`,
        {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //for new obe pam matrix
  //obe-srv/v2/courses/utils/reports/programme/pam/:programme_id/:regulation_year


  //New Course Scheme CO-PO MappingAPIs
  async createUpdateCOPO(map_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/course-scheme/co-po-map`, map_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async createUpdateCOPOBulk(map_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/course-scheme/co-po-map/utils/add/bulk`, map_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async getCourseCOPO(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/co-po-map/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteCourseCOPOById(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/course-scheme/co-po-map/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async coursesCOPOBulkUpload(file: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/co-po-map/utils/upload/bulk`, file, this.getHeaderOptions())
      .toPromise();
  }


  //Staff Assignment
  async addStaffCourseMap(map_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/staff-course-map`, map_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async searchStaffCourseMap(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/staff-course-map/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async getGuestStaffUsers(): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/exam/setup/utils/find/guest`, this.getHeaderOptions())
      .toPromise();
  }

  async searchStaffCourseMapFilter(type: string, search_data: any): Promise<any> {
    if (type == 'roll_no_search') {
      return this.httpClient
        .post(`${this.base_url}/obe-srv/v2/staff-course-map/utils/search/assignment/by_roll_no`, search_data, this.getHeaderOptions())
        .toPromise();
    }
    else if (type == 'course_code_search') {
      return this.httpClient
        .post(`${this.base_url}/obe-srv/v2/staff-course-map/utils/search/assignment/by_course_code`, search_data, this.getHeaderOptions())
        .toPromise();
    }
    else {
      return this.httpClient
        .post(`${this.base_url}/obe-srv/v2/staff-course-map/utils/search/assignment/by_department_id`, search_data, this.getHeaderOptions())
        .toPromise();
    }

  }

  async deleteStaffCourseMap(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/staff-course-map/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async staffCourseBulkUpload(formData: any): Promise<any> {
    /* return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/staff-course-map/utils/upload/bulk`, file, this.getHeaderOptions())
      .toPromise(); */
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/obe-srv/v2/student-course-map/utils/upload/bulk`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }


  //Student-Course Assignment
  async addStudentCourseMap(map_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/student-course-map`, map_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  async searchStudentCourseMap(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-map/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }
  async searchStudentCourseMapFilter(type: string, search_data: any): Promise<any> {
    if (type == 'roll_no_search') {
      return this.httpClient
        .post(`${this.base_url}/obe-srv/v2/student-course-map/utils/search/assignment/by_roll_no`, search_data, this.getHeaderOptions())
        .toPromise();
    }
    else if (type == 'course_code_search') {
      return this.httpClient
        .post(`${this.base_url}/obe-srv/v2/student-course-map/utils/search/assignment/by_course_code`, search_data, this.getHeaderOptions())
        .toPromise();
    }
    else {
      return this.httpClient
        .post(`${this.base_url}/obe-srv/v2/student-course-map/utils/search/assignment/by_programme_id`, search_data, this.getHeaderOptions())
        .toPromise();
    }

  }

  async deleteStudentCourseMap(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/student-course-map/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async studentCourseBulkUpload(formData: any): Promise<any> {
    /* return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-map/utils/upload/bulk`, file, this.getHeaderOptions())
      .toPromise(); */
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/obe-srv/v2/student-course-map/utils/upload/bulk`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //Course Advanced Settings
  async getCourseSettings(course_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/advanced-settings/utils/search`, course_data, this.getHeaderOptions())
      .toPromise();
  }

  async createAdvancedSettings(setting_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/course-scheme/advanced-settings`, setting_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteAdvancedSettings(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/course-scheme/advanced-settings/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Courseware API
  async getCourseware(course_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/courseware/utils/search`, course_data, this.getHeaderOptions())
      .toPromise();
  }

  async createUpdateCourseware(courseware_data): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/course-scheme/courseware`, courseware_data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteCourseware(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/course-scheme/courseware/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //New Mark Reports
  async getAttainmentReport(staff_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/reports/utils/attainment/report`, staff_data, this.getHeaderOptions())
      .toPromise();
  }

  async getConsolidatedReport(staff_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/course-scheme/reports/utils/consolidate/report`, staff_data, this.getHeaderOptions())
      .toPromise();
  }

  //Elective Polling APIs

  //Elective Polling Admin
  async searchStudentRequest(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/course-metrix/search`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async findRequestStudentList(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/requested/search`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async approveRequest(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/approve`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async unApproveRequest(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/unapprove`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async changeCourseRequest(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/change`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async approveRequestBulk(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/approve/bulk`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async unApproveRequestBulk(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/unapprove/bulk`, request_data, this.getHeaderOptions())
      .toPromise();
  }


  //Elective Polling Student View
  async checkAvailableRequest(): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/available/list`, {}, this.getHeaderOptions())
      .toPromise();
  }

  async findAvailableCourses(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/available/course/list`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async createRequestCourse(request_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request`, request_data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteCourseRequest(master_id: string, course_code: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/student-course-request/${master_id}/${course_code}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async finalizeElectivePolling(id: string, data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/student-course-request-master/utils/finalize/request/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //Course Request Setting Master
  async searchRequestMaster(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request-master/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async createRequestMaster(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request-master`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteRequestMaster(master_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/student-course-request-master/${master_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Course Quantity Setting Master
  async searchRequestQuantity(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request-qty/utils/search`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async createRequestQuantity(search_data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/obe-srv/v2/student-course-request-qty`, search_data, this.getHeaderOptions())
      .toPromise();
  }

  async deleteRequestQuantity(master_id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/student-course-request-qty/${master_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Hostel Fees Settings List API
  async getHostelFeesHeaderList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/hostel-srv/hostel-fee/header/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  async createHostelFeesHeader(data: any[]): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/hostel-srv/hostel-fee/header`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateHostelFeesHeader(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/hostel-srv/hostel-fee/header/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteHostelFeeHeader(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/hostel-srv/hostel-fee/header/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //OBE Submission Flow
  async submitForReview(data: any): Promise<any> { //used for submitting marks for review and switching between levels
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/update`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getSubmittedMarks(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/submission/list`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async reviewMarkEntry(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/student-course-map/utils/search/by/raw`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async coeFinalize(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/coe/internal/update`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getMarkGradeRegister(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/find/mark/cum/grade`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getComponentsSubmissionDetails(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/utils/search/submission-details`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }
  //Question Format APIs
  async createExamQuestionFormat(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/question-format`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateExamQuestionFormat(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/exam/question-format/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  async searchExamQuestionFormat(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/question-format/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteExamQuestionFormat(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/exam/question-format/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findQuestionFormat(id: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/exam/question-format/${id}`, this.getHeaderOptions())
      .toPromise();
  }


  //Exam Setup APIs
  async createExamSetup(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/setup`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async applyThirdValuations(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/final/apply/third/valuations`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateExamSetup(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/exam/setup/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  async searchExamSetup(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/setup/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteExamSetup(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/exam/setup/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findExamSetup(id: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/exam/setup/${id}`, this.getHeaderOptions())
      .toPromise();
  }

  async closeExamSetup(id: string, data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/setup/utils/close/exam/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //Exam Score Sheet APIs
  async findExamsByValuations(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/find/by/staff`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getCompareOfExamValuations(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/final/get/valuations`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findStudentScoreCard(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findOneStudentScoreCard(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/search/by/scorecard`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateScoreCard(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/update`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //Student Submission Course List
  async getStudentSubmissionCourseList(): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/student/timetable/utils/timetable`, {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Student Submission

  async uploadAnswerFile(data: any): Promise<any> {
    /* return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/submission/utils/upload/file`, data,
        this.getHeaderOptions()
      )
      .toPromise(); */

    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/exam-srv/exam/submission/utils/upload/file`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async submitStudentAnswer(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/submission`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateStudentSubmission(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/exam/submission/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchStudentSubmission(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/submission/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getStudentSubmissionById(id: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/exam/submission/${id}`, this.getHeaderOptions())
      .toPromise();
  }

  async deleteStudentSubmission(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/exam/submission/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findResultCopy(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/find/result/copy`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findStatementOfMark(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/scorecard/utils/find/statement/of/mark`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //OBE Member
  async createOBEMember(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/obe/incharge`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchOBEMembers(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/obe/incharge/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async deleteOBEMember(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/obe-srv/v2/obe/incharge/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //upload settlement file
  async uploadSettlementFile(data: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/accounts-srv/transactions/online/utils/update/settlement/details`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //holiday calendar setting API
  async createHoliday(holiday): Promise<any> {
    return this.httpClient.post(`${this.base_url}/holiday-settings-srv/holiday`, holiday, this.getHeaderOptions()).toPromise();
  }

  async updateHoliday(holiday): Promise<any> {
    return this.httpClient.put(`${this.base_url}/holiday-settings-srv/holiday/${holiday?.id}`, holiday, this.getHeaderOptions()).toPromise();
  }

  async deleteHoliday(holidaySlotId: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/holiday-settings-srv/holiday/${holidaySlotId}`, this.getHeaderOptions()).toPromise();
  }

  async getHolidays(accYear: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/holiday-settings-srv/holiday/utils/list`, { 'academic_year': accYear }, this.getHeaderOptions()).toPromise();
  }

  async bulkUploadHoliday(holidayList: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/holiday-settings-srv/holiday/utils/upload/bulk`, holidayList, this.getHeaderOptions()).toPromise();
  }

  //Transport Routes
  async listTransportRoutes(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/transport-srv/busroute`, this.getHeaderOptions()).toPromise();
  }

  async createTransportRoute(route_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/transport-srv/busroute`, route_data, this.getHeaderOptions()).toPromise();
  }

  async updateTransportRoute(_id, route_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/transport-srv/busroute/${_id}`, route_data, this.getHeaderOptions()).toPromise();
  }

  async deleteTransportRoute(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/transport-srv/busroute/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //Transport Fee-Settings
  async listTransportFees(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/transport-srv/feesettings`, this.getHeaderOptions()).toPromise();
  }

  async createTransportFees(fee_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/transport-srv/feesettings`, fee_data, this.getHeaderOptions()).toPromise();
  }

  async updateTransportFees(_id, fee_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/transport-srv/feesettings/${_id}`, fee_data, this.getHeaderOptions()).toPromise();
  }

  async deleteTransportFees(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/transport-srv/feesettings/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //Time settings API
  async listTimetableTemplates(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/template`, this.getHeaderOptions()).toPromise();
  }

  async createTimetableTemplate(template): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/template`, template, this.getHeaderOptions()).toPromise();
  }

  async updateTimetableTemplate(_id, template_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/template/${_id}`, template_data, this.getHeaderOptions()).toPromise();
  }

  async deleteTimetableTemplate(template_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/timetable-srv/template/${template_id}`, this.getHeaderOptions()).toPromise();
  }

  async finalizeTimetableTemplate(_id): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/template/finalize/${_id}`, {}, this.getHeaderOptions()).toPromise();
  }

  async listFinalizedTimetableTemplates(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/template/finalizedlist`, this.getHeaderOptions()).toPromise();
  }


  //Time Template - Programme Map
  async listTemplateProgrammeMap(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/programmemap`, this.getHeaderOptions()).toPromise();
  }
  async getProgrammeTemplateMapList(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/programmemap/list`, filter_data, this.getHeaderOptions()).toPromise();
  }
  async createTemplateProgrammeMap(map_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/programmemap`, map_data, this.getHeaderOptions()).toPromise();
  }

  async updateTemplateProgrammeMap(_id, map_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/programmemap/${_id}`, map_data, this.getHeaderOptions()).toPromise();
  }

  async deleteTemplateProgrammeMap(map_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/timetable-srv/programmemap/${map_id}`, this.getHeaderOptions()).toPromise();
  }
  async listTemplateProgrammeMapHistory(_id: string): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/programmemap/history/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async programmeTemplateBulkUpload(formData: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/timetable-srv/programmemap/list/upload`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }


  //Timetable
  async getTimeTableList(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/programme/timetable/list`, search_data, this.getHeaderOptions()).toPromise();
  }

  async updateProgrammeMapTimeTable(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/programme/timetable/staff/update`, search_data, this.getHeaderOptions()).toPromise();
  }

  async bulkProgrammeMapTimeTable(data): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/timetable-srv/programme/timetable/staff/update/upload`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async updateStaffMapTimeTable(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/programme/timetable/staff/map`, search_data, this.getHeaderOptions()).toPromise();
  }

  async bulkStaffMapTimeTable(data): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/timetable-srv/programme/timetable/staff/map/upload`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async getStaffTimeTableById(staff_id): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/staff/timetable/${staff_id}`, this.getHeaderOptions()).toPromise();
  }

  async getStaffTimetable(date_range_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/staff/timetable/attendance`, date_range_data, this.getHeaderOptions()).toPromise();
  }


  async deleteStaffMapping(map_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/programme/timetable/staff/delete`, map_data, this.getHeaderOptions()).toPromise();
  }


  //Day order change
  async getDayOrderChangeList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/dayorder-settings/list`, this.getHeaderOptions()).toPromise();
  }

  async createDayOrderChange(change_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/dayorder-settings`, change_data, this.getHeaderOptions()).toPromise();
  }

  async updateDayOrderChange(_id, update_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/dayorder-settings/${_id}`, update_data, this.getHeaderOptions()).toPromise();
  }

  async deleteDayOrderChange(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/timetable-srv/dayorder-settings/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //attendance
  async getStudentsDataForAttendance(hour_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/students/search/attendance`, hour_data, this.getHeaderOptions()).toPromise();
  }

  async CreateorUpdateStudentAttendance(attendance_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/students/fill/attendance`, attendance_data, this.getHeaderOptions()).toPromise();
  }

  async getAdjustmentStaffList(hour_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/staff/timetable/attendance/adjustment/list`, hour_data, this.getHeaderOptions()).toPromise();
  }

  //late entry
  async makeLateEntryRequest(request_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/staff/late-entry-settings`, request_data, this.getHeaderOptions()).toPromise();
  }

  async updateLateEntryRequest(_id, update_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/staff/late-entry-settings/${_id}`, update_data, this.getHeaderOptions()).toPromise();
  }

  async getLateEntryRequests(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/staff/late-entry-settings/list`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getRoomData(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/room/list`, this.getHeaderOptions()).toPromise();
  }

  async getAllStaffList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/programme/timetable/staff/list`, this.getHeaderOptions()).toPromise();
  }

  async getAllCourseList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/programme/timetable/course/list`, this.getHeaderOptions()).toPromise();
  }

  //Attendance Reports
  async getStudentSemWiseAttendanceReport(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/reports/admin/search/semester-wise`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getStudentMonthWiseAttendanceReport(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/reports/admin/search/month-wise`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getStudentDateWiseAttendanceReport(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/reports/admin/search/date-wise`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getStaffAttendanceReport(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/reports/admin/search/by/staff`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getNotMarkedStaffList(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/reports/admin/search/staff/notmarked`, search_data, this.getHeaderOptions()).toPromise();
  }

  async getMarkedStaffList(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/reports/admin/search/staff/marked`, search_data, this.getHeaderOptions()).toPromise();
  }

  async allowReEntryRequest(_id): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/students/retake/attendance/approval/${_id}`, {}, this.getHeaderOptions()).toPromise();
  }


  async bulkCourseMarkUpload(data): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/exam-srv/exam/scorecard/utils/update/upload`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async approveAllStudents(data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/student-course-request/utils/request/approve/all`, data, this.getHeaderOptions()).toPromise();
  }

  async getElectiveStudentData(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/student-course-request/utils/requested/search/group/by/programme`, search_data, this.getHeaderOptions()).toPromise();
  }

  async getStudentTimetable(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/student/timetable`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getStudentAttendanceReport(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/student/timetable/monthwise`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async addStudentAttendance(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/students/fill/attendance/byadmin`, student_data, this.getHeaderOptions()).toPromise();
  }

  async studentAttendanceBulkUpload(data): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/timetable-srv/students/fill/attendance/byadmin/date-wise/upload`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }




  //role management
  async createRole(role_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/roles-srv/roles`, role_data, this.getHeaderOptions()).toPromise();
  }

  async getRolesList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/roles-srv/roles/list`, this.getHeaderOptions()).toPromise();
  }

  async updateRole(_id, role_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/roles-srv/roles/${_id}`, role_data, this.getHeaderOptions()).toPromise();
  }


  async deleteRole(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/roles-srv/roles/${_id}`, this.getHeaderOptions()).toPromise();
  }


  //user role management
  async getUserRoles(user_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/roles-srv/roles/user/search`, user_data, this.getHeaderOptions()).toPromise();
  }

  async assignRoleToUser(user_role_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/roles-srv/roles/user/assign-roles`, user_role_data, this.getHeaderOptions()).toPromise();
  }


  async bulkExamSetupkUpload(data): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/exam-srv/exam/setup/upload`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async assignVirtualSection(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/student-course-request/assign/virtual-section`, student_data, this.getHeaderOptions()).toPromise();
  }

  //OBE overall mark submission
  async submitAllComponents(course_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/mark-submission/update/overall`, course_data, this.getHeaderOptions()).toPromise();
  }

  //Timetable Configuration Settings
  async createConfiguration(role_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/academic-settings`, role_data, this.getHeaderOptions()).toPromise();
  }

  async updateConfiguration(_id, role_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/academic-settings/${_id}`, role_data, this.getHeaderOptions()).toPromise();
  }

  async deleteConfiguration(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/timetable-srv/academic-settings/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async getConfigurationList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/academic-settings/list`, this.getHeaderOptions()).toPromise();
  }

  async getConfigurationListByDate(date: string): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/academic-settings/utils/search/by/date/${date}`, this.getHeaderOptions()).toPromise();
  }

  //Admission Account Settings
  async addAdmissionAccountSettings(account_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/application/account/settings`, account_data, this.getHeaderOptions()).toPromise();
  }

  async updateAdmissionAccountSettings(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/admission-srv/application/account/settings/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async getAdmissionAccountSettings(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/application/account/settings/list`, filter_data, this.getHeaderOptions()).toPromise();
  }

  //Course Exit Survey
  async getStaffCES(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/course-exit/utils/search`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async createCES(survey_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/course-exit`, survey_data, this.getHeaderOptions()).toPromise();
  }

  async updateCES(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/survey/course-exit/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async getCESForStudent(): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/course-exit/student/survey-request`, {}, this.getHeaderOptions()).toPromise();
  }

  async updateStudentResponseCES(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/survey/course-exit/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async allowResubmissionCES(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/course-exit/student/survey-retake`, student_data, this.getHeaderOptions()).toPromise();
  }

  //Scroller Messages
  async createScrollerMessages(message_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/admission/notifications`, message_data, this.getHeaderOptions()).toPromise();
  }

  async getScrollerMessages(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/admission/notifications/list`, this.getHeaderOptions()).toPromise();
  }

  async uploadScrollerMessageFile(formData): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/admission-srv/admission/notifications/upload`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //Student-Guardian Assignment
  async createGuardianAssignment(assignment_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/students-srv/guardian/assignment`, assignment_data, this.getHeaderOptions()).toPromise();
  }

  async updateGuardianAssignment(_id, assignment_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/students-srv/guardian/assignment/${_id}`, assignment_data, this.getHeaderOptions()).toPromise();
  }

  async getGuardianAssignment(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/students-srv/guardian/assignment/utils/search`, filter_data, this.getHeaderOptions()).toPromise();
  }


  async sendSMS(sms_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/admission/sendsms`, sms_data, this.getHeaderOptions()).toPromise();
  }


  async getAdmissionReport(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/reports/appicants/programme/payments`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async getExcessApplicationPayments(admission_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/reports/appicants/application/excess-payment`, admission_data, this.getHeaderOptions()).toPromise();
  }

  async getMultipleSelectedApplicants(admission_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/reports/appicants/multiple/selected`, admission_data, this.getHeaderOptions()).toPromise();
  }

  //Graduate Exit Survey
  async getProgrammeGES(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/graduate-exit/utils/search`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async createGES(survey_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/graduate-exit`, survey_data, this.getHeaderOptions()).toPromise();
  }

  async updateGES(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/survey/graduate-exit/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async getGESForStudent(): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/graduate-exit/student/survey-request`, {}, this.getHeaderOptions()).toPromise();
  }

  async updateStudentResponseGES(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/survey/graduate-exit/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async allowResubmissionGES(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/graduate-exit/student/survey-retake`, student_data, this.getHeaderOptions()).toPromise();
  }

  //Extra Exit Survey
  async getExtraCES(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/co-ex-curricular/utils/search`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async createExtraCES(survey_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/co-ex-curricular`, survey_data, this.getHeaderOptions()).toPromise();
  }

  async updateExtraCES(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/survey/co-ex-curricular/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async getExtraCESForStudent(): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/co-ex-curricular/student/survey-request`, {}, this.getHeaderOptions()).toPromise();
  }

  async updateStudentResponseExtraCES(_id, account_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/survey/co-ex-curricular/${_id}`, account_data, this.getHeaderOptions()).toPromise();
  }

  async allowResubmissionExtraCES(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/survey/co-ex-curricular/student/survey-retake`, student_data, this.getHeaderOptions()).toPromise();
  }

  //Skillset Master
  async getSkillSetList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/obe-srv/non-academic/skillset/list`, this.getHeaderOptions()).toPromise();
  }

  async getSkillSetById(_id: string): Promise<any> {
    return this.httpClient.get(`${this.base_url}/obe-srv/non-academic/skillset/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async getSkillSetNameList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/obe-srv/non-academic/skillset/namelist`, this.getHeaderOptions()).toPromise();
  }

  async createSkillset(skillset_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/non-academic/skillset`, skillset_data, this.getHeaderOptions()).toPromise();
  }

  async updateSkillset(_id, skillset_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/non-academic/skillset/${_id}`, skillset_data, this.getHeaderOptions()).toPromise();
  }

  async deleteSkillset(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/obe-srv/non-academic/skillset/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async deleteSkill(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/obe-srv/non-academic/skillset/skills/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async skillsetBulkUpload(formData: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/obe-srv/non-academic/skillset/bulk/upload`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //Skillset Evidence Upload
  async getSkillsetEvidenceList(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/non-academic/skillset/upload/utils/search`, search_data, this.getHeaderOptions()).toPromise();
  }

  async uploadSkillsetEvidence(skillset_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/non-academic/skillset/upload`, skillset_data, this.getHeaderOptions()).toPromise();
  }

  async updateSkillsetEvidence(_id, skillset_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/non-academic/skillset/upload/${_id}`, skillset_data, this.getHeaderOptions()).toPromise();
  }

  async uploadEvidenceFile(formData): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/obe-srv/non-academic/skillset/upload/certificates`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //Certificate Tracker Master
  async getCertificateMasterList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/certificate-master`, this.getHeaderOptions()).toPromise();
  }

  async createCertificateMaster(certificate_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/certificate-master`, certificate_data, this.getHeaderOptions()).toPromise();
  }

  async updateCertificateMaster(_id, certificate_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/admission-srv/certificate-master/${_id}`, certificate_data, this.getHeaderOptions()).toPromise();
  }

  async deleteCertificateMaster(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/admission-srv/certificate-master/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //Update Student Certificate Tracker
  async updateStudentCertficateTracker(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/reports/certificate/status/update`, student_data, this.getHeaderOptions()).toPromise();
  }

  //Non-Academic Evidence Review
  async studentEvidenceSearch(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/non-academic/skillset/upload/utils/search`, filter_data, this.getHeaderOptions()).toPromise();
  }

  async studentEvidenceUpdate(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/non-academic/skillset/upload/stage/change`, student_data, this.getHeaderOptions()).toPromise();
  }

  //User Creation

  async getStaffList(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/staffs-srv/staff/find/user`, search_data, this.getHeaderOptions()).toPromise();
  }

  async createStaff(staff_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/staffs-srv/staff/create/user`, staff_data, this.getHeaderOptions()).toPromise();
  }

  async updateStaff(_id, staff_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/staffs-srv/staff/update/user/${_id}`, staff_data, this.getHeaderOptions()).toPromise();
  }


  //External Valuations
  async getExternalValuationList(staff_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/exam-srv/exam/scorecard/utils/find/by/external/staff`, staff_data, this.getHeaderOptions()).toPromise();
  }

  //Group Polling
  async makeBulkPollingRequest(group_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/student-course-request/bulk`, group_data, this.getHeaderOptions()).toPromise();
  }

  //Seed Courses for Student
  async seedCoursesForStudent(enrolled_year, roll_no): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/courses/utils/update/default/settings/all/${enrolled_year}/${roll_no}`, {}, this.getHeaderOptions()).toPromise();
  }

  // Repeat sem student
  async addUpdateRepeatSemesterStudent(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/students-srv/student/repeat-student`, student_data, this.getHeaderOptions()).toPromise();
  }

  async getRepeatSemesterStudent(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/students-srv/student/get/repeat-students`, student_data, this.getHeaderOptions()).toPromise();
  }

  //Repeat Semester
  async addRepeatSemesterStudent(student_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/students-srv/student/repeat`, student_data, this.getHeaderOptions()).toPromise();
  }

  async updateRepeatSemesterStudent(_id, student_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/students-srv/student/repeat/${_id}`, student_data, this.getHeaderOptions()).toPromise();
  }

  async getRepeatSemesterStudentList(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/students-srv/student/repeat/search`, search_data, this.getHeaderOptions()).toPromise();
  }

  async deleteRepeatSemesterStudent(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/students-srv/student/repeat/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //Fine Rules
  async getFineList(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/fine-srv/fine`, this.getHeaderOptions()).toPromise();
  }

  async addFineRule(fine_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/fine-srv/fine`, fine_data, this.getHeaderOptions()).toPromise();
  }

  async updateFineRule(_id, fine_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/fine-srv/fine/${_id}`, fine_data, this.getHeaderOptions()).toPromise();
  }

  async deleteFineRule(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/fine-srv/fine/${_id}`, this.getHeaderOptions()).toPromise();
  }


  //Constructive Alignment
  async getConstructiveAlignment(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/obe-srv/constructive-alignment`, this.getHeaderOptions()).toPromise();
  }

  async searchConstructiveAlignment(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/constructive-alignment/utils/search`, search_data, this.getHeaderOptions()).toPromise();
  }

  async createConstructiveAlignment(fine_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/constructive-alignment`, fine_data, this.getHeaderOptions()).toPromise();
  }

  async updateConstructiveAlignment(_id, fine_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/obe-srv/constructive-alignment/${_id}`, fine_data, this.getHeaderOptions()).toPromise();
  }

  async deleteConstructiveAlignment(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/obe-srv/constructive-alignment/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //Internal Question setting

  async searchInternalExamSetup(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/setup/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findInternalExamSetup(id: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/internal-exam/setup/${id}`, this.getHeaderOptions())
      .toPromise();
  }

  async findInternalQuestionFormat(id: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/internal-exam/question-format/${id}`, this.getHeaderOptions())
      .toPromise();
  }

  async createInternalExamSetup(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/setup`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateInternalExamSetup(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/internal-exam/setup/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //Internal Exam Score Sheet APIs
  async findInternalExamsByValuations(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/scorecard/utils/find/by/staff`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findInternalStudentScoreCard(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/scorecard/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async findInternalOneStudentScoreCard(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/scorecard/utils/search/by/scorecard`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateInternalScoreCard(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/scorecard/utils/update`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //Student Submission Course List
  async getInternalStudentSubmissionCourseList(): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/student/timetable/utils/timetable`, {},
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Student Submission

  async uploadInternalAnswerFile(data: any): Promise<any> {
    /* return this.httpClient
      .post(
        `${this.base_url}/exam-srv/exam/submission/utils/upload/file`, data,
        this.getHeaderOptions()
      )
      .toPromise(); */

    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/exam-srv/internal-exam/submission/utils/upload/file`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async submitInternalStudentAnswer(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/submission`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateInternalStudentSubmission(id: string, data: any): Promise<any> {
    return this.httpClient
      .put(
        `${this.base_url}/exam-srv/internal-exam/submission/${id}`,
        data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async searchInternalStudentSubmission(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/exam-srv/internal-exam/submission/utils/search`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getInternalStudentSubmissionById(id: string): Promise<any> {
    return this.httpClient
      .get(`${this.base_url}/exam-srv/internal-exam/submission/${id}`, this.getHeaderOptions())
      .toPromise();
  }

  async deleteInternalStudentSubmission(id: string): Promise<any> {
    return this.httpClient
      .delete(
        `${this.base_url}/exam-srv/internal-exam/submission/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //OD Request & Approval
  async getODRequestById(_id: string): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/od-request/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async searchODRequests(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/od-request/utils/search`, search_data, this.getHeaderOptions()).toPromise();
  }

  async createODRequest(od_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/od-request`, od_data, this.getHeaderOptions()).toPromise();
  }

  async updateODRequest(_id, od_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/od-request/${_id}`, od_data, this.getHeaderOptions()).toPromise();
  }

  async deleteODRequest(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/timetable-srv/od-request/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async changeODRequest(_id, od_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/od-request/change/status/${_id}`, od_data, this.getHeaderOptions()).toPromise();
  }

  //seed semester wise
  async studentCourseSemesterSeed(od_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/obe-srv/v2/courses/utils/update/default/coursemap`, od_data, this.getHeaderOptions()).toPromise();
  }

  //Leave Request & Approval
  async getLeaveRequestById(_id: string): Promise<any> {
    return this.httpClient.get(`${this.base_url}/timetable-srv/leave-request/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async searchLeaveRequests(search_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/leave-request/utils/search`, search_data, this.getHeaderOptions()).toPromise();
  }

  async createLeaveRequest(od_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/timetable-srv/leave-request`, od_data, this.getHeaderOptions()).toPromise();
  }

  async updateLeaveRequest(_id, od_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/leave-request/${_id}`, od_data, this.getHeaderOptions()).toPromise();
  }

  async deleteLeaveRequest(_id: string): Promise<any> {
    return this.httpClient.delete(`${this.base_url}/timetable-srv/leave-request/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async changeLeaveRequest(_id, od_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/timetable-srv/leave-request/change/status/${_id}`, od_data, this.getHeaderOptions()).toPromise();
  }

  async uploadLeaveAttachment(formData: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/timetable-srv/leave-request/upload/file`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async uploadBulkOfflineTransaction(formData: any): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/accounts-srv/transactions/online/utils/adjust/offline/bulk`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }


  async uploadBulkHostelFee(formData: any, fee_header_id: string): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/hostel-srv/hostel-fee/header/utils/seed/students/${fee_header_id}`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async getStudentExamFee(filter_data): Promise<any> {
    return this.httpClient.post(`${this.base_url}/exam-srv/exam-fee/header/students-map/search`, filter_data, this.getHeaderOptions()).toPromise();
  }

  //upload student exam fee - bulk
  async uploadExamFeesBulk(data: any, exam_header_id: string): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/exam-srv/exam-fee/header/utils/upload/fees/${exam_header_id}`,
          data,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //Get hostel admission header list
  async getHostelAdmissionFeesHeaderList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/hostel-srv/hostel-admission-fee/header/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  //Get hostel admission header list
  async getTransportAdmissionFeesHeaderList(data: any): Promise<any> {
    return this.httpClient
      .post(`${this.base_url}/transport-srv/transport-admission-fee/header/utils/search`, data, this.getHeaderOptions())
      .toPromise();
  }

  //Template
  createTemplate(data: any) {
    return this.httpClient.post(this.base_url + '/report-srv/report/template', data,
      this.getHeaderOptions());
  }

  async getTemplate(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/report-srv/report/template`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getTemplates(_id: string): Promise<any> {

    return this.httpClient
      .get(
        `${this.base_url}/report-srv/report/template/${_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }



  async updateTemplate(_id, template_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/report-srv/report/template/${_id}`, template_data, this.getHeaderOptions()).toPromise();
  }


  async deleteTemplate(_id: string): Promise<any> {

    return this.httpClient.delete(`${this.base_url}/report-srv/report/template/${_id}`, this.getHeaderOptions()).toPromise();
  }

  //Template filter
  async getTemplateFilter(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/report-srv/report/template/template_filter`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Template Settings
  createTemplateSettings(data: any) {
    return this.httpClient.post(this.base_url + '/report-srv/templateSettings', data,
      this.getHeaderOptions());
  }

  async getTemplateSetting(templateId: string): Promise<any> {

    return this.httpClient
      .get(
        `${this.base_url}/report-srv/templateSettings/${templateId}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateTemplateSetting(id, setting_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/report-srv/templateSettings/${id}`, setting_data, this.getHeaderOptions()).toPromise();
  }

  //Program Template
  createProgramTemplate(data: any) {
    return this.httpClient.post(this.base_url + '/report-srv/report/programTemplate', data,
      this.getHeaderOptions());
  }

  async getProgramTemplate(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/report-srv/report/programTemplate`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgramTemplates(_id: string): Promise<any> {

    return this.httpClient
      .get(
        `${this.base_url}/report-srv/report/programTemplate/${_id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateProgramTemplate(_id, template_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/report-srv/report/programTemplate/${_id}`, template_data, this.getHeaderOptions()).toPromise();
  }

  async deleteProgramTemplate(_id: string): Promise<any> {

    return this.httpClient.delete(`${this.base_url}/report-srv/report/programTemplate/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async deleteProgramTemplateMapping(_id: string): Promise<any> {

    return this.httpClient.delete(`${this.base_url}/report-srv/report/programTemplateWithMapping/${_id}`, this.getHeaderOptions()).toPromise();
  }

  async getProgramMapping(template_id: string): Promise<any> {
    return this.httpClient.get(`${this.base_url}/report-srv/templateSettings/${template_id}`, this.getHeaderOptions()).toPromise();
  }

  //Program Template Mapping
  createProgramTemplateMapping(data: any): Promise<any> {
    return this.httpClient.post(this.base_url + '/report-srv/report/programeTemplateMapping', data,
      this.getHeaderOptions()).toPromise();
  }

  async getProgramTemplateMapping(prgTempId: string, id: string): Promise<any> {

    return this.httpClient
      .get(
        `${this.base_url}/report-srv/report/programeTemplateMapping/${prgTempId}/${id}`,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateProgramTemplateMapping(_id, template_data): Promise<any> {
    return this.httpClient.put(`${this.base_url}/report-srv/report/programeTemplateMapping/${_id}`, template_data, this.getHeaderOptions()).toPromise();
  }

  async uploadFiles(formData): Promise<any> {
    const token = CookieStore.getBearerToken();
    const headers = new HttpHeaders().set('Authorization', token);
    if (token) {
      return this.httpClient
        .post(
          `${this.base_url}/report-srv/templateSettings/upload/templateSettings`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  async uploadReportFile(formData): Promise<any> {
    const token = CookieStore.getBearerToken();
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.httpClient
        .post(
          `${this.base_url}/report-srv/report/programeTemplateMapping/upload/templateMapping`,
          formData,
          { headers }
        )
        .toPromise();
    } else {
      return Promise.resolve({ success: false });
    }
  }

  //CAS Report
  async searchCASReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/cas_report_info`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async saveUpdateCASReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/cas_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async saveUpdateFinalAttainment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/overall_attainment/cas_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


  //PAM
  async searchPAM(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/search/pam_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async generatePAM(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/genderate-save/pam_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async addRemovePAMCourses(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/pam_report_update`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async bulkPAMUpload(data: any, regulation_year: number, programme_id: string): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/bulk-upload/pam_report/${regulation_year}/${programme_id}`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }





  //Programme Outcome Report
  async getPOReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/get/po_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async generateCOAttainmentReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/co_attainment_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async generatePercentageOfCOsReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/percentage_attainment_co`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getPAMCourseValues(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/generate/po_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async savePOReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/save/po_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async saveUpdateRemarksCOSAttainment(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/update_remarks/percentage_co`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updatePOReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/update/po_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //Holistic attainment overall
  async getHolisticReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/non-academic/skillset/upload/overall-holistic/attainment`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateExhibitingValues(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/non-academic/skillset/upload/overall-holistic/attainment/exhibiting-values`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getSemesterNA(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/non-academic/skillset/upload/semester-wise/non-academic`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async getProgrammeNA(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/non-academic/skillset/upload/programme-wise/non-academic`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  //CAGP Report
  async getCAGPReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/cagp_report_info`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }

  async updateCAGPReport(data: any): Promise<any> {
    return this.httpClient
      .post(
        `${this.base_url}/obe-srv/v2/mark-submission/cagp_report`, data,
        this.getHeaderOptions()
      )
      .toPromise();
  }


}
