<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Upload Exam Answer Sheets</p>
                    </div>

                    <div class="card-body">
                        <div class="row mg-b-10">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-info btn-sm" (click)="renderData()">Refresh List</button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 10%;">Roll No.</th>
                                        <th style="width: 25%;">File Action</th>
                                        <th style="width: 15%;">Status</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="submission_list.length>0">

                                        <ng-container
                                            *ngFor="let submission of submission_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{submission.roll_no}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <input class="form-control" required="" type="file"
                                                            accept="application/pdf" [(ngModel)]="files[i]"
                                                            (change)="openModal($event,submission,i)">
                                                    </div>
                                                </td>
                                                <td><b>{{submission.stage | titlecase}}</b><br>
                                                    <span (click)="viewPreview(submission)" style="cursor: pointer;"
                                                        *ngIf="submission.file_path!=''"><u>Preview</u></span>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="submission.stage =='SUBMITTED'">
                                                        <button class="btn btn-success btn-sm mg-r-10"
                                                            (click)="reviewFile(submission,'APPROVED')">
                                                            Approve
                                                        </button>
                                                        <button class="btn btn-danger btn-sm"
                                                            (click)="reviewFile(submission,'REJECTED')">
                                                            Reject
                                                        </button>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="submission.stage == 'APPROVED' || submission.stage =='REJECTED'">
                                                        <button class="btn btn-info btn-sm"
                                                            (click)="revertStage(submission)">
                                                            Change
                                                        </button>
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!submission_list_loading && submission_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="submission_list_loading && submission_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_file_data">
                    <div class="modal-header">
                        <h6 class="modal-title">File Preview</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <pdf-viewer [src]="this.preview_url" [render-text]="false" [original-size]="true"
                                        [fit-to-page]="true" [show-borders]="true" style="height: 600px;">
                                    </pdf-viewer>
                                </div>
                            </div>
                            <div class="row mg-t-15">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-primary mg-r-10" (click)="uploadFile()"
                                        *ngIf="modal_mode=='UPLOAD' && !file_uploading">
                                        Upload File
                                    </button>
                                    <button class="btn btn-primary mg-r-10" disabled
                                        *ngIf="modal_mode=='UPLOAD' && file_uploading ">
                                        Uploading...
                                    </button>
                                    <button class="btn btn-secondary" (click)="closeModal()">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!-- Reason Large Modal -->
        <div class="modal" id="reason-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_file_data">
                    <div class="modal-header">
                        <h6 class="modal-title">Reject Reason</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12">
                                    <textarea [(ngModel)]="reason" class="form-control"
                                        placeholder="Enter Reason for rejecting this answer sheet"></textarea>
                                </div>
                            </div>
                            <div class="row mg-t-15">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-danger mg-r-10" (click)="rejectFile()"
                                        *ngIf="!reject_loading">
                                        Reject
                                    </button>
                                    <button class="btn btn-danger mg-r-10" disabled *ngIf="reject_loading">
                                        Updating...
                                    </button>
                                    <button class="btn btn-secondary" (click)="closeReasonModal()">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>