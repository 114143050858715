<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Roles & Permissions</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-body">
                        <div class="panel panel-primary tabs-style-1">
                            <div class=" tab-menu-heading">
                                <div class="tabs-menu1">
                                    <!-- Tabs -->
                                    <ul class="nav panel-tabs main-nav-line">
                                        <li class="nav-item"><a href="#tab1" class="nav-link active"
                                                data-toggle="tab">Roles</a></li>
                                        <!-- <li class="nav-item"><a href="#tab2" class="nav-link"
                                                data-toggle="tab">Permissions</a></li> -->
                                    </ul>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body main-content-body-right border-top-0 border">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tab1">
                                        <div class="card-body">
                                            <div class="breadcrumb-header justify-content-between">
                                                <div class="left-content">
                                                    <div>
                                                        <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Roles</h2>
                                                        <p class="mg-b-0">Create, Update and Delete Roles</p>
                                                    </div>
                                                </div>
                                                <div class="d-flex my-xl-auto right-content">
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-info"
                                                            (click)="onAddClick()">Add New Role</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Display existing roles-->
                                            <div class="table-responsive">
                                                <table class="table table-striped mg-b-0 text-md-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Role</th>
                                                            <th>Description</th>
                                                            <th style="width: 15%;">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="roles.length>0">
                                                            <ng-container
                                                                *ngFor="let role of roles;let i=index;trackBy:fromDataResolver.identify;">
                                                                <tr>
                                                                    <td scope="row">
                                                                        {{i+1}}
                                                                    </td>
                                                                    <td>{{role.role_key}}</td>
                                                                    <td>{{role.role_description}}</td>
                                                                    <td>
                                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                                            <button type="button"
                                                                                class="btn btn-sm btn-info mr-2"
                                                                                (click)="onRoleEditClick(role)">
                                                                                <fa-icon [icon]="fonts.faPencilAlt">
                                                                                </fa-icon>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="!role_list_loading && roles.length==0">
                                                            <tr>
                                                                <th colspan="4" class="text-center">No Records found
                                                                </th>
                                                            </tr>
                                                        </ng-container>
                                                        <ng-container *ngIf="role_list_loading && roles.length==0">
                                                            <tr>
                                                                <th colspan="4" class="text-center">Loading Please wait
                                                                </th>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="tab-pane" id="tab2">
                                        <div class="card-body">
                                            <div class="breadcrumb-header justify-content-between">
                                                <div class="left-content">
                                                    <div>
                                                        <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">
                                                            Permissions</h2>
                                                        <p class="mg-b-0">Assign Permissions to Roles</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <form [formGroup]="permission_map_form">
                                                <div class="table-responsive">
                                                    <table class="table table-striped mg-b-0 text-md-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Permission</th>
                                                                <th
                                                                    *ngFor="let role of roles; let i = index; trackBy:fromDataResolver.identify;">
                                                                    {{role.role_name}}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody formArrayName="permission_map">
                                                            <tr *ngFor="let permission of aliasesArrayControl; let i = index; trackBy:fromDataResolver.identify;"
                                                                [formGroupName]="i">
                                                                <td scope="row">
                                                                    {{i+1}}
                                                                </td>
                                                                <td>{{permission.value.permission_name}}</td>
                                                                <ng-container formArrayName="mapped_roles">
                                                                    <td *ngFor="let role of permission.value.mapped_roles; let j = index; trackBy:fromDataResolver.identify;"
                                                                        [formGroupName]="j">
                                                                        <input type="checkbox"
                                                                            formControlName="is_selected"
                                                                            class="custom-control custom-checkbox">
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="add-btn-row">
                                                    <button class="btn btn-primary mg-r-20"
                                                        (click)="onPermissionsSave()">Save Permissions</button>
                                                    <button class="btn btn-danger mg-r-20"
                                                        (click)="onPermissionsReset()">Reset Permissions</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="roleForm">
                    <div class="modal-header">
                        <h6 class="modal-title" *ngIf="!this.roleForm.value._id">Add Role</h6>
                        <h6 class="modal-title" *ngIf="this.roleForm.value._id">Edit Role</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="roleForm" class="form-horizontal">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Role Key</label>
                                    <input class="form-control" required="" type="text" placeholder="Enter Role Key"
                                        formControlName="role_key">
                                    <div *ngIf="isInvalid('role_key')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Role Key is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                        Description</label>
                                    <input class="form-control" placeholder="Enter Role Description" type="text"
                                        formControlName="role_description">
                                </div>

                                <div class="add-btn-row">
                                    <ng-container *ngIf="!this.roleForm.value._id">
                                        <ng-container *ngIf="!role_save_loading">
                                            <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                                [disabled]="canDisable()">Save</button>
                                            <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                                [disabled]="canDisable()">Save & Close</button>
                                        </ng-container>
                                        <ng-container *ngIf="role_save_loading">
                                            <button class="btn btn-primary mg-r-20" disabled>Saving...</button>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="this.roleForm.value._id">
                                        <ng-container *ngIf="!role_save_loading">
                                            <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                                [disabled]="canDisable()">Update</button>
                                            <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                                [disabled]="canDisable()">Update & Close</button>
                                        </ng-container>
                                        <ng-container *ngIf="role_save_loading">
                                            <button class="btn btn-primary mg-r-20" disabled>Updating...</button>
                                        </ng-container>
                                    </ng-container>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete(this.roleForm.value._id)"
                                        *ngIf="this.roleForm.value._id">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>