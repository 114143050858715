<!-- Loader -->
<div id="global-loader">
  <img src="assets/img/loader.svg" class="loader-img" alt="Loader">
</div>
<!-- /Loader -->
<!-- Page -->
<div class="page">



  <router-outlet></router-outlet>





</div>
<!-- End Page -->