import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface NavigationItem {
  label?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  notication$: BehaviorSubject<Array<NavigationItem>>;
  navItems: NavigationItem[];
  _homeURL: NavigationItem = {} as NavigationItem;
  constructor(private router: Router) {
    this.notication$ = new BehaviorSubject<Array<NavigationItem>>([]);
    this.navItems = [];
  }

  set homeURL(nav: NavigationItem) {
    this._homeURL.label = nav.label;
    this._homeURL.url = nav.url;
  }

  get homeURL(): NavigationItem {
    return this._homeURL;
  }

  getNavigation(): BehaviorSubject<NavigationItem[]> {
    return this.notication$;
  }

  setNavigation(data: NavigationItem[]): void {
    this.navItems = data;
    this.notication$.next(this.navItems);
  }

  updateNavigation(label: string, url: string): void {
    const navItem = { label, url };
    if (this.getNavigation().value) {
      const found = this.getNavigation().value.filter(item => item.label === label);
      if (!found.length) {
        this.getNavigation().value.push(navItem);
      }
    } else {
      this.setNavigation([navItem]);
    }
  }

  navigateBack(): void {
    if (this.navItems.length) {
      this.navItems.pop();
      const numOfElements = this.navItems.length;
      const lastElement: NavigationItem = this.navItems[numOfElements - 1];
      if ((numOfElements - 1) >= 0) {
        this.router.navigate([lastElement.url]);
      }
      this.notication$.next(this.navItems);
    } else {
      if (this.homeURL) {
        this.router.navigate([this.homeURL.url]);
      }
    }
  }

  resetNavigation(): void {
    this.navItems = [];
    this.notication$.next(this.navItems);
  }
}

