<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!--Programme-->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolled year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <div class="row" *ngIf="display_card && canDisplayReport()">
                          <div class="col-md-12" style="text-align: right;">
                              <button class="btn btn-sm btn-secondary mg-r-10" (click)="downloadExcel()">Download
                                  Excel</button>
                          </div>
                        </div>

                        <!--Report Table-->
                        <ng-container *ngIf="display_card && canDisplayReport()">
                          <div class="container">
                              <div class="table-responsive" style="margin-top: 20px;">
                                  <table class="table table-bordered" id="percentage-attainment-co-table">
                                      <tbody>
                                          <tr>
                                              <th colspan="4" style="text-align: center;">AYYA NADAR JANAKI AMMAL
                                                  COLLEGE (Autonomous), SIVAKASI</th>
                                          </tr>
                                          <tr>
                                              <th colspan="4" style="text-align: center;">Programme:
                                                  {{report_data.programme_name}}</th>
                                          </tr>
                                          <tr>
                                              <th colspan="4" style="text-align: center;">Batch:
                                                  {{report_data.enrolled_year}}</th>
                                          </tr>
                                          <tr>
                                              <th colspan="4" style="text-align: center;">Percentage of attainment of CO's</th>
                                          </tr>

                                          <tr>
                                            <th>
                                                Component & Subject code
                                            </th>
                                            <th>
                                                Title of the course
                                            </th>
                                            <th>
                                              Final CO Attainment of the course
                                            </th>
                                            <th>
                                              Level of Attainment
                                            </th>
                                          </tr>

                                          <!--Report Rows-->
                                          <ng-container *ngFor="let partData of report_data?.part_wise_data; let i = index;">
                                            <ng-container *ngFor="let semData of partData?.semester_wise_data; let j = index;">
                                              <tr>
                                                <th>{{partData.part | uppercase}}</th>
                                                    <th>{{semData.semester}} Semester</th>
                                                    <ng-container *ngFor="let row of getEmptyRows(2)">
                                                        <td></td>
                                                    </ng-container>
                                              </tr>
                                              <ng-container *ngFor="let course of semData.course_wise_data; let k = index">
                                                <tr>
                                                  <td>{{course.course_code}}</td>
                                                  <td>{{course.course_name}}</td>
                                                  <td>{{ course.final_co_attainment }}</td>
                                                  <td>{{ course.final_grade_attainment }}</td>
                                                </tr>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>

                                          <ng-container>
                                            <tr>
                                                <td colspan="4">
                                                    <textarea class="form-control" [(ngModel)]="report_data.remarks"
                                                        rows="3" cols="10" placeholder="Enter Remarks"></textarea>
                                                </td>
                                            </tr>
                                          </ng-container>

                                      </tbody>
                                  </table>
                              </div>
                          </div>



                      </ng-container>
                      <!--Report Table End -->

                        <div class="row" *ngIf="display_card">
                          <div class="col-md-12 text-center">
                              <button class="btn btn-success btn-md" *ngIf="!report_loading"
                                  (click)="saveUpdateRemarks()" [disabled]="!canDisplayReport() || report_data.remarks == ''">Save & Generate
                                  Report</button>
                              <button class="btn btn-md btn-success" type="button" disabled *ngIf="report_loading">
                                  <span class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span>
                                  Saving...
                              </button>
                          </div>
                        </div>

                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>

<!-- /main-content -->
<app-footer></app-footer>
