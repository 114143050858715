import { Room } from './../room-model';
import { Floor } from './../floor-model';
import { StudentRoomMap } from '../asset-management/studentRoomMap';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { Hostel } from './../hostelModel';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-room-attendance',
  templateUrl: './room-attendance.component.html',
  styleUrls: ['./room-attendance.component.scss']
})
export class RoomAttendanceComponent implements OnInit {
  hostel: Hostel = {} as Hostel;
  room_id:string='';
  studentRoomMaps: StudentRoomMap[] = [] as StudentRoomMap[];
  fonts = fonts;
  listLoading: boolean = false;
  rooms : {
    room_id:string,
    room_name:string
  } []= [{
    room_name:"111",
    room_id: "7490b999-5e8f-40fa-a8cf-4a0ea06f1fb0"
  },
  {
    room_name: "112",
    room_id: "b46f7603-4145-40da-8075-f069f87e3ec5"
  },
  {
    room_name: "113",
    room_id: "69b23f72-7057-4024-ab2d-a019fccd9d26"
  }
]
  absent_reasons : string [ ] = ["On Duty", "Illness"];
attendance_data: FormGroup;

  fromDataResolver = FromDataResolver;
  constructor(private formBuilder: FormBuilder,
    private restService:AdminservicesService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
      this.attendance_data = this.formBuilder.group({
        room_id: ['', Validators.required],
        date_time: ['', Validators.required],
        attendance: this.formBuilder.array([])
      });
   }

  getHostelName(hostel: Hostel): string {
    this.hostel = hostel;
    return this.hostel.name;
  }


  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.setNavigation([{label: 'Room Attendance', url: 'admin/hostel/room-attendance'}]);
    this.notificationService.setNotifcation({});    
  }


  async getStudentRoomMaps(): Promise<void> {
    try {
      const res = await this.restService.studentRoomMapService.getStudentRoomMap('', this.attendance_data.get('room_id')?.value);
      if (res && res.length) {
        this.studentRoomMaps = res;
        console.log(res);
        var attendance = this.attendance_data.get('attendance') as FormArray;
        res.forEach(student=>{
          attendance.push(this.formBuilder.group({
            given_name:[student.given_name],
            is_absent:[false],
            reason: [""]
          }))
        })
      } else {
        this.studentRoomMaps = [];
        this.notificationService.setNotifcation({ message: 'No students found', type: 'Warning'});
      }
    } catch(error:any) {
      this.notificationService.setNotifcation({ message: error.message, type: 'Failed'});
    }
  }

  absentChanged(ev:any, i:number)
  {
    if(!ev.target.checked)
    {
      var attendance = this.attendance_data.get('attendance') as FormArray;
      attendance.at(i).get('reason')?.setValue("");
    }
  }

  get aliasesArrayControl() {
    return (this.attendance_data.get('attendance') as FormArray).controls;
  }


  onSearchClick(): void {
    if (this.attendance_data.get('room_id')?.value) {
      this.getStudentRoomMaps();
    }

  }

  canDisableAction() {
   return (this.attendance_data.get('room_id')?.value=='')?true:false;
  }

  ngOnDestroy() {
  }

  saveAttendance()
  {
    console.log(this.attendance_data.value);
  }

  resetAttendance()
  {
    var attendance = this.attendance_data.get('attendance') as FormArray;
    attendance.controls.forEach(element=>{
      element.get('is_absent')?.setValue(false); 
      element.get('reason')?.setValue("");
    });     
  }

  clearAttendance()
  {
    this.resetAttendance();
    (this.attendance_data.get('attendance') as FormArray).clear();
    this.attendance_data.reset();
  }
}
