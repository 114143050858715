<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="d-flex my-xl-auto right-content">
                    <div class="pr-1 mb-3 mb-xl-0">
                        <button type="button" class="btn btn-info" (click)="onAddClick()">Add New</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage OBE Parts and their Settings</p>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id')">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="programme_type.programme_type_id">{{programme_type.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" (click)="getPartSettingsList()">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                        <br>




                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th>S.No</th>
                                        <th>Programme Type</th>
                                        <th>Part Name</th>
                                        <th>Incharge Type</th>
                                        <th>Student vs Staff Arrangement</th>
                                        <th>Student Assignment Type</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="part_settings.length>0">
                                        <ng-container>
                                            <tr style="text-align: center;"
                                                *ngFor="let parts of part_settings;let i=index">
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{parts.programme_type_id}}</td>
                                                <td>{{parts.part}}</td>
                                                <td>{{parts.incharge_type}}</td>
                                                <td>{{parts.staff_assignment_type}}</td>
                                                <td>{{parts.student_assignment_type}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(parts)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!part_setting_list_loading && part_settings.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="part_setting_list_loading && part_settings.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>



    </div>
</div>
<!-- Large Modal -->
<div class="modal" id="modal-popup">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
            <div class="modal-header">
                <!--<h6 class="modal-title">Part Settings Form</h6>-->
                <h6 class="modal-title" *ngIf="!this.part_setting_form.value.id">Create Part Settings </h6>
                <h6 class="modal-title" *ngIf="this.part_setting_form.value.id">Edit Part Settings</h6>

            </div>
            <div class="modal-body">
                <div class="card card-body pd-20 pd-md-40">
                    <form [formGroup]="part_setting_form">
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                            <select formControlName="programme_type_id" class="form-control"
                                (change)="onFilterSelectChange('programme_type_id')">
                                <option value="">Select Programme Type</option>
                                <option
                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                    [value]="programme_type.programme_type_id">{{programme_type.name}}</option>
                            </select>
                            <div *ngIf="isInvalid('programme_type_id')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Programme Type is required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Part</label>
                            <select formControlName="part" class="form-control">
                                <option value="">All Parts</option>
                                <option *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                    [value]="part.id">{{part.text}}</option>
                            </select>
                            <div *ngIf="isInvalid('part')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Part Name is required.</li>
                                </ul>
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                Enrollment Year</label>
                            <select formControlName="enrolled_year" class="form-control">
                                <option value="">Select</option>
                                <option
                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="year">{{year}}</option>
                            </select>
                            <div *ngIf="isInvalid('enrolled_year')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Enrolled Year is required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                Semester</label>
                            <select formControlName="semester" class="form-control">
                                <option value="">Select</option>
                                <option
                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="semester.id">{{semester.text}}</option>
                            </select>
                            <div *ngIf="isInvalid('semester')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Semester is required.</li>
                                </ul>
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Staff Incharge Type
                            </label>
                            <select formControlName="incharge_type" class="form-control"
                                (change)="typeChange($event,'incharge_type')">
                                <option value="">Choose Incharge Type</option>
                                <option *ngFor="let incharge_type of this.incharge_types;
                                let i=index;trackBy:fromDataResolver.identify;" [value]="incharge_type.key">
                                    {{incharge_type.value}}
                                </option>
                            </select>
                            <div *ngIf="isInvalid('incharge_type')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Incharge Type is required.</li>
                                </ul>
                            </div>
                        </div>


                        <div class="form-group" *ngIf="part_setting_form.value.incharge_type=='SPECIFIC'">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                Name</label>
                            <kendo-multiselect [data]="department_list" valueField="programme_id"
                                style="height: 38px;font-family: Arial, Helvetica, sans-serif;"
                                textField="programme_display_name" formControlName="programme_ids"
                                placeholder="Select Programme(s)" class="menu_select">
                            </kendo-multiselect>
                            <div *ngIf="isInvalid('programme_ids')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Programme Name(s) is required.</li>
                                </ul>
                            </div>
                        </div>


                        <div class="form-group"
                            *ngIf=" (part_setting_form.value.incharge_type=='SPECIFIC'||part_setting_form.value.incharge_type=='AUTONOMOUS')">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Staff
                                Assignment Type</label>
                            <select formControlName="staff_assignment_type" class="form-control"
                                (change)="typeChange($event,'staff_assignment_type')">
                                <option value="">Choose Student vs. Staff Arrangement</option>
                                <option *ngFor="let type of this.student_staff_arrange_types;
                                let i=index;trackBy:fromDataResolver.identify;" [value]="type.key">
                                    {{type.value}}
                                </option>
                            </select>
                            <div *ngIf="isInvalid('staff_assignment_type')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Staff Assignment Type is required.</li>
                                </ul>
                            </div>
                        </div>


                        <div class="form-group"
                            *ngIf=" ((part_setting_form.value.incharge_type=='SPECIFIC'||part_setting_form.value.incharge_type=='AUTONOMOUS')&&(part_setting_form.value.staff_assignment_type=='CHOICE')&& !(part_setting_form.value.incharge_type=='PARENT'))">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Student Assignment
                                Type
                            </label>
                            <select formControlName="student_assignment_type" class="form-control">
                                <option value="">Select Student Assignment Method</option>
                                <option *ngFor="let type of this.student_assignment_types;
                                        let i=index;trackBy:fromDataResolver.identify;" [value]="type.key">
                                    {{type.value}}
                                </option>
                            </select>
                            <div *ngIf="isInvalid('student_assignment_type')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required"> Student Assignment Type is required.</li>
                                </ul>
                            </div>
                        </div>



                        <div class="add-btn-row" style="text-align: center;">
                            <!--<button class="btn btn-primary mg-r-20" (click)="onAdd()">Add</button>-->

                            <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                [disabled]="canDisable()">Add</button>
                            <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                [disabled]="canDisable()">Add & Close</button>
                            <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                *ngIf="this.part_setting_form.value.id"
                                [hidden]="!this.part_setting_form.value.id">Delete</button>
                            <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                            <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- /main-content -->
<app-footer></app-footer>