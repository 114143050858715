<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Building-wise Attendance</h4>
            </div>
          </div>
          <form [formGroup]="attendance_data">
          <div class="card-body">
            <div class="row row-sm">
              
                
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Date & Time</label>
                  <input type="datetime-local" class="form-control" formControlName="date_time" placeholder="Enter date">
                </div>
              </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Hostel</label>
                    <select class="form-control" formControlName = "hostel_name"
                    name="room"
                      >
                      <option value="">Select Hostel</option>
                      <option *ngFor="let hostel of hostels;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="hostel.hostel_name">
                        {{hostel.hostel_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            
              <div class="row row-sm">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll No.</label>
                    <input type="text" class="form-control" formControlName="roll_no" 
                    placeholder="Enter Roll No.">
                  </div>
                </div>
  
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select In/Out</label><br>  
                      <input type="radio" name="in_out" formControlName="in_out" value="in" class="mg-t-20"> In 
                      <input type="radio" name="in_out" formControlName="in_out" value="out" > Out  
                    </div>
                  </div>
  
                  <div class="col-lg-2">
                    <div class="form-group">
                      <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Save"
                        [disabled]="canDisableAction()" (click)="onSaveClick()" data-placement="bottom"
                        data-toggle="tooltip" title="Save Attendance">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              
            <!-- </form> -->
          </div>
            <div class="card-body no-border-top" *ngIf="attendance!.length>0">
            <div class="table-responsive border-top userlist-table">
              <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                <thead>
                  <th>
                    <span>Roll No</span>
                  </th>
                  <th>
                    <span>Timing</span>
                  </th>
                  <th>
                      <span>Entered</span>
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let att of attendance; let i = index;">
                    <td>{{ att?.roll_no }} </td>
                    <td>{{ att?.date_time }} </td>
                    <td>{{ att?.in_out | titlecase}} </td>
                    
                  </tr>

                </tbody>
              </table>
            
            </div>
            
          </div>
        </form>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
