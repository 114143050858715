<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>

  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Incharges</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
    </div>

    <div class="row row-sm">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
        <form [formGroup]="generalMenuForm">
            <div class="card" >
                <div class="card-header pb-0">
                  <div class="d-flex justify-content-between">
                    <h4 class="card-title mg-b-0 mb-3">Menu Items</h4>
                  </div>
                  <select
                  class="form-control"
                  formControlName="month" 
                  (change)="chooseMonth()"> 
                      <option value="">Select Month</option>
                      <option *ngFor="let month of months;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="month.month_id">{{month.month_name}}</option>
                  </select>
                </div>
      
                
                <div class="card-body">
                  <div class="table-responsive border-top userlist-table" *ngIf="generalMenuForm.controls['month']?.value">
                    <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                      <thead>
                        <th width="5%">
                            <span>S.No</span>
                        </th>
                        <th width="15%">
                          <span>Date (Day)</span>
                        </th>
                        <th *ngFor="let time of food_time">
                          {{time}}
                        </th>
                      </thead>
                      <tbody class="form-group" formArrayName="menu_list">
                        <!-- <div > -->
                            <tr *ngFor="let item of aliasesArrayControl; let i = index; trackBy:fromDataResolver.identify;"
                            [formGroupName]="i">
                                <td>{{i+1}}</td>
                                <td>{{item.value.date}}<br>({{item.value.day}})</td>   
                                <td *ngFor= "let menu of item.value.menu; let j = index;trackBy:fromDataResolver.identify;" formArrayName="menu">
                                  <div [formGroupName]="j">
                                  <tr *ngFor="let food of menu.food_menu; let k = index; trackBy:fromDataResolver.identify;" formArrayName="food_menu">
                                    <div [formGroupName]="k">          
                                      <!-- <select
                                      class="form-control"
                                      formControlName="food_list" 
                                      > 
                                        <option value="">Select Food</option>
                                        <option *ngFor="let menu of menuItems;let i=index;trackBy:fromDataResolver.identify;" 
                                        [ngValue]="menu.item_name">{{menu.item_name}}</option>
                                      </select> -->             
                                    <kendo-multiselect
                                    [data]="menuItems"
                                    valueField="id" textField="item_name"
                                    formControlName="food_list"
                                    placeholder="Enter {{food.food_type}} Menu"
                                    class="menu_select"
                                    ></kendo-multiselect>

                                    </div>
                                  </tr>
                                </div>
                                </td>
                            </tr>
                        <!-- </div> -->
      
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="add-btn-row" *ngIf="generalMenuForm.controls['month'].value">
                    <button class="btn btn-primary mg-r-20" (click)="onSave()">Save</button>
                </div>
              </div>
        </form>
        
      </div>
    </div>

    


  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>


<!-- <td>
  <kendo-multiselect
  [data]="menuItems"
  valueField="id" textField="item_name"
  formControlName="veg_menu1"
  [placeholder]="'Enter Veg Menu'"
  class="menu_select"
  ></kendo-multiselect><br>
  <kendo-multiselect
  [data]="menuItems"
  valueField="id" textField="item_name"
  [placeholder]="'Enter N.Veg Menu'"
  class="menu_select"
  formControlName="nveg_menu1"></kendo-multiselect><br>
  <input type="checkbox" (click)="setNonVeg($event.target,i,'nveg_menu1')"> Same as Veg
</td>
<td>
  <kendo-multiselect
  [data]="menuItems"
  valueField="id" textField="item_name"
  [placeholder]="'Enter Veg Menu'"
  class="menu_select"
  formControlName="veg_menu2"></kendo-multiselect><br>
  <kendo-multiselect
  [data]="menuItems"
  [placeholder]="'Enter N.Veg Menu'"
  valueField="id" textField="item_name"
  class="menu_select"
  formControlName="nveg_menu2"></kendo-multiselect><br>
  <input type="checkbox" (click)="setNonVeg($event.target,i,'nveg_menu2')"> Same as Veg
</td>
<td>
  <kendo-multiselect
  [data]="menuItems"
  valueField="id" textField="item_name"
  class="menu_select"
  [placeholder]="'Enter Veg Menu'"
  formControlName="veg_menu3"></kendo-multiselect><br>
  <kendo-multiselect
  [data]="menuItems"
  valueField="id" textField="item_name"
  [placeholder]="'Enter N.Veg Menu'"
  class="menu_select"
  formControlName="nveg_menu3"></kendo-multiselect><br>
  <input type="checkbox" (click)="setNonVeg($event.target,i,'nveg_menu3')"> Same as Veg
</td> -->




