<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>

  <!-- container -->
  <div class="container-fluid ">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Rooms Management</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
    </div>

    <!-- Create Room -->
    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="card box-shadow-0">
          <div class="card-header">
            <h4 class="card-title mb-1">Create Room</h4>
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="roomForm" class="form-horizontal">
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Room Name</label>
                <input class="form-control" required="" type="text" formControlName="name">
              </div>
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canSave()">Add Room</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 grid-margin">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Rooms</h4>
              <div class="text-align-right">
                <form [formGroup]="searchForm" class="form-horizontal">
                  <div class="form-group">
                    <input placeholder="Search..." class="form-control" required="" type="text" formControlName="name">
                  </div>
                </form>
              </div>

            </div>

          </div>
          <div class="card-body">
            <div class="table-responsive border-top userlist-table">
              <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                <thead>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Actions</span>
                  </th>
                </thead>
                <tbody>

                  <tr *ngFor="let room of rooms">

                    <td>{{ room.name }} </td>
                    <td>
                      <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEdit(room)">
                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                      </button>
                      <button type="button" class="btn btn-sm btn-danger mr-2" (click)="onDelete(room)">
                        <fa-icon [icon]="fonts.faTrash"></fa-icon>
                      </button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
