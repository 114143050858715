<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage On-Duty Requests</p>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="request_filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Request
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="search_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Request
                                            Status</label>
                                        <select formControlName="status" class="form-control">
                                            <option value="">Select Request Status</option>
                                            <option
                                                *ngFor="let status of request_status;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="status.id">{{status.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mg-t-25 search-button" title="Search"
                                            *ngIf="!request_list_loading" (click)="getStudentODRequestList()">
                                            Get Report
                                        </button>
                                        <button class="btn btn-info mg-t-25 search-button" type="button" disabled
                                            *ngIf="request_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr class="text-center">
                                        <th>S.No</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Hours</th>
                                        <th>R.Nos.</th>
                                        <th>Reason</th>
                                        <th>Status</th>
                                        <th style="width: 13%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <ng-container *ngIf="odRequests.length>0">
                                        <ng-container
                                            *ngFor="let request of odRequests;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row" style="width: 10px;">{{i+1}}</td>
                                                <td style="width: 15%;">{{getDateTimeView(request.start_date)}}</td>
                                                <td style="width: 15%;">{{getDateTimeView(request.end_date)}}</td>
                                                <td>{{request.hour_number}}</td>
                                                <td>{{request.roll_nos}}</td>
                                                <td style="width: 25%;">{{request.reason}}</td>
                                                <td [class.text-success]="request.status === 'APPROVED'"
                                                    [class.text-danger]="request.status === 'REJECTED'">
                                                    <b>{{request.status | titlecase}}</b>
                                                </td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button"
                                                            (click)="onChangeStatus(request, 'APPROVED')"
                                                            class="btn btn-sm btn-success mr-2"
                                                            *ngIf="request.status=='NEW'">
                                                            <fa-icon [icon]="fonts.faCheck"></fa-icon>
                                                        </button>

                                                        <button type="button"
                                                            (click)="onChangeStatus(request, 'REJECTED')"
                                                            class="btn btn-sm btn-danger mr-2"
                                                            *ngIf="request.status=='NEW'">
                                                            <fa-icon [icon]="fonts.faTimes"></fa-icon>
                                                        </button>

                                                        <!-- <button type="button" (click)="onEditClick(request)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                                        </button> -->

                                                        <button type="button" (click)="onChangeStatus(request, 'NEW')"
                                                            class="btn btn-sm btn-warning "
                                                            *ngIf="request.status=='APPROVED' || request.status=='REJECTED' ">
                                                            <fa-icon [icon]="fonts.faRedo"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!request_list_loading && odRequests.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="request_list_loading && odRequests.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <!-- <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="odRequestForm">
                    <div class="modal-header">
                        <h6 class="modal-title">Create On-Duty Request</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="odRequestForm" class="form-horizontal">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                        Date</label>
                                    <input class="form-control" required="" readonly type="datetime-local"
                                        formControlName="from">
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End Date</label>
                                    <input class="form-control" readonly required="" type="datetime-local"
                                        formControlName="to">
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Students
                                        R.Nos</label>
                                    <textarea class="form-control" required="" type="text" readonly
                                        formControlName="rnos"
                                        placeholder="Enter roll nos. separated by commas. Ex: 10PA01, 11PA11 etc.."></textarea>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Reason for
                                        On-Duty Request</label>
                                    <textarea class="form-control" required="" type="text" readonly
                                        formControlName="reason"></textarea>
                                </div>

                                <div class="add-btn-row">
                                    <button class="btn btn-success mg-r-20" (click)="onAccept()"
                                        *ngIf="odRequestForm.get('status')!.value =='Applied'">Approve</button>
                                    <button class="btn btn-danger mg-r-20" (click)="onReject()"
                                        *ngIf="odRequestForm.get('status')!.value =='Applied'">Reject</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>