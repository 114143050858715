import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { StockItems } from 'src/app/controllers/admins/hostel-management/manage-mess/stock-item';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { isString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';



@Component({
  selector: 'app-attendance-table',
  templateUrl: './attendance-table.component.html',
  styleUrls: ['./attendance-table.component.scss']
})
export class AttendanceTableComponent implements OnInit {
  fonts = fonts;
  semester_attendance_data: SemesterAttendanceInterface[] = [];
  monthly_attendance_data: MonthlyAttendanceInterface[] = [];
  title = 'Students Attendance Data';
  attendance_data_loading: boolean = false;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  days: Date[] = [];
  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  category_list_loading = false;
  attendance_table_filter_form: FormGroup;
  attendance_upload_form: FormGroup;
  student_list_loading: boolean = false;
  student_modal_data: {
    student_rno: string, student_name: string
  } = { student_rno: "", student_name: "" };

  mode: string = "general";
  //filter_mode: string = "general";
  sections: any = [];
  hour_data: HourReportInterface[] = [];
  popup_mode: string = '';
  hour_status: {
    key: string, value: string
  }[] = [
      { key: "PRESENT", value: "Present" }, { key: "ABSENT", value: "Absent" }, { key: "LEAVE", value: "Leave" },
      { key: "ON-DUTY", value: "On Duty" }
    ];
  save_loading: boolean = false;
  student_attendance_data: any = undefined;
  bulk_upload_hour_data: Modal = {
    modal_id: "hour_upload_common_modal",
    modal_button_text: "Student Attendance Bulk Upload",
    modal_header: "Student Attendance Bulk Upload",
    list_name: "Hour Upload",
    return_type: "FILE",
    download_note: "Valid Hour Status are PRESENT, ABSENT, LEAVE, ON-DUTY",
    csv_data: {
      columns: [
        { column_name: "date", column_type: "required" },
        { column_name: "hour_number", column_type: "required" },
        { column_name: "roll_no", column_type: "required" },
        { column_name: "status", column_type: "required" },
      ],
      row_data: "",
    },
    table_data: {
      column_names: ["Date", "Hour No.", "Roll No.", "Status"],
      column_values: ["15-05-2021", "2", "20PA01", "ABSENT"]
    }
  };
  report_types: { id: string, name: string }[] = [{
    id: "DAY_WISE", name: "Day Wise Report"
  }, { id: "HOUR_WISE", name: "Hour Wise Report" }]
  show_day_wise: boolean = true;
  constructor(private adminService: AdminservicesService, private fb: FormBuilder) {
    this.attendance_table_filter_form = this.fb.group({
      filter_mode: ["month"],
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      enrolled_year: [''],
      section: [''],
      semester: [''],
      start_date: [''],
      end_date: [''],
      roll_no: [''],
      percentage: [''],
      //report_type: ['']
    });

    this.attendance_upload_form = this.fb.group({
      attendance_date: ['', [Validators.required]],
      hour_number: ['', [Validators.required]],
      roll_no: ['', [Validators.required]],
      hour_status: ['', [Validators.required]]
    });

  }
  ngOnInit(): void {
    this.getProgrammeTypeList();
  }

  filterChange(ev) {
    this.semester_attendance_data = []; this.monthly_attendance_data = [];
    this.attendance_table_filter_form.get('programme_type_id').setValue(""); this.attendance_table_filter_form.get('finance_type').setValue("");
    this.attendance_table_filter_form.get('programme_id').setValue(""); this.attendance_table_filter_form.get('enrolled_year').setValue("");
    this.attendance_table_filter_form.get('section').setValue(""); this.attendance_table_filter_form.get('semester').setValue("");
    this.attendance_table_filter_form.get('start_date').setValue(""); this.attendance_table_filter_form.get('end_date').setValue("");
    this.attendance_table_filter_form.get('roll_no').setValue("");
  }
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.adminService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.adminService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id == "programme_id") {
      this.sections = [];
      this.attendance_table_filter_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.attendance_table_filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
      if (this.sections.length == 1) {
        this.attendance_table_filter_form.get('section').setValue(this.sections[0].section_name);
      }
    }

    if (id === 'programme_type_id' || id === 'finance_type') {
      this.attendance_table_filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.attendance_table_filter_form);
    }
  }

  getDate(date_time: any) {
    if (isString(date_time)) {
      date_time = new Date(date_time);
    }
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "/" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "/" + date_time.getFullYear()
  }

  displayHourData(rno: string, name: string, attendance) {
    console.log(attendance);
    if (attendance.hour_data?.length > 0) {
      this.monthly_attendance_data = attendance;
      this.student_modal_data.student_rno = rno; this.student_modal_data.student_name = name;

    }
  }

  async getStudentData() {
    if (!this.attendance_table_filter_form.value.programme_type_id) {
      alert("Please choose programme type"); return;
    }
    if (!this.attendance_table_filter_form.value.finance_type) {
      alert("Please choose Finance Type"); return;
    }
    if (!this.attendance_table_filter_form.value.programme_id) {
      alert("Please choose programme name"); return;
    }
    if (!this.attendance_table_filter_form.value.enrolled_year) {
      alert("Please choose Enrollment Year"); return;
    }
    if (!this.attendance_table_filter_form.value.semester) {
      alert("Please choose Semester"); return;
    }
    //if (this.attendance_table_filter_form.value.filter_mode != 'semester') {
    if (!this.attendance_table_filter_form.value.start_date) {
      alert("Please choose Start Date"); return;
    }
    if (!this.attendance_table_filter_form.value.end_date) {
      alert("Please choose End Date"); return;
    }
    /* if (!this.attendance_table_filter_form.value.report_type) {
      alert("Please choose Report Type"); return;
    } */
    //}
    try {
      this.student_list_loading = true;
      /* if (this.attendance_table_filter_form.value.filter_mode == 'semester') {
        this.semester_attendance_data = [];
        const service_response = await this.adminService.getStudentSemWiseAttendanceReport(this.attendance_table_filter_form.value);
        if (service_response && service_response.success) {
          this.semester_attendance_data = service_response.data;
          console.log(this.semester_attendance_data);
        }
      }
      else { */
      this.monthly_attendance_data = [];
      const service_response = await this.adminService.getStudentMonthWiseAttendanceReport(this.attendance_table_filter_form.value);
      if (service_response && service_response.success) {
        this.monthly_attendance_data = service_response.data;
        this.monthly_attendance_data.sort((a, b) => (a.roll_no > b.roll_no) ? 1 : -1);
        console.log(this.monthly_attendance_data);
      }
      //}
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_list_loading = false;
    }
  }


  viewHourData(day: HourReportInterface[]) {
    this.hour_data = day;
    this.popup_mode = 'view';
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  closeDataClick() {
    this.hour_data = [];
    this.popup_mode = '';
    this.student_attendance_data = undefined;
    JQueryHelper.closeModal('#modal-popup');
  }

  viewTotalReport(data) {
    this.student_attendance_data = data;
    this.popup_mode = 'total';
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  addStudentAttendance() {
    this.popup_mode = 'add';
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  isInvalid(formControl: any): boolean {
    return this.attendance_upload_form.touched && this.attendance_upload_form.get(formControl)?.errors !== null;
  }
  canDisable(): boolean {
    return this.attendance_upload_form.status !== 'VALID';
  }

  async onSave() {
    try {
      var form_data = this.attendance_upload_form.value;
      console.log(form_data);
      this.save_loading = true;
      const res = await this.adminService.addStudentAttendance(form_data);
      if (res) {
        this.getStudentData();
        alert("Student attendance added successfully.");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  onSaveAndClose() {
    this.onSave();
    this.onCloseClick();
  }

  reset(): void {
    this.attendance_upload_form.reset();
    Object.keys(this.attendance_upload_form.controls).forEach(control => { this.attendance_upload_form.get(control).setValue("") });
  }

  onCloseClick() {
    this.reset();
    this.popup_mode = '';
    JQueryHelper.closeModal('#modal-popup');
  }

  async bulkUploadHourData(e) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_upload_hour_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.adminService.studentAttendanceBulkUpload(formData);
      if (response.success && response.data) {
        alert("Attendance File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_upload_hour_data.show_loader = false;
    }
  }

  triggerFunction(event: any) {
    var roll_nos: string = this.attendance_upload_form.get('roll_no').value;
    var prev_string = roll_nos.substring(roll_nos.length - 8).substring(0, 5);
    if (event.key === 'Enter') {
      event.preventDefault();
      this.attendance_upload_form.get('roll_no').setValue(roll_nos + "," + prev_string);
    }
  }

  getProgrammeName() {
    return (this.programme_list.find(x => x.programme_id == this.attendance_table_filter_form.value.programme_id)?.programme_name as string).split('.').join('');
  }

  downloadMonthlyCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const student of this.monthly_attendance_data) {
      let data_item = {
        "S.No": i,
        "Roll No.": student.roll_no,
        "Name": student.given_name + " " + student.middle_name,
        "Total days present | Total working days": student.no_days_present.toString() + " | " + student.total_working_days.toString()
      };
      if (this.show_day_wise) {
        this.monthly_attendance_data.forEach(data => {
          data.date_wise.forEach(date => {
            let date_string = new Date(date.day_date).getDate() + "-" + (new Date(date.day_date).getMonth() + 1 as number);
            data_item[date_string] = date.no_of_hours_absent.toString() + "/" + date.no_of_hours.toString();
          })
        })
      }
      data_item['Percentage'] = (student.attendance_percentage.toFixed(2)).toString() + '%';
      // data_item['Percentage'] = (((student.total_working_days - (student.total_no_hours_absent / 5)) /
      //   student.total_working_days) * 100).toPrecision(2) + "%";
      /* if (this.attendance_table_filter_form.value.report_type == 'DAY_WISE') {
        data_item['Percentage'] = (student.attendance_percentage.toPrecision(2)) + "%";
      }
      else {
        const attendance_percentage = (((student.total_no_hours_present +
          student.total_no_of_unmarked_hours) /
          student.total_no_hours) * 100)
        data_item['Percentage'] = (attendance_percentage.toPrecision(2)) + "%";
      } */
      data_list.push(data_item);
      i++;
    }
    CSVHelper.downloadCSV(data_list, "Student_Attendance_Report_" + "_" + this.getProgrammeName() + "_" + this.attendance_table_filter_form.value.enrolled_year
      + "_" + DateHelper.convertToControlDate(new Date()));
  }
}

interface SemesterAttendanceInterface {
  roll_no: string,
  given_name: string,
  middle_name: string,
  total_working_days: number,
  no_days_absent: number,
  attendance_data: {
    month: number,
    year: number,
    month_name: string,
    month_total_working_days: number,
    month_no_days_absent: number,
  }[] //multiple months in the semester
}

interface MonthlyAttendanceInterface {
  roll_no: string,
  given_name: string,
  middle_name: string,
  total_working_days: number, //overall for month
  no_days_present: number //overall for month
  no_days_absent: number, //overall for month
  total_no_hours_present: number,
  total_no_of_unmarked_hours: number,
  total_no_hours_absent: number,
  total_no_hours: number,
  attendance_percentage: number,
  date_wise: {
    day_date: string,
    day_order_name: string,
    no_of_hours: number, //for single day
    no_of_hours_absent: number, //for single day
    no_of_hours_present: number,
    hour_wise: HourReportInterface[] //multiple hours in a day
  }[] //multiple days in the month
}

interface HourReportInterface {
  hour_number: number,
  course_code: string,
  is_present: boolean,
  is_marked: boolean
  course_name: string,
  marked_by_id: string, marked_by_name: string,
  od_given: boolean, leave_applied: boolean,
}
