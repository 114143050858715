import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { Modal } from 'src/app/components/ModalInterface';
var XLSX = require("xlsx");

@Component({
  selector: 'app-cagp-report',
  templateUrl: './cagp-report.component.html',
  styleUrls: ['./cagp-report.component.scss']
})
export class CagpReportComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;

  // master data
  component_search_form: any;

  // core data
  course_list: any[] = [];
  course_list_loading = false;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading: boolean = false;
  filter_programme_list: any[] = [];
  filter_programme_list_loading: boolean = false;
  // cas report
  cas_report_data: StudentMarksData = {} as StudentMarksData;
  cas_report_data_loading = false;

  active_accordion: number = 1;
  report_data: CAGPReport = {} as CAGPReport;
  report_data_loading: boolean = false;
  ces_value: string = 'EXISTING';
  internal_value: string = 'EXISTING';
  external_value: string = 'EXISTING';
  internal_course_list: any[] = [];
  internal_course_list_loading: boolean = false;
  internal_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "internal-map",
    modal_button_text: "Internal Marks Bulk Upload",
    modal_header: "Internal Marks Bulk Upload",
    list_name: "Internal Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "co1", column_type: "required" },
        { column_name: "co2", column_type: "required" },
        { column_name: "co3", column_type: "required" },
        { column_name: "co4", column_type: "required" },
        { column_name: "co5", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "CO1", "CO2", "CO3", "CO4", "CO5", "Total"],
      column_values: ["-", "-", "-", "30", "25", "40", "10", "5", "110"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No, Given & Middle Names and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  course_data: any = {};
  ces_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "ces-map",
    modal_button_text: "CES Marks Bulk Upload",
    modal_header: "CES Marks Bulk Upload",
    list_name: "CES Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "co1", column_type: "required" },
        { column_name: "co2", column_type: "required" },
        { column_name: "co3", column_type: "required" },
        { column_name: "co4", column_type: "required" },
        { column_name: "co5", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "CO1", "CO2", "CO3", "CO4", "CO5", "Total"],
      column_values: ["-", "-", "-", "30", "25", "40", "10", "5", "110"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  external_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "external-map",
    modal_button_text: "External Marks Bulk Upload",
    modal_header: "External Marks Bulk Upload",
    list_name: "External Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "co1", column_type: "required" },
        { column_name: "co2", column_type: "required" },
        { column_name: "co3", column_type: "required" },
        { column_name: "co4", column_type: "required" },
        { column_name: "co5", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "CO1", "CO2", "CO3", "CO4", "CO5", "Total"],
      column_values: ["-", "-", "-", "30", "25", "40", "10", "5", "110"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  external_month: number = 0;
  external_year: number = 0;
  external_valuation: number | string = 0;
  valuation_list: any[] = [];
  valuation_list_loading: boolean = false;
  internal_list_loading: boolean = false;
  external_list_loading: boolean = false;
  ces_list_loading: boolean = false;
  report_loading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.buildComponentSearchForm();
    this.getProgrammeTypeList();
    this.getProgrammeDataList();
    // Load core data
  }

  /// master data
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    this.course_list = [];
    this.component_search_form.get('staff_map_id').setValue('');
    this.report_data = {} as CAGPReport;
    if (id === 'programme_type_id') {
      this.getFilterProgrammeDataList(this.component_search_form);
    }
  }
  filterSectionChanged(ev) {
    this.report_data = {} as CAGPReport;
  }

  async getFilterProgrammeDataList(form: any): Promise<void> {
    try {
      this.filter_programme_list_loading = true;
      this.filter_programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.filter_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.filter_programme_list_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  buildComponentSearchForm(): void {
    this.component_search_form = this.fb.group({
      programme_type_id: ['UG'],
      enrolled_year: [2020],
      offering_programme_id: [''],
      semester: [3],
      course_code: ['20UAC306'],
      staff_map_id: ['']
    });
  }

  async onSearchClick(): Promise<void> {
    try {
      this.course_list_loading = true;
      this.course_list = [];
      /* if (!this.component_search_form.value.offering_programme_id) {
        alert("Please choose Offering Programme"); return;
      } */
      if (!this.component_search_form.value.enrolled_year) {
        alert("Please choose Enrolled Year"); return;
      }
      if (!this.component_search_form.value.semester) {
        alert("Please choose Semesteer"); return;
      }
      if (!this.component_search_form.value.course_code) {
        alert("Please enter Course Code"); return;
      }
      var search_query: any = {
        programme_type_id: this.component_search_form.value.programme_type_id,
        programme_id: this.component_search_form.value.programme_id,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        course_code: this.component_search_form.value.course_code,
      };

      //const service_response = await this.restService.getCoursesByStaff(search_query);
      const service_response = await this.restService.searchStaffCourseMap(search_query); //v2
      if (service_response && service_response.success) {
        this.course_list = service_response.data.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.course_list = [];
      this.course_list_loading = false;
    } finally {
      this.course_list_loading = false;
    }
  }

  getCourseDetails(course) {
    if (course.virtual_section) {
      return course.virtual_section;
    }
    else {
      return this.getProgrammeName(course.class_taking_programme_id) + " " + course.class_taking_section + " - " + course.staff.roll_no;
    }
  }

  changeActiveAccordion(accordion_no: number) {
    if (this.active_accordion == accordion_no)
      this.active_accordion = 0;
    else
      this.active_accordion = accordion_no;
  }
  isActiveAccordion(accordion_no: number) {
    if (accordion_no == this.active_accordion) return true;
    return false;
  }

  async getReportData() {
    this.report_data = {} as CAGPReport;
    this.course_data = this.course_list.find(x => x._id == this.component_search_form.value.staff_map_id);
    const report_search_obj = {
      "enrolled_year": this.component_search_form.value.enrolled_year,
      "semester": this.component_search_form.value.semester,
      "offered_by_programme_type_id": this.component_search_form.value.programme_type_id,
      "offering_programme_id": this.component_search_form.value.offering_programme_id,
      "course_code": this.component_search_form.value.course_code,
      "staff_map_id": this.component_search_form.value.staff_map_id
    };

    const response = await this.restService.getCAGPReport(report_search_obj);
    if (response.success && response.data) {
      this.report_data = response.data
    }

    /* this.report_data = {
      _id: "4451fd31-b159-4bc3-bc98-34cdc45085ce",
      id: "4451fd31-b159-4bc3-bc98-34cdc45085ce",
      //user input
      offered_by_programme_type_id: "UG",
      offering_programme_id: "b5d8a3cf-8c01-4ad4-909a-06e721e6fad0",
      enrolled_year: 2020,
      semester: 3,
      course_code: "20UAC306",
      staff_map_id: "56591ede-b49f-43ed-b86a-511b7e724195",

      //course data
      class_taking_finance_type_id: "self_finance",
      class_taking_programme_id: "b5d8a3cf-8c01-4ad4-909a-06e721e6fad0",
      class_taking_section: "A",
      part: "PART_III",
      course_type: "CORE",

      //marks

      internal_marks: {
        weightage: {
          co1: 15,
          co2: 10,
          co3: 15,
          co4: 12,
          co5: 8,
          total: 60
        },
        marks: [
          {
            roll_no: "20UL01",
            given_name: "AAA",
            middle_name: "C",
            co_marks: {
              co1: 10,
              co2: 8,
              co3: 13,
              co4: 10,
              co5: 7,
              total: 54
            }
          },
          {
            roll_no: "20UL02",
            given_name: "BBB",
            middle_name: "X",
            co_marks: {
              co1: 10,
              co2: 8,
              co3: 13,
              co4: 12,
              co5: 4,
              total: 44
            },
          },
          {
            roll_no: "20UL02",
            given_name: "BBB",
            middle_name: "X",
            co_marks: {
              co1: 10,
              co2: 8,
              co3: 13,
              co4: 12,
              co5: 4,
              total: 44
            },
          },

        ],
        reference_id: "FILE",
      },
      external_marks: {
        weightage: {
          co1: 25,
          co2: 10,
          co3: 15,
          co4: 12,
          co5: 8,
          total: 80
        },
        marks: [
          {
            roll_no: "20UL01",
            given_name: "AAA",
            middle_name: "C",
            co_marks: {
              co1: 20,
              co2: 8,
              co3: 13,
              co4: 10,
              co5: 7,
              total: 54
            }
          },
          {
            roll_no: "20UL02",
            given_name: "BBB",
            middle_name: "X",
            co_marks: {
              co1: 21,
              co2: 8,
              co3: 13,
              co4: 12,
              co5: 4,
              total: 44
            }
          },
          {
            roll_no: "20UL02",
            given_name: "BBB",
            middle_name: "X",
            co_marks: {
              co1: 10,
              co2: 8,
              co3: 13,
              co4: 12,
              co5: 4,
              total: 44
            },
          }
        ],
        reference_id: "FILE",
      },


      cagp_marks: {
        co1: 5.5,
        co2: 4.8,
        co3: 4.3,
        co4: 3.2,
        co5: 4.5,
        total: 0
      },
      class_average: {
        co1: 55,
        co2: 48,
        co3: 43,
        co4: 32,
        co5: 45,
        total: 0
      },
      total_marks: {
        weightage: {
          co1: 85,
          co2: 70,
          co3: 95,
          co4: 72,
          co5: 58,
          total: 0
        },
        marks: [
          {
            roll_no: "20UL01",
            given_name: "AAA",
            middle_name: "C",
            co_marks: {
              co1: 20,
              co2: 8,
              co3: 13,
              co4: 10,
              co5: 7,
              total: 54
            },
            gps_marks: {
              co1: 2.0,
              co2: 8.0,
              co3: 1.3,
              co4: 1.0,
              co5: 7.0,
              total: 0
            }
          },
          {
            roll_no: "20UL02",
            given_name: "BBB",
            middle_name: "X",
            co_marks: {
              co1: 21,
              co2: 8,
              co3: 13,
              co4: 12,
              co5: 4,
              total: 44
            },
            gps_marks: {
              co1: 2.1,
              co2: 8.0,
              co3: 1.3,
              co4: 1.2,
              co5: 4.0,
              total: 0
            },
          },
          {
            roll_no: "20UL03",
            given_name: "CCC",
            middle_name: "Z",
            co_marks: {
              co1: 11,
              co2: 18,
              co3: 23,
              co4: 32,
              co5: 50,
              total: 190
            },
            gps_marks: {
              co1: 1.1,
              co2: 1.8,
              co3: 2.3,
              co4: 3.2,
              co5: 5.0,
              total: 0
            },
          },

        ],
      }


      //final report


    } */
  }

  async bulkUploadInternal(e: any) {
    try {
      console.log(e);
      this.internal_render_data.show_loader = true;
      /* const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return; */
      const validity = this.validateBulkUploadData(e);
      if (!validity.valid) {
        alert(validity.error);
        return;
      }
      this.report_data.internal_marks = this.convertToStudentsMarkFormat(e)
      this.cas_report_data = JSON.parse(JSON.stringify(this.report_data.internal_marks));
      if (this.report_data._id) {
        this.saveUpdateReport();
      }
      alert("Internal Marks File uploaded successfully");
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.internal_render_data.show_loader = false;
    }

  }

  async getInternalCO() {
    try {
      this.internal_list_loading = true;
      this.cas_report_data_loading = true;
      const search_query: any = {
        "staff_map_id": this.component_search_form.value.staff_map_id
      }
      const service_response = await this.restService.getAttainmentReport(search_query);
      if (service_response && service_response.success) {
        const existing_co_data = service_response.data;
        const co_header: any[] = existing_co_data.co_header;
        this.cas_report_data.weightage = {} as COMarks;
        for (var i = 0; i < co_header.length; i++) {
          this.cas_report_data.weightage['co' + (i + 1)] = co_header[i].total;
        }
        this.cas_report_data.weightage.total = existing_co_data.co_header_total;
        const students_mark: any[] = existing_co_data.data;
        this.cas_report_data.marks = [];
        for (var i = 0; i < students_mark.length; i++) {
          this.cas_report_data.marks[i] = {} as COStudentMarks;
          this.cas_report_data.marks[i].roll_no = students_mark[i].roll_no;
          this.cas_report_data.marks[i].given_name = students_mark[i].given_name;
          this.cas_report_data.marks[i].middle_name = students_mark[i].middle_name;
          this.cas_report_data.marks[i].co_marks = {} as COMarks;
          this.cas_report_data.marks[i].co_marks.total = students_mark[i].COCOMMarks_Total;
          for (var j = 0; j < students_mark[i].COCOMMarks.length; j++) {
            this.cas_report_data.marks[i].co_marks['co' + (j + 1)] = students_mark[i].COCOMMarks[j].mark
          }
        }
        console.log(this.cas_report_data);
        this.report_data.internal_marks = this.cas_report_data;
        JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.internal_list_loading = false;
    } finally {
      this.cas_report_data_loading = false;
      this.internal_list_loading = false;
    }
  }


  async onCasReportCloseClick(): Promise<void> {
    this.cas_report_data = {} as StudentMarksData;
    JQueryHelper.closeModal('#modal-popup-attainmentreport');
  }

  bulkUploadExternal(e) {
    try {
      console.log(e);
      this.external_render_data.show_loader = true;
      /* const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return; */
      const validity = this.validateBulkUploadData(e);
      if (!validity.valid) {
        alert(validity.error);
        return;
      }
      this.report_data.external_marks = this.convertToStudentsMarkFormat(e)
      this.cas_report_data = JSON.parse(JSON.stringify(this.report_data.external_marks));
      if (this.report_data._id) {
        this.saveUpdateReport();
      }
      alert("Terminal Marks File uploaded successfully");
      JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.external_render_data.show_loader = false;
    }
  }

  async getExternalMarksFromCAS(){
    if (!this.component_search_form.value.enrolled_year) {
      alert("Please select Enrolled Year"); return;
    }
    if(!this.component_search_form.value.programme_type_id) {
      alert("Please select programme type id"); return;
    }
    if(!this.component_search_form.value.offering_programme_id) {
      alert("Please select offering programme id"); return;
    }
    if (!this.component_search_form.value.semester) {
      alert("Please select Semester"); return;
    }
    if (!this.component_search_form.value.course_code) {
      alert("Please enter Course Code"); return;
    }

    if(!this.component_search_form.value.staff_map_id) {
      alert("Please select Staff map id"); return;
    }

    const search_cas_data: any = {
      "enrolled_year": this.component_search_form.value.enrolled_year,
      "semester": this.component_search_form.value.semester,
      "offered_by_programme_type_id": this.component_search_form.value.programme_type_id,
      "offering_programme_id": this.component_search_form.value.offering_programme_id,
      "course_code": this.component_search_form.value.course_code,
      "staff_map_id": this.component_search_form.value.staff_map_id
    };

    try {
      this.external_list_loading = true;
      const service_response = await this.restService.searchCASReport(search_cas_data);
      if (service_response && service_response.success) {

        if(!service_response.data || service_response.data.length == 0 ||
          !service_response.data.external_marks || service_response.data.external_marks.length > 0) {
          alert("CAS Results are empty"); return;
        }

        this.report_data.external_marks = service_response.data.external_marks;
        this.cas_report_data = service_response.data.external_marks;
        this.cas_report_data = JSON.parse(JSON.stringify(this.report_data.external_marks));
        if (this.report_data._id) {
          this.saveUpdateReport();
        }
        // this.cas_report_data.weightage = {} as COMarks;
        // for (var i = 0; i < co_header.length; i++) {
        //   this.cas_report_data.weightage['co' + (i + 1)] = co_header[i].total;
        // }
        // this.cas_report_data.weightage.total = existing_co_data.co_header_total;
        // const students_mark: any[] = existing_co_data.data;
        // this.cas_report_data.marks = [];
        // for (var i = 0; i < students_mark.length; i++) {
        //   this.cas_report_data.marks[i] = {} as COStudentMarks;
        //   this.cas_report_data.marks[i].roll_no = students_mark[i].roll_no;
        //   this.cas_report_data.marks[i].given_name = students_mark[i].given_name;
        //   this.cas_report_data.marks[i].middle_name = students_mark[i].middle_name;
        //   this.cas_report_data.marks[i].co_marks = {} as COMarks;
        //   this.cas_report_data.marks[i].co_marks.total = students_mark[i].COCOMMarks_Total;
        //   for (var j = 0; j < students_mark[i].COCOMMarks.length; j++) {
        //     this.cas_report_data.marks[i].co_marks['co' + (j + 1)] = students_mark[i].COCOMMarks[j].mark
        //   }
        // }
        // console.log(this.cas_report_data);
        // this.report_data.internal_marks = this.cas_report_data;
        JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
      }
    } catch(error) {
      this.external_list_loading = false;
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.external_list_loading = false;
    }
  }

  async searchValuations() {
    if (!this.external_month) {
      alert("Please select Month"); return;
    }
    if (!this.external_year) {
      alert("Please select Year"); return;
    }
    const search_data = {
      month: this.external_month,
      year: this.external_year
    }
    this.valuation_list = [];
    this.valuation_list_loading = true;
    try {
      const service_response = await this.restService.searchExamSetup(search_data);
      if (service_response && service_response.success) {
        const exams = service_response.data;
        const exam = exams.find(x => (x.course_code == this.component_search_form.value.course_code
          && x.semester == this.component_search_form.value.semester
          && x.enrolled_year == this.component_search_form.value.enrolled_year))
        const setting_response = await this.restService.findExamSetup(exam._id);
        if (setting_response && setting_response.success) {
          var exam_setting = setting_response.data;
          let valuations: any[] = exam_setting.valuations;
          if (valuations.findIndex(x => x.valuation_no == this.external_valuation) < 0) {
            this.valuation_list_loading = false;
            alert("Chosen valuation no. does not exist");
            return;
          }
          else {
            let valuation: any;
            if (this.course_data.virtual_section) {
              valuation = valuations.find(x => (x.virtual_section == this.course_data.virtual_section && x.valuation_no == this.external_valuation));
            }
            else {
              valuation = valuations.find(x => (x.section == this.course_data.class_taking_section
                && x.programme_id == this.course_data.class_taking_programme_id
                && x.valuation_no == this.external_valuation
              ));
            }
            const external_mark_payload = {
              staff_map_id: this.component_search_form.value.staff_map_id,
              exam_id: exam._id,
              evaluated_in: valuation.evaluated_in
            };
            const score_response = await this.restService.findStudentScoreCard(external_mark_payload);
            if (score_response && score_response.success) {
              let score_sheet_data: any[] = score_response.data;
              score_sheet_data = score_sheet_data.sort((a, b) => a.student_roll_no.localeCompare(b.student_roll_no));
              this.cas_report_data.weightage = {} as COMarks;
              //neeeed to get it dynamically
              this.cas_report_data.weightage.co1 = 20; this.cas_report_data.weightage.co2 = 15;
              this.cas_report_data.weightage.co3 = 20; this.cas_report_data.weightage.co4 = 10;
              this.cas_report_data.weightage.co5 = 20; this.cas_report_data.weightage.total = 85;
              //change above dynamically
              this.cas_report_data.marks = [];
              for (var i = 0; i < score_sheet_data.length; i++) {
                this.cas_report_data.marks[i] = {} as COStudentMarks
                this.cas_report_data.marks[i].given_name = score_sheet_data[i].student?.given_name;
                this.cas_report_data.marks[i].middle_name = score_sheet_data[i].student?.middle_name
                this.cas_report_data.marks[i].roll_no = score_sheet_data[i].student_roll_no;
                let total = 0;
                this.cas_report_data.marks[i].co_marks = {} as COMarks;
                for (var j = 0; j < score_sheet_data[i].co_items?.length; j++) {
                  let score = score_sheet_data[i].co_items[j];
                  this.cas_report_data.marks[i].co_marks['co' + (score.co_id)] = score.mark;
                  total += score.mark;
                }
                this.cas_report_data.marks[i].co_marks['total'] = total;
                if (score_sheet_data[i].co_items?.length == 0 || !score_sheet_data[i].co_items) {
                  this.cas_report_data.marks[i].co_marks.co1 = 0; this.cas_report_data.marks[i].co_marks.co2 = 0;
                  this.cas_report_data.marks[i].co_marks.co3 = 0; this.cas_report_data.marks[i].co_marks.co4 = 0;
                  this.cas_report_data.marks[i].co_marks.co5 = 0; this.cas_report_data.marks[i].co_marks.total = 0;
                }
              }
              this.report_data.external_marks = this.cas_report_data;
              JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
            }
          }



        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    this.valuation_list_loading = false;
  }

  viewUploadedMarks(mark_type) {
    if (mark_type == 'INTERNAL') {
      this.cas_report_data = this.report_data.internal_marks;
    }
    else if (mark_type == 'EXTERNAL') {
      this.cas_report_data = this.report_data.external_marks;
    }
    JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
  }

  removeUploadedMarks(mark_type) {
    let obj: any = {
      _id: this.report_data._id
    }
    if (mark_type == 'INTERNAL') {
      this.report_data.internal_marks = undefined;
      obj.internal_marks = [];
    }
    else if (mark_type == 'EXTERNAL') {
      this.report_data.external_marks = undefined;
      obj.external_marks = [];
    }
    if (this.report_data._id) {
      this.saveUpdateReport();
    }
  }

  canDisplayReport() {
    if (!this.report_data.internal_marks) {
      return false;
    }
    if (!this.report_data.external_marks) {
      return false;
    }
    return true;
  }

  createArrayWithLimit(n) {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(i);
    }
    return result;
  }

  downloadPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A4 landscape; } .table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;} #report-table {font-size: 10px;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('report-html').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  formatStr(str: string) {
    return str.toUpperCase();
  }

  downloadExcel() {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("report-table");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, `${this.course_data.course_code}_${this.course_data.enrolled_year}_CAS_REPORT.xlsx`);
  }

  convertToStudentsMarkFormat(bulk_upload_data: BulkUploadFormat[]) {
    let return_data: StudentMarksData = {} as StudentMarksData;
    const weightage_row = bulk_upload_data.find(x => x.roll_no == '-');
    const index = bulk_upload_data.findIndex(x => x.roll_no == '-');
    delete (weightage_row.roll_no); delete (weightage_row.given_name); delete (weightage_row.middle_name);
    if (weightage_row) {
      return_data.weightage = {} as COMarks;
      for (let key in weightage_row) {
        return_data.weightage[key] = weightage_row[key]
      }
    }
    bulk_upload_data.splice(index, 1);
    return_data.marks = [];
    for (var i = 0; i < bulk_upload_data.length; i++) {
      var student = bulk_upload_data[i];
      if (student.roll_no == '-') {
        continue
      };
      return_data.marks[i] = {} as COStudentMarks;
      return_data.marks[i].roll_no = student.roll_no;
      return_data.marks[i].given_name = student.given_name;
      return_data.marks[i].middle_name = student.middle_name;
      return_data.marks[i].co_marks = {} as COMarks;
      return_data.marks[i].co_marks.co1 = student.co1; return_data.marks[i].co_marks.co2 = student.co2;
      return_data.marks[i].co_marks.co3 = student.co3; return_data.marks[i].co_marks.co4 = student.co4;
      return_data.marks[i].co_marks.co5 = student.co5; return_data.marks[i].co_marks.total = student.total;
    }
    return return_data;
  }

  validateBulkUploadData(bulk_upload_data: BulkUploadFormat[]) {
    let return_data: { valid: boolean, error: string } = { valid: true, error: "" }
    if (bulk_upload_data.filter(x => x.roll_no == '-').length == 0) {
      return_data.valid = false; return_data.error = "Cannot find weightage; Please reupload File with weightage"
      return return_data;
    };
    for (var i = 0; i < bulk_upload_data.length; i++) {
      let co_total = parseFloat(bulk_upload_data[i].co1 as string) + parseFloat(bulk_upload_data[i].co2 as string)
        + parseFloat(bulk_upload_data[i].co3 as string) + parseFloat(bulk_upload_data[i].co4 as string) + parseFloat(bulk_upload_data[i].co5 as string)
      if (bulk_upload_data[i].total != co_total) {
        return_data.error = `Total does not match CO Value on Row ${i + 1}`;
        break;
      }
    }
    return return_data;
  }

  async saveUpdateReport() {
    if (!this.report_data._id) {
      this.report_data.offered_by_programme_type_id = this.component_search_form.value.programme_type_id;
      this.report_data.offering_programme_id = this.component_search_form.value.offering_programme_id;
      this.report_data.enrolled_year = this.component_search_form.value.enrolled_year;
      this.report_data.semester = this.component_search_form.value.semester;
      this.report_data.course_code = this.component_search_form.value.course_code;
      this.report_data.staff_map_id = this.component_search_form.value.staff_map_id;
      this.report_data.part = this.component_search_form.value.part;
      this.report_data.course_type = this.component_search_form.value.course_type;
      if (this.course_data.virtual_section) {
        this.report_data.virtual_section = this.course_data.virtual_section;
      }
      else {
        this.report_data.class_taking_programme_id = this.course_data.class_taking_programme_id;
        this.report_data.class_taking_section = this.course_data.class_taking_section;
      }
    }
    const response = await this.restService.updateCAGPReport(this.report_data);
    if (response.success && response.data) {
      this.getReportData();
    }

  }
}

interface BulkUploadFormat {
  roll_no: string,
  given_name?: string,
  middle_name?: string,
  co1: number | string,
  co2: number | string,
  co3: number | string,
  co4: number | string,
  co5: number | string,
  total: number
}

interface StudentMarksData {
  weightage: COMarks,
  marks: COStudentMarks[],
  reference_id?: string,
}

interface StudentGPSData {
  weightage: COMarks, //Row 4 - P4 to T4
  marks: COGPSMarks[],
  reference_id?: string,
}
interface COGPSMarks {
  roll_no: string,
  given_name?: string,
  middle_name?: string,
  co_marks: COMarks,
  gps_marks: COMarks
}

interface COMarks {
  co1: number | string,
  co2: number | string,
  co3: number | string,
  co4: number | string,
  co5: number | string,
  total: number | string
}

interface COStudentMarks {
  roll_no: string,
  given_name?: string,
  middle_name?: string,
  co_marks: COMarks
}



interface CAGPReport {
  _id?: string,
  id?: string,

  //user input
  offered_by_programme_type_id: string,
  offering_programme_id: string,
  enrolled_year: number,
  semester: number,
  course_code: string,
  staff_map_id: string,

  //course data
  class_taking_finance_type_id: string,
  class_taking_programme_id?: string,
  class_taking_section?: string,
  virtual_section?: string,
  part: string,
  course_type: string,

  //marks

  internal_marks: StudentMarksData,
  external_marks: StudentMarksData,




  //final report
  total_marks: StudentGPSData,
  class_average: COMarks,
  cagp_marks: COMarks

}
