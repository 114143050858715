<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Students Count Report</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Students Count Report</h4>
              <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
            </div>
            <p class="tx-12 tx-gray-500 mb-2">View Students Count Report</p>
          </div>

          <div class="card-body" *ngIf="report_search_form">
            <form [formGroup]="report_search_form">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                      Type</label>
                    <select formControlName="programme_type_id" class="form-control"
                      (change)="onFilterSelectChange('programme_type_id',$event)">
                      <option value="">Select Programme Type</option>
                      <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="category.programme_type_id">{{category.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                      Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Programme</label>
                    <!-- <kendo-multiselect [data]="programme_filter_list" valueField="programme_id"
                      textField="programme_display_name" formControlName="programme_ids"
                      placeholder="Select Programme(s)" class="menu_select">
                    </kendo-multiselect> -->
                    <ng-multiselect-dropdown [placeholder]="'Select Programme(s)'"
                      [settings]="programmeDropdownSettings" [data]="programme_filter_list"
                      formControlName="programme_ids">
                    </ng-multiselect-dropdown>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                      years</label>
                    <!-- <kendo-multiselect [data]="commonEnums.getLastNYears(20)" formControlName="enrolled_years"
                      placeholder="Select Enrolment Year(s)" class="menu_select"></kendo-multiselect> -->
                    <ng-multiselect-dropdown [placeholder]="'Select Enrolment Year(s)'" [settings]="dropdownSettings"
                      [data]="commonEnums.getLastNYears(20)" formControlName="enrolled_years">
                    </ng-multiselect-dropdown>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Gender</label>
                    <select formControlName="gender" class="form-control">
                      <option value="">Select Gender</option>
                      <option *ngFor="let gender of commonEnums.genders;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="gender.id">{{gender.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Community</label>
                    <!-- <kendo-multiselect [data]="commonEnums.community_list" formControlName="community_ids"
                      placeholder="Select Community Name(s)" class="menu_select"></kendo-multiselect> -->
                    <ng-multiselect-dropdown [placeholder]="'Select Community Name(s)'" [settings]="dropdownSettings"
                      [data]="commonEnums.community_list" formControlName="community_ids">
                    </ng-multiselect-dropdown>
                  </div>
                </div>


                <div class="col-lg-2">
                  <div class="form-group">
                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!report_list_loading" (click)="getReport()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="report_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>

                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
            <div class="d-flex right-content mg-b-5" style="float: right;">
              <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info" data-toggle="dropdown"
                  id="dropDownMenuButton" type="button">Download<i class="fas fa-caret-down ml-1"></i></button>
                <div class="dropdown-menu tx-13">
                  <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>

                    <th rowspan="3" style="width: 8%;">Class</th>
                    <ng-container *ngIf="students_count_report && students_count_report.length>0">
                      <ng-container
                        *ngFor="let enrolled_year of students_count_report[0].enrolled_year_list; let i=index;trackBy:fromDataResolver.identify;">
                        <th
                          [attr.colspan]="(students_count_report[0].enrolled_year_list[0].gender_list.length*students_count_report[0].enrolled_year_list[0].gender_list[0].community_list.length)+3"
                          style="width: 8%;text-align: center;">{{enrolled_year.enrolled_year}}</th>
                      </ng-container>

                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container *ngIf="students_count_report && students_count_report.length>0">
                      <ng-container
                        *ngFor="let enrolled_year of students_count_report[0].enrolled_year_list; let i=index;trackBy:fromDataResolver.identify;">

                        <ng-container
                          *ngFor="let enrolled_year of students_count_report[0].enrolled_year_list[0].gender_list; let i=index;trackBy:fromDataResolver.identify;">
                          <th
                            [attr.colspan]="(students_count_report[0].enrolled_year_list[0].gender_list[0].community_list.length)+1"
                            style="width: 8%;text-align: center;">{{enrolled_year.gender}}</th>
                          <th *ngIf="enrolled_year.gender == 'male'"></th>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </tr>

                  <tr>

                    <ng-container *ngIf="students_count_report && students_count_report.length>0">
                      <ng-container
                        *ngFor="let enrolled_year of students_count_report[0].enrolled_year_list; let i=index;trackBy:fromDataResolver.identify;">
                        <ng-container
                          *ngFor="let gender_list of students_count_report[0].enrolled_year_list[0].gender_list; let i=index;trackBy:fromDataResolver.identify;">
                          <ng-container
                            *ngFor="let community_list of students_count_report[0].enrolled_year_list[0].gender_list[0].community_list; let i=index;trackBy:fromDataResolver.identify;">
                            <th style="width: 8%;text-align: center;">{{community_list.community_id}}</th>
                          </ng-container>
                          <th>{{gender_list.gender ? (gender_list.gender == 'female'? 'F' : 'M') : '' }} Total</th>
                          <th *ngIf="gender_list.gender == 'male'">Grand Total</th>
                        </ng-container>
                      </ng-container>
                    </ng-container>


                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="students_count_report && students_count_report.length>0">
                    <ng-container
                      *ngFor="let student of students_count_report; let i=index;trackBy:fromDataResolver.identify;">
                      <tr>

                        <td>{{student.programme_name}} {{student.finance_type ==
                          'self_finance'?' (SF)': ' (R)'}}</td>
                        <ng-container
                          *ngFor="let enrolled_year of student.enrolled_year_list; let i=index;trackBy:fromDataResolver.identify;">
                          <ng-container
                            *ngFor="let gender_list of enrolled_year.gender_list; let i=index;trackBy:fromDataResolver.identify;">
                            <ng-container
                              *ngFor="let community_list of gender_list.community_list; let i=index;trackBy:fromDataResolver.identify;">
                              <td class="text-center" style="text-align: center;">{{community_list.count}}</td>
                            </ng-container>
                            <td><b>{{gender_list.count}}</b></td>
                            <td *ngIf="gender_list.gender == 'male'"><b><u>{{enrolled_year.count}}</u></b></td>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!report_list_loading && students_count_report?.length==0">
                    <tr>
                      <th colspan="5" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="report_list_loading && students_count_report?.length==0">
                    <tr>
                      <th colspan="5" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <!-- <ng-container *ngIf="total_records>100">
                                <ul class="pagination pull-right">
                                    <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                                                class="icon ion-ios-arrow-back"></i></a></li>

                                    <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                                                class="icon ion-ios-arrow-forward"></i></a></li>
                                </ul>
                            </ng-container> -->
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>