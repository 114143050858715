<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" (click)="onAddClick()" class="btn btn-info"> Add Setting </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Set Format for Terminal Examination Question Paper</p>
                    </div>
                    <div class="card-body">
                        <!-- <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Course
                                            Type</label>
                                        <select formControlName="course_type" class="form-control"
                                            (change)="onFilterSelectChange('course_type',$event)">
                                            <option value="">All Course Types</option>
                                            <option
                                                *ngFor="let course_type of course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="course_type.id">{{course_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!setting_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="setting_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form> -->
                        <br>



                        <div class="table-responsive">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th style="width: 10%;">S.No</th>
                                        <th>Question Format Name</th>
                                        <!-- <th>Programme Type</th>
                                        <th>Enrolment Year</th>
                                        <th>Semester</th>
                                        <th>Programme</th>
                                        <th>Part</th>
                                        <th>Course Type</th>
                                        <th>Course Code</th> -->
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of setting_data; let i = index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.format_name}}</td>
                                        <!-- <td>{{data.programme_type_id}}</td>
                                        <td>{{data.enrolled_year}}</td>
                                        <td>{{data.semester}}</td>
                                        <td>{{data.programme_name}}</td>
                                        <td>{{data.part}}</td>
                                        <td>{{data.course_type}}</td>
                                        <td>{{data.course_code}}</td> -->
                                        <td>
                                            <div class="pr-1 mb-3 mb-xl-0">
                                                <button type="button" (click)="onEditClick(data)"
                                                    class="btn btn-sm btn-info mr-2">
                                                    <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <ng-container *ngIf="setting_data.length==0 && setting_data_loading">
                                        <tr>
                                            <td colspan="3" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="setting_data.length==0 && !setting_data_loading">
                                        <tr>
                                            <td colspan="3" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <!--<h6 class="modal-title">Student-Course Assignment Form</h6>-->
                        <h6 class="modal-title">
                            <ng-container *ngIf="!questionFormatSettingForm.value._id">
                                Add
                            </ng-container>
                            <ng-container *ngIf="questionFormatSettingForm.value._id">
                                Update
                            </ng-container>
                            Question Format Setting
                        </h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="questionFormatSettingForm" class="form-horizontal">
                                <h6>Format's Settings</h6>
                                <!-- <div class="row row-sm" *ngIf="!questionFormatSettingForm.value._id">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control"
                                                (change)="onAddFilterSelectChange('programme_type_id',$event)">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrolment
                                                year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select Enrolment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme</label>
                                            <select formControlName="programme_id" class="form-control"
                                                (change)="onAddFilterSelectChange('programme_id',$event)">
                                                <option value="">All Programmes</option>
                                                <option
                                                    *ngFor="let programme of add_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                    {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Part</label>
                                            <select formControlName="part" class="form-control"
                                                (change)="onAddFilterSelectChange('part',$event)">
                                                <option value="">All Parts</option>
                                                <option
                                                    *ngFor="let part of this.add_parts;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="part.id">
                                                    {{part.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Course
                                                Type</label>
                                            <select formControlName="course_type" class="form-control"
                                                (change)="onFilterSelectChange('course_type',$event)">
                                                <option value="">All Course Types</option>
                                                <option
                                                    *ngFor="let course_type of add_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="course_type.id">{{course_type.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                                Code</label>
                                            <input type="text" class="form-control" formControlName="course_code"
                                                placeholder="Search with Course Code.">
                                        </div>
                                    </div>
                                </div>

                                <div class="row row-sm mg-b-10" *ngIf="questionFormatSettingForm.value._id">
                                    <div class="col-md-4">
                                        Programme Type: <b>{{questionFormatSettingForm.value.programme_type_id}}</b>
                                    </div>
                                    <div class="col-md-4">
                                        Enrolled Year: <b>{{questionFormatSettingForm.value.enrolled_year}}</b>
                                    </div>
                                    <div class="col-md-4">
                                        Semester: <b>{{questionFormatSettingForm.value.semester}}</b>
                                    </div>
                                </div>

                                <div class="row row-sm mg-b-10" *ngIf="questionFormatSettingForm.value._id">
                                    <div class="col-md-4">
                                        Programme:
                                        <b>{{questionFormatSettingForm.value.programme_id?programme_name:'---'}}</b>
                                    </div>
                                    <div class="col-md-4">
                                        Part:
                                        <b>{{questionFormatSettingForm.value.part?questionFormatSettingForm.value.part:'---'}}</b>
                                    </div>
                                    <div class="col-md-4">
                                        Course Type:
                                        <b>{{questionFormatSettingForm.value.course_type?questionFormatSettingForm.value.course_type:'---'}}</b>
                                    </div>
                                </div>

                                <div class="row row-sm mg-b-10" *ngIf="questionFormatSettingForm.value._id">
                                    <div class="col-md-4">
                                        Course Code:
                                        <b>{{questionFormatSettingForm.value.course_code?questionFormatSettingForm.value.course_code:'---'}}</b>
                                    </div>
                                </div> -->

                                <div class="row row-sm">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Question
                                                Format Name</label>
                                            <input type="text" class="form-control" formControlName="format_name"
                                                placeholder="Enter a unique name for Question Format Setting">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Question
                                                Format Name</label>
                                            <select formControlName="question_format_type" class="form-control"
                                                (change)="onQuestionFormatChange($event)">
                                                <option value="">All Question Format Types</option>
                                                <option
                                                    *ngFor="let question_format_type of question_format_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="question_format_type.id">{{question_format_type.text}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="questionFormatSettingForm.value.question_format_type =='Section-Wise'">
                                    <h6>Section Settings</h6>
                                    <ng-container formArrayName="exam_question_format">
                                        <ng-container *ngFor="let section of sectionList; let i=index;"
                                            [formGroupName]="i">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <b>Section {{i+1}}</b>
                                                </div>
                                                <div class="col-md-2">
                                                    <button class="btn btn-danger"
                                                        (click)="deleteSection(i)">Delete</button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                            Section
                                                            Name</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="section_name"
                                                            placeholder="Enter Section Name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                            No.
                                                            of questions in this section</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="no_of_questions"
                                                            (change)="checkMaxQuestions($event,i,'NO_QUESTIONS')"
                                                            placeholder="Enter No. of Questions in this section">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                            Mark
                                                            for each question</label>
                                                        <input type="text" class="form-control"
                                                            formControlName="question_max_mark"
                                                            placeholder="Enter Mark for each question in this section">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                            Question Type</label>
                                                        <select formControlName="question_type" class="form-control">
                                                            <option value="">Question Type</option>
                                                            <option
                                                                *ngFor="let type of this.question_types;let i=index;trackBy:fromDataResolver.identify;"
                                                                [value]="type.id">
                                                                {{type.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                            No.
                                                            of question to be answered</label>
                                                        <input type="text" class="form-control"
                                                            (change)="checkMaxQuestions($event,i, 'ANSWER_QUESTIONS')"
                                                            formControlName="no_of_questions_to_answered"
                                                            placeholder="Enter No. of question to be answered">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <ng-container formArrayName="co_conditions">
                                                                                <h6>CO Level Settings</h6>
                                                                                <div class="table-responsive">
                                                                                    <table class="table table-bordered">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>CO Level</th>
                                                                                                <th>No. of questions</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr *ngFor="let settings of getCoSettings(i); let j=index;"
                                                                                                [formGroupName]="j">
                                                                                                <td class="text-center" style="width: 10%;">
                                                                                                    {{settings.value.co_id}}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input type="text" class="form-control"
                                                                                                        formControlName="no_of_questions"
                                                                                                        placeholder="Enter No. of question to be asked in this CO">
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </ng-container> -->
                                        </ng-container>
                                    </ng-container>

                                    <div class="mg-b-20" style="text-align: center;">
                                        <button class="btn btn-primary" (click)="addSection()"
                                            title="Add New Question">Add
                                            Section</button>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="questionFormatSettingForm.value.question_format_type =='Co-Wise'">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                    Required CO Levels</label>
                                                <kendo-multiselect [data]="co_ids" valueField="id" textField="text"
                                                    formControlName="allowed_co_ids" placeholder="Select CO Level(s)">
                                                </kendo-multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>


                                <div class="add-btn-row" style="text-align: center;">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">
                                        <ng-container
                                            *ngIf="!this.questionFormatSettingForm.value._id && !this.setting_save_loading">
                                            Save
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!this.questionFormatSettingForm.value._id && this.setting_save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.questionFormatSettingForm.value._id && !this.setting_save_loading">
                                            Update
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.questionFormatSettingForm.value._id && this.setting_save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">
                                        <ng-container
                                            *ngIf="!this.questionFormatSettingForm.value._id && !this.setting_save_loading">
                                            Save & Close
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!this.questionFormatSettingForm.value._id && this.setting_save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.questionFormatSettingForm.value._id && !this.setting_save_loading">
                                            Update & Close
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.questionFormatSettingForm.value._id && this.setting_save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <ng-container *ngIf="this.questionFormatSettingForm.value._id">
                                        <button class="btn btn-danger mg-r-20"
                                            (click)="onDelete(this.questionFormatSettingForm.value._id)"
                                            *ngIf="!setting_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled
                                            *ngIf="setting_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </ng-container>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>