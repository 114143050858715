import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-programme-fee-setting',
  templateUrl: './programme-fee-setting.component.html',
  styleUrls: ['./programme-fee-setting.component.scss']
})
export class ProgrammeFeeSettingComponent implements OnInit {
  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  cookieStore = CookieStore;

  // Master data
  programme_type_list: any[] = [];
  programme_type_list_loading = false;


  programme_fee_search_form: any;
  admission_name_list: any[] = [];

  programme_fee_data: any = undefined;
  programme_fee_data_loading = false;


  programme_list: any[] = [];
  programme_fee_settings_loading: any;
  programme_list_loading = false;


  programme_fee_settings_form: any;

  programme_fee_settings_save_loading = false;

  programme_fee_header_id: string = '';
  enrolled_year: number = 0;
  programme_type_id: string = '';

  fine_list_loading: boolean = false;
  fine_list: FineRuleInterface[] = [];
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(params => {
      this.programme_fee_header_id = params.setting_id;
      this.programme_type_id = params.programme_type_id;
      this.enrolled_year = parseInt(params.enrolled_year);
    });
  }

  ngOnInit(): void {
    this.getMasterDatas();
    this.prepareAdmissionSearchForm();
    this.getFineList();
  }

  async getFineList() //get from API
  {
    try {
      this.fine_list_loading = true;
      this.fine_list = [];
      const service_response = await this.restService.getFineList();
      if (service_response && service_response.success) {
        this.fine_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.fine_list_loading = false;
    }

  }

  getProgrammeName(programme_id) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  //#region Master Operations

  async getMasterDatas(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  //#endregion


  //#region Core Operations
  prepareAdmissionSearchForm(): void {
    this.programme_fee_search_form = this.fb.group({
      regulation_year: [this.enrolled_year],
      admission_id: [''],
      finance_type: [''],
      programme_id: [''],
      semester: [''],
      term: [''],
      fee_target_type: ['']
    });
    this.onRegulationYearChange();
  }
  async onRegulationYearChange(): Promise<void> {
    try {
      const query = {
        year: this.enrolled_year
      };
      this.admission_name_list = [];
      this.admissionNameChanged();
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {
        this.admission_name_list = service_response.data;
        var admission_id = this.admission_name_list.find(x => x.programme_type_id == this.programme_type_id)?._id;
        this.programme_fee_search_form.get('admission_id').setValue(admission_id);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
    }
  }
  admissionNameChanged(ev?) {
    this.programme_fee_search_form.get('finance_type').setValue('');
    this.programme_fee_search_form.get('programme_id').setValue('');
  }
  async onFinanceTypeChange(): Promise<void> {
    try {

      const query: any = {
        finance_type: this.programme_fee_search_form.value.finance_type,
        programme_type_id: this.admission_name_list.find(x => x._id == this.programme_fee_search_form.value.admission_id)?.programme_type_id
      };
      this.programme_fee_search_form.get('programme_id').setValue('');
      this.programme_list_loading = true;
      this.programme_list = [];
      const response = await this.restService.searchProgrammesLite(query);
      if (response.success && response.data && response.data.length > 0) {
        this.programme_list = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }
  async onSearchClick(): Promise<void> {

    try {
      if (!this.programme_fee_search_form.value.regulation_year) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_fee_search_form.value.admission_id) {
        alert('Please select admission name');
        return;
      }
      if (!this.programme_fee_search_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_fee_search_form.value.semester) {
        alert('Please select semester');
        return;
      }
      if (!this.programme_fee_search_form.value.term) {
        alert('Please select term');
        return;
      }
      if (!this.programme_fee_search_form.value.fee_target_type) {
        alert('Please select fee_target_type');
        return;
      }
      this.programme_fee_data_loading = true;

      const query: any = {
        programme_id: this.programme_fee_search_form.value.programme_id,
        regulation_year: this.programme_fee_search_form.value.regulation_year,
        semester: this.programme_fee_search_form.value.semester,
        term: this.programme_fee_search_form.value.term,
        fee_target_type: this.programme_fee_search_form.value.fee_target_type,
        admission_id: this.programme_fee_search_form.value.admission_id
      };
      const response = await this.restService.getSavedProgrammeFeeSettings(query);
      console.log(response.data);
      if (response.success && response.data) {
        //this.buildProgrammeSplitSettingsForm(this.programme_fee_search_form.value.programme_id, response.data);
        this.programme_fee_data = response.data
      }

    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_fee_data_loading = false;
    }


  }

  onEditClick() {
    this.buildProgrammeSplitSettingsForm(this.programme_fee_data.programme_id, this.programme_fee_data);
    JQueryHelper.openModal('#modal-programme-fee', { keyboard: false, backdrop: 'static' });
  }

  buildProgrammeSplitSettingsForm(programme_id: string, data: any): void {
    this.programme_fee_settings_form = this.fb.group({
      programme_id: [programme_id],
      mode: [data.mode || ''],
      fine_settings_id: [((data.fine_settings_id) ? data.fine_settings_id : '')],
      accounts: this.fb.array(this.buildProgrammeSplitItemForm(data.account_splits))
    });
  }

  buildProgrammeSplitItemForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return: any[] = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        sub_acc_id: [data_item.sub_acc_id],
        account_no: [data_item.account_no],
        amount: [data_item.amount],
      }));
    }
    return value_to_return;
  }

  getTotalFees(account_splits: any[]) {
    var total = 0;
    for (var i = 0; i < account_splits.length; i++) {
      total += account_splits[i]?.amount;
    }
    return total;
  }

  getTotalAmount(): number {
    const programme_fee_form_data = this.programme_fee_settings_form.value;
    const account_data_list = programme_fee_form_data.accounts;
    let total_amount = 0;
    let has_error = false;
    for (const account_data of account_data_list) {


      try {
        account_data.amount = parseFloat(account_data.amount);
      } catch (error) {
        alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
        has_error = true;
        break;
      }

      if (account_data.application_fee_amount < 0) {
        alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
        has_error = true;
        break;
      }

      total_amount = total_amount + account_data.amount;

    }
    if (has_error) {
      return 0;
    }
    return total_amount;
  }
  async onSaveProgrammeFormClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.programme_fee_settings_save_loading = true;
      const programme_fee_form_data = this.programme_fee_settings_form.value;
      const account_data_list = programme_fee_form_data.accounts;
      let has_error = false;
      let total_amount = 0;
      for (const account_data of account_data_list) {
        try {
          account_data.amount = parseFloat(account_data.amount);
        } catch (error) {
          alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
          has_error = true;
          break;
        }
        if (account_data.application_fee_amount < 0) {
          alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
          has_error = true;
          break;
        }
        total_amount = total_amount + account_data.amount;
      }
      if (has_error) {
        return;
      }

      var admission = this.admission_name_list.find(x => x._id == this.programme_fee_search_form.value.admission_id);
      const data_to_save: any = {
        programme_fee_header_id: this.programme_fee_header_id,
        admission_id: admission?._id,
        regulation_year: admission?.year,
        programme_id: this.programme_fee_search_form.value.programme_id,
        start_date: admission?.start_date,
        end_date: admission?.end_date,
        semester: this.programme_fee_search_form.value.semester,
        term: this.programme_fee_search_form.value.term,
        fee_target_type: this.programme_fee_search_form.value.fee_target_type,
        mode: this.programme_fee_settings_form.value.mode,
        amount: total_amount,
        fine_settings_id: this.programme_fee_settings_form.value.fine_settings_id,
        account_splits: account_data_list
      };
      console.log(data_to_save);
      const saved_respose = await this.restService.createProgrammeFeeBulk(data_to_save);
      if (saved_respose.success) {
        alert('Saved Successfully');
        this.onSearchClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_fee_settings_save_loading = false;
    }
  }

  resetProgrammeFeeForm(): void {
    this.programme_fee_settings_form = undefined;
  }
  async onProgrammeFeeFormCloseClick(): Promise<void> {
    this.resetProgrammeFeeForm();
    JQueryHelper.closeModal('#modal-programme-fee');
  }


}

//#region Programme Form
/* async onConfigureProgrammeFeeClick(admission: any): Promise<void> {
  this.admission = admission;
  this.buildProgrammeFeeForm(admission);
  JQueryHelper.openModal('#modal-programme-fee', { keyboard: false, backdrop: 'static' });
} */




/* buildProgrammeFeeForm(admission_data: any): void {
  this.programme_fee_patch_form = this.fb.group({
    admission_id: [admission_data._id],
    admission_name: [admission_data.name],
    programme_type_id: [admission_data.programme_type_id],
    regulation_year: [admission_data.year],
    finance_type: [''],
    programme_id: [''],
    semester: [''],
    term: [''],
    fee_target_type: ['']
  });
} */

/* async onPrgFinanceTypeChange(): Promise<void> {
  try {

    const query: any = {
      finance_type: this.programme_fee_patch_form.value.finance_type,
      programme_type_id: this.programme_fee_patch_form.value.programme_type_id
    };
    this.programme_fee_patch_form.get('programme_id').setValue('');
    this.programme_list_loading = true;
    this.programme_list = [];
    const response = await this.restService.searchProgrammesLite(query);
    if (response.success && response.data && response.data.length > 0) {
      this.programme_list = response.data;
    }

  } catch (error) {
    alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
  } finally {
    this.programme_list_loading = false;
  }
} */

interface FineRuleInterface {
  _id: string,
  fine_name: string,
  re_admission_fee: number,
  fine_rules: {
    day_limit: number,
    fine_amount: number,
    is_per_day: boolean
  }[],
  post_fine_rule: string
}







  //#endregion

