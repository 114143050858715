<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Elective Polling Report Course-wise & Class-wise</p>
                    </div>
                    <div class="card-body">
                        <h6>Search By</h6>
                        <div class="row ">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="course_wise_report" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Course-wise
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="programme_wise_report" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Programme-wise
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester, if applicable</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='course_wise_report'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_wise_report'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_wise_report'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="student_programme_id" class="form-control"
                                            (change)="onFilterSelectChange('student_programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="filter_form.value.student_programme_id!='' && sections.length>1 && type=='programme_wise_report'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="student_programme_section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Student
                                            Stage</label>
                                        <select formControlName="filter_stage" class="form-control">
                                            <option value="">Select Student Type</option>
                                            <ng-container *ngIf="type=='course_wise_report'">
                                                <option
                                                    *ngFor="let stage of course_stages;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="stage.id">{{stage.text}}</option>
                                            </ng-container>
                                            <ng-container *ngIf="type=='programme_wise_report'">
                                                <option
                                                    *ngFor="let stage of programme_stages;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="stage.id">{{stage.text}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                        <br>

                        <div class="d-flex right-content mg-b-5" style="float: right;" *ngIf="table_data.length>0">
                            <button class="btn btn-info btn-md mg-r-10" (click)="downloadFile('CSV')">Download
                                CSV</button>
                            <button class="btn btn-info btn-md" (click)="printPDF()">Print Report</button>
                        </div>

                        <div id="print-area">
                            <div class="row" style="margin-left:50px" *ngIf="table_data.length>0">
                                <div class="col-md-2">
                                    <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                                        alt="college logo"
                                        style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                                </div>
                                <div class="col-md-8 text-center">
                                    <h4 style=" margin-bottom: 0px !important;">AYYA NADAR JANAKI AMMAL COLLEGE
                                        (Autonomous)</h4>
                                    <h4>Sivakasi - 626 124.</h4>
                                    <h5>{{table_data[0].course_type.split('_').join(' ')}} COURSE REPORT</h5>
                                </div>
                            </div>
                            <div class="row" *ngIf="type == 'course_wise_report' && table_data.length>0"
                                style="margin-top: 10px;">
                                <div class="col-md-12 text-center">
                                    <h4>Course Code: <b>{{this.filter_form.value.course_code}}</b></h4>
                                </div>
                            </div>
                            <div class="row" *ngIf="type == 'programme_wise_report' && table_data.length>0"
                                style="margin-top: 10px;">
                                <div class="col-md-12 text-center">
                                    <h4>Class: <b>{{getProgrammeName(this.filter_form.value.student_programme_id)}}
                                            '{{this.filter_form.value.student_programme_section}}'</b></h4>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered ">
                                    <thead>
                                        <tr style="text-align: center;">
                                            <th class="table-head">S.No</th>
                                            <th class="table-head">Roll No</th>
                                            <th class="table-head">Name</th>
                                            <th *ngIf="this.type=='programme_wise_report'" class="table-head">
                                                Course Code</th>
                                            <th *ngIf="this.type=='programme_wise_report'" class="table-head">
                                                Course Name</th>
                                            <th *ngIf="this.type == 'course_wise_report'" class="table-head">
                                                Class Name</th>
                                            <th *ngIf="this.type == 'course_wise_report'" class="table-head">Section
                                            </th>
                                            <th *ngIf="table_data.length > 0 && this.type=='programme_wise_report' && filter_form.value.filter_stage == 'ALL'"
                                                class="table-head">
                                                Status</th>
                                            <th style="width: 20%;" class="table-head">Signature</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let data of table_data; let i = index;trackBy:fromDataResolver.identify;">
                                            <td class="table-cell">{{i+1}}</td>
                                            <td class="table-cell">{{data.roll_no}}</td>
                                            <td class="table-cell">{{data.student.given_name}}
                                                {{data.student.middle_name}}</td>
                                            <td *ngIf="this.type=='programme_wise_report'" class="table-cell">
                                                {{data.course_code}}</td>
                                            <td *ngIf="this.type=='programme_wise_report'" class="table-cell">
                                                {{data.course_name}}</td>
                                            <td *ngIf="this.type == 'course_wise_report'" class="table-cell">
                                                {{data.student_programme_name}}
                                            </td>
                                            <td *ngIf="this.type == 'course_wise_report'" class="table-cell">
                                                {{data.student_programme_section}}
                                            </td>
                                            <td *ngIf="this.type=='programme_wise_report' && filter_form.value.filter_stage == 'ALL'"
                                                class="table-cell">
                                                <span *ngIf="data.course_code == '-' ">
                                                    Not Requested
                                                </span>
                                                <span *ngIf="data.course_code != '-' ">
                                                    {{data.approved ? 'Approved' : 'Requested'}}
                                                </span>
                                            </td>
                                            <td></td>

                                        </tr>
                                        <ng-container *ngIf="table_data.length==0 && table_data_loading">
                                            <tr>
                                                <td colspan="6" class="text-center"><b>Loading...</b></td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="table_data.length==0 && !table_data_loading">
                                            <tr>
                                                <td colspan="6" class="text-center"><b>No Records found.</b></td>
                                            </tr>
                                        </ng-container>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>



<!--Unused filters-->
<!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->







<!-- <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Position</label>
                                        <select formControlName="course_position" class="form-control">
                                            <option value="">Select Course Position</option>
                                            <option
                                                *ngFor="let post of this.course_positions;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="post">
                                                {{post}}</option>
                                        </select>
                                    </div>
                                </div> -->