import { Component, OnInit } from '@angular/core';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/components/notification/notification.service';
import { NavigationService } from './../../../components/navigation/navigation.service';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { isString } from '@progress/kendo-angular-grid/dist/es2015/utils';

@Component({
  selector: 'app-student-attendance',
  templateUrl: './student-attendance.component.html',
  styleUrls: ['./student-attendance.component.scss']
})
export class StudentAttendanceComponent implements OnInit {

  fonts = fonts;
  max_hour: number = 10;
  commonEnums = CommonEnums;
  current_week: string = "";
 
  timetable_loading: boolean = false;
  report_search_form: FormGroup;
  timetable: DaywiseOrderInterface[] = [];
  searchData: {
    staff_data: string,
    course_data: string,
    student_data: string
  } = { staff_data: "", course_data: "", student_data: "" };
  title = 'Student Time Table';
  attendance_table: StudentDayWiseInterface[] = [];
  id = '';

  week_string: string = "";
  week_no: number = 1;
  start_date: Date = new Date();
  end_date: Date = new Date();
  chosen_day: number = 0;
  attendance_report: StudentWeekWiseInterface[] = [];
  report_data_loading: boolean = false;
  fromDataResolver = FromDataResolver;
  public format = {
    displayFormat: "MM/yyyy",
    inputFormat: "MM/yyyy",
  };

  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.ISO8601_week_no(new Date());
    this.report_search_form = this.formBuilder.group({
      start_date: [""],
      end_date: [""],
    });
  }

  ngOnInit(): void {
  }

  dateChanged(ev: any) {
    console.log(ev);
    this.ISO8601_week_no(ev);
  }

  isCurrentDate(date: Date) {
    //console.log(date);
    if (date == new Date()) {
      return true;
    }
    return false;
  }
  isDateInWeekRange(date: Date) {
    if (date >= this.start_date && date <= this.end_date) {
      return "week-range";
    }
    return "non-week";
  }

  async ISO8601_week_no(tdt: any) {
    //var tdt:any = new Date();
    var dayn = (tdt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    this.week_no = 1 + Math.ceil((firstThursday - tdt) / 604800000);
    this.week_string = tdt.getFullYear() + "-W" + this.week_no;
    this.start_date = new Date(this.getFirstMondayOfWeek(this.week_no));
    this.end_date = new Date(this.start_date);
    this.end_date.setDate(this.start_date.getDate() + 6);
    console.log(this.start_date, this.end_date);
    try {
      this.timetable_loading = true;
      var date_range_data = {
        start_date: this.start_date,
        end_date: this.end_date
      };
      console.log(date_range_data);
      const response = await this.restService.getStudentTimetable(date_range_data);
      if (response && response.success) {
        console.log(response.data);
        this.timetable = response.data.day_wise_order;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.timetable_loading = false;
    }
  }

  getFirstMondayOfWeek(weekNo: number): any {
    var firstMonday = new Date(new Date().getFullYear(), 0, 4, 0, 0, 0, 0);

    while (firstMonday.getDay() != 1) {
      firstMonday.setDate(firstMonday.getDate() - 1);
    }
    if (1 <= weekNo && weekNo <= 52)
      return firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));

    firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));
    if (weekNo == 53 && firstMonday.getDate() >= 22 && firstMonday.getDate() <= 28)
      return firstMonday;
    return null;
  }

  


 


  

  async getAttendanceReport() {
    try {
      this.report_data_loading = true;
      if (!this.report_search_form.value.start_date) {
        alert("Please choose Start Date"); return;
      }
      if (!this.report_search_form.value.end_date) {
        alert("Please choose End Date"); return;
      }
      const response = await this.restService.getStudentAttendanceReport(this.report_search_form.value);
      if (response && response.success) {
        console.log(response.data);
        this.attendance_report = response.data[0].date_wise;
        console.log(this.attendance_report);
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.report_data_loading = false;
    }

  }

  viewAttendance(student_data: StudentWeekWiseInterface) {
    this.attendance_table = student_data.hour_wise;
  }

  getReportDisplayDate(date: string) {
    var date_obj = new Date(date);
    return date_obj.getDate() + "/" + (date_obj.getMonth() + 1);
  }
  

}


interface StudentTimetableInterface {
  roll_no: string,
  enrolled_year: number,
  programme_id: string,
  section_id: string,
  given_name: string,
  middle_name: string,
  finance_type: string,
  programme_type_id: string,
  programme_name: string,
  section_name: string,
  semester: number,
  programme_map_id: string,
  day_wise_order: DaywiseOrderInterface[]
}


interface DaywiseOrderInterface {
  day: string,
  date: string,
  hours: HoursInterface[],
  no_of_hours: number,
  no_of_hours_present: number,
  no_of_hours_absent: number,

}
interface HoursInterface {
  hour_number: number,
  start_time: string,
  end_time: string,
  duration: number,
  is_break: false,
  course_code: string,
  course_name: string,
  part: string,
  staff_id: string,
  staff_name: string,
  room_no: string,
  marked_by_id: string,
  marked_by_name: string,
  is_present: boolean,
  leave_applied: boolean,
  od_given: boolean,
  is_marked: boolean
}


interface StudentAttendanceReportInterface {
  given_name: string,
  middle_name: string,
  no_days_absent: number,
  no_days_present: number,
  roll_no: string,
  total_working_days: number
  date_wise: StudentWeekWiseInterface[]
}

interface StudentWeekWiseInterface {
  day_date: string,
  day_order_name: string,
  no_of_hours: number,
  no_of_hours_absent: number,
  no_of_hours_present: number,
  hour_wise: StudentDayWiseInterface[]
}

interface StudentDayWiseInterface {
  course_code: string,
  course_name: string,
  hour_number: number,
  is_marked: boolean,
  is_present: boolean,
  leave_applied: boolean,
  marked_by_id: string,
  marked_by_name: string,
  od_given: boolean
}




