import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../components/navigation/navigation.service';
import { NotificationService } from '../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-student-extra-exit-survey',
  templateUrl: './student-extra-exit-survey.component.html',
  styleUrls: ['./student-extra-exit-survey.component.scss']
})
export class StudentExtraExitSurveyComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Student Extra Curricular/Co-Curricular Exit Survey';
  extra_survey_list_loading: boolean = false;
  extra_survey_list: CourseExitSurveyInterface[] = [];
  programme: CourseExitSurveyInterface = {} as CourseExitSurveyInterface;
  save_loading: boolean = false;
  programme_list: any[] = [];
  remarks: string = '';
  programme_list_loading: boolean = false;
  response_point_map: {
    response: string, point: number
  }[] = [
      { response: 'STRONGLY_AGREE', point: 4 }, { response: 'AGREE', point: 3 },
      { response: 'NEUTRAL', point: 2 }, { response: 'DISAGREE', point: 1 }
    ]
  ngOnInit(): void {
    this.getCourseList();
    this.getProgrammeDataList();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
  }

  async getCourseList(): Promise<void> {
    try {
      this.extra_survey_list_loading = true;
      this.extra_survey_list = [];
      const survey_data = await this.restService.getExtraCESForStudent();
      console.log(survey_data);
      if (survey_data.success) {
        this.extra_survey_list = survey_data.data;
        this.extra_survey_list.forEach(course => {
          course.survey_questions.forEach(question => {
            if (course.student_response[0]?.survey_responses.length == 0 || course.student_response.length == 0) {
              question.response = "";
            }
            else {
              question.response = course.student_response[0].survey_responses.find(x => x.pso_id == question.pso_id)?.response
            }
          })
        });
        console.log(this.extra_survey_list);
        JQueryHelper.openModal('#survey-modal-popup', { keyboard: false, backdrop: 'static' });
      }
      //replace with API response
      /* this.course_list.push({
        _id: "1233",
        roll_no: "21PA23",
        programme_type_id: "UG",
        enrolled_year: 2020,
        course_code: "20UAC413",
        course_name: "Database Technology",
        programme_id: "afb68dd7-29c6-4a7c-a0d8-feeae4811372",
        section: "A",
        survey_questions: [
          {
            co_level: 1, question: "Question 1", response_options: ['STRONGLY_AGREE', 'AGREE', 'NEUTRAL', 'DISAGREE']
          },
          {
            co_level: 2, question: "Question 2", response_options: ['STRONGLY_AGREE', 'AGREE', 'NEUTRAL', 'DISAGREE']
          },
          {
            co_level: 3, question: "Question 3", response_options: ['STRONGLY_AGREE', 'AGREE', 'NEUTRAL', 'DISAGREE']
          },
          {
            co_level: 4, question: "Question 4", response_options: ['STRONGLY_AGREE', 'AGREE', 'NEUTRAL', 'DISAGREE']
          },
          {
            co_level: 5, question: "Question 5", response_options: ['STRONGLY_AGREE', 'AGREE', 'NEUTRAL', 'DISAGREE']
          }
        ],
        is_active: false,
        start_date: "2022-06-10T00:00",
        end_date: "2022-06-16T00:00",
        student_response: [{
          roll_no: "21PA23",
          survey_responses: [{
            co_level: 1,
            question: "Question 1",
            response: "STRONGLY_AGREE",
            point: 4
          },
          {
            co_level: 2,
            question: "Question 2",
            response: "AGREE",
            point: 3
          },
          {
            co_level: 3,
            question: "Question 3",
            response: "NEUTRAL",
            point: 2
          },
          {
            co_level: 4,
            question: "Question 4",
            response: "STRONGLY_AGREE",
            point: 4
          },
          {
            co_level: 5,
            question: "Question 5",
            response: "STRONGLY_AGREE",
            point: 4
          },
          ]
        }]
      },
      ); */
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.extra_survey_list_loading = false;
    }
  }



  answerSurvey(course: CourseExitSurveyInterface) {
    this.programme = course;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onSave(): void {
    this.saveSurveyResponse();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.programme = {} as CourseExitSurveyInterface;
    this.remarks = '';
    JQueryHelper.closeModal('#modal-popup');
  }



  async saveSurveyResponse(): Promise<void> {
    var survey_responses: any[] = [];
    this.programme.survey_questions.forEach(question => {
      question.point = this.response_point_map.find(x => x.response == question.response).point;
      survey_responses.push({
        pso_id: question.pso_id,
        question: question.question,
        response: question.response,
        point: question.point
      });
    });
    const save_obj = {
      _id: this.programme._id,
      student_response: [{
        roll_no: this.programme.student_response[0].roll_no,
        remarks: this.remarks,
        survey_responses: survey_responses
      }]
    }
    console.log(save_obj);
    try {
      this.save_loading = true;
      const res = await this.restService.updateStudentResponseExtraCES(this.programme._id, save_obj);
      if (res) {
        alert("Survey response saved successfully.");
      }
      this.getCourseList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }


  canDisable(): boolean {
    var can_disable: boolean = false;
    for (var i = 0; i < this.programme.survey_questions.length; i++) {
      if (this.programme.survey_questions[i].response == '') {
        can_disable = true;
      }
    }
    return can_disable;
  }

  reset() {
    this.programme.survey_questions.forEach(question => {
      question.response = "";
    });
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  getClassName(enrolled_year: number, programme_id?: string, section?: string, virtual_section?: string) {
    if (virtual_section) {
      return "Virtual Section " + virtual_section;
    }
    else {
      var programme_name = this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
      return this.commonEnums.getClassName(enrolled_year, programme_name, section);
    }
  }
}


interface CourseExitSurveyInterface {
  _id?: string,
  roll_no: string,
  programme_type_id: string,
  enrolled_year: number,

  is_active: boolean,
  start_date: string,
  end_date: string,
  survey_questions: CourseExitSurveyQuestionInterface[],
  student_response?: CourseExitSurveyResponseInterface[]
}

interface CourseExitSurveyQuestionInterface {
  pso_id: number,
  question: string,
  response_options: string[],
  response?: string,
  point?: number
}

interface CourseExitSurveyResponseInterface {
  _id?: string,
  roll_no: string,
  given_name?: string,
  middle_name?: string,
  response_date?: string,
  survey_responses: {
    pso_id: number,
    question: string,
    response: string,
    point: number
  }[]
}