import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-parent-request-form',
  templateUrl: './parent-request-form.component.html',
  styleUrls: ['./parent-request-form.component.scss']
})
export class ParentRequestFormComponent implements OnInit {

  register_save_loading: boolean = false;
  submitted: boolean = false;

  fonts = fonts;

  register_form: FormGroup;
  registration: any | undefined = undefined;
  student_name: string = '';

  constructor(private fb: FormBuilder, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.register_form.controls;
  }

  async getStudentName() {
    try {
      const search_form_query: any = {};
      if (this.register_form.value.student_rollno.length > 0) {
        search_form_query.roll_no = this.register_form.value.student_rollno;
        const service_response = await this.restService.searchStudentWithProgrammes(search_form_query);
        if (service_response && service_response.success && service_response.data.length > 0) {
          this.student_name = service_response.data[0].given_name + ' ' + service_response.data[0].middle_name + ' - ' + service_response.data[0].programme_name;
        } else {
          this.student_name = "Invalid Roll No"
        }
      }
    }
    catch (error) {
      console.log(error);
    } finally {

    }

  }

  async onSaveClick() {
    try {

      const consent = confirm('Are you sure, do you want to save?');
      if (!consent) {
        return;
      }
      this.submitted = true;
      this.register_save_loading = true;

      const registration_data = this.register_form.value;
      let save_respose;

      // create record
      if (!registration_data.parent_name) {
        alert('Please enter Name');
        return;
      }
      if (!registration_data.mobile_no) {
        alert('Please enter Mobile No');
        return;
      }

      if (!registration_data.student_rollno) {
        alert('Please enter Student Roll No');
        this.student_name = '';
        return;
      }

      this.getStudentName();

      if (this.student_name == "INVALID ROLL NO") {
        alert('Invalid Roll No');
        return;
      }

      console.log(registration_data);
      //   save_respose = await this.restService.createAccount(registration_data);
      /*   if (save_respose.success) { */
      alert('Saved Successfully');
      this.submitted = false;
      this.student_name = '';
      this.resetForm();
      /*   } */

    } catch (error) {
      alert('Error while read data');
      console.log(error);
    } finally {
      this.register_save_loading = false;
    }
  }
  resetForm(): void {
    this.register_form.reset();
    this.registration = undefined;
  }

  buildForm(): void {

    if (!this.registration) {
      this.registration = {
        _id: '',
        parent_name: '',
        mobile_no: '',
        email_id: '',
        student_rollno: '',
        updatedAt: ''
      };
    }

    this.register_form = this.fb.group({
      _id: [this.registration._id],
      parent_name: [this.registration.parent_name, [Validators.required]],
      mobile_no: [this.registration.mobile_no, [Validators.required, Validators.pattern('^([0-9]{10})$')]],
      email_id: [this.registration.email_id, [Validators.email]],
      student_rollno: [this.registration.student_rollno, [Validators.required, Validators.maxLength(6), Validators.pattern('^([0-9]{2})+([a-zA-Z]{2})+([0-9]{2})$')]],
      stage: 'requested',
      updatedAt: [this.registration.updatedAt, [Validators.required]],
    });
  }

}
