import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  fonts = fonts;

  name = '';
  email = '';
  picture = '';

  female = false;
  constructor() {

    const user = CookieStore.getUserInfo();
    this.name = user.name;
    this.email = user.email;
    this.picture = user.picture;

    if (user.gender && (user.gender.toLowerCase() === 'male' || user.gender.toLowerCase() === 'm')) {
      this.female = false;
    } else {
      this.female = true;
    }



  }

  isRoleExists(roles: string[]): boolean {
    if (CookieStore.isRoleExists(roles)) {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit(): void {
  }

}
