import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-terminal-exam-result',
  templateUrl: './terminal-exam-result.component.html',
  styleUrls: ['./terminal-exam-result.component.scss']
})
export class TerminalExamResultComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  sections: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;

  // Core datas

  course_search_form: any;
  course_data_list: any = undefined;

  course_list_loading = false;

  public department_list: Array<any> = [];

  today_date = new Date();

  programme_types: { key: string, text: string }[] = [{ key: "PG", text: "P.G. Courses" }, { key: "UG", text: "U.G. Courses" },
  { key: "CC", text: "COMMUNITY COLLEGE Courses" }, { key: 'PD', text: 'P.G. Diploma Courses' }];

  course_code_list_length: number = 0;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
    this.getProgrammeDataList();
  }


  /// master data
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      month: [0], //required
      year: [0],
      enrolled_year: [0], //required
      programme_id: [''], //optional
      section: [''],
      semester: [0],
      //appearance_type: [''],
      //course_code: ['']
    });

  }

  async onFilterSelectChange(id: string, event?: any): Promise<void> {
    /* if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList(this.programme_search_form);
    } */

    if (id == "programme_id") {
      this.sections = [];
      this.programme_search_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.programme_search_form.get('section').setValue(this.sections[0].section_name);
        }
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_search_form.get('programme_id').setValue('');
      this.programme_search_form.get('section').setValue('');
      this.programme_list = [];
      this.sections = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  getProgrammeType(programme_type: string) {
    return this.programme_types.filter(data => data.key === programme_type);
  }

  getMonth(month: string) {
    return this.commonEnums.months.find(x => x.id == month)?.text;
  }
  getProgrammeName(id: string) {
    return this.programme_list.find(x => x.programme_id == id)?.programme_name;
  }

  async onSearchClick(): Promise<void> {
    this.searchCourse();
  }

  async searchCourse(): Promise<void> {
    try {
      if (!this.programme_search_form.value.month) {
        alert('Please select Month');
        return;
      }
      if (!this.programme_search_form.value.year) {
        alert('Please select Year');
        return;
      }
      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please select Enrollment Year');
        return;
      }

      if (!this.programme_search_form.value.programme_id) {
        alert('Please select Programme');
        return;
      }

      if (!this.programme_search_form.value.semester) {
        alert('Please select Programme');
        return;
      }

      this.course_list_loading = true;
      this.course_data_list = undefined;
      var search_form_query: any = {
        month: parseInt(this.programme_search_form.value.month),
        year: this.programme_search_form.value.year,
        enrolled_year: this.programme_search_form.value.enrolled_year,
        semester: this.programme_search_form.value.semester,
        programme_id: this.programme_search_form.value.programme_id,
      };

      if (this.programme_search_form.value.section)
        search_form_query.section = this.programme_search_form.value.section;

      console.log(search_form_query);
      const service_response = await this.restService.findResultCopy(search_form_query);
      if (service_response && service_response.success) {
        this.course_data_list = service_response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_list_loading = false;
    }

  }


  printData() {
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script> " +
      "<style>@page { size: A4 landscape;} @media print { html, body { margin-right:3%; margin-left:2%; margin-top:2%; margin-bottom:3%}}</style>")
    mywindow.document.write('</head><body><div style="font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-area').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.write('<script>document.getElementById("print-table").classList.remove("table-responsive");</script>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }


}
