<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Add Questions to Exam Question Paper</p>
                    </div>

                    <ng-container *ngIf="question_format_type == 'Section-Wise'">
                        <div class="card-body" *ngIf="question_setting_form">
                            <h5>Course Code: {{exam_setup.course_code}}</h5>
                            <form [formGroup]="question_setting_form">
                                <div class="table-responsive">
                                    <div class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width:5%">Question No.</th>
                                                <th style="width: 15%;">CO-Level</th>
                                                <th style="width: 15%;">K-Level</th>
                                                <th>Question</th>
                                                <th style="width: 15%;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="questions_settings">
                                            <ng-container *ngFor="let section of questionSettings; let i=index;"
                                                [formGroupName]="i">
                                                <tr>
                                                    <td colspan="5">
                                                        <span><b>Section {{section.value.section_no}}</b></span><br>
                                                        <span><b>CO Conditions to be noted. No of questions in each
                                                                CO-Level
                                                                in
                                                                this section should match the following CO
                                                                Conditions</b></span>
                                                        <ol class="mg-b-0">
                                                            <li
                                                                *ngFor="let condition of question_settings[i].co_conditions; let ind = index">
                                                                CO-{{condition.co_id}}:
                                                                <b>{{condition.no_of_questions}}</b>
                                                            </li>
                                                        </ol>
                                                    </td>
                                                </tr>
                                                <ng-container formArrayName="questions">
                                                    <tr *ngFor="let question of getQuestions(i); let j=index;"
                                                        [formGroupName]="j">
                                                        <td>{{question.value.question_no}}. {{question.value.option}}
                                                        </td>
                                                        <td>
                                                            <!-- <input type="number" class="form-control"
                                                                                    placeholder="Enter CO Level" formControlName="co_id"
                                                                                    (focusout)="coChanged($event,i,j,question)"
                                                                                    [attr.readonly]="(question.value.option=='B')?true:null"> -->
                                                            <select class="form-control" formControlName="co_id"
                                                                (change)="coChanged($event,i,j,question)"
                                                                [attr.disabled]="(question.value.option=='B')?true:null">
                                                                <option value="0">Choose CO Level</option>
                                                                <option *ngFor="let co of co_k_map" [value]="co.co_id">
                                                                    {{co.co_id}}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td class="text-center">
                                                            <!-- <input type="number" class="form-control"
                                                                                    placeholder="Enter K Level" formControlName="k_id"
                                                                                    (focusout)="kChanged($event,i,j,question)"
                                                                                    [attr.readonly]="(question.value.option=='B')?true:null"> -->
                                                            <ng-container *ngIf="getKLevels(i,j,question).length>1">
                                                                <select class="form-control" formControlName="k_id"
                                                                    (change)="kChanged($event,i,j,question)"
                                                                    [attr.disabled]="(question.value.option=='B')?true:null">
                                                                    <option value="0">Choose K Level</option>
                                                                    <option *ngFor="let k of getKLevels(i,j,question)"
                                                                        [value]="k">
                                                                        {{k}}
                                                                    </option>
                                                                </select>
                                                            </ng-container>
                                                            <ng-container *ngIf="getKLevels(i,j,question).length==1">
                                                                {{getKLevels(i,j,question)[0]}}
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <!-- {{question.value.question}} -->
                                                            <div [innerHTML]="question.value.question"></div>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-info btn-sm"
                                                                (click)="editQuestion(i,j)">Edit
                                                                Question</button>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </tbody>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary" (click)="saveQuestionPaper()"
                                        *ngIf="!question_save_loading" [disabled]="isDisabled()">Save Question</button>
                                    <button class="btn btn-primary" (click)="saveQuestionPaper()"
                                        *ngIf="question_save_loading" disabled>Saving...</button>
                                </div>
                            </form>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="question_format_type == 'Co-Wise'">
                        <div class="card-body" *ngIf="question_setting_form">
                            <h5>Course Code: {{exam_setup.course_code}}</h5>
                            <form [formGroup]="question_setting_form">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width: 15%;">CO-Level</th>
                                                <th>Max. Marks</th>
                                                <th>Attainment Description</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="co_settings">
                                            <ng-container *ngFor="let co of coSettings; let i=index;"
                                                [formGroupName]="i">
                                                <tr>
                                                    <td>CO{{co.value.co_id}}</td>
                                                    <td>
                                                        <input type="text" formControlName="max_mark"
                                                            class="form-control"
                                                            placeholder="Enter Max. Mark for this CO Level">
                                                    </td>
                                                    <td>
                                                        <textarea formControlName="description" class="form-control"
                                                            placeholder="Enter Attainment Description for this CO level"></textarea>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary" (click)="saveQuestionPaper()"
                                        *ngIf="!question_save_loading" [disabled]="isDisabled()">Save Setting</button>
                                    <button class="btn btn-primary" (click)="saveQuestionPaper()"
                                        *ngIf="question_save_loading" disabled>Saving...</button>
                                </div>
                            </form>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Question</h6>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="card card-body pd-20 pd-md-40">
                                <form class="form-horizontal">
                                    <!-- <textarea [(ngModel)]="question_editor" name="editor" rows="20" cols="20"
                                        class="form-control"></textarea> -->
                                    <kendo-editor #editors class="question" [(ngModel)]="question_editor" name="editor"
                                        style="height: 300px;">
                                        <kendo-toolbar>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorUndoButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorRedoButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorBoldButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorItalicButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnderlineButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorSubscriptButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorSuperscriptButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignRightButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-dropdownlist kendoEditorFormat>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontSize>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontFamily>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-colorpicker kendoEditorForeColor>
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient">
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorIndentButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorOutdentButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnlinkButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-editor-insert-table-button>
                                            </kendo-editor-insert-table-button>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddColumnAfterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowAfterButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorDeleteColumnButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteRowButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteTableButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-button kendoEditorInsertFileButton>
                                            </kendo-toolbar-button>
                                            <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                            <kendo-toolbar-button kendoEditorViewSourceButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button kendoEditorCleanFormattingButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button text="Upload Image"
                                                (click)="openUploadImage($event,'question')">
                                            </kendo-toolbar-button>
                                        </kendo-toolbar>
                                    </kendo-editor>
                                </form>
                            </div>
                        </form>
                        <div class="text-center">
                            <button class="btn ripple btn-primary mg-r-5" (click)="saveQuestion()" type="button"
                                *ngIf="!question_save_loading" [disabled]="isDisabled()">Save</button>
                            <button class="btn ripple btn-primary mg-r-5" type="button" [disabled]
                                *ngIf="question_save_loading">Saving...</button>
                            <button class="btn ripple btn-info mg-r-5" (click)="saveAndCloseQuestion()" type="button"
                                [disabled]="isDisabled()">Save & Close</button>
                            <button class="btn ripple btn-secondary" (click)="closeQuestion()"
                                type="button">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <div class="modal" id="modal-2">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="editor_popup_form">
                    <div class="modal-header">
                        Upload Image
                    </div>
                    <div class="modal-body">
                        <div class="card card-body">
                            <input class="form-control" required="" type="file"
                                (change)="uploadImage($event,editor_popup_form)" accept="image/*">
                            <ng-container *ngIf='editor_popup_form.value.image_url'>
                                <img [style.width.px]="editor_popup_form.value.width"
                                    [style.height.px]="editor_popup_form.value.height"
                                    [src]='editor_popup_form.value.image_url' />
                            </ng-container>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">


                                <button class="btn ripple btn-secondary" (click)="onCloseImageUpload()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>



    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>