<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Staff Hour-wise Attendance</p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Start Date</label>
                                        <input type="date" formControlName="start_date" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            End Date</label>
                                        <input type="date" formControlName="end_date" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="staff_id"
                                            placeholder="Search with Staff Roll No..">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Report Type</label>
                                        <select formControlName="report_type" class="form-control">
                                            <option value="">Select Report Type</option>
                                            <option
                                                *ngFor="let report_type of report_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="report_type">{{report_type.split('_').join(' ') | uppercase}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="table-responsive">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th style="width: 5%;">S.No</th>
                                        <th>Date</th>
                                        <th>Staff ID</th>
                                        <th>Staff Name</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th style="width: 10%;">Hour No.</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of table_data; let i = index; trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.attendance_date | date:commonEnums.date_format}}</td>
                                        <td>
                                            {{data.staff_id}}
                                        </td>
                                        <td>
                                            {{data.staff_name}}
                                        </td>
                                        <td>
                                            {{data.course_code}}
                                        </td>
                                        <td>
                                            {{data.course_name}}
                                        </td>
                                        <td>
                                            {{data.hour_number}}
                                        </td>
                                        <td style="width: 10%;">

                                        </td>
                                    </tr>

                                    <ng-container *ngIf="!table_data_loading && table_data.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records
                                                found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="table_data_loading && table_data.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading
                                                Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>