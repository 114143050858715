<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">




        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0 no-print">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Transfer/ Cancel reports</h4>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Type</label>
                                        <select formControlName="transfer_type" class="form-control" (change)="onTransferTypeChange()">
                                            <option value="transfer">Transferred</option>
                                            <option value="cancel">Cancelled</option>
                                        </select>

                                    </div>

                                </div>
                                <div class="col-lg-1">
                                    <div class="form-group mg-t-25">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="apply_date_filter" formControlName="apply_date_filter">
                                            <label class="custom-control-label" for="apply_date_filter">Apply date filter</label>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="programme_search_form.value.apply_date_filter">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start Date</label>
                                            <input class="form-control" required="" type="datetime-local" formControlName="start_time">
                                        </div>
                                    </div>
                                    <div class="col-lg-2">

                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End Date</label>
                                            <input class="form-control" required="" type="datetime-local" formControlName="end_time">
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-1">
                                    <div class="form-group mg-t-25">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="completed" formControlName="completed">
                                            <label class="custom-control-label" for="completed">Completed</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search" *ngIf="!transfer_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled *ngIf="transfer_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>




                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="card-body">

                        <div class="table-responsive">
                            <table id="printable" class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th rowspan="2" class="text-center" style="width:5%;">S.No</th>
                                        <th rowspan="2" class="text-center">Date</th>
                                        <th rowspan="2" class="text-center">Name</th>
                                        <th rowspan="2" class="text-center">Mobile No.</th>
                                        <ng-container *ngIf="programme_search_form.value.transfer_type=='transfer'">
                                            <th rowspan="2" class="text-center">Completed</th>
                                        </ng-container>
                                        <th colspan="5" class="text-center">FROM</th>
                                        <ng-container *ngIf="programme_search_form.value.transfer_type=='transfer'">
                                            <th colspan="3" class="text-center">TO</th>


                                            <th rowspan="2" class="text-center">Amount to pay</th>
                                        </ng-container>

                                    </tr>
                                    <tr>
                                        <th class="text-center">App. No.</th>
                                        <th class="text-center">Prg. Name.</th>
                                        <th class="text-center">Fee</th>
                                        <ng-container *ngIf="programme_search_form.value.transfer_type=='transfer'">
                                            <th class="text-center">Charges</th>
                                            <th class="text-center">Balance</th>

                                            <th class="text-center">App. No.</th>
                                            <th class="text-center">Prg. Name.</th>
                                            <th class="text-center">Fee</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>



                                    <ng-container *ngFor="let transfer of transfer_list;let i=index;trackBy:formDataResolver.identify;">
                                        <tr>
                                            <td scope="row">
                                                {{i+1}}
                                            </td>
                                            <td class="uppercase">
                                                <ng-container *ngIf="programme_search_form.value.transfer_type=='transfer'">
                                                    {{transfer.transferred_date|date:commonEnums.date_format}}
                                                </ng-container>
                                                <ng-container *ngIf="programme_search_form.value.transfer_type=='cancel'">
                                                    {{transfer.createdAt|date:commonEnums.date_format}}
                                                </ng-container>
                                            </td>

                                            <td class="uppercase">
                                                {{transfer.applicant_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{transfer.mobile_numbers.join(", ")}}
                                            </td>
                                            <ng-container *ngIf="programme_search_form.value.transfer_type=='transfer'">
                                                <td class="uppercase">
                                                    {{transfer.completed?"YES":"NO"}}
                                                </td>
                                            </ng-container>
                                            <td class="uppercase">
                                                {{transfer.from_application.application_no}}
                                            </td>
                                            <td class="uppercase">
                                                {{transfer.from_programme_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{transfer.before_fee_amount}}
                                            </td>
                                            <ng-container *ngIf="programme_search_form.value.transfer_type=='transfer'">
                                                <td class="uppercase">
                                                    {{transfer.extra_charge_amount}}
                                                </td>
                                                <td class="uppercase">
                                                    {{transfer.before_balance}}
                                                </td>


                                                <td class="uppercase">
                                                    {{transfer.to_application.application_no}}
                                                </td>
                                                <td class="uppercase">
                                                    {{transfer.to_programme_name}}
                                                </td>
                                                <td class="uppercase">
                                                    {{transfer.after_fee_amount}}
                                                </td>

                                                <td class="uppercase">
                                                    {{transfer.running_balance}}
                                                </td>

                                            </ng-container>

                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!transfer_list_loading && transfer_list.length==0">
                                        <tr>
                                            <th colspan="14" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="transfer_list_loading && transfer_list.length==0">
                                        <tr>
                                            <th colspan="14" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>



                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>

</div>
<!-- /main-content -->
<app-footer></app-footer>