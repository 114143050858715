<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Scroller Messages displayed in the Admission Landing
                            Page</p>
                    </div>

                    <div class="card-body">
                        <div *ngIf="scroller_form">
                            <form [formGroup]="scroller_form">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width: 5%;">S.No</th>
                                                <th style="width: 40%;">Message</th>
                                                <th style="width: 25%;">Link</th>
                                                <th style="width: 5%">Is Active</th>
                                                <th style="width: 10%;">Display Priority</th>
                                                <th style="width: 15%;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="messages">
                                            <tr *ngFor="let message of messagesList; let i = index;"
                                                [formGroupName]="i">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    <textarea class="form-control" placeholder="Enter Message"
                                                        formControlName="message"></textarea>
                                                </td>
                                                <td>
                                                    <input class="form-control" required="" type="file"
                                                        (change)="uploadFile($event,i)">
                                                    <p *ngIf="message.value.link">
                                                        <a [href]="message.value.link" target="_blank">Existing File</a>
                                                    </p>
                                                </td>
                                                <td>
                                                    <input type="checkbox" formControlName="is_active">
                                                </td>
                                                <td>
                                                    <button type="button" (click)="moveUp(i)" title="Move Up"
                                                        class="btn btn-sm btn-info mr-2">
                                                        <fa-icon [icon]="fonts.faArrowUp"></fa-icon>
                                                    </button>
                                                    <button type="button" (click)="moveDown(i)" title="Move Down"
                                                        class="btn btn-sm btn-info mr-2">
                                                        <fa-icon [icon]="fonts.faArrowDown"></fa-icon>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm"
                                                        (click)="removeScrollerMessage(i)">Remove</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" class="text-center">
                                                    <button class="btn btn-md btn-primary"
                                                        (click)="addScrollerMessage()">Add New
                                                        Message</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-center">
                                <button class="btn ripple btn-success" (click)="onSaveMessagesClick()"
                                    type="button">Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>