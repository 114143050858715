<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>

  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Incharges</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
    </div>

    <div class="row row-sm">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card" >
                <div class="card-header pb-0">
                  <div class="d-flex justify-content-between">
                    <h4 class="card-title mg-b-0 mb-3">Monthly Bill</h4>
                  </div>
 
                  <div class="row mb-3">
                    <div class="col-md-4">
                      <select
                        class="form-control"
                        [(ngModel)]="year" 
                        (change)="chooseYear()"> 
                      <option value="">Select Year</option>
                      <option *ngFor="let year of years;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                  </select>
                    </div>
                    <div class="col-md-8">
                      <select
                        class="form-control"
                        [(ngModel)]="month" 
                        (change)="chooseMonth()"> 
                      <option value="">Select Month</option>
                      <option *ngFor="let month of months;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="month.month_name">{{month.month_name}}</option>
                    </select>
                    </div>
                  </div>
                </div>
      
                
                <div class="card-body" *ngIf="month!=''">
                  <div class="row mb-3">
                    <div class="col-md-9">
                      <h6>Ayya Nadar Janaki Ammal College, Sivakasi</h6>
                      <p>Sivakasi, Srivilliputhur Main Road, Sivakasi - 626124<br>
                      Email: support_anjac@anjaconline.org<br>
                      Mobile: 04562-254100</p>
                    </div>
                    <div class="col-md-3">
                      <h1 class="invoice-title">
                        Invoice
                      </h1>
                    </div>
                  </div>


                  <div class="row mb-3">
                    <div class="col-md-6">
                      <label class="tx-gray-600">Billed To</label>
                      <p>{{invoice_data.student_name}} <br>{{invoice_data.student_rno}} <br>{{invoice_data.student_class}} <br>
                        {{invoice_data.student_email}}
                      </p>
                    </div>
                    <div class="col-md-6">
                      <label class="tx-gray-600">Invoice Information</label>
                      <p class="invoice-info-row"><span>Invoice No</span> <span>{{invoice_data.invoice_no}}</span></p>
                      <p class="invoice-info-row"><span>Issue Date</span> <span>{{invoice_data.issue_date}}</span></p>
                      <p class="invoice-info-row"><span>Due Date</span> <span>{{invoice_data.due_date}}</span></p>  
                    </div>
                  </div>

                  <div class="table-responsive border-top userlist-table">
                    <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                      <thead>
                        <th>
                            <span>S.No</span>
                        </th>
                        <th>
                          <span>Expense Type</span>
                        </th>
                        <th>
                          <span>Amount</span>
                        </th>
    
                      </thead>
                      <tbody>
                      <tr *ngFor="let expense of monthly_expenses; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{expense.expense_name}}</td>
                        <td>{{expense.expense_amount}}</td>
                      </tr>
                      <tr><td colspan="2">Total Expense</td><td><b class="total_bill">Rs. {{monthly_total}}</b></td></tr>
                      </tbody>
                    </table>
                  </div>

                  <hr>

                  <a class="btn btn-purple float-right mt-3 ml-2" href=""> <i class="mdi mdi-currency-usd mr-1"></i>Pay Now </a>
                  <a href="#" class="btn btn-danger float-right mt-3 ml-2" onclick="javascript:window.print();"> <i class="mdi mdi-printer mr-1"></i>Print </a>
                  <a href="#" class="btn btn-success float-right mt-3"> <i class="mdi mdi-telegram mr-1"></i>Send Invoice </a>
                                                  
                </div>
              </div>
      </div>
    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
