<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Give OBE Feedback for Courses</p>
                    </div>

                    <div class="card-body">

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 15%;">Course Code</th>
                                        <th style="width: 35%;">Course Name</th>
                                        <th style="width: 20%">Due Date</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="course_list.length>0">
                                        <ng-container
                                            *ngFor="let course of course_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{course.course_code | uppercase}}</td>
                                                <td>{{course.course_name}}</td>
                                                <td>{{course.end_date | date:commonEnums.date_format}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="answerSurvey(course)"
                                                            class="btn btn-sm btn-info mr-2"
                                                            *ngIf="!course?.student_response[0]?.submitted">
                                                            Submit Response
                                                        </button>
                                                        <span *ngIf="course?.student_response[0]?.submitted"
                                                            style="color: green;">
                                                            Submitted
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="course.survey_questions?.length>0">
                    <div class="modal-header">
                        <h6 class="modal-title">Course Exit Survey Response</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-6">
                                    Course Code & Title: <b>{{course.course_code}} - {{course.course_name}}</b>
                                </div>
                                <div class="col-md-6">
                                    Class:
                                    <b>{{getClassName(course.enrolled_year,course.programme_id,course.section,course.virtual_section,
                                        course.programme_type_id)}}</b>
                                </div>
                            </div>
                            <p>Strongly Agree - 4 points; Agree - 3 Points; Neutral - 2 points; Disagree - Zero Points.
                            </p>
                            <h6>How well do you accept the following statements with regard to the skills acquired on
                                completion of this course?</h6>
                            <form class="form-horizontal">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width: 5%;">S.No</th>
                                                <th style="width: 10%;">CO Level</th>
                                                <th style="width: 35%;">Question</th>
                                                <th style="width: 50%;">Responses</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let question of course.survey_questions;let i=index;trackBy:fromDataResolver.identify;">
                                                <td>{{i+1}}</td>
                                                <td>CO{{question.co_level}}</td>
                                                <td>{{question.question}}</td>
                                                <td>
                                                    <span class="mg-r-10"
                                                        *ngFor="let option of question.response_options;let j=index;trackBy:fromDataResolver.identify;">
                                                        <input type="radio" [(ngModel)]="question.response"
                                                            class="mg-r-5" name="option_{{i}}" [value]="option">
                                                        <label>{{option.split('_').join(' ') | uppercase}}</label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <textarea class="form-control" placeholder="Any other remarks"
                                                        name="remarks" rows="5" cols="10" [(ngModel)]="remarks">
                                                    </textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <!-- <ng-container
                                    *ngFor="let question of course.survey_questions;let i=index;trackBy:fromDataResolver.identify;">
                                    <div class="row mg-b-10">
                                        <div class="col-md-12">
                                            CO{{question.co_level}} - {{question.question}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="mg-r-10"
                                                *ngFor="let option of question.response_options;let j=index;trackBy:fromDataResolver.identify;">
                                                <input type="radio" [(ngModel)]="question.response" class="mg-r-5"
                                                    name="option_{{i}}" [value]="option">
                                                <label>{{option.split('_').join(' ') | uppercase}}</label>
                                            </span>
                                        </div>
                                    </div>
                                </ng-container> -->
                                <div class="text-center mg-t-20">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()" *ngIf="!save_loading"
                                        [disabled]="canDisable()">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        *ngIf="!save_loading" [disabled]="canDisable()">Save &
                                        Close</button>
                                    <button class="btn btn-info mg-r-20" *ngIf="save_loading"
                                        disabled>Saving...</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>