<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Internal Examinations Setup</p>
                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="component_search_form">
                            <form [formGroup]="component_search_form">
                                <div class="row row-sm">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                enrollment year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <ng-container
                                        *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal','aarc_member']) ">
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by
                                                    Staff Rollno</label>
                                                <input type="text" class="form-control" formControlName="roll_no"
                                                    placeholder="Enter Staff Roll No.">
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by
                                                    Course Code</label>
                                                <input type="text" class="form-control" formControlName="course_code"
                                                    placeholder="Enter Course Code">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="col-lg-2">
                                        <div class="form-group">

                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="course_list_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-container>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 3%;">S.No</th>
                                        <th style="width: 10%;">Course Code</th>
                                        <th>Course Name</th>
                                        <th style="width: 5%;">Part</th>
                                        <th style="width: 10%;">Part Type</th>
                                        <th style="width: 10%;">Staff Name</th>
                                        <th style="width: 10%;">Programme & Section</th>
                                        <th style="width: 10%">Virtual Section</th>
                                        <th style="width: 15%;">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let course of course_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{this.start_index + i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.course_code}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.course_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.part?course.part.split('_').join(' '):''}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.course_type?course.course_type.split('_').join(' '):''}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.staff.salutation}}. {{course.staff.middle_name}}.
                                                {{course.staff.given_name}}
                                                {{course.staff.qualification}} ({{course.roll_no}})
                                            </td>
                                            <td class="uppercase">
                                                <span
                                                    *ngIf="course.class_taking_programme_id && course.class_taking_section">
                                                    {{getProgrammeName(course.class_taking_programme_id)}}
                                                    '{{course.class_taking_section}}'
                                                </span>
                                            </td>
                                            <td class="uppercase">
                                                {{course.virtual_section}}
                                            </td>
                                            <td>

                                                <div class="btn-group dropdown">
                                                    <button type="button" class="btn btn-sm btn-info"
                                                        (click)="onViewComponentsClick(course)"
                                                        *ngIf="!course.show_loading">View Components</button>
                                                    <button type="button" class="btn btn-sm btn-info" disabled
                                                        *ngIf="course.show_loading">Loading...</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="course.show_co">
                                            <tr>
                                                <td colspan="10">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width: 5%;">S.No</th>
                                                                    <th style="width: 5%;">Term</th>
                                                                    <th style="width: 35%;">Course Component</th>
                                                                    <th style="width: 5%;">Max Mark</th>
                                                                    <th style="width:30%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let component of course.component_list;let i=index;trackBy:fromDataResolver.identify;">
                                                                    <tr>
                                                                        <td>
                                                                            {{i+1}}
                                                                        </td>
                                                                        <td>
                                                                            {{component.term}}
                                                                        </td>
                                                                        <td>
                                                                            {{component.component_type}}
                                                                        </td>
                                                                        <td>
                                                                            {{component.max_mark}}
                                                                        </td>
                                                                        <td>
                                                                            <button class="btn btn-sm btn-info mg-r-15"
                                                                                (click)="onEditClick(course,component)">Exam
                                                                                Setup</button>
                                                                        </td>
                                                                    </tr>

                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!course.loading && course.component_list.length==0">
                                                                    <tr>
                                                                        <th colspan="5" class="text-center">Mapping not
                                                                            found please contact OBE
                                                                            incharge
                                                                        </th>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="course.loading && course.component_list.length==0">
                                                                    <tr>
                                                                        <th colspan="5" class="text-center">Loading
                                                                            Please wait</th>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>


                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="10" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="10" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                            <ng-container *ngIf="total_records>100">
                                <ul class="pagination pull-right">
                                    <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                                                class="icon ion-ios-arrow-back"></i></a></li>

                                    <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                                                class="icon ion-ios-arrow-forward"></i></a></li>
                                </ul>
                            </ng-container>
                        </div>


                    </div>
                </div>
            </div>
        </div>


        <!-- Exam Setup Large Modal -->
        <div class="modal" id="exam-setup-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="exam_setting_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Internal Exam Setup</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-sm mg-r-15 btn-info text-right"
                                        (click)="navigateToQuestionSetting()">Add
                                        Questions</button>
                                    <button class="btn btn-sm btn-info text-right"
                                        (click)="navigateToValuationScheme()">Add Valuation
                                        Scheme</button>
                                </div>
                            </div>
                            <form [formGroup]="exam_setting_form" class="form-horizontal">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Question Format Setting Name</label>
                                            <select formControlName="question_format_id" class="form-control">
                                                <option value="">Select Question Format Name</option>
                                                <option
                                                    *ngFor="let format of format_setting_data;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="format._id">{{format.format_name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('question_format_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Question Format Setting Name is
                                                        required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                                Date</label>
                                            <input type="datetime-local" class="form-control"
                                                formControlName="start_time" placeholder="Enter Start date">
                                            <div *ngIf="isInvalid('start_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                                                Date</label>
                                            <input type="datetime-local" class="form-control" formControlName="end_time"
                                                (focusout)="validateDate($event,'end_time')"
                                                placeholder="Enter End date">
                                            <div *ngIf="isInvalid('end_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Exam Mode</label>
                                            <select formControlName="mode_of_exam" class="form-control">
                                                <option value="">Select Mode</option>
                                                <option
                                                    *ngFor="let mode of this.exam_modes;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="mode.key">{{mode.text}}
                                                </option>
                                            </select>
                                            <div *ngIf="isInvalid('mode_of_exam')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Exam Mode is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="allow_students_answer_submission"
                                                class="mg-r-5">Allow student
                                            submission? (If selected, students can
                                            upload their answer sheets in the portal.)
                                            <div *ngIf="isInvalid('allow_students_answer_submission')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose if students can upload their
                                                        answer sheets for this exam or not.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="this.exam_setting_form.value.allow_students_answer_submission">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Submission Start
                                                Date</label>
                                            <input type="datetime-local" class="form-control"
                                                formControlName="submission_start_time" placeholder="Enter Start date"
                                                (focusout)="validateDate($event, 'submission_start_time')">
                                            <div *ngIf="isInvalid('start_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Submission Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Submission End
                                                Date</label>
                                            <input type="datetime-local" class="form-control"
                                                formControlName="submission_end_time"
                                                (focusout)="validateDate($event,'submission_end_time')"
                                                placeholder="Enter End date">
                                            <div *ngIf="isInvalid('end_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Submission End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="active" class="mg-r-5">Is Active?
                                            <div *ngIf="isInvalid('active')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose if the setting is active for
                                                        examination or not.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-bordered text-md-nowrap">
                                        <thead>
                                            <tr class="text-center">
                                                <th style="width:5%" class="text-center">S.No.</th>
                                                <th style="width:12%" class="text-center">Valuation No.</th>
                                                <th style="width:13%" class="text-center">Type</th>
                                                <th style="width:10%" class="text-center">Staff ID.</th>
                                                <th style="width:10%" class="text-center">End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="valuations">
                                            <ng-container *ngFor="let valuation of valuatorList; let in=index;">
                                                <tr [formGroupName]="in">
                                                    <td class="text-center">{{in+1}}</td>
                                                    <td>
                                                        <span>{{valuation.value.valuation_no}}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{valuation.value.evaluated_in}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <p>
                                                            <span
                                                                *ngFor="let staff of valuation.value.staff_list; let ind = index">{{staff.roll_no}}
                                                                <span *ngIf="valuation.value.staff_list.length-1>ind">,
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="text-center">
                                                        <input type="datetime-local" class="form-control"
                                                            formControlName="end_time" placeholder="Enter End date">
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.exam_setting_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.exam_setting_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.exam_setting_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.exam_setting_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.exam_setting_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.exam_setting_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.exam_setting_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.exam_setting_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <!-- <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.exam_setting_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.exam_setting_form.value._id && delete_loading" disabled>Deleting...</button> -->
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn btn-dark mg-r-20" (click)="onCloseClick()">Close</button>
                            </div>

                        </div>
                    </div>



                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>