import { NotificationService } from './notification.service';
import { NotificationEvent } from './eventType';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  message: string;
  type: string;
  constructor(private notificationService: NotificationService) {
    this.message = '';
    this.type = '';
  }

  onClose() {
    this.message = '';
    this.type = '';
  }

  ngOnInit(): void {
    this.notificationService.getNotification().subscribe((event: NotificationEvent) => {
      if (event.type) {
        this.message = event.message || '';
        this.type = event.type || '';
      } else {
        this.message = '';
        this.type = '';
      }
    });
  }

  getClass(): string[] {
    if (this.type === 'Success') {
      return ['alert-success'];
    } else if (this.type === 'Warning') {
      return ['alert-warning'];
    }
    return ['alert-danger'];
  }

  getType(): string {
    if (this.type.toLocaleLowerCase() === 'success') {
      return 'Success';
    } else if (this.type === 'Warning') {
      return 'Warning';
    }
    return 'Error';
  }
}
