import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-manage-components',
  templateUrl: './manage-components.component.html',
  styleUrls: ['./manage-components.component.scss']
})
export class ManageComponentsComponent implements OnInit {



  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // master data

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  parts: any[] = [];
  course_types: any[] = [];

  component_search_form: any;

  // core data

  component_list: any[] = [];
  component_list_loading = false;

  component_form: any;
  component_save_loading = false;
  component_delete_loading = false;
  component: any;


  calculation_form_search: any;
  calculation_form_search_loading = false;

  calculation_form: any;
  calculation_form_save_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {

    // Load master data
    this.buildComponentSearchForm();
    this.getProgrammeTypeList();

    // Load core data

  }



  /// master data

  buildComponentSearchForm(): void {

    this.component_search_form = this.fb.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: ['']
    });

  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    this.getProgrammeDataList();
  }

  /// Core data

  async onAddClick(): Promise<void> {
    this.buildComponentForm();
    JQueryHelper.openModal('#modal-popup-component', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(component: any): Promise<void> {
    this.component = component;
    this.parts = this.commonEnums.getParts(this.component.programme_type_id);
    this.course_types = [];
    if (this.component.part) {
      const course_types = this.commonEnums.getCoursePartType(this.component.programme_type_id, this.component.part);
      if (course_types) {
        this.course_types = course_types;
      }
    }


    this.buildComponentForm();
    JQueryHelper.openModal('#modal-popup-component', { keyboard: false, backdrop: 'static' });
  }

  async onDeleteClick(component: any): Promise<void> {


    try {
      this.component_delete_loading = true;
      const service_response = await this.restService.deleteComponent(component._id);
      if (service_response && service_response.success) {
        this.getProgrammeDataList();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.component_delete_loading = false;
    }

  }

  async onCloseClick(): Promise<void> {
    this.resetComponentForm();
    JQueryHelper.closeModal('#modal-popup-component');
  }
  resetComponentForm(): void {
    this.component = undefined;
    this.component_form = undefined;
  }

  buildComponentForm(): void {
    if (!this.component) {
      this.component = {};
      this.component._id = '';
      this.component.programme_type_id = this.component_search_form.value.programme_type_id;
      this.component.part = '';
      this.component.course_type = '';
      this.component.course_code = '';
      this.component.semester = this.component_search_form.value.semester;
      this.component.enrolled_year = this.component_search_form.value.enrolled_year;
      this.component.component_type = '';
      this.component.term = '';
      this.component.component_name = '';
      // this.component.allowed_co_ids = this.commonEnums.getCOIDs().map(c => c.id);
      this.component.description = '';
      this.component.max_mark = 0;
      this.component.scale_to = 0;
    }
    this.component_form = this.fb.group({
      _id: [this.component._id],
      programme_type_id: [this.component.programme_type_id],
      part: [this.component.part],
      course_type: [this.component.course_type],
      semester: [this.component.semester],
      enrolled_year: [this.component.enrolled_year],
      component_type: [this.component.component_type],
      course_code: [this.component.course_code],
      term: [this.component.term],
      component_name: [this.component.component_name],
      // allowed_co_ids: this.fb.array(this.buildCOListForm(this.component.allowed_co_ids)),
      description: [this.component.description],
      max_mark: [this.component.max_mark],
      scale_to: [this.component.scale_to],
    });

    if (this.component_form.value.programme_type_id) {
      this.parts = this.commonEnums.getParts(this.component_form.value.programme_type_id);
    }
  }

  buildCOListForm(cos_list: any[]): any[] {
    const value_to_return: any[] = [];
    if (!cos_list) {
      cos_list = [];
    }

    if (cos_list.length > 0) {
      for (const cos of cos_list) {
        value_to_return.push(this.fb.group({
          id: cos,
          text: cos,
        }));
      }

    }

    return value_to_return;

  }



  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.parts = this.commonEnums.getParts(this.component_form.value.programme_type_id);
    } else if (id === 'part') {
      this.course_types = [];
      const course_types = this.commonEnums.getCoursePartType(this.component_form.value.programme_type_id, this.component_form.value.part);
      if (course_types) {
        this.course_types = course_types;
      }
    }

  }



  async getProgrammeDataList(): Promise<void> {
    try {
      this.component_list_loading = true;
      const service_response = await this.restService.searchComponent(this.component_search_form.value);
      if (service_response && service_response.success) {
        this.component_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.component_list_loading = false;
    }
  }

  async onSaveComponentClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.component_list_loading = true;
      console.log(JSON.stringify(this.component_form.value));
      const component_data = this.component_form.value;

      if (!component_data.programme_type_id) {
        alert('Please select programme');
        return;
      }
      if (!component_data.enrolled_year) {
        alert('Please select enrolled year');
        return;
      }
      if (!component_data.semester) {
        alert('Please select semester');
        return;
      }

      if (!component_data.component_type) {
        alert('Please select component type');
        return;
      }

      if (!component_data.term) {
        alert('Please select term');
        return;
      }

      if (!component_data.part) {
        alert('Please select part');
        return;
      }

      // if (!component_data.course_type) {
      //   alert('Please select course type');
      //   return;
      // }

      if (!component_data.max_mark || component_data.max_mark <= 0 || component_data.max_mark > 100) {
        alert('Please enter valid max mark');
        return;
      }
      if (!component_data.scale_to || component_data.scale_to <= 0 || component_data.scale_to > 100) {
        alert('Please enter valid scale to mark');
        return;
      }

      // component_data.allowed_co_ids = component_data.allowed_co_ids.map((c: any) => c.id);
      let service_response;
      if (component_data._id) {
        service_response = await this.restService.updateComponent(component_data);
        if (service_response && service_response.success) {
          alert('Updated Successfully');
          await this.getProgrammeDataList();
          // this.onCloseClick();
        }
      } else {
        if (!component_data.programme_type_id) {
          alert('Please select programme type');
          return;
        }
        service_response = await this.restService.createComponent(component_data);
        if (service_response && service_response.success) {
          alert('Created Successfully');
          await this.getProgrammeDataList();
          // this.onCloseClick();
        }
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.component_list_loading = false;
    }
  }

  // Calculation

  async onAddCalculaitonClick(): Promise<void> {
    this.buildCalComponentForm();
    JQueryHelper.openModal('#modal-popup-calculation', { keyboard: false, backdrop: 'static' });
  }

  buildCalComponentForm(): void {
    this.calculation_form_search = this.fb.group({
      programme_type_id: [this.component_search_form.value.programme_type_id],
      semester: [this.component_search_form.value.semester],
      enrolled_year: [this.component_search_form.value.enrolled_year],
    });
    this.onSearchCalculation(true);
  }

  async onSearchCalculation(background: boolean = false): Promise<void> {


    try {
      if (!this.calculation_form_search.value.programme_type_id) {
        if (!background) {
          alert('Please select programme type');
        }
        return;
      }
      if (!this.calculation_form_search.value.semester) {
        if (!background) {
          alert('Please select semester');
        }
        return;
      }
      if (!this.calculation_form_search.value.enrolled_year) {
        if (!background) {
          alert('Please select enrolled_year');
        }
        return;
      }
      this.calculation_form_search_loading = true;
      const service_response = await this.restService.getOBEComponentCalculation(this.calculation_form_search.value);
      if (service_response && service_response.success) {
        this.buildCalComponentDetailForm(service_response.data);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.calculation_form_search_loading = false;
    }

  }

  buildCalComponentDetailForm(data: any[]): void {
    this.calculation_form = this.fb.group({
      calculation_list: this.fb.array(this.buildCalDataList(data))
    });
  }


  buildCalDataList(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const data_to_return = [];
    for (const data_item of data) {
      data_to_return.push(this.fb.group({
        component_types: [data_item.component_types || ''],
        programme_type_id: [data_item.programme_type_id || ''],
        semester: [data_item.semester || ''],
        enrolled_year: [data_item.enrolled_year || ''],
        evaluation_type: [data_item.evaluation_type || ''],
        calculation_type: [data_item.calculation_type || ''],
      }))
    }
    return data_to_return;
  }

  resetCalculationComponentForm(): void {
    this.calculation_form = null;
  }
  async onCloseCalculaitonClick(): Promise<void> {
    this.resetCalculationComponentForm();
    JQueryHelper.closeModal('#modal-popup-calculation');
  }


  async onCalculationSaveClick(): Promise<void> {


    try {
      const consent = confirm("Are you sure you want to save?");
      if (!consent) {
        return;
      }

      this.calculation_form_save_loading = true;
      const service_response = await this.restService.createComponentBulk(this.calculation_form.value.calculation_list);
      if (service_response && service_response.success) {
        alert('Saved Successfully');
        await this.onSearchCalculation();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.calculation_form_save_loading = false;
    }

  }



}
