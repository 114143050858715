<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Transport Fees</p>
                    </div>

                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Add
                                New Fees Setting</button>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 15%;">Term</th>
                                        <th style="width: 15%;">Route</th>
                                        <th style="width: 15%;">Start Date</th>
                                        <th style="width: 15%;">End Date</th>
                                        <th style="width: 20%;">Amount</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="transport_fees_list.length>0">
                                        <ng-container
                                            *ngFor="let fee of transport_fees_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{fee.term_name | titlecase}}</td>
                                                <td>{{getRouteName(fee.route_id)}}</td>
                                                <td>{{fee.start_date | date:commonEnums.date_time_format}}</td>
                                                <td>{{fee.end_date | date:commonEnums.date_time_format}}</td>
                                                <td>{{fee.amount}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(fee)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!transport_fees_list_loading && transport_fees_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="transport_fees_list_loading && transport_fees_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="collection-modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="transport_fees_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Transport Fees Collection Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="transport_fees_form" class="form-horizontal">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                                Date</label>
                                            <input class="form-control" required="" type="date"
                                                formControlName="start_date">
                                            <div *ngIf="isInvalid('start_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                                                Date</label>
                                            <input class="form-control" required="" type="date"
                                                formControlName="end_date">
                                            <div *ngIf="isInvalid('end_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Month</label>
                                            <select formControlName="month" class="form-control">
                                                <option value="0">Choose Month</option>
                                                <option
                                                    *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="month.id">{{month.text}}</option>
                                            </select>

                                            <div *ngIf="isInvalid('month')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Month is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Year</label>
                                            <select formControlName="year" class="form-control">
                                                <option value="0">Select year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('year')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Route</label>
                                            <select formControlName="route_id" class="form-control"
                                                (change)="routeChanged($event)">
                                                <option value="">Choose Route</option>
                                                <option
                                                    *ngFor="let route of routes_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="route._id">
                                                    {{route.route_name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('route_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Route Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Fee Charges</label>
                                            <input type="text" class="form-control" formControlName="amount"
                                                placeholder="Enter Fees Charges for the route">
                                            <div *ngIf="isInvalid('amount')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Extra Fee Charges is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Extra
                                                Fee Charges</label>
                                            <input type="text" class="form-control"
                                                formControlName="extra_charge_amount"
                                                placeholder="Enter Extra Fees Charges">
                                            <div *ngIf="isInvalid('extra_charge_amount')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Extra Fee Charges is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                        Description</label>
                                    <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                                        formControlName="description">
                                </textarea>
                                    <div *ngIf="isInvalid('description')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Description is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="checkbox" formControlName="active" class="mg-r-5">Is Active?
                                    <div *ngIf="isInvalid('active')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Choose if the term is active for payment or
                                                not.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()"
                                        *ngIf="!save_loading">
                                        <ng-container *ngIf="!transport_fees_form.value._id">
                                            Save
                                        </ng-container>
                                        <ng-container *ngIf="transport_fees_form.value._id">
                                            Update
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()" *ngIf="!save_loading">
                                        <ng-container *ngIf="!transport_fees_form.value._id">
                                            Save & Close
                                        </ng-container>
                                        <ng-container *ngIf="transport_fees_form.value._id">
                                            Update & Close
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" disabled *ngIf="save_loading">
                                        <ng-container *ngIf="!transport_fees_form.value._id">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="transport_fees_form.value._id">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="fee_setting_id && !delete_loading"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-danger mg-r-20" disabled
                                        *ngIf="fee_setting_id && delete_loading">Deleting...</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>