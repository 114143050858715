import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-virtual-section',
  templateUrl: './virtual-section.component.html',
  styleUrls: ['./virtual-section.component.scss']
})
export class VirtualSectionComponent implements OnInit {

  title = 'Virtual Section Settings';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  obe_course_request_master_id: string = '';
  programme_type_id: string = '';

  filter_form: FormGroup;

  table_data: VirtualSectionInterface = {} as VirtualSectionInterface;
  table_data_loading: boolean = false;


  programme_list: any[] = [];
  programme_list_loading = false;
  virtual_section_list: VirtualSectionDataInterface[] = [];
  settings_save_loading: boolean = false;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private notificationService: NotificationService, private restService: AdminservicesService) {
  }

  ngOnInit(): void {
    this.buildSearchForm();
    this.route.queryParams.subscribe(params => {
      this.programme_type_id = params.programme_type_id;
      this.obe_course_request_master_id = params.obe_course_request_master_id;
    });
  }

  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      course_code: [''],
    });
  }

  isTableDataEmpty() {
    if (JSON.stringify(this.table_data) == '{}' || JSON.stringify(this.table_data) == '[]') {
      return true;
    }
    return false;
  }

  async onSearchClick() {
    this.table_data = {} as VirtualSectionInterface;
    var search_query: any = {};
    search_query.obe_course_request_master_id = this.obe_course_request_master_id;
    if (!this.filter_form.value.course_code) {
      alert("Please enter valid Course Code"); return;
    }
    search_query.course_code = this.filter_form.value.course_code;
    console.log(search_query);
    this.table_data_loading = true;
    try {
      const service_response = await this.restService.getElectiveStudentData(search_query);
      if (service_response && service_response.success) {
        console.log(service_response.data);
        this.table_data = service_response.data;
        if (this.table_data.section_data.length == 0) {
          alert("Virtual Section not created for this course! Please create Virtual Sections in Quantity Settings Page");
          return;
        }
        this.table_data.requested_list.forEach(student => {
          student.common_virtual_section = student.student_data[0].virtual_section;
        });
        this.virtual_section_list = this.table_data.section_data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  setCommonVirtualSection(ev: Event, i: number) {
    const select_input = ev.target as HTMLSelectElement;
    this.table_data.requested_list[i].student_data.forEach(student => {
      student.virtual_section = select_input.value
    });
  }

  getSectionQty(section_name) {
    var count = 0;
    this.table_data.requested_list.forEach(programme => {
      count += programme.student_data.filter(x => x.virtual_section == section_name).length;
    });
    return count;
  }

  async saveSettings() {
    this.table_data.section_data.forEach(section => {
      var programme_section_qty: number = 0;
      this.table_data.requested_list.forEach(programme => {
        programme_section_qty += programme.student_data.filter(x => x.virtual_section == section.section_name).length;
      });
      if (programme_section_qty > section.max_qty) {
        alert("Max Allocated Strength for Virtual Section " + section.section_name + " must be less than or equal to the max strength");
        return;
      }
    });

    this.settings_save_loading = true;
    try {
      var is_error: boolean = false;
      for (var i = 0; i < this.table_data.requested_list.length; i++) {
        if (this.table_data.requested_list[i].common_virtual_section == '') {
          is_error = true;
        }
      }
      if (is_error) {
        alert("Please set Virtual Section for all programmes");
        return;
      }
      const service_response = await this.restService.assignVirtualSection(this.table_data.requested_list);
      if (service_response && service_response.success) {
        alert("Virtual Section Settings Saved Successfully");
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.settings_save_loading = false;
    }
  }

}

interface VirtualSectionInterface {
  max_qty: number,
  requested_list: RequestedListInterface[],
  section_data: VirtualSectionDataInterface[]
}

interface RequestedListInterface {
  course_code: string,
  course_name: string,
  course_type: string,
  enrolled_year: number,
  part: string,
  semester: number,
  student_programme_id: string,
  student_programme_name: string,
  student_programme_section: string,
  student_data: ElectiveStudentData[],
  common_virtual_section?: string,
  obe_course_request_master_id?: string
}

interface ElectiveStudentData {
  given_name: string,
  middle_name: string,
  roll_no: string,
  sub: string,
  virtual_section?: string
}

interface VirtualSectionDataInterface {
  section_name: string,
  max_qty: number,
  id?: string
}