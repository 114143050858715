import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-missing-assignment',
  templateUrl: './missing-assignment.component.html',
  styleUrls: ['./missing-assignment.component.scss']
})
export class MissingAssignmentComponent implements OnInit {

  title = 'Missing Assignment';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;

  filter_form: FormGroup;
  studentassignForm: FormGroup;
  table_data_loading: boolean = false;
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  all_programme_list: any[] = [];
  programme_list_loading = false;
  programme_section_list: any[] = [];
  sections: any = [];
  parts: any[] = [];
  type: string = "missing_staff";
  appearance_types: { key: string, value: string }[] = [{ key: "normal", value: "Normal" }, { key: "ARREAR", value: "Arrear" }, { key: "REPEAT", value: "Repeat Semester" }];
  part_course_types: any[] | undefined = [];
  table_data: any = {};
  course_positions: ["Core 1", "Core 2", "Core 3", "Elective 1", "Elective 2", "Supplementary 1"];
  course_handling_staffs: {
    staff_id: string, staff_name: string
  }[] = [];
  map_delete_loading: boolean = false;
  category_list: any[] = [];
  department_list: any[] = [];
  department_list_loading = false;
  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) {
    this.studentassignForm = this.formBuilder.group({
      _id: [''],
      course_code: ['', [Validators.required]],
      semester: ['', [Validators.required]],
      enrolled_year: ['', [Validators.required]],
      roll_no: ['', [Validators.required]],
      appearance_type: ['', [Validators.required]],
      //staff_id: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
    this.getDepartmentCategoryList();
  }
  async getDepartmentCategoryList(): Promise<void> {
    try {
      this.category_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }

  async getDepartmentDataList(form): Promise<void> {
    try {
      this.department_list_loading = true;
      this.department_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.department_category_id) {
          search_form_query.department_category_id = form.value.department_category_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
        console.log(this.department_list);
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_list_loading = false;
    }
  }
  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      roll_no: [''],
      course_code: [''],
      enrolled_year: [''],
      department_category_id: [''],
      department_id: [''],
      semester: [''],
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section: [''],
      //course_position: ['']
      part: ['']
    });
  }

  isTableDataEmpty() {
    if (JSON.stringify(this.table_data) == '{}' || JSON.stringify(this.table_data) == '[]') {
      return true;
    }
    return false;
  }
  searchStaffByCourse(ev: any) {
    var course_code = ev.target.value;
    //search staff list by course_code
    this.course_handling_staffs = [{
      staff_id: "SF001", staff_name: "Mr. AAA"
    }, { staff_id: "SF002", staff_name: "Mr. BBB" }]
  }
  async onProgrammeFilterSelectChange(id: string, event: any): Promise<void> {
    //this.onResetClick();
    if (id == "programme_id") {
      this.sections = [];
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.filter_form.get('section').setValue(this.sections[0].section_name);
        }
      }
      //API call to get course_position list based on programme_id and semester
    }


    if (id === 'programme_type_id' || id === 'finance_type') {
      this.filter_form.get('programme_id').setValue("");
      this.filter_form.get('part').setValue("");
      this.getProgrammeDataList(this.filter_form);
      if (id === 'programme_type_id' && this.filter_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.filter_form.value.programme_type_id);
      }
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'department_category_id' || id === 'finance_type') {
      this.filter_form.get('department_id').setValue("");
      this.filter_form.get('part').setValue("");
      this.getDepartmentDataList(this.filter_form);
      if (id === 'department_category_id' && this.filter_form.value.department_category_id) {
        this.parts = this.commonEnums.getParts(this.filter_form.value.department_category_id);
      }
    }
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }




  changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.programme_list = []; this.parts = [];
    this.table_data = {};
  }
  async onSearchClick() {
    this.table_data = {};
    var search_query: any = {};

    if (this.type == 'missing_student') {
      if (!this.filter_form.value.programme_type_id) {
        alert("Please choose Programme Type"); return;
      }
      if (!this.filter_form.value.finance_type) {
        alert("Please choose Finance Type"); return;
      }
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose Enrollment Year"); return;
      }
      if (!this.filter_form.value.programme_id) {
        alert("Please choose Programme Name"); return;
      }
      if (!this.filter_form.value.semester) {
        alert("Please choose Semester"); return;
      }
      search_query = {
        programme_type_id: this.filter_form.value.programme_type_id,
        finance_type: this.filter_form.value.finance_type,
        enrolled_year: this.filter_form.value.enrolled_year,
        programme_id: this.filter_form.value.programme_id,
        section: this.filter_form.value.section,
        semester: this.filter_form.value.semester,
      }
      if (this.filter_form.value.part) {
        search_query.part = this.filter_form.value.part;
      }
    }

    if (this.type == 'missing_staff') {
      if (!this.filter_form.value.department_category_id) {
        alert("Please choose Category Type"); return;
      }
      if (!this.filter_form.value.finance_type) {
        alert("Please choose Finance Type"); return;
      }
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose Enrollment Year"); return;
      }
      if (!this.filter_form.value.department_id) {
        alert("Please choose Department Name"); return;
      }
      if (!this.filter_form.value.semester) {
        alert("Please choose Semester"); return;
      }
      search_query = {
        department_id: this.filter_form.value.department_id,
        enrolled_year: this.filter_form.value.enrolled_year,
        semester: this.filter_form.value.semester,
      }
      if (this.filter_form.value.part) {
        search_query.part = this.filter_form.value.part;
      }
    }
    console.log(search_query);
    this.table_data_loading = true;
    try {
      //replace with search api
      /* const service_response = await this.restService.searchStudentCourseMapFilter(this.type, search_query);
      if (service_response && service_response.success) {
        console.log(service_response);
        this.table_data = service_response.data;
      } */
      if (this.type == 'missing_student') {
        this.table_data = {
          success: true,
          student_data: [{
            roll_no: "20PA01",
            given_name: "AAA",
            middle_name: "X",
            programme_name: "II MCA",
            section: "A"
          },
          {
            roll_no: "20PA02",
            given_name: "BBB",
            middle_name: "Y",
            programme_name: "II MCA",
            section: "A"
          },
          {
            roll_no: "20PA03",
            given_name: "CCC",
            middle_name: "Z",
            programme_name: "II MCA",
            section: "A"
          },
          ]
        };
      }
      if (this.type == 'missing_staff') {
        this.table_data = {
          success: true,
          staff_data: [{
            roll_no: "SF001",
            given_name: "AAA",
            middle_name: "X",
            department_name: "M.C.A.",
          },
          {
            roll_no: "SF0002",
            given_name: "BBB",
            middle_name: "Y",
            department_name: "M.C.A.",
          },
          {
            roll_no: "SF0003",
            given_name: "CCC",
            middle_name: "Z",
            department_name: "M.C.A.",
          },
          ]
        };
      }
    }
    catch (error) {
      console.log(error);
      alert(error);
    }
    finally {
      this.table_data_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id).programme_name;
  }
}
