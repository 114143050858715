<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Courses</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <!-- <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Course</button>
                </div> -->
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Courses</h4>
                            <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Courses</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                                        <select formControlName="programme_type_id" class="form-control" (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>

                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                                        <select formControlName="finance_type" class="form-control" (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme</label>
                                        <select formControlName="programme_id" class="form-control" (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select regulation year</label>
                                        <select formControlName="regulation_year" class="form-control">
                                            <option value="">Select regulation year</option>
                                            <option *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>




                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th>Year</th>
                                        <th>Part</th>
                                        <th>Course Type</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let course_data of course_data_list;let i=index;trackBy:fromDataResolver.identify;">

                                        <tr>
                                            <td colspan="8">
                                                <b>{{course_data.type}}</b>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="course_data.courses.length>0">


                                            <ng-container *ngFor="let course of course_data.courses;let i=index;trackBy:fromDataResolver.identify;">
                                                <tr>
                                                    <td scope="row">
                                                        {{i+1}}
                                                    </td>
                                                    <td class="text-uppercase">{{course.course_code}}</td>
                                                    <td class="text-uppercase">{{course.course_name}}</td>
                                                    <td class="text-uppercase">{{course.regulation_year}}</td>
                                                    <td class="text-uppercase">{{course.part}}</td>
                                                    <td class="text-uppercase">{{course.course_type}}</td>
                                                    <td>
                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                            <button type="button" class="btn btn-sm btn-info mr-2" title="Edit Course" (click)="onEditClick(course)">
                                                                <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-info mr-2" title="Manage CO PO mappings" (click)="onCOPOMapClick(course)">
                                                                <fa-icon [icon]="fonts.faChartLine"></fa-icon>
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-info mr-2" title="Manage Coursewares" (click)="onCoursewareClick(course)">
                                                                <fa-icon [icon]="fonts.faBookmark"></fa-icon>
                                                            </button>


                                                        </div>
                                                    </td>

                                                </tr>
                                            </ng-container>

                                        </ng-container>

                                        <ng-container *ngIf="course_data.courses.length==0">
                                            <tr>
                                                <th colspan="8" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="!course_list_loading && course_data_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_data_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="course_form && course">
                    <div class="modal-header">
                        <h6 class="modal-title" *ngIf="!course._id">Add New Course</h6>
                        <h6 class="modal-title" *ngIf="course._id">Update Course</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="course_form">
                                <div class="row">
                                    <ng-container *ngIf="!course._id">
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                                                <select formControlName="programme_type_id" class="form-control" (change)="onFilterSelectChange('programme_type_id',$event)">
                                                    <option value="">Select Programme Type</option>
                                                    <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="category.programme_type_id">{{category.name}}</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                                                <select formControlName="finance_type" class="form-control" (change)="onFilterSelectChange('finance_type',$event)">
                                                    <option value="">Select Finance Type</option>
                                                    <option *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme</label>
                                                <select formControlName="programme_id" class="form-control" (change)="onFilterSelectChange('programme_id',$event)">
                                                    <option value="">All Programmes</option>
                                                    <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Part</label>
                                                <select formControlName="part" class="form-control" (change)="onFilterSelectChange('part',$event)">
                                                    <option value="">All Parts</option>
                                                    <option *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="part.id">{{part.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-6" *ngIf="this.part_course_types && this.part_course_types.length>0">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Course Type</label>
                                                <select formControlName="course_type" class="form-control">
                                                    <option value="">All Course Type</option>
                                                    <option *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="course_type.id">{{course_type.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Year</label>
                                                <select formControlName="regulation_year" class="form-control">
                                                    <option value="">All Years</option>
                                                    <option *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </ng-container>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course Code</label>
                                            <input class="form-control" required="" type="text" formControlName="course_code">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course Name</label>
                                            <input class="form-control" required="" type="text" formControlName="course_name">
                                        </div>
                                    </div>



                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveClick()" *ngIf="!course_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <ng-container *ngIf="course._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()" *ngIf="!course_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="course_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </ng-container>

                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!-- Large Modal -->
        <div class="modal" id="modal-copomap">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="course_co_po_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage CO PO Map</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="course_co_po_form">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Copy Paste from MS Word or MS Excel</label>
                                    <textarea class="form-control" style="height: 200px;" formControlName="bulk_update"></textarea>
                                </div>

                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onExcelToJavasciptClick()">Convert</button>
                                </div>
                                <div class="mg-b-20"></div>
                                <div class="text-center" style="font-size: medium; font-weight: bold;">OR</div>
                                <div class="mg-b-20"></div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Fill CO PO Map</label>

                                </div>
                                <div class="row">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <ng-container *ngFor="let po of  po_total;let j=index;trackBy:fromDataResolver.identify;">
                                                        <th>PO {{j+1}}</th>
                                                    </ng-container>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let co of fromDataResolver.getFormArrayControlls(course_co_po_form,'co_po_map');let i=index;trackBy:fromDataResolver.identify;">
                                                    <tr>
                                                        <th scope="row">CO {{fromDataResolver.getValueFromFromGroup(co,"co_id")}}</th>


                                                        <ng-container *ngFor="let po of fromDataResolver.getFormArrayControlls(co,'do_data');let j=index;trackBy:fromDataResolver.identify;">

                                                            <ng-container [formGroup]="po">
                                                                <td>
                                                                    <div class="form-group">
                                                                        <input class="form-control" required="" type="text" formControlName="score" (blur)="valueChanged($event)">
                                                                    </div>
                                                                </td>


                                                            </ng-container>
                                                        </ng-container>



                                                    </tr>
                                                </ng-container>
                                                <tr>
                                                    <td class="font-bold">Weightage</td>
                                                    <ng-container *ngFor="let po of  po_total;let j=index;trackBy:fromDataResolver.identify;">
                                                        <td>{{po.score}}</td>
                                                    </ng-container>


                                                </tr>
                                                <tr *ngIf="this.course">
                                                    <td class="font-bold">Weighted Percentage of Course Contribution to POs</td>
                                                    <ng-container *ngFor="let co of this.course.co_po_map_contribution;let j=index;trackBy:fromDataResolver.identify;">
                                                        <td>{{co.contribution}}</td>
                                                    </ng-container>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onCOPOSaveClick()" *ngIf="!course_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <button class="btn ripple btn-secondary" (click)="onCOPOCloseClick()" type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Large Modal -->
        <div class="modal" id="modal-courseware">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="courseware_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Courseware</h6>
                    </div>
                    <div class="modal-body">
                        <div class="">

                            <form [formGroup]="courseware_form">
                                <div class="main-content-label mg-b-5">
                                    COURSE OUTCOMES
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;" formControlName="course_outcome"></textarea>
                                </div>

                                <ng-container *ngIf="course && course.course_type == 'PRACTICAL'">
                                    <div class="mg-b-20"></div>
                                    <div class="main-content-label mg-b-5">
                                        List of Practicals
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" style="height: 350px;" formControlName="practicals"></textarea>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="course && course.course_type != 'PRACTICAL'">
                                    <div class="mg-b-20"></div>
                                    <div class="main-content-label mg-b-5">
                                        Units and Topics
                                    </div>
                                    <div class="row">
                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Unit Name</th>
                                                        <th>Content</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let co of fromDataResolver.getFormArrayControlls(courseware_form,'course_units');let i=index;trackBy:fromDataResolver.identify;">
                                                        <ng-container [formGroup]="co">
                                                            <tr>
                                                                <th scope="row">Unit {{i+1}}</th>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <input class="form-control" required="" type="text" formControlName="title">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <textarea class="form-control" formControlName="course_topics_string"></textarea>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Tutorials
                                </div>
                                <div class="row">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th style="width: 5%;">S.No</th>
                                                    <!-- <th>Title</th> -->
                                                    <th>Activity</th>
                                                    <th>Unit Covered</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let co of fromDataResolver.getFormArrayControlls(courseware_form,'tutorials');let i=index;trackBy:fromDataResolver.identify;">
                                                    <ng-container [formGroup]="co">
                                                        <tr>
                                                            <th scope="row">{{i+1}}</th>
                                                            <!-- <td>
                                                                <div class="form-group">
                                                                    <input class="form-control" required="" type="text" formControlName="topic">
                                                                </div>
                                                            </td> -->
                                                            <td>
                                                                <div class="form-group">
                                                                    <input class="form-control" required="" type="text" formControlName="activity">
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="form-group">
                                                                    <input class="form-control" required="" type="text" formControlName="unit_covered">
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </ng-container>
                                                </ng-container>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Text Books
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;" formControlName="text_book"></textarea>
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Reference Books
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;" formControlName="reference_book"></textarea>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Web References
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;" formControlName="web_reference"></textarea>
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onCoursewareSaveClick()" *ngIf="!course_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <button class="btn ripple btn-secondary" (click)="onCoursewareCloseClick()" type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>