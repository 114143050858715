<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">




        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0 no-print">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Offline due</h4>
                        </div>
                    </div>

                    <div class="card-body">

                        <div class="table-responsive">
                            <table id="printable" class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center" style="width:5%;">S.No</th>
                                        <th class="text-center" class="no-print" style="width:10%;">Date.</th>

                                    </tr>
                                </thead>
                                <tbody>



                                    <ng-container *ngFor="let applicant of offline_due_list;let i=index;trackBy:formDataResolver.identify;">
                                        <tr>
                                            <td scope="row">
                                                {{i+1}}

                                            </td>
                                            <ng-container>
                                                <td class="no-print uppercase">{{applicant.updatedAt|date:commonEnums.date_format}}</td>
                                            </ng-container>

                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!offline_due_list_loading && offline_due_list.length==0">
                                        <tr>
                                            <th colspan="14" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="offline_due_list_loading && offline_due_list.length==0">
                                        <tr>
                                            <th colspan="14" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>



                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>

</div>
<!-- /main-content -->
<app-footer></app-footer>