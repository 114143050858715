import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-fine-settings',
  templateUrl: './fine-settings.component.html',
  styleUrls: ['./fine-settings.component.scss']
})
export class FineSettingsComponent implements OnInit {
  @ViewChild('limit') inputEl: ElementRef<HTMLInputElement> = {} as ElementRef;
  commonEnums = CommonEnums;
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.setting_name = params?.setting_name;
    });

    this.fine_setting_form = this.formBuilder.group({
      _id: [''],
      fine_name: ['', [Validators.required]],
      re_admission_fee: ['', [Validators.required]],
      day_limit: [''],
      fine_amount: [''],
      is_per_day: [false],
      post_fine_rule: ['', Validators.required]
    });
  }
  fonts = fonts;
  post_fine_rules: {
    id: string, name: string
  }[] = [
      {
        id: "DISALLOW-PAYMENT", name: "Disallow Payment"
      },
      {
        id: "FOLLOW-LAST-FINE-AMOUNT", name: "Follow last fine amount"
      },
    ]
  fine_list_loading: boolean = false;
  fine_list: FineRuleInterface[] = [];
  fine_rules: {
    day_limit: number, fine_amount: number, is_per_day: boolean
  }[] = [];
  fine_setting_form: FormGroup;
  setting_name: string = "";
  title = 'Fine Settings';
  department_list: any[] = [];
  department_list_loading: boolean = false;
  fromDataResolver = FromDataResolver;
  ngOnInit(): void {
    this.getFineList();
  }

  async getFineList() //get from API
  {
    try {
      this.fine_list_loading = true;
      this.fine_list = [];
      const service_response = await this.restService.getFineList();
      if (service_response && service_response.success) {
        this.fine_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.fine_list_loading = false;
    }

  }

  addRule() {
    this.fine_rules.push({
      day_limit: this.fine_setting_form.value.day_limit,
      fine_amount: this.fine_setting_form.value.fine_amount,
      is_per_day: this.fine_setting_form.value.is_per_day
    })
    this.fine_setting_form.get('day_limit')?.setValue(""); this.fine_setting_form.get('fine_amount')?.setValue("");
    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 0);
  }

  triggerFunction(event: any) {
    //console.log(event.key);
    if (event.ctrlKey && event.key === 'Enter') {
      this.onSave();
    }
  }
  async onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(fine: FineRuleInterface): Promise<void> {
    this.fine_setting_form.get('_id')?.setValue(fine._id);
    this.fine_setting_form.get('fine_name')?.setValue(fine.fine_name);
    this.fine_setting_form.get('re_admission_fee')?.setValue(fine.re_admission_fee);
    this.fine_setting_form.get('post_fine_rule')?.setValue(fine.post_fine_rule);
    this.fine_rules = fine.fine_rules
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.fine_rules = [];
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }
  isInvalid(formControl: string): boolean {
    return this.fine_setting_form.touched && this.fine_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.fine_setting_form.status !== 'VALID';
  }

  onSave(): void {
    this.addItem();
  }

  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  reset(): void {
    this.fine_setting_form.reset();
  }

  onDeleteFine(fine_index: number) {
    this.fine_rules.splice(fine_index, 1);
  }

  async addItem(): Promise<void> {
    try {
      if (this.fine_rules.length == 0) {
        alert("At least one fine rule must be added!")
        return;
      }
      var fine: FineRuleInterface = {} as FineRuleInterface;
      fine._id = this.fine_setting_form!.get('_id')!.value;
      fine.fine_name = this.fine_setting_form!.get('fine_name')!.value;
      fine.fine_rules = this.fine_rules;
      fine.re_admission_fee = this.fine_setting_form.get('re_admission_fee').value;
      fine.post_fine_rule = this.fine_setting_form!.get('post_fine_rule')!.value;
      if (this.fine_setting_form.get('_id').value) {
        const res = await this.restService.updateFineRule(this.fine_setting_form.value._id, fine);
        if (!res) {
          alert('Failed to update fine settings');
        }
        alert('Updated fine settings successfully');
      }
      else {
        const res = await this.restService.addFineRule(fine);
        if (!res) {
          alert('Failed to create exam settings');
        }
        alert('Created fine settings successfully');
      }
      this.getFineList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onDelete() {
    try {
      const consent = confirm("Are you sure want to delete this rule?");
      if (!consent) {
        return;
      }
      const res = await this.restService.deleteFineRule(this.fine_setting_form.value._id);
      if (!res) {
        alert('Failed to delete fine settings');
      }
      alert('Deleted fine settings successfully');
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
  }

}

interface FineRuleInterface {
  _id: string,
  fine_name: string,
  re_admission_fee: number,
  fine_rules: {
    day_limit: number,
    fine_amount: number,
    is_per_day: boolean
  }[],
  post_fine_rule: string
}
