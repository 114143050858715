<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" (click)="onAddClick()" class="btn btn-info"> Add Setting </button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Academic Year & Timetable Configurations</p>
                    </div>

                    <div class="card-body">
                        <div class="row row-sm">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Year</label>
                                    <select [(ngModel)]="search_year" class="form-control">
                                        <option value="0">Select Year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="year">{{year}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                                        *ngIf="!settings_list_loading" (click)="onSearchClick()">
                                        Get Settings
                                    </button>
                                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                        *ngIf="settings_list_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 50%;">Academic Year</th>
                                        <th style="width: 40%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="settings_list.length>0">
                                        <ng-container
                                            *ngFor="let setting of settings_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{setting.academic_year}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(setting)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="collection-modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="configuration_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Configuration</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="configuration_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Start Year</label>
                                            <select formControlName="year_start" class="form-control">
                                                <option value="0">Select Start Year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('year_start')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Starting Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                End Year</label>
                                            <select formControlName="year_end" class="form-control">
                                                <option value="0">Select End Year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getAcademicYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('year_end')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Starting Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="table-responsive" formArrayName="semesters">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style="width: 5%;">S.No</th>
                                                <th style="width: 10%;">Semester Name</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Attendance Mark Time Limit</th>
                                                <th>Late Entry Mark Time Limit</th>
                                                <th style="width: 15%;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let semester of semesterList; let i = index;"
                                                [formGroupName]="i">
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        formControlName="semester_name"
                                                        placeholder="Enter Semester Name">
                                                </td>
                                                <td>
                                                    <input class="form-control" required="" type="date"
                                                        formControlName="start_date">
                                                </td>
                                                <td>
                                                    <input class="form-control" required="" type="date"
                                                        formControlName="end_date">
                                                </td>
                                                <td>
                                                    <input class="form-control" required="" type="time"
                                                        formControlName="max_time_allowed">
                                                </td>
                                                <td>
                                                    <input class="form-control" required="" type="time"
                                                        formControlName="max_time_allowed_late_entry">
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-danger"
                                                        (click)="deleteSemester(i)">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="7" class="text-center">
                                                    <button class="btn btn-primary btn-sm" (click)="addSemester()">Add
                                                        Semester</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>




                                <div class="form-group">
                                    <input type="checkbox" formControlName="is_active" class="mg-r-5">Is Active?
                                    <div *ngIf="isInvalid('is_active')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Choose if the Academic Year is active for
                                                timetable or
                                                not.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Save &
                                        Close</button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()" *ngIf="setting_id"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>