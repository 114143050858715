import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-admission-news',
  templateUrl: './admission-news.component.html',
  styleUrls: ['./admission-news.component.scss']
})
export class AdmissionNewsComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  dropdownSettings: IDropdownSettings = {};
  title = 'Admission News';
  scroller_form: FormGroup = undefined;
  scroller_list_loading: boolean = false;
  scroller_save_loading: boolean = false;
  constructor(private fb: FormBuilder, private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.getScrollerMessages();
  }

  async getScrollerMessages() {
    try {
      this.scroller_list_loading = true;
      const response = await this.restService.getScrollerMessages();
      if (response.success && response.data) {
        const message_data = response.data;
        console.log(message_data);
        this.scroller_form = this.fb.group({
          messages: this.fb.array(this.buildScrollerForm(message_data))
        });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.scroller_list_loading = false;
    }
  }

  buildScrollerForm(messages: ScrollMessageInterface[]) {
    if (!messages) {
      messages = [];
    }
    var message_list_array: any[] = [];
    if (messages && messages.length > 0) {
      var message_count = 0;
      for (const message of messages) {
        //console.log(component)
        message_list_array.push(this.fb.group({
          message: [message.message],
          link: [message.link],
          is_active: [message.is_active],
          priority_no: [message.priority_no]
        }));
        message_count++;
      }
    }
    const remaining_count = 1 - messages.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        message_list_array.push(this.fb.group({
          message: [""],
          link: [""],
          is_active: [false],
          priority_no: [0]
        }));
      }
    }
    console.log(message_list_array);
    return message_list_array;
  }

  get messagesList() {
    return (this.scroller_form.get('messages') as FormArray).controls;
  }


  async uploadFile(e: any, i: number) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadScrollerMessageFile(formData);
      if (response.success && response.data) {
        alert("File Uploaded Successfully");
        (this.scroller_form.get('messages') as FormArray).controls[i].get('link').setValue(response.data.url);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  moveUp(current_index) {
    if (current_index == 0) {
      alert("Cannot move upward further!")
      return;
    }
    else {
      var upIndex = current_index - 1;
      var arr: any[] = ((this.scroller_form as FormGroup).get('messages') as FormArray).value;
      var element = arr[current_index];
      arr.splice(current_index, 1);
      arr.splice(upIndex, 0, element);
    }
    this.scroller_form = this.fb.group({
      messages: this.fb.array(this.buildScrollerForm(arr))
    });
    console.log(this.scroller_form.value);
  }

  moveDown(current_index) {
    var arr: any[] = ((this.scroller_form as FormGroup).get('messages') as FormArray).value;
    if (current_index == arr.length - 1) {
      alert("Cannot move donward further");
      return;
    }
    else {
      var downIndex = current_index + 1;
      var element = arr[current_index];
      arr.splice(current_index, 1);
      arr.splice(downIndex, 0, element);
    }
    this.scroller_form = this.fb.group({
      messages: this.fb.array(this.buildScrollerForm(arr))
    });
    console.log(this.scroller_form.value);
  }

  addScrollerMessage() {
    (this.scroller_form.get('messages') as FormArray).push(this.fb.group({
      message: [""],
      link: [""],
      is_active: [false],
      priority_no: [0]
    }));
  }

  removeScrollerMessage(i: number) {
    const consent = confirm("Are you sure want to remove this message? This action is irreversible!");
    if (!consent) {
      return;
    }
    (this.scroller_form.get('messages') as FormArray).removeAt(i);
  }

  async onSaveMessagesClick() {
    var form_data: ScrollerDataInterface = this.scroller_form.value;
    var priority = 1;
    form_data.messages.forEach(message => {
      message.priority_no = priority;
      priority++;
    });
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.scroller_save_loading = true;
      const saved_respose = await this.restService.createScrollerMessages(form_data);
      if (saved_respose.success) {
        alert('Messages Saved Successfully');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.scroller_save_loading = false;
    }

  }

}


interface ScrollerDataInterface {
  _id: string,
  messages: ScrollMessageInterface[]
}

interface ScrollMessageInterface {
  message: string,
  link: string,
  is_active: boolean,
  priority_no: number
}