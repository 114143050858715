import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';


@Component({
  selector: 'app-staff-assignment',
  templateUrl: './staff-assignment.component.html',
  styleUrls: ['./staff-assignment.component.scss']
})
export class StaffAssignmentComponent implements OnInit {
  //bulk render data
  bulk_render_data: Modal = {
    return_type: "FILE",
    modal_button_text: "Staff-Courses Bulk Upload",
    modal_header: "Staff-Courses Bulk Upload",
    list_name: "Staff-Courses Assign",
    csv_data: {
      columns: [
        { column_name: "enrolled_year", column_type: "required" },
        { column_name: "semester", column_type: "required" },
        { column_name: "roll_no", column_type: "required" },
        { column_name: "course_code", column_type: "required" },
        { column_name: "class_taking_programme", column_type: "required" },
        { column_name: "class_taking_section", column_type: "optional" },
        { column_name: "virtual_section", column_type: "optional" },
        { column_name: "is_primary", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["enrolled_year", "semester", "roll_no", "course_code", "class_taking_programme", "class_taking_section", "virtual_section", "is_primary"],
      column_values: ["2021", "1", "SF000", "20UAC101", "B.C.A.", "A", "", "TRUE"]
    },
    show_loader: false
  };
  title = 'Staff-Course Assignment';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;

  filter_form: FormGroup;
  staffassignForm: FormGroup;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  all_programme_list: any[] = [];
  department_list: any[] = [];
  department_list_loading = false;
  programme_section_list: any[] = [];
  sections: any = [];
  parts: any[] = [];
  public type: string = "roll_no_search";
  table_data: any = {};
  course_details_loading: boolean = false;
  map_delete_loading: boolean = false;
  table_data_loading: boolean = false;
  assign_save_loading: boolean = false;
  //modal variables
  course_data: any;
  map_programme_list: any[] = [];
  map_sections: any = [];
  has_virtual_sections: boolean = false;
  virtual_sections: any[] = [];
  category_list: any[] = [];
  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) {
    this.staffassignForm = this.formBuilder.group({
      _id: [''],
      //programme_type_id: ['', [Validators.required]],
      enrolled_year: ['', [Validators.required]],
      course_code: ['', [Validators.required]],
      semester: ['', [Validators.required]],
      roll_no: ['', [Validators.required]],
      class_taking_programme_id: [''],
      class_taking_section: [''],
      virtual_section: [''],
      is_primary: [false]
    });
  }

  ngOnInit(): void {
    this.getDepartmentCategoryList();
    this.getAllProgrammeDataList();
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  async getDepartmentCategoryList(): Promise<void> {
    try {
      this.category_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }
  }

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.all_programme_list = [];
      const search_form_query: any = {
        //is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
    }
  }

  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      roll_no: [''],
      course_code: [''],
      enrolled_year: [''],
      semester: [''],
      department_category_id: [''],
      finance_type: [''],
      department_id: [''],
      //section: [''],
      part: ['']
    });
  }

  isTableDataEmpty() {
    if (JSON.stringify(this.table_data) == '{}' || JSON.stringify(this.table_data) == '[]') {
      return true;
    }
    return false;
  }
  async onFilterSelectChange(id: string, event: any): Promise<void> {
    //this.onResetClick();
    if (id == "programme_id") {
      this.sections = [];
      const programme = this.department_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.filter_form.get('section').setValue(this.sections[0].section_name);
        }
      }
      //API call to get course_position list based on programme_id and semester
    }


    if (id === 'department_category_id' || id === 'finance_type') {
      this.filter_form.get('department_id').setValue("");
      this.filter_form.get('part').setValue("");
      this.getProgrammeDataList(this.filter_form);
      if (id === 'department_category_id' && this.filter_form.value.department_category_id) {
        this.parts = this.commonEnums.getParts(this.filter_form.value.department_category_id);
      }
    }
  }

  async getProgrammeDataList(form): Promise<void> {
    try {
      this.department_list_loading = true;
      this.department_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.department_category_id) {
          search_form_query.department_category_id = form.value.department_category_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
        console.log(this.department_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.department_list_loading = false;
    }
  }


  changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.department_list = []; this.parts = [];
    this.table_data = {};
  }

  async getMapProgrammeDataList(allowed_programmes: string[]): Promise<void> {
    try {
      console.log(allowed_programmes);
      this.map_programme_list = [];
      allowed_programmes.forEach(programme => {
        this.map_programme_list.push(this.all_programme_list.find(x => x.programme_id == programme));
      });
      console.log(this.map_programme_list);
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
    }
  }
  async onMapProgrammeSelectChange(event?: any): Promise<void> {
    this.map_sections = [];
    const programme = this.map_programme_list.find(x => x.programme_id == this.staffassignForm.value.class_taking_programme_id);
    if (programme && programme.section_details && programme.section_details.length > 0) {
      this.map_sections = programme.section_details;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  /* changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.table_data = [];
  } */
  async onSearchClick() {
    this.table_data = {};
    var search_query: any = {};
    if (this.type == 'roll_no_search') {
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose valid Enrolled Year"); return;
      }
      if (!this.filter_form.value.roll_no) {
        alert("Please enter valid Roll No."); return;
      }
      search_query.enrolled_year = this.filter_form.value.enrolled_year;
      search_query.roll_no = this.filter_form.value.roll_no;
    }
    if (this.type == 'course_code_search') {
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose valid Enrolled Year"); return;
      }
      if (!this.filter_form.value.course_code) {
        alert("Please enter valid Course Code"); return;
      }
      search_query.enrolled_year = this.filter_form.value.enrolled_year;
      search_query.course_code = this.filter_form.value.course_code;
    }

    if (this.type == 'programme_search') {
      if (!this.filter_form.value.department_category_id) {
        alert("Please choose Category Type"); return;
      }
      if (!this.filter_form.value.finance_type) {
        alert("Please choose Finance Type"); return;
      }
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose Enrollment Year"); return;
      }
      if (!this.filter_form.value.department_id) {
        alert("Please choose Department Name"); return;
      }
      if (!this.filter_form.value.semester) {
        alert("Please choose Semester"); return;
      }
      search_query = {
        /* department_category_id: this.filter_form.value.department_category_id,
        finance_type: this.filter_form.value.finance_type, */
        department_id: this.filter_form.value.department_id,
        enrolled_year: this.filter_form.value.enrolled_year,
        //section: this.filter_form.value.section,
        semester: this.filter_form.value.semester,
      }
      if (this.filter_form.value.part) {
        search_query.part = this.filter_form.value.part;
      }
    }
    console.log(search_query);
    this.table_data_loading = true;
    try {
      const service_response = await this.restService.searchStaffCourseMapFilter(this.type, search_query);
      if (service_response && service_response.success) {
        console.log(service_response);
        this.table_data = service_response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }


  //Modal Methods
  onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async getCourseDetails(ev?: any, search_course_code?: string) {
    try {
      var course_code;
      if (search_course_code) {
        course_code = search_course_code;
      }
      else {
        course_code = ((ev.target) as HTMLInputElement).value;
        this.staffassignForm.get('class_taking_programme_id').setValue("");
        this.staffassignForm.get('class_taking_section').setValue("");
      }
      const search_query = {
        //"programme_type_id": this.staffassignForm.value.programme_type_id,
        "enrolled_year": this.staffassignForm.value.enrolled_year,
        "course_code": course_code,
        "semester": this.staffassignForm.value.semester
      }
      this.course_details_loading = true;
      const response_data = await this.restService.getCourseDetailsForStaffAssignment(search_query);
      console.log(response_data);
      console.log(this.staffassignForm.value);
      if (response_data.success) {
        this.course_data = response_data.data;
        if ((this.course_data.part == "PART_I") || (this.course_data.part == "PART_II") ||
          (this.course_data.part == "PART_III" && this.course_data.course_type == "ELECTIVE") ||
          (this.course_data.part == "PART_IV" && this.course_data.course_type == "NON_MAJOR_ELECTIVE") ||
          (this.course_data.part == "PART_IV" && this.course_data.course_type == "SKILL_BASED_ELECTIVE") ||
          (this.course_data.part == "PART_V") ||
          (this.course_data.part == "ELECTIVE") ||
          (this.course_data.part == "SUPPORTIVE_COURSES" && this.course_data.course_type == "SUPPORTIVE_COURSES_1")
        ) {
          this.has_virtual_sections = true;
          this.virtual_sections = this.course_data.advanced_settings.virtual_sections;
        }
        else {
          this.has_virtual_sections = false;
          this.getMapProgrammeDataList(this.course_data.allowed_programme_ids);
          this.onMapProgrammeSelectChange();
          this.staffassignForm.updateValueAndValidity();
          console.log(this.staffassignForm.value);
        }
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      console.log(error);
    }
    finally {
      this.course_details_loading = false;
    }
  }

  hasVirtualSection(course_data): boolean {
    if ((course_data.part == "PART_I") || (course_data.part == "PART_II") ||
      (course_data.part == "PART_III" && course_data.course_type == "ELECTIVE") ||
      (course_data.part == "PART_IV" && course_data.course_type == "NON_MAJOR_ELECTIVE") ||
      (course_data.part == "PART_IV" && course_data.course_type == "SKILL_BASED_ELECTIVE") ||
      (course_data.part == "PART_V") ||
      (course_data.part == "ELECTIVE") ||
      (course_data.part == "SUPPORTIVE_COURSES" && course_data.course_type == "SUPPORTIVE_COURSES_1")) {
      return true;
    }
    return false;
  }

  /* onEditStaffMap(_id: string, data: any) {
    var form_data: any = {
      _id: _id,
      enrolled_year: "",
      course_code: "",
      semester: "",
      roll_no: "",
      virtual_section: "",
      class_taking_programme_id: "",
      class_taking_section: "",
      is_primary: (data.is_primary) ? true : false,
    }
    this.staffassignForm.setValue(form_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  } */


  onEditByRollNo(staff_data: any) {
    console.log(staff_data);
    var form_data: any = {
      _id: staff_data._id,
      enrolled_year: staff_data.enrolled_year,
      course_code: staff_data.course_code,
      semester: staff_data.semester,
      roll_no: staff_data.roll_no,
      is_primary: staff_data.is_primary,
    }
    if (this.hasVirtualSection(staff_data)) {
      form_data.virtual_section = staff_data.virtual_section
      form_data.class_taking_programme_id = "";
      form_data.class_taking_section = "";
    }
    else {
      form_data.class_taking_programme_id = staff_data.class_taking_programme_id
      form_data.class_taking_section = staff_data.class_taking_section
      form_data.virtual_section = "";
    }
    this.staffassignForm.setValue(form_data);
    this.getCourseDetails("", staff_data.course_code);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditByCourseCode(staff_data: any, table_data: any) {
    console.log(staff_data);
    console.log("table data", table_data);
    const form_data: any = {
      _id: staff_data._id,
      enrolled_year: staff_data.enrolled_year,
      course_code: staff_data.course_code,
      semester: staff_data.semester,
      roll_no: staff_data.roll_no,
      is_primary: staff_data.is_primary,
    }
    if (this.hasVirtualSection(table_data)) {
      form_data.virtual_section = staff_data.virtual_section
      form_data.class_taking_programme_id = "";
      form_data.class_taking_section = "";
    }
    else {
      form_data.class_taking_programme_id = staff_data.class_taking_programme_id
      form_data.class_taking_section = staff_data.class_taking_section
      form_data.virtual_section = "";
    }
    this.staffassignForm.setValue(form_data);
    this.getCourseDetails("", staff_data.course_code);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditByProgramme(_id: string, roll_no: string, staff_data: any) {
    console.log(staff_data);
    var form_data: any = {
      _id: _id,
      enrolled_year: staff_data.enrolled_year, //need to be within staff_data
      course_code: staff_data.course_code,
      semester: staff_data.semester, //need to be within staff_data
      roll_no: roll_no,
      is_primary: (staff_data.is_primary) ? true : false,
    }
    if (this.hasVirtualSection(staff_data)) {
      form_data.virtual_section = staff_data.virtual_section
      form_data.class_taking_programme_id = "";
      form_data.class_taking_section = "";
    }
    else {
      form_data.class_taking_programme_id = staff_data.programme_id
      form_data.class_taking_section = staff_data.section
      form_data.virtual_section = "";
    }
    this.staffassignForm.setValue(form_data);
    this.getCourseDetails("", staff_data.course_code);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  isInvalid(formControl: any): boolean {
    return this.staffassignForm.touched && this.staffassignForm.get(formControl)?.errors !== null;
  }
  canDisable(): boolean {
    if (this.staffassignForm.value._id) return false;
    return this.staffassignForm.status !== 'VALID';
  }
  reset(): void {
    this.course_data = undefined; this.has_virtual_sections = false; this.map_programme_list = []; this.map_sections = [];
    this.staffassignForm.reset();
    Object.keys(this.staffassignForm.controls).forEach(control => { this.staffassignForm.get(control).setValue("") });
    this.staffassignForm.get('is_primary').setValue(false);
  }
  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  onSave() {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  async addItem(): Promise<void> {
    try {
      var map_data: any;
      let save_respose;
      if (this.has_virtual_sections) {
        if (this.staffassignForm.get('virtual_section').value == "") {
          alert("Please Choose a valid Virtual Section"); return;
        }
      }
      else {
        if (this.staffassignForm.get('class_taking_programme_id').value == "") {
          alert("Please Choose a valid Programme Name"); return;
        }
        if (this.staffassignForm.get('class_taking_section').value == "") {
          alert("Please Choose a valid Section"); return;
        }
      }
      // create or update record
      if (this.staffassignForm.value._id) //edit mode --> _id and is_primary only
      {
        map_data = {
          _id: this.staffassignForm.value._id,
          is_primary: this.staffassignForm.value.is_primary,
        }
        if (this.has_virtual_sections) {
          map_data.virtual_section = this.staffassignForm.value.virtual_section;
        }
        else {
          map_data.class_taking_programme_id = this.staffassignForm.value.class_taking_programme_id;
          map_data.class_taking_section = this.staffassignForm.value.class_taking_section;
        }
      }
      else //add mode --> entire form value
      {
        map_data = this.staffassignForm.value;
        if (this.has_virtual_sections) {
          delete map_data.class_taking_programme_id; delete map_data.class_taking_section;
        }
        else {
          delete map_data.virtual_section;
        }
      }
      console.log(map_data);
      this.assign_save_loading = true;
      save_respose = await this.restService.addStaffCourseMap(map_data);
      if (save_respose.success) {
        if (this.staffassignForm.value._id) {
          alert('Updated Successfully');
        }
        else {
          alert('Saved Successfully');
        }
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.assign_save_loading = false;
    }
  }

  async onDelete() {
    //delete
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      this.map_delete_loading = true;
      const save_respose = await this.restService.deleteStaffCourseMap(this.staffassignForm.value._id); //--> new api
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Deleted Successfully');
        this.onCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.map_delete_loading = false;
    }
    this.onCloseClick();
  }

  async bulkUpload(e: any) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_render_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.staffCourseBulkUpload(formData);
      if (response.success && response.data && response.data.url) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_data.show_loader = false;
    }
  }

  downloadFile(file_type: string) {
    const data_list: any[] = [];
    let i = 1;
    if (this.type == 'roll_no_search') {
      for (const data of this.table_data) {
        data_list.push({
          "S_No": i,
          "Course_Code": data.course_code,
          "Course_Name": data.course_name,
          "Programme_Name": this.getProgrammeName(data.class_taking_programme_id),
          "Section": data.class_taking_section
        });
        i++;
      }
      if (file_type == 'CSV') {
        CSVHelper.downloadCSV(data_list, 'Courses taken by ' + this.filter_form.value.roll_no + ' ' + DateHelper.convertToControlDate(new Date()));
      }
      else {
        const arr = data_list.map(d => [d.S_No, d.Course_Code, d.Course_Name, d.Programme_Name, d.Section])
        const doc = new jsPDF()
        autoTable(doc, {
          head: [['S.No', 'Course Code', 'Course Name', 'Programme Name', 'Section']],
          body: arr,
          theme: 'grid'
        })
        doc.save('Courses taken by ' + this.filter_form.value.roll_no + ' ' + DateHelper.convertToControlDate(new Date()) + '.pdf')
      }
    }
    if (this.type == 'course_code_search') {
      for (const data of this.table_data) {
        data_list.push({
          "S_No": i,
          "Roll_No": data.staff.roll_no,
          "Name": data.staff.given_name + " " + data.staff.middle_name,
          "Programme_Name": this.getProgrammeName(data.class_taking_programme_id),
          "Section": data.class_taking_section
        });
        i++;
      }
      if (file_type == 'CSV') {
        CSVHelper.downloadCSV(data_list, 'Staff handling ' + this.filter_form.value.course_code + ' ' + DateHelper.convertToControlDate(new Date()));
      }
      else {
        const arr = data_list.map(d => [d.S_No, d.Roll_No, d.Name, d.Programme_Name, d.Section])
        const doc = new jsPDF()
        autoTable(doc, {
          head: [['S.No', 'Roll No', 'Name', 'Programme Name', 'Section']],
          body: arr,
          theme: 'grid'
        })
        doc.save('Staff handling ' + this.filter_form.value.course_code + ' ' + DateHelper.convertToControlDate(new Date()) + '.pdf')
      }
    }
  }




}


