import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';
import { EditorComponent } from '@progress/kendo-angular-editor';
@Component({
  selector: 'app-add-quiz-questions',
  templateUrl: './add-quiz-questions.component.html',
  styleUrls: ['./add-quiz-questions.component.scss']
})
export class AddQuizQuestionsComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Upload Quiz Questions';

  quiz_questions_loading: boolean = false;
  quiz_questions: IQuestion[] = [];
  question: IQuestion = {} as IQuestion;
  question_form: FormGroup = undefined;

  save_loading: boolean = false;
  delete_loading: boolean = false;
  delete_question_loading: boolean[] = [];
  cookieStore = CookieStore;

  question_editor: any = "";
  edit_question_index: any = {};
  question_save_loading: boolean = false;
  total_questions: number = 0;
  common_question_mark: number = 0;
  common_negative_mark: number = 0;
  question_header_types: { id: string, text: string }[] = [{ id: 'SINGLE', text: 'Normal' }, { id: 'COMP', text: 'Comprehension' }];
  co_k_map: {
    co_id: number,
    k_id: number[]
  }[] = [{
    co_id: 1, k_id: [1, 2]
  }, { co_id: 2, k_id: [3] }, { co_id: 3, k_id: [4] }, { co_id: 4, k_id: [5] }, { co_id: 5, k_id: [6] }]
  question_types: { id: string, text: string }[] = [{ id: "MCQ", text: "Multiple Choice" }, { id: "BLANK", text: "Fill in the blank" }];
  option_string: { id: number, text: string }[] = [
    { id: 1, text: "a" }, { id: 2, text: "b" }, { id: 3, text: "c" }, { id: 4, text: "d" }, { id: 5, text: "e" },
    { id: 6, text: "f" }, { id: 7, text: "g" }, { id: 8, text: "h" }, { id: 9, text: "h" }, { id: 10, text: "i" },
  ];
  question_counter: number = 0;
  editor_config: IEditorConfig = {} as IEditorConfig;
  ngOnInit(): void {
  }
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router,
    private cdr: ChangeDetectorRef) {
    this.buildForm();
  }

  //overall form
  buildForm(): void {
    //load questions from api
    this.question.questions = [];
    this.question_form = this.formBuilder.group({
      questions: this.formBuilder.array(this.buildQuestionsHeaderForm(this.question.questions))
    });
    console.log(this.question_form.value);
    this.generateQuestionNumber();
  }

  //questions form
  buildQuestionsHeaderForm(questions: IQuizQuestion[]): any {
    if (!questions) {
      questions = [];
    }
    var questions_array: any[] = [];
    if (questions && questions.length > 0) {
      var questions_count = 0;
      for (const question of questions) {
        questions_array.push(this.formBuilder.group({
          question_type_header: [question.question_type_header, [Validators.required]],
          section_common_question: [question.section_common_question],
          section_common_image: [question.section_common_image],
          question_data: this.formBuilder.array(this.buildQuestionForm(question.question_data))
        }));
        this.delete_question_loading[questions_count] = false;
        questions_count++;
      }
    }
    const remaining_count = 1 - questions.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        questions_array.push(this.formBuilder.group({
          question_type_header: ['SINGLE', [Validators.required]],
          section_common_question: [''],
          section_common_image: [''],
          question_data: this.formBuilder.array(this.buildQuestionForm([]))
        }));
      }
    }
    console.log(questions_array);
    return questions_array;
  }

  get questionsHeader(): FormGroup[] {
    console.log(((this.question_form as FormGroup).get('questions') as FormArray).controls);
    return (((this.question_form as FormGroup).get('questions') as FormArray).controls as FormGroup[]);
  }

  questionTypeHeaderChanged(event, i: number) {
    const select_element_value = (event.target as HTMLSelectElement).value;
    if (select_element_value == 'SINGLE') {
      var question_array = ((((this.question_form as FormGroup).get('questions') as FormArray).controls[i] as FormGroup).
        get('question_data') as FormArray)
      var question_controls = question_array.controls;
      if (question_controls.length > 1) {
        const consent = confirm("Question Category contains more than 1 question. Changing Question Header Type will remove remaining questions. This action is irreversible!");
        if (consent) {
          var original_arr_length: number = question_controls.length;
          for (var index = 1; index < original_arr_length; index++) {
            question_array.removeAt(i);
            this.total_questions--;
          }
        }
      }
      this.generateQuestionNumber();
    }
  }

  addQuestionHeader() {
    ((this.question_form as FormGroup).get('questions') as FormArray).push(this.formBuilder.group({
      question_type_header: ['SINGLE', [Validators.required]],
      section_common_question: [''],
      section_common_image: [''],
      question_data: this.formBuilder.array(this.buildQuestionForm([]))
    }));
    this.generateQuestionNumber();
  }

  async deleteQuestionHeader(index: number) {
    const consent = confirm("Are you sure want to delete this question?");
    if (!consent) {
      return;
    }
    try {
      this.delete_question_loading[index] = true;
      const question_id = ((this.question_form as FormGroup).get('questions') as FormArray).at(index).value._id;
      const res = await this.restService.deleteSkill(question_id);
      if (res.success) {
        alert("Question removed successfully");
        ((this.question_form as FormGroup).get('questions') as FormArray).removeAt(index);
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_question_loading[index] = false;
    }
  }


  //question form
  buildQuestionForm(questions_data: IQuestionData[]): any {
    if (!questions_data) {
      questions_data = [];
    }
    var questions_array: any[] = [];
    if (questions_data && questions_data.length > 0) {
      var questions_count = 0;
      for (const question_data of questions_data) {
        questions_array.push(this.formBuilder.group({
          _id: [question_data._id],
          co_id: [question_data.co_id, [Validators.required]],
          k_id: [question_data.k_id, [Validators.required]],
          question_type: [question_data.question_type, [Validators.required]],
          question_text: [question_data.question_text, [Validators.required]],
          question_url: [question_data.question_url, [Validators.required]],
          question_mark: [question_data.question_mark, [Validators.required]],
          negative_mark: [question_data.negative_mark, [Validators.required]],
          options: this.formBuilder.array(this.buildOptionForm(question_data.options)),
          correct_answers: [question_data.correct_answers], //option_index (for MCQ) or list of all possible correct answers (for BLANK)
          question_number: [(question_data.question_number ? question_data.question_number : 0)]
        }));
        this.delete_question_loading[questions_count] = false;
        this.total_questions++;
        questions_count++;
      }
    }
    const remaining_count = 1 - questions_data.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        questions_array.push(this.formBuilder.group({
          _id: [''],
          co_id: [0, [Validators.required]],
          k_id: [0, [Validators.required]],
          question_type: ['MCQ', [Validators.required]],
          question_text: ['', [Validators.required]],
          question_url: ['', [Validators.required]],
          question_mark: [((this.common_question_mark != 0) ? this.common_question_mark : 0), [Validators.required]],
          negative_mark: [((this.common_negative_mark != 0) ? this.common_negative_mark : 0), [Validators.required]],
          options: this.formBuilder.array(this.buildOptionForm([])),
          correct_answers: [''], //option_index (for MCQ) or list of all possible correct answers (for BLANK)
          question_number: [0]
        }));
        this.total_questions++;
      }
    }
    console.log(questions_array);
    return questions_array;
  }

  generateQuestionNumber() {
    var question_number: number = 0;
    ((this.question_form as FormGroup).get('questions') as FormArray).controls.forEach(question_header => {
      (question_header.get('question_data') as FormArray).controls.forEach(question_data => {
        question_number++;
        question_data.get('question_number').setValue(question_number);
      })
    })
  }

  getQuestionsList(index: number): FormGroup[] {
    return (((((this.question_form as FormGroup).get('questions') as FormArray).controls[index] as FormGroup).get('question_data') as FormArray).controls as FormGroup[]);
  }

  addQuestion(index: number) {
    ((((this.question_form as FormGroup).get('questions') as FormArray).controls[index] as FormGroup).get('question_data') as FormArray).push(this.formBuilder.group({
      _id: [''],
      co_id: [0, [Validators.required]],
      k_id: [0, [Validators.required]],
      question_type: ['MCQ', [Validators.required]],
      question_text: ['', [Validators.required]],
      question_url: ['', [Validators.required]],
      question_mark: [((this.common_question_mark != 0) ? this.common_question_mark : 0), [Validators.required]],
      negative_mark: [((this.common_negative_mark != 0) ? this.common_negative_mark : 0), [Validators.required]],
      options: this.formBuilder.array(this.buildOptionForm([])),
      correct_answers: [''],
      question_number: [0]
    }));
    this.total_questions++;
    this.generateQuestionNumber();
  }

  async deleteQuestion(i_index: number, j_index: number) {
    if (((((this.question_form as FormGroup).get('questions') as FormArray).controls[i_index] as FormGroup).get('question_data') as FormArray).controls.length == 1) {
      alert("At least one question is required under each category");
      return;
    }
    const consent = confirm("Are you sure want to delete this question? This action is irreversible!");
    if (!consent) {
      return;
    }
    try {
      /* this.delete_question_loading[index] = true;
      const question_id = ((this.question_form as FormGroup).get('questions') as FormArray).at(index).value._id;
      const res = await this.restService.deleteSkill(question_id); */
      /* if (res.success) {
        
      } */
      this.total_questions--;
      alert("Question removed successfully");
      return ((((this.question_form as FormGroup).get('questions') as FormArray).controls[i_index] as FormGroup).get('question_data') as FormArray).removeAt(j_index);
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_question_loading[i_index] = false;
    }
  }

  //options form
  buildOptionForm(options_data: IOption[]): any {
    if (!options_data) {
      options_data = [];
    }
    var options_array: any[] = [];
    if (options_data && options_data.length > 0) {
      var options_count = 0;
      for (const option of options_data) {
        options_array.push(this.formBuilder.group({
          option_index: [option.option_index, [Validators.required]],
          option_text: [option.option_text, [Validators.required]],
          option_url: [option.option_url, [Validators.required]],
          //is_correct: [option.is_correct]
        }));
        options_count++;
      }
    }
    const remaining_count = 1 - options_data.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        options_array.push(this.formBuilder.group({
          option_index: [1, [Validators.required]],
          option_text: ['', [Validators.required]],
          option_url: [''],
          //is_correct: [false]
        }));
      }
    }
    console.log(options_array);
    return options_array;
  }

  getOptionsList(i: number, j: number): FormGroup[] {
    return ((((((this.question_form as FormGroup).get('questions') as FormArray).controls[i] as FormGroup).
      get('question_data') as FormArray).controls[j] as FormGroup).get('options') as FormArray).controls as FormGroup[];
  }

  addOption(i: number, j: number) {
    var options_count = ((((((this.question_form as FormGroup).get('questions') as FormArray).controls[i] as FormGroup).
      get('question_data') as FormArray).controls[j] as FormGroup).get('options') as FormArray).controls.length;
    ((((((this.question_form as FormGroup).get('questions') as FormArray).controls[i] as FormGroup).
      get('question_data') as FormArray).controls[j] as FormGroup).get('options') as FormArray).push(this.formBuilder.group({
        option_index: [options_count + 1, [Validators.required]],
        option_text: ['', [Validators.required]],
        option_url: [''],
        //is_correct: [false]
      }));
  }

  async deleteOption(i: number, j: number, k: number) {
    const consent = confirm("Are you sure want to delete this option?");
    if (!consent) {
      return;
    }
    try {
      ((((((this.question_form as FormGroup).get('questions') as FormArray).controls[i] as FormGroup).
        get('question_data') as FormArray).controls[j] as FormGroup).get('options') as FormArray).removeAt(k)
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
    }
  }

  openEditor(form: FormGroup, key: string, i_index?: number, j_index?: number) {
    this.editor_config.form = form; this.editor_config.key = key;
    if (i_index) {
      this.editor_config.i = i_index;
    }
    if (j_index) {
      this.editor_config.j = j_index;
    }
    if (form.get(key).value) {
      this.question_editor = form.get(key).value;
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async saveQuestion() {
    console.log(this.question_editor);
    const val = this.editor_config.form.get(this.editor_config.key);
    if (val) {
      val.setValue(this.question_editor);
    }
    await this.saveQuestionPaper();
  }

  saveAndCloseQuestion() {
    this.saveQuestion();
    this.closeQuestion();
  }
  closeQuestion() {
    this.question_editor = "";
    JQueryHelper.closeModal('#modal-popup');
  }

  isDisabled() {
    return this.question_editor ? false : true;
  }

  async saveQuestionPaper() {

  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.question_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.question_form.value;
      console.log(form_data);
      /* this.save_loading = true;
      if (this.question_form.value._id) {
        const res = await this.restService.updateSkillset(this.question_form.value._id, form_data);
        if (res) {
          alert("Skillset updated successfully.");
        }
      }
      else {
        const res = await this.restService.createSkillset(form_data);
        if (res) {
          alert("Skillset added successfully.");
        }
      } */
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteSkillset(this.question_form.get('_id')!.value);
      if (res.success) {
        alert("Skillset removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.question_form.touched && this.question_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.question_form.status !== 'VALID';
  }


  async uploadImage(e: any, form: FormGroup, key: string): Promise<void> {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadFile(formData);
      if (response.success && response.data && response.data.url) {
        const val = form.get(key);
        if (val) {
          val.setValue(response.data.url);
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  coChanged(ev, i: number, j: number, question) {
    console.log(ev.target.value);
    (((this.question_form.get('questions') as FormArray).controls[i]).get('question_data') as FormArray).controls[j].get('co_id').setValue(ev.target.value);
    this.question_form.updateValueAndValidity();
  }

  getKLevels(i: number, j: number, question) {
    var co_id = (((this.question_form.get('questions') as FormArray).controls[i]).get('question_data') as FormArray).controls[j].get('co_id').value;
    if (co_id == 0) return [];
    var k_ids: number[] = this.co_k_map.find(x => x.co_id == co_id).k_id;
    if (k_ids.length == 1) {
      (((this.question_form.get('questions') as FormArray).controls[i]).get('question_data') as FormArray).controls[j].get('k_id').setValue(k_ids[0]);
    }
    this.question_form.updateValueAndValidity();
    return k_ids;
  }

  questionTypeChanged(event, i, j, question) {

  }

  getOptionString(index: number) {
    return this.option_string.find(x => x.id == index)?.text;
  }

  getOptionsChooseList(i: number, j: number) {
    var options_length: number = ((((((this.question_form as FormGroup).get('questions') as FormArray).controls[i] as FormGroup).
      get('question_data') as FormArray).controls[j] as FormGroup).get('options') as FormArray).length;
    var options_arr: {
      id: number, text: string
    }[] = [];
    for (var i = 0; i < options_length; i++) {
      options_arr.push(this.option_string.find(x => x.id == i + 1))
    }
    return options_arr;
  }

  setCommonQuestionMark() {
    const consent = confirm("Are you sure want to set common mark for all questions? This will apply to all created questions!");
    if (!consent) {
      this.common_question_mark = 0;
      return;
    }
    ((this.question_form as FormGroup).get('questions') as FormArray).controls.forEach(question_header => {
      (question_header.get('question_data') as FormArray).controls.forEach(question_data => {
        question_data.get('question_mark').setValue(this.common_question_mark);
      })
    });
  }

  setCommonNegativeMark() {
    const consent = confirm("Are you sure want to set common negative mark for all questions? This will apply to all created questions!");
    if (!consent) {
      this.common_negative_mark = 0;
      return;
    }
    ((this.question_form as FormGroup).get('questions') as FormArray).controls.forEach(question_header => {
      (question_header.get('question_data') as FormArray).controls.forEach(question_data => {
        question_data.get('negative_mark').setValue(this.common_negative_mark);
      })
    });
  }

}


interface IQuestion {
  questions: IQuizQuestion[]
}

interface IQuizQuestion // passed in params
{
  question_type_header: string, //SINGLE, COMP
  section_common_question?: string,
  section_common_image?: string,
  question_data: IQuestionData[]
}

interface IQuestionData {
  _id: string,
  co_id: string,
  k_id: number,
  question_type: string //MCQ, BLANK
  question_text: string,
  question_url?: string,
  options: IOption[]
  correct_answers: string //option_index (for MCQ) or list of all possible correct answers (for BLANK)
  question_mark: number,
  negative_mark: number,
  question_number?: number
}

interface IOption {
  option_index: string,
  option_text: string,
  option_url?: string,
  //is_correct?: boolean
}

interface IEditorConfig {
  form: FormGroup,
  key: string,
  i?: number,
  j?: number
}