import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-non-skillset-master',
  templateUrl: './non-skillset-master.component.html',
  styleUrls: ['./non-skillset-master.component.scss']
})
export class NonSkillsetMasterComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Non-Academic Attainment Skillset';

  skillset_list_loading: boolean = false;
  skillset_list: IOBENonAcademicSkillsetMaster[] = [];
  skillset: IOBENonAcademicSkillsetMaster = {} as IOBENonAcademicSkillsetMaster;
  skillset_form: FormGroup = {} as FormGroup;
  skillset_id: string = "";
  dropdownSettings: IDropdownSettings = {};
  save_loading: boolean = false;
  delete_loading: boolean = false;
  delete_skill_loading: boolean[] = [];
  cookieStore = CookieStore;
  bulk_skills_data: Modal = {
    modal_id: "bulk_upload_common_modal",
    modal_button_text: "Skills Bulk Upload",
    modal_header: "Skills Bulk Upload",
    list_name: "Skills Upload",
    return_type: "FILE",
    download_note: "Enter Skillset name exactly as created",
    csv_data: {
      columns: [
        { column_name: "skillset_name", column_type: "required" },
        { column_name: "skill_name", column_type: "required" },
        { column_name: "max_point", column_type: "required" },
      ],
      row_data: "",
    },
    table_data: {
      column_names: ["Skill Set Name", "Skill Name.", "Max Point"],
      column_values: ["Interpersonal Skills", "Story Telling", "4"]
    }
  };
  ngOnInit(): void {
    this.getSkillSetList();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }



  async getSkillSetList() {
    try {
      this.skillset_list_loading = true;
      this.skillset_list = [];
      const service_response = await this.restService.getSkillSetList();
      if (service_response && service_response.success) {
        this.skillset_list = service_response.data;
      }
      /* this.skillset_list = [{
        skillset_name: "Interpersonal Skills",
        max_point: 20,
        skills: [{
          skill_name: "Story Telling", max_point: 4
        },
        {
          skill_name: "Being Class Representatives", max_point: 5
        }
        ]
      }] */
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.skillset_list_loading = false;
    }

  }


  buildForm(): void {
    if (JSON.stringify(this.skillset) == '{}') {
      this.skillset = {
        _id: "",
        skillset_name: "",
        skillset_max_point: 0,
        skills: [],
        reference_skills: "",
        applicable_enrolled_years: [],
        order: 0,
      };
    }
    this.skillset_form = this.formBuilder.group({
      _id: [this.skillset._id],
      skillset_name: [this.skillset.skillset_name, [Validators.required]],
      skillset_max_point: [this.skillset.skillset_max_point, [Validators.required]],
      // applicable_enrolled_years: [this.skillset.applicable_enrolled_years],
      order: [this.skillset.order],
      reference_skills: [this.skillset.reference_skills],
      skills: this.formBuilder.array(this.buildSkillsForm(this.skillset.skills)),

    });
    console.log(this.skillset_form);
  }

  buildSkillsForm(skills: IOBENonAcademicSkills[]): any {
    if (!skills) {
      skills = [];
    }
    var skills_array: any[] = [];
    if (skills && skills.length > 0) {
      var skills_count = 0;
      for (const skill of skills) {
        skills_array.push(this.formBuilder.group({
          _id: [skill._id],
          skill_name: [skill.skill_name, [Validators.required]],
          max_point: [skill.max_point, [Validators.required]],
        }));
        this.delete_skill_loading[skills_count] = false;
        skills_count++;
      }
    }
    const remaining_count = 1 - skills.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        skills_array.push(this.formBuilder.group({
          skill_name: ["", [Validators.required]],
          max_point: [0, [Validators.required]],
        }));
      }
    }
    console.log(skills_array);
    return skills_array;
  }

  get skills() {
    return ((this.skillset_form as FormGroup).get('skills') as FormArray).controls;
  }

  addSkill() {
    ((this.skillset_form as FormGroup).get('skills') as FormArray).push(this.formBuilder.group({
      skill_name: ["", [Validators.required]],
      max_point: [0, [Validators.required]],
    }));
  }

  async deleteSkill(index: number) {
    const consent = confirm("Are you sure want to delete this skill?");
    if (!consent) {
      return;
    }
    try {
      this.delete_skill_loading[index] = true;
      console.log(((this.skillset_form as FormGroup).get('skills') as FormArray).at(index).value);
      const skill_id = ((this.skillset_form as FormGroup).get('skills') as FormArray).at(index).value._id;
      const res = await this.restService.deleteSkill(skill_id);
      if (res.success) {
        alert("Skillset removed successfully");
        ((this.skillset_form as FormGroup).get('skills') as FormArray).removeAt(index);
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_skill_loading[index] = false;
    }
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditClick(skillset: IOBENonAcademicSkillsetMaster) {
    this.skillset = skillset;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.skillset_id = "";
    this.skillset = {} as IOBENonAcademicSkillsetMaster;
    this.skillset_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.skillset_form.value;
      console.log(form_data);
      this.save_loading = true;
      if (this.skillset_form.value._id) {
        const res = await this.restService.updateSkillset(this.skillset_form.value._id, form_data);
        if (res) {
          alert("Skillset updated successfully.");
        }
      }
      else {
        const res = await this.restService.createSkillset(form_data);
        if (res) {
          alert("Skillset added successfully.");
        }
      }
      this.getSkillSetList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteSkillset(this.skillset_form.get('_id')!.value);
      if (res.success) {
        alert("Skillset removed successfully");
        this.getSkillSetList();
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.skillset_form.touched && this.skillset_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.skillset_form.status !== 'VALID';
  }

  async bulkUploadSkillsData(e) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_skills_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.skillsetBulkUpload(formData);
      if (response.success && response.data) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_skills_data.show_loader = false;
    }
  }



}

interface IOBENonAcademicSkillsetMaster {
  _id?: string,
  skillset_name: string;
  skills: IOBENonAcademicSkills[];
  skillset_max_point: number;
  reference_skills: string,
  applicable_enrolled_years: number[],
  order: number;
}


interface IOBENonAcademicSkills {
  _id?: string,
  skill_name: string
  max_point: number;
}
