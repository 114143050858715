import { NavigationService, NavigationItem } from './navigation.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  navigationItems: NavigationItem[];
  constructor(private navigationService: NavigationService) {
    this.navigationItems = [];
  }

  ngOnInit(): void {
    this.navigationService.getNavigation().subscribe(nav => {
      this.navigationItems = nav;
    });
  }
}
