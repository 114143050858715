import { StudentRoomMapService } from './../../../../../services/rest/student-room-map.service';
import { Assets } from './../assets';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { DateHelper } from './../../../../../services/helpers/DateHelper';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { StudentRoomMap } from '../studentRoomMap';
import { customValidator } from '../customValidator';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

@Component({
  selector: 'app-student-room-map',
  templateUrl: './student-room-map.component.html',
  styleUrls: ['./student-room-map.component.scss']
})
export class StudentRoomMapComponent implements OnInit {
  fonts = fonts;
  studentRoomMapForm: FormGroup;
  asset_ids: string[] = [];
  assets: Assets[] = [] as Assets[];
  assetForm: FormGroup;
  title: string = 'Add Student to Room';
  roomId: string = '';
  studentRoomMapId = '';
  foodPreferences: string[] = ['Non-Veg', 'Veg'];
  fromDataResolver = FromDataResolver;


  constructor(private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private restService: AdminservicesService,
    private router: Router,
    private route: ActivatedRoute) {
    this.studentRoomMapForm = this.formBuilder.group({
      studentEmail: ['', [customValidator()]],
      from: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      to: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      remarks: [''],
      studentDetails: [''],
      foodPreference: ['']
    });
    this.assetForm = this.formBuilder.group({
      assetId: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.homeURL = {label: 'Assets', url: 'admin/hostel/asset-management'};
    this.route.params.subscribe(param => {
      this.roomId = param?.roomId;
      this.studentRoomMapId = param?.studentRoomMapId;
      if (this.studentRoomMapId) {
        this.title = 'Edit Student Room Map';
        this.getStudentRoomMapById();
      }
      this.navigationService.updateNavigation(this.title, this.router.url);
    });
  }

  onSearchClick(): void {
     this.getStudentSub();
  }

  canDisableSearch(): boolean {
    return !this.studentRoomMapForm.value?.studentEmail;
  }

  async getStudentRoomMapById(): Promise<void> {
    try {
      const res: StudentRoomMap[] = await this.restService.studentRoomMapService.getStudentRoomMap(this.studentRoomMapId);
      if (res[0]) {
        const student = await this.restService.getStudentInfo(res[0].sub);
        if (student?.data?.email) {
          this.studentRoomMapForm = this.formBuilder.group({
            studentEmail: student.data.email,
            from: DateHelper.convertToControlDate(new Date(res[0].from)),
            to: DateHelper.convertToControlDate(new Date(res[0].to)),
            remarks: res[0].remarks,
            foodPreference: res[0].food_preference,
            studentDetails: ''
          });
          this.asset_ids = res[0].asset_ids;
          this.getAllAssetDetails();
          this.getStudentSub();
        }

      }
    } catch(error) {

    }
  }
  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }
  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  getTittle(): string {
    return '';
  }

  canDisableAddAsset(): boolean {
    return !this.assetForm.value?.assetId;
  }

  async getAllAssetDetails(): Promise<void> {
    this.assets = [];
    for (let index = 0; index < this.asset_ids.length; index++) {
      const asset: Assets[] = await this.restService.roomAssetService.getRoomAsset(this.asset_ids[index]);
      if (asset && asset[0]) {
        this.assets.push(asset[0]);
      }
    }
  }

  async onAddAsset(): Promise<void> {
    const assetId = this.assetForm.value.assetId;
    try  {
      if (assetId) {
        const asset = await this.restService.roomAssetService.getRoomAssetByRef(assetId);
        if (!asset) {
          this.notificationService.setNotifcation({ message: `Cannot find asset with asset Id : ${assetId} `, type: 'Failed'});
        } else {
          const isFound = this.assets.find(item => item.asset_ref === assetId);
          if (!isFound) {
            this.assets.push(asset);
          } else {
            this.notificationService.setNotifcation({ message: `Asset id ${assetId} already added to the student`, type: 'Failed'});
          }
          this.assetForm.reset();
        }
      }
    } catch(error) {
      this.notificationService.setNotifcation({ message: `Cannot find asset with asset Id : ${assetId} `, type: 'Failed'});
    }

  }
  canSave(): boolean {
    return false;
  }

  onDeleteAsset(asset_id: string = ''): void {
    if (asset_id) {
      this.assets = this.assets.filter(item =>  item.asset_ref !== asset_id);
    }
  }

  canDisable(): boolean {

    const type = this.studentRoomMapForm.value.foodPreference;
    if (type) {
      if (!this.foodPreferences.find(value => value === type)) {
        return true;
      }
    } else {
      return true;
    }
    let startDate = this.studentRoomMapForm.value.from;
    let endDate = this.studentRoomMapForm.value.to;
    if (startDate) {
      startDate = Date.parse(startDate);
    }
    if (endDate) {
      endDate = Date.parse(endDate);
    }
    if (!startDate || !endDate || endDate < startDate) {
      return true;
    }
    if (this.studentRoomMapForm.touched) {
      return this.studentRoomMapForm.status !== 'VALID';
    }
    return false;
    
  }

  isInvalid(formControl: string): boolean {
    if(this.studentRoomMapForm.touched) {
          if (formControl === 'studentEmail') {
            if (this.studentRoomMapForm.get(formControl)?.errors?.invalidField) {
              return true;
            }
          }
    }
    return this.studentRoomMapForm.touched && this.studentRoomMapForm.get(formControl)?.errors !== null;
  }

  async getFormData(): Promise<StudentRoomMap> {
    const studentRoomMap: StudentRoomMap = {} as StudentRoomMap;
    studentRoomMap.asset_ids = this.assets.map(item => item?.id || '');
    try {
      const res = await this.getStudentSub();
      if (!res) {
        return {} as StudentRoomMap;
      }
      studentRoomMap.sub = res;
    } catch(error) {
      this.notificationService.setNotifcation({ message: `Student email-id is not found.`, type: 'Failed'});
    }
    studentRoomMap.room_id = this.roomId;
    studentRoomMap.from = DateHelper.convertToControlDate(this.studentRoomMapForm.value.from);
    studentRoomMap.to = DateHelper.convertToControlDate(this.studentRoomMapForm.value.to);
    studentRoomMap.remarks = this.studentRoomMapForm.value.remarks;
    studentRoomMap.food_preference = this.studentRoomMapForm.value.foodPreference;
    return studentRoomMap;
  }

  async getStudentSub(): Promise<string> {
    const email = this.studentRoomMapForm.value.studentEmail;
    const isEmail = /\S+@\S+\.\S+/i.test(email);
    let student = '';
    try {
      let res;
      if (isEmail) {
        res = await this.restService.getStudentByEmail(this.studentRoomMapForm.value.studentEmail);    
      } else {
        res = await this.restService.getStudentByRollNo(this.studentRoomMapForm.value.studentEmail);
      }
      if (res?.data && Array.isArray(res.data) && res.data.length) {
        const data =  res.data[0];
        const sub =  data?.sub ? data.sub : '';
        const readOnlyData = `Name: ${data?.given_name}\nProgrammes: ${data?.programme_name}\nMob: ${data?.mobile_number}`
        this.studentRoomMapForm.patchValue({
          studentDetails: readOnlyData
        });
        return sub;
      } else {
        this.notificationService.setNotifcation({ message: `Student with email-id or roll no ${email} is not found.`, type: 'Failed'});  
      }
    } catch(error) {
      this.notificationService.setNotifcation({ message: `Student with email-id or roll no ${email} is not found.`, type: 'Failed'});
    }
    return student;
  }

  async onSave(): Promise<void> {
    try {
        const body = await this.getFormData();
        let response;

        if (this.studentRoomMapId) {
          response = await this.restService.studentRoomMapService.updateStudentRoomMap(this.studentRoomMapId, body);
          this.notificationService.setNotifcation({ message: 'Updated successfully.', type: 'Success'});
        } else {
          response = await this.restService.studentRoomMapService.createStudentRoomMap(body);
          this.notificationService.setNotifcation({ message: 'Added student to room successfully.', type: 'Success'});
        }
        
        
        this.onClose();
    } catch(error) {
     this.notificationService.setNotifcation({ message: `Failed to add student to room ${error?.message}`, type: 'Failed' });
    }
   
  }

}
