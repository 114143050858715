<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Elective Courses' Students Quantity Settings</p>
                    </div>

                    <div class="card-body">
                        <!-- <div class="row row-sm">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled Year
                                    </label>
                                    <select [(ngModel)]="enrolled_year" class="form-control">
                                        <option value="0">Select Enrolled Year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Semester</label>
                                    <select class="form-control" [(ngModel)]="semester">
                                        <option value="0">Select Semester</option>
                                        <option *ngFor="
                                        let semester of commonEnums.getSemesterList(6);
                                        let i = index;
                                        trackBy: fromDataResolver.identify
                                        " [ngValue]="semester.id">
                                            {{ semester.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                                        *ngIf="!courses_list_loading" (click)="onSearchClick()">
                                        Get Courses
                                    </button>
                                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                        *ngIf="courses_list_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                            </div>
                        </div> -->
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-2">
                                                            <div class="form-group">
                                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                                                                <select formControlName="finance_type" class="form-control"
                                                                    (change)="onFilterSelectChange('finance_type',$event)">
                                                                    <option value="">Select Finance Type</option>
                                                                    <option
                                                                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                <!-- <div class="col-lg-2">
                                                            <div class="form-group">
                                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme</label>
                                                                <select formControlName="programme_id" class="form-control"
                                                                    (change)="onFilterSelectChange('programme_id',$event)">
                                                                    <option value="">All Programmes</option>
                                                                    <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                                        [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="this.part_course_types && this.part_course_types?.length>0">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Type</label>
                                        <select formControlName="course_type" class="form-control">
                                            <option value="">All Course Type</option>
                                            <option
                                                *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="course_type.id">{{course_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" formControlName="course_code" class="form-control"
                                            placeholder="Search with Course Code">
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!courses_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="courses_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <!-- <th>Offering Department</th> -->
                                        <!-- <th>Course Position</th> -->
                                        <th>Part</th>
                                        <th>Course Type</th>
                                        <th>Quantity</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="courses_list.length>0">
                                        <ng-container
                                            *ngFor="let course of courses_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{course.course_code}}</td>
                                                <td>{{course.course_name}}</td>
                                                <!-- <td>{{course.programme_name}}
                                                    ({{commonEnums.getFinanceDisplayText(course.finance_type)}})</td> -->
                                                <!--Render finance_type near programme_name-->
                                                <!-- <td>{{course.course_position}}</td> -->
                                                <td>{{course.part}}</td>
                                                <td>{{course.course_type}}</td>
                                                <td>{{course.qty}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(course)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!courses_list_loading && courses_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="courses_list_loading && courses_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Elective Quantity Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <h6>Student Quantity for {{course.course_code}} - {{course.course_name}}</h6>
                            <form [formGroup]="quantity_setting_form" class="form-horizontal">
                                <div class="row">
                                    <!-- <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Enrolled Year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select Enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('enrolled_year')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Enrollment Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="0">Select Semester</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('semester')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Semester is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Course-Student Quantity</label>
                                            <input type="number" class="form-control" formControlName="qty"
                                                placeholder="Enter Course-Student Quantity">
                                            <div *ngIf="isInvalid('qty')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Student Quantity is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Virtual Section
                                            Settings</label>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Section Name</th>
                                                        <th>Max Quantity</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="section_data">
                                                    <tr *ngFor="let section of sectionList; let i = index;"
                                                        [formGroupName]="i">
                                                        <td>
                                                            {{i+1}}
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control"
                                                                formControlName="section_name"
                                                                placeholder="Enter Section Name">
                                                        </td>
                                                        <td>
                                                            <input type="number" class="form-control"
                                                                formControlName="max_qty"
                                                                placeholder="Enter Max. Strength for this section">
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-danger btn-sm"
                                                                (click)="deleteSection(i)">Delete</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" class="text-center">
                                                            <button class="btn btn-primary btn-sm"
                                                                (click)="addSection()">Add Section</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p style="margin-bottom: 10px !important;">
                                            <span>
                                                <b>(Note: This virtual section settings does not affect the polling
                                                    process and
                                                    can be changed as required per Post-Polling requirements)</b>
                                            </span>
                                        </p>
                                    </div>

                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Update</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Update &
                                        Close</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>