<!--Exam Fee Setting component-->
<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add Fee Setting</button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage fees amount for course types.</p>
                    </div>

                    <div class="card-body">
                        <!-- <h4>Exam fee setting for {{setting_name}}</h4> -->
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th>Course Type</th>
                                        <th>Appearance Type</th>
                                        <th>Finance Type</th>
                                        <th>Fees</th>
                                        <th style="width:15%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="settings_list?.length>0">
                                        <ng-container
                                            *ngFor="let setting of settings_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{getCourseTypeDisplayText(setting?.course_type)}}
                                                    {{(setting?.hours>0)?(setting?.hours+" Hours"):""}}</td>
                                                <td>{{setting.appearance_type | titlecase}}</td>
                                                <td>{{commonEnums.getFinanceDisplayText(setting.finance_type)}}</td>
                                                <td>Rs. {{setting.fee_amount}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(setting)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                        <!--
                        <h4 class="mg-t-15">Enable setting availability</h4>
                         <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th>Programme Name</th>
                                        <th class="text-center">
                                            <input type="checkbox" 
                                            (change)="enableAllProgrammes($event)"
                                            [checked]="allProgrammesChecked()"
                                            title="Enable/disable for all Programmes & Years"> 
                                            &nbsp; Enable for all
                                        </th>
                                        <th *ngFor="let year of years |slice:0:max_year;let i=index;trackBy:fromDataResolver.identify;">
                                            <input type="checkbox" 
                                            (change)="enableAllNYears($event, year)"
                                            [checked]="allNYearsChecked(year)"
                                            title="Enable/disable for all {{year}}Years"> 
                                            &nbsp; {{year}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="department_list.length>0">
                                        <ng-container *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{department.display_name}}</td>
                                                <td class="text-center">
                                                    <div>
                                                        <input type="checkbox" [(ngModel)]="department.enabled_all_years"
                                                        (change)="enableAllYearsInProgrammeChecked($event,department)"
                                                        title="Click to enable this settings for all years in this department"
                                                        >
                                                    </div>
                                                </td>    
                                                <td class="text-center"
                                                 *ngFor="let year of years |slice:0:max_year;let j=index;trackBy:fromDataResolver.identify;">
                                                    <div *ngIf="j<=department.no_of_years-1">
                                                        <input type="checkbox"
                                                        title="Click to enable setting for this year in the department"
                                                        [value]="year"
                                                        (change)="yearChecked($event,department,year)"
                                                        [checked]="(department.enabled_years?.indexOf(year)>=0)?true:null"
                                                        >
                                                    </div>
                                                    <div *ngIf="j>department.no_of_years-1">
                                                        ---
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                    
                                    </ng-container>
                    
                                    <ng-container *ngIf="!department_list_loading && department_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="department_list_loading && department_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                    
                                </tbody>
                            </table>
                        </div>
                        <div class="row text-center mg-t-10">
                            <div class="col-md-12">
                                <button class="btn btn-info mg-r-20" (click)="saveSettings()">Save</button>
                            </div>
                        </div>
                        !-->
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="fee_setting_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Fees Settings</h6>
                        <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="fee_setting_form" class="form-horizontal">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Course
                                        Type</label>
                                    <select formControlName="course_type" class="form-control"
                                        (change)="onCourseTypeChange($event)">
                                        <option value="">Choose Course Type</option>
                                        <option
                                            *ngFor="let course_type of course_types;let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="course_type.id">
                                            {{ course_type.display_text }}</option>
                                    </select>
                                    <div *ngIf="isInvalid('course_type')">
                                        <ul class="parsley-errors-list filled">
                                            <li class="parsley-required">Course Type is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="form-group" *ngIf="fee_setting_form.get('course_type')!.value=='PRACTICAL'">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select No. of
                                        hours</label>
                                    <select formControlName="hours" class="form-control">
                                        <option value="0">Choose No. of Hours</option>
                                        <option
                                            *ngFor="let hour of no_of_hours;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="hour">
                                            {{ hour }}</option>
                                    </select>
                                    <div *ngIf="isInvalid('hours')">
                                        <ul class="parsley-errors-list filled">
                                            <li class="parsley-required">No. of hours is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Appearance
                                        Type</label>
                                    <select formControlName="appearance_type" class="form-control">
                                        <option value="">Choose Appearance Type</option>
                                        <option
                                            *ngFor="let appearance_type of appearance_types;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="appearance_type">
                                            {{ appearance_type | titlecase }}</option>
                                    </select>
                                    <div *ngIf="isInvalid('appearance_type')">
                                        <ul class="parsley-errors-list filled">
                                            <li class="parsley-required">Appearance Type is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                        Type</label>
                                    <select formControlName="finance_type" class="form-control">
                                        <option value="">Select Finance Type</option>
                                        <option
                                            *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                    </select>

                                    <div *ngIf="isInvalid('finance_type')">
                                        <ul class="parsley-errors-list filled">
                                            <li class="parsley-required">Finance Type is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Fees</label>
                                    <input class="form-control" placeholder="Enter Fees Amount (Rs.)" required=""
                                        type="text" formControlName="fee_amount">
                                    <div *ngIf="isInvalid('fee_amount')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Fees is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Exam Fine Rule Name</label>
                                    <input class="form-control"
                                    placeholder="Enter Fine Rule Name"
                                    required="" type="text" formControlName="fine_rule_name">
                                    <div *ngIf="isInvalid('fine_rule_name')">
                                      <ul class="parsley-errors-list filled" id="parsley-id-9">
                                        <li class="parsley-required">Fine Rule Name is required.</li>
                                      </ul>
                                    </div>
                                  </div> -->


                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Save & Close</button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="this.fee_setting_form.value.id" [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>