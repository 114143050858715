import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-manage-part-one',
  templateUrl: './manage-part-one.component.html',
  styleUrls: ['./manage-part-one.component.scss']
})
export class ManagePartOneComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;

  // Query String Data
  prg_type = 'UG';
  part = '';

  all_programme_list: any[] = [];
  all_programme_list_loading = false;

  // Master Data
  part1_department_list: any[] = [];
  part1_department_list_loading = false;

  // Core Data

  part1_search_form: any;


  programme_schemes_loading = false;

  part1_form: any;

  programme_schemes_delete_loading = false;

  programme_schemes_save_loading = false;

  programme_scheme_approval_loading = false;

  // assign staffs for a section
  assign_staff: any;


  // part 4
  // programme list
  programme_list: any[] = [];
  programme_list_loading = false;


  course_types: any[] = [];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(params => {
      this.part = params.part;
      if (params.prg_type) {
        this.prg_type = params.prg_type;
      }
    });

  }

  ngOnInit(): void {
    this.getProgrammesList().then(d => {
      this.getPart1Programmes();
      this.getProgrammeDataList();
      if (this.part == "PART_IV" || this.part == "PART_V") {

        if (this.part == "PART_IV") {
          this.course_types = this.commonEnums.getCoursePartType(this.prg_type, this.part)!;
        }
      } else if (this.part == "SUPPORTIVE_COURSES") {

        this.course_types = this.commonEnums.getCoursePartType(this.prg_type, this.part)!;
      }
    });

  }

  async getProgrammesList(): Promise<void> {
    try {
      this.all_programme_list_loading = true;

      const query = {

      };
      const response = await this.restService.searchProgrammesLite(query);
      if (response.success) {
        this.all_programme_list = response.data;
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.all_programme_list_loading = false;
    }
  }

  async buildSearchForm(): Promise<void> {
    this.part1_search_form = this.fb.group({
      programme_id: [''],
      revision_year: [''],
      // programme_category: ['all'],
      part: [this.part]
    });
  }

  async getPart1Programmes(): Promise<void> {
    try {
      this.part1_department_list_loading = true;
      const prg_list = JSON.parse(JSON.stringify(this.all_programme_list));
      this.part1_department_list = prg_list.filter((c: any) => c.part_type == this.part);
      this.buildSearchForm();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.part1_department_list_loading = false;
    }
  }

  async onDropdownChange(data: any): Promise<void> {
    // this.searchPart1Programmes(true);
  }

  async searchPart1Programmes(auto: boolean = false): Promise<void> {
    try {

      if (!this.part1_search_form.value.programme_id) {
        if (!auto) {
          alert('Please select programme');
        }
        return;
      }
      if (!this.part1_search_form.value.revision_year) {
        if (!auto) {
          alert('Please select revision year');
        }
        return;
      }
      // if (!this.part1_search_form.value.programme_category) {
      //   if (!auto) {
      //     alert('Please select programme category');
      //   }
      //   return;
      // }

      this.programme_schemes_loading = true;
      this.part1_form = null;
      const response = await this.restService.searchCommonProgrameSchemeHeader(this.part1_search_form.value);
      if (response.success) {
        if (!response.data) {
          response.data = {};
        }
        this.buildPartForm(response.data);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_schemes_loading = false;
    }
  }

  buildPartForm(data: any): void {
    this.part1_form = this.fb.group({
      approved: [data.approved],
      programme_schemes: this.fb.array(this.buildPartDataForm(data.programme_schemes))
    });
  }

  buildPartDataForm(programme_schemes: any[]): any[] {
    const data = [];
    if (!programme_schemes) {
      programme_schemes = [];
    }
    if (programme_schemes.length > 0) {
      for (const data_item of programme_schemes) {
        if (!data_item.course) {
          data_item.course = {};
        }
        data.push(this.fb.group({
          _id: [data_item._id],
          semester: [data_item.semester],
          course_type: [data_item.course_type],
          course_code: [data_item.course.course_code],
          course_name: [data_item.course.course_name],
          weekly_lecture_hours: [data_item.weekly_lecture_hours],
          weekly_tutorial_hours: [data_item.weekly_tutorial_hours],
          weekly_practical_hours: [data_item.weekly_practical_hours],
          credits: [data_item.credits],
          exam_duration: [data_item.exam_duration],
          max_internal_mark: [data_item.max_internal_mark],
          max_external_mark: [data_item.max_external_mark],
          // programme_category: [data_item.programme_category],
          programme_ids: [data_item.programme_ids],
          staff_assignment_by: [data_item.staff_assignment_by || 'self'],
          student_grouped_by: [data_item.student_grouped_by || 'department_wise'],
        }));
      }
    } else {
      data.push(this.fb.group({
        _id: [''],
        semester: [''],
        course_type: [''],
        course_code: [''],
        course_name: [''],
        weekly_lecture_hours: [0],
        weekly_tutorial_hours: [0],
        weekly_practical_hours: [0],
        credits: [0],
        exam_duration: [0],
        max_internal_mark: [0],
        max_external_mark: [0],
        // programme_category: [''],
        programme_ids: [],
        staff_assignment_by: ['self'],
        student_grouped_by: ['department_wise']
      }));
    }
    return data;
  }


  isDisabled(): boolean {
    if (this.part1_form && this.part1_form.value.approved) {
      return true;
    }
    return false;
  }

  getTotal(max_internal_mark: number, max_external_mark: number): number {
    try {
      return Number(max_internal_mark) + Number(max_external_mark);
    } catch (error) {

    }
    return 0;
  }


  async onDeleteProgrameClick(id: string, index: number): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      if (!id) {
        // remove local data
        const form = (this.part1_form as FormGroup).get('programme_schemes') as FormArray;
        form.removeAt(index);
        return;
      }

      this.programme_schemes_delete_loading = true;

      const save_respose = await this.restService.deleteCommonProgrameSchemeById(id);
      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.searchPart1Programmes();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.programme_schemes_delete_loading = false;
    }
  }

  addNewCourse(): void {
    const form = (this.part1_form as FormGroup).get('programme_schemes') as FormArray;
    form.push(this.fb.group({
      _id: [''],
      semester: [''],
      course_type: [''],
      course_code: [''],
      course_name: [''],
      weekly_lecture_hours: [0],
      weekly_tutorial_hours: [0],
      weekly_practical_hours: [0],
      credits: [0],
      exam_duration: [0],
      max_internal_mark: [0],
      max_external_mark: [0],
      // programme_category: [''],
      programme_ids: [''],
      staff_assignment_by: ['self'],
      student_grouped_by: ['department_wise']
    }));
  }

  async saveCourseScheme(): Promise<void> {
    try {
      this.programme_schemes_save_loading = true;

      const programme_schemes = [];
      for (const programme_scheme of this.part1_form.value.programme_schemes) {
        const data = {
          _id: programme_scheme._id,
          semester: programme_scheme.semester,
          course_type: programme_scheme.course_type,
          course: {
            course_code: programme_scheme.course_code,
            course_name: programme_scheme.course_name,
          },
          weekly_lecture_hours: programme_scheme.weekly_lecture_hours,
          weekly_tutorial_hours: programme_scheme.weekly_tutorial_hours,
          weekly_practical_hours: programme_scheme.weekly_practical_hours,
          credits: programme_scheme.credits,
          exam_duration: programme_scheme.exam_duration,
          max_internal_mark: programme_scheme.max_internal_mark,
          max_external_mark: programme_scheme.max_external_mark,
          programme_ids: programme_scheme.programme_ids,
          staff_assignment_by: programme_scheme.staff_assignment_by,
          student_grouped_by: programme_scheme.student_grouped_by,
        };
        programme_schemes.push(data);
      }
      const data_to_update = {
        approved: this.part1_form.value.approved,
        programme_schemes,
        programme_id: this.part1_search_form.value.programme_id,
        revision_year: this.part1_search_form.value.revision_year,
        // programme_category: this.part1_search_form.value.programme_category,
        part: this.part
      };
      const response = await this.restService.createCommonProgrameSchemeHeader(data_to_update);
      if (response.success) {
        alert('Saved Successfully');
        await this.searchPart1Programmes();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_schemes_save_loading = false;
    }
  }

  async approveCourseScheme(): Promise<void> {
    try {
      const n1 = Math.floor((Math.random() * 10) + 1);
      const n2 = Math.floor((Math.random() * 10) + 1);
      const value =
        prompt('Are you sure do you want to approve? Once it is approved you cant change it. \nSolve the math to save ' + n1 + '+' + n2 + ' = ? ');
      try {
        if (!value || Number(value) !== (n1 + n2)) {
          alert('Wrong value entered');
          return;
        }
      } catch (error) {
        alert('Wrong value entered');
        return;
      }

      const data_to_update = {
        programme_id: this.part1_search_form.value.programme_id,
        revision_year: this.part1_search_form.value.revision_year,
        part: this.part,
        // programme_category: this.part1_search_form.value.programme_category,
      };
      this.programme_scheme_approval_loading = true;
      const response = await this.restService.approveCommonProgrameSchemeHeader(data_to_update);
      if (response.success) {
        alert('Approved Successfully');
        await this.searchPart1Programmes();
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_scheme_approval_loading = false;
    }
  }

  async onUpApprovalClick(): Promise<void> {
    try {
      const n1 = Math.floor((Math.random() * 10) + 1);
      const n2 = Math.floor((Math.random() * 10) + 1);
      const value =
        prompt('Are you sure do you want to approve? Once it is approved you cant change it. \nSolve the math to save ' + n1 + '+' + n2 + ' = ? ');
      try {
        if (!value || Number(value) !== (n1 + n2)) {
          alert('Wrong value entered');
          return;
        }
      } catch (error) {
        alert('Wrong value entered');
        return;
      }

      const data_to_update = {
        programme_id: this.part1_search_form.value.programme_id,
        revision_year: this.part1_search_form.value.revision_year,
        part: this.part,
        // programme_category: this.part1_search_form.value.programme_category,
      };
      this.programme_scheme_approval_loading = true;
      const response = await this.restService.unApproveCommonProgrameSchemeHeader(data_to_update);
      if (response.success) {
        alert('Un-Approved Successfully');
        await this.searchPart1Programmes();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_scheme_approval_loading = false;
    }
  }

  async onCreateSectionClick(programe: FormGroup, index: number): Promise<void> {
    try {

      const id = programe.value._id;

      programe.value.open = true;

      programe.value.regulation_year_form = this.fb.group({
        regulation_year: ['']
      });

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_schemes_delete_loading = false;
    }
  }

  async onRegulationYearChange(programe: FormGroup): Promise<void> {
    try {

      const regulation_year = programe.value.regulation_year_form.value.regulation_year;
      const data_to_search = {
        regulation_year,
        common_prg_scheme_id: programe.value._id
      };
      const response = await this.restService.getCmnPrgCourseSections(data_to_search);
      if (response) {
        if (!response.data) {
          response.data = [];
        }

        let data_for_dept_list = [];
        if (programe.value.student_grouped_by == "department_wise") {

          const allowed_programmes = this.programme_list.filter(c => programe.value.programme_ids.includes(c.programme_id));

          for (const allowed_programme of allowed_programmes) {

            for (const section_detail of allowed_programme.section_details) {

              const saved_prg_secton = response.data.find((c: any) =>
                c.programme_id == allowed_programme.programme_id &&
                c.programme_section_id == section_detail._id
              );
              if (!saved_prg_secton) {

                data_for_dept_list.push({
                  _id: '',
                  common_prg_scheme_id: programe.value._id,
                  regulation_year: regulation_year,
                  section_name: allowed_programme.programme_id + "-" + section_detail._id,
                  section_id: allowed_programme.programme_id + "-" + section_detail._id,

                  programme_id: allowed_programme.programme_id,
                  programme_name: allowed_programme.display_name,

                  programme_section_id: section_detail._id,
                  programme_section_name: section_detail.section_name,

                });


              } else {
                saved_prg_secton.programme_name = allowed_programme.display_name;
                saved_prg_secton.programme_section_name = section_detail.section_name;
                data_for_dept_list.push(saved_prg_secton)
              }
            }
          }

        } else {
          data_for_dept_list = response.data;
        }

        programe.value.section_form = this.fb.group({
          sections: this.fb.array(this.buildSectionForm(programe, regulation_year, data_for_dept_list))
        });


      }
    } catch (error) {
      alert(error);
    }
  }

  buildSectionForm(programe: FormGroup, regulation_year: number, data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    if (data.length > 0) {
      for (const data_item of data) {
        value_to_return.push(this.fb.group({
          _id: [data_item._id],
          common_prg_scheme_id: [programe.value._id],
          regulation_year: [regulation_year],
          section_name: [data_item.section_name],
          section_id: [data_item.section_id],

          programme_id: [data_item.programme_id],
          programme_name: [data_item.programme_name],

          programme_section_id: [data_item.programme_section_id],
          programme_section_name: [data_item.programme_section_name],
        }));
      }
    } else {
      value_to_return.push(this.fb.group({
        _id: [''],
        common_prg_scheme_id: [programe.value._id],
        regulation_year: [regulation_year],
        section_name: [''],
        section_id: [''],

        programme_id: [''],
        programme_name: [''],

        programme_section_id: [''],
        programme_section_name: [''],
      }));
    }


    return value_to_return;
  }

  async onDeleteSectionClick(programe: FormGroup, section: FormGroup, index: number): Promise<void> {
    try {
      const section_parent_form = programe.value.section_form;
      const sections = section_parent_form.get('sections') as FormArray;
      if (!section.value._id) {
        sections.removeAt(index);
        return;
      }
      const response = await this.restService.deleteCmnPrgCourseSection(section.value._id);
      if (response.success) {
        alert('Deleted Successfully');
        await this.onRegulationYearChange(programe);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }


  async onAddNewSection(programe: FormGroup, section_form: FormGroup): Promise<void> {
    const sections = section_form.get('sections') as FormArray;
    sections.push(this.fb.group({
      common_prg_scheme_id: [programe.value._id],
      regulation_year: [programe.value.regulation_year_form.value.regulation_year],
      section_name: [''],
    }));
  }

  async onSaveSection(programe: FormGroup): Promise<void> {
    try {
      const section_data = programe.value.section_form.value;
      let hasError = false;
      let i = 1;
      for (const section of section_data.sections) {
        if (!section.section_name) {
          alert('Please fill section name in row ' + i);
          hasError = true;
          break;
        }
        i++;
      }
      if (hasError) {
        return;
      }
      const response = await this.restService.createCmnPrgCourseSectionInBulk(section_data.sections);
      if (response.success) {
        alert('Saved Successfully');
        await this.onRegulationYearChange(programe);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }



  async onAssignStaffClick(programe: FormGroup, section_form: FormGroup, index: number): Promise<void> {
    try {
      const semester = programe.value.semester;
      const course_code = programe.value.course_code;
      const regulation_year = programe.value.regulation_year_form.value.regulation_year;
      const section_id = section_form.value._id;

      const response = await this.restService.getAssignedStaffWithCourse(semester, course_code, regulation_year, section_id);
      if (response.success) {
        if (!response.data) {
          response.data = [];
        }
        this.buildAssignStaffForm(response.data, semester, course_code, regulation_year, section_id);
        JQueryHelper.openModal('#modal-staffassign', { keyboard: false, backdrop: 'static' });
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_schemes_delete_loading = false;
    }
  }

  buildAssignStaffForm(data: any[], semester: number, course_code: string, regulation_year: number, section_id: string): void {
    this.assign_staff = this.fb.group({
      semester: [semester],
      course_code: [course_code],
      regulation_year: [regulation_year],
      section_id: [section_id],
      staffs: this.fb.array(this.buildStaffForm(data))
    });
  }

  buildStaffForm(staffs: any[]): any[] {
    const staff_data: any[] = [];
    if (!staffs) {
      staffs = [];
    }
    if (staffs.length > 0) {
      for (const staff of staffs) {
        staff_data.push(this.fb.group(
          {
            _id: [staff._id],
            sub: [staff.sub],
            roll_no: [staff.roll_no],
            name: [staff.name],
            departments: [staff.departments],
            is_primary: [staff.is_primary]
          }
        ));
      }
    } else {
      staff_data.push(this.fb.group(
        {
          _id: [''],
          sub: [''],
          roll_no: [''],
          name: [''],
          departments: [''],
          is_primary: [false]
        }
      ));
    }

    return staff_data;
  }

  async onRollNoChanged(data: any, staff_form: FormGroup, staff_parent_form: FormGroup): Promise<void> {
    try {
      const roll_no = staff_form.value.roll_no;
      if (!roll_no) {
        return;
      }
      let is_primary = true;
      if (staff_parent_form.value.staffs && staff_parent_form.value.staffs.length > 1) {
        is_primary = false;
      }
      const saved_item_response = await this.restService.findStaffBuRollNo(roll_no.toUpperCase());
      if (saved_item_response.success && saved_item_response.data) {
        staff_form.patchValue({
          _id: [''],
          name: saved_item_response.data.name,
          departments: saved_item_response.data.departments,
          sub: saved_item_response.data.sub,
          is_primary
        });
      } else {
        staff_form.patchValue({
          _id: [''],
          roll_no: '',
          name: '',
          departments: '',
          sub: '',
          is_primary,
          section_id: [this.assign_staff.value.section_id]
        });
        alert('Invalid roll no');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  addMoreStaff(staff_parent_form: FormGroup): void {
    (staff_parent_form.get('staffs') as FormArray).push(
      this.fb.group(
        {
          _id: [''],
          sub: [''],
          roll_no: [''],
          name: [''],
          departments: [''],
          is_primary: [false],
          section_id: [this.assign_staff.value.section_id]
        }
      )
    );
  }

  async removeStaff(id: string, staff_form: FormGroup, staff_parent_form: FormGroup
    // tslint:disable-next-line:align
    , index: number): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to remove ?');
      if (!consent) {
        return;
      }

      const sub = staff_form.value.sub;
      if (!sub) {
        (staff_parent_form.get('staffs') as FormArray).removeAt(index);
        return;
      }

      const save_response = await this.restService.unAssignStaffWithCourse(id);
      if (save_response.success) {
        alert('Saved Successfully');
        (staff_parent_form.get('staffs') as FormArray).removeAt(index);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }

  }

  async onSaveStaffAssignClick(staff_parent_form: FormGroup): Promise<void> {

    try {
      const consent = confirm('Are you sure do you want to save ?');
      if (!consent) {
        return;
      }


      const staff_parent_data = staff_parent_form.value.staffs;
      const data_items: any[] = [];
      for (const staff_parent of staff_parent_data) {
        data_items.push({
          enrolled_year: Number(this.assign_staff.value.regulation_year),
          semester: this.assign_staff.value.semester,
          course_code: this.assign_staff.value.course_code,
          sub: staff_parent.sub,
          is_primary: staff_parent.is_primary,
          section_id: this.assign_staff.value.section_id
        });
      }

      const save_response = await this.restService.assignStaffWithCourse(data_items);
      if (save_response.success) {
        alert('Saved Successfully');
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }

  }


  async onCloseStaffAssignClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-staffassign');
  }




  async getProgrammeDataList(): Promise<void> {
    try {

      this.programme_list_loading = true;
      this.programme_list = [];

      const prg_list = JSON.parse(JSON.stringify(this.all_programme_list));
      this.programme_list = prg_list.filter((c: any) => c.programme_type_id == this.prg_type && (c.is_virtual == false || c.is_virtual == null || c.is_virtual == undefined));

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }


}
