<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1"></h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">CO Report</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Print CO Report for each valuations</p>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="!mark_data">
                            <div class="col-md-12 text-center">
                                <span><b>
                                        <ng-container *ngIf="!mark_data_loading">
                                            No Data Found
                                        </ng-container>
                                        <ng-container *ngIf="mark_data_loading">
                                            Loading... Please wait.
                                        </ng-container>
                                    </b></span>
                            </div>
                        </div>
                        <div class="float-right" *ngIf="mark_data">
                            <button class="btn btn-info btn-sm mg-r-10" (click)="printFoilCard()">Print Report</button>
                        </div>
                        <div id="foil_card_content" *ngIf="mark_data">
                            <div class="row" style="margin-left:40px">
                                <div class="col-md-2">
                                    <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                                        alt="college logo"
                                        style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                                </div>
                                <div class="col-md-8 text-center">
                                    <h4 style=" margin-bottom: 0px !important;">AYYA NADAR JANAKI AMMAL COLLEGE
                                        (Autonomous)</h4>
                                    <h4>Sivakasi - 626 124.</h4>
                                    <h5 style="margin-top:5px">TERMINAL EXAMINATIONS - NOVEMBER, 2022
                                    </h5>
                                </div>
                            </div>
                            <div class="row" style="margin-top:10px; margin-left: 40px;">
                                <div class="col-md-5">
                                    <b>{{mark_data?.class_name}}</b>
                                </div>
                                <div class="col-md-4">
                                    <b>Valuation - {{mark_data?.valuation_no}}</b>
                                </div>
                                <div class="col-md-3">
                                    <b>{{mark_data?.semester}} Semester</b>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table style="width:80%; margin: 0 auto;">
                                    <tbody>
                                        <tr>
                                            <td class="text-center">Course Code</td>
                                            <td class="text-center">Title of the Course</td>
                                        </tr>
                                        <tr style="border:1.5px solid black">
                                            <td class="text-center" style="border: 1.5px solid black; padding: 10px;">
                                                <b>{{mark_data?.course_code}}</b>
                                            </td>
                                            <td class="text-center"><b>{{mark_data?.course_name}}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    Maximum Marks: <b>{{mark_data?.marks[0].scale_to}}</b>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" *ngIf="mark_data?.marks?.length>0">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%;">S.No.</th>
                                            <th style="width: 10%;">R.No.</th>
                                            <th style="width: 20%;">Name</th>
                                            <th *ngFor="let head of mark_data?.marks[0].co_items; let i = index;">
                                                CO{{i+1}}
                                            </th>
                                            <th style="width: 10%;">Sub Total<br>
                                                ({{mark_data?.marks[0].max_total}})
                                            </th>
                                            <th style="width: 10%;">Total<br>
                                                ({{mark_data?.marks[0].scale_to}})</th>
                                            <th style="width: 10%;">Final<br>
                                                ({{mark_data?.marks[0].scale_to}})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of mark_data?.marks; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{student.roll_no}}</td>
                                            <td>{{student.name}}</td>
                                            <td *ngFor="let co_item of student.co_items">
                                                {{co_item.mark}}
                                            </td>
                                            <td>{{student.sub_total | number : '1.2-2'}}</td>
                                            <td>{{student.total_raw | number : '1.2-2' }}</td>
                                            <td>{{student.total}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div class="row" style="margin-top: 40px;">
                                <div class="col-md-4" style="font-weight: bold;">
                                    <span style="margin-left: 40px;">
                                        Sivakasi,
                                    </span> <br>
                                    <span style="margin-left: 40px;">
                                        {{today_date | date:'dd/MM/yyyy' }}
                                    </span>
                                </div>
                                <div class="col-md-8 text-right" style="font-weight: bold;">
                                    <span style="margin-right: 60px;">
                                        Signature of the External Examiner
                                    </span><br>
                                    <span style="margin-right: 60px;">
                                        {{mark_data?.staff_name | uppercase}}
                                    </span><br>
                                    <span style="margin-right: 60px;">
                                        (Name in BLOCK LETTERS)
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>