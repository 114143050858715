import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-transfer-report',
  templateUrl: './transfer-report.component.html',
  styleUrls: ['./transfer-report.component.scss']
})
export class TransferReportComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;
  cookieStore = CookieStore;

  // Master data
  programme_search_form: any;

  transfer_list: any[] = [];
  transfer_list_loading = false;

  admission_id = '';

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();

    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
    });
  }

  ngOnInit(): void {
    this.buildFormSearch();
  }

  buildFormSearch(): void {
    const start_date = DateHelper.getStartDate(new Date());
    if (!start_date) {
      return;
    }
    const end_date = DateHelper.getEndDate(new Date());
    if (!end_date) {
      return;
    }
    this.programme_search_form = this.fb.group({
      transfer_type: ['transfer'],
      admission_id: [this.admission_id],
      start_time: [DateHelper.convertToControlDateTime(start_date)],
      end_time: [DateHelper.convertToControlDateTime(end_date)],
      apply_date_filter: [false],
      completed: [false]
    });
  }

  onTransferTypeChange(): void {
    this.transfer_list = [];
    this.programme_search_form.get('completed').setValue(false);
  }

  async onSearchClick(): Promise<void> {
    try {
      this.transfer_list = [];
      this.transfer_list_loading = true;
      const query: any = {
        admission_id: this.admission_id
      };

      if (this.programme_search_form.value.apply_date_filter) {
        query.start_time = new Date(this.programme_search_form.value.start_time);
        query.end_time = new Date(this.programme_search_form.value.end_time);
      }

      if (this.programme_search_form.value.completed) {
        query.completed = this.programme_search_form.value.completed;
      }

      const response = await this.restService.getTransferList(this.programme_search_form.value.transfer_type, query);
      if (response.success) {
        this.transfer_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.transfer_list_loading = false;
    }
  }

}
