<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid ">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
    </div>

    <!-- Create hostel -->
    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="card box-shadow-0">
          <div class="card-header">
            <h4 class="card-title mb-1">{{ getTittle() }}</h4>
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="roomAssetForm" class="form-horizontal">
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Asset Id</label>
                <input class="form-control" required="" type="text" formControlName="asset_ref">
                <div *ngIf="isInvalid('asset_ref')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Asset Id is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Asset Type/Name</label>
                <input class="form-control" required="" type="text" formControlName="type">
                <div *ngIf="isInvalid('type')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Asset Type is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Asset Description</label>
                <input class="form-control" type="text" formControlName="description">
                <!-- <div *ngIf="isInvalid('description')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Asset Type is required.</li>
                  </ul>
                </div> -->
              </div>

              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Added Date</label>
                <input class="form-control" required="" type="date" formControlName="added_date">
                <div *ngIf="isInvalid('added_date')">
                  <ul class="parsley-errors-list filled">
                    <li class="parsley-required">Added date is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Expiry Date</label>
                <input class="form-control" required="" type="date" formControlName="expires_at">
                <div *ngIf="isInvalid('expires_at')">
                  <ul class="parsley-errors-list filled">
                    <li class="parsley-required">Expiry date is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group checkbox">
                <div class="custom-checkbox custom-control">
                  <input type="checkbox" data-checkboxes="active" class="custom-control-input" id="active"
                    formControlName="active">
                  <label for="active" class="custom-control-label mt-1">Active</label>
                </div>
              </div>
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                <button class="btn ripple btn-secondary" (click)="onClose()" type="button">Close</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
