import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-student-od-approval',
  templateUrl: './student-od-approval.component.html',
  styleUrls: ['./student-od-approval.component.scss']
})
export class StudentOdApprovalComponent implements OnInit {

  fonts = fonts;
  request_list_loading: boolean = false;
  odRequests: IODRequest[] = [];
  odRequest: IODRequest = {} as IODRequest;
  odRequestForm: FormGroup;
  title = 'On-Duty Request Approval';
  id = '';
  request_filter_form: FormGroup;
  request_status: { id: string, text: string }[] = [
    { id: "NEW", text: "Requested" }, { id: "APPROVED", text: "Approved" }, { id: "REJECTED", text: "Rejected" }];
  fromDataResolver = FromDataResolver;
  constructor(private restService: AdminservicesService, private formBuilder: FormBuilder,) {
    this.request_filter_form = this.formBuilder.group({
      search_date: [''],
      status: ['']
    });
  }

  ngOnInit(): void {

  }

  getTittle(): string {
    return this.title;
  }

  async getStudentODRequestList() {
    try {
      this.request_list_loading = true;
      this.odRequests = [];
      var search_data: any = {
        "academic_year": "2022-2023"
      }
      if (this.request_filter_form.value.search_date) {
        search_data.search_date = this.request_filter_form.value.search_date
      }
      if (this.request_filter_form.value.status) {
        search_data.status = this.request_filter_form.value.status
      }
      const service_response = await this.restService.searchODRequests(search_data);
      if (service_response && service_response.success) {
        this.odRequests = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.request_list_loading = false;
    }

  }

  getDateTimeView(date_time: Date) {
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" + date_time.getFullYear() + " " +
      (date_time.getHours() > 12 ? date_time.getHours() - 12 : date_time.getHours()) + ":" +
      ((date_time.getMinutes() > 9) ? date_time.getMinutes() : ((date_time.getMinutes() == 0) ? date_time.getMinutes() + "0" : "0" + (date_time.getMinutes())));
  }

  getDateTime(date_time: Date) {
    return date_time.getFullYear() +
      "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" +
      (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate())
      + "T" +
      date_time.getHours()
      + ":" +
      ((date_time.getMinutes() > 9) ? date_time.getMinutes() : ((date_time.getMinutes() == 0) ? date_time.getMinutes() + "0" : "0" + (date_time.getMinutes())));
  }

  async onChangeStatus(request: IODRequest, to_status: string) {
    try {
      this.request_list_loading = true;
      this.odRequests = [];
      const od_data: any = {
        "status": to_status,
      }
      const service_response = await this.restService.changeODRequest(request._id, od_data);
      if (service_response && service_response.success) {
        if (to_status == 'APPROVED') {
          alert("OD Request Approved Successfully");
        }
        if (to_status == 'REJECTED') {
          alert("OD Request Rejected Successfully");
        }
        if (to_status == 'NEW') {
          alert("OD Request status reverted Successfully");
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.request_list_loading = false;
    }
  }
}

interface IODRequest {
  _id?: string,
  academic_year?: string,
  start_date: string,
  end_date: string,
  hour_number: number[],
  roll_nos: string[],
  reason: string,
  status?: string
}