<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Notifications</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info mg-r-10" (click)="openBulkSMS()">Send SMS</button>
                    <button type="button" class="btn btn-info" (click)="openBulkEmail()">Send E-Mail</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Student Notifications</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Send Notifications to Students</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programmes</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2"
                                    *ngIf="this.programme_section_list && this.programme_section_list.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section_id" class="form-control"
                                            (change)="onFilterSelectChange('section_id',$event)">
                                            <option value="">All Section</option>
                                            <option
                                                *ngFor="let programme_section of programme_section_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_section._id">{{programme_section.section_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Gender</label>
                                        <select formControlName="gender" class="form-control"
                                            (change)="onFilterSelectChange('gender',$event)">
                                            <option value="">All Gender</option>
                                            <option
                                                *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="gender.id">{{gender.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year</label>
                                        <select formControlName="enrolled_year" class="form-control"
                                            (change)="onFilterSelectChange('enrolled_year',$event)">
                                            <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20)"
                                                [ngValue]="enrolled_year">
                                                {{enrolled_year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll No</label>
                                        <input class="form-control" required="" type="text" formControlName="roll_no"
                                            placeholder="Enter Roll No.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!student_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>




                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th>Select
                                            <br>
                                            <input type="checkbox" (change)="markSelected($event)"
                                                [checked]="isChecked()" title="Choose All students">
                                            Select All

                                        </th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="students_list.length>0">


                                        <ng-container
                                            *ngFor="let student of students_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="text-uppercase">{{student.roll_no}}</td>
                                                <td class="text-uppercase">{{student.given_name}}
                                                    {{student.middle_name}}</td>
                                                <td class="text-uppercase">{{student.mobile_number}}</td>
                                                <td class="text-lowercase">{{student.email}}</td>
                                                <td class="text-center">
                                                    <input type="checkbox" name="student.roll_no" id="student.roll_no"
                                                        [(ngModel)]="student.is_selected" value="student.roll_no">
                                                </td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                                            title="Send SMS" (click)="openSMS(student)">
                                                            <fa-icon [icon]="fonts.faMobile"></fa-icon>
                                                        </button>

                                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                                            title="Send E-Mail" (click)="openEmail(student)">
                                                            <fa-icon [icon]="fonts.faEnvelope"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>

                        <div class="row mg-t-15" *ngIf="students_list.length>0">
                            <div class="col-md-12 text-center">
                                <button type="button" class="btn btn-info btn-sm mg-r-10"
                                    (click)="openSelectedSMS()">Send
                                    SMS</button>
                                <button type="button" class="btn btn-info btn-sm mg-r-10"
                                    (click)="openSelectedEmail()">Send
                                    E-Mail</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="modal_mode=='sms'">
                    <div class="modal-header">
                        Send SMS
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="sms_form">
                            <div class="row">
                                <div class="col-md-12">
                                    <ng-container *ngIf="student">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">To Mobile
                                            No.</label>
                                        <input type="text" formControlName="mobile_no" name="mobile_no"
                                            class="form-control" readonly>
                                    </ng-container>

                                    <ng-container *ngIf="!student">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Mobile
                                            No.</label>
                                        <input type="text" formControlName="to_number" name="mobile_no"
                                            class="form-control" placeholder="Enter Mobile Nos. separated by comma">
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter SMS
                                        Content</label>
                                    <textarea formControlName="message" name="sms_content" class="form-control"
                                        rows="10" cols="30">
                                    </textarea>
                                </div>
                            </div>
                        </form>

                        <div class="row mg-t-20">
                            <div class="col-md-12">
                                <button class="btn btn-md btn-secondary mg-r-10" (click)="closeModal()"
                                    style="float: right;">Close</button>
                                <button class="btn btn-md btn-success mg-r-10" (click)="sendSMS()" style="float: right;"
                                    *ngIf="!sms_save_loading">Send SMS</button>
                                <button class="btn btn-md btn-success mg-r-10" *ngIf="sms_save_loading"
                                    style="float: right;" disabled>Sending...</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="modal-content modal-content-demo" *ngIf="modal_mode=='email'">
                    <div class="modal-header">
                        Send Email
                    </div>
                    <div class="modal-body">

                        <form [formGroup]="mail_form">
                            <div class="row">
                                <div class="col-md-12">
                                    <ng-container *ngIf="student">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">To Email
                                            ID</label>
                                        <input type="text" formControlName="mail_id" name="mail_id" class="form-control"
                                            readonly>
                                    </ng-container>

                                    <ng-container *ngIf="!student">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Email
                                            ID</label>
                                        <input type="text" formControlName="mail_id" name="mail_id" class="form-control"
                                            placeholder="Enter Email Ids. separated by comma">
                                    </ng-container>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Subject</label>
                                    <input type="text" formControlName="mail_subject" name="mail_subject"
                                        class="form-control" placeholder="Enter Email Subject">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Email
                                        Content</label>
                                    <kendo-editor #editors class="question" formControlName="mail_body" name="editor"
                                        style="height: 300px;">
                                        <kendo-toolbar>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorUndoButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorRedoButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorBoldButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorItalicButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnderlineButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorSubscriptButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorSuperscriptButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignRightButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-dropdownlist kendoEditorFormat>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontSize>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontFamily>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-colorpicker kendoEditorForeColor>
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient">
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorIndentButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorOutdentButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnlinkButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-editor-insert-table-button>
                                            </kendo-editor-insert-table-button>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddColumnAfterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowAfterButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorDeleteColumnButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteRowButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteTableButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-button kendoEditorInsertFileButton>
                                            </kendo-toolbar-button>
                                            <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                            <kendo-toolbar-button kendoEditorViewSourceButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button kendoEditorCleanFormattingButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button text="Upload Image"
                                                (click)="openUploadImage($event,'question')">
                                            </kendo-toolbar-button>
                                        </kendo-toolbar>
                                    </kendo-editor>
                                </div>
                            </div>
                        </form>

                        <div class="row mg-t-20">
                            <div class="col-md-12">
                                <button class="btn btn-md btn-secondary mg-r-10" (click)="closeModal()"
                                    style="float: right;">Close</button>
                                <button class="btn btn-md btn-success mg-r-10" (click)="sendMail()"
                                    style="float: right;">Send Mail</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <div class="modal" id="modal-2">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="editor_popup_form">
                    <div class="modal-header">
                        Upload Image
                    </div>
                    <div class="modal-body">
                        <div class="card card-body">
                            <input class="form-control" required="" type="file"
                                (change)="uploadImage($event,editor_popup_form)" accept="image/*">
                            <ng-container *ngIf='editor_popup_form.value.image_url'>
                                <img [style.width.px]="editor_popup_form.value.width"
                                    [style.height.px]="editor_popup_form.value.height"
                                    [src]='editor_popup_form.value.image_url' />
                            </ng-container>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">


                                <button class="btn ripple btn-secondary" (click)="onCloseImageUpload()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>