<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">CAS Report</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <!-- <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Component</button> -->
                </div>
            </div>
        </div>
        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">



                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">CAS Report</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Generate course-wise CAS Report</p>
                    </div>


                    <div class="card-body">
                        <ng-container *ngIf="component_search_form">
                            <form [formGroup]="component_search_form">
                                <div class="row row-sm">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control"
                                                (change)="onFilterSelectChange('programme_type_id',$event)">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                enrollment year</label>
                                            <select formControlName="enrolled_year" class="form-control"
                                                (change)="onFilterSelectChange('enrolled_year',$event)">
                                                <option value="">Select enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Offering
                                                Programme</label>
                                            <select formControlName="offering_programme_id" class="form-control"
                                                (change)="onFilterSelectChange('programme_id',$event)">
                                                <option value="">All Programmes</option>
                                                <option
                                                    *ngFor="let programme of filter_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                    {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                semester</label>
                                            <select formControlName="semester" class="form-control"
                                                (change)="onFilterSelectChange('semester',$event)">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">
                                                Course Code</label>
                                            <input type="text" class="form-control" formControlName="course_code"
                                                (change)="onFilterSelectChange('course_code',$event)"
                                                placeholder="Enter course code">
                                        </div>
                                    </div>


                                    <div class="col-lg-2">
                                        <div class="form-group">

                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="course_list_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                        <div class="mg-b-20"></div>

                        <!-- <div class="row" *ngIf="course_list?.length > 0">
                            <div class="col-md-2">
                                <h6>Choose Programme & Section</h6>
                            </div>
                        </div> -->

                        <form [formGroup]="component_search_form">
                            <div class="row" *ngIf="course_list?.length > 0">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">
                                            Choose Programme & Section</label>
                                        <select formControlName="staff_map_id" class="form-control"
                                            (change)="getReportData()">
                                            <option value="">Choose option</option>
                                            <option [value]="course._id"
                                                *ngFor="let course of course_list; let i = index;">
                                                {{getCourseDetails(course)}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="mg-b-20"></div>

                        <form [formGroup]="component_search_form" *ngIf="component_search_form.value.staff_map_id">
                          <div class="row" *ngIf="course_list?.length > 0">
                              <div class="col-lg-3">
                                  <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">
                                          Enter overall attainment</label>
                                        <input type="text" class="form-control" formControlName="overall_attainment"
                                        placeholder="Enter overall attainment">
                                  </div>
                              </div>

                              <div class="col-lg-3">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">
                                        Enter final grade</label>
                                      <input type="text" class="form-control" formControlName="final_grade"
                                      placeholder="Enter final grade">
                                </div>
                              </div>

                              <div class="col-lg-2">
                                <div class="form-group">

                                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                        title="Submit" *ngIf="!final_attainment_list_loading" (click)="onSubmitFinalAttainment()">
                                         Submit
                                    </button>
                                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                        disabled *ngIf="final_attainment_list_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                              </div>
                          </div>
                        </form>


                        <div id="accordion" *ngIf="component_search_form.value.staff_map_id">
                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-one" data-toggle="collapse"
                                        href="#collapseOne" (click)="changeActiveAccordion(1)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Internal Marks</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faCheck" class="valid"
                                                    *ngIf="report_data.internal_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faTimes" class="invalid"
                                                    *ngIf="!report_data.internal_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(1)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(1)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseOne" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <!--No Data in Report; Upload data-->
                                        <ng-container *ngIf="!report_data || !report_data.internal_marks">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="radio" value="EXISTING" name="internal_value"
                                                            [(ngModel)]="internal_value">
                                                        Use Existing Internal Marks
                                                        <input type="radio" value="FILE" [(ngModel)]="internal_value"
                                                            name="internal_value" class="mg-l-10"> Upload
                                                        Internal Marks
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="internal_value == 'EXISTING'">
                                                <div class="col-md-4">
                                                    <button class="btn btn-md btn-info" *ngIf="!internal_list_loading"
                                                        (click)="getInternalCO()">Get
                                                        Existing Internal
                                                        Marks</button>

                                                    <button class="btn btn-md btn-info" type="button" disabled
                                                        *ngIf="internal_list_loading">
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                </div>


                                            </div>


                                            <div class="row" *ngIf="internal_value == 'FILE'">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <app-modal [render_data]="internal_render_data"
                                                            (csvData)="bulkUploadInternal($event)"></app-modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="report_data && report_data.internal_marks">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="viewUploadedMarks('INTERNAL')">View Uploaded
                                                        Marks</button>
                                                    <button class="btn btn-md btn-danger mg-l-10"
                                                        (click)="removeUploadedMarks('INTERNAL')">Remove Uploaded
                                                        Marks</button>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-two" data-toggle="collapse"
                                        href="#collapseTwo" (click)="changeActiveAccordion(2)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>External Marks</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faCheck" class="valid"
                                                    *ngIf="report_data.external_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faTimes" class="invalid"
                                                    *ngIf="!report_data.external_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(2)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(2)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <ng-container *ngIf="!report_data || !report_data.external_marks">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="radio" value="EXISTING"
                                                            [(ngModel)]="external_value" name="external_value">
                                                        Use Existing Terminal Marks
                                                        <input type="radio" value="FILE" [(ngModel)]="external_value"
                                                            name="external_value" class="mg-l-10"> Upload
                                                        File
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row row-sm" *ngIf="external_value == 'EXISTING'">
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                            Month</label>
                                                        <select [(ngModel)]="external_month" class="form-control">
                                                            <option value="0">Select Month</option>
                                                            <option
                                                                *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                                [value]="month.id">{{month.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                            Year</label>
                                                        <select [(ngModel)]="external_year" class="form-control">
                                                            <option value="0">Select year</option>
                                                            <option
                                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                                [ngValue]="year">{{year}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                            Valuation</label>
                                                        <select [(ngModel)]="external_valuation" class="form-control">
                                                            <option value="0">Select Valuation</option>
                                                            <option value="TOTAL">Total</option>
                                                            <option value="1">Valuation 1</option>
                                                            <option value="2">Valuation 2</option>
                                                            <option value="3">Valuation 3</option>
                                                            <option value="4">Valuation 4</option>
                                                            <option value="5">Valuation 5</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2">
                                                    <div class="form-group">
                                                        <button type="button"
                                                            class="mg-t-30 btn btn-info mr-2 search-button"
                                                            title="Search" *ngIf="!valuation_list_loading"
                                                            (click)="searchValuations()">
                                                            Get Marks
                                                        </button>
                                                        <button class="mg-t-30 btn btn-info mr-2 search-button"
                                                            type="button" disabled *ngIf="valuation_list_loading">
                                                            <span class="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"></span>
                                                            Loading...
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="external_value == 'FILE'">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <app-modal [render_data]="external_render_data"
                                                            (csvData)="bulkUploadExternal($event)"></app-modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="report_data && report_data.external_marks">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="viewUploadedMarks('EXTERNAL')">View Uploaded
                                                        Marks</button>
                                                    <button class="btn btn-md btn-danger mg-l-10"
                                                        (click)="removeUploadedMarks('EXTERNAL')">Remove Uploaded
                                                        Marks</button>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-three" data-toggle="collapse"
                                        href="#collapseThree" (click)="changeActiveAccordion(3)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Course Exit Survey</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faCheck" class="valid"
                                                    *ngIf="report_data.ces_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faTimes" class="invalid"
                                                    *ngIf="!report_data.ces_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(3)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(3)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseThree" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <ng-container *ngIf="!report_data || !report_data.ces_marks">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="radio" value="EXISTING" [(ngModel)]="ces_value"
                                                            name="ces_value"> Use
                                                        Existing Survey results
                                                        <input type="radio" value="FILE" [(ngModel)]="ces_value"
                                                            name="ces_value" class="mg-l-10"> Upload File
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="ces_value == 'EXISTING'">
                                                <div class="col-md-4">
                                                    <button class="btn btn-md btn-info" (click)="getCESCO()"
                                                        *ngIf="!ces_list_loading">Get
                                                        Existing Survey Marks</button>
                                                    <button class="btn btn-md btn-info" type="button" disabled
                                                        *ngIf="ces_list_loading">
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="ces_value == 'FILE'">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <app-modal [render_data]="ces_render_data"
                                                            (csvData)="bulkUploadCES($event)"></app-modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="report_data && report_data.ces_marks">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="viewUploadedMarks('CES')">View Uploaded Marks</button>
                                                    <button class="btn btn-md btn-danger mg-l-10"
                                                        (click)="removeUploadedMarks('CES')">Remove Uploaded
                                                        Marks</button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-four" data-toggle="collapse"
                                        href="#collapseFour" (click)="changeActiveAccordion(4)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Report Parameters</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(4)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(4)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseFour" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">CO
                                                        Target (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.target_percentage"
                                                        placeholder="Enter % of the Maximum in each CO">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        class="main-content-label tx-11 tx-medium tx-gray-600">Internal
                                                        Marks Scale (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.internal_scale_to"
                                                        placeholder="Enter value to which the total internal marks has to be scaled down">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        class="main-content-label tx-11 tx-medium tx-gray-600">External
                                                        Marks Scale (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.external_scale_to"
                                                        placeholder="Enter value to which the total external marks has to be scaled down">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Direct
                                                        Attainment
                                                        Scale (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.da_scale"
                                                        placeholder="Enter % to which Direct attainment must be scaled">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        class="main-content-label tx-11 tx-medium tx-gray-600">Remarks</label>
                                                    <textarea class="form-control" [(ngModel)]="report_data.remarks"
                                                        rows="3" cols="10" placeholder="Enter Remarks"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row" *ngIf="component_search_form.value.staff_map_id">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-success btn-md" *ngIf="!report_loading"
                                    (click)="saveUpdateReport()" [disabled]="!canDisplayReport()">Save & Generate
                                    Report</button>
                                <button class="btn btn-md btn-success" type="button" disabled *ngIf="report_loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Saving...
                                </button>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="component_search_form.value.staff_map_id && canDisplayReport() && report_data?._id">
                            <div class="col-md-12" style="text-align: right;">
                                <button class="btn btn-sm btn-secondary mg-r-10" (click)="downloadExcel()">Download
                                    Excel</button>
                                <button class="btn btn-sm btn-secondary" (click)="downloadPDF()">Download PDF</button>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="component_search_form.value.staff_map_id && canDisplayReport() && report_data?._id">
                            <div class="container" id="report-html">
                                <!-- <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-12 text-center">
                                        <h6>AYYA NADAR JANAKI AMMAL COLLEGE (Autonomous), SIVAKASI</h6>
                                    </div>

                                    <div class="col-md-12 text-center">
                                        <h6>Details of CO Attainment Calculations</h6>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <p>Year: <b>{{report_data.enrolled_year}}</b></p>
                                    </div>

                                    <div class="col-md-3">
                                        <p>Semester: <b>{{report_data.semester}}</b></p>
                                    </div>

                                    <div class="col-md-3">
                                        <p>Course Code: <b>{{report_data.course_code}} - {{course_data.course_name}}</b></p>
                                    </div>

                                    <div class="col-md-3">
                                        <p *ngIf="!report_data.virtual_section">Programme & Section:
                                            <b>{{getProgrammeName(report_data.class_taking_programme_id)}} -
                                                {{report_data.class_taking_section}}
                                            </b>
                                        </p>
                                        <p *ngIf="report_data.virtual_section">Virtual Section:
                                            <b>{{report_data.virtual_section}}
                                            </b>
                                        </p>
                                    </div>
                                </div> -->
                                <div class="table-responsive" style="margin-top: 20px;">
                                    <table class="table table-bordered" id="report-table">
                                        <tbody>
                                            <tr>
                                                <th colspan="21" style="text-align: center;">AYYA NADAR JANAKI AMMAL
                                                    COLLEGE (Autonomous), SIVAKASI</th>
                                            </tr>
                                            <tr>
                                                <th colspan="21" style="text-align: center;">Details of CO Attainment
                                                    Calculations</th>
                                            </tr>
                                            <tr>
                                                <th colspan="3">
                                                    Year: {{report_data.enrolled_year}}
                                                </th>
                                                <th colspan="3">
                                                    Semester: {{report_data.semester}}
                                                </th>
                                                <th colspan="8">
                                                    Course Code: {{report_data.course_code}} -
                                                    {{course_data.course_name}}
                                                </th>
                                                <th colspan="7">
                                                    <ng-container *ngIf="!report_data.virtual_section">Programme &
                                                        Section:
                                                        {{getProgrammeName(report_data.class_taking_programme_id)}} -
                                                        {{report_data.class_taking_section}}
                                                    </ng-container>
                                                    <ng-container *ngIf="report_data.virtual_section">Virtual Section:
                                                        {{report_data.virtual_section}}
                                                    </ng-container>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colspan="3">

                                                </th>
                                                <th colspan="6">
                                                    Internal
                                                </th>
                                                <th colspan="6">
                                                    External
                                                </th>
                                                <th colspan="6">
                                                    Course Exit Survey
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colspan="3">

                                                </th>
                                                <th
                                                    *ngFor="let weight of report_data.internal_marks?.weightage | keyvalue;">
                                                    {{weight.value}}
                                                </th>
                                                <th
                                                    *ngFor="let weight of report_data.external_marks?.weightage | keyvalue;">
                                                    {{weight.value}}
                                                </th>
                                                <th *ngFor="let weight of report_data.ces_marks?.weightage | keyvalue;">
                                                    {{weight.value}}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colspan="3">

                                                </th>
                                                <th
                                                    *ngFor="let weight of report_data.internal_marks?.weightage | keyvalue;">
                                                    {{weight.key | uppercase}}
                                                </th>
                                                <th
                                                    *ngFor="let weight of report_data.external_marks?.weightage | keyvalue;">
                                                    {{weight.key | uppercase}}
                                                </th>
                                                <th *ngFor="let weight of report_data.ces_marks?.weightage | keyvalue;">
                                                    {{weight.key | uppercase}}
                                                </th>
                                            </tr>
                                            <tr
                                                *ngFor="let student of report_data?.internal_marks?.marks; let i = index;">
                                                <td>{{i+1}}</td>
                                                <td>{{student.roll_no}}</td>
                                                <td>{{student.given_name}} {{student.middle_name}}</td>
                                                <td *ngFor="let weight of student.co_marks | keyvalue;">
                                                    {{weight.value}}
                                                </td>
                                                <td
                                                    *ngFor="let weight of report_data.external_marks?.marks[i].co_marks | keyvalue;">
                                                    {{weight.value}}
                                                </td>
                                                <td
                                                    *ngFor="let weight of report_data.ces_marks?.marks[i].co_marks | keyvalue;">
                                                    {{weight.value}}
                                                </td>
                                            </tr>

                                            <!--Report Rows-->
                                            <tr>
                                                <td colspan="3">
                                                    <b>Target({{report_data.target_percentage}}% of the Maximum in each
                                                        CO)</b>
                                                </td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.internal_result?.target_weights | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td></td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.external_result?.target_weights | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td *ngFor="let arr of createArrayWithLimit(7)"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>No. of Students >= the Target</b>
                                                </td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.internal_result?.target_students | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td></td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.external_result?.target_students | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td *ngFor="let arr of createArrayWithLimit(7)"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>Average of Internal COs</b>
                                                </td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.internal_result?.co_percent | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td><b>{{report_data.internal_result.average_marks}}</b></td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.external_result?.co_percent | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td><b>{{report_data.external_result.average_marks}}</b></td>
                                                <td *ngFor="let arr of createArrayWithLimit(6)"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>CO Attainment Level</b>
                                                </td>
                                                <ng-container
                                                    *ngFor="let weight of report_data.internal_result?.co_grade | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td *ngFor="let arr of createArrayWithLimit(13)"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>Scaledown (Internal {{report_data.internal_scale_to}}% & External
                                                        {{report_data.external_scale_to}}%)</b>
                                                </td>
                                                <td *ngFor="let arr of createArrayWithLimit(5)"></td>
                                                <td>
                                                    <b>{{report_data.internal_result.scaled_marks}}</b>
                                                </td>
                                                <td *ngFor="let arr of createArrayWithLimit(5)"></td>
                                                <td>
                                                    <b>{{report_data.external_result.scaled_marks}}</b>
                                                </td>
                                                <td *ngFor="let arr of createArrayWithLimit(6)"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>Direct Attainemnt (100%)</b>
                                                </td>
                                                <td colspan="18" style="text-align: right;">
                                                    <b>{{report_data.direct_attainment_sum}}%</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>Direct Attainment (Scaled to {{report_data.da_scale}}%)</b>
                                                </td>
                                                <td colspan="18" style="text-align: right;">
                                                    <b>{{report_data.direct_attainmeent_scaled}}%</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <b>Indirect Attainment ({{100 - report_data.da_scale}}%)</b>
                                                </td>
                                                <td colspan="18" style="text-align: right;">
                                                    <b>{{report_data.indriect_attainment_average}}%</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="3">
                                                    <b>Final Attainment of the course</b>
                                                </td>
                                                <td colspan="18" style="text-align: right;">
                                                    <b>{{report_data.overall_attainment}}%</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="3">
                                                    <b>Final Grade of the course</b>
                                                </td>
                                                <td colspan="18" style="text-align: right;">
                                                    <b>{{report_data.final_grade}}</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="3">
                                                    <b>Remarks</b>
                                                </td>
                                                <td colspan="18" style="text-align: right;">
                                                    <b>{{report_data.remarks}}</b>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </ng-container>

                        <ng-container *ngIf="component_search_form.value.staff_map_id && !canDisplayReport()">
                            <p><b>Please upload all marks inputs & set report params to generate and view the
                                    report.</b></p>
                        </ng-container>


                    </div>


                </div>
            </div>


        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-attainmentreport">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="this.cas_report_data">
                    <div class="modal-header">
                        <h6 class="modal-title">View Co-Wise Marks</h6>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 3%;">S.No</th>
                                            <th style="width: 10%;">Roll No</th>
                                            <th style="width: 20%;">Name</th>
                                            <th *ngFor="let weight of cas_report_data.weightage | keyvalue;">
                                                {{weight.key | uppercase}} ({{weight.value}})

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let mark of cas_report_data.marks; let i = index;">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{mark.roll_no}}
                                            </td>
                                            <td>
                                                {{mark.given_name}} {{mark.middle_name}}
                                            </td>
                                            <td *ngFor="let weight of mark.co_marks | keyvalue;">
                                                {{weight.value}}
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onCasReportCloseClick()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
