<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Generate Student Leave, Absent, OD Report</p>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="attendance_table_filter_form">


                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mg-t-25 search-button" title="Search"
                                            *ngIf="!student_list_loading" (click)="getStudentData()">
                                            Get Report
                                        </button>
                                        <button class="btn btn-info mg-t-25 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- attendance_table_filter_form.get('filter_mode').value=='month' && -->
                        <div class="d-flex right-content mg-b-5" style="float: right;"
                            *ngIf="monthly_attendance_data.length>0">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap" id="absent-report-table">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th>Select</th>
                                        <th style="width: 10%;">Roll No.</th>
                                        <th>Name</th>
                                        <th>Programme</th>
                                        <th>Mobile number</th>
                                        <th>Absent Hours</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <ng-container>
                                        <tr *ngFor="let data of monthly_attendance_data; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <input type="checkbox" [(ngModel)]="data.is_selected">
                                            </td>
                                            <td>{{data.roll_no}}</td>
                                            <td>{{data.given_name}}  {{data.middle_name}}</td>
                                            <td>{{data.programme_name}}</td>
                                            <td>{{data.mobile_number}}</td>
                                            <td *ngIf="data.hours.length > 0">
                                              <ng-container *ngFor="let hr of data.hours; let j = index">
                                                {{hr}},
                                              </ng-container>
                                            </td>
                                            <td *ngIf="data.hours.length == 0">0</td>
                                        </tr>
                                    </ng-container>


                                    <ng-container
                                        *ngIf="!attendance_data_loading && (monthly_attendance_data.length==0)">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="attendance_data_loading && (monthly_attendance_data.length==0)">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <!-- <div class="modal" id="modal-popup-view">
          <div class="modal-dialog modal-lg small-modal" role="document">
              <div class="modal-content modal-content-demo" *ngIf="monthly_attendance_data && hour_numbers.length > 0">
                  <div class="modal-header">
                      <h6 class="modal-title">View absent hours</h6>
                  </div>
                  <div class="modal-body">
                      <div>
                          <div class="table-responsive">
                              <table class="table table-bordered mg-b-0 text-md-nowrap">
                                  <thead>
                                      <tr>
                                          <th style="width: 5%;">S.No</th>
                                          <th>Hour number</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let hr of hour_numbers; let i = index;">
                                          <td>
                                              {{i+1}}
                                          </td>
                                          <td>
                                              {{hr}}
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>

                          <div class="mg-b-20"></div>
                          <div class="text-align-right">
                              <button class="btn ripple btn-secondary" (click)="onModalCloseClick()"
                                  type="button">Close</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div> -->
      <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>
