import { ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { IStudents } from 'src/app/services/rest/entity/IStudents';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-students-search',
  templateUrl: './students-search.component.html',
  styleUrls: ['./students-search.component.scss']
})
export class StudentsSearchComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_section_list: any[] = [];

  programme_search_form: any;

  filter_programme_list: any[] = [];
  filter_section_list: any[] = [];

  student_sub: string = "";

  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];

  status_types: any[] = [
    { id: 'is_left', text: 'Left' },
    { id: 'is_long_absent', text: 'Long Absent', },
    { id: 'is_repeat', text: 'Repeat' },
    { id: 'is_lateral_entry', text: 'Lateral Entry' }
  ];

  // core data

  students_list: any[] = [];
  student_list_loading = false;

  student: IStudents | undefined = undefined;
  student_form: any;
  dropdownSettings: IDropdownSettings = {};
  statusDropdownSettings: IDropdownSettings = {};
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService,
    private appRef: ApplicationRef, private changeDetector: ChangeDetectorRef) {
    JQueryHelper.toPageInit();
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 20,
    };
    this.statusDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
    };
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
    // Load core data
    this.getProgrammeDataList();
  }
  /// master data
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      enrolled_years: [2020],
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section_id: [''],
      gender: [''],
      religion: [''],
      community_id: [''],
      blood_group: [''],
      //caste: [''],
      status_types: [""],//is_left, is_long_absent, is_repeat, is_lateral_entry
      roll_no: [''],
      aadhar_card_no: [""],
    });

  }


  resetForm(): void {
    this.student_form = undefined;
    this.student = undefined;
    this.filter_programme_list = []; this.filter_section_list = [];
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    } else if (id === 'programme_id') {
      this.programme_section_list = [];
      this.programme_search_form.get('section_id').setValue("");
      const programme = this.programme_list.find(c => c.programme_id === this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.programme_section_list = programme.section_details;
      }
      if (programme.section_details.length == 1) {
        this.programme_search_form.get('section_id').setValue(programme.section_details[0]._id);
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      if (this.programme_search_form.value.programme_type_id && this.programme_search_form.value.finance_type) {

        this.programme_list_loading = true;
        this.programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.programme_search_form) {
          if (this.programme_search_form.value.programme_type_id) {
            search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
          }
          if (this.programme_search_form.value.finance_type) {
            search_form_query.finance_type = this.programme_search_form.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.programme_list = service_response.data;
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }



  async onSearchClick(): Promise<void> {
    await this.searchFormDataList();

  }

  async searchFormDataList(): Promise<void> {
    try {


      this.student_list_loading = true;
      this.students_list = [];
      const search_form_query: any = {};
      if (this.programme_search_form.value.enrolled_years.length > 0) {
        search_form_query.enrolled_years = this.programme_search_form.value.enrolled_years
      }
      else {
        alert("Please select at least one enrollment year")
        return;
      }

      if (this.programme_search_form.value.programme_id) {
        search_form_query.programme_id = this.programme_search_form.value.programme_id;
      }
      if (this.programme_search_form.value.gender) {
        search_form_query.gender = this.programme_search_form.value.gender;
      }
      if (this.programme_search_form.value.programme_type_id) {
        search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
      }
      if (this.programme_search_form.value.finance_type) {
        search_form_query.finance_type = this.programme_search_form.value.finance_type;
      }
      if (this.programme_search_form.value.section_id) {
        search_form_query.section_id = this.programme_search_form.value.section_id;
      }

      if (this.programme_search_form.value.community_id) {
        search_form_query.community_id = this.programme_search_form.value.community_id;
      }

      if (this.programme_search_form.value.blood_group) {
        search_form_query.blood_group = this.programme_search_form.value.blood_group;
      }

      if (this.programme_search_form.value.religion) {
        search_form_query.religion = this.programme_search_form.value.religion;
      }

      if (this.programme_search_form.value.status_types.length > 0) {
        let status_types_data = [];
        let chosen_types = this.programme_search_form.value.status_types;
        for (var i = 0; i < chosen_types.length; i++) {
          status_types_data.push(chosen_types[i].id);
        }
        search_form_query.status_types = status_types_data;
      }
      else {
        search_form_query.status_types = ['all'];
      }

      if (this.programme_search_form.value.roll_no) {
        search_form_query.roll_no = this.programme_search_form.value.roll_no;
      }

      if (this.programme_search_form.value.aadhar_card_no) {
        search_form_query.aadhar_card_no = this.programme_search_form.value.aadhar_card_no;
      }

      const service_response = await this.restService.searchAllStudents(search_form_query);

      if (service_response && service_response.success) {
        this.students_list = service_response.data;
      }
      //console.log(this.students_list);
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.student_list_loading = false;
    }
  }


  async downloadAsCSV() {
    const data_list: any[] = [];
    let i = 1;

    CSVHelper.downloadCSV(data_list, 'students-search-result-' + DateHelper.convertToControlDate(new Date()));
  }


}
