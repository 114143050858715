import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DOCUMENT } from '@angular/common';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';

@Component({
  selector: 'app-internal-exam-valuation',
  templateUrl: './internal-exam-valuation.component.html',
  styleUrls: ['./internal-exam-valuation.component.scss']
})
export class InternalExamValuationComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;
  title = 'Internal Exam Valuation';

  student_search_form: any;
  course_list_loading: boolean = false;
  course_list: any[] = [];
  appearance_types: { key: string, text: string }[] = [{ key: "normal", text: "Normal" }, { key: "ARREAR", text: "Arrear" },
  { key: "REPEAT", text: "Repeat Semester" }];
  student_list_loading: boolean[] = [];
  student: any = undefined;
  setting: IExamSetup = {} as IExamSetup;
  score_card_form: FormGroup = undefined;
  setting_id: string = "";
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  student_search_query: any = {};
  student_data: any[] = [];
  section_strings: { id: number, key: string }[] = [{ id: 1, key: "A" }, { id: 2, key: "B" }, { id: 3, key: "C" }, { id: 4, key: "D" },
  { id: 5, key: "E" }, { id: 6, key: "F" }, { id: 7, key: "G" }, { id: 8, key: "H" }, { id: 9, key: "I" }, { id: 10, key: "J" }];
  //modal data
  evaluation_data: any; //overall result
  score_card: any; //student data
  score_card_data: any = {}; //mark data
  questions_settings: any[] = []; //question_settings inside exam_setup
  question: any = {};
  mark_save_loading: boolean = false;
  submission_detail: any = {};
  section_render_data: {
    section_no: number, section_name: string, span: number
  }[] = [];

  file_uploading: boolean = false;
  student_file_data: any = undefined;
  file: File;
  files: any[] = [];
  preview_url: string = "";
  modal_mode: string = "";
  exam_id: string = "";
  submission_id: string = "";
  reason: string = "";
  reject_loading: boolean;
  zoom_to: number = 1;
  exam_question_format: any[] = [];
  score_sheet_data: any[] = [];
  score_sheet_data_loading: boolean[] = [];
  modal_course_data: any = {};
  question_format_type: string = '';
  co_settings: ICoSettings[] = [];
  attainment_description = '';

  bulk_upload_mark_data: Modal = {
    modal_id: "bulk_upload_mark_data_modal",
    modal_button_text: "Marks Bulk Upload",
    return_type: "FILE",
    modal_header: "Marks Bulk Upload",
    list_name: "Marks Upload",
    btn_size: "btn-sm",
    download_note: "Do not change the exam_id, valuation_no, student_roll_no fields in the downloaded file; For CO Level without mark, enter zero",
    csv_data: {
      columns: [
        { column_name: "exam_id", column_type: "required" },
        { column_name: "valuation_no", column_type: "required" },
        { column_name: "student_roll_no", column_type: "required" },
        { column_name: "co1", column_type: "optional" },
        { column_name: "co2", column_type: "optional" },
        { column_name: "co3", column_type: "optional" },
        { column_name: "co4", column_type: "optional" },
        { column_name: "co5", column_type: "optional" },
      ],
      row_data: ""
    },
    table_data: {
      column_names: ["Exam ID", "Valuation No.", "Student Roll No", "CO1", "CO2", "CO3", "CO4", "CO5"],
      column_values: ["454dasfd-asdf-sdfsd", "1", "20PA01", "10", "10", "15", "5", "0"]
    }
  };

  ngOnInit(): void {

  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, @Inject(DOCUMENT) private document: any) {
    //this.buildMarkForm();
    this.buildFilterForm();
    this.getProgrammeTypeList();
    this.getProgrammeDataList();
  }

  buildFilterForm(): void {
    this.student_search_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [""],
      semester: [""],
      course_code: ['']
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }
  getProgrammeName(class_taking_programme_id: string) {
    return this.programme_list.find(x => x.programme_id == class_taking_programme_id)?.display_name;
  }

  async onSearchClick() {

    if (!this.student_search_form.value.programme_type_id) {
      alert("Please select Programme Type"); return;
    }
    if (!this.student_search_form.value.enrolled_year) {
      alert("Please select Enrolment Year"); return;
    }
    if (!this.student_search_form.value.semester) {
      alert("Please select Semester"); return;
    }
    this.course_list = [];
    this.course_list_loading = true;
    try {
      const service_response = await this.restService.findInternalExamsByValuations(this.student_search_form.value);
      if (service_response && service_response.success) {
        this.course_list = service_response.data;
        var i = 0;
        this.course_list.forEach(course => {
          course.is_opened = false;
          this.student_list_loading[i] = false;
          this.score_sheet_data_loading[i] = false;
          i++;
        })
        console.log(this.course_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.course_list_loading = false;
    }
  }

  async displayStudents(course, i) {
    course.is_opened = true;
    try {
      this.student_data = [];
      this.student_list_loading[i] = true;
      this.student_search_query = {
        "exam_id": course._id,
        "staff_map_id": course.valuations.staff_course_map_id,
        "evaluated_in": course.valuations.evaluated_in
      }
      await this.getStudentData();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_list_loading[i] = false;
    }

    this.course_list.forEach(course => {
      if (course._id != course._id) {
        course.is_opened = false;
      }
    });
  }

  getMonth(month: string) {
    return this.commonEnums.months.find(x => x.id == month)?.text;
  }

  async viewScoreSheet(course, i) {
    try {
      this.score_sheet_data = [];
      this.section_render_data = [];
      this.modal_course_data = {
        course_code: course.course_code,
        course_name: course.course_name,
        month: this.getMonth(course.month),
        year: course.year
      }
      this.score_sheet_data_loading[i] = true;
      this.student_search_query = {
        "exam_id": course._id,
        "staff_map_id": course.valuations.staff_course_map_id,
        "evaluated_in": course.valuations.evaluated_in
      }
      const service_response = await this.restService.findInternalStudentScoreCard(this.student_search_query);
      if (service_response && service_response.success) {
        this.score_sheet_data = (service_response.data);
        this.modal_course_data.valuation_no = this.score_sheet_data[0]?.valuation_no;
        var score_card_items = this.score_sheet_data[0]?.score_card_items;
        for (var index = 0; index < score_card_items.length; index++) {
          if (this.section_render_data.findIndex(x => x.section_no == score_card_items[index].section_no) < 0) {
            this.section_render_data.push({
              section_no: score_card_items[index].section_no,
              section_name: this.section_strings.find(x => x.id == score_card_items[index].section_no).key,
              span: 1
            })
          }
          else {
            this.section_render_data.find(x => x.section_no == score_card_items[index].section_no)!.span++;
          }
        }
        console.log(this.section_render_data);

        JQueryHelper.openModal('#score-sheet-popup', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.score_sheet_data_loading[i] = false;
    }
  }

  async closeScoreSheet() {
    this.score_sheet_data = [];
    this.section_render_data = [];
    this.modal_course_data = {};
    JQueryHelper.closeModal('#score-sheet-popup');
  }

  printScoreSheet() {
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script> " +
      "<style>@page { size: landscape;} @media print { html, body { margin-right:3%; margin-left:3%; margin-top:2%; margin-bottom:3%} #print-table{width:90%}}</style>")
    mywindow.document.write('</head><body><div style="font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-area').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.write('<script>document.getElementById("print-table").classList.remove("table-responsive");</script>');
    //mywindow.document.write('<script>document.getElementById("print-table").style.width = "90%";</script>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  async getStudentData() {
    try {
      const service_response = await this.restService.findInternalStudentScoreCard(this.student_search_query);
      if (service_response && service_response.success) {
        this.student_data = (service_response.data);
        this.student_data.forEach(student => {
          this.bulk_upload_mark_data.csv_data.row_data += student.exam_id + "," + student.valuation_no + "," + student.student_roll_no + "\r\n";
        })
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {

    }
  }

  closeStudents(student, i) {
    student.is_opened = false;
    this.student_search_query = {};
    this.student_data = [];
    this.bulk_upload_mark_data.csv_data.row_data = '';
  }

  getSectionName(section_no: number) {
    return this.section_strings.find(x => x.id == section_no)?.key
  }

  zoomIn() {
    if (this.zoom_to < 3) {
      this.zoom_to = this.zoom_to + 0.25;
    }
  }

  zoomOut() {
    if (this.zoom_to > 1) {
      this.zoom_to = this.zoom_to - 0.25;
    }
  }

  async onEvaluateClick(student) {
    //this.student = student;
    try {
      var search_query = {
        "score_card_id": student._id,
      }
      const service_response = await this.restService.findInternalOneStudentScoreCard(search_query);
      if (service_response && service_response.success) {
        this.evaluation_data = service_response.data;
        this.score_card = this.evaluation_data.score_card;
        this.exam_question_format = this.evaluation_data.question_format?.exam_question_format;
        this.question_format_type = this.evaluation_data.question_format?.question_format_type;
        if (this.question_format_type == 'Co-Wise') {
          this.co_settings = this.evaluation_data.exam_setup.co_settings;
        }
        /* this.score_card.file_path = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf"; //need to get from api */
        this.submission_detail = this.evaluation_data.submission_detail;
        if (this.score_card.score_card_items?.length == 0) {
          alert("Could not load Evaluation Sheet. Please check CO-K Level Mapping in question setting or contact admin");
          return;
        }
        var score_card_items: any[] = this.score_card.score_card_items;
        score_card_items.forEach(item => {
          item.question_no = parseInt(item.question_no);
        })
        this.score_card_data = {
          exam_id: this.score_card.exam_id,
          student_sub: this.score_card.student_sub,
          valuation_no: this.score_card.valuation_no,
          evaluated_in: this.score_card.evaluated_in,
          score_card_items: score_card_items
        }
        this.questions_settings = this.evaluation_data.exam_setup.questions_settings
        this.buildMarkForm();
        if (this.question_format_type == 'Section-Wise')
          this.displayScheme(1, 1, '');
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
    var elem: HTMLElement | any = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    JQueryHelper.openModal('#score-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  displayScheme(section_no: number, question_no: number, option: string) {
    var questions: any[] = this.questions_settings.find(x => x.section_no == section_no).questions;
    this.question = questions.find(x => x.question_no == question_no && x.option == option);
  }

  displayAttainment(co_id) {
    this.attainment_description = this.co_settings.find(x => x.co_id == co_id)?.description;
  }

  checkMaxCoMark(ev, co_id) {
    var txtbox = (ev.target as HTMLInputElement);
    var max_mark = this.co_settings.find(x => x.co_id == co_id)?.max_mark;
    if (parseFloat(txtbox.value) > max_mark) {
      alert("Error! Max mark for this CO Level is " + max_mark);
      txtbox.value = "0";
      (this.score_card_form.get('score_card_items') as FormArray).controls.
        find(x => x.get('co_id').value == co_id).get('mark').setValue(0);
    }
    if (parseFloat(txtbox.value) < 0) {
      alert("Error! Mark cannot be less than zero");
      txtbox.value = "0";
      (this.score_card_form.get('score_card_items') as FormArray).controls.
        find(x => x.get('co_id').value == co_id).get('mark').setValue(0);
    }
  }


  checkMaxMark(ev, section_no) {
    var txtbox = (ev.target as HTMLInputElement);
    var max_mark = this.exam_question_format.find(x => x.section_no == section_no)?.question_max_mark;
    if (parseFloat(txtbox.value) > max_mark) {
      alert("Error! Max mark for this question is " + max_mark);
      txtbox.value = "0";
    }
    if (parseFloat(txtbox.value) < 0) {
      alert("Error! Mark cannot be less than zero");
      txtbox.value = "0";
    }
  }
  async onCloseClick(): Promise<void> {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (this.document.webkitExitFullscreen) { /* Safari */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) { /* IE11 */
      this.document.msExitFullscreen();
    }
    this.getStudentData();
    this.evaluation_data = {}; //overall result
    this.score_card = {}; //student data
    this.score_card_data = {}; //mark data
    this.questions_settings = []; //question_settings inside exam_setup
    this.question = {};
    this.score_card_form = undefined;
    this.exam_question_format = [];
    this.co_settings = [];
    this.question_format_type = '';
    this.attainment_description = '';
    JQueryHelper.closeModal('#score-modal-popup');
  }

  async saveMark() {
    try {
      var save_err: boolean = false;
      this.mark_save_loading = true;
      var form_data = JSON.parse(JSON.stringify(this.score_card_form.value));
      if (this.question_format_type == 'Section-Wise') {
        var score_card_items: any[] = form_data.score_card_items;
        score_card_items.forEach(item => {
          delete item.display_section_header;
        });
        for (var i = 0; i < score_card_items.length; i++) {
          if (score_card_items[i].option != '' && score_card_items[i].mark != 0) {
            var search_option: string = "";
            if (score_card_items[i].option == 'A') search_option = 'B';
            else search_option = 'A';
            if (score_card_items.find(x => x.question_no == score_card_items[i].question_no && x.option == search_option).mark > 0) {
              alert("Save Error! Question " + score_card_items[i].question_no + " has mark in both options. Either OR question cannot have marks in both option. ");
              save_err = true;
              break;
            }
          }
        }
        form_data.score_card_items = score_card_items;
      }
      console.log(form_data);
      let save_respose;
      if (!save_err) {
        save_respose = await this.restService.updateInternalScoreCard(form_data);
        if (save_respose.success) {
          alert('Mark Updated Successfully');
        }
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.mark_save_loading = false;
    }
  }

  buildMarkForm(): void {
    if (JSON.stringify(this.score_card_data) == '{}') {
      this.score_card_data = {
        exam_id: "",
        student_sub: "",
        valuation_no: "",
        evaluated_in: "",
        score_card_items: []
      };
    }
    this.score_card_form = this.formBuilder.group({
      exam_id: [this.score_card_data.exam_id, [Validators.required]],
      student_sub: [this.score_card_data.student_sub, [Validators.required]],
      valuation_no: [this.score_card_data.valuation_no, [Validators.required]],
      evaluated_in: [this.score_card_data.evaluated_in, [Validators.required]],
      score_card_items: this.formBuilder.array(this.buildScoreSheetForm(this.score_card_data.score_card_items)),
    });
  }

  buildScoreSheetForm(score_list: any[]): any {
    if (!score_list) {
      score_list = [];
    }
    score_list.sort((a, b) => {
      if (a.question_no > b.question_no)
        return 1;
      else if (a.question_no < b.question_no)
        return -1
      else
        return 0;
    });
    console.log(score_list);
    var score_array: any[] = [];
    if (score_list && score_list.length > 0) {
      var score_count = 0;
      var rendered_sections = [];
      var display_section_header: boolean = false;
      for (const score of score_list) {
        //console.log(component)
        if ((rendered_sections.length == 0) || (rendered_sections.indexOf(score.section_no) < 0)) {
          rendered_sections.push(score.section_no);
          display_section_header = true;
        }
        else {
          display_section_header = false;
        }
        score_array.push(this.formBuilder.group({
          co_id: [score.co_id, [Validators.required]],
          k_id: [score.k_id, [Validators.required]],
          section_no: [score.section_no, [Validators.required]],
          question_no: [score.question_no, [Validators.required]],
          mark: [score.mark, [Validators.required]],
          option: [score.option],
          display_section_header: [display_section_header]
        }));
        score_count++;
      }
    }
    const remaining_count = 1 - score_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        score_array.push(this.formBuilder.group({
          co_id: [""],
          k_id: [""],
          section_no: [""],
          question_no: [""],
          option: [""],
          mark: [""]
        }));
      }
    }
    console.log(score_array);
    return score_array;
  }

  get scoreCardItems() {
    return ((this.score_card_form as FormGroup).get('score_card_items') as FormArray).controls;
  }



  reset(): void {
    this.setting_id = "";
    this.setting = {} as IExamSetup;
    this.score_card_form.reset();
  }





  isInvalid(formControl: string): boolean {
    return this.score_card_form.touched && this.score_card_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.score_card_form.status !== 'VALID';
  }


  async openModal(e: any, student: any, j: number): Promise<void> {
    this.submission_id = "";
    this.submission_id = student.submission_detail?._id;
    if (!student.submission_detail?._id) {
      this.student_file_data = {
        "exam_id": student.exam_id,
        "roll_no": student.student_roll_no,
        index: j,
      }
    }
    else {
      var stage: string = "";
      if (!student.submission_detail?.stage) {
        stage = "SUBMITTED";
      }
      else {
        stage = student.submission_detail?.stage;
      }
      this.student_file_data = {
        "exam_id": student.submission_detail?.exam_id,
        "roll_no": student.submission_detail?.roll_no,
        "stage": stage,
        "reviewed_by_note": student.submission_detail?.reviewed_by_note,
        index: j,
      }
    }
    //const query_data = new HttpParams({ fromObject: data }).toString()
    this.file = (e.target as HTMLInputElement).files[0];
    console.log(this.file.size);
    if (this.file.size > 1073741824) {
      alert("File size should be less than 1 GB.");
      e.target.value = "";
      return;
    }
    console.log(this.file);
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.preview_url = event.target.result;
    }
    reader.readAsDataURL(this.file);
    this.modal_mode = "UPLOAD";
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  async uploadFile(): Promise<void> {
    try {
      const consent = confirm("Are you sure want to submit this file?");
      if (!consent) return;
      const formData = new FormData();
      console.log(this.file);
      formData.append('file', this.file);
      this.file_uploading = true;
      const response = await this.restService.uploadInternalAnswerFile(formData);
      if (response.success && response.data) {
        var file_url = response.data.url;
        this.files[this.student_file_data.index] = "";
        delete this.student_file_data.index;
        this.student_file_data.file_path = file_url;
        if (!this.submission_id || this.submission_id == undefined || this.submission_id == "" || this.submission_id == null) {
          await this.createSubmission();
        }
        else {
          await this.updateSubmissionData();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      console.log(error);
    } finally {
      this.file_uploading = false;
    }
  }

  async createSubmission() {
    this.modal_mode = "UPLOAD";
    const resp = await this.restService.submitInternalStudentAnswer(this.student_file_data);
    if (resp && resp.success) {
      this.getStudentData();
      alert("File Uploaded Successfully");
      this.closeModal();
    }
  }

  async reviewFile(submission: any, stage: string) {
    try {
      this.submission_id = submission._id;
      this.student_file_data = {
        "exam_id": submission.exam_id,
        "roll_no": submission.roll_no,
        "file_path": submission.file_path,
        "stage": stage,
        "reviewed_by_note": ""
      }
      if (stage == 'REJECTED') {
        this.modal_mode = "REJECT";
        JQueryHelper.openModal('#reason-popup', { keyboard: false, backdrop: 'static' });
      }
      else {
        this.modal_mode = "";
        const consent = confirm("Are you sure want to approve this submission?");
        if (!consent) return;
        await this.updateSubmissionData();
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
      console.log(error);
    }
    finally {

    }
  }

  async updateSubmissionData() {
    const resp = await this.restService.updateInternalStudentSubmission(this.submission_id, this.student_file_data);
    if (resp && resp.success) {
      this.getStudentData();
      alert("Submission Review submitted Successfully");
      if (this.modal_mode == "REJECT") {
        this.closeReasonModal();
      }
      if (this.modal_mode == "UPLOAD") {
        this.closeModal();
      }
    }
  }
  async rejectFile() {
    this.student_file_data.reviewed_by_note = this.reason;
    this.student_file_data.stage = "REJECTED";
    this.reject_loading = true;
    await this.updateSubmissionData();
    this.reject_loading = false;
  }

  async revertStage(submission: any) {
    try {
      this.submission_id = submission._id;
      this.student_file_data = {
        "exam_id": submission.exam_id,
        "roll_no": submission.roll_no,
        "file_path": submission.file_path,
        "stage": "SUBMITTED",
        "reviewed_by_note": ""
      }
      const consent = confirm("Are you sure want to cancel this status?");
      if (!consent) return;
      this.modal_mode = "";
      await this.updateSubmissionData();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
      console.log(error);
    }
    finally {

    }
  }


  closeReasonModal() {
    this.student_file_data = undefined;
    this.reason = "";
    JQueryHelper.closeModal('#reason-popup');
  }

  closeModal() {
    this.files[this.student_file_data.index] = "";
    this.preview_url = "";
    this.modal_mode = "";
    this.file = null; this.student_file_data = undefined;
    JQueryHelper.closeModal('#modal-popup');
  }

  viewPreview(submission) {
    this.modal_mode = "PREVIEW";
    this.preview_url = submission.file_path;
    this.student_file_data = {
      "exam_id": submission.exam_id,
      "roll_no": submission.roll_no
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  navigateToFoilCard(course) {
    var programme_type_id = course.programme_type_id;
    var enrolled_year = course.enrolled_year;
    var class_name = this.commonEnums.getClassName(enrolled_year);
    var part = course.part;
    var course_type = course.course_type;
    var programme_id = course.valuations.programme_id;
    var section = course.valuations.section;
    if (programme_type_id == 'UG') {
      if (part == 'PART_I' || part == 'PART_II') {
        class_name = part.split('_').join(' ');
      }
      if (part == 'PART_III' && course_type == 'CORE') {
        class_name += this.getProgrammeName(programme_id) + " '" + section + "'";
      }
      if (part == 'PART_III' && course_type == 'ALLIED') {
        class_name += " YEAR " + programme_type_id + " " + course_type.split('_').join(' ');
      }
      if (part == "PART_III" && course_type == 'ELECTIVE') {
        class_name += " YEAR " + programme_type_id + " " + course_type.split('_').join(' ');
      }
    }
    else {
      if (part == 'ELECTIVE') {
        class_name += " YEAR " + programme_type_id + " " + course_type.split('_').join(' ');
      }
      else {
        class_name += this.getProgrammeName(programme_id);
      }
    }
    HTMLHelper.openLinkInNewTab("/admin/terminal/foil-card?exam_id=" + course._id
      + "&staff_course_map_id=" + course.valuations.staff_course_map_id
      + "&course_code=" + course.course_code
      + "&course_name=" + course.course_name
      + "&month=" + course.month
      + "&year=" + course.year
      + "&semester=" + course.semester
      + "&valuation_no=" + course.valuations.valuation_no
      + "&staff_name=" + course.valuations.name
      + "&class_name=" + class_name
      + "&evaluated_in=" + course.valuations.evaluated_in
    );
  }

  navigateToCoReport(course) {
    var programme_type_id = course.programme_type_id;
    var enrolled_year = course.enrolled_year;
    var class_name = this.commonEnums.getClassName(enrolled_year);
    var part = course.part;
    var course_type = course.course_type;
    var programme_id = course.valuations.programme_id;
    var section = course.valuations.section;
    if (programme_type_id == 'UG') {
      if (part == 'PART_I' || part == 'PART_II') {
        class_name = part.split('_').join(' ');
      }
      if (part == 'PART_III' && course_type == 'CORE') {
        class_name += this.getProgrammeName(programme_id) + " '" + section + "'";
      }
      if (part == 'PART_III' && course_type == 'ALLIED') {
        class_name += " YEAR " + programme_type_id + " " + course_type.split('_').join(' ');
      }
      if (part == "PART_III" && course_type == 'ELECTIVE') {
        class_name += " YEAR " + programme_type_id + " " + course_type.split('_').join(' ');
      }
    }
    else {
      if (part == 'ELECTIVE') {
        class_name += " YEAR " + programme_type_id + " " + course_type.split('_').join(' ');
      }
      else {
        class_name += this.getProgrammeName(programme_id);
      }
    }


    HTMLHelper.openLinkInNewTab("/admin/terminal/co-report?exam_id=" + course._id
      + "&staff_course_map_id=" + course.valuations.staff_course_map_id
      + "&course_code=" + course.course_code
      + "&course_name=" + course.course_name
      + "&month=" + course.month
      + "&year=" + course.year
      + "&semester=" + course.semester
      + "&valuation_no=" + course.valuations.valuation_no
      + "&staff_name=" + course.valuations.name
      + "&class_name=" + class_name
      + "&evaluated_in=" + course.valuations.evaluated_in
    );
  }

  async bulkUploadMarkData(e) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_upload_mark_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.bulkCourseMarkUpload(formData);
      if (response.success && response.data) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_upload_mark_data.show_loader = false;
    }
  }
}

interface IExamSetup {
  _id: string;
  month: number,
  year: number,
  format_setting_name: string,
  course_code: string;
  exam_type: string; //???
  enrolled_year: number;
  semester: number;
  mode_of_exam: string; //online, offline
  appearance_type: string; //regular, arrear, repeat
  allow_students_answer_submission: boolean; //true, false

  start_time: Date;
  end_time: Date;

  valuations: IExamValuationSettings[];
  valuation_mark_difference: number;

  active: boolean;
  closed: boolean;

}

export interface IExamValuationSettings {
  _id?: string;
  id?: string;
  evaluated_in: string;
  valuation_no: number;
  roll_no: string;
  sub: string;
  name?: string;
  start_time: Date;
  end_time: Date;
  completed: boolean;
}

interface ICoSettings {
  co_id: number,
  description: string,
  max_mark: number,
  id?: string
}