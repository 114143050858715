import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { StockItems } from 'src/app/controllers/admins/hostel-management/manage-mess/stock-item';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
@Component({
  selector: 'app-stock-purchase',
  templateUrl: './stock-purchase.component.html',
  styleUrls: ['./stock-purchase.component.scss']
})
export class StockPurchaseComponent implements OnInit {
  //bulk render data
  bulk_render_data : Modal = {
    modal_button_text:"Purchase Bulk Upload",
    modal_header: "Stock Purchase List",
    list_name: "Stock Purchase",
    csv_data: {
      columns: [
        {column_name: "purchase_date", column_type: "required"},
        {column_name: "item_name", column_type: "required"},
        {column_name: "item_id", column_type: "required"},
        {column_name: "item_category", column_type: "optional"},
        {column_name: "measurement_type", column_type: "optional"},
        {column_name: "price", column_type: "required"},
        {column_name: "quantity", column_type: "required"},
        {column_name: "total_price", column_type: "required"},
    ]
    },
    table_data: {
      column_names: ["Purchase Date", "Item Name", "Item ID", "Quanity", "Total Price"],
      column_values: ["12/08/2021", "Rice", "1", "20", "10000"]
    }
  };

  
  fonts = fonts;
  menuItems: StockItems = {} as StockItems;
  measurementType = ['KG', 'LTR', "GRM", "MTR", "CTR"];
  itemCategory = ["Grains", "Vegetables", "Fruits", "Dairy", "Meat & Eggs", "Oils", "Beverages", "Desserts & Sweets", "Other Foods"];
  //get Item Master from Service
  itemMaster: Array<{ text: string; value: string }> = [
    { text: "Rice", value: "1" },
    { text: "Wheat", value: "2" },
    { text: "Coffee Powder", value: "3" },
    { text: "Tea Powder", value: "4" },
    { text: "Tomato", value: "5" },
    { text: "Potato", value: "6" },
    { text: "Carrot", value: "7" }
  ];
  stock_list_loading:boolean = false;
  inStock:{
    purchase_date:Date,
    item_id:string,
    transaction_id:number,
    item_name:string,
    item_category:string,
    measurement_type:string,
    price:number,
    quantity:number,
    total_price:number
  } [ ] = [
    {
      purchase_date:new Date("08/01/2021"), item_id:"1", item_name:"Rice", measurement_type:"KG", item_category: "Grains",
      price:350, quantity:3, total_price: 1050, transaction_id:1
    },
    {
      purchase_date:new Date("08/01/2021"), item_id:"2", item_name:"Wheat", measurement_type:"KG", item_category: "Grains",
      price:250, quantity:2, total_price: 500, transaction_id:2
    },
    {
      purchase_date:new Date("08/02/2021"), item_id:"3", item_name:"Coffee Powder", measurement_type:"GRM",
      price:70, quantity:20, total_price: 1400, item_category: "Beverages", transaction_id:3
    }
   ];
  purchaseForm: FormGroup;
  title = 'Stock Purchase';
  id = '';
  fromDataResolver = FromDataResolver;

  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.purchaseForm = this.formBuilder.group({
        purchase_date: [new Date().toISOString().substring(0,10), Validators.required],
        item_id:[''],
        transaction_id: [''],
        item_name: ['', [Validators.required]],
        item_category:['', [Validators.required]],
        measurement_type: [''],
        quantity: [0,Validators.required],
        price: [0, Validators.required],
        total_price:[0]
      });
    }
  
  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Stock Purchase', url: 'admin/hostel/stock-purchase' };
    this.route.params.subscribe(params => {
      this.id = params?.id;
      this.navigationService.updateNavigation(this.title, this.router.url);
    });
  }

  onAddClick()
  {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }
  calculateTotal()
  {
    this.purchaseForm.controls['total_price'].setValue(
      this.purchaseForm.controls['quantity'].value==0?this.purchaseForm.controls['price'].value:
      this.purchaseForm.controls['quantity'].value * this.purchaseForm.controls['price'].value
    )
  }
  async onEditClick(item: any): Promise<void> {
    this.purchaseForm.setValue( item);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  getTittle(): string {
    return this.title;
  }
  

  isInvalid(formControl: string): boolean {
    if ((formControl === 'quantity' || formControl === 'price') && isNaN(this.purchaseForm.get(formControl)?.value)) {
      return true;
    }
    return this.purchaseForm.touched && this.purchaseForm.get(formControl)?.errors !== null;
  }

  getMenuItemFromForm(): StockItems {
    const menuItem = this.getFormData();
    return {
      id: this.id,
      item_name: menuItem.item_name,
      item_type: menuItem.item_type,
      item_category:menuItem.item_category,
      quantity: menuItem.quantity,
      price: menuItem.price,
    };
  }

  canDisable(): boolean {
    const type = this.purchaseForm.value.measurement_type;
    if (!this.measurementType.includes(type)) {
      return true;
    }
    if (isNaN(this.purchaseForm.value?.quantity)) {
      return true;
    }
    if (isNaN(this.purchaseForm.value?.price)) {
      return true;
    }
    return this.purchaseForm.status !== 'VALID';
  }

  getFormData(): any {
    return this.purchaseForm.value;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
     this.addItem();
  }

  onSaveAndClose():void
  {
    this.onSave();
    this.onCloseClick();
  }
  reset(): void {
    //this.purchaseForm.reset();
    this.purchaseForm.controls['purchase_date'].setValue(new Date().toISOString().substring(0,10))
    this.purchaseForm.controls['quantity'].setValue(0);
    this.purchaseForm.controls['total_price'].setValue(0);
    this.purchaseForm.controls['price'].setValue(0);
    this.purchaseForm.controls['measurement_type'].setValue("");    
    this.purchaseForm.controls["item_category"].setValue("");
  }
  async addItem(): Promise<void> {
    try {
      if(this.purchaseForm.value.transaction_id)
      {
        var item= this.inStock.find(x=>x.transaction_id==this.purchaseForm.value.transaction_id);
        item!.quantity = parseInt(this.purchaseForm.value.quantity);
        item!.price = this.purchaseForm.value.price;
        item!.total_price = parseInt(this.purchaseForm.value.total_price);
        item!.purchase_date = this.purchaseForm.value.purchase_date;
        this.inStock.splice(this.inStock.findIndex(x=>x.item_id==this.purchaseForm.value.item_id),1);
        this.inStock.unshift(item!);
      }
      else
      {
        this.purchaseForm.value.item_id = this.itemMaster.find(x=>x.text==this.purchaseForm.value.item_name)?.value;
        this.purchaseForm.value.transaction_id = Math.random();
        this.inStock.unshift(this.purchaseForm.value)
      }
      /*console.log(this.purchaseForm.value.item_id);
      console.log(this.inStock.findIndex(x=>x.item_id==this.purchaseForm.value.item_id))
      if(this.inStock.findIndex(x=>x.item_id==this.purchaseForm.value.item_id)>=0)
      {
        var item= this.inStock.find(x=>x.item_id==this.purchaseForm.value.item_id);
        item!.quantity += parseInt(this.purchaseForm.value.quantity);
        item!.price = this.purchaseForm.value.price;
        item!.total_price += parseInt(this.purchaseForm.value.total_price);
        item!.purchase_date = this.purchaseForm.value.purchase_date;
        this.inStock.splice(this.inStock.findIndex(x=>x.item_id==this.purchaseForm.value.item_id),1);
        this.inStock.unshift(item!);
      }
      else
      { */
        
      //}
      this.purchaseForm.reset();
      this.reset();        
      /* 
      const res = await this.restService.menuItemService.creatMenuItems(this.getMenuItemFromForm());
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
      }
      this.notificationService.setNotifcation({ message: 'Created menu item successfully', type: 'Success'});
     */
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to add item to stock.', type: 'Failed'});
    }
  }

  onDelete()
  {
    this.inStock.splice(this.inStock.findIndex(x=>x.transaction_id==this.purchaseForm.value.transaction_id),1)
    this.reset();
    this.purchaseForm.reset();
    this.onCloseClick();
  }

  bulkUpload(ev:any)
  {
    console.log(ev);
  }
}
