<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Hall Ticket</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">HALL TICKET</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Download exam hall tickets</p>
                    </div>


                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 15%;">Exam Name</th>
                                        <th style="width: 25%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="!loader">
                                        <th>1</th>
                                        <th>Terminal Examination - April, 2024</th>
                                        <th>
                                            <ng-container *ngIf="hall_ticket_available">
                                                <a href="https://ajoybalajik11.github.io/A_24/{{enrolled_year}}/{{roll_no}}.pdf"
                                                    download="{{roll_no}}.pdf" target="_blank">Download</a>
                                            </ng-container>
                                            <ng-container *ngIf="!hall_ticket_available">
                                                <span>N/A</span>
                                            </ng-container>
                                        </th>
                                    </tr>
                                    <ng-container *ngIf="loader">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading please wait ...</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->

        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>
