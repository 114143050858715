import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-staff-not-marked-attendance',
  templateUrl: './staff-not-marked-attendance.component.html',
  styleUrls: ['./staff-not-marked-attendance.component.scss']
})
export class StaffNotMarkedAttendanceComponent implements OnInit {
  title = 'Staff Hour wise Attendance Report';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;
  table_data: LateEntryResultInterface[] = [];
  table_data_loading: boolean = false;
  assign_save_loading: boolean = false;
  report_types: string[] = ['not_marked', 'marked'];


  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) {

  }

  ngOnInit(): void {
    this.buildSearchForm();
  }

  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      staff_id: [''],
      course_code: [''],
      report_type: [''],
    });
  }

  isTableDataEmpty() {
    if (JSON.stringify(this.table_data) == '{}' || JSON.stringify(this.table_data) == '[]') {
      return true;
    }
    return false;
  }

  /* changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.table_data = [];
  } */
  async onSearchClick() {
    this.table_data = [];
    var search_query: any = {};
    if (!this.filter_form.value.start_date) {
      alert("Please choose start date"); return;
    }
    if (!this.filter_form.value.end_date) {
      alert("Please choose end date"); return;
    }
    if (!this.filter_form.value.report_type) {
      alert("Please choose Report Type"); return;
    }
    search_query.start_date = this.filter_form.value.start_date;
    search_query.end_date = this.filter_form.value.end_date;
    if (this.filter_form.value.course_code) {
      search_query.course_code = this.filter_form.value.course_code;
    }
    if (this.filter_form.value.staff_id) {
      search_query.staff_id = this.filter_form.value.staff_id;
    }
    console.log(search_query);
    this.table_data_loading = true;
    try {
      var service_response;
      if (this.filter_form.value.report_type == 'not_marked') {
        service_response = await this.restService.getNotMarkedStaffList(search_query);
      }
      if (this.filter_form.value.report_type == 'marked') {
        service_response = await this.restService.getMarkedStaffList(search_query);
      }
      if (service_response && service_response.success) {
        console.log(service_response);
        this.table_data = service_response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }
}


interface LateEntryResultInterface {
  _id: string,
  date: string,
  staff_id: string,
  staff_name: string,
  course_code: string,
  course_name: string,
  reason?: string,
  hour_number: number,
  re_open_request_status: string
  createdAt: string,
}