<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Review Non-Academic Attainment Evidence</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Review Non-Academic Attainment Evidence</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Review Files uploaded by Students & evaluate them</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <!--  -->
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Enrollment Year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Choose Enrollment Year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Roll No.</label>
                                        <input type="text" class="form-control" formControlName="roll_no"
                                            placeholder="Enter Student Roll No.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Stage</label>
                                        <select formControlName="stage" class="form-control">
                                            <option value="">Choose Stage</option>
                                            <option
                                                *ngFor="let stage of this.stages;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="stage">
                                                {{stage}}</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mr-2 search-button" title="Search"
                                            *ngIf="!review_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="review_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!--Individual List-->
                    <div class="card-body">
                        <div class="mg-b-20"></div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width:10%">Roll No.</th>
                                        <th style="width: 15%;">Name</th>
                                        <th style="width: 10%;">Evidence</th>
                                        <th style="width: 10%;">Skillset</th>
                                        <th style="width: 20%;">Skill</th>
                                        <th style="width: 10%;">Stage</th>
                                        <th style="width: 20%;">Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let evidence of review_evidence_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{evidence.roll_no}}
                                        </td>
                                        <td>
                                            {{evidence.given_name}} {{evidence.middle_name}}
                                        </td>
                                        <td>
                                            <a [href]="evidence.evidence_url" target="_blank">View</a>
                                        </td>
                                        <td class="uppercase">
                                            {{getSkillsetName(evidence.skillset_id)}}
                                        </td>
                                        <td>
                                            {{evidence.skill}}
                                        </td>
                                        <td class="uppercase">
                                            {{evidence.stage?.split('_')?.join(' ')}}
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-info mg-r-10 mg-t-5"
                                                (click)="editClick(evidence)">Edit</button>
                                            <button type="button" class="btn btn-sm btn-info mg-t-5"
                                                (click)="onMoveClick('single',evidence)">Move to</button>
                                        </td>
                                    </tr>

                                    <tr *ngIf="review_evidence_list.length == 0 && review_list_loading">
                                        <td colspan="8" class="text-center"><b>Loading...</b></td>
                                    </tr>
                                    <tr *ngIf="review_evidence_list.length == 0 && !review_list_loading">
                                        <td colspan="8" class="text-center"><b>No Records found.</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row mg-t-20" *ngIf="review_evidence_list.length > 0">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-md btn-info" (click)="onMoveClick('all')">Move All</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!-- Evidence Upload Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="evidence_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Upload Evidence for OBE Non-Academic Attainment</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="evidence_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="0">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('semester')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Semester is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload File
                                                Evidence</label>
                                            <input class="form-control" required="" type="file"
                                                (change)="uploadFile($event)">
                                            <div *ngIf="evidence_form.value.evidence_url">
                                                <a [href]="evidence_form.value.evidence_url" target="_blank">View
                                                    Uploaded File</a>
                                            </div>
                                            <div *ngIf="isInvalid('evidence_url')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Evidence File is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Skillset Category</label>
                                            <select formControlName="skillset_id" class="form-control"
                                                (change)="filterSkillsetList($event)">
                                                <option value="">Select Skillset Name</option>
                                                <option
                                                    *ngFor="let skillset of skillset_list; let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="skillset._id">{{skillset.skillset_name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Skill</label>
                                            <select formControlName="skill" class="form-control">
                                                <option value="">Select Skill Name</option>
                                                <option
                                                    *ngFor="let skill of skills_list; let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="skill.skill_name">{{skill.skill_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Place</label>
                                            <input type="text" placeholder="Enter Event Place" class="form-control"
                                                formControlName="place">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Date</label>
                                            <input type="date" placeholder="Enter Event Date" class="form-control"
                                                formControlName="date">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Evidence
                                                Description</label>
                                            <textarea class="form-control" formControlName="description"
                                                placeholder="Enter a description about this evidence" rows="5" cols="5">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Points</label>
                                            <input type="number" class="form-control" formControlName="point"
                                                placeholder="Enter Points for this evidence">
                                            <div *ngIf="isInvalid('point')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Point is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Remarks,
                                                if any</label>
                                            <textarea class="form-control" formControlName="remarks"
                                                placeholder="Enter Review Remarks, if any" rows="5" cols="5"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Stage
                                                to Move</label>
                                            <select formControlName="stage" class="form-control">
                                                <option value="">Choose Stage</option>
                                                <option
                                                    *ngFor="let stage of this.review_stages;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="stage">
                                                    {{stage}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.evidence_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.evidence_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.evidence_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.evidence_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <!-- <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.evidence_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.evidence_form.value._id && delete_loading" disabled>Deleting...</button> -->
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->



        <!--Description Modal-->
        <div class="modal" id="modal-move">
            <div class="modal-dialog modal-lg modal-sm" role="document" *ngIf="move_form">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Move Evidence</h6>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="move_form" class="form-horizontal">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Stage
                                            to Move</label>
                                        <select formControlName="stage" class="form-control">
                                            <option value="">Choose Stage</option>
                                            <option
                                                *ngFor="let stage of this.review_stages;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="stage">
                                                {{stage}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Remarks, if
                                            any</label>
                                        <textarea class="form-control" formControlName="remarks"
                                            placeholder="Ex: Student entry evaluated and evidence is produced correctly"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-center mg-t-20">
                            <button class="btn btn-primary mg-r-20" (click)="moveToClick()"
                                *ngIf="!move_loading">Move</button>
                            <button class="btn btn-primary mg-r-20" *ngIf="move_loading" disabled>Loading...</button>
                            <button class="btn btn-secondary mg-r-20" (click)="closeMove()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Description Modal-->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
