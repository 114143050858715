<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Transaction Report</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal>
          <button type="button" class="btn btn-info" (click)="addTransaction()">Add Transaction</button>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Transaction Report</h4>
              <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
            </div>
            <p class="tx-12 tx-gray-500 mb-2">View Transaction Report</p>
          </div>

          <div class="card-body" *ngIf="report_filter_form && transaction_header_id==undefined">
            <form [formGroup]="report_filter_form">
              <div class="row row-sm">

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                      Date</label>
                    <input type="date" class="form-control" formControlName="start_date" (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                      Date</label>
                    <input type="date" class="form-control" formControlName="end_date" (change)="onResetClick()">
                  </div>
                </div>



                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                      Type</label>
                    <select formControlName="programme_type_id" class="form-control"
                      (change)="onFilterSelectChange('programme_type_id',$event)">
                      <option value="">Select Programme Type</option>
                      <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="category.programme_type_id">{{category.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                      Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Programme</label>
                    <select formControlName="programme_id" class="form-control"
                      (change)="onFilterSelectChange('programme_id',$event)">
                      <option value="">All Programmes</option>
                      <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programme.programme_id">{{programme.programme_name}}
                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                      year</label>
                    <select formControlName="enrolled_year" class="form-control" (change)="onResetClick()">
                      <option value="">Select Enrolment year</option>
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>


                <div class="col-lg-2" *ngIf="report_filter_form.value.programme_id!='' && sections.length>1">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Section</label>
                    <select formControlName="section" class="form-control" (change)="onResetClick()">
                      <option value="">Choose Section</option>
                      <option *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="section.section_name">{{section.section_name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Purpose</label>
                    <select formControlName="purpose" class="form-control" (change)="onPurposeChange()">
                      <option value="">Select Purpose</option>
                      <option *ngFor="let purpose of purposes;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="purpose.id">{{purpose.name}}</option>
                    </select>
                  </div>
                </div>

                <!-- <div class="col-lg-2" *ngIf="report_filter_form.value.purpose!=''">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Month</label>
                                        <select formControlName="month" class="form-control">
                                            <option value="">Select Month</option>
                                            <option *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="month.id">{{month.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="report_filter_form.value.purpose!=''">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Year</label>
                                        <select formControlName="year" class="form-control">
                                            <option value="">Select Year</option>
                                            <option *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div> -->


                <div class="col-lg-2"
                  *ngIf="report_filter_form.value.purpose!='' && report_filter_form.value.purpose!='HOSTEL_ADMISSION_FEE' && report_filter_form.value.purpose!='TRANSPORTATION_ADMISSION_FEE'">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Fee
                      Term</label>
                    <select formControlName="term" class="form-control" (change)="onResetClick()">
                      <option value="">Select Fee Term</option>
                      <option *ngFor="let term of fees_headers;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="term.term_name">{{term.term_name}}
                        {{(report_filter_form.value.programme_type_id=='')?
                        (term.programme_type_id?("("+term.programme_type_id+")"):'')
                        :''}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2"
                  *ngIf="report_filter_form.value.purpose!='' && (report_filter_form.value.purpose=='HOSTEL_ADMISSION_FEE' || report_filter_form.value.purpose=='TRANSPORTATION_ADMISSION_FEE')">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Fee
                      Term</label>
                    <select formControlName="term" class="form-control" (change)="onResetClick()">
                      <option value="">Select Fee Term</option>
                      <option *ngFor="let term of fees_headers;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="term.admission_name">
                        {{term.admission_name}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll
                      No.</label>
                    <input type="text" class="form-control" formControlName="roll_no"
                      placeholder="Search with student roll no." (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Name</label>
                    <input type="text" class="form-control" formControlName="name"
                      placeholder="Search with student name" (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter College
                      Transaction ID.</label>
                    <input type="text" class="form-control" formControlName="clg_trans_id"
                      placeholder="Search with college Transaction ID" (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Gateway
                      Transaction ID</label>
                    <input type="text" class="form-control" formControlName="gw_trans_id"
                      placeholder="Search with gateway transaction ID" (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Bank
                      Transaction ID</label>
                    <input type="text" class="form-control" formControlName="bank_trans_id"
                      placeholder="Search with bank transaction ID" (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select payment
                      mode</label>
                    <select formControlName="payment_mode" class="form-control" (change)="onResetClick()">
                      <option value="">Select payment mode</option>
                      <option *ngFor="let mode of payment_modes;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="mode.id">{{mode.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select payment
                      status</label>
                    <select formControlName="payment_collected" class="form-control" (change)="onResetClick()">
                      <option value="d">Select payment status</option>
                      <option *ngFor="let status of payment_status;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="status.id">{{status.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Amount Split
                      status</label>
                    <select formControlName="split_status" class="form-control" (change)="onResetClick()">
                      <option value="d">Select Amount Split Status</option>
                      <option *ngFor="let status of split_status;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="status.id">{{status.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Split Start
                      Date</label>
                    <input type="date" class="form-control" formControlName="split_start_date"
                      (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Split End
                      Date</label>
                    <input type="date" class="form-control" formControlName="split_end_date" (change)="onResetClick()">
                  </div>
                </div>


                <div class="col-lg-2">
                  <div class="form-group">
                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!report_list_loading" (click)="onSearchClick()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="report_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </div>
                </div>

              </div>
            </form>
            <div class="row">
              <div class="col-lg-4">
                <input type="checkbox" [(ngModel)]="show_split" class="mg-r-5" (change)="showSplit($event)">Show Account
                Wise Collection Breakup
              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="d-flex right-content mg-b-5" style="float: right;">
              <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info" data-toggle="dropdown"
                  id="dropDownMenuButton" type="button">Download<i class="fas fa-caret-down ml-1"></i></button>
                <div class="dropdown-menu tx-13">
                  <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                  <!-- <a class="dropdown-item" (click)="downloadDBF()">Download DBF</a> -->
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Roll No.</th>
                    <th>Name</th>
                    <th>Class</th>
                    <th>Date</th>
                    <th>Purpose</th>
                    <th>Term</th>
                    <th>Amount</th>
                    <th>Mode</th>
                    <th>Payment Status</th>
                    <th style="width: 10%;">Clg. TID</th>
                    <th style="width: 10%;">Gateway TID</th>
                    <th style="width: 10%;">Bank TID</th>
                    <th>Split Status</th>
                    <th>Split Date</th>
                    <ng-container *ngIf="show_split">
                      <th *ngFor="let split_header of split_headers; let i=index;trackBy:fromDataResolver.identify;">
                        {{split_header}}
                      </th>
                    </ng-container>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of transaction_report; let i=index;trackBy:fromDataResolver.identify;">
                    <td>{{this.start_index + i+1}}</td>
                    <td>{{report?.roll_no}}</td>
                    <td class="uppercase">{{report?.name}}</td>
                    <td class="uppercase">
                      {{commonEnums.getClassName(report?.year,report?.programme_name,report?.section)}}
                    </td>
                    <td>{{report?.payment_date | date:commonEnums.date_format}}</td>
                    <td class="uppercase">{{getPurpose(report?.purpose)}}</td>
                    <td class="uppercase">{{(report?.term!='')?report?.term:"---"}}</td>
                    <td>Rs. {{report?.amount}}</td>
                    <td>{{getPaymentMode(report?.payment_mode)}}</td>
                    <td>{{getPaymentStatus(report?.payment_collected)}} <br>
                      <span *ngIf="(report.payment_mode=='online') && (report.payment_collected==false)"
                        class="online-adjustment" (click)="openAdjustmentModal(report.clg_trans_id)">
                        Online Adjustment
                      </span>
                    </td>
                    <td>{{report.hasOwnProperty('clg_trans_id')?((report?.clg_trans_id!='' &&
                      report?.clg_trans_id!='null')?report?.clg_trans_id:"---"):'---'}}
                    <td>{{report.hasOwnProperty('gw_trans_id')?((report?.gw_trans_id!='' &&
                      report?.gw_trans_id!='null' )?report?.gw_trans_id:"---"):'---'}}
                    </td>
                    <td>{{report.hasOwnProperty('bank_trans_id')?((report?.bank_trans_id!='' &&
                      report?.bank_trans_id!='null')?report?.bank_trans_id:"---"):'---'}}
                    </td>
                    <td>{{(report?.split_status == true)?'Yes':'No'}}</td>
                    <td>{{report?.split_date | date:commonEnums.date_format}}</td>
                    <ng-container *ngIf="show_split">
                      <th *ngFor="let split_header of split_headers; let i=index;trackBy:fromDataResolver.identify;">
                        <span *ngIf="report!.split_data_list[i].amount != 0">{{report!.split_data_list[i].amount}}
                        </span>
                        <span *ngIf="report!.split_data_list[i].amount == 0">0</span>
                      </th>
                    </ng-container>
                    <td>
                      <button type="button" (click)="viewTransactionHistory(report.id)"
                        class="btn btn-sm btn-info mr-2">
                        <fa-icon [icon]="fonts.faInfo"></fa-icon>
                      </button>

                    </td>

                  </tr>
                  <ng-container *ngIf="!report_list_loading && transaction_report?.length==0">
                    <tr>
                      <th colspan="16" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="report_list_loading && transaction_report?.length==0">
                    <tr>
                      <th colspan="16" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <ng-container *ngIf="total_records>500">
                <ul class="pagination pull-right">
                  <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                        class="icon ion-ios-arrow-back"></i></a></li>

                  <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                        class="icon ion-ios-arrow-forward"></i></a></li>
                </ul>
              </ng-container>
            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- Transaction Large Modal -->
    <div class="modal" id="transaction-modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">View Transaction History</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <pre>{{transaction_data}}</pre>
              <div class="text-center mg-t-10">
                <button class="btn btn-secondary mg-r-20" (click)="closeTransactionModal()">Close</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Transaction Large Modal -->
    <div class="modal" id="adjustment-modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">Make Online Adjustment</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="online_adjustment_form" class="form-horizontal">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Transaction
                    ID</label>
                  <input class="form-control" placeholder="Enter Transaction ID" required="" type="text"
                    formControlName="transaction_id">
                  <div *ngIf="isInvalid('transaction_id')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Transaction ID is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select ID Type</label>
                  <select formControlName="id_type" class="form-control">
                    <option value="">Select ID Type</option>
                    <option *ngFor="let type of id_types; let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="type.id">{{type.name}}</option>
                  </select>
                  <div *ngIf="isInvalid('id_type')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">ID Type is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload Proof</label>
                                    <input type="file" formControlName="proof" class="form-control">
                                  <div *ngIf="isInvalid('proof')">
                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                      <li class="parsley-required">Proof is required.</li>
                                    </ul>
                                  </div>
                                </div> -->

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                    Description</label>
                  <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                    formControlName="description">
                                    </textarea>
                  <div *ngIf="isInvalid('description')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Description is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="form-group">
                  <input type="checkbox" formControlName="signed_by" class="mg-r-5">I agree to make
                  this transaction
                  <div *ngIf="isInvalid('signed_by')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Click to continue transaction.</li>
                    </ul>
                  </div>
                </div>

              </form>
              <div class="text-center">
                <button class="btn btn-success mg-r-20" [disabled]="canDisable()" (click)="saveAdjustmentData()">Make
                  Adjustment</button>
                <button class="btn btn-secondary mg-r-20" (click)="closeAdjustmentModal()">Close</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Add Transaction Large Modal -->
    <div class="modal" id="add-modal-popup">
      <div class="modal-dialog big-modal" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">Add New Transaction (Offline Payment)</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">

              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <input class="form-control" placeholder="Enter Student Roll No." required="" type="text"
                      [(ngModel)]="search_rno">
                  </div>
                </div>
                <div class="col-md-10">

                  <ng-container *ngIf="!payment_details_loading">
                    <button class="btn btn-info mg-r-20" (click)="getStudentPaymentData()">Get Payment
                      History</button>
                  </ng-container>
                  <ng-container *ngIf="payment_details_loading">
                    <button class="btn btn-info mg-r-20" disabled> <span class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span>
                      Loading...</button>

                  </ng-container>





                </div>
              </div>

              <div class="card box-shadow-0" *ngIf="payment_details?.length>0">
                <div class="card-header">
                  <h4 class="card-title mb-1">Payment History</h4>
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-md-6">
                      Student Name: <b>{{offline_student_data?.given_name}}</b>
                    </div>
                    <div class="col-md-6">
                      Class: <b>{{offline_student_data?.class_name}}</b>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <!-- <th>Programe Type</th> -->
                          <th>Programe Name</th>
                          <th>Fees Type</th>
                          <th>Term Name</th>
                          <!-- <th>Semester</th> -->
                          <!-- <th>Installment</th> -->
                          <th>Due Date</th>
                          <!-- <th>Started Date</th> -->
                          <th>Amount</th>
                          <!-- <th>Fine</th> -->

                          <th>Status</th>
                          <th style="width: 15%;">Action</th>
                          <!-- <th>Transaction Reference(s)</th> -->

                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="payment_details && payment_details.length>0">
                          <ng-container
                            *ngFor="let payment_detail of payment_details;let i=index;trackBy:fromDataResolver.identify;">
                            <tr>
                              <th scope="row">
                                {{i+1}}
                              </th>
                              <td class="uppercase">{{payment_detail.programme_name}}</td>
                              <td class="uppercase">
                                {{payment_detail.fee_header_type.replace('_',' ')}}</td>
                              <td class="uppercase">{{payment_detail.term_name}}</td>
                              <td class="uppercase">
                                <ng-container *ngIf="payment_detail.due_date">
                                  {{payment_detail.due_date |
                                  date:commonEnums.date_format}}
                                </ng-container>
                              </td>
                              <td>
                                {{payment_detail.amount | currency:"INR"}}
                                <ng-container *ngIf="payment_detail.breakups && payment_detail.breakups.length>0">
                                  <div class="col-sm-12">
                                    <button (click)="viewBreakupsClick(payment_detail)" title="View Breakups Details"
                                      class="btn btn-sm btn-info btn-block">
                                      View Details</button>
                                  </div>


                                </ng-container>


                              </td>
                              <td>
                                <div class="col-sm-12 uppercase" *ngIf="payment_detail.payment_completed">
                                  Paid
                                </div>
                                <div class="col-sm-12 uppercase" *ngIf="!payment_detail.payment_completed">
                                  Not Paid
                                </div>
                              </td>
                              <td>
                                <!-- <div class="col-sm-12" *ngIf="!payment_detail.payment_completed">
                                                        <button (click)="payCourse2Fee(payment_detail)" class="btn btn-success btn-block">
                                                            Pay Online</button>
                                                        </div> -->
                                <div class="col-sm-12" *ngIf="!payment_detail.payment_completed">
                                  <button type="button" class="btn btn-sm btn-info mr-2"
                                    (click)="openCloseOfflinePayment(payment_detail)">
                                    <ng-container *ngIf="!payment_detail.show_payment">
                                      Make Payment
                                    </ng-container>
                                    <ng-container *ngIf="payment_detail.show_payment">
                                      Close Payment
                                    </ng-container>
                                  </button>

                                </div>
                              </td>
                              <!-- <td>
                                                        {{payment_detail.transaction_ids.join(", ")}}
                                                    </td> -->
                            </tr>

                            <tr *ngIf="payment_detail.show_payment">
                              <td colspan="8">
                                <form [formGroup]="add_transaction_form" class="form-horizontal">
                                  <div id="form_row">
                                    <div class="col-md-12 centered-form">
                                      <div class="form-group centered-form-control">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                          Reference No.</label>
                                        <input class="form-control" placeholder="Enter Reference No." required=""
                                          type="text" formControlName="reference_no">
                                        <div *ngIf="isInvalidTransaction('reference_no')">
                                          <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">
                                              Reference No. is required.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="form-group centered-form-control">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                          ID Type</label>
                                        <select formControlName="id_type" class="form-control">
                                          <option value="">Select ID Type
                                          </option>
                                          <option
                                            *ngFor="let type of id_types; let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="type.id">
                                            {{type.name}}</option>
                                        </select>
                                        <div *ngIf="isInvalidTransaction('id_type')">
                                          <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">ID
                                              Type is required.</li>
                                          </ul>
                                        </div>
                                      </div>

                                      <!-- <div class="form-group">
                                                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload Proof</label>
                                                                        <input type="file" formControlName="proof" class="form-control">
                                                                    <div *ngIf="isInvalidTransaction('proof')">
                                                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                                        <li class="parsley-required">Proof is required.</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div> -->

                                      <div class="form-group centered-form-control">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                          Description</label>
                                        <textarea id="description" class="form-control"
                                          [placeholder]="'Enter Description'" formControlName="description">
                                                                        </textarea>
                                        <div *ngIf="isInvalidTransaction('description')">
                                          <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">
                                              Description is required.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="form-group centered-form-control">
                                        <input type="checkbox" formControlName="signed_by" class="mg-r-5">I agree to
                                        make this
                                        transaction
                                        <div *ngIf="isInvalidTransaction('signed_by')">
                                          <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">
                                              Click to continue
                                              transaction.</li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="text-center mg-b-10">
                                        <button class="btn btn-success mg-r-20" [disabled]="canDisableOfflinePayment()"
                                          (click)="makeOfflinePayment(payment_detail)">Make
                                          Payment</button>

                                        <button class="btn btn-secondary mg-r-20"
                                          (click)="openCloseOfflinePayment(payment_detail)">Close
                                          Payment</button>
                                      </div>
                                    </div>

                                  </div>
                                </form>
                              </td>


                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!payment_details_loading && payment_details.length==0">
                          <tr>
                            <th colspan="9" class="text-center">No Records found</th>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="payment_details_loading && payment_details.length==0">
                          <tr>
                            <th colspan="9" class="text-center">Loading Please wait</th>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>




              <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select ID Type</label>
                                    <select formControlName="id_type" class="form-control">
                                        <option value="">Select ID Type</option>
                                        <option *ngFor="let type of id_types; let i=index;trackBy:fromDataResolver.identify;" [ngValue]="type">{{type}}</option>
                                    </select>
                                  <div *ngIf="isInvalid('id_type')">
                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                      <li class="parsley-required">Year is required.</li>
                                    </ul>
                                  </div>
                                </div> -->


              <div class="text-center">
                <button class="btn btn-secondary mg-r-20" (click)="closeAddModal()">Close</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <div class="modal" id="exam-fee-modal-popup">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="current_selection && current_selection.breakups">
          <div class="modal-header">
            <h6 class="modal-title">Course Details</h6>
          </div>
          <div class="modal-body">

            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Course Code</th>
                    <th>Course Name</th>
                    <th>Course Type</th>
                    <th>Appearance Type <br> (Regular - R, Arrear - A)</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="current_selection.breakups.length>0">


                    <ng-container
                      *ngFor="let breakup of current_selection.breakups;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td class="uppercase">{{breakup.course_code}}</td>
                        <td class="uppercase">{{breakup.course_name}}</td>
                        <td class="uppercase">{{breakup.course_type}}
                          <ng-container *ngIf="breakup.exam_duration>0">
                            [{{breakup.exam_duration}} hours]
                          </ng-container>

                        </td>
                        <td class="uppercase">{{breakup.appearance_type=="normal"?"R":"A"}}</td>
                        <td class="uppercase">
                          {{breakup.fee_amount | currency:"INR"}}
                        </td>


                      </tr>
                    </ng-container>
                    <ng-container
                      *ngIf="current_selection.breakups_extra_charges && current_selection.breakups_extra_charges.length >0">
                      <ng-container
                        *ngFor="let breakups_extra_charge of current_selection.breakups_extra_charges;let i=index;trackBy:fromDataResolver.identify;">
                        <tr>
                          <td scope="row" colspan="5" class="uppercase text-right">
                            {{breakups_extra_charge.item_name}}
                          </td>
                          <td class="uppercase">
                            {{breakups_extra_charge.amount | currency:"INR"}}
                          </td>

                        </tr>
                      </ng-container>
                    </ng-container>
                    <tr>
                      <td scope="row" colspan="5" class="uppercase text-right" style="font-weight: bold;">
                        Fine
                      </td>
                      <td class="uppercase" style="font-weight: bold;">
                        {{current_selection.fine ? current_selection.fine :'0' | currency:"INR"}}
                      </td>

                    </tr>
                    <tr>
                      <td scope="row" colspan="5" class="uppercase text-right" style="font-weight: bold;">
                        Total
                      </td>
                      <td class="uppercase" style="font-weight: bold;">
                        {{current_selection.amount | currency:"INR"}}
                      </td>

                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </div>
            <div class="mg-b-20"></div>
            <div class="text-align-right">

              <button class="btn ripple btn-secondary" (click)="onCloseBreakupsClick()" type="button">Close</button>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>