import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-report-template-filter',
  templateUrl: './report-template-filter.component.html',
  styleUrls: ['./report-template-filter.component.scss']
})
export class ReportTemplateFilterComponent implements OnInit {
  template_name: string = "";
  template_id: string = '';

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;

  // master data
  component_search_form: any;

  programme_dropdown_settings: IDropdownSettings = {};
  filter_programme_list: any[] = [];
  filter_programme_list_loading: boolean = false;
  programme_filter_list: { programme_id: string, programme_display_name: string }[] = []

  section_dropdown_settings: IDropdownSettings = {};
  filter_section_list: any[] = [];
  filter_section_list_loading: boolean = false;
  section_filter_list: { section_id: string, section_name: string }[] = [];
  template_data: any = undefined;
  format_types: any[] = [
    {
      id: "Section wise",
      value: FormatType.SECTION_WISE
    },
    {
      id: "Programme wise",
      value: FormatType.PROGRAMME_WISE
    },
  ];
  template_filter_data: any = {} as TemplateFilterResponse;
  template_filter_loading: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    this.programme_dropdown_settings = {
      singleSelection: false,
      idField: 'programme_id',
      textField: 'programme_display_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };

    this.section_dropdown_settings = {
      singleSelection: false,
      idField: 'section_id',
      textField: 'section_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    this.getFilterFormData();
    this.buildComponentSearchForm();
  }

  getFilterFormData() {
    let temp_programme = [];
    let temp_section = [];
    this.route.params.subscribe(async params => {
      this.template_id = params['templateId'];
      try {
        const template_response = await this.restService.getTemplate();
        if (template_response.data && template_response.data) {
          this.template_data = template_response.data.find(template => template._id == this.template_id);
          this.template_name = this.template_data?.template_name
          if(this.template_data != undefined || this.template_id) {
            this.programme_filter_list = [];
            this.filter_programme_list = this.template_data?.program_details;
            this.filter_programme_list.forEach(programme => {
              temp_programme.push({
                programme_id: programme.programme_id,
                programme_display_name: (programme.programme_name + " " + (programme.finance_type == "regular" ? "(R)" : "(SF)"))
              })
            });
            setTimeout(() => {
              this.programme_filter_list = temp_programme;
            }, 1000)
            if(this.filter_programme_list.length > 0) {
              const section_template_response = await this.restService.getTemplateSetting(this.template_id);
              if (section_template_response.data && section_template_response.data[0]) {
                this.filter_section_list = section_template_response.data[0]?.template_data;
                this.filter_section_list.forEach(section => {
                  temp_section.push({
                    section_id: section._id,
                    section_name: section.title
                  })
                });
                setTimeout(() => {
                  this.section_filter_list = temp_section;
                }, 1000)
              }
            } else {
              alert("Template id is not mapped with programme"); return;
            }

          } else {
            alert("Template id is mismatching"); return;
          }

        }

      } catch (error) {
        console.error('Error loading Template settings:', error);
      }
    });
  }

  buildComponentSearchForm(): void {
    this.component_search_form = this.fb.group({
      template_section_ids: [[]],
      programme_ids: [[]],
      format_type: ['']
    });
  }

  async onSearchClick(): Promise<void> {
    try {
      this.template_filter_loading = true;
      this.template_filter_data = {} as TemplateFilterResponse;

      if (!this.component_search_form.value.template_section_ids && this.component_search_form.value.template_section_ids.length > 0) {
        alert("Please select section name"); return;
      }
      if (!this.component_search_form.value.programme_ids && this.component_search_form.value.programme_ids.length > 0) {
        alert("Please select programme name"); return;
      }
      if (!this.component_search_form.value.format_type) {
        alert("Please select format"); return;
      }
      var search_query: any = {
        "template_id": this.template_id,
        "template_section_ids": this.component_search_form.value.template_section_ids.map(section => section.section_id),
        "format_type": this.component_search_form.value.format_type,
        "programme_ids": this.component_search_form.value.programme_ids.map(pgm => pgm.programme_id)
      };

      const service_response = await this.restService.getTemplateFilter(search_query); //v2
      if (service_response && service_response.success) {
        this.template_filter_data = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.template_filter_loading = false;
      this.template_filter_data = {} as TemplateFilterResponse;
    } finally {
      this.template_filter_loading = false;
    }
  }

  // downloadPDF(format_type) {
  //   /* this.template_data.template_name = 'NAAC Report'
  //   this.template_data.academic_year = '2023-24' */
  //   var window_height = window.screen.height;
  //   var window_width = window.screen.width;
  //   let mapping_data : TemplateFilterResponse = this.template_filter_data;
  //   if(format_type == FormatType.PROGRAMME_WISE) {

  //   }

  //   let report_html = '';
  //   for (var i = 0; i < mapping_data.length; i++) {
  //     let section = mapping_data[i];
  //     //render section header
  //     report_html += `<div class='row' style="margin-top: 10px;">
  //       <div class='col-md-12'>
  //         <p><b>${section.title}</b></p>
  //         <p>${section.description}</p>
  //       </div>
  //     </div>
  //     `;
  //     if (section.format == 'List') {
  //       const list_data = section.list_data;
  //       const header_div = document.createElement('div');
  //       header_div.innerHTML = list_data.header;
  //       const contents_div = document.createElement('div');
  //       contents_div.innerHTML = list_data.contents
  //       report_html +=
  //         `<div class='row' style='margin-top: 5px;'>
  //           <div class='col-md-12'>
  //             ${header_div.innerHTML}
  //           </div>
  //         </div>
  //         <div class='row' style='margin-top: 5px;'>
  //           <div class='col-md-12'>
  //             ${contents_div.innerHTML}
  //           </div>
  //         </div>`;
  //     }
  //     if (section.format == 'Table') {
  //       const table_data = section.table_data;
  //       let table_str = '';
  //       table_str += `<table class='table table-bordered'>`;
  //       table_str += `<tr>`;
  //       for (var h = 0; h < table_data[0].length; h++) {
  //         table_str += `<th>${table_data[0][h].column_name}</th>`;
  //       }
  //       table_str += `</tr>`;
  //       for (var row = 0; row < table_data.length; row++) {
  //         table_str += `<tr>`;
  //         for (var col = 0; col < table_data[row]?.length; col++) {
  //           if (table_data[row][col]?.column_data) {
  //             table_str += `<td>${table_data[row][col]?.column_data}</td>`
  //           }
  //           else {
  //             table_str += `<td>--</td>`
  //           }

  //         }
  //         table_str += `</tr>`;
  //       }
  //       table_str += `</table>`
  //       report_html += table_str;
  //     }
  //   }

  //   console.log(report_html);
  //   var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
  //   mywindow.document.write('<html><head>');
  //   mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
  //     "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
  //     "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
  //     "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
  //   mywindow.document.write('</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">');
  //   mywindow.document.write(
  //     '<div class="row" style = "margin-left:50px; margin-top: 10px;" id = "print-header" >' +
  //     ' <div class="col-md-2" > ' +
  //     '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
  //     '</div>' +
  //     '<div class= "col-md-8 text-center" > ' +
  //     '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
  //     '(Autonomous) </h4>' +
  //     '<h4> Sivakasi - 626 124. </h4>' +
  //     '<h5 style = "margin-top:10px">' + this.template_data.template_name + ' - ' + this.template_data.academic_year +
  //     ' </h5>' +
  //     '</div>' +
  //     '</div>');
  //   mywindow.document.write(report_html);
  //   mywindow.document.write('</body></html>');
  //   mywindow.document.close(); // necessary for IE >= 10
  //   mywindow.focus(); // necessary for IE >= 10
  //   setTimeout(function () {
  //     mywindow.print();
  //     mywindow.close();
  //   }, 1000);
  //   return true;
  // }

  isObjectNotEmpty(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

}

enum FormatType {
  SECTION_WISE = "SECTION_WISE",
  PROGRAMME_WISE = "PROGRAMME_WISE"
}

interface TemplateFilterResponse {
  template_id: string;
  template_name: string;
  format_type: FormatType;
  section_wise_data: ISectionFilterResponse[];
  programme_wise_data: IProgrammeFilterResponse[];
}

interface ISectionFilterResponse {
  section_id: string;
  title: string;
  description: string;
  file_link: string;
  format: string;
  programme_mapping_data: IProgrammeMappingData[];
}

interface IProgrammeMappingData {
  programme_id: string;
  programme_type_id: string;
  finance_type: string;
  programme_name: string;
  table_data?: [[ITableMappingData]],
  list_data?: IListMappingData
}

interface ITableMappingData {
  table_data_id: string,
  column_name: string,
  column_format: string,
  column_data: string | number
}

interface IListMappingData {
  header: string,
  contents: string
}

interface IProgrammeFilterResponse {
  programme_id: string;
  programme_type_id: string;
  finance_type: string;
  programme_name: string;
  section_mapping_data: ISectionMappingData[];
}

interface ISectionMappingData {
  section_id: string;
  title: string;
  description: string;
  file_link: string;
  format: string;
  table_data?: [[ITableMappingData]];
  list_data?: IListMappingData;
}
