import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-fees-schedule-list',
  templateUrl: './fees-schedule-list.component.html',
  styleUrls: ['./fees-schedule-list.component.scss']
})
export class FeesScheduleListComponent implements OnInit {


  // common methods 
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // core data
  schedule_list: any[] = [];
  schedule_list_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getFeeScheduleList();
  }


  async getFeeScheduleList() {
    try {
      this.schedule_list_loading = true;
      this.schedule_list = [];
      const service_response = await this.restService.getFeeScheduleList();
      if (service_response && service_response.success) {
        this.schedule_list = service_response.data;
      }
    } catch (error) {
      alert("Error while read data")
    } finally {
      this.schedule_list_loading = false;
    }
  }


  getCondString(cond: any) {
    let str: string[] = [];
    if (cond && cond.programme_types && cond.programme_types.length > 0) {
      for (const programme_type of cond.programme_types) {


        let fin: string[] = [];
        for (const finance_type of cond.finance_types) {
          fin.push(finance_type == "self_finance" ? "SF" : "ADIED");
        }

        str.push(programme_type + ": (" + fin.join(", ") + ")");
      }

    }
    return str.join(", ");
  }

  onViewFeeClick(data: any) {
    this.router.navigate(["/admin/students/payments/programme-wise"], { queryParams: { fee_schedule_id: data._id } })
  }

}
