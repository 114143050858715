import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-student-courses',
  templateUrl: './student-courses.component.html',
  styleUrls: ['./student-courses.component.scss'],
})
export class StudentCoursesComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  programme_course_list: any[] = [];
  programme_course_list_loading = false;

  search_form: any;


  course_request_form: any;
  course_request_form_loading = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router
  ) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.buildFilterForm();
  }

  buildFilterForm(): void {
    this.search_form = this.fb.group({
      semester: [''],
    });
  }

  async getSavedProgrammeCourseList(): Promise<void> {
    try {
      if (!this.search_form.value.semester) {
        alert('Please select semester');
        return;
      }
      this.programme_course_list = [];
      this.programme_course_list_loading = true;

      const save_respose = await this.restService.getCourseListForStudentBySub({
        semester: this.search_form.value.semester,
      });
      if (save_respose.success) {
        this.programme_course_list = save_respose.data;
      }
      console.log(this.programme_course_list);
    } catch (error) {
      alert(
        CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read)
      );
    } finally {
      this.programme_course_list_loading = false;
    }
  }

  async getAvailableProgrammes(programme: any, course: any): Promise<void> {
    this.buildCourseRequestForm(course);
    setTimeout(() => {
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    }, 500)

  }


  async onCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-popup');
    this.course_request_form = null;
  }

  /* async onSaveClick(): Promise<void> {
    try {

      console.log(this.course_request_form.value);
      const other_requested_courses = this.course_request_form.value.other_courses_list.filter((c: any) => c.requested == true)

      const requested_course_codes = [];
      for (let index = 0; index < other_requested_courses.length; index++) {
        const other_requested_course = other_requested_courses[index];
        requested_course_codes.push({
          course_code: other_requested_course.course_code,
          priority: (index + 1)
        })
      }

      if (this.course_request_form.value.same_courses_list && this.course_request_form.value.same_courses_list.length > 0) {
        const same_requested_courses = this.course_request_form.value.same_courses_list.filter((c: any) => c.requested == true)
        for (let index = 0; index < same_requested_courses.length; index++) {
          const same_requested_course = same_requested_courses[index];
          requested_course_codes.push({
            course_code: same_requested_course.course_code,
            priority: 3
          })
        }
      }
      const data_to_save = {
        _id: this.course_request_form.value._id,
        requested_course_codes
      }
      this.course_request_form_loading = true;
      const response = await this.restService.requestElectiveCourses(data_to_save);
      if (response.success) {
        alert('Saved Successfully');
        this.onCloseClick();
        await this.getSavedProgrammeCourseList();
      }

    } catch (error) {
      alert(
        CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read)
      );
    } finally {
      this.course_request_form_loading = false;
    }
  } */


  /* buildCourseRequestForm(course: any): void {
    const available_course = course.available_course_list;
    this.course_request_form = this.fb.group({
      _id: [course._id],
      other_courses_list: this.fb.array(this.buildCourseListForm(available_course.other_courses_list)),
      same_courses_list: this.fb.array(this.buildCourseListForm(available_course.same_courses_list)),
    })
    console.log(this.course_request_form.value);
  }

  buildCourseListForm(courses_list: any[]): any[] {

    if (!courses_list) {
      courses_list = [];
    }

    const value_to_return = [];

    for (const course of courses_list) {
      value_to_return.push(this.fb.group({
        course_code: [course.course_code],
        course_name: [course.course_name],
        requested: [course.requested || false],
      }))
    }

    return value_to_return;


  } */

  buildCourseRequestForm(course: any): void {
    //const available_course = course.available_course_list;
    const available_course = {
      courses_list: [
        {
          course_code: "20PAC103", course_name: "Python Programming"
        },
        {
          course_code: "20PME01", course_name: "Mathematics for Computer Sciences"
        },
        {
          course_code: "20PNE01", course_name: "English Literature"
        },
        {
          course_code: "20PTE01", course_name: "Tamil Literature"
        },
        {
          course_code: "20PKE01", course_name: "Financial Accounting"
        }
      ]
    }
    this.course_request_form = this.fb.group({
      _id: [course._id],
      courses_list: this.fb.array(this.buildCourseListForm(available_course.courses_list)),
    });
    console.log(this.course_request_form);
  }

  buildCourseListForm(courses_list: any[]): any[] {
    if (!courses_list) {
      courses_list = [];
    }
    const value_to_return = [];
    for (const course of courses_list) {
      value_to_return.push(this.fb.group({
        course_code: [course.course_code],
        course_name: [course.course_name],
        requested: [course.requested || false],
      }))
    }
    return value_to_return;
  }

  async onCheckBoxChange(current_course: FormGroup): Promise<void> {
    console.log(this.course_request_form.value.other_courses_list);
    const requested_courses = this.course_request_form.value.other_courses_list.filter((c: any) => c.requested == true)
    if (requested_courses.length > 2) {
      alert("Only 2 courses are allowed to select. Please unselect other course and try this on.");
      current_course.get('requested')!.setValue(false);
    }
  }

  async onRequestButtonClick(current_course: FormGroup, current_index: number): Promise<void> {
    //api call
    var prev_index: number = (this.course_request_form.get('courses_list') as FormArray).controls.findIndex(x => x.value.requested == true);
    if (prev_index == current_index) {
      alert("You have already selected this course");
    }
    else {
      if (prev_index >= 0) {
        const change_consent = confirm("You have already selected a course. Do you want to change to this course?");
        if (!change_consent)
          return;
        (this.course_request_form.get('courses_list') as FormArray).controls[prev_index].get('requested').setValue(false);
        current_course.get('requested').setValue(true);
      }
      else {
        current_course.get('requested').setValue(true);
      }
      //make api call with selected course code
      /*
    try{
    let service_response = await this.restService.createUpdateComponent(save_data);
    if (service_response && service_response.success) {
      this.onSearchClick();
      alert('Saved Successfully');
      //this.onCloseClick();
    }
    else {
      alert("Could not save data");
    }
  } catch(error) {
    console.log(error);
    alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
  } finally {
  } */
    }

  }

  async viewSyllabus(current_course: FormGroup) {
    this.onCloseClick();
    //navigate to new page and display its syllabus
    HTMLHelper.openLinkInNewTab("/students/syllabus?course_code=" + current_course.get('course_code').value);
  }


}
