import { Room } from './../room-model';
import { Floor } from './../floor-model';
import { StudentRoomMap } from './studentRoomMap';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { Hostel } from './../hostelModel';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.scss']
})
export class AssetManagementComponent implements OnInit, OnDestroy {
  @ViewChild('hostel') hostelRef: ElementRef;
  roomSelectionForm: FormGroup;
  hostels: Hostel[] = [] as Hostel[];
  hostel: Hostel = {} as Hostel;
  floor: Floor = {} as Floor;
  room: Room = {} as Room;
  studentRoomMaps: StudentRoomMap[] = [] as StudentRoomMap[];
  fonts = fonts;
  listLoading: boolean = false;
  fromDataResolver = FromDataResolver;
  constructor(private formBuilder: FormBuilder,
    private restService:AdminservicesService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.hostelRef = {} as ElementRef;
    this.roomSelectionForm = this.formBuilder.group({
      hostelSelect: ['', Validators.required],
      floorSelect: ['', Validators.required],
      roomSelect: ['', Validators.required]
    });
   }

   @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
      this.clearSession();
   }

  isHostel(data: Hostel|Floor|Room): data is Hostel {
    return (<Hostel>data).floors !== undefined;
  }
  isFloor(data: Hostel|Floor|Room): data is Floor {
    return (<Floor>data).rooms !== undefined;
  }
  isRoom(data: Hostel|Floor|Room): data is Room {
    return (<Room>data).floor_id !== undefined;
  }

  getHostelName(hostel: Hostel): string {
    this.hostel = hostel;
    return this.hostel.name;
  }
  onFilterSelectChange(data: string, event: any = null): void {
    this.clearSession();
    if (data === 'hostel') {
      const hostelName = this.roomSelectionForm.value.hostelSelect;
      if (hostelName) {
        this.hostel = this.hostels.filter(hostel => hostel.name === hostelName)[0] || {} as Hostel;
      }
    } else if (data === 'floor') {
      const floorName = this.roomSelectionForm.value.floorSelect;
      if (floorName) {
        this.floor = this.hostel?.floors?.filter(floor => floor.name === floorName)[0] || {} as Floor;
      }
    } else if (data === 'room') {
      const roomName = this.roomSelectionForm.value.roomSelect;
      if (roomName) {
        this.room = this.floor?.rooms?.filter(floor => floor.name === roomName)[0] || {} as Room;
      }
    }
  }


  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.setNavigation([{label: 'Assets', url: 'admin/hostel/asset-management'}]);
    this.notificationService.setNotifcation({});
    this.getHostel();
    
  }

  restoreLastSession(): void {
    if (localStorage.getItem('hostel') && localStorage.getItem('floor') && localStorage.getItem('room')) {
      this.roomSelectionForm = this.formBuilder.group({
        hostelSelect: localStorage.getItem('hostel'),
        floorSelect: localStorage.getItem('floor'),
        roomSelect: localStorage.getItem('room')
      });
      this.onFilterSelectChange('hostel');
      this.onFilterSelectChange('floor');
      this.onFilterSelectChange('room');
      this.onSearchClick();
   }
}
  async getHostel(): Promise<void> {
    try {
      this.hostels = await this.restService.hostelManagementService.getHostels().toPromise();
      this.restoreLastSession();
    } catch(error) {
      this.notificationService.setNotifcation({ message: error.message, type: 'Failed'});
    }
  }

  async getStudentRoomMaps(): Promise<void> {
    try {
      const res = await this.restService.studentRoomMapService.getStudentRoomMap('', this.room.id);
      if (res && res.length) {
        this.studentRoomMaps = res;
      } else {
        this.studentRoomMaps = [];
        this.notificationService.setNotifcation({ message: 'No students found', type: 'Warning'});
      }
    } catch(error) {
      this.notificationService.setNotifcation({ message: error.message, type: 'Failed'});
    }
  }

  saveSession() {
    localStorage.setItem('hostel', this.roomSelectionForm.value.hostelSelect);
    localStorage.setItem('floor', this.roomSelectionForm.value.floorSelect);
    localStorage.setItem('room', this.roomSelectionForm.value.roomSelect);
  }

  clearSession() {
    localStorage.removeItem('hostel');
    localStorage.removeItem('floor');
    localStorage.removeItem('room');
  }

  onEdit(studentRoomMap: StudentRoomMap): void {
    this.saveSession();
    this.router.navigate([`admin/hostel/student-roommap/${this.room.id}/${studentRoomMap?._id}`]);
  }

  async onDelete(studentRoomMap: StudentRoomMap): Promise<void> {
    try {
      const res = await this.restService.studentRoomMapService.deleteStudentRoomMap(studentRoomMap?._id);
      if (res) {
        this.studentRoomMaps = this.studentRoomMaps.filter(studentMap => studentMap?._id !== studentRoomMap?._id);
      }
    } catch(error) {
      this.notificationService.setNotifcation({ message: error?.message, type: 'Failed'});
    }
  }

  onAdd(): void {
    this.router.navigate(['admin/hostel/room-asset']);
  }

  onSearchClick(): void {
    const id = this.room?.id;
    if (id) {
      this.getStudentRoomMaps();
    }

  }

  canDisableAction() {
   return !this.room?.id;
  }

  addStudent(): void {
    this.saveSession();
    this.router.navigate([`admin/hostel/student-roommap/${this.room.id}`]);
  }

  onAssetList(): void {
    this.router.navigate(['admin/hostel/asset-list']);
  }
  ngOnDestroy() {
  }

}
