import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';



@Component({
  selector: 'app-admissionflows',
  templateUrl: './admissionflows.component.html',
  styleUrls: ['./admissionflows.component.scss']
})
export class AdmissionflowsComponent implements OnInit {


  // common methods 
  fonts = fonts;
  fromDataResolver = FromDataResolver;

  // Supporting master data 

  programme_type_list: any[] = [];
  finance_types = [{ id: "regular", text: "AIDED" }, { id: "self_finance", text: "SELF FINANCE" }]




  // Core datas

  programme_search_form: any;



  programme_list: any[] = [];
  programme_list_loading = false;

  programe: any | undefined = undefined;
  programme_save_loading = false;
  programme_delete_loading = false;

  programme_form: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getDepartmentCategoryList();

    // Build Filter Form
    this.buildFilterForm();
    // Load privamy data
    this.getFormDataList()
  }

  buildFilterForm() {
    this.programme_search_form = this.fb.group({
      finance_type: [""],
      programme_type_id: [""]
    })
  }


  async onAddClick() {
    this.buildForm();
    JQueryHelper.openModal("#modal-popup", { keyboard: false, backdrop: "static" })
  }

  async onEditClick(programe: any) {
    this.programe = programe;
    this.buildForm();
    JQueryHelper.openModal("#modal-popup", { keyboard: false, backdrop: "static" })
  }

  async onSaveClick() {
    try {
      const consent = confirm("Are you sure do you want to save?");
      if (!consent) {
        return;
      }


      this.programme_save_loading = true;

      const programme_data = this.programme_form.value;

      if (!programme_data.programme_code) {
        alert("Please enter programe code");
        return;
      }

      let save_respose;
      if (programme_data._id) {
        // update record
        save_respose = await this.restService.updateProgramme(programme_data.programme_code, programme_data);
      } else {
        // create record
        if (!programme_data.programme_type_id) {
          alert("Please enter programe type id");
          return;
        }
        if (!programme_data.finance_type) {
          alert("Please enter programe finance type");
          return;
        }
        if (!programme_data.programme_name) {
          alert("Please enter name");
          return;
        }
        if (!programme_data.short_name) {
          alert("Please enter short name");
          return;
        }
        save_respose = await this.restService.createProgramme(programme_data);
      }

      if (save_respose.success) {
        alert("Saved Successfully");
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert("Error while read data")
    } finally {
      this.programme_save_loading = false;
    }
  }

  async onDeleteClick() {
    try {

      const consent = confirm("Are you sure do you want to delete?");
      if (!consent) {
        return;
      }

      this.programme_delete_loading = true;

      const categgory_data = this.programme_form.value;
      if (!categgory_data.programme_code) {
        alert("Please enter programme_code")
      }


      let save_respose = await this.restService.deleteProgrammeById(categgory_data.programme_code);


      if (save_respose.success) {
        alert("Deleted Successfully");
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert("Error while read data")
    } finally {
      this.programme_delete_loading = false;
    }
  }




  async onCloseClick() {
    this.resetForm();
    JQueryHelper.closeModal("#modal-popup")
  }

  async getFormDataList() {
    try {
      this.prepareVariablesBeforeLoad();
      const search_form_query = this.programme_search_form.value;
      const service_response = await this.restService.searchProgrammes(search_form_query);
      if (service_response && service_response.success) {
        // fix data 
        for (const data of service_response.data) {
          if (data.finance_type == "self_finance") {
            data.finance_type_display = "Self Finance";
          } else if (data.finance_type == "regular") {
            data.finance_type_display = "Aided";
          }
        }
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert("Error while read data")
    } finally {
      this.prepareVariablesAfterLoad();
    }
  }


  prepareVariablesBeforeLoad() {
    this.programme_list = [];
    this.programme_list_loading = true;
  }
  prepareVariablesAfterLoad() {
    this.programme_list_loading = false;
  }

  resetForm() {
    this.programme_form = undefined;
    this.programe = undefined;
  }
  buildForm() {

    if (!this.programe) {
      this.programe = {
        _id: "",
        programme_id: "",
        finance_type: "",
        programme_type_id: "",
        programme_code: "",
        programme_name: "",
        short_name: "",
        started_date: "",
        discontinued: false,
        discontinued_date: "",
        description: "",
      }
    }

    this.programme_form = this.fb.group({
      _id: [this.programe._id],
      programme_id: [this.programe.programme_id],
      finance_type: [this.programe.finance_type, [Validators.required]],
      programme_type_id: [this.programe.programme_type_id, [Validators.required]],
      programme_code: [this.programe.programme_code, [Validators.required]],
      programme_name: [this.programe.programme_name, [Validators.required]],
      short_name: [this.programe.short_name, [Validators.required]],
      started_date: [this.programe.started_date],
      discontinued: [this.programe.discontinued],
      discontinued_date: [this.programe.discontinued_date],
      description: [this.programe.description, [Validators.required]]
    })
  }



  /// master data 

  async getDepartmentCategoryList() {
    try {
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert("Error while read data")
    } finally {

    }
  }

  // Filter 

  async onFilterSelectChange(id: string, event: any) {
    if (id == 'finance_type') {
      await this.getFormDataList();
    } else if (id == 'programme_type_id') {
      await this.getFormDataList();
    }
  }

}
