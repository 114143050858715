import { Assets } from './../assets';
import { Room } from './../../room-model';
import { Floor } from './../../floor-model';
import { Incharge } from './../../incharge-model';
import { Hostel, HostelBody } from './../../hostelModel';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, HostListener, OnInit } from '@angular/core';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-room-asset',
  templateUrl: './room-asset.component.html',
  styleUrls: ['./room-asset.component.scss']
})
export class RoomAssetComponent implements OnInit {
  assetId: string = '';
  hostel: Hostel = {} as Hostel;
  id: string = '';
  floorId: string = '';
  roomId: string = '';
  inchargeId: string = '';
  title: string = 'Create Asset';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  roomAssetForm: FormGroup;

  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.roomAssetForm = this.formBuilder.group({
      asset_ref: ['', [Validators.required]],
      type: ['', [Validators.required]],
      description: [''],
      active:[false],
      added_date: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      expires_at: [DateHelper.convertToControlDate(new Date()), [Validators.required]]
    });
  }

  getTittle(): string {
    return this.title;
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Assets', url: 'admin/hostel/asset-management' };
    
    this.route.params.subscribe(params => {
      this.assetId = params?.assetId;
      if (this.assetId) {
        this.title = 'Edit Asset';
      }
      this.navigationService.updateNavigation(this.title, this.router.url);
      this.handleGetAsset();
    });
  }

  async handleGetAsset(): Promise<void> {
    try {
      if (this.assetId) {
        const roomAsset: Assets[] = await this.restService.roomAssetService.getRoomAsset(this.assetId);
        if (roomAsset && Array.isArray(roomAsset) && roomAsset[0]) {
          this.roomAssetForm = this.formBuilder.group({
            asset_ref: roomAsset[0].asset_ref,
            type: roomAsset[0].type,
            description: roomAsset[0].description,
            added_date: DateHelper.convertToControlDate(new Date(roomAsset[0].added_date)),
            expires_at: DateHelper.convertToControlDate(new Date(roomAsset[0].expires_at)),
            active: roomAsset[0].active,
          })
        }
      }
    } catch (error) {
      this.notificationService.setNotifcation({ message: `Failed to get Asset ${this.assetId}: ${error.message}`});
    }
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  canDisable(): boolean {
    const type = this.roomAssetForm.value.type;
    if (!type) {
      return true;
    }
    let startDate = this.roomAssetForm.value.added_date;
    let endDate = this.roomAssetForm.value.expires_at;
    if (startDate) {
      startDate = Date.parse(startDate);
    }
    if (endDate) {
      endDate = Date.parse(endDate);
    }
    if (!startDate || !endDate || endDate < startDate) {
      return true;
    }
    return this.roomAssetForm.status !== 'VALID';
  }

  isInvalid(formControl: string): boolean {
    return this.roomAssetForm.touched && this.roomAssetForm.get(formControl)?.errors !== null;
  }

  async createAsset(): Promise<void> {
      try {
        const createdAsset = this.restService.roomAssetService.createRoomAsset(this.getFormData());
        this.onClose();
      } catch (error) {
        this.notificationService.setNotifcation({ message: `Failed to update Asset ${this.assetId} : ${error.message}`, type: 'Failed'});
      }
  }

  getFormData(): Assets {
    const asset = this.roomAssetForm.value;
    asset.added_date = DateHelper.convertToControlDate(asset.added_date);
    asset.expires_at = DateHelper.convertToControlDate(asset.expires_at);
    return asset;
  }

  async updateAsset(): Promise<void> {
    if (this.assetId) {
      try {
        const updatedAsset = this.restService.roomAssetService.updateRoomAsset(this.assetId, this.getFormData());
        this.onClose();
      } catch (error) {
        this.notificationService.setNotifcation({ message: `Failed to update Asset ${this.assetId} : ${error.message}`, type: 'Failed'});
      }
    }
  }

  onSave(): void {
    if (this.assetId) {
      this.updateAsset();
    } else {
      this.createAsset();
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {

  }

}
