<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal>
                    <button type="button" (click)="onAddClick()" class="btn btn-info"
                        *ngIf="cookieStore.isRoleExists(['coe', 'principal']) "> Add Setting </button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Terminal Exam Settings for Courses</p>
                    </div>


                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Month</label>
                                        <select formControlName="month" class="form-control">
                                            <option value="0">Select Month</option>
                                            <option
                                                *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="month.id">{{month.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Year</label>
                                        <select formControlName="year" class="form-control">
                                            <option value="0">Select year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!settings_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="settings_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 10%;">Exam Type</th>
                                        <th style="width: 20%;">Course Code</th>
                                        <th style="width: 5%;">Enrolled Year</th>
                                        <th style="width: 5%;">Semester</th>
                                        <th style="width: 10%;">Mode</th>
                                        <th style="width: 15%;">Start Date</th>
                                        <th style="width: 15%;">End Date</th>
                                        <th>Status</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="settings_list.length>0">
                                        <ng-container
                                            *ngFor="let setting of settings_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{setting.exam_type}}</td>
                                                <td>{{setting.course_code}}</td>
                                                <td>{{setting.enrolled_year}}</td>
                                                <td>{{setting.semester}}</td>
                                                <td>{{setting.mode_of_exam}}</td>
                                                <td>{{setting.start_time | date:commonEnums.date_time_format}}</td>
                                                <td>{{setting.end_time | date:commonEnums.date_time_format}}</td>
                                                <td>
                                                    <span *ngIf="setting.closed"
                                                        style="color: red;"><b>Closed</b></span>
                                                    <span *ngIf="!setting.closed"
                                                        style="color: green;"><b>Open</b></span>
                                                </td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(setting)"
                                                            title="Edit Setting" class="btn btn-sm btn-info mr-2 mt-2"
                                                            *ngIf="cookieStore.isRoleExists(['coe', 'principal']) ">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                        <button type="button"
                                                            (click)="navigateToQuestionSetting(setting)"
                                                            title="Add Question Paper"
                                                            class="btn btn-sm btn-info mr-2 mt-2">
                                                            <fa-icon [icon]="fonts.faFile"></fa-icon>
                                                        </button>
                                                        <button type="button" (click)="navigateValuationScheme(setting)"
                                                            title="Add Valuation Scheme"
                                                            class="btn btn-sm btn-info mr-2 mt-2">
                                                            <fa-icon [icon]="fonts.faListOl"></fa-icon>
                                                        </button>
                                                        <!-- <button type="button" (click)="navigateMarkRegister(setting)"
                                                            title="Mark-Grade Register"
                                                            class="btn btn-sm btn-info mr-2 mt-2">
                                                            <fa-icon [icon]="fonts.faAddressCard"></fa-icon>
                                                        </button> -->
                                                        <ng-container *ngIf="!setting.closed">
                                                            <button type="button" (click)="finalizeExam(setting,i)"
                                                                title="Finalize Exam"
                                                                class="btn btn-sm btn-info mr-2 mt-2"
                                                                *ngIf="!finalize_loading[i]">
                                                                <fa-icon [icon]="fonts.faLock"></fa-icon>
                                                            </button>
                                                            <button type="button" disabled
                                                                class="btn btn-sm btn-info mr-2 mt-2"
                                                                *ngIf="finalize_loading[i]">
                                                                Finalize Loading...
                                                            </button>
                                                        </ng-container>
                                                        <ng-container *ngIf="setting.closed">
                                                            <button type="button" title="Exam Closed"
                                                                class="btn btn-sm btn-info mr-2 mt-2" disabled>
                                                                <fa-icon [icon]="fonts.faLock"></fa-icon>
                                                            </button>
                                                        </ng-container>

                                                        <!-- <button type="button"
                                                            (click)="navigateToSubmissionReview(setting)"
                                                            title="Review Submission" class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faCheck"></fa-icon>
                                                        </button> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="10" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="10" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="exam_setting_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Exam Setup</h6>
                    </div>
                    <div class="modal-body" *ngIf="state == 'SETTING'">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="exam_setting_form" class="form-horizontal">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Month</label>
                                            <select formControlName="month" class="form-control">
                                                <option value="0">Select Month</option>
                                                <option
                                                    *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="month.id">{{month.text}}</option>
                                            </select>

                                            <div *ngIf="isInvalid('month')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Month is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Year</label>
                                            <select formControlName="year" class="form-control">
                                                <option value="0">Select year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('year')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Course Code</label>
                                            <input type="text" class="form-control" formControlName="course_code"
                                                placeholder="Enter Course Code">
                                            <div *ngIf="isInvalid('course_code')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Course Code is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Question Format Setting Name</label>
                                            <select formControlName="question_format_id" class="form-control">
                                                <option value="">Select Question Format Name</option>
                                                <option
                                                    *ngFor="let format of format_setting_data;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="format._id">{{format.format_name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('question_format_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Question Format Setting Name is
                                                        required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="category.programme_type_id">{{category.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrollment Year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="0">Select Enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('enrolled_year')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Enrollment Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="0">Select Semester</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>

                                            <div *ngIf="isInvalid('semester')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Semester is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                                Date</label>
                                            <input type="datetime-local" class="form-control"
                                                formControlName="start_time" placeholder="Enter Start date">
                                            <div *ngIf="isInvalid('start_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                                                Date</label>
                                            <input type="datetime-local" class="form-control" formControlName="end_time"
                                                (focusout)="validateDate($event,'end_time')"
                                                placeholder="Enter End date">
                                            <div *ngIf="isInvalid('end_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Exam Mode</label>
                                            <select formControlName="mode_of_exam" class="form-control">
                                                <option value="">Select Mode</option>
                                                <option
                                                    *ngFor="let mode of this.exam_modes;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="mode.key">{{mode.text}}
                                                </option>
                                            </select>
                                            <div *ngIf="isInvalid('mode_of_exam')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Exam Mode is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Appearance Type</label>
                                            <select formControlName="appearance_type" class="form-control">
                                                <option value="">Select Appearance Type</option>
                                                <option
                                                    *ngFor="let appearance_type of this.appearance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="appearance_type.key">{{appearance_type.text}}</option>
                                            </select>

                                            <div *ngIf="isInvalid('appearance_type')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Appearance Type is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="allow_students_answer_submission"
                                                class="mg-r-5">Allow student submission? (If selected, students can
                                            upload their answer sheets in the portal.)
                                            <div *ngIf="isInvalid('allow_students_answer_submission')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose if students can upload their
                                                        answer sheets for this exam or not.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="this.exam_setting_form.value.allow_students_answer_submission">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Submission Start
                                                Date</label>
                                            <input type="datetime-local" class="form-control"
                                                formControlName="submission_start_time" placeholder="Enter Start date"
                                                (focusout)="validateDate($event, 'submission_start_time')">
                                            <div *ngIf="isInvalid('start_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Submission Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Submission End
                                                Date</label>
                                            <input type="datetime-local" class="form-control"
                                                formControlName="submission_end_time"
                                                (focusout)="validateDate($event,'submission_end_time')"
                                                placeholder="Enter End date">
                                            <div *ngIf="isInvalid('end_time')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Submission End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="active" class="mg-r-5">Is Active?
                                            <div *ngIf="isInvalid('active')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose if the setting is active for
                                                        examination or not.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-3">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="closed" class="mg-r-5">Is Closed?
                                            <div *ngIf="isInvalid('closed')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose if the setting is closed or not.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>


                                <h6>Valuation Settings</h6>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                the max. mark difference between each valuations</label>
                                            <input type="number" class="form-control"
                                                formControlName="valuation_mark_difference"
                                                placeholder="Enter Difference between each valuation">
                                            <div *ngIf="isInvalid('valuation_mark_difference')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Valuation Mark Difference is required.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                the Pass Mark for each valuations</label>
                                            <input type="number" class="form-control" formControlName="pass_mark"
                                                placeholder="Enter the Pass Mark for each valuations">
                                            <div *ngIf="isInvalid('pass_mark')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Pass Mark is required.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                the Max. Cond. Mark</label>
                                            <select formControlName="max_cond_mark" class="form-control">
                                                <option value="">Select Max. Cond. Mark</option>
                                                <option
                                                    *ngFor="let cond_mark of this.getMaxCond();let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="cond_mark">{{cond_mark}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('max_cond_mark')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Max. Cond. Mark is required.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                the Overall Pass Mark combining Internal & External</label>
                                            <input type="number" class="form-control"
                                                formControlName="pass_mark_combined"
                                                placeholder="Enter the Overall Pass Mark combining Internal & External">
                                            <div *ngIf="isInvalid('pass_mark_combined')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Overall Pass Mark is required.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered text-md-nowrap">
                                        <thead>
                                            <tr class="text-center">
                                                <th style="width:5%" class="text-center">S.No.</th>
                                                <th style="width:12%" class="text-center">Valuation No.</th>
                                                <th style="width:13%" class="text-center">Type</th>
                                                <th style="width:20%" class="text-center">Pgm & Sec</th>
                                                <th style="width:10%" class="text-center">Staff ID.</th>
                                                <!-- <th style="width:15%" class="text-center">Start Date</th>
                                                <th style="width:15%" class="text-center">End Date</th> -->
                                                <th style="width:10%" class="text-center">Status</th>
                                                <th style="width: 15%;" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="valuations">
                                            <ng-container *ngFor="let valuation of valuatorList; let in=index;">
                                                <tr [formGroupName]="in">
                                                    <td class="text-center">{{in+1}}</td>
                                                    <td>
                                                        <!-- <input type="number" class="form-control"
                                                            formControlName="valuation_no"
                                                            placeholder="Enter Valuation No."> -->
                                                        <select formControlName="valuation_no" class="form-control"
                                                            (change)="valuationChanged($event, in)">
                                                            <option value="">Select Valuation No.</option>
                                                            <option
                                                                *ngFor="let valuation_no of this.valuation_nos;let i=index;trackBy:fromDataResolver.identify;"
                                                                [value]="valuation_no">{{valuation_no}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select formControlName="evaluated_in" class="form-control"
                                                            (change)="evaluatedInChanged($event, in)">
                                                            <option value="">Select Valuation Type</option>
                                                            <option
                                                                *ngFor="let valuation_type of this.valuation_types;let i=index;trackBy:fromDataResolver.identify;"
                                                                [value]="valuation_type.key">{{valuation_type.text}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <!-- <select formControlName="staff_list"
                                                            class="form-control" (change)="pgmChanged($event,in)">
                                                            <option value="">Select Programme & Section</option>
                                                            <option
                                                                *ngFor="let pgm of this.available_programme_list[in];let k=index;trackBy:fromDataResolver.identify;"
                                                                [value]="pgm._id">
                                                                {{getProgrammeDisplay(pgm)}}
                                                            </option>
                                                        </select> -->
                                                        <ng-container>
                                                          <kendo-multiselect [data]="available_programme_list[in]"
                                                            valueField="staff_course_map_id" textField="roll_no"
                                                            (valueChange)="pgmChanged($event,in)"
                                                            [placeholder]="'Choose Staff Id(s)'"
                                                            formControlName="staff_list">
                                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                                <span class="template">{{
                                                                    getProgrammeDisplay(dataItem)}}</span>
                                                            </ng-template>
                                                          </kendo-multiselect>
                                                        </ng-container>

                                                        <!-- <ng-container *ngIf="exam_setting_form.value.valuations[in].evaluated_in == 'EXTERNAL'">
                                                          <kendo-multiselect [data]="available_programme_list[in]"
                                                            valueField="staff_course_map_id" textField="roll_no"
                                                            (valueChange)="pgmChanged($event,in)"
                                                            [placeholder]="'Choose Staff Id(s)'"
                                                            formControlName="staff_list">
                                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                                <span class="template">{{
                                                                    getProgrammeDisplay(dataItem)}}</span>
                                                            </ng-template>
                                                          </kendo-multiselect>
                                                        </ng-container> -->

                                                    </td>
                                                    <!-- {{getProgrammeName(pgm.class_taking_programme_id)}} -
                                                    {{(pgm?.virtual_section && pgm?.virtual_section
                                                    !='')?pgm?.virtual_section:pgm.class_taking_section}} -->
                                                    <td class="text-center">
                                                        <input type="text" class="form-control"
                                                            placeholder="Enter Staff ID." formControlName="roll_no"
                                                            *ngIf="valuation.value.evaluated_in !='INTERNAL' && valuation.value.evaluated_in !='' ">
                                                        <p *ngIf="valuation.value.evaluated_in =='INTERNAL'">
                                                            <span
                                                                *ngFor="let staff of valuation.value.staff_list; let ind = index">{{staff.roll_no}}
                                                                <span *ngIf="valuation.value.staff_list.length-1>ind">,
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <!-- <td class="text-center">
                                                        <input type="date" class="form-control"
                                                            (focusout)="validateValuationDate($event,'start_time', in)"
                                                            formControlName="start_time" placeholder="Enter Start date">
                                                    </td>-->
                                                    <td class="text-center">
                                                        <!-- (focusout)="validateValuationDate($event,'end_time', in)" -->
                                                        <input type="datetime-local" class="form-control"
                                                            formControlName="end_time" placeholder="Enter End date">
                                                    </td>
                                                    <!-- <td>
                                                        <ng-container *ngIf="valuation.value.stage!=''">
                                                            <button class="btn btn-info"
                                                                (click)="changeValuationStage(valuation.value.stage, in)"
                                                                *ngIf="valuation.value.stage!='COMPLETED'">
                                                                <ng-container
                                                                    *ngIf="valuation.value.stage =='NOT_STARTED'">
                                                                    Start Valuation
                                                                </ng-container>
                                                                <ng-container *ngIf="valuation.value.stage =='STARTED'">
                                                                    Close Valuation
                                                                </ng-container>
                                                            </button>
                                                            <span *ngIf="valuation.value.stage=='COMPLETED'">
                                                                <b>VALUATION COMPLETED</b>
                                                            </span>
                                                        </ng-container>
                                                    </td> -->
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-info btn-sm mg-r-5 mg-t-5"
                                                            (click)="viewMarks(in)" *ngIf="exam_setting_form.value._id">
                                                            View Marks
                                                        </button>
                                                        <button type="button" class="btn btn-danger btn-sm mg-t-5"
                                                            (click)="deleteValuator(in)">
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td colspan="8" class="text-center">
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="addValuator()">
                                                        Add New Valuation
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="text-center">
                                    <!-- <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Save &
                                        Close</button> -->
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">
                                        <ng-container
                                            *ngIf="!this.exam_setting_form.value._id && !this.setting_save_loading">
                                            Save
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!this.exam_setting_form.value._id && this.setting_save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.exam_setting_form.value._id && !this.setting_save_loading">
                                            Update
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.exam_setting_form.value._id && this.setting_save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">
                                        <ng-container
                                            *ngIf="!this.exam_setting_form.value._id && !this.setting_save_loading">
                                            Save & Close
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!this.exam_setting_form.value._id && this.setting_save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.exam_setting_form.value._id && !this.setting_save_loading">
                                            Update & Close
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.exam_setting_form.value._id && this.setting_save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="this.exam_setting_form.value._id"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-body" *ngIf="state == 'MARKS'">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Roll No.</th>
                                            <th>CO1</th>
                                            <th>CO2</th>
                                            <th>CO3</th>
                                            <th>CO4</th>
                                            <th>CO5</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let student of student_data; let i = index; trackBy:fromDataResolver.identify;">
                                            <td>{{i+1}}</td>
                                            <td>{{student.student_roll_no}}</td>
                                            <td
                                                *ngFor="let co_item of student.co_items; let j = index; trackBy:fromDataResolver.identify;">
                                                {{co_item.mark}}
                                            </td>
                                            <td>{{student.total}}</td>
                                        </tr>
                                    </tbody>
                                    <tr>
                                        <td colspan="8" *ngIf="student_data_loading && student_data.length==0"
                                            class="text-center">
                                            <b>Loading...</b>
                                        </td>
                                        <td colspan="8" *ngIf="!student_data_loading && student_data.length==0"
                                            class="text-center">
                                            <b>No Mark Data Found</b>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="text-right">
                                <button class="btn btn-secondary" (click)="closeMarks()">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>
