import { Component, OnInit, ViewChild } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-student-assignment',
  templateUrl: './student-assignment.component.html',
  styleUrls: ['./student-assignment.component.scss']
})
export class StudentAssignmentComponent implements OnInit {

  //bulk render data
  bulk_render_data: Modal = {
    return_type: "FILE",
    file_upload_only: true,
    modal_id: "student-map",
    modal_button_text: "Student-Courses Bulk Upload",
    modal_header: "Student-Courses Bulk Upload",
    list_name: "Student-Courses Assign",
    csv_data: {
      columns: [
        { column_name: "course_code", column_type: "required" },
        { column_name: "enrolled_year", column_type: "required" },
        { column_name: "semester", column_type: "required" },
        { column_name: "roll_nos", column_type: "required" },
        { column_name: "appearance_type", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Course Code", "Enrolled Year", "Semester", "Roll Nos.", "Appearance Type"],
      column_values: ["20UAC101", "2020", "1", "20PA01,20PA02..", "REGULAR"]
    },
    show_loader: false
  };
  title = 'Student-Course Assignment';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;
  studentassignForm: FormGroup;
  table_data_loading: boolean = false;
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  all_programme_list: any[] = [];
  programme_list_loading = false;
  programme_section_list: any[] = [];
  sections: any = [];
  parts: any[] = [];
  type: string = "roll_no_search";
  appearance_types: { key: string, value: string }[] = [{ key: "normal", value: "Normal" }, { key: "ARREAR", value: "Arrear" }, { key: "REPEAT", value: "Repeat Semester" }];
  part_course_types: any[] | undefined = [];
  table_data: any = {};
  course_positions: ["Core 1", "Core 2", "Core 3", "Elective 1", "Elective 2", "Supplementary 1"];
  course_handling_staffs: {
    staff_id: string, staff_name: string
  }[] = [];
  map_delete_loading: boolean = false;
  studentsemesterForm: FormGroup = {} as FormGroup;
  file_upload: any;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) {
    this.studentassignForm = this.formBuilder.group({
      _id: [''],
      course_code: ['', [Validators.required]],
      semester: ['', [Validators.required]],
      enrolled_year: ['', [Validators.required]],
      roll_nos: ['', [Validators.required]],
      appearance_type: ['', [Validators.required]],
      //staff_id: ['', [Validators.required]],
    });

    this.studentsemesterForm = this.formBuilder.group({
      enrolled_year: ['', [Validators.required]],
      semester: ['', [Validators.required]],
      roll_nos: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }

  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      roll_no: [''],
      course_code: [''],
      enrolled_year: [''],
      semester: [''],
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section: [''],
      //course_position: ['']
      part: [''],
      course_type: ['']
    });
  }

  isTableDataEmpty() {
    if (JSON.stringify(this.table_data) == '{}' || JSON.stringify(this.table_data) == '[]') {
      return true;
    }
    return false;
  }
  searchStaffByCourse(ev: any) {
    var course_code = ev.target.value;
    //search staff list by course_code
    this.course_handling_staffs = [{
      staff_id: "SF001", staff_name: "Mr. AAA"
    }, { staff_id: "SF002", staff_name: "Mr. BBB" }]
  }
  async onFilterSelectChange(id: string, event: any): Promise<void> {
    //this.onResetClick();
    if (id == "programme_id") {
      this.sections = [];
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.filter_form.get('section').setValue(this.sections[0].section_name);
        }
      }
      //API call to get course_position list based on programme_id and semester
    }

    if (id === 'part') {
      if (this.filter_form.value.programme_type_id) {
        this.part_course_types = this.commonEnums.getCoursePartType(
          this.filter_form.value.programme_type_id, this.filter_form.value.part);
      }
    }
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.filter_form.get('programme_id').setValue("");
      this.filter_form.get('part').setValue("");
      this.getProgrammeDataList(this.filter_form);
      if (id === 'programme_type_id' && this.filter_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.filter_form.value.programme_type_id);
      }
    }
  }
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }


  changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.programme_list = []; this.parts = [];
    this.table_data = {};
  }
  async onSearchClick() {
    this.table_data = {};
    var search_query: any = {};
    if (this.type == 'roll_no_search') {
      if (!this.filter_form.value.roll_no) {
        alert("Please enter valid Roll No."); return;
      }
      search_query.roll_no = this.filter_form.value.roll_no;
    }
    if (this.type == 'course_code_search') {
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose valid Enrolled Year"); return;
      }
      if (!this.filter_form.value.course_code) {
        alert("Please enter valid Course Code"); return;
      }
      search_query.enrolled_year = this.filter_form.value.enrolled_year;
      search_query.course_code = this.filter_form.value.course_code;
    }

    if (this.type == 'programme_search') {
      if (!this.filter_form.value.programme_type_id) {
        alert("Please choose Programme Type"); return;
      }
      if (!this.filter_form.value.finance_type) {
        alert("Please choose Finance Type"); return;
      }
      if (!this.filter_form.value.enrolled_year) {
        alert("Please choose Enrollment Year"); return;
      }
      if (!this.filter_form.value.programme_id) {
        alert("Please choose Programme Name"); return;
      }
      if (!this.filter_form.value.semester) {
        alert("Please choose Semester"); return;
      }
      search_query = {
        programme_type_id: this.filter_form.value.programme_type_id,
        finance_type: this.filter_form.value.finance_type,
        enrolled_year: this.filter_form.value.enrolled_year,
        programme_id: this.filter_form.value.programme_id,
        section: this.filter_form.value.section,
        semester: this.filter_form.value.semester,
      }
      if (this.filter_form.value.part) {
        search_query.part = this.filter_form.value.part;
      }
      if (this.filter_form.value.course_type) {
        search_query.course_type = this.filter_form.value.course_type;
      }
    }
    console.log(search_query);
    this.table_data_loading = true;
    try {
      const service_response = await this.restService.searchStudentCourseMapFilter(this.type, search_query);
      if (service_response && service_response.success) {
        console.log(service_response);
        this.table_data = service_response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id).programme_name;
  }
  //Modal Methods
  onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(student_data: any, semester?: number): Promise<void> {
    console.log(student_data);
    const edit_data = {
      _id: student_data._id,
      roll_nos: student_data.roll_no,
      course_code: student_data.course_code,
      appearance_type: student_data.appearance_type,
      semester: 0
    }
    if (semester) {
      edit_data.semester = semester;
    }
    else {
      edit_data.semester = student_data.semester
    }
    this.studentassignForm.setValue(edit_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  isInvalid(formControl: any): boolean {
    return this.studentassignForm.touched && this.studentassignForm.get(formControl)?.errors !== null;
  }
  canDisable(): boolean {
    return this.studentassignForm.status !== 'VALID';
  }
  reset(): void {
    this.studentassignForm.reset();
    this.studentassignForm.controls["appearance_type"].setValue("");
    this.studentassignForm.controls["semester"].setValue("");
    this.studentassignForm.controls["enrolled_year"].setValue("");
    this.course_handling_staffs = [];
  }
  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  onEditByRollNo(enrolled_year: number, semester: number, roll_no: string, course_data: any) {
    const form_data = {
      _id: course_data._id,
      course_code: course_data.course_code,
      enrolled_year: enrolled_year,
      semester: semester,
      roll_nos: roll_no,
      appearance_type: course_data.appearance_type
    }
    this.studentassignForm.setValue(form_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditByCourseCode(course_code: string, student_data: any) {
    const form_data = {
      _id: student_data._id,
      course_code: course_code,
      enrolled_year: student_data.enrolled_year,
      semester: student_data.semester,
      roll_nos: student_data.roll_no,
      appearance_type: student_data.appearance_type
    }
    this.studentassignForm.setValue(form_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditByProgramme(_id: string, roll_no: string, enrolled_year: number, course_data: any) {
    const form_data = {
      _id: _id,
      course_code: course_data.course_code,
      enrolled_year: enrolled_year,
      semester: this.filter_form.value.semester,
      roll_nos: roll_no,
      appearance_type: course_data.appearance_type
    }
    this.studentassignForm.setValue(form_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  onSave() {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  async addItem(): Promise<void> {
    try {
      var map_data = this.studentassignForm.value
      console.log(map_data);
      let save_respose;
      // create or update record
      save_respose = await this.restService.addStudentCourseMap(map_data);
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Saved Successfully');
        //this.onCloseClick();
      }
    } catch (error) {
      //this.notificationService.setNotifcation({ message: 'Failed to create Course-Staff Assignment.', type: 'Failed' });
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }


  async onDelete() {
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      this.map_delete_loading = true;
      const save_respose = await this.restService.deleteStudentCourseMap(this.studentassignForm.value._id); //--> new api
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Deleted Successfully');
        this.onCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.map_delete_loading = false;
    }
    this.onCloseClick();
  }

  async bulkUpload(e: any) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_render_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.studentCourseBulkUpload(formData);
      if (response.success && response.data && response.data.url) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_data.show_loader = false;
    }

  }

  onSemesterwiseClick() {
    JQueryHelper.openModal('#modal-popup-semester', { keyboard: false, backdrop: 'static' });
  }

  onSaveSemesterClick() {
    this.addSemsterwise();
  }

  onSaveSemesterAndClose() {
    this.addSemsterwise();
    this.onCloseSemesterwiseClick();
  }

  async addSemsterwise() {
    try {
      var map_data = this.studentsemesterForm.value
      console.log(map_data);
      let save_respose;
      // create or update record
      save_respose = await this.restService.studentCourseSemesterSeed(map_data);
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Saved Successfully');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  canDisableSemester(): boolean {
    return this.studentsemesterForm.status !== 'VALID';
  }

  resetSemesterwise(): void {
    this.studentassignForm.reset();
    this.studentsemesterForm.controls["semester"].setValue("");
    this.studentsemesterForm.controls["enrolled_year"].setValue("");
  }

  async onCloseSemesterwiseClick(): Promise<void> {
    this.resetSemesterwise();
    JQueryHelper.closeModal('#modal-popup-semester');
  }

  downloadFile(file_type: string) {
    const data_list: any[] = [];
    let i = 1;
    /* if (this.type == 'roll_no_search') {
      for (const data of this.table_data) {
        data_list.push({
          "S_No": i,
          "Course_Code": data.course_code,
          "Course_Name": data.course_name,
          "Appearance_Type": data.appearance_type,
        });
        i++;
      }
      if (file_type == 'CSV') {
        CSVHelper.downloadCSV(data_list, 'Courses studied by ' + this.filter_form.value.roll_no + ' ' + DateHelper.convertToControlDate(new Date()));
      }
      else {
        const arr = data_list.map(d => [d.S_No, d.Course_Code, d.Course_Name, d.Appearance_Type])
        const doc = new jsPDF()
        autoTable(doc, {
          head: [['S.No', 'Course Code', 'Course Name', 'Appearance Type']],
          body: arr,
          theme: 'grid'
        })
        doc.save('Courses studied by ' + this.filter_form.value.roll_no + ' ' + DateHelper.convertToControlDate(new Date()) + '.pdf')
      }
    } */
    if (this.type == 'course_code_search') {
      for (const data of this.table_data.student_data) {
        data_list.push({
          "S_No": i,
          "Roll_No": data.roll_no,
          "Name": data.given_name + " " + data.middle_name,
          "Programme_Name": data.programme_name,
          "Section": data.section,
          "Appearance_Type": data.appearance_type,
        });
        i++;
      }
      if (file_type == 'CSV') {
        CSVHelper.downloadCSV(data_list, 'Student studying ' + this.filter_form.value.course_code + ' ' + DateHelper.convertToControlDate(new Date()));
      }
      else {
        var records: any[] = this.table_data.student_data;
        //console.log(records);
        var records_length = records.length;
        var table_string: string = '';
        table_string +=
          '<table ' +
          'class="table table-bordered">' +
          '<thead><tr><th>S.No</th><th>Roll No.</th><th>Name</th><th>Programme Name</th><th>Section</th><th>Remarks/<br>Signature</th></tr></thead>' +
          '<tbody>';
        for (var index = 0; index < records_length; index++) {
          table_string += '<tr>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + (index + 1) + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + records[index].roll_no + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + records[index].given_name + " " + records[index].middle_name + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + records[index].programme_name + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + records[index].section + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center"> </td></tr>';
        }
        table_string += '</tbody></table>'

        var window_height = window.screen.height;
        var window_width = window.screen.width;
        var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
        mywindow.document.write('<html><head>');
        mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
          "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
          "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
          "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
        mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
        mywindow.document.write(
          '<div class="row" style = "margin-left:50px" id = "print-header" >' +
          ' <div class="col-md-2" > ' +
          '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
          '</div>' +
          '<div class= "col-md-8 text-center" > ' +
          '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
          '(Autonomous) </h4>' +
          '<h4> Sivakasi - 626 124. </h4>' +
          '<h5 style = "margin-top:5px"> Coursewise Student Report' +
          ' </h5>' +
          '</div>' +
          '</div>');
        mywindow.document.write('<h6 class= "text-center"><b>' + this.table_data.part + ' - ' + this.table_data.course_type + '</b></h6>');
        mywindow.document.write('<h6 class= "text-center"><b>' + this.table_data.course_code + ' - ' + this.table_data.course_name + '</b></h6>');
        mywindow.document.write('<div class="row">');
        mywindow.document.write(table_string);
        mywindow.document.write('</div>');
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      }
    }
  }


  downloadProgrammeFile(file_type: string) {
    if (file_type == 'PDF') {
      var records: any[] = this.table_data;
      //console.log(records);
      var records_length = records.length;
      var table_string: string = '';
      table_string +=
        '<table ' +
        'class="table table-bordered">' +
        '<thead><tr><th>S.No</th><th>Roll No.</th><th>Name</th><th>Code</th><th>Course Name</th><th>Offered By</th><th>Remarks/<br>Signature</th></tr></thead>' +
        '<tbody>';
      for (var index = 0; index < records_length; index++) {
        var course_data: any[] = records[index].data;
        for (var jindex = 0; jindex < course_data.length; jindex++) {
          table_string += '<tr>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + (index + 1) + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + records[index].roll_no + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + records[index].given_name + " " + records[index].middle_name + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + course_data[jindex].course_code + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + course_data[jindex].course_name + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center">' + course_data[jindex].programme_name + '</td>' +
            '<td style="height: 30px; padding:0; margin:0; text-align:center"> </td></tr>';
        }
      }
      table_string += '</tbody></table>'

      var window_height = window.screen.height;
      var window_width = window.screen.width;
      var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
      mywindow.document.write('<html><head>');
      mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
        "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
        "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
        "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
      mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
      mywindow.document.write(
        '<div class="row" style = "margin-left:50px" id = "print-header" >' +
        ' <div class="col-md-2" > ' +
        '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
        '</div>' +
        '<div class= "col-md-8 text-center" > ' +
        '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
        '(Autonomous) </h4>' +
        '<h4> Sivakasi - 626 124. </h4>' +
        '<h5 style = "margin-top:5px"> Classwise Student Report' +
        ' </h5>' +
        '</div>' +
        '</div>');
      mywindow.document.write('<h6 class= "text-center"><b>' + this.table_data[0].programme_name + ' - ' + this.table_data[0].section + '</b></h6>');
      mywindow.document.write('<div class="row">');
      mywindow.document.write(table_string);
      mywindow.document.write('</div>');
      mywindow.document.write('</body></html>');
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10
      setTimeout(function () {
        mywindow.print();
        mywindow.close();
      }, 1000);
    }
  }
}
