import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import * as querystring from 'querystring';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-admissiondashboard',
  templateUrl: './admissiondashboard.component.html',
  styleUrls: ['./admissiondashboard.component.scss']
})
export class AdmissiondashboardComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  cookieStore = CookieStore;

  // Master data
  admission_id = '';
  regulation_year = new Date().getFullYear();

  admissions_list = [];
  admissions_list_loading = false;

  // Core data
  programme_overveiw_master: any;
  programme_overveiw: any;
  programme_overveiw_loading = false;

  programme_list_loading: boolean = false;
  programme_list: any[] = [];
  admission_search_form: any;
  multiple_selection_list: any[] = [];
  multiple_selection_list_loading: boolean = false;

  // management quota
  management_quota_search_form: any;
  management_quota_search_form_loading = false;

  applicant_details: any;

  management_quota_form: any;
  management_quota_form_loading = false;

  // transfer quota
  transfer_type = '';
  transfer_prg_search_form: any;
  transfer_prg_search_form_loading = false;

  transfer_prg_applicant_details: any;

  transfer_prg_quota_form: any;
  transfer_prg_quota_form_loading = false;


  constructor(private fb: FormBuilder, private location: Location,
    // tslint:disable-next-line:align
    private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
      this.regulation_year = Number(data.regulation_year);

    });
  }

  ngOnInit(): void {
    this.buildAdmissionSearch();
    this.getProgrammeDataList();
  }

  buildAdmissionSearch(): void {
    this.admission_search_form = this.fb.group({
      admission_id: [this.admission_id],
      regulation_year: [this.regulation_year],
      finance_type: [''],
    });
    this.getCoreDatas();
    this.getOverAllAdmissionCounts();
  }

  async onRegulationYearChange(): Promise<void> {
    this.admission_search_form.get('admission_id').setValue('');
    this.admission_search_form.get('finance_type').setValue('');
    this.getCoreDatas();

    this.updateURL();
  }

  updateURL(): void {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route, queryParams:
      {
        regulation_year: this.admission_search_form.value.regulation_year,
        admission_id: this.admission_search_form.value.admission_id,
      }
    }).toString();
    // this.location.go(url);
    location.replace(url);
  }

  async onAdmissionChange(): Promise<void> {
    this.getOverAllAdmissionCounts();
    this.updateURL();
  }

  async getCoreDatas(): Promise<void> {
    try {
      const query = {
        year: this.admission_search_form.value.regulation_year
      };
      this.admissions_list = [];
      this.admissions_list_loading = true;
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {

        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  async getOverAllAdmissionCounts(): Promise<void> {
    try {
      this.programme_overveiw_loading = true;
      this.programme_overveiw = null;
      const query = {
        admission_id: this.admission_search_form.value.admission_id
      };
      const response = await this.restService.getOverAllAdmissionCounts(query);
      if (response.success && response.data) {
        this.programme_overveiw_master = JSON.parse(JSON.stringify(response.data));
        this.programme_overveiw = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_overveiw_loading = false;
    }
  }

  onTotalRegisteredUserClick(): void {

    const regulation_year = this.admission_search_form.value.regulation_year;
    HTMLHelper.openLinkInNewTab('/admin/admissions/status/registered?regulation_year=' +
      regulation_year + '&admission_id=' + this.admission_id);


  }

  viewOfflineDue(): void {

    const regulation_year = this.admission_search_form.value.regulation_year;
    HTMLHelper.openLinkInNewTab('/admin/admissions/reports/offline-due?regulation_year=' +
      regulation_year + '&admission_id=' + this.admission_id);


  }

  async onFinanceTypeChange(): Promise<void> {
    let programme_wise;
    if (this.admission_search_form.value.finance_type) {
      programme_wise = JSON.parse(JSON.stringify(this.programme_overveiw_master)).programme_wise
        .filter((c: any) => c.finance_type === this.admission_search_form.value.finance_type);
    } else {
      programme_wise = JSON.parse(JSON.stringify(this.programme_overveiw_master)).programme_wise;
    }
    this.programme_overveiw.programme_wise = programme_wise;
    let total_application_issued = 0;
    for (const programme_wise_data of programme_wise) {
      total_application_issued = total_application_issued + programme_wise_data.total_application_issued;
    }
    this.programme_overveiw.total_application_issued = total_application_issued;
  }

  async onVieDetailsClick(programme: any): Promise<void> {
    this.router.navigate(['admin/admissions/applicant/list'], {
      queryParams: {
        regulation_year: this.admission_search_form.value.regulation_year,
        admission_id: this.admission_search_form.value.admission_id,
        finance_type: programme.finance_type,
        programme_id: programme.programme_id,
      }
    });
  }

  async onStatusWiseClick(programme_wise: any, status_wise: any): Promise<void> {
    console.log(status_wise);
    const qs = querystring.stringify({
      regulation_year: this.admission_search_form.value.regulation_year,
      admission_id: this.admission_search_form.value.admission_id,
      finance_type: programme_wise.finance_type,
      programme_id: programme_wise.programme_id,
      programme_name: (programme_wise.programme_name + ' ' + (programme_wise.finance_type === 'regular' ? '(R)' : '(SF)'))
    });

    HTMLHelper.openLinkInNewTab(`/admin/admissions/reports/applicant/${status_wise.status}?${qs}`);

  }

  async onManagementQuotaClick(): Promise<void> {
    try {
      this.management_quota_search_form = this.fb.group({
        application_no: [''],
      });
      if (this.applicant_details) {
        for (const application_data of this.applicant_details.applications) {
          application_data.show_detials = false;
          application_data.details_form = null;
        }
      }
      JQueryHelper.openModal('#modal-management-quota', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onApplicationSearch(): Promise<void> {
    try {
      if (!this.management_quota_search_form.value.application_no) {
        return;
      }
      this.management_quota_search_form_loading = true;
      this.applicant_details = null;
      const response = await this.restService.getDetailsByApplicationNo(this.management_quota_search_form.value.application_no);
      if (response.success && response.data) {
        this.applicant_details = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.management_quota_search_form_loading = false;
    }
  }

  async onManagementQuotaCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-management-quota');
  }

  async onViewManagementClick(application: any): Promise<void> {
    for (const application_data of this.applicant_details.applications) {
      application_data.show_detials = false;
      application_data.details_form = null;
    }
    application.show_detials = true;
    application.details_form = this.fb.group({
      quota_type: ['management'],
      comments: ['']
    });
  }
  async onAllowPaymentClick(application: any): Promise<void> {
    try {
      const consent = confirm('Are you sure?');
      if (consent) {
        const data_list = [];
        const data = {
          admission_id: application.admission_id,
          application_no: application.application_no,
          status: 'ready_to_pay',
          reason: application.details_form.value.quota_type + '-' + application.details_form.value.comments
        };
        data_list.push(data);

        const response = await this.restService.saveApplicantsWithGivenStatusBulk(data_list);
        if (response.success) {
          alert('Move to Ready to pay list successfully');
          this.onManagementQuotaCloseClick();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  // transfer course
  async onTransferClick(transfer_type: string): Promise<void> {
    try {
      this.transfer_type = transfer_type;
      this.transfer_prg_search_form = this.fb.group({
        application_no: [''],
      });
      if (this.transfer_prg_applicant_details) {
        this.transfer_prg_applicant_details = null;
      }
      JQueryHelper.openModal('#modal-transfer', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onTransferApplicationSearch(): Promise<void> {
    try {
      if (!this.transfer_prg_search_form.value.application_no) {
        return;
      }
      this.transfer_prg_search_form_loading = true;
      this.transfer_prg_applicant_details = null;
      const response = await this.restService.checkAndGetCoursesToTransfer(this.transfer_prg_search_form.value.application_no);
      if (response.success && response.data) {
        this.transfer_prg_applicant_details = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.transfer_prg_search_form_loading = false;
    }
  }

  async onTransferCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-transfer');
  }

  async onViewTransferEstimateClick(application: any): Promise<void> {
    for (const application_data of this.transfer_prg_applicant_details.applications) {
      application_data.show_detials = false;
      application_data.details_form = null;
    }


    application.show_detials = true;

    const response = await this.restService.getTransferCourseEstimate(
      this.transfer_prg_search_form.value.application_no, application.application_no);
    if (response.success) {
      application.details_form = response.data;
    }
  }

  async onConfirmTransferClick(application: any): Promise<void> {
    try {
      const consent = confirm('Are you sure?');
      if (consent) {

        const response = await this.restService.transferCourse(
          this.transfer_prg_search_form.value.application_no, application.application_no);
        if (response.success) {
          alert('Transfer submitted successfully');
          this.onTransferCloseClick();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  // cancel prgramme
  async onCancelPrgramme(): Promise<void> {
    try {
      const consent = confirm('Are you sure?');
      if (consent) {

        const response = await this.restService.cancelCourse(this.transfer_prg_search_form.value.application_no);
        if (response.success) {
          alert('Programme Cancelled successfully');
          this.onTransferCloseClick();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  // transfer
  async onTransferReportClick(): Promise<void> {
    //HTMLHelper.openLinkInNewTab(`/admin/admissions/reports/transfer?admission_id=${this.admission_id}&transfer_type=transfer`);
    window.open('/admin/admissions/reports/transfer?admission_id=' + this.admission_id + '&transfer_type=transfer', "_blank");
  }

  async onViewCollectionClick(): Promise<void> {
    const admission: any = this.admissions_list.find((c: any) => c._id === this.admission_search_form.value.admission_id);
    if (!admission) {
      return;
    }
    const qs = querystring.stringify({
      admission_id: this.admission_search_form.value.admission_id,
      finance_type: 'regular',
      admission_name: admission.name,
      admission_report: true
    });

    HTMLHelper.openLinkInNewTab(`/admin/accounts/fee-reports?${qs}`);
  }

  async getMultipleSelectedApplicants(): Promise<void> {
    try {
      const search_form = {
        admission_id: this.admission_search_form.value.admission_id,
      }
      this.multiple_selection_list_loading = true;
      JQueryHelper.openModal('#modal-multiple-selection', { keyboard: false, backdrop: 'static' });
      const response = await this.restService.getMultipleSelectedApplicants(search_form);
      if (response.success) {
        this.multiple_selection_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.multiple_selection_list_loading = false;
    }
  }

  async closeMultipleSelection() {
    JQueryHelper.closeModal('#modal-multiple-selection');
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
        console.log(this.programme_list);
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

}
