import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-manage-programmes',
  templateUrl: './manage-programmes.component.html',
  styleUrls: ['./manage-programmes.component.scss']
})
export class ManageProgrammesComponent implements OnInit {


  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;

  // Supporting master data

  programme_type_list: any[] = [];





  // Core datas

  programme_search_form: any;



  programme_list: any[] = [];
  programme_list_loading = false;

  programe: any | undefined = undefined;
  programme_save_loading = false;
  programme_delete_loading = false;

  programme_form: any;




  /// programme_revision
  programme_revision_list: any[] = [];
  programme_revision_list_loading = false;

  programme_revision: any | undefined = undefined;
  programme_revision_save_loading = false;
  programme_revision_delete_loading = false;

  programme_revision_form: any;


  // Programme Scheme
  saved_programme_scheme: any;
  programme_scheme_save_loading = false;
  programme_scheme_approval_loading = false;
  programme_scheme_delete_loading = false;

  programme_scheme_form: any[] = [];


  parts: any[] = [];
  part_course_types: any[] = [];


  programme_scheme_filter_form: any;

  programme_scheme_approved = false;



  // alied handed by
  programme_list_for_handled_by: any[] = [];

  // pam report
  pam_data: any;


  // part 5
  part5_search_form: any;


  part5_form: any;
  part5_form_delete_loading = false;

  part5_form_save_loading = false;

  // alied handed by
  programme_list_for_shadow_list: any[] = [];
  department_list: any[] = [];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getDepartmentCategoryList();
    // Build Filter Form
    this.buildFilterForm();

    this.getProgrammeListForShadowList();
    this.getDepartmentList();

  }

  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      finance_type: [''],
      programme_type_id: ['']
    });
  }


  async onAddClick(): Promise<void> {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(programe: any): Promise<void> {
    this.programe = programe;
    this.parts = this.commonEnums.getParts(this.programe.programme_type_id);
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }


      this.programme_save_loading = true;

      const programme_data = this.programme_form.value;

      if (!programme_data.programme_code) {
        alert('Please enter programe code');
        return;
      }

      if (programme_data.section_details && programme_data.section_details.length > 0) {
        for (let index = 0; index < programme_data.section_details.length; index++) {
          const element = programme_data.section_details[index];
          element.order = index;
        }
      }
      let save_respose;
      if (programme_data._id) {
        // update record
        save_respose = await this.restService.updateProgramme(programme_data.programme_code, programme_data);
      } else {
        // create record
        if (!programme_data.programme_type_id) {
          alert('Please enter programe type id');
          return;
        }
        if (!programme_data.finance_type) {
          alert('Please enter programe finance type');
          return;
        }
        if (!programme_data.programme_name) {
          alert('Please enter name');
          return;
        }
        if (!programme_data.department_id) {
          alert('Please choose department name');
          return;
        }
        if (!programme_data.short_name) {
          alert('Please enter short name');
          return;
        }
        save_respose = await this.restService.createProgramme(programme_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_save_loading = false;
    }
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      this.programme_delete_loading = true;

      const categgory_data = this.programme_form.value;
      if (!categgory_data.programme_code) {
        alert('Please enter programme_code');
      }


      const save_respose = await this.restService.deleteProgrammeById(categgory_data.programme_code);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.programme_delete_loading = false;
    }
  }




  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  async getFormDataList(): Promise<void> {
    try {
      const search_form_query = this.programme_search_form.value;
      if (!search_form_query.programme_type_id) {
        return;
      }
      if (!search_form_query.finance_type) {
        return;
      }
      this.prepareVariablesBeforeLoad();

      const service_response = await this.restService.searchProgrammes(search_form_query);
      if (service_response && service_response.success) {
        // fix data
        for (const data of service_response.data) {
          data.show_revision = false;
          if (data.finance_type === 'self_finance') {
            data.finance_type_display = 'Self Finance';
          } else if (data.finance_type === 'regular') {
            data.finance_type_display = 'Aided';
          }
        }
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.prepareVariablesAfterLoad();
    }
  }

  async getProgrammeListForShadowList(): Promise<void> {
    try {

      const query = {
        is_virtual: false,
      }
      const service_response = await this.restService.searchProgrammes(query);
      if (service_response && service_response.success) {
        // fix data
        for (const data of service_response.data) {
          data.show_revision = false;
          if (data.finance_type === 'self_finance') {
            data.finance_type_display = 'Self Finance';
          } else if (data.finance_type === 'regular') {
            data.finance_type_display = 'Aided';
          }
        }
        this.programme_list_for_shadow_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.prepareVariablesAfterLoad();
    }
  }


  prepareVariablesBeforeLoad(): void {
    this.programme_list = [];
    this.programme_list_loading = true;
  }
  prepareVariablesAfterLoad(): void {
    this.programme_list_loading = false;
  }

  resetForm(): void {
    this.programme_form = undefined;
  }
  buildForm(): void {

    if (!this.programe) {
      this.programe = {
        _id: '',
        programme_id: '',
        finance_type: '',
        programme_type_id: '',
        department_id: '', //new field
        programme_code: '',
        programme_name: '',
        short_name: '',
        started_date: '',
        discontinued: false,
        discontinued_date: '',
        description: '',
        is_virtual: false,
        shadow_programme_id: '',
        is_shadow: false,
        is_holding_common_paper: false,
        part_type: '',
        programme_category: '',
        section_details: []
      };
    }

    this.programme_form = this.fb.group({
      _id: [this.programe._id],
      programme_id: [this.programe.programme_id],
      finance_type: [this.programe.finance_type, [Validators.required]],
      programme_type_id: [this.programe.programme_type_id, [Validators.required]],
      department_id: [this.programe.department_id, [Validators.required]], //new field
      programme_code: [this.programe.programme_code, [Validators.required]],
      programme_name: [this.programe.programme_name, [Validators.required]],
      short_name: [this.programe.short_name, [Validators.required]],
      started_date: [this.programe.started_date],
      discontinued: [this.programe.discontinued],
      discontinued_date: [this.programe.discontinued_date],
      description: [this.programe.description, [Validators.required]],
      is_virtual: [this.programe.is_virtual],
      shadow_programme_id: [this.programe.shadow_programme_id],
      is_shadow: [this.programe.is_shadow],
      is_holding_common_paper: [this.programe.is_holding_common_paper],
      part_type: [this.programe.part_type],
      programme_category: [this.programe.programme_category],
      section_details: this.fb.array(this.buildRevisionSectionForm(this.programe.section_details))
    });
  }

  buildRevisionSectionForm(value_list: any[]): any[] {
    const value_to_return: any[] = [];
    if (!value_list) {
      value_list = [];
    }
    if (value_list.length > 0) {
      for (const value of value_list) {
        value_to_return.push(this.fb.group({
          _id: [value._id],
          order: [value.order],
          section_name: [value.section_name],
          roll_no_prefix: [value.roll_no_prefix],
          starting_no: [value.starting_no || 1],
          max_digits_count: [value.max_digits_count || 3],
        }));
      }
    } else {
      value_to_return.push(this.fb.group({
        _id: [''],
        order: [0],
        section_name: ['A'],
        roll_no_prefix: [''],
        starting_no: [1],
        max_digits_count: [3]
      }));
    }

    return value_to_return;

  }



  // Revision section
  async addNewSection(): Promise<void> {

    const form = this.fb.group({
      _id: [''],
      section_name: [''],
      roll_no_prefix: [''],
      starting_no: [1],
      max_digits_count: [3],
      order: [0],
    });


    (this.programme_form.get('section_details') as FormArray).push(form);
  }

  async deleteSection(id: string, index: number): Promise<void> {

    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      if (!id) {
        (this.programme_form.get('section_details') as FormArray).removeAt(index);
        return;
      }

      const resp = await this.restService.deleteProgrameSectionById(id);
      if (resp && resp.success) {
        (this.programme_form.get('section_details') as FormArray).removeAt(index);
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }


  }



  /// master data
  async getDepartmentCategoryList(): Promise<void> {
    try {
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }
  }

  async getDepartmentList(): Promise<void> {
    const search_form_query = {};
    const service_response = await this.restService.searchDepartments(search_form_query);
    if (service_response && service_response.success) {
      // fix data
      for (const data of service_response.data) {
        if (data.finance_type === 'self_finance') {
          data.finance_type_display = 'Self Finance';
        } else if (data.finance_type === 'regular') {
          data.finance_type_display = 'Aided';
        }
      }
      this.department_list = service_response.data;
      console.log(this.department_list);
    }
  }

  // Filter
  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'finance_type') {
      await this.getFormDataList();
    } else if (id === 'programme_type_id') {
      await this.getFormDataList();
    }
  }


  async onShowOrHideRevisionClick(programme: any): Promise<void> {
    if (programme.show_revision) {
      programme.show_revision = false;
      this.programme_revision_list = [];
    } else {
      for (const programme_item of this.programme_list) {
        programme_item.show_revision = false;
      }
      programme.show_revision = true;
      this.programe = programme;
      this.getProgrammeRevisionByProgrammeId(programme);
    }
  }


  async getProgrammeRevisionByProgrammeId(programme: any): Promise<void> {
    try {
      programme.programme_revision_list_loading = true;
      const service_response = await this.restService.findProgrameRevisionList(programme.programme_id);
      if (service_response && service_response.success) {
        this.programme_revision_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      programme.programme_revision_list_loading = false;
    }
  }



  async onAddRevisionClick(): Promise<void> {
    this.buildRevisionForm();
    JQueryHelper.openModal('#modal-details', { keyboard: false, backdrop: 'static' });
  }

  async onEditRevisionClick(programme_revision: any): Promise<void> {
    this.programme_revision = programme_revision;
    this.buildRevisionForm();
    JQueryHelper.openModal('#modal-details', { keyboard: false, backdrop: 'static' });
  }
  async onCloseRevisionClick(): Promise<void> {
    this.resetRevisionForm();
    JQueryHelper.closeModal('#modal-details');
  }

  resetRevisionForm(): void {
    this.programme_revision_form = undefined;
    this.programme_revision = undefined;
  }
  buildRevisionForm(): void {


    if (!this.programme_revision) {
      let programme_type = this.programme_type_list.find(c => c.programme_type_id === this.programe.programme_type_id);
      if (!programme_type) {
        programme_type = {};
      }
      this.programme_revision = {
        _id: '',
        revision_year: '',
        programme_id: this.programe.programme_id,
        duration: programme_type.duration,
        duration_type: 'YEAR',
        max_seats: programme_type.max_seats,
        no_of_semesters: programme_type.no_of_semesters
      };
    }

    if (!this.programme_revision.eligibility) {
      this.programme_revision.eligibility = {
        genders: [],
        age: 0,
        previous_subjects: []
      };
    }

    if (!this.programme_revision.eligibility.genders) {
      this.programme_revision.eligibility.genders = [];
    }
    if (!this.programme_revision.eligibility.previous_subjects) {
      this.programme_revision.eligibility.previous_subjects = [];
    }

    this.programme_revision_form = this.fb.group({
      _id: [this.programme_revision._id],
      revision_year: [this.programme_revision.revision_year],
      programme_id: [this.programme_revision.programme_id, [Validators.required]],
      duration: [this.programme_revision.duration, [Validators.required]],
      duration_type: [this.programme_revision.duration_type, [Validators.required]],
      max_seats: [this.programme_revision.max_seats, [Validators.required]],
      no_of_semesters: [this.programme_revision.no_of_semesters, [Validators.required]],
      eligibility: this.fb.group({
        genders: this.fb.array(this.buildStringArrayForm(this.programme_revision.eligibility.genders)),
        age: [this.programe.max_seats, [Validators.required]],
        previous_subjects: this.fb.array(this.buildStringArrayForm(this.programme_revision.eligibility.previous_subjects)),
      })

    });
  }



  buildStringArrayForm(value_list: string[]): any[] {
    const value_to_return: any[] = [];
    if (value_list && value_list.length > 0) {
      for (const value of value_list) {
        value_to_return.push(this.fb.control([value]));
      }
    }

    return value_to_return;

  }

  async onSaveRevisionClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }


      this.programme_revision_save_loading = true;

      const programme_revision_data = this.programme_revision_form.value;

      if (!programme_revision_data.revision_year) {
        alert('Please enter revision year');
        return;
      }

      let save_respose;
      if (programme_revision_data._id) {
        // update record
        save_respose = await this.restService.updateProgrameRevision(
          programme_revision_data.programme_id,
          programme_revision_data.revision_year,
          programme_revision_data);
      } else {
        // create record
        save_respose = await this.restService.createProgrameRevision(programme_revision_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.getProgrammeRevisionByProgrammeId(this.programe);
        this.onCloseRevisionClick();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_revision_save_loading = false;
    }
  }

  async onDeleteRevisionClick(revision_id: string): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }


      const save_respose = await this.restService.deleteProgrameRevisionById(revision_id);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.getProgrammeRevisionByProgrammeId(this.programe);
        this.onCloseRevisionClick();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.programme_delete_loading = false;
    }
  }



  // Programme Scheme
  async onAddProgrammeSchemeClick(programe: any, programme_revision: any): Promise<void> {
    if (this.programe.programme_type_id === 'CC') {
      alert('Community college coming soon');
      return;
    }

    if (this.programe.programme_type_id === 'UG') {
      await this.loadProgrammes();
    }


    this.programme_revision = programme_revision;
    this.programe = programe;
    this.parts = this.commonEnums.getParts(this.programe.programme_type_id);
    this.buildProgrammeFilterForm();
    JQueryHelper.openModal('#modal-scheme', { keyboard: false, backdrop: 'static' });
  }

  fillParts(): void {
    if (this.programme_form && this.programme_form.value.programme_type_id) {
      this.parts = this.commonEnums.getParts(this.programme_form.value.programme_type_id);
    }
  }

  onSemesterChange(data: any): void {
    const consent = true; // confirm('Are you sure ? make sure all changes are saved before switching!!');
    if (consent) {
      if (this.programme_scheme_filter_form.value.semester) {
        this.buildProgrammeSchemeForm(this.programme_revision);
      } else {
        this.resetProgrammeSchemeForm();
      }
    }
  }

  resetProgrammeSchemeForm(): void {
    this.programme_scheme_form[this.programme_scheme_filter_form.value.semester] = undefined;
  }

  resetFullProgrammeSchemeForm(): void {
    this.programme_scheme_form = [];
    this.saved_programme_scheme = undefined;
    this.parts = [];
    this.programme_scheme_approved = false;
  }

  buildProgrammeFilterForm(): void {
    this.programme_scheme_filter_form = this.fb.group({
      semester: ['']
    });
  }

  async buildProgrammeSchemeForm(programme_revision: any): Promise<void> {
    const search_item = {
      programme_id: this.programe.programme_id,
      revision_id: programme_revision._id,
      semester: Number(this.programme_scheme_filter_form.value.semester)
    };

    const saved_items = await this.restService.searchProgrameSchemeHeader(search_item);
    if (saved_items && saved_items.data) {
      this.saved_programme_scheme = saved_items.data;
      this.programme_scheme_approved = this.saved_programme_scheme.approved;
    }
    let semester_data: any[] = [];
    if (this.saved_programme_scheme
      && this.saved_programme_scheme.programme_schemes
      && this.saved_programme_scheme.programme_schemes.length > 0) {
      semester_data = this.saved_programme_scheme.programme_schemes
        .filter((c: any) => c.semester === this.programme_scheme_filter_form.value.semester);
    }

    this.programme_scheme_form[this.programme_scheme_filter_form.value.semester] = this.fb.group({
      _id: [programme_revision._id],
      semester: [this.programme_scheme_filter_form.value.semester],
      semesters: this.fb.array(this.buildSemesterArrayForm(semester_data, this.programe.programme_id, programme_revision._id))
    });

  }


  buildSemesterArrayForm(semester_data: any[], programme_id: string, revision_id: string): any[] {
    const value_to_return: any[] = [];
    if (!semester_data) {
      semester_data = [];
    }
    if (semester_data.length > 0) {
      for (const course_item of semester_data) {
        const course_types = this.getCourseTypesByPart(course_item.part);
        value_to_return.push(this.fb.group({
          _id: [course_item._id],
          is_external: [course_item.is_external],
          programme_id: [programme_id],
          revision_id: [revision_id],
          semester: [this.programme_scheme_filter_form.value.semester],
          part: [course_item.part],
          course_types: [course_types],
          course_type: [course_item.course_type],
          description: [course_item.description],
          courses: this.fb.array(this.buildCourseItemArrayForm(course_item.courses)),
          weekly_lecture_hours: [course_item.weekly_lecture_hours],
          weekly_tutorial_hours: [course_item.weekly_tutorial_hours],
          weekly_practical_hours: [course_item.weekly_practical_hours],
          credits: [course_item.credits],
          exam_duration: [course_item.exam_duration],
          max_internal_mark: [course_item.max_internal_mark],
          max_external_mark: [course_item.max_external_mark],
          total_mark: [course_item.total_mark],
          programme_category: [course_item.programme_category],
        }));
      }
    } else {
      value_to_return.push(this.fb.group({
        _id: [''],
        is_external: [false],
        programme_id: [programme_id],
        revision_id: [revision_id],
        semester: [this.programme_scheme_filter_form.value.semester],
        part: [''],
        course_types: [],
        course_type: [''],
        description: [''],
        courses: this.fb.array(this.buildCourseItemArrayForm([])),
        weekly_lecture_hours: [0],
        weekly_tutorial_hours: [0],
        weekly_practical_hours: [0],
        credits: [0],
        exam_duration: [0],
        max_internal_mark: [50],
        max_external_mark: [50],
        total_mark: [100],
        programme_category: [''],
      }));
    }


    return value_to_return;

  }

  buildCourseItemArrayForm(course_items: any[]): any[] {
    const value_to_return: any[] = [];
    if (!course_items) {
      course_items = [];
    }
    if (course_items.length > 0) {
      for (const course_item of course_items) {
        value_to_return.push(this.fb.group({
          course_code: [course_item.course_code],
          course_name: [course_item.course_name],
          offered_by: [course_item.offered_by],
          offered_by_type: [course_item.offered_by_type],
          syllabus_managed_by: [course_item.syllabus_managed_by],
          usage_type: [course_item.usage_type],
        }));
      }
    } else {
      value_to_return.push(this.fb.group({
        course_code: [''],
        course_name: [''],
        offered_by: [''],
        offered_by_type: [''],
        syllabus_managed_by: [''],
        usage_type: [''],
      }));
    }


    return value_to_return;

  }

  onAddNewProgrammeRow(): void {

    const form = this.fb.group({
      _id: [''],
      is_external: [false],
      programme_id: [this.programe.programme_id],
      revision_id: [this.programme_revision._id],
      semester: [this.programme_scheme_filter_form.value.semester],
      part: [''],
      course_types: [''],
      course_type: [''],
      description: [''],
      courses: this.fb.array(this.buildCourseItemArrayForm([])),
      weekly_lecture_hours: [0],
      weekly_tutorial_hours: [0],
      weekly_practical_hours: [0],
      credits: [0],
      exam_duration: [0],
      max_internal_mark: [50],
      max_external_mark: [50],
      total_mark: [100],
      programme_category: [''],
    });
    (this.programme_scheme_form[this.programme_scheme_filter_form.value.semester].get('semesters') as FormArray).push(form);
  }

  onAddNewCourseRow(semrow: FormGroup): void {
    const form = this.fb.group({
      course_code: [''],
      course_name: [''],
    });
    (semrow.get('courses') as FormArray).push(form);
  }

  onDeleteCourseRow(semrow: FormGroup, index: number): void {
    (semrow.get('courses') as FormArray).removeAt(index);
  }

  async valueChanged(data: any): Promise<void> {
    console.log(data);
  }

  getTotal(max_internal_mark: number, max_external_mark: number): number {
    try {
      return Number(max_internal_mark) + Number(max_external_mark);
    } catch (error) {

    }
    return 0;
  }


  async onPartFilterSelectChange(form: FormGroup, event: any): Promise<void> {
    const course_types = this.getCourseTypesByPart(form.value.part);
    if (course_types && course_types.length > 0) {
      form.patchValue({
        course_type: course_types[0].id,
        course_types
      });
    } else {
      form.patchValue({
        course_type: '',
        course_types: null
      });
    }
  }

  getCourseTypesByPart(part: string): any[] | null {
    const part_obj = this.parts.find(c => c.id === part);
    if (part_obj && part_obj.course_types && part_obj.course_types.length > 0) {
      return part_obj.course_types;
    }
    return null;
  }

  isMultiCourseAllowed(part: string): boolean {
    const part_obj = this.parts.find(c => c.id === part);
    if (part_obj && part_obj.allow_multi_course) {
      return true;
    }
    return false;
  }



  async onSaveProgrammeSchemClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      const programme_scheme_form_data = this.programme_scheme_form[this.programme_scheme_filter_form.value.semester].value;

      console.log(programme_scheme_form_data);
      let has_error = false;
      let i = 0;
      let part_order: any = {};
      for (const semester of programme_scheme_form_data.semesters) {
        if (semester.courses && semester.courses.length > 0) {
          for (const course of semester.courses) {
            if (course.course_code) {
              course.course_code = course.course_code.trim().toUpperCase();
            }
            if (course.course_name) {
              course.course_name = course.course_name.trim().toUpperCase();
            }
          }
          semester.courses = semester.courses.filter((c: any) => c.course_code);

          if (semester.courses && semester.courses.length === 0) {
            alert('Please enter course details in row ' + (i + 1));
            has_error = true;
            break;
          }
        } else {
          alert('Please enter course details in row ' + (i + 1));
          has_error = true;
          break;
        }


        try {
          semester.weekly_lecture_hours = parseInt(semester.weekly_lecture_hours, 0);
        } catch (error) {
          alert('Please enter valid Weekly lecture hour in row ' + (i + 1));
          has_error = true;
          break;
        }
        try {
          semester.weekly_tutorial_hours = parseInt(semester.weekly_tutorial_hours, 0);
        } catch (error) {
          alert('Please enter valid Weekly tutorial hour in row ' + (i + 1));
          has_error = true;
          break;
        }
        try {
          semester.weekly_practical_hours = parseInt(semester.weekly_practical_hours, 0);
        } catch (error) {
          alert('Please enter valid Weekly practical hour in row ' + (i + 1));
          has_error = true;
          break;
        }
        try {
          semester.credits = parseInt(semester.credits, 0);
        } catch (error) {
          alert('Please enter valid credits in row ' + (i + 1));
          has_error = true;
          break;
        }
        try {
          semester.exam_duration = parseInt(semester.exam_duration, 0);
        } catch (error) {
          alert('Please enter valid exam duration in row ' + (i + 1));
          has_error = true;
          break;
        }
        try {
          semester.max_internal_mark = parseInt(semester.max_internal_mark, 0);
        } catch (error) {
          alert('Please enter valid internal mark in row ' + (i + 1));
          has_error = true;
          break;
        }
        try {
          semester.max_external_mark = parseInt(semester.max_external_mark, 0);
        } catch (error) {
          alert('Please enter valid external mark in row ' + (i + 1));
          has_error = true;
          break;
        }
        semester.total_mark = semester.max_internal_mark + semester.max_external_mark;
        if (semester.total_mark > 100) {
          alert('Please enter valid internal and external mark in row ' + (i + 1) + ', Total mark must not exceed 100');
          has_error = true;
          break;
        }
        if (part_order[semester.part]) {
          part_order[semester.part] = part_order[semester.part] + 1;
        } else {
          part_order[semester.part] = 1;
        }
        semester.order = part_order[semester.part];
        i++;
      }

      part_order = {};
      if (has_error) {
        return;
      }
      this.programme_scheme_save_loading = true;
      const programme_schemes = programme_scheme_form_data.semesters.filter(
        (c: any) => c.is_external !== true
          || c.is_external === undefined
          || c.is_external === null);
      const data_to_save = {
        programme_id: this.programe.programme_id,
        revision_id: this.programme_revision._id,
        programme_schemes
      };
      const save_respose = await this.restService.createProgrameSchemeHeader(data_to_save);
      if (save_respose.success) {
        alert('Saved Successfully');
        await this.buildProgrammeSchemeForm(this.programme_revision);
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_scheme_save_loading = false;
    }
  }

  async onCloseSchemeClick(): Promise<void> {
    this.resetFullProgrammeSchemeForm();
    JQueryHelper.closeModal('#modal-scheme');
  }


  async onDeleteProgrameClick(id: string, index: number): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      if (!id) {
        // remove local data
        const form: FormGroup = this.programme_scheme_form[this.programme_scheme_filter_form.value.semester];
        (form.get('semesters') as FormArray).removeAt(index);
        return;
      }

      this.programme_scheme_delete_loading = true;

      const save_respose = await this.restService.deleteProgrameScheme(id);
      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.buildProgrammeSchemeForm(this.programme_revision);
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.programme_scheme_delete_loading = false;
    }
  }


  async onApprovalClick(): Promise<void> {
    try {
      const n1 = Math.floor((Math.random() * 10) + 1);
      const n2 = Math.floor((Math.random() * 10) + 1);
      const value =
        prompt('Are you sure do you want to approve? Once it is approved you cant change it. \nSolve the math to save ' + n1 + '+' + n2 + ' = ? ');
      try {
        if (!value || Number(value) !== (n1 + n2)) {
          alert('Wrong value entered');
          return;
        }
      } catch (error) {
        alert('Wrong value entered');
        return;
      }




      this.programme_scheme_approval_loading = true;

      const approval_data = {
        programme_id: this.programe.programme_id,
        revision_id: this.programme_revision._id,
      };

      const save_respose = await this.restService.approveProgrameSchemeHeader(approval_data);
      if (save_respose.success) {
        alert('Approved Successfully');
        await this.buildProgrammeSchemeForm(this.programme_revision);
        this.onCloseSchemeClick();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));

    } finally {
      this.programme_scheme_approval_loading = false;
    }
  }

  async onUpApprovalClick(): Promise<void> {
    try {
      const n1 = Math.floor((Math.random() * 10) + 1);
      const n2 = Math.floor((Math.random() * 10) + 1);
      const value =
        prompt('Are you sure do you want to un approve? \nSolve the math to save ' + n1 + '+' + n2 + ' = ? ');
      try {
        if (!value || Number(value) !== (n1 + n2)) {
          alert('Wrong value entered');
          return;
        }
      } catch (error) {
        alert('Wrong value entered');
        return;
      }




      this.programme_scheme_approval_loading = true;

      const approval_data = {
        programme_id: this.programe.programme_id,
        revision_id: this.programme_revision._id,
      };

      const save_respose = await this.restService.unApproveProgrameSchemeHeader(approval_data);
      if (save_respose.success) {
        alert('UnApproved Successfully');
        await this.buildProgrammeSchemeForm(this.programme_revision);
        this.onCloseSchemeClick();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));

    } finally {
      this.programme_scheme_approval_loading = false;
    }
  }

  onAssignStaffClick(programe: any, programme_revision: any): void {
    this.router.navigate(['/admin/staff/assign-staff'], {
      queryParams: {
        programme_id: programe.programme_id,
        revision_id: programme_revision._id,
        no_of_semesters: programme_revision.no_of_semesters,
      }
    });
  }

  onAssignStudentsClick(programe: any, programme_revision: any): void {
    this.router.navigate(['/admin/staff/assign-student'], {
      queryParams: {
        programme_id: programe.programme_id,
        revision_id: programme_revision._id,
        no_of_semesters: programme_revision.no_of_semesters,
      }
    });
  }


  // PAM Report
  async onPAMReportClick(programe: any, programme_revision: any): Promise<void> {

    try {
      const response = await this.restService.getPAMReport(programe.programme_id, programme_revision.revision_year);
      if (response.success && response.data) {
        this.pam_data = response.data;
        JQueryHelper.openModal('#modal-pamreport', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }


  }

  async onClosePAMReportClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-pamreport');
  }

  isDisabled(data: any): boolean {

    if (data.is_external) {
      return true;
    }
    if (this.programme_scheme_approved) {
      return true;
    }

    return false;
  }



  async loadProgrammes(): Promise<void> {
    try {
      if (this.programme_list_for_handled_by && this.programme_list_for_handled_by.length > 0) {
        return;
      }
      const search_form_query = this.programme_search_form.value;
      if (!search_form_query.programme_type_id) {
        return;
      }
      const search_query = {
        programme_type_id: search_form_query.programme_type_id
      };

      const service_response = await this.restService.searchProgrammes(search_query);
      if (service_response && service_response.success) {
        // fix data
        for (const data of service_response.data) {
          data.show_revision = false;
          if (data.finance_type === 'self_finance') {
            data.finance_type_display = 'SF';
          } else if (data.finance_type === 'regular') {
            data.finance_type_display = 'Aided';
          }
        }
        this.programme_list_for_handled_by = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }

  }


}
