import { NavigationService } from './../../../components/navigation/navigation.service';
import { NotificationService } from './../../../components/notification/notification.service';
import { Router } from '@angular/router';
import { Hostel } from './hostelModel';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as fonts from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-hostel-management',
  templateUrl: './hostel-management.component.html',
  styleUrls: ['./hostel-management.component.scss']
})
export class HostelManagementComponent implements OnInit {
  // eslint-disable-next-line no-useless-constructor
  constructor(private restService: AdminservicesService,
    private router: Router,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { }

  private hostels: Hostel[] = [];
  fonts = fonts;

  hostelDataHandler = (hostels: Hostel[]): void => {
    this.hostels = hostels;
  };

  hostelDataErrorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  hostelDeleteHandler = (result: any, id: string = ''): void => {
    // Remove the deleted one from UI
    if (result && id) {
      this.notificationService.setNotifcation({ message: 'Deleted hostel successfully.', type: 'Success' });
      this.hostels = this.hostels.filter(hostel => hostel.id !== id);
    }
  };

  public get hostelData(): Hostel[] {
    return this.hostels;
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    const navItem = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.navigationService.setNavigation([navItem]);
    this.restService.hostelManagementService.getHostels().subscribe(this.hostelDataHandler, this.hostelDataErrorHandler);
  }

  onAdd(): void {
    this.router.navigate(['admin/hostel/hostel-management/create']);
  }

  onEdit(hostel: Hostel): void {
    this.router.navigate([`admin/hostel/hostel-management/update/${hostel.id}`]);
  }

  onDelete(hostel: Hostel): void {
    if (hostel.id) {
      this.restService.hostelManagementService.deleteHostel(hostel.id).subscribe(data => this.hostelDeleteHandler(data, hostel.id));
    }
  }
}
