import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DOCUMENT } from '@angular/common';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';

@Component({
  selector: 'app-terminal-exam-deviation',
  templateUrl: './terminal-exam-deviation.component.html',
  styleUrls: ['./terminal-exam-deviation.component.scss']
})
export class TerminalExamDeviationComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;
  title = 'Exam Deviation';
  comparision_form: FormGroup;
  valuation_obj = {
    exam_id: '',
    res_student_info : [] as StudentValuationMarksInfo[]
  }
  exam_setting_form: FormGroup = {} as FormGroup;
  student_search_form: any;
  available_programme_list: any[] = [];
  course_list_loading: boolean = false;
  course_list: ExamFinalComparisionResponse[] = [] as ExamFinalComparisionResponse[];
  student_valudation: ExamFinalComparisionResponse = {} as ExamFinalComparisionResponse;
  appearance_types: { key: string, text: string }[] = [{ key: "normal", text: "Normal" }, { key: "ARREAR", text: "Arrear" },
  { key: "REPEAT", text: "Repeat Semester" }];
  valuation_types: { key: string, text: string }[] = [{ key: "INTERNAL", text: "Internal Examiner" }, { key: "EXTERNAL", text: "External Examiner" }];
  student_list_loading: boolean[] = [];
  student: any = undefined;
  score_card_form: FormGroup = undefined;
  setting_save_loading: boolean = false;
  setting_id: string = "";
  category_list: any[] = [];
  category_list_loading = false;
  programme_list_loading = false;
  valuation_nos: number[] = [1, 2, 3, 4, 5];
  programme_list: any[] = [];
  sections: any = [];
  exam_id: string
  score_sheet_data_loading: boolean[] = [];
  valuations_data: ValuationsInfo[] = [];

  constructor(private fb: FormBuilder, private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.buildSearchForm();
    this.getProgrammeTypeList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  buildSearchForm(): void {
    this.student_search_form = this.fb.group({
      month: [''],
      year: [''],
      programme_type_id: [''],
      finance_type: [''],
      enrolled_year: [''],
      semester: [''],
      appearance_type: [''],
      course_code: [''],
      programme_id: [''],
      section: [''],
    });
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id == "programme_id") {
      this.sections = [];
      this.student_search_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.student_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
      if (this.sections.length == 1) {
        this.student_search_form.get('section').setValue(this.sections[0].section_name);
      }
    }

    if (id === 'programme_type_id' || id === 'finance_type') {
      this.student_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.student_search_form);
    }
  }

  async onSearchClick() {

    if (!this.student_search_form.value.programme_type_id) {
      alert("Please select Programme Type"); return;
    }
    if (!this.student_search_form.value.month) {
      alert("Please select month"); return;
    }
    if (!this.student_search_form.value.year) {
      alert("Please select year"); return;
    }
    if (!this.student_search_form.value.enrolled_year) {
      alert("Please select Enrollment Year"); return;
    }
    if (!this.student_search_form.value.semester) {
      alert("Please select Semester"); return;
    }
    if (!this.student_search_form.value.appearance_type) {
      alert("Please select Appearance Type"); return;
    }
    if (!this.student_search_form.value.programme_id) {
      alert("Please select Programme"); return;
    }
    if (!this.student_search_form.value.section) {
      alert("Please select section"); return;
    }

    this.course_list = [];
    this.course_list_loading = true;
    try {
      const search_qry = {
        ...this.student_search_form.value,
        month: parseInt(this.student_search_form.value.month)
      }
      const service_response = await this.restService.getCompareOfExamValuations(search_qry);
      if (service_response && service_response.success) {
        this.course_list = service_response.data;
        let i = 0;
        this.course_list.forEach(course => {
          course.is_opened = false;
          this.student_list_loading[i] = false;
          this.score_sheet_data_loading[i] = false;
          i++;
        })
        console.log(this.course_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.course_list_loading = false;
    }
  }

  selectAllCheckBox() {

  }

  moveToNextValuate() {
    // Check whether all checkboxes are selected or not
   console.log(this.comparision_form.value.student_info);
   this.valuation_obj.res_student_info = this.comparision_form.value.student_info.filter(st => st.check == true);
   if(this.valuation_obj.res_student_info.length == 0) {
    alert("Please choose atleast one student info"); return;
   }
   this.valuation_obj.exam_id = this.comparision_form.value.exam_id;
   this.buildExamSettingForm();
   JQueryHelper.openModal('#valuation-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  displayStudents(course: ExamFinalComparisionResponse, i: any) {
    course.is_opened = true;
    this.student_list_loading[i] = true;
    this.student_valudation = {} as ExamFinalComparisionResponse;
    this.student_valudation.is_opened = false;
    try {
      this.valuations_data = [];
      this.exam_id = course.exam_id;
      this.student_list_loading[i] = true;
      this.student_valudation = course;
      this.valuations_data = course.valuations;
      this.student_valudation.is_opened = true;

      this.comparision_form = this.fb.group({
        exam_id: [this.exam_id, Validators.required],
        student_info: this.generatingStudentInfo()
      });

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_list_loading[i] = false;
    }
  }

  generatingStudentInfo() {
    let student_info = this.valuations_data[0]?.student_valuation_marks
    let student_array: FormArray = this.fb.array([]);
    for (var i = 0; i < student_info.length; i++) {
      let difference = this.differenceOfStudentMarks(this.valuations_data, i)
      let student_info_grp = this.fb.group({
        roll_no: student_info[i].student_roll_no,
        difference: difference,
        check: (difference > 0) ? [false] : [{value: '', disabled: true}]
      });
      student_array.push(student_info_grp)
    }
    return student_array;
  }

  async evaluatedInChanged(event: any, i: number) {
    this.available_programme_list[i] = [];
    var select_element = event.target as HTMLSelectElement;
    if (select_element.value == "") {
      return;
    }

    if (!this.student_search_form.value.enrolled_year) {
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      alert("Please enter Enrollment Year"); return;
    }
    if (!this.student_search_form.value.programme_type_id) {
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      alert("Please enter Programme Type"); return;
    }
    if (!this.student_search_form.value.semester) {
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      alert("Please enter Semester"); return;
    }
    var search_query = {
      course_code: this.student_valudation.course_code,
      enrolled_year: this.student_search_form.value.enrolled_year,
      programme_type_id: this.student_search_form.value.programme_type_id,
      semester: this.student_search_form.value.semester
    };
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    if (select_element.value == 'EXTERNAL') {
      form_row.addControl('roll_no', new FormControl('', [Validators.required]));
    }
    else {
      if (form_row?.contains('roll_no')) {
        form_row?.removeControl('roll_no');
      }
    }
    //form_row.get('staff_course_map_id').setValue("");
    //form_row.get('roll_no').setValue("");
    form_row.get('staff_list').setValue("");
    form_row.get('programme_id').setValue("");
    form_row.get('section').setValue("");
    form_row.get('virtual_section').setValue("");

    try {
      const service_response = await this.restService.searchStaffCourseMap(search_query); //v2
      if (service_response && service_response.success) {
        const res_data: any[] = service_response.data.data;
        res_data.forEach(programme => {
          this.available_programme_list[i].push({
            roll_no: programme.roll_no,
            staff_course_map_id: programme._id,
            class_taking_programme_id: programme.class_taking_programme_id,
            class_taking_section: programme.class_taking_section,
            virtual_section: programme.virtual_section,
            staff: programme.staff,
            staff_name: this.getStaffName(programme.staff, programme.roll_no)
          })
        })
        console.log(this.available_programme_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }
  }

  deleteValuator(index: number) {
    const consent = confirm("Are you sure want to delete this Valuation?");
    if (!consent) return;
    ((this.exam_setting_form as FormGroup).get('valuations') as FormArray).removeAt(index);
  }

  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  onSave(): void {
    this.addItem();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.exam_setting_form.value;

      var valuations: any[] = [];
      valuations = form_data.valuations;
      for (var i = 0; i < valuations.length; i++) {
        /* valuations[i].start_time = new Date(valuations[i].start_time).toISOString();*/
        valuations[i].end_time = new Date(valuations[i].end_time).toISOString();
        if (valuations[i].virtual_section && valuations[i].virtual_section != '') {
          delete valuations[i].programme_id;
          delete valuations[i].section;
        }
        else {
          delete valuations[i].virtual_section;
        }
        var staff_list: any[] = valuations[i].staff_list;
        var staff_obj_arr: any[] = [];
        staff_list.forEach(staff => {
          var staff_obj = {
            roll_no: staff.roll_no,
            staff_course_map_id: staff.staff_course_map_id
          };
          staff_obj_arr.push(staff_obj);
        });
        valuations[i].staff_list = staff_obj_arr;
      }
      let update_respose;
      form_data.valuations = valuations;
      form_data.student_info = this.valuation_obj.res_student_info;
      console.log(form_data);

      //Move to next valuation
      this.setting_save_loading = true;
      update_respose = await this.restService.applyThirdValuations(form_data);
      if(update_respose.success && update_respose.data) {
        await this.onSearchClick();
        alert('Exam Data Saved Successfully');
      }
      else {
        alert("Error! Could not create Exam Data");
      }
      this.onSearchClick();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      console.log(error);
    }
    finally {
      this.setting_save_loading = false;
    }
  }

  canDisable(): boolean {
    return this.exam_setting_form.status !== 'VALID';
  }

  pgmChanged(event, i) {
    var select_element = event;
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    console.log(form_row);
    var pgm = this.available_programme_list[i].find(x => x.staff_course_map_id == select_element[0]?.staff_course_map_id);
    console.log(pgm);
    /* if (form_row.get('evaluated_in').value == 'INTERNAL') {
      form_row.get('roll_no').setValue(pgm.roll_no);
    }
    else {
      form_row.get('roll_no').setValue("");
    } */
    if (pgm.virtual_section && pgm.virtual_section != '') {
      form_row.get('virtual_section').setValue(pgm.virtual_section);
      form_row.get('programme_id').setValue("");
      form_row.get('section').setValue("");
    }
    else {
      form_row.get('programme_id').setValue(pgm.class_taking_programme_id);
      form_row.get('section').setValue(pgm.class_taking_section);
      form_row.get('virtual_section').setValue("");
    }
  }

  addValuator() {
    ((this.exam_setting_form as FormGroup).get('valuations') as FormArray).push(this.fb.group({
      _id: [""],
      sub: [""],
      evaluated_in: ["", [Validators.required]],
      valuation_no: [""],
      //staff_course_map_id: ["", [Validators.required]],
      programme_id: [""],
      section: [""],
      virtual_section: [""],
      staff_list: [""],
      //roll_no: ["", [Validators.required]],
      //start_time: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      end_time: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      stage: [""]
      //completed: [""],
    }));
  }

  reset(): void {
    this.exam_setting_form.reset();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#valuation-modal-popup');
  }

  getProgrammeDisplay(programme) {
    if (programme.virtual_section && programme.virtual_section != '') {
      return programme.virtual_section + " (" + programme.staff.given_name + " " + programme.staff.middle_name + "-" + programme.roll_no + ")";
    }
    return this.getProgrammeName(programme.class_taking_programme_id) + " - " + programme.class_taking_section + " (" + programme.staff.given_name + " " + programme.staff.middle_name + "-" + programme.roll_no + ")";
  }

  getProgrammeName(class_taking_programme_id: string) {
    return this.programme_list.find(x => x.programme_id == class_taking_programme_id)?.display_name;
  }

  getStaffName(staff, roll_no) {
    console.log(staff.given_name + " " + staff.middle_name + "-" + roll_no);
    return staff.given_name + " " + staff.middle_name + "-" + roll_no;
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  closeStudents(student, i) {
    student.is_opened = false;
    this.student_valudation.is_opened = false;
    this.student_valudation = {} as ExamFinalComparisionResponse;
    this.valuations_data = [];
  }

  differenceOfStudentMarks(valuations, j) {
    let sorting_arr = [];
    let temp_marks = 0;
    for(let  valuate of valuations){
      if(valuate.student_valuation_marks[j]?.total_mark != undefined) {
        sorting_arr.push(valuate.student_valuation_marks[j]?.total_mark);
        temp_marks = valuate.student_valuation_marks[j]?.total_mark - temp_marks;
      } else {
        sorting_arr.push(0);
        temp_marks = 0 - temp_marks;
      }

    }
    let res_arr = sorting_arr.sort((a,b) => b - a);
    if(res_arr.length <= 1) {
      return res_arr[0] ? res_arr[0] : 0;
    }
    let average = 0;
    if(res_arr[0] == 0 || res_arr[1] == 0) {
      average = (res_arr[0] + res_arr[1]) / 1
    } else {
      average = (res_arr[0] + res_arr[1]) / 2;
    }
    return Math.round(average);
  }

  buildExamSettingForm() {
    this.exam_setting_form = this.fb.group({
      exam_id: [this.valuation_obj.exam_id],
      valuations: this.fb.array([this.buildValuationsForm()]),
    });
  }

  //Return new exam setting for UI
  examSetting(): FormArray {
    return this.exam_setting_form.get('valuations') as FormArray;
  }

  valuationChanged(event, i) {
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    var select_element = event.target as HTMLSelectElement;
    if (select_element.value == "1" || select_element.value == "2") {
      form_row.get('stage').setValue("STARTED");
    }
    else {
      form_row.get('stage').setValue("NOT_STARTED");
    }
  }

  buildValuationsForm(): any {
    return this.fb.group({
      _id: [''],
      sub: [''],
      evaluated_in: ['', [Validators.required]],
      programme_id: [''],
      section: [''],
      virtual_section: [''],
      valuation_no: [''],
      end_time: ['', [Validators.required]],
      staff_list: [[]],
      stage: ['']
    })
  }

  showExamSettingModal() {
    if(this.valuation_obj.exam_id && this.valuation_obj.res_student_info.length > 0) {
      return true;
    }
    return false;
  }

}

interface ExamFinalComparisionResponse {
  exam_id: string;
  course_code: string;
  course_name: string;
  is_opened: boolean;
  valuations: ValuationsInfo[]
}

interface ValuationsInfo {
   valuation_no: number;
   evaluated_in: string;
   staff_sub: string;
   staff_name: string;
   student_valuation_marks: StudentValuationMarksInfo[]
}

export interface StudentValuationMarksInfo {
  student_name: string;
  student_roll_no: string;
  total_mark: number;
}
