import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-department-category',
  templateUrl: './department-category.component.html',
  styleUrls: ['./department-category.component.scss']
})
export class DepartmentCategoryComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;

  category_list: any[] = [];
  category_list_loading = false;

  category: any | undefined = undefined;
  category_save_loading = false;
  category_delete_loading = false;

  category_form: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getFormDataList();
  }

  async onAddClick(): Promise<void> {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(category: any): Promise<void> {
    this.category = category;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onSaveClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.category_save_loading = true;

      const categgory_data = this.category_form.value;

      if (!categgory_data.department_category_id) {
        alert('Please enter department categoryid');
        return;
      }

      let save_respose;
      if (categgory_data._id) {
        // update record
        save_respose = await this.restService.updateDepartmentCategory(categgory_data.department_category_id, categgory_data);
      } else {
        // create record
        if (!categgory_data.name) {
          alert('Please enter name');
          return;
        }
        if (!categgory_data.short_name) {
          alert('Please enter short name');
          return;
        }
        save_respose = await this.restService.createDepartmentCategory(categgory_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.category_save_loading = false;
    }
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      this.category_delete_loading = true;

      const categgory_data = this.category_form.value;
      if (!categgory_data.department_category_id) {
        alert('Please enter department categoryid');
      }


      const save_respose = await this.restService.deleteDepartmentCategoryById(categgory_data.department_category_id);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.category_delete_loading = false;
    }
  }




  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  async getFormDataList(): Promise<void> {
    try {
      this.prepareVariablesBeforeLoad();
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.prepareVariablesAfterLoad();
    }
  }


  prepareVariablesBeforeLoad(): void {
    this.category_list = [];
    this.category_list_loading = true;
  }
  prepareVariablesAfterLoad(): void {
    this.category_list_loading = false;
  }

  resetForm(): void {
    this.category_form = undefined;
    this.category = undefined;
  }
  buildForm(): void {

    if (!this.category) {
      this.category = {
        _id: '',
        department_category_id: '',
        name: '',
        short_name: '',
        description: '',
      };
    }

    this.category_form = this.fb.group({
      _id: [this.category._id],
      department_category_id: [this.category.department_category_id, [Validators.required]],
      name: [this.category.name, [Validators.required]],
      short_name: [this.category.short_name, [Validators.required]],
      description: [this.category.description, [Validators.required]]
    });
  }

}
