<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Transport Routes</p>
                    </div>

                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Add
                                New Route</button>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 40%;">Route</th>
                                        <th style="width: 35%;">Amount</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="routes_list.length>0">
                                        <ng-container
                                            *ngFor="let route of routes_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{route.route_name | titlecase}}</td>
                                                <td>{{route.amount}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(route)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!routes_list_loading && routes_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="routes_list_loading && routes_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Route Settings Large Modal -->
        <div class="modal" id="collection-modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="route_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Transport Route Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="route_form" class="form-horizontal">


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Route
                                                Name</label>
                                            <input type="text" class="form-control" formControlName="route_name"
                                                placeholder="Enter Unique Route Name">
                                            <div *ngIf="isInvalid('route_name')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Route Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Route
                                                Fee Charges</label>
                                            <input type="text" class="form-control" formControlName="amount"
                                                placeholder="Enter Route Fees Charges">
                                            <div *ngIf="isInvalid('amount')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Route Fee Charges is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                        Description</label>
                                    <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                                        formControlName="description">
                                </textarea>
                                    <div *ngIf="isInvalid('description')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Description is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="checkbox" formControlName="active" class="mg-r-5">Is Active?
                                    <div *ngIf="isInvalid('active')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Choose if the Route is active for payment or
                                                not.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()"
                                        *ngIf="!save_loading">
                                        <ng-container *ngIf="!route_form.value._id">
                                            Save
                                        </ng-container>
                                        <ng-container *ngIf="route_form.value._id">
                                            Update
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()" *ngIf="!save_loading">
                                        <ng-container *ngIf="!route_form.value._id">
                                            Save & Close
                                        </ng-container>
                                        <ng-container *ngIf="route_form.value._id">
                                            Update & Close
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" disabled *ngIf="save_loading">
                                        <ng-container *ngIf="!route_form.value._id">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="route_form.value._id">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="route_id && !delete_loading" [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-danger mg-r-20" disabled
                                        *ngIf="route_id && delete_loading">Deleting...</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>