<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" (click)="addStudentAttendance()" class="btn btn-info">Add Student
                        Attendance</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Student Attendance</p>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="attendance_table_filter_form">

                            <!-- <div class="row mg-t-10">
                                <div class="col-lg-3">
                                    <input type="radio" formControlName="filter_mode" value="semester"
                                        (change)="filterChange($event)" name="filter_mode"> &nbsp;
                                    <label>Semester Wise </label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="radio" formControlName="filter_mode" value="month" name="filter_mode"
                                        (change)="filterChange($event)">
                                    &nbsp;
                                    <label>Date Range wise </label>
                                </div>
                            </div> -->


                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="attendance_table_filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Roll No.</label>
                                        <input type="text" formControlName="roll_no" class="form-control"
                                            placeholder="Enter Roll No.">
                                    </div>
                                </div>
                                <!-- *ngIf="attendance_table_filter_form.get('filter_mode').value=='month'" -->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="start_date">
                                    </div>
                                </div>
                                <!-- *ngIf="attendance_table_filter_form.get('filter_mode').value=='month'" -->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="end_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Minimum Attendance %</label>
                                        <input type="text" formControlName="percentage" class="form-control"
                                            placeholder="Enter Minimum Attendance %">
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Report Type</label>
                                        <select formControlName="report_type" class="form-control">
                                            <option value="">Choose Report Type</option>
                                            <option
                                                *ngFor="let type of report_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="type.id">{{type.name}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mg-t-25 search-button" title="Search"
                                            *ngIf="!student_list_loading" (click)="getStudentData()">
                                            Get Report
                                        </button>
                                        <button class="btn btn-info mg-t-25 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- attendance_table_filter_form.get('filter_mode').value=='month' && -->
                        <div class="d-flex right-content mg-b-5" style="float: right;"
                            *ngIf="monthly_attendance_data.length>0">
                            <input type="checkbox" [(ngModel)]="show_day_wise"> <span
                                class="mg-r-10 mg-t-10 mg-l-5">Show Day-wise data</span>
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadMonthlyCSV()">Download CSV</a>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 10%;">Roll No.</th>
                                        <!-- <ng-container
                                            *ngIf="attendance_table_filter_form.get('filter_mode').value=='semester' && semester_attendance_data.length>0">
                                            <th
                                                *ngFor="let data of semester_attendance_data[0].attendance_data; let i = index">
                                                {{data.month_name}}, {{data.year}}
                                            </th>
                                        </ng-container> -->
                                        <!-- attendance_table_filter_form.get('filter_mode').value=='month' && -->
                                        <ng-container *ngIf="monthly_attendance_data.length>0 && show_day_wise">
                                            <th
                                                *ngFor="let data of monthly_attendance_data[0].date_wise; let i = index">
                                                {{data.day_date |
                                                date:commonEnums.date_format}}
                                            </th>
                                        </ng-container>
                                        <th>
                                            <!-- <ng-container
                                                *ngIf="attendance_table_filter_form.value.report_type == 'DAY_WISE'"> -->
                                            Total Days Present / Total Working Days
                                            <!-- </ng-container> -->
                                        </th>
                                        <th>
                                            <!-- <ng-container
                                                *ngIf="attendance_table_filter_form.value.report_type == 'HOUR_WISE'"> -->
                                            Total Hours Present / Total Working Hours
                                            <!-- </ng-container> -->

                                        </th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <ng-container
                                        *ngIf="attendance_table_filter_form.get('filter_mode').value=='semester'">
                                        <tr *ngFor="let data of semester_attendance_data; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{data.roll_no}}</td>
                                            <td *ngFor="let month of data.attendance_data; let j = index;">
                                                {{month.month_total_working_days -
                                                month.month_no_days_absent}}/{{month.month_total_working_days}}
                                            </td>
                                            <td>{{data.total_working_days -
                                                data.no_days_absent}}/{{data.total_working_days}}</td>
                                        </tr>
                                    </ng-container> -->
                                    <!-- *ngIf="attendance_table_filter_form.get('filter_mode').value=='month'" -->
                                    <ng-container>
                                        <tr *ngFor="let data of monthly_attendance_data; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{data.roll_no}}</td>
                                            <ng-container *ngIf="show_day_wise">
                                                <td *ngFor="let day of data.date_wise; let j = index;">
                                                    <span style="cursor: pointer; text-decoration: underline;"
                                                        (click)="viewHourData(day.hour_wise)">{{day.no_of_hours_absent}}/{{day.no_of_hours}}</span>
                                                </td>
                                            </ng-container>
                                            <td>
                                                <!-- <ng-container
                                                    *ngIf="attendance_table_filter_form.value.report_type == 'DAY_WISE'"> -->
                                                {{data.total_working_days - (data.total_no_hours_absent / 5) | number :
                                                '1.2-2' }} /
                                                {{data.total_working_days}}
                                                <!-- </ng-container> -->
                                            </td>
                                            <td>
                                                <!-- <ng-container
                                                    *ngIf="attendance_table_filter_form.value.report_type == 'HOUR_WISE'"> -->
                                                {{data.total_no_hours_present + data.total_no_of_unmarked_hours}} /
                                                {{data.total_no_hours}}
                                                <!-- </ng-container> -->
                                            </td>
                                            <!-- <td>
                                                <ng-container
                                                    *ngIf="attendance_table_filter_form.value.report_type == 'DAY_WISE'">
                                                <span style="cursor: pointer; text-decoration:underline"
                                                    (click)="viewTotalReport(data)">{{data.attendance_percentage |
                                                    number : '1.2-2'}}%</span>
                                                </ng-container>
                                            </td> -->
                                            <td>
                                                <!-- <ng-container
                                                    *ngIf="attendance_table_filter_form.value.report_type == 'HOUR_WISE'"> -->
                                                <span style="cursor: pointer; text-decoration:underline"
                                                    (click)="viewTotalReport(data)" *ngIf="data.total_no_hours > 0">
                                                    {{(((data.total_working_days - (data.total_no_hours_absent / 5)) /
                                                    data.total_working_days) * 100) |
                                                    number : '1.2-2'}}%</span>
                                                <span style="cursor: pointer; text-decoration:underline"
                                                    (click)="viewTotalReport(data)" *ngIf="data.total_no_hours == 0">
                                                    0.00%</span>
                                                <!-- </ng-container> -->
                                            </td>
                                        </tr>
                                    </ng-container>


                                    <ng-container
                                        *ngIf="!attendance_data_loading && (semester_attendance_data.length==0 && monthly_attendance_data.length==0)">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="attendance_data_loading && (semester_attendance_data.length==0 && monthly_attendance_data.length==0)">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Students Attendance Data
                        </h6>
                    </div>
                    <div class="modal-body" *ngIf="popup_mode == 'view'">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">S.No</th>
                                            <th class="text-center">Hour No.</th>
                                            <th class="text-center">Course Code</th>
                                            <th class="text-center">Course Name</th>
                                            <th class="text-center">Is Present</th>
                                            <th class="text-center">Marked By</th>
                                        </tr>
                                    </thead>
                                    <tr *ngFor="let hour of hour_data; let i=index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{hour.hour_number}}</td>
                                        <td>{{hour.course_code}}</td>
                                        <td>{{hour.course_name}}</td>
                                        <td>
                                            <ng-container *ngIf="hour.is_marked">
                                                <span *ngIf="hour.is_present">
                                                    Present
                                                </span>
                                                <span *ngIf="hour.od_given">
                                                    (OD Given)
                                                </span>
                                                <span *ngIf="!hour.is_present && !hour.leave_applied">
                                                    Absent
                                                </span>
                                                <span *ngIf="hour.leave_applied">
                                                    Leave
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf="!hour.is_marked">
                                                <i>Attendance Pending</i>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <span *ngIf="hour.is_marked">
                                                {{hour.marked_by_name}}
                                            </span>
                                            <span *ngIf="!hour.is_marked">
                                                ---
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeDataClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body" *ngIf="popup_mode == 'add'">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="d-flex right-content mg-b-25" style="flex-direction: row-reverse;">
                                <app-modal [render_data]="bulk_upload_hour_data" (csvData)="bulkUploadHourData($event)">
                                </app-modal>
                            </div>
                            <form [formGroup]="attendance_upload_form" class="form-horizontal">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Date</label>
                                    <input type="date" class="form-control" formControlName="attendance_date">
                                    <div *ngIf="isInvalid('attendance_date')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Attendance Date is required</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Hour
                                        No(s).</label>
                                    <textarea class="form-control" placeholder="Enter Hour No(s) separated by Comma."
                                        formControlName="hour_number"></textarea>
                                    <div *ngIf="isInvalid('hour_number')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Hour No. is required</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Student Roll
                                        No(s).</label>
                                    <textarea class="form-control"
                                        placeholder="Enter Student Roll No(s) separated by Comma. Press Enter Key to continue previous roll no. header"
                                        (keydown)="triggerFunction($event)" formControlName="roll_no">
                                    </textarea>
                                    <div *ngIf="isInvalid('roll_no')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Student Roll No. is required</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Hour
                                        Status</label>
                                    <select formControlName="hour_status" class="form-control">
                                        <option value="">Select Hour Status</option>
                                        <option
                                            *ngFor="let status of hour_status;let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="status.key">{{status.value}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('hour_status')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Hour Status is required</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="add-btn-row" style="text-align: center;">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()"
                                        *ngIf="!save_loading">
                                        Save
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()" *ngIf="!save_loading">
                                        Save & Close
                                    </button>
                                    <button class="btn btn-info mg-r-20" disabled *ngIf="save_loading">
                                        Saving...
                                    </button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="modal-body" *ngIf="popup_mode == 'total'">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 70%;">Stat</th>
                                            <th class="text-center">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total No. of Working Days in the range</td>
                                            <td>{{student_attendance_data.total_working_days}}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of Days Present in the range</td>
                                            <td>{{(student_attendance_data.total_working_days -
                                                (student_attendance_data.total_no_hours_absent / 5)) |
                                                number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of Days Absent in the range (Consolidated)</td>
                                            <td>{{(student_attendance_data.total_no_hours_absent / 5) |
                                                number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Hours</td>
                                            <td>{{student_attendance_data.total_no_hours}}</td>
                                        </tr>
                                        <tr>
                                            <td>Hours Present</td>
                                            <td>{{student_attendance_data.total_no_hours_present}}</td>
                                        </tr>
                                        <tr>
                                            <td>Hours Absent</td>
                                            <td>{{student_attendance_data.total_no_hours_absent}}</td>
                                        </tr>
                                        <tr>
                                            <td>Hours Unmarked</td>
                                            <td>{{student_attendance_data.total_no_of_unmarked_hours}}</td>
                                        </tr>
                                        <tr>
                                            <td>Attendance Percentage Obtained for the range</td>
                                            <td>
                                                <!-- <ng-container
                                                    *ngIf="attendance_table_filter_form.value.report_type == 'DAY_WISE'">
                                                    {{student_attendance_data.attendance_percentage | number :
                                                    '1.2-2'}}%
                                                </ng-container> -->
                                                <ng-container *ngIf="student_attendance_data.total_no_hours !=0">
                                                    {{(((student_attendance_data.total_no_hours_present +
                                                    student_attendance_data.total_no_of_unmarked_hours) /
                                                    student_attendance_data.total_no_hours) * 100) |
                                                    number : '1.2-2'}}%
                                                </ng-container>
                                                <ng-container *ngIf="student_attendance_data.total_no_hours ==0">
                                                    --
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeDataClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>