import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  //roles
  roles: RoleInterface[] = [];
  roleForm: FormGroup;
  role_list_loading: boolean = false;
  role_save_loading: boolean = false;
  role_delete_loading: boolean = false;
  permissions: string[] = [];
  permission_map_form: FormGroup;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    this.roleForm = fb.group({
      id: [''],
      _id: [''],
      role_key: ['', [Validators.required]],
      role_name: [''],
      role_description: ['']
    });
    this.permission_map_form = fb.group({
      permission_map: fb.array([])
    });
    this.getRoles();
    this.getPermissions();
    this.getMapping();
  }
  ngOnInit(): void {
  }

  async getRoles() {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getRolesList();
      if (response && response.success) {
        console.log(response.data);
        this.roles = response.data;
        console.log(this.roles);
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_list_loading = false;
    }

  }

  isInvalid(formControl: string): boolean {
    return this.roleForm.touched && this.roleForm.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    console.log(this.roleForm);
    return this.roleForm.status !== 'VALID';
  }

  async onRoleEditClick(role: RoleInterface): Promise<void> {
    this.roleForm.setValue(role);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.roleForm.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  async onSave() {
    const form_data = this.roleForm.value;
    try {
      if (!this.roleForm.value.role_key) {
        alert("Please choose Start Date"); return;
      }
      form_data.role_name = form_data.role_key; //role name same as role key
      this.role_save_loading = true;
      if (this.roleForm.value._id != "") {
        const response = await this.restService.updateRole(form_data._id, form_data);
        if (response && response.success) {
          alert("Role updated successfully.");
        }
      }
      else {
        const response = await this.restService.createRole(form_data);
        if (response && response.success) {
          alert("Role created successfully.");
        }
      }
      this.getRoles();
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_save_loading = false;
    }
  }

  onSaveAndClose() {
    this.onSave();
    this.onCloseClick();
  }

  reset() {
    this.roleForm.reset();
  }

  async onDelete(_id: any) {
    try {
      this.role_delete_loading = true;
      const response = await this.restService.deleteRole(_id);
      if (response && response.success) {
        alert("Role deleted successfully.");
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_delete_loading = false;
    }
  }

  //permissions

  get aliasesArrayControl() {
    return (this.permission_map_form.get('permission_map') as FormArray).controls;
  }

  getPermissions() {
    this.permissions.push("access_all", "read", "read_write", "write", "delete", "read_delete");
    console.log(this.permissions);
  }

  getMapping() {
    var permission_map = this.permission_map_form.get('permission_map') as FormArray;
    permission_map.controls = [];
    var mapped_roles: FormArray;
    this.permissions.forEach(permission => {
      var permission_group = this.fb.group({
        permission_name: [permission],
        mapped_roles: this.fb.array([])
      });
      mapped_roles = permission_group.get('mapped_roles') as FormArray;
      this.roles.forEach(role => {
        mapped_roles.push(this.fb.group({
          role: [role.role_name],
          is_selected: [false]
        }));
      })
      permission_map.push(permission_group);
    });

    //console.log(this.permission_map_form)
  }
  onPermissionsSave() {
    var save_obj: {
      role: string,
      permissions: string[]
    }[] = [];
    this.roles.forEach(role => {
      save_obj.push({ role: role.role_name, permissions: [] });
      (this.permission_map_form.get('permission_map') as FormArray).controls.forEach(element => {
        ((element.get('mapped_roles') as FormArray).value as Array<{ role: string, is_selected: string }>).
          filter(x => x.role == role.role_name)?.
          forEach(map => {
            if (map.is_selected) {
              save_obj.find(x => x.role == role.role_name)?.permissions.push(element.get('permission_name')?.value);
            }
          });
      });
    });
    console.log(save_obj);
  }

  onPermissionsReset() {
    (this.permission_map_form.get('permission_map') as FormArray).controls.forEach(element => {
      (element.get('mapped_roles') as FormArray).controls.forEach(control => {
        control.get("is_selected")?.setValue(false);
      })
    });
  }



}

interface RoleInterface {
  id?: string,
  _id?: string,
  role_key: string,
  role_description?: string,
  role_name?: string,
}