import { Router } from '@angular/router';
import { Assets } from './../assets';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { Component, HostListener, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-room-asset-list',
  templateUrl: './room-asset-list.component.html',
  styleUrls: ['./room-asset-list.component.scss']
})
export class RoomAssetListComponent implements OnInit {
  roomAssets: Assets[] = [] as Assets[];
  fonts = fonts;
  commonEnums = CommonEnums;
  constructor(private notificationService: NotificationService,
    private navigationService: NavigationService,
    private restService: AdminservicesService,
    private router: Router) { }

  ngOnInit(): void {
    this.roomAssets = [] as Assets[];
    this.navigationService.homeURL = { label: 'Assets', url: 'admin/hostel/asset-list' };
    this.navigationService.setNavigation([{ label: 'Assets', url: 'admin/hostel/asset-list' }]);
    this.restService.initializeServices();
    this.getRoomAssetList();
  }

  async getRoomAssetList(): Promise<void> {
    try {
      const roomAssets = await this.restService.roomAssetService.getRoomAsset();
      if (roomAssets) {
        this.roomAssets = roomAssets;
      }
    } catch(error) {
      this.notificationService.setNotifcation({message: `Failed to get roomAsset: ${error?.message}`, type: 'Failed' });
    }
  }

  onEdit(assetId: string = ''): void {
    this.router.navigate([`admin/hostel/room-asset/${assetId}`]);
  }

  async onDelete(assetId: string = ''): Promise<void> {
    try {
      const result = await this.restService.roomAssetService.deleteRoomAsset(assetId);
      this.roomAssets = this.roomAssets.filter(roomAsset => roomAsset.id !== assetId);
    } catch(error) {
      this.notificationService.setNotifcation({ message: `Failed to delete ${assetId} : $${error.message}`, type: 'Failed'});
    }
  }


  onAdd(): void {
    this.router.navigate(['admin/hostel/room-asset']);
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

}
