import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-transport-fees-settings',
  templateUrl: './transport-fees-settings.component.html',
  styleUrls: ['./transport-fees-settings.component.scss']
})
export class TransportFeesSettingsComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Transport Fees List';
  transport_fees_list_loading: boolean = false;
  transport_fees_list: SettingInterface[] = [];
  fee_setting: SettingInterface = {} as SettingInterface;
  routes_list: {
    _id: string, route_name: string, amount: number,
    description: string, active: boolean
  }[] = [];
  routes_list_loading: boolean = false;
  transport_fees_form: FormGroup = {} as FormGroup;
  fee_setting_id: string = "";
  save_loading: boolean = false;
  delete_loading: boolean = false;
  ngOnInit(): void {
    /* this.getProgrammeDataList();
    this.getProgrammeTypeList(); */
    this.buildForm();
    this.getRouteList();
  }

  async getRouteList() {
    this.routes_list = [];
    this.routes_list_loading = true;
    /* this.routes_list.push(
      {
        _id: "123",
        route_name: "Sivakasi",
        amount: 2000,
        description: "College to Sivakasi",
        active: true,
      },
      {
        _id: "1233",
        route_name: "Tirutangal",
        amount: 3000,
        description: "College to Tirutangal",
        active: true,
      },
    ); */
    try {
      const service_response = await this.restService.listTransportRoutes();
      if (service_response && service_response.success) {
        this.routes_list = service_response.data;
        console.log(this.routes_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.routes_list_loading = false;
  }

  getRouteName(route_id: string) {
    return this.routes_list.find(x => x._id == route_id)?.route_name;
  }

  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.getFeesSettingsList();
  }

  async getFeesSettingsList() {
    this.transport_fees_list = [];
    this.transport_fees_list_loading = true;
    this.transport_fees_list.push(
      {
        _id: "2343",
        term_name: "February 2022",
        start_date: "2022-02-01T00:00",
        end_date: "2022-04-01T00:00",
        month: "February",
        year: 2022,
        route_id: "123",
        amount: 2000,
        extra_charge_amount: 0,
        description: "College to Sivakasi",
        //fine_settings_id: string,
        active: true,
      }
    )
    /* try {
      const service_response = await this.restService.listTransportFees();
      if (service_response && service_response.success) {
        this.transport_fees_list = service_response.data;
        console.log(this.transport_fees_list);
      }
    } catch (error) {
      alert('Error while read data');
    } */
    this.transport_fees_list_loading = false;
  }

  routeChanged(ev: any) {
    this.transport_fees_form.get('amount').setValue(this.routes_list.find(x => x._id == this.transport_fees_form.get('route_id').value).amount);
  }

  buildForm(): void {
    console.log(this.fee_setting);
    if (JSON.stringify(this.fee_setting) == '{}') {
      this.fee_setting = {
        _id: "",
        term_name: "",
        start_date: "",
        end_date: "",
        month: "0",
        year: 0,
        route_id: "",
        amount: 0,
        extra_charge_amount: 0,
        description: "",
        active: false,
      };
    }
    this.transport_fees_form = this.formBuilder.group({
      _id: [this.fee_setting._id],
      term_name: [this.fee_setting.term_name],
      start_date: [this.fee_setting.start_date != "" ? (this.fee_setting?.start_date?.substring(0, 10)) : '', [Validators.required]],
      end_date: [this.fee_setting.end_date != "" ? (this.fee_setting?.end_date?.substring(0, 10)) : '', [Validators.required]],
      month: [this.fee_setting.month, [Validators.required]],
      year: [this.fee_setting.year, [Validators.required]],
      route_id: [this.fee_setting.route_id, [Validators.required]],
      amount: [this.fee_setting.amount, [Validators.required]],
      extra_charge_amount: [this.fee_setting.extra_charge_amount, [Validators.required]],
      description: [this.fee_setting.description, [Validators.required]],
      active: [this.fee_setting.active],
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    this.fee_setting_id = setting._id; //term_name
    var settings = this.transport_fees_list.find(x => x._id == setting._id);
    if (settings) {
      console.log(settings);
      this.fee_setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.fee_setting_id = "";
    this.fee_setting = {} as SettingInterface;
    this.transport_fees_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      this.transport_fees_form.get('term_name')?.setValue((this.commonEnums.months.find(x => x.id == this.transport_fees_form.get('month')?.value).text) + " " + this.transport_fees_form.get('year')?.value);
      var form_data = this.transport_fees_form.value;
      form_data.start_date = new Date(this.transport_fees_form.value.start_date).toISOString();
      form_data.end_date = new Date(this.transport_fees_form.value.end_date).toISOString();
      //form_data.programme_type_id = this.programme_type_id;
      //form_data.fine_settings_id = "";
      //form_data.active=true;
      console.log(form_data);
      this.save_loading = true;
      if (this.transport_fees_form.value._id) {
        const res = await this.restService.updateTransportFees(this.transport_fees_form.value._id, form_data);
        if (!res) {
          alert('Failed to Update Transport Fee settings');
        }
        alert('Updated Transport Fee Settings Successfully');
      }
      else {
        const res = await this.restService.createTransportFees(form_data);
        if (!res) {
          alert('Failed to Create Transport Fee settings');
        }
        alert('Created Transport Fee Settings Successfully');
      }
      this.getFeesSettingsList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteTransportFees(this.transport_fees_form.get('_id')!.value);
      if (res.success) {
        alert('Deleted Transport Fee Settings Successfully');
        this.getFeesSettingsList();
      }
      else {
        alert('Failed to Delete Transport Fee settings');
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }

  isInvalid(formControl: string): boolean {
    return this.transport_fees_form.touched && this.transport_fees_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.transport_fees_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

}

interface SettingInterface {
  _id: string,
  term_name: string
  start_date: string,
  end_date: string,
  month: string,
  year: number,
  route_id: string,
  amount: number,
  extra_charge_amount: number,
  description: string,
  //fine_settings_id: string,
  active: boolean,
}