<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- Container -->
    <div class="container-fluid">
        <!-- breadcrump -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Template Configuration</h2>
                <p class="mg-b-0"></p>
            </div>
            <div class="d-flex right-content mg-b-5" style="float: right;margin-right: 1%;">
                <button type="button" class="btn btn-primary btn-md" style="margin-right: 5%;"
                    (click)="onBackClicks()">Back</button>
                <button type="button" class="btn btn-info btn-md" (click)="downloadPDF()">Print</button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-body">
                        <div class="content" *ngIf="mapping_data">
                            <form [formGroup]="mapping_data" class="form-horizontal">
                                <div formArrayName="mapping_data">
                                    <div *ngFor="let section of getMappingArray().controls; let sectionIndex=index;"
                                        [formGroupName]="sectionIndex">
                                        <div class="row" *ngIf="section.value.format == 'Group'">
                                            <hr *ngIf="sectionIndex != 0">
                                            <div class="col-md-12">
                                                <p><b>{{section.value.title}}</b></p>
                                            </div>
                                            <div class="col-md-12">
                                                <p><b>{{section.value.description}}</b></p>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="section.value.format != 'Group' ">
                                            <table class="table table-bordered">
                                                <tr>
                                                    <th style="width: 80%;"><b>{{section.value.title}}</b></th>
                                                    <th>Type: <b>{{section.value.format}}</b></th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 80%;">{{section.value.description}}</th>
                                                    <th>
                                                        <input type="file" (change)="uploadFile($event,sectionIndex)">
                                                        <p *ngIf="section.value.file_link">
                                                            <a [href]="section.value.file_link" target="_blank">Existing
                                                                File</a>
                                                        </p>
                                                    </th>
                                                </tr>
                                                <ng-container *ngIf="section.value.format == 'List' "
                                                    formGroupName="list_data">
                                                    <tr>
                                                        <th colspan="2">
                                                            <p>List Heading</p>
                                                            <kendo-editor #editors class="question"
                                                                formControlName="header" name="editor"
                                                                style="height: 300px;">
                                                                <kendo-toolbar>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorUndoButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorRedoButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorBoldButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorItalicButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorUnderlineButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorStrikethroughButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorSubscriptButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorSuperscriptButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignLeftButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignCenterButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignRightButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignJustifyButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFormat>
                                                                    </kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFontSize>
                                                                    </kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFontFamily>
                                                                    </kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-colorpicker kendoEditorForeColor>
                                                                    </kendo-toolbar-colorpicker>
                                                                    <kendo-toolbar-colorpicker kendoEditorBackColor
                                                                        view="gradient">
                                                                    </kendo-toolbar-colorpicker>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorInsertUnorderedListButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorInsertOrderedListButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorIndentButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorOutdentButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorCreateLinkButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorUnlinkButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-editor-insert-table-button>
                                                                    </kendo-editor-insert-table-button>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddColumnBeforeButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddColumnAfterButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddRowBeforeButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddRowAfterButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorDeleteColumnButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorDeleteRowButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorDeleteTableButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-button kendoEditorInsertFileButton>
                                                                    </kendo-toolbar-button>
                                                                    <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                                                    <kendo-toolbar-button kendoEditorViewSourceButton>
                                                                    </kendo-toolbar-button>
                                                                    <kendo-toolbar-button
                                                                        kendoEditorCleanFormattingButton>
                                                                    </kendo-toolbar-button>
                                                                </kendo-toolbar>
                                                            </kendo-editor>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th colspan="2">
                                                            <p>List Contents</p>
                                                            <kendo-editor #editors class="question"
                                                                formControlName="contents" name="editor"
                                                                style="height: 300px;">
                                                                <kendo-toolbar>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorUndoButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorRedoButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorBoldButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorItalicButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorUnderlineButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorStrikethroughButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorSubscriptButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorSuperscriptButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignLeftButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignCenterButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignRightButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAlignJustifyButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFormat>
                                                                    </kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFontSize>
                                                                    </kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFontFamily>
                                                                    </kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-colorpicker kendoEditorForeColor>
                                                                    </kendo-toolbar-colorpicker>
                                                                    <kendo-toolbar-colorpicker kendoEditorBackColor
                                                                        view="gradient">
                                                                    </kendo-toolbar-colorpicker>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorInsertUnorderedListButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorInsertOrderedListButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorIndentButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorOutdentButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorCreateLinkButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorUnlinkButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-editor-insert-table-button>
                                                                    </kendo-editor-insert-table-button>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddColumnBeforeButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddColumnAfterButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddRowBeforeButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorAddRowAfterButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorDeleteColumnButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorDeleteRowButton>
                                                                        </kendo-toolbar-button>
                                                                        <kendo-toolbar-button
                                                                            kendoEditorDeleteTableButton>
                                                                        </kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-button kendoEditorInsertFileButton>
                                                                    </kendo-toolbar-button>
                                                                    <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                                                    <kendo-toolbar-button kendoEditorViewSourceButton>
                                                                    </kendo-toolbar-button>
                                                                    <kendo-toolbar-button
                                                                        kendoEditorCleanFormattingButton>
                                                                    </kendo-toolbar-button>
                                                                </kendo-toolbar>
                                                            </kendo-editor>
                                                        </th>
                                                    </tr>
                                                </ng-container>

                                                <ng-container *ngIf="section.value.format == 'Table' ">
                                                    <tr>
                                                        <td colspan="2">
                                                            <p><b>Section Data:</b></p>
                                                            <table class="table table-bordered" style="width: 100%;">
                                                                <tr>
                                                                    <th
                                                                        *ngFor="let header of getTableHeader(sectionIndex); let headIndex=index;">
                                                                        {{header.value.column_name}}
                                                                    </th>
                                                                    <th>
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                                <ng-container formArrayName="table_data">
                                                                    <tr
                                                                        *ngFor="let rowArr of getTableData(sectionIndex); let rowArrIndex=index;">
                                                                        <ng-container [formArrayName]="rowArrIndex">
                                                                            <td *ngFor="let row of getTableFormArray(rowArr); let rowIndex=index;"
                                                                                [formGroupName]="rowIndex">
                                                                                <ng-container
                                                                                    *ngIf="row.value.column_format == 'Number' || row.value.column_format == 'number'">
                                                                                    <input type="number"
                                                                                        formControlName="column_data"
                                                                                        placeholder="Enter a numerical Value"
                                                                                        class="form-control">
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="row.value.column_format == 'String' || row.value.column_format == 'string' ">
                                                                                    <input type="text"
                                                                                        formControlName="column_data"
                                                                                        placeholder="Enter a text value"
                                                                                        class="form-control">
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="row.value.column_format == 'File' || row.value.column_format == 'file' ">
                                                                                    <input type="file"
                                                                                        (change)="uploadColFile($event,sectionIndex, rowArrIndex, rowIndex)">
                                                                                    <p *ngIf="row.value.column_data">
                                                                                        <a [href]="row.value.column_data"
                                                                                            target="_blank">Existing
                                                                                            File</a>
                                                                                    </p>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td
                                                                                style="text-align: center; vertical-align: middle;">
                                                                                <button
                                                                                    (click)="onDeleteRow(sectionIndex,rowArrIndex)"
                                                                                    class="btn btn-sm btn-danger">Remove</button>
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="text-align: center;"
                                                                            [attr.colspan]="getTableHeader(sectionIndex)?.length + 1">
                                                                            <button class="btn btn-sm btn-info"
                                                                                (click)="addTableRow(sectionIndex)">Add
                                                                                Row</button>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </ng-container>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary mg-r-20" style="margin-top: 20px"
                                (click)="saveMapping()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
