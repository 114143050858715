import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Chart } from 'chart.js';
var XLSX = require("xlsx");
@Component({
  selector: 'app-holistic-report',
  templateUrl: './holistic-report.component.html',
  styleUrls: ['./holistic-report.component.scss']
})
export class HolisticReportComponent implements OnInit {

  title = 'Student Holistic Attainment Report';
  commonEnums = CommonEnums;
  exhibiting_values: any[] = [];
  exhibiting_values_loading: boolean = false;
  exhibiting_value_selected: ExhibitingValuesType;
  fromDataResolver = FromDataResolver;
  CookieStore = CookieStore;
  fonts = fonts;
  filter_form: FormGroup;
  exhibiting_value_form: FormGroup;
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  sections: any[] = [];
  table_data_loading: boolean = false;
  co_levels: {
    co_id: string, co_text: string, co_skill: string
  }[] = [];
  holistic_attainment_report: HolisticAttainmentReport = undefined;
  academic_attainment_data: StudentAcademicAttainmentScores = {} as StudentAcademicAttainmentScores;
  non_academic_attainment_data: StudentNonAcademaicAttainmentScores[] = [];
  grade_bgs: {
    grade: string, bg: string
  }[] = [];
  academic_graph_data: any[] = [];
  constructor(private formBuilder: FormBuilder, private restService: AdminservicesService) { }
  @ViewChild('barCanvas') private barCanvas: ElementRef;
  private barChart: Chart;
  dataset = [
    {
      "part_type": "CORE",
      "gps_data": [
        {
          "co_id": "co1",
          "co_mark": 5.5,
          "co_text": ""
        },
        {
          "co_id": "co2",
          "co_mark": 4.5,
          "co_text": ""
        }
      ],
      "cagp_data": [
        {
          "co_id": "co1",
          "co_mark": 5,
          "co_text": ""
        },
        {
          "co_id": "co2",
          "co_mark": 4,
          "co_text": ""
        }
      ]
    },
    {
      "part_type": "ELECTIVE",
      "gps_data": [
        {
          "co_id": "co1",
          "co_mark": 5.5,
          "co_text": ""
        },
        {
          "co_id": "co2",
          "co_mark": 4.5,
          "co_text": ""
        }
      ],
      "cagp_data": [
        {
          "co_id": "co1",
          "co_mark": 5,
          "co_text": ""
        },
        {
          "co_id": "co2",
          "co_mark": 4,
          "co_text": ""
        }
      ]
    }
  ];

  // ngAfterViewInit() {
  //   this.generateBarChart();
  // }
  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
    this.buildExhibitingValueForm();
    this.co_levels = [
      {
        co_id: 'co1', co_text: 'CO1', co_skill: 'Remembering & Understanding Skills'
      },
      {
        co_id: 'co2', co_text: 'CO2', co_skill: 'Applying Skills'
      },
      {
        co_id: 'co3', co_text: 'CO3', co_skill: 'Analying Skills'
      },
      {
        co_id: 'co4', co_text: 'CO4', co_skill: 'Evaluating Skills'
      },
      {
        co_id: 'co5', co_text: 'CO5', co_skill: 'Creative Skills'
      }
    ];
    this.grade_bgs = [
      { grade: 'O+', bg: 'green' },
      { grade: 'O', bg: 'aqua' },
      { grade: 'D++', bg: 'orange' },
      { grade: 'D+', bg: 'pink' },
      { grade: 'D', bg: 'lightgray' },
      { grade: 'A++', bg: 'gray' },
      { grade: 'A+', bg: 'lightblue' },
      { grade: 'A', bg: 'skyblue' },
      { grade: 'B+', bg: 'bisque' },
      { grade: 'B', bg: 'brown' },
      { grade: 'U+', bg: 'red' },
    ]

    Object.keys(ExhibitingValuesType).forEach(v => this.exhibiting_values.push({id:ExhibitingValuesType[v], value: ExhibitingValuesType[v]}));
  }
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      enrolled_year: [''],
      roll_no: [''],
    });
  }

  buildExhibitingValueForm(): void {
    this.exhibiting_value_form = this.formBuilder.group({
      exhbiting_values: ['']
    })
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSubmitExhibitingValue() {
    if(!this.exhibiting_value_form.value.exhbiting_values) {
      alert("Please select exhibiting values"); return;
    }

    if(!this.holistic_attainment_report?._id) {
      alert("_id not found"); return;
    }

    this.exhibiting_values_loading = true;
    try {

      const payload_obj = { "_id": this.holistic_attainment_report?._id,
      "exhbiting_values": this.exhibiting_value_form.value.exhbiting_values }
      const exhibiting_value_response = await this.restService.updateExhibitingValues(payload_obj);
      if(exhibiting_value_response && exhibiting_value_response.success && exhibiting_value_response.data) {
        this.exhibiting_value_selected = this.exhibiting_value_form.value.exhbiting_values;
        alert("Exhibiting values updated successfully"); return;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      this.exhibiting_values_loading = false;
    } finally {
      this.exhibiting_values_loading = false;
    }
  }

  async onSearchClick() {
    if (!this.filter_form.value.enrolled_year) {
      alert("Please choose Regulation Year"); return;
    }
    if (!this.filter_form.value.roll_no) {
      alert("Please enter Roll No."); return;
    }

    this.table_data_loading = true;
    try {

      const service_response = await this.restService.getHolisticReport(this.filter_form.value);
      if (service_response && service_response.success) {
        this.holistic_attainment_report = service_response.data;
        this.academic_graph_data = this.holistic_attainment_report.attainment_academic_skills_scores.part_wise_data;
        this.academic_attainment_data = service_response?.data?.attainment_academic_skills_scores;
        this.non_academic_attainment_data = service_response.data?.attainment_non_academic_skills_scores;
        this.exhibiting_value_selected = service_response?.data?.exhbiting_values ? service_response?.data?.exhbiting_values : '';
      }

      //prepare graph data

      // this.academic_graph_data = [];
      // for (var i = 0; i < part_wise_data.length; i++) {
      //   const part = part_wise_data[i];
      //   const gps_marks = part.part_average_co_marks.gps_marks;
      //   const cagp_marks = part.part_average_co_marks.cagp_marks;
      //   let gps_data: co_level[] = [];
      //   let cagp_data: co_level[] = [];
      //   Object.keys(gps_marks).forEach(key => {
      //     gps_data.push({
      //       co_id: key,
      //       co_mark: gps_marks[key],
      //       co_text: ""
      //     });
      //   })

      //   Object.keys(cagp_marks).forEach(key => {
      //     cagp_data.push({
      //       co_id: key,
      //       co_mark: cagp_marks[key],
      //       co_text: ""
      //     });
      //   })
      //   this.academic_graph_data.push({
      //     part_type: part.part_type,
      //     gps_data: gps_data,
      //     cagp_data: cagp_data
      //   });
      // }
      this.generateBarChart();
      console.log(this.academic_graph_data);


    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      this.table_data_loading = false;
      this.holistic_attainment_report = undefined;
      this.academic_attainment_data = {} as StudentAcademicAttainmentScores;
      this.non_academic_attainment_data = [];
    }
    finally {
      this.table_data_loading = false;
    }
  }

  downloadExcel() {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("report-table");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, `${this.holistic_attainment_report.roll_no}_HOLISTIC_ATTAINMENT_REPORT.xlsx`);
  }

  downloadPDF() {
    var mywindow = window.open('', 'PRINT', `height=${window.screen.height},width=${window.screen.width}`);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A4 potrait; } .table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;} #report-table {font-size: 10px;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('attainment-html').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  async onModalCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-popup-view');
  }

  getBackgroundColor(grade) {
    if (!grade) {
      return 'background:white';
    }
    let bg = ''
    if (grade) {
      bg = this.grade_bgs.find(x => x.grade == grade)?.bg ? this.grade_bgs.find(x => x.grade == grade)?.bg : '';
    }
    return 'background:' + bg;
  }

  getEmptyRows(length) {
    return new Array(length).fill(0);
  }

  generateBarChart() {

    try {
      // const barCanvas = this.barCanvas.nativeElement;
      // const ctx = barCanvas.getContext('2d');

      // Extract data for the chart
      const ctx = document.getElementById('acadamicgraph') as HTMLCanvasElement;
      if(ctx != null) {
        const part_data = [];
        const rembering_interpersonal_skills = [];
        const applying_skills = [];
        const analysing_skills = [];
        const evaluating_skills = [];
        const creative_skills = [];
        this.academic_graph_data.forEach(data => {

          rembering_interpersonal_skills.push(data.part_average_co_marks.gps_marks.co1)
          rembering_interpersonal_skills.push(data.part_average_co_marks.cagp_marks.co1)

          applying_skills.push(data.part_average_co_marks.gps_marks.co2)
          applying_skills.push(data.part_average_co_marks.cagp_marks.co2)

          analysing_skills.push(data.part_average_co_marks.gps_marks.co3)
          analysing_skills.push(data.part_average_co_marks.cagp_marks.co3)

          evaluating_skills.push(data.part_average_co_marks.gps_marks.co4)
          evaluating_skills.push(data.part_average_co_marks.cagp_marks.co4)

          creative_skills.push(data.part_average_co_marks.gps_marks.co5)
          creative_skills.push(data.part_average_co_marks.cagp_marks.co5)

          part_data.push(data.part_type + ' - GPS')
          part_data.push(data.part_type + '- CAGP')
        });
        this.barChart = new Chart(ctx, {
            type: 'bar',
            data: {
              labels: part_data,
              datasets: [
                {
                  label: 'Remembering and understaning skills',
                  data: rembering_interpersonal_skills,
                  backgroundColor: 'rgba(132, 221, 146, 0.8)',
                  borderColor: 'rgb(132, 221, 146)',
                  borderWidth: 1
                },
                {
                  label: 'Applying skills',
                  data: applying_skills,
                  backgroundColor: 'rgba(124, 248, 255, 0.8)',
                  borderColor: 'rgba(124, 248, 255)',
                  borderWidth: 1
                },
                {
                  label: 'Analyzing skills',
                  data: analysing_skills,
                  backgroundColor: 'rgba(171, 169, 172, 0.8)',
                  borderColor: 'rgba(171, 169, 172)',
                  borderWidth: 1
                },
                {
                  label: 'Evaluating skills',
                  data: evaluating_skills,
                  backgroundColor: 'rgba(178, 84, 255, 0.8)',
                  borderColor: 'rgba(178, 84, 255)',
                  borderWidth: 1
                },
                {
                  label: 'Creative skills',
                  data: creative_skills,
                  backgroundColor: 'rgba(255, 84, 89, 0.8)',
                  borderColor: 'rgba(255, 84, 89)',
                  borderWidth: 1
                },
              ]
            },
            options: {
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
            }
        });
      }

    } catch (err) {
      console.log(err);
    }

  }
}


//Non Academic Attainment Semester wise Report
interface HolisticAttainmentReport {
  id?: string;
  _id?: string;

  //user_input
  enrolled_year: number;
  roll_no: string;

  programme_type_id: string;
  finance_type: string;
  section: string;
  programme_id: string;
  sub: string;
  programme_name: string;
  year_of_study: string;
  date_of_birth: string;
  given_name: string;
  middle_name: string;
  attainment_academic_skills_scores: StudentAcademicAttainmentScores;
  attainment_non_academic_skills_scores: StudentNonAcademaicAttainmentScores[];
}

export interface StudentNonAcademaicAttainmentScores {
  skillset_id: string;
  skillset_name: string;
  skillset_average_mark: number;
  skillset_grade: string;
}

export interface StudentAcademicAttainmentScores {
  part_wise_data: PartWiseDataInterface[];
  average_total_cos_semester: GrandTotalAcademicMarks;
  average_final_co_level: GrandTotalAcademicMarks;
  final_co_grades: GrandTotalAcademicMarks;
}

export interface GrandTotalAcademicMarks {
  gps_marks: COMarks;
  cagp_marks: COMarks;
}

export interface PartWiseDataInterface {
  part_type: string;
  semesters_data: SemesterWiseDataAcademicAttainment[];
  part_total_marks: GrandTotalAcademicMarks;
  part_average_co_marks: GrandTotalAcademicMarks;
  part_grade: GrandTotalAcademicMarks;
}

export interface SemesterWiseDataAcademicAttainment {
  semester: number;
  course_data: CourseDataAcademicAttainment[];
}

export interface CourseDataAcademicAttainment {
  course_code: string;
  course_name: string;
  gps_marks: COMarks;
  cagp_marks: COMarks;
}

export interface COMarks {
  co1: string | number;
  co2: string | number;
  co3: string | number;
  co4: string | number;
  co5: string | number;
}


//Graph Data  - Academic Attainment
export interface AcademicGraphData {
  part_type: string,
  gps_data: co_level[],
  cagp_data: co_level[]
}

export interface co_level {
  co_id: number | string,
  co_text?: string,
  co_mark: number
}

enum ExhibitingValuesType {
  VERY_GOOD = "Very Good",
  GOOD = "Good",
  AVERAGE = "Average",
  LEAST_SGINIFICANT = "Least Significant"
}
