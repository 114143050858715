import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ICOPOContribution } from 'src/app/services/rest/entity/ICOPOContribution';
import { ICOPOMap } from 'src/app/services/rest/entity/ICOPOMap';
import { ICourses } from 'src/app/services/rest/entity/ICourses';
import { ICoursesBooks } from 'src/app/services/rest/entity/ICoursesBooks';
import { ICoursesDetails } from 'src/app/services/rest/entity/ICoursesDetails';
import { ICoursesTutorials } from 'src/app/services/rest/entity/ICoursesTutorials';
import { ICoursesUnits } from 'src/app/services/rest/entity/ICoursesUnits';

@Component({
  selector: 'app-course-setup',
  templateUrl: './course-setup.component.html',
  styleUrls: ['./course-setup.component.scss']
})
export class CourseSetupComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;


  co_count = 5;
  po_count = 7;

  parts: any[] = [];
  part_course_types: any[] | undefined = [];
  course_code: string = ""; //to remove 
  // Core datas

  course_search_form: any;
  course_data_list: any[] = [];

  course_list_loading = false;

  // Core data creation

  course_form: any;
  course: any;
  course_save_loading = false;
  course_delete_loading = false;

  course_co_po_form: any;

  courseware_form: any;
  course_setting_form: any;
  setting_save_loading: boolean = false;
  section_delete_loading: boolean[] = [];
  settings_loading: boolean = false;
  po_total: any[] = [];
  // CRUD
  state = '';

  bulk_render_data: Modal = {
    modal_button_text: "Upload as CSV",
    modal_id: "co-po-upload-csv",
    modal_header: "Upload CO-PO Mapping as CSV",
    list_name: "CO-PO Mapping",
    csv_data: {
      columns: [
        { column_name: "--", column_type: "optional" },
        { column_name: "po1", column_type: "required" },
        { column_name: "po2", column_type: "required" },
        { column_name: "po3", column_type: "required" },
        { column_name: "po4", column_type: "required" },
        { column_name: "po5", column_type: "required" },
        { column_name: "po6", column_type: "required" },
        { column_name: "po7", column_type: "required" },
      ],
      row_data: "CO1 \r\n CO2 \r\n CO3 \r\n CO4 \r\n CO5 \r\n"
    },
    table_data: {
      column_names: ["", "PO1", "PO2", "PO3", "PO4", "PO5", "PO6", "PO7"],
      column_values: ["CO1", "3", "3", "3", "1", "1", "0", "0"]
    },

  };
  course_positions: number[] = [];
  chosen_programmes: {
    programme_id: string, section_id: string
  }[] = [];
  public department_list: Array<any> = [];
  student_programme_list: any[] = [];
  student_on_board_types: { key: any, value: any }[] = [
    { key: "ASSIGNED_BY_STUDENTS_PARENT_DPT", value: "Assigned by Students Parent Department" },
    { key: "ASSIGNED_BASED_ON_STUDENT_CHOICE", value: "Assigned based on Students Choice" },
    { key: "ASSIGNED_BY_STAFF_ADMIN", value: "Assigned by Staff Admin" },
    { key: "ASSIGNED_BY_COURSE_PARENT_DPT", value: "Assigned by Course's Parent Department" }
  ]

  student_sitting_arrangement_types: { key: any, value: any }[] = [
    { key: "BASED_ON_STUDENTS_PARENT_DPT", value: "Based by Students' Parent Department" },
    { key: "BASED_ON_VIRTUAL_SECTION", value: "Create Virtual Section(s)" },
  ];

  staff_on_board_types: { key: any, value: any }[] = [
    { key: "STAFF_INCHARGE_ASSINGED_BY_COURSE_PARENT_DPT", value: "Staff Assigned by Course's Parent Department" },
    { key: "STAFF_INCHARGE_ASSINGED_BY_OTHER_DPT", value: "Staff Assigned by other Course Handling Department" },
  ];

  incharge_types: { key: any, value: any }[] = [{ key: "SPECIFIC_PRG", value: "Specific Department" },
  { key: "PARENT_PRG", value: "Parent Department" }, { key: "AUTONOMOUS", value: "Autonomous" }];




  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
    this.getProgrammeDataList();
    this.buildCourseSettingForm({});
    /* for (var i = 1; i <= 10; i++) this.course_positions.push(i); */
  }

  buildCourseSettingForm(settings_data) {
    if (JSON.stringify(settings_data) != '{}') {
      this.course_setting_form = this.fb.group({
        id: [settings_data.id],
        course_code: [settings_data.course_code, [Validators.required]],
        enrolled_year: [settings_data.enrolled_year, [Validators.required]],
        semester: [settings_data.semester, [Validators.required]],
        incharge_type: [settings_data.incharge_type, [Validators.required]],
        student_on_board_type: [settings_data.student_on_board_type, [Validators.required]],
        staff_on_bording_type: [settings_data.staff_on_bording_type, [Validators.required]],
        student_sitting_arragement_type: [settings_data.student_sitting_arragement_type, [Validators.required]],
        virtual_sections: this.fb.array(this.buildSectionListForm(settings_data.virtual_sections)),
        exclude_from_polling: [settings_data.exclude_from_polling, [Validators.required]]
      });
    }
    else {
      this.course_setting_form = this.fb.group({
        id: [""],
        course_code: ['', [Validators.required]],
        enrolled_year: ['', [Validators.required]],
        semester: ['', [Validators.required]],
        incharge_type: ['', [Validators.required]],
        student_on_board_type: ['', [Validators.required]],
        staff_on_bording_type: ['', [Validators.required]],
        student_sitting_arragement_type: ['', [Validators.required]],
        virtual_sections: this.fb.array(this.buildSectionListForm([])),
        exclude_from_polling: [false, [Validators.required]]
      });
    }

  }

  buildSectionListForm(section_list): any {
    if (!section_list) {
      section_list = [];
    }
    var section_list_array: any[] = [];
    if (section_list && section_list.length > 0) {
      var section_count = 0;
      for (const section of section_list) {
        //console.log(component)
        this.section_delete_loading[section_count] = false;
        section_list_array.push(this.fb.group({
          section_id: [section.section_id],
          section_name: [section.section_name],
          allowed_programmes: this.fb.array(this.buildAllowedProgrammes(section.allowed_programmes)),
          is_expanded: [false],
        }));
        section_count++;
      }
    }
    const remaining_count = 1 - section_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.section_delete_loading[index] = false;
        section_list_array.push(this.fb.group({
          section_id: ['A'],
          section_name: ['A'],
          allowed_programmes: this.fb.array(this.buildAllowedProgrammes([])),
          is_expanded: [false],
        }));
      }
    }
    console.log(section_list_array);
    return section_list_array;
  }

  buildAllowedProgrammes(programme_list): any {
    if (!programme_list) {
      programme_list = [];
    }
    var programme_list_array: any[] = [];
    if (programme_list && programme_list.length > 0) {
      var programme_count = 0;
      for (const programme of programme_list) {
        console.log(programme)
        this.chosen_programmes.push(programme);
        programme_list_array.push(this.fb.group({
          programme_id: [(programme.programme_id as string).toLowerCase()],
          section_id: [programme.section_id],
        }));
        programme_count++;
      }
    }
    const remaining_count = 1 - programme_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        programme_list_array.push(this.fb.group({
          programme_id: [""],
          section_id: [""],
        }));
      }
    }
    console.log(programme_list_array);
    return programme_list_array;
  }

  get sectionList() {
    return ((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls;
  }

  getAllowedProgrammes(index: number) {
    var allowed_programmes = ((((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls[index]).get('allowed_programmes') as FormArray).controls;
    console.log(allowed_programmes);
    return allowed_programmes;
  }

  getSections(i: number, j: number) {
    var sections = [];
    var programme_id = ((((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls[i]).get('allowed_programmes') as FormArray).controls[j].get('programme_id').value
    const programme = this.programme_list.find(x => x.programme_id == ((programme_id as string).toLowerCase()));
    if (programme && programme.section_details && programme.section_details.length > 0) {
      sections = programme.section_details;
      if (sections.length == 1) {
        ((((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls[i]).get('allowed_programmes') as FormArray).controls[j].get('section_id').setValue(sections[0].section_name)
      }
    }
    return sections;
  }

  isSectionSelected(ev: Event, i: number, j: number) {
    console.log(this.chosen_programmes);
    var chosen_section = ((ev.target) as HTMLSelectElement).value;
    var programme_id = ((((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls[i]).get('allowed_programmes') as FormArray).controls[j].get('programme_id').value
    if (this.chosen_programmes.filter(x => x.programme_id.toLowerCase() == programme_id && x.section_id == chosen_section).length > 0) {
      alert("This programme and section is already chosen");
      (ev.target as HTMLSelectElement).value = "";
    }
    else {
      this.chosen_programmes.push({ programme_id: programme_id, section_id: chosen_section });
    }
  }

  viewAllowedProgrammes(section) {
    //add code to close other section's allowed programme
    ((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls.forEach((control: FormGroup) => {
      control.get('is_expanded').setValue(false);
    });
    section.get('is_expanded').setValue(true);
  }

  closeAllowedProgrammes(section) {
    section.get('is_expanded').setValue(false);
  }


  async onSettingClick(course: any) {
    this.state = 'SETTING';
    this.course = course;
    this.course_setting_form.get('course_code').setValue(this.course.course_code);
    this.course_setting_form.get('semester').setValue(this.course.semester);
    console.log(this.course_setting_form.value);
    try {
      //get data from api based on course code; 
      JQueryHelper.openModal('#modal-setting', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }

  async onSettingSaveClick() {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.setting_save_loading = true;
      //this.course_setting_form.get('id').setValue(this.course.id);

      var course_data = this.course_setting_form.value;
      if (this.course_setting_form.value.student_sitting_arragement_type != 'BASED_ON_VIRTUAL_SECTION') {
        delete course_data.virtual_sections;
      }
      if (this.course_setting_form.value.student_sitting_arragement_type == 'BASED_ON_VIRTUAL_SECTION') {
        course_data.virtual_sections.forEach(section_data => {

          delete section_data.is_expanded;
          section_data.section_name = section_data.section_id;
        });
      }
      console.log(course_data);
      const save_respose = await this.restService.createAdvancedSettings(course_data);
      if (save_respose.success) {
        this.onSearchClick();
        alert('Saved Successfully');
        this.onSettingCloseClick();
      }
    } catch (error) {
      alert('Error while saving data');
    } finally {
      this.setting_save_loading = false;
    }
  }

  async onSettingCloseClick() {
    this.course_setting_form.reset();
    this.course_setting_form.get('enrolled_year').setValue("");
    /* Object.keys((<FormGroup>this.course_setting_form).controls).forEach(control => {
      this.course_setting_form.get(control).setValue("");
    }) */
    this.course = {};
    JQueryHelper.closeModal('#modal-setting');
  }

  isInvalid(formControl: string): boolean {
    return this.course_setting_form.touched && this.course_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.course_setting_form.status !== 'VALID';
  }

  async settingTypeChanged(ev: any) {
    //empty settings fields
    console.log(this.course_setting_form.value);
    if (this.course_setting_form.value.enrolled_year && this.course_setting_form.value.semester) {
      this.course_setting_form.value.incharge_type = "";
      this.course_setting_form.value.student_on_board_type = "";
      this.course_setting_form.value.staff_on_bording_type = "";
      this.course_setting_form.value.student_sitting_arragement_type = "";
      this.course_setting_form.value.virtual_sections = [];
      this.course_setting_form.value.exclude_from_polling = false;
      const course_data = {
        "enrolled_year": this.course_setting_form.value.enrolled_year,
        //"semester": this.course_setting_form.value.semester,
        "course_code": this.course.course_code
      }
      console.log(course_data);
      this.settings_loading = true;
      const service_response = await this.restService.getCourseSettings(course_data);
      if (service_response && service_response.success) {
        console.log(service_response);
        const settings_data = service_response.data;
        if (settings_data) {
          settings_data.course_code = course_data.course_code;
          settings_data.enrolled_year = course_data.enrolled_year,
            settings_data.semester = this.course.semester
          this.buildCourseSettingForm(settings_data);
        }
        else {
          var default_setting: any = {};
          default_setting.id = "";
          default_setting.course_code = course_data.course_code;
          default_setting.enrolled_year = course_data.enrolled_year;
          default_setting.semester = this.course.semester;
          default_setting.incharge_type = "";
          default_setting.student_on_board_type = "";
          default_setting.staff_on_bording_type = "";
          default_setting.student_sitting_arragement_type = "";
          default_setting.exclude_from_polling = false;
          this.buildCourseSettingForm(default_setting);
        }
        console.log(this.course_setting_form.value);
      }
      this.settings_loading = false;
    }
  }

  arrangementTypeChange(event?: any) {
    if (event.target.value == 'BASED_ON_VIRTUAL_SECTION') {
      if (this.course_setting_form.value.virtual_sections.length == 0) {
        //if no default value exists push default value into the virtual_sections array
        this.course_setting_form.get('virtual_sections').setValue(["A"]);
      }

    }
  }
  addSection() {
    ((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).push(this.fb.group({
      section_id: [""],
      section_name: [""],
      allowed_programmes: this.fb.array(this.buildAllowedProgrammes([])),
      is_expanded: [false],
    }));
  }
  deleteSection(index: number) {
    const consent = confirm("Are you sure want to delete this section?");
    if (!consent) return;
    ((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).removeAt(index);
  }

  addAllowedProgramme(index) {
    ((((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls[index] as FormGroup).get("allowed_programmes") as FormArray).push(this.fb.group({
      programme_id: [""],
      section_id: [""],
    }));
  }
  deleteAllowedProgramme(i, j) {
    const consent = confirm("Are you sure want to delete this programme?");
    if (!consent) return;
    ((((this.course_setting_form as FormGroup).get('virtual_sections') as FormArray).controls[i]).get('allowed_programmes') as FormArray).removeAt(j);
  }





  /// master data

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      offered_by_programme_type_id: [''], //required
      revision_year: [''], //required
      //offered_by_finance_type: [''],
      //offered_by_programme_id: [''],
      //semester: ['']
      course_code: [''] //optional search field
    });

  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      /* if (this.state === 'ADD') {
        this.getProgrammeDataList(this.course_form);
        if (id === 'programme_type_id' && this.course_form.value.offered_by_programme_type_id) {
          this.parts = this.commonEnums.getParts(this.course_form.value.offered_by_programme_type_id);
        }
      } else {
        this.getProgrammeDataList(this.programme_search_form);
        if (id === 'programme_type_id' && this.programme_search_form.value.offered_by_programme_type_id) {
          this.parts = this.commonEnums.getParts(this.programme_search_form.value.offered_by_programme_type_id);
        }
      } */
    } else if (id === 'part') {
      if (this.state === 'ADD') {
        if (this.course_form.value.offered_by_programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(this.course_form.value.offered_by_programme_type_id,
            this.course_form.value.part);
        }
      } else {
        if (this.course_form.value.offered_by_programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(
            this.programme_search_form.value.offered_by_programme_type_id, this.programme_search_form.value.part);

        }
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (this.programme_search_form) {
        if (this.programme_search_form.value.offered_by_programme_type_id) {
          search_form_query.programme_type_id = this.programme_search_form.value.offered_by_programme_type_id;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }



  async onSearchClick(): Promise<void> {
    this.searchCourse();
  }

  async searchCourse(): Promise<void> {
    try {
      console.log(this.programme_search_form.value);

      if (!this.programme_search_form.value.offered_by_programme_type_id) {
        alert('Please select programme type');
        return;
      }
      if (!this.programme_search_form.value.revision_year) {
        alert('Please select revision year');
        return;
      }

      this.course_list_loading = true;

      this.course_data_list = [];
      /* var search_form_query: any = {
        revision_year: this.programme_search_form.value.revision_year,
        offered_by_programme_type_id: this.programme_search_form.value.offered_by_programme_type_id
      }; */  //new search filter query

      const search_form_query: any = {
        programme_type_id: this.programme_search_form.value.offered_by_programme_type_id,
        revision_year: this.programme_search_form.value.revision_year,
      };
      if (this.programme_search_form.value.course_code) {
        search_form_query.course_code = this.programme_search_form.value.course_code;
      }
      //const service_response = await this.restService.searchCouseList(search_form_query);
      const service_response = await this.restService.getCourses(search_form_query);
      if (service_response && service_response.success) {
        this.course_data_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_list_loading = false;
    }

  }

  downloadAsCSV(): void {

  }


  // Core data

  buildCOPOForm(course): void {


    if (!course) {
      course = [];
    }

    for (let index = 0; index < this.co_count; index++) {
      const co_element = index + 1;
      const co = course.filter(c => c.co_id === co_element);
      if (co && co.length > 0) {


        for (let po_index = 0; po_index < this.po_count; po_index++) {
          const po_element = po_index + 1;
          if (!co.find(c => c.po_id === po_element)) {
            const co_po_map: ICOPOMap = {} as ICOPOMap;
            co_po_map.course_id = course._id;
            co_po_map.co_id = co_element;
            co_po_map.po_id = po_element;
            co_po_map.score = 0;
            course.push(co_po_map);
          }
        }


      } else {
        for (let po_index = 0; po_index < this.po_count; po_index++) {
          const po_element = po_index + 1;
          const co_po_map: ICOPOMap = {} as ICOPOMap;
          co_po_map.course_id = course._id;
          co_po_map.co_id = co_element;
          co_po_map.po_id = po_element;
          co_po_map.score = 0;
          course.push(co_po_map);
        }
      }
    }

    const map: any[] = [

    ];

    for (let index = 0; index < this.co_count; index++) {
      const co_element = index + 1;
      const co = course.filter(c => c.co_id === co_element).sort((a, b) => a.po_id - b.po_id);

      map.push({
        co_id: co_element,
        do_data: co
      });
    }
    console.log(JSON.stringify(map, null, 4));

    this.course_co_po_form = this.fb.group({
      _id: [course._id],
      bulk_update: [''],
      co_po_map: this.fb.array(this.addCO(map))
    });

    this.course = course;
    this.calculatePOWeightage();
  }

  valueChanged(data: any): void {
    this.calculatePOWeightage();
  }

  calculatePOWeightage(): void {
    this.po_total = [];
    const course_data = this.course_co_po_form.value;

    if (course_data.co_po_map && course_data.co_po_map.length > 0) {
      const co_po_map_to_save: any[] = [];

      for (const co_po_map of course_data.co_po_map) {
        co_po_map_to_save.push(...co_po_map.do_data);
      }

      for (let index = 0; index < this.co_count; index++) {
        const co_element = index + 1;
        if (this.course) {
          const fil = co_po_map_to_save.filter(c => c.co_id === co_element);
          for (const fill of fil) {
            const tp = this.po_total.find(c => c.id === fill.po_id);

            if (tp) {
              tp.score = tp.score + parseInt(fill.score, 0);
            } else {
              this.po_total.push({ id: fill.po_id, score: parseInt(fill.score, 0) });

            }
          }

        }
      }
      console.log(this.po_total);
    }

  }

  addCO(co: any[]): any[] {
    const f: any[] = [];
    for (const co_d of co) {
      f.push(this.fb.group({
        co_id: co_d.co_id,
        do_data: this.fb.array(this.addPO(co_d.co_id, co_d.do_data))
      }));
    }
    return f;
  }

  addPO(co_id: number, po: any[]): any[] {
    const f: any[] = [];
    for (const po_d of po) {
      f.push(this.fb.group({
        co_id,
        po_id: po_d.po_id,
        score: po_d.score
      }));
    }
    return f;
  }


  buildCoursewareForm(course): void {
    if (!course) {
      course = {} as ICoursesDetails;
    }
    this.courseware_form = this.fb.group({
      course_id: [course._id],
      course_outcome: [course.course_outcome],
      course_units: this.fb.array(this.addUnits(course._id, course.course_units)),
      practicals: [course.practicals],
      text_book: [course.text_book],
      reference_book: [course.reference_book],
      tutorials: this.fb.array(this.addTutorials(course._id, course.tutorials)),
      web_reference: [course.web_reference]
    });
  }

  addUnits(course_id: string, units: ICoursesUnits[]): any[] {
    const f: any[] = [];
    if (!units || units.length === 0) {
      units = [];
      for (let index = 0; index < 5; index++) {
        const unit = {} as ICoursesUnits;
        unit.title = '';
        unit.course_topics_string = '';
        unit.no_of_hours = '';
        units.push(unit);
      }
    }
    for (const unit of units) {
      f.push(this.fb.group({
        course_id: [course_id],
        title: [unit.title],
        description: [unit.description],
        order: [unit.order],
        course_topics_string: [unit.course_topics_string],
        no_of_hours: [unit.no_of_hours]
      }));
    }
    return f;
  }

  addTextBooks(course_id: string, units: ICoursesBooks[]): any[] {
    const f: any[] = [];
    if (!units || units.length === 0) {
      units = [];
      for (let index = 0; index < 5; index++) {
        const unit = {} as ICoursesBooks;
        unit.book_name = '';
        unit.author_name = '';
        unit.version = '';
        units.push(unit);
      }
    }
    for (const unit of units) {
      f.push(this.fb.group({
        course_id: [course_id],
        book_name: [unit.book_name],
        author_name: [unit.author_name],
        version: [unit.version]
      }));
    }
    return f;
  }

  addTutorials(course_id: string, units: ICoursesTutorials[]): any[] {
    const f: any[] = [];
    if (!units || units.length === 0) {
      units = [];
      for (let index = 0; index < 5; index++) {
        const unit = {} as ICoursesTutorials;
        unit.topic = '';
        unit.activity = '';
        unit.unit_covered = '';
        units.push(unit);
      }
    }
    for (const unit of units) {
      f.push(this.fb.group({
        course_id: [course_id],
        topic: [unit.topic],
        activity: [unit.activity],
        unit_covered: [unit.unit_covered]
      }));
    }
    return f;
  }

  async onCOPOMapClick(course: any): Promise<void> {
    this.state = 'COPO';
    this.course = course;
    this.course_code = course.course_code;
    try {
      //const course_response = await this.restService.findCourse(course.course_code);
      const course_response = await this.restService.getCourseCOPO({ course_code: course.course_code });
      console.log(course_response);
      if (course_response.success) {
        this.po_total = [];
        for (let index = 0; index < this.po_count; index++) {
          const po_element = index + 1;
          this.po_total.push({ id: po_element, score: 0 });
        }

        this.buildCOPOForm(course_response.data);
        console.log(course_response.data);
        JQueryHelper.openModal('#modal-copomap', { keyboard: false, backdrop: 'static' });
      }

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }

  async onCoursewareClick(course: any): Promise<void> {
    this.state = 'CW';
    this.course = course;
    try {
      //const course_response = await this.restService.findCourse(course.course_code);
      const course_response = await this.restService.getCourseware({ course_code: course.course_code });
      if (course_response.success) {
        this.buildCoursewareForm(course_response.data);
        console.log(course_response.data);
        JQueryHelper.openModal('#modal-courseware', { keyboard: false, backdrop: 'static' });
      }

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }


  resetCOPOForm(): void {
    this.course_co_po_form = undefined;
    this.state = '';
  }
  async onCOPOCloseClick(): Promise<void> {
    this.state = '';
    this.course_code = "";
    this.resetCOPOForm();
    JQueryHelper.closeModal('#modal-copomap');
  }

  resetCoursewareForm(): void {
    this.courseware_form = undefined;
    this.state = '';
  }
  async onCoursewareCloseClick(): Promise<void> {
    this.state = '';
    this.resetCoursewareForm();
    JQueryHelper.closeModal('#modal-courseware');
  }


  async onCOPOSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      if (!this.course) {
        return;
      }

      this.calculatePOWeightage();
      this.course_save_loading = true;

      const course_data = this.course_co_po_form.value;

      if (!course_data.co_po_map || course_data.co_po_map.length === 0) {
        alert('Please fill mapping');
        return;
      }


      var co_po_map_to_save: any[] = [];

      for (const co_po_map of course_data.co_po_map) {
        co_po_map_to_save.push(...co_po_map.do_data);
      }

      for (var co_po_map of co_po_map_to_save) {
        co_po_map.course_code = this.course_code;
      }
      /* co_po_map_to_save.forEach(element => {
        //element.course_code = this.course.course_code;
      }) */
      /* const course_data_to_save: any = {
        _id: course_data._id,
        course_code: this.course.course_code,
        co_po_map: co_po_map_to_save
      }; */

      console.log(co_po_map_to_save);

      //const save_respose = await this.restService.updateCourse(course_data_to_save);
      const save_respose = await this.restService.createUpdateCOPOBulk(co_po_map_to_save);
      if (save_respose.success) {
        alert('Saved Successfully');
        // await this.searchCourse();
        this.onCOPOCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }

  async onCoursewareSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      if (!this.course) {
        return;
      }

      this.course_save_loading = true;

      const course_data = this.courseware_form.value;

      /* const course_data_to_save: any = {
        _id: course_data.course_id,
        course_code: this.course.course_code,
        course_details: course_data
        
      }; */

      var save_data = {
        "course_code": this.course.course_code,
        "course_outcome": course_data.course_outcome,
        "reference_book": course_data.reference_book,
        "text_book": course_data.text_book,
        "course_units": course_data.course_units,
        "practicals": course_data.practicals,
        "tutorials": course_data.tutorials,
        "web_reference": course_data.web_reference,
      }

      console.log();

      //const save_respose = await this.restService.updateCourse(course_data_to_save);
      const save_respose = await this.restService.createUpdateCourseware(save_data);
      if (save_respose.success) {
        alert('Saved Successfully');
        // await this.searchCourse();
        this.onCoursewareCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }


  async onExcelToJavasciptClick(uploaded_obj?: any): Promise<void> {
    if (this.course) {
      var val: string;
      if (uploaded_obj) {
        val = uploaded_obj;
      }
      else {
        val = this.course_co_po_form.value.bulk_update;
      }
      const rows = val.trim().split('\n');
      const cOPOMaps: ICOPOMap[] = [];
      if (rows.length === this.co_count) {
        let i = 0;
        let has_error = false;
        for (const row of rows) {
          const co_no = (i + 1);
          const cols = row.split('\t');
          if (cols.length !== this.po_count) {
            alert('PO\' in the CO ' + co_no + ' are not matching please copy and paste correctly');
            has_error = true;
            break;
          }
          let j = 0;
          for (const col of cols) {
            const cOPOMap = {} as ICOPOMap;
            cOPOMap.course_id = this.course._id;
            cOPOMap.co_id = co_no;
            cOPOMap.po_id = (j + 1);
            cOPOMap.score = parseInt(col, 10);
            cOPOMaps.push(cOPOMap);
            j++;
          }

          i++;
        }

        if (has_error) {
          return;
        }
      } else if (rows.length === (this.co_count * this.po_count)) {
        let skip = 0;
        let take = 7;
        let i = 0;
        for (let index = 0; index < this.co_count; index++) {
          const cols = rows.slice(skip, take);
          let j = 0;
          for (const col of cols) {
            const cOPOMap = {} as ICOPOMap;
            cOPOMap.course_id = this.course._id;
            cOPOMap.co_id = i + 1;
            cOPOMap.po_id = (j + 1);
            cOPOMap.score = parseInt(col, 10);
            cOPOMaps.push(cOPOMap);
            j++;
          }
          skip = skip + this.po_count;
          take = take + this.po_count;
          i++;
        }




      } else {
        alert('CO\'s are not matching please copy and paste correctly');
        return;
      }



      this.course = cOPOMaps;
      this.buildCOPOForm(this.course);
      this.calculatePOWeightage();

    }
  }

  uploadCOPOExcel() {

  }


  uploadSyllabusWordFile() {

  }

  bulkUpload(ev: any) {
    console.log(ev);
    var excel_array: any[] = ev;
    var upload_string: string = "";
    for (var i = 0; i < excel_array.length; i++) {
      var values = Object.values(excel_array[i]);
      console.log(values);
      for (var j = 1; j < values.length; j++) {
        upload_string += values[j];
        if (j != values.length - 1) {
          upload_string += "\t";
        }
      }
      upload_string += "\n"
    }
    console.log(upload_string);
    this.onExcelToJavasciptClick(upload_string);
  }



}

/* interface CourseInterface {

  _id: string;
  id: string;
  // most used
  offered_by_programme_type_id: string,
  offered_by_finance_type: string,
  offered_by_programme_id: string,

  part: string;
  allowed_programme_ids: string[], //only if PART is 1 or 4

  course_type: string;
  study_by_programme_type_id: string, //only if course_type is ALLIED
  study_by_programme_id: string, //only if course_type is ALLIED

  course_code: string;
  course_name: string;
  dependency_course_code: string,
  semester: string,

  //updated existing fields
  revision_year: number;
  position_no: number,
  exam_conducted: boolean,
  exam_duration: number,
  max_internal_mark: number,
  max_external_mark: number,


  //not in api return only for view render object
  co_po_map: ICOPOMap[];
  co_po_map_contribution: ICOPOContribution[];
  course_details: ICoursesDetails;

  //add controls for fields


  weekly_lecture_hours: number,
  weekly_tutorial_hours: number,
  weekly_practical_hours: number,
  credits: number,
  total_external_mark: number,
  approved: boolean,
  approved_by: string,
  is_common_course: boolean,


  // db defaults
  migration_flag: string;
  createdAt: Date;
  updatedAt: Date;
  __ref: string;


  // ui view


} */
