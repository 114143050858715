<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Entrance Exams</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-primary" (click)="onQuestionCatClick()">Manage Question Categories</button>
                </div>
                <ng-container *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin'])">
                    <ng-container *ngIf="this.admission_search_form.value.admission_id">
                        <div class="pr-1 mb-3 mb-xl-0">
                            <button type="button" class="btn btn-info" (click)="onAddExamClick()">Add New Exam</button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>


        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Admission Reports</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Admissions List</p> -->
                    </div>

                    <div class="card-body" *ngIf="admission_search_form">
                        <form [formGroup]="admission_search_form">
                            <div class="row row-sm">
                                <ng-container *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin'])">

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select regulation year</label>
                                            <select formControlName="regulation_year" class="form-control" (change)="onRegulationYearChange()">
                                                <option value="">Select regulation year</option>
                                                <option *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Admission</label>
                                        <select formControlName="admission_id" class="form-control" (change)="onAdmissionChange()">
                                            <option value="">Select admission</option>
                                            <option *ngFor="let admission of admissions_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="admission._id">{{admission.name}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>


                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th>Exam Name</th>
                                        <th style="width: 5%;">Active</th>
                                        <th style="width: 15%;">Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="entrance_exam_list.length>0">


                                        <ng-container *ngFor="let entrance_exam of entrance_exam_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="uppercase">{{entrance_exam.name}}</td>
                                                <td class="uppercase"> {{entrance_exam.active?"Yes":"No"}}</td>
                                                <td>
                                                    <ng-container *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin'])">
                                                        <button type="button" class="btn btn-sm btn-info" title="Edit Exam" (click)="onEditExamClick(entrance_exam)">
                                                            <fa-icon [icon]="fonts.faPen"></fa-icon>
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-info mg-l-10" title="View Patches" (click)="onViewPatchClick(entrance_exam)">
                                                            <fa-icon [icon]="fonts.faEye"></fa-icon>
                                                        </button>
                                                    </ng-container>
                                                    <button type="button" class="btn btn-sm btn-info mg-l-10" title="View Questions bank" (click)="onViewQuestonsClick(entrance_exam)">
                                                        <fa-icon [icon]="fonts.faList"></fa-icon>
                                                    </button>


                                                </td>



                                            </tr>
                                            <ng-container *ngIf="entrance_exam.show_patch">
                                                <tr>
                                                    <td colspan="4">
                                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width: 5%;">S.No</th>
                                                                    <th style="width: 15%;">Start Time</th>
                                                                    <th style="width: 5%;">Active</th>
                                                                    <th style="width: 5%;">Duration</th>

                                                                    <th style="width: 5%;">No of questions</th>
                                                                    <th style="width: 15%;">Allow Up To</th>
                                                                    <th style="width: 15%;">Closed</th>
                                                                    <th style="width: 15%;">Closed Time</th>
                                                                    <th style="width: 15%;">Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngIf="entrance_exam.exam_patches && entrance_exam.exam_patches.length>0">


                                                                    <ng-container *ngFor="let exam_patch of entrance_exam.exam_patches;let i=index;trackBy:fromDataResolver.identify;">
                                                                        <tr>
                                                                            <td scope="row">
                                                                                {{i+1}}

                                                                            </td>
                                                                            <td class="uppercase">{{exam_patch.start_time|date:commonEnums.date_time_format}}</td>
                                                                            <td class="uppercase"> {{exam_patch.active?"Yes":"No"}}</td>
                                                                            <td class="uppercase"> {{exam_patch.duration}}</td>
                                                                            <td class="uppercase"> {{exam_patch.no_of_questions}}</td>
                                                                            <td class="uppercase"> {{exam_patch.allow_applicant_reg_until|date:commonEnums.date_time_format}}</td>
                                                                            <td class="uppercase"> {{exam_patch.closed?'YES':'NO'}}</td>
                                                                            <td class="uppercase"> {{exam_patch.closed_time|date:commonEnums.date_time_format}}</td>
                                                                            <td>

                                                                                <button type="button" class="btn btn-sm btn-info" title="Edit Patch" (click)="onEditPatchClick(entrance_exam,exam_patch)">
                                                                                    <fa-icon [icon]="fonts.faPen"></fa-icon>
                                                                                </button>
                                                                                <button type="button" class="btn btn-sm btn-info mg-l-10" title="View Exam hall" (click)="onViewExamClick(entrance_exam,exam_patch)">
                                                                                    <fa-icon [icon]="fonts.faEye"></fa-icon>
                                                                                </button>

                                                                            </td>



                                                                        </tr>

                                                                    </ng-container>

                                                                </ng-container>
                                                                <ng-container *ngIf="entrance_exam.exam_patches.length==0">
                                                                    <tr>
                                                                        <th colspan="9" class="text-center">No Records found</th>
                                                                    </tr>
                                                                </ng-container>
                                                                <tr>
                                                                    <th colspan="9" class="text-center">
                                                                        <button type="button" class="btn btn-sm btn-info" (click)="onAddPatchClick(entrance_exam)">
                                                                            <fa-icon [icon]="fonts.faPlus"></fa-icon> Add new patch
                                                                        </button>

                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="!entrance_exam_list_loading && entrance_exam_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="entrance_exam_list_loading && entrance_exam_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>


    <!-- Large Modal -->
    <div class="modal" id="modal-exam">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo" *ngIf="entrance_exam_form">
                <div class="modal-header">
                    <h6 class="modal-title" *ngIf="!entrance_exam._id">Add New Exam</h6>
                    <h6 class="modal-title" *ngIf="entrance_exam._id">Update Exam</h6>

                </div>
                <div class="modal-body">
                    <div class="card card-body pd-20 pd-md-40">
                        <form [formGroup]="entrance_exam_form">

                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Exam Name</label>
                                <input class="form-control" required="" type="text" formControlName="name">
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programmes</label>
                                <select multiple formControlName="programme_ids" class="form-control">
                                    <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type=='self_finance'?' (SF)':' (R)'}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter description</label>
                                <textarea class="form-control" required="" formControlName="description" cols="30" rows="10"></textarea>
                            </div>
                            <div class="form-group justify-content-end">
                                <div class="checkbox">
                                    <div class="custom-checkbox custom-control">
                                        <input type="checkbox" class="custom-control-input" id="active" formControlName="active">
                                        <label for="active" class="custom-control-label mt-1">Is Active</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onExamSaveClick()" *ngIf="!entrance_exam_form_loading">Save</button>
                                <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="entrance_exam_form_loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>



                                <button class="btn ripple btn-secondary" (click)="onExamCloseClick()" type="button">Close</button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->


    <!-- Large Modal -->
    <div class="modal" id="modal-patch">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo" *ngIf="entrance_exam_patch_form">
                <div class="modal-header">
                    <h6 class="modal-title" *ngIf="!entrance_exam_patch._id">Add New Patch</h6>
                    <h6 class="modal-title" *ngIf="entrance_exam_patch._id">Update Patch</h6>

                </div>
                <div class="modal-body">
                    <div class="card card-body pd-20 pd-md-40">
                        <form [formGroup]="entrance_exam_patch_form">
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Name</label>
                                <input class="form-control" required="" type="text" formControlName="name">
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start Time</label>
                                <input class="form-control" required="" type="datetime-local" formControlName="start_time">
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Duration (In Mins)</label>
                                <input class="form-control" required="" type="number" formControlName="duration">
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter no. of questions</label>
                                <input class="form-control" required="" type="number" formControlName="no_of_questions">
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Allow Until Time</label>
                                <input class="form-control" required="" type="datetime-local" formControlName="allow_applicant_reg_until">
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programmes</label>
                                <select multiple formControlName="programme_ids" class="form-control">
                                    <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type=='self_finance'?' (SF)':' (R)'}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter description</label>
                                <textarea class="form-control" required="" formControlName="description" cols="30" rows="10"></textarea>
                            </div>
                            <div class="form-group justify-content-end">
                                <div class="checkbox">
                                    <div class="custom-checkbox custom-control">
                                        <input type="checkbox" class="custom-control-input" id="active" formControlName="active">
                                        <label for="active" class="custom-control-label mt-1">Is Active</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onExamPatchSaveClick()" *ngIf="!entrance_exam_patch_form_loading">Save</button>
                                <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="entrance_exam_patch_form_loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>



                                <button class="btn ripple btn-secondary" (click)="onExamPatchCloseClick()" type="button">Close</button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->


    <!-- Large Modal -->
    <div class="modal" id="modal-question-cat">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">Manage Question Category</h6>
                </div>
                <div class="modal-body">
                    <div class="">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th>Programme Name</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="programme_master_list.length>0">


                                        <ng-container *ngFor="let programme of programme_master_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="uppercase">{{programme.programme_name}} {{programme.finance_type == 'self_finance'?' (SF)': ' (R)'}}</td>
                                                <td>

                                                    <button type="button" class="btn btn-sm btn-info mg-l-10" title="View Categories" (click)="onViewQuestonsCatClick(programme)">
                                                        <fa-icon [icon]="fonts.faEye"></fa-icon>
                                                    </button>


                                                </td>



                                            </tr>
                                            <ng-container *ngIf="programme.show_details">
                                                <tr>
                                                    <td colspan="3">
                                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width: 5%;">S.No</th>
                                                                    <th>Category</th>
                                                                    <th style="width: 15%;">Percentage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngFor="let category of fromDataResolver.getFormArrayControlls(programme.category_form,'categories');let j=index;trackBy:fromDataResolver.identify;">
                                                                    <ng-container [formGroup]="category">
                                                                        <tr>
                                                                            <td>
                                                                                {{j+1}}
                                                                            </td>
                                                                            <td>
                                                                                {{category.value.category_id}}
                                                                            </td>
                                                                            <td>
                                                                                <input class="form-control" required="" type="number" formControlName="count">
                                                                            </td>
                                                                        </tr>

                                                                    </ng-container>
                                                                </ng-container>


                                                            </tbody>
                                                        </table>
                                                        <div class="mg-b-20"></div>
                                                        <div class="text-align-right">
                                                            <button class="btn btn-primary mg-r-20" (click)="onQuestionExamSaveClick(programme)" *ngIf="!question_cat_form_loading">Save</button>
                                                            <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="question_cat_form_loading">
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>



                                                            <button class="btn ripple btn-secondary" (click)="onQuestionCatCloseClick()" type="button">Close</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </ng-container>


                                    </ng-container>
                                    <ng-container *ngIf="!programme_master_list_loading && programme_master_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="programme_master_list_loading && programme_master_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->


</div>
<!-- /main-content -->
<app-footer></app-footer>