import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';

@Component({
  selector: 'app-programme-header',
  templateUrl: './programme-header.component.html',
  styleUrls: ['./programme-header.component.scss']
})
export class ProgrammeHeaderComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Programme Fees Settings List';
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_type_id: string = ""; //ngModel
  months: {
    name: string, val: number
  }[] = [{ name: "November", val: 11 }, { name: "April", val: 4 }];
  settings_list_loading: boolean = false;
  settings_list: SettingInterface[] = [];
  setting: SettingInterface = {} as SettingInterface;
  programme_list: any[] = [];
  collection_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  search_btn_pressed: boolean = false;
  enrolled_years: number[] = [];
  delete_loading: boolean = false;
  save_loading: boolean = false;
  generate_loading: boolean = false;
  fine_list_loading: boolean = false;
  fine_list: FineRuleInterface[] = [];
  ngOnInit(): void {
    this.getProgrammeDataList();
    this.getProgrammeTypeList();
    this.commonEnums.getLastNYears(10).forEach(year => {
      this.enrolled_years.push(year);
    });
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.getFineList();
  }

  async getFineList() //get from API
  {
    try {
      this.fine_list_loading = true;
      this.fine_list = [];
      const service_response = await this.restService.getFineList();
      if (service_response && service_response.success) {
        this.fine_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.fine_list_loading = false;
    }

  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
      console.log(this.programme_list);
    } catch (error) {
      alert('Error while read data');
    }
    console.log(this.programme_list);
  }

  async onFilterSelectChange(programme_type_id: string, event: any) {

  }

  onSearchClick() {
    if (this.programme_type_id != '') {
      this.getFeesSettingsList();
      this.search_btn_pressed = true;
    }
    else {
      alert("Please Choose Valid Programme Type");
    }

  }

  async getFeesSettingsList() {
    this.settings_list = [];
    this.settings_list_loading = true;
    try {
      const service_response = await this.restService.getProgrammeFeesHeaderList({ "programme_type_id": this.programme_type_id, fee_type: "normal" });
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.settings_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        term_name: "",
        id: "",
        start_date: "",
        end_date: "",
        description: "",
        extra_charge_amount: 0,
        month: 0,
        year: 0,
        active: false,
        programme_type_id: this.programme_type_id,
        enrolled_year_list: [],
        //fine_settings_id: "",
      };
    }
    var selected_years: number[] = [];
    this.setting.enrolled_year_list.forEach(year => {
      selected_years.push(year.enrolled_year);
    })
    this.collection_form = this.formBuilder.group({
      id: [this.setting.id],
      start_date: [this.setting.start_date != "" ? (this.setting?.start_date?.substring(0, 10)) : '', [Validators.required]],
      end_date: [this.setting.end_date != "" ? (this.setting?.end_date?.substring(0, 10)) : '', [Validators.required]],
      description: [this.setting.description, [Validators.required]],
      extra_charge_amount: [this.setting.extra_charge_amount, [Validators.required]],
      year: [this.setting.year, [Validators.required]],
      month: [this.setting.month, [Validators.required]],
      term_name: [this.setting.term_name],
      active: [this.setting.active],
      programme_type_id: [this.setting.programme_type_id],
      enrolled_year_list: [selected_years, [Validators.required]],
      //fine_settings_id: [this.setting.fine_settings_id]
    });
    console.log(this.collection_form.value);
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  getMonthId(id: string) {
    return parseInt(id);
  }
  async onEditClick(setting: any) {
    this.setting_id = setting.id; //term_name
    var settings = this.settings_list.find(x => x.id == setting.id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    }
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as SettingInterface;
    this.collection_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      this.collection_form.get('term_name')?.setValue(this.commonEnums.months.find(x => x.id == this.collection_form.get('month')?.value)?.text + " " + this.collection_form.get('year')?.value);
      var form_data = this.collection_form.value;
      form_data.start_date = new Date(this.collection_form.value.start_date).toISOString();
      form_data.end_date = new Date(this.collection_form.value.end_date).toISOString();
      form_data.programme_type_id = this.programme_type_id;
      /* if (!form_data.fine_settings_id) {
        form_data.fine_settings_id = "";
      } */
      //form_data.active=true;

      //generating enrolled year obj
      var enrolled_year_list: number[] = this.collection_form.value.enrolled_year_list;
      var enrolled_year_obj: IProgrammeFeesPreparationStatus[] = [];
      for (var i = 0; i < enrolled_year_list.length; i++) {
        if (this.collection_form.value.id) {
          if (this.setting.enrolled_year_list.findIndex(x => x.enrolled_year == enrolled_year_list[i]) >= 0) {
            enrolled_year_obj.push({
              enrolled_year: enrolled_year_list[i],
              generated: this.setting.enrolled_year_list.find(x => x.enrolled_year == enrolled_year_list[i])?.generated
            });
          }
          else {
            enrolled_year_obj.push({
              enrolled_year: enrolled_year_list[i],
              generated: false
            });
          }
        }
        else {
          enrolled_year_obj.push({
            enrolled_year: enrolled_year_list[i],
            generated: false
          });
        }
      }
      form_data.enrolled_year_list = enrolled_year_obj;
      console.log(form_data);
      this.save_loading = true;
      if (this.collection_form.value.id) {
        const res = await this.restService.updateProgrammeFeesHeader(this.collection_form.value.id, form_data);
        if (!res) {
          alert("Error! Could not update Programme Fee")
        }
        alert("Programme Fee updated successfully");
      }
      else {
        const res = await this.restService.createProgrammeFeesHeader(form_data);
        if (!res) {
          alert("Error! Could not create Programme Fee")
        }
        alert("Programme Fee created successfully");
      }
      this.getFeesSettingsList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      this.delete_loading = true;
      const res = await this.restService.deleteProgrammeFeeHeader(this.collection_form.get('id')!.value);
      if (res.success) {
        alert("Programme fee header deleted successfully");
      }
      else {
        alert("Error! Could not delete Programme fee header");
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.delete_loading = false;
    }

  }

  isInvalid(formControl: string): boolean {
    return this.collection_form.touched && this.collection_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.collection_form.status !== 'VALID';
  }

  navigateToProgrammeFeeSettings(setting: any, enrolled_year: number) {
    HTMLHelper.openLinkInNewTab("/admin/programmes/programme-fee-setting?setting_id=" + setting.id
      + "&enrolled_year=" + enrolled_year
      + "&programme_type_id=" + this.programme_type_id,
    );

    /* this.router.navigate(["/admin/programmes/programme-fee-setting"], {
      queryParams: {
        setting_id: setting.id,
        programme_type_id: this.programme_type_id,
        enrolled_year: enrolled_year
      }
    }); //pass setting.id */
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  openFeeSettings(setting) {
    setting.is_opened = true;
  }

  closeFeeSettings(setting) {
    setting.is_opened = false;
  }

  async generateFees(header_id: string, enrolled_year: number) {
    var data = {
      header_id: header_id,
      enrolled_year: enrolled_year
    };
    console.log(data);

    try {
      const consent = confirm('Are you sure do you want to generate Fees?');
      if (!consent) {
        return;
      }
      this.generate_loading = true;
      const res = await this.restService.generateProgrammeFees(data);
      if (res.success) {
        this.onSearchClick();
        alert("Programme fee generated successfully");
      }
      else {
        alert("Error! Could not generate Programme fees");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.generate_loading = false;
    }

  }
}

interface SettingInterface {
  term_name: string,
  programme_type_id?: string,
  start_date: string,
  id?: string,
  end_date: string,
  description: string,
  extra_charge_amount: number,
  month: number,
  year: number,
  //fine_settings_id?: string,
  active: boolean,
  fee_type?: string,
  enrolled_year_list: IProgrammeFeesPreparationStatus[]
}

interface IProgrammeFeesPreparationStatus {
  _id?: string,
  enrolled_year: number;
  generated: boolean;
}


interface FineRuleInterface {
  _id: string,
  fine_name: string,
  re_admission_fee: number,
  fine_rules: {
    day_limit: number,
    fine_amount: number,
    is_per_day: boolean
  }[],
  post_fine_rule: string
}