<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid mg-t-30">




    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0 no-print">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">{{getStatusDisplayText(status) | uppercase}} List : {{programme_name}}</h4>
              <ng-container
                *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
                <div class="d-flex my-xl-auto right-content">
                  <!-- <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-sm btn-info " (click)="onPrintClick()">Print</button>
                  </div> -->


                  <ng-container *ngIf='status=="enrolled"'>
                    <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-sm btn-info " (click)="navigateToCertificateTracker()">
                        Certificate Tracker
                      </button>
                    </div>
                    <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-sm btn-success " (click)="onRollNoClick()">
                        <ng-container *ngIf="this.rollno_generation_loading">
                          Please wait...
                        </ng-container>
                        <ng-container *ngIf="!this.rollno_generation_loading">
                          Generate Rollno
                        </ng-container>
                      </button>
                    </div>
                    <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-sm btn-success " (click)="onGenerateEmailClick()">
                        <ng-container *ngIf="this.email_generation_loading">
                          Please wait...
                        </ng-container>
                        <ng-container *ngIf="!this.email_generation_loading">
                          Generate Email
                        </ng-container>
                      </button>
                    </div>
                    <div class="pr-1 mb-3 mb-xl-0">
                      <div class="dropdown">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm ripple btn-info"
                          data-toggle="dropdown" id="dropDownMenuButton" type="button">Download Mail Template<i
                            class="fas fa-caret-down ml-1"></i></button>
                        <div class="dropdown-menu tx-13">
                          <a class="dropdown-item" (click)="downloadGoogleMailTemplate()">Google Mail</a>
                          <!-- <a class="dropdown-item" (click)="downloadPDF()">Download PDF</a> -->
                          <!-- <a class="dropdown-item" (click)="downloadDBF()">Download DBF</a> -->
                        </div>
                      </div>
                    </div>



                  </ng-container>

                  <div class="pr-1 mb-3 mb-xl-0" *ngIf="status == 'in_applied' || status == 'enrolled' ">
                    <button type="button" class="btn btn-sm btn-primary " (click)="downloadEnrolledUsers()">Download
                      List</button>
                  </div>

                  <ng-container *ngIf="status == 'merit_list' || status=='verified' ">
                    <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-sm btn-info " (click)="printMeritList()">Print
                        List</button>
                    </div>

                    <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-sm btn-primary " (click)="downloadMeritListCSV()">Download
                        List</button>
                    </div>
                  </ng-container>

                </div>


              </ng-container>

            </div>
          </div>
          <div class="card-body no-print" *ngIf="admission_search_form">
            <form [formGroup]="admission_search_form">
              <div class="row row-sm">



                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Gender</label>
                    <select formControlName="gender" class="form-control">
                      <option value="">Select Gender</option>
                      <option *ngFor="let gender of commonEnums.genders;let i=index;trackBy:formDataResolver.identify;"
                        [ngValue]="gender.id">{{gender.text}}</option>
                    </select>
                  </div>
                </div>

                <ng-container *ngIf="status=='merit_list'">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Total Mark Filter</label>
                      <input type="text" class="form-control" placeholder="Enter Total Mark Filter Value"
                        formControlName="total_filter">
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <button class="mg-t-30 btn btn-info btn-md" (click)="totalMarkFilterClick()">Filter Total</button>
                    </div>
                  </div>


                  <ng-container *ngIf="programme_id=='3cde4b49-5dc5-43f4-9e5c-16df1abbd464'">
                    <div class="col-lg-2">
                      <div class="form-group">
                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Subject Type</label>
                        <select formControlName="subject_type" class="form-control">
                          <option value="">All</option>
                          <option value="computer_science">Computer Science</option>
                          <option value="non_computer_science">Non Computer Science</option>
                        </select>
                      </div>
                    </div>
                  </ng-container>


                </ng-container>

                <div class="col-lg-1">
                  <div class="form-group mg-t-25">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="apply_date_filter"
                        formControlName="apply_date_filter">
                      <label class="custom-control-label" for="apply_date_filter">Apply date filter</label>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="admission_search_form.value.apply_date_filter">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start Date</label>
                      <input class="form-control" required="" type="datetime-local" formControlName="start_date">
                    </div>
                  </div>
                  <div class="col-lg-2">

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End Date</label>
                      <input class="form-control" required="" type="datetime-local" formControlName="end_date">
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf='status=="in_applied"'>
                  <div class="col-lg-2">
                    <div class="form-group mg-t-25">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="skip_status"
                          formControlName="skip_status">
                        <label class="custom-control-label" for="skip_status">Include all applicants (skip
                          status)</label>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf='status=="verified"'>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Entrance Option</label>
                      <select formControlName="entrance_option" class="form-control">
                        <option value="">All</option>
                        <option value="appeared">Entrance Appeared</option>
                        <option value="not_appeared">Entrance not appeared</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Community</label>
                      <select formControlName="community" class="form-control"
                        (change)="onCommunityFilterChange($event)">
                        <option value="">All</option>
                        <option value="open_competition">Open</option>
                        <option value="bc/bcm">BC/BCM</option>
                        <option value="mbc/dnc">MBC/DNC</option>
                        <option value="sc/st/sca">SC/ST/SCA</option>
                      </select>
                    </div>
                  </div>

                  <ng-container *ngIf="programme_id=='3cde4b49-5dc5-43f4-9e5c-16df1abbd464'">
                    <div class="col-lg-2">
                      <div class="form-group">
                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Subject Type</label>
                        <select formControlName="subject_type" class="form-control">
                          <option value="">All</option>
                          <option value="computer_science">Computer Science</option>
                          <option value="non_computer_science">Non Computer Science</option>
                        </select>
                      </div>

                    </div>

                  </ng-container>

                </ng-container>
                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!applicant_list_loading" (click)="getApplicantsWithGivenStatus()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="applicant_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>


              </div>
            </form>
          </div>
          <div class="card-body">
            <div class="row mg-b-10" *ngIf="status == 'in_applied' || status =='verification_list' ">
              <div class="col-md-12">
                <button class="btn btn-md btn-primary no-print" (click)="approveAllApplicants('merit_list')"
                  *ngIf="!approve_all_loading && status =='in_applied'" style="float: right;">Approve All</button>
                <button class="btn btn-md btn-primary no-print" (click)="approveAllApplicants('verified')"
                  *ngIf="!approve_all_loading && status =='verification_list'" style="float: right;">Verify All</button>
                <button class="btn btn-md btn-primary no-print" disabled *ngIf="approve_all_loading"
                  style="float: right;">Loading...</button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="printable" class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" style="width:5%;">S.No</th>
                    <th class="text-center" class="no-print" style="width:10%;">Date.</th>
                    <ng-container *ngIf='status!="in_applied"'>
                      <ng-container
                        *ngIf='status!="ready_to_pay" && status!="payment_delayed" && status!="paid" && status!="enrolled"'>
                        <th class="no-print" style="width:15%;">
                          Action
                          <ng-container *ngIf='status=="merit_list"'>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="selection_all"
                                (change)="onSlectAllCandidatesClick($event)">
                              <label class="custom-control-label" for="selection_all"></label>
                            </div>
                          </ng-container>
                        </th>
                      </ng-container>
                      <ng-container
                        *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">

                        <ng-container
                          *ngIf='status=="verified" || status=="ready_to_pay" || status=="payment_delayed" || status=="paid"'>
                          <th class="no-print" style="width:15%;">
                            Selection
                            <ng-container *ngIf='status =="verified" || status =="ready_to_pay" '>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="selection_all"
                                  (change)="onSlectAllCandidatesClick($event)">
                                <label class="custom-control-label" for="selection_all"></label>
                              </div>
                            </ng-container>

                            <ng-container *ngIf=' status=="paid"'>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="selection_all"
                                  (change)="onSlectAllClick($event)">
                                <label class="custom-control-label" for="selection_all"></label>
                              </div>
                            </ng-container>
                          </th>

                        </ng-container>
                        <ng-container *ngIf='status=="ready_to_pay" && finance_type!="self_finance"'>
                          <th class="no-print" style="width:15%;">
                            Offline Due
                          </th>

                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <th class="text-center" style="width:10%;">App. No.</th>

                    <ng-container *ngIf='status=="enrolled"'>
                      <th class="text-center" style="width:10%;">Roll No.</th>
                      <th class="text-center" style="width:30%;">Email</th>
                    </ng-container>

                    <ng-container *ngIf='status!="enrolled"'>

                      <th class="text-center" class="no-print" style="width:10%;">App. Date.</th>
                    </ng-container>
                    <ng-container *ngIf="admission_search_form.value.skip_status">
                      <th class="text-center" style="width:10%;">Status</th>
                    </ng-container>

                    <th class="text-center" style="width:15%;">Name</th>
                    <th class="text-center" style="width:15%;"
                      [ngClass]="{'no-print':isStatusWisePrintNeeded('Mob. No.')}">Mob. No.</th>
                    <th class="text-center no-print" style="width:15%;">Date Of Birth</th>
                    <th class="text-center" style="width:10%;"
                      [ngClass]="{'no-print':isStatusWisePrintNeeded('Community')}">Community</th>
                    <th class="text-center" style="width:10%;"
                      [ngClass]="{'no-print':isStatusWisePrintNeeded('Caste')}">Caste</th>
                    <ng-container *ngIf='status!="enrolled"'>
                      <th class="text-center" style="width:25%;"
                        [ngClass]="{'no-print':isStatusWisePrintNeeded('Institute')}">Institute</th>
                      <th class="text-center no-print">Institute Reg.No.</th>
                      <th class="text-center no-print">Gender</th>
                      <ng-container *ngIf="applicant_list &&applicant_list.length>0">
                        <ng-container
                          *ngFor="let mark of applicant_list[0].marks;let i=index;trackBy:formDataResolver.identify;">
                          <th class="text-center" [ngClass]="{'no-print':isPrintNeeded(mark.title)}">{{mark.title}}</th>
                        </ng-container>
                      </ng-container>

                      <th [ngClass]="{'no-print':isStatusWisePrintNeeded('Remarks')}">Remarks</th>
                    </ng-container>
                    <ng-container *ngIf='status=="ready_to_pay"'>
                      <th class="text-center ">Date</th>
                      <th class="text-center ">Signature</th>
                    </ng-container>
                    <th *ngIf='status=="ready_to_pay" || status=="payment_delayed"'>
                      Manual Payment
                    </th>
                  </tr>
                </thead>
                <tbody>



                  <ng-container *ngFor="let applicant of applicant_list;let i=index;trackBy:formDataResolver.identify;">
                    <tr (click)="onStartVerifyClick(applicant)" style="cursor: pointer;">
                      <td scope="row">
                        {{i+1}}

                      </td>
                      <ng-container>
                        <td class="no-print uppercase">{{applicant.updatedAt|date:commonEnums.date_format}}</td>
                      </ng-container>
                      <ng-container *ngIf='status!="in_applied"'>
                        <ng-container
                          *ngIf='status!="ready_to_pay" && status!="payment_delayed" && status!="paid" && status!="enrolled" && status!="merit_list"'>
                          <td class="no-print">
                            <ng-container *ngIf='status=="verification_list"'>
                              <button style="width: 80px;" class='btn btn-sm btn-info'
                                (click)="onRejectApplicantClick(applicant)">Reject</button>
                            </ng-container>

                            <ng-container *ngIf='status=="rejected"'>
                              <button style="width: 80px;" class='btn btn-sm btn-info'
                                (click)="onUnRejectClick(applicant)">Un Reject</button>
                            </ng-container>
                            <ng-container *ngIf='status=="verified"'>
                              <button style="width: 80px;" class='btn btn-sm btn-info'
                                (click)="onUnVerifyClick(applicant)">Un Verify</button>
                            </ng-container>

                          </td>
                        </ng-container>
                        <ng-container
                          *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin', 'admission_member'])">

                          <ng-container
                            *ngIf='status=="verified" || status=="ready_to_pay" || status=="payment_delayed" || status=="paid" || status=="merit_list"'>
                            <td class="no-print">
                              <ng-container
                                *ngIf='status=="verified" || status=="ready_to_pay" || status=="payment_delayed" || status=="merit_list"'>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="selection_{{i}}"
                                    (change)="onSlectionClick(applicant,$event)">
                                  <label class="custom-control-label" for="selection_{{i}}"></label>
                                </div>
                              </ng-container>
                              <ng-container *ngIf='status=="paid"'>
                                {{applicant.selected ?'YES':'NO'}}
                              </ng-container>
                            </td>
                          </ng-container>
                          <ng-container *ngIf='status=="ready_to_pay" && finance_type!="self_finance"'>
                            <td class="no-print">
                              <ng-container
                                *ngIf="applicant.payment_term_1_cleared && !applicant.payment_term_2_cleared">
                                Cleared Due 1
                                <button class='btn btn-sm btn-info' (click)="onOfflineDue2ClearClick(applicant)">Clear
                                  Offline Due 2</button>
                              </ng-container>
                              <ng-container
                                *ngIf="applicant.payment_term_1_cleared && applicant.payment_term_2_cleared">
                                Cleared Due 1 & 2
                              </ng-container>
                              <ng-container *ngIf="!applicant.payment_term_1_cleared">
                                <ng-container *ngIf="!applicant.enrolled_already">
                                  <button class='btn btn-sm btn-info' (click)="onOfflineDue1ClearClick(applicant)">Clear
                                    Offline Due 1</button>
                                </ng-container>
                                <ng-container *ngIf="applicant.enrolled_already">
                                  N/A <fa-icon [icon]="fonts.faCheckCircle" class="side-bar-icon" style="color: green;"
                                    title="Already Entrolled with Applicaiton No {{applicant.enrolled_already_application_no}}">
                                  </fa-icon>
                                </ng-container>


                              </ng-container>
                            </td>

                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <td class="uppercase">{{applicant.application_no}}

                        <ng-container *ngIf="applicant.short_listed_already">
                          <fa-icon [icon]="fonts.faCheckCircle" class="side-bar-icon" style="color: green;"
                            title="Already In Ready To Pay State with Applicaiton No {{applicant.short_listed_already_application_no}}">
                          </fa-icon>
                        </ng-container>

                      </td>
                      <ng-container *ngIf='status=="enrolled"'>
                        <td class="uppercase">{{applicant.roll_no}}

                        </td>
                        <td class="lowercase">
                          {{applicant.education_email}}
                        </td>
                      </ng-container>

                      <ng-container *ngIf='status!="enrolled"'>

                        <td class="no-print uppercase">{{applicant.createdAt|date:commonEnums.date_format}}</td>
                      </ng-container>
                      <ng-container *ngIf="admission_search_form.value.skip_status">
                        <td class="uppercase">{{applicant.status}}</td>
                      </ng-container>
                      <td class="uppercase">{{applicant.name}}</td>
                      <td class="uppercase" [ngClass]="{'no-print':isStatusWisePrintNeeded('Mob. No.')}">

                        {{applicant.mobile_number}},
                        <ng-container *ngIf="applicant.mobile_number_2">

                          <br> {{applicant.mobile_number_2}},
                        </ng-container>
                        <ng-container *ngIf="applicant.mobile_number_3">
                          <br>{{applicant.mobile_number_3}},
                        </ng-container>
                        <ng-container *ngIf="applicant.mobile_number_4">
                          <br>{{applicant.mobile_number_4}},
                        </ng-container>
                        <ng-container *ngIf="applicant.mobile_number_5">
                          <br>{{applicant.mobile_number_5}}
                        </ng-container>
                      </td>
                      <td class="uppercase no-print">{{applicant.birth_date | date:commonEnums.date_format}}</td>
                      <td class="uppercase" [ngClass]="{'no-print':isStatusWisePrintNeeded('Community')}">
                        {{applicant.community_id}}</td>
                      <td class="uppercase" [ngClass]="{'no-print':isStatusWisePrintNeeded('Caste')}">
                        {{applicant.caste}}</td>
                      <ng-container *ngIf='status!="enrolled"'>
                        <td class="uppercase" [ngClass]="{'no-print':isStatusWisePrintNeeded('Institute')}">
                          {{applicant.institute.substring(0,20)}}</td>
                        <td class="uppercase no-print">{{applicant.institute_reg_no}}</td>
                        <td class="uppercase no-print">{{applicant.gender}}</td>

                        <ng-container
                          *ngFor="let mark of applicant.marks;let i=index;trackBy:formDataResolver.identify;">
                          <td [ngClass]="{'no-print':isPrintNeeded(mark.title)}">{{mark.mark | number}}</td>
                        </ng-container>
                        <td class="uppercase" [ngClass]="{'no-print':isStatusWisePrintNeeded('Remarks')}">
                          {{applicant.remarks}}</td>
                      </ng-container>
                      <ng-container *ngIf='status=="ready_to_pay"'>
                        <td></td>
                        <td></td>
                      </ng-container>
                      <td *ngIf='status=="ready_to_pay" || status=="payment_delayed"'>
                        <button class='btn btn-sm btn-info' (click)="onOnlineDueClearClick(applicant)">Clear Online
                          Due</button>
                      </td>

                    </tr>
                  </ng-container>
                  <ng-container *ngIf="!applicant_list_loading && applicant_list.length==0">
                    <tr>
                      <th colspan="14" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="applicant_list_loading && applicant_list.length==0">
                    <tr>
                      <th colspan="14" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>



                </tbody>
              </table>
            </div>
          </div>

          <ng-container
            *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">


            <div class="no-print">

              <ng-container *ngIf="status=='merit_list'">
                <div class="bottom-bar">
                  There are <b>{{selection_list.length}}</b> candidates in Merit list

                  <button class="btn btn-sm btn-warning" [attr.disabled]="selection_list.length>0 ? null : ''"
                    (click)="onViewSelectedClick()">View and allow to Verification List</button>
                </div>
              </ng-container>

              <ng-container *ngIf="status=='verified'">
                <div class="bottom-bar">
                  There are <b>{{selection_list.length}}</b> candidates in selected list

                  <button class="btn btn-sm btn-warning" [attr.disabled]="selection_list.length>0 ? null : ''"
                    (click)="onViewSelectedClick()">View And Allow Payment</button>
                </div>
              </ng-container>
              <ng-container *ngIf="status=='ready_to_pay'">
                <div class="bottom-bar">
                  There are <b>{{selection_list.length}}</b> candidates in selected list

                  <button class="btn btn-sm btn-warning" [attr.disabled]="selection_list.length>0 ? null : ''"
                    (click)="onViewSelectedClick()">View And Dis-Allow Payment</button>
                </div>
              </ng-container>
              <ng-container *ngIf="status=='payment_delayed'">
                <div class="bottom-bar">
                  There are <b>{{selection_list.length}}</b> candidates in selected list

                  <button class="btn btn-sm btn-warning" [attr.disabled]="selection_list.length>0 ? null : ''"
                    (click)="onViewSelectedClick()">View And Allow Payment</button>
                </div>
              </ng-container>
              <ng-container *ngIf="status=='paid'">
                <div class="bottom-bar">
                  There are <b>{{selection_list.length}}</b> candidates in selected list

                  <button class="btn btn-sm btn-warning" [attr.disabled]="selection_list.length>0 ? null : ''"
                    (click)="onViewSelectedClick()">Enroll</button>
                </div>
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>


    </div>

  </div>


  <!-- Large Modal -->
  <div class="modal" id="modal-applicant-details">
    <div class="modal-dialog modal-lg big-modal" role="document">
      <div class="modal-content modal-content-demo" *ngIf="user_info">
        <div class="modal-header">
          <h6 class="modal-title">Currently Verifing : {{user_info.name}}</h6>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="row row-sm">
              <ng-container [formGroup]="user_form">

                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-8 col-lg-8">
                          <div class="form-group">
                            <ng-container *ngIf='user_form.value.aadhar_card_no_file_front'>
                              <div>Front Side </div>
                              <a href="{{user_form.value.aadhar_card_no_file_front}}" target="_blank">
                                <app-ngx-image-zoom [thumbWidth]='800' [thumbHeight]='400'
                                  [thumbImage]='user_form.value.aadhar_card_no_file_front'
                                  [fullImage]='user_form.value.aadhar_card_no_file_front'></app-ngx-image-zoom>

                              </a>
                            </ng-container>
                          </div>
                          <div class="form-group">
                            <ng-container *ngIf='user_form.value.aadhar_card_no_file_back'>
                              <div>Back Side</div>
                              <a href="{{user_form.value.aadhar_card_no_file_back}}" target="_blank">
                                <app-ngx-image-zoom [thumbWidth]='800' [thumbHeight]='400'
                                  [thumbImage]='user_form.value.aadhar_card_no_file_back'
                                  [fullImage]='user_form.value.aadhar_card_no_file_back'></app-ngx-image-zoom>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                          <div class="form-group">
                            <label for="blood_group">AADHAR card number<small style="color: red;"> *</small></label>
                            <input class="form-control" placeholder="AADHAR card number" type="text"
                              formControlName='aadhar_card_no'>

                          </div>
                          <div class="form-group">
                            <label>Full Name <small style="color: red;">
                                *</small></label>

                            <div class="row mt-0">
                              <div class="col-3">
                                <input class="form-control" placeholder="Inital" type="text"
                                  formControlName='middle_name'>
                              </div>
                              <div class="col-9">
                                <input class="form-control" placeholder="Full Name" type="text"
                                  formControlName='given_name'>
                              </div>

                            </div>



                          </div>
                          <div class="form-group">
                            <label>Date of Birth </label>
                            <input class="form-control" type="date" formControlName='birth_date'>
                          </div>
                          <div class="form-group">
                            <label>Gender </label> <br>
                            <div class="mg-t-5">
                              <div class="custom-control custom-radio" style="display: inline;">
                                <input id="male" type="radio" class="custom-control-input" value="male" name="gender"
                                  formControlName="gender">
                                <label class="custom-control-label" for="male">Male</label>
                              </div>

                              <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                                <input id="female" type="radio" class="custom-control-input" value="female"
                                  name="gender" formControlName="gender">
                                <label class="custom-control-label" for="female">Female</label>
                              </div>
                              <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                                <input id="transgender" type="radio" class="custom-control-input" value="transgender"
                                  name="gender" formControlName="gender">
                                <label class="custom-control-label" for="transgender">Transgender</label>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="blood_group">Blood Group<small style="color: red;"> *</small></label>

                            <select class="form-control " id="blood_group" name="blood_group"
                              formControlName="blood_group">
                              <option value="">Choose your Blood Group</option>
                              <option
                                *ngFor="let blood_group of commonEnums.blood_groups;let i=index;trackBy:formDataResolver.identify;"
                                [ngValue]="blood_group.id">
                                {{blood_group.text}}
                              </option>
                            </select>
                          </div>
                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="physically_challanged"
                                name="physically_challanged" formControlName="physically_challanged">
                              <label class="custom-control-label" for="physically_challanged">Are you physically
                                disabled?</label>
                            </div>


                          </div>
                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="ex_service" name="ex_service"
                                formControlName="ex_service">
                              <label class="custom-control-label" for="ex_service">Are you son/daughter
                                of Ex-Service man?</label>
                            </div>


                          </div>
                          <ng-container *ngIf="this.user_form.value.ex_service==true">



                            <div class="form-group">
                              <label for="ex_service_rank">Ex-Service Man Rank<small style="color: red;">
                                  *</small></label>
                              <input type="text" id="ex_service_rank" name="ex_service_rank" class="form-control"
                                formControlName="ex_service_rank">

                            </div>


                            <div class="form-group">
                              <label for="ex_service_i_card_no">Ex-Service Man I-Card Number<small style="color: red;">
                                  *</small></label>
                              <input type="text" id="ex_service_i_card_no" name="ex_service_i_card_no"
                                class="form-control" formControlName="ex_service_i_card_no">

                            </div>



                          </ng-container>

                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="sports_certs" name="sports_certs"
                                formControlName="sports_certs">
                              <label class="custom-control-label" for="sports_certs">Have you participated in
                                Sports Event(s)
                                ?</label>
                            </div>


                          </div>

                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="need_hostel" name="need_hostel"
                                formControlName="need_hostel">
                              <label class="custom-control-label" for="need_hostel">Do you need Hostel
                                Facility?</label>
                            </div>


                          </div>
                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="need_bus" name="need_bus"
                                formControlName="need_bus">
                              <label class="custom-control-label" for="need_bus">Do you need Transportation
                                Facility?</label>
                            </div>


                          </div>
                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="is_applicant_married"
                                name="is_applicant_married" formControlName="is_applicant_married">
                              <label class="custom-control-label" for="is_applicant_married">Is Applicant
                                Married?</label>
                            </div>


                          </div>
                        </div>

                      </div>

                    </div>
                  </div>


                </div>

                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="main-content-label tx-13 mg-b-25">
                        Conatct
                      </div>
                      <div class="main-profile-contact-list">
                        <div class="row">
                          <div class="col-md-3 col-lg-3">
                            <label>Father's / Guardian's Mobile Number <small style="color: red;">
                                *</small></label>
                            <input class="form-control" placeholder="Mobile Number" type="text"
                              formControlName='mobile_number'>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <label>Applicant Mobile Number <small style="color: red;">
                                *</small></label>
                            <input class="form-control" placeholder="Applicant Mobile Number" type="text"
                              formControlName='mobile_number_2'>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <label>Alternate Contact No. 2 </label>
                            <input class="form-control" placeholder="Alternate Contact No. 2" type="text"
                              formControlName='mobile_number_3'>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <label>Alternate Contact No. 3</label>
                            <input class="form-control" placeholder="Alternate Contact No. 3" type="text"
                              formControlName='mobile_number_4'>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <label>Alternate Contact No. 4</label>
                            <input class="form-control" placeholder="Alternate Contact No. 4" type="text"
                              formControlName='mobile_number_5'>
                          </div>
                        </div>
                        <div class="mt-3"></div>

                        <div class="row">
                          <div class="col-md-3 col-lg-3">
                            <label>Email <small style="color: red;">
                                *</small></label>
                            <input class="form-control" placeholder="Email" type="text" formControlName='email'>
                          </div>

                        </div>
                      </div><!-- main-profile-contact-list -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">

                      <div class="mb-4 main-content-label">Nationality and Address</div>
                      <p class="mb-25">Enter your communication address, This will be used to send any official letter
                        from college later.</p>
                      <div class="row">
                        <div class="col-md-8 col-lg-8">
                          <div class="form-group">
                            <ng-container *ngIf='user_form.value.aadhar_card_no_file_front'>
                              <div>Front Side </div>
                              <a href="{{user_form.value.aadhar_card_no_file_front}}" target="_blank">
                                <app-ngx-image-zoom [thumbWidth]='800' [thumbHeight]='400'
                                  [thumbImage]='user_form.value.aadhar_card_no_file_front'
                                  [fullImage]='user_form.value.aadhar_card_no_file_front'></app-ngx-image-zoom>
                              </a>
                            </ng-container>
                          </div>
                          <div class="form-group">
                            <ng-container *ngIf='user_form.value.aadhar_card_no_file_back'>
                              <div>Back Side</div>
                              <a href="{{user_form.value.aadhar_card_no_file_back}}" target="_blank">
                                <app-ngx-image-zoom [thumbWidth]='800' [thumbHeight]='400'
                                  [thumbImage]='user_form.value.aadhar_card_no_file_back'
                                  [fullImage]='user_form.value.aadhar_card_no_file_back'></app-ngx-image-zoom>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-4">

                          <div class="form-group">
                            <label for="nationality">Nationality<small style="color: red;">
                                *</small></label>
                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="indian" type="radio" class="custom-control-input" value="indian"
                                name="nationality" formControlName="nationality">
                              <label class="custom-control-label" for="indian">Indian</label>
                            </div>

                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="others" type="radio" class="custom-control-input" value="others"
                                name="nationality" formControlName="nationality">
                              <label class="custom-control-label" for="others">Others</label>
                            </div>

                            <ng-container formGroupName="address">
                              <div
                                *ngIf="formDataResolver.getValueFromFromGroup(this.user_form,'nationality')=='others'">
                                <div class="form-group">
                                  <label for="country">Select your Country<small style="color: red;">
                                      *</small></label>
                                  <select class="form-control " id="country" name="country" formControlName="country">
                                    <option
                                      *ngFor="let country of commonEnums.country_list;let i=index;trackBy:formDataResolver.identify;"
                                      [ngValue]="country">
                                      {{country}}
                                    </option>
                                  </select>

                                </div>

                              </div>
                              <div class="form-group">

                                <ng-container
                                  *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'country')=='India'">

                                  <label for="state">Select your state<small style="color: red;">
                                      *</small></label>
                                  <select class="form-control " id="state" name="state" formControlName="state">
                                    <option
                                      *ngFor="let india_state of commonEnums.india_states;let i=index;trackBy:formDataResolver.identify;"
                                      [ngValue]="india_state">
                                      {{india_state}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'country')!='India'">
                                  <label for="state">State<small style="color: red;"> *</small></label>
                                  <input type="text" id="state" name="state" class="form-control"
                                    formControlName="state" appFormControlValidationMsg validationMsgId="state">
                                </ng-container>

                              </div>
                              <div class="form-group">
                                <ng-container
                                  *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'state')=='Tamil Nadu'">

                                  <label for="district">Select your district<small style="color: red;">
                                      *</small></label>
                                  <select class="form-control " id="district" name="district"
                                    formControlName="district">
                                    <option
                                      *ngFor="let district_in_tamil_nadu of commonEnums.districts_in_tamil_nadu;let i=index;trackBy:formDataResolver.identify;"
                                      [ngValue]="district_in_tamil_nadu">
                                      {{district_in_tamil_nadu}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'state')!='Tamil Nadu'">
                                  <label for="district">District<small style="color: red;"> *</small></label>
                                  <input type="text" id="district" name="district" class="form-control"
                                    formControlName="district">
                                </ng-container>



                              </div>
                              <div class="form-group">

                                <label for="city">City<small style="color: red;"> *</small></label>
                                <input type="text" id="city" name="city" class="form-control" formControlName="city">
                              </div>
                              <div class="form-group">
                                <label for="pincode">Pincode<small style="color: red;"> *</small></label>
                                <input type="text" id="pincode" name="pincode" class="form-control"
                                  formControlName="pincode">

                              </div>
                              <div class="form-group">
                                <label for="street">Enter the residential address <small style="color: red;">
                                    *</small></label>
                                <input type="text" id="street" name="street" class="form-control"
                                  formControlName="street">
                                <small id="mobile_number_3_help_line" class="text-muted d-block">
                                  Enter door number, street name and other landmarks.
                                </small>
                              </div>


                            </ng-container>



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="mb-4 main-content-label">Identification Marks</div>
                      <p class="mb-25">Fill your idendication mark one by one, this will be used in your
                        certifficates. (Required to fill atleast one)</p>
                      <div class="row">
                        <div class="col-xl-12">
                          <ng-container formArrayName="identification_marks">
                            <div
                              *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form,'identification_marks'); let i=index;trackBy:formDataResolver.identify;">
                              <div class="form-group">
                                <input type="text" id="identification_marks_{{i}}"
                                  placeholder="Indendification Mark {{i+1}}" name="identification_marks_{{i}}"
                                  class="form-control" [formControlName]="i">
                              </div>
                            </div>
                          </ng-container>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="mb-4 main-content-label">Information about Parent / Guardian</div>
                      <p class="mb-25">Fill up your Parent / Guardian details. This will help us for further process.
                      </p>
                      <div class="row">
                        <div class="col-xl-12">

                          <div class="form-group">

                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="parent" type="radio" class="custom-control-input" value="parent"
                                name="parental_status" formControlName="parental_status">
                              <label class="custom-control-label" for="parent">Parent</label>
                            </div>

                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="guardian" type="radio" class="custom-control-input" value="guardian"
                                name="parental_status" formControlName="parental_status">
                              <label class="custom-control-label" for="guardian">Guardian</label>
                            </div>


                          </div>
                          <ng-container formArrayName="family_members">


                            <div class="table-wrap">
                              <div class="table-responsive">
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <ng-container *ngIf="this.user_form.controls.parental_status.value=='parent'">
                                        <th style="width:3%">S.No.</th>

                                        <th style="width:15%">Parant</th>
                                      </ng-container>
                                      <ng-container *ngIf="this.user_form.controls.parental_status.value=='guardian'">
                                        <th style="width:15%">Guardian Relationship</th>
                                      </ng-container>
                                      <th style="width:20%">Name</th>
                                      <ng-container *ngIf="!locationHelper.isMobile()">
                                        <th style="width:20%">Occupation</th>
                                        <th style="width:15%">Monthly Income (Rs.)</th>
                                      </ng-container>
                                      <!-- <th>Contact No</th>
                                                                                                                                                                    <th>Contact Email</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <ng-container
                                      *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form,'family_members'); let i=index;trackBy:formDataResolver.identify;">

                                      <ng-container
                                        *ngIf="this.user_form.controls.parental_status.value=='parent' && (i==0 || i==1)">
                                        <tr>

                                          <ng-container [formGroupName]="i">
                                            <ng-container
                                              *ngIf="this.user_form.controls.parental_status.value=='parent'">
                                              <td>
                                                {{i+1}}
                                              </td>
                                            </ng-container>
                                            <td>
                                              <div class="form-group">
                                                <ng-container *ngIf="i==0">
                                                  <label for="" style="margin-top: 11px;">Father</label>
                                                </ng-container>
                                                <ng-container *ngIf="i==1">
                                                  <label for="" style="margin-top: 11px;">Mother</label>
                                                </ng-container>

                                              </div>
                                            </td>
                                            <ng-container *ngIf="!locationHelper.isMobile()">
                                              <td>
                                                <div class="form-group">
                                                  <input type="text" id="given_name" name="given_name"
                                                    class="form-control" formControlName="given_name">
                                                </div>
                                              </td>
                                              <td>
                                                <div class="form-group">
                                                  <input type="text" id="occupation" name="occupation"
                                                    class="form-control" formControlName="occupation">
                                                </div>
                                              </td>
                                              <td>
                                                <div class="form-group">
                                                  <input type="number" min="0" id="salary" name="salary"
                                                    class="form-control" formControlName="salary">
                                                </div>
                                              </td>
                                            </ng-container>
                                            <ng-container *ngIf="locationHelper.isMobile()">
                                              <td>
                                                <div class="form-group">
                                                  <label for="street">Name</label>
                                                  <input type="text" id="given_name" name="given_name"
                                                    class="form-control" formControlName="given_name">
                                                </div>
                                                <div class="form-group">
                                                  <label for="street">Occupation</label>
                                                  <input type="text" id="occupation" name="occupation"
                                                    class="form-control" formControlName="occupation">
                                                </div>
                                                <div class="form-group">
                                                  <label for="street">Salary</label>
                                                  <input type="number" min="0" id="salary" name="salary"
                                                    class="form-control" formControlName="salary">
                                                </div>
                                              </td>
                                            </ng-container>

                                          </ng-container>
                                        </tr>
                                      </ng-container>

                                      <ng-container
                                        *ngIf="this.user_form.controls.parental_status.value=='guardian' && (i==2)">
                                        <tr>

                                          <ng-container [formGroupName]="i">


                                            <td>
                                              <ng-container *ngIf="i==2">
                                                <select class="form-control " id="relationship_name"
                                                  name="relationship_name" formControlName="relationship_name">
                                                  <option
                                                    *ngFor="let relationship_name of commonEnums.relationship_names;let i=index;trackBy:formDataResolver.identify;"
                                                    [ngValue]="relationship_name">
                                                    {{relationship_name}}
                                                  </option>
                                                </select>
                                              </ng-container>
                                            </td>
                                            <ng-container *ngIf="!locationHelper.isMobile()">
                                              <td>
                                                <div class="form-group">
                                                  <input type="text" id="given_name" name="given_name"
                                                    class="form-control" formControlName="given_name">
                                                </div>
                                              </td>
                                              <td>
                                                <div class="form-group">
                                                  <input type="text" id="occupation" name="occupation"
                                                    class="form-control" formControlName="occupation">
                                                </div>
                                              </td>
                                              <td>
                                                <div class="form-group">
                                                  <input type="number" min="0" id="salary" name="salary"
                                                    class="form-control" formControlName="salary">
                                                </div>
                                              </td>
                                            </ng-container>
                                            <ng-container *ngIf="locationHelper.isMobile()">
                                              <td>
                                                <div class="form-group">
                                                  <label for="street">Name</label>
                                                  <input type="text" id="given_name" name="given_name"
                                                    class="form-control" formControlName="given_name">
                                                </div>
                                                <div class="form-group">
                                                  <label for="street">Occupation</label>
                                                  <input type="text" id="occupation" name="occupation"
                                                    class="form-control" formControlName="occupation">
                                                </div>
                                                <div class="form-group">
                                                  <label for="street">Salary</label>
                                                  <input type="number" min="0" id="salary" name="salary"
                                                    class="form-control" formControlName="salary">
                                                </div>
                                              </td>
                                            </ng-container>
                                          </ng-container>
                                        </tr>
                                      </ng-container>

                                    </ng-container>
                                  </tbody>
                                </table>
                              </div>
                            </div>



                          </ng-container>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="mb-4 main-content-label">Community and Caste</div>

                      <div class="row">
                        <div class="col-md-8 col-lg-8">
                          <div class="form-group">

                            <ng-container *ngIf='user_form.value.community_id_file'>
                              <div>Preview</div>
                              <a href="{{user_form.value.community_id_file}}" target="_blank">
                                <app-ngx-image-zoom [thumbWidth]='600' [thumbHeight]='600'
                                  [thumbImage]='user_form.value.community_id_file'
                                  [fullImage]='user_form.value.community_id_file'></app-ngx-image-zoom>
                              </a>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                          <div class="form-group">
                            <label for="community_id">Select Community<small style="color: red;"> *</small></label>

                            <select class="form-control " id="community_id" name="community_id"
                              formControlName="community_id">
                              <option value="">Choose your community</option>
                              <option
                                *ngFor="let community of commonEnums.community_list;let i=index;trackBy:formDataResolver.identify;"
                                [ngValue]="community">{{community}}
                              </option>
                            </select>


                          </div>
                          <div class="form-group">
                            <label for="caste">Caste<small style="color: red;"> *</small></label>
                            <input type="text" id="caste" name="caste" class="form-control" formControlName="caste">
                            <small id="mobile_number_3_help_line" class="text-muted d-block">
                              Enter as per community certifiate
                            </small>
                          </div>
                          <div class="form-group">
                            <label for="religion">Religion<small style="color: red;"> *</small></label>
                            <select class="form-control " id="religion" name="religion" formControlName="religion">
                              <option value="">Choose your Religion</option>
                              <option
                                *ngFor="let religion of commonEnums.religions;let i=index;trackBy:formDataResolver.identify;"
                                [ngValue]="religion.id">
                                {{religion.text}}
                              </option>
                            </select>

                          </div>
                        </div>

                      </div>



                    </div>

                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="mb-4 main-content-label">Programme Type</div>
                      <div class="row">
                        <div class="col-md-12 col-lg-12">

                          <div class="form-group">
                            <label for="nationality">Would you like to Apply For<small style="color: red;">
                                *</small></label>
                            <ng-container *ngIf="locationHelper.isMobile()">
                              <br />
                            </ng-container>
                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="ug" type="radio" class="custom-control-input" value="UG" name="apply_for"
                                formControlName="apply_for">
                              <label class="custom-control-label" for="ug">UG Programmes</label>
                            </div>

                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="pg" type="radio" class="custom-control-input" value="PG" name="apply_for"
                                formControlName="apply_for">
                              <label class="custom-control-label" for="pg">PG Programmes</label>
                            </div>


                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                <ng-container *ngIf="user_form.value.apply_for === 'UG'">


                  <!-- <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="mb-4 main-content-label">Academic Details : SSLC </div>
                        <ng-container formGroupName="sslc_academics">

                          <div class="row mg-t-30">

                            <ng-container formGroupName="accademic_info">
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">School Name<small style="color: red;">
                                      *</small></label>
                                  <input type="text" name="school_name" class="form-control"
                                    formControlName="school_name">

                                </div>

                              </div>
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">Place of School<small style="color: red;">
                                      *</small></label>
                                  <input type="text" name="school_place" class="form-control"
                                    formControlName="school_place">

                                </div>

                              </div>
                              <div class="col-md-6 col-md-6">

                                <div class="form-group">
                                  <label for="ex_service_rank">Examination Registration Number<small
                                      style="color: red;">
                                      *</small></label>
                                  <input type="text" id="exam_reg_no" name="exam_reg_no" class="form-control"
                                    formControlName="exam_reg_no">
                                  <small id="mobile_number_3_help_line" class="text-muted d-block">
                                    Enter as per hall ticket
                                  </small>
                                </div>


                              </div>

                              <div class="col-md-6 col-lg-6">

                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-6 form-group">
                                      <label for="month_of_passout">Month of Passing <small style="color: red;">
                                          *</small>
                                      </label>

                                      <select class="form-control custom-select" formControlName="month_of_passout">
                                        <option *ngFor="let month of commonEnums.months" [ngValue]="month.id">
                                          {{month.text}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-md-6 form-group">
                                      <label for="year_of_passout">Year of Passing <small style="color: red;"> *</small>
                                      </label>
                                      <select class="form-control custom-select" formControlName="year_of_passout">
                                        <option *ngFor="let year of commonEnums.getLastNYears(10)" [ngValue]="year">
                                          {{year}}
                                        </option>
                                      </select>
                                    </div>

                                  </div>

                                </div>

                              </div>


                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="syllabus_type">Board <small style="color: red;"> *</small>
                                  </label>
                                  <select class="form-control" id="syllabus_type" name="syllabus_type"
                                    (change)="onSSLCBoardChange()" formControlName="syllabus_type">
                                    <option
                                      *ngFor="let syllabus_type of commonEnums.sslc_syllabus_types;let i=index;trackBy:formDataResolver.identify;"
                                      [ngValue]="syllabus_type">
                                      {{syllabus_type}}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group mg-t-5">
                                  <label for="medium_of_study">Medium of instruction<small style="color: red;">
                                      *</small></label>
                                  <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                      <div class="custom-control custom-radio" style="display: inline;">
                                        <input id="sslc_tamil" type="radio" class="custom-control-input" value="tamil"
                                          formControlName="medium_of_study">
                                        <label class="custom-control-label" for="sslc_tamil">Tamil</label>
                                      </div>

                                      <div class="custom-control custom-radio"
                                        style="display: inline; margin-left: 20px;">
                                        <input id="sslc_english" type="radio" class="custom-control-input"
                                          value="english" formControlName="medium_of_study">
                                        <label class="custom-control-label" for="sslc_english">English</label>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>



                            </ng-container>






                            <div class="col-md-12 col-lg-12">
                              <div class="row">
                                <div class="col-md-6 col-lg-6">
                                  <div class="form-group">

                                    <ng-container
                                      *ngIf='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file'>
                                      <div>Preview</div>
                                      <a href="{{user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file}}"
                                        target="_blank">
                                        <app-ngx-image-zoom [thumbWidth]='600' [thumbHeight]='600'
                                          [thumbImage]='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file'
                                          [fullImage]='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file'>
                                        </app-ngx-image-zoom>
                                      </a>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                  <label class="mb-4 main-content-label">Mark Details<small style="color: red;">
                                      *</small></label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered mb-0">
                                      <thead>
                                        <tr>
                                          <th width="60">S.No.</th>


                                          <th width="150">Subject Type</th>

                                          <th>Subject Name</th>

                                          <th width="150">Mark / Grade</th>


                                        </tr>
                                      </thead>
                                      <tbody>


                                        <ng-container formArrayName="marks">
                                          <ng-container
                                            *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.sslc_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                            <ng-container [formGroup]='alias'>
                                              <tr>

                                                <td>
                                                  {{i+1}}
                                                </td>

                                                <td>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                                    <select class="form-control " formControlName="subject_type">
                                                      <option value="">Choose</option>
                                                      <option
                                                        *ngFor="let icse_subject_type of commonEnums.icse_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                        [ngValue]="icse_subject_type">
                                                        {{icse_subject_type}}
                                                      </option>
                                                    </select>
                                                  </ng-container>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
                                                    {{alias.value.subject_type}}
                                                    {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                                  </ng-container>
                                                </td>
                                                
                                                <td>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">

                                                    <ng-container *ngIf="alias.value.subject_type == 'LANGUAGE'">

                                                      <ng-container *ngIf="alias.value.part_index == 1">

                                                        {{alias.value.subject_name}}
                                                      </ng-container>
                                                      <ng-container *ngIf="alias.value.part_index == 0">

                                                        <ng-container
                                                          *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type === 'STATE_BOARD'">
                                                          TAMIL
                                                        </ng-container>


                                                        <ng-container
                                                          *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type !== 'STATE_BOARD'">
                                                          <select class="form-control" formControlName="subject_name">
                                                            <option value="">Choose Subject</option>
                                                            <option
                                                              *ngFor="let language1_subject of commonEnums.language1_subjects;let i=index;trackBy:formDataResolver.identify;"
                                                              [ngValue]="language1_subject">
                                                              {{language1_subject}}
                                                            </option>
                                                          </select>
                                                        </ng-container>
                                                      </ng-container>




                                                    </ng-container>
                                                    <ng-container *ngIf="alias.value.subject_type != 'LANGUAGE'">
                                                      {{alias.value.subject_name}}
                                                    </ng-container>
                                                  </ng-container>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                                    <input type="text" class="form-control"
                                                      formControlName="subject_name">
                                                  </ng-container>
                                                </td>


                                                <td>
                                                  <input type="text" class="form-control" formControlName="mark">
                                                </td>


                                              </tr>





                                            </ng-container>
                                          </ng-container>




                                        </ng-container>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </ng-container>



                      </div>

                    </div>
                  </div> -->

                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="mb-4 main-content-label">Academic Details : HSC </div>
                        <ng-container formGroupName="hsc_academics">

                          <div class="row mg-t-30">

                            <ng-container formGroupName="accademic_info">
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">School Name<small style="color: red;">
                                      *</small></label>
                                  <input type="text" name="school_name" class="form-control"
                                    formControlName="school_name">

                                </div>

                              </div>
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">Place of School<small style="color: red;">
                                      *</small></label>
                                  <input type="text" name="school_place" class="form-control"
                                    formControlName="school_place">

                                </div>

                              </div>
                              <div class="col-md-6 col-md-6">

                                <div class="form-group">
                                  <label for="ex_service_rank">Examination Registration Number<small
                                      style="color: red;">
                                      *</small></label>
                                  <input type="text" id="exam_reg_no" name="exam_reg_no" class="form-control"
                                    formControlName="exam_reg_no">
                                  <small id="mobile_number_3_help_line" class="text-muted d-block">
                                    Enter as per hall ticket
                                  </small>
                                </div>


                              </div>

                              <div class="col-md-6 col-lg-6">

                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-6 form-group">
                                      <label for="month_of_passout">Month of Passing <small style="color: red;">
                                          *</small>
                                      </label>

                                      <select class="form-control custom-select" formControlName="month_of_passout">
                                        <option *ngFor="let month of commonEnums.months" [ngValue]="month.id">
                                          {{month.text}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-md-6 form-group">
                                      <label for="year_of_passout">Year of Passing <small style="color: red;"> *</small>
                                      </label>
                                      <select class="form-control custom-select" formControlName="year_of_passout">
                                        <option *ngFor="let year of commonEnums.getLastNYears(10)" [ngValue]="year">
                                          {{year}}
                                        </option>
                                      </select>
                                    </div>

                                  </div>

                                </div>

                              </div>


                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="syllabus_type">Board <small style="color: red;"> *</small>
                                  </label>
                                  <select class="form-control " id="syllabus_type" name="syllabus_type"
                                    formControlName="syllabus_type" (change)="onBoardChange()">
                                    <option
                                      *ngFor="let syllabus_type of commonEnums.syllabus_types;let i=index;trackBy:formDataResolver.identify;"
                                      [ngValue]="syllabus_type">
                                      {{syllabus_type}}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group mg-t-5">
                                  <label for="medium_of_study">Medium of instruction<small style="color: red;">
                                      *</small></label>
                                  <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                      <div class="custom-control custom-radio" style="display: inline;">
                                        <input id="hsc_tamil" type="radio" class="custom-control-input" value="tamil"
                                          formControlName="medium_of_study">
                                        <label class="custom-control-label" for="hsc_tamil">Tamil</label>
                                      </div>

                                      <div class="custom-control custom-radio"
                                        style="display: inline; margin-left: 20px;">
                                        <input id="hsc_english" type="radio" class="custom-control-input"
                                          value="english" formControlName="medium_of_study">
                                        <label class="custom-control-label" for="hsc_english">English</label>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                              <ng-container
                                *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                <div class="col-md-12 col-lg-12">
                                  <div class="form-group mg-t-5">
                                    <div class="custom-control custom-checkbox">
                                      <input id="vocational" type="checkbox" class="custom-control-input"
                                        formControlName="vocational" (change)="onBoardChange()">
                                      <label class="custom-control-label" for="vocational">Have you studied under
                                        Vocational Group (For commerce students only) ?</label>
                                    </div>


                                  </div>
                                </div>
                              </ng-container>
                              <div class="col-md-12 col-lg-12">
                                <div class="form-group mg-t-5">
                                  <div class="custom-control custom-checkbox">
                                    <input id="first_graduate" type="checkbox" class="custom-control-input"
                                      formControlName="first_graduate">
                                    <label class="custom-control-label" for="first_graduate">Are you the First Graduate
                                      in your family?</label>
                                  </div>


                                </div>
                              </div>
                              <!-- <div class="col-md-6 col-lg-6">
                                                                        <div class="form-group">
                                                                            <label for="blood_group">Upload HSC Mark Certificate<small style="color: red;"> *</small></label>
                                                                            <input class="form-control" required="" type="file" (change)="uploadImage($event,user_form.controls.hsc_academics.controls.accademic_info, 'mark_statement_file')" accept="image/*">
                                                                            <ng-container *ngIf='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file'>
                                                                                <label for="blood_group">Preview</label>
                                                                                <img style="width: 400px;height: 200px;" [src]='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file' />
                                                                            </ng-container>
                                                                        </div>
                                                                    </div> -->

                            </ng-container>



                            <div class="col-md-12 col-lg-12">
                              <div class="row">
                                <div class="col-md-6 col-lg-6">
                                  <div class="form-group">

                                    <ng-container
                                      *ngIf='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file'>
                                      <div>Preview</div>
                                      <a href="{{user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file}}"
                                        target="_blank">
                                        <app-ngx-image-zoom [thumbWidth]='600' [thumbHeight]='600'
                                          [thumbImage]='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file'
                                          [fullImage]='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file'>
                                        </app-ngx-image-zoom>
                                      </a>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                  <label class="mb-4 main-content-label">Mark Details<small style="color: red;">
                                      *</small></label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered mb-0">
                                      <thead>
                                        <tr>
                                          <th width="60">S.No.</th>


                                          <th width="150">Subject Type</th>
                                          <!-- <th width="150">Part Type</th> -->
                                          <th>Subject Name</th>

                                          <th width="150">+1 Mark / Grade</th>
                                          <th width="150">+2 Mark / Grade</th>

                                        </tr>
                                      </thead>
                                      <tbody>


                                        <ng-container formArrayName="marks">
                                          <ng-container
                                            *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.hsc_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                            <ng-container [formGroup]='alias'>
                                              <tr>

                                                <td>
                                                  {{i+1}}
                                                </td>

                                                <td>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                    {{alias.value.subject_type}}
                                                    {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                                  </ng-container>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                                    <select class="form-control " formControlName="subject_type">
                                                      <option value="">Choose</option>
                                                      <option
                                                        *ngFor="let cbsc_subject_type of commonEnums.cbsc_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                        [ngValue]="cbsc_subject_type">
                                                        {{cbsc_subject_type}}
                                                      </option>
                                                    </select>
                                                  </ng-container>

                                                </td>
                                                <td>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                    <select class="form-control " formControlName="subject_id">
                                                      <option value="">Choose</option>
                                                      <option
                                                        *ngFor="let subject of getSchoolSubjectsByPartType(alias.value.part_type);let i=index;trackBy:formDataResolver.identify;"
                                                        [ngValue]="subject.subject_id">
                                                        {{subject.subject_name}}
                                                      </option>
                                                    </select>
                                                  </ng-container>
                                                  <ng-container
                                                    *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                                    <input class="form-control" required="" type="text"
                                                      formControlName="subject_name">
                                                  </ng-container>
                                                </td>


                                                <td>
                                                  <input type="text" class="form-control" formControlName="mark_1">
                                                </td>
                                                <td>
                                                  <!-- N/A -->
                                                  <input type="text" class="form-control" formControlName="mark">
                                                </td>


                                              </tr>





                                            </ng-container>
                                          </ng-container>




                                        </ng-container>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>


                            </div>



                          </div>

                        </ng-container>



                      </div>

                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="user_form.value.apply_for === 'PG'">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="mb-4 main-content-label">Academic Details : Degree </div>
                        <ng-container formGroupName="degree_academics">

                          <div class="row mg-t-30">

                            <ng-container formGroupName="accademic_info">
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">Name of the college/university last studied<small
                                      style="color: red;">
                                      *</small></label>

                                  <div class="row">

                                    <div class="custom-control custom-radio"
                                      style="display: inline; margin-left: 20px;">
                                      <input id="true" type="radio" class="custom-control-input" value="false"
                                        name="is_anjac" formControlName="is_anjac">
                                      <label class="custom-control-label" for="true">Others</label>
                                    </div>
                                    <ng-container
                                      *ngIf='user_form.value.degree_academics.accademic_info.is_anjac=="false"'>
                                      <input type="text" name="school_name" class="form-control mg-l-40"
                                        formControlName="school_name"
                                        placeholder="Name of the college/university last studied">
                                    </ng-container>


                                    <div class="custom-control custom-radio"
                                      style="display: inline; margin-left: 20px;">
                                      <input id="false" type="radio" class="custom-control-input" value="true"
                                        name="is_anjac" formControlName="is_anjac">
                                      <label class="custom-control-label" for="false">Ayya Nadar Janaki Ammal
                                        College</label>
                                    </div>
                                  </div>

                                </div>

                              </div>
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">Place<small style="color: red;">
                                      *</small></label>
                                  <input type="text" name="school_place" class="form-control"
                                    formControlName="school_place">

                                </div>

                              </div>
                              <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                  <label for="ex_service_rank">Course Name<small style="color: red;">
                                      *</small></label>
                                  <input type="text" name="course_name" class="form-control"
                                    formControlName="course_name">

                                </div>

                              </div>
                              <div class="col-md-6 col-md-6">

                                <div class="form-group">
                                  <label for="ex_service_rank">College/University Registration Number *<small
                                      style="color: red;">
                                      *</small></label>
                                  <input type="text" id="exam_reg_no" name="exam_reg_no" class="form-control"
                                    formControlName="exam_reg_no">

                                </div>


                              </div>

                              <div class="col-md-6 col-lg-6">

                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-6 form-group">
                                      <label for="month_of_passout">Month of Passing <small style="color: red;">
                                          *</small>
                                      </label>

                                      <select class="form-control custom-select" formControlName="month_of_passout">
                                        <option *ngFor="let month of commonEnums.months" [ngValue]="month.id">
                                          {{month.text}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-md-6 form-group">
                                      <label for="year_of_passout">Year of Passing <small style="color: red;"> *</small>
                                      </label>
                                      <select class="form-control custom-select" formControlName="year_of_passout">
                                        <option *ngFor="let year of commonEnums.getLastNYears(10)" [ngValue]="year">
                                          {{year}}
                                        </option>
                                      </select>
                                    </div>

                                  </div>

                                </div>

                              </div>




                              <div class="col-md-12 col-lg-12">
                                <div class="form-group mg-t-5">
                                  <div class="custom-control custom-checkbox">
                                    <input id="first_graduate" type="checkbox" class="custom-control-input"
                                      formControlName="first_graduate">
                                    <label class="custom-control-label" for="first_graduate">Are you the First Graduate
                                      in your family?</label>
                                  </div>


                                </div>
                              </div>

                            </ng-container>



                            <div class="col-md-12 col-lg-12">
                              <label for="ex_service_rank" class="mb-4 main-content-label">Mark Details<small
                                  style="color: red;">
                                  *</small></label>
                              <div class="table-responsive">
                                <table class="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <th width="5%">S.No.</th>

                                      <th width="20%">Semester</th>
                                      <th width="10%">No. of Subjects (Only Part III)</th>
                                      <th width="10%">Total Marks</th>
                                      <th width="10%">Marks / Grade Obtained</th>



                                    </tr>
                                  </thead>
                                  <tbody>


                                    <ng-container formArrayName="marks">
                                      <ng-container
                                        *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.degree_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                        <tr>
                                          <td colspan="5" class="text-center">
                                            <ng-container *ngIf='alias.value.semester_file'>
                                              <a href="{{alias.value.semester_file}}" target="_blank">
                                                <app-ngx-image-zoom [thumbWidth]='800' [thumbHeight]='800'
                                                  [thumbImage]='alias.value.semester_file'
                                                  [fullImage]='alias.value.semester_file'></app-ngx-image-zoom>
                                              </a>
                                            </ng-container>
                                          </td>
                                        </tr>
                                        <tr>


                                          <ng-container [formGroupName]="i">
                                            <td>
                                              {{i+1}}
                                            </td>


                                            <td>

                                              Semester {{ commonEnums.rommon_letters[i+1]}}


                                            </td>
                                            <td>

                                              <select class="form-control custom-select" name="no_of_subjects"
                                                formControlName="no_of_subjects">
                                                <option *ngFor="let no_of_subjects of commonEnums.getNumbers(10)"
                                                  [ngValue]="no_of_subjects">
                                                  {{no_of_subjects}}
                                                </option>
                                              </select>



                                            </td>
                                            <td>

                                              {{formDataResolver.getValueFromFromGroup(alias,"no_of_subjects")*100}}


                                            </td>

                                            <td>
                                              <div class="form-group pt-15">

                                                <input type="number" id="mark" name="mark" min="0" class="form-control"
                                                  formControlName="mark">

                                              </div>
                                            </td>


                                          </ng-container>
                                        </tr>
                                      </ng-container>
                                    </ng-container>

                                  </tbody>
                                </table>
                              </div>

                            </div>



                          </div>

                        </ng-container>



                      </div>

                    </div>
                  </div>
                </ng-container>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-footer text-center">
                      <div class="row">
                        <div class="col-lg-4">
                          <button type="submit" class="btn btn-danger waves-effect waves-light"
                            (click)="onRejectClick()">Reject</button>
                        </div>
                        <div class="col-lg-4">
                          <button type="submit" class="btn btn-info waves-effect waves-light"
                            (click)="onProfileSaveClick()">Save</button>
                        </div>
                        <div class="col-lg-4">
                          <button type="submit" class="btn btn-success waves-effect waves-light"
                            (click)="onApproveClick('merit_list')" *ngIf="status == 'in_applied'">Approve</button>

                          <button type="submit" class="btn btn-success waves-effect waves-light"
                            (click)="onApproveClick('verified')" *ngIf="status == 'verification_list'">Verified</button>
                        </div>

                      </div>

                    </div>
                    <div class="mg-b-20"></div>
                    <div class="text-align-right">
                      <button class="btn ripple btn-secondary" (click)="onApplicationFeeFormCloseClick()"
                        type="button">Close</button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- /Col -->
            </div>
          </div>


        </div>

      </div>
    </div>
  </div>
  <!--End Large Modal -->


  <!-- Large Modal -->
  <div class="modal" id="modal-view-selected">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content modal-content-demo" *ngIf="selection_list.length>0">
        <div class="modal-header">
          <h6 class="modal-title">Selected List</h6>
        </div>
        <div class="modal-body">
          <div class="card card-body">
            <div class="row mg-b-15" *ngIf="status == 'verified' ">
              <div class="col-md-12">
                <button class="btn btn-sm btn-info mg-r-10" (click)="printSelectionList()" style="float: right;">Print
                  Selection List</button>
                <button class="btn btn-sm btn-info mg-r-10" (click)="printStudentsSelectionList()"
                  style="float: right;">Print
                  Selection List - Students</button>
                <button class="btn btn-sm btn-info mg-r-10" (click)="downloadSelectionList()"
                  style="float: right;">Download Selection List</button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-striped table-hover mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th class="text-center">S.No</th>
                    <th class="text-center">Application No.</th>
                    <th class="text-center">Name</th>
                    <th class="text-center">Mobile No.</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let applicant of selection_list;let i=index;trackBy:formDataResolver.identify;">
                    <tr>
                      <td scope="row">
                        {{i+1}}

                      </td>

                      <td class="uppercase">{{applicant.application_no}}</td>
                      <td class="uppercase">{{applicant.name}}</td>
                      <td class="uppercase">{{applicant.mobile_number}}
                        <ng-container *ngIf="applicant.mobile_number_2">
                          , {{applicant.mobile_number_2}}
                        </ng-container>
                        <ng-container *ngIf="applicant.mobile_number_3">
                          , {{applicant.mobile_number_3}}
                        </ng-container>
                        <ng-container *ngIf="applicant.mobile_number_4">
                          , {{applicant.mobile_number_4}}
                        </ng-container>
                        <ng-container *ngIf="applicant.mobile_number_5">
                          , {{applicant.mobile_number_5}}
                        </ng-container>

                      </td>
                    </tr>
                  </ng-container>




                </tbody>
              </table>
            </div>
            <div class="mg-b-20"></div>
            <div class="text-align-right">
              <ng-container *ngIf="status_change_loading">
                <button class="btn btn-secondary mg-r-20" disabled>Please wait...</button>
              </ng-container>
              <ng-container *ngIf="!status_change_loading">

                <ng-container *ngIf="status=='merit_list'">
                  <button class="btn btn-success mg-r-20" (click)="onAllowPaymentClick('verification_list')">Move to
                    Verification List</button>
                </ng-container>

                <ng-container *ngIf="status=='verified'">
                  <button class="btn btn-success mg-r-20" (click)="onAllowPaymentClick('ready_to_pay')">Allow
                    Payment</button>
                </ng-container>
                <ng-container *ngIf="status=='ready_to_pay'">
                  <button class="btn btn-danger mg-r-20" (click)="onAllowPaymentClick('verified')">Move to verified
                    list</button>
                  <button class="btn btn-danger mg-r-20" (click)="onAllowPaymentClick('payment_delayed')">Move to
                    delayed list</button>
                </ng-container>
                <ng-container *ngIf="status=='payment_delayed'">
                  <button class="btn btn-danger mg-r-20" (click)="onAllowPaymentClick('ready_to_pay')">Move to Allow
                    Payment</button>
                </ng-container>
                <ng-container *ngIf="status=='paid'">
                  <button class="btn btn-danger mg-r-20" (click)="onAllowPaymentClick('enrolled')">Enroll User</button>
                </ng-container>
              </ng-container>


              <button class="btn ripple btn-secondary" (click)="onSelectionViewCloseClick()"
                type="button">Close</button>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  <!--End Large Modal -->

</div>
<!-- /main-content -->
<app-footer></app-footer>