<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Search Students</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>

        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Search Students</h4>
                            <input type="button" class="btn btn-sm btn-primary" value="Download"
                                *ngIf="students_list.length>0" (click)="downloadAsCSV()">
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Filter & Download Students Data</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year</label>
                                        <ng-multiselect-dropdown [placeholder]="'Select Enrolment Year(s)'"
                                            [settings]="dropdownSettings" [data]="commonEnums.getLastNYears(10)"
                                            formControlName="enrolled_years">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programmes</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2"
                                    *ngIf="this.programme_section_list && this.programme_section_list.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section_id" class="form-control"
                                            (change)="onFilterSelectChange('section_id',$event)">
                                            <option value="">All Section</option>
                                            <option
                                                *ngFor="let programme_section of programme_section_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_section._id">{{programme_section.section_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Gender</label>
                                        <select formControlName="gender" class="form-control"
                                            (change)="onFilterSelectChange('gender',$event)">
                                            <option value="">All Gender</option>
                                            <option
                                                *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="gender.id">{{gender.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Religion</label>
                                        <select formControlName="religion" class="form-control">
                                            <option value="">Select Religion</option>
                                            <option
                                                *ngFor="let religion of commonEnums.religions;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="religion.id">{{religion.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Community</label>
                                        <select formControlName="community_id" class="form-control">
                                            <option value="">Select Community</option>
                                            <option
                                                *ngFor="let community of commonEnums.community_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="community">{{community}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Blood
                                            Group</label>
                                        <select formControlName="blood_group" class="form-control">
                                            <option value="">Select Blood Group</option>
                                            <option
                                                *ngFor="let blood of commonEnums.blood_groups;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="blood.id">{{blood.text}}</option>
                                        </select>
                                    </div>
                                </div>




                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Religion</label>
                                        <ng-multiselect-dropdown [placeholder]="'Select Religion Name(s)'" [settings]="statusDropdownSettings"
                                            [data]="commonEnums.religions" formControlName="community_ids">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div> -->


                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Community</label>
                                        <ng-multiselect-dropdown [placeholder]="'Select Community Name(s)'" [settings]="statusDropdownSettings"
                                            [data]="commonEnums.community_list" formControlName="community_ids">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div> -->


                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Blood Group</label>
                                        <ng-multiselect-dropdown [placeholder]="'Select Blood Group(s)'" [settings]="statusDropdownSettings"
                                            [data]="commonEnums.blood_groups" formControlName="community_ids">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div> -->


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Status
                                            Types</label>
                                        <ng-multiselect-dropdown [placeholder]="'Select Status Type(s)'"
                                            [settings]="statusDropdownSettings" [data]="status_types"
                                            formControlName="status_types">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll No</label>
                                        <input class="form-control" required="" type="text" formControlName="roll_no"
                                            placeholder="Enter Roll No.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Aadhar No</label>
                                        <input class="form-control" required="" type="text"
                                            formControlName="aadhar_card_no" placeholder="Enter Aadhar No.">
                                    </div>
                                </div>





                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!student_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>




                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Father's Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th>Last Updated At</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="students_list.length>0">


                                        <ng-container
                                            *ngFor="let student of students_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="text-uppercase">{{student.roll_no}}</td>
                                                <td class="text-uppercase">{{student.given_name}}
                                                    {{student.middle_name}}</td>
                                                <td class="text-uppercase">{{student.gender}}</td>
                                                <td class="text-uppercase">{{student?.father_name}}</td>
                                                <td class="text-uppercase">{{student.mobile_number}}</td>
                                                <td class="text-lowercase">{{student.email}}</td>
                                                <td>{{student.updatedAt | date}}</td>
                                                <td>

                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>