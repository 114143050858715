<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Student Exam Answer Sheet Evaluation</p>
                    </div>


                    <div class="card-body" *ngIf="student_search_form">
                        <form [formGroup]="student_search_form">
                            <div class="row row-sm">
                              <div class="col-lg-2">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Month</label>
                                    <select formControlName="month" class="form-control">
                                        <option value="">Select Month</option>
                                        <option
                                            *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="month.id">{{month.text}}</option>
                                    </select>
                                </div>
                              </div>
                              <div class="col-lg-2">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Year</label>
                                    <select formControlName="year" class="form-control">
                                        <option value="">Select year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                    </select>
                                </div>
                              </div>
                              <div class="col-lg-2">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                        Type</label>
                                    <select formControlName="programme_type_id" class="form-control"
                                        (change)="onFilterSelectChange('programme_type_id',$event)">
                                        <option value="">Select Programme Type</option>
                                        <option
                                            *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="category.programme_type_id">{{category.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                        Type</label>
                                    <select formControlName="finance_type" class="form-control"
                                        (change)="onFilterSelectChange('finance_type',$event)">
                                        <option value="">Select Finance Type</option>
                                        <option
                                            *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Programme</label>
                                    <select formControlName="programme_id" class="form-control"
                                        (change)="onFilterSelectChange('programme_id',$event)">
                                        <option value="">All Programmes</option>
                                        <option
                                            *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="programme.programme_id">{{programme.programme_name}}
                                            {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-2"
                                *ngIf="student_search_form.value.programme_id!='' && sections.length>1">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Section</label>
                                    <select formControlName="section" class="form-control">
                                        <option value="">Choose Section</option>
                                        <option
                                            *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="section.section_name">{{section.section_name}}</option>
                                    </select>
                                </div>
                              </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select enrollment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Appearance Type</label>
                                        <select formControlName="appearance_type" class="form-control">
                                            <option value="">Select Appearance Type</option>
                                            <option
                                                *ngFor="let appearance_type of this.appearance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="appearance_type.key">{{appearance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <ng-container *ngIf="cookieStore.isRoleExists(['coe', 'principal']) ">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by
                                                Course Code</label>
                                            <input type="text" class="form-control" formControlName="course_code"
                                                placeholder="Search with Course Code">
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                      <div class="table-responsive">
                          <table class="table table-bordered mg-b-0 text-md-nowrap">
                              <thead>
                                  <tr>
                                      <th style="width: 5%;">S.No</th>
                                      <th>Course Code</th>
                                      <th>Course name</th>
                                      <th style="width: 15%;">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <ng-container *ngIf="course_list.length>0">
                                      <ng-container
                                          *ngFor="let course of course_list;let i=index;trackBy:fromDataResolver.identify;">
                                          <tr>
                                              <td scope="row">
                                                  {{i+1}}
                                              </td>
                                              <td>{{course.course_code}}</td>
                                              <td>{{course.course_name}}</td>
                                              <td>
                                                  <div class="pr-1 mb-3 mb-xl-0">
                                                      <button type="button" (click)="displayStudents(course,i)"
                                                          class="btn btn-sm btn-info mr-2 mg-t-5"
                                                          *ngIf="!course.is_opened">
                                                          View Students
                                                      </button>
                                                      <button type="button" (click)="closeStudents(course,i)"
                                                          class="btn btn-sm btn-info mr-2 mg-t-5"
                                                          *ngIf="course.is_opened">
                                                          Close Students
                                                      </button>
                                                      <button type="button" disabled class="btn btn-sm btn-info
                                                          mr-2 mg-t-5" *ngIf="score_sheet_data_loading[i]">
                                                          Loading...
                                                      </button>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr *ngIf="course.is_opened">
                                              <td [attr.colspan]="valuations_data.length + 4">
                                                  <button type="button" class="mg-t-30 mg-r-20 btn btn-primary mr-2 search-button" style="margin-left: 19px;margin-bottom: 17px;" title="Valuate"
                                                  (click)="moveToNextValuate()" data-placement="bottom"
                                                  data-toggle="tooltip" title="Valuate">
                                                    Valuate
                                                  </button>
                                                  <form [formGroup]="comparision_form">
                                                    <div class="table-responsive">
                                                      <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                      <div class="">
                                                                          <input type="checkbox" (click)="selectAllCheckBox()" class="mg-r-5">
                                                                      </div>
                                                                    </th>
                                                                    <th>S.No</th>
                                                                    <th>Roll No</th>
                                                                    <th>Name</th>
                                                                    <ng-container *ngFor="let valuations of valuations_data; let vi = index;">
                                                                      <th style="width: 10%;">Valuation - {{valuations.valuation_no}}</th>
                                                                    </ng-container>
                                                                    <th>Average</th>
                                                                    <th>Internal</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container formArrayName="student_info">
                                                                  <tr *ngFor="let student of valuations_data[0]?.student_valuation_marks; let j=index;" [formGroupName]="j">
                                                                    <td>
                                                                      <div>
                                                                        <input type="hidden" formControlName="roll_no">
                                                                        <input type="hidden" formControlName="difference">
                                                                        <input type="checkbox" formControlName="check" class="mg-r-5">
                                                                      </div>
                                                                    </td>
                                                                    <td>{{j+1}}</td>
                                                                    <td>{{student.student_roll_no}}</td>
                                                                    <td>{{student.student_name}}</td>
                                                                    <ng-container *ngFor="let student_marks of valuations_data; let k = index;">
                                                                      <td *ngIf="student_marks.student_valuation_marks[j]?.total_mark != undefined">{{student_marks.student_valuation_marks[j]?.total_mark}}</td>
                                                                      <td *ngIf="student_marks.student_valuation_marks[j]?.total_mark == undefined">0</td>
                                                                    </ng-container>
                                                                    <td>{{ differenceOfStudentMarks(valuations_data, j) }}</td>
                                                                    <td>12</td>
                                                                  </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!student_list_loading[i] && valuations_data.length==0">
                                                                    <tr>
                                                                        <th [attr.colspan]="valuations_data.length+5" class="text-center">No Records
                                                                            found</th>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="student_list_loading[i] && valuations_data.length==0">
                                                                    <tr>
                                                                        <th [attr.colspan]="valuations_data.length+5" class="text-center">Loading
                                                                            Please wait</th>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                  </form>
                                              </td>
                                          </tr>
                                      </ng-container>
                                  </ng-container>

                                  <ng-container *ngIf="!course_list_loading && course_list.length==0">
                                      <tr>
                                          <th colspan="4" class="text-center">No Records found</th>
                                      </tr>
                                  </ng-container>
                                  <ng-container *ngIf="course_list_loading && course_list.length==0">
                                      <tr>
                                          <th colspan="4" class="text-center">Loading Please wait</th>
                                      </tr>
                                  </ng-container>

                              </tbody>
                          </table>
                      </div>
                  </div>

                </div>
            </div>
        </div>


    </div>
</div>


<!-- Settings Large Modal -->
<div class="modal" id="valuation-modal-popup" *ngIf="showExamSettingModal()">
  <div class="modal-dialog big-modal" role="document">
      <div class="modal-content modal-content-demo" *ngIf="exam_setting_form">
          <div class="modal-header">
              <h6 class="modal-title">Add Valuation</h6>
          </div>
          <div class="modal-body">
              <div class="card card-body pd-20 pd-md-40">
                  <form [formGroup]="exam_setting_form" class="form-horizontal">
                      <div class="table-responsive">
                          <table class="table table-bordered text-md-nowrap">
                              <thead>
                                  <tr class="text-center">
                                      <th style="width:5%" class="text-center">S.No.</th>
                                      <th style="width:12%" class="text-center">Valuation No.</th>
                                      <th style="width:13%" class="text-center">Type</th>
                                      <th style="width:20%" class="text-center">Pgm & Sec</th>
                                      <th style="width:10%" class="text-center">Staff ID.</th>
                                      <th style="width:10%" class="text-center">Status</th>
                                      <th style="width: 15%;" class="text-center">Action</th>
                                  </tr>
                              </thead>
                              <tbody formArrayName="valuations">
                                  <ng-container *ngFor="let valuation of examSetting().controls; let in=index;">
                                      <tr [formGroupName]="in">
                                          <td class="text-center">{{in+1}}</td>
                                          <td>
                                              <select formControlName="valuation_no" class="form-control"
                                                  (change)="valuationChanged($event, in)">
                                                  <option value="">Select Valuation No.</option>
                                                  <option
                                                      *ngFor="let valuation_no of this.valuation_nos;let i=index;trackBy:fromDataResolver.identify;"
                                                      [value]="valuation_no">{{valuation_no}}
                                                  </option>
                                              </select>
                                          </td>
                                          <td>
                                              <select formControlName="evaluated_in" class="form-control"
                                                  (change)="evaluatedInChanged($event, in)">
                                                  <option value="">Select Valuation Type</option>
                                                  <option
                                                      *ngFor="let valuation_type of this.valuation_types;let i=index;trackBy:fromDataResolver.identify;"
                                                      [value]="valuation_type.key">{{valuation_type.text}}
                                                  </option>
                                              </select>
                                          </td>
                                          <td>
                                              <ng-container>
                                                <kendo-multiselect [data]="available_programme_list[in]"
                                                  valueField="staff_course_map_id" textField="roll_no"
                                                  (valueChange)="pgmChanged($event,in)"
                                                  [placeholder]="'Choose Staff Id(s)'"
                                                  formControlName="staff_list">
                                                  <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                      <span class="template">{{
                                                          getProgrammeDisplay(dataItem)}}</span>
                                                  </ng-template>
                                                </kendo-multiselect>
                                              </ng-container>

                                          </td>
                                          <td class="text-center">
                                              <input type="text" class="form-control"
                                                  placeholder="Enter Staff ID." formControlName="roll_no"
                                                  *ngIf="valuation.value.evaluated_in !='INTERNAL' && valuation.value.evaluated_in !='' ">
                                              <p *ngIf="valuation.value.evaluated_in =='INTERNAL'">
                                                  <span
                                                      *ngFor="let staff of valuation.value.staff_list; let ind = index">{{staff.roll_no}}
                                                      <span *ngIf="valuation.value.staff_list.length-1>ind">,
                                                      </span>
                                                  </span>
                                              </p>
                                          </td>
                                          <td class="text-center">
                                              <input type="datetime-local" class="form-control"
                                                  formControlName="end_time" placeholder="Enter End date">
                                          </td>
                                          <td class="text-center">
                                            <button type="button" class="btn btn-danger btn-sm mg-t-5"
                                                (click)="deleteValuator(in)">
                                                Delete
                                            </button>
                                        </td>
                                      </tr>
                                  </ng-container>
                                  <tr>
                                      <td colspan="8" class="text-center">
                                          <button type="button" class="btn btn-primary"
                                              (click)="addValuator()">
                                              Add New Valuation
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                      <div class="text-center">
                          <button class="btn btn-primary mg-r-20" (click)="onSave()"
                              [disabled]="canDisable()">
                              <ng-container
                                  *ngIf="!this.exam_setting_form.value._id && !this.setting_save_loading">
                                  Save
                              </ng-container>
                              <ng-container
                                  *ngIf="!this.exam_setting_form.value._id && this.setting_save_loading">
                                  Saving...
                              </ng-container>
                          </button>
                          <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                              [disabled]="canDisable()">
                              <ng-container
                                  *ngIf="!this.exam_setting_form.value._id && !this.setting_save_loading">
                                  Save & Close
                              </ng-container>
                              <ng-container
                                  *ngIf="!this.exam_setting_form.value._id && this.setting_save_loading">
                                  Saving...
                              </ng-container>
                          </button>
                          <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                          <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
<!--End Large Modal -->



<!-- /main-content -->
<app-footer></app-footer>
