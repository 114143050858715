<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Map Timetable Templates to Programmes</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>

                                        <div *ngIf="isInvalid('semester')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required">Semester is required.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!mapping_list_loading"
                                            (click)="getMappingSettingsList()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="mapping_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-body">

                        <div class="d-flex right-content mg-b-5" style="float: right;" *ngIf="mapping_list.length>0">
                            <!-- <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Bulk Map
                                Programmes</button> -->
                            <app-modal [render_data]="bulk_render_data" (csvData)="downloadBulkUploadFile($event)">
                            </app-modal>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 30%;">Programme</th>
                                        <th style="width: 10%;">Section</th>
                                        <th style="width: 10%;">Enrolled Year</th>
                                        <th style="width: 10%;">Semester</th>
                                        <th style="width: 20%;">Template Name</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="mapping_list.length>0">
                                        <ng-container
                                            *ngFor="let setting of mapping_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{setting.programme_name}}</td>
                                                <td>{{setting.section_details.section_name}}</td>
                                                <td>{{setting.enrolled_year}}</td>
                                                <td>{{setting.semester}}</td>
                                                <td>{{getTemplateName(setting.template_id)}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="openModal(setting)"
                                                            title="Edit Setting" class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!mapping_list_loading && mapping_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="mapping_list_loading && mapping_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="programme_time_setting_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Map Programme - Time Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="programme_time_setting_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Template
                                                Name</label>
                                            <select formControlName="template_id" class="form-control">
                                                <option value="">Select Template Name</option>
                                                <option
                                                    *ngFor="let template of template_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="template._id">{{template.template_name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('template_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Template Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="template_map_history_list.length>0">
                                    <b>List of previously mapped templates for this programme</b>
                                </p>
                                <div class="table-responsive" *ngIf="template_map_history_list.length>0">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Template Name</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Revert</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let template of template_map_history_list; let i = index;trackBy:fromDataResolver.identify;">
                                                <td>{{i+1}}</td>
                                                <td>{{template.template_name}}</td>
                                                <td>{{template.start_date | date:commonEnums.date_format}}</td>
                                                <td>{{template.end_date | date:commonEnums.date_format}}</td>
                                                <td>
                                                    <button type="button" (click)="revertToTemplate(template)"
                                                        title="Revert back to this template"
                                                        class="btn btn-sm btn-info mr-2">
                                                        <fa-icon [icon]="fonts.faUndo"></fa-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()"
                                        *ngIf="!save_loading">
                                        <ng-container *ngIf="!programme_time_setting_form.value._id">
                                            Save
                                        </ng-container>
                                        <ng-container *ngIf="programme_time_setting_form.value._id">
                                            Update
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()" *ngIf="!save_loading">
                                        <ng-container *ngIf="!programme_time_setting_form.value._id">
                                            Save & Close
                                        </ng-container>
                                        <ng-container *ngIf="programme_time_setting_form.value._id">
                                            Update & Close
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" disabled *ngIf="save_loading">
                                        <ng-container *ngIf="!programme_time_setting_form.value._id">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="programme_time_setting_form.value._id">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="setting_id && !delete_loading">Delete</button>
                                    <button class="btn btn-danger mg-r-20" disabled
                                        *ngIf="setting_id && delete_loading">Deleting...</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>