<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Student Statement of Marks</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Statement of Marks</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Student Mark Statement</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>

                                    </div>
                                </div> -->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Month</label>
                                        <select formControlName="month" class="form-control">
                                            <option value="0">Select Month</option>
                                            <option
                                                *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="month.id">{{month.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Year</label>
                                        <select formControlName="year" class="form-control">
                                            <option value="0">Select year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year
                                        </label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="offered_by_finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="programme_search_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!student_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="row" *ngIf="student_data_list?.data?.length>0">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-info mg-r-10" (click)="printData()">Print</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <ng-container *ngIf="!student_list_loading && !student_data_list">
                                    <b>No Records Found</b>
                                </ng-container>
                                <ng-container *ngIf="student_list_loading && !student_data_list">
                                    <b>Loading...</b>
                                </ng-container>
                            </div>
                        </div>

                        <div id="print-area" *ngIf="student_data_list?.data?.length>0">
                            <div class="row">
                                <div class="col-md-2">
                                    <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                                        alt="college logo"
                                        style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                                </div>
                                <div class="col-md-8 text-center">
                                    <h4 style=" margin-bottom: 20px !important;">AYYA NADAR JANAKI AMMAL COLLEGE
                                        (Autonomous), SIVAKASI.</h4>
                                    <h5>Statement of Marks for the Terminal Examination {{student_data_list?.term}}
                                        (CBCS Pattern)</h5>
                                </div>
                            </div>

                            <div class="row" style="margin-top: 15px;">
                                <div class="col-md-6">
                                    Class: <b>{{getProgrammeName(student_data_list?.query?.programme_id)}}
                                        {{student_data_list?.query?.section}}
                                    </b>
                                </div>
                                <div class="col-md-6 text-right">
                                    Semester: <b>{{student_data_list?.query?.semester}}</b>
                                </div>
                            </div>

                            <div class="table-responsive" *ngIf="student_data_list?.data?.length>0" id="print-table">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th rowspan="2" style="vertical-align: middle;">R.No.</th>
                                            <th *ngFor="let header of student_data_list?.data[0]?.course_wise_list; let i = index; trackBy:fromDataResolver.identify;"
                                                colspan="5" style="text-align: center;">
                                                {{header.course_code}}
                                            </th>
                                        </tr>
                                        <tr>
                                            <ng-container
                                                *ngFor="let header of student_data_list?.data[0]?.course_wise_list; let i = index; trackBy:fromDataResolver.identify;">
                                                <th>I<br>
                                                    {{header.max_internal_total}}
                                                </th>
                                                <th>E<br>
                                                    {{header.max_external_total}}
                                                </th>
                                                <th>T<br>
                                                    {{header.max_total}}
                                                </th>
                                                <th>GP</th>
                                                <th>LG</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let student of student_data_list?.data; let i = index; trackBy:fromDataResolver.identify;">
                                            <td>{{student.roll_no}}</td>
                                            <ng-container
                                                *ngFor="let course of student.course_wise_list; let j = index; trackBy:fromDataResolver.identify;">
                                                <td>{{course.internal_total}}</td>
                                                <td>{{course.external_total}}</td>
                                                <td>{{course.total}}</td>
                                                <td>{{course.grade_point | number:'1.1-2'}}</td>
                                                <td>{{course.grade_letter}}</td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </div>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>