<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Add Scheme for Internal Exam Valuation</p>
                    </div>

                    <div class="card-body" *ngIf="question_setting_form">
                        <h5>Course Code: {{exam_setup.course_code}}</h5>
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn btn-info btn-sm"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Question Paper
                                    Analysis<i class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="openAnalysis('OUT_OF_SYLLABUS')">Out of
                                        Syllabus</a>
                                    <a class="dropdown-item" (click)="openAnalysis('TYPOGRAPHICAL_ERROR')">Typographical
                                        Errors</a>
                                    <a class="dropdown-item" (click)="openAnalysis('OTHER_REMARKS')">Other Remarks</a>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="question_setting_form">
                            <div class="table-responsive">
                                <div class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width:5%">Question No.</th>
                                            <th style="width: 5%;">CO-Level</th>
                                            <th style="width: 5%;">K-Level</th>
                                            <th>Question</th>
                                        </tr>
                                    </thead>
                                    <tbody formArrayName="questions_settings">
                                        <ng-container *ngFor="let section of questionSettings; let i=index;"
                                            [formGroupName]="i">
                                            <tr>
                                                <td colspan="4">
                                                    <span><b>Section {{section.value.section_no}}</b></span>
                                                    <button class="btn btn-sm btn-info" style="float: right;"
                                                        (click)="openAnalysis('SECTION_ANALYSIS', section.value.section_no)">Add
                                                        Analysis</button>
                                                </td>
                                            </tr>
                                            <ng-container formArrayName="questions">
                                                <tr *ngFor="let question of getQuestions(i); let j=index;"
                                                    [formGroupName]="j">
                                                    <td>{{question.value.question_no}}. {{question.value.option}}</td>
                                                    <td>
                                                        {{question.value.co_id}}
                                                    </td>
                                                    <td>
                                                        {{question.value.k_id}}
                                                    </td>
                                                    <td>
                                                        Question:
                                                        <div [innerHTML]="question.value.question"></div>
                                                        <b>Key points:</b>
                                                        <ng-container formArrayName="scheme_list">
                                                            <div class="table-responsive"
                                                                *ngIf="getSchemePoints(i,j).length>0">
                                                                <table class="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style="width: 75%;">Key Point</th>
                                                                            <th style="width: 10%;">Mark</th>
                                                                            <th style="width: 15%;">Action(s)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let key of getSchemePoints(i,j); let k=index;"
                                                                            [formGroupName]="k">
                                                                            <td>
                                                                                <div [innerHTML]="key.value.scheme_key">
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {{key.value.mark}}
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    class="btn btn-sm btn-info mg-r-5"
                                                                                    (click)="editSchemePoint(i,j,k)">Edit</button>
                                                                                <button class="btn btn-sm btn-danger"
                                                                                    (click)="deleteSchemePoint(i,j,k)">Delete</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center mg-t-5">
                                                                <button class="btn btn-primary btn-sm"
                                                                    (click)="addSchemePoint(i,j)">Add Key Point</button>
                                                            </div>

                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <!-- Valuation Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Valuation Scheme</h6>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="card card-body pd-20 pd-md-40">
                                <form class="form-horizontal">
                                    <!-- <textarea [(ngModel)]="question_editor" name="editor" rows="20" cols="20"
                                        class="form-control"></textarea> -->
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Max. Mark
                                            for this key point</label>
                                        <input type="number" [(ngModel)]="question_mark" name="mark"
                                            placeholder="Enter Max. Mark for this key point" class="form-control">
                                    </div>
                                    <kendo-editor #editors class="question" [(ngModel)]="question_editor" name="editor"
                                        style="height: 300px;">
                                        <kendo-toolbar>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorSuperscriptButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignRightButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontSize>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontFamily>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient">
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddColumnAfterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowAfterButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorDeleteColumnButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteTableButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                            <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                            <kendo-toolbar-button kendoEditorCleanFormattingButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button text="Upload Image"
                                                (click)="openUploadImage($event,'question')">
                                            </kendo-toolbar-button>
                                        </kendo-toolbar>
                                    </kendo-editor>
                                </form>
                            </div>
                        </form>
                        <div class="text-center">
                            <button class="btn ripple btn-primary mg-r-5" (click)="saveSchemePoint()" type="button"
                                [disabled]="canDisable()" *ngIf="!question_save_loading">Save</button>
                            <button class="btn ripple btn-primary mg-r-5" type="button" [disabled]
                                *ngIf="question_save_loading">Saving...</button>
                            <button class="btn ripple btn-info mg-r-5" (click)="saveAndCloseSchemePoint()" type="button"
                                [disabled]="canDisable()">Save & Close</button>
                            <button class="btn ripple btn-secondary" (click)="closeSchemePoint()"
                                type="button">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <div class="modal" id="modal-2">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="editor_popup_form">
                    <div class="modal-header">
                        Upload Image
                    </div>
                    <div class="modal-body">
                        <div class="card card-body">
                            <input class="form-control" required="" type="file"
                                (change)="uploadImage($event,editor_popup_form)" accept="image/*">
                            <ng-container *ngIf='editor_popup_form.value.image_url'>
                                <img [style.width.px]="editor_popup_form.value.width"
                                    [style.height.px]="editor_popup_form.value.height"
                                    [src]='editor_popup_form.value.image_url' />
                            </ng-container>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">


                                <button class="btn ripple btn-secondary" (click)="onCloseImageUpload()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Valuation Large Modal -->
        <div class="modal" id="analysis-popup">
            <div class="modal-dialog big-modal" role="document" *ngIf="analysis_data">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Question Paper Analysis</h6>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="card card-body pd-20 pd-md-40">
                                <form class="form-horizontal">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">
                                            Enter {{analysis_data?.type?.split('_').join(' ')}}
                                        </label>
                                        <textarea [(ngModel)]="analysis_data.data" name="analysis_editor" rows="10"
                                            cols="20" class="form-control"></textarea>
                                    </div>
                                </form>
                            </div>
                        </form>
                        <div class="text-center">
                            <button class="btn ripple btn-primary mg-r-5" (click)="updateAnalysis()" type="button"
                                [disabled]="canDisableAnalysis()" *ngIf="!question_save_loading">Save</button>
                            <button class="btn ripple btn-primary mg-r-5" type="button" [disabled]
                                *ngIf="analysis_data_loading">Saving...</button>
                            <button class="btn ripple btn-info mg-r-5" (click)="updateAndCloseAnalysis()" type="button"
                                [disabled]="canDisableAnalysis()">Save & Close</button>
                            <button class="btn ripple btn-secondary" (click)="closeAnalysis()"
                                type="button">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>