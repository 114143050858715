import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-review-na-evidence',
  templateUrl: './review-na-evidence.component.html',
  styleUrls: ['./review-na-evidence.component.scss']
})
export class ReviewNaEvidenceComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;
  // master data
  programme_search_form: any;

  // core data

  review_evidence_list: IOBEStudentSkillSetUpload[] = [];
  review_list_loading = false;

  evidence: IOBEStudentSkillSetUpload = {} as IOBEStudentSkillSetUpload;
  programme_type_list: any[] = [];


  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;

  evidence_form: FormGroup;
  move_form: FormGroup;



  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;
  description: string = "";
  reviewer_description: string = "";
  component: any;
  to_stage_count: number
  review_stages: string[] = ["OBE_MEMBER", "OBE_APPROVE", "OBE_MONITOR"];
  stages: string[] = ["OBE_MEMBER", "OBE_APPROVE", "OBE_MONITOR"];

  approve_all_loading: boolean[] = [];

  skillset_list_loading: boolean = false;
  skillset_list: IOBENonAcademicSkillsetMaster[] = [];
  skills_list: IOBENonAcademicSkills[] = [];

  save_loading: boolean = false;
  delete_loading: boolean = false;
  move_data: StageMoveInterface = {} as StageMoveInterface;
  move_mode: string = '';
  move_loading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {

    // Load master data
    this.buildComponentSearchForm();
    this.getProgrammeTypeList();
    this.getSkillSetList();
    //this.onSearchClick();
  }



  /// master data

  async getSkillSetList() {
    try {
      this.skillset_list_loading = true;
      this.skillset_list = [];
      const service_response = await this.restService.getSkillSetList();
      if (service_response && service_response.success) {
        this.skillset_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.skillset_list_loading = false;
    }

  }
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  buildComponentSearchForm(): void {

    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      enrolled_year: [0],
      semester: [0],
      programme_id: [''],
      roll_no: [''],
      stage: ['']
    });
    this.stages.forEach(stage => {
      if (this.cookieStore.isRoleExists([stage.toLowerCase()])) {
        this.programme_search_form.get('stage').setValue(stage);
      }
    });
  }

  async onFilterSelectChange(id: string, event?: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.programme_list = [];
      this.programme_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.programme_search_form);
    }
  }

  getName(name: string) {
    return name.replace('undefined', '');
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }


  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }

  async onSearchClick(): Promise<void> {
    try {
      this.review_list_loading = true;
      this.review_evidence_list = [];
      var search_query: any = {};
      search_query = {
        skip: this.start_index,
        limit: this.take_index
      };
      if (this.programme_search_form.value.programme_type_id) {
        search_query.programme_type_id = this.programme_search_form.value.programme_type_id;
      }
      if (this.programme_search_form.value.programme_id) {
        search_query.programme_id = this.programme_search_form.value.programme_id;
      }
      if (this.programme_search_form.value.enrolled_year) {
        search_query.enrolled_year = (this.programme_search_form.value.enrolled_year as number).toString();
      }
      if (this.programme_search_form.value.semester) {
        search_query.semester = this.programme_search_form.value.semester;
      }
      if (this.programme_search_form.value.programme_id) {
        search_query.programme_id = this.programme_search_form.value.programme_id;
      }
      if (this.programme_search_form.value.roll_no) {
        search_query.roll_no = this.programme_search_form.value.roll_no;
      }
      if (this.programme_search_form.value.stage) {
        search_query.stage = this.programme_search_form.value.stage;
      }

      /* this.review_evidence_list.push({
        roll_no: "20PA01",
        given_name: "Ajay Balaji",
        middle_name: "K",
        sub: "a330dssdf01230-dsfsd-2dsff",
        programme_id: "afb68dd7-29c6-4a7c-a0d8-feeae4811372",
        semester: 2,
        evidence_url: "https://sdtimes.com/wp-content/uploads/2018/04/angular.png",
        skillset_id: "e2fe05c8-a37c-4d0b-886f-398c88bd4c70",
        skill: "Appearing in Competitive Examinations",
        point: 4,
        description: "Participated in Intercollegiate Competition",
        date: "2022-05-22T00:00",
        place: "Sivakasi",
        stage: "OBE_MEMBER"
      },
        {
          roll_no: "20PA02",
          given_name: "Vikram",
          middle_name: "A",
          sub: "a330dssd3241230-dsfsd-2dsff",
          programme_id: "afb68dd7-29c6-4a7c-a0d8-feeae4811372",
          semester: 2,
          evidence_url: "https://sdtimes.com/wp-content/uploads/2018/04/angular.png",
          skillset_id: "2416e8cf-75eb-4e29-b15f-a454b838335f",
          skill: "Natural Disaster work with NGOs",
          point: 4,
          description: "Participated NGO in Sivakasi",
          date: "2022-05-22T00:00",
          place: "Sivakasi",
          stage: "OBE_MEMBER"
        }

      ) */

      const service_response = await this.restService.studentEvidenceSearch(search_query);
      if (service_response && service_response.success) {
        this.review_evidence_list = service_response.data;
        console.log(this.review_evidence_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.review_list_loading = false;
    }
  }


  editClick(evidence: IOBEStudentSkillSetUpload) {
    this.evidence = evidence;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  buildForm(): void {
    if (JSON.stringify(this.evidence) == '{}') {
      this.evidence = {
        roll_no: "",
        given_name: "",
        middle_name: "",
        sub: "",
        programme_id: "",
        semester: 0,
        evidence_url: "",
        skillset_id: "",
        skill: "",
        point: 0,
        description: "",
        date: "",
        place: "",
        stage: "",
      };
    }
    this.evidence_form = this.fb.group({
      _id: [this.evidence._id],
      semester: [this.evidence.semester, [Validators.required]],
      evidence_url: [this.evidence.evidence_url, [Validators.required]],
      description: [this.evidence.description, [Validators.required]],
      skillset_id: [this.evidence.skillset_id],
      skill: [this.evidence.skill],
      date: [DateHelper.convertToControlDate(new Date(this.evidence.date))],
      place: [this.evidence.place],
      point: [this.evidence.point],
      stage: [this.evidence.stage.toUpperCase()],
      remarks: [this.evidence.remarks]
    });

    if (this.evidence.skillset_id) {
      this.skills_list = this.skillset_list.find(x => x._id == this.evidence.skillset_id)?.skills;
    }
  }

  async uploadFile(e: any) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadEvidenceFile(formData);
      if (response.success && response.data) {
        alert("File Uploaded Successfully");
        (this.evidence_form.get('evidence_url').setValue(response.data.url));
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  filterSkillsetList(ev) {
    this.skills_list = [];
    this.evidence_form.get('skill').setValue("");
    var select_element_id: string = (ev.target as HTMLSelectElement).value;
    this.skills_list = this.skillset_list.find(x => x._id == select_element_id)?.skills;
    console.log(this.skills_list);
  }

  isInvalid(formControl: string): boolean {
    return this.evidence_form.touched && this.evidence_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.evidence_form.status !== 'VALID';
  }

  onSaveClick(): void {
    this.addItem();
  }

  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.evidence_form.value;
      form_data.stage = form_data.stage.toLowerCase();
      console.log(form_data);
      this.save_loading = true;
      console.log(form_data);
      const res = await this.restService.updateSkillsetEvidence(this.evidence_form.value._id, form_data);
      if (res) {
        alert("Evidence updated successfully.");
      }
      this.onSearchClick();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteSkillset(this.evidence_form.get('_id')!.value);
      if (res.success) {
        alert("Skillset removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.skills_list = [];
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.evidence = {} as IOBEStudentSkillSetUpload;
    this.evidence_form.reset();
  }

  getSkillsetName(skillset_id: string): string {
    return this.skillset_list.find(x => x._id == skillset_id)?.skillset_name;
  }

  buildMoveForm(): void {

    this.move_form = this.fb.group({
      stage: ["", [Validators.required]],
      remarks: [""]
    });
  }

  async onMoveClick(mode: string, evidence?: IOBEStudentSkillSetUpload) {
    this.move_mode = mode;
    if (mode == 'single') {
      this.move_data = {
        _id: evidence._id,
        roll_no: evidence.roll_no,
        sub: evidence.sub,
      }
    }
    this.buildMoveForm();
    JQueryHelper.openModal('#modal-move', { keyboard: false, backdrop: 'static' });
  }

  async moveToClick() {
    try {
      var move_data_arr: StageMoveInterface[] = [];
      this.move_loading = true;
      if (this.move_mode == 'single') {
        this.move_data.stage = this.move_form.value.stage;
        this.move_data.remarks = this.move_form.value.remarks;
        console.log(this.move_data);
        move_data_arr.push(this.move_data);
      }
      else {
        this.review_evidence_list.forEach(evidence => {
          move_data_arr.push({
            _id: evidence._id,
            roll_no: evidence.roll_no,
            sub: evidence.sub,
            stage: this.move_form.value.stage,
            remarks: this.move_form.value.remarks
          })
        });
        console.log(move_data_arr);
      }
      const service_response = await this.restService.studentEvidenceUpdate(move_data_arr);
      if (service_response && service_response.success) {
        this.onSearchClick();
        alert('Evidence Moved Successfully');
      }
      else {
        alert("Error! Could not move evidence");
      }
      this.onSearchClick();
      this.closeMove();
    }
    catch (error) {
      console.log(error);
      CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save);
    }
    finally {
      this.move_loading = false;
    }
  }

  closeMove() {
    this.evidence = {} as IOBEStudentSkillSetUpload;
    this.move_form.reset();
    this.move_form.get('stage').setValue("");
    this.move_form = undefined;
    JQueryHelper.closeModal('#modal-move');
  }


}


interface IOBEStudentSkillSetUpload {
  _id?: string,
  roll_no: string;
  given_name: string,
  middle_name: string
  sub: string;
  programme_id: string;
  semester: number,
  evidence_url: string,
  skillset_id: string,
  skill: string,
  point: number;
  description: string,
  date: string,
  place: string,
  stage: string;
  reviewed_by?: string;
  approved_by?: string;
  approved_date?: Date;
  remarks?: string
}

interface IOBENonAcademicSkillsetMaster {
  _id?: string,
  skillset_name: string;
  skills: IOBENonAcademicSkills[];
  max_point: number;
}


interface IOBENonAcademicSkills {
  skill_name: string
  max_point: number;
}

interface StageMoveInterface {
  _id: string,
  roll_no: string,
  sub: string,
  stage?: string,
  remarks?: string
}
