import { Room } from './../../room-model';
import { Floor } from './../../floor-model';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { Hostel, HostelBody } from './../../hostelModel';
import { Incharge } from './../../incharge-model';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminservicesService } from './../../../../../services/rest/adminservices.service';
import { Component, HostListener, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hostel-incharges',
  templateUrl: './hostel-incharges.component.html',
  styleUrls: ['./hostel-incharges.component.scss']
})
export class HostelInchargesComponent implements OnInit {
  fonts = fonts;
  hostel: Hostel = {} as Hostel;
  floorId: string = '';
  roomId: string = '';
  commonEnums = CommonEnums;
  constructor(private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.route.params.subscribe(params => {
      this.navigationService.updateNavigation('Incharges', this.router.url);
      this.restService.hostelManagementService.getHostels(params.hostelId).subscribe(hostelData => {
        if (!hostelData?.length) {
          return;
        }
        this.hostel.id = params.id;
        this.floorId = params?.floorId;
        this.roomId = params?.roomId;
        this.hostel = hostelData[0];
      });
    });
  }

  getIncharges(): Incharge[] {
    let incharges: Incharge[] = [];
    const floor: Floor = this.hostel.floors?.filter(myfloor => myfloor.id === this.floorId)[0] || {} as Floor;
    if (this.roomId && this.floorId) {
      const room: Room = floor.rooms.filter(myroom => myroom.id === this.roomId)[0];
      incharges = room?.incharges || [];
    } else if (this.floorId) {
      incharges = floor?.incharges || [];
    } else {
      incharges = this.hostel.incharges || [];
    }
    return incharges;
  }

  onAdd(): void {
    this.router.navigate(['admin/hostel/hostel-management/manage-incharges'], { queryParams: {
      hostelId: this.hostel.id,
      floorId: this.floorId,
      roomId: this.roomId
    } });
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  onEdit(incharge: Incharge): void {
    this.router.navigate(['admin/hostel/hostel-management/manage-incharges'], { queryParams: {
      hostelId: this.hostel.id,
      inchargeId: incharge.id,
      floorId: this.floorId,
      roomId: this.roomId
    } });
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  onDelete(incharge: Incharge): void {
    let subURL = `${this.hostel.id}?inchargesId=${incharge.id}`;
    if (this.floorId) {
      subURL = subURL.concat(`&floorId=${this.floorId}`);
    }
    if (this.roomId) {
      subURL = subURL.concat(`&roomId=${this.roomId}`);
    }
    this.restService.hostelManagementService.deleteHostel(subURL).subscribe(data => {
      if (this.floorId && !this.roomId) {
        this.hostel.floors?.forEach(floor => {
          floor.incharges = floor.incharges?.filter(incharges => incharges.id !== incharge.id);
        });
      } else if (this.floorId && this.roomId) {
        this.hostel.floors?.forEach(floor => {
          if (floor.id === this.floorId) {
            floor.rooms.forEach(room => {
              if (room.id === this.roomId) {
                room.incharges = room.incharges?.filter(roomIncharge => incharge.id !== roomIncharge.id);
              }
            });
          }
        });
      } else {
        this.hostel.incharges = this.hostel.incharges?.filter(inchargeData => inchargeData.id !== incharge.id);
      }
      this.notificationService.setNotifcation({ message: 'Incharge deleted successfully', type: 'Success' });
    }, this.errorHandler);
  }
}
