import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ViewChild, ViewChildren } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-internal-exam-upload',
  templateUrl: './internal-exam-upload.component.html',
  styleUrls: ['./internal-exam-upload.component.scss']
})
export class InternalExamUploadComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Submit Internal Exam Answer Sheet';
  course_list: any[] = [];
  course_list_loading: boolean = false;
  file_uploading: boolean = false;
  student_file_data: any = undefined;
  file: File;
  files: any[] = [];
  preview_url: string = "";
  modal_mode: string = "";
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.renderData();
  }

  async renderData() {
    try {
      this.course_list_loading = true;
      const service_resp = await this.restService.getInternalStudentSubmissionCourseList();
      if (service_resp && service_resp.success) {
        this.course_list = service_resp.data;
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      console.log(error);
    }
    finally {
      this.course_list_loading = false;
    }
  }


  isTimeExpired(submission_end_time: Date) {
    var sub_time = new Date(submission_end_time)
    var curr_date = new Date();
    if (curr_date < sub_time)
      return false;
    return true;
  }
  async openModal(e: any, course: any, j: number): Promise<void> {
    this.student_file_data = {
      "exam_id": course.exam.exam_id,
      "roll_no": course.roll_no,
      index: j,
    }
    //const query_data = new HttpParams({ fromObject: data }).toString()
    this.file = (e.target as HTMLInputElement).files[0];
    console.log(this.file.size);
    if (this.file.size > 1073741824) {
      alert("File size should be less than 1 GB.");
      e.target.value = "";
      return;
    }
    console.log(this.file);
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.preview_url = event.target.result;
    }
    reader.readAsDataURL(this.file);
    this.modal_mode = "UPLOAD";
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  async uploadFile(): Promise<void> {
    try {
      const consent = confirm("Are you sure want to submit this file? Once uploaded you cannot make any changes!");
      if (!consent) return;
      const formData = new FormData();
      console.log(this.file);
      formData.append('file', this.file);
      this.file_uploading = true;
      const response = await this.restService.uploadInternalAnswerFile(formData);
      if (response.success && response.data) {
        var file_url = response.data.url;
        delete this.student_file_data.index;
        this.student_file_data.file_path = file_url;
        const resp = await this.restService.submitInternalStudentAnswer(this.student_file_data);
        if (resp && resp.success) {
          this.renderData();
          alert("File Uploaded Successfully");
          this.closeModal();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      console.log(error);
    } finally {
      this.file_uploading = false;
    }
  }

  closeModal() {
    this.files[this.student_file_data.index] = "";
    this.preview_url = "";
    this.modal_mode = "";
    this.file = null; this.student_file_data = undefined;
    JQueryHelper.closeModal('#modal-popup');
  }

  viewPreview(course) {
    this.modal_mode = "PREVIEW";
    this.preview_url = course.exam.submission.file_path;
    this.student_file_data = {
      "exam_id": course.exam.exam_id,
      "roll_no": course.roll_no
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
}
