import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function customValidator(): ValidatorFn {
  const emailRe = /\S+@\S+\.\S+/i;
  const rnumberRe = /^\d(.*\d)?$/i
  return (control: AbstractControl): ValidationErrors | null => {
    const isRollNumber = rnumberRe.test(control.value);
    const isEmail = emailRe.test(control.value);
    const result = isRollNumber || isEmail;
    return !result ? { invalidField: true } : null;
  };
}
