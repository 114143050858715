import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';

@Component({
  selector: 'app-internal-exam-setup',
  templateUrl: './internal-exam-setup.component.html',
  styleUrls: ['./internal-exam-setup.component.scss']
})
export class InternalExamSetupComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  dropdownSettings: IDropdownSettings = {};

  title = 'Internal Examination Setup';

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_type_id: string = ""; //ngModel
  settings_list_loading: boolean = false;

  setting: IExamSetup = {} as IExamSetup;
  programme_list: any[] = [];
  programme_list_loading: boolean = false;
  exam_setting_form: FormGroup = undefined;
  setting_id: string = "";
  component_search_form: FormGroup = undefined;
  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;
  course_list: any[] = [];
  course_list_loading = false;
  survey_data_loading: boolean = false;
  save_loading: boolean = false;
  student_response_loading: boolean = false;
  delete_loading: boolean = false;
  cookieStore = CookieStore;
  course: any = {} as any;
  component: any = {} as any;
  popup_mode: string = '';
  format_setting_data: any[] = [];
  exam_modes: {
    key: string, text: string
  }[] = [{
    key: "ONLINE", text: "Online Exam"
  },
  {
    key: "OFFLINE", text: "Offline Exam"
  }
    ];

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.getProgrammeDataList();
    this.getExamFormatSettings();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildComponentSearchForm();
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  buildComponentSearchForm(): void {
    this.component_search_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: [''],
      roll_no: [''],
      course_code: [''],
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }


  async onSearchClick(): Promise<void> {
    try {
      this.course_list_loading = true;
      this.course_list = [];
      const search_query = {
        programme_type_id: this.component_search_form.value.programme_type_id,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        roll_no: this.component_search_form.value.roll_no.toUpperCase(),
        course_code: this.component_search_form.value.course_code,
        skip: this.start_index,
        take: this.take_index
      };
      //const service_response = await this.restService.getCoursesByStaff(search_query);
      const service_response = await this.restService.searchStaffCourseMap(search_query); //v2
      if (service_response && service_response.success) {
        this.course_list = service_response.data.data;
        this.total_records = service_response.data.total;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_list_loading = false;
    }
  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }

  async onViewComponentsClick(course: any): Promise<void> {
    console.log(course);
    if (course.show_co) {
      course.show_co = false;
    } else {
      for (const courseobj of this.course_list) {
        courseobj.show_co = false;
      }
      this.getCoursesByStaff(course);
      course.show_co = true;
    }
  }

  async getCoursesByStaff(course: any): Promise<void> {
    try {
      if (!course.co_settings || course.co_settings.length == 0) {
        alert("OBE component not configured!, Please contact OBE incharge");
        return;
      }
      course.loading = true;
      course.component_list = JSON.parse(JSON.stringify(course.co_settings));
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      console.log(error);
    } finally {
      course.loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  async onEditClick(course, component) {
    this.course = course; this.component = component;
    const search_data: any = {
      enrolled_year: course.enrolled_year,
      semester: course.semester,
      course_code: course.course_code,
      //component_id: component._id,
      programme_type_id: course.offered_by_programme_type_id,
      obe_component_id: component._id
    }
    if (course.class_taking_programme_id && course.class_taking_section) {
      search_data.programme_id = course.class_taking_programme_id,
        search_data.section = course.class_taking_section
    }
    if (course.virtual_section) {
      search_data.virtual_section = course.virtual_section
    }
    try {
      const service_response = await this.restService.searchInternalExamSetup(search_data);
      if (service_response && service_response.success) {
        var settings = service_response.data;
        if (settings) {
          console.log(settings);
          this.setting = settings;
          this.buildForm();
          JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
        }
      }
      this.buildForm();
      JQueryHelper.openModal('#exam-setup-popup', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async getExamFormatSettings() {
    try {
      const service_response = await this.restService.searchExamQuestionFormat({});
      if (service_response && service_response.success) {
        console.log(service_response);
        this.format_setting_data = service_response.data;
      }
      else {
        alert("Error! Could not get Question Format Data");
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        programme_type_id: "",
        course_code: "",
        question_format_id: "",
        exam_type: "ACADEMIC",
        enrolled_year: 0,
        semester: 0,
        mode_of_exam: "",
        allow_students_answer_submission: false,
        start_time: new Date(),
        end_time: new Date(),
        submission_start_time: new Date(),
        submission_end_time: new Date(),
        valuations: [],
        active: false,
        obe_component_id: "",
      };
    }
    this.exam_setting_form = this.formBuilder.group({
      _id: [this.setting._id],
      programme_type_id: [this.setting.programme_type_id, [Validators.required]],
      exam_type: ["ACADEMIC"],
      question_format_id: [((this.setting.question_format_id) ? this.setting.question_format_id : ""), [Validators.required]],
      course_code: [this.setting.course_code, [Validators.required]],
      enrolled_year: [this.setting.enrolled_year, [Validators.required]],
      semester: [this.setting.semester, [Validators.required]],
      start_time: [(this.setting?.start_time ? (DateHelper.convertToControlDateTime(new Date(this.setting?.start_time))) : new Date()), [Validators.required]],
      end_time: [(this.setting.end_time ? (DateHelper.convertToControlDateTime(new Date(this.setting?.end_time))) : new Date()), [Validators.required]],
      mode_of_exam: [((this.setting.mode_of_exam) ? this.setting.mode_of_exam : ""), [Validators.required]],
      obe_component_id: [this.setting.obe_component_id],
      allow_students_answer_submission: [this.setting.allow_students_answer_submission],
      submission_start_time:
        this.setting.allow_students_answer_submission ?
          [DateHelper.convertToControlDateTime(new Date(this.setting.submission_start_time))] : '',
      submission_end_time: this.setting.allow_students_answer_submission ?
        [DateHelper.convertToControlDateTime(new Date(this.setting.submission_end_time))] : '',
      active: [this.setting.active],
      valuations: this.formBuilder.array(this.buildValuationsForm(this.setting.valuations)),

    });
  }

  buildValuationsForm(valuator_list: IExamValuationSettings[]): any {
    if (!valuator_list) {
      valuator_list = [];
    }
    var valuator_list_array: any[] = [];
    if (valuator_list && valuator_list.length > 0) {
      var valuator_count = 0;
      for (const valuator of valuator_list) {
        valuator_list_array.push(this.formBuilder.group({
          _id: [valuator._id],
          sub: [valuator.sub],
          evaluated_in: [valuator.evaluated_in, [Validators.required]],
          programme_id: [valuator.programme_id],
          section: [valuator.section],
          virtual_section: [valuator.virtual_section],
          valuation_no: [valuator.valuation_no],
          end_time: [(valuator.end_time ? (DateHelper.convertToControlDate(valuator.end_time)) : new Date()), [Validators.required]],
          staff_list: [valuator.staff_list],
          stage: [valuator.stage]
        }));
        valuator_count++;
      }
    }
    const remaining_count = 1 - valuator_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        valuator_list_array.push(this.formBuilder.group({
          _id: [""],
          sub: [""],
          evaluated_in: ["INTERNAL", [Validators.required]],
          programme_id: [""],
          section: [""],
          virtual_section: [""],
          valuation_no: ["1"],
          staff_list: [""],
          stage: [""],
          end_time: [new Date(), [Validators.required]],
        }));
      }
    }
    console.log(valuator_list_array);
    return valuator_list_array;
  }

  get valuatorList() {
    return ((this.exam_setting_form as FormGroup).get('valuations') as FormArray).controls;
  }

  validateDate(event, control: string) //validate end_time, sub_start_time, sub_end_time
  {
    var control_date: Date;
    var to_compare_date: Date;
    var input_element = (event.target as HTMLInputElement);
    if (control == 'end_time') {
      to_compare_date = this.exam_setting_form.get('start_time').value;
      control_date = this.exam_setting_form.get('end_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Exam End Time cannot be less than Exam Start Time");
        input_element.value = ""; return;
      }
    }
    if (control == 'submission_start_time') {
      to_compare_date = this.exam_setting_form.get('end_time').value;
      control_date = this.exam_setting_form.get('submission_start_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Submission Start Time cannot be less than Exam End Time");
        input_element.value = ""; return;
      }
    }

    if (control == 'submission_end_time') {
      to_compare_date = this.exam_setting_form.get('submission_start_time').value;
      control_date = this.exam_setting_form.get('submission_end_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Submission End Time cannot be less than Submission Start Time");
        input_element.value = ""; return;
      }
    }
  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.popup_mode = '';
    this.onSearchClick();
    JQueryHelper.closeModal('#exam-setup-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as IExamSetup;
    this.exam_setting_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.exam_setting_form.value;
      form_data.start_time = new Date(this.exam_setting_form.value.start_time).toISOString();
      form_data.end_time = new Date(this.exam_setting_form.value.end_time).toISOString();
      console.log(form_data);

      this.save_loading = true;
      if (this.exam_setting_form.value._id) {
        const res = await this.restService.updateInternalExamSetup(this.exam_setting_form.value._id, form_data);
        if (res) {
          alert("Internal Exam Setup Updated Successfully.");
        }
      }
      else {
        const res = await this.restService.createInternalExamSetup(form_data);
        if (res) {
          alert("Internal Exam Setup Created Successfully.");
        }
      }
      this.onSearchClick();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  /* async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteExamFeeHeader(this.survey_form.get('_id')!.value);
      if (res.success) {
        alert("Internal Exam Setup Deleted Successfully.");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  } */

  isInvalid(formControl: string): boolean {
    return this.exam_setting_form.touched && this.exam_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.exam_setting_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  onBackClick() {
    this.popup_mode = 'add';
  }


  getClassName(enrolled_year: number, programme_id?: string, section?: string, virtual_section?: string, programme_type_id?: string) {
    if (virtual_section) {
      return "Virtual Section " + virtual_section;
    }
    else {
      var programme_name = this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
      return this.commonEnums.getClassName(enrolled_year, programme_name, section, programme_type_id);
    }
  }

  navigateToQuestionSetting() {
    HTMLHelper.openLinkInNewTab("staff/internal/question-setting?exam_id=" + this.setting._id
      + "&question_format_id=" + this.setting.question_format_id);
  }

  navigateToValuationScheme() {
    HTMLHelper.openLinkInNewTab("staff/internal/valuation-scheme?exam_id=" + this.setting._id
      + "&question_format_id=" + this.setting.question_format_id);
  }


}

interface IExamSetup {
  _id: string;

  question_format_id: string, //input
  mode_of_exam: string; //online, offline //input
  allow_students_answer_submission: boolean; //true, false //input
  start_time: Date; //input
  end_time: Date; //input
  submission_start_time?: Date; //input
  submission_end_time?: Date; //input
  active: boolean; //input
  obe_component_id?: string
  programme_type_id: string,
  course_code: string;
  exam_type: string; //default ACADEMIC
  enrolled_year: number;
  semester: number;
  valuations: IExamValuationSettings[];
  closed?: boolean;

}

export interface IExamValuationSettings {
  _id?: string;
  id?: string;
  evaluated_in: string;
  programme_id?: string,
  section?: string,
  virtual_section?: string,
  valuation_no: number;
  roll_no?: string;
  sub: string;
  name?: string;
  end_time: Date; //user input
  stage: string,
  completed?: boolean;
  staff_list: {
    roll_no: string,
    staff_course_map_id: string
  }[]
}