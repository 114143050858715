import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { Modal } from 'src/app/components/ModalInterface';
var XLSX = require("xlsx");
import { Chart } from 'chart.js';
//import * as Chart from 'chart.js';

//Chart.Chart.register.apply(null, Object.values(ChartJs).filter((chartClass) => (chartClass.id)));


@Component({
  selector: 'app-programme-outcome',
  templateUrl: './programme-outcome.component.html',
  styleUrls: ['./programme-outcome.component.scss']
})
export class ProgrammeOutcomeComponent implements OnInit {

  title = 'Programme Outcome';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  internal_value: string = 'EXISTING';
  table_data_loading: boolean = false;
  course_code: string = '';
  sections: any[] = [];
  po_levels: string[] = ['po1', 'po2', 'po3', 'po4', 'po5', 'po6', 'po7'];
  // cas report
  po_table_data: POStudentMarksData = {} as POStudentMarksData;
  po_table_data_loading = false;
  internal_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "internal-map",
    modal_button_text: "Internal Marks Bulk Upload",
    modal_header: "Internal Marks Bulk Upload",
    list_name: "Internal Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "co1", column_type: "required" },
        { column_name: "co2", column_type: "required" },
        { column_name: "co3", column_type: "required" },
        { column_name: "co4", column_type: "required" },
        { column_name: "co5", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "CO1", "CO2", "CO3", "CO4", "CO5", "Total"],
      column_values: ["-", "-", "-", "30", "25", "40", "10", "5", "110"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No, Given & Middle Names and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  active_accordion: number = 1;
  report_data: POReport = {} as POReport;
  report_data_loading: boolean = false;
  ces_value: string = 'EXISTING';
  pgm_course_value: string = 'EXISTING';
  pgm_course_modal_data: ProgrammeCourseMarks = undefined as ProgrammeCourseMarks;
  ges_value: string = 'EXISTING';
  internal_course_list: any[] = [];
  internal_course_list_loading: boolean = false;
  pgm_course_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "internal-map",
    modal_button_text: "Internal Marks Bulk Upload",
    modal_header: "Internal Marks Bulk Upload",
    list_name: "Internal Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "co1", column_type: "required" },
        { column_name: "co2", column_type: "required" },
        { column_name: "co3", column_type: "required" },
        { column_name: "co4", column_type: "required" },
        { column_name: "co5", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "CO1", "CO2", "CO3", "CO4", "CO5", "Total"],
      column_values: ["-", "-", "-", "30", "25", "40", "10", "5", "110"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No, Given & Middle Names and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  course_data: any = {};
  ces_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "ces-map",
    modal_button_text: "CES Marks Bulk Upload",
    modal_header: "CES Marks Bulk Upload",
    list_name: "CES Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "po1", column_type: "required" },
        { column_name: "po2", column_type: "required" },
        { column_name: "po3", column_type: "required" },
        { column_name: "po4", column_type: "required" },
        { column_name: "po5", column_type: "required" },
        { column_name: "po6", column_type: "required" },
        { column_name: "po7", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "PO1", "PO2", "PO3", "PO4", "PO5", "PO6", "PO7", "Total"],
      column_values: ["-", "-", "-", "4", "4", "4", "4", "4", "4", "4", "28"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  ges_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "external-map",
    modal_button_text: "Graduate Exit Survey Marks Bulk Upload",
    modal_header: "Graduate Exit Survey Marks Bulk Upload",
    list_name: "Graduate Exit Survey Marks Bulk",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "given_name", column_type: "required" },
        { column_name: "middle_name", column_type: "required" },
        { column_name: "po1", column_type: "required" },
        { column_name: "po2", column_type: "required" },
        { column_name: "po3", column_type: "required" },
        { column_name: "po4", column_type: "required" },
        { column_name: "po5", column_type: "required" },
        { column_name: "po6", column_type: "required" },
        { column_name: "po7", column_type: "required" },
        { column_name: "total", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Given Name", "Middle Name", "PO1", "PO2", "PO3", "PO4", "PO5", "PO6", "PO7", "Total"],
      column_values: ["-", "-", "-", "4", "4", "4", "4", "4", "4", "4", "28"]
    },
    show_loader: false,
    download_note: `The first row must be the weightages of COs. In first weightage row provide '-' as the Roll No and enter the overall weightage of corresponding COs. From the second row, add students marks.
    Note: That data provided here is uploaded as given, kindly ensure the validity of the data.
    `
  };
  external_month: number = 0;
  external_year: number = 0;
  external_valuation: number | string = 0;
  valuation_list: any[] = [];
  ges_list_loading: boolean = false;
  pgm_course_list_loading: boolean = false;
  ces_list_loading: boolean = false;
  report_loading: boolean = false;
  display_card: boolean = false;
  chart: any;

  constructor(private formBuilder: FormBuilder, private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section: [''],
      enrolled_year: [''],
      regulation_year: [''],
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {
    this.display_card = false;
    if (id == "programme_id") {
      this.sections = [];
      this.filter_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
      if (this.sections.length == 1) {
        this.filter_form.get('section').setValue(this.sections[0].section_name);
      }
    }

    if (id === 'programme_type_id' || id === 'finance_type') {
      this.filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.filter_form);
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick() {
    if (!this.filter_form.value.programme_type_id) {
      alert("Please choose Programme Type"); return;
    }
    if (!this.filter_form.value.finance_type) {
      alert("Please choose Finance Type"); return;
    }
    if (!this.filter_form.value.programme_id) {
      alert("Please choose Programme Name"); return;
    }
    if (this.sections.length > 0 && !this.filter_form.value.section) {
      alert("Please choose section"); return;
    }
    if (!this.filter_form.value.regulation_year) {
      alert("Please choose Regulation Year"); return;
    }
    if (!this.filter_form.value.enrolled_year) {
      alert("Please choose Regulation Year"); return;
    }
    this.table_data_loading = true;
    try {
      this.display_card = true;
      this.getReportData();
      /* const service_response = await this.restService.getPAMReportV2(this.filter_form.value.programme_id, this.filter_form.value.regulation_year);
      if (service_response && service_response.success) {
        this.pam_report = service_response.data;
      } */


    }
    catch (error) {
      console.log(error);
      alert(error);
    }
    finally {
      this.table_data_loading = false;
    }
  }

  changeActiveAccordion(accordion_no: number) {
    if (this.active_accordion == accordion_no)
      this.active_accordion = 0;
    else
      this.active_accordion = accordion_no;
  }
  isActiveAccordion(accordion_no: number) {
    if (accordion_no == this.active_accordion) return true;
    return false;
  }

  async getReportData() {
    this.report_data = {} as POReport;
    const report_search_obj = {
      "enrolled_year": this.filter_form.value.enrolled_year,
      "regulation_year": this.filter_form.value.regulation_year,
      "programme_type_id": this.filter_form.value.programme_type_id,
      "programme_id": this.filter_form.value.programme_id,
      "finance_type": this.filter_form.value.finance_type,
      "section": this.filter_form.value.section,
    };

    const response = await this.restService.getPOReport(report_search_obj);
    if (response.success && response.data) {
      this.report_data = response.data
    }
  }

  async getPgmCourse() {
    try {
      this.pgm_course_list_loading = true;
      this.pgm_course_modal_data = {} as ProgrammeCourseMarks;
      const search_query: any = this.filter_form.value;

      const service_response = await this.restService.getPAMCourseValues(search_query);
      if (service_response && service_response.success) {
        this.pgm_course_modal_data = service_response.data;
        this.report_data.programme_course_marks = this.pgm_course_modal_data;
        JQueryHelper.openModal('#modal-popup-pgm-course', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.pgm_course_list_loading = false;
    } finally {
      this.pgm_course_list_loading = false;
    }
  }

  onPgmCourseCloseClick() {
    this.pgm_course_modal_data = {} as ProgrammeCourseMarks;
    JQueryHelper.closeModal('#modal-popup-pgm-course');
  }

  async bulkUploadCES(e: any) {
    try {
      console.log(e);
      this.ces_render_data.show_loader = true;
      /* const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return; */
      const validity = this.validateBulkUploadData(e);
      if (!validity.valid) {
        alert(validity.error);
        return;
      }
      this.report_data.ces_data = this.convertToStudentsMarkFormat(e)
      this.po_table_data = JSON.parse(JSON.stringify(this.report_data.ces_data));
      if (this.report_data._id) {
        this.saveUpdateReport();
      }
      alert("Co-Curricular Exit Survey Marks File uploaded successfully");
      JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.ces_render_data.show_loader = false;
    }

  }

  async getCESCO() {
    try {
      this.ces_list_loading = true;
      this.po_table_data = {} as POStudentMarksData;
      this.po_table_data_loading = true;
      //to do after non-academic evidence flow
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.ces_list_loading = false;
    } finally {
      this.po_table_data_loading = false;
      this.ces_list_loading = false;
    }
  }

  async onCasReportCloseClick(): Promise<void> {
    this.po_table_data = {} as POStudentMarksData;
    JQueryHelper.closeModal('#modal-popup-attainmentreport');
  }

  bulkUploadGES(e) {
    try {
      console.log(e);
      this.ges_render_data.show_loader = true;
      /* const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return; */
      const validity = this.validateBulkUploadData(e);
      if (!validity.valid) {
        alert(validity.error);
        return;
      }
      this.report_data.ges_data = this.convertToStudentsMarkFormat(e)
      this.po_table_data = JSON.parse(JSON.stringify(this.report_data.ges_data));
      if (this.report_data._id) {
        this.saveUpdateReport();
      }
      alert("Graduate Exit Survey File uploaded successfully");
      JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.ges_render_data.show_loader = false;
    }
  }

  async bulkUploadPgmCourseAttainment(e: any) {
    try {
      console.log(e);
      this.internal_render_data.show_loader = true;
      /* const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return; */
      // const validity = this.validateBulkUploadDataOfPgmCourse(e);
      // if (!validity.valid) {
      //   alert(validity.error);
      //   return;
      // }
      // this.pgm_course_modal_data = service_response.data;
      // this.report_data.programme_course_marks = this.pgm_course_modal_data;

      // this.report_data.internal_marks = this.convertToStudentsMarkFormat(e)
      // this.cas_report_data = JSON.parse(JSON.stringify(this.report_data.internal_marks));
      // if (this.report_data._id) {
      //   this.saveUpdateReport();
      // }
      // alert("Internal Marks File uploaded successfully");
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.internal_render_data.show_loader = false;
    }

  }

  async getGESData() {
    const search_data = {
      programme_id: this.filter_form.value.programme_id,
      programme_type_id: this.filter_form.value.programme_type_id,
      enrolled_year: this.filter_form.value.enrolled_year,
      section: this.filter_form.value.section
    }
    this.valuation_list = [];
    this.ges_list_loading = true;
    try {
      //new api needed
      const service_response = await this.restService.getProgrammeGES(search_data);
      if (service_response && service_response.success) {
        const ges_result: any[] = service_response.data;
        let student_responses: any[] = ges_result[0].student_response;
        student_responses = student_responses.sort((a, b) => a.roll_no.localeCompare(b.roll_no));
        this.report_data.ges_data = {} as  POStudentMarksData;
        this.report_data.ges_data.weightage = {} as POMarks;
        this.report_data.ges_data.weightage.po1 = 4; this.report_data.ges_data.weightage.po2 = 4;
        this.report_data.ges_data.weightage.po3 = 4; this.report_data.ges_data.weightage.po4 = 4;
        this.report_data.ges_data.weightage.po5 = 4; this.report_data.ges_data.weightage.po6 = 4;
        this.report_data.ges_data.weightage.po7 = 4; this.report_data.ges_data.weightage.total = 28;
        this.report_data.ges_data.marks = [];
        for (var i = 0; i < student_responses.length; i++) {
          this.report_data.ges_data.marks[i] = {} as POStudentMarks
          this.report_data.ges_data.marks[i].given_name = student_responses[i].given_name;
          this.report_data.ges_data.marks[i].middle_name = student_responses[i].middle_name
          this.report_data.ges_data.marks[i].roll_no = student_responses[i].roll_no;
          let total = 0;
          this.report_data.ges_data.marks[i].po_marks = {} as POMarks;
          for (var j = 0; j < student_responses[i].survey_responses?.length; j++) {
            let survey_response = student_responses[i].survey_responses[j];
            this.report_data.ges_data.marks[i].po_marks['po' + (survey_response.pso_id)] = survey_response.point;
            total += survey_response.point;
          }
          this.report_data.ges_data.marks[i].po_marks['total'] = total;
          if (student_responses[i].survey_responses?.length == 0 || !student_responses[i].survey_responses) {
            this.report_data.ges_data.marks[i].po_marks.po1 = 0; this.report_data.ges_data.marks[i].po_marks.po2 = 0;
            this.report_data.ges_data.marks[i].po_marks.po3 = 0; this.report_data.ges_data.marks[i].po_marks.po4 = 0;
            this.report_data.ges_data.marks[i].po_marks.po5 = 0; this.report_data.ges_data.marks[i].po_marks.po6 = 0;
            this.report_data.ges_data.marks[i].po_marks.po7 = 0; this.report_data.ges_data.marks[i].po_marks.total = 0;
          }
        }
        this.po_table_data = undefined;
        this.po_table_data = this.report_data.ges_data;
        JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    this.ges_list_loading = false;
  }

  viewUploadedMarks(mark_type) {
    if (mark_type == 'PROGRAMME-COURSE') {
      this.pgm_course_modal_data = this.report_data.programme_course_marks;
      JQueryHelper.openModal('#modal-popup-pgm-course', { keyboard: false, backdrop: 'static' });
    }
    else if (mark_type == 'GES') {
      this.po_table_data = this.report_data.ges_data;
      JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
    }
    else {
      this.po_table_data = this.report_data.ces_data;
      JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
    }

  }

  removeUploadedMarks(mark_type) {
    let obj: any = {
      _id: this.report_data._id
    }
    if (mark_type == 'PROGRAMME-COURSE') {
      this.report_data.programme_course_marks = undefined;
      obj.internal_marks = [];
    }
    else if (mark_type == 'GES') {
      this.report_data.ges_data = undefined;
      obj.external_marks = [];
    }
    else {
      this.report_data.ces_data = undefined;
      obj.ces_marks = [];
    }
    if (this.report_data._id) {
      this.saveUpdateReport();
    }
  }

  canDisplayReport() {
    if (!this.report_data.programme_course_marks) {
      return false;
    }
    if (!this.report_data.ges_data) {
      return false;
    }
    if (!this.report_data.ces_data) {
      return false;
    }
    if (!this.report_data.da_scale) {
      return false;
    }
    if (!this.report_data.ges_scale) {
      return false;
    }
    if (!this.report_data.ces_scale) {
      return false;
    }
    if (!this.report_data.da_scale) {
      return false;
    }
    return true;
  }

  createArrayWithLimit(n) {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(i);
    }
    return result;
  }

  downloadPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A4 landscape; } .table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;} #report-table {font-size: 10px;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('report-html').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  formatStr(str: string) {
    return str.toUpperCase();
  }

  downloadExcel() {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("report-table");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, `${this.report_data.programme_name}_${this.report_data.enrolled_year}_PO_REPORT.xlsx`);
  }

  convertToStudentsMarkFormat(bulk_upload_data: BulkUploadFormat[]) {
    let return_data: POStudentMarksData = {} as POStudentMarksData;
    const weightage_row = bulk_upload_data.find(x => x.roll_no == '-');
    const index = bulk_upload_data.findIndex(x => x.roll_no == '-');
    delete (weightage_row.roll_no); delete (weightage_row.given_name); delete (weightage_row.middle_name);
    if (weightage_row) {
      return_data.weightage = {} as POMarks;
      for (let key in weightage_row) {
        return_data.weightage[key] = weightage_row[key]
      }
    }
    bulk_upload_data.splice(index, 1);
    return_data.marks = [];
    for (var i = 0; i < bulk_upload_data.length; i++) {
      var student = bulk_upload_data[i];
      if (student.roll_no == '-') {
        continue
      };
      return_data.marks[i] = {} as POStudentMarks;
      return_data.marks[i].roll_no = student.roll_no;
      return_data.marks[i].given_name = student.given_name;
      return_data.marks[i].middle_name = student.middle_name;
      return_data.marks[i].po_marks = {} as POMarks;
      return_data.marks[i].po_marks.po1 = student.po1; return_data.marks[i].po_marks.po2 = student.po2;
      return_data.marks[i].po_marks.po3 = student.po3; return_data.marks[i].po_marks.po4 = student.po4;
      return_data.marks[i].po_marks.po5 = student.po5; return_data.marks[i].po_marks.po6 = student.po6;
      return_data.marks[i].po_marks.po7 = student.po7; return_data.marks[i].po_marks.total = student.total;
    }
    return return_data;
  }

  validateBulkUploadData(bulk_upload_data: BulkUploadFormat[]) {
    let return_data: { valid: boolean, error: string } = { valid: true, error: "" }
    if (bulk_upload_data.filter(x => x.roll_no == '-').length == 0) {
      return_data.valid = false; return_data.error = "Cannot find weightage; Please reupload File with weightage"
      return return_data;
    };
    for (var i = 0; i < bulk_upload_data.length; i++) {
      let co_total = parseFloat(bulk_upload_data[i].po1 as string) + parseFloat(bulk_upload_data[i].po2 as string)
        + parseFloat(bulk_upload_data[i].po3 as string) + parseFloat(bulk_upload_data[i].po4 as string) + parseFloat(bulk_upload_data[i].po5 as string)
      parseFloat(bulk_upload_data[i].po6 as string) + parseFloat(bulk_upload_data[i].po7 as string)
      if (bulk_upload_data[i].total != co_total) {
        return_data.error = `Total does not match CO Value on Row ${i + 1}`;
        break;
      }
    }
    return return_data;
  }

  // validateBulkUploadDataOfPgmCourse(bulk_upload_data: ProgrammeCourseMarks) {
  //   let return_data: { valid: boolean, error: string } = { valid: true, error: "" }
  //   if(Object.values(bulk_upload_data.pam_total_weights).length == 0) {
  //     return_data.valid = false; return_data.error = "Cannot find total weightage; Please upload file with total weightage"
  //     return return_data;
  //   }
  //   if(Object.values(bulk_upload_data.attainment_total_weights).length == 0) {
  //     return_data.valid = false; return_data.error = "Cannot find attainment total weightage; Please upload file with attainment total weightage"
  //     return return_data;
  //   }

  //   if (bulk_upload_data.filter(x => x.roll_no == '-').length == 0) {
  //     return_data.valid = false; return_data.error = "Cannot find weightage; Please reupload File with weightage"
  //     return return_data;
  //   };
  //   for (var i = 0; i < bulk_upload_data.length; i++) {
  //     let co_total = parseFloat(bulk_upload_data[i].po1 as string) + parseFloat(bulk_upload_data[i].po2 as string)
  //       + parseFloat(bulk_upload_data[i].po3 as string) + parseFloat(bulk_upload_data[i].po4 as string) + parseFloat(bulk_upload_data[i].po5 as string)
  //     parseFloat(bulk_upload_data[i].po6 as string) + parseFloat(bulk_upload_data[i].po7 as string)
  //     if (bulk_upload_data[i].total != co_total) {
  //       return_data.error = `Total does not match CO Value on Row ${i + 1}`;
  //       break;
  //     }
  //   }
  //   return return_data;
  // }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  async saveUpdateReport() {
    try {
      this.report_loading = true;
      if (!this.report_data._id) {
        //insert data
        this.report_data.programme_type_id = this.filter_form.value.programme_type_id,
          this.report_data.finance_type = this.filter_form.value.finance_type,
          this.report_data.programme_id = this.filter_form.value.programme_id,
          this.report_data.section = this.filter_form.value.section,
          this.report_data.enrolled_year = this.filter_form.value.enrolled_year,
          this.report_data.regulation_year = this.filter_form.value.regulation_year,
          this.report_data.programme_name = this.getProgrammeName(this.filter_form.value.programme_id)
        const service_response = await this.restService.savePOReport(this.report_data);
        if (service_response && service_response.success) {
          this.getReportData();
          alert("PO Report generated successfully");
          this.report_loading = false;
        }
      }
      else {
        //update data
        const service_response = await this.restService.updatePOReport(this.report_data);
        if (service_response && service_response.success) {
          this.getReportData();
          alert("PO Report updated successfully");
          this.report_loading = false;
        }
      }
    }
    catch (err) {
      this.report_loading = false;
      alert(err);
    }
    finally {
      this.report_loading = false;
    }

  }


  viewConsolidated() {
    JQueryHelper.openModal('#modal-popup-consolidated', { keyboard: false, backdrop: 'static' });
    this.createPieChart();
  }

  closeConsolidated() {
    JQueryHelper.closeModal('#modal-popup-consolidated');
  }

  downloadConsolidatedPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A4 landscape; } .table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;} #report-table {font-size: 10px;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-consolidated').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  createPieChart() {
    let weights: number[] = [];
    let attainment_total_weights = this.report_data.programme_course_marks.attainment_total_weights;

    Object.keys(attainment_total_weights).forEach(key => {
      if (key != 'total') {
        weights.push(parseFloat(attainment_total_weights[key]));
      }
    });

    const ctx = document.getElementById('myPieChart') as HTMLCanvasElement;
    this.chart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: this.po_levels.map(x => x.toUpperCase()),
        datasets: [

          //Set a total weights results here
          {
            label: "Direct PO Attainment for 100% from all courses",
            data: weights, // Specify your data here
            fill: true,
            backgroundColor: 'rgba(255, 99, 132, 0.7)',
            borderColor: 'rgba(255,99,132)',
            pointBackgroundColor: 'rgba(255,99,132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255,99,132)'
          },

      ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }



}

interface BulkUploadFormat {
  roll_no: string,
  given_name?: string,
  middle_name?: string,
  po1: number | string,
  po2: number | string,
  po3: number | string,
  po4: number | string,
  po5: number | string,
  po6: number | string,
  po7: number | string,
  total: number
}



//PO  Report

interface POStudentMarks {
  roll_no: string,
  given_name?: string,
  middle_name?: string,
  po_marks: POMarks,
  //total_po_marks: number
}

interface POStudentMarksData {
  weightage: POMarks,
  marks: POStudentMarks[],
  total_pos: POMarks,
  reference_id?: string,
}

interface POMarks {
  po1: number | string,
  po2: number | string,
  po3: number | string,
  po4: number | string,
  po5: number | string,
  po6: number | string,
  po7: number | string,
  total: number | string
}

interface PAMCoursesData {
  course_code: string,
  course_name: string,
  pam_weights: POMarks,
  final_cas_attainment: number,
  programmme_course_marks_attainment: POMarks
}

interface ProgrammeCourseMarks {
  pam_courses_data: PAMCoursesData[],
  pam_total_weights: POMarks,
  attainment_total_weights: POMarks
  //scaled_weights: POMarks
}


interface POReport {
  _id?: string,
  id?: string,

  //user input
  programme_type_id: string,
  finance_type: string,
  programme_id: string,
  programme_name?: string,
  section: string,
  enrolled_year: number,
  regulation_year: number,


  //marks
  programme_course_marks: ProgrammeCourseMarks,
  ges_data: POStudentMarksData,
  ces_data: POStudentMarksData

  //params
  da_scale: number, //70%
  ges_scale: number, //10%
  ces_scale: number, //20%

  //results
  des_scaled_results: POMarks,
  ges_scaled_results: POMarks,
  ces_scaled_results: POMarks,
  total_po_marks: POMarks,

  total_marks: number //sum of all total_po_marks
  grade: string,
  remarks: string

  observations_by_obemc?: string,
  suggestions_by_obemc?: string
}
