import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
@Component({
  selector: 'app-elective-polling-settings',
  templateUrl: './elective-polling-settings.component.html',
  styleUrls: ['./elective-polling-settings.component.scss']
})
export class ElectivePollingSettingsComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Elective Polling Settings List';
  settings_list_loading: boolean = false;
  settings_list: SettingInterface[] = [];
  setting: SettingInterface = {} as SettingInterface;
  polling_setting_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  enrolled_year: number = 0;
  category_list: any[] = [];
  category_list_loading = false;
  programme_search_form: any;
  programme_list: any[] = [];
  programme_list_loading = false;
  parts: any[] = [];
  part_course_types: any[] | undefined = [];
  condition_delete_loading: boolean[] = [];
  state = '';
  course_data_list: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      itemsShowLimit: 20,
      allowSearchFilter: true
    };
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.getProgrammeTypeList();
    this.buildFilterForm();
    //this.getCoursesList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  buildFilterForm(): void {
    this.programme_search_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: ['0']
    });
  }

  async getCoursesList() {
    try {
      this.course_data_list = [];
      let temp = [];
      const search_form_query: any = {
        programme_type_id: this.polling_setting_form.value.programme_type_id,
        revision_year: 2020, //need to change dynamically
      };
      const service_response = await this.restService.getCourses(search_form_query);
      if (service_response && service_response.success) {
        var course_data = service_response.data;
        course_data.forEach(course => {
          temp.push(course);
        });
      }
      setTimeout(() => {
        this.course_data_list = temp;
      }, 1000)
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      if (this.state === 'ADD') {
        this.getProgrammeDataList(this.polling_setting_form);
        if (id === 'programme_type_id' && this.polling_setting_form.value.programme_type_id) {
          this.parts = this.commonEnums.getParts(this.polling_setting_form.value.programme_type_id);
        }
      } else {
        this.getProgrammeDataList(this.programme_search_form);
        if (id === 'programme_type_id' && this.programme_search_form.value.programme_type_id) {
          this.parts = this.commonEnums.getParts(this.programme_search_form.value.programmxe_type_id);
        }
      }
    } else if (id === 'part') {
      if (this.state === 'ADD') {
        if (this.polling_setting_form.value.programme_type_id) {
          this.polling_setting_form.get('course_type').setValue("");
          this.part_course_types = this.commonEnums.getCoursePartType(this.polling_setting_form.value.programme_type_id,
            this.polling_setting_form.value.part);
          if (this.polling_setting_form.value.part == 'PART_I' || this.polling_setting_form.value.part == 'PART_V') {
            this.getCoursesList();
          }
        }
      } else {
        if (this.polling_setting_form.value.programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(
            this.programme_search_form.value.programme_type_id, this.programme_search_form.value.part);
        }
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        /* if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        } */
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
        this.programme_list.forEach(programme => {
          programme.programme_display_name = (programme.programme_name + " " + (programme.finance_type == "regular" ? "(R)" : "(SF)"))
        })
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  onSearchClick() {
    if (!this.programme_search_form.value.programme_type_id) {
      alert("Please select Programme Type"); return;
    }
    if (!this.programme_search_form.value.enrolled_year) {
      alert("Please select Enrollment Year"); return;
    }
    if (!this.programme_search_form.value.semester) {
      alert("Please select Enrollment Year"); return;
    }
    this.getPollingSettingsList();
  }

  async getPollingSettingsList() {
    this.settings_list = [];
    this.settings_list_loading = true;
    try {
      const service_response = await this.restService.searchRequestMaster(this.programme_search_form.value);
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.settings_list_loading = false;
  }

  courseTypeChanged(ev) {
    const chk = (ev.target as HTMLInputElement);
    console.log(chk.value);
    if (chk.value == "NON_MAJOR_ELECTIVE") {
      this.getCoursesList();
      this.polling_setting_form.addControl("conditions", this.formBuilder.array(this.buildPollingConditionsForm(this.setting.conditions)))
    }
    else {
      this.course_data_list = [];
      this.polling_setting_form.removeControl("conditions");
    }
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        enrolled_year: 0,
        semester: 0,
        start_date: "",
        end_date: "",
        programme_type_id: "",
        part: "",
        course_type: "",
        active: false,
        locked: false,

        //new fields
        exclude_course_codes: [],
        conditions: [],
      };
    }
    this.polling_setting_form = this.formBuilder.group({
      _id: [this.setting._id],
      enrolled_year: [this.setting.enrolled_year],
      semester: [this.setting.semester],
      start_date: [this.setting.start_date != "" ? DateHelper.convertToControlDateTime(new Date(this.setting?.start_date)) : '', [Validators.required]],
      end_date: [this.setting.end_date != "" ? DateHelper.convertToControlDateTime(new Date(this.setting?.end_date)) : '', [Validators.required]],
      /* start_date: [this.setting.start_date != "" ? (this.setting?.start_date) : '', [Validators.required]],
      end_date: [this.setting.end_date != "" ? (this.setting?.end_date) : '', [Validators.required]], */
      programme_type_id: [this.setting.programme_type_id],
      part: [this.setting.part],
      course_type: [this.setting.course_type],
      active: [this.setting.active],
      locked: [this.setting.locked],
      exclude_course_codes: [
        this.setting.exclude_course_codes &&
          this.setting.exclude_course_codes?.length > 0 ?
          this.setting.exclude_course_codes.join(',') : ""],
      conditions: this.formBuilder.array(this.buildPollingConditionsForm(this.setting.conditions)),
      rule_list: this.formBuilder.array(this.buildRuleListForm(this.setting.rule_list)),
    });
    if (this.setting.course_type == 'NON_MAJOR_ELECTIVE') {
      this.getCoursesList();
      this.polling_setting_form.addControl("conditions", this.formBuilder.array(this.buildPollingConditionsForm(this.setting.conditions)))
    }
    if (this.setting.part == 'PART_I' || this.setting.part == 'PART_V') {
      this.getCoursesList();
      this.polling_setting_form.addControl("rule_list", this.formBuilder.array(this.buildRuleListForm(this.setting.rule_list)))
    }
    this.parts = this.commonEnums.getParts(this.polling_setting_form.value.programme_type_id);
    this.part_course_types = this.commonEnums.getCoursePartType(this.polling_setting_form.value.programme_type_id,
      this.polling_setting_form.value.part);
  }

  buildRuleListForm(rule_list: RuleDataInterface[]): any {
    if (!rule_list) {
      rule_list = [];
    }
    var rule_list_array: any[] = [];
    if (rule_list && rule_list.length > 0) {
      var rule_count = 0;
      for (const rule of rule_list) {
        //console.log(component)
        this.condition_delete_loading[rule_count] = false;
        rule_list_array.push(this.formBuilder.group({
          _id: [rule._id],
          rule_name: [rule.rule_name],
          allowed_programmes: [rule.allowed_programmes],
          group_data: this.formBuilder.array(this.buildGroupDataForm(rule.group_data)),
          is_expanded: [false],
        }));
        /* var allowed_programmes: any[] = [];
        console.log(this.programme_list);
        rule.allowed_programmes.forEach(programme_id => {
          allowed_programmes.push(this.programme_list.find(x => x.programme_id == programme_id))
        });
        console.log(allowed_programmes); */
        /* if (rule.allowed_programmes.length > 0) {
          (rule_list_array[rule_count] as FormGroup).addControl('allowed_programmes', new FormControl([allowed_programmes], [Validators.required]))
        } */
        rule_count++;
      }
    }
    const remaining_count = 1 - rule_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.condition_delete_loading[index] = false;
        rule_list_array.push(this.formBuilder.group({
          _id: [""],
          rule_name: [""],
          allowed_programmes: [""],
          group_data: this.formBuilder.array(this.buildGroupDataForm([])),
          is_expanded: [false],
        }));
      }
    }
    console.log(rule_list_array);
    return rule_list_array;
  }

  get ruleList() {
    return (this.polling_setting_form.get('rule_list') as FormArray).controls;
  }

  viewGroupData(rule) {
    //add code to close other section's allowed programme
    ((this.polling_setting_form as FormGroup).get('rule_list') as FormArray).controls.forEach((control: FormGroup) => {
      control.get('is_expanded').setValue(false);
    });
    rule.get('is_expanded').setValue(true);
  }

  closeGroupData(rule) {
    rule.get('is_expanded').setValue(false);
  }

  addRule() {
    (this.polling_setting_form.get('rule_list') as FormArray).push(this.formBuilder.group({
      _id: [""],
      rule_name: [""],
      allowed_programmes: [""],
      group_data: this.formBuilder.array(this.buildGroupDataForm([])),
      is_expanded: [false],
    }));
  }

  deleteRule(i: number) {
    const consent = confirm("Are you sure want to delete this rule?");
    if (!consent)
      return;
    (this.polling_setting_form.get('rule_list') as FormArray).removeAt(i);
  }

  buildGroupDataForm(group_list: GroupDataInterface[]): any {
    if (!group_list) {
      group_list = [];
    }
    var group_list_array: any[] = [];
    if (group_list && group_list.length > 0) {
      var group_count = 0;
      for (const group of group_list) {
        //console.log(component)
        this.condition_delete_loading[group_count] = false;
        group_list_array.push(this.formBuilder.group({
          _id: [group._id],
          group_name: [group.group_name],
          available_courses: [group.available_courses],
        }));
        group_count++;
      }
    }
    const remaining_count = 1 - group_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.condition_delete_loading[index] = false;
        group_list_array.push(this.formBuilder.group({
          _id: [""],
          group_name: [""],
          available_courses: [[]],
        }));
      }
    }
    console.log(group_list_array);
    return group_list_array;
  }


  getGroupData(i: number) {
    return (((this.polling_setting_form.get('rule_list') as FormArray).controls[i] as FormGroup).get('group_data') as FormArray).controls;
  }

  addGroupData(i: number) {
    ((this.polling_setting_form.get('rule_list') as FormArray).controls[i].get('group_data') as FormArray).
      push(this.formBuilder.group({
        _id: [""],
        group_name: [""],
        available_courses: [[]],
      }));
  }

  deleteGroupData(i: number, j: number) {
    const consent = confirm("Are you sure want to delete this Group?");
    if (!consent)
      return;
    ((this.polling_setting_form.get('rule_list') as FormArray).controls[i].get('group_data') as FormArray).removeAt(j);
  }


  buildPollingConditionsForm(conditions_list): any {
    if (!conditions_list) {
      conditions_list = [];
    }
    var condition_list_array: any[] = [];
    if (conditions_list && conditions_list.length > 0) {
      var condition_count = 0;
      for (const condition of conditions_list) {
        //console.log(component)
        this.condition_delete_loading[condition_count] = false;
        condition_list_array.push(this.formBuilder.group({
          studied_course_codes: [condition.studied_course_codes],
          allowed_course_codes: [condition.allowed_course_codes],
          tamil_in_hsc: [condition.tamil_in_hsc],
        }));
        condition_count++;
      }
    }
    const remaining_count = 1 - conditions_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.condition_delete_loading[index] = false;
        condition_list_array.push(this.formBuilder.group({
          studied_course_codes: [""],
          allowed_course_codes: [""],
          tamil_in_hsc: [""],
        }));
      }
    }
    console.log(condition_list_array);
    return condition_list_array;
  }

  get conditionList() {
    return ((this.polling_setting_form as FormGroup).get('conditions') as FormArray).controls;
  }

  addCondition() {
    ((this.polling_setting_form as FormGroup).get('conditions') as FormArray).push(this.formBuilder.group({
      studied_course_codes: [""],
      allowed_course_codes: [""],
      tamil_in_hsc: [""],
    }));
  }

  moveUp(current_index) {
    if (current_index == 0) {
      alert("Cannot move upward further!")
      return;
    }
    else {
      var upIndex = current_index - 1;
      var arr = ((this.polling_setting_form as FormGroup).get('conditions') as FormArray).controls;
      var element = arr[current_index];
      arr.splice(current_index, 1);
      arr.splice(upIndex, 0, element);
    }
  }

  moveDown(current_index) {
    var arr = ((this.polling_setting_form as FormGroup).get('conditions') as FormArray).controls;
    if (current_index == arr.length - 1) {
      alert("Cannot move donward further");
      return;
    }
    else {
      var downIndex = current_index + 1;
      var element = arr[current_index];
      arr.splice(current_index, 1);
      arr.splice(downIndex, 0, element);
    }
  }

  deleteCondition(index: number) {
    const consent = confirm("Are you sure want to delete this condition?");
    if (!consent) return;
    ((this.polling_setting_form as FormGroup).get('conditions') as FormArray).removeAt(index);
  }

  onAddClick() {
    this.buildForm();
    this.state = "ADD";
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    this.setting_id = setting._id; //term_name
    var settings = this.settings_list.find(x => x._id == setting._id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.state = "";
    this.parts = [];
    this.part_course_types = [];
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as SettingInterface;
    this.polling_setting_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.polling_setting_form.value;
      form_data.start_date = new Date(this.polling_setting_form.value.start_date).toISOString()
      form_data.end_date = new Date(this.polling_setting_form.value.end_date).toISOString()
      form_data.exclude_course_codes = (this.polling_setting_form.value.exclude_course_codes as string).split(",");
      if (form_data.part == 'PART_I' && form_data.part == 'PART_II') {
        delete form_data.course_type;
      }
      if (form_data.part != 'PART_I' && form_data.part != 'PART_V') {
        delete form_data.rule_list;
      }
      console.log(form_data);
      let save_respose;
      save_respose = await this.restService.createRequestMaster(this.polling_setting_form.value);
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Saved Successfully');
      }
      this.getPollingSettingsList(); //refresh list
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      //alert("An error occured while saving");
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      const res = await this.restService.deleteRequestMaster(this.polling_setting_form.get('_id')!.value);
      if (res.success) {
        alert("Deleted Polling Master settings successfully");
        this.getPollingSettingsList();
      }
      else {
        alert("Failed to delete polling settings");
      }

      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
    }
  }
  isInvalid(formControl: string): boolean {
    return this.polling_setting_form.touched && this.polling_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.polling_setting_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  navigateToManageRequest(setting) {
    /* HTMLHelper.openLinkInNewTab("/admin/obe/manage-elective-request?programme_type_id=" + this.programme_search_form.value.programme_type_id
      + "&enrolled_year=" + this.programme_search_form.value.enrolled_year
      + "&semester=" + this.programme_search_form.value.semester
      + "&obe_course_request_master_id=" + setting._id
    ); */
    window.open("/admin/obe/manage-elective-request?programme_type_id=" + this.programme_search_form.value.programme_type_id
      + "&enrolled_year=" + this.programme_search_form.value.enrolled_year
      + "&semester=" + this.programme_search_form.value.semester
      + "&obe_course_request_master_id=" + setting._id, "_blank");
    /* this.router.navigate(["/admin/obe/manage-elective-request"], {
      queryParams: {
        programme_type_id: this.programme_search_form.value.programme_type_id,
        enrolled_year: this.programme_search_form.value.enrolled_year,
        semester: this.programme_search_form.value.semester,
        obe_course_request_master_id: setting._id
      }
    }); */
  }

  navigateToReport(setting) {
    HTMLHelper.openLinkInNewTab("/admin/obe/view-elective-report?programme_type_id=" + this.programme_search_form.value.programme_type_id
      + "&enrolled_year=" + this.programme_search_form.value.enrolled_year
      + "&semester=" + this.programme_search_form.value.semester
      + "&obe_course_request_master_id=" + setting._id
    );
  }

  navigateToVirtualSection(setting) {
    HTMLHelper.openLinkInNewTab("/admin/obe/virtual-section-settings?programme_type_id=" + this.programme_search_form.value.programme_type_id
      + "&enrolled_year=" + this.programme_search_form.value.enrolled_year
      + "&semester=" + this.programme_search_form.value.semester
      + "&obe_course_request_master_id=" + setting._id
    );
  }

  async lockCourseRequest(setting) {
    //if (is_locked) return;
    const consent = confirm("Are you sure you want to lock requests for this course type? This action is irreversible!");
    if (!consent) {
      return;
    }
    try {
      var course_data: any = {
        locked: true
      }
      let save_respose;
      save_respose = await this.restService.finalizeElectivePolling(setting._id, {});
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Polling locked Successfully');
      }
      this.getPollingSettingsList(); //refresh list
    } catch (error) {
      alert("An error occured while updating");
    }

  }
}

interface SettingInterface {
  _id: string,
  enrolled_year: number,
  semester: number,
  programme_type_id: string,
  part: string,
  course_type: string,
  start_date: string,
  end_date?: string,
  active: boolean,
  locked: boolean,

  //new fields
  exclude_course_codes: string[],
  conditions: {
    studied_course_codes: string[],
    allowed_course_codes: string[],
    tamil_in_hsc: string
  }[],
  rule_list?: RuleDataInterface[]
}

interface RuleDataInterface {
  _id?: string,
  rule_name: string,
  allowed_programmes: string[],
  group_data: GroupDataInterface[]
}
interface GroupDataInterface {
  _id?: string,
  group_name: string,
  available_courses: string[]
}