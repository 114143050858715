import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-child',
  templateUrl: './choose-child.component.html',
  styleUrls: ['./choose-child.component.scss']
})
export class ChooseChildComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
