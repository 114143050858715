import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-fee-collection-report',
  templateUrl: './fee-collection-report.component.html',
  styleUrls: ['./fee-collection-report.component.scss']
})
export class FeeCollectionReportComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  programme_fee_patch_form: any;

  admissions_list: any[] = [];
  admissions_list_loading = false;


  programme_list: any[] = [];
  programme_list_loading = false;


  fee_data: any;
  fee_data_loading = false;


  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  // for admission report
  admission_id = '';
  admission_report = false;
  finance_type = '';
  admission_name = '';
  programme_id = '';
  semester: any = '';


  // programme fee settings
  programme_fee_patch_form_popup: any;

  programme_fee_settings_form: any;
  programme_fee_settings_save_loading = false;
  programme_fee_settings_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();

    this.route.queryParams.subscribe(data => {
      if (data.admission_id) {
        this.admission_id = data.admission_id;
        this.semester = 1;
      }
      if (data.admission_report) {
        this.admission_report = data.admission_report === 'true' ? true : false;
      }
      if (data.finance_type) {
        this.finance_type = data.finance_type;
      }
      if (data.admission_name) {
        this.admission_name = data.admission_name;
      }
      if (data.programme_id) {
        this.programme_id = data.programme_id;
      }

    });


  }

  ngOnInit(): void {
    this.getAdmissionList();
    this.getProgrammeTypes();
  }


  async getProgrammeTypes(): Promise<void> {
    try {
      if (!this.admission_report) {
        this.programme_type_list_loading = true;
        const service_response = await this.restService.getProgrammeTypes();
        if (service_response && service_response.success) {
          this.programme_type_list = service_response.data;
          this.buildProgrammeFeeForm();
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_type_list_loading = false;
    }
  }



  async getAdmissionList(): Promise<void> {
    try {
      if (this.admission_report) {
        this.programme_fee_patch_form = null;
        this.admissions_list = [];
        this.admissions_list_loading = true;
        const service_response = await this.restService.searchAdmissionList({});
        if (service_response && service_response.success) {
          this.admissions_list = service_response.data;
          this.buildProgrammeFeeForm();
          if (this.admission_report) {
            this.onSearchClick();
          }
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  buildProgrammeFeeForm(): void {
    this.programme_fee_patch_form = this.fb.group({
      enrolled_year: [new Date().getFullYear()],
      programme_type_id: [''],
      admission_id: [this.admission_id],
      admission_name: [this.admission_name],
      finance_type: [this.finance_type],
      programme_id: [this.programme_id],
      semester: [this.semester],
      // term: [''],
      // fee_target_type: ['']
    });
  }


  async onYearChange(form: FormGroup): Promise<void> {
    form.get('programme_type_id')!.setValue('');
    form.get('finance_type')!.setValue('');
    form.get('programme_id')!.setValue('');
    form.get('semester')!.setValue('');

  }

  async onProgrammeTypeChange(form: FormGroup): Promise<void> {
    form.get('finance_type')!.setValue('');
    form.get('programme_id')!.setValue('');
    form.get('semester')!.setValue('');
  }

  async onPrgFinanceTypeChange(form: FormGroup): Promise<void> {
    try {
      if (!form.value.finance_type) {
        return;
      }
      if (!form.value.programme_type_id) {
        return;
      }
      const query: any = {
        finance_type: form.value.finance_type,
        programme_type_id: form.value.programme_type_id
      };
      form.get('programme_id')!.setValue('');
      form.get('semester')!.setValue('');
      this.programme_list_loading = true;
      this.programme_list = [];
      const response = await this.restService.searchProgrammesLite(query);
      if (response.success && response.data && response.data.length > 0) {
        this.programme_list = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    try {
      if (!this.admission_report) {
        if (!this.programme_fee_patch_form.value.enrolled_year) {
          alert('Please select enrolled year');
          return;
        }
        if (!this.programme_fee_patch_form.value.programme_type_id) {
          alert('Please select programme type');
          return;
        }
      }
      if (!this.programme_fee_patch_form.value.semester) {
        alert('Please select semester');
        return;
      }
      if (!this.programme_fee_patch_form.value.finance_type) {
        alert('Please select finance type');
        return;
      }
      this.fee_data_loading = true;
      this.fee_data = null;
      const query_data = this.programme_fee_patch_form.value;
      if (this.admission_report) {
        query_data.admission_report = this.admission_report;
      }
      const response = await this.restService.getFeeCollectionReport(query_data);
      if (response.success && response.data) {
        this.fee_data = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.fee_data_loading = false;
    }
  }



  //#region Programme Form
  async onConfigureProgrammeFeeClick(): Promise<void> {
    this.buildProgrammeFeeSettingForm();
    JQueryHelper.openModal('#modal-programme-fee', { keyboard: false, backdrop: 'static' });
  }


  buildProgrammeFeeSettingForm(): void {
    this.programme_fee_patch_form_popup = this.fb.group({
      programme_type_id: [this.programme_fee_patch_form.value.programme_type_id],
      regulation_year: [this.programme_fee_patch_form.value.enrolled_year],
      finance_type: [this.programme_fee_patch_form.value.finance_type],
      programme_id: [''],
      semester: [''],
      term: [''],
      fee_target_type: ['']
    });
  }


  async onSearchPrgFeeClick(): Promise<void> {

    try {
      if (!this.programme_fee_patch_form_popup.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.semester) {
        alert('Please select semester');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.term) {
        alert('Please select term');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.fee_target_type) {
        alert('Please select fee_target_type');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.programme_type_id) {
        alert('Please select programme_type_id');
        return;
      }
      this.programme_fee_settings_loading = true;

      const query: any = {
        programme_id: this.programme_fee_patch_form_popup.value.programme_id,
        regulation_year: this.programme_fee_patch_form_popup.value.regulation_year,
        programme_type_id: this.programme_fee_patch_form_popup.value.programme_type_id,
        semester: this.programme_fee_patch_form_popup.value.semester,
        term: this.programme_fee_patch_form_popup.value.term,
        fee_target_type: this.programme_fee_patch_form_popup.value.fee_target_type,
      };
      const response = await this.restService.getSavedProgrammeFeeSettings(query);
      if (response.success && response.data) {
        this.buildProgrammeSplitSettingsForm(this.programme_fee_patch_form_popup.value.programme_id, response.data);
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_fee_settings_loading = false;
    }


  }

  buildProgrammeSplitSettingsForm(programme_id: string, data: any): void {
    this.programme_fee_settings_form = this.fb.group({
      programme_id: [programme_id],
      mode: [data.mode || ''],
      accounts: this.fb.array(this.buildProgrammeSplitItemForm(data.account_splits))
    });
  }

  buildProgrammeSplitItemForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return: any[] = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        sub_acc_id: [data_item.sub_acc_id],
        account_no: [data_item.account_no],
        amount: [data_item.amount],
      }));
    }
    return value_to_return;
  }

  getTotalAmount(): number {
    const programme_fee_form_data = this.programme_fee_settings_form.value;
    const account_data_list = programme_fee_form_data.accounts;
    let total_amount = 0;
    let has_error = false;
    for (const account_data of account_data_list) {


      try {
        account_data.amount = parseFloat(account_data.amount);
      } catch (error) {
        alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
        has_error = true;
        break;
      }

      if (account_data.application_fee_amount < 0) {
        alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
        has_error = true;
        break;
      }

      total_amount = total_amount + account_data.amount;

    }
    if (has_error) {
      return 0;
    }
    return total_amount;
  }
  async onSaveProgrammeFormClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.regulation_year) {
        alert('Please select enrolled year');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.semester) {
        alert('Please select semester');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.term) {
        alert('Please select term');
        return;
      }
      if (!this.programme_fee_patch_form_popup.value.fee_target_type) {
        alert('Please select fee_target_type');
        return;
      }

      this.programme_fee_settings_save_loading = true;

      const programme_fee_form_data = this.programme_fee_settings_form.value;
      const account_data_list = programme_fee_form_data.accounts;


      let has_error = false;
      let total_amount = 0;
      for (const account_data of account_data_list) {


        try {
          account_data.amount = parseFloat(account_data.amount);
        } catch (error) {
          alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
          has_error = true;
          break;
        }

        if (account_data.application_fee_amount < 0) {
          alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
          has_error = true;
          break;
        }
        total_amount = total_amount + account_data.amount;

      }
      if (has_error) {
        return;
      }

      const data_to_save: any = {
        regulation_year: this.programme_fee_patch_form_popup.value.regulation_year,
        programme_id: this.programme_fee_patch_form_popup.value.programme_id,
        semester: this.programme_fee_patch_form_popup.value.semester,
        term: this.programme_fee_patch_form_popup.value.term,
        fee_target_type: this.programme_fee_patch_form_popup.value.fee_target_type,
        mode: this.programme_fee_settings_form.value.mode,
        amount: total_amount,
        account_splits: account_data_list
      };


      const saved_respose = await this.restService.createProgrammeFeeBulk(data_to_save);
      if (saved_respose.success) {
        alert('Saved Successfully');
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_fee_settings_save_loading = false;
    }
  }

  resetProgrammeFeeForm(): void {
    this.programme_fee_patch_form_popup = undefined;
    this.programme_fee_settings_form = undefined;
    this.programme_list = [];
  }
  async onProgrammeFeeFormCloseClick(): Promise<void> {
    this.resetProgrammeFeeForm();
    JQueryHelper.closeModal('#modal-programme-fee');
  }

  //#endregion


}
