import { Assets } from './../../controllers/admins/hostel-management/asset-management/assets';
import { Injectable } from '@angular/core';
import { Incharge } from './../../controllers/admins/hostel-management/incharge-model';
import { Floor } from './../../controllers/admins/hostel-management/floor-model';
import { Hostel, HostelBody } from './../../controllers/admins/hostel-management/hostelModel';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RoomAssetService {
  private _baseURL: string = ''
  private _headers: any;
  private roomAssetURL: string = 'hostel-srv/admin/roomAsset';
  private roomAssetByRefURL: string = 'hostel-srv/admin/roomAssetByRef';

  constructor(private httpClient: HttpClient) { }

  set url(baseURL: string) {
    this._baseURL = baseURL;
  }

  set headers(headers: any) {
    this._headers = headers;
  }

  errorHandler = (error: any): Observable<Hostel> => {
    console.log('roomAssetService Error ', error);
    return of({} as Hostel);
  }

  getRoomAsset(id: string = ''): Promise<Assets[]> {
    let url = `${this._baseURL}/${this.roomAssetURL}/`;
    if (id) {
      url = url.concat(`${id}`);
    }
    return this.httpClient.get<Assets[]>(url, this._headers).pipe(
      map((res: any) => {
        if (Array.isArray(res.data)) {
          return res.data;
        }
        return [res.data];
      })
    ).toPromise();
  }

  getRoomAssetByRef(ref: string = ''): Promise<Assets> {
    let url = `${this._baseURL}/${this.roomAssetByRefURL}/`;
    if (ref) {
      url = url.concat(`${ref}`);
    }
    return this.httpClient.get<Assets>(url, this._headers).pipe(
      map((res: any) => {
        return res.data;
      })
    ).toPromise();
  }

  createRoomAsset(asset: Assets): Promise<Assets> {
    return this.httpClient.post<Assets>(`${this._baseURL}/${this.roomAssetURL}`, asset, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }

  updateRoomAsset(id: string = '', data: Assets): Promise<Assets> {
    return this.httpClient.put<Assets>(`${this._baseURL}/${this.roomAssetURL}/${id}`, data, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }

  deleteRoomAsset(assetId: string): Promise<Assets> {
    return this.httpClient.delete<boolean>(`${this._baseURL}/${this.roomAssetURL}/${assetId}`, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }
}
