<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Fees Report</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <!-- <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Course</button>
                </div> -->
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Fees Report</h4>
              <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
            </div>
            <p class="tx-12 tx-gray-500 mb-2">View Fees Report</p>
          </div>

          <div class="card-body" *ngIf="programme_search_form">
            <form [formGroup]="programme_search_form">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                      Type</label>
                    <select formControlName="programme_type_id" class="form-control"
                      (change)="onFilterSelectChange('programme_type_id',$event)">
                      <option value="">Select Programme Type</option>
                      <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="category.programme_type_id">{{category.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Purpose</label>
                    <select formControlName="purpose" class="form-control" (change)="onPurposeChange()">
                      <option value="">Select Purpose</option>
                      <option *ngFor="let purpose of purposes;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="purpose.id">{{purpose.name}}</option>
                    </select>
                  </div>
                </div>


                <div class="col-lg-2"
                  *ngIf="programme_search_form.value.purpose!='' && programme_search_form.value.purpose!='HOSTEL_ADMISSION_FEE' && programme_search_form.value.purpose!='TRANSPORTATION_ADMISSION_FEE'">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Fee
                      Term</label>
                    <select formControlName="fee_header_id" class="form-control" (change)="onResetClick()">
                      <option value="">Select Fee Term</option>
                      <option *ngFor="let term of fees_headers;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="term.id">{{term.term_name}}
                        {{(programme_search_form.value.programme_type_id=='')?
                        (term.programme_type_id?("("+term.programme_type_id+")"):'')
                        :''}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2"
                  *ngIf="programme_search_form.value.purpose!='' && (programme_search_form.value.purpose=='HOSTEL_ADMISSION_FEE' || programme_search_form.value.purpose=='TRANSPORTATION_ADMISSION_FEE')">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Fee
                      Term</label>
                    <select formControlName="fee_header_id" class="form-control" (change)="onResetClick()">
                      <option value="">Select Fee Term</option>
                      <option *ngFor="let term of fees_headers;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="term.admission_id">
                        {{term.admission_name}}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                      Date</label>
                    <input type="date" class="form-control" formControlName="start_date" (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                      Date</label>
                    <input type="date" class="form-control" formControlName="end_date" (change)="onResetClick()">
                  </div>
                </div>



                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                      Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Programme</label>
                    <select formControlName="programme_id" class="form-control"
                      (change)="onFilterSelectChange('programme_id',$event)">
                      <option value="">All Programmes</option>
                      <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programme.programme_id">{{programme.programme_name}}
                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                      year</label>
                    <select formControlName="enrolled_year" class="form-control" (change)="onResetClick()">
                      <option value="">Select Enrolment year</option>
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>


                <div class="col-lg-2" *ngIf="programme_search_form.value.programme_id!='' && sections.length>1">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Section</label>
                    <select formControlName="section" class="form-control" (change)="onResetClick()">
                      <option value="">Choose Section</option>
                      <option *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="section.section_name">{{section.section_name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Gender</label>
                    <select formControlName="gender" class="form-control" (change)="onResetClick()">
                      <option value="">Select Gender</option>
                      <option *ngFor="let year of commonEnums.genders;let i=index;trackBy:fromDataResolver.identify;"
                        [value]="year.id">{{year.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Payment
                      Status</label>
                    <select formControlName="payment_collected" class="form-control" (change)="onResetClick()">
                      <option value="d">Select payment status</option>
                      <option *ngFor="let status of payment_status;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="status.id"
                        [disabled]="((programme_search_form.value.purpose=='HOSTEL_ADMISSION_FEE' || programme_search_form.value.purpose=='TRANSPORTATION_ADMISSION_FEE') && status.name == 'NOT PAID')?true:null">
                        {{status.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                      Community</label>
                    <!-- <kendo-multiselect [data]="commonEnums.community_list" formControlName="community_ids"
                      placeholder="Select Community Name(s)" class="menu_select"></kendo-multiselect> -->
                    <ng-multiselect-dropdown [placeholder]="'Select Community Name(s)'" [settings]="dropdownSettings"
                      [data]="commonEnums.community_list" formControlName="community_ids">
                    </ng-multiselect-dropdown>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Amount Split
                      status</label>
                    <select formControlName="split_status" class="form-control" (change)="onResetClick()">
                      <option value="d">Select Amount Split Status</option>
                      <option *ngFor="let status of split_status;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="status.id">
                        {{status.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Split Start
                      Date</label>
                    <input type="date" class="form-control" formControlName="split_start_date"
                      (change)="onResetClick()">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Split End
                      Date</label>
                    <input type="date" class="form-control" formControlName="split_end_date" (change)="onResetClick()">
                  </div>
                </div>


                <!-- <div class="col-lg-2" *ngIf="programme_search_form.value.purpose!=''">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Month</label>
                                        <select formControlName="month" class="form-control">
                                            <option value="">Select Month</option>
                                            <option *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="month.id">{{month.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="programme_search_form.value.purpose!=''">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Year</label>
                                        <select formControlName="year" class="form-control">
                                            <option value="">Select Year</option>
                                            <option *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div> -->





                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!report_list_loading" (click)="onSearchClick()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="report_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div class="row">
              <div class="col-lg-4">
                <input type="checkbox" [(ngModel)]="show_split" class="mg-r-5" (change)="showSplit($event)">Show Account
                Wise Collection Breakup
              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="d-flex right-content mg-b-5" style="float: right;">
              <ng-container *ngIf="fees_report.length > 0 && programme_search_form.value.purpose == 'EXAM_FEE' && programme_search_form.value.payment_collected == false">
                <button type="button" class="btn btn-info mr-2" [disabled]="disableApplyFine()" title="Search"
                    *ngIf="!apply_fine_loading" (click)="applyExamFeeFine()">
                    Apply fine
                  </button>
                  <button class="btn btn-info mr-2" type="button" disabled
                    *ngIf="apply_fine_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
              </ng-container>
              <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info" data-toggle="dropdown"
                  id="dropDownMenuButton" type="button">Download<i class="fas fa-caret-down ml-1"></i></button>
                <div class="dropdown-menu tx-13">
                  <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                  <!-- <a class="dropdown-item" (click)="downloadPDF()">Download PDF</a> -->
                  <!-- <a class="dropdown-item" (click)="downloadDBF()">Download DBF</a> -->
                </div>
              </div>
            </div>
            <div class="table-responsive" id="print-table">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th class="table-cell">S.No</th>
                    <th class="table-cell">Roll No.</th>
                    <th class="table-cell">Name</th>
                    <th class="table-cell">Gender</th>
                    <th class="table-cell">Class</th>
                    <th class="table-cell">Paid Date</th>
                    <th class="table-cell">Fees</th>
                    <th class="table-cell">Fine</th>
                    <th class="table-cell">Total</th>
                    <th class="table-cell">Purpose</th>
                    <th class="table-cell">Term</th>
                    <th class="table-cell">Payment Status</th>
                    <th class="table-cell">Split Status</th>
                    <th class="table-cell">Split Date</th>
                    <ng-container *ngIf="show_split">
                      <th *ngFor="let split_header of split_headers; let i=index;trackBy:fromDataResolver.identify;"
                        class="table-cell">
                        {{split_header}}
                      </th>
                    </ng-container>
                    <th class="table-cell">Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of fees_report; let i=index;trackBy:fromDataResolver.identify;">
                    <td>{{this.start_index + i+1}}</td>
                    <td>{{report.roll_no}}</td>
                    <td class="uppercase">{{report.name}}</td>
                    <td>{{getGender(report.gender)}}</td>
                    <td class="uppercase">
                      {{commonEnums.getClassName(report?.enrolled_year,report?.programme_name,report?.section)}}
                    </td>
                    <td>{{report.hasOwnProperty('payment_date')?(report?.payment_date |
                      date:commonEnums.date_format):'---'}}</td>
                    <td>Rs. {{getOriginalAmount(report)}}</td>
                    <!-- <td>Rs. {{report.original_amount}}</td> -->
                    <td>Rs. {{report.fine? report.fine:'0'}}</td>
                    <td>Rs. {{report.amount}}</td>
                    <td class="uppercase">{{getPurpose(report.purpose)}}</td>
                    <td class="uppercase">{{report.term_name}}</td>
                    <td>{{getPaymentStatus(report.payment_collected)}}</td>
                    <td>{{(report?.split_status == true)?'Collected':'Not Collected'}}</td>
                    <td>{{report?.split_date | date:commonEnums.date_format}}</td>
                    <ng-container *ngIf="show_split">
                      <th *ngFor="let split_header of split_headers; let i=index;trackBy:fromDataResolver.identify;">
                        <span *ngIf="report!.split_data_list[i].amount != 0">{{report!.split_data_list[i].amount}}
                        </span>
                        <span *ngIf="report!.split_data_list[i].amount == 0">0</span>
                      </th>
                    </ng-container>
                    <td>
                      <button type="button"
                        (click)="viewTransactionHistory(report.transaction_header_id, report.roll_no)"
                        class="btn btn-sm btn-info mr-2">
                        <fa-icon [icon]="fonts.faInfo"></fa-icon>
                      </button>

                    </td>

                  </tr>
                  <ng-container *ngIf="!report_list_loading && fees_report?.length==0">
                    <tr>
                      <th colspan="15" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="report_list_loading && fees_report?.length==0">
                    <tr>
                      <th colspan="15" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <ng-container *ngIf="total_records>500">
                <ul class="pagination pull-right">
                  <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                        class="icon ion-ios-arrow-back"></i></a></li>

                  <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                        class="icon ion-ios-arrow-forward"></i></a></li>
                </ul>
              </ng-container>
            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- Settings Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">View Transaction History</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              Transaction JSON History
              <div class="text-center">
                <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->


  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
