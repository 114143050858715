<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Elective Request</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Manage Elective Request</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Approve/reject student Elective Course Requests</p>
                    </div>

                    <div class="card-body" *ngIf="search_form">
                        <form [formGroup]="search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="filterChange($event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control"
                                            (change)="filterChange($event)">
                                            <option value="">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control"
                                            (change)="filterChange($event)">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="this.part_course_types && this.part_course_types?.length>0">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Type</label>
                                        <select formControlName="course_type" class="form-control">
                                            <option value="">All Course Type</option>
                                            <option
                                                *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="course_type.id">{{course_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course</label>
                                        <select formControlName="obe_course_request_master_id" class="form-control">
                                            <option value="">Select Course</option>
                                            <option
                                                *ngFor="let setting of this.settings_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="setting._id">{{setting.part}}-{{setting.course_type}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h5>AYYA NADAR JANAKI AMMAL COLLEGE (AUTONOMOUS), SIVAKASI.</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 text-center">
                                <b>{{commonEnums.getClassName(search_form.value.enrolled_year)}} Year -
                                    {{getCourseType()}}</b>
                            </div>
                            <div class="col-md-4 text-center">
                                <b>ACADEMIC AFFAIRS {{search_form.value.programme_type_id}}</b>
                            </div>
                            <div class="col-md-4 text-center">
                                <b>{{commonEnums.getAcademicYear()}}</b>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-md-12" style="text-align: right;">
                                <button class="btn btn-sm btn-secondary" (click)="printTable()">Download Excel</button>
                            </div>
                        </div>
                        <div class="table-responsive table-container" *ngIf="courses_request_data.length>0"
                            id="report-html">
                            <table class="table table-bordered table-hover" id="matrix-table">
                                <thead>
                                    <tr>
                                        <th rowspan="2"
                                            style="vertical-align: middle !important; background: white; z-index: 15;">
                                            Code
                                            <br>(Allowed Seats Count)
                                        </th>
                                        <th rowspan="2" style="vertical-align: middle !important;">Title</th>
                                        <th rowspan="2" style="vertical-align: middle !important;">Dept Offering</th>
                                        <ng-container
                                            *ngFor="let head of programme_header; let i=index;trackBy: fromDataResolver.identify">
                                            <th class="text-center" style="vertical-align: middle !important;"
                                                [attr.colspan]="head.section_list.length"
                                                [attr.rowspan]="(head.section_list.length-1==0)?2:1">
                                                {{head.programme_name}}</th>
                                        </ng-container>
                                        <!-- <th rowspan="2" style="vertical-align: middle !important;">Total</th> -->
                                    </tr>
                                    <tr>
                                        <ng-container
                                            *ngFor="let head of programme_header; let j=index;trackBy: fromDataResolver.identify">
                                            <ng-container *ngIf="head.section_list.length>1">
                                                <th
                                                    *ngFor="let section of head.section_list; let k=index;trackBy: fromDataResolver.identify">
                                                    {{section}}
                                                </th>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let course_request of courses_request_data; let l=index;trackBy: fromDataResolver.identify">
                                        <td>{{course_request.course_code}} <br> ({{course_request.seats_allowed_count}})
                                        </td>
                                        <td>{{course_request.course_name}}</td>
                                        <td>{{course_request.offered_by_programme_name}}</td>
                                        <td class="content-cell"
                                            *ngFor="let request of course_request.programme_wise_list; let m=index;trackBy: fromDataResolver.identify">
                                            <span
                                                (click)="viewStudentList(request.programme_id,request.section, course_request.course_code,course_request.course_name, course_request.offered_by_programme_name)"
                                                style="cursor:pointer"
                                                *ngIf="request.requested_count>0 && course_request.programme_wise_list.length-1!=m"><b>{{request.approved_count}}:{{request.requested_count}}</b></span>
                                            <span
                                                *ngIf="request.requested_count==0 && course_request.programme_wise_list.length-1!=m">
                                                0
                                            </span>
                                            <span *ngIf="course_request.programme_wise_list.length-1==m">
                                                <b>{{request.approved_count}}:{{request.requested_count}}</b>
                                            </span>

                                        </td>
                                        <!-- <td>
                                            <span><b>{{course_request.total_approved_count}} /
                                                    {{course_request.total_request_count}}</b></span>
                                        </td> -->
                                    </tr>
                                    <tr style="font-weight:bold ;">
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td
                                            *ngFor="let total of total_row; let n=index; trackBy: fromDataResolver.identify">
                                            {{total}}</td>
                                        <!-- <td>{{total_count}}</td> -->
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div class="row" *ngIf="courses_request_data.length==0 && !table_list_loading">
                            <div class="col-md-12 text-center">
                                <span><b>No Request Data Found</b></span>
                            </div>
                        </div>

                        <div class="row" *ngIf="courses_request_data.length==0 && table_list_loading">
                            <div class="col-md-12 text-center">
                                <span><b>Loading Request Data...</b></span>
                            </div>
                        </div>


                        <div class="row" *ngIf="courses_request_data.length>0">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-md btn-primary" *ngIf="!approve_all_loading"
                                    (click)="approveAllStudents()">
                                    Approve All
                                </button>
                                <button class="btn btn-md btn-primary" disabled *ngIf="approve_all_loading">
                                    Saving...
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>



    <!--New Course Apply Modal-->
    <div class="modal" id="request-modal-popup">
        <div class="modal-dialog big-modal" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">Manage Course Request</h6>
                </div>
                <!-- <div class="card-body" *ngIf="modal_mode=='LIST'">
                    <div class="row">
                        <div class="col-md-12 text-center" *ngIf="table_list_loading && courses_request_data.length==0">
                            <span>Loading Data</span>
                        </div>
                        <div class="col-md-12 text-center"
                            *ngIf="!table_list_loading && courses_request_data.length==0">
                            <span>No Records Found</span>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="vertical-align: middle !important;">Code</th>
                                    <th rowspan="2" style="vertical-align: middle !important;">Title</th>
                                    <th rowspan="2" style="vertical-align: middle !important;">Dept Offering</th>
                                    <ng-container
                                        *ngFor="let head of programme_header; let i=index;trackBy: fromDataResolver.identify">
                                        <th class="text-center" style="vertical-align: middle !important;"
                                            [attr.colspan]="head.section_list.length"
                                            [attr.rowspan]="(head.section_list.length-1==0)?2:1">
                                            {{head.programme_short_code}}</th>
                                    </ng-container>
                                    <th rowspan="2" style="vertical-align: middle !important;">Total</th>
                                </tr>
                                <tr>
                                    <ng-container
                                        *ngFor="let head of programme_header; let j=index;trackBy: fromDataResolver.identify">
                                        <ng-container *ngIf="head.section_list.length>1">
                                            <th
                                                *ngFor="let section of head.section_list; let k=index;trackBy: fromDataResolver.identify">
                                                {{section}}
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let course_request of courses_request_data; let l=index;trackBy: fromDataResolver.identify">
                                    <td>{{course_request.course_code}}</td>
                                    <td>{{course_request.course_name}}</td>
                                    <td>{{course_request.offering_programme_name}}</td>
                                    <td
                                        *ngFor="let request of course_request.request_data; let m=index;trackBy: fromDataResolver.identify">
                                        <span
                                            (click)="viewStudentList(request.programme_id, course_request.course_code,course_request.course_name, course_request.offering_programme_name)"
                                            style="cursor:pointer"
                                            *ngIf="request.request_count>0"><b>{{request.approved_count}}/{{request.request_count}}</b></span>
                                        <span *ngIf="request.request_count==0">
                                            0
                                        </span>
                                    </td>
                                    <td>
                                        <span><b>{{course_request.total_approved_count}} /
                                                {{course_request.total_request_count}}</b></span>
                                    </td>
                                </tr>
                                <tr style="font-weight:bold ;">
                                    <td></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td
                                        *ngFor="let total of total_row; let n=index; trackBy: fromDataResolver.identify">
                                        {{total}}</td>
                                    <td>{{total_count}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="mg-b-20"></div>
                    <div class="text-align-right">
                        <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                    </div>
                </div> -->
                <div class="card-body" *ngIf="modal_mode=='APPROVE'">
                    <h5>Students applied for {{requestStudentMeta.course_code}} - {{requestStudentMeta.course_name}}
                        (Offered by Department of {{requestStudentMeta.offering_programme_name}})</h5>
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="text-center">
                                    <th style="width:10%">S.No</th>
                                    <th style="width:15%">Roll No</th>
                                    <th style="width:20%">Name</th>
                                    <th style="width: 15%;">Class</th>
                                    <th style="width: 10%;">Section</th>
                                    <th style="width:10%">
                                        <div class="form-group mg-t-5" style="margin-bottom:0px !important">
                                            <input type="checkbox" (change)="allClick($event)"> <span
                                                class="mg-l-10">All</span>
                                        </div>
                                    </th>
                                    <th style="width:20%">
                                        Change
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let student of requested_student_data; let l=index;trackBy: fromDataResolver.identify">
                                    <tr class="text-center">
                                        <td>{{l+1}}</td>
                                        <td>{{student.roll_no}}</td>
                                        <td>{{student.student.given_name}} {{student.student.middle_name}} </td>
                                        <td>{{commonEnums.getClassName(student.enrolled_year,
                                            student.student_programme_name)}}</td>
                                        <td>{{student.student_programme_section}}</td>
                                        <td class="text-center">

                                            <!-- <div class="col-xs-3 mg-l-10">
                                                    <button class="btn btn-success btn-sm mg-r-10"
                                                        (click)="approveStudent(student)"
                                                        *ngIf="!student.approved">Approve</button>
                                                    <button class="btn btn-danger btn-sm mg-r-10"
                                                        (click)="cancelStudent(student)" *ngIf="student.approved">Cancel
                                                        Approve</button>
                                                </div> -->
                                            <input type="checkbox" [(ngModel)]="student.is_approve_checked"
                                                (change)="studentApprovedChecked($event,student)">
                                            <!-- <button class="btn btn-danger btn-sm" (click)="cancelStudent(student)"
                                                *ngIf="student.approved">Cancel</button> -->
                                        </td>
                                        <td>
                                            <button class="btn btn-info btn-sm mg-r-10"
                                                (click)="openChangeStudent(student)"
                                                *ngIf="!student.is_approved && !student.change_student_opened">Change</button>
                                            <button class="btn btn-info btn-sm mg-r-10"
                                                (click)="closeChangeStudent(student)"
                                                *ngIf="!student.is_approved && student.change_student_opened">Close</button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="student.change_student_opened">
                                        <td colspan="7">
                                            <div id="form_row">
                                                <div class="col-md-12 centered-form">
                                                    <div class="form-group centered-form-control">
                                                        <label
                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                            Course Code to change to</label>
                                                        <select class="form-control" [(ngModel)]="student.change_to">
                                                            <option value="">Change To</option>
                                                            <option *ngFor="let course of change_to_course_codes"
                                                                [value]="course.course_code">
                                                                {{course.course_code}} - {{course.course_name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="text-center mg-b-10">
                                                        <button class="btn btn-primary btn mg-t-5"
                                                            (click)="changeStudentTo(student)">Change</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>


                                <ng-container *ngIf="!student_list_loading && requested_student_data.length == 0">
                                    <tr>
                                        <th colspan="7" class="text-center">No Records found</th>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="student_list_loading && requested_student_data.length == 0">
                                    <tr>
                                        <th colspan="7" class="text-center">
                                            Loading Please wait...
                                        </th>
                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>
                    </div>
                    <div class="mg-b-20"></div>
                    <div class="text-align-right">
                        <button class="btn ripple btn-success mg-r-10" (click)="approveSelectedStudents()"
                            type="button">Approve
                            Selected Students</button>
                        <button class="btn ripple btn-danger mg-r-10" (click)="rejectSelectedStudents()"
                            type="button">Reject
                            Selected Students</button>
                        <button class="btn ripple btn-secondary" (click)="backToListView()" type="button">Close</button>
                    </div>
                </div>


            </div>
        </div>
    </div>

</div>
<!-- /main-content -->
<app-footer></app-footer>