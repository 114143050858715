<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Timetable & Mark Attendance</p>
                    </div>

                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-sm" (click)="openReportModal()">Attendance
                                Report</button>
                        </div>

                        <div class="week mg-b-20">
                            <kendo-datepicker [weekNumber]="true" (valueChange)="dateChanged($event)"
                                calendarType="classic" [animateCalendarNavigation]="true" [value]="start_date"
                                displayFormat="dd/MM/yyyy">
                                <ng-template kendoCalendarMonthCellTemplate let-date>
                                    <span class="current_date" *ngIf="isCurrentDate(date)">{{ date.getDate() }}</span>
                                    <span [ngClass]="isDateInWeekRange(date)">{{ date.getDate() }}</span>
                                </ng-template>
                            </kendo-datepicker>
                            <label style="margin-left:20px">For week {{getDate(start_date)}} to
                                {{getDate(end_date)}}</label>
                        </div>




                        <div class="table-responsive">
                            <table class="table table-bordered" id="timetable">
                                <thead>
                                    <tr class="d-flex">
                                        <th class="col-1">Day</th>
                                        <th *ngFor="let head of hour_header | slice:0:max_hour" class="col-2">
                                            {{head.hour_name}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="timetable!.length>0">
                                        <ng-container
                                            *ngFor="let day of timetable;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr class="d-flex">
                                                <td class="col-1">
                                                    {{day.day_name}}<br>
                                                    <!-- {{day.date | date:commonEnums.date_format}} -->
                                                    {{getDisplayDate(i)}}
                                                </td>
                                                <ng-container
                                                    *ngFor="let head of hour_header | slice:0:max_hour; let j=index;trackBy:fromDataResolver.identify;">
                                                    <td class="col-2">

                                                        <!--Hour Object Exists-->
                                                        <ng-container *ngIf="hourExists(i,head.hour_number)">
                                                            <ng-container
                                                                *ngFor="let course of getCourses(i,head.hour_number); let k=index;trackBy:fromDataResolver.identify;">
                                                                <span class="text-center">
                                                                    {{getHourCourseTime(i,head.hour_number,k)}}
                                                                </span><br>
                                                                <ng-container
                                                                    *ngIf="canMarkAttendance(i,head.hour_number,k)">
                                                                    <span
                                                                        (click)="openAttendance(day,i,head.hour_number,k)"
                                                                        class="course_code"
                                                                        style="text-decoration: underline; cursor: pointer;">{{getHourCourseCode(i,head.hour_number,k)}}</span>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!canMarkAttendance(i,head.hour_number,k)">
                                                                    <span>{{getHourCourseCode(i,head.hour_number,k)}}</span>
                                                                </ng-container>
                                                                <br>
                                                                {{getHourCourseName(i,head.hour_number,k)}} <br>
                                                                <div class="row">
                                                                    <div class="col-md-8">
                                                                        {{getHourProgrammeName(i,head.hour_number,k)}}
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        {{getHourRoomNo(i,head.hour_number,k)}}
                                                                    </div>
                                                                </div>
                                                                <ng-container *ngIf="hourExists(i,head.hour_number)">
                                                                    <fa-icon [icon]="fonts.faPlus"
                                                                        style="color: brown; cursor: pointer;"
                                                                        class="mg-r-10" data-toggle="tooltip"
                                                                        title="Additional Adjustment"
                                                                        *ngIf="canMarkAttendance(i,head.hour_number,k)"
                                                                        (click)="openAdditionalAdjustment(day,i,head.hour_number)">
                                                                    </fa-icon>
                                                                    <fa-icon [icon]="fonts.faCheck"
                                                                        style="color: green; cursor: pointer;"
                                                                        class="mg-r-10" data-toggle="tooltip"
                                                                        title="Marked"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'MARKED',k)">
                                                                    </fa-icon>
                                                                    <fa-icon [icon]="fonts.faExclamationTriangle"
                                                                        style="color: orange; cursor: pointer;"
                                                                        class="mg-r-10" data-toggle="tooltip"
                                                                        title="Not Marked"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'NA',k)">
                                                                    </fa-icon>
                                                                    <!-- <fa-icon [icon]="fonts.faBan"
                                                                        style="color: brown; cursor: pointer;"
                                                                        class="mg-r-10"
                                                                        (click)="openReopenRequest(i,head.hour_number,'ADJUSTMENT')"
                                                                        data-toggle="tooltip"
                                                                        title="Make Adjustment Request"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'LATE_ADJUSTMENT')">
                                                                    </fa-icon> -->
                                                                    <fa-icon [icon]="fonts.faBan"
                                                                        style="color: red; cursor: pointer;"
                                                                        (click)="openReopenRequest(i,head.hour_number,'LATE_ENTRY',k)"
                                                                        data-toggle="tooltip" title="Make Request"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'READY',k)">
                                                                    </fa-icon>
                                                                    <fa-icon [icon]="fonts.faSpinner"
                                                                        style="color: red; font-weight: bolder; cursor: pointer;"
                                                                        data-toggle="tooltip"
                                                                        title="Reopen Request Made"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'SUBMITTED',k)">
                                                                    </fa-icon>
                                                                    <fa-icon [icon]="fonts.faCheck"
                                                                        style="color: red; cursor: pointer;"
                                                                        data-toggle="tooltip" title="Request Accepted"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'ACCEPTED',k)">
                                                                    </fa-icon>
                                                                    <fa-icon [icon]="fonts.faInfo"
                                                                        style="color: blue; cursor: pointer;"
                                                                        data-toggle="tooltip"
                                                                        [title]="getAdjustmentData(i,head.hour_number,k)"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'ADJUSTMENT_DONE',k)">
                                                                    </fa-icon>
                                                                    <!-- <fa-icon [icon]="fonts.faInfo"
                                                                        style="color: green; cursor: pointer;"
                                                                        data-toggle="tooltip"
                                                                        (click)="showAdjustmentMade(i,head.hour_number)"
                                                                        title="Adjustment Made"
                                                                        *ngIf="isAdjustmentMade(day,i,head.hour_number)">
                                                                    </fa-icon> -->
                                                                    <span
                                                                        style="float:right; cursor: pointer; text-decoration: underline;"
                                                                        (click)="showAbsenteesList(i,head.hour_number,k)"
                                                                        *ngIf="canDisplayIcon(i,head.hour_number,'ABS',k)"><b>Ab:
                                                                            {{getAbsentStudentsCount(i,head.hour_number,k)}}
                                                                        </b></span>
                                                                </ng-container>
                                                                <br>
                                                            </ng-container>

                                                            <!--To add additional adjustments for empty hour-->
                                                            <ng-container
                                                                *ngIf="getAdjustments(i,head.hour_number)?.length>=0">
                                                                <ng-container
                                                                    *ngIf="getAdjustments(i,head.hour_number).length>=0">
                                                                    <span style="cursor: pointer;"
                                                                        *ngIf="canMakeAdjustment(day,i,head.hour_number)"
                                                                        (click)="openAttendance(day,i,head.hour_number)">---</span>
                                                                </ng-container>

                                                                <span
                                                                    *ngIf="!canMakeAdjustment(day,i,head.hour_number)">---</span>
                                                                <br>
                                                            </ng-container>

                                                            <!-- *ngFor="let course of getAdjustments(i,head.hour_number); let k=index;trackBy:fromDataResolver.identify;" -->
                                                            <ng-container
                                                                *ngIf="getAdjustments(i,head.hour_number).length>0">
                                                                <fa-icon [icon]="fonts.faInfo"
                                                                    style="color: green; cursor: pointer;"
                                                                    data-toggle="tooltip"
                                                                    (click)="showAdjustmentMade(i,head.hour_number)"
                                                                    title="Adjustment Made"
                                                                    *ngIf="isAdjustmentMade(day,i,head.hour_number)">
                                                                </fa-icon>
                                                            </ng-container>
                                                        </ng-container>

                                                        <!--Hour does not exist-->
                                                        <ng-container *ngIf="!hourExists(i,head.hour_number)">
                                                            <span style="cursor: pointer;"
                                                                *ngIf="canMakeAdjustment(day,i,head.hour_number)"
                                                                (click)="openAttendance(day,i,head.hour_number)">---</span>
                                                            <span
                                                                *ngIf="!canMakeAdjustment(day,i,head.hour_number)">---</span>
                                                            <br>
                                                            <fa-icon [icon]="fonts.faBan"
                                                                style="color: red; cursor: pointer;"
                                                                (click)="openReopenRequest(i,head.hour_number,'ADJUSTMENT')"
                                                                data-toggle="tooltip" title="Make Adjustment Request"
                                                                *ngIf="canDisplayIcon(i,head.hour_number,'LATE_ADJUSTMENT')">
                                                            </fa-icon>
                                                        </ng-container>

                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!timetable_loading && timetable.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="timetable_loading && timetable.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <!--Legend Area-->
                        <div class="row mg-t-15">
                            <div class="col-md-12">
                                <span><b><u>Note:</u></b></span><br>
                                <span>Click on the course code to mark attendance for your allocated
                                    hours and for Adjustment hours click on "---" to choose staff and mark attendance
                                    for
                                    them</span><br>
                                <span><b><u>Legend:</u></b></span><br>
                                <fa-icon [icon]="fonts.faCheck" style="color: green;" data-toggle="tooltip"
                                    title="Marked">
                                </fa-icon>
                                <span class="mg-r-10"> - Attendance Marked</span>
                                <fa-icon [icon]="fonts.faExclamationTriangle" style="color: orange;"
                                    data-toggle="tooltip" title="Not Marked">
                                </fa-icon>
                                <span class="mg-r-10"> - Attendance Not Yet Marked</span>
                                <fa-icon [icon]="fonts.faBan" style="color: red;" data-toggle="tooltip"
                                    title="Make Request">
                                </fa-icon>
                                <span class="mg-r-10"> - Make Late Entry Request</span>
                                <fa-icon [icon]="fonts.faSpinner" style="color: red; font-weight: bolder;"
                                    data-toggle="tooltip" title="Reopen Request Made">
                                </fa-icon>
                                <span class="mg-r-10"> - Late Entry Request on Hold</span>
                                <fa-icon [icon]="fonts.faCheck" style="color: red;" data-toggle="tooltip"
                                    title="Request Accepted">
                                </fa-icon>
                                <span class="mg-r-10"> - Late Entry Request Accepted; Can Mark Attendance</span>
                                <fa-icon [icon]="fonts.faPlus" style="color: brown;" data-toggle="tooltip"
                                    title="Additional Adjustment">
                                </fa-icon>
                                <span class="mg-r-10"> - Add Additional Adjustment</span>
                                <fa-icon [icon]="fonts.faInfo" style="color: blue;" data-toggle="tooltip">
                                </fa-icon>
                                <span class="mg-r-10"> - Adjustment made for you</span>
                                <fa-icon [icon]="fonts.faInfo" style="color: green;" data-toggle="tooltip"
                                    title="Adjustment Made">
                                </fa-icon>
                                <span class="mg-r-10"> - View Adjustments you have made</span>

                                <!-- <fa-icon [icon]="fonts.faBan" style="color: brown;" data-toggle="tooltip"
                                    title="Make Request">
                                </fa-icon>
                                <span class="mg-r-10"> - Make Adjustment Late Entry Request</span>
                                <fa-icon [icon]="fonts.faSpinner" style="color: brown; font-weight: bolder;"
                                    data-toggle="tooltip" title="Reopen Request Made">
                                </fa-icon>
                                <span class="mg-r-10"> - Adjustment Late Entry Request on Hold</span>
                                <fa-icon [icon]="fonts.faCheck" style="color: brown;" data-toggle="tooltip"
                                    title="Request Accepted">
                                </fa-icon>
                                <span class="mg-r-10"> - Adjustment Late Entry Request Accepted; Can Mark
                                    Attendance</span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Large Modal -->
        <div class="modal" id="attendance-modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='attendance'">
                    <div class="modal-header">
                        <h6 class="modal-title">Mark Attendance</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-6">
                                    Course Code: <b> {{modalData.course_code}}</b>
                                </div>
                                <div class="col-md-6">
                                    Course Code: <b>{{modalData.course_name}}</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    Class Name: <b>{{getProgrammeName()}}</b>
                                </div>
                                <div class="col-md-6">
                                    Hour: <b>{{students_list.hour_number}} <span
                                            *ngIf="allocated_attendance_mode"><i>(Adjustment Hour)</i></span></b>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 10%;">S.No</th>
                                            <!-- <th class="text-center" style="width: 15%;">Photo</th> -->
                                            <th class="text-center" style="width: 15%;">Roll No.</th>
                                            <th class="text-center" style="width: 40%;">Name</th>
                                            <th class="text-center">
                                                <input type="checkbox" (change)="markPresent($event)"
                                                    [checked]="isChecked()"
                                                    title="Mark Present/Absent for all students">
                                                All Present
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let student of students_list.attendance_data; let i=index;trackBy:fromDataResolver.identify;">
                                            <td class="text-center">{{i+1}}</td>
                                            <!-- <td class="text-center"><img [src]="student.student_profile" width="100px"
                                                                                        height="50px"></td> -->
                                            <td class="text-center">{{student.roll_no}}</td>
                                            <td>{{student.given_name}} {{student.middle_name}}</td>
                                            <td class="text-center">
                                                <!-- <div class="is_present_checkbox">
                                                    <input type="checkbox" [(ngModel)]="student.is_present"
                                                        [attr.disabled]="(student.od_given==true)?true:null">
                                                    <p *ngIf="student!.od_given">(OD Given)</p>
                                                </div> -->
                                                <div class="is_present_checkbox">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="student.is_present"
                                                            [attr.disabled]="(student.od_given==true)?true:null">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <p *ngIf="student!.od_given">(OD Given)</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngIf="!student_data_loading && students_list?.attendance_data?.length ==0">
                                            <tr>
                                                <th colspan="4" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="student_data_loading && students_list?.attendance_data?.length ==0">
                                            <tr>
                                                <th colspan="4" class="text-center">Loading Students Data...</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="footer text-right mg-t-20">
                                <button class="btn btn-info mg-r-10" (click)="onVerifyClick()">Verify</button>
                                <button class="btn btn-secondary" (click)="backToStaffList()"
                                    *ngIf="allocated_attendance_mode">Back</button>
                                <button class="btn btn-secondary" (click)="onCloseClick()"
                                    *ngIf="!allocated_attendance_mode">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='staff_list'">
                    <div class="modal-header">
                        <h6 class="modal-title">Choose Staff</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="department_search_form">
                                <div class="row row-sm">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Department
                                                Type</label>
                                            <select formControlName="department_category_id" class="form-control"
                                                (change)="onFilterSelectChange('department_category_id',$event)">
                                                <option value="">Select Department Type</option>
                                                <option
                                                    *ngFor="let department_type of department_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="department_type.department_category_id">
                                                    {{department_type.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                                Type</label>
                                            <select formControlName="finance_type" class="form-control"
                                                (change)="onFilterSelectChange('finance_type',$event)">
                                                <option value="">Select Finance Type</option>
                                                <option
                                                    *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Staff's
                                                Department</label>
                                            <select formControlName="department_id" class="form-control">
                                                <option value="">All Department</option>
                                                <option
                                                    *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="department.department_id">{{department.department_name}}
                                                    {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!staff_list_loading" (click)="getStaffList()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="staff_list_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <p>Click on a course to mark attendance for that hour.</p>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 10%;">S.No</th>
                                            <th class="text-center" style="width: 15%;">Staff Id.</th>
                                            <th class="text-center" style="width: 25%;">Name</th>
                                            <th class="text-center" style="width: 50%;">Course Name (Code)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let staff of staffList; let i=index;trackBy:fromDataResolver.identify;">
                                            <td>{{i+1}}</td>
                                            <td>{{staff.staff_id}}</td>
                                            <td>{{staff.staff_name}}</td>
                                            <td>
                                                <p
                                                    *ngFor="let course of staff.course_data; let j = index; trackBy:fromDataResolver.identify;">
                                                    <span>
                                                        {{course.course_name}}
                                                        ({{course.course_code}})</span>
                                                    <button class="btn btn-sm btn-info mg-l-10"
                                                        (click)="openAdjustmentAttendance(staff,course)">Mark
                                                        Attendance</button>
                                                </p>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="!staff_list_loading && staffList?.length ==0">
                                            <tr>
                                                <th colspan="4" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="staff_list_loading && staffList?.length ==0">
                                            <tr>
                                                <th colspan="4" class="text-center">Loading Staff Data...</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeStaffClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='absent_list'">
                    <div class="modal-header">
                        <h6 class="modal-title">Absent List</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <span>Students Marked Absent for this hour </span><br>
                            <span>No of Absentees: <b>{{absent_display_list.length}}</b></span>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 10%;">S.No</th>
                                            <th class="text-center" style="width: 15%;">Roll No.</th>
                                            <th class="text-center">Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let student of absent_display_list; let i=index;trackBy:fromDataResolver.identify;">
                                            <td>{{i+1}}</td>
                                            <td>{{student.roll_no}}</td>
                                            <td>{{student.given_name}} {{student.middle_name}}</td>
                                        </tr>
                                        <ng-container
                                            *ngIf="!student_absent_list_loading && absent_display_list?.length ==0">
                                            <tr>
                                                <th colspan="3" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="student_absent_list_loading && absent_display_list?.length ==0">
                                            <tr>
                                                <th colspan="3" class="text-center">Loading Absentees Data...</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeAbsentList()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='adjustment_made'">
                    <div class="modal-header">
                        <h6 class="modal-title">View Adjustment you have made</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 10%;">S.No</th>
                                            <th class="text-center" style="width: 15%;">Class</th>
                                            <!-- <th class="text-center" style="width: 25%;">Name</th> -->
                                            <th class="text-center" style="width: 50%;">Course Name (Code)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let adjustment of adjustment_made_data; let i=index;trackBy:fromDataResolver.identify;">
                                            <td>{{i+1}}</td>
                                            <td>{{getProgrammeNameByList(adjustment.adjustment_data.enrolled_year,
                                                adjustment.adjustment_data.programme_data)}}</td>
                                            <td>{{adjustment.adjustment_data.course_code}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeStaffClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='late_entry_reason'">
                    <div class="modal-header">
                        <h6 class="modal-title">Make Attendance Reopen Request -
                            {{reopen_data.late_entry_type.split('_').join(' ')
                            | titlecase}} </h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Reason for {{reopen_data.late_entry_type.split('_').join(' ') | titlecase}}
                                            reopen
                                            request</label>
                                        <textarea [(ngModel)]="reopen_request_reason" name="reopen_request_reason"
                                            class="form-control" rows="10" cols="30">
                                        </textarea>
                                    </div>
                                </div>
                            </form>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-success mg-r-10" (click)="makeReopenRequest()"
                                        *ngIf="!reopen_request_loading">Request</button>
                                    <button class="btn btn-success mg-r-10" disabled
                                        *ngIf="reopen_request_loading">Requesting...</button>
                                    <button class="btn btn-secondary" (click)="closeReopenRequest()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="modal-content modal-content-demo"
                    *ngIf="students_list?.attendance_data?.length>0 && popup_mode=='verify'">
                    <div class="modal-header">
                        <h6 class="modal-title">Confirm!</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-6">
                                    Course Code: <b> {{modalData.course_code}}</b>
                                </div>
                                <div class="col-md-6">
                                    Course Code: <b>{{modalData.course_name}}</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    Class Name: <b></b>
                                </div>
                                <div class="col-md-6">
                                    Hour: <b>{{students_list.hour_number}}</b>
                                </div>
                            </div>
                            Following students are marked absent for the hour. Are you sure you want to save this
                            attendance?
                            <div class="table-responsive"
                                *ngIf="absent_list!=undefined && absent_list?.attendance_data?.length>0">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">S.No</th>
                                            <th class="text-center">Roll No.</th>
                                            <th class="text-center">Name</th>
                                            <th class="text-center">
                                                Remove
                                            </th>
                                        </tr>
                                    </thead>
                                    <tr
                                        *ngFor="let student of absent_list!.attendance_data; let i=index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{student.roll_no}}</td>
                                        <td>{{student.given_name}} {{student.middle_name}}</td>
                                        <td class="text-center">
                                            <!-- <div class="is_present_checkbox">
                                                <input type="checkbox" [(ngModel)]="student.is_present">
                                            </div> -->
                                            <fa-icon [icon]="fonts.faTimes" style="color: red; cursor: pointer;"
                                                (click)="removeStudentFromAbsentList(student)">
                                            </fa-icon>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div *ngIf="absent_list != undefined && absent_list!.attendance_data!.length==0">
                                Absentees: Nil
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeConfirmClick()">Back</button>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button class="btn btn-success" (click)="saveAttendance()"
                                        *ngIf="!student_save_loading">Save</button>
                                    <button class="btn btn-success" disabled
                                        *ngIf="student_save_loading">Saving...</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='report'">
                    <div class="modal-header">
                        <h6 class="modal-title">Attendance Report</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="report_search_form">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                                Date</label>
                                            <input type="date" class="form-control" formControlName="start_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                                                Date</label>
                                            <input type="date" class="form-control" formControlName="end_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <button class="btn btn-info mg-t-30" (click)="getMissedHours()"
                                            *ngIf="!report_data_loading">Get
                                            Report</button>
                                        <button class="btn btn-info mg-t-30" disabled
                                            *ngIf="report_data_loading">Loading...</button>
                                    </div>
                                </div>
                            </form>

                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">S.No</th>
                                            <th class="text-center">Date (Day)</th>
                                            <th class="text-center">Hour No.</th>
                                            <th class="text-center">Course Name (Code)</th>
                                            <th class="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let entry of attendance_report; let i=index;trackBy:fromDataResolver.identify;">
                                            <td>{{i+1}}</td>
                                            <td>{{entry.day_date | date:commonEnums.date_format}} ({{entry.day_order}})
                                            </td>
                                            <td>{{entry.hour_number}} ({{entry.start_time}} - {{entry.end_time}})</td>
                                            <td>{{entry.course_name}} ({{entry.course_code}})</td>
                                            <td>{{entry.is_marked?'Marked':'Not Marked'}} <br>
                                                <i>{{entry.is_adjusted?'(Adjusted)':''}}</i>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="!report_data_loading && attendance_report?.length ==0">
                                            <tr>
                                                <th colspan="6" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="report_data_loading && attendance_report?.length ==0">
                                            <tr>
                                                <th colspan="6" class="text-center">Loading Report...</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeMissedHoursModal()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
        <!--End Large Modal -->
    </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>





<!--Non-Allocated Attendance Mode-->
<!-- <div class="modal-content modal-content-demo" *ngIf="!allocated_attendance_mode">
                    <div class="modal-header">
                        <h6 class="modal-title">Mark Attendance</h6> 
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                        <div class="row">
                            <div class="col-md-12">
                                Search with Staff Name / ID <br>
                                <kendo-autocomplete
                                #staff
                                id="staff_name"
                                [data]="staffData"
                                [valueField]="'staff_id'"
                                [filterable]="true"
                                (filterChange)="handleStaffFilter($event)"                      
                                [placeholder]="'Enter Staff Name/ID'"
                                [suggest]="true"
                                [(ngModel)]="searchData.staff_data"
                                >
                                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                    <span [title]="dataItem.staff_department"
                                    >{{ dataItem.staff_name }} ({{dataItem.staff_id}})</span>
                                </ng-template>
                                </kendo-autocomplete> 
                                <button type="button" class="btn btn-info btn-md-6 mg-l-10" (click)="searchStudentByStaff()">Search</button> <br>
                                <span style="cursor:pointer" (click)="advancedSearchDisplay()">
                                <span *ngIf="!show_advanced_search"><fa-icon [icon]="fonts.faAngleDown" class="mg-r-10"></fa-icon>Show</span> 
                                <span *ngIf="show_advanced_search"><fa-icon [icon]="fonts.faAngleUp" class="mg-r-10"></fa-icon>Hide</span>
                                 Advanced Search</span>
                            </div>
                            <div class="col-md-12" *ngIf="show_advanced_search">
                                <div class="row">
                                    <div class="col-md-12">
                                        Search By Course Code / Name <br>
                                        <kendo-autocomplete
                                            #course
                                            id="course_name"
                                            [data]="courseData"
                                            [valueField]="'course_code'"
                                            [filterable]="true"
                                            (filterChange)="handleCourseFilter($event)"                      
                                            [placeholder]="'Enter Course Name/Code'"
                                            [suggest]="true"
                                            [(ngModel)]="searchData.course_data"
                                        >
                                            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                                <span [title]="dataItem.course_department"
                                                >{{ dataItem.course_name }} ({{dataItem.course_code}})</span>
                                            </ng-template>
                                        </kendo-autocomplete> 
                                        <button type="button" class="btn btn-info btn-md-6 mg-l-10" (click)="searchStudentByCourse()">Search</button> <br>
                                    </div>
                                    <div class="col-md-12">
                                        <span title="Enter any student Roll No. currently present in the class">Search By Student Roll No.</span><br>
                                        <div class="input-group">
                                                <input type="text"
                                                    class="form-control md-4"
                                                    placeholder="Enter Student Roll No."                                            
                                                    [(ngModel)]="searchData.student_data"
                                                    style="max-width: 200px;"
                                                >
                                            <button type="button" class="btn btn-info btn-md-6 mg-l-10" (click)="searchStudentByRollNo()">Search</button> <br>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mg-t-20" *ngIf="student_attendance!.students!.length>0">
                            
                                <div class="col-md-6">
                                  Course Code: <b> {{modalData.course_code}}</b>
                                </div>
                                <div class="col-md-6">
                                  Course Code: <b>{{modalData.course_name}}</b>
                                </div>
                            
                            
                              <div class="col-md-6">
                                Class Name: <b>{{modalData.class_name}}</b>
                              </div>
                              <div class="col-md-6">
                                Hour: <b>{{modalData.hour_number}}</b>
                              </div>
                          
                          <div class="table-responsive">
                              <table class="table table-bordered mg-b-0 text-md-nowrap">
                                  <thead>
                                      <tr>
                                          <th class="text-center">S.No</th>
                                          <th class="text-center">Roll No.</th>
                                          <th class="text-center">Name</th>
                                          <th class="text-center">
                                              <input type="checkbox" 
                                              (change)="markPresent($event)"
                                              title="Mark Present/Absent for all students"> 
                                              Is Present
                                          </th>
                                      </tr>
                                  </thead>
                                  <tr *ngFor="let student of student_attendance.students; let i=index;trackBy:fromDataResolver.identify;">
                                      <td>{{i+1}}</td>    
                                      <td>{{student.student_rno}}</td>
                                      <td>{{student.student_name}}</td>
                                      <td>
                                          <div class="is_present_checkbox">
                                              <input type="checkbox" [(ngModel)]="student.is_present"
                                              [attr.disabled] = "(student.is_od_given==true)?true:null"
                                              >
                                              <p *ngIf="student!.is_od_given">(OD Given)</p>
                                          </div>
                                      </td>
                                  </tr>
                              </table>
                          </div>
                        </div>
                        

                          
                        <div class="footer text-right mg-t-20">
                            <button class="btn btn-info mg-r-10" (click)="onVerifyClick()" *ngIf="student_attendance!.students!.length>0">Verify</button>
                            <button class="btn btn-secondary" (click)="onCloseClick()">Close</button>
                        </div>
                        </div>
                    </div>
                </div> -->