import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-elective-report',
  templateUrl: './view-elective-report.component.html',
  styleUrls: ['./view-elective-report.component.scss']
})
export class ViewElectiveReportComponent implements OnInit {

  title = 'Elective Polling Reports';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  obe_course_request_master_id: string = '';
  programme_type_id: string = '';

  filter_form: FormGroup;

  table_data: any[] = []
  table_data_loading: boolean = false;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;
  programme_section_list: any[] = [];
  sections: any = [];

  type: string = "course_wise_report";
  course_stages: { id: string, text: string }[] = [{ id: "REQUESTED", text: "Requested" }, { id: "APPROVED", text: "Approved" }]
  programme_stages: { id: string, text: string }[] = [{ id: "ALL", text: "All" }, { id: "REQUESTED", text: "Requested" }, { id: "APPROVED", text: "Approved" }, { id: "NOT_REQUESTED", text: "Not Requested" }]



  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private notificationService: NotificationService, private restService: AdminservicesService) {
  }

  ngOnInit(): void {
    this.buildSearchForm();
    this.route.queryParams.subscribe(params => {
      this.programme_type_id = params.programme_type_id;
      this.obe_course_request_master_id = params.obe_course_request_master_id;
    });
  }

  //Search Filter
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      course_code: [''],
      semester: [0],
      finance_type: [''],
      student_programme_id: [''],
      student_programme_section: [''],
      filter_stage: [''],
    });
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'finance_type') {
      this.filter_form.get('student_programme_id').setValue("");
      this.getProgrammeDataList(this.filter_form);
    }

    if (id == "student_programme_id") {
      this.sections = [];
      this.filter_form.get('student_programme_section').setValue('');
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.student_programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.filter_form.get('student_programme_section').setValue(this.sections[0].section_name);
        }
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        search_form_query.programme_type_id = this.programme_type_id
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }


  changeType(event: any) {
    this.filter_form.reset();
    Object.keys(this.filter_form.controls).forEach(control => { this.filter_form.get(control).setValue("") });
    this.programme_list = [];
    this.table_data = [];
  }
  async onSearchClick() {
    this.table_data = [];
    var search_query: any = {};
    search_query.obe_course_request_master_id = this.obe_course_request_master_id;
    if (!this.filter_form.value.filter_stage) {
      this.filter_form.get('filter_stage').setValue('APPROVED');
    }
    /* if (this.filter_form.value.filter_stage == 'APPROVED') {
      search_query.approved = true;
    } */
    search_query.filter_stage = this.filter_form.value.filter_stage;
    if (this.type == 'course_wise_report') {
      if (!this.filter_form.value.course_code) {
        alert("Please enter valid Course Code"); return;
      }
      search_query.course_code = this.filter_form.value.course_code;
    }

    if (this.filter_form.value.semester && this.filter_form.value.semester != 0) {
      search_query.semester = this.filter_form.value.semester
    }

    if (this.type == 'programme_wise_report') {

      if (!this.filter_form.value.finance_type) {
        alert("Please choose Finance Type"); return;
      }
      if (!this.filter_form.value.student_programme_id) {
        alert("Please choose Programme Name"); return;
      }
      search_query.student_programme_id = this.filter_form.value.student_programme_id;

      if (this.filter_form.value.student_programme_section) {
        search_query.student_programme_section = this.filter_form.value.student_programme_section;
      }
    }
    console.log(search_query);
    this.table_data_loading = true;
    try {
      const service_response = await this.restService.findRequestStudentList(search_query);
      if (service_response && service_response.success) {
        console.log(service_response);
        this.table_data = service_response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }


  downloadFile(file_type: string) {
    const data_list: any[] = [];
    let i = 1;
    if (this.type == 'programme_wise_report') {
      var class_name = (this.getProgrammeName(this.filter_form.value.student_programme_id) + ' - ' + this.filter_form.value.student_programme_section).split(".").join('');
      for (const data of this.table_data) {
        data_list.push({
          "S_No": i,
          "Roll_No": data.roll_no,
          "Name": data.student.given_name + " " + data.student.middle_name,
          "Course_Code": data.course_code,
          "Course_Name": data.course_name
        });
        i++;
      }
      if (file_type == 'CSV') {
        CSVHelper.downloadCSV(data_list, 'NME Courses studied by ' + class_name + ' ' + DateHelper.convertToControlDate(new Date()));
      }
      /* else {
        const arr = data_list.map(d => [d.S_No, d.Roll_No, d.Name, d.Course_Code, d.Course_Name,])
        const doc = new jsPDF()
        autoTable(doc, {
          head: [['S.No', 'Roll No', 'Name', 'Course Code', 'Course Name']],
          body: arr,
          theme: 'grid'
        })
        doc.save('NME Courses studied by ' + class_name + ' ' + DateHelper.convertToControlDate(new Date()) + '.pdf')
      } */
    }
    if (this.type == 'course_wise_report') {
      for (const data of this.table_data) {
        data_list.push({
          "S_No": i,
          "Roll_No": data.roll_no,
          "Name": data.student.given_name + " " + data.student.middle_name,
          "Class": data.student_programme_name,
          "Section": data.student_programme_section
        });
        i++;
      }
      if (file_type == 'CSV') {
        CSVHelper.downloadCSV(data_list, 'Students studying ' + this.filter_form.value.course_code + ' ' + DateHelper.convertToControlDate(new Date()));
      }
      /* else {
        const arr = data_list.map(d => [d.S_No, d.Roll_No, d.Name, d.Class, d.Section])
        const doc = new jsPDF()
        autoTable(doc, {
          head: [['S.No', 'Roll No', 'Name', 'Class', 'Section']],
          body: arr,
          theme: 'grid'
        })
        doc.save('Students studying ' + this.filter_form.value.course_code + ' ' + DateHelper.convertToControlDate(new Date()) + '.pdf')
      } */
    }
  }

  printPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>.table-cell {height:30px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:40px; font-family:\'serif\'">');

    mywindow.document.write(document.getElementById('print-area').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }
}
