<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Exam Hall</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <ng-container *ngIf="!closed">
                <div class="d-flex my-xl-auto right-content">
                    <div class="pr-1 mb-3 mb-xl-0">
                        <button type="button" class="btn btn-danger mr-2" (click)="onCloseExamPatchClick()">Close Exam</button>
                    </div>

                </div>
            </ng-container>

        </div>


        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Exam Reports : {{this.exam_name}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Page will referesh every 5 minutes</p>
                    </div>


                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th>Name</th>
                                        <th>AADHAR Card No.</th>
                                        <th>Mobile No.</th>
                                        <th>Start Time</th>
                                        <th>Last Active Time</th>
                                        <th>Mark</th>
                                        <th>Finished</th>
                                        <th style="width: 10%;">Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="entrance_exam_list.length>0">


                                        <ng-container *ngFor="let entrance_exam of entrance_exam_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="uppercase">{{entrance_exam.name}}</td>
                                                <td class="uppercase"> {{entrance_exam.aadhar_card_no}}</td>
                                                <td class="uppercase">
                                                    {{entrance_exam.mobile_number}}
                                                    <ng-container *ngIf="entrance_exam.mobile_number_2">
                                                        , {{entrance_exam.mobile_number_2}}
                                                    </ng-container>
                                                    <ng-container *ngIf="entrance_exam.mobile_number_3">
                                                        , {{entrance_exam.mobile_number_3}}
                                                    </ng-container>
                                                    <ng-container *ngIf="entrance_exam.mobile_number_4">
                                                        , {{entrance_exam.mobile_number_4}}
                                                    </ng-container>
                                                    <ng-container *ngIf="entrance_exam.mobile_number_5">
                                                        , {{entrance_exam.mobile_number_5}}
                                                    </ng-container>
                                                </td>
                                                <td class="uppercase">
                                                    {{entrance_exam.createdAt|date:commonEnums.time_format}}
                                                </td>
                                                <td class="uppercase">
                                                    {{entrance_exam.last_active |date:commonEnums.time_format}}
                                                </td>
                                                <td>
                                                    {{entrance_exam.correct_count|number}}
                                                </td>
                                                <td>
                                                    {{entrance_exam.submitted?'YES':'NO'}}
                                                </td>

                                                <td>

                                                    <button type="button" class="btn btn-sm btn-info mg-l-10" (click)="allowReExam(entrance_exam)">
                                                        <fa-icon [icon]="fonts.faRedo"></fa-icon>
                                                    </button>


                                                </td>



                                            </tr>

                                        </ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="!entrance_exam_list_loading && entrance_exam_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="entrance_exam_list_loading && entrance_exam_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>


</div>
<!-- /main-content -->
<app-footer></app-footer>