<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Programmes</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <ng-container *ngIf="cookieStore.isRoleExists(['principal'])">
          <div class="pr-1 mb-3 mb-xl-0">
            <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Programme</button>
          </div>
        </ng-container>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Programmes</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Programmes</p>
          </div>

          <div class="card-body" *ngIf="programme_search_form">


            <form [formGroup]="programme_search_form">
              <div class="row row-sm">

                <div class="col-lg-4">

                  <div class="form-group">

                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Finance Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">All Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                    <select formControlName="programme_type_id" class="form-control"
                      (change)="onFilterSelectChange('programme_type_id',$event)">
                      <option value="">All Programme Type</option>
                      <option
                        *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programme_type.programme_type_id">{{programme_type.name}}</option>
                    </select>
                  </div>
                </div>

              </div>
            </form>
          </div>

          <div class="card-body">


            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Fin. Type</th>
                    <th>Prg. Type</th>
                    <th>Prg. Cat.</th>
                    <th>Prg. Code</th>
                    <th>Prg. Name</th>
                    <th>Short Name</th>
                    <th>Last Updated At</th>
                    <th style="width: 15%;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="programme_list.length>0">


                    <ng-container
                      *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td class="text-uppercase">{{programe.finance_type_display}}</td>
                        <td class="text-uppercase">{{programe.programme_type_id}}</td>
                        <td class="text-uppercase">{{programe.programme_category}}</td>
                        <td class="text-uppercase">{{programe.programme_code}}</td>
                        <td class="text-uppercase">{{programe.programme_name}}</td>
                        <td class="text-uppercase">{{programe.short_name}}</td>
                        <td>{{programe.updatedAt | date}}</td>
                        <td>
                          <div class="pr-1 mb-3 mb-xl-0">
                            <ng-container *ngIf="cookieStore.isRoleExists(['principal'])">
                              <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEditClick(programe)">
                                <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                              </button>
                            </ng-container>
                            <button type="button" class="btn btn-sm btn-info mr-2"
                              (click)="onShowOrHideRevisionClick(programe)">
                              <ng-container *ngIf="programe.show_revision">
                                <fa-icon [icon]="fonts.faBookOpen"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="!programe.show_revision">
                                <fa-icon [icon]="fonts.faBook"></fa-icon>
                              </ng-container>
                            </button>
                          </div>
                        </td>

                      </tr>
                      <tr *ngIf="programe.show_revision">
                        <td colspan="9">
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Revision year</th>
                                    <th>Max. Seats</th>
                                    <th>Duration</th>
                                    <th>Sememster</th>
                                    <th style="width: 25%;">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngIf="programme_revision_list.length>0">


                                    <ng-container
                                      *ngFor="let programme_revision of programme_revision_list;let i=index;trackBy:fromDataResolver.identify;">
                                      <tr>
                                        <td scope="row">
                                          {{i+1}}
                                        </td>
                                        <td class="text-uppercase">{{programme_revision.revision_year}}</td>
                                        <td class="text-uppercase">{{programme_revision.max_seats}}</td>
                                        <td class="text-uppercase">{{programme_revision.duration}}
                                          {{programme_revision.duration_type}}</td>
                                        <td class="text-uppercase">{{programme_revision.no_of_semesters}}</td>

                                        <td>
                                          <div class="pr-1 mb-3 mb-xl-0">
                                            <ng-container *ngIf="cookieStore.isRoleExists(['principal'])">
                                              <button type="button" class="btn btn-sm btn-info mr-2"
                                                title="Edit Revision" (click)="onEditRevisionClick(programme_revision)">
                                                <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                              </button>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="!programe.is_shadow">
                                              <button type="button" class="btn btn-sm btn-info mr-2"
                                                title="Add Programme Scheme"
                                                (click)="onAddProgrammeSchemeClick(programe,programme_revision)">
                                                <fa-icon [icon]="fonts.faCalendarCheck"></fa-icon>
                                              </button>

                                              <button type="button" class="btn btn-sm btn-info mr-2"
                                                title="View PAM Report"
                                                (click)="onPAMReportClick(programe,programme_revision)">
                                                <fa-icon [icon]="fonts.faAddressBook"></fa-icon>
                                              </button>
                                            </ng-container>
                                            <ng-container
                                              *ngIf="cookieStore.isRoleExists(['hod','staff_admin','principal'])">
                                              <button type="button" class="btn btn-sm btn-info mr-2"
                                                title="Assign Staffs"
                                                (click)="onAssignStaffClick(programe,programme_revision)">
                                                <fa-icon [icon]="fonts.faUser"></fa-icon>
                                              </button>
                                            </ng-container> -->

                                          </div>
                                        </td>

                                      </tr>

                                    </ng-container>

                                  </ng-container>

                                  <ng-container
                                    *ngIf="!programe.programme_revision_list_loading && programme_revision_list.length==0">
                                    <tr>
                                      <th colspan="8" class="text-center">No Records found</th>
                                    </tr>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="programe.programme_revision_list_loading && programme_revision_list.length==0">
                                    <tr>
                                      <th colspan="8" class="text-center">Loading Please wait</th>
                                    </tr>
                                  </ng-container>

                                  <ng-container *ngIf="!programe.programme_revision_list_loading">
                                    <ng-container *ngIf="cookieStore.isRoleExists(['principal'])">
                                      <tr>
                                        <th colspan="8" class="text-center">
                                          <button class="btn btn-sm btn-primary" (click)="onAddRevisionClick()">Add New
                                            Revision</button>
                                        </th>
                                      </tr>
                                    </ng-container>
                                  </ng-container>

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!programme_list_loading && programme_list.length==0">
                    <tr>
                      <th colspan="9" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="programme_list_loading && programme_list.length==0">
                    <tr>
                      <th colspan="9" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>


    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="programme_form">
          <div class="modal-header">
            <h6 class="modal-title" *ngIf="!programe._id">Add New programe</h6>
            <h6 class="modal-title" *ngIf="programe._id">Update programe</h6>

          </div>
          <div class="modal-body">
            <div class="card card-body">
              <form [formGroup]="programme_form">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Finance
                        Type</label>
                      <select formControlName="finance_type" class="form-control">
                        <option value="">Choose Programme Finance Type</option>
                        <option
                          *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="finance_type.id">{{finance_type.text}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                      <select formControlName="programme_type_id" class="form-control" (change)="fillParts()">
                        <option value="">Choose Programme Type</option>
                        <option
                          *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="programme_type.programme_type_id">{{programme_type.name}}</option>
                      </select>

                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Category</label>
                      <select formControlName="programme_category" class="form-control">
                        <option value="">Choose Programme Category</option>
                        <option
                          *ngFor="let category of commonEnums.programme_categories;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="category.id">{{category.text}}</option>
                      </select>

                    </div>
                  </div>

                </div>


                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Programe Code</label>
                      <input class="form-control" required="" type="text" formControlName="programme_code">
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Programe Name</label>
                      <input class="form-control" required="" type="text" formControlName="programme_name">
                    </div>
                  </div>
                </div>


                <!--New Department choose control-->
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose Department Name</label>
                  <select formControlName="department_id" class="form-control">
                    <option value="">Choose Department</option>
                    <option *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                      [value]="department.department_id">{{department.department_name +
                      '('+department.finance_type_display+')'}} - {{department?.department_category_id}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Short Name</label>
                  <input class="form-control" required="" type="text" formControlName="short_name">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description</label>
                  <textarea class="form-control" formControlName="description"></textarea>
                </div>

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Started Date</label>
                  <input class="form-control" type="date" formControlName="started_date">
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group justify-content-end">
                      <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                          <input type="checkbox" class="custom-control-input" id="discontinued"
                            formControlName="discontinued">
                          <label for="discontinued" class="custom-control-label mt-1">Is Discontined</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group justify-content-end">
                      <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                          <input type="checkbox" class="custom-control-input" id="is_virtual"
                            formControlName="is_virtual">
                          <label for="is_virtual" class="custom-control-label mt-1">Is Virtual</label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col">
                    <div class="form-group justify-content-end">
                      <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                          <input type="checkbox" class="custom-control-input" id="is_shadow"
                            formControlName="is_shadow">
                          <label for="is_shadow" class="custom-control-label mt-1">Is Shadow</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group" *ngIf="programme_form.value.is_shadow">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Shadow From Programme</label>
                      <select formControlName="shadow_programme_id" class="form-control">
                        <option value="">Select Programme</option>
                        <option
                          *ngFor="let programme of programme_list_for_shadow_list;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="programme.programme_id">{{programme.programme_name +
                          '('+programme.finance_type_display+')'}}</option>
                      </select>

                    </div>
                  </div>
                </div>


                <div class="form-group justify-content-end">
                  <div class="checkbox">
                    <div class="custom-checkbox custom-control">
                      <input type="checkbox" class="custom-control-input" id="is_holding_common_paper"
                        formControlName="is_holding_common_paper">
                      <label for="is_holding_common_paper" class="custom-control-label mt-1">Is Holding Common
                        Papers</label>
                    </div>
                  </div>
                </div>
                <div class="form-group" *ngIf="programme_form.value.is_holding_common_paper">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Part Type</label>
                  <select formControlName="part_type" class="form-control">
                    <option value="">Choose Part Type</option>
                    <option *ngFor="let part of parts;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="part.id">{{part.text}}</option>
                  </select>

                </div>

                <div class="form-group"
                  *ngIf="fromDataResolver.getValueFromFromGroup(this.programme_form,'discontinued') == true">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Discontined Date</label>
                  <input class="form-control" type="date" formControlName="discontinued_date">
                </div>

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Sections</label>
                  <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Section Name</th>
                          <th>Roll No Prefix</th>
                          <th>Starting Number</th>
                          <th>Max Digits</th>
                          <th style="width: 25%;">Action</th>
                        </tr>
                      </thead>
                      <tbody>

                        <ng-container
                          *ngFor="let section_detail of fromDataResolver.getFormArrayControlls(programme_form,'section_details');let i=index;trackBy:fromDataResolver.identify;">
                          <ng-container [formGroup]="section_detail">
                            <tr>
                              <th>
                                {{i+1}}
                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" required="" type="text" formControlName="section_name">
                                </div>
                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" required="" type="text" formControlName="roll_no_prefix">
                                </div>
                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" required="" type="number" formControlName="starting_no">
                                </div>
                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" required="" type="number"
                                    formControlName="max_digits_count">
                                </div>
                              </th>
                              <th>
                                <button type="button" class="btn btn-sm btn-danger mr-2" title="Delete Section"
                                  (click)="deleteSection(section_detail.value._id,i)">
                                  <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                </button>
                              </th>

                            </tr>
                          </ng-container>
                        </ng-container>
                        <tr>
                          <th colspan="6" class="text-center">
                            <button class="btn btn-sm btn-primary" (click)="addNewSection()">Add New Section</button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                    *ngIf="!programme_save_loading">Save</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="programme_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                  <ng-container *ngIf="programe._id">
                    <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()"
                      *ngIf="!programme_delete_loading">Delete</button>
                    <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="programme_delete_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </ng-container>

                  <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-details">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="programme_revision_form">
          <div class="modal-header">
            <h6 class="modal-title" *ngIf="!programme_revision._id">Add New revision</h6>
            <h6 class="modal-title" *ngIf="programme_revision._id">Update revision</h6>

          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="programme_revision_form">


                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Revision Year</label>
                  <input class="form-control" required="" type="number" formControlName="revision_year">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Duration in Years</label>
                  <input class="form-control" required="" type="text" formControlName="duration">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter No. of Semesters</label>
                  <input class="form-control" required="" type="number" formControlName="no_of_semesters">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Max. Seats</label>
                  <input class="form-control" required="" type="number" formControlName="max_seats">
                </div>

                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onSaveRevisionClick()"
                    *ngIf="!programme_revision_save_loading">Save</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled
                    *ngIf="programme_revision_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                  <ng-container *ngIf="programme_revision._id">
                    <button class="btn btn-danger mg-r-20" (click)="onDeleteRevisionClick(programme_revision._id)"
                      *ngIf="!programme_revision_delete_loading">Delete</button>
                    <button class="btn btn-danger mg-r-20" type="button" disabled
                      *ngIf="programme_revision_delete_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </ng-container>

                  <button class="btn ripple btn-secondary" (click)="onCloseRevisionClick()" type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-scheme">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="programme_scheme_filter_form">
          <div class="modal-header">

            <h6 class="modal-title">Update course scheme</h6>

          </div>
          <div class="modal-body">
            <div class="">
              <form [formGroup]="programme_scheme_filter_form">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Semester</label>
                  <select formControlName="semester" class="form-control" (change)="onSemesterChange($event)">
                    <option value="">Select Semester</option>
                    <option
                      *ngFor="let semester of commonEnums.getSemesterList(this.programme_revision.no_of_semesters);let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="semester.id">{{semester.text}}</option>
                  </select>
                </div>
              </form>
              <ng-container
                *ngIf="programme_scheme_form && programme_scheme_form.length>0 && this.programme_scheme_filter_form.value.semester">
                <form [formGroup]="programme_scheme_form[this.programme_scheme_filter_form.value.semester]">
                  <div class="mg-b-20" style="font-size: medium;font-weight: bold;">
                    Configure Programme Scheme : Semester
                    {{this.commonEnums.rommon_letters[this.fromDataResolver.getValueFromFromGroup(programme_scheme_form[this.programme_scheme_filter_form.value.semester],"semester")]}}
                  </div>
                  <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                      <thead>
                        <tr>
                          <th rowspan="2" class="verical-middle text-center" style="width: 3%;">S.No</th>

                          <th rowspan="2" class="verical-middle text-center" style="width: 10%;">Part Type</th>


                          <th rowspan="2" class="verical-middle text-center" style="width: 10%;">Course Type</th>


                          <th rowspan="2" class="verical-middle text-center" style="width: 15%;">Course Code</th>
                          <th rowspan="2" class="verical-middle text-center">Course</th>
                          <th colspan="3" class="text-center">Weekly Contact Hours</th>

                          <th rowspan="2" class="verical-middle text-center" style="width: 5%;">No. Of Credits</th>
                          <th rowspan="2" class="verical-middle text-center" style="width: 5%;">Exam. Hours</th>
                          <th colspan="3" class="text-center">Marks</th>
                          <th rowspan="2" class="verical-middle text-center" style="width: 4%;">Action</th>
                        </tr>
                        <tr>


                          <th class="text-center" style="width: 5%;">L</th>
                          <th class="text-center" style="width: 5%;">T</th>
                          <th class="text-center" style="width: 5%;">P</th>

                          <th class="text-center" style="width: 6%;">Int.</th>
                          <th class="text-center" style="width: 6%;">Ter.</th>
                          <th class="text-center" style="width: 6%;">Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        <ng-container
                          *ngFor="let sem of fromDataResolver.getFormArrayControlls(programme_scheme_form[this.programme_scheme_filter_form.value.semester],'semesters');let i=index;trackBy:fromDataResolver.identify;">
                          <ng-container [formGroup]="sem">
                            <tr>
                              <th class="verical-middle">
                                <div class="form-group">
                                  <label>{{i+1}}</label>
                                </div>

                              </th>

                              <th>

                                <div class="form-group">
                                  <select formControlName="part" class="form-control"
                                    (change)="onPartFilterSelectChange(sem,$event)"
                                    [attr.disabled]="isDisabled(sem.value) ? '' : null">
                                    <option value="">Select Part</option>
                                    <option *ngFor="let part of parts;let i=index;trackBy:fromDataResolver.identify;"
                                      [ngValue]="part.id">{{part.text}}</option>
                                  </select>
                                </div>


                              </th>



                              <th>
                                <div class="form-group"
                                  *ngIf="sem.value.course_types &&  sem.value.course_types.length>0">
                                  <select formControlName="course_type" class="form-control"
                                    [attr.disabled]="isDisabled(sem.value) ? '' : null">
                                    <option
                                      *ngFor="let course_type of sem.value.course_types;let i=index;trackBy:fromDataResolver.identify;"
                                      [ngValue]="course_type.id">{{course_type.text}}</option>
                                  </select>
                                </div>

                              </th>


                              <th>
                                <ng-container
                                  *ngFor="let course of fromDataResolver.getFormArrayControlls(sem,'courses');let i=index;trackBy:fromDataResolver.identify;">
                                  <ng-container [formGroup]="course">
                                    <div class="form-group">
                                      <div class="input-group mb-3">
                                        <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                          required="" type="text" (blur)="valueChanged($event)"
                                          formControlName="course_code">
                                        <ng-container *ngIf=" !isDisabled(sem.value)">
                                          <div class="input-group-append" *ngIf="isMultiCourseAllowed(sem.value.part)">
                                            <button type="button" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                              *ngIf="fromDataResolver.getFormArrayControlls(sem,'courses').length == (i+1)"
                                              class="btn btn-sm btn-info mr-2" title="Add New Course"
                                              (click)="onAddNewCourseRow(sem)">
                                              <fa-icon [icon]="fonts.faPlus"></fa-icon>
                                            </button>
                                            <button type="button" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                              *ngIf="fromDataResolver.getFormArrayControlls(sem,'courses').length != (i+1)"
                                              class="btn btn-sm btn-danger mr-2" title="Remove Course"
                                              (click)="onDeleteCourseRow(sem,i)">
                                              <fa-icon [icon]="fonts.faMinus"></fa-icon>
                                            </button>

                                          </div>
                                        </ng-container>
                                      </div>

                                    </div>
                                    <div class="form-group"
                                      *ngIf="sem.value.part=='PART_III' && sem.value.course_type=='ALLIED'">

                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Syllabus Handled
                                        By</label>
                                      <select formControlName="syllabus_managed_by" class="form-control"
                                        [attr.disabled]="isDisabled(sem.value) ? '' : null">
                                        <option value="" disabled="disabled">Select Handled By</option>
                                        <option
                                          *ngFor="let programme_obj of programme_list_for_handled_by;let i=index;trackBy:fromDataResolver.identify;"
                                          [ngValue]="programme_obj.programme_id">{{programme_obj.programme_name +
                                          '('+programme_obj.finance_type_display+')'}}</option>
                                      </select>
                                    </div>

                                  </ng-container>
                                </ng-container>

                              </th>
                              <th>
                                <ng-container
                                  *ngFor="let course of fromDataResolver.getFormArrayControlls(sem,'courses');let i=index;trackBy:fromDataResolver.identify;">
                                  <ng-container [formGroup]="course">
                                    <div class="form-group">
                                      <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                        required="" type="text" (blur)="valueChanged($event)"
                                        formControlName="course_name">
                                    </div>
                                  </ng-container>
                                </ng-container>

                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" (blur)="valueChanged($event)"
                                    formControlName="weekly_lecture_hours">
                                </div>

                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" (blur)="valueChanged($event)"
                                    formControlName="weekly_tutorial_hours">
                                </div>

                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" (blur)="valueChanged($event)"
                                    formControlName="weekly_practical_hours">
                                </div>

                              </th>

                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" (blur)="valueChanged($event)" formControlName="credits">
                                </div>

                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" (blur)="valueChanged($event)"
                                    formControlName="exam_duration">
                                </div>

                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" max="50" (blur)="valueChanged($event)"
                                    formControlName="max_internal_mark">
                                </div>

                              </th>
                              <th>
                                <div class="form-group">
                                  <input class="form-control" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                    required="" type="number" max="50" (blur)="valueChanged($event)"
                                    formControlName="max_external_mark">
                                </div>

                              </th>
                              <th class="verical-middle">
                                <div class="form-group">
                                  <label>{{getTotal(sem.value.max_internal_mark, sem.value.max_external_mark)}}</label>
                                </div>

                              </th>
                              <th>
                                <div class="form-group">
                                  <div class="pr-1 mb-3 mb-xl-0">
                                    <button type="button" [attr.disabled]="isDisabled(sem.value) ? '' : null"
                                      class="btn btn-sm btn-danger mr-2" title="Edit Revision"
                                      (click)="onDeleteProgrameClick(sem.value._id,i)">
                                      <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                    </button>
                                  </div>
                                </div>

                              </th>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!programme_scheme_approved">
                          <ng-container *ngIf="!programe.programme_revision_list_loading">
                            <tr>
                              <th colspan="14" class="text-center">
                                <button class="btn btn-sm btn-primary" (click)="onAddNewProgrammeRow()">Add New
                                  Course</button>
                              </th>
                            </tr>

                          </ng-container>
                        </ng-container>
                      </tbody>



                    </table>
                  </div>

                  <div class="mg-b-20"></div>
                  <div class="text-align-right">
                    <ng-container *ngIf="!programme_scheme_approved">
                      <button class="btn btn-primary mg-r-20" (click)="onSaveProgrammeSchemClick()"
                        *ngIf="!programme_scheme_save_loading">Save</button>
                      <button class="btn btn-primary mg-r-20" type="button" disabled
                        *ngIf="programme_scheme_save_loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>
                    </ng-container>
                    <ng-container
                      *ngIf="saved_programme_scheme && cookieStore.isRoleExists(['hod','staff_admin','principal'])">
                      <ng-container *ngIf="!programme_scheme_approved">
                        <button class="btn btn-success mg-r-20" (click)="onApprovalClick()"
                          *ngIf="!programme_scheme_approval_loading">Approve</button>
                        <button class="btn btn-success mg-r-20" type="button" disabled
                          *ngIf="programme_scheme_approval_loading">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </button>
                      </ng-container>
                      <ng-container *ngIf="programme_scheme_approved">
                        <button class="btn btn-success mg-r-20" (click)="onUpApprovalClick()"
                          *ngIf="!programme_scheme_approval_loading">Un-Approve</button>
                        <button class="btn btn-success mg-r-20" type="button" disabled
                          *ngIf="programme_scheme_approval_loading">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </button>
                      </ng-container>
                    </ng-container>
                    <button class="btn ripple btn-secondary" (click)="onCloseSchemeClick()" type="button">Close</button>
                  </div>
                </form>
              </ng-container>
              <ng-container *ngIf="!this.programme_scheme_filter_form.value.semester">
                <div class="mg-b-20"></div>
                <div class="text-align-right">

                  <button class="btn ripple btn-secondary" (click)="onCloseSchemeClick()" type="button">Close</button>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-pamreport">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="pam_data">
          <div class="modal-header">

            <h6 class="modal-title">Programme Articulation Matrix- Total weightage</h6>

          </div>
          <div class="modal-body">
            <div class="">
              <div class="table-responsive">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 3%;">S.No</th>
                      <th style="width: 10%;">Part Type</th>
                      <th style="width: 10%;">Course Type</th>
                      <th style="width: 15%;">Course Code</th>
                      <th>Course</th>
                      <ng-container
                        *ngFor="let sem of this.pam_data.po_map_total;let i=index;trackBy:fromDataResolver.identify;">
                        <th>PO {{commonEnums.rommon_letters[sem.po_id]}}</th>
                      </ng-container>
                    </tr>

                  </thead>

                  <tbody>
                    <ng-container
                      *ngFor="let sem of this.pam_data.courses;let i=index;trackBy:fromDataResolver.identify;">

                      <tr>
                        <td>
                          {{i+1}}

                        </td>
                        <td>
                          {{sem.part}}
                        </td>
                        <td>
                          {{sem.course_type}}
                        </td>
                        <td>
                          {{sem.course_code}}
                        </td>
                        <td>
                          {{sem.course_name}}
                        </td>
                        <ng-container *ngFor="let po_map of sem.po_map;let i=index;trackBy:fromDataResolver.identify;">

                          <th>{{po_map.total}}</th>

                        </ng-container>

                      </tr>
                    </ng-container>

                    <tr>
                      <th colspan="5" style="text-align: right;">
                        Total
                      </th>
                      <ng-container
                        *ngFor="let sem of this.pam_data.po_map_total;let i=index;trackBy:fromDataResolver.identify;">
                        <th>{{sem.total}}</th>
                      </ng-container>
                    </tr>


                  </tbody>



                </table>
              </div>
              <ng-container>
                <div class="mg-b-20"></div>
                <div class="text-align-right">

                  <button class="btn ripple btn-secondary" (click)="onClosePAMReportClick()"
                    type="button">Close</button>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->



  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>