import { MenuItems } from './menu-item';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-manage-mess',
  templateUrl: './manage-mess.component.html',
  styleUrls: ['./manage-mess.component.scss']
})
export class ManageMessComponent implements OnInit {
  fonts = fonts;
  commonEnums = CommonEnums;
  menuItems: MenuItems[] = [] as MenuItems[];
  constructor(private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.setNavigation([{label: 'Menu Items', url: 'admin/hostel/manage-mess' }]);
    this.getMenuItems();
  }

  async getMenuItems(): Promise<void> {
    try {
      this.menuItems = await this.restService.menuItemService.getMenuItems();
    } catch(error) {
      this.notificationService.setNotifcation({ message: 'Failed to load menu items.', type: 'Failed' });
    }
  }

  getItems(): MenuItems[] {
    return this.menuItems;
  }

  onEdit(menuItem: MenuItems): void {
    this.router.navigate([`admin/hostel/menu-item/${menuItem?.id}`]);
  }

  async onDelete(menuItem: MenuItems): Promise<void> {
   try {
    const res = await this.restService.menuItemService.deleteMenuItems(menuItem?.id);
    if (res) {
      this.notificationService.setNotifcation({ message: 'Delete menu item successfully', type: 'Success' });
      this.menuItems = this.menuItems.filter(item => item.id !== menuItem.id);
    } else {
      this.notificationService.setNotifcation({ message: 'Failed to delete menu item.', type: 'Failed' }); 
    }
   } catch(error) {
     this.notificationService.setNotifcation({ message: 'Failed to delete menu item.', type: 'Failed' });
   }
  }

  onAdd(): void {
    this.router.navigate([`admin/hostel/menu-item`]);
  }

}
