import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }

  ngOnInit(): void {
  }

  
  url : string = "";
  
  getURL(){
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/notification/settings-645e25c2e4995a7c25a4e889?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
