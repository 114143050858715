import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-sms-campaign',
  templateUrl: './sms-campaign.component.html',
  styleUrls: ['./sms-campaign.component.scss']
})
export class SmsCampaignComponent implements OnInit {

  constructor(private domsanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  url: string = "";

  getURL() {
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/sms-app/smscampaign-64592e64d192432eef6496d1?embed=true&access_token=" + access_token
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
