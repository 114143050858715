<app-side-bar></app-side-bar>

    <div class="main-content app-content">

      <app-combo-bar></app-combo-bar>
      <app-notification></app-notification>
  
      <!-- container -->
      <div class="container-fluid">
  
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
              <div class="left-content">
                  <div>
                      <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                      <p class="mg-b-0"></p>
                  </div>
              </div>
              <div class="d-flex my-xl-auto right-content">
                  <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-info" (click)="onAddClick()">Update Stock</button>
                  </div>
              </div>
          </div>
  
  
  
          <div class="row">
  
  
              <div class="col-xl-12">
                  <div class="card mg-b-20">
                      <div class="card-header pb-0">
                          <div class="d-flex justify-content-between">
                              <h4 class="card-title mg-b-0">{{title}}</h4>
  
                          </div>
                          <p class="tx-12 tx-gray-500 mb-2">Create, Update Stock Purchases</p>
                      </div>
  
                      <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Last Usage Date</th>
                                        <th>Item Id</th>
                                        <th>Item Name</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="usedStock.length>0">
                    
                    
                                        <ng-container *ngFor="let item of usedStock;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                    
                                                </td>
                                                <td>{{item.usage_date | date}}</td>
                                                <td>{{item.item_id}}</td>
                                                <td>{{item.item_name}}</td>
                                                <td>{{item.quantity}}</td>
                                                <td>{{item.usage_price}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                                        (click)="onEditClick(item)">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                    
                                                        </button>
                                                    </div>
                                                </td>
                    
                                            </tr>
                                        </ng-container>
                    
                                    </ng-container>
                    
                                    <ng-container *ngIf="!stock_list_loading && usedStock.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="stock_list_loading && usedStock.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                    
                                </tbody>
                            </table>
                        </div>
                    </div> 
                  </div>
              </div>
  
  
          </div>
  
  
          <!-- Large Modal -->
          <div class="modal" id="modal-popup">
              <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content modal-content-demo" *ngIf="usageForm">
                      <div class="modal-header">
                          <h6 class="modal-title">Update Stock Item</h6>
                          <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->
  
                      </div>
                      <div class="modal-body">
                          <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="usageForm" class="form-horizontal">
                              <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Usage Date</label>
                                  <input class="form-control" required="" type="date" formControlName="usage_date">
                                  <div *ngIf="isInvalid('usage_date')">
                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                      <li class="parsley-required">Usage date is required.</li>
                                    </ul>
                                  </div>
                                </div>
                              
                              
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Product Name</label>
                                <!-- <input class="form-control" required="" type="text" formControlName="item_name"> -->
                                <kendo-autocomplete
                                id="itemMaster"
                                [data]="itemMaster"
                                [valueField]="'text'"
                                [suggest]="true"
                                [placeholder]="'Enter Item Name'"
                                formControlName="item_name"
                                (change)="getAvailability()"
                              >
                              </kendo-autocomplete>
                                <div *ngIf="isInvalid('item_name')">
                                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Item Name is required.</li>
                                  </ul>
                                </div>
                              </div>
                              <!-- <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Measurement Type</label>
                                <select formControlName="measurement_type" class="form-control"
                                  (change)="onFilterSelectChange('measurement_type',$event)">
                                  <option value="">Choose one</option>
                                  <option *ngFor="let type of measurementType;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="type">
                                    {{ type }}</option>
                                </select>
                                <div *ngIf="isInvalid('measurement_type')">
                                  <ul class="parsley-errors-list filled">
                                    <li class="parsley-required">Measurement type is required.</li>
                                  </ul>
                                </div>
                              </div> -->

                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Available Quantity</label>
                                <input class="form-control" readonly required="" type="text" formControlName="available_quantity">
                              </div>

                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Usage Quantity</label>
                                <input class="form-control"
                                (change)="getUsageTotal()"
                                required="" type="text" formControlName="quantity">
                                <div *ngIf="isInvalid('quantity')">
                                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Quantity is not valid. Leave default value zero, if it is not
                                      applicable.</li>
                                  </ul>
                                </div>
                              </div>
                              <!-- <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Price</label>
                                <input class="form-control" required=""
                                (change)="calculateTotal()"
                                type="text" formControlName="price">
                                <div *ngIf="isInvalid('price')">
                                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Price is not valid. Leave default value zero, if it is not applicable.
                                    </li>
                                  </ul>
                                </div>
                              </div> -->
                          
                              <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Usage Price</label>
                                  <input class="form-control" readonly required="" type="text" formControlName="usage_price">
                                </div>
                          
                          
                              <div class="add-btn-row">
                                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Add</button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()" [disabled]="canDisable()">Add & Close</button>
                                <button class="btn btn-danger mg-r-20" (click)="onDeleteAndClose()" *ngIf="id">Delete</button>
                                <button class="btn btn-light mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                              </div>
                            </form>

                          </div>
                      </div>
  
                  </div>
              </div>
          </div>
          <!--End Large Modal -->
  
      </div>
  </div>


  
  <!-- /main-content -->
  <app-footer></app-footer>

