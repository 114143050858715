<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Create Staff</button>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create and Manage Users (Teaching, Non-Teaching, Guest)</p>
                    </div>

                    <div class="card-body" *ngIf="user_search_form">
                        <form [formGroup]="user_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select User
                                            Type</label>
                                        <select formControlName="user_type" class="form-control">
                                            <option value="">Select User Type</option>
                                            <option
                                                *ngFor="let user of user_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="user">{{user}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll No.
                                            Role</label>
                                        <input type="text" class="form-control"
                                            placeholder="Enter Roll No. to search with" formControlName="roll_no">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mr-2 search-button" title="Search"
                                            *ngIf="!staff_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="staff_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!--Individual List-->
                    <div class="card-body">
                        <div class="mg-b-20"></div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width:10%">Roll No.</th>
                                        <th style="width: 15%;">Name</th>
                                        <th style="width: 20%;">Personal Email</th>
                                        <th style="width: 10%;">Mobile No.</th>
                                        <th style="width: 20%;">Email</th>
                                        <th style="width: 10%;">Type</th>
                                        <th style="width: 15%;">Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let staff of staff_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td class="uppercase">
                                            {{staff.roll_no}}
                                        </td>
                                        <td class="uppercase">
                                            {{staff.given_name}} {{staff.family_name}}
                                        </td>
                                        <td>
                                            {{staff.personal_email}}
                                        </td>
                                        <td>
                                            {{staff.mobile_number}}
                                        </td>
                                        <td>
                                            {{staff.email}}
                                        </td>
                                        <td>
                                            {{staff.user_type}}
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-info mg-r-10 mg-t-5"
                                                (click)="editClick(staff)">Edit</button>
                                        </td>
                                    </tr>

                                    <ng-container *ngIf="!staff_list_loading && staff_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="staff_list_loading && staff_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!-- Evidence Upload Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="staff_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Staff Accounts</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="staff_form" class="form-horizontal">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Roll No.</label>
                                            <input type="text" placeholder="Enter Staff Roll No." class="form-control"
                                                formControlName="roll_no">
                                            <div *ngIf="isInvalid('roll_no')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Roll No. is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                First Name</label>
                                            <input type="text" placeholder="Enter Staff First Name."
                                                class="form-control" formControlName="given_name">
                                            <div *ngIf="isInvalid('given_name')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">First Name is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Staff Middle Name.</label>
                                            <input type="text" placeholder="Enter Staff Middle Name."
                                                class="form-control" formControlName="middle_name">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Last Name</label>
                                            <input type="text" placeholder="Enter Staff Last Name." class="form-control"
                                                formControlName="family_name">
                                            <div *ngIf="isInvalid('family_name')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Last Name is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Staff Personal Email.</label>
                                            <input type="email" placeholder="Enter Staff Personal Email."
                                                class="form-control" formControlName="personal_email">
                                            <div *ngIf="isInvalid('personal_email')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Personal Email is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Staff Organization Email.</label>
                                            <input type="email" placeholder="Enter Staff Organization Email."
                                                class="form-control" formControlName="email">
                                            <p style="color: grey"><b>Please create Email through
                                                    https://admin.google.com/</b>
                                            </p>
                                            <div *ngIf="isInvalid('email')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Organization Email is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Mobile No.</label>
                                            <input type="number" placeholder="Enter Staff Mobile No."
                                                class="form-control" formControlName="mobile_number">
                                            <div *ngIf="isInvalid('mobile_number')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Mobile Number is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                User Type</label>
                                            <select formControlName="user_type" class="form-control">
                                                <option value="">Select User Type</option>
                                                <option
                                                    *ngFor="let user of user_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="user">{{user |
                                                    titlecase}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('user_type')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">User Type is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Date of Birth.</label>
                                            <input type="date" placeholder="Enter Date of Birth." class="form-control"
                                                formControlName="birth_date">
                                            <div *ngIf="isInvalid('birth_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Date of Birth is required</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Date of Joining.</label>
                                            <input type="date" placeholder="Enter Date of joining." class="form-control"
                                                formControlName="date_of_joining">
                                        </div>
                                    </div>

                                    <div class="col-md-6" *ngIf="!staff_form.value.is_active">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Date of Resignation.</label>
                                            <input type="date" placeholder="Enter Date of Resignation."
                                                class="form-control" formControlName="date_of_resignation">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Roles</label>
                                            <kendo-multiselect [data]="roles" valueField="role_key" textField="role_key"
                                                formControlName="roles" placeholder="Select Role(s)">
                                            </kendo-multiselect>
                                            <div *ngIf="isInvalid('roles')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose at least one Role</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <input type="checkbox" formControlName="is_active" class="mg-r-5">Is Active?
                                </div>
                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.staff_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.staff_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.staff_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.staff_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.staff_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.staff_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.staff_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.staff_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <!-- <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.staff_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.staff_form.value._id && delete_loading" disabled>Deleting...</button> -->
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>