<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Admission Fees Collection Report</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Admission Fees Collection Report</h4>
                            <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Admission Fees Collection & Term Wise Breakup Report</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Regulation
                                            year</label>
                                        <select formControlName="regulation_year" class="form-control"
                                            (change)="getAdmissionIDs($event)">
                                            <option value="">Select Regulation year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(5);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Admission</label>
                                        <select formControlName="admission_id" class="form-control"
                                            (change)="onResetClick()">
                                            <option value="">Select Admission</option>
                                            <option
                                                *ngFor="let admission of admissions_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="admission._id">{{admission.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="start_date"
                                            (change)="onResetClick()">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="end_date"
                                            (change)="onResetClick()">
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Target</label>
                                        <select formControlName="fee_target_type" class="form-control">
                                            <option value="">Select Target</option>
                                            <option value="REGULAR">Regular</option>
                                            <option value="SC">SC/ST/SCA/BC(PARAYAN/PALLAN)</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Gender</label>
                                        <select formControlName="gender" class="form-control"
                                            (change)="onFilterSelectChange('gender',$event)">
                                            <option value="">All Gender</option>
                                            <option
                                                *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="gender.id">
                                                {{gender.text}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Payment
                                            Status</label>
                                        <select formControlName="payment" class="form-control"
                                            (change)="onResetClick()">
                                            <option value="">Select payment status</option>
                                            <option
                                                *ngFor="let status of payment_status;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="status.id">{{status.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Community</label>
                                        <ng-multiselect-dropdown [placeholder]="'Select Community Name(s)'"
                                            [settings]="dropdownSettings" [data]="commonEnums.community_list"
                                            formControlName="community_ids">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Staff Roll.
                                            No</label>
                                        <input type="text" class="form-control" formControlName="staff_id"
                                            placeholder="Enter Staff Roll No." (change)="onResetClick()">
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!report_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="report_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">

                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                                </div>
                            </div>
                            <button class="btn btn-md btn-info mg-l-15" (click)="viewTransferList()">View Transfer
                                List</button>
                        </div>
                        <div class="table-responsive" id="print-table">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th class="table-cell">S.No</th>
                                        <th class="table-cell">Date</th>
                                        <th class="table-cell">Programme</th>
                                        <th class="table-cell">Roll No.</th>
                                        <th class="table-cell">App No.</th>
                                        <th class="table-cell">Name</th>
                                        <th class="table-cell">Birth Date</th>
                                        <th class="table-cell">Mobile No.</th>
                                        <th class="table-cell">Total Fees</th>
                                        <th class="table-cell">Term</th>
                                        <th class="table-cell">Fee Amount</th>
                                        <th class="table-cell">Paid Amount</th>
                                        <th class="table-cell">Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let report of fees_report; let i=index;trackBy:fromDataResolver.identify;">
                                        <td>{{this.start_index + i+1}}</td>
                                        <td>{{report.hasOwnProperty('paid_date')?(report?.paid_date |
                                            date:commonEnums.date_format):'---'}}</td>
                                        <td class="uppercase">
                                            {{report.programme_name}}
                                        </td>
                                        <td>
                                            <span *ngIf="report.roll_no">{{report.roll_no}}</span>
                                            <span *ngIf="!report.roll_no">---</span>
                                        </td>
                                        <td>{{report.application_no}}</td>
                                        <td class="uppercase">{{report.name}}</td>
                                        <td>{{report.hasOwnProperty('birth_date')?(report?.birth_date |
                                            date:commonEnums.date_format):'---'}}</td>
                                        <td>{{report.mobile_number}}</td>
                                        <td>Rs. {{report.programme_fee_amount}}</td>
                                        <td class="uppercase">{{report.term_name}}</td>
                                        <td>Rs. {{report.prg_amount}}</td>
                                        <td>Rs. {{report.prg_amount}}</td>
                                        <td>
                                            <span *ngIf="report.from_application && report.status != 'cancelled' ">
                                                <b>Transferred From
                                                    {{getProgrammeName(report.from_application?.programme_id)}} -
                                                    App No: {{report.from_application?.application_no}}
                                                    <span *ngIf="report.from_application?.roll_no">, Roll No:
                                                        {{report.from_application?.roll_no}}</span>
                                                </b>
                                            </span>
                                            <p *ngIf="report.status == 'cancelled'">
                                                <b><i>Cancelled</i></b>
                                            </p>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="!report_list_loading && fees_report?.length==0">
                                        <tr>
                                            <th colspan="13" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="report_list_loading && fees_report?.length==0">
                                        <tr>
                                            <th colspan="13" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <ng-container *ngIf="total_records>500">
                                <ul class="pagination pull-right">
                                    <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                                                class="icon ion-ios-arrow-back"></i></a></li>

                                    <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                                                class="icon ion-ios-arrow-forward"></i></a></li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>


                </div>
            </div>
        </div>


        <!-- Evidence Upload Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Transfer Report</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <p *ngIf="this.programme_search_form.value.programme_id"><b>Students transferred from
                                    {{getProgrammeName(this.programme_search_form.value.programme_id)}} to other courses
                                    are
                                    listed below</b></p>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="table-cell">S.No</th>
                                            <th class="table-cell">Date</th>
                                            <th class="table-cell">App No.</th>
                                            <th class="table-cell">Roll No.</th>
                                            <th class="table-cell">Name</th>
                                            <th class="table-cell">To Programme Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let transfer of transfer_list; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{transfer.transferred_date | date:commonEnums.date_format}}</td>
                                            <td>{{transfer.from_application.application_no}}</td>
                                            <td>
                                                <span *ngIf="transfer?.from_application?.roll_no">
                                                    {{transfer.from_application.roll_no}}
                                                </span>
                                                <span *ngIf="!transfer?.from_application?.roll_no">
                                                    ---
                                                </span>
                                            </td>
                                            <td>
                                                {{transfer.applicant_name}}
                                            </td>
                                            <td>
                                                {{transfer.to_programme_name}}
                                            </td>
                                        </tr>

                                        <ng-container *ngIf="!transfer_list_loading && transfer_list?.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="transfer_list_loading && transfer_list?.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>