import { Component, OnInit } from '@angular/core';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-staffdashboard',
  templateUrl: './staffdashboard.component.html',
  styleUrls: ['./staffdashboard.component.scss']
})
export class StaffdashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  isRoleExists(roles: string[]) {
    if (CookieStore.isRoleExists(roles)) {
      return true;
    } else {
      return false;
    }
  }
}
