import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-term-master',
  templateUrl: './term-master.component.html',
  styleUrls: ['./term-master.component.scss']
})
export class TermMasterComponent implements OnInit {

  constructor(private restService:AdminservicesService, private notificationService:NotificationService,
    private formBuilder:FormBuilder, private router:Router, private route:ActivatedRoute) { 
  
    this.term_setting_form = this.formBuilder.group({
      term_type_name:['', [Validators.required]],
    });
  }
  fonts = fonts;
  term_type_list_loading:boolean = false;
  term_setting_form: FormGroup; 
  title = 'Exam Term Settings';
  term_type_list: {
    term_type_name: string
  } [ ] = [ ];
  fromDataResolver = FromDataResolver;
  ngOnInit(): void {
    this.gettermTypeList();
  }

  gettermTypeList() //get from API
  {
    //await this.restService.gettermSettingsList()
    this.term_type_list.push(
      {
        term_type_name:"April-2021",
      }
    );
  }

  
  async onAddClick()
  {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }



  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }
  isInvalid(formControl: string): boolean {
    return this.term_setting_form.touched && this.term_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.term_setting_form.status !== 'VALID';
  }

  onSave(): void {
    this.addItem();
    this.reset();
 }

 onSaveAndClose():void
 {
   this.onSave();
   this.onCloseClick();
 }
 reset(): void {
   this.term_setting_form.reset();
 }


 async addItem(): Promise<void> {
  try { 
    this.term_type_list.unshift(this.term_setting_form.value);
    /* 
    const res = await this.restService.updateExamSettings(this.setting_name,this.collection_form.value);
    if (!res) {
      this.notificationService.setNotifcation({ message: 'Failed to update exam settings', type: 'Failed'});
    }
    this.notificationService.setNotifcation({ message: 'Updated exam settings successfully', type: 'Success'});
   */
  } catch (error) {
    this.notificationService.setNotifcation({ message: 'Failed to update exam settings', type: 'Failed'});
  }
}

  onDeleteClick(term:any)
  {
    this.term_type_list.splice(this.term_type_list.findIndex(x=>x.term_type_name==term.term_type_name),1); 
    this.onCloseClick();
  }






}
