import { Room } from './../room-model';
import { Floor } from './../floor-model';
import { StudentRoomMap } from '../asset-management/studentRoomMap';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { Hostel } from './../hostelModel';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-hostel-attendance',
  templateUrl: './hostel-attendance.component.html',
  styleUrls: ['./hostel-attendance.component.scss']
})
export class HostelAttendanceComponent implements OnInit {

  hostel: Hostel = {} as Hostel;
  room_id:string='';
  studentRoomMaps: StudentRoomMap[] = [] as StudentRoomMap[];
  fonts = fonts;
  listLoading: boolean = false;
  hostels : {
    hostel_name:string
  } []= [{
    hostel_name:"Boys Hostel"
  },
  {
    hostel_name: "Girls Hostel"
  },
  {
    hostel_name: "Staff Hostel"
  }
] 
attendance_data: FormGroup;

  fromDataResolver = FromDataResolver;
  constructor(private formBuilder: FormBuilder,
    private restService:AdminservicesService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
      this.attendance_data = this.formBuilder.group({
        hostel_name:['', Validators.required],
        roll_no: ['', Validators.required],
        date_time: ['', Validators.required],
        in_out: ['']
      });
   }
   attendance: {
     roll_no:string,
     date_time:string,
     in_out:string
   } [ ] = [
     {roll_no: "12PA10", date_time:"28-08-2021 09:45",in_out:"out"},
     {roll_no: "12PA11", date_time:"28-08-2021 09:50",in_out:"out"},
     {roll_no: "12PA12", date_time:"28-08-2021 09:52",in_out:"out"},
     {roll_no: "12PA10", date_time:"28-08-2021 16:18",in_out:"in"},
     {roll_no: "12PA12", date_time:"28-08-2021 16:20",in_out:"in"},
  ]
  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.setNavigation([{label: 'Hostel Attendance', url: 'admin/hostel/hostel-attendance'}]);
    this.notificationService.setNotifcation({});
    this.readAttendance();
  }



  canDisableAction() {
   return (this.attendance_data.get('room_id')?.value=='')?true:false;
  }

  ngOnDestroy() {
  }


  getDateTime(dt:string)
  {
    var date_time = new Date(dt);
    return (date_time.getDate()>9?date_time.getDate():"0"+date_time.getDate())+"-"+
    ((date_time.getMonth()+1)>9?(date_time.getMonth()+1):"0"+(date_time.getMonth()+1))+
    "-"+date_time.getFullYear()+" "+
    date_time.getHours()+":"+date_time.getMinutes();
    
  }

  onSaveClick()
  {
    this.attendance_data.get('date_time')?.setValue
    (this.getDateTime(this.attendance_data.get('date_time')?.value));
    if(this.attendance_data.get('roll_no')?.value.indexOf(",")>0)
    {
      var rnos = (this.attendance_data.get('roll_no')?.value as String).split(",");
      rnos.forEach(roll_no=>{
        roll_no = roll_no.trim();
        this.attendance.push({roll_no:roll_no, date_time: this.attendance_data.get('date_time')?.value,
        in_out: this.attendance_data.get('in_out')?.value
      })
      })
    }
    else
    {
      this.attendance.push(this.attendance_data.value);
    }
    this.attendance_data.reset();
  }
  resetAttendance()
  {
    this.attendance_data.reset();
  }

  readAttendance()
  {
    this.attendance.push({
      roll_no: "12PA21", date_time: "28-08-2021 16:50", in_out:"In"
    });
    setTimeout(()=>{
      this.readAttendance()
    },60000);
  }
}
