<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Hostel Student Fees List</p>
                    </div>

                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button class="btn btn-md btn-info mg-r-10">
                                <input type="file" accept=".csv" (change)="fileChangeListener($event)" #fileImportInput
                                    [(ngModel)]="file_upload" placeholder="ATTACH CSV FILE"
                                    style="width: 150px; color:black">
                            </button>
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Add
                                Student Fee</button>

                        </div>



                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 20%;">Roll No</th>
                                        <th style="width: 20%;">Fee</th>
                                        <th style="width: 30%;">Description</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="student_fee_list.length>0">
                                        <ng-container
                                            *ngFor="let student of student_fee_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{student.roll_no | uppercase}}</td>
                                                <td>{{student.amount}}</td>
                                                <td>{{student.description}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(student)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            Edit Fees
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!student_fee_list_loading && student_fee_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_fee_list_loading && student_fee_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Fee Settings Large Modal -->
        <div class="modal" id="collection-modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_fee_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Student Hostel Fees Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="student_fee_form" class="form-horizontal">




                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Roll No.</label>
                                            <input type="text" class="form-control" formControlName="roll_no"
                                                placeholder="Enter Student Roll No.">
                                            <div *ngIf="isInvalid('roll_no')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Student Roll No. is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Extra
                                                Fee Charges</label>
                                            <input type="text" class="form-control" formControlName="amount"
                                                placeholder="Enter Student Hostel Fees">
                                            <div *ngIf="isInvalid('amount')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Student Fee is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                        Description</label>
                                    <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                                        formControlName="description">
                                </textarea>
                                    <div *ngIf="isInvalid('description')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Description is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Save &
                                        Close</button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()" *ngIf="setting_id"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Preview Records Large Modal -->
        <div class="modal" id="csv-modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="csvRecords.length>0">
                    <div class="modal-header">
                        <h6 class="modal-title">Preview File</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 10%;">S.No</th>
                                            <th style="width: 20%;">Roll No</th>
                                            <th style="width: 20%;">Fee</th>
                                            <th style="width: 30%;">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="csvRecords.length>0">
                                            <ng-container
                                                *ngFor="let record of csvRecords;let i=index;trackBy:fromDataResolver.identify;">
                                                <tr>
                                                    <td scope="row">
                                                        {{i+1}}
                                                    </td>
                                                    <td>{{record.roll_no | uppercase}}</td>
                                                    <td>{{record.amount}}</td>
                                                    <td>{{record.description}}</td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="!csvRecords_loading && csvRecords.length==0">
                                            <tr>
                                                <th colspan="5" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="csvRecords_loading && csvRecords.length==0">
                                            <tr>
                                                <th colspan="5" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mg-b-10">
                        <button class="btn btn-primary mg-r-20" (click)="onUpload()">Upload</button>
                        <button class="btn btn-secondary mg-r-20" (click)="closeCSVModal()">Cancel</button>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>