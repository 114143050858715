<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Staffs</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Staff</button>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Staffs</h4>
              <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="staffs_list.length>0"
                (click)="downloadAsCSV()">
            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Staffs</p>
          </div>

          <div class="card-body" *ngIf="department_search_form">
            <form [formGroup]="department_search_form">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Type</label>
                    <select formControlName="department_category_id" class="form-control"
                      (change)="onFilterSelectChange('department_category_id',$event)">
                      <option value="">Select Department Type</option>
                      <option
                        *ngFor="let department_type of department_type_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="department_type.department_category_id">{{department_type.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department</label>
                    <select formControlName="department_id" class="form-control"
                      (change)="onFilterSelectChange('department_id',$event)">
                      <option value="">All Department</option>
                      <option *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="department.department_id">{{department.department_name}}
                        {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Gender</label>
                    <select formControlName="gender" class="form-control"
                      (change)="onFilterSelectChange('gender',$event)">
                      <option value="">All Gender</option>
                      <option *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="gender.id">{{gender.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll No</label>
                    <input class="form-control" required="" type="text" formControlName="roll_no">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!staffs_list_loading" (click)="onSearchClick()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="staffs_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Roll No.</th>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    <th>Last Updated At</th>
                    <th style="width: 20%;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="staffs_list.length>0">


                    <ng-container *ngFor="let staff of staffs_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td class="text-uppercase">{{staff.roll_no}}</td>
                        <td class="text-uppercase">{{staff.given_name}} {{staff.middle_name}}</td>
                        <td class="text-uppercase">{{staff.gender}}</td>
                        <td class="text-uppercase">{{staff.mobile_number}}</td>
                        <td class="text-lowercase">{{staff.email}}</td>
                        <td>{{staff.updatedAt | date}}</td>
                        <td>
                          <div class="pr-1 mb-3 mb-xl-0">
                            <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEditClick(staff)">
                              <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                            </button>
                            <button type="button" class="btn btn-sm btn-primary mr-2"
                              (click)="onInitResetPasswordClick(staff)">
                              <fa-icon [icon]="fonts.faPassport"></fa-icon>
                            </button>
                            <button type="button" class="btn btn-sm btn-secondary" (click)="onAssignRoles(staff)">
                              <fa-icon [icon]="fonts.faUser"></fa-icon>
                            </button>

                          </div>
                        </td>

                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!staffs_list_loading && staffs_list.length==0">
                    <tr>
                      <th colspan="8" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="staffs_list_loading && staffs_list.length==0">
                    <tr>
                      <th colspan="8" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="staff_form && staff">
          <div class="modal-header">
            <h6 class="modal-title" *ngIf="!staff._id">Add New Staff</h6>
            <h6 class="modal-title" *ngIf="staff._id">Update Staff</h6>

          </div>
          <div class="modal-body">
            <div class="">
              <form [formGroup]="staff_form">
                <div class="row">
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll no</label>
                      <input class="form-control" required="" type="text" formControlName="roll_no"
                        (blur)="onRollNoChange($event)">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Salutation</label>
                      <input class="form-control" required="" type="text" formControlName="salutation">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Staff Name</label>
                      <input class="form-control" required="" type="text" formControlName="given_name">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Initial</label>
                      <input class="form-control" required="" type="text" formControlName="middle_name">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Birth Date</label>
                      <input class="form-control" required="" type="date" formControlName="birth_date">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Father's Name</label>
                      <input class="form-control" required="" type="text" formControlName="father_name">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Husband's Name, if available</label>
                      <input class="form-control" type="text" formControlName="husband_name">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Contact Number</label>
                      <input class="form-control" required="" type="text" formControlName="mobile_number">
                    </div>



                  </div>

                  <div class="col-xl-3">

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Email</label>
                      <input class="form-control" required="" type="text" formControlName="email">
                      <p style="color: grey"><b>Please create Email through
                          https://admin.google.com/</b>
                      </p>
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Personal Email</label>
                      <input class="form-control" required="" type="text" formControlName="personal_email">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Gender</label>
                      <select formControlName="gender" class="form-control"
                        (change)="onFilterSelectChange('gender',$event)">
                        <option value="">Select Gender</option>
                        <option
                          *ngFor="let gender of commonEnums.genders;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="gender.id">{{gender.text}}</option>
                      </select>
                    </div>


                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Blood Group</label>
                      <select formControlName="blood_group" class="form-control"
                        (change)="onFilterSelectChange('blood_group',$event)">
                        <option value="">All Blood Groups</option>
                        <option
                          *ngFor="let blood_group of commonEnums.blood_groups;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="blood_group.id">{{blood_group.text}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">AADHAR Card Number</label>
                      <input class="form-control" required="" type="text" formControlName="aadhar_card_no">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">PAN Card Number, if
                        available</label>
                      <input class="form-control" type="text" formControlName="pan_number">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Designation</label>
                      <input class="form-control" required="" type="text" formControlName="designation">
                    </div>




                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Qualification</label>
                      <input class="form-control" required="" type="text" formControlName="qualification">
                    </div>

                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Date of joining</label>
                      <input class="form-control" required="" type="date" formControlName="date_of_joining">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Community</label>
                      <select formControlName="community_id" class="form-control"
                        (change)="onFilterSelectChange('community_id',$event)">
                        <option value="">All Community</option>
                        <option
                          *ngFor="let community_id of commonEnums.community_list;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="community_id">{{community_id}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Caste</label>
                      <input class="form-control" required="" type="text" formControlName="caste">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Religion</label>
                      <select formControlName="religion" class="form-control"
                        (change)="onFilterSelectChange('religion',$event)">
                        <option value="">All Religions</option>
                        <option
                          *ngFor="let religion of commonEnums.religions;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="religion.id">
                          {{religion.text}}</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="physically_challanged"
                          name="physically_challanged" formControlName="physically_challanged">
                        <label class="custom-control-label" for="physically_challanged">Are you physically
                          challenged?</label>
                      </div>
                    </div>


                  </div>

                </div>

                <div> <b>Address</b></div>

                <div formGroupName="address">
                  <div class="row">
                    <div class="col-xl-6">
                      <div> <b>Present Address</b></div>
                      <div formGroupName="present">
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Street</label>
                          <input class="form-control" required="" type="text" formControlName="street">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">City</label>
                          <input class="form-control" required="" type="text" formControlName="city">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">District</label>
                          <input class="form-control" required="" type="text" formControlName="district">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">State</label>
                          <input class="form-control" required="" type="text" formControlName="state">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Country</label>
                          <input class="form-control" required="" type="text" formControlName="country">
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div> <b>Communication Address</b></div>
                      <div formGroupName="communication">
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Street</label>
                          <input class="form-control" required="" type="text" formControlName="street">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">City</label>
                          <input class="form-control" required="" type="text" formControlName="city">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">District</label>
                          <input class="form-control" required="" type="text" formControlName="district">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">State</label>
                          <input class="form-control" required="" type="text" formControlName="state">
                        </div>
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Country</label>
                          <input class="form-control" required="" type="text" formControlName="country">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div><b>Bank Information (If available)</b></div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Bank Name</label>
                      <input class="form-control" type="text" formControlName="bank_name">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Bank Location</label>
                      <input class="form-control" type="text" formControlName="bank_location">
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Bank Acc. No.</label>
                      <input class="form-control" type="text" formControlName="bank_account_number">
                    </div>
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Bank IFSC Code</label>
                      <input class="form-control" type="text" formControlName="bank_ifsc_code">
                    </div>
                  </div>
                </div>


                <div><b>Previous Work Experience (If available)</b></div>
                <table class="table" style="width: 100%;" formArrayName="experience">
                  <thead>
                    <th style="width: 3%;">S.No</th>
                    <th>Employer Name</th>
                    <th>Employer Location</th>
                    <th>Years of Experience</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Remarks</th>
                    <th style="width:10%">Delete</th>
                  </thead>
                  <ng-container *ngFor="let experience of experienceList;let i=index">
                    <tr [formGroupName]="i">
                      <td>{{i+1}}</td>
                      <td>
                        <input type="text" class="form-control" placeholder="Employer Name"
                          formControlName="employeer_name">
                      </td>
                      <td>
                        <input type="text" class="form-control" placeholder="Employer Location"
                          formControlName="employeer_location">
                      </td>
                      <td>
                        <input type="number" class="form-control" placeholder="Employer Location"
                          formControlName="years_of_experience">
                      </td>
                      <td>
                        <input type="date" class="form-control" placeholder="Start Date" formControlName="start_date">
                      </td>
                      <td>
                        <input type="date" class="form-control" placeholder="Start Date" formControlName="end_date">
                      </td>
                      <td>
                        <input type="text" placeholder="Enter Remarks,if any" formControlName="remarks"
                          class="form-control">
                      </td>
                      <td>
                        <button type="button" class="btn btn-danger" (click)="deleteExperience(i)">
                          Delete
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <tr>
                    <td colspan="8" class="text-center">
                      <button type="button" class="btn btn-primary" (click)="addExperience()">
                        Add New Experience
                      </button>
                    </td>
                  </tr>
                </table>


                <table class="table" style="width: 100%;" formArrayName="departments">
                  <thead>
                    <th style="width: 10%;">S.No</th>
                    <th>Department Name</th>
                    <th>Designation</th>
                    <th style="width:15%">Delete</th>
                  </thead>
                  <ng-container *ngFor="let department of departmentList;let i=index">
                    <tr [formGroupName]="i">
                      <td>{{i+1}}</td>
                      <td>
                        <select formControlName="department_id" class="form-control">
                          <option value="">All Department
                          </option>
                          <option
                            *ngFor="let department of all_department_list;let i=index;trackBy:fromDataResolver.identify;"
                            [ngValue]="department.department_id">{{department.department_name}}
                            {{department.department_category_id}} -
                            {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                        </select>
                      </td>
                      <td>
                        <input type="text" placeholder="Enter Designation" formControlName="designation"
                          class="form-control">
                      </td>
                      <td>
                        <button type="button" class="btn btn-danger" (click)="deleteDepartment(i)">
                          Delete
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  <tr>
                    <td colspan="4" class="text-center">
                      <button type="button" class="btn btn-primary" (click)="addDepartment()">
                        Add New Department
                      </button>
                    </td>
                  </tr>
                </table>







                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                    *ngIf="!staff_save_loading">Save</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="staff_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>

                  <!-- <ng-container *ngIf="staff._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()" *ngIf="!staff_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="staff_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                    </ng-container> -->


                  <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-resetpassword">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="change_password_form">
          <div class="modal-header">
            <h6 class="modal-title">Reset Password</h6>


          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="change_password_form">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Password</label>
                  <input class="form-control" required="" type="text" formControlName="password">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Confirm Password</label>
                  <input class="form-control" required="" type="text" formControlName="confirm_password">
                </div>



                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onFinishResetPasswordClick()"
                    *ngIf="!change_password_save_loading">Change Password</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="change_password_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>


                  <button class="btn ripple btn-secondary" (click)="onResetPassordCloseClick()"
                    type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-assignrole">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">Assign Roles for <b>{{user_role_data.name}}</b>
            </h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-md-40">
              <div class="row">
                <div class="col-md-12">
                  <b>Available Roles</b>
                  <div class="card card-body">
                    <div class="table-responsive">
                      <form>
                        <table class="table table-striped mg-b-0 text-md-nowrap">
                          <tr *ngFor="let data of roles; let i=index; trackBy:fromDataResolver.identify;">
                            <td style="width:60%">{{data.role_key}}
                              <!-- <div class="role_list" id="{{data.role_name}}_list" style="display:none">
                                                      <span>Available Permissions</span>
                                                      <ol>
                                                        <li *ngFor="let per of data.permissions">{{per}}</li>
                                                      </ol>
                                                    </div> -->
                            </td>

                            <td style="width:20%">
                              <!-- <button type="button" class="btn btn-sm btn-info mr-2"
                                                      (click)="switchPermissionVisibility(data.role_name)">
                                                      <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                                    </button> -->
                              {{data.role_description}}
                            </td>
                            <td style="width:20%">
                              <input type="checkbox" class="form-control-sm" [id]="data.role_key" [name]="data.role_key"
                                [checked]="user_role_data.roles.indexOf(data.role_key)>=0?true:false"
                                (click)="selectRole($event)" [value]="data.role_key" [(ngModel)]="roles[i].is_allowed">
                            </td>
                          </tr>
                        </table>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4">
                  <b>Allowed Permissions</b> <br>
                  <div class="card card-body">
                    <div class="table-responsive">
                      <table class="table table-striped mg-b-0 text-md-nowrap">
                        <tr
                          *ngFor="let permission of assigned_roles.permissions; let i=index; trackBy:fromDataResolver.identify;">
                          {{i+1}} {{permission}}
                        </tr>
                      </table>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="add-btn-row">
                <button class="btn btn-primary mg-r-20" (click)="onSaveRoles()" *ngIf="!role_save_loading">Save
                  Roles</button>
                <button class="btn btn-primary mg-r-20" disabled *ngIf="role_save_loading">Saving...</button>
                <button class="btn btn-danger mg-r-20" (click)="onResetRoles()">Reset Roles</button>
                <button class="btn btn-dark mg-r-20" (click)="onCloseRoles()">Close</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>