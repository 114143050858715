<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Transportation Request</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>

    <div class="row row-sm">
      <div class="col-lg-5 col-xl-2 col-md-5 col-12">
        <div class="card bg-primary-gradient text-white ">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="icon1 mt-2 text-center">
                  <i class="fe fe-users tx-40"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-0 text-center">
                  <span class="text-white">Total</span>
                  <h2 class="text-white mb-0">{{staus_count.total}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-2 col-md-5 col-12">
        <div class="card bg-info-gradient text-white ">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="icon1 mt-2 text-center">
                  <i class="fe fe-pause-circle tx-40"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-0 text-center">
                  <span class="text-white">Pending</span>
                  <h2 class="text-white mb-0">{{staus_count.applied}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-2 col-md-5 col-12">
        <div class="card bg-success-gradient text-white">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="icon1 mt-2 text-center">
                  <i class="fe fe-check tx-40"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-0 text-center">
                  <span class="text-white">Approved</span>
                  <h2 class="text-white mb-0">{{staus_count.approved}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-2 col-md-5 col-12">
        <div class="card bg-success-gradient text-white">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="icon1 mt-2 text-center">
                  <i class="fe fe-user-plus tx-40"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-0 text-center">
                  <span class="text-white">Enrolled</span>
                  <h2 class="text-white mb-0">{{staus_count.enrolled}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-2 col-md-5 col-12">
        <div class="card bg-warning-gradient text-white">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="icon1 mt-2 text-center">
                  <i class="fe fe-x-circle tx-40"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-0 text-center">
                  <span class="text-white">Rejected</span>
                  <h2 class="text-white mb-0">{{staus_count.rejected}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-2 col-md-5 col-12">
        <div class="card bg-warning-gradient text-white">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="icon1 mt-2 text-center">
                  <i class="fe fe-thumbs-down tx-40"></i>
                </div>
              </div>
              <div class="col-6">
                <div class="mt-0 text-center">
                  <span class="text-white">Failed</span>
                  <h2 class="text-white mb-0">{{staus_count.payment_failed}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Transportation Request</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Review and Approve or Reject Applications</p>
          </div>

          <div class="card-body" *ngIf="search_form">
            <form [formGroup]="search_form">
              <div class="row row-sm">

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Bus Admission Year</label>
                    <select formControlName="requested_year" class="form-control">
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Student Entrollment
                      Year</label>
                    <select formControlName="enrolled_year" class="form-control">
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Status</label>
                    <select class="form-control" formControlName="status">
                      <option label="All">
                      </option>
                      <option value="applied">
                        Applied and Pending for verification
                      </option>
                      <option value="allowed">
                        Approved
                      </option>
                      <option value="payment_done">
                        Enrolled
                      </option>
                      <option value="rejected">
                        Rejected
                      </option>
                      <option value="payment_failure">
                        Payment Failed
                      </option>

                    </select>
                  </div>
                </div>



                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!applied_list_loading" (click)="getAllTransportationAppliedList()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="applied_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>

              </div>
            </form>
          </div>

          <div class="card-body">
            <div class="d-flex right-content mg-b-5" style="float: right;">
              <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info" data-toggle="dropdown"
                  id="dropDownMenuButton" type="button">Download<i class="fas fa-caret-down ml-1"></i></button>
                <div class="dropdown-menu tx-13">
                  <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                </div>

              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Roll No(s)</th>
                    <th>Programme(s)</th>
                    <th>From</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Transaction Id</th>
                    <th style="width: 15%;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="applied_list && applied_list.length>0">


                    <ng-container *ngFor="let applied of applied_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <th scope="row">
                          {{i+1}}

                        </th>
                        <ng-container *ngIf="applied.user_info">
                          <td>{{applied.user_info.given_name}} {{applied.user_info.family_name}}
                            {{applied.user_info.middle_name}}</td>
                          <td>{{applied.user_info.email}}</td>
                          <td>{{applied.user_info.mobile_number}}</td>
                          <td>
                            <ng-container *ngIf="applied.user_info.programmes && applied.user_info.programmes.length>0">
                              <ng-container
                                *ngFor="let programme of applied.user_info.programmes;let i=index;trackBy:fromDataResolver.identify;">
                                {{programme.roll_no}} ,
                              </ng-container>
                            </ng-container>

                          </td>
                          <td>
                            <ng-container *ngIf="applied.user_info.programmes && applied.user_info.programmes.length>0">
                              <ng-container
                                *ngFor="let programme of applied.user_info.programmes;let i=index;trackBy:fromDataResolver.identify;">
                                {{programme.programme_name}} ,
                              </ng-container>
                            </ng-container>
                          </td>
                          <td>{{applied.from}}</td>
                          <td>{{applied.amount|currency:'INR'}}</td>
                          <td>
                            <ng-container *ngIf="applied.status=='pending'">
                              Applied
                            </ng-container>
                            <ng-container *ngIf="applied.status=='payment_failure'">
                              Payment Failed
                            </ng-container>
                            <ng-container *ngIf="applied.status=='reject' || applied.status=='rejected'">
                              Rejected By Incharge
                            </ng-container>
                            <ng-container *ngIf="applied.status=='allowed'">
                              Allowed By Incharge
                            </ng-container>
                            <ng-container *ngIf="applied.status=='payment_done'">
                              Enrolled
                            </ng-container>

                          </td>
                          <td>{{applied.transaction_id?applied.transaction_id:(applied.unique_id?
                            applied.unique_id.split("-")[4]:"")}}</td>

                          <td>
                            <ng-container *ngIf="applied.status=='pending'">
                              <div class="btn-list">
                                <button class="btn btn-sm btn-success mg-t-5 mg-r-5"
                                  (click)="approveStatus(applied._id)">Approve</button>
                                <button class="btn btn-sm btn-danger mg-t-5 mg-r-5"
                                  (click)="rejectStatus(applied._id)">Reject</button>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="applied.status=='payment_failure' || applied.status=='allowed'">
                              <button type="button" class="btn btn-sm btn-info mr-2" title="Make Payment Corrections"
                                (click)="onInitPaymentCorrectionClick(applied)">
                                Update Payment
                              </button>
                            </ng-container>
                            <ng-container *ngIf="applied.status=='reject'">
                              <button class="btn btn-sm btn-success"
                                (click)="approveStatus(applied._id)">Re-Approve</button>
                            </ng-container>
                            <ng-container *ngIf="applied.status=='rejected'">
                              <button class="btn btn-sm btn-success"
                                (click)="approveStatus(applied._id)">Re-Approve</button>
                            </ng-container>
                            <!-- <ng-container *ngIf="applied.status=='allowed'">
                                                            Approved, Waiting for Payment
                                                        </ng-container> -->
                            <!-- <ng-container *ngIf="applied.status=='payment_done'">
                                                            Paid and Enrolled
                                                        </ng-container> -->

                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                    <tr *ngIf="selected_status=='' || selected_status=='payment_done'">
                      <th colspan="7" class="text-right">Total Collected</th>
                      <th class="text-center">{{staus_count.collected_amount|currency:'INR'}}</th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                    </tr>
                  </ng-container>

                  <ng-container *ngIf="!applied_list || applied_list.length==0">
                    <tr>
                      <th colspan="11" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-payment-correction">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="payment_correction_form">
          <div class="modal-header">
            <h6 class="modal-title">Update Payment</h6>


          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="payment_correction_form">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Paymentmode</label>
                  <select formControlName="payment_mode" class="form-control"
                    (change)="onFilterSelectChange('payment_mode',$event)">
                    <option *ngFor="let payment_mode of payment_modes;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="payment_mode.id">{{payment_mode.text}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Transaction Id</label>
                  <input class="form-control" required="" type="text" formControlName="transaction_id">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description</label>
                  <textarea class="form-control" required="" name="" id="" cols="30" rows="10"
                    formControlName="description"></textarea>
                </div>



                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onFinishPaymentCorrectionClick()"
                    *ngIf="!payment_correction_form_save_loading">Update</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled
                    *ngIf="payment_correction_form_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>


                  <button class="btn ripple btn-secondary" (click)="onPaymentCorrectionCloseClick()"
                    type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>