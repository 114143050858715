import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { StudentRoomMap } from 'src/app/controllers/admins/hostel-management/asset-management/studentRoomMap';

@Injectable({
  providedIn: 'root'
})
export class StudentRoomMapService {
  private _baseURL: string = ''
  private _headers: any;
  private studentRoomMap: string = 'hostel-srv/student-roommap';

  constructor(private httpClient: HttpClient) { }

  set url(baseURL: string) {
    this._baseURL = baseURL;
  }

  set headers(headers: any) {
    this._headers = headers;
  }

  errorHandler = (error: any): Promise<StudentRoomMap> => {
    console.log('StudentRoomMapService Error ', error);
    return of({} as StudentRoomMap).toPromise();
  }

  getStudentRoomMap(id: string = '', roomId: string = ''): Promise<StudentRoomMap[]> {
    let url = `${this._baseURL}/${this.studentRoomMap}/${id}`;
    if (roomId) {
      url = url.concat(`?roomId=${roomId}`);
    }
    return this.httpClient.get<StudentRoomMap[]>(url, this._headers).pipe(
      map((res: any) => {
        if (Array.isArray(res.data)) {
          return res.data;
        }
        return [res.data];
      })
    ).toPromise();
  }

  createStudentRoomMap(studentRoomMap: StudentRoomMap): Promise<StudentRoomMap> {
    return this.httpClient.post<StudentRoomMap>(`${this._baseURL}/${this.studentRoomMap}`, studentRoomMap, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }

  updateStudentRoomMap(id: string = '', data: StudentRoomMap): Promise<StudentRoomMap> {
    return this.httpClient.put<StudentRoomMap>(`${this._baseURL}/${this.studentRoomMap}/${id}`, data, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }

  deleteStudentRoomMap(id: string = ''): Promise<boolean> {
    return this.httpClient.delete<boolean>(`${this._baseURL}/${this.studentRoomMap}/${id}`, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }
}
