import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
@Component({
  selector: 'app-create-parent',
  templateUrl: './create-parent.component.html',
  styleUrls: ['./create-parent.component.scss']
})
export class CreateParentComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Create Family Member Account';

  member_list_loading: boolean = false;
  member_list: IFamilyMember[] = [];
  member: IFamilyMember = {} as IFamilyMember;
  member_form: FormGroup = {} as FormGroup;
  member_id: string = "";


  save_loading: boolean = false;
  delete_loading: boolean = false;
  cookieStore = CookieStore;

  ngOnInit(): void {
    this.getParentList();
  }
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
  }


  async getParentList() {
    try {
      this.member_list_loading = true;
      this.member_list = [];
      /* const service_response = await this.restService.getSkillsetEvidenceList({});
      if (service_response && service_response.success) {
        this.member_list = service_response.data;
      } */
      this.member_list = [{
        _id: "dsfdsafd-asdf-q3fdasf",
        name: "Vikram A",
        email: "vikram_a@gmail.com",
        mobile_number: "9876543210",
        aadhaar_number: 76543219086745,
        aadhaar_url: "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603",
        relationship: "Father",
        occupation: "Business",
        monthly_income: 30000
      }
      ]
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.member_list_loading = false;
    }

  }


  buildForm(): void {
    if (JSON.stringify(this.member) == '{}') {
      this.member = {
        _id: "",
        name: "",
        email: "",
        mobile_number: "",
        aadhaar_number: 0,
        aadhaar_url: "",
        relationship: "",
        occupation: "",
        monthly_income: 0,
        is_active: true,
        is_verified: false
      };
    }
    this.member_form = this.formBuilder.group({
      _id: [this.member._id],
      name: [this.member.name, [Validators.required]],
      email: [this.member.email],
      mobile_number: [this.member.mobile_number, [Validators.required]],
      aadhaar_number: [this.member.aadhaar_number, [Validators.required]],
      aadhaar_url: [this.member.aadhaar_url, [Validators.required]],
      relationship: [this.member.relationship, [Validators.required]],
      occupation: [((this.member.occupation) ? this.member.occupation : "")],
      monthly_income: [((this.member.monthly_income) ? this.member.monthly_income : 0)],
      is_active: [this.member.is_active]
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditClick(parent: IFamilyMember) {
    this.member = parent;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.member_id = "";
    this.member = {} as IFamilyMember;
    this.member_form.reset();
  }


  async addItem(): Promise<void> {
    try {
      var form_data = this.member_form.value;
      console.log(form_data);
      this.save_loading = true;
      if (this.member_form.value._id) {
        const res = await this.restService.updateSkillsetEvidence(this.member_form.value._id, form_data);
        if (res) {
          alert("Family member updated successfully.");
        }
      }
      else {
        const res = await this.restService.uploadSkillsetEvidence(form_data);
        if (res) {
          alert("Family Member added successfully.");
        }
      }
      this.getParentList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteSkillset(this.member_form.get('_id')!.value);
      if (res.success) {
        alert("Family member removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.member_form.touched && this.member_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.member_form.status !== 'VALID';
  }

  async uploadFile(e: any) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadEvidenceFile(formData);
      if (response.success && response.data) {
        alert("Aadhar Uploaded Successfully");
        (this.member_form.get('aadhaar_url').setValue(response.data.url));
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }


}

interface IFamilyMember {
  _id?: string,
  name: string,
  email?: string,
  mobile_number: string,
  aadhaar_number: number,
  aadhaar_url: string,
  relationship: string,
  occupation?: string,
  monthly_income?: number
  is_active?: boolean, //default true
  is_verified?: boolean //default false
}