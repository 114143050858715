import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
@Component({
  selector: 'app-staff-attendance-report',
  templateUrl: './staff-attendance-report.component.html',
  styleUrls: ['./staff-attendance-report.component.scss']
})
export class StaffAttendanceReportComponent implements OnInit {
  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  department_type_list: any[] = [];
  department_type_list_loading = false;

  department_list: any[] = [];
  department_list_loading = false;
  all_programme_list: any[] = [];
  department_search_form: FormGroup;
  staffs_list: any[] = [];
  staffData: any[] = [];
  staffs_list_loading: boolean = false;
  report_loading: boolean = false;
  report_data: StaffReportInterface[] = [];

  modalData: StaffReportInterface = {} as StaffReportInterface;
  student_data_loading: boolean = false;
  students_list: StudentAttendanceInterface = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
  popup_mode: string = '';
  absent_list: StudentAttendanceInterface = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
  student_save_loading: boolean = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) { }

  ngOnInit(): void {

    // Load master data
    this.getDepartmentTypeList();
    this.buildFilterForm();
    this.getAllProgrammeDataList();
    // Load core data
  }

  async getDepartmentTypeList(): Promise<void> {
    try {
      this.department_type_list_loading = true;
      this.department_type_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.department_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read department type');
    } finally {
      this.department_type_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.department_search_form = this.fb.group({
      department_category_id: [''],
      finance_type: [''],
      department_id: [''],
      staff_id: [''],
      start_date: [''],
      end_date: ['']
    });

  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'department_category_id' || id === 'finance_type') {
      this.getDepartmentDataList();
    }

  }

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.all_programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    }
  }

  async getDepartmentDataList(): Promise<void> {
    try {
      this.department_list_loading = true;
      this.department_list = [];
      this.staffData = []; this.staffs_list = [];
      const search_form_query: any = {

      };
      if (this.department_search_form) {
        if (this.department_search_form.value.department_category_id) {
          search_form_query.department_category_id = this.department_search_form.value.department_category_id;
        }
        if (this.department_search_form.value.finance_type) {
          search_form_query.finance_type = this.department_search_form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_list_loading = false;
    }
  }


  handleStaffFilter(value: string) {
    this.staffData = this.staffs_list.filter
      ((s) => s!.given_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        || s!.roll_no.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }

  async searchFormDataList(ev): Promise<void> {
    try {

      this.staffs_list_loading = true;
      this.staffs_list = [];
      const search_form_query: any = {
        department_id: this.department_search_form.value.department_id
      };
      const service_response = await this.restService.searchStaff(search_form_query);
      if (service_response && service_response.success) {
        this.staffs_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.staffs_list_loading = false;
    }
  }

  async getReport() {
    if (!this.department_search_form.value.department_category_id) {
      alert('Please select department category');
      return;
    }
    if (!this.department_search_form.value.finance_type) {
      alert('Please select finance type');
      return;
    }
    if (!this.department_search_form.value.department_id) {
      alert('Please select department');
      return;
    }
    if (!this.department_search_form.value.staff_id) {
      alert('Please select at least one staff');
      return;
    }
    if (!this.department_search_form.value.start_date) {
      alert('Please select start date');
      return;
    }
    if (!this.department_search_form.value.end_date) {
      alert('Please select end date');
      return;
    }
    try {
      this.report_loading = true;
      const search_form_query: any = {
        staff_id: this.department_search_form.value.staff_id,
        start_date: new Date(this.department_search_form.value.start_date).toISOString(),
        end_date: new Date(this.department_search_form.value.end_date).toISOString(),
      };
      console.log(search_form_query);
      const service_response = await this.restService.getStaffAttendanceReport(search_form_query);
      if (service_response && service_response.success) {
        this.report_data = service_response.data;
        console.log(this.report_data);
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.report_loading = false;
    }
  }

  getProgrammeNameByList(enrolled_year, programme_data) {
    if (programme_data.length == 1) {
      var programme_name = this.all_programme_list.find(x => x.programme_id == programme_data[0].programme_id)?.programme_name;
      var section = programme_data[0].section;
      return this.commonEnums.getClassName(enrolled_year, programme_name, section);
    }
    else {
      return "Virtual Section " + programme_data[0].virtual_section;
    }
  }

  async viewStudentAttendnace(data: StaffReportInterface) {
    this.modalData = data;
    try {
      this.student_data_loading = true;
      var search_data = {
        "date": data.day_date,
        "day": data.day_order,
        "hour_number": data.hour_number,
        "enrolled_year": data.enrolled_year,
        "course_code": data.course_code,
        "programme_data": data.programme_data,
        "is_adjusted": data.is_adjusted, //true for adjustment mode
        "semester": data.semester
      }
      console.log(search_data);
      this.popup_mode = 'attendance';
      JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
      const response = await this.restService.getStudentsDataForAttendance(search_data);
      if (response && response.success) {
        console.log(response.data);
        this.students_list = response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_data_loading = false;
    }
  }

  markPresent(ev: any) {
    this.students_list.attendance_data.forEach(student => {
      if (!student.od_given) {
        if (ev.target.checked) {
          student.is_present = true;
        }
        else {
          student.is_present = false;
        }
      }
    });
  }

  isChecked() {
    if (this.students_list.attendance_data.filter(x => x.is_present).length == this.students_list.attendance_data.length)
      return true;
    else
      return false;
  }

  async onCloseClick(): Promise<void> {
    this.popup_mode = '';
    this.modalData = {} as StaffReportInterface;
    this.students_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
    this.absent_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
    JQueryHelper.closeModal('#attendance-modal-popup');
  }

  onVerifyClick() {
    this.absent_list.course_code = this.students_list.course_code;
    this.absent_list.attendance_data = JSON.parse(JSON.stringify(this.students_list.attendance_data.filter(x => x.is_present == false)));
    console.log(this.absent_list);
    this.popup_mode = 'verify';
  }


  closeConfirmClick() {
    this.popup_mode = 'attendance';
    this.absent_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
  }

  removeStudentFromAbsentList(student: StudentDataInterface) {
    this.students_list.attendance_data.find(x => x.roll_no == student.roll_no)!.is_present = true;
    this.absent_list.attendance_data.splice(this.absent_list.attendance_data.findIndex(x => x.roll_no == student.roll_no), 1);
  }

  async saveAttendance() {
    this.absent_list.attendance_data.forEach(student => {
      this.students_list.attendance_data.find(x => x.roll_no == student.roll_no)!.is_present = student.is_present;
    })
    try {
      const consent = confirm("Are you sure want to save attendance?");
      if (!consent) return;
      this.student_save_loading = true;
      const response = await this.restService.CreateorUpdateStudentAttendance(this.students_list);
      if (response && response.success) {
        alert("Student Attendance stored successfully");
        this.absent_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
        this.onCloseClick();
        this.getReport();
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.student_save_loading = false;
    }
  }


  async allowReEntry(data) {
    try {
      const consent = confirm("Are you sure want to allow re-entry for this hour?");
      if (!consent) return;
      const response = await this.restService.allowReEntryRequest(data._id);
      if (response && response.success) {
        this.getReport();
        alert("Re-entry permitted succesfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
    }
  }
}

interface StaffReportInterface {
  _id?: string,
  day_date: string,
  day_order: string,
  hour_number: number,
  start_time?: string,
  end_time?: string,
  enrolled_year: number,
  course_code: string, course_name: string, part: string,
  programme_data: { programme_id?: string, section?: string, virtual_section?: string }[],
  is_marked: boolean,
  is_adjusted: boolean,
  semester?: number
  adjusted_for?: { staff_id: string, staff_name: string }
  adjusted_by?: { staff_id: string, staff_name: string }
}

interface StudentAttendanceInterface {
  course_code: string,
  date: string,
  day: string,
  hour_number: number,
  attendance_data: StudentDataInterface[]
}

interface StudentDataInterface {
  given_name: string,
  is_present: boolean,
  leave_applied: boolean,
  middle_name: string,
  od_given: boolean,
  roll_no: string
}