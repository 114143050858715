import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;

  // Supporting master data

  category_list: any[] = [];
  finance_types = [{ id: 'regular', text: 'AIDED' }, { id: 'self_finance', text: 'SELF FINANCE' }];




  // Core datas

  department_search_form: any;



  department_list: any[] = [];
  department_list_loading = false;

  department: any | undefined = undefined;
  department_save_loading = false;
  department_delete_loading = false;

  department_form: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getDepartmentCategoryList();

    // Build Filter Form
    this.buildFilterForm();
    // Load privamy data
    this.getFormDataList();
  }

  buildFilterForm(): void {
    this.department_search_form = this.fb.group({
      finance_type: [''],
      department_category_id: ['']
    });
  }


  async onAddClick(): Promise<void> {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(department: any): Promise<void> {
    this.department = department;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }


      this.department_save_loading = true;

      const department_data = this.department_form.value;

      if (!department_data.department_code) {
        alert('Please enter department code');
        return;
      }

      let save_respose;
      if (department_data._id) {
        // update record
        save_respose = await this.restService.updateDepartment(department_data.department_id, department_data);
      } else {
        // create record
        if (!department_data.department_code) {
          alert('Please enter department code');
          return;
        }
        if (!department_data.finance_type) {
          alert('Please enter department finance type');
          return;
        }
        if (!department_data.department_name) {
          alert('Please enter name');
          return;
        }
        if (!department_data.short_name) {
          alert('Please enter short name');
          return;
        }
        save_respose = await this.restService.createDepartment(department_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_save_loading = false;
    }
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      this.department_delete_loading = true;

      const categgory_data = this.department_form.value;
      if (!categgory_data.department_code) {
        alert('Please enter department_code');
      }


      const save_respose = await this.restService.deleteDepartmentById(categgory_data.department_code);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_delete_loading = false;
    }
  }




  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  async getFormDataList(): Promise<void> {
    try {
      this.prepareVariablesBeforeLoad();
      const search_form_query = this.department_search_form.value;
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        // fix data
        for (const data of service_response.data) {
          if (data.finance_type === 'self_finance') {
            data.finance_type_display = 'Self Finance';
          } else if (data.finance_type === 'regular') {
            data.finance_type_display = 'Aided';
          }
        }
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.prepareVariablesAfterLoad();
    }
  }


  prepareVariablesBeforeLoad(): void {
    this.department_list = [];
    this.department_list_loading = true;
  }
  prepareVariablesAfterLoad(): void {
    this.department_list_loading = false;
  }

  resetForm(): void {
    this.department_form = undefined;
    this.department = undefined;
  }
  buildForm(): void {

    if (!this.department) {
      this.department = {
        _id: '',
        department_id: '',
        finance_type: '',
        department_category_id: '',
        department_code: '',
        department_name: '',
        short_name: '',
        started_date: '',
        discontinued: false,
        discontinued_date: '',
        description: '',
      };
    }

    this.department_form = this.fb.group({
      _id: [this.department._id],
      department_id: [this.department.department_id],
      finance_type: [this.department.finance_type, [Validators.required]],
      department_category_id: [this.department.department_category_id, [Validators.required]],
      department_code: [this.department.department_code, [Validators.required]],
      department_name: [this.department.department_name, [Validators.required]],
      short_name: [this.department.short_name, [Validators.required]],
      started_date: [this.department.started_date],
      discontinued: [this.department.discontinued],
      discontinued_date: [this.department.discontinued_date],
      description: [this.department.description, [Validators.required]]
    });
  }



  /// master data

  async getDepartmentCategoryList(): Promise<void> {
    try {
      this.category_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }

  // Filter

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'finance_type') {
      await this.getFormDataList();
    } else if (id === 'department_category_id') {
      await this.getFormDataList();
    }
  }


}
