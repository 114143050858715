import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mypayments',
  templateUrl: './mypayments.component.html',
  styleUrls: ['./mypayments.component.scss']
})
export class MypaymentsComponent implements OnInit {
  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;


  payment_details: any[] = [];
  payment_details_loading = false;

  current_selection: any;
  hall_ticket_details: IHallTicket;

  constructor(private route: ActivatedRoute, private restService: AdminservicesService, private fb: FormBuilder) {
    JQueryHelper.toPageInit();
    this.getTransportRoutes();
  }

  ngOnInit(): void {
  }

  async getTransportRoutes(): Promise<void> {

    try {

      this.payment_details = [];
      this.payment_details_loading = true;
      const rest_response = await this.restService.getStudentPaymentList();
      if (rest_response.success && rest_response.data) {
        this.payment_details.push(...rest_response.data);
      }




      // const rest_response = await this.restService.getStudentPaymentList();
      // if (rest_response.success && rest_response.data) {

      //   for (const data of rest_response.data) {
      //     this.payment_details.push({
      //       "payment_setting_id": data._id,
      //       "sub": data.sub,
      //       "programme_name": data.programme_name,
      //       "programme_id": "",
      //       "purpose": "PROGRAMME",
      //       "term": data.name,
      //       "semester": "",
      //       "completed": data.status == "payment_done",
      //       "status": data.status != "payment_done" ? "FAILURE" : "SUCCESS",
      //       "amount": data.amount,
      //       "receipt_id": data.payment_response ? (data.payment_response.payment_response ? (data.payment_response.payment_response.transaction_id) : "") : "",
      //       "message": "",
      //       "verison": 2
      //     });
      //   }
      // }
    } catch (error) {

    } finally {
      this.payment_details_loading = false;
    }

  }


  async payCourseFee(id: string): Promise<void> {
    const payment_url = await this.restService.getCourseFeePaymentUrl(id);
    location.href = payment_url;
  }

  async payCourse2Fee(payment_object: any): Promise<void> {
    if (payment_object.fee_header_type == "EXAM_FEE") {
      if (payment_object.active == false) {
        alert("Your previous payment dues are not cleared, please clear that first to continue here")
        return;
      }
    }
    const payment_url = await this.restService.getStudentPaymentUrl(payment_object.fee_header_type, payment_object.payment_init_id);
    location.href = payment_url;

    // } else if (payment_object.fee_header_type == "PROGRAMME_FEE") {
    //   if (payment_object.version == 2) {
    //     this.payCourseFee(payment_object.payment_init_id)
    //   } else {
    //     const payment_url = await this.restService.getCourseFeePayment2Url(payment_object.payment_init_id);
    //     location.href = payment_url;
    //   }
    // }


  }

  async viewBreakupsClick(payment_object: any): Promise<void> {
    if (payment_object.fee_header_type == "EXAM_FEE") {
      this.current_selection = payment_object;
      JQueryHelper.openModal('#exam-fee-modal-popup', { keyboard: false, backdrop: 'static' });
    }
  }

  async onCloseBreakupsClick() {
    if (this.current_selection.fee_header_type == "EXAM_FEE") {
      JQueryHelper.closeModal('#exam-fee-modal-popup');
      this.current_selection = null;
    }
  }

  async viewHallTicket() {
    JQueryHelper.openModal('#hall-ticket-modal-popup', { keyboard: false, backdrop: 'static' });
    //api call?
    this.getStudentDetails();
  }

  getStudentDetails() {
    this.hall_ticket_details = {
      programme_type: "PG",
      term: "November 2021",
      name: "Abirami, A",
      roll_no: "20PA01",
      class: "II-M.C.A.",
      issue_date: "31 DECEMBER 2021",
      image_url: "https://lh3.googleusercontent.com/a-/AOh14GhyAHgKUKZf3MxGJYUOiccFouMhs3hMkP2wz6-d=s96-c",
      appearing_courses: [
        {
          appearance_type: "Regular",
          course_list: [
            {
              course_code: "20PAC11",
              course_name: "Python Programming",
              date_time: ["20.01.2022 FN"],
            },
            {
              course_code: "20PAC12",
              course_name: "Operating System",
              date_time: ["21.01.2022 FN"],
            },
            {
              course_code: "20PAC13",
              course_name: "Data Structures & Algorithms",
              date_time: ["22.01.2022 FN"],
            },
            {
              course_code: "20PAC14",
              course_name: "Python Programming Lab",
              date_time: ["23.01.2022 FN"],
            },
            {
              course_code: "20PAC15",
              course_name: "Comprehension & Viva Voce",
              date_time: ["24.01.2022 FN"],
            },
          ]
        },
        {
          appearance_type: "Arrear",
          course_list: [
            {
              course_code: "20PAC03",
              course_name: "Data Structrue Lab",
              date_time: ["27.01.2022 FN"],
            }
          ]
        }
      ]
    }
  }

  emptyCells(course_length: number): number[] {
    if (course_length <= 10) {
      var empty_cells = 10 - course_length;
      return new Array(empty_cells);
    }
    return [];
  }

  async closeHallTicketModal() {
    JQueryHelper.closeModal('#hall-ticket-modal-popup');
    this.hall_ticket_details = {} as IHallTicket;
  }

  viewTimeTable() {
    JQueryHelper.openModal('#time-table-modal-popup', { keyboard: false, backdrop: 'static' });
    //api call?
    this.getStudentDetails();
  }

  async closeTimeTableModal() {
    JQueryHelper.closeModal('#time-table-modal-popup');
    this.hall_ticket_details = {} as IHallTicket;
  }

  printHallTicket() {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>")
    mywindow.document.write('</head><style> #hall_ticket {' +
      'overflow: hidden; ' +
      '}' +
      '#background-img' +
      '{' +
      'position: absolute; ' +
      'z-index: 0; ' +
      'width: 100%; ' +
      'height: 100%; ' +
      '} ' +
      '#hall_ticket_content' +
      '{' +
      'position: relative; ' +
      'z-index: 1;' +
      '}</style><body >');
    mywindow.document.write("<div id='hall_ticket'>");
    mywindow.document.write(document.getElementById('hall_ticket').innerHTML);
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10


    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;

  }

}

interface IHallTicket {
  programme_type: string,
  term: string,
  name: string,
  roll_no: string,
  class: string,
  issue_date: string,
  image_url: string,
  appearing_courses: {
    appearance_type: string,
    course_list: {
      course_code: string,
      course_name: string,
      date_time: string[]
    }[] //multiple courses in same appearance type
  }[] //multiple appearance types like Regular, Arrear
}