import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';


@Component({
  selector: 'app-non-skillset-upload',
  templateUrl: './non-skillset-upload.component.html',
  styleUrls: ['./non-skillset-upload.component.scss']
})
export class NonSkillsetUploadComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Student Evidence Upload (OBE Non-Academic Attainment)';

  evidence_list_loading: boolean = false;
  evidence_list: IOBEStudentSkillSetUpload[] = [];
  evidence: IOBEStudentSkillSetUpload = {} as IOBEStudentSkillSetUpload;
  evidence_form: FormGroup = {} as FormGroup;
  evidence_id: string = "";
  skillset_list_loading: boolean = false;
  skillset_list: IOBENonAcademicSkillsetMaster[] = [];
  skills_list: IOBENonAcademicSkills[] = [];

  save_loading: boolean = false;
  delete_loading: boolean = false;
  cookieStore = CookieStore;


  //move
  move_form: FormGroup;
  move_data: StageMoveInterface = {} as StageMoveInterface;
  move_loading: boolean = false;

  ngOnInit(): void {
    this.getEvidenceList();
    this.getSkillSetList();
  }
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
  }


  async getSkillSetList() {
    try {
      this.skillset_list_loading = true;
      this.skillset_list = [];
      const service_response = await this.restService.getSkillSetList();
      if (service_response && service_response.success) {
        this.skillset_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.skillset_list_loading = false;
    }

  }

  async getEvidenceList() {
    try {
      this.evidence_list_loading = true;
      this.evidence_list = [];
      const service_response = await this.restService.getSkillsetEvidenceList({});
      if (service_response && service_response.success) {
        this.evidence_list = service_response.data;
      }
      /* this.evidence_list = [{
        semester: 2,
        evidence_url: "",
        skillset_id: "a4532611-78cf-4fcd-a555-883b53902501",
        skill: "Appearing in Competitive Examinations",
        description: "Participated in Story Telling Competition and won 1st Place held at ANJAC",
        date: "2021-10-31T00:00",
        place: "Sivakasi",
      }
      ] */
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.evidence_list_loading = false;
    }

  }


  buildForm(): void {
    if (JSON.stringify(this.evidence) == '{}') {
      this.evidence = {
        _id: "",
        semester: 0,
        evidence_url: "",
        skillset_id: "",
        skill: "",
        description: "",
        date: new Date().toISOString(),
        place: "",
      };
    }
    this.evidence_form = this.formBuilder.group({
      _id: [this.evidence._id],
      semester: [this.evidence.semester, [Validators.required]],
      evidence_url: [this.evidence.evidence_url, [Validators.required]],
      description: [this.evidence.description, [Validators.required]],
      skillset_id: [this.evidence.skillset_id],
      skill: [this.evidence.skill],
      date: [this.evidence.date],
      place: [this.evidence.place]
    });

    if (this.evidence.skillset_id) {
      this.skills_list = this.skillset_list.find(x => x._id == this.evidence.skillset_id)?.skills;
    }
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditClick(evidence: IOBEStudentSkillSetUpload) {
    this.evidence = evidence;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.skills_list = [];
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.evidence_id = "";
    this.evidence = {} as IOBEStudentSkillSetUpload;
    this.evidence_form.reset();
  }

  filterSkillsetList(ev) {
    this.skills_list = [];
    this.evidence_form.get('skill').setValue("");
    var select_element_id: string = (ev.target as HTMLSelectElement).value;
    this.skills_list = this.skillset_list.find(x => x._id == select_element_id)?.skills;
    console.log(this.skills_list);
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.evidence_form.value;
      console.log(form_data);
      this.save_loading = true;
      if (this.evidence_form.value._id) {
        const res = await this.restService.updateSkillsetEvidence(this.evidence_form.value._id, form_data);
        if (res) {
          alert("Evidence updated successfully.");
        }
      }
      else {
        const res = await this.restService.uploadSkillsetEvidence(form_data);
        if (res) {
          alert("Evidence added successfully.");
        }
      }
      this.getEvidenceList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteSkillset(this.evidence_form.get('_id')!.value);
      if (res.success) {
        alert("Skillset removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.evidence_form.touched && this.evidence_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.evidence_form.status !== 'VALID';
  }

  async uploadFile(e: any) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadEvidenceFile(formData);
      if (response.success && response.data) {
        alert("File Uploaded Successfully");
        (this.evidence_form.get('evidence_url').setValue(response.data.url));
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }



  buildMoveForm(): void {

    this.move_form = this.formBuilder.group({
      stage: ["PRINCIPAL", [Validators.required]],
      remarks: ["", [Validators.required]]
    });
  }

  async onMoveClick(evidence?: IOBEStudentSkillSetUpload) {
    this.move_data = {
      _id: evidence._id,
      roll_no: evidence.roll_no,
      sub: evidence.sub,
    }
    this.buildMoveForm();
    JQueryHelper.openModal('#modal-move', { keyboard: false, backdrop: 'static' });
  }

  async moveToClick() {
    try {
      var move_data_arr: StageMoveInterface[] = [];
      this.move_loading = true;
      this.move_data.stage = this.move_form.value.stage;
      this.move_data.remarks = this.move_form.value.remarks;
      console.log(this.move_data);
      move_data_arr.push(this.move_data);
      const service_response = await this.restService.studentEvidenceUpdate(move_data_arr);
      if (service_response && service_response.success) {
        this.getEvidenceList();
        alert('Grievance Request submitted Successfully');
      }
      else {
        alert("Error! Could not make request");
      }
      this.getEvidenceList();
      this.closeMove();
    }
    catch (error) {
      console.log(error);
      CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save);
    }
    finally {
      this.move_loading = false;
    }
  }

  closeMove() {
    this.evidence = {} as IOBEStudentSkillSetUpload;
    this.move_form.reset();
    this.move_form.get('stage').setValue("");
    this.move_form = undefined;
    JQueryHelper.closeModal('#modal-move');
  }

}

interface IOBEStudentSkillSetUpload {
  _id?: string,
  semester: number,
  evidence_url: string,
  skillset_id: string,
  skill: string,
  description: string,
  date: string,
  place: string,
  sub?: string,
  roll_no?: string,
  point?: number
}


interface IOBENonAcademicSkillsetMaster {
  _id?: string,
  skillset_name: string;
  skills: IOBENonAcademicSkills[];
  max_point: number;
}


interface IOBENonAcademicSkills {
  skill_name: string
  max_point: number;
}

interface StageMoveInterface {
  _id: string,
  roll_no: string,
  sub: string,
  stage?: string,
  remarks?: string
}