<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <div class="row row-sm">
        <iframe [src]="getURL()" frameBorder="0"></iframe>
      </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>