import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-assign-elective-students',
  templateUrl: './assign-elective-students.component.html',
  styleUrls: ['./assign-elective-students.component.scss']
})
export class AssignElectiveStudentsComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  search_form: any;
  search_btn_loading: boolean = false;
  unselected_student_list: IUnSelectedStudent[] = [];
  student_list_loading: boolean = false;
  available_elective_courses_list: IElectiveCourse[] = [];

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_section_list: any[] = [];
  course_list_loading: boolean = false;
  assign_roll_no: string = "";
  assign_course_code: string = "";
  assign_save_loading: boolean = false;


  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildFilterForm();
  }
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  buildFilterForm(): void {
    this.search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section_id: [''],
      enrolled_year: [""],
    });
  }

  async getStudentList() {
    if (!this.search_form.value.programme_type_id) {
      alert("Please choose a Programme Type"); return;
    }
    if (!this.search_form.value.finance_type) {
      alert("Please choose a Finance Type"); return;
    }
    if (!this.search_form.value.programme_id) {
      alert("Please choose a Programme Name"); return;
    }
    if (!this.search_form.value.enrolled_year) {
      alert("Please choose an Enrolment Year"); return;
    }
    this.student_list_loading = true;
    this.unselected_student_list = [];
    this.unselected_student_list.push({
      roll_no: "20UL01", name: "AAA", class: "II BCA 'A'"
    },
      {
        roll_no: "20UL02", name: "BBB", class: "II BCA 'A'"
      },
      {
        roll_no: "20UL03", name: "CCC", class: "II BCA 'A'"
      }
    );
    this.student_list_loading = false;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    } else if (id === 'programme_id') {
      this.programme_section_list = [];
      const programme = this.programme_list.find(c => c.programme_id === this.search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.programme_section_list = programme.section_details;
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      if (this.search_form.value.programme_type_id && this.search_form.value.finance_type) {

        this.programme_list_loading = true;
        this.programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.search_form) {
          if (this.search_form.value.programme_type_id) {
            search_form_query.programme_type_id = this.search_form.value.programme_type_id;
          }
          if (this.search_form.value.finance_type) {
            search_form_query.finance_type = this.search_form.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.programme_list = service_response.data;
        }
        console.log(this.programme_list);
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async assignCourse(roll_no: string) {
    this.assign_roll_no = roll_no;
    this.course_list_loading = true;
    this.available_elective_courses_list = [];
    JQueryHelper.openModal('#assign-modal-popup', { keyboard: false, backdrop: 'static' });
    this.available_elective_courses_list.push({
      course_code: "M5E02", course_name: "Applications of Calc.", offering_programme_id: "1", offering_programme_name: "Mathematics"
    },
      {
        course_code: "P5E02", course_name: "Comm. Physics", offering_programme_id: "2", offering_programme_name: "Physics"
      },
      {
        course_code: "C5E02", course_name: "Industrial Chemistry", offering_programme_id: "3", offering_programme_name: "Chemistry"
      },
      {
        course_code: "S5E02", course_name: "Python Programming", offering_programme_id: "4", offering_programme_name: "Computer Science (R)"
      },
      {
        course_code: "I5E02", course_name: "Web Programming", offering_programme_id: "5", offering_programme_name: "Computer Science (SF)"
      }
    )
    this.course_list_loading = false;
  }

  async onCloseClick() {
    JQueryHelper.closeModal('#assign-modal-popup');
  }

  async assignCourseToStudent(ev: Event) {
    var value = (ev.target as HTMLInputElement).value; //course_code

  }

  async saveAssignClick() {
    this.assign_save_loading = true;
    console.log(this.assign_course_code); console.log(this.assign_roll_no);
    this.assign_save_loading = false;
    this.onCloseClick();
    //pass course_code and assign_roll_no to api
    //refresh list call
  }

}
interface IElectiveCourse {
  course_code: string; course_name: string; offering_programme_id: string, offering_programme_name: string;
}

interface IUnSelectedStudent {
  roll_no: string, name: string, class: string;
}