<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Quiz Questions</p>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-md btn-info">Preview Quiz</button>
                            </div>
                        </div>
                        <form name="common_settings">
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Total No. of questions added: <b>{{total_questions}}</b></p>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Mark for all questions</label>
                                        <input type="number" class="form-control" name="common_question_mark"
                                            placeholder="Enter Mark for all questions"
                                            [(ngModel)]="common_question_mark" (change)="setCommonQuestionMark()">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Negative Mark for all questions</label>
                                        <input type="number" class="form-control" name="common_negative_mark"
                                            placeholder="Enter Negative Mark for all questions"
                                            [(ngModel)]="common_negative_mark" (change)="setCommonNegativeMark()">
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="question_form">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 85%;">Question</th>
                                            <th style="width: 15%;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="question_form">
                                        <ng-container formArrayName="questions">
                                            <tr *ngFor="let question_header of questionsHeader; let i = index;"
                                                [formGroupName]="i">
                                                <td>
                                                    <div class="row mg-t-10">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label
                                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                    Question Category</label>
                                                                <select formControlName="question_type_header"
                                                                    (change)="questionTypeHeaderChanged($event,i)"
                                                                    class="form-control">
                                                                    <option
                                                                        *ngFor="let header of question_header_types; let in = index;"
                                                                        [value]="header.id">
                                                                        {{header.text}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mg-t-10"
                                                        [ngClass]="question_header.value.question_type_header == 'COMP'? 'inner-cell': '' "
                                                        *ngIf="question_header.value.question_type_header == 'COMP' ">
                                                        <div class="col-md-10">
                                                            <div
                                                                *ngIf="!question_header?.value?.section_common_question">
                                                                ---
                                                            </div>
                                                            <div *ngIf="question_header?.value?.section_common_question"
                                                                [innerHTML]="question_header.value.section_common_question">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <button
                                                                (click)="openEditor(question_header, 'section_common_question')"
                                                                class="btn btn-info btn-sm mg-t-10">Add
                                                                Passage Text</button>
                                                            <div class="form-group">
                                                                <label
                                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Upload
                                                                    Common
                                                                    Image</label>
                                                                <input class="form-control" type="file"
                                                                    (change)="uploadImage($event,question_header,'section_common_image')"
                                                                    accept="image/*">
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <ng-container class="mg-t-10">
                                                        <ng-container formArrayName="question_data">
                                                            <div class="inner-cell"
                                                                *ngFor="let question of getQuestionsList(i); let j = index;"
                                                                [formGroupName]="j">
                                                                <div class="row mg-t-5">
                                                                    <div class="col-md-9">
                                                                        <p>
                                                                            <span
                                                                                *ngIf="question_header.value.question_type_header == 'COMP'">Comprehension
                                                                            </span>Question No:
                                                                            {{question.value.question_number}}
                                                                        </p>
                                                                    </div>
                                                                    <div class="col-md-3">
                                                                        <button class="btn btn-danger btn-sm"
                                                                            (click)="deleteQuestion(i,j)">Delete
                                                                            Question</button>
                                                                    </div>
                                                                </div>
                                                                <div class="row inner-cell mg-t-10 mg-b-5">
                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label
                                                                                class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                                CO Level</label>
                                                                            <select class="form-control"
                                                                                formControlName="co_id"
                                                                                (change)="coChanged($event,i,j,question)">
                                                                                <option value="0">Choose CO Level
                                                                                </option>
                                                                                <option *ngFor="let co of co_k_map"
                                                                                    [value]="co.co_id">
                                                                                    {{co.co_id}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label
                                                                                class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                                K Level</label>
                                                                            <ng-container
                                                                                *ngIf="getKLevels(i,j,question).length>1">
                                                                                <select class="form-control"
                                                                                    formControlName="k_id">
                                                                                    <option value="0">Choose K Level
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let k of getKLevels(i,j,question)"
                                                                                        [value]="k">
                                                                                        {{k}}
                                                                                    </option>
                                                                                </select>
                                                                            </ng-container>
                                                                            <p class="mg-t-15"
                                                                                *ngIf="getKLevels(i,j,question).length==1">
                                                                                K{{getKLevels(i,j,question)[0]}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <label
                                                                                class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                                Question Type</label>
                                                                            <select class="form-control"
                                                                                formControlName="question_type"
                                                                                (change)="questionTypeChanged($event,i,j,question)">
                                                                                <option value="">Choose Question Type
                                                                                </option>
                                                                                <option
                                                                                    *ngFor="let type of question_types"
                                                                                    [value]="type.id">
                                                                                    {{type.text}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <label
                                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                                            Question Marks</label>
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Enter Mark"
                                                                            formControlName="question_mark"><br>
                                                                        <label
                                                                            class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                                            Negative Marks, if any</label>
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Enter Negative Mark, if any"
                                                                            formControlName="negative_mark">
                                                                    </div>
                                                                </div>
                                                                <div class="row inner-cell mg-t-10 mg-b-5">
                                                                    <div class="col-md-10">
                                                                        <p>
                                                                            Question Text:
                                                                        </p>
                                                                        <div *ngIf="!question?.value?.question_text">
                                                                            ---
                                                                        </div>
                                                                        <div *ngIf="question?.value?.question_text"
                                                                            [innerHTML]="question?.value?.question_text">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <button
                                                                            (click)="openEditor(question, 'question_text')"
                                                                            class="btn btn-info btn-sm mg-t-10">Add
                                                                            Question Text</button>
                                                                        <div class="form-group">
                                                                            <label
                                                                                class="main-content-label tx-11 tx-medium tx-gray-600">Upload
                                                                                Question Image</label>
                                                                            <input class="form-control" type="file"
                                                                                (change)="uploadImage($event,question,'question_url')"
                                                                                accept="image/*">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ng-container formArrayName="options">
                                                                    <ng-container
                                                                        *ngFor="let option of getOptionsList(i,j); let k = index;"
                                                                        [formGroupName]="k">
                                                                        <div class="row inner-cell mg-t-5 mg-b-5">
                                                                            <div class="col-md-10">
                                                                                <p>
                                                                                    <span
                                                                                        *ngIf="question.value.question_type == 'MCQ' ">Option:</span>
                                                                                    <span
                                                                                        *ngIf="question.value.question_type == 'BLANK' ">Answer:</span>
                                                                                    {{getOptionString(k+1)}}):
                                                                                </p>
                                                                                <div
                                                                                    *ngIf="!option?.value?.option_text">
                                                                                    ---
                                                                                </div>
                                                                                <div *ngIf="option?.value?.option_text"
                                                                                    [innerHTML]="option?.value?.option_text">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <button
                                                                                    (click)="openEditor(option, 'option_text')"
                                                                                    class="btn btn-info btn-sm mg-t-10">
                                                                                    <ng-container
                                                                                        *ngIf="question.value.question_type == 'MCQ' ">
                                                                                        Add Option Text
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="question.value.question_type == 'BLANK' ">
                                                                                        Add Answer Text
                                                                                    </ng-container>
                                                                                </button>
                                                                                <div class="form-group"
                                                                                    *ngIf="question.value.question_type == 'MCQ'">
                                                                                    <label
                                                                                        class="main-content-label tx-11 tx-medium tx-gray-600">Upload
                                                                                        Option Image</label>
                                                                                    <input class="form-control"
                                                                                        type="file"
                                                                                        (change)="uploadImage($event,option,'option_text')"
                                                                                        accept="image/*">
                                                                                </div>
                                                                                <!-- <div class="form-group"
                                                                                    *ngIf="question.value.question_type == 'MCQ'">
                                                                                    <label
                                                                                        class="main-content-label tx-11 tx-medium tx-gray-600 mg-r-5"><b>Is
                                                                                            Correct Answer</b></label>
                                                                                    <input type="radio"
                                                                                        name="option_{{i}}_{{j}}"
                                                                                        [value]="k"
                                                                                        id="option_{{i}}_{{j}}_{{k}}"
                                                                                        formControlName="is_correct">
                                                                                </div> -->
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <div class="row">
                                                                        <div class="col-md-12 text-center">
                                                                            <button class="btn btn-sm btn-info"
                                                                                (click)="addOption(i,j)">
                                                                                <ng-container
                                                                                    *ngIf="question.value.question_type == 'MCQ' ">
                                                                                    Add Option
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="question.value.question_type == 'BLANK' ">
                                                                                    Add Answer
                                                                                </ng-container>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <div class="row inner-cell mg-t-5 mg-b-5"
                                                                    *ngIf="question.value.question_type == 'MCQ' ">
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <label
                                                                                class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                                                Correct Answer</label><br>
                                                                            <select class="form-control"
                                                                                formControlName="correct_answers">
                                                                                <option value=''>Choose Answer
                                                                                    Option</option>
                                                                                <option
                                                                                    *ngFor="let option of getOptionsChooseList(i,j)"
                                                                                    [value]="option.id">
                                                                                    Option {{option.text}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ng-container
                                                                *ngIf="question_header.value.question_type_header == 'COMP' ">
                                                                <div class="row">
                                                                    <div class="col-md-12 text-center">
                                                                        <button class="btn btn-md btn-info"
                                                                            (click)="addQuestion(i)">Add
                                                                            Comprehension Question</button>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-danger"
                                                        (click)="deleteQuestionHeader(i)"
                                                        *ngIf="question_header.value.question_type_header == 'COMP' ">Delete</button>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td colspan="2" class="text-center">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="addQuestionHeader()">Add
                                                        Question</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-success btn-md" (click)="onSaveClick()">Save Quiz</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--Editor-->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Add Question</h6>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="card card-body pd-20 pd-md-40">
                                <form class="form-horizontal">
                                    <kendo-editor #editors class="question" [(ngModel)]="question_editor" name="editor"
                                        style="height: 300px;">
                                        <kendo-toolbar>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorUndoButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorRedoButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorBoldButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorItalicButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnderlineButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorSubscriptButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorSuperscriptButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignRightButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-dropdownlist kendoEditorFormat>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontSize>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontFamily>
                                            </kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-colorpicker kendoEditorForeColor>
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient">
                                            </kendo-toolbar-colorpicker>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorIndentButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorOutdentButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnlinkButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-editor-insert-table-button>
                                            </kendo-editor-insert-table-button>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddColumnAfterButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowBeforeButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAddRowAfterButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorDeleteColumnButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteRowButton>
                                                </kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorDeleteTableButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-button kendoEditorInsertFileButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button kendoEditorViewSourceButton>
                                            </kendo-toolbar-button>
                                            <kendo-toolbar-button kendoEditorCleanFormattingButton>
                                            </kendo-toolbar-button>
                                            <!-- <kendo-toolbar-button text="Upload Image"
                                                    (click)="openUploadImage($event,'question')">
                                                </kendo-toolbar-button> -->
                                        </kendo-toolbar>
                                    </kendo-editor>
                                </form>
                            </div>
                        </form>
                        <div class="text-center">
                            <button class="btn ripple btn-primary mg-r-5" (click)="saveQuestion()" type="button"
                                *ngIf="!question_save_loading" [disabled]="isDisabled()">Save</button>
                            <button class="btn ripple btn-primary mg-r-5" type="button" [disabled]
                                *ngIf="question_save_loading">Saving...</button>
                            <button class="btn ripple btn-info mg-r-5" (click)="saveAndCloseQuestion()" type="button"
                                [disabled]="isDisabled()">Save & Close</button>
                            <button class="btn ripple btn-secondary" (click)="closeQuestion()"
                                type="button">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--End Editor-->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>