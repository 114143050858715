import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
var XLSX = require("xlsx");

@Component({
  selector: 'app-co-attainment-report',
  templateUrl: './co-attainment-report.component.html',
  styleUrls: ['./co-attainment-report.component.scss']
})
export class CoAttainmentReportComponent implements OnInit {

  title = 'CO Attainment';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;

  table_data_loading: boolean = false;
  active_accordion: number = 1;
  report_data: COAttainmentReport = {} as COAttainmentReport;
  report_data_loading: boolean = false;
  sections: any[] = [];
  co_levels: string[] = ['CO1', 'CO2', 'CO3', 'CO4', 'CO5'];

  display_card: boolean = false;


  constructor(private formBuilder: FormBuilder, private restService: AdminservicesService) {  }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }

  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section: [''],
      enrolled_year: [''],
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    this.display_card = false;
    if (id == "programme_id") {
      this.sections = [];
      this.filter_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
      if (this.sections.length == 1) {
        this.filter_form.get('section').setValue(this.sections[0].section_name);
      }
    }

    if (id === 'programme_type_id' || id === 'finance_type') {
      this.filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.filter_form);
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick() {
    if (!this.filter_form.value.programme_type_id) {
      alert("Please choose Programme Type"); return;
    }
    if (!this.filter_form.value.finance_type) {
      alert("Please choose Finance Type"); return;
    }
    if (!this.filter_form.value.programme_id) {
      alert("Please choose Programme Name"); return;
    }
    if (this.sections.length > 0 && !this.filter_form.value.section) {
      alert("Please choose section"); return;
    }
    if (!this.filter_form.value.enrolled_year) {
      alert("Please choose Enrolled Year"); return;
    }
    this.table_data_loading = true;
    try {
      this.display_card = true;
      this.report_data = {} as COAttainmentReport;
      const report_search_obj = {
        "offering_programme_id": this.filter_form.value.programme_id,
        "offered_by_programme_type_id": this.filter_form.value.programme_type_id,
        "enrolled_year": this.filter_form.value.enrolled_year,
      };

      const response = await this.restService.generateCOAttainmentReport(report_search_obj);
      if (response.success && response.data) {
        this.report_data = response.data
      }
    }
    catch (error) {
      console.log(error);
      this.table_data_loading = false;
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
    }
  }

  canDisplayReport() {
    if (!this.report_data.programme_name) {
      return false;
    }
    if (!this.report_data.part_wise_data && this.report_data.part_wise_data.length == 0) {
      return false;
    }
    return true;
  }

  getEmptyRows(length) {
    return new Array(length).fill(0);
  }

  downloadExcel() {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("co-attainment-report-table");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, `${this.report_data.programme_name}_CO_ATTAINMENT_REPORT.xlsx`);
  }

}


interface COMarks {
  co1: number | string,
  co2: number | string,
  co3: number | string,
  co4: number | string,
  co5: number | string,
  total: number | string
}

interface CouseWiseData {
  course_code: string;
  course_name: string;
  class_average: COMarks;
  total_weightage: COMarks;
}

interface SemesterWiseData {
  semester: number;
  course_wise_data: CouseWiseData[];
}

interface PartWiseData {
  part: string;
  semester_wise_data: SemesterWiseData[];
  total_cos_marks: COMarks;
  total_class_average_marks: COMarks;
  co_marks_level: COMarks;
  grade_of_cos: COMarks;
}

interface COAttainmentReport {
  _id?:string;
  id?:string;

  //user input
  class_taking_programme_id: string;
  class_taking_section: string;
  enrolled_year: number;

  //results
  programme_name: string;
  part_wise_data: PartWiseData[];
  grand_total_cos_marks: COMarks;
  grand_total_class_average: COMarks;
  final_co_level_marks: COMarks;
  final_cos_grade: COMarks;
}
