import { Component, OnInit } from '@angular/core';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';

@Component({
  selector: 'app-fee-collection-settings',
  templateUrl: './fee-collection-settings.component.html',
  styleUrls: ['./fee-collection-settings.component.scss']
})
export class FeeCollectionSettingsComponent implements OnInit {

  constructor() {
    JQueryHelper.toPageInit();
  }
  ngOnInit(): void {

  }
}
