import { MenuItems } from '../menu-item';
import { NavigationService } from '../../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../../components/notification/notification.service';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
@Component({
  selector: 'app-general-menu',
  templateUrl: './general-menu.component.html',
  styleUrls: ['./general-menu.component.scss']
})
export class GeneralMenuComponent implements OnInit {

  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  generalMenuForm: FormGroup; //overall form
  year:number;
  index:number =0; mode:string = ''; column:number = 0;
  food_time = ["FN", "AN","Dinner"];
  food_type = ["Veg", "Non Veg"];
  months: {
    month_name:string,
    month_id: string
  } [] = [
    {month_name:"January", month_id:"01"},{month_name:"February", month_id:"02"},{month_name:"March", month_id:"03"},
    {month_name:"April", month_id:"04"},{month_name:"May", month_id:"05"},{month_name:"June", month_id:"06"},
    {month_name:"July", month_id:"07"},{month_name:"August", month_id:"08"},{month_name:"September", month_id:"09"},
    {month_name:"October", month_id:"10"},{month_name:"November", month_id:"11"},{month_name:"December", month_id:"12"}, 
  ];
  menuItems: MenuItems[] = [] as MenuItems[];

  //initializers
    constructor(private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.generalMenuForm= this.formBuilder.group({
        month: ['', Validators.required],
        menu_list: this.formBuilder.array([
          /* this.formBuilder.group({
            "date":[''],
            "day": [''],
            "menu": this.formBuilder.array([
              formBuilder.group({
                food_time: [''],
                food_menu: this.formBuilder.array([
                  this.formBuilder.group({
                    food_type:[''],
                    food_list:[]
                  })
                ])
              })
            ])
          }) */
        ])
      });
      
      this.year = new Date().getFullYear();
    }
    onAdd(): void {
      this.router.navigate([`admin/hostel/menu-item`]);
    }
    ngOnInit(): void {
      this.restService.initializeServices();
      this.notificationService.setNotifcation({});
      this.navigationService.setNavigation([{label: 'General Menu', url: 'admin/hostel/general-menu' }]);
      this.getMenuItems();
    }

    //month generation
    chooseMonth():void
    {
      (this.generalMenuForm.controls['menu_list'] as FormArray).clear();
      this.getDaysInMonth(parseInt(this.generalMenuForm.controls['month'].value)-1, this.year).forEach(element=>{
          this.addMenuGroup({
            date:this.getDate(element.getDate()),
            day:this.getDay(element.getDay()),
            menu: []
          });
      });
      console.log(this.generalMenuForm);
    }
    getDaysInMonth(month:number, year:number) {
      var date = new Date(year, month, 1);
      var days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        if(!isNaN(date.getTime()))
        {
          date.setDate(date.getDate() + 1);
        }
        else
        {
          break;
        }
      }
      return days;
    }

    //form
    get aliasesArrayControl() {
      return (this.generalMenuForm.get('menu_list') as FormArray).controls;
    }
    createMenuList(defaultObject:any): FormGroup
    {
      var menu_list= this.formBuilder.group ({
        "date":[defaultObject.date],
        "day": [defaultObject.day],
        "menu": this.formBuilder.array([])
      });
      var menu = menu_list.get('menu') as FormArray;
      var menu_group:FormGroup;
      var food_menu:FormArray;
      var food_group:FormGroup;
      for(var i=0; i<this.food_time.length; i++)
      {
        
        menu_group = this.formBuilder.group({
          food_time:[this.food_time[i]],
          food_menu: this.formBuilder.array([])
        });
        food_menu = menu_group.get('food_menu') as FormArray;
        for(var j=0; j<this.food_type.length;j++)
        {
          food_group = this.formBuilder.group({
            food_type: [this.food_type[j]],
            food_list: [''] //replace data from service
          });
          food_menu.push(food_group);
        }
        menu.push(menu_group);
      }
      return menu_list;
    }
    public addMenuGroup(defaultObject:any) {
      const menu_list = this.generalMenuForm.get('menu_list') as FormArray;
      menu_list.push(this.createMenuList(defaultObject))
    }
    async getMenuItems(): Promise<void> {
      try {
        this.menuItems = await this.restService.menuItemService.getMenuItems();
        console.log(this.menuItems);
      } catch(error) {
        this.notificationService.setNotifcation({ message: 'Failed to load menu items.', type: 'Failed' });
      }
    }
    getItems(): MenuItems[] {
      return this.menuItems;
    }

    
    //date
    getDate(date:number):string
    {
        return (date>9?date:"0"+date)+"/"+this.generalMenuForm.controls['month'].value+"/"+this.year;
    }
    getDay(day:number):string
    {
      var day_string = ["Sun", "Mon","Tues","Wed","Thurs","Fri","Sat"];
      return day_string[day];
    }
    getSelectedDate()
    { 
      const menu_list = this.generalMenuForm.get('menu_list') as FormArray;
      //console.log(this.index);
      return this.getDate(menu_list.at(this.index).value.date) + " (" + this.getDay(menu_list.at(this.index).value.day)+")";
    }
  
    //save
    onSave():void
    {
      this.generalMenuForm.get('month')?.setValue(this.generalMenuForm.get('month')?.value + "/"+ this.year);
    console.log(this.generalMenuForm.value);
    }

  
  setNonVeg(ev:any,i:number,menu:string)
  {
    var item = this.generalMenuForm.controls['menu_list'] as FormArray
    var patch;
    if(ev.checked==true)
    {
      var copy = item.at(i).value[menu.substr(1)]
      patch={[menu]:copy};
      item.at(i).patchValue(patch);
    }
    else
    {
      patch={[menu]:""};
      item.at(i).patchValue(patch);
    }
    
  }
}
