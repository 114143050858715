import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
@Component({
  selector: 'app-hostel-fee-setting',
  templateUrl: './hostel-fee-setting.component.html',
  styleUrls: ['./hostel-fee-setting.component.scss']
})
export class HostelFeeSettingComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Hostel Students Fees List';
  student_fee_list_loading: boolean = false;
  student_fee_list: SettingInterface[] = [];
  setting: SettingInterface = {} as SettingInterface;
  programme_list: any[] = [];
  student_fee_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  search_btn_pressed: boolean = false;
  datechange: boolean = false;
  file_upload: any;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  csvRecords: any[] = [];
  header = true;
  required_columns: string[] = [];
  invalid_csv: boolean = true;
  file: any;
  csvRecords_loading: boolean = false;
  ngOnInit(): void {
    /* this.getProgrammeDataList();
    this.getProgrammeTypeList(); */
    this.buildForm();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private ngxCsvParser: NgxCsvParser) {
    this.getFeesSettingsList();
  }

  async getFeesSettingsList() {
    this.student_fee_list = [];
    this.student_fee_list_loading = true;
    try {
      /* const service_response = await this.restService.getHostelFeesHeaderList({});
      if (service_response && service_response.success) {
        this.student_fee_list = service_response.data;
        console.log(this.student_fee_list);
      } */
    } catch (error) {
      alert('Error while read data');
    }
    this.student_fee_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        roll_no: "",
        amount: 0,
        description: "",
      };
    }
    this.student_fee_form = this.formBuilder.group({
      _id: [this.setting._id],
      roll_no: [this.setting.roll_no, [Validators.required]],
      description: [this.setting.description, [Validators.required]],
      amount: [this.setting.amount, [Validators.required]],
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    this.setting_id = setting._id; //term_name
    var settings = this.student_fee_list.find(x => x._id == setting._id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as SettingInterface;
    this.student_fee_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.student_fee_form.value;
      console.log(form_data);
      if (this.student_fee_form.value.id) {
        const res = await this.restService.updateHostelFeesHeader(this.student_fee_form.value.id, form_data);
        if (!res) {
          alert('Failed to Update Hostel Fee settings');
        }
        alert('Updated Hostel Fee Settings Successfully');
      }
      else {
        const res = await this.restService.createHostelFeesHeader(form_data);
        if (!res) {
          alert('Failed to Create Hostel Fee settings');
        }
        alert('Created Hostel Fee Settings Successfully');
      }
      this.getFeesSettingsList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    const res = await this.restService.deleteHostelFeeHeader(this.student_fee_form.get('id')!.value);
    if (res.success) {
      alert('Deleted Hostel Fee Settings Successfully');
      this.getFeesSettingsList();
    }
    else {
      alert('Failed to Delete Hostel Fee settings');
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.student_fee_form.touched && this.student_fee_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.student_fee_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  navigateToHostelFee(setting) {
    this.router.navigate(["/admin/programmes/hostel-fee-setting"], {
      queryParams: {
        setting_id: setting.id,
      }
    }); //pass setting.id
  }


  fileChangeListener($event: any): void {
    // Select the files from the event
    var missed_fields: string[] = [];
    this.csvRecords_loading = true;
    this.csvRecords = [];
    var invalid_csv: boolean = false;
    var files = $event.srcElement.files;
    var required_columns = ["Roll No", "Amount"];
    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: any[] | NgxCSVParserError) => {
        console.log('Result', result);
        this.required_columns.forEach(column => {
          if (Object.keys(result[0]).indexOf(column) < 0) //key missing
          {
            missed_fields.push(column);
            invalid_csv = true;
          }
          else {
            if ((result as any[]).find(x => x[column] == "")) {
              missed_fields.push(column);
              invalid_csv = true;
            }
          }
        });
        this.invalid_csv = invalid_csv;
        if (this.invalid_csv) {
          alert("Given CSV is not valid; Required Field(s) " + missed_fields + " missing; Please check the uploaded file");
        }
        else {
          this.csvRecords = result as any[];
          this.file = files[0];
          console.log(this.file);
          console.log(this.file_upload);
          alert("Given CSV File is valid. Click the upload button");
        }
        missed_fields = [];
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
    //$event.target.value = "";
    this.invalid_csv = true;
    this.csvRecords_loading = false;
    JQueryHelper.openModal('#csv-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onUpload() {

  }

  closeCSVModal() {
    JQueryHelper.closeModal('#csv-modal-popup');
  }
}

interface SettingInterface {
  _id: string,
  roll_no: string
  amount: number,
  description: string,
}
