<div class="mg-l-20 mg-b-15">
    <div class="row" *ngIf="render_data.roll_no==undefined">
        <div class="col-md-2">
            <div class="form-group">
                <input class="form-control" placeholder="Enter Student Roll No." required="" type="text"
                    [(ngModel)]="search_rno">
            </div>
        </div>
        <div class="col-md-10">
            <ng-container *ngIf="!payment_details_loading">
                <button class="btn btn-info mg-r-20" (click)="getStudentPaymentData()">Get Payment
                    History</button>
            </ng-container>
            <ng-container *ngIf="payment_details_loading">
                <button class="btn btn-info mg-r-20" disabled> <span class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span>
                    Loading...</button>
            </ng-container>
        </div>
    </div>
    <b *ngIf="payment_details?.length>0">Payment History</b>
    <div class="row" *ngIf="payment_details?.length>0 && render_data.mode=='stand_alone'">
        <div class="col-md-6">
            Student Name: <b>{{offline_student_data?.given_name}}</b>
        </div>
        <div class="col-md-6">
            Class: <b>{{offline_student_data?.class_name}}</b>
        </div>
    </div>

    <div class="table-responsive" *ngIf="payment_details?.length>0">
        <table class="table table-bordered mg-b-0 text-md-nowrap">
            <thead>
                <tr>
                    <th>S.No</th>
                    <!-- <th>Programe Type</th> -->
                    <th>Programe Name</th>
                    <th>Fees Type</th>
                    <th>Term Name</th>
                    <!-- <th>Semester</th> -->
                    <!-- <th>Installment</th> -->
                    <th>Due Date</th>
                    <!-- <th>Started Date</th> -->
                    <th>Amount</th>
                    <!-- <th>Fine</th> -->

                    <th>Status</th>
                    <th style="width: 15%;">Action</th>
                    <!-- <th>Transaction Reference(s)</th> -->

                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="payment_details && payment_details.length>0">
                    <ng-container
                        *ngFor="let payment_detail of payment_details;let i=index;trackBy:fromDataResolver.identify;">
                        <tr>
                            <th scope="row">
                                {{i+1}}
                            </th>
                            <td class="uppercase">{{payment_detail.programme_name}}</td>
                            <td class="uppercase">
                                {{payment_detail.fee_header_type.replace('_',' ')}}</td>
                            <td class="uppercase">{{payment_detail.term_name}}</td>
                            <td class="uppercase">
                                <ng-container *ngIf="payment_detail.due_date">
                                    {{payment_detail.due_date |
                                    date:commonEnums.date_format}}
                                </ng-container>
                            </td>
                            <td>
                                {{payment_detail.amount | currency:"INR"}}
                                <ng-container *ngIf="payment_detail.breakups && payment_detail.breakups.length>0">
                                    <div class="col-sm-12">
                                        <button (click)="viewBreakupsClick(payment_detail)"
                                            title="View Breakups Details" class="btn btn-sm btn-info btn-block">
                                            View Deatils</button>
                                    </div>


                                </ng-container>


                            </td>
                            <td>
                                <div class="col-sm-12 uppercase" *ngIf="payment_detail.payment_completed">
                                    Paid
                                </div>
                                <div class="col-sm-12 uppercase" *ngIf="!payment_detail.payment_completed">
                                    Not Paid
                                </div>
                            </td>
                            <td>
                                <!-- <div class="col-sm-12" *ngIf="!payment_detail.payment_completed">
                                                            <button (click)="payCourse2Fee(payment_detail)" class="btn btn-success btn-block">
                                                                Pay Online</button>
                                                            </div> -->
                                <div class="col-sm-12" *ngIf="!payment_detail.payment_completed">
                                    <button type="button" class="btn btn-sm btn-info mr-2 mg-b-5"
                                        (click)="openCloseOfflinePayment(payment_detail)">
                                        <ng-container *ngIf="!payment_detail.show_payment">
                                            Make Offline Payment
                                        </ng-container>
                                        <ng-container *ngIf="payment_detail.show_payment">
                                            Close Payment
                                        </ng-container>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-info">
                                        Make Voluntary Payment
                                    </button>
                                </div>
                            </td>
                            <!-- <td>
                                                            {{payment_detail.transaction_ids.join(", ")}}
                                                        </td> -->
                        </tr>

                        <tr *ngIf="payment_detail.show_payment">
                            <td colspan="8">
                                <form [formGroup]="add_transaction_form" class="form-horizontal">
                                    <div id="form_row">
                                        <div class="col-md-12 centered-form">
                                            <div class="form-group centered-form-control">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                    Reference No.</label>
                                                <input class="form-control" placeholder="Enter Reference No."
                                                    required="" type="text" formControlName="reference_no">
                                                <div *ngIf="isInvalidTransaction('reference_no')">
                                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                        <li class="parsley-required">
                                                            Reference No. is required.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="form-group centered-form-control">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    ID Type</label>
                                                <select formControlName="id_type" class="form-control">
                                                    <option value="">Select ID Type
                                                    </option>
                                                    <option
                                                        *ngFor="let type of id_types; let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="type.id">
                                                        {{type.name}}</option>
                                                </select>
                                                <div *ngIf="isInvalidTransaction('id_type')">
                                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                        <li class="parsley-required">ID
                                                            Type is required.</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <!-- <div class="form-group">
                                                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload Proof</label>
                                                                            <input type="file" formControlName="proof" class="form-control">
                                                                        <div *ngIf="isInvalidTransaction('proof')">
                                                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                                            <li class="parsley-required">Proof is required.</li>
                                                                            </ul>
                                                                        </div>
                                                                        </div> -->

                                            <div class="form-group centered-form-control">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                    Description</label>
                                                <textarea id="description" class="form-control"
                                                    [placeholder]="'Enter Description'" formControlName="description">
                                                                            </textarea>
                                                <div *ngIf="isInvalidTransaction('description')">
                                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                        <li class="parsley-required">
                                                            Description is required.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="form-group centered-form-control">
                                                <input type="checkbox" formControlName="signed_by" class="mg-r-5">I
                                                agree to
                                                make this
                                                transaction
                                                <div *ngIf="isInvalidTransaction('signed_by')">
                                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                        <li class="parsley-required">
                                                            Click to continue
                                                            transaction.</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="text-center mg-b-10">
                                                <button class="btn btn-success mg-r-20"
                                                    [disabled]="canDisableOfflinePayment()"
                                                    (click)="makeOfflinePayment(payment_detail)">Make
                                                    Payment</button>

                                                <button class="btn btn-secondary mg-r-20"
                                                    (click)="openCloseOfflinePayment(payment_detail)">Close
                                                    Payment</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </td>


                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!payment_details_loading && payment_details.length==0">
                    <tr>
                        <th colspan="9" class="text-center">No Records found</th>
                    </tr>
                </ng-container>
                <ng-container *ngIf="payment_details_loading && payment_details.length==0">
                    <tr>
                        <th colspan="9" class="text-center">Loading Please wait</th>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>







<!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select ID Type</label>
                                    <select formControlName="id_type" class="form-control">
                                        <option value="">Select ID Type</option>
                                        <option *ngFor="let type of id_types; let i=index;trackBy:fromDataResolver.identify;" [ngValue]="type">{{type}}</option>
                                    </select>
                                  <div *ngIf="isInvalid('id_type')">
                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                      <li class="parsley-required">Year is required.</li>
                                    </ul>
                                  </div>
                                </div> -->




<div class="modal" id="exam-fee-modal-popup" *ngIf="render_data.mode=='stand_alone'">
    <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="current_selection && current_selection.breakups">
            <div class="modal-header">
                <h6 class="modal-title">Course Details</h6>
            </div>
            <div class="modal-body">

                <div class="table-responsive">
                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Course Code</th>
                                <th>Course Name</th>
                                <th>Course Type</th>
                                <th>Appearance Type <br> (Regular - R, Arrear - A)</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="current_selection.breakups.length>0">


                                <ng-container
                                    *ngFor="let breakup of current_selection.breakups;let i=index;trackBy:fromDataResolver.identify;">
                                    <tr>
                                        <td scope="row">
                                            {{i+1}}

                                        </td>
                                        <td class="uppercase">{{breakup.course_code}}</td>
                                        <td class="uppercase">{{breakup.course_name}}</td>
                                        <td class="uppercase">{{breakup.course_type}}
                                            <ng-container *ngIf="breakup.exam_duration>0">
                                                [{{breakup.exam_duration}} hours]
                                            </ng-container>

                                        </td>
                                        <td class="uppercase">{{breakup.appearance_type=="normal"?"R":"A"}}</td>
                                        <td class="uppercase">
                                            {{breakup.fee_amount | currency:"INR"}}
                                        </td>


                                    </tr>
                                </ng-container>
                                <ng-container
                                    *ngIf="current_selection.breakups_extra_charges && current_selection.breakups_extra_charges.length >0">
                                    <ng-container
                                        *ngFor="let breakups_extra_charge of current_selection.breakups_extra_charges;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td scope="row" colspan="5" class="uppercase text-right">
                                                {{breakups_extra_charge.item_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{breakups_extra_charge.amount | currency:"INR"}}
                                            </td>

                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <tr>
                                    <td scope="row" colspan="5" class="uppercase text-right" style="font-weight: bold;">
                                        Total
                                    </td>
                                    <td class="uppercase" style="font-weight: bold;">
                                        {{current_selection.amount | currency:"INR"}}
                                    </td>

                                </tr>
                            </ng-container>


                        </tbody>
                    </table>
                </div>
                <div class="mg-b-20"></div>
                <div class="text-align-right">

                    <button class="btn ripple btn-secondary" (click)="onCloseBreakupsClick()"
                        type="button">Close</button>
                </div>

            </div>

        </div>
    </div>
</div>