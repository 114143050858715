import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-offline-due',
  templateUrl: './offline-due.component.html',
  styleUrls: ['./offline-due.component.scss']
})
export class OfflineDueComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;
  cookieStore = CookieStore;

  // Master data
  offline_due_list: any[] = [];
  offline_due_list_loading = false;

  admission_id = '';
  regulation_year = 0;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();

    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
      this.regulation_year = Number(data.regulation_year);
    });
  }

  ngOnInit(): void {
    const start_date = DateHelper.getStartDate(new Date());
    if (!start_date) {
      return;
    }
    const end_date = DateHelper.getEndDate(new Date());
    if (!end_date) {
      return;
    }

    this.getOfflineDue();
  }

  async getOfflineDue(): Promise<void> {
    try {
      this.offline_due_list = [];
      this.offline_due_list_loading = true;
      const response = await this.restService.getOfflineDue({ admission_id: this.admission_id });
      if (response.success) {
        this.offline_due_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.offline_due_list_loading = false;
    }
  }

  async downloadEnrolledUsers(): Promise<void> {
    // const file_name = this.programme_name.toLowerCase() + '.csv';
    // const data_to_download: any[] = [];
    // // tslint:disable-next-line:prefer-for-of
    // for (let index = 0; index < this.applicant_list.length; index++) {
    //   const applicant = this.applicant_list[index];

    //   const mobile_numbers = [];
    //   if (applicant.mobile_number) {
    //     mobile_numbers.push(applicant.mobile_number);
    //   }
    //   if (applicant.mobile_number_2) {
    //     mobile_numbers.push(applicant.mobile_number_2);
    //   }
    //   if (applicant.mobile_number_3) {
    //     mobile_numbers.push(applicant.mobile_number_3);
    //   }
    //   if (applicant.mobile_number_4) {
    //     mobile_numbers.push(applicant.mobile_number_4);
    //   }
    //   if (applicant.mobile_number_5) {
    //     mobile_numbers.push(applicant.mobile_number_5);
    //   }

    //   let data_item: any;
    //   if (this.status === 'enrolled') {
    //     data_item = {
    //       'S.NO': (index + 1),
    //       'APP. NO.': applicant.application_no,
    //       'ROLL NO.': applicant.roll_no,
    //       EMAIL: applicant.education_email,
    //       NAME: applicant.name.toUpperCase(),
    //       'MOB. NO.': mobile_numbers.join(' '),
    //       'DATE OF BIRTH': DateHelper.toDisplayDate(new Date(applicant.birth_date)),
    //       GENDER: applicant.gender.toUpperCase(),
    //       COMMUNITY: applicant.community_id,
    //       CASTE: applicant.caste
    //     };
    //   } else {
    //     data_item = {
    //       'S.NO': (index + 1),
    //       'DATE.': DateHelper.toDisplayDate(new Date(applicant.updatedAt)),
    //       'APP. NO.': applicant.application_no,
    //       'APP. DATe.': DateHelper.toDisplayDate(new Date(applicant.createdAt)),
    //       NAME: applicant.name.toUpperCase(),
    //       'MOB. NO.': mobile_numbers.join(' '),
    //       'DATE OF BIRTH': DateHelper.toDisplayDate(new Date(applicant.birth_date)),
    //       GENDER: applicant.gender.toUpperCase(),
    //       COMMUNITY: applicant.community_id,
    //       CASTE: applicant.caste,
    //       INSTITUTE: applicant.institute,
    //       INSTITUTE_REG_NO: applicant.institute_reg_no,
    //     };
    //     for (const mark of applicant.marks) {
    //       data_item[mark.title.toUpperCase()] = mark.mark;
    //     }
    //     data_item.REMARKS = applicant.remarks;

    //   }

    //   data_to_download.push(data_item);
    // }

    // CSVHelper.downloadCSV(data_to_download, file_name);

  }
}
