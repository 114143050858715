import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';


@Component({
  selector: 'app-view-syllabus',
  templateUrl: './view-syllabus.component.html',
  styleUrls: ['./view-syllabus.component.scss']
})
export class ViewSyllabusComponent implements OnInit {
  @ViewChild('courseOutcome') course_outcome: ElementRef;

  course_code: string = "";
  syllabus_data: any;

  constructor(private restService: AdminservicesService, private fb: FormBuilder,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.course_code = params.course_code;
      console.log(this.course_code);
      if (this.course_code != undefined) {
        this.getSyllabus();
      }
    });
  }

  async getSyllabus() {
    try {
      const course_response = await this.restService.findCourseV2(this.course_code);
      if (course_response.success) {
        this.syllabus_data = course_response.data;
        console.log(this.syllabus_data);
        //this.course_outcome!.nativeElement.innerHTML = this.syllabus_data.course_details.course_outcome;
      }
    } catch (error) {
      console.log(error);
      alert('Error while read syllabus data');
    } finally {
    }
  }
}
