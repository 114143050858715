<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Staff Timetable</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Staff Timetable</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Staff Timetable</p>
                    </div>

                    <div class="card-body" *ngIf="department_search_form">
                        <form [formGroup]="department_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department
                                            Type</label>
                                        <select formControlName="department_category_id" class="form-control"
                                            (change)="onFilterSelectChange('department_category_id',$event)">
                                            <option value="">Select Department Type</option>
                                            <option
                                                *ngFor="let department_type of department_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department_type.department_category_id">
                                                {{department_type.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Department</label>
                                        <select formControlName="department_id" class="form-control"
                                            (change)="onFilterSelectChange('department_id',$event)">
                                            <option value="">All Department</option>
                                            <option
                                                *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department.department_id">{{department.department_name}}
                                                {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Gender</label>
                                        <select formControlName="gender" class="form-control"
                                            (change)="onFilterSelectChange('gender',$event)">
                                            <option value="">All Gender</option>
                                            <option
                                                *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="gender.id">{{gender.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll No</label>
                                        <input class="form-control" required="" type="text" formControlName="roll_no">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!staffs_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="staffs_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="staffs_list.length>0">
                                        <ng-container
                                            *ngFor="let staff of staffs_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td class="text-uppercase">{{staff.roll_no}}</td>
                                                <td class="text-uppercase">{{staff.given_name}} {{staff.middle_name}}
                                                </td>
                                                <td class="text-uppercase">{{staff.gender}}</td>
                                                <td class="text-uppercase">{{staff.mobile_number}}</td>
                                                <td class="text-lowercase">{{staff.email}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                                            (click)="viewTimetable(staff)">
                                                            <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!staffs_list_loading && staffs_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="staffs_list_loading && staffs_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">View Staff Timetable</h6>
                    </div>
                    <div class="modal-body">
                        <span *ngIf="staff_timetable_loading"><b>Loading...</b></span>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>Day</th>
                                        <th *ngFor="let head of hour_header | slice:0:max_hour">
                                            {{head.hour_name}} <br>
                                            {{head.hour_time?head.hour_time:''}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="staff_timetable!.length>0">
                                        <ng-container
                                            *ngFor="let day of staff_timetable;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td>
                                                    {{day.day}}
                                                </td>
                                                <ng-container
                                                    *ngFor="let head of hour_header | slice:0:max_hour; let j=index;trackBy:fromDataResolver.identify;">
                                                    <td>
                                                        <ng-container *ngIf="isStaffHourExists(i,head.hour_number)">
                                                            <ng-container
                                                                *ngFor="let hour_data of getStaffHourData(i,head.hour_number); let ind = index;">
                                                                <span>
                                                                    <b>{{hour_data.course_data.course_name}}
                                                                        ({{hour_data.course_data.course_code}})</b>
                                                                </span>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <span
                                                                            *ngIf="!hour_data.programme_data?.virtual_section">
                                                                            {{getProgrammeName(hour_data.programme_data.programme_id)}}
                                                                            '{{hour_data.programme_data.section}}'
                                                                        </span>
                                                                        <span
                                                                            *ngIf="hour_data.programme_data?.virtual_section">
                                                                            {{hour_data.programme_data.virtual_section}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <span>
                                                                            {{hour_data.room_data.room_no}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isStaffHourExists(i,head.hour_number)">
                                                            <span>---</span>
                                                        </ng-container>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!staff_timetable_loading && staff_timetable.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="staff_timetable_loading && staff_timetable.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="mg-b-20"></div>
                        <div class="text-align-right">
                            <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                type="button">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>