<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Fees Settings List</p>
                    </div>

                    <div class="card-body">
                        <div class="row row-sm">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                        Route</label>
                                    <select [(ngModel)]="route_id" class="form-control">
                                        <option value="">Choose Route</option>
                                        <option
                                            *ngFor="let route of routes_list;let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="route._id">
                                            {{route.route_name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                                        *ngIf="!student_list_loading" (click)="onSearchClick()">
                                        Get List
                                    </button>
                                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                        *ngIf="student_list_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 20%;">Roll No.</th>
                                        <th style="width: 40%;">Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="student_list.length>0">
                                        <ng-container
                                            *ngFor="let student of student_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{student.roll_no | uppercase}}</td>
                                                <td>{{student.name}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="viewTransportHistory(student)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            Transport History
                                                        </button>
                                                        <button type="button" (click)="openSMS(student)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            Send SMS
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!student_list_loading && student_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_list_loading && student_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="transport-modal-popup">
            <div class="modal-dialog modal-lg" role="document" *ngIf="student">
                <div class="modal-content modal-content-demo" *ngIf="popup_mode == 'history'">
                    <div class="modal-header">
                        <h6 class="modal-title">Student Transport Route History</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">

                            <div class="container mb-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>Student Transport Timeline</h4>
                                        <ul class="timeline">
                                            <li>
                                                <a>College to Sivakasi</a>
                                                <a href="#" class="float-right">01/06/2022</a>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <img src="../../../../../assets/img/route_map.png"
                                                            height="115px" width="100%" (click)="viewMap()"
                                                            style="cursor:pointer">
                                                        <p>Sivakasi to College (Time: 32 Mins.)</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img src="../../../../../assets/img/route_map.png"
                                                            height="115px" width="100%" (click)="viewMap()"
                                                            style="cursor:pointer">
                                                        <p>College to Sivakasi (Time: 40 Mins.)</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <a>College to Sivakasi</a>
                                                <a href="#" class="float-right">02/06/2022</a>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <img src="../../../../../assets/img/route_map.png"
                                                            height="115px" width="100%" (click)="viewMap()"
                                                            style="cursor:pointer">
                                                        <p>Sivakasi to College (Time: 35 Mins.)</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img src="../../../../../assets/img/route_map.png"
                                                            height="115px" width="100%" (click)="viewMap()"
                                                            style="cursor:pointer">
                                                        <p>College to Sivakasi (Time: 45 Mins.)</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <a>College to Sivakasi</a>
                                                <a href="#" class="float-right">03/06/2022</a>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <img src="../../../../../assets/img/route_map.png"
                                                            height="115px" width="100%" (click)="viewMap()"
                                                            style="cursor:pointer">
                                                        <p>Sivakasi to College (Time: 31 Mins.)</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img src="../../../../../assets/img/route_map.png"
                                                            height="115px" width="100%" (click)="viewMap()"
                                                            style="cursor:pointer">
                                                        <p>College to Sivakasi (Time: 43 Mins.)</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-md btn-secondary" (click)="closeModal()"
                                        style="float: right;">Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="modal-content modal-content-demo" *ngIf="popup_mode == 'sms'">
                    <div class="modal-header">
                        <h6 class="modal-title">Send SMS</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form>
                                <div class="row">
                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter SMS
                                            Content</label>
                                        <textarea [(ngModel)]="sms_data" name="sms_data" class="form-control" rows="10"
                                            cols="30">
                                        </textarea>
                                    </div>
                                </div>

                                <div class="row mg-t-20">
                                    <div class="col-md-12">
                                        <button class="btn btn-md btn-secondary mg-r-10" (click)="closeModal()"
                                            style="float: right;">Close</button>
                                        <button class="btn btn-md btn-success mg-r-10" (click)="sendSMS()"
                                            style="float: right;">Send SMS</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <div class="modal" id="map-modal-popup">
            <div class="modal-dialog big-modal" role="document" *ngIf="student">
                <div class="modal-content modal-content-demo" *ngIf="popup_mode == 'history'">
                    <div class="modal-header">
                        <h6 class="modal-title">View Transport Route</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-4">
                                    <span>Route: <b>College to Sivakasi</b></span>
                                </div>
                                <div class="col-md-4">
                                    <span>Pickup Time: <b>01.06.2022 8:30 AM</b></span>
                                </div>
                                <div class="col-md-4">
                                    <span>Drop Time: <b>01.06.2022 9:15 AM</b></span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <img src="../../../../../assets/img/route_map.png" height="100%" width="100%">
                                </div>
                            </div>

                            <div class="row mg-t-20">
                                <div class="col-md-12">
                                    <button class="btn btn-md btn-secondary mg-r-10" (click)="closeMap()"
                                        style="float: right;">Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>