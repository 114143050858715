import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  extrainfo_form: any;

  female = false;

  user = CookieStore.getUserInfo();

  payment_details: any[] = [];


  constructor(private fb: FormBuilder, private restService: AdminservicesService, private domsanitizer: DomSanitizer) {
    JQueryHelper.toPageInit();
    this.getTransportRoutes();

  }

  url: string = "";

  getURL() {
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/student-dashboard/dashboard-646cb6dcef177c62ce865e51?access_token=" + access_token;
    console.log(this.url);
    console.log(access_token)
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  isRoleExists(roles: string[]) {
    if (CookieStore.isRoleExists(roles)) {
      return true;
    } else {
      return false;
    }
  }

  async getTransportRoutes(): Promise<void> {

    try {
      this.payment_details = [];
      const rest_response = await this.restService.getMyPaymentList();
      if (rest_response.success && rest_response.data) {
        this.payment_details = rest_response.data;
      } else {
        this.payment_details = [];
      }
    } catch (error) {

    }



  }


  ngOnInit(): void {



    if (this.user.gender && (this.user.gender.toLowerCase() === 'male' || this.user.gender.toLowerCase() === 'm')) {
      this.female = false;
    } else {
      this.female = true;
    }


    // const form_field: any = {

    // };

    // let show_model = false;
    // if (this.user.family_name === '-') {
    //   this.user.family_name = '';
    // }
    // if (this.user.middle_name === '-') {
    //   this.user.middle_name = '';
    // }
    // if (!this.user.middle_name && !this.user.family_name) {
    //   show_model = true;
    //   form_field.initial = ['', [Validators.required]];
    // }
    // if (!this.user.gender) {
    //   show_model = true;
    //   form_field.gender = ['', [Validators.required]];
    // }
    // if (show_model) {
    //   this.extrainfo_form = this.fb.group(form_field);
    //   JQueryHelper.openModal('#extra_info', { keyboard: false, backdrop: 'static' });
    // }
  }

  async onExtraInfoClick(): Promise<void> {

    const consent = confirm('Are you sure do you want to save?');
    if (consent) {
      const user_info: any = {

      };
      const val = this.extrainfo_form.value;
      if (val.initial) {
        user_info.middle_name = val.initial;
      }
      if (val.gender) {
        user_info.gender = val.gender;
      }
      if (Object.keys(user_info).length > 0) {
        try {
          const rest_response = await this.restService.updateStudentInfo(user_info);
          if (rest_response.success) {
            const userinfo_response = await this.restService.getUserInfo();
            if (userinfo_response.success) {
              CookieStore.saveUserInfo(userinfo_response.data);
              this.user = CookieStore.getUserInfo();
            }
            JQueryHelper.closeModal('#extra_info');
          } else {
            alert('Error while saving basic info, try again');
          }
        } catch (error) {
          alert('Error while saving basic info, try again');
        }
      } else {
        alert('Please fill all required fields');
      }
    }
  }

}
