import { Incharge } from './../../controllers/admins/hostel-management/incharge-model';
import { Floor } from './../../controllers/admins/hostel-management/floor-model';
import { Hostel, HostelBody } from './../../controllers/admins/hostel-management/hostelModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HostelManagementService {
  private _baseURL: string = ''
  private _headers: any;
  private hostelURL: string = 'hostel-srv/admin/hostel';

  constructor(private httpClient: HttpClient) { }

  set url(baseURL: string) {
    this._baseURL = baseURL;
  }

  set headers(headers: any) {
    this._headers = headers;
  }

  errorHandler = (error: any): Observable<Hostel> => {
    console.log('HostelManagementService Error ', error);
    return of({} as Hostel);
  }

  getHostels(id: string = ''): Observable<Hostel[]> {
    return this.httpClient.get<Hostel[]>(`${this._baseURL}/${this.hostelURL}/${id}`, this._headers).pipe(
      map((res: any) => {
        if (Array.isArray(res.data)) {
          return res.data;
        }
        return [res.data];
      })
    );
  }

  createHostel(hostel: string): Observable<Hostel> {
    return this.httpClient.post<Hostel>(`${this._baseURL}/${this.hostelURL}`, { name: hostel }, this._headers).pipe(
      map((res: any) => res.data)
    );
  }

  updateHostel(id: string = '', data: HostelBody): Observable<Hostel> {
    return this.httpClient.put<Hostel>(`${this._baseURL}/${this.hostelURL}/${id}`, data, this._headers).pipe(
      map((res: any) => res.data)
    );
  }

  deleteHostel(hostelId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this._baseURL}/${this.hostelURL}/${hostelId}`, this._headers).pipe(
      map((res: any) => res.data)
    );
  }
}
