import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ViewChild, ViewChildren } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-internal-question-setting',
  templateUrl: './internal-question-setting.component.html',
  styleUrls: ['./internal-question-setting.component.scss']
})
export class InternalQuestionSettingComponent implements OnInit {

  @ViewChildren('editors') public editors: any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    // defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['code']
    ]
  };
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Internal Question Paper Setting';

  //route params
  exam_id: string = "";
  question_format_id: string = "";

  question_format: IExamQuestionFormat[] = [];
  exam_setup: any;
  default_questions_settings: any[] = [];
  default_co_settings: any[] = [];
  question_settings: any[] = [];
  question_setting_form: FormGroup = undefined;
  question_save_loading: boolean = false;
  edit_question_index: any = {};
  question_editor: any;
  editor_popup_form: FormGroup;
  co_k_map: {
    co_id: number,
    k_id: number[]
  }[] = [{
    co_id: 1, k_id: [1, 2]
  }, { co_id: 2, k_id: [3] }, { co_id: 3, k_id: [4] }, { co_id: 4, k_id: [5] }, { co_id: 5, k_id: [6] }]
  question_format_type: string = '';
  co_settings: ICOSettings[] = [];
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.exam_id = params.exam_id;
      this.question_format_id = params.question_format_id;
      if (this.question_format_id != undefined && this.exam_id != undefined) {
        this.renderData();
      }
    });
  }

  getKLevels(i: number, j: number, question) {
    var co_id = (((this.question_setting_form.get('questions_settings') as FormArray).controls[i]).get('questions') as FormArray).controls.find(x => x.value.question_no == question.value.question_no && x.value.option == question.value.option).get('co_id').value;
    if (co_id == 0) return [];
    var k_ids: number[] = this.co_k_map.find(x => x.co_id == co_id).k_id;
    if (k_ids.length == 1) {
      (((this.question_setting_form.get('questions_settings') as FormArray).controls[i]).get('questions') as FormArray).controls.find(x => x.value.question_no == question.value.question_no && x.value.option == question.value.option).get('k_id').setValue(k_ids[0]);
    }
    this.question_setting_form.updateValueAndValidity();
    return k_ids;
  }

  coChanged(ev, i: number, j: number, question) {
    console.log(ev.target.value);
    if (question.value.option == 'A') {
      (((this.question_setting_form.get('questions_settings') as FormArray).controls[i]).get('questions') as FormArray).controls.find(x => x.value.question_no == question.value.question_no && x.value.option == 'B').get('co_id').setValue(ev.target.value);
      //((this.question_setting_form.get('questions_settings') as FormArray).controls[i].get('questions') as FormArray).controls[j].get('co_id').setValue(ev.target.value);
      this.question_setting_form.updateValueAndValidity();
    }

  }

  kChanged(ev, i: number, j: number, question) {
    if (question.value.option == 'A') {
      (((this.question_setting_form.get('questions_settings') as FormArray).controls[i]).get('questions') as FormArray).controls.find(x => x.value.question_no == question.value.question_no && x.value.option == 'B').get('k_id').setValue(ev.target.value);
      this.question_setting_form.updateValueAndValidity();
    }
  }

  async renderData() {
    try {
      const service_resp = await this.restService.findInternalExamSetup(this.exam_id);
      if (service_resp && service_resp.success) {
        this.exam_setup = service_resp.data;
        console.log(this.exam_setup);
        this.default_questions_settings = JSON.parse(JSON.stringify(this.exam_setup.questions_settings));
        this.default_co_settings = JSON.parse(JSON.stringify(this.exam_setup.co_settings));
        console.log(this.default_questions_settings);
        const service_response = await this.restService.findQuestionFormat(this.question_format_id);
        if (service_response && service_response.success) {
          this.question_format_type = service_response.data.question_format_type;
          if (this.question_format_type == 'Section-Wise') {
            this.question_format = service_response.data.exam_question_format;
            console.log(this.question_format);
            var question_no: number = 0; //carry question number between sections
            var default_questions: any[];
            this.question_format.forEach(section => {
              var section_data: any = {};
              section_data.section_no = section.section_no;
              section_data.questions = [];
              section_data.co_conditions = section.co_conditions;

              var no_of_questions: number = 0;
              if (section.question_type == "SINGLE") {
                no_of_questions = section.no_of_questions;
              }
              if (section.question_type == "EITHER_OR") {
                no_of_questions = section.no_of_questions * 2;
              }
              for (var i = 0; i < no_of_questions; i++) {
                var question_data: any = {
                  _id: "",
                  question: "",
                  co_id: 0,
                  k_id: 0,
                  scheme_list: [],
                }
                if (section.question_type == "SINGLE") {
                  question_no++;
                  question_data.question_no = question_no;
                  question_data.option = "";
                }
                if (section.question_type == 'EITHER_OR') {
                  if (i % 2 == 0) {
                    question_no++;
                    question_data.option = "A"
                  }
                  else {
                    question_data.option = "B"
                  }
                  question_data.question_no = question_no;
                }
                //load default setting
                console.log(this.default_questions_settings);
                if (this.default_questions_settings?.length > 0) {
                  default_questions = this.default_questions_settings.find(x => x.section_no == section.section_no)?.questions
                  if (default_questions?.length > 0) {
                    var question = default_questions.find(x => x.question_no == question_data.question_no && x.option == question_data.option);
                    if (question) {
                      console.log(question);
                      question_data = question;
                    }
                  }
                }
                section_data.questions[i] = question_data;
              }
              this.question_settings.push(section_data);
            });
            console.log(this.question_settings);
            this.question_setting_form = this.formBuilder.group({
              questions_settings: this.formBuilder.array(this.buildSectionsSettings(this.question_settings)),
            });
            console.log(this.question_setting_form.value);

          }
          if (this.question_format_type == 'Co-Wise') {
            var allowed_co_ids: number[] = service_response.data.allowed_co_ids;
            console.log(allowed_co_ids);
            if (allowed_co_ids.length > 0) {
              allowed_co_ids.forEach(co_id => {
                this.co_settings.push({
                  co_id: co_id,
                  max_mark: (this.default_co_settings.find(x => x.co_id == co_id)?.max_mark) ? this.default_co_settings.find(x => x.co_id == co_id)?.max_mark : 0,
                  description: (this.default_co_settings.find(x => x.co_id == co_id)?.description) ? this.default_co_settings.find(x => x.co_id == co_id)?.description : ""
                });
              })
            }
            this.question_setting_form = this.formBuilder.group({
              co_settings: this.formBuilder.array(this.buildCoSettings(this.co_settings)),
            });
            console.log(this.question_setting_form.value);

          }
        }
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }

  buildSectionsSettings(questions_settings: any[]): any {
    if (!questions_settings) {
      questions_settings = [];
    }
    var section_list_array: any[] = [];
    if (questions_settings && questions_settings.length > 0) {
      var question_count = 0;
      for (const section of questions_settings) {
        section_list_array.push(this.formBuilder.group({
          section_no: [section.section_no, [Validators.required]],
          questions: this.formBuilder.array(this.buildQuestionSettings(section.questions)),
        }));
        question_count++;
      }
    }
    return section_list_array;
  }

  buildCoSettings(co_settings: ICOSettings[]): any {
    if (co_settings.length == 0) {
      co_settings = [];
    }
    var co_list_array: any[] = [];
    if (co_settings && co_settings.length > 0) {
      var co_count = 0;
      for (const co of co_settings) {
        co_list_array.push(this.formBuilder.group({
          co_id: [co.co_id, [Validators.required]],
          max_mark: [co.max_mark, [Validators.required]],
          description: [co.description],
        }));
        co_count++;
      }
    }
    return co_list_array;
  }

  get questionSettings() {
    return (this.question_setting_form.get('questions_settings') as FormArray).controls;
  }

  get coSettings() {
    return (this.question_setting_form.get('co_settings') as FormArray).controls;
  }

  buildQuestionSettings(questions: any[]): any {
    if (!questions) {
      questions = [];
    }
    var question_list_array: any[] = [];
    if (questions && questions.length > 0) {
      var question_count = 0;
      for (const question of questions) {
        question_list_array.push(this.formBuilder.group({
          _id: [question._id],
          question: [question.question],
          co_id: [question.co_id, [Validators.required, Validators.min(1)]],
          k_id: [question.k_id, [Validators.required, Validators.min(1)]],
          scheme_list: [question.scheme_list],
          question_no: [question.question_no],
          option: [question.option]
        }));
        question_count++;
      }
    }
    return question_list_array;
  }

  getQuestions(index: number) {
    return ((this.question_setting_form.get('questions_settings') as FormArray).controls[index].get('questions') as FormArray).controls;
  }

  editQuestion(i: number, j: number) {
    this.edit_question_index.i = i; this.edit_question_index.j = j;
    this.question_editor = ((this.question_setting_form.get('questions_settings') as FormArray).
      controls[this.edit_question_index.i].get('questions') as FormArray).controls[this.edit_question_index.j].
      get('question').value;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async saveQuestion() {
    console.log(this.question_editor);
    ((this.question_setting_form.get('questions_settings') as FormArray).
      controls[this.edit_question_index.i].get('questions') as FormArray).controls[this.edit_question_index.j].
      get('question').setValue(this.question_editor);
    await this.saveQuestionPaper();
  }

  saveAndCloseQuestion() {
    this.saveQuestion();
    this.closeQuestion();
  }
  closeQuestion() {
    this.question_editor = "";
    JQueryHelper.closeModal('#modal-popup');
  }

  isDisabled() {
    return this.question_setting_form?.status !== 'VALID';
  }

  async saveQuestionPaper() {
    try {
      var form_data: any = this.question_setting_form.value;
      var err: boolean = false;
      if (this.question_format_type == 'Section-Wise') {
        form_data.questions_settings.forEach(section => {
          console.log(section.section_no);
          var user_co_ids: number[] = [];
          var default_co_ids: any[] = [];
          var question_type: string = "";
          default_co_ids = this.question_settings.find(x => x.section_no == section.section_no).co_conditions;
          question_type = this.question_format.find(x => x.section_no == section.section_no).question_type;
          for (var i = 0; i < 5; i++) user_co_ids[i] = 0;
          var questions: any[] = section.questions;
          questions.forEach(question => {
            user_co_ids[question.co_id - 1]++;
          });
          console.log(user_co_ids);
          //default_co_ids.forEach(co => {
          for (var i = 0; i < default_co_ids.length; i++) {
            if (question_type == "SINGLE") {
              if (default_co_ids[i].no_of_questions != user_co_ids[default_co_ids[i].co_id - 1]) {
                alert("CO Condition not matched in Section " + section.section_no);
                err = true;
                break;
              }
            }
            if (question_type == "EITHER_OR") {
              if (default_co_ids[i].no_of_questions * 2 != user_co_ids[default_co_ids[i].co_id - 1]) {
                alert("CO Condition not matched in Section " + section.section_no);
                err = true;
                break;
              }
            }
          }

          //});
        });
      }

      if (this.question_format_type == 'Co-Wise') {

      }
      console.log(form_data);
      //compare if co_conditions in question_settings is satisfied in form_data
      if (!err) {
        this.question_save_loading = true;
        const service_response = await this.restService.updateInternalExamSetup(this.exam_setup._id, form_data);
        if (service_response && service_response.success) {
          alert('Question Paper saved successfully');
        }
        else {
          alert("Could not save Question Paper");
        }
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
      console.log(error);
    }
    finally {
      this.question_save_loading = false;
    }
  }

  openUploadImage(event: any, editor_ref: string): void {
    let editor: EditorComponent | undefined;
    for (const result of this.editors._results) {
      if (result.element.nativeElement && result.element.nativeElement.className) {
        const class_names: string[] = result.element.nativeElement.className.split(' ');
        if (class_names.includes(editor_ref)) {
          editor = result;
          break;
        }
      }
    }
    this.editor_popup_form = this.formBuilder.group({
      image_url: [''],
      height: [''],
      width: [''],
      editor: [editor]
    });
    JQueryHelper.openModal('#modal-2', { keyboard: false, backdrop: 'static' });
  }
  async uploadImage(e: any, form: FormGroup): Promise<void> {

    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const img = new Image();

          img.src = (reader.result as string);
          img.onload = () => {
            // const image_url = form.get('image_url');
            // if (image_url) {
            //   image_url.setValue(img.src);
            // }
            // const height = form.get('height');
            // if (height) {
            //   height.setValue(img.height);
            // }
            // const width = form.get('width');
            // if (width) {
            //   width.setValue(img.width);
            // }
            if (form.value.editor) {
              form.value.editor.exec('insertImage', {
                src: img.src,
                height: img.height,
                width: img.width
              });
              JQueryHelper.closeModal('#modal-2');
            }
          };
        };

        reader.readAsDataURL(file);
      }

    } catch (error) {

    } finally {

    }
  }

  onCloseImageUpload(): void {
    JQueryHelper.closeModal('#modal-2');
  }


}

interface IExamQuestionFormat {
  _id: string,
  section_no: number,
  section_name: string,
  no_of_questions: number,
  question_max_mark: number,
  question_type: string,
  no_of_questions_to_answered: number,
  co_conditions: ICoCondition[]
}

interface ICoCondition {
  _id: string
  co_id: number,
  no_of_questions: number,
}

interface ICOSettings {
  co_id: number,
  max_mark: number,
  description: string
}