import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
@Component({
  selector: 'app-repeat-attendance',
  templateUrl: './repeat-attendance.component.html',
  styleUrls: ['./repeat-attendance.component.scss']
})
export class RepeatAttendanceComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;

  attendance_list: any[] = [];
  attendance_list_loading = false;

  attendance_search_form: any;
  attendance: any = {};
  attendance_save_form: any;
  attendance_save_form_loading = false;
  sections: any = [];
  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  commonEnums = CommonEnums;
  category_list_loading = false;
  report_enrolled_year: string = '';
  report_semester: string = '';
  report_roll_no: string = '';
  report_list_loading = false;
  hour_status: {
    key: string, value: string
  }[] = [
      { key: "PRESENT", value: "Present" }, { key: "ABSENT", value: "Absent" }, { key: "LEAVE", value: "Leave" },
      { key: "ON-DUTY", value: "On Duty" }
    ];
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.buildSearchForm({});
    this.getProgrammeTypeList();
  }

  onViewReportClick() {
    JQueryHelper.openModal('#report-popup', { keyboard: false, backdrop: 'static' });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id == "programme_id") {
      this.sections = [];
      this.attendance_search_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.attendance_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
      if (this.sections.length == 1) {
        this.attendance_search_form.get('section').setValue(this.sections[0].section_name);
      }
    }

    if (id === 'programme_type_id' || id === 'finance_type') {
      this.attendance_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.attendance_search_form);
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  buildSearchForm(data: any): void {
    const date = data.attendance_date || new Date();
    this.attendance_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      to_enrolled_year: [''],
      to_section_id: [''],
      repeating_semester: [''],
      attendance_date: [DateHelper.convertToControlDate(date)],
      attendance_hour: [data.attendance_hour || ''],
      roll_no: [''],
    });
  }
  async onAddClick(): Promise<void> {
    this.buildAttendanceForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditClick(attendance) {
    this.attendance = attendance;
    this.buildAttendanceForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  buildAttendanceForm(): void {
    if (!this.attendance) {
      this.attendance = {
        attendance_date: DateHelper.convertToControlDate(new Date()),
        attendance_hour: "",
        course_code: '',
        roll_no: '',
        status: 'PRESENT'
      }
    }
    this.attendance_save_form = this.fb.group({
      attendance_date: [this.attendance.attendance_date, [Validators.required]],
      attendance_hour: [this.attendance.attendance_hour, [Validators.required]],
      course_code: [this.attendance.course_code, [Validators.required]],
      roll_no: [this.attendance.roll_no, [Validators.required]],
      status: [this.attendance.status, [Validators.required]]
    });
  }

  async getSavedAttendance(): Promise<void> {
    try {

      this.attendance_list_loading = true;

      const attendance_search_form_data = this.attendance_search_form.value;
      if (!attendance_search_form_data.programme_id) {
        alert('Please select Programme');
        return;
      }

      if (!attendance_search_form_data.to_enrolled_year) {
        alert('Please select Enrolled Year');
        return;
      }

      if (!attendance_search_form_data.repeating_semester) {
        alert('Please select semester');
        return;
      }

      if (!attendance_search_form_data.attendance_date) {
        alert('Please select attendance date');
        return;
      }

      if (!DateHelper.isValidDate(attendance_search_form_data.attendance_date)) {
        alert('Please select valid attendance date');
        return;
      }
      attendance_search_form_data.attendance_date = new Date(attendance_search_form_data.attendance_date);
      this.attendance_list = [];
      this.attendance_list_loading = true;
      const save_respose = await this.restService.getRepeatSemesterStudentAttendance(attendance_search_form_data);
      if (save_respose.success && save_respose.data && save_respose.data.length > 0) {
        this.attendance_list = save_respose.data;
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.attendance_list_loading = false;
    }
  }
  async onClearSearch(): Promise<void> {
    this.buildSearchForm({});
  }

  getAttendanceReport() {
    const report_obj = {
      enrolled_year: this.report_enrolled_year,
      semester: this.report_semester,
      roll_no: this.report_roll_no
    }
  }


  async onSaveClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.attendance_save_form_loading = true;

      const attendance_save_form_data = this.attendance_save_form.value;

      if (!attendance_save_form_data.attendance_date) {
        alert('Please select attendance date');
        return;
      }

      if (!attendance_save_form_data.attendance_hour) {
        alert('Please select attendance hour');
        return;
      }


      if (!DateHelper.isValidDate(attendance_save_form_data.attendance_date)) {
        alert('Please select valid attendance date');
        return;
      }
      // attendance_save_form_data.attendance_date = new Date(attendance_save_form_data.attendance_date);

      if (!attendance_save_form_data.course_code || attendance_save_form_data.course_code.trim().length === 0) {
        alert('Please enter course code');
        return;
      }
      attendance_save_form_data.roll_no.trim().replace(/\n/g, ' ').replace(/,/g, ' ').split(' ');
      // attendance_save_form_data.roll_nos = [];
      // for (const roll_no of roll_nos) {
      //   attendance_save_form_data.roll_nos.push({ is_absent: true, attendance_type: 'abbsent', roll_no });
      // }
      const save_respose = await this.restService.markRepeatSemesterAttendance(attendance_save_form_data);
      if (save_respose.success) {
        alert('Saved successfully');

        this.onCloseClick();
        this.buildSearchForm({
          attendance_date: attendance_save_form_data.attendance_date,
          attendance_hour: attendance_save_form_data.attendance_hour,
        });
        this.getSavedAttendance();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.attendance_save_form_loading = false;
    }
  }

  // async onDeleteClick(): Promise<void> {
  //   try {

  //     const consent = confirm('Are you sure do you want to delete?');
  //     if (!consent) {
  //       return;
  //     }

  //     this.category_delete_loading = true;

  //     const categgory_data = this.category_form.value;
  //     if (!categgory_data.department_category_id) {
  //       alert('Please enter department categoryid');
  //     }


  //     const save_respose = await this.restService.deleteDepartmentCategoryById(categgory_data.department_category_id);


  //     if (save_respose.success) {
  //       alert('Deleted Successfully');
  //       await this.getFormDataList();
  //       this.onCloseClick();
  //     }


  //   } catch (error) {
  //     alert('Error while read data');
  //   } finally {
  //     this.category_delete_loading = false;
  //   }
  // }



  resetForm(): void {
    this.attendance_save_form = null;
  }
  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  displayAttendanceStatus(attendance: any) {
    if(attendance && attendance.is_present) {
      return "PRESENT";
    } else if(attendance && (!attendance.is_present)) {
      return "ABSENT";
    } else if(attendance.is_present && attendance && attendance.od_given) {
      return "ONDUTY";
    } else if(attendance && (!attendance.is_present) && (!attendance.od_given) && attendance.leave_applied) {
      return "LEAVE";
    } else {
      return "N/A";
    }
  }

  onReportCloseClick() {
    this.report_enrolled_year = ''; this.report_semester = ''; this.report_roll_no = '';
    JQueryHelper.closeModal('#report-popup');
  }



}
