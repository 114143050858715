import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
//import {DBFFile, FieldDescriptor} from 'dbffile';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
@Component({
  selector: 'app-fees-report',
  templateUrl: './fees-report.component.html',
  styleUrls: ['./fees-report.component.scss']
})
export class FeesReportComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  programme_search_form: FormGroup;
  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  category_list_loading = false;
  parts: any[] = [];
  department_list = [];
  purposes: any[] = [{ id: "EXAM_FEE", name: "Exam Fees" },
  { id: "PROGRAMME_FEE", name: "Programme Fee" }, { id: "HOSTEL_FEE", name: "Hostel Fee" },
  { id: "HOSTEL_ADMISSION_FEE", name: "Hostel Admission Fee" },
  { id: "TRANSPORTATION_ADMISSION_FEE", name: "Transportation Admission Fee" },
  ];
  fees_headers: {
    id?: string, term_name?: string, programme_type_id?: string,
    admission_id?: string, admission_name?: string
  }[] = []; //replace with API Call
  report_list_loading: boolean = false;
  apply_fine_loading: boolean = false;
  sections: any = [];
  fees_report: FeesReportInterface[] = [];
  payment_status: any[] = [{ id: true, name: "PAID" }, { id: false, name: "NOT PAID" }];
  start_index = 0;
  total_records = 0;
  take_index = 500;
  dropdownSettings: IDropdownSettings = {};

  split_status: any[] = [{ id: "ALL", name: "All" }, { id: true, name: "Collected" }, { id: false, name: "Not Collected" }];
  split_headers: string[] = [];
  max_splits_cols: number = 0;
  show_split: boolean = true;

  constructor(private restService: AdminservicesService, private fb: FormBuilder, private http: HttpClient, private router: Router) {
    this.programme_search_form = this.fb.group({
      start_date: [''],
      end_date: [''],
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      enrolled_year: [''],
      section: [''],
      purpose: ['', [Validators.required]],
      fee_header_id: [''],
      payment_collected: ['d'],
      community_ids: [''],
      split_status: ['d'],
      split_start_date: [''],
      split_end_date: [''],
      gender: ['']
    });
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();

    //this.testWrite();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    this.getReport()
  }

  async onPurposeChange() {
    this.onResetClick();
    var filter_data: any = {}; this.fees_headers = [];
    if (this.programme_search_form.value.purpose != '') {
      this.programme_search_form.get('fee_header_id').setValue("");
      try {
        if (this.programme_search_form.value.programme_type_id != '') {
          filter_data.programme_type_id = this.programme_search_form.value.programme_type_id;
        }
        console.log(filter_data);
        if (this.programme_search_form.value.purpose == "EXAM_FEE") {
          const service_response = await this.restService.getExamFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                id: term.id, programme_type_id: term.programme_type_id, term_name: term.term_name
              })
            })
          }
        } else if (this.programme_search_form.value.purpose == "PROGRAMME_FEE") {
          const service_response = await this.restService.getProgrammeFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                id: term.id, programme_type_id: term.programme_type_id, term_name: term.term_name
              })
            })
          }
        }
        else if (this.programme_search_form.value.purpose == "HOSTEL_FEE") {
          const service_response = await this.restService.getHostelFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                id: term.id, programme_type_id: term.programme_type_id, term_name: term.term_name
              })
            })
          }
        }
        else if (this.programme_search_form.value.purpose == "HOSTEL_ADMISSION_FEE") {
          const service_response = await this.restService.getHostelAdmissionFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                admission_id: term.admission_id, admission_name: term.admission_name
              })
            })
          }
        }
        else if (this.programme_search_form.value.purpose == "TRANSPORTATION_ADMISSION_FEE") {
          const service_response = await this.restService.getTransportAdmissionFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                admission_id: term.admission_id, admission_name: term.admission_name
              })
            })
          }
        }
      } catch (error) {
        console.log(error);
        alert('Error while read data');
      }
    }
    else {
      this.fees_headers = [];
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    this.onResetClick();
    if (id == "programme_id") {
      this.sections = [];
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
    }
    if (id == 'programme_type_id') {
      if (this.programme_search_form.value.purpose != '') {
        this.onPurposeChange();
      }
    }
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.programme_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.programme_search_form);
      if (id === 'programme_type_id' && this.programme_search_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.programme_search_form.value.programme_type_id);
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
        console.log(this.programme_list);
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  getPaymentStatus(status) {
    return this.payment_status.find(x => x.id == status).name;
  }

  async onResetClick(): Promise<void> {
    this.start_index = 0;
    this.take_index = 500;
    this.total_records = 0;
    this.fees_report = [];
  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }
  }

  getPurpose(purpose: string) {
    return this.purposes.find(x => x.id == purpose).name;
  }

  async getReport() {
    var form_data = this.programme_search_form.value
    if (form_data.purpose == '') {
      alert("Please choose a purpose for report generation");
      return;
    }
    console.log(form_data);
    const search_query: any = {
      //payment_collected:false,
      skip: this.start_index,
      take: this.take_index
    };
    if (!form_data.purpose) {
      alert("Please choose purpose for report");
      return;
    }
    if (form_data.start_date) {
      search_query.start_date = new Date(form_data.start_date).toISOString();
    }
    if (form_data.end_date) {
      search_query.end_date = new Date(form_data.end_date).toISOString()
    }
    if (form_data.programme_type_id) {
      search_query.programme_type_id = form_data.programme_type_id
    }
    if (form_data.programme_id) {
      search_query.programme_id = form_data.programme_id
    }
    if (form_data.finance_type) {
      search_query.finance_type = form_data.finance_type
    }
    if (form_data.enrolled_year) {
      search_query.enrolled_year = form_data.enrolled_year
    }
    if (form_data.section) {
      search_query.section = form_data.section
    }
    if (form_data.payment_collected != 'd' && form_data.payment_collected == true) {
      search_query.payment_collected = true;
    }
    if (form_data.payment_collected != 'd' && form_data.payment_collected == false) {
      search_query.payment_collected = false;
    }
    if (form_data.purpose) {
      search_query.purpose = form_data.purpose
    }
    if (form_data.fee_header_id) {
      search_query.fee_header_id = form_data.fee_header_id
    }
    if (form_data.community_ids) {
      search_query.community_ids = form_data.community_ids;
    }
    if (form_data.split_start_date) {
      search_query.split_start_date = new Date(form_data.split_start_date).toISOString();
    }
    if (form_data.split_end_date) {
      search_query.split_end_date = new Date(form_data.split_end_date).toISOString()
    }
    if (form_data.split_status != 'd' && form_data.split_status == true) {
      search_query.split_status = true;
    }
    if (form_data.split_status != 'd' && form_data.split_status == false) {
      search_query.split_status = false;
    }
    console.log(search_query);
    this.split_headers = [];
    this.fees_report = []; this.total_records = 0;
    this.report_list_loading = true;
    try {
      const service_response = await this.restService.searchFees(search_query);
      if (service_response && service_response.success) {
        this.fees_report = service_response.data?.data;
        this.total_records = service_response.data?.count;
        console.log(this.fees_report);
        if (this.fees_report.length == 0) {
          alert("No Students Record Found");
          this.report_list_loading = false;
          return;
        }
        this.fees_report.forEach(report => {
          if (report?.split_data_list && report?.split_data_list?.length > this.max_splits_cols)
            this.max_splits_cols = report?.split_data_list?.length;
        });
        this.generateSplitHeaders(this.fees_report[0].split_data_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.report_list_loading = false;
  }

  getSplitStatus(split_status) {
    return this.split_status.find(x => x.id == split_status).name;
  }
  generateSplitHeaders(splits: any[]) {
    this.split_headers = [];
    for (var i = 0; i < splits.length; i++)
      this.split_headers.push(splits[i].sub_acc_id + " (" + splits[i].account_no + ")");
  }
  showSplit(ev: any) {

  }

  viewTransactionHistory(transaction_header_id: string, roll_no: string) {
    /* this.router.navigate(["/admin/accounts/transaction-report"],{queryParams:{
      transaction_header_id: transaction_header_id
    }}) */

    /* HTMLHelper.openLinkInNewTab("/admin/accounts/transaction-report?transaction_header_id=" + transaction_header_id
      + "&roll_no=" + roll_no
    ); */
    window.open("/admin/accounts/transaction-report?transaction_header_id=" + transaction_header_id
      + "&roll_no=" + roll_no, "_blank");
  }

  async onCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-popup');
  }
  downloadCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const fees of this.fees_report) {
      data_list.push({
        "S.No": i,
        "Roll No.": fees.roll_no,
        "Name": fees.name,
        "Gender": this.getGender(fees.gender),
        "Class": this.commonEnums.getClassName(fees.enrolled_year, fees.programme_name, fees.section),
        "Paid Date": fees?.payment_date?.substring(0, 10),
        "Original Fees": this.getOriginalAmount(fees),
        "Fine": fees.fine,
        "Total": fees.amount,
        "Purpose": fees.purpose,
        "Month": fees.term_name,
        "Payment Status": this.getPaymentStatus(fees.payment_collected),
        "Split Status": (fees?.split_status == true) ? 'Collected' : 'Not Collected'
      });
      i++;
    }
    CSVHelper.downloadCSV(data_list, this.programme_search_form.value.purpose + "_" + DateHelper.convertToControlDate(new Date()));
  }

  getTotalAmount(report: any) {
    if (report.fine == 0 || isNaN(report.fine) || report.fine == null || report.fine == undefined) {
      return report.amount;
    }
    return report.amount + report.fine;
  }

  getOriginalAmount(report: any) {
    if (report.fine == 0 || isNaN(report.fine) || report.fine == null || report.fine == undefined) {
      return report.original_amount;
    }
    return report.original_amount - report.fine;
  }

  disableApplyFine() {
      if(this.fees_report.length > 0 && this.programme_search_form.value.fee_header_id  != ''
      && this.programme_search_form.value.purpose == "EXAM_FEE" && this.programme_search_form.value.payment_collected == false){
        return false;
    }
    return true;
  }

  async applyExamFeeFine() {
    if(this.programme_search_form.value.purpose != "EXAM_FEE") {
      alert("Applicable for exam fee only"); return;
    }

    if(this.programme_search_form.value.payment_collected != false) {
      alert("Applicable for not paid students"); return;
    }

    if(!this.programme_search_form.value.fee_header_id) {
      alert("Please choose term"); return;
    }

    if(!this.programme_search_form.value.programme_type_id) {
      alert("Please select programme type id"); return;
    }

    if(!this.programme_search_form.value.programme_id) {
      alert("Please select programme name"); return;
    }

    if(!this.programme_search_form.value.finance_type) {
      alert("Please select finance type"); return;
    }

    try {
      const consent = confirm("Are you sure want to apply this fine?");
      if (!consent) {
        return;
      }
      this.apply_fine_loading = true;
      const transaction_header_ids = this.fees_report.map(fr => fr.transaction_header_id);
      const query: any= {}
      query['transaction_header_ids'] = transaction_header_ids;
      query['exam_fee_header_id'] = this.programme_search_form.value.fee_header_id;
      const service_response = await this.restService.applyFineExamFee(query);
      if(service_response.success && service_response.data == true){
        this.onSearchClick();
        alert("Fine Applied successfully");
      } else {
        alert("Something went wrong")
      }
    } catch (err) {
      if(err.error?.error_description) {
        alert(err.error.error_description);
      } else {
        alert("Error while read data");
      }
    } finally {
      this.apply_fine_loading = false;
    }
  }

  downloadPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>.table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-table').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  /* async  testWrite() {
    let fieldDescriptors:FieldDescriptor []= [
        { name: 'fname', type: 'C', size: 255 },
        { name: 'lname', type: 'C', size: 255 }
    ];

    let records = [
        { fname: 'Joe', lname: 'Bloggs' },
        { fname: 'Mary', lname: 'Smith' }
    ];

    let dbf = await DBFFile.create('sample.dbf', fieldDescriptors);
    console.log('DBF file created.');
    await dbf.appendRecords(records);
    console.log(`${records.length} records added.`);
} */

  downloadDBF() {

  }

  getGender(gender: string) {
    return gender.charAt(0).toUpperCase() + gender.slice(1);
  }
}

interface FeesReportInterface {
  //"programme_type_id":string,
  //
  //"programme_id":string,
  "enrolled_year": number,
  "section": string,
  "payment_date": string,
  "transaction_header_id": string,
  "programme_name": string,
  "finance_type": string,
  "payment_collected": boolean,
  "amount": number,
  "fine": number,
  "original_amount": number,
  "purpose": string,
  "term_name": string,
  "roll_no": string,
  "name": string,
  "gender": string,
  "split_data_list": any[],
  "split_status": boolean,
}
