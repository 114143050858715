import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-hostel-wise-attendance',
  templateUrl: './hostel-wise-attendance.component.html',
  styleUrls: ['./hostel-wise-attendance.component.scss']
})
export class HostelWiseAttendanceComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }

  ngOnInit(): void {
  }

  
  url : string = "";
  
  getURL(){
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/hostel-v2/hostel-level-attendance-6443e0509fce2c3c1ac7ed38?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }


}
