import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { IAddress } from 'src/app/services/rest/entity/IAddress';
import { IAddressExtended } from 'src/app/services/rest/entity/IAddressExtended';
import { IStaffDepartmentMap } from 'src/app/services/rest/entity/IStaffDepartmentMap';
import { IStaffs } from 'src/app/services/rest/entity/IStaffs';


@Component({
  selector: 'app-manage-staffs',
  templateUrl: './manage-staffs.component.html',
  styleUrls: ['./manage-staffs.component.scss']
})
export class ManageStaffsComponent implements OnInit {


  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  department_type_list: any[] = [];
  department_type_list_loading = false;

  department_list: any[] = [];
  department_list_loading = false;

  department_search_form: any;

  department_delete_loading: boolean[] = [];
  experience_delete_loading: boolean[] = [];

  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];


  // core data

  staffs_list: any[] = [];
  staffs_list_loading = false;

  staff: IStaffs | undefined = undefined;
  staff_form: any;
  staff_save_loading = false;
  staff_delete_loading = false;


  change_password_form: any;
  change_password_save_loading = false;

  //Adding Roles and Permissions
  roles_permissions: {
    role_name: string,
    permissions: string[]
  }[] = [];
  assigned_roles: {
    staff_name: string,
    staff_id: string,
    sub: string,
    roles: string[],
    permissions: string[]
  } = { staff_id: "", staff_name: "", sub: "", roles: [], permissions: [] };
  all_department_list: any[] = [];
  roles: RoleInterface[] = [];
  role_list_loading: boolean = false;
  user_role_data: UserRoleInterface = {} as UserRoleInterface;
  role_save_loading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.roles_permissions.push(
      {
        role_name: "Admin",
        permissions: ["access_all", "read", "read_write", "write_delete", "read_delete", "write"]
      },
      {
        role_name: "HOD",
        permissions: ["access_all", "read", "read_write", "write"]
      },
      {
        role_name: "Staff",
        permissions: ["read", "write"]
      },
      {
        role_name: "AARC Member",
        permissions: ["read", "write", "write_delete", "read_delete"]
      }
    );
  }

  ngOnInit(): void {

    // Load master data
    this.getDepartmentTypeList();
    this.buildFilterForm();
    this.getAllDepartmentDataList();

    // Load core data
  }



  /// master data

  async getDepartmentTypeList(): Promise<void> {
    try {
      this.department_type_list_loading = true;
      this.department_type_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.department_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read department type');
    } finally {
      this.department_type_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.department_search_form = this.fb.group({
      department_category_id: [''],
      finance_type: [''],
      department_id: [''],
      gender: [''],
      roll_no: [''],
    });

  }

  // build staff form
  buildForm(): void {

    if (!this.staff) {
      this.staff = ({} as IStaffs);
      this.staff.gender = '';
      this.staff.salutation = '';
      this.staff.salutation = '';
      this.staff.aadhar_card_no = '';
      this.staff.blood_group = '';
      this.staff.caste = '';
      this.staff.community_id = '';
      this.staff.qualification = '';
      this.staff.physically_challanged = false;


      this.staff.pan_number = "";
      this.staff.father_name = "";
      this.staff.husband_name = "";
      this.staff.bank_name = "";
      this.staff.bank_location = "";
      this.staff.bank_account_number = "";
      this.staff.bank_ifsc_code = "";

      this.staff.academics = [];
      this.staff.address = ({} as IAddressExtended);
      this.staff.address.present = ({} as IAddress);
      this.staff.address.communication = ({} as IAddress);

      this.staff.experience = [];
    }

    if (!this.staff.address) {
      this.staff.address = ({} as IAddressExtended);
      this.staff.address.present = ({} as IAddress);
      this.staff.address.communication = ({} as IAddress);
    }
    if (!this.staff.address.present) {
      this.staff.address.present = ({} as IAddress);
    }
    if (!this.staff.address.communication) {
      this.staff.address.communication = ({} as IAddress);
    }

    this.staff_form = this.fb.group({
      _id: [this.staff._id],
      sub: [this.staff.sub, [Validators.required]],
      aadhar_card_no: [this.staff.aadhar_card_no, [Validators.required]],
      email: [this.staff.email, [Validators.required]],
      personal_email: [this.staff.personal_email, [Validators.required]],
      given_name: [this.staff.given_name, [Validators.required]],
      middle_name: [this.staff.middle_name, [Validators.required]],
      mobile_number: [this.staff.mobile_number, [Validators.required]],

      birth_date: [DateHelper.convertToControlDate(this.staff.birth_date), [Validators.required]],
      gender: [this.staff.gender, [Validators.required]],
      blood_group: [this.staff.blood_group, [Validators.required]],
      community_id: [this.staff.community_id, [Validators.required]],
      caste: [this.staff.caste, [Validators.required]],
      religion: [this.staff.religion, [Validators.required]],
      address: this.fb.group({
        present: this.fb.group({
          street: [this.staff.address.present.street, [Validators.required]],
          city: [this.staff.address.present.city, [Validators.required]],
          district: [this.staff.address.present.district, [Validators.required]],
          state: [this.staff.address.present.state, [Validators.required]],
          country: [this.staff.address.present.country, [Validators.required]],
        }),
        communication: this.fb.group({
          street: [this.staff.address.present.street, [Validators.required]],
          city: [this.staff.address.present.city, [Validators.required]],
          district: [this.staff.address.present.district, [Validators.required]],
          state: [this.staff.address.present.state, [Validators.required]],
          country: [this.staff.address.present.country, [Validators.required]],
        }),
      }),
      nationality: [this.staff.nationality, [Validators.required]],
      physically_challanged: [this.staff.physically_challanged, [Validators.required]],
      salutation: [this.staff.salutation, [Validators.required]],
      designation: [this.staff.designation, [Validators.required]],
      qualification: [this.staff.qualification, [Validators.required]],
      roll_no: [this.staff.roll_no, [Validators.required]],
      date_of_joining: [this.staff.date_of_joining, [Validators.required]],

      departments: this.fb.array(this.buildDepartmentForm(this.staff.departments)),

      pan_number: [this.staff.pan_number],
      father_name: [this.staff.father_name, [Validators.required]],
      husband_name: [this.staff.husband_name],
      bank_name: [this.staff.bank_name],
      bank_location: [this.staff.bank_location],
      bank_account_number: [this.staff.bank_account_number],
      bank_ifsc_code: [this.staff.bank_ifsc_code],

      experience: this.fb.array(this.buildExperienceForm(this.staff.experience)),

    });
  }

  buildDepartmentForm(departments): any {
    if (!departments) {
      departments = [];
    }
    var department_list_array: any[] = [];
    if (departments && departments.length > 0) {
      var department_count = 0;
      for (const department of departments) {
        //console.log(component)
        this.department_delete_loading[department_count] = false;
        department_list_array.push(this.fb.group({
          sub: [department.sub],
          department_id: [department.department_id],
          designation: [department.designation],
        }));
        department_count++;
      }
    }
    const remaining_count = 1 - departments.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.department_delete_loading[index] = false;
        department_list_array.push(this.fb.group({
          sub: [this.staff.sub],
          department_id: ['', [Validators.required]],
          designation: ['', [Validators.required]],
        }));
      }
    }
    console.log(department_list_array);
    return department_list_array;
  }

  get departmentList() {
    return ((this.staff_form as FormGroup).get('departments') as FormArray).controls;
  }

  addDepartment() {
    ((this.staff_form as FormGroup).get('departments') as FormArray).push(this.fb.group({
      sub: [this.staff.sub],
      department_id: ['', [Validators.required]],
      designation: ['', [Validators.required]],
    }));
  }
  deleteDepartment(index: number) {
    const consent = confirm("Are you sure want to delete this department?");
    if (!consent) return;
    ((this.staff_form as FormGroup).get('departments') as FormArray).removeAt(index);
  }

  buildExperienceForm(experiences): any {
    if (!experiences) {
      experiences = [];
    }
    var experience_list_array: any[] = [];
    if (experiences && experiences.length > 0) {
      var experience_count = 0;
      for (const experience of experiences) {
        //console.log(component)
        this.experience_delete_loading[experience_count] = false;
        experience_list_array.push(this.fb.group({
          employeer_name: [experience.employeer_name],
          employeer_location: [experience.employeer_location],
          years_of_experience: [experience.years_of_experience],
          start_date: [(experience.start_date ? DateHelper.convertToControlDate(new Date(experience.start_date)) : DateHelper.convertToControlDate(new Date()))],
          end_date: [(experience.end_date ? DateHelper.convertToControlDate(new Date(experience.end_date)) : DateHelper.convertToControlDate(new Date()))],
          remarks: [experience.remarks],
        }));
        experience_count++;
      }
    }
    const remaining_count = 1 - experiences.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.department_delete_loading[index] = false;
        experience_list_array.push(this.fb.group({
          employeer_name: [''],
          employeer_location: [''],
          years_of_experience: [''],
          start_date: [''],
          end_date: [''],
          remarks: [''],
        }));
      }
    }
    console.log(experience_list_array);
    return experience_list_array;
  }

  get experienceList() {
    return ((this.staff_form as FormGroup).get('experience') as FormArray).controls;
  }

  addExperience() {
    ((this.staff_form as FormGroup).get('experience') as FormArray).push(this.fb.group({
      employeer_name: [''],
      employeer_location: [''],
      years_of_experience: [''],
      start_date: [''],
      end_date: [''],
      remarks: [''],
    }));
  }
  deleteExperience(index: number) {
    this.experience_delete_loading[index] = true;
    const consent = confirm("Are you sure want to delete this department?");
    if (!consent) return;
    ((this.staff_form as FormGroup).get('experience') as FormArray).removeAt(index);
    this.experience_delete_loading[index] = false;
  }


  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }
  resetForm(): void {
    this.staff_form = undefined;
    this.staff = undefined;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'department_category_id' || id === 'finance_type') {
      this.getDepartmentDataList();
    }

  }



  async getDepartmentDataList(): Promise<void> {
    try {
      this.department_list_loading = true;
      this.department_list = [];
      const search_form_query: any = {

      };
      if (this.department_search_form) {
        if (this.department_search_form.value.department_category_id) {
          search_form_query.department_category_id = this.department_search_form.value.department_category_id;
        }
        if (this.department_search_form.value.finance_type) {
          search_form_query.finance_type = this.department_search_form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_list_loading = false;
    }
  }

  async getAllDepartmentDataList(): Promise<void> {
    try {
      this.all_department_list = [];
      const search_form_query: any = {

      };
      /* if (this.department_search_form) {
        if (this.department_search_form.value.department_category_id) {
          search_form_query.department_category_id = this.department_search_form.value.department_category_id;
        }
        if (this.department_search_form.value.finance_type) {
          search_form_query.finance_type = this.department_search_form.value.finance_type;
        }
      } */
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.all_department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }





  async onAddClick(): Promise<void> {
    if (!this.department_search_form.value.department_id) {
      alert('Please select department');
      return;
    }
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onRollNoChange(data: any): Promise<void> {
    const roll_no = this.staff_form.value.roll_no;
    if (roll_no && roll_no.length > 2) {
      const staff_response = await this.restService.findStaffInfo({ roll_no });
      if (staff_response && staff_response.data) {
        this.staff = staff_response.data;
        this.buildForm();
      }
    }

  }

  async onEditClick(staff: any): Promise<void> {

    const staff_response = await this.restService.getStaffInfo(staff.sub);
    if (staff_response && staff_response.data) {
      this.staff = staff_response.data;
      this.buildForm();
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    }

  }

  async onSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      console.log(JSON.stringify(this.staff_form.value, null, 4));
      const staff = this.staff_form.value;

      //no need this code
      /* staff.departments = [];
      const depart_map = {} as IStaffDepartmentMap;
      depart_map.department_id = this.department_search_form.value.department_id;
      staff.departments.push(depart_map); */


      let saved_staff_response;
      if (staff._id) {
        saved_staff_response = await this.restService.updateStaffInfo(staff);
      } else {
        saved_staff_response = await this.restService.createStaffInfo(staff);
      }
      if (saved_staff_response && saved_staff_response.success === true) {
        alert('Saved Successfully');
        this.onCloseClick();
        await this.searchFormDataList();
      } else {
        alert('Error while saving');
      }


    } catch (error) {
      alert('Error while saving');
    } finally {

    }
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }




    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }



  async onSearchClick(): Promise<void> {
    await this.searchFormDataList();

  }

  async searchFormDataList(): Promise<void> {
    try {
      let valid_search = false;
      if (!this.department_search_form.value.department_id) {
        alert('Please select department');
        return;
      }
      if (this.department_search_form.value.department_id) {
        valid_search = true;
      }
      if (this.department_search_form.value.department_category_id) {
        valid_search = true;
      }
      if (this.department_search_form.value.finance_type) {
        valid_search = true;
        if (!this.department_search_form.value.department_category_id) {
          alert('Please select department type');
          return;
        }
      }

      if (!valid_search) {
        alert('Select any one filter');
        return;
      }

      this.staffs_list_loading = true;
      this.staffs_list = [];
      const search_form_query: any = {
        department_id: this.department_search_form.value.department_id
      };
      if (this.department_search_form.value.gender) {
        search_form_query.gender = this.department_search_form.value.gender;
      }
      if (this.department_search_form.value.department_category_id) {
        search_form_query.department_category_id = this.department_search_form.value.department_category_id;
      }
      if (this.department_search_form.value.roll_no) {
        search_form_query.roll_no = this.department_search_form.value.roll_no;
      }
      if (this.department_search_form.value.finance_type) {
        search_form_query.finance_type = this.department_search_form.value.finance_type;
      }
      const service_response = await this.restService.searchStaff(search_form_query);
      if (service_response && service_response.success) {
        this.staffs_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.staffs_list_loading = false;
    }
  }

  // Reset Password
  async onInitResetPasswordClick(staff: any): Promise<void> {
    this.buildResetPassordForm(staff.sub);
    JQueryHelper.openModal('#modal-popup-resetpassword', { keyboard: false, backdrop: 'static' });
  }

  buildResetPassordForm(sub: string): void {

    this.change_password_form = this.fb.group({
      sub: [sub, [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    });
  }

  async onResetPassordCloseClick(): Promise<void> {
    this.resetResetPassordForm();
    JQueryHelper.closeModal('#modal-popup-resetpassword');
  }
  resetResetPassordForm(): void {
    this.change_password_form = undefined;
  }

  async onFinishResetPasswordClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to reset the password?');
      if (!consent) {
        return;
      }
      this.change_password_save_loading = true;
      console.log(JSON.stringify(this.change_password_form.value, null, 4));
      if (this.change_password_form.value.password !== this.change_password_form.value.confirm_password) {
        alert('Password and Confirm passwords are not matching');
        return;
      }
      const saved_staff_response = await this.restService.resetUserPassword(this.change_password_form.value);
      if (saved_staff_response && saved_staff_response.success === true) {
        alert('Resetted Successfully');
        this.onResetPassordCloseClick();
      } else {
        alert('Error while Reset');
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.change_password_save_loading = false;
    }
  }



  downloadAsCSV(): void {
    const data_list: any[] = [];
    let i = 1;
    for (const staff of this.staffs_list) {
      data_list.push({
        'S.No': i,
        'ROLL NO.': staff.roll_no,
        NAME: staff.given_name + (staff.middle_name ? ' ' + staff.middle_name : ''),
        GENDER: staff.gender ? staff.gender.toUpperCase() : 'N/A',
        'MOBILE NO.': staff.mobile_number,
        EMAIL: staff.email,
        'PROGRAMME NAME': staff.department_name + (staff.finance_type === 'regular' ? ' (R)' : ' (SF)'),
      });
      i++;
    }



    CSVHelper.downloadCSV(data_list, 'staffs-list-as-of-' + DateHelper.convertToControlDate(new Date()));
  }

  async getRoles() {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getRolesList();
      if (response && response.success) {
        this.roles = response.data;
        this.roles.forEach(role => {
          role.is_allowed = false;
        })
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_list_loading = false;
    }

  }

  async onAssignRoles(staff: any): Promise<void> {
    await this.getRoles();
    this.user_role_data.email = staff.email;
    this.user_role_data.roles = staff.roles;
    this.user_role_data.sub = staff.sub;
    this.user_role_data.name = staff.given_name + " " + staff.family_name;
    this.user_role_data.roles.forEach(role => {
      this.roles.find(x => x.role_key == role)!.is_allowed = true;
    })
    /* const staff_data = {
      email: staff.email
    }; */

    /* try {
      this.role_list_loading = true;
      const response = await this.restService.getUserRoles(staff_data);
      if (response && response.success) {
        this.user_role_data = response.data;
        this.user_role_data.roles.forEach(role => {
          this.roles.find(x => x.role_key == role)!.is_allowed = true;
        })
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_list_loading = false;
    } */


    JQueryHelper.openModal('#modal-popup-assignrole', { keyboard: false, backdrop: 'static' });
  }

  selectRole(event: any) {
    /* var role = event.target.value;
    if (event.target.checked) //role to be added and add its permissions to list
    {
      this.assigned_roles.roles.push(role);
    }
    else //remove role and remove its permissions from list
    {
      this.assigned_roles.roles.splice(this.assigned_roles.roles.indexOf(role), 1);
    }
    this.assigned_roles.permissions = [];
    this.assigned_roles.roles.forEach(role => {
      this.roles_permissions.find(x => x.role_name == role)?.permissions.forEach(permission => {
        if (this.assigned_roles.permissions.indexOf(permission) < 0) {
          this.assigned_roles.permissions.push(permission);
        }
      });
    });
    console.log(this.assigned_roles); */
  }

  switchPermissionVisibility(role_name: string) {
    if (document.getElementById(role_name + "_list")?.style.display != "block") {
      document.getElementById(role_name + "_list")?.style.setProperty("display", "block");
    }
    else {
      document.getElementById(role_name + "_list")?.style.setProperty("display", "none");
    }
  }

  async onSaveRoles() {
    var form_data = this.user_role_data;
    form_data.roles = [];
    this.roles.filter(x => x.is_allowed == true).forEach(role => {
      form_data.roles.push(role.role_key);
    });
    if (form_data.roles.length == 0) {
      alert("Assign minimum one role to a user!");
      return;
    }
    console.log(form_data);
    try {
      this.role_save_loading = true;
      const consent = confirm("Are you sure want to save?");
      if (!consent) return;
      const response = await this.restService.assignRoleToUser(form_data);
      if (response && response.success) {
        alert("Roles Assigned successfully.");
      }
      this.onSearchClick();
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_save_loading = false;
    }
  }

  onResetRoles() {
    this.roles.forEach(role => role.is_allowed = false);
  }

  onCloseRoles() {
    this.user_role_data = {} as UserRoleInterface;
    this.roles = [];
    JQueryHelper.closeModal('#modal-popup-assignrole');
  }

}
interface RoleInterface {
  id?: string,
  _id?: string,
  role_key: string,
  role_description?: string,
  role_name?: string,
  is_allowed?: boolean,
}

interface UserRoleInterface {
  email: string,
  group_id?: string,
  name: string,
  roles: string[],
  sub: string
} 