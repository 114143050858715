<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Generate Holistic Attainment Report for students
                        </p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolled year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">
                                            Roll No.</label>
                                        <input type="text" class="form-control" formControlName="roll_no"
                                            placeholder="Enter Roll No.">
                                    </div>
                                </div>




                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="row" *ngIf="holistic_attainment_report?._id">
                          <div class="col-md-10" style="text-align: right;">
                               <button class="btn btn-sm btn-secondary" (click)="downloadPDF()">Download Over all academics PDF</button>
                          </div>
                          <div class="col-md-2" style="text-align: right;">
                            <button class="btn btn-sm btn-secondary" (click)="downloadPDF()">Download Non-academics PDF</button>
                       </div>
                        </div>

                        <div id="report-container" *ngIf="holistic_attainment_report" style="margin-top: 15px;" id="attainment-html">
                            <div id="header" style="border: 0.5px solid black; padding: 10px;">
                                <div class="row">
                                    <div class="col-md-2">
                                        <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                                            alt="college logo"
                                            style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                                    </div>
                                    <div class="col-md-8 text-center">
                                        <h5>AYYA NADAR JANAKI AMMAL COLLEGE</h5>
                                        <p>
                                            (Autonomous, affiliated to Madurai Kamaraj University, Re-accredited (4th
                                            Cycle) with 'A+' grade (CGPA 3.48
                                            out of 4) by
                                            NAAC, recoganized as College of Excellence and Mentor Institution by UGC,
                                            Star College by DBT, Ranked 69th
                                            at National
                                            Level in NIRF 2023 and DST-FIST (2023) Supported & ISO 9001:2015 Certified
                                            Institution)
                                        </p>
                                    </div>
                                    <div class="col-md-2">

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <p><b>OUTCOME BASED EDUCATION - HOLISTIC ATTAINMENT GRADE SHEET</b></p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Name of student in Tamil</b>
                                    </div>
                                    <div class="col-md-3">

                                    </div>
                                    <div class="col-md-3">
                                        <b>Reg No.</b>
                                    </div>
                                    <div class="col-md-3">
                                        {{holistic_attainment_report.roll_no}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Name of student in English</b>
                                    </div>
                                    <div class="col-md-3">
                                        {{holistic_attainment_report.given_name}}
                                        {{holistic_attainment_report.middle_name}}
                                    </div>
                                    <div class="col-md-3">
                                        <b>Date of birth</b>
                                    </div>
                                    <div class="col-md-3">
                                        {{holistic_attainment_report.date_of_birth | date}}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Programme of study</b>
                                    </div>
                                    <div class="col-md-3">
                                        {{holistic_attainment_report.programme_name}}
                                    </div>
                                    <div class="col-md-3">
                                        <b>Medium of study</b>
                                    </div>
                                    <div class="col-md-3">
                                        English
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <b>Year of study</b>
                                    </div>
                                    <div class="col-md-3">
                                        {{holistic_attainment_report.enrolled_year}}
                                    </div>
                                </div>

                            </div>

                            <div class="academic-attainment" style="margin-top: 15px;">
                                <h6>I. ATTAINMENT OF ACADEMIC SKILLS</h6>
                                <div class='table-responsive'>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th rowspan="3">CO Levels</th>
                                                <th rowspan="3">Skills</th>
                                                <th
                                                    [attr.colspan]="academic_attainment_data.part_wise_data?.length * 2">
                                                    Points
                                                    Scored
                                                    out of 10</th>
                                                <th rowspan="2" colspan="2">Overall Grade</th>
                                            </tr>
                                            <tr>
                                                <ng-container
                                                    *ngFor="let part of academic_attainment_data.part_wise_data">
                                                    <th colspan="2">{{part.part_type}}</th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <ng-container
                                                    *ngFor="let part of academic_attainment_data.part_wise_data">
                                                    <th>GPS</th>
                                                    <th>CAGP</th>
                                                </ng-container>
                                                <th>GA</th>
                                                <th>CAG</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of co_levels; let i = index;">
                                                <td>{{row.co_text}}</td>
                                                <td>{{row.co_skill}}</td>
                                                <ng-container
                                                    *ngFor="let part of academic_attainment_data?.part_wise_data">
                                                    <td>{{part.part_average_co_marks.gps_marks[row.co_id]}}</td>
                                                    <td>{{part.part_average_co_marks.cagp_marks[row.co_id]}}</td>
                                                </ng-container>
                                                <td [attr.style]="getBackgroundColor(
                                                    academic_attainment_data?.final_co_grades?.gps_marks[row.co_id]
                                                )">{{academic_attainment_data?.final_co_grades?.gps_marks[row.co_id]}}
                                                </td>
                                                <td [attr.style]="getBackgroundColor(
                                                    academic_attainment_data?.final_co_grades?.cagp_marks[row.co_id]
                                                )">{{academic_attainment_data?.final_co_grades?.cagp_marks[row.co_id]}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="non-academic-attainment">
                                <h6>II. ATTAINMENT OF NON ACADEMIC SKILLS</h6>
                                <div class='table-responsive'>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S.No. </th>
                                                <th>Name of the Non Academic Skill</th>
                                                <th>Grade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let skill of non_academic_attainment_data; let i = index;">
                                                <td>{{i+1}}</td>
                                                <td>{{skill.skillset_name}}</td>
                                                <td>{{skill.skillset_grade}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Exhibiting values -->
                            <div class="exhibiting-values" *ngIf="CookieStore.isRoleExists(['hod'])">
                              <h6>III. EXHIBITING VALUES</h6>
                              <form [formGroup]="exhibiting_value_form">
                                <div class="row row-sm">
                                  <div class="col-lg-2">
                                      <div class="form-group">
                                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Exhbiting values</label>
                                          <select formControlName="exhbiting_values" class="form-control" [(ngModel)]="exhibiting_value_selected">
                                              <option [disabled] value="">Select Exhibiting values</option>
                                              <option
                                                  *ngFor="let exhibiting of exhibiting_values;let i=index;trackBy:fromDataResolver.identify;"
                                                  [ngValue]="exhibiting.id">{{exhibiting.value}}</option>
                                          </select>
                                      </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Submit" *ngIf="!exhibiting_values_loading" (click)="onSubmitExhibitingValue()">
                                            Submit
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="exhibiting_values_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>

                            <div *ngIf="!(CookieStore.isRoleExists(['hod']))">
                              <h6>III. EXHIBITING VALUES : {{exhibiting_value_selected}}</h6>
                            </div>

                            <!--Graph Section-->
                            <div>
                              <canvas id="acadamicgraph"></canvas>
                            </div>
                            <!-- <canvas #barCanvas></canvas> -->

                            <div class="row" *ngIf="holistic_attainment_report?._id">
                              <div class="col-md-12" style="text-align: right;">
                                  <button class="btn btn-sm btn-secondary mg-r-10" (click)="downloadExcel()">Download
                                      Excel</button>
                              </div>
                            </div>

                            <!--Breakup Table-->
                            <div class="table-responsive">
                                <table class="table table-bordered" id="report-table">
                                    <thead>
                                        <tr>
                                            <th colspan="12" class="text-center">
                                                <h6>AYYA NADAR JANAKI AMMAL COLLEGE (Autonomous), SIVAKASI</h6>
                                                <h6>OBE Holistic Attainment - Attainment of Academic Skills (Course-wise
                                                    Breakup)</h6>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colspan="4">
                                                <b>Name of the Student: </b>
                                                <span>{{holistic_attainment_report.given_name}}
                                                    {{holistic_attainment_report.middle_name}}</span>
                                            </th>
                                            <th colspan="4">
                                                <b>Reg. No.: </b> <span>{{holistic_attainment_report.roll_no}}</span>
                                            </th>
                                            <th colspan="4">
                                                <b>Date of Birth: </b>
                                                <span>{{holistic_attainment_report.date_of_birth | date}}</span>
                                            </th>
                                        </tr>

                                        <tr>
                                            <th colspan="4">
                                                <b>Programme of Study: </b>
                                                <span>{{holistic_attainment_report.programme_name}}</span>
                                            </th>
                                            <th colspan="4">
                                                <b>Year of Study:</b>
                                                <span>{{holistic_attainment_report.enrolled_year}}</span>
                                            </th>
                                            <th colspan="4">

                                            </th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        <tr>
                                            <th>Component & Subject Code</th>
                                            <th>Title of the Course</th>
                                            <ng-container
                                                *ngFor="let weight of holistic_attainment_report?.attainment_academic_skills_scores?.final_co_grades?.cagp_marks | keyvalue;">
                                                <th colspan="2">
                                                    {{weight.key | uppercase}}
                                                </th>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <td colspan="2"></td>
                                            <ng-container
                                                *ngFor="let weight of holistic_attainment_report?.attainment_academic_skills_scores?.final_co_grades?.cagp_marks | keyvalue;">
                                                <th>GPS</th>
                                                <th>CAGP</th>
                                            </ng-container>
                                        </tr>
                                        <ng-container
                                            *ngFor="let part of holistic_attainment_report.attainment_academic_skills_scores?.part_wise_data; let i = index;">
                                            <ng-container *ngFor="let semester of part.semesters_data; let j = index;">
                                                <tr>
                                                    <th>{{part.part_type | uppercase}}</th>
                                                    <th>{{semester.semester}} Semester</th>
                                                    <ng-container *ngFor="let row of getEmptyRows(10)">
                                                        <td></td>
                                                    </ng-container>
                                                </tr>
                                                <tr *ngFor="let course of semester.course_data; let k = index">
                                                    <td>{{course.course_code}}</td>
                                                    <td>{{course.course_name}}</td>
                                                    <ng-container
                                                        *ngFor="let itr of course.gps_marks| keyvalue; let l = index;">
                                                        <td>{{course.gps_marks[itr.key]}}</td>
                                                        <td>{{course.cagp_marks[itr.key]}}</td>
                                                    </ng-container>
                                                </tr>
                                            </ng-container>

                                            <!--Part Total Rows-->
                                            <tr>
                                                <td></td>
                                                <th>Total</th>
                                                <ng-container
                                                    *ngFor="let itr of part.part_total_marks.gps_marks| keyvalue; let in = index;">
                                                    <td>{{part.part_total_marks.gps_marks[itr.key]}}</td>
                                                    <td>{{part.part_total_marks.cagp_marks[itr.key]}}</td>
                                                </ng-container>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <th>Average CO Mark</th>
                                                <ng-container
                                                    *ngFor="let itr of part.part_total_marks.gps_marks| keyvalue; let in = index;">
                                                    <td>{{part.part_average_co_marks.gps_marks[itr.key]}}</td>
                                                    <td>{{part.part_average_co_marks.cagp_marks[itr.key]}}</td>
                                                </ng-container>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <th>Grade</th>
                                                <ng-container
                                                    *ngFor="let itr of part.part_total_marks.gps_marks| keyvalue; let in = index;">
                                                    <td>{{part.part_grade.gps_marks[itr.key]}}</td>
                                                    <td>{{part.part_grade.cagp_marks[itr.key]}}</td>
                                                </ng-container>
                                            </tr>

                                        </ng-container>

                                        <tr>
                                            <td></td>
                                            <th>Grant Total COs in all semesters</th>
                                            <ng-container
                                                *ngFor="let itr of holistic_attainment_report?.attainment_academic_skills_scores?.average_total_cos_semester?.cagp_marks | keyvalue; let in = index;">
                                                <th>{{holistic_attainment_report?.attainment_academic_skills_scores?.average_total_cos_semester?.gps_marks[itr.key]}}
                                                </th>
                                                <th>{{holistic_attainment_report?.attainment_academic_skills_scores?.average_total_cos_semester?.cagp_marks[itr.key]}}
                                                </th>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <th>Cumulative Attainment</th>
                                            <th>Average Final CO Levelled to 10</th>
                                            <ng-container
                                                *ngFor="let itr of holistic_attainment_report?.attainment_academic_skills_scores?.average_final_co_level?.cagp_marks | keyvalue; let in = index;">
                                                <th>{{holistic_attainment_report?.attainment_academic_skills_scores?.average_final_co_level?.gps_marks[itr.key]}}
                                                </th>
                                                <th>{{holistic_attainment_report?.attainment_academic_skills_scores?.average_final_co_level?.cagp_marks[itr.key]}}
                                                </th>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <th>Final CO Grades</th>
                                            <ng-container
                                                *ngFor="let itr of holistic_attainment_report?.attainment_academic_skills_scores?.final_co_grades?.cagp_marks | keyvalue; let in = index;">
                                                <th>{{holistic_attainment_report?.attainment_academic_skills_scores?.final_co_grades?.gps_marks[itr.key]}}
                                                </th>
                                                <th>{{holistic_attainment_report?.attainment_academic_skills_scores?.final_co_grades?.cagp_marks[itr.key]}}
                                                </th>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-view">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">View Evidences for this skillset</h6>
                    </div>
                    <div class="modal-body">
                        <div>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onModalCloseClick()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
