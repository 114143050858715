<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Assign Staff for Course</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">

      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Assign Staff for Course</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Staff for Course</p>
          </div>


          <div class="card-body" *ngIf="semester_wise_programme_scheme_form">

            <form [formGroup]="semester_wise_programme_scheme_form">
              <div class="row row-sm">
                <div class="col-lg-2" *ngIf="this.programme_data &&  this.programme_data.section_details.length>1">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Section</label>
                    <select formControlName="section_id" class="form-control" (change)="onEnrollmentYearChange($event)">
                      <option value="">Select Section</option>
                      <option
                        *ngFor="let section_detail of programme_data.section_details;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="section_detail._id">Section {{section_detail.section_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select enrollment year</label>
                    <select formControlName="enrolled_year" class="form-control"
                      (change)="onEnrollmentYearChange($event)">
                      <option value="">Select enrollment year</option>
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!semester_programme_data_loading" (click)="getSavedProgrammeScheme()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="semester_programme_data_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>
              </div>
            </form>
            <div class="mg-b-20"></div>
            <ng-container
              *ngIf="semester_programme_data && semester_wise_programme_scheme_form.value.enrolled_year && semester_wise_programme_scheme_form.value.section_id">
              <ng-container
                *ngFor="let semester of commonEnums.getSemesterList(no_of_semesters);let semester_index=index;trackBy:fromDataResolver.identify">
                <div class="mg-t-20 mg-b-20">
                  SEMESTER {{semester_index+1}} :
                </div>
                <div class="table-responsive" *ngIf="semester_programme_data">
                  <table class="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Part Type</th>
                        <th>Course Type</th>
                        <th>Course Code</th>
                        <th>Course</th>
                        <th style="width: 15%;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="semester_programme_data.programme_schemes.length>0">


                        <ng-container
                          *ngFor="let programe of getSemesterData(semester_index+1);let programme_scheme_index=index;trackBy:fromDataResolver.identify">
                          <tr>
                            <td scope="row">
                              {{programme_scheme_index+1}}

                            </td>
                            <td class="text-uppercase">{{programe.part}}</td>
                            <td class="text-uppercase">{{programe.course_type}}</td>
                            <td class="text-uppercase">{{getCourseDisplay(programe.courses,'course_code')}}</td>
                            <td class="text-uppercase">{{getCourseDisplay(programe.courses,'course_name')}}</td>

                            <td>
                              <div class="pr-1 mb-3 mb-xl-0">
                                <button type="button" class="btn btn-sm btn-info mr-2"
                                  (click)="onAssignButtonClick(programe,(semester_index+1))">
                                  <fa-icon [icon]="fonts.faUser"></fa-icon>
                                </button>

                              </div>
                            </td>

                          </tr>
                          <tr *ngIf="programe.open">
                            <td colspan="6">


                              <div class="mg-b-20"></div>
                              <ng-container *ngIf="programe.courses.length>1">
                                <form [formGroup]="programe.section_form">
                                  <div class="row row-sm">
                                    <div class="col-lg-3">
                                      <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                          Course</label>
                                        <select formControlName="course_code" class="form-control"
                                          (change)="onCourseChange($event,programe,(semester_index+1))">
                                          <option value="">Select Course</option>
                                          <option
                                            *ngFor="let course of programe.courses;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="course.course_code">{{course.course_name}}
                                            ({{course.course_code}})</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </ng-container>




                              <div class="row row-sm" *ngIf=" programe.section_form.value.course_code">
                                <div class="col-lg-12" *ngIf="programe.staff_form">
                                  <div class="table-responsive">
                                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                                      <thead>
                                        <tr>

                                          <th style="width: 3%;">S.No</th>
                                          <th style="width: 10%;">Roll No</th>
                                          <th>Name</th>
                                          <th>Department</th>
                                          <th style="width: 15%;">Is Incharge</th>
                                          <th style="width: 15%;">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <ng-container
                                          *ngFor="let staff of fromDataResolver.getFormArrayControlls(programe.staff_form,'staffs');let staff_index=index;trackBy:fromDataResolver.identify">
                                          <ng-container [formGroup]="staff">
                                            <tr>

                                              <td>
                                                {{staff_index+1}}
                                              </td>
                                              <td>
                                                <div class="form-group" style="margin-bottom: 0;">
                                                  <input class="form-control" [attr.name]="staff.value.sub" required=""
                                                    type="text" formControlName="roll_no"
                                                    (blur)="onRollNoChanged($event,staff,programe.staff_form)">
                                                </div>
                                              </td>
                                              <td>
                                                {{staff.value.name}}
                                              </td>

                                              <td>
                                                {{staff.value.departments}}
                                              </td>
                                              <td>
                                                <div class="custom-control custom-checkbox">
                                                  <input type="checkbox" class="custom-control-input"
                                                    [attr.id]='"p-"+staff_index' [attr.name]='"p-"+staff_index'
                                                    formControlName="is_primary">
                                                  <label class="custom-control-label" [attr.for]='"p-"+staff_index'>Is
                                                    incharge</label>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                  <button type="button" class="btn btn-sm btn-danger mr-2"
                                                    (click)="removeStaff(staff.value._id, staff,programe.staff_form,staff_index)">
                                                    <fa-icon [icon]="fonts.faTrash"></fa-icon>

                                                  </button>

                                                </div>
                                              </td>
                                            </tr>
                                          </ng-container>

                                        </ng-container>
                                        <tr>
                                          <td colspan="6" class="text-center">

                                            <button class="btn btn-sm btn-primary"
                                              (click)="addMoreStaff(programe.staff_form)">Add More Staff</button>


                                            <button class="btn btn-sm btn-success mg-l-20"
                                              (click)="onSaveStaffAssignClick(programe,programe.staff_form,(semester_index+1),programe.section_form.value.course_code)"
                                              *ngIf="!staff_assign_loading">Save</button>
                                            <button class="btn btn-sm btn-success mg-l-20" type="button" disabled
                                              *ngIf="staff_assign_loading">
                                              <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                              Loading...
                                            </button>

                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                </div>

                              </div>






                              <div class="mg-b-20"></div>





                            </td>
                          </tr>

                        </ng-container>

                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </div>


    </div>



  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
