<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="onAdd()">Create Floor</button>
        </div>
      </div>
    </div>

    <div class="row row-sm">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Floors</h4>
            </div>

          </div>
          <div class="card-body">
            <div class="table-responsive border-top userlist-table">
              <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                <thead>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Number of Rooms</span>
                  </th>
                  <th>
                    <span>Actions</span>
                  </th>
                </thead>
                <tbody>

                  <tr *ngFor="let floor of hostel.floors">
                    <td>{{ floor.name }} </td>
                    <td>{{ floor?.rooms?.length }}</td>
                    <td>
                      <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEdit(floor)">
                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                      </button>
                      <button type="button" class="btn btn-sm btn-danger mr-2" (click)="onDelete(floor)">
                        <fa-icon [icon]="fonts.faTrash"></fa-icon>
                      </button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
