import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper'
import { Modal } from 'src/app/components/ModalInterface';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { TemplateConfigurationComponent } from '../template-configuration/template-configuration.component';

@Component({
  selector: 'app-program-template',
  templateUrl: './program-template.component.html',
  styleUrls: ['./program-template.component.scss']
})
export class ProgramTemplateComponent implements OnInit {

  fonts = fonts;
  resData: any = [];
  role_delete_loading: boolean = false;
  role_list_loading: boolean = false;
  selectedFile: File;
  commonEnums = CommonEnums;
  selectedFormat: any;
  programme_fee_patch_form: any;
  isInputDisabled: boolean = true;
  settings_loading: boolean = false;
  section_delete_loading: boolean[] = [];
  template_delete_loading: boolean[] = [];
  fromDataResolver = FromDataResolver;
  program_template: FormGroup = {} as FormGroup;
  program_data: FormGroup = {} as FormGroup;
  template: templateInterface = {} as templateInterface;
  programs: programInterface = {} as programInterface;
  programme_type_list: any[] = [];
  programme_list: any[] = [];
  programme_type_list_loading = false;
  programme_list_loading = false;
  programme_section_list: any[] = [];
  selectedTemplateName: string = "";
  program: { templateName: string, _id: string, }[] = [];
  program_list: programInterface[] = [
    // {
    //   id: '1',
    //   programme_type_id:'This is the default Program 1.',
    //   finance_type:'',
    //   programme_id: 'This is the default Program 1.',
    //   template_name: 'This is the default template 1.',
    // }
  ];
  format: { key: any, value: any }[] = [
    { key: "Table-0", value: "Table" },
    { key: "List-1", value: "List" },
  ];
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.program_data = this.formBuilder.group({
      title: '',
      description: '',
      header: '',
      format: '',
      template: this.formBuilder.array([this.newTemplate()]),
    });
    this.buildForm();
    this.getTemplate();
  }

  getFinanceTypeText(finance_type: string) {
    console.log(this.commonEnums.finance_types)
    return this.commonEnums.finance_types.find(x => x.id == finance_type)?.text;

  }


  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  categoryChange(event: any) {
    let target = event.target.value; // Get the selected value from the dropdown
    // Do whatever you need to do with the selected value
  }

  async getTemplate() {
    try {
      const response = await this.restService.getTemplate();
      if (response && response.data) {
        this.program = response.data;
        console.log(this.program);
      }
    } catch (error) {
      console.error(error);
      // Handle error as needed
    }
  }



  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    } else if (id === 'programme_id') {
      this.programme_section_list = [];
      this.program_template.get('section_id').setValue("");
      const programme = this.programme_list.find(c => c.programme_id === this.program_template.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.programme_section_list = programme.section_details;
      }
      if (programme.section_details.length == 1) {
        this.program_template.get('section_id').setValue(programme.section_details[0]._id);
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      if (this.program_template.value.programType && this.program_template.value.finance_type) {

        this.programme_list_loading = true;
        this.programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.program_template) {
          if (this.program_template.value.programType) {
            search_form_query.programme_type_id = this.program_template.value.programType;
          }
          if (this.program_template.value.finance_type) {
            search_form_query.finance_type = this.program_template.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.programme_list = service_response.data;
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  isInvalid(formControl: string): boolean {
    return this.program_template.touched && this.program_template.get(formControl)?.errors !== null;
  }

  addTableToTemplate(templateIndex: number, currentNumberOfTables: number, desiredNumberOfTables: number) {
    const tablesArray = this.tables(templateIndex);
    const numberOfRowsToAdd = desiredNumberOfTables - currentNumberOfTables;
    if (numberOfRowsToAdd > 0) {
      for (let i = 0; i < numberOfRowsToAdd; i++) {
        tablesArray.push(this.newTable());
      }
    } else if (numberOfRowsToAdd < 0) {
      for (let i = 0; i < -numberOfRowsToAdd; i++) {
        tablesArray.removeAt(currentNumberOfTables - 1 - i);
      }
    }
  }


  addTablesForAllTemplates() {
    const formDataString = localStorage.getItem('formData');
    const formData = JSON.parse(formDataString);
    const templateArray = this.templates();

    for (let j = 0; j < templateArray.length; j++) {
      const currentNumberOfTables = this.tables(j).length;
      const desiredNumberOfTables = formData.template[j].table.length;
      this.addTableToTemplate(j, currentNumberOfTables, desiredNumberOfTables);
    }
  }


  ngOnInit(): void {
    this.getTemplate();
    this.getProgramTemplate();
    const formDataString = localStorage.getItem('formData');
    let numberOfNewEmployees = 0;
    if (formDataString) {
      const formData = JSON.parse(formDataString);
      this.program_data.patchValue(formData);
      numberOfNewEmployees = formData.template.length;
      console.log(formData);
    } else {
      console.log('No stored form data found');
    }
    this.program_data = this.formBuilder.group({
      template: this.formBuilder.array([]),
    });
    for (let i = 0; i < numberOfNewEmployees; i++) {
      this.addTemplate();
    }
    this.addTablesForAllTemplates();
    console.log('Form Data:', this.program_data.value);
  }

  async getProgramTemplate() {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getProgramTemplate();
      if (response && response.data) {
        console.log(response.data);
        this.program_list = response.data;

      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_list_loading = false;
    }
    ;
  }

  async addData() {
    if (this.program_template.invalid) {
      return;
    }

    const formData = this.program_template.value;
    console.log(formData);



    if (formData._id) {
      try {
        const response = await this.restService.updateProgramTemplate(formData._id, formData);
        if (response && response.success) {
          alert('Updated successfully.');
          this.onCloseClick();
          this.getProgramTemplate();
        }
      } catch (error) {
        console.error('Error updating template:', error);
      }
    } else {

      const addTemplate = {
        programe_type_id: formData.programType,
        finance_type: formData.finance_type,
        programme_id: formData.programme_id,
        template_id: formData.template_id
      }
      // Create a new template
      this.restService.createProgramTemplate(addTemplate).subscribe(
        (result) => {
          this.resData = result;
          console.log(this.resData.data);
          alert('Saved Successfully');
          this.onCloseClick();
          this.getProgramTemplate();

        },
        (error) => {
          console.error('Error:', error);
        }
      );
    }
  }

  async getprogramTemplateById(_id: any) {
    debugger;
    try {
      const response = await this.restService.getProgramTemplates(_id);
      if (response && response.data[0] && response.data[0].has_program_template_mapping) {
        console.log(response.data[0]);
        // Call the onDeleteMapping function here if has_program_template_mapping is true
        await this.onDeleteMapping(_id);
      } else if (response && response.data[0] && !response.data[0].has_program_template_mapping) {
        await this.onDelete(_id);
      } else {
        // Handle other cases or errors here
        alert("Failed to fetch Program Template.");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      // Any cleanup code can go here if needed
    }
  }

  async onDelete(_id: any) {

    try {
      this.role_delete_loading = true;
      const response = await this.restService.deleteProgramTemplate(_id);
      if (response && response.success) {
        alert("Program Template deleted successfully.");
      }
      this.getProgramTemplate();
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_delete_loading = false;
    }
  }

  async onDeleteMapping(_id: any) {

    try {
      this.role_delete_loading = true;
      const response = await this.restService.deleteProgramTemplateMapping(_id);
      if (response && response.success) {
        alert("Program Template Mapping deleted successfully.");
      }
      this.getProgramTemplate();
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_delete_loading = false;
    }
  }

  canDisable(): boolean {

    return this.program_template.invalid || this.program_template.pristine;
  }

  templates(): FormArray {
    return this.program_data.get('template') as FormArray;
  }

  newTemplate(): FormGroup {
    return this.formBuilder.group({
      title: '',
      description: '',
      header: '',
      content: '',
      format: '',
      table: this.formBuilder.array([this.newTable()])
    });
  }

  addTemplate() {
    this.templates().push(this.newTemplate());
  }

  tables(i: number): FormArray {
    return this.templates()
      .at(i)
      .get('table') as FormArray;
  }

  newTable(): FormGroup {
    return this.formBuilder.group({
      title: '',
      value: '',
      string: '',
      number: '',
      file: ''

    });
  }


  async onProgrammeTypeChange(form: FormGroup): Promise<void> {
    form.get('finance_type')!.setValue('');
    form.get('programme_id')!.setValue('');
    form.get('semester')!.setValue('');
  }

  private onUpload(imageFor) {
    const fd = new FormData();
    fd.append('imageFile', this.selectedFile, this.selectedFile.name);
    // this.http.post('https://localhost:3443/products/upload', fd)
    //   .subscribe(res => imageFor === 'FRONT' ? this.imgFront = res : this.imgBack = res);
  }

  onFileSelected(event, imageFor) {
    this.selectedFile = <File>event.target.files[0];
    this.onUpload(imageFor);
  }

  buildForm(): void {

    // if (JSON.stringify(this.programs) == '{}') {
    //   this.programs = {
    //     id: "",
    //     programme_type_id:"",
    //     finance_type:"",
    //     programme_id: "",
    //     templateRef: "",
    //   };
    // }
    // this.program_template = this.formBuilder.group({
    //   id: [this.programs.id],
    //   programme_type_id: [this.programs.programme_type_id, [Validators.required]],
    //   finance_type: [this.programs.finance_type, [Validators.required]],
    //   programme_id: [this.programs.programme_id, [Validators.required]],
    //   templateRef: [this.programs.templateRef, [Validators.required]],

    // });
    this.program_template = this.formBuilder.group({
      _id: [''],
      programType: ['', Validators.required],
      finance_type: ['', Validators.required],
      programme_id: ['', Validators.required],
      template_id: ['', Validators.required]
    });
  }

  onAddClick() {
    this.getProgrammeTypeList();
    this.buildForm();
    JQueryHelper.openModal('#program-modal-popup', { keyboard: false, backdrop: 'static' });
  }



  // onAddClicks() {

  //   this.router.navigate(['/admin/reports/template-configuration']);


  // }

  onAddClicks(prgTempId: string, id: string) {

    this.router.navigate([`/admin/reports/template-configuration/${prgTempId}/${id}`]);
  }

  onEditClicks(prgTempId: string, id: string) {

    this.router.navigate([`/admin/reports/template-configuration/${prgTempId}/${id}`]);
  }



  async onClosedClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#program_data-modal-popup');
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#program-modal-popup');
  }

  async getProgramName(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_type_list_loading = false;
    }
  }



  reset(): void {
    this.programs = {} as programInterface;
    this.program_template.reset();
  }


}

interface programInterface {
  id: string,
  _id: string,
  programe_type_id: string;
  display_name: string;
  finance_type: string;
  programme_id: string,
  template_name: string,
  template_id: string
  has_program_template_mapping: boolean;
}

interface templateInterface {
  id: string,
  title: string,
  description: string,
  format: string,
  header: string
}


