import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-entrance-examhall',
  templateUrl: './entrance-examhall.component.html',
  styleUrls: ['./entrance-examhall.component.scss']
})
export class EntranceExamhallComponent implements OnInit {

  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  cookieStore = CookieStore;

  // Master data
  admission_id = '';
  exam_id = '';
  exam_patch_id = '';
  exam_name = '';
  closed = false;

  entrance_exam_list: any[] = [];
  entrance_exam_list_loading = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.params.subscribe(data => {
      this.admission_id = data.admission_id;
      this.exam_id = data.exam_id;
      this.exam_patch_id = data.exam_patch_id;
    });
    this.route.queryParams.subscribe(data => {
      this.exam_name = data.exam_name;
      this.closed = data.closed === 'true';
    });
  }

  ngOnInit(): void {
    this.getExamStudentDetails();
    setInterval(() => {
      this.getExamStudentDetails();
    }, 1000 * 60 * 5);
  }

  async getExamStudentDetails(): Promise<void> {
    try {
      const data = {
        admission_id: this.admission_id,
        exam_id: this.exam_id,
        exam_patch_id: this.exam_patch_id
      };
      this.entrance_exam_list = [];
      this.entrance_exam_list_loading = true;
      const response = await this.restService.getExamApplicantDetails(data);
      if (response.success) {
        this.entrance_exam_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.entrance_exam_list_loading = false;
    }
  }

  async allowReExam(entrance_exam: any): Promise<void> {
    try {
      const consent = confirm('Are you sure, do you want to re-allow ' + entrance_exam.name + ' ?\nThis will clear all his/her marks.');
      if (!consent) {
        return;
      }
      const data = {
        applicant_id: entrance_exam.applicant_id,
        admission_id: this.admission_id,
        exam_id: this.exam_id,
        exam_patch_id: this.exam_patch_id
      };
      const response = await this.restService.reAllowExam(data);
      if (response.success) {
        await this.getExamStudentDetails();
        alert('Re-allowed successfully');

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_list_loading = false;
    }
  }

  async onCloseExamPatchClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure, do you want to close the this patch?\nOnce it closed it can\'t be reponed.');
      if (!consent) {
        return;
      }
      const response = await this.restService.closeExamPatch(this.exam_patch_id);
      if (response.success) {
        await this.getExamStudentDetails();
        this.closed = true;
        alert('Closed successfully');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_list_loading = false;
    }
  }

}
