<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Timetable for Staffs</p>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="time_setting_filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">Choose Programme</option>
                                            <option
                                                *ngFor="let programme of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">
                                                {{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="time_setting_filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!settings_list_loading" (click)="getData()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="settings_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>

                                </div>
                            </div>


                        </form>

                        <!-- <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onMappingClick()">Staff Mapping</button>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 15%;">S.No</th>
                                        <th style="width: 40%;">Programme</th>
                                        <th style="width: 15%;">Enrolment Year</th>
                                        <th style="width: 15%;">Section</th>
                                        <th style="width: 15%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="timetable_data!.length>0">
                                        <ng-container
                                            *ngFor="let timetable of timetable_data;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td width="10%">{{i+1}}</td>
                                                <td>{{getProgrammeName(timetable.programme_id)}}</td>
                                                <td>{{timetable.enrolled_year}}</td>
                                                <td>{{timetable.section}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(timetable)"
                                                            class="btn btn-sm btn-info mr-2" title="Timetable Settings">
                                                            <fa-icon [icon]="fonts.faCog"></fa-icon>
                                                        </button>

                                                        <button type="button" (click)="onMappingClick(timetable)"
                                                            class="btn btn-sm btn-info mr-2" title="Staff Mapping">
                                                            <fa-icon [icon]="fonts.faUsers"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!timetable_loading && timetable_data!.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="timetable_loading && timetable_data!.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="timetable!=null">
                    <div class="modal-header">
                        <h6 class="modal-title">Timetable Settings</h6>
                    </div>
                    <div class="modal-body" *ngIf="modal_mode=='view'">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="d-flex my-xl-auto right-content" style="flex-direction: row-reverse;">
                                <!-- <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal> -->
                                <div class="pr-1 mb-3 mb-xl-0">
                                    <app-modal [render_data]="bulk_render_hour_data"
                                        (csvData)="bulkUploadHourMapping($event)"></app-modal>
                                </div>
                            </div>
                            <div class="row mg-b-10">
                                <div class="col-md-6">
                                    Programme: <b> {{getProgrammeName(timetable.programme_id)}} </b>
                                </div>
                                <div class="col-md-6">
                                    Enrolment Year: <b>{{timetable.enrolled_year}}</b>
                                </div>
                            </div>

                            <div class="row mg-b-10">
                                <div class="col-md-6">
                                    Semester:<b> {{timetable.semester}}</b>
                                </div>
                                <div class="col-md-6">
                                    Section: <b>{{timetable.section}}</b>
                                </div>
                            </div>

                            <div class="table-responsive" *ngIf="timetable_render?.length>0">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="10%">Day</th>
                                            <th *ngFor="let head of hour_header | slice:0:max_hour">
                                                {{head.hour_name}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <ng-container
                                        *ngFor="let day of timetable_render;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{day.day}}<br>
                                            </td>
                                            <ng-container
                                                *ngFor="let hour of day.hours; let j=index;trackBy:fromDataResolver.identify;">
                                                <td class="table-data" *ngIf="!hour.is_break">
                                                    <div class="time-display">
                                                        <span style="text-decoration: underline; cursor: pointer;"
                                                            (click)="editHourData(day.day,i,(hour.hour_number))"
                                                            *ngIf="hour.type=='PROGRAMME_MAP' || hour.type==undefined">{{hour.start_time}}
                                                            - {{hour.end_time}}</span>
                                                        <span *ngIf="hour.type=='STAFF_MAP'">{{hour.start_time}}
                                                            - {{hour.end_time}}</span>
                                                    </div>
                                                    <div class="data-display">
                                                        <div>
                                                            <span class="course_code"
                                                                *ngIf="hour.staff_course_data.length==1">{{hour.staff_course_data[0].course_code}}</span>
                                                            <span class="course_code mg-l-5"
                                                                *ngIf="hour.staff_course_data.length==1">{{hour.staff_course_data[0].staff_id}}</span>
                                                            <span class="course_code" style="cursor: pointer;"
                                                                *ngIf="hour.staff_course_data.length>1"
                                                                (click)="viewHourData(hour.staff_course_data)">
                                                                <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                                            </span>
                                                            <span class="course_code" *ngIf="hour.type==undefined">
                                                                <i>Empty</i>
                                                            </span>
                                                            <br>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="table-data" *ngIf="hour.is_break">
                                                    <div class="time-display">
                                                        <span>{{hour.start_time}}
                                                            - {{hour.end_time}}</span>
                                                    </div>
                                                    <div class="data-display">
                                                        <span class="course_code"><i>Break</i></span>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <td *ngFor="let hour of getEmptyHours(i)">
                                                ---
                                            </td>
                                        </tr>
                                    </ng-container>
                                </table>
                            </div>
                            <div class="row mg-t-10">
                                <div class="col-md-12">
                                    <span *ngFor="let staff of staffList; let i = index;" class="mg-r-5">
                                        {{staff.roll_no}} - {{staff.given_name}} {{staff.middle_name}};
                                    </span>
                                </div>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6 text-right">
                                    <button class="btn btn-secondary" (click)="closeClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal-body" *ngIf="modal_mode=='info'">
                        <div class="card card-body pd-20 pd-md-40">
                            <h4>View Hour Info</h4>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Course Code</th>
                                            <th>Staff Id(s)</th>
                                            <th>Room No.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of hour_info_data; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{data.course_code}}</td>
                                            <td>{{data.staff_id}}</td>
                                            <td>{{data.room_no}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeHourData()">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal-body" *ngIf="modal_mode=='edit'">
                        <div class="card card-body pd-20 pd-md-40">

                            <div class="row mg-b-10">
                                <div class="col-md-6">
                                    Day: <b> {{edit_data.day}} </b>
                                </div>
                                <div class="col-md-6">
                                    Hour No: <b>{{hour_data.hour_number}}</b>
                                </div>
                            </div>

                            <div class="row mg-b-10">
                                <div class="col-md-6">
                                    Start Time: <b>{{hour_data.start_time}}</b>
                                </div>
                                <div class="col-md-6">
                                    End Time: <b>{{hour_data.end_time}}</b>
                                </div>
                            </div>

                            <div class="row mg-b-15">
                                <div class="col-md-6">
                                    Duration: <b>{{hour_data.duration}}</b>
                                </div>
                            </div>

                            <div>
                                <form [formGroup]="hour_map_form">
                                    <div class="row mg-b-10">
                                        <div class="col-md-3">
                                            Enter Course Code / Name
                                        </div>
                                        <div class="col-md-9">
                                            <kendo-autocomplete #course id="course_name" [data]="courseData"
                                                [valueField]="'course_code'" [filterable]="true"
                                                (filterChange)="handleCourseFilter($event)"
                                                [placeholder]="'Enter Course Name/Code'" [suggest]="true"
                                                formControlName="course_code" style="width: 100%;">
                                                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                                    <span>{{ dataItem.course_name
                                                        }} ({{dataItem.course_code}})</span>
                                                </ng-template>
                                            </kendo-autocomplete>
                                        </div>
                                    </div>
                                    <div class="row mg-b-10">
                                        <div class="col-md-3">
                                            Enter Staff Name / ID
                                        </div>
                                        <div class="col-md-9">
                                            <kendo-multiselect #staff id="staff_name" [data]="staffData"
                                                [valueField]="'roll_no'" [textField]="'given_name'" [filterable]="true"
                                                (filterChange)="handleStaffFilter($event)"
                                                [placeholder]="'Enter Staff Name/ID'" [valuePrimitive]="true"
                                                formControlName="staff_id">
                                                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                    <span>{{ dataItem.given_name }}
                                                        {{ dataItem.middle_name }}
                                                        ({{dataItem.roll_no}})</span>
                                                </ng-template>
                                            </kendo-multiselect>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>Enter Room No.</label>
                                        </div>
                                        <div class="col-md-9">
                                            <kendo-autocomplete #room_pgm id="room_no_pgm" [data]="roomData"
                                                [valueField]="'room_no'" [filterable]="true"
                                                (filterChange)="handleRoomFilter($event)"
                                                [placeholder]="'Enter Room No.'" [suggest]="true"
                                                formControlName="room_no" style="width: 100%;">
                                                <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                                    <span>{{ dataItem.block_name
                                                        }} - {{dataItem.room_no}}</span>
                                                </ng-template>
                                            </kendo-autocomplete>
                                            <!-- <select class="form-control" formControlName="room_no">
                                                <option value="">Choose Room No.</option>
                                                <option *ngFor="let room of room_nos" [ngValue]="room">{{room}}</option>
                                            </select> -->
                                        </div>

                                    </div>
                                </form>
                            </div>


                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeHourData()">Back</button>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button class="btn btn-success" (click)="saveHourData()"
                                        *ngIf="!save_loading">Update</button>
                                    <button class="btn btn-success" *ngIf="save_loading" disabled>Updating...</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="mapping-modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="timetable!=null">
                    <div class="modal-header">
                        <h6 class="modal-title"><span *ngIf="mapping_mode=='edit'">Manage </span>Staff Hour Mapping</h6>
                    </div>
                    <div class="modal-body" *ngIf="mapping_mode=='view'">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="d-flex right-content mg-b-25" style="flex-direction: row-reverse;">
                                <!-- <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal> -->
                                <app-modal [render_data]="bulk_render_staff_data"
                                    (csvData)="bulkUploadStaffMapping($event)"></app-modal>
                            </div>
                            <div class="row">
                                Map Staff Data
                                <!-- for {{mapping_data?.enrolled_year}}
                                (Semester: {{mapping_data?.semester}}) -->
                            </div>
                            <p *ngIf="staff_list_loading"><b>Loading...</b></p>
                            <div class="table-responsive" *ngIf="staffList!.length>0">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">S.No</th>
                                            <th class="text-center">Staff Id.</th>
                                            <th class="text-center">Name</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tr *ngFor="let staff of staffList; let i=index;trackBy:fromDataResolver.identify;">
                                        <td width="10%">{{i+1}}</td>
                                        <td width="20%">{{staff.roll_no}}</td>
                                        <td width="40%">{{staff.given_name}} {{staff.middle_name}}</td>
                                        <td style="cursor: pointer">
                                            <button type="button" class="btn btn-info"
                                                (click)="openTimeTableModal(staff.roll_no, staff.given_name, staff.middle_name)">Map
                                                Hour</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="row mg-t-20">
                                <div class="col-md-6 text-right">
                                    <button class="btn btn-secondary" (click)="mappingCloseClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal-body" *ngIf="mapping_mode=='timetable'">
                        <div class="row mg-b-10">
                            <div class="col-md-9">
                                Staff Name: {{mapping_data?.given_name}} {{mapping_data?.middle_name}}
                                ({{mapping_data?.staff_id}})
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-sm btn-primary" (click)="downloadTimeTable()">Download
                                    PDF</button>
                            </div>
                        </div>

                        <span *ngIf="time_table_loading"><b>Loading...</b></span>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>Day</th>
                                        <th *ngFor="let head of hour_header | slice:0:max_hour">
                                            {{head.hour_name}} <br>
                                            {{head.hour_time?head.hour_time:''}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="staff_timetable!.length>0">
                                        <ng-container
                                            *ngFor="let day of staff_timetable;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td>
                                                    {{day.day}}
                                                </td>
                                                <ng-container
                                                    *ngFor="let head of hour_header | slice:0:max_hour; let j=index;trackBy:fromDataResolver.identify;">
                                                    <td>
                                                        <ng-container *ngIf="isStaffHourExists(i,head.hour_number)">
                                                            <ng-container
                                                                *ngFor="let hour_data of getStaffHourData(i,head.hour_number); let ind = index;">
                                                                <span>
                                                                    <b>{{hour_data.course_data.course_name}}
                                                                        ({{hour_data.course_data.course_code}})</b>
                                                                </span>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <span
                                                                            *ngIf="!hour_data.programme_data?.virtual_section">
                                                                            {{getProgrammeName(hour_data.programme_data.programme_id)}}
                                                                            '{{hour_data.programme_data.section}}'
                                                                        </span>
                                                                        <span
                                                                            *ngIf="hour_data.programme_data?.virtual_section">
                                                                            {{hour_data.programme_data.virtual_section}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <span>
                                                                            {{hour_data.room_data.room_no}}
                                                                        </span>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <button class="btn btn-sm btn-danger"
                                                                            style="float: right;"
                                                                            (click)="deleteMappingData(day.day,head.hour_number,hour_data.course_data.course_code, hour_data.programme_data)">
                                                                            <fa-icon [icon]="fonts.faTimes"></fa-icon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isStaffHourExists(i,head.hour_number)">
                                                            <span>---</span>
                                                        </ng-container>
                                                        <div class="row mg-t-5">
                                                            <div class="col-md-12">
                                                                <button class="btn btn-sm btn-info"
                                                                    style="float: right;"
                                                                    (click)="addMappingData(day.day,head.hour_number)">
                                                                    <fa-icon [icon]="fonts.faPlus"></fa-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!staff_timetable_loading && staff_timetable.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="staff_timetable_loading && staff_timetable.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="row mg-t-20">
                            <div class="col-md-6 text-right">
                                <button class="btn btn-secondary" (click)="closeTimeTableModal()">Back</button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body" *ngIf="mapping_mode=='edit'">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="mapping_data_form">

                                <!-- <div class="row mg-b-10">
                                    <div class="col-md-6">
                                        Enrolment Year: <b>{{mapping_data?.enrolled_year}}</b>
                                    </div>
                                    <div class="col-md-6">
                                        Semester: <b>{{mapping_data?.semester}}</b>
                                    </div>
                                </div> -->
                                <div class="row mg-b-10">
                                    <div class="col-md-6">
                                        Day: <b>{{mapping_data?.day}}</b>
                                    </div>
                                    <div class="col-md-6">
                                        Hour: <b>{{mapping_data?.hour}}</b>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrollment
                                                Year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select Enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label><br>
                                        <kendo-autocomplete #course id="course_name" [data]="courseData"
                                            [valueField]="'course_code'" [filterable]="true"
                                            (filterChange)="handleCourseFilter($event)"
                                            (valueChange)="checkProgramme($event)"
                                            [placeholder]="'Enter Course Name/Code'" [suggest]="true"
                                            formControlName="course_code" style="width: 100%;">
                                            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                                <span>{{ dataItem.course_name
                                                    }} ({{dataItem.course_code}})</span>
                                            </ng-template>
                                        </kendo-autocomplete>
                                        <!-- <input type="text" formControlName="course_code" class="form-control"
                                                                                        placeholder="Enter Course Code" (change)="getProgrammeDetails($event)"> -->
                                    </div>
                                </div>

                                <div class="row" *ngIf="course_info?.advanced_settings?.virtual_sections?.length==0">
                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            To-Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onMapProgrammeSelectChange($event)">
                                            <option value="">Choose Programme</option>
                                            <option
                                                *ngFor="let programme of map_programmes;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="programme.programme_id">
                                                {{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row"
                                    *ngIf="course_info?.advanced_settings?.virtual_sections?.length==0 && map_sections.length>0">
                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of map_sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row" *ngIf="course_info?.advanced_settings?.virtual_sections?.length>0">
                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Virtual Section</label>
                                        <select formControlName="virtual_section" class="form-control">
                                            <option value="">Select Virtual Section</option>
                                            <option
                                                *ngFor="let section of virtual_sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="section.section_id">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Room
                                            No</label>
                                        <kendo-autocomplete #room id="room_no_mapping" [data]="roomData"
                                            [valueField]="'room_no'" [filterable]="true"
                                            (filterChange)="handleRoomFilter($event)" [placeholder]="'Enter Room No.'"
                                            [suggest]="true" formControlName="room_no" style="width: 100%;">
                                            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                                <span>{{ dataItem.block_name
                                                    }} - {{dataItem.room_no}}</span>
                                            </ng-template>
                                        </kendo-autocomplete>
                                        <!-- <select class="form-control" formControlName="room_no">
                                            <option value="">Choose Room No.</option>
                                            <option *ngFor="let room of room_nos" [ngValue]="room">{{room}}</option>
                                        </select> -->

                                    </div>
                                </div>

                            </form>


                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeMappingData()">Back</button>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button class="btn btn-success" (click)="saveMappingData()"
                                        *ngIf="!save_loading">Save</button>
                                    <button class="btn btn-success" disabled *ngIf="save_loading">Saving...</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>