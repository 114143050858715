import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper'
import { Modal } from 'src/app/components/ModalInterface';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-report-template-create',
  templateUrl: './report-template-create.component.html',
  styleUrls: ['./report-template-create.component.scss']
})
export class ReportTemplateCreateComponent implements OnInit {

  templateSaved: boolean = false;
  templateForm: FormGroup;
  selectedFormat: string = '';
  fonts = fonts;
  resData: any = [];
  patchvalue: any = {};
  commonEnums = CommonEnums;
  section_delete_loading: boolean[] = [];
  template_delete_loading: boolean[] = [];
  fromDataResolver = FromDataResolver;
  title = 'Report List';
  report_list_loading: boolean = false;
  report_delete_loading: boolean = false;
  report_save_loading: boolean = false;
  settings_loading: boolean = false;
  targetIndex = 0;
  mapped_programmes: any[] = [];
  // Format options array
  formatOptions = [
    { key: 'List', value: 'List Format' },
    { key: 'Table', value: 'Table Format' },

    // Add more options as needed
  ];

  formats = [
    { key: 'String', value: 'String' },
    { key: 'Number', value: 'Number' },
    { key: 'File', value: 'File' }
  ];

  showHeader = false;
  showTable = false;
  reports: reportInterface = {} as reportInterface;
  templates: templateInterface = {} as templateInterface;
  report: reportInterface[] = [];
  programme_list: any[] = [];
  template_save_loading = false;
  role_list_loading: boolean = false;
  template_configuration: FormGroup = {} as FormGroup;
  collection_form: FormGroup = {} as FormGroup;
  report_form: FormGroup;
  fee_header_id: string = '';
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {

    this.report_form = formBuilder.group({
      _id: [''],
      template_name: ['', [Validators.required]],
      template_description: [''],
      academic_year: ['']
    });
    this.getTemplate();
    // console.log(this.report_form.value)
  }

  ngOnInit(): void {
    this.report_form.patchValue(this.resData);
    this.buildForm();
    this.initTemplateForm();
  }
  //template actions
  //build template create form
  buildForm(): void {
    this.report_form = this.formBuilder.group({
      _id: [''],
      template_name: ['', Validators.required],
      template_description: ['', Validators.required],
      academic_year: ['']
    });
  }

  //list of templates
  async getTemplate() {
    try {
      this.report_list_loading = true;
      const response = await this.restService.getTemplate();
      if (response && response.data) {
        this.report_list_loading = false;
        this.report = response.data;
        console.log(this.report);
      }
    } catch (error) {
      console.error(error);
      this.report_list_loading = false;
      // Handle error as needed
    }
  }

  //open template create form
  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#report-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  //for edit mode
  async getTemplateData(_id: any) {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getTemplates(_id);
      if (response && response.data) {
        console.log(response.data);

        this.report_form.patchValue({
          _id: response.data._id,
          template_name: response.data.template_name,
          template_description: response.data.template_description,
          academic_year: response.data.academic_year

        });
        console.log("Form Object:", this.report_form.value);
        JQueryHelper.openModal('#report-modal-popup', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.role_list_loading = false;
    }
  }

  //disable template create form
  disableCreateForm(): boolean {
    return this.report_form.invalid;
  }

  //validate create form control
  isInvalid(formControl: string): boolean {
    return this.report_form.touched && this.report_form.get(formControl)?.errors !== null;
  }

  //for creating template
  async createTemplate() {
    if (this.report_form.invalid) {
      return;
    }
    const formData = this.report_form.value;
    console.log(formData);
    if (formData._id) {
      try {
        const response = await this.restService.updateTemplate(formData._id, formData);
        if (response && response.success) {
          alert('Updated successfully.');
          this.onCloseClick();
          this.getTemplate();
        }
      } catch (error) {
        console.error('Error updating template:', error);
      } finally {
      }
    } else {
      const addTemplate = {
        template_name: formData.template_name,
        template_description: formData.template_description,
        academic_year: formData.academic_year

      };
      // Create a new template
      this.restService.createTemplate(addTemplate).subscribe(
        (result) => {
          this.resData = result;
          console.log(this.resData.data);
          alert('Saved Successfully');
          this.onCloseClick();
          this.getTemplate();
        },
        (error) => {
          console.error('Error:', error);
          alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
        },
        () => {

        }
      );
    }
  }

  //for deleting template
  async onDelete(_id: any) {

    try {
      const consent = confirm('Are you sure do you want to delete this template? This action is irreversible!');
      if (!consent) {
        return;
      }
      this.report_delete_loading = true;
      const response = await this.restService.deleteTemplate(_id);
      if (response && response.success) {
        alert("Template deleted successfully.");
        this.getTemplate();
      }

    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.report_delete_loading = false;
    }
  }

  //reset create form
  reset(): void {
    // this.setting_id = "";
    this.reports = {} as reportInterface;
    this.report_form.reset();
  }

  //close create popup
  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#report-modal-popup');
  }


  //template settings
  //open new template settings pop-up
  async createTemplateSettings(reportId: string, tagName: string) {
    const selectedReport = this.report.find((report) => report._id === reportId);
    this.initTemplateForm();
    if (selectedReport) {
      this.templateForm.get('template_id')?.setValue(selectedReport._id);
      this.templateForm.get('templateTag')?.setValue(tagName);
      JQueryHelper.openModal('#template-configuration-modal-popup', { keyboard: false, backdrop: 'static' });
    }
  }

  //default template form
  initTemplateForm() {
    this.templateForm = this.formBuilder.group({
      _id: [''],
      template_id: [''],
      templateTag: [''],
      template_data: this.formBuilder.array([this.newTemplate()]),
    });
  }

  //init template settings form - Template Data
  newTemplate(): FormGroup {
    return this.formBuilder.group({

      title: ['', [Validators.required]],
      description: '',
      format: [this.getDefaultValue(), [Validators.required]],
      file_link: '',
      list_data: this.formBuilder.group({
        header: [''],
      }),
      table_data: this.formBuilder.array([this.newTable()])
    });
  }

  //default format
  getDefaultValue(): string {
    // Implement your logic here to determine the default value dynamically
    // For example, you can check some conditions and return the appropriate default value
    // For now, returning 'N/A' as the default value
    return 'Group';
  }

  //default tabble structure
  newTable(): FormGroup {
    return this.formBuilder.group({
      column_name: '',
      column_format: '',

    });
  }

  //Return template data for UI
  template(): FormArray {
    return this.templateForm.get('template_data') as FormArray;
  }

  //add section
  addSection() {
    this.template().push(this.newTemplate());
  }

  //remove section
  removeSection(templateIndex: number) {
    const consent = confirm("Are you sure want to delete this section?");
    if (!consent) return;
    this.template().removeAt(templateIndex);
  }

  //tables in a section for UI
  tables(templateIndex: number): FormArray {
    return this.template()
      .at(templateIndex)
      .get('table_data') as FormArray;
  }

  //add row to a given table
  addTableRow(templateIndex: number) {
    this.tables(templateIndex).push(this.newTable());
  }

  //remove row from a given table
  removeColumn(templateIndex: number, tableIndex: number) {
    const consent = confirm("Are you sure want to delete this column?");
    if (!consent) return;
    this.tables(templateIndex).removeAt(tableIndex);
  }

  //save template settings
  async addTemplateSettings() {
    const formData = this.templateForm.value;
    console.log(formData)
    if (formData.template_data && Array.isArray(formData.template_data)) {
      for (let i = 0; i < formData.template_data.length; i++) {
        const template = formData.template_data[i];
        if (template.format && template.format == 'List') {
          delete template.table_data;
        }
        if (template.format && template.format == 'Table') {
          delete template.list_data;
        }
        if (template.format && template.format == 'Group') {
          delete template.list_data;
          delete template.table_data;
        }
      }
    }
    const addTemplate = {
      template_id: formData.template_id,
      template_data: formData.template_data,

    };
    // Create a new template
    this.restService.createTemplateSettings(addTemplate).subscribe((result) => {
      this.resData = result;
      console.log(this.resData.data);
      alert('Saved Successfully');
      this.templateForm.reset();
      this.onClosedClick();
      this.getTemplate();
    },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  //get template setting
  async getTemplatesetting(templateId: any, i: number, tagName: string) {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getTemplateSetting(templateId);
      if (response && response.data) {
        console.log(response.data);

        this.templateForm = this.formBuilder.group({
          _id: response.data[0]._id,
          template_id: response.data[0].template_id,
          template_data: this.formBuilder.array([]),
        });

        response.data[0].template_data.forEach((templateItem: any) => {
          const listData = templateItem.list_data || {};
          const fileLink = templateItem.file_link;
          console.log(fileLink);
          const templateGroup = this.formBuilder.group({
            title: [templateItem.title, [Validators.required]],
            description: [templateItem.description],
            file_link: [fileLink],
            format: [templateItem.format, Validators.required],
            list_data: this.formBuilder.group({
              header: listData.header || null,
            }),
            table_data: this.formBuilder.array([]),
          });


          templateItem.table_data.forEach((tableItem: any) => {
            const tableGroup = this.formBuilder.group({
              column_name: tableItem.column_name,
              column_format: tableItem.column_format,
              _id: tableItem._id,
            });

            (templateGroup.get('table_data') as FormArray).push(tableGroup);
          });

          (this.templateForm.get('template_data') as FormArray).push(templateGroup);
        });

        console.log("Form Object:", this.templateForm.value);
        JQueryHelper.openModal('#template-configuration-modal-popup', {
          keyboard: false,
          backdrop: 'static',
        });
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.role_list_loading = false;
    }
  }

  async viewTemplatesetting(templateId: any, i: number, tagName: string) {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getTemplateSetting(templateId);
      if (response && response.data) {
        console.log(response.data);

        this.templateForm = this.formBuilder.group({
          _id: response.data[0]._id,
          template_id: response.data[0].template_id,
          template_data: this.formBuilder.array([]),
        });

        response.data[0].template_data.forEach((templateItem: any) => {
          const listData = templateItem.list_data || {};
          const fileLink = templateItem.file_link;
          console.log(fileLink);
          const templateGroup = this.formBuilder.group({
            title: [templateItem.title, [Validators.required]],
            description: [templateItem.description],
            file_link: [fileLink],
            format: [templateItem.format, Validators.required],
            list_data: this.formBuilder.group({
              header: listData.header || null,
            }),
            table_data: this.formBuilder.array([]),
          });


          templateItem.table_data.forEach((tableItem: any) => {
            const tableGroup = this.formBuilder.group({
              column_name: tableItem.column_name,
              column_format: tableItem.column_format,
              _id: tableItem._id,
            });

            (templateGroup.get('table_data') as FormArray).push(tableGroup);
          });

          (this.templateForm.get('template_data') as FormArray).push(templateGroup);
        });

        console.log("Form Object:", this.templateForm.value);
        JQueryHelper.openModal('#view-configuration-modal-popup', {
          keyboard: false,
          backdrop: 'static',
        });
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.role_list_loading = false;
    }
  }

  onViewClosedClick() {
    JQueryHelper.closeModal('#view-configuration-modal-popup');
  }

  //update template settings
  async updateSetting() {
    try {
      const formData = this.templateForm.value;
      if (formData.template_data && Array.isArray(formData.template_data)) {
        for (let i = 0; i < formData.template_data.length; i++) {
          const template = formData.template_data[i];
          if (template.format && template.format == 'List') {
            delete template.table_data;
          }
          if (template.format && template.format == 'Table') {
            delete template.list_data;
          }
          if (template.format && template.format == 'Group') {
            delete template.list_data;
            delete template.table_data;
          }
        }
      }
      try {
        const updateResponse = await this.restService.updateTemplateSetting(formData._id, formData);

        if (updateResponse && updateResponse.success) {
          alert('Updated successfully.');
          this.onClosedClick();
          this.getTemplate();
        } else {
          console.error('Failed to update template setting.');
        }
      } catch (error) {
        console.error('Error updating template:', error);
      } finally {

      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  //reset settings form
  resetsettingsform(): void {
    this.templates = {} as templateInterface;
    this.templateForm.reset();
  }

  //check section controls validity
  isSectionControlInvalid(controlName: string, index: number): boolean {
    return this.templateForm.touched &&
      (this.templateForm.get('template_data') as FormArray).controls[index].get(controlName).invalid
    //this.templateForm.get(controlName)?.errors !== null;
  }

  //check if template settings form is valid
  disableSettingsForm(): boolean {
    return this.templateForm.invalid;
  }

  //close setting popup
  async onClosedClick(): Promise<void> {
    this.resetsettingsform();
    this.buildForm();
    JQueryHelper.closeModal('#template-configuration-modal-popup');
  }

  async uploadFile(e: any) {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.uploadFiles(formData);
      if (response.success && response.data) {
        alert("File Uploaded Successfully");

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  save() {
    const formData = this.templateForm.value;
    console.log(formData);

    // Convert the form data to a JSON string and store it in local storage
    localStorage.setItem('formData', JSON.stringify(formData));

    // You can also add a message to indicate that the data has been saved
    console.log('Form data saved to local storage');

    JQueryHelper.closeModal('#template-configuration-modal-popup');
  }

  //mapped programmes
  openMappedProgrammeModal(template) {
    this.mapped_programmes = template.program_details;
    JQueryHelper.openModal('#programmes-modal-popup', {
      keyboard: false,
      backdrop: 'static',
    });
  }

  navigateFilterPage(template) {
    window.open(`admin/reports/report-template-filter/${template._id}`, "_blank")
  }

  async closeMapModal(): Promise<void> {
    this.mapped_programmes = [];
    JQueryHelper.closeModal('#programmes-modal-popup');
  }


}

interface reportInterface {
  _id: string,
  template_name: string,
  template_description: string,
  academic_year: string,
  has_template_settings: boolean;
  has_programe_template: boolean;
  program_details?: any[]
}

interface templateInterface {
  _id: string,
  title: string,
  description: string,
  file_link: string,
  list_data: { header: string },
  table_data: [{ column_name: string, column_format: string }]
}
