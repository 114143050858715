import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-manage-parent-requests',
  templateUrl: './manage-parent-requests.component.html',
  styleUrls: ['./manage-parent-requests.component.scss']
})
export class ManageParentRequestsComponent implements OnInit {
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  applied_list_raw: any[] = [];
  applied_list: any[] = [];
  staus_count = {
    total: 0,
    requested: 0,
    accepted: 0,
    rejected: 0,
  };

  selected_status = '';

  //search
  search_form: any;

  applied_list_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.buildSearchForm();
  }

  ngOnInit(): void {
  }

  buildSearchForm(): void {
    this.search_form = this.fb.group({
      status: ['']
    })
    this.getAllParentRequestList();
  }


  async getAllParentRequestList(): Promise<void> {

    try {
      this.applied_list = [
        { user_info: { parent_name: 'Saravana', email: 'kumaranvnr@gmail.com', mobile_number: '1234567890', student_rollno: '20PA21' }, student_info: { given_name: 'shai', middle_name: 'a', programme_name: 'MCA' }, status: 'requested' },
        { user_info: { parent_name: 'kumar', email: '', mobile_number: '1234567890', student_rollno: '20PA22' }, student_info: { given_name: 'sharan', middle_name: 'b', programme_name: 'MCA' }, status: 'accepted' },
        { user_info: { parent_name: 'mani', email: 'mani@gmail.com', mobile_number: '1234567890', student_rollno: '20PA23' }, student_info: { given_name: 'murugan', middle_name: 'c', programme_name: 'MCA' }, status: 'rejected' }];
      this.applied_list_raw = [];
      this.staus_count = {
        total: 100,
        requested: 25,
        accepted: 50,
        rejected: 25
      };
      this.applied_list_loading = true;

      let query: any = {
        status: this.search_form.value.status
      };

      /*       const rest_response = await this.restService.getAllParentAppliedList(query);
            if (rest_response.success && rest_response.data) {
              this.applied_list = rest_response.data;
              this.applied_list_raw = rest_response.data;
              this.staus_count.total = this.applied_list_raw.length;
              this.staus_count.requested = this.applied_list_raw.filter(c => c.status === 'requested').length;
              this.staus_count.accepted = this.applied_list_raw.filter(c => c.status === 'accepted').length;
              this.staus_count.rejected = this.applied_list_raw.filter(c => c.status === 'rejected').length;
            } else {
              this.applied_list = [];
              this.applied_list_raw = [];
              this.staus_count = {
                total: 0,
                requested: 0,
                accepted: 0,
                rejected: 0
              };
            } */
    } catch (error) {

    } finally {
      this.applied_list_loading = false;
    }
  }

  async rejectStatus(_id: string): Promise<void> {
    const consent = confirm('Are you sure, do you want to reject this parent request?');
    if (consent) {
      try {
        /*  const rest_response = await this.restService.changeParentRequestApplicationStatus({ _id, status: 'rejected' });
         if (rest_response.success) {
           this.getAllParentRequestList();
           alert('Successfully Rejected');
         } */
      } catch (error) {
        alert('Error while rejecting');
      }

    }
  }
  async approveStatus(_id: string): Promise<void> {
    const consent = confirm('Are you sure, do you want to approve this parent request?');
    if (consent) {
      try {
        /*  const rest_response = await this.restService.changeParentRequestApplicationStatus({ _id, status: 'allowed' });
         if (rest_response.success) {
           this.getAllParentRequestList();
           alert('Successfully Approved');
         } */
      } catch (error) {
        alert('Error while approving');
      }

    }

  }

}
