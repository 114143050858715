import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-manage-transport-route',
  templateUrl: './manage-transport-route.component.html',
  styleUrls: ['./manage-transport-route.component.scss']
})
export class ManageTransportRouteComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }

  ngOnInit(): void {
  }

  
  url : string = "";
  
  getURL(){
    const access_token = CookieStore.getToken();
    this.url = " https://app.appsmith.com/app/transport/apps-transport-route-master-6444c42a6a3ea4285a5944f1?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
