import { ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { IAddress } from 'src/app/services/rest/entity/IAddress';
import { IAddressExtended } from 'src/app/services/rest/entity/IAddressExtended';
import { IFamilyMembers } from 'src/app/services/rest/entity/IFamilyMembers';
import { IRelativeInCollege } from 'src/app/services/rest/entity/IRelativeInCollege';
import { IStudentProgrammeMap } from 'src/app/services/rest/entity/IStudentProgrammeMap';
import { IStudents } from 'src/app/services/rest/entity/IStudents';

@Component({
  selector: 'app-manage-students',
  templateUrl: './manage-students.component.html',
  styleUrls: ['./manage-students.component.scss']
})
export class ManageStudentsComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_section_list: any[] = [];

  programme_search_form: any;

  filter_programme_list: any[] = [];
  filter_section_list: any[] = [];

  student_sub: string = "";

  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];


  // core data

  students_list: any[] = [];
  student_list_loading = false;

  student: IStudents | undefined = undefined;
  student_form: any;
  student_save_loading = false;
  student_delete_loading = false;


  change_password_form: any;
  change_password_save_loading = false;

  create_search_type: string = "aadhar";
  create_search_input: string;
  create_search_loading: boolean = false;
  basic_update_loading: boolean = false;
  programme_update_loading: boolean = false;
  programme_delete_loading: boolean[] = [];
  payment_details_loading: boolean = false;
  modal_mode: string = "";
  prev_modal_state: string = "";
  current_selection: any;
  active_accordion: number = 1;
  fee_render_data: any;
  seed_loading: boolean[] = [];

  //extra fields for download option

  father_occupation: string = "";
  father_salary: number | undefined;

  student_address: string = '';
  student_blood_group: string = ''
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService,
    private appRef: ApplicationRef, private changeDetector: ChangeDetectorRef) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {

    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();

    // Load core data
    this.getProgrammeDataList();
  }



  /// master data

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section_id: [''],
      enrolled_year: [2020],
      gender: [''],
      roll_no: [''],
    });

  }

  async searchStudentDataWithAadhar() {
    if (this.create_search_input == "") {
      alert("Please enter a valid " + ((this.create_search_type == 'aadhar') ? 'Aadhar No' : 'Roll No') + ".");
      return;
    }
    this.create_search_loading = true;
    var search_data: any;
    if (this.create_search_type == 'aadhar') {
      search_data = {
        aadhar_card_no: this.create_search_input
      }
    }
    else {
      search_data = {
        roll_no: this.create_search_input
      }
    }
    const res = await this.restService.searchStudentWithProgrammes(search_data);
    if (res.success) {
      if (res.data.length == 0) {
        alert("No student data found for this " + ((this.create_search_type == 'aadhar') ? 'Aadhar No' : 'Roll No') + ".");
        this.filter_programme_list = []; this.filter_section_list = [];
        this.student = {} as IStudents; this.student_form.reset();
      }
      else {
        this.filter_programme_list = []; this.filter_section_list = [];
        const student_response = await this.restService.getStudentInfo(res.data[0].sub);
        if (student_response && student_response.data) {
          this.student = student_response.data;
          //console.log(this.student);
          this.buildForm();
        }
        else {
          alert("Cannot get Student Data");
        }
      }

    }
    else {
      alert("Error! Cannot find student data with this " + ((this.create_search_type == 'aadhar') ? 'Aadhar No' : 'Roll No') + ".");
    }
    this.create_search_loading = false;
  }

  // build student form
  buildForm(): void {
    if (!this.student) {
      this.student = ({} as IStudents);
      this.student.gender = '';

      this.student.aadhar_card_no = '';//(this.create_search_type=='aadhar'?((this.create_search_input!='')?this.create_search_input:''):'');
      this.student.blood_group = '';
      this.student.caste = '';
      this.student.community_id = '';
      this.student.birth_date = new Date();
      this.student.ex_service = false;
      this.student.physically_challanged = false;
      this.student.additional_mobile_numbers = [];
      this.student.academics = [];
      this.student.address = ({} as IAddressExtended);
      this.student.address.present = ({} as IAddress);
      this.student.address.communication = ({} as IAddress);
      this.student.is_left = false;
      this.student.is_lateral_entry = false;
      this.student.lateral_entry_on_semester = '';
      this.student.is_long_absent = false;
      this.student.left_on_date = new Date();
      this.student.left_on_semester = 0;
      this.student.family_members = [];
      this.student.identification_marks = [];
      this.student.relative_in_college = ({} as IRelativeInCollege);
      this.student.programmes = [];
      this.student.fee_list = [];
    }

    if (!this.student.address) {
      this.student.address = ({} as IAddressExtended);
    }
    if (!this.student.address.present) {
      this.student.address.present = ({} as IAddress);
    }
    if (!this.student.address.communication) {
      this.student.address.communication = ({} as IAddress);
    }
    if (this.student.fee_list.length > 0) {
      this.fee_render_data = {};
      this.fee_render_data.roll_no = "20PA01"; this.fee_render_data.mode = "sub_component";
    }

    this.student_form = this.fb.group({
      _id: [this.student._id],
      sub: [this.student.sub, [Validators.required]],
      aadhar_card_no: [this.student.aadhar_card_no, [Validators.required]],
      email: [this.student.email, [Validators.required]],
      personal_email: [this.student.personal_email, [Validators.required]],
      given_name: [this.student.given_name, [Validators.required]],
      middle_name: [this.student.middle_name, [Validators.required]],
      mobile_number: [this.student.mobile_number, [Validators.required]],
      additional_mobile_numbers: this.fb.array(this.buildAdditionalContactNunberForm(this.student.additional_mobile_numbers)),
      birth_date: [DateHelper.convertToControlDate(this.student.birth_date), [Validators.required]],
      gender: [this.student.gender, [Validators.required]],
      blood_group: [this.student.blood_group, [Validators.required]],
      community_id: [this.student.community_id, [Validators.required]],
      caste: [this.student.caste, [Validators.required]],
      religion: [this.student.religion, [Validators.required]],
      address: this.fb.group({
        present: this.fb.group({
          street: [this.student.address.present.street, [Validators.required]],
          city: [this.student.address.present.city, [Validators.required]],
          district: [this.student.address.present.district, [Validators.required]],
          state: [this.student.address.present.state, [Validators.required]],
          country: [this.student.address.present.country, [Validators.required]],
        }),
        communication: this.fb.group({
          street: [this.student.address.present.street, [Validators.required]],
          city: [this.student.address.present.city, [Validators.required]],
          district: [this.student.address.present.district, [Validators.required]],
          state: [this.student.address.present.state, [Validators.required]],
          country: [this.student.address.present.country, [Validators.required]],
        }),
      }),
      nationality: [this.student.nationality, [Validators.required]],
      physically_challanged: [this.student.physically_challanged, [Validators.required]],
      ex_service: [this.student.ex_service, [Validators.required]],
      is_left: [this.student.is_left],
      left_on_date: [((this.student.left_on_date) ? DateHelper.convertToControlDate(this.student.left_on_date) : DateHelper.convertToControlDate(new Date()))],
      left_on_semester: [this.student.left_on_semester],
      is_long_absent: [this.student.is_long_absent],
      is_lateral_entry: [this.student.is_lateral_entry],
      lateral_entry_on_semester: [this.student.lateral_entry_on_semester],
      identification_marks: this.fb.array(this.buildidentificationMarkForm(this.student.identification_marks)),
      family_members: this.fb.array(this.buildFamilyMemberForm(this.student.family_members)),
      programmes: this.fb.array(this.buildProgrammesForm(this.student.programmes))
    });
    this.programme_delete_loading = [];
    this.student.programmes.forEach((programme, index) => {
      this.programme_delete_loading[index] = false;
      this.getProgrammeFilterDataList(index).then(res => {
        this.onProgrammeFilterSelectChange("programme_id", "ev", index);
      });
    });
    /* if (this.student.programmes.length > 0) {
      (this.student_form.get('programmes') as FormArray).disable();
    } */

  }



  buildidentificationMarkForm(identification_marks: string[]): any {
    if (!identification_marks) {
      identification_marks = [];
    }
    const additional_mobile_numbers_obj = [];
    if (identification_marks && identification_marks.length > 0) {
      for (const identification_mark of identification_marks) {
        additional_mobile_numbers_obj.push(this.fb.control(identification_mark, [Validators.required]));
      }
    }
    const remaining_count = 3 - identification_marks.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        additional_mobile_numbers_obj.push(this.fb.control('', [Validators.required]));
      }
    }
    return additional_mobile_numbers_obj;
  }

  buildFamilyMemberForm(family_members: IFamilyMembers[]): any {
    if (!family_members) {
      family_members = [];
    }
    const additional_mobile_numbers_obj = [];
    if (family_members && family_members.length > 0) {
      for (const family_member of family_members) {
        additional_mobile_numbers_obj.push(this.fb.group({
          given_name: [family_member.given_name, [Validators.required]],
          salary: [family_member.salary, [Validators.required]],
          occupation: [family_member.occupation, [Validators.required]],
          relationship_type: [family_member.relationship_type, [Validators.required]],
          contact_nos: this.fb.array(this.buildParentContactNunberForm(family_member.contact_nos)),
        }));
      }
    }
    const remaining_count = 1 - family_members.length;
    if (remaining_count && remaining_count > 0) {
      this.programme_delete_loading[0] = false;
      for (let index = 0; index < remaining_count; index++) {
        additional_mobile_numbers_obj.push(this.fb.group({
          given_name: ['', [Validators.required]],
          salary: ['', [Validators.required]],
          occupation: ['', [Validators.required]],
          relationship_type: ['', [Validators.required]],
          contact_nos: this.fb.array(this.buildParentContactNunberForm([])),
        }));
      }
    }
    return additional_mobile_numbers_obj;
  }

  buildParentContactNunberForm(contact_numbers: string[]): any {
    if (!contact_numbers) {
      contact_numbers = [];
    }
    const additional_mobile_numbers_obj = [];
    if (contact_numbers && contact_numbers.length > 0) {
      for (const additional_mobile_number of contact_numbers) {
        additional_mobile_numbers_obj.push(this.fb.control(additional_mobile_number, [Validators.required]));
      }
    }
    const remaining_count = 1 - contact_numbers.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        additional_mobile_numbers_obj.push(this.fb.control('', [Validators.required]));
      }
    }
    return additional_mobile_numbers_obj;
  }

  buildAdditionalContactNunberForm(additional_mobile_numbers: string[]): any {
    if (!additional_mobile_numbers) {
      additional_mobile_numbers = [];
    }
    const additional_mobile_numbers_obj = [];
    if (additional_mobile_numbers && additional_mobile_numbers.length > 0) {
      for (const additional_mobile_number of additional_mobile_numbers) {
        additional_mobile_numbers_obj.push(this.fb.control(additional_mobile_number, [Validators.required]));
      }
    }
    const remaining_count = 4 - additional_mobile_numbers.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        additional_mobile_numbers_obj.push(this.fb.control('', [Validators.required]));
      }
    }
    return additional_mobile_numbers_obj;
  }
  async onCloseClick(): Promise<void> {
    this.create_search_input = "";
    this.create_search_type = "aadhar";
    this.modal_mode = ""; this.prev_modal_state = "";
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }
  resetForm(): void {
    this.student_form = undefined;
    this.student = undefined;
    this.filter_programme_list = []; this.filter_section_list = [];
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    } else if (id === 'programme_id') {
      this.programme_section_list = [];
      this.programme_search_form.get('section_id').setValue("");
      const programme = this.programme_list.find(c => c.programme_id === this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.programme_section_list = programme.section_details;
      }
      if (programme.section_details.length == 1) {
        this.programme_search_form.get('section_id').setValue(programme.section_details[0]._id);
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      if (this.programme_search_form.value.programme_type_id && this.programme_search_form.value.finance_type) {

        this.programme_list_loading = true;
        this.programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.programme_search_form) {
          if (this.programme_search_form.value.programme_type_id) {
            search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
          }
          if (this.programme_search_form.value.finance_type) {
            search_form_query.finance_type = this.programme_search_form.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.programme_list = service_response.data;
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  buildProgrammesForm(programmes: IStudentProgrammeMap[]): any {
    if (!programmes) {
      programmes = [];
    }
    var programme_list: any[] = [];
    if (programmes && programmes.length > 0) {
      var programme_count = 0;
      for (const programme of programmes) {
        //console.log(programme)
        programme_list.push(this.fb.group({
          _id: [programme._id],
          programme_type_id: [programme.programme_type_id, [Validators.required]],
          finance_type: [programme.finance_type, [Validators.required]],
          programme_id: [programme.programme_id, [Validators.required]],
          section_id: [programme.section_id, [Validators.required]],
          roll_no: [programme.roll_no, [Validators.required]],
          enrolled_year: [programme.enrolled_year, [Validators.required]],
        }));
        //this.getProgrammeFilterDataList(programme_count, programme.programme_type_id, programme.finance_type);
        programme_count++;
      }
    }
    const remaining_count = 1 - programmes.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.programme_delete_loading[index] = false;
        programme_list.push(this.fb.group({
          _id: [''],
          programme_type_id: ['', [Validators.required]],
          finance_type: ['', [Validators.required]],
          programme_id: ['', [Validators.required]],
          section_id: ['', [Validators.required]],
          roll_no: ['', [Validators.required]],
          enrolled_year: [new Date().getFullYear(), [Validators.required]],
        }));
      }
    }
    //console.log(programme_list);
    return programme_list;
  }

  async onProgrammeFilterSelectChange(id: string, event: any, i: number): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeFilterDataList(i);
    } else if (id === 'programme_id') {
      this.filter_section_list[i] = [];
      //this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i]?.get('section_id').setValue("");
      const programme = this.filter_programme_list[i]?.find(c => c.programme_id === this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.programme_id);
      //console.log(this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i]?.value?.programme_id);
      //console.log(this.filter_programme_list[i]);
      //console.log(programme);
      //console.log(this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].get('section_id').value);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.filter_section_list[i] = programme.section_details;
        if (this.filter_section_list[i].length == 1) {
          this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].get('section_id').setValue(this.filter_section_list[i][0]._id);
        }
      }

      //console.log(this.filter_section_list);
    }
  }

  async getProgrammeFilterDataList(i: number): Promise<void> {
    try {
      this.filter_programme_list[i] = []; this.filter_section_list[i] = [];
      this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i]!.value!.programme_id = "";
      if (this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i]!.value!.programme_type_id &&
        this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i]!.value!.finance_type) {
        const search_form_query: any = {
          is_virtual: false,
        };

        if (this.student_form.get('programmes').value) {
          if (this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.programme_type_id) {
            search_form_query.programme_type_id = this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.programme_type_id;
          }
          if (this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.finance_type) {
            search_form_query.finance_type = this.fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.filter_programme_list[i] = service_response.data;
        }
      }
      /* else {
        const search_form_query: any = {
          is_virtual: false,
        };
        search_form_query.programme_type_id = programme_type_id;
        search_form_query.finance_type = finance_type;
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.filter_programme_list[i] = service_response.data;
        }
      } */
      //console.log(this.filter_programme_list);

    } catch (error) {
      //console.log(error);
      alert('Error while read programme filter data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  addMoreProgrammes(): void {
    this.programme_delete_loading[this.programme_delete_loading.length] = false;
    this.fromDataResolver.getFormArray(this.student_form, 'programmes').push(this.fb.group({
      _id: [''],
      programme_type_id: ['', [Validators.required]],
      finance_type: ['', [Validators.required]],
      programme_id: ['', [Validators.required]],
      section_id: ['', [Validators.required]],
      roll_no: ['', [Validators.required]],
      enrolled_year: [new Date().getFullYear(), [Validators.required]],
    }));
  }

  getFinanceTypeText(finance_type: string) {
    return this.commonEnums.finance_types.find(x => x.id == finance_type)?.text;
  }

  getProgrammeName(programme_id: string, index: number) {
    return this.filter_programme_list[index]?.find(x => x.programme_id == programme_id)?.programme_name;
  }

  getSectionName(section_id: string, index: number) {
    return this.filter_section_list[index]?.find(x => x._id == section_id)?.section_name;
  }


  async onAddClick(): Promise<void> {
    this.modal_mode = "ADD";
    this.active_accordion = 1;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(student: any): Promise<void> {
    this.modal_mode = "EDIT";
    this.active_accordion = 1;
    const student_response = await this.restService.getStudentInfo(student.sub);
    if (student_response && student_response.data) {
      this.student = student_response.data;
      //console.log(this.student);
      this.buildForm();
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    }

  }

  async updateBasicDetails() {
    try {
      const consent = confirm('Are you sure do you update Basic Details ?');
      if (!consent) {
        return;
      }
      this.basic_update_loading = true;
      //console.log(JSON.stringify(this.student_form.value, null, 4));
      //console.log(this.student_form.value);
      let save_data = this.student_form.value;
      save_data.birth_date = new Date(save_data.birth_date).toISOString();
      // if (!save_data.is_left) {
      //   delete save_data.is_left;
      // }
      if (save_data.is_left) {
        if (!save_data.left_on_date) {
          alert("Please choose Left On Date"); return;
        }
        if (!save_data.left_on_semester) {
          alert("Please choose Left On Semester"); return;
        }
      }

      if (save_data.is_lateral_entry) {
        if (!save_data.lateral_entry_on_semester) {
          alert("Please choose Lateral Entry Semester"); return;
        }
      }
      if (!save_data.is_lateral_entry) {
        save_data.lateral_entry_on_semester = '';
      }

      if (!save_data.is_left) {
        save_data.left_on_date = '';
        save_data.left_on_semester = '';
      }
      const saved_student_response = await this.restService.updateStudentInfoByAdmin(save_data);
      if (saved_student_response && saved_student_response.success === true) {
        alert('Updated Basic Details Successfully');
        //this.onCloseClick();
      } else {
        alert('Error while saving');
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
    this.basic_update_loading = false;
  }

  async updateProgrammeDetails() {
    try {
      const consent = confirm('Are you sure do you update Programme Details ?');
      if (!consent) {
        return;
      }
      this.programme_update_loading = true;
      //console.log(JSON.stringify(this.student_form.value, null, 4));
      //console.log(this.student_form.value);
      //console.log(this.student_form.value.programmes);
      const saved_student_response = await this.restService.createProgrammeMap(this.student_form.value.programmes);
      if (saved_student_response && saved_student_response.success === true) {
        alert('Updated Programme Details Successfully');
        const student_response = await this.restService.getStudentInfo(this.student.sub);
        if (student_response && student_response.data) {
          this.student = student_response.data;
          //console.log(this.student);
          this.buildForm();
        }
        else {
          alert("Cannot get Student Data");
        }
        //this.onCloseClick();
      } else {
        alert('Error while saving');
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
    this.programme_update_loading = false;
  }

  get programmeMapControls() {
    return (this.student_form.get('programmes') as FormArray).controls;
  }
  async deleteProgrammeMap(index: number) {
    const consent = confirm('Are you sure do you want to delete this programme?');
    if (!consent) {
      return;
    }
    this.programme_delete_loading[index] = true;
    if (this.student.sub) {
      const programme_id = ((this.student_form.get('programmes') as FormArray).controls[index] as FormGroup).get('programme_id').value;
      //console.log(programme_id);
      //console.log(this.student.sub);
      const delete_programme_response = await this.restService.deleteProgrammeMap(programme_id, this.student.sub);
      if (delete_programme_response && delete_programme_response.success === true) {
        alert('Programme Map Deleted Successfully');
        this.onCloseClick();
      } else {
        alert('Error while deleting');
      }
      //this.student.programmes.splice(index, 1);
    }
    (this.student_form.get('programmes') as FormArray).removeAt(index);
    this.filter_programme_list[index] = []; this.filter_programme_list.splice(index, 1);
    this.filter_section_list[index] = []; this.filter_section_list.splice(index, 1);
    this.programme_delete_loading.splice(index, 1);
    //this.programme_delete_loading[index] = false;
  }

  async onSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.student_save_loading = true;
      //console.log(JSON.stringify(this.student_form.value, null, 4));
      //console.log(this.student_form.value);
      const saved_student_response = await this.restService.createStudent(this.student_form.value);
      if (saved_student_response && saved_student_response.success === true) {
        alert('Saved Successfully');
        this.onCloseClick();
      } else {
        alert('Error while saving');
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
    this.student_save_loading = false;
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }



  async onSearchClick(): Promise<void> {
    await this.searchFormDataList();

  }

  async searchFormDataList(): Promise<void> {
    try {

      if (this.programme_search_form.value.roll_no) {

      } else {
        if (!this.programme_search_form.value.programme_id) {
          alert('Please select programme');
          return;
        }
      }

      this.student_list_loading = true;
      this.students_list = [];
      const search_form_query: any = {

      };
      if (this.programme_search_form.value.roll_no) {
        search_form_query.roll_no = this.programme_search_form.value.roll_no;
      } else {
        if (this.programme_search_form.value.enrolled_year) {
          search_form_query.enrolled_year = this.programme_search_form.value.enrolled_year;
        }
        if (this.programme_search_form.value.programme_id) {
          search_form_query.programme_id = this.programme_search_form.value.programme_id;
        }
        if (this.programme_search_form.value.gender) {
          search_form_query.gender = this.programme_search_form.value.gender;
        }
        if (this.programme_search_form.value.programme_type_id) {
          search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
        }
        if (this.programme_search_form.value.finance_type) {
          search_form_query.finance_type = this.programme_search_form.value.finance_type;
        }
        if (this.programme_search_form.value.section_id) {
          search_form_query.section_id = this.programme_search_form.value.section_id;
        }
      }
      const service_response = await this.restService.searchStudentWithProgrammes(search_form_query);

      if (service_response && service_response.success) {
        this.students_list = service_response.data;
        for (var i = 0; i < this.students_list.length; i++) {
          this.seed_loading[i] = false;
        }
      }
      //console.log(this.students_list);
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.student_list_loading = false;
    }
  }


  addAlternateContactNumber(): void {
    this.fromDataResolver.getFormArray(this.student_form, 'additional_mobile_numbers').push(this.fb.control('', [Validators.required]));
  }

  addMoreFamilyMemebers(): void {
    this.fromDataResolver.getFormArray(this.student_form, 'family_members').push(this.fb.group({
      given_name: ['', [Validators.required]],
      salary: ['', [Validators.required]],
      occupation: ['', [Validators.required]],
      relationship_type: ['', [Validators.required]],
      contact_nos: this.fb.array(this.buildParentContactNunberForm([])),
    }));
  }


  // Reset Password
  async onInitResetPasswordClick(student: any): Promise<void> {
    this.buildResetPassordForm(student.sub);
    JQueryHelper.openModal('#modal-popup-resetpassword', { keyboard: false, backdrop: 'static' });
  }

  buildResetPassordForm(sub: string): void {

    this.change_password_form = this.fb.group({
      sub: [sub, [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    });
  }

  async onResetPassordCloseClick(): Promise<void> {
    this.resetResetPassordForm();
    JQueryHelper.closeModal('#modal-popup-resetpassword');
  }
  resetResetPassordForm(): void {
    this.change_password_form = undefined;
  }

  async onFinishResetPasswordClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to reset the password?');
      if (!consent) {
        return;
      }
      this.change_password_save_loading = true;
      //console.log(JSON.stringify(this.change_password_form.value, null, 4));
      if (this.change_password_form.value.password !== this.change_password_form.value.confirm_password) {
        alert('Password and Confirm passwords are not matching');
        return;
      }
      const saved_student_response = await this.restService.resetUserPassword(this.change_password_form.value);
      if (saved_student_response && saved_student_response.success === true) {
        alert('Resetted Successfully');
        this.onResetPassordCloseClick();
      } else {
        alert('Error while Reset');
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.change_password_save_loading = false;
    }
  }


  async downloadAsCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const student of this.students_list) {
      let father_details = student.family_members.map(data => {
        if (data.relationship_type === "Father") {
          this.father_occupation = data.occupation;
          this.father_salary = data.salary
        }
      });
      // const student_response = await this.restService.getStudentInfo(student.sub);
      // console.log("stud",student_response)
      this.student_address = student.address.present.street + ' ' +
        student.address.present.city + '-' + student.address.present.district + '' +
        student.address.present.state;
      this.student_blood_group = student.blood_group;
      data_list.push({
        'S.No': i,
        'ROLL NO.': student.roll_no,
        NAME: student.given_name + (student.middle_name ? ' ' + student.middle_name : ''),
        GENDER: student.gender ? student.gender.toUpperCase() : 'N/A',
        'BLOOD GROUP': this.student_blood_group,
        'PRESENT ADDRESS': this.student_address,
        'MOBILE NO.': student.mobile_number,
        EMAIL: student.email,
        'PROGRAMME NAME': student.programme_name + (student.finance_type === 'regular' ? ' (R)' : ' (SF)'),
        //newly added fields
        'FINANCE TYPE': student.finance_type,
        'ENROLLED YEAR': student.enrolled_year,
        'COMMUNITY': student.community_id,
        'CASTE': student.caste,
        'RELIGION': student.religion,
        'FATHER NAME': student.father_name,
        'FATHER OCCUPATION': this.father_occupation,
        'FATHER SALARY': this.father_salary

      });
      i++;
    }



    CSVHelper.downloadCSV(data_list, 'students-list-as-of-' + DateHelper.convertToControlDate(new Date()));
  }

  viewBreakups(breakup_data) {
    this.viewBreakupsClick(breakup_data);
    //console.log(breakup_data);
  }
  async viewBreakupsClick(payment_object: any): Promise<void> {
    if (payment_object.fee_header_type == "EXAM_FEE") {
      this.current_selection = payment_object;
      this.prev_modal_state = this.modal_mode;
      this.modal_mode = 'BREAKUP-CHARGES'
      this.active_accordion = 0;
    }
  }

  async onCloseBreakupsClick() {
    if (this.current_selection.fee_header_type == "EXAM_FEE") {
      JQueryHelper.closeModal('#exam-fee-modal-popup');
      this.current_selection = null;
      this.modal_mode = this.prev_modal_state;
      this.prev_modal_state = "";
      setTimeout(() => {
        document.getElementById("collapse-three").click();
      }, 200)

    }
  }

  changeActiveAccordion(accordion_no: number) {
    if (this.active_accordion == accordion_no)
      this.active_accordion = 0;
    else
      this.active_accordion = accordion_no;
  }
  isActiveAccordion(accordion_no: number) {
    if (accordion_no == this.active_accordion) return true;
    return false;
  }


  async onSeedCourses(student, i: number) {
    try {
      this.seed_loading[i] = true;
      const res = await this.restService.seedCoursesForStudent(student.enrolled_year, student.roll_no);
      if (res) {
        alert("Student Courses Seeding done successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.seed_loading[i] = false;
    }
  }

}
