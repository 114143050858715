<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Programme Outcome Report</p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!--Programme-->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Regulation
                                            year</label>
                                        <select formControlName="regulation_year" class="form-control">
                                            <option value="">Select Regulation year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolled year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <div id="accordion" *ngIf="display_card">
                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-one" data-toggle="collapse"
                                        href="#collapseOne" (click)="changeActiveAccordion(1)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Programme-Courses Attainment</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faCheck" class="valid"
                                                    *ngIf="report_data.programme_course_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faTimes" class="invalid"
                                                    *ngIf="!report_data.programme_course_marks"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(1)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(1)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseOne" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <!--No Data in Report; Upload data-->
                                        <ng-container *ngIf="!report_data || !report_data.programme_course_marks">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <button class="btn btn-md btn-info" *ngIf="!pgm_course_list_loading"
                                                        (click)="getPgmCourse()">Get
                                                        Programme-Course Attainment
                                                        Marks</button>

                                                    <button class="btn btn-md btn-info" type="button" disabled
                                                        *ngIf="pgm_course_list_loading">
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="report_data && report_data.programme_course_marks">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="viewUploadedMarks('PROGRAMME-COURSE')">View Uploaded
                                                        Programme-Course Scores</button>
                                                    <button class="btn btn-md btn-danger mg-l-10"
                                                        (click)="removeUploadedMarks('PROGRAMME-COURSE')">Remove
                                                        Uploaded
                                                        Marks</button>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-two" data-toggle="collapse"
                                        href="#collapseTwo" (click)="changeActiveAccordion(2)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Graduate Exit Survey</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faCheck" class="valid"
                                                    *ngIf="report_data.ges_data"></fa-icon>
                                                <fa-icon [icon]="fonts.faTimes" class="invalid"
                                                    *ngIf="!report_data.ges_data"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(2)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(2)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <ng-container *ngIf="!report_data || !report_data.ges_data">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="radio" value="EXISTING" [(ngModel)]="ges_value"
                                                            name="ges_value">
                                                        Use Existing Survey Marks
                                                        <input type="radio" value="FILE" [(ngModel)]="ges_value"
                                                            name="ges_value" class="mg-l-10"> Upload
                                                        File
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row row-sm" *ngIf="ges_value == 'EXISTING'">
                                                <div class="col-md-4">
                                                    <button class="btn btn-md btn-info" (click)="getGESData()"
                                                        *ngIf="!ges_list_loading">Get
                                                        Existing Survey Marks</button>
                                                    <button class="btn btn-md btn-info" type="button" disabled
                                                        *ngIf="ges_list_loading">
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="ges_value == 'FILE'">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <app-modal [render_data]="ges_render_data"
                                                            (csvData)="bulkUploadGES($event)"></app-modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="report_data && report_data.ges_data">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="viewUploadedMarks('GES')">View Uploaded
                                                        Marks</button>
                                                    <button class="btn btn-md btn-danger mg-l-10"
                                                        (click)="removeUploadedMarks('GES')">Remove Uploaded
                                                        Marks</button>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-three" data-toggle="collapse"
                                        href="#collapseThree" (click)="changeActiveAccordion(3)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Co-Curricular Exit Survey</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faCheck" class="valid"
                                                    *ngIf="report_data.ces_data"></fa-icon>
                                                <fa-icon [icon]="fonts.faTimes" class="invalid"
                                                    *ngIf="!report_data.ces_data"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(3)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(3)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseThree" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <ng-container *ngIf="!report_data || !report_data.ces_data">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="radio" value="EXISTING" [(ngModel)]="ces_value"
                                                            name="ces_value"> Use
                                                        Existing Survey results
                                                        <input type="radio" value="FILE" [(ngModel)]="ces_value"
                                                            name="ces_value" class="mg-l-10"> Upload File
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="ces_value == 'EXISTING'">
                                                <div class="col-md-4">
                                                    <button class="btn btn-md btn-info" (click)="getCESCO()"
                                                        *ngIf="!ces_list_loading">Get
                                                        Existing Survey Marks</button>
                                                    <button class="btn btn-md btn-info" type="button" disabled
                                                        *ngIf="ces_list_loading">
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="ces_value == 'FILE'">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <app-modal [render_data]="ces_render_data"
                                                            (csvData)="bulkUploadCES($event)"></app-modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="report_data && report_data.ces_data">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <button class="btn btn-md btn-info"
                                                        (click)="viewUploadedMarks('CES')">View Uploaded
                                                        Marks</button>
                                                    <button class="btn btn-md btn-danger mg-l-10"
                                                        (click)="removeUploadedMarks('CES')">Remove
                                                        Uploaded
                                                        Marks</button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" id="collapse-four" data-toggle="collapse"
                                        href="#collapseFour" (click)="changeActiveAccordion(4)">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <b>Report Parameters</b>
                                            </div>
                                            <div class="col-md-3 text-align-right">
                                                <fa-icon [icon]="fonts.faAngleDown"
                                                    *ngIf="!isActiveAccordion(4)"></fa-icon>
                                                <fa-icon [icon]="fonts.faAngleUp"
                                                    *ngIf="isActiveAccordion(4)"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div id="collapseFour" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Direct
                                                        Attainment
                                                        Scale (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.da_scale"
                                                        placeholder="Enter % to which Direct attainment must be scaled">
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        class="main-content-label tx-11 tx-medium tx-gray-600">Graduate
                                                        Exit Survey Scale (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.ges_scale"
                                                        placeholder="Enter value to which the total internal marks has to be scaled down">
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        class="main-content-label tx-11 tx-medium tx-gray-600">Co-Curricular
                                                        Exit Survey Scale (%)</label>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="report_data.ces_scale"
                                                        placeholder="Enter value to which the total external marks has to be scaled down">
                                                </div>
                                            </div>



                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label
                                                        class="main-content-label tx-11 tx-medium tx-gray-600">Remarks</label>
                                                    <textarea class="form-control" [(ngModel)]="report_data.remarks"
                                                        rows="3" cols="10" placeholder="Enter Remarks"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row" *ngIf="display_card">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-success btn-md" *ngIf="!report_loading"
                                    (click)="saveUpdateReport()" [disabled]="!canDisplayReport()">Save & Generate
                                    Report</button>
                                <button class="btn btn-md btn-success" type="button" disabled *ngIf="report_loading">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Saving...
                                </button>
                            </div>
                        </div>

                        <!--End of accordion-->

                        <div class="row" *ngIf="display_card && canDisplayReport() && report_data?._id">
                            <div class="col-md-12" style="text-align: right;">
                                <button class="btn btn-sm btn-secondary mg-r-10" (click)="viewConsolidated()">View
                                    Consolidated PO Report</button>
                                <button class="btn btn-sm btn-secondary mg-r-10" (click)="downloadExcel()">Download
                                    Excel</button>
                                <button class="btn btn-sm btn-secondary" (click)="downloadPDF()">Download PDF</button>
                            </div>
                        </div>

                        <!--Report Table-->
                        <ng-container *ngIf="display_card && canDisplayReport() && report_data?._id">
                            <div class="container" id="report-html">
                                <div class="table-responsive" style="margin-top: 20px;">
                                    <table class="table table-bordered" id="report-table">
                                        <tbody>
                                            <tr>
                                                <th colspan="17" style="text-align: center;">AYYA NADAR JANAKI AMMAL
                                                    COLLEGE (Autonomous), SIVAKASI</th>
                                            </tr>
                                            <tr>
                                                <th colspan="17" style="text-align: center;">Programme:
                                                    {{report_data.programme_name}}</th>
                                            </tr>
                                            <tr>
                                                <th colspan="17" style="text-align: center;">Enrolled Year:
                                                    {{report_data.enrolled_year}}</th>
                                            </tr>
                                            <tr>
                                                <th colspan="17" style="text-align: center;">Evaluation of Attainment of
                                                    Programme Outcome (PO)</th>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Course Code
                                                </th>
                                                <th>
                                                    Course Title
                                                </th>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.programme_course_marks?.pam_courses_data[0]?.pam_weights | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.key | uppercase}}
                                                    </th>
                                                </ng-container>
                                                <th>
                                                    Final CO Attainment of the Courses
                                                </th>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.programme_course_marks?.pam_courses_data[0]?.programmme_course_marks_attainment | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.key | uppercase}}
                                                    </th>
                                                </ng-container>
                                            </tr>

                                            <!--Report Rows-->
                                            <tr
                                                *ngFor="let course of report_data?.programme_course_marks?.pam_courses_data">
                                                <td>
                                                    {{course.course_code}}
                                                </td>
                                                <td>
                                                    {{course.course_name}}
                                                </td>
                                                <ng-container *ngFor="let weight of course.pam_weights | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                                <td>
                                                    {{course.final_cas_attainment}}
                                                </td>
                                                <ng-container
                                                    *ngFor="let weight of course.programmme_course_marks_attainment | keyvalue;">
                                                    <td *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Total weightage</td>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.programme_course_marks?.pam_total_weights | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </th>
                                                </ng-container>
                                                <td></td>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.programme_course_marks?.attainment_total_weights | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </th>
                                                </ng-container>
                                            </tr>

                                            <tr>
                                                <td colspan="10">Direct PO Attainment for {{report_data.da_scale}}%</td>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.des_scaled_results | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <td colspan="10">Indirect PO Attainment for Exit Survey
                                                    {{report_data.ges_scale}}%</td>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.ges_scaled_results | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <td colspan="10">Indirect PO Attainment for Co-Curricular Survey
                                                    {{report_data.ces_scale}}%</td>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.ces_scaled_results | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <td colspan="10">Direct and Indirect Attainment
                                                    ({{report_data.da_scale}} + {{report_data.ges_scale}} +
                                                    {{report_data.ces_scale}})</td>
                                                <ng-container
                                                    *ngFor="let weight of report_data?.total_po_marks | keyvalue;">
                                                    <th *ngIf="weight.key != 'total'">
                                                        {{weight.value}}
                                                    </th>
                                                </ng-container>
                                            </tr>

                                            <tr>
                                                <td colspan="16">Average PO Attainment for
                                                    {{report_data.programme_name}}</td>
                                                <th>
                                                    {{report_data.total_marks}}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td colspan="16">Grade</td>
                                                <th>
                                                    {{report_data.grade}}
                                                </th>
                                            </tr>

                                            <tr>
                                                <td colspan="16">Remarks</td>
                                                <th>
                                                    {{report_data.remarks}}
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </ng-container>
                        <!--Report Table End-->

                        <ng-container *ngIf="display_card && canDisplayReport() && report_data?._id">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Observations
                                                by OBEMC</label>
                                            <textarea class="form-control"
                                                [(ngModel)]="report_data.observations_by_obemc" rows="3" cols="10"
                                                placeholder="Enter Observations given by OBEMC"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Suggestions by
                                                OBEMC</label>
                                            <textarea class="form-control"
                                                [(ngModel)]="report_data.suggestions_by_obemc" rows="3" cols="10"
                                                placeholder="Enter Suggestions given by OBEMC"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="display_card && !canDisplayReport()">
                            <p><b>Please upload all outcome inputs & set report params to generate and view the
                                    report.</b></p>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup-attainmentreport">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="this.po_table_data">
                    <div class="modal-header">
                        <h6 class="modal-title">View PO-Wise Marks</h6>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 3%;">S.No</th>
                                            <th style="width: 10%;">Roll No</th>
                                            <th style="width: 20%;">Name</th>
                                            <th *ngFor="let weight of po_table_data.weightage | keyvalue;">
                                                {{weight.key | uppercase}} ({{weight.value}})

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let mark of po_table_data.marks; let i = index;">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{mark.roll_no}}
                                            </td>
                                            <td>
                                                {{mark.given_name}} {{mark.middle_name}}
                                            </td>
                                            <td *ngFor="let weight of mark.po_marks | keyvalue;">
                                                {{weight.value}}
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onCasReportCloseClick()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!-- Large Modal -->
        <div class="modal" id="modal-popup-pgm-course">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="this.pgm_course_modal_data">
                    <div class="modal-header">
                        <h6 class="modal-title">View Programme-Course Attainment Scores</h6>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 10%;">Course Code</th>
                                            <th style="width: 20%;">Course Title</th>
                                            <th
                                                *ngFor="let weight of pgm_course_modal_data?.pam_courses_data[0]?.pam_weights | keyvalue;">
                                                {{weight.key | uppercase}}
                                            </th>
                                            <th>
                                                Final CO Attainment of the Courses
                                            </th>
                                            <th
                                                *ngFor="let weight of pgm_course_modal_data?.pam_courses_data[0]?.programmme_course_marks_attainment | keyvalue;">
                                                {{weight.key | uppercase}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let course of pgm_course_modal_data.pam_courses_data; let i = index;">
                                            <td>
                                                {{course.course_code}}
                                            </td>
                                            <td>
                                                {{course.course_name}}
                                            </td>
                                            <td *ngFor="let weight of course?.pam_weights | keyvalue;">
                                                {{weight.value}}
                                            </td>
                                            <td>
                                                {{course.final_cas_attainment}}
                                            </td>
                                            <th
                                                *ngFor="let weight of course?.programmme_course_marks_attainment | keyvalue;">
                                                {{weight.value}}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                Total Weightage
                                            </td>
                                            <th
                                                *ngFor="let weight of pgm_course_modal_data?.pam_total_weights | keyvalue;">
                                                {{weight.value}}
                                            </th>
                                            <td></td>
                                            <th
                                                *ngFor="let weight of pgm_course_modal_data?.attainment_total_weights | keyvalue;">
                                                {{weight.value}}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onPgmCourseCloseClick()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!-- Large Modal -->
        <div class="modal" id="modal-popup-consolidated">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo"
                    *ngIf="display_card && canDisplayReport() && report_data?._id">
                    <div class="modal-header">
                        <h6 class="modal-title">View Final PO Attainment Consolidated Sheet</h6>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="row" style="margin-bottom: 10px;">
                            <div class="col-md-12" style="text-align: right;">
                                <button class="btn btn-sm btn-primary" (click)="downloadConsolidatedPDF()">
                                    Print
                                </button>
                            </div>
                        </div> -->
                        <div id='print-consolidated'>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 40%;">Evaluation of PO Attainment</th>
                                            <ng-container
                                                *ngFor="let weight of report_data?.programme_course_marks?.attainment_total_weights | keyvalue;">
                                                <th *ngIf="weight.key != 'total'">
                                                    {{weight.key | uppercase}}
                                                </th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Direct PO Attainment for 100 % from all courses</td>
                                            <ng-container
                                                *ngFor="let weight of report_data?.programme_course_marks?.attainment_total_weights | keyvalue;">
                                                <td *ngIf="weight.key != 'total'">
                                                    {{weight.value}}
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <td>Direct PO Attainment for {{report_data.da_scale}}% from all courses</td>
                                            <ng-container
                                                *ngFor="let weight of report_data?.des_scaled_results | keyvalue;">
                                                <td *ngIf="weight.key != 'total'">
                                                    {{weight.value}}
                                                </td>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <td>Indirect PO Attainment for Exit Survey
                                                {{report_data.ges_scale}}%</td>
                                            <ng-container
                                                *ngFor="let weight of report_data?.ges_scaled_results | keyvalue;">
                                                <td *ngIf="weight.key != 'total'">
                                                    {{weight.value}}
                                                </td>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <td>Indirect PO Attainment for Co-Curricular Survey
                                                {{report_data.ces_scale}}%</td>
                                            <ng-container
                                                *ngFor="let weight of report_data?.ces_scaled_results | keyvalue;">
                                                <td *ngIf="weight.key != 'total'">
                                                    {{weight.value}}
                                                </td>
                                            </ng-container>
                                        </tr>
                                        <tr>
                                            <td>Final Attainment of POs
                                                (Direct and Indirect Attainment = {{report_data.da_scale}} +
                                                {{report_data.ges_scale}} +
                                                {{report_data.ces_scale}})</td>
                                            <ng-container
                                                *ngFor="let weight of report_data?.total_po_marks | keyvalue;">
                                                <th *ngIf="weight.key != 'total'">
                                                    {{weight.value}}
                                                </th>
                                            </ng-container>
                                        </tr>

                                        <tr>
                                            <td>Average PO% attained</td>
                                            <th colspan="7">
                                                {{report_data.total_marks}}
                                            </th>
                                        </tr>

                                        <tr>
                                            <td>Rank</td>
                                            <th colspan="7">
                                                {{report_data.grade}}
                                            </th>
                                        </tr>

                                        <tr>
                                            <td>Observations by OBEMC</td>
                                            <th colspan="7">
                                                {{report_data.observations_by_obemc}}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>Suggestions by OBEMC</td>
                                            <th colspan="7">
                                                {{report_data.suggestions_by_obemc}}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p><b>Attainment of Programme Outcomes (POs)</b></p>
                            <div style="width: 300px; height: 300px;">
                                <canvas id="myPieChart"></canvas>
                            </div>

                            <p class="mg-b-10">Legend:</p>
                            <div class="table-responsive">
                                <table class="table table-bordered" style="width: 30%;">
                                    <thead>
                                        <tr>
                                            <th>Average PO% Attained</th>
                                            <th>Rank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>>80 & above</td>
                                            <td>Excellent</td>
                                        </tr>
                                        <tr>
                                            <td>>70 to 80</td>
                                            <td>Very Good</td>
                                        </tr>
                                        <tr>
                                            <td>>60 to 70</td>
                                            <td>Good</td>
                                        </tr>
                                        <tr>
                                            <td>>50 to 60</td>
                                            <td>Average</td>
                                        </tr>
                                        <tr>
                                            <td> Less than 50 </td>
                                            <td>Least Significant</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="row mg-t-30">
                                <div class="col-md-3 text-center">
                                    <p>OBE Monitoring Council</p>
                                </div>
                                <div class="col-md-3 text-center">
                                    <p>IQAC</p>
                                </div>
                                <div class="col-md-3 text-center">
                                    <p>COE</p>
                                </div>
                                <div class="col-md-3 text-center">
                                    <p>Principal</p>
                                </div>
                            </div>
                        </div>
                        <div class="mg-b-20"></div>
                        <div class="text-align-right">
                            <button class="btn ripple btn-secondary" (click)="closeConsolidated()"
                                type="button">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
