<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>

  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Incharges</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
    </div>

    <div class="row row-sm">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card" >
                <div class="card-header pb-0">
                  <div class="d-flex justify-content-between">
                    <h4 class="card-title mg-b-0 mb-3">Generate Monthly Bill</h4>
                  </div>
                  <span>Year: <b>{{year}}</b></span>
                  <select
                  class="form-control"
                  [(ngModel)]="month" 
                  (change)="chooseMonth()"> 
                      <option value="">Select Month</option>
                      <option *ngFor="let month of months;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="month.month_name">{{month.month_name}}</option>
                  </select>
                </div>
      
                
                <div class="card-body">
                  <div class="table-responsive border-top userlist-table" *ngIf="monthly_expenses.length>0">
                    <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                      <thead>
                        <th>
                            <span>S.No</span>
                        </th>
                        <th>
                          <span>Expense Type</span>
                        </th>
                        <th>
                          <span>Amount</span>
                        </th>
    
                      </thead>
                      <tbody>
                      <tr *ngFor="let expense of monthly_expenses; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{expense.expense_name}}</td>
                        <td>{{expense.expense_amount}}</td>
                      </tr>
                      <tr><td colspan="2">Total Expense</td><td><b>Rs. {{monthly_total}}</b></td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="add-btn-row" *ngIf="monthly_expenses.length>0">
                    <button class="btn btn-primary mg-r-20" >Generate</button>
                </div>
                </div>
                
              </div>
       
        
      </div>
    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
