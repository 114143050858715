import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { MenuItems } from 'src/app/controllers/admins/hostel-management/manage-mess/menu-item';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-manage-menu-items',
  templateUrl: './manage-menu-items.component.html',
  styleUrls: ['./manage-menu-items.component.scss']
})
export class ManageMenuItemsComponent implements OnInit {
  menuItems: MenuItems = {} as MenuItems;
  itemType = ['Veg', 'Non-Veg'];
  menuItemForm: FormGroup;
  title = 'Create Menu Item';
  id = '';
  fromDataResolver = FromDataResolver;

  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.menuItemForm = this.formBuilder.group({
        item_name: ['', [Validators.required]],
        item_code: ['', [Validators.required]],
        item_type: [''],
        quantity: [0,Validators.required],
        price: [0, Validators.required],
      });
    }
  
  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Menu Items', url: 'admin/hostel/manage-mess' };
    this.route.params.subscribe(params => {
      this.id = params?.id;
      if (this.id) {
        this.title = 'Edit Menu Item';
        this.getMenuItem();
      }
      this.navigationService.updateNavigation(this.title, this.router.url);
    });
  }

  async getMenuItem(): Promise<void> {
    try {
      const res: MenuItems[] = await this.restService.menuItemService.getMenuItems(this.id);
      if (res) {
        this.menuItemForm = this.formBuilder.group({
          item_name: res[0].item_name,
          item_type: res[0].item_type,
          quantity: res[0].quantity,
          price: res[0].price
        });
      }
    } catch(erro) {
      this.notificationService.setNotifcation({ message: 'Failed load menu item.', type: 'Failed' });
    }
  }

  getTittle(): string {
    return this.title;
  }
  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  isInvalid(formControl: string): boolean {
    if ((formControl === 'quantity' || formControl === 'price') && isNaN(this.menuItemForm.get(formControl)?.value)) {
      return true;
    }
    return this.menuItemForm.touched && this.menuItemForm.get(formControl)?.errors !== null;
  }

  getMenuItemFromForm(): MenuItems {
    const menuItem = this.getFormData();
    return {
      id: this.id,
      item_name: menuItem.item_name,
      item_type: menuItem.item_type,
      quantity: menuItem.quantity,
      price: menuItem.price
    };
  }

  canDisable(): boolean {
    const type = this.menuItemForm.value.item_type;
    if (!this.itemType.includes(type)) {
      return true;
    }
    if (isNaN(this.menuItemForm.value?.quantity)) {
      return true;
    }
    if (isNaN(this.menuItemForm.value?.price)) {
      return true;
    }
    return this.menuItemForm.status !== 'VALID';
  }

  getFormData(): any {
    return this.menuItemForm.value;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
   if (this.id) {
     this.updateMenuItem();
   } else {
     this.createMenuItem();
   }
  }

  async updateMenuItem(): Promise<void> {
    try {
      const res = await this.restService.menuItemService.updateMenuItems(this.id, this.getMenuItemFromForm());
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to update menu item.', type: 'Failed'});
      }
      this.notificationService.setNotifcation({ message: 'Updated menu item successfully', type: 'Success'});
      this.onClose();
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to update menu item.', type: 'Failed'});
    }    
  }

  async createMenuItem(): Promise<void> {
    try {
      const res = await this.restService.menuItemService.creatMenuItems(this.getMenuItemFromForm());
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
      }
      this.notificationService.setNotifcation({ message: 'Created menu item successfully', type: 'Success'});
      this.onClose();
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
    }
  }
}
