<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>

  <!-- container -->
  <div class="container-fluid ">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="onClickIncharges()">Manage Incharges</button>
        </div>
      </div>
    </div>

    <!-- Edit Room -->
    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="card box-shadow-0">
          <div class="card-header">
            <h4 class="card-title mb-1">Edit Room</h4>
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="roomForm" class="form-horizontal">
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Update Room Name</label>
                <input class="form-control" required="" type="text" formControlName="name">
              </div>
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canSave()">Update Room</button>
                <button class="btn ripple btn-secondary" (click)="onClose()" type="button">Close</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>


  </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
