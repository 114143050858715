import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-review-mark',
  templateUrl: './review-mark.component.html',
  styleUrls: ['./review-mark.component.scss']
})
export class ReviewMarkComponent implements OnInit {


  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;
  // master data
  programme_search_form: any;

  // core data

  review_component_list: any[] = [];
  review_list_loading = false;

  course_mark_map_form: any;
  course_mark_map_form_loading = false;


  // assign mark limit
  selected_course: any;
  selected_component: any;

  assign_mark_map_form: any;
  assign_mark_map_form_loading = false;
  programme_type_list: any[] = [];


  // cas report
  cas_report_data: any;
  cas_report_data_loading = false;

  // cos report
  cos_report_data: any;
  cos_report_data_loading = false;

  // assign students
  assign_student_form: any;
  assign_student_form_loading = false;

  assigned_students_list: any;
  assigned_students_list_loading: any;


  // assigng survey questions


  question_list_loading = false;

  question_mark_map_form: any;
  question_mark_map_form_loading = false;

  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;

  department_list_loading: boolean = false;
  department_list: any[] = [];
  sections: any = [];
  parts: any[] = [];
  part_course_types: any[] | undefined = [];

  question_status_map_form_loading = false;

  question_status_map_form_saving = false;

  hod_submit_loading: boolean = false;
  coe_submit_loading: boolean = false;

  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;
  description: string = "";
  reviewer_description: string = "";
  component: any;
  to_stage_count: number
  review_stages: string[] = ["STAFF", "OBE_MEMBER", "HOD", "COE", "FINALIZED"];
  stages: string[] = ["STAFF", "OBE_MEMBER", "HOD", "FINALIZED", "NOT_FINALIZED"];
  finalize_loading: boolean[] = [];
  approve_all_loading: boolean[] = [];
  filtered_department_list: any[] = [];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {

    // Load master data
    this.buildComponentSearchForm();
    this.getProgrammeTypeList();
    this.getDepartmentDataList();
    /* if (!this.cookieStore.isRoleExists(['coe']))
      this.onSearchClick(); */
    // Load core data

  }



  /// master data
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  buildComponentSearchForm(): void {

    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      enrolled_year: [0],
      semester: [0],
      programme_id: [''],
      //section: [''],
      //department_id: [''],
      course_code: [''],
      stage: ['']
      //part:[''],
      //course_type:['']
    });
  }

  async onFilterSelectChange(id: string, event?: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.programme_list = []; this.sections = [];
      this.programme_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.programme_search_form);
    }
    if (id == "programme_id") {
      this.sections = [];
      this.programme_search_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.programme_search_form.get('section').setValue([this.sections[0]]);
        }
      }
    }
    /* if (id === 'part') {
      this.programme_search_form.get('course_type').setValue("");
      if (this.programme_search_form.value.programme_type_id) {
        this.part_course_types = this.commonEnums.getCoursePartType(
          this.programme_search_form.value.programme_type_id, this.programme_search_form.value.part);
      }
    } */
  }

  getName(name: string) {
    return name.replace('undefined', '');
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async getDepartmentDataList(): Promise<void> {
    try {
      this.department_list_loading = true;
      this.department_list = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_list_loading = false;
    }
  }

  filterDepartment(ev) {
    this.filtered_department_list = [];
    var select = ev.target as HTMLSelectElement;
    this.filtered_department_list = this.department_list.filter(x => x.department_category_id == select.value);
    console.log(this.filtered_department_list)
  }



  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }

  async onSearchClick(): Promise<void> {
    try {
      this.review_list_loading = true;
      this.review_component_list = [];
      var search_query: any = {};

      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please select Enrollment Year');
        return;
      }

      if (!this.programme_search_form.value.semester) {
        alert('Please select Semester');
        return;
      }

      if (this.cookieStore.isRoleExists(['coe'])) {
        if (!this.programme_search_form.value.programme_type_id) {
          alert('Please select Programme Type');
          return;
        }
      }

      search_query = {
        //programme_type_id: this.programme_search_form.value.programme_type_id,
        enrolled_year: this.programme_search_form.value.enrolled_year,
        semester: this.programme_search_form.value.semester,
        skip: this.start_index,
        limit: this.take_index
      };
      /* if (this.programme_search_form.value.programme_id) {
        search_query.programme_id = this.programme_search_form.value.programme_id;
      }
      if (this.programme_search_form.value.section) {
        search_query.section = this.programme_search_form.value.section;
      } */
      if (this.programme_search_form.value.programme_type_id) {
        search_query.programme_type_id = this.programme_search_form.value.programme_type_id;
      }
      else {
        search_query.programme_type_id = "";
      }
      if (this.programme_search_form.value.programme_id) {
        search_query.programme_id = this.programme_search_form.value.programme_id;
      }
      if (this.programme_search_form.value.course_code) {
        search_query.course_code = this.programme_search_form.value.course_code;
      }
      if (this.programme_search_form.value.stage) {
        search_query.stage = this.programme_search_form.value.stage;
      }

      const service_response = await this.restService.getSubmittedMarks(search_query);
      if (service_response && service_response.success) {
        this.review_component_list = service_response.data;
        let index = 0;
        this.review_component_list.forEach(course => {
          course.is_opened = true;
          this.finalize_loading[index] = false;
          this.approve_all_loading[index] = false;
          index++;
        });
        console.log(this.review_component_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.review_list_loading = false;
    }
  }

  onViewComponentsClick(course) {
    course.is_opened = true;
  }

  onCloseComponentsClick(course) {
    course.is_opened = false;
  }

  async onFinalizeClick(course, i: number) {
    if (course.overall_submitted) {
      alert("This course is already finalized");
    }
    if (!course.all_components_submitted) {
      alert("Error! Please finalize all components before finalizing entire course!")
    }
    else {
      const consent = confirm("Warning: Are you sure want to finalize Internal Submission for this course? This action is irreversible!");
      if (!consent) return;
      var save_data: any = {
        "programme_type_id": course.offered_by_programme_type_id,
        "course_code": course.course_code,
        "part": course.part,
        "course_type": course.course_type,
        "enrolled_year": course.enrolled_year,
        "semester": course.semester,
      }
      if (course.programme_id) {
        save_data.programme_id = course.programme_id
      }
      if (course.section) {
        save_data.section = course.section
      }
      if (course.virtual_section) {
        save_data.virtual_section = course.virtual_section
      }
      try {
        this.finalize_loading[i] = true;
        const service_response = await this.restService.coeFinalize(save_data);
        if (service_response && service_response.success) {
          this.onSearchClick();
          alert("Course successfully finalized");
        }
        else {
          alert("Error! Could not finalize course");
        }
      }
      catch (error) {
        alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      } finally {
        this.finalize_loading[i] = false;
      }
    }
  }

  viewConReport(course) {

  }

  async onReviewMarkClick(component: any) {
    try {
      var component_data: any = {
        course_code: component.course_code,
        enrolled_year: component.enrolled_year,
        semester: component.semester,
        obe_component_id: component.obe_component_id
      }
      component.mark_loading = true;
      const mark_service_response = await this.restService.getCouseCoCOM(component_data);
      if (mark_service_response && mark_service_response.success
        && mark_service_response.data && mark_service_response.data.length > 0) {
        const max_mark_settings = mark_service_response.data;
        if (component.virtual_section) {
          component_data.virtual_section = component.virtual_section;
        }
        else {
          component_data.programme_id = component.programme_id;
          component_data.section = component.section;
        }
        const service_response = await this.restService.reviewMarkEntry(component_data);
        if (service_response && service_response.success) {
          console.log(service_response.data);
          this.buildMarkAssignForm(max_mark_settings, component, service_response.data.data);
          JQueryHelper.openModal('#modal-popup-assignmark', { keyboard: false, backdrop: 'static' });
        }
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      component.mark_loading = false;
    }
  }

  buildMarkAssignForm(max_mark_settings: any[], component: any, data: any[]): void {

    this.assign_mark_map_form = this.fb.group({
      course_code: [component.course_code],
      section: [component.section],
      //course_name: [course.course_name],
      obe_component_id: [component.obe_component_id],
      component_type: [component.component_type],
      term: [component.term],
      students: this.fb.array(this.buildMarkAssignItemForm(max_mark_settings, component, data)),
      course_file: []
    });
  }

  buildMarkAssignItemForm(max_mark_settings: any[], component: any, data: any[]): any[] {
    const item_to_return = [];
    if (!data) {
      data = [];
    }

    for (const data_item of data) {
      let total = 0;
      for (const course_mark of data_item.course_marks) {
        total = Number((total + Number(course_mark.mark)).toFixed(2));
      }
      item_to_return.push(this.fb.group({
        sub: [data_item.sub],
        given_name: [data_item.given_name],
        middle_name: [data_item.middle_name],
        roll_no: [data_item.roll_no],
        course_marks: this.fb.array(this.buildMarkItemForm(max_mark_settings, component, data_item.sub, data_item.course_marks)),
        total: [total],
      }));
    }

    return item_to_return;
  }

  buildMarkItemForm(max_mark_settings: any[], component: any, sub: string, data: any[]): any[] {
    const item_to_return = [];
    if (!data) {
      data = [];
    }

    for (const data_item of data) {
      const co_max_settings = max_mark_settings.find(c => c.co_id === data_item.co_id);
      let max_mark = 0;
      if (co_max_settings) {
        max_mark = co_max_settings.max_mark;
      }
      item_to_return.push(this.fb.group({
        sub: [sub],
        course_code: [component.course_code],
        obe_component_id: [data_item.obe_component_id],
        co_id: [data_item.co_id],
        mark: [data_item.mark],
        max_mark: [max_mark],
        semester: [component.semester],
        enrolled_year: [component.enrolled_year],
        stage: [component.stage] //new field added
      }));
    }

    return item_to_return;
  }

  async onMarkAssignCloseClick(): Promise<void> {
    this.resetMarkAssignForm();
    JQueryHelper.closeModal('#modal-popup-assignmark');
  }
  resetMarkAssignForm(): void {
    this.assign_mark_map_form = undefined;
    this.selected_course = undefined;
    this.selected_component = undefined;
  }

  async onMarkAssignSaveClick(student: any, course_mark_form: FormGroup): Promise<void> {
    try {
      let has_error = false;
      if (course_mark_form.value.mark > course_mark_form.value.max_mark) {

        course_mark_form.patchValue({
          mark: 0
        });
        has_error = true;
      }
      let total = 0;
      for (const course_mark of student.value.course_marks) {
        total = total + Number(course_mark.mark);
      }
      student.value.total = total;

      if (has_error && !course_mark_form.value._id) {
        return;
      }
      this.course_mark_map_form_loading = true;
      const update_mark = JSON.parse(JSON.stringify(course_mark_form.value));
      delete update_mark.stage;
      const service_response = await this.restService.createCourseCoCOMMark(update_mark);
      //Which V2 API to save student mark assignment??
      if (service_response && service_response.success) {

      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_mark_map_form_loading = false;
    }
  }


  async getDescription(component: any, to_stage_count: number) {
    this.component = component;
    this.component.submit_loading = false;
    this.to_stage_count = to_stage_count;
    var to_stage_index = this.review_stages.indexOf(this.component.stage) + this.to_stage_count;
    var to_stage = this.review_stages[to_stage_index];
    this.component.to_stage = to_stage;
    this.description = "";
    JQueryHelper.openModal('#modal-description', { keyboard: false, backdrop: 'static' });
  }

  async moveComponent() {
    var to_stage_index = this.review_stages.indexOf(this.component.stage) + this.to_stage_count;
    var to_stage = this.review_stages[to_stage_index];
    var submit_data: any = {
      _id: this.component._id,
      obe_component_id: this.component.obe_component_id,
      course_code: this.component.course_code,
      component_type: this.component.component_type,
      term: this.component.term,
      semester: this.component.semester,
      enrolled_year: this.component.enrolled_year,
      stage: to_stage,
      description: this.description
    }
    if (this.component.virtual_section) {
      submit_data.virtual_section = this.component.virtual_section;
    }
    else {
      submit_data.programme_id = this.component.programme_id;
      submit_data.section = this.component.section;
    }
    try {
      this.component.submit_loading = true;
      console.log(submit_data);
      const service_response = await this.restService.submitForReview(submit_data);
      if (service_response && service_response.success) {
        this.onSearchClick();
        alert('Review Request Moved Successfully');
      }
      else {
        alert("Error! Could not move request");
      }
    }
    catch (error) {
      console.log(error);
      CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save);
    }
    finally {
      this.component.submit_loading = false;
    }
    this.closeDescription();
  }

  async approveAll(course, i) {
    var approve_object: any = {
      course_code: course.course_code,
      enrolled_year: course.enrolled_year,
      semester: course.semester
    }
    if (course.programme_id && course.section) {
      approve_object.programme_id = course.programme_id;
      approve_object.section = course.section;
    }
    else {
      approve_object.virtual_section = course.virtual_section;
    }
    try {
      this.approve_all_loading[i] = true;
      console.log(approve_object);
      const service_response = await this.restService.submitAllComponents(approve_object);
      if (service_response && service_response.success) {
        this.onSearchClick();
        alert('Components Approved Successfully');
      }
      else {
        alert("Error! Could not approve request");
      }
    }
    catch (error) {
      console.log(error);
      CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save);
    }
    finally {
      this.approve_all_loading[i] = false;
    }

  }

  closeDescription() {
    this.component = undefined; this.description = ""; this.to_stage_count = 0;
    JQueryHelper.closeModal('#modal-description');
  }

  openReviewDescription(description: string) {
    this.reviewer_description = description;
    JQueryHelper.openModal('#modal-review-description', { keyboard: false, backdrop: 'static' });
  }

  closeReviewDescription() {
    this.reviewer_description = "";
    JQueryHelper.closeModal('#modal-review-description');
  }
}
