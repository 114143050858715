<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Elective Polling Settings</p>
                    </div>


                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>

                                        <div *ngIf="isInvalid('semester')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required">Semester is required.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!settings_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="settings_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>




                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <!-- <div class="row row-sm">
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled Year
                                                        </label>
                                                        <select [(ngModel)]="enrolled_year" class="form-control">
                                                            <option value="0">Select Enrolled Year</option>
                                                            <option
                                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                                [ngValue]="year">{{year}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                    
                                                <div class="col-lg-4">
                                                    <div class="form-group">
                                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                                                            *ngIf="!settings_list_loading" (click)="onSearchClick()">
                                                            Get Settings
                                                        </button>
                                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                                            *ngIf="settings_list_loading">
                                                            <span class="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"></span>
                                                            Loading...
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> -->

                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Add
                                New Settings</button>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 10%;">Programme Type</th>
                                        <th style="width: 10%;">Enrolled Year</th>
                                        <th style="width: 10%;">Semester</th>
                                        <th style="width: 15%;">Part</th>
                                        <th style="width: 15%;">Course Type</th>
                                        <th style="width: 15%;">Start Date</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="settings_list.length>0">
                                        <ng-container
                                            *ngFor="let setting of settings_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{setting.programme_type_id}}</td>
                                                <td>{{setting.enrolled_year}}</td>
                                                <td>{{setting.semester}}</td>
                                                <td>{{setting.part}}</td>
                                                <td>{{setting.course_type}}</td>
                                                <td>{{setting.start_date | date:commonEnums.date_time_format}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(setting)"
                                                            title="Edit Setting" class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                        <button type="button" (click)="navigateToManageRequest(setting)"
                                                            title="Manage Student Request"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faUser"></fa-icon>
                                                        </button>
                                                        <button type="button"
                                                            (click)="navigateToVirtualSection(setting)"
                                                            title="Manage Virtual Sections"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                                        </button>
                                                        <button type="button" (click)="navigateToReport(setting)"
                                                            title="View Report" class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faList"></fa-icon>
                                                        </button>
                                                        <button type="button" (click)="lockCourseRequest(setting)"
                                                            *ngIf="!setting.locked" title="Lock Setting"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faLock"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="settings_list_loading && settings_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="polling_setting_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Elective Polling Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="polling_setting_form" class="form-horizontal">

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrolled Year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="0">Select year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('enrolled_year')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Year is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="0">Select Semester</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>

                                            <div *ngIf="isInvalid('semester')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Semester is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                                Date</label>
                                            <!-- <input class="form-control" required="" type="date"
                                                formControlName="start_date"> -->
                                            <input type="datetime-local" class="form-control"
                                                formControlName="start_date" placeholder="Enter Start date">
                                            <div *ngIf="isInvalid('start_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                                                Date</label>
                                            <!-- <input class="form-control" required="" type="date"
                                                formControlName="end_date"> -->
                                            <input type="datetime-local" class="form-control" formControlName="end_date"
                                                placeholder="Enter End date">
                                            <div *ngIf="isInvalid('end_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme Type</label>
                                            <select formControlName="programme_type_id" class="form-control"
                                                (change)="onFilterSelectChange('programme_type_id',$event)">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="category.programme_type_id">{{category.name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('programme_type_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Programme Type is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Part</label>
                                            <select formControlName="part" class="form-control"
                                                (change)="onFilterSelectChange('part',$event)">
                                                <option value="">Select Part</option>
                                                <option
                                                    *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="part.id">{{part.text}}
                                                </option>
                                            </select>
                                            <div *ngIf="isInvalid('part')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Part is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-6"
                                        *ngIf="this.part_course_types && this.part_course_types.length>0">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Course Type</label>
                                            <select formControlName="course_type" class="form-control"
                                                (change)="courseTypeChanged($event)">
                                                <option value="">Select Course Type</option>
                                                <option
                                                    *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="course_type.id">{{course_type.text}}</option>
                                            </select>

                                            <div *ngIf="isInvalid('course_type')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Course Type is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Add
                                                Course Codes to be excluded from polling</label>
                                            <input type="text" class="form-control"
                                                formControlName="exclude_course_codes"
                                                placeholder="Ex: 20UAC101,20UAC102...">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="active" class="mg-r-5">Is Active?
                                            <div *ngIf="isInvalid('active')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Choose if the setting is active for
                                                        student polling or
                                                        not.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h6 *ngIf="polling_setting_form.value.course_type=='NON_MAJOR_ELECTIVE'">Polling
                                    Conditions for NME Course</h6>
                                <div class="table-responsive"
                                    *ngIf="polling_setting_form.value.course_type=='NON_MAJOR_ELECTIVE'">
                                    <table class="table table-bordered text-md-nowrap">
                                        <thead>
                                            <tr class="text-center">
                                                <th style="width:5%" class="text-center">Priority</th>
                                                <th style="width:35%" class="text-center">Courses Studied</th>
                                                <th style="width:10%" class="text-center">Studied Tamil in HSC?</th>
                                                <th style="width:35%" class="text-center">Allowed Courses</th>
                                                <th style="width: 15%;" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="conditions">
                                            <ng-container *ngFor="let condition of conditionList; let i=index;">
                                                <tr [formGroupName]="i">
                                                    <td class="text-center">{{i+1}}</td>
                                                    <td>
                                                        <kendo-multiselect [data]="course_data_list"
                                                            valueField="course_code" textField="course_name"
                                                            formControlName="studied_course_codes"
                                                            placeholder="Select Programme(s)" [valuePrimitive]="true">
                                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                                <span class="template">{{dataItem.course_code}} -
                                                                    {{dataItem.course_name}}</span>
                                                            </ng-template>
                                                        </kendo-multiselect>
                                                    </td>
                                                    <td class="text-center">
                                                        <select formControlName="tamil_in_hsc" class="form-control">
                                                            <option value="">Choose HSC Tamil Status</option>
                                                            <option value="NA">NA</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <kendo-multiselect [data]="course_data_list"
                                                            valueField="course_code" textField="course_name"
                                                            formControlName="allowed_course_codes"
                                                            placeholder="Select Programme(s)" [valuePrimitive]="true">
                                                            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                                                <span class="template">{{dataItem.course_code}} -
                                                                    {{dataItem.course_name}}</span>
                                                            </ng-template>
                                                        </kendo-multiselect>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" (click)="moveUp(i)" title="Move Up"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faArrowUp"></fa-icon>
                                                        </button>
                                                        <button type="button" (click)="moveDown(i)" title="Move Down"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faArrowDown"></fa-icon>
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-danger"
                                                            (click)="deleteCondition(i)">
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td colspan="5" class="text-center">
                                                    <button type="button" class="btn btn-secondary"
                                                        (click)="addCondition()">
                                                        Add New Condition
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <h6 *ngIf="polling_setting_form.value.part=='PART_I' ||
                                           polling_setting_form.value.part=='PART_V'">Grouping
                                    Conditions for Part I/V Course</h6>
                                <div class="table-responsive" *ngIf="polling_setting_form.value.part=='PART_I' 
                                    || polling_setting_form.value.part=='PART_V'">
                                    <table class="table table-bordered text-md-nowrap">
                                        <thead>
                                            <tr class="text-center">
                                                <th style="width:5%" class="text-center">S.No</th>
                                                <th style="width:25%" class="text-center">Rule Name</th>
                                                <th style="width:50%" class="text-center">Allowed Programmes</th>
                                                <th style="width: 20%;" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="rule_list">
                                            <ng-container *ngFor="let rule of ruleList; let i=index;">
                                                <ng-container [formGroupName]="i">
                                                    <tr>
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td class="text-center">
                                                            <input type="text" placeholder="Enter Rule Name"
                                                                class="form-control" formControlName="rule_name">
                                                        </td>
                                                        <td>
                                                            <kendo-multiselect [data]="programme_list"
                                                                valueField="programme_id"
                                                                textField="programme_display_name"
                                                                formControlName="allowed_programmes"
                                                                placeholder="Select Allowed Programme(s)"
                                                                [valuePrimitive]="true">
                                                            </kendo-multiselect>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-info mg-r-10"
                                                                (click)="viewGroupData(rule)">
                                                                View Groups
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-danger"
                                                                (click)="deleteRule(i)">
                                                                Delete Rule
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <ng-container *ngIf="rule.value.is_expanded"
                                                        formArrayName="group_data">
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table" style="width: 100%;">
                                                                    <thead>
                                                                        <th>S.No</th>
                                                                        <th>Group Name</th>
                                                                        <th>Available Courses</th>
                                                                        <th>Action</th>
                                                                    </thead>
                                                                    <ng-container
                                                                        *ngFor="let group of getGroupData(i);let j=index">
                                                                        <tr [formGroupName]="j">
                                                                            <td>{{j+1}}</td>
                                                                            <td class="text-center">
                                                                                <input type="text"
                                                                                    placeholder="Enter Group Name"
                                                                                    class="form-control"
                                                                                    formControlName="group_name">
                                                                            </td>
                                                                            <td>
                                                                                <kendo-multiselect
                                                                                    [data]="course_data_list"
                                                                                    valueField="course_code"
                                                                                    textField="course_name"
                                                                                    formControlName="available_courses"
                                                                                    placeholder="Select Course(s)"
                                                                                    [valuePrimitive]="true">
                                                                                    <ng-template
                                                                                        kendoMultiSelectItemTemplate
                                                                                        let-dataItem>
                                                                                        <span
                                                                                            class="template">{{dataItem.course_code}}
                                                                                            -
                                                                                            {{dataItem.course_name}}</span>
                                                                                    </ng-template>
                                                                                </kendo-multiselect>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button"
                                                                                    class="btn btn-danger"
                                                                                    (click)="deleteGroupData(i,j)">
                                                                                    Delete
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    <tr>
                                                                        <td colspan="4" class="text-center">
                                                                            <button type="button"
                                                                                class="btn btn-primary"
                                                                                (click)="addGroupData(i)">
                                                                                Add New Group
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <tr>
                                                <td colspan="4" class="text-center">
                                                    <button type="button" class="btn btn-secondary" (click)="addRule()">
                                                        Add New Rule
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Save</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Save &
                                        Close</button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="setting_id">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>