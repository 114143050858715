<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Transportation Request (College Bus Facility)</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>

    <div class="row">

      <!-- <ng-container *ngIf="!valid_gender"> -->
      <!-- <div class="col-lg-12 col-md-12">
        <div class="alert alert-info" role="alert">
          <button aria-label="Close" class="close" data-dismiss="alert" type="button">
          </button>
          <a>Transportation facilities available only for Female Students</a>
        </div>
      </div> -->
      <!-- </ng-container> -->

      <!-- <ng-container *ngIf="valid_gender"> -->
      <ng-container>
        <ng-container *ngIf="!transport_details">
          <div class="col-md-10 col-lg-8 col-xl-6 mx-auto d-block" *ngIf="transport_route">
            <form [formGroup]="transport_route">
              <div class="card card-body pd-20 pd-md-40 border shadow-none">
                <h5 class="card-title mg-b-20">Choose your route</h5>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select From</label>
                  <select class="form-control custom-select" id="selected_from" name="selected_from"
                    formControlName="selected_from" (change)="onFromChange($event)">
                    <option value="">Choose</option>
                    <option *ngFor="let transport_r of transport_routes;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="transport_r.from">
                      {{transport_r.from}}
                    </option>
                  </select>
                </div>
                <div class="form-group" *ngIf="amount>0">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Amount</label>
                  <div class="pos-relative">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Rs. {{amount}}</label>
                  </div>
                </div>

                <button class="btn btn-main-primary btn-block" [disabled]="amount==0"
                  (click)="applyForTransport()">Apply</button>
              </div>
            </form>
          </div>
        </ng-container>

        <ng-container *ngIf="transport_details">
          <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card card-success">
              <div class="card-header pb-0">
                <h5 class="card-title mb-0 pb-0">Transpotation Request</h5>
              </div>
              <div class="card-body">
                <b>Fee</b> : Rs. {{transport_details.amount}} <br />
                <b>Status</b> : {{transport_details.status}}
              </div>
              <div class="card-footer text-center">

                <div class="col-sm-12" *ngIf="transport_details.status=='pending'">
                  Waiting for approval
                </div>
                <div class="col-sm-12" *ngIf="transport_details.status=='allowed'">
                  <button (click)="payTransportFee()" class="btn btn-success btn-block">Pay Online</button>
                </div>
                <div class="col-sm-12" *ngIf="transport_details.status=='payment_done'">
                  Paid
                </div>
                <div class="col-sm-12" *ngIf="transport_details.status=='payment_failure'">
                  <button (click)="payTransportFee()" class="btn btn-success btn-block">Retry : Pay Online</button>
                </div>
                <div class="col-sm-12" *ngIf="transport_details.status=='rejected'">
                  Your Application Rejected
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>


  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>