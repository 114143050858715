<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal>
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" (click)="onAddClick()" class="btn btn-info"> Assign Courses to Staff</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Assign Courses to Staff</p>
                    </div>
                    <div class="card-body">
                        <h6>Search By</h6>
                        <div class="row ">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="roll_no_search" (change)="changeType($event)" id="flexRadioDefault1"
                                            checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Staff Roll No.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="course_code_search" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Course code
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="programme_search" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Department
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department
                                            Category</label>
                                        <select formControlName="department_category_id" class="form-control"
                                            (change)="onFilterSelectChange('department_category_id',$event)">
                                            <option value="">All Department Categories</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.department_category_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Department</label>
                                        <select formControlName="department_id" class="form-control"
                                            (change)="onFilterSelectChange('department_id',$event)">
                                            <option value="">All Departments</option>
                                            <option
                                                *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department.department_id">{{department.department_name}}
                                                {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2"
                                    *ngIf="filter_form.value.programme_id!='' && sections.length>1 && type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div> -->


                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2" *ngIf="type=='roll_no_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="roll_no"
                                            placeholder="Search with Staff Roll No..">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='course_code_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                        <br>
                        <!-- <div class="d-flex right-content mg-b-5" style="float: right;">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadFile('CSV')">Download CSV</a>
                                    <a class="dropdown-item" (click)="downloadFile('PDF')">Download PDF</a>
                                </div>
                            </div>
                        </div> -->

                        <div class="row" *ngIf="this.type == 'roll_no_search' && !isTableDataEmpty()">
                            <div class="col-md-2">
                                Roll No: <b>{{table_data.roll_no}}</b>
                            </div>
                            <div class="col-md-5">
                                Name: <b>{{table_data.name}}</b>
                            </div>
                            <div class="col-md-5">
                                Programme: <b>{{table_data.department_name}}</b>
                            </div>
                        </div>
                        <div class="row" *ngIf="this.type == 'course_code_search' && !isTableDataEmpty()">
                            <div class="col-md-2">
                                Course Code: <b>{{table_data.course_code}}</b>
                            </div>
                            <div class="col-md-4">
                                Course Name: <b>{{table_data.course_name}}</b>
                            </div>
                            <div class="col-md-3">
                                Offered by: <b>{{table_data.programme_name}}</b>
                            </div>
                            <div class="col-md-3">
                                Part (Course Type): <b>{{table_data.part}} ({{table_data.course_type}})</b>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;" *ngIf="this.type == 'roll_no_search'">
                                        <th>S.No</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th>Programme Name</th>
                                        <th>Section</th>
                                        <th>Virtual Section</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                    <tr *ngIf="this.type == 'course_code_search'">
                                        <th>S.No</th>
                                        <th>Staff ID</th>
                                        <th>Staff Name</th>
                                        <th>Programme Name</th>
                                        <th>Section</th>
                                        <th>Virtual Section</th>
                                        <th>Semester</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                    <tr *ngIf="this.type == 'programme_search'">
                                        <th>S.No</th>
                                        <th>Staff ID</th>
                                        <th>Staff Name</th>
                                        <th>Programme Name</th>
                                        <th>Section</th>
                                        <th>Virtual Section</th>
                                        <th>Part</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="this.type == 'roll_no_search'">
                                    <ng-container
                                        *ngFor="let group_data of table_data.group_data;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td colspan="5" class="text-center">
                                                <b>Semester: {{group_data.semester}}</b>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="let data of group_data.data; let j = index;trackBy:fromDataResolver.identify;">
                                            <td>{{j+1}}</td>
                                            <td>{{data.course_code}}</td>
                                            <td>{{data.course_name}}</td>
                                            <td>{{data.programme_name}}</td>
                                            <td>{{data.section}}</td>
                                            <td>{{data.virtual_section}}</td>
                                            <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                    <button type="button" (click)="onEditByRollNo(data)"
                                                        class="btn btn-sm btn-info mr-2">
                                                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                        <tr>
                                            <td colspan="7" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                        <tr>
                                            <td colspan="7" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                                <tbody *ngIf="this.type == 'course_code_search'">
                                    <tr
                                        *ngFor="let data of table_data.staff_data; let i = index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.roll_no}}</td>
                                        <td>{{data.given_name | uppercase}} {{data.middle_name | uppercase}}</td>
                                        <td>
                                            {{getProgrammeName(data.class_taking_programme_id)}}</td>
                                        <td>{{data.class_taking_section}}</td>
                                        <td>{{data.virtual_section}}</td>
                                        <td>{{data.semester}}</td>
                                        <td>
                                            <div class="pr-1 mb-3 mb-xl-0">
                                                <button type="button" (click)="onEditByCourseCode(data, table_data)"
                                                    class="btn btn-sm btn-info mr-2">
                                                    <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                        <tr>
                                            <td colspan="8" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                        <tr>
                                            <td colspan="8" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                                <tbody *ngIf="this.type == 'programme_search'">
                                    <ng-container *ngIf="!isTableDataEmpty()">
                                        <ng-container
                                            *ngFor="let staff_data of table_data;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td colspan="7" class="text-center">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            Staff Id: <b>{{staff_data.roll_no}}</b>
                                                        </div>
                                                        <div class="col-md-6">
                                                            Name: <b>{{staff_data.given_name | uppercase}}
                                                                {{staff_data.middle_name | uppercase}}</b>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let data of staff_data.data; let j = index;trackBy:fromDataResolver.identify;">
                                                <td>{{j+1}}</td>
                                                <td>{{data.course_code}}</td>
                                                <td>{{data.course_name}}</td>
                                                <td>{{data.programme_name}}</td>
                                                <td>{{data.section}}</td>
                                                <td>{{data.virtual_section}}</td>
                                                <td>{{data.part}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button"
                                                            (click)="onEditByProgramme(staff_data._id, staff_data.roll_no, data)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="( isTableDataEmpty() || table_data.length==0) && table_data_loading">
                                        <tr>
                                            <td colspan="9" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="( isTableDataEmpty() || table_data.length==0) && !table_data_loading">
                                        <tr>
                                            <td colspan="9" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>



                    </div>

                </div>
            </div>

        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup" *ngIf="staffassignForm">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <!--<h6 class="modal-title">Staff-Course Assignment Form</h6>-->
                        <h6 class="modal-title" *ngIf="!this.staffassignForm.value.id">Staff-Course Assignment
                        </h6>
                        <h6 class="modal-title" *ngIf="this.staffassignForm.value.id">Edit Staff-Course
                            Assignment</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="staffassignForm" class="form-horizontal">
                                <!-- <div class="form-group" *ngIf="!this.staffassignForm.value._id">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                        Type</label>
                                    <select formControlName="programme_type_id" class="form-control">
                                        <option value="">Select Programme Type</option>
                                        <option
                                            *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                    </select>
                                </div> -->

                                <div class="form-group" *ngIf="!this.staffassignForm.value._id">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                        Year</label>
                                    <select formControlName="enrolled_year" class="form-control">
                                        <option value="">Choose Enrollment Year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>

                                    </select>
                                    <div *ngIf="isInvalid('enrolled_year')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Enrolment Year is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="!this.staffassignForm.value._id">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Semester</label>
                                    <select formControlName="semester" class="form-control">
                                        <option value="">Select Semester</option>
                                        <option
                                            *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="semester.id">{{semester.text}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('semester')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Semester is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="!this.staffassignForm.value._id">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Staff Roll
                                        No.</label>
                                    <input type="text" class="form-control" placeholder="Enter Staff Roll No."
                                        formControlName="roll_no">
                                    <div *ngIf="isInvalid('roll_no')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Staff Roll No. is required</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="!this.staffassignForm.value._id">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                        Code</label>
                                    <input type="text" formControlName="course_code" class="form-control"
                                        placeholder="Enter Course Code" (change)="getCourseDetails($event)">
                                    <div *ngIf="isInvalid('course_code')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Course Code is required.</li>
                                        </ul>
                                    </div>
                                </div>
                                <ng-container *ngIf="!course_data && course_details_loading">
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <b>Loading...</b>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="course_data">
                                    <div class="row">
                                        <div class="col-md-5">
                                            Course Name: <b>{{course_data.course_name}}</b>
                                        </div>
                                        <div class="col-md-2">
                                            Part: <b>{{course_data.part}}</b>
                                        </div>
                                        <div class="col-md-5">
                                            Course Type: <b>{{course_data.course_type}}</b>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="!has_virtual_sections">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="class_taking_programme_id" class="form-control"
                                            (change)="onMapProgrammeSelectChange($event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of map_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>

                                    <div class="form-group" *ngIf="!has_virtual_sections">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="class_taking_section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of map_sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>


                                    <div class="form-group" *ngIf="has_virtual_sections">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Virtual Section</label>
                                        <select formControlName="virtual_section" class="form-control">
                                            <option value="">Select Virtual Section</option>
                                            <option
                                                *ngFor="let section of virtual_sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="section.section_id">{{section.section_name}}</option>
                                        </select>
                                        <div *ngIf="isInvalid('virtual_section')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required"> Virtual Section is required.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="form-group">
                                    <input type="checkbox" formControlName="is_primary" class="mg-r-5">Is Primary?
                                    <div *ngIf="isInvalid('is_primary')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Choose if the staff is primary incharge for
                                                the
                                                course (in case of multiple staff handling the course).</li>
                                        </ul>
                                    </div>
                                </div>



                                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                        Staff(s)</label>
                                    <kendo-multiselect #staff id="staff_name" [data]="staffData"
                                        [valueField]="'staff_id'" [textField]="'staff_name'" [filterable]="true"
                                        (filterChange)="handleStaffFilter($event)" [placeholder]="'Enter Staff Name/ID'"
                                        (valueChange)="staffListChanged($event)" [valuePrimitive]="true"
                                        formControlName="staff_ids">
                                    </kendo-multiselect>
                                    <div *ngIf="isInvalid('staff_ids')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">At least 1 Staff Name is required.</li>
                                        </ul>
                                    </div>
                                </div> -->

                                <!-- <div class="table-responsive mg-b-15">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose Primary
                                        Staff</label>
                                    <table class="table table-hover table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th style="width: 50px;">S.No.</th>
                                                <th>Staff ID</th>
                                                <th>Staff Name</th>
                                                <th>Is Primary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let staff of staffassignForm.value.staff_ids;let in=index;trackBy:fromDataResolver.identify;">
                                                <td>
                                                    {{in+1}}
                                                </td>
                                                <td>
                                                    {{staff}}
                                                </td>
                                                <td>
                                                    {{getStaffName(staff)}}
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="radio" formControlName="primary_staff_id"
                                                            [value]="staff" class="mg-r-5">Is Primary Staff?
                                                    </div>
                                                </td>
                                            </tr>
                                            <ng-container *ngIf="staffassignForm.value.staff_ids?.length==0">
                                                <tr>
                                                    <td colspan="4" class="text-center">
                                                        No Staffs Selected
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>

                                <div *ngIf="isInvalid('primary_staff_id') && staffassignForm.value.staff_ids.length>1 ">
                                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                                        <li class="parsley-required">Please mark primary staff for this course</li>
                                    </ul>
                                </div> -->



                                <div class="add-btn-row" style="text-align: center;">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">
                                        <ng-container *ngIf="!this.staffassignForm.value._id">
                                            Add
                                        </ng-container>
                                        <ng-container *ngIf="this.staffassignForm.value._id">
                                            Update
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">
                                        <ng-container *ngIf="!this.staffassignForm.value._id && !assign_save_loading">
                                            Add & Close
                                        </ng-container>
                                        <ng-container *ngIf="!this.staffassignForm.value._id && assign_save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="this.staffassignForm.value._id && !assign_save_loading">
                                            Update & Close
                                        </ng-container>
                                        <ng-container *ngIf="this.staffassignForm.value._id && assign_save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <ng-container *ngIf="this.staffassignForm.value._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                            *ngIf="!map_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled
                                            *ngIf="map_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </ng-container>


                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>