<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Time Settings</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Template</button>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Time Template Setttings</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Time Template Settings</p>
          </div>

          <!-- <div class="card-body">
            <form [formGroup]="time_setting_filter_form">
              <div class="row row-sm">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Type</label>
                    <select formControlName="department_name" class="form-control">
                      <option value="" disabled selected>Select Department Type</option>
                      <option *ngFor="let programme of department_list; let i=index; trackBy:fromDataResolver.identify;"
                        [ngValue]="programme.programme_name">{{programme.programme_name}}
                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment Year</label>
                    <select formControlName="academic_year" class="form-control">
                      <option value="" disabled selected>Select Enrollment Year</option>
                      <option *ngFor="let range of getYearRange(); let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="range">{{range}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Semester</label>
                    <select formControlName="semester_type" class="form-control">
                      <option value="" disabled selected>Select Semester</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Section
                    </label>
                    <select formControlName="section" class="form-control" required="">
                      <option value="" disabled selected hidden>All Section</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row row-sm">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Date</label>
                    <div class="example-wrapper">
                      <kendo-datepicker formControlName="start_date" [format]="format" placeholder="Select Date">
                      </kendo-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div> -->

          <div class="card-body">
            <div class="table-responsive" *ngIf="template_list.length">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th style="width: 10%;">S.No</th>
                    <th>Template Name</th>
                    <th style="width: 15%;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of template_list;let i=index; trackBy:fromDataResolver.identify;">
                    <td>{{i+1}}</td>
                    <td>{{data.template_name | titlecase}}</td>
                    <td>
                      <span style="color: red;" *ngIf="data.status=='FINALIZED'" class="mg-r-10">FINALIZED</span>
                      <button class="btn btn-sm btn-info mr-2" (click)="editSlot(data, true, i)" type="button"
                        *ngIf="data.status!='FINALIZED'">
                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                      </button>
                      <button class="btn btn-sm btn-info mr-2" (click)="finalizeTemplate(data)" type="button"
                        *ngIf="data.status!='FINALIZED'">
                        <fa-icon [icon]="fonts.faLock"></fa-icon>
                      </button>
                      <button class="btn btn-sm btn-info mr-2" (click)="viewTemplate(data)" type="button"
                        *ngIf="data.status=='FINALIZED'">
                        <fa-icon [icon]="fonts.faInfo"></fa-icon>
                      </button>
                      <button class="btn btn-sm btn-danger mr-2" (click)="deleteTemplateById(data._id)" type="button"
                        *ngIf="data.status=='FINALIZED'">
                        <fa-icon [icon]="fonts.faTrash"></fa-icon>
                      </button>
                    </td>
                  </tr>
                  <ng-container *ngIf="!template_list_loading && template_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="template_list_loading && template_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>


    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog big-modal" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">Time Settings</h6>

          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="card mg-b-20">
                  <div class="card-body pt-0" *ngIf="popup_mode == 'edit'">
                    <form [formGroup]="timeSettingForm" novalidate>
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Template Name</label>
                          <input type="text" placeholder="Enter Template Name" class="form-control"
                            formControlName="template_name">
                        </div>
                      </div>
                    </form>
                    <div class="row">
                      <div class="col-sm-3">
                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Day</label>
                        <select placeholder="Select Day" [(ngModel)]="selectedDay" class="form-control"
                          (change)="dayChange()">

                          <option value="">Select Day</option>
                          <option *ngFor="let day of day; let i=index; trackBy:fromDataResolver.identify;"
                            [ngValue]="day">{{day}}</option>
                        </select>
                      </div>
                      <div class="col-sm-9">
                        <form [formGroup]="timeSettingForm" novalidate>
                          <div formArrayName="days">
                            <div *ngFor="let data of days().controls; let i = index; trackBy:fromDataResolver.identify;"
                              [formGroupName]="i">
                              <div class="row">
                                <div class="col-sm-4" *ngIf="data.value.day === selectedDay">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                    Hour</label>
                                  <div class="example-wrapper">
                                    <kendo-timepicker formControlName="start_hour" required="" placeholder="Start Hour"
                                      (change)="slotChange(i)"></kendo-timepicker>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </form>
                      </div>
                    </div>


                    <div class="col-sm-12 ml-0 pl-0 mt-3">
                      <form [formGroup]="timeSettingForm" novalidate>
                        <div formArrayName="days">
                          <div *ngFor="let data of days().controls; let i = index; trackBy:fromDataResolver.identify;"
                            [formGroupName]="i">
                            <div class="" *ngIf="data.value.day === selectedDay" style="display: block; width: 100%;">
                              <table class="table table-bordered mg-b-0 text-md-nowrap"
                                *ngIf="slotArrayControl(i).length">
                                <thead>
                                  <tr>
                                    <th>Hour</th>
                                    <th>Duration(In Mins)</th>
                                    <th>Hour Duration</th>
                                    <th>Hour Type</th>
                                    <th>Action</th>

                                  </tr>
                                </thead>
                                <tbody formArrayName="slot">
                                  <tr
                                    *ngFor="let item of slotArrayControl(i); let j = index; trackBy:fromDataResolver.identify;"
                                    [formGroupName]="j">
                                    <td>
                                      {{j+1}}
                                    </td>
                                    <td>
                                      <input formControlName="duration" required="" class="form-control"
                                        placeholder="Duration in Mins" type="text" (change)="slotChange(i)">
                                    </td>
                                    <td>
                                      <fa-icon class="text-primary" [icon]="fonts.faClock"
                                        *ngIf="item.value.startTime || item.value.endTime"></fa-icon>
                                      <label class="ml-2" *ngIf="item.value.startTime || item.value.endTime">
                                        {{item.value.startTime}} - {{item.value.endTime}}
                                      </label>
                                    </td>
                                    <td>
                                      <div class="form-check">
                                        <input class="form-check-input" (change)="slotChange(i)"
                                          formControlName="isBreak" type="checkbox" id="isBreakSlot{{j}}">
                                        <label class="form-check-label" for="isBreakSlot{{j}}">
                                          isBreak
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <button class="btn btn-sm btn-danger mr-2" (click)="removeSlot(i,j)"
                                        type="button">
                                        <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!-- <div class="mt-2" *ngFor="let item of slotArrayControl(i); let j = index;" [formGroupName]="j">
                             <div class="row">
                               <div class="col-sm-3">
                                 <input formControlName="duration" required=""  class="form-control" placeholder="Duration in Mins"
                                 type="text" (change)="slotChange(i)"  >
                               </div>
                               <div class="col-sm-3 pl-3 pt-2">
                                 <fa-icon [icon]="fonts.faCalendar"></fa-icon>                                        
                                 <label class="ml-2">                                                                                    
                                     {{item.value.startTime}} - {{item.value.endTime}} 
                                 </label>
                               </div>
                               <div class="col-sm-3 pl-3 pt-2">
                                 <div class="form-check">
                                   <input class="form-check-input"  (change)="slotChange(i)" formControlName="isBreak" type="checkbox"  id="isBreakSlot">
                                   <label class="form-check-label" for="isBreakSlot">
                                     isBreak
                                   </label>
                                 </div>
                               </div>
                               <div class="col-sm-3">                                   
                                 <button class="btn btn-sm btn-warning mg-r-20" (click)="removeSlot(i,j)"  type="button" >Remove Slot</button>
                               </div>
                             </div>
                            </div> -->
                            </div>

                          </div>
                        </div>
                      </form>
                    </div>

                    <div class="col-sm-12">
                      <form [formGroup]="timeSettingForm" novalidate>
                        <div formArrayName="days">
                          <div *ngFor="let data of days().controls; let i = index; trackBy:fromDataResolver.identify;"
                            [formGroupName]="i">
                            <div class="text-center" *ngIf="data.value.day === selectedDay">
                              <button class="btn btn-md btn-info mt-3" type="button" (click)="AddSlot(i)">Add
                                Hour</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div class="table-responsive mt-2">
                      <label class="main-content-label tx-black-600">Time table</label>
                      <form [formGroup]="timeSettingForm">
                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Start Hour</th>
                              <ng-container
                                *ngFor="let arr of countArr;let headerIndex = index;trackBy:fromDataResolver.identify;">
                                <th>
                                  {{headerIndex +1}}
                                </th>
                              </ng-container>

                            </tr>
                          </thead>
                          <tbody formArrayName="days">
                            <tr *ngFor="let item of days().controls; let i = index; trackBy:fromDataResolver.identify;"
                              [formGroupName]="i">
                              <td>
                                {{item.value.day}}
                              </td>
                              <td>
                                {{item.value.start_hour | date:'shortTime'}}
                              </td>
                              <ng-container formArrayName="slot" *ngIf="item.value.slot.length">
                                <td
                                  *ngFor="let data of aliasesArrayControl(i); let j = index; trackBy:fromDataResolver.identify;"
                                  [formGroupName]="j">

                                  <label
                                    class="main-content-label tx-11 mb-0 mt-0 tx-small tx-black-600">{{data.value.startTime}}
                                    - {{data.value.endTime}} ({{data.value.duration}} mins) </label>
                                  <br *ngIf="data.value.isBreak" />
                                  <span class="text-primary" *ngIf="data.value.isBreak">Break</span>
                                </td>

                              </ng-container>
                            </tr>

                          </tbody>
                        </table>
                      </form>

                    </div>


                    <div class="add-btn-row mt-2 text-center">
                      <button class="btn btn-md btn-success mg-r-20" (click)="save()" *ngIf="!save_loading">
                        <ng-container *ngIf="!isEdit">
                          Save
                        </ng-container>
                        <ng-container *ngIf="isEdit">
                          Update
                        </ng-container>
                      </button>
                      <button class="btn btn-md btn-primary mg-r-20" (click)="saveAndClose()" *ngIf="!save_loading">
                        <ng-container *ngIf="!isEdit">
                          Save & Close
                        </ng-container>
                        <ng-container *ngIf="isEdit">
                          Update & Close
                        </ng-container>
                      </button>
                      <button class="btn btn-info mg-r-20" disabled *ngIf="save_loading">
                        <ng-container *ngIf="!isEdit">
                          Saving...
                        </ng-container>
                        <ng-container *ngIf="isEdit">
                          Updating...
                        </ng-container>
                      </button>
                      <button class="btn btn-md btn-danger mg-r-20" *ngIf="isEdit && !delete_loading"
                        (click)="deleteTemplate()">Delete</button>
                      <button class="btn btn-danger mg-r-20" disabled
                        *ngIf="isEdit && delete_loading">Deleting...</button>
                      <button class="btn btn-md btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                    </div>

                  </div>

                  <div class="card-body pt-0" *ngIf="popup_mode == 'view'">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">Day</th>
                            <th *ngFor="let head of hour_header | slice:0:max_hour" class="text-center">
                              {{head.hour_name}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let day of view_time_table; let i = index;">
                            <td class="text-center">{{day.day}}</td>
                            <td *ngFor="let hour of day.hours; let j = index" class="text-center">
                              <span>{{hour.start_time}} - {{hour.end_time}}</span>
                              <br>
                              <span *ngIf="hour.is_break" class="text-center">
                                <b>Break</b>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="add-btn-row mt-2 text-center">
                      <button class="btn btn-md btn-secondary mg-r-20" (click)="closeViewTemplate()">Close</button>
                    </div>

                  </div>


                </div>
              </div>
            </div>


          </div>



        </div>
      </div>
    </div>

    <!--End Large Modal -->

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>