import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { StockItems } from 'src/app/controllers/admins/hostel-management/manage-mess/stock-item';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
@Component({
  selector: 'app-stock-usage',
  templateUrl: './stock-usage.component.html',
  styleUrls: ['./stock-usage.component.scss']
})
export class StockUsageComponent implements OnInit {

  menuItems: StockItems = {} as StockItems;
  measurementType = ['KG', 'LTR', "GRM", "MTR", "CTR"];
  //get Item Master from Service
  itemMaster: Array<{ text: string; value: string }> = [
    { text: "Rice", value: "1" },
    { text: "Wheat", value: "2" },
    { text: "Coffee Powder", value: "3" },
    { text: "Tea Powder", value: "4" },
    { text: "Tomato", value: "5" },
    { text: "Potato", value: "6" },
    { text: "Carrot", value: "7" }
  ];
  inStock:{
    purchase_date:Date,
    item_id:string,
    item_name:string,
    measurement_type:string,
    price:number,
    quantity:number,
    total_price:number,
    item_category:string
  } [ ] = [
    {
      purchase_date:new Date("08/01/2021"), item_id:"1", item_name:"Rice", measurement_type:"KG",
      item_category:"Grains",
      price:350, quantity:20, total_price: 7000
    },
    {
      purchase_date:new Date("08/01/2021"), item_id:"2", item_name:"Wheat", measurement_type:"KG",
      price:250, quantity:20, total_price: 5000,
      item_category:"Grains",
    },
    {
      purchase_date:new Date("08/02/2021"), item_id:"3", item_name:"Coffee Powder", measurement_type:"GRM",
      price:70, quantity:50, total_price: 3500,
      item_category:"Beverages",
    }
   ];
   stock_list_loading:boolean = false;
   usedStock:{
    usage_date:Date,
    item_id:string,
    transaction_id:number,
    item_name:string,
    quantity:number,
    usage_price:number
  } [ ] = [
    {
      usage_date:new Date("08/05/2021"), item_id:"1", item_name:"Rice",transaction_id:1,
      quantity:5, usage_price: 1750
    },
    {
      usage_date:new Date("08/05/2021"), item_id:"2", item_name:"Wheat",quantity:5, usage_price: 1250, transaction_id:2
    }
   ];
   fonts = fonts;
  usageForm: FormGroup;
  title = 'Stock Usage';
  id = '';
  fromDataResolver = FromDataResolver;

  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.usageForm = this.formBuilder.group({
        usage_date: [new Date().toISOString().substring(0,10), Validators.required],
        item_id:[''],
        transaction_id: [''],
        item_name: ['', [Validators.required]],
        //measurement_type: [''],
        quantity: [0,Validators.required],
        //price: [0, Validators.required],
        usage_price:[0],
        available_quantity:[0]
      });
    }
  
    ngOnInit(): void {
      this.restService.initializeServices();
      this.notificationService.setNotifcation({});
      this.navigationService.homeURL = { label: 'Stock Usage', url: 'admin/hostel/stock-usage' };
      this.route.params.subscribe(params => {
        this.id = params?.id;
        this.navigationService.updateNavigation(this.title, this.router.url);
      });
    }
    onAddClick()
  {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  async onCloseClick(): Promise<void> {
    this.reset();
    this.id="";
    JQueryHelper.closeModal('#modal-popup');
  }
    getMeasurementType(item_id:string):string|undefined
    {
      return this.inStock.find(x=>x.item_id==item_id)?.measurement_type;
    }

    getUsageTotal():void
    {
      console.log(this.inStock.find(x=>x.item_name==
        this.usageForm.value.item_name)!.price);
      if(this.usageForm.value.quantity<= this.inStock.find(x=>x.item_name==
        this.usageForm.value.item_name)!.quantity)
        {
          this.usageForm.controls['usage_price'].setValue( this.inStock.find(x=>x.item_name==
            this.usageForm.value.item_name)!.price* this.usageForm.value.quantity);
        }
        else
        {
          alert("Usage Quantity must be less than available quantity");
          this.usageForm.controls['quantity'].setValue(0);
          this.usageForm.controls['usage_price'].setValue(0);
        }
      
    }

    getAvailability(){
      this.usageForm.controls['available_quantity'].setValue(this.inStock.find(x=>x.item_name==this.usageForm.value.item_name)!.quantity);
    }
  
  

  getTittle(): string {
    return this.title;
  }
  

  isInvalid(formControl: string): boolean {
    if ((formControl === 'quantity' || formControl === 'price') && isNaN(this.usageForm.get(formControl)?.value)) {
      return true;
    }
    return this.usageForm.touched && this.usageForm.get(formControl)?.errors !== null;
  }

  getMenuItemFromForm(): StockItems {
    const menuItem = this.getFormData();
    return {
      id: this.id,
      item_name: menuItem.item_name,
      item_type: menuItem.item_type,
      quantity: menuItem.quantity,
      price: menuItem.price,
      item_category: menuItem.item_category
    };
  }

  canDisable(): boolean {
    if (isNaN(this.usageForm.value?.quantity)) {
      return true;
    }
    return this.usageForm.status !== 'VALID';
  }

  getFormData(): any {
    return this.usageForm.value;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
     this.addItem();
  }

  onSaveAndClose():void{
    this.onSave();
    this.onCloseClick();
  }
  reset(): void {
    this.usageForm.reset();
    this.usageForm.controls['usage_date'].setValue(new Date().toISOString().substring(0,10));
    this.usageForm.controls['quantity'].setValue(0);
    this.usageForm.controls['usage_price'].setValue(0);
    this.usageForm.controls['available_quantity'].setValue(0);
    //this.usageForm.controls['price'].setValue(0);
    //this.usageForm.controls['measurement_type'].setValue("");    
  }
  async onEditClick(item: any): Promise<void> {
    this.id = item.transaction_id;
    item.available_quantity = this.inStock.find(x=>x.item_id==item.item_id)?.quantity;
    this.usageForm.setValue( item);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  async addItem(): Promise<void> {
    try {
      this.usageForm.value.item_id = this.itemMaster.find(x=>x.text==this.usageForm.value.item_name)?.value;
      this.usageForm.value.available_quantity = this.inStock.find(x=>x.item_id==this.usageForm.value.item_id)!.quantity;
      if(this.usageForm.value.transaction_id)
      {
        var item = this.usedStock.find(x=>x.transaction_id == this.usageForm.value.transaction_id);
        item!.quantity = parseInt(this.usageForm.value.quantity);
        item!.usage_price = parseInt(this.usageForm.value.usage_price);
        item!.usage_date = this.usageForm.value.usage_date;
      }
      /* if(this.usedStock.findIndex(x=>x.item_id==this.usageForm.value.item_id)>=0)
      {
        var item: any;
        item = this.usedStock.find(x=>x.item_id==this.usageForm.value.item_id);
        item.quantity += parseInt(this.usageForm.value.quantity);
        item.usage_price += parseInt(this.usageForm.value.usage_price);
        item.usage_date = this.usageForm.value.usage_date;
        this.usedStock.splice(this.usedStock.findIndex(x=>x.item_id==this.usageForm.value.item_id),1);
        this.usedStock.unshift(item);
      } */
      else
      {
        this.inStock.find(x=>x.item_id==this.usageForm.value.item_id)!.quantity -= this.usageForm.value.quantity;
        this.usageForm.value.transaction_id = Math.random();
        this.usedStock.unshift(this.usageForm.value)
      }
      this.reset();        
      /* 
      const res = await this.restService.menuItemService.creatMenuItems(this.getMenuItemFromForm());
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
      }
      this.notificationService.setNotifcation({ message: 'Created menu item successfully', type: 'Success'});
     */
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to add item to stock.', type: 'Failed'});
    }
  }
  onDeleteAndClose()
  {
    this.usedStock.splice(this.usedStock.findIndex(x=>x.transaction_id==this.usageForm.value.transaction_id),1)
    this.reset();
    this.usageForm.reset();
    this.onCloseClick();
  }
}
