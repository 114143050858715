import { formatDate } from '@angular/common';
export class DateHelper {
    static convertToControlDate(date: Date): string {
        if (date) {
            return formatDate(date, 'yyyy-MM-dd', 'en');
        } else {
            return '';
        }
    }
    static convertToControlDateTime(date: Date): string {
        if (date) {
            return formatDate(date, 'yyyy-MM-ddTHH:mm', 'en');
        } else {
            return '';
        }
    }

    static toDisplayDate(date: Date): string {
        if (date) {
            return formatDate(date, 'dd/MM/yyyy', 'en');
        } else {
            return '';
        }
    }

    static getStartDate(date: Date, error_message: string = ''): Date | null {
        const parsed_date = this.validateAndParseDate(date, error_message);
        if (parsed_date) {
            parsed_date.setHours(0, 0, 0, 0);
            return parsed_date;
        } else {
            return null;
        }
    }
    static getEndDate(date: Date, error_message: string = ''): Date | null {
        const parsed_date = this.validateAndParseDate(date, error_message);
        if (parsed_date) {
            parsed_date.setHours(23, 59, 59, 999);
            return parsed_date;
        } else {
            return null;
        }
    }

    static isValidDate(date: any): Date | null {
        try {
            return new Date(date);
        } catch (error) {
            return null;
        }
    }

    static validateAndParseDate(date: any, error_message: string | null = null): Date | null {
        const valid_date = this.isValidDate(date);
        if (valid_date) {
            return valid_date;
        } else {
            if (error_message) {
                alert(error_message);
            }
            return null;
        }

    }
}
