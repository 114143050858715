import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-applied-list',
  templateUrl: './applied-list.component.html',
  styleUrls: ['./applied-list.component.scss']
})
export class AppliedListComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;
  cookieStore = CookieStore;

  // Master data
  subject_list: any[] = [];

  status = '';

  admission_id = '';
  regulation_year = new Date().getFullYear();
  finance_type = '';
  programme_id = '';
  programme_name = '';

  // Core data
  applicant_list: any[] = [];
  applicant_list_loading = false;

  // Applicant
  user_info: any;
  user_form: any;

  applicant_id = '';
  application_no = '';
  applicant_list_copy: any[] = [];
  parental_status = 'parent';

  selection_list: any[] = [];

  admission_search_form: any;

  // change status
  status_change_loading = false;

  // email generation
  email_generation_loading = false;

  // rollno generation
  rollno_generation_loading = false;

  approve_all_loading: boolean = false;

  programme_list: any[] = [];
  programme_list_loading: boolean = false;


  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.params.subscribe(data => {
      this.status = data.status;
    });

    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
      this.regulation_year = Number(data.regulation_year);
      this.finance_type = data.finance_type;
      this.programme_id = data.programme_id;
      this.programme_name = data.programme_name;
    });
  }

  ngOnInit(): void {
    this.getProgrammeDataList();
    const start_date = DateHelper.getStartDate(new Date());
    if (!start_date) {
      return;
    }
    const end_date = DateHelper.getEndDate(new Date());
    if (!end_date) {
      return;
    }
    this.admission_search_form = this.fb.group({
      gender: [''],
      entrance_option: [''],
      community: [''],
      subject_type: [''],
      total_filter: [0],
      apply_date_filter: [false],
      skip_status: [false],
      start_date: [DateHelper.convertToControlDateTime(start_date)],
      end_date: [DateHelper.convertToControlDateTime(end_date)],
    });

    this.getApplicantsWithGivenStatus();
    this.getSchoolSubjects();
  }


  async getApplicantsWithGivenStatus(): Promise<void> {
    try {
      this.applicant_list = [];
      this.applicant_list_loading = true;
      const search_form_query: any = {
        programme_id: this.programme_id,
        admission_id: this.admission_id,
        finance_type: this.finance_type,
        status: this.status
      };

      if (this.admission_search_form) {
        if (this.admission_search_form.value.apply_date_filter) {
          search_form_query.start_date = new Date(this.admission_search_form.value.start_date);
          search_form_query.end_date = new Date(this.admission_search_form.value.end_date);
        }
        if (this.admission_search_form.value.gender) {
          search_form_query.gender = this.admission_search_form.value.gender;
        }
        if (this.status === 'verified') {
          search_form_query.entrance_option = this.admission_search_form.value.entrance_option;
        }


        if (this.admission_search_form.value.subject_type) {
          if (this.admission_search_form.value.subject_type === 'computer_science') {
            search_form_query.is_computer_science = true;
          } else if (this.admission_search_form.value.subject_type === 'non_computer_science') {
            search_form_query.is_computer_science = false;
          }
        }

        if (this.admission_search_form.value.skip_status) {
          search_form_query.skip_status = true;
        }
      }

      const service_response = await this.restService.getApplicantsWithGivenStatus(search_form_query);
      if (service_response && service_response.success) {
        this.applicant_list = service_response.data;
        this.applicant_list_copy = this.applicant_list;
      }

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.applicant_list_loading = false;
    }
  }

  async onStartVerifyClick(applicant: any): Promise<void> {
    if (this.status === 'in_applied' || this.status == 'verification_list') {
      this.applicant_id = applicant.applicant_id;
      this.application_no = applicant.application_no;
      const user_info_loaded = await this.getUserInfo();
      if (user_info_loaded) {
        JQueryHelper.openModal('#modal-applicant-details', { keyboard: false, backdrop: 'static' });
      }
    }
  }


  async onApplicationFeeFormCloseClick(): Promise<void> {
    this.applicant_id = '';
    this.application_no = '';
    JQueryHelper.closeModal('#modal-applicant-details');
  }



  //#endregion applicant

  async getSchoolSubjects(): Promise<void> {
    try {
      const response = await this.restService.getSchoolSubjects();
      if (response.success && response.data) {
        this.subject_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  getSchoolSubjectsByPartType(part_type: number): any[] {
    return this.subject_list.filter(c => c.part_type === part_type);
  }

  async getUserInfo(): Promise<boolean> {
    try {
      const response = await this.restService.getApplicantInfo(this.applicant_id);
      if (response.success && response.data) {
        this.user_info = response.data;
        this.buildUserForm();
      }
      return Promise.resolve(true);
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      return Promise.resolve(false);
    }
  }

  buildUserForm(): void {
    try {
      if (!this.user_info.address) {
        this.user_info.address = {};
      }
      if (!this.user_info.academics) {
        this.user_info.academics = [];
      }
      /* let sslc_academics = this.user_info.sslc_academics;
      if (!sslc_academics) {
        sslc_academics = {};
      }
      if (!sslc_academics.accademic_info) {
        sslc_academics.accademic_info = {};
      } */

      let hsc_academics = this.user_info.hsc_academics;
      if (!hsc_academics) {
        hsc_academics = {};
      }
      if (!hsc_academics.accademic_info) {
        hsc_academics.accademic_info = {};
      }

      let degree_academics = this.user_info.degree_academics;
      if (!degree_academics) {
        degree_academics = {};
      }
      if (!degree_academics.accademic_info) {
        degree_academics.accademic_info = {};
      }




      this.user_form = this.fb.group({
        _id: [this.user_info._id],
        aadhar_card_no: [this.user_info.aadhar_card_no],
        aadhar_card_no_file_front: [this.user_info.aadhar_card_no_file_front],
        aadhar_card_no_file_back: [this.user_info.aadhar_card_no_file_back],
        mobile_number: [this.user_info.mobile_number],
        mobile_number_2: [this.user_info.mobile_number_2],
        mobile_number_3: [this.user_info.mobile_number_3],
        mobile_number_4: [this.user_info.mobile_number_4],
        mobile_number_5: [this.user_info.mobile_number_5],
        email: [this.user_info.email],
        middle_name: [this.user_info.middle_name],
        given_name: [this.user_info.given_name],
        birth_date: [DateHelper.convertToControlDate(new Date(this.user_info.birth_date))],
        gender: [this.user_info.gender || ''],
        blood_group: [this.user_info.blood_group || ''],
        community_id: [this.user_info.community_id || ''],
        community_id_file: [this.user_info.community_id_file || ''],
        caste: [this.user_info.caste || ''],
        religion: [this.user_info.religion || ''],
        physically_challanged: [this.user_info.physically_challanged || false],
        ex_service: [this.user_info.ex_service || false],
        ex_service_rank: [this.user_info.ex_service_rank || ''],
        ex_service_i_card_no: [this.user_info.ex_service_i_card_no || ''],
        sports_certs: [this.user_info.sports_certs || false],
        need_hostel: [this.user_info.need_hostel || false],
        need_bus: [this.user_info.need_bus || false],
        is_applicant_married: [this.user_info.is_applicant_married || false],
        nationality: [this.user_info.nationality || 'indian'],
        address: this.fb.group({
          country: [this.user_info.address.country || 'India'],
          state: [this.user_info.address.state || 'Tamil Nadu'],
          district: [this.user_info.address.district],
          city: [this.user_info.address.city],
          pincode: [this.user_info.address.pincode],
          street: [this.user_info.address.street],
        }),
        identification_marks: this.fb.array(this.prepareIdentificationMasrs(this.user_info.identification_marks)),
        parental_status: [this.parental_status],
        family_members: this.fb.array(this.prepareFamilyInfo(this.user_info.family_members)),
        apply_for: [this.user_info.apply_for || ''],
        /* sslc_academics: this.fb.group({
          accedemic_type: ['SSLC'],
          accademic_info: this.fb.group({
            school_name: [sslc_academics.accademic_info.school_name || ''],
            school_place: [sslc_academics.accademic_info.school_place || ''],
            course_name: [sslc_academics.accademic_info.course_name || ''],
            exam_reg_no: [sslc_academics.accademic_info.exam_reg_no || ''],
            year_month_passout: [sslc_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [sslc_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [sslc_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [sslc_academics.accademic_info.medium_of_study || 'tamil'],
            syllabus_type: [sslc_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
            mark_statement_file: [sslc_academics.accademic_info.mark_statement_file || ''],
          }),
          marks: this.fb.array(this.prepareAccedamicMarkInfo('SSLC', 'STATE_BOARD', sslc_academics.marks))
        }), */
        hsc_academics: this.fb.group({
          accedemic_type: ['HSC'],
          accademic_info: this.fb.group({
            school_name: [hsc_academics.accademic_info.school_name || ''],
            school_place: [hsc_academics.accademic_info.school_place || ''],
            course_name: [hsc_academics.accademic_info.course_name || ''],
            exam_reg_no: [hsc_academics.accademic_info.exam_reg_no || ''],
            vocational: [hsc_academics.accademic_info.vocational || false],
            year_month_passout: [hsc_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [hsc_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [hsc_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [hsc_academics.accademic_info.medium_of_study || 'tamil'],
            first_graduate: [hsc_academics.accademic_info.first_graduate || false],
            syllabus_type: [hsc_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
            mark_statement_file: [hsc_academics.accademic_info.mark_statement_file || ''],
          }),
          marks: this.fb.array(this.prepare12thAccedamicMarkInfo('HSC',
            (hsc_academics.accademic_info.vocational || false), 'STATE_BOARD', hsc_academics.marks))
        }),
        degree_academics: this.fb.group({
          accedemic_type: ['DEGREE'],
          accademic_info: this.fb.group({
            is_anjac: [degree_academics.accademic_info.school_name === 'Ayya Nadar Janaki Ammal College' ? 'true' : 'false'],
            school_name: [degree_academics.accademic_info.school_name || ''],
            school_place: [degree_academics.accademic_info.school_place || ''],
            course_name: [degree_academics.accademic_info.course_name || ''],
            exam_reg_no: [degree_academics.accademic_info.exam_reg_no || ''],
            year_month_passout: [degree_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [degree_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [degree_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [degree_academics.accademic_info.medium_of_study || 'english'],
            first_graduate: [degree_academics.accademic_info.first_graduate || false]
          }),
          marks: this.fb.array(this.prepareDegreeAccedamicMarkInfo('DEGREE', degree_academics.marks))
        })

      });

    } catch (error) {
      console.log(error);
    }

  }

  getImage(url: string): any {
    return [{ image: url, thumbImage: url }];
  }
  private prepareDegreeAccedamicMarkInfo(acadamic_type: string, marks: any[]): any {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({
          sno: [mark.sno],
          semester: [mark.semester || (mark.sno + 1)],
          semester_file: [mark.semester_file || ''],
          no_of_subjects: [mark.no_of_subjects || 4],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      for (let index = 0; index < 6; index++) {
        value_to_return.push(this.fb.group({
          sno: [index],
          semester: [index + 1],
          semester_file: [''],
          no_of_subjects: [4],
          mark: [0],
        }));
      }
    }
    return value_to_return;
  }

  /* async onSSLCBoardChange(): Promise<void> {
    const syllabus_type = this.user_form.value.sslc_academics.accademic_info.syllabus_type;
    const values = this.prepareAccedamicMarkInfo('SSLC', syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('sslc_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  } */

  private prepareAccedamicMarkInfo(acadamic_type: string, syllabus_type: string, marks: any[]): any[] {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({

          sno: [mark.sno],
          subject_id: [mark.subject_id || ''],
          subject_name: [mark.subject_name || ''],
          subject_type: [mark.subject_type || ''],
          part_type: [mark.part_type || ''],
          part_index: [mark.part_index || ''],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      let subjects = [];
      if (syllabus_type === 'ICSE') {
        subjects = [
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 2,
            sno: 4
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 3,
            sno: 5
          }
        ];
      } else {
        subjects = [
          {
            subject_type: 'LANGUAGE',
            subject_name: 'TAMIL',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: 'LANGUAGE',
            subject_name: 'ENGLISH',
            part_type: 1,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: 'THEORY',
            subject_name: 'MATHEMATICS',
            part_type: 2,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: 'THEORY',
            subject_name: 'SCIENCE',
            part_type: 2,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: 'THEORY',
            subject_name: 'SOCIAL SCIENCE',
            part_type: 2,
            part_index: 2,
            sno: 4
          }
        ];
      }
      for (const subject of subjects) {
        value_to_return.push(this.fb.group({
          sno: [subject.sno],
          subject_id: [''],
          subject_name: [subject.subject_name],
          subject_type: [subject.subject_type],
          part_type: [subject.part_type],
          part_index: [subject.part_index],
          mark_1: [0],
          mark: [0],
        }));

      }

    }



    return value_to_return;
  }

  async onBoardChange(): Promise<void> {
    const vocational = this.user_form.value.hsc_academics.accademic_info.vocational;
    const syllabus_type = this.user_form.value.hsc_academics.accademic_info.syllabus_type;
    const values = this.prepare12thAccedamicMarkInfo('HSC', vocational, syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('hsc_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  }
  private prepare12thAccedamicMarkInfo(acadamic_type: string, vocational: boolean, syllabus_type: string, marks: any[]): any {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({

          sno: [mark.sno],
          subject_id: [mark.subject_id || ''],
          subject_name: [mark.subject_name || ''],
          subject_type: [mark.subject_type || ''],
          part_type: [mark.part_type || ''],
          part_index: [mark.part_index || ''],
          mark_1: [mark.mark_1 || 0],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      let subjects = [];
      if (syllabus_type === 'STATE_BOARD') {
        if (vocational === true) {
          subjects = [
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 1,
              part_index: 0,
              sno: 0
            },
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 2,
              part_index: 1,
              sno: 1
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 0,
              sno: 2
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 1,
              sno: 3
            },
            {
              subject_type: 'PRACTICAL',
              subject_name: '',
              part_type: 4,
              part_index: 0,
              sno: 4
            },
            {
              subject_type: 'PRACTICAL',
              subject_name: '',
              part_type: 4,
              part_index: 1,
              sno: 5
            }
          ];
        } else {
          subjects = [
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 1,
              part_index: 0,
              sno: 0
            },
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 2,
              part_index: 1,
              sno: 1
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 0,
              sno: 2
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 1,
              sno: 3
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 3,
              sno: 4
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 4,
              sno: 5
            }
          ];
        }

      } else {
        subjects = [
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 3,
            sno: 4
          }
        ];
      }

      for (const subject of subjects) {
        value_to_return.push(this.fb.group({
          sno: [subject.sno],
          subject_id: [''],
          subject_name: [subject.subject_name],
          subject_type: [subject.subject_type],
          part_type: [subject.part_type],
          part_index: [subject.part_index],
          mark_1: [0],
          mark: [0],
        }));

      }

    }



    return value_to_return;
  }





  private prepareIdentificationMasrs(id_marks: string[]): any[] {
    const prepared_id_marks = [];
    if (!id_marks) {
      id_marks = [];
    }
    if (id_marks && id_marks.length > 0) {
      for (const id_mark of id_marks) {
        prepared_id_marks.push(this.fb.control(id_mark));
      }
    }
    if (prepared_id_marks.length < 2) {
      const length = 2 - prepared_id_marks.length;
      for (let index = 0; index < length; index++) {
        prepared_id_marks.push(this.fb.control(''));
      }
    }
    return prepared_id_marks;
  }

  private prepareFamilyInfo(data: any[]): any[] {
    const family_infos = [];
    if (!data) {
      data = [];
    }

    const father = data.find(c => c.relationship_type.toLowerCase() === 'father');
    if (father) {
      family_infos.push(
        this.fb.group({
          id: [father.id],
          relationship_type: ['Father'],
          relationship_name: [''],
          given_name: [father.given_name || ''],
          occupation: [father.occupation || ''],
          salary: [father.salary || '', [Validators.min(1)]],
          // contact_no: [father.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [father.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Father'],
          relationship_name: [''],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]],

          // contact_no: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: ["", [Validators.email]]

        })
      );
    }
    const mother = data.find(c => c.relationship_type.toLowerCase() === 'mother');
    if (mother) {
      family_infos.push(
        this.fb.group({
          id: [mother.id],
          relationship_type: ['Mother'],
          relationship_name: [''],
          given_name: [mother.given_name || ''],
          occupation: [mother.occupation || ''],
          salary: [mother.salary || '', [Validators.min(1)]],

          // contact_no: [mother.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [mother.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Mother'],
          relationship_name: [''],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]],

          // contact_no: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: ["", [Validators.email]]

        })
      );
    }

    const guardian = data.find(c => c.relationship_type.toLowerCase() === 'guardian');
    if (guardian) {
      family_infos.push(
        this.fb.group({
          id: [guardian.id],
          relationship_type: [guardian.relationship_type],
          relationship_name: [guardian.relationship_name],
          given_name: [guardian.given_name || ''],
          occupation: [guardian.occupation || ''],
          salary: [guardian.salary || '', [Validators.min(1)]],

          // contact_no: [guardian.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [guardian.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Guardian'],
          relationship_name: ['Uncle'],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]]

        })
      );
    }


    return family_infos;


  }

  private validateMobile(mobile: string): boolean {
    if (!mobile) {
      return false;
    }
    if (mobile.trim().length === 0) {
      return false;
    }
    if (mobile.trim().length !== 10) {
      return false;
    }
    try {
      parseFloat(mobile);
    } catch (error) {
      return false;
    }

    return true;
  }

  private validateString(val: string): boolean {
    if (!val) {
      return false;
    }
    if (val.trim().length === 0) {
      return false;
    }
    return true;
  }

  private validateDate(val: string): boolean {
    if (!val) {
      return false;
    }
    try {
      const datae = new Date(val);
    } catch (error) {
      return false;
    }
    return true;
  }
  private validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async onProfileSaveClick(from_external: boolean = false): Promise<void> {
    try {
      if (!from_external) {
        const consent = confirm('Are you sure do you want to save?');
        if (!consent) {
          return;
        }
      }

      const user_info = this.user_form.value;

      if (!this.validateEmail(user_info.email)) {
        alert('Please enter valid email');
        return;
      }

      if (user_info.apply_for === 'UG') {
        //const sslc_academics = user_info.sslc_academics;

        /* sslc_academics.accademic_info.year_month_passout = sslc_academics.accademic_info.year_of_passout + '-'
          + sslc_academics.accademic_info.month_of_passout.padStart(2, '0'); */


        const valid_marks = [];
        /* for (const mark of sslc_academics.marks) {
          try {

            mark.mark = parseInt(mark.mark, 10);
            if (mark.mark > 0 && mark.mark <= 100 && mark.subject_name) {
              valid_marks.push(mark);
            }
          } catch (error) {

          }
        } */
        /* if (valid_marks.length !== sslc_academics.marks.length) {
          alert('Please enter valid SSLC marks');
          return;
        } */


        //user_info.sslc_academics = sslc_academics;


        const hsc_academics = user_info.hsc_academics;


        hsc_academics.accademic_info.year_month_passout = hsc_academics.accademic_info.year_of_passout + '-'
          + hsc_academics.accademic_info.month_of_passout.padStart(2, '0');

        const valid_marks_2 = [];
        const validate_mark = false;
        for (const mark of hsc_academics.marks) {
          let is_valid = true;
          if (validate_mark) {
            try {
              mark.mark = parseInt(mark.mark, 10);
              mark.mark = Math.abs(mark.mark);
              if (mark.mark > 0 && mark.mark <= 100) {
                is_valid = true;
              } else {
                is_valid = false;
              }
            } catch (error) {
              is_valid = false;
            }

          }
          if (is_valid) {
            if (mark.subject_id) {
              const subject = this.subject_list.find(c => c.subject_id === mark.subject_id);
              mark.subject_name = subject.subject_name;
            }
            if (mark.subject_name || mark.subject_id) {
              is_valid = true;
            } else {
              is_valid = false;
            }
          }
          if (is_valid) {
            valid_marks_2.push(mark);
          }

        }
        if (valid_marks_2.length !== hsc_academics.marks.length) {
          alert('Please enter valid HSC marks');
          return;
        }
        user_info.hsc_academics = hsc_academics;


      } else if (user_info.apply_for === 'PG') {
        const degree_academics = user_info.degree_academics;

        if (degree_academics.accademic_info.is_anjac === 'true') {
          degree_academics.accademic_info.school_name = 'Ayya Nadar Janaki Ammal College';
        }

        degree_academics.accademic_info.year_month_passout = degree_academics.accademic_info.year_of_passout + '-'
          + degree_academics.accademic_info.month_of_passout.padStart(2, '0');

        const valid_marks_2 = [];
        let has_error = false;
        const validate_mark = true;
        for (const mark of degree_academics.marks) {
          const max_mark = Number(mark.no_of_subjects) * 100;

          if (validate_mark) {
            try {
              mark.mark = parseInt(mark.mark, 10);
              mark.mark = Math.abs(mark.mark);
              if (mark.mark !== 0) {
                if (mark.mark > 0 && mark.mark <= max_mark) {
                  if (mark.semester <= 4) {
                    // if (!this.validateString(mark.semester_file)) {
                    //   alert('Please upload Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' mark certificate');
                    //   has_error = true;
                    //   break;
                    // }
                  }
                } else {
                  has_error = true;
                  alert('Error : In Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' Mark must be between 1 to ' + max_mark);
                  break;
                }
              }
            } catch (error) {
              has_error = true;
              alert('Error : In Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' Please enter valid mark');
              break;
            }

          }
          valid_marks_2.push(mark);

        }
        if (has_error) {
          return;
        }
        const valid_marks_2_filter = valid_marks_2.filter(c => c.mark !== 0);
        if (valid_marks_2_filter.length < 4) {
          alert('Please enter at least 4 semester marks');
          return;
        }
        user_info.degree_academics = degree_academics;
      }


      console.log(user_info);

      const respose = await this.restService.saveApplicantInfo(this.applicant_id, user_info);
      if (respose.success) {
        if (!from_external) {
          alert('Saved Successfully');
          this.onApplicationFeeFormCloseClick();
          await this.getApplicantsWithGivenStatus();
        }
      }

    } catch (error) {
      console.error(error);
      alert('Error while saving Profile');
    }

  }

  async uploadImage(e: any, form: FormGroup, key: string): Promise<void> {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadFile(formData);
      if (response.success && response.data && response.data.url) {
        const val = form.get(key);
        if (val) {
          val.setValue(response.data.url);
        }

      }
    } catch (error) {

    } finally {

    }
  }

  async onRejectClick(): Promise<void> {
    try {
      const consent = prompt('Enter Reason For Rejection');
      if (consent) {
        await this.onProfileSaveClick(true);
        const data = {
          admission_id: this.admission_id,
          application_no: this.application_no,
          status: 'rejected',
          reason: consent
        };
        const response = await this.restService.saveApplicantsWithGivenStatus(data);
        if (response.success) {
          alert('Rejected successfully');
          this.onApplicationFeeFormCloseClick();
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }
  async onApproveClick(status_to_send): Promise<void> {
    try {
      const consent = confirm('Are you sure, do you want to approve?');
      if (consent) {
        await this.onProfileSaveClick(true);
        const data = {
          admission_id: this.admission_id,
          application_no: this.application_no,
          //status: 'verified',
          status: status_to_send,
        };
        const response = await this.restService.saveApplicantsWithGivenStatus(data);
        if (response.success) {
          if (status_to_send == 'merit_list') {
            alert('Approved successfully');
          }

          if (status_to_send == 'verified') {
            alert('Verified successfully');
          }

          this.onApplicationFeeFormCloseClick();
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }
  //#endregion

  async onUnRejectClick(applicant: any): Promise<void> {
    try {
      this.application_no = applicant.application_no;
      const consent = prompt('Enter Reason For Cancel the Rejection');
      if (consent) {
        const data = {
          admission_id: this.admission_id,
          application_no: applicant.application_no,
          status: 'in_applied',
          reason: consent
        };
        const response = await this.restService.saveApplicantsWithGivenStatus(data);
        if (response.success) {
          alert('Cancelled the Rejection successfully');
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onRejectApplicantClick(applicant: any): Promise<void> {
    try {
      this.application_no = applicant.application_no;
      const consent = prompt('Enter Reason For Rejection');
      if (consent) {
        const data = {
          admission_id: this.admission_id,
          application_no: applicant.application_no,
          status: 'rejected',
          reason: consent
        };
        const response = await this.restService.saveApplicantsWithGivenStatus(data);
        if (response.success) {
          alert('Applicant Rejected successfully');
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onUnVerifyClick(applicant: any): Promise<void> {
    try {
      this.application_no = applicant.application_no;
      const consent = prompt('Enter Reason For Cancel the Verification');
      if (consent) {
        const data = {
          admission_id: this.admission_id,
          application_no: applicant.application_no,
          //status: 'in_applied',
          status: 'verification_list',
          reason: consent
        };
        const response = await this.restService.saveApplicantsWithGivenStatus(data);
        if (response.success) {
          alert('Cancelled the Verification successfully');
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onSlectionClick(applicant: any, event: any): Promise<void> {
    if (applicant.selected === true) {
      this.selection_list = this.selection_list.filter(c => c.application_no !== applicant.application_no);
      applicant.selected = false;
    } else {
      if (!this.selection_list.find(c => c.application_no === applicant.application_no)) {
        this.selection_list.push(applicant);
      }
      applicant.selected = true;
    }

  }
  async onSlectAllClick(event: any): Promise<void> {
    this.selection_list = [];
    if (event.target.checked) {
      for (const applicant of this.applicant_list) {
        applicant.selected = event.target.checked;
        this.selection_list.push(applicant);
      }
    } else {
      for (const applicant of this.applicant_list) {
        applicant.selected = false;
      }
    }
  }

  async onSlectAllCandidatesClick(event: any): Promise<void> {
    this.selection_list = [];
    if (event.target.checked) {
      var i = 0;
      for (const applicant of this.applicant_list) {
        applicant.selected = event.target.checked;
        this.selection_list.push(applicant);
        (document.getElementById("selection_" + i) as HTMLInputElement).checked = true;
        i++;
      }
    } else {
      var i = 0;
      for (const applicant of this.applicant_list) {
        applicant.selected = false;
        (document.getElementById("selection_" + i) as HTMLInputElement).checked = false;
        i++;
      }
    }
  }


  async onViewSelectedClick(): Promise<void> {
    try {
      JQueryHelper.openModal('#modal-view-selected', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onSelectionViewCloseClick(): Promise<void> {

    JQueryHelper.closeModal('#modal-view-selected');
  }

  async onAllowPaymentClick(status_to_send: string = ''): Promise<void> {
    try {
      if (this.status_change_loading) {
        return;
      }
      const consent = confirm('Are you sure?');
      if (consent) {
        if (!status_to_send) {
          return;
        }
        const data_list = [];
        for (const selection_data of this.selection_list) {
          const data = {
            admission_id: this.admission_id,
            application_no: selection_data.application_no,
            programme_id: selection_data.programme_id,
            status: status_to_send
          };
          data_list.push(data);
        }
        this.status_change_loading = true;
        const response = await this.restService.saveApplicantsWithGivenStatusBulk(data_list);
        if (response.success) {
          alert('Successfully saved');
          this.selection_list = [];
          await this.getApplicantsWithGivenStatus();
          await this.onSelectionViewCloseClick();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.status_change_loading = false;
    }
  }

  onPrintClick(): void {
    window.print();
  }




  async onGenderChange(): Promise<void> {
    this.getApplicantsWithGivenStatus();
  }

  async onEntranceChange(): Promise<void> {
    this.getApplicantsWithGivenStatus();
  }

  isStatusWisePrintNeeded(title: string): boolean {
    if (title === 'Mob. No.') {
      if (this.status === 'verified') {
        return true;
      }
    }
    if (title === 'Community' || title === 'Caste' || title === 'Institute' || title === 'Institute Reg.No.'
      || title === 'Gender' || title === 'Remarks') {
      if (this.status === 'ready_to_pay') {
        return true;
      }
    }
    return false;
  }

  isPrintNeeded(title: string): boolean {
    if (title === 'Total') {
      if (this.status === 'ready_to_pay') {
        return true;
      }
      return false;
    }
    else if (title === 'Accademic') {
      if (this.status === 'ready_to_pay') {
        return true;
      }
      return false;
    } else if (title === 'Entrance') {
      if (this.status === 'ready_to_pay') {
        return true;
      }
      return false;
    } else if (title === 'Sem. Total') {
      if (this.status === 'ready_to_pay') {
        return true;
      }
      return false;
    }
    return true;
  }

  async onOfflineDue1ClearClick(applicant: any): Promise<void> {
    try {
      this.application_no = applicant.application_no;
      const payment_ref = prompt('Enter Referance No.');
      if (payment_ref) {
        const data_to_save = {
          prg_fee_settings_id: applicant.payment_term_1_fee_settings_id,
          sub: applicant.applicant_id,
          payment_ref
        };
        const response = await this.restService.clearOfflinePaymentDues(data_to_save);
        if (response.success) {
          alert('Due 1 Cleared successfully');
          this.getApplicantsWithGivenStatus();
        }
      } else {
        alert('Please enter the referance no');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }
  async onOfflineDue2ClearClick(applicant: any): Promise<void> {
    try {
      this.application_no = applicant.application_no;
      const payment_ref = prompt('Enter Referance No.');
      if (payment_ref) {
        /* const data_to_save = {
          prg_fee_settings_id: applicant.payment_term_2_fee_settings_id,
          sub: applicant.applicant_id,
          payment_ref
        }; */
        const data_to_save = {
          application_no: applicant.application_no,
          remarks: payment_ref
        };
        const response = await this.restService.clearOnlinePaymentDues(data_to_save);
        //const response = await this.restService.clearOfflinePaymentDues(data_to_save);
        if (response.success) {
          alert('Due 2 Cleared successfully');
          this.getApplicantsWithGivenStatus();
        }
      } else {
        alert('Please enter the referance no');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }



  async onGenerateEmailClick(): Promise<void> {
    try {
      if (this.email_generation_loading) {
        return;
      }
      const consent = confirm('Are you sure? do you want to generate email?');
      if (consent) {
        const data_list = [];
        for (const applicant_data of this.applicant_list) {
          const data = {
            admission_id: this.admission_id,
            application_no: applicant_data.application_no,
            programme_id: applicant_data.programme_id,
            applicant_id: applicant_data.applicant_id
          };
          data_list.push(data);
        }
        this.email_generation_loading = true;
        const response = await this.restService.generateBulkEmail(data_list);
        if (response.success) {
          alert('Submitted successfully');
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.email_generation_loading = false;
    }
  }

  async onRollNoClick(): Promise<void> {
    try {
      if (this.rollno_generation_loading) {
        return;
      }
      const consent = confirm('Are you sure? do you want to generate roll no?\nMake sure you already generated email.');
      if (consent) {
        const data_list = [];
        for (const applicant_data of this.applicant_list) {
          const data = {
            admission_id: this.admission_id,
            application_no: applicant_data.application_no,
            programme_id: applicant_data.programme_id,
            applicant_id: applicant_data.applicant_id,
            status: 'enrolled'
          };
          data_list.push(data);
        }
        this.rollno_generation_loading = true;
        const response = await this.restService.generateRollNo(data_list);
        if (response.success) {
          alert('Submitted successfully');
          await this.getApplicantsWithGivenStatus();
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.rollno_generation_loading = false;
    }
  }

  async downloadEnrolledUsers(): Promise<void> {
    const file_name = this.programme_name.toLowerCase() + '.csv';
    const data_to_download: any[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.applicant_list.length; index++) {
      const applicant = this.applicant_list[index];

      const mobile_numbers = [];
      if (applicant.mobile_number) {
        mobile_numbers.push(applicant.mobile_number);
      }
      if (applicant.mobile_number_2) {
        mobile_numbers.push(applicant.mobile_number_2);
      }
      if (applicant.mobile_number_3) {
        mobile_numbers.push(applicant.mobile_number_3);
      }
      if (applicant.mobile_number_4) {
        mobile_numbers.push(applicant.mobile_number_4);
      }
      if (applicant.mobile_number_5) {
        mobile_numbers.push(applicant.mobile_number_5);
      }

      let data_item: any;
      if (this.status === 'enrolled') {
        let total_mark = applicant.marks.find(x => x.title == 'Total')?.mark;
        data_item = {
          'S.NO': (index + 1),
          'APP. NO.': applicant.application_no,
          'ROLL NO.': applicant.roll_no,
          EMAIL: applicant.education_email,
          NAME: applicant.name.toUpperCase(),
          'MOB. NO.': mobile_numbers.join(' '),
          'DATE OF BIRTH': DateHelper.toDisplayDate(new Date(applicant.birth_date)),
          GENDER: applicant.gender.toUpperCase(),
          COMMUNITY: applicant.community_id,
          CASTE: applicant.caste,
          'TOTAL MARK': total_mark ? total_mark : 0
        };
      } else {
        data_item = {
          'S.NO': (index + 1),
          'DATE.': DateHelper.toDisplayDate(new Date(applicant.updatedAt)),
          'APP. NO.': applicant.application_no,
          'APP. DATe.': DateHelper.toDisplayDate(new Date(applicant.createdAt)),
          NAME: applicant.name.toUpperCase(),
          'MOB. NO.': mobile_numbers.join(' '),
          'DATE OF BIRTH': DateHelper.toDisplayDate(new Date(applicant.birth_date)),
          GENDER: applicant.gender.toUpperCase(),
          COMMUNITY: applicant.community_id,
          CASTE: applicant.caste,
          INSTITUTE: applicant.institute,
          INSTITUTE_REG_NO: applicant.institute_reg_no,
        };
        if (this.status === 'in_applied' && this.admission_search_form.value.skip_status) {
          data_item.STATUS = applicant.status.toUpperCase();
        }
        for (const mark of applicant.marks) {
          data_item[mark.title.toUpperCase()] = mark.mark;
        }
        data_item.REMARKS = applicant.remarks;

      }

      data_to_download.push(data_item);
    }

    CSVHelper.downloadCSV(data_to_download, file_name);

  }

  async onOnlineDueClearClick(applicant: any): Promise<void> {
    try {
      const payment_ref = prompt('Enter Referance No.');
      if (payment_ref) {
        const data_to_save = {
          application_no: applicant.application_no,
          remarks: payment_ref
        };
        const response = await this.restService.clearOnlinePaymentDues(data_to_save);
        if (response.success) {
          alert('Cleared successfully');
          this.getApplicantsWithGivenStatus();
        }
      } else {
        alert('Please enter the referance no');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async approveAllApplicants(status_to_send) {
    try {
      if (this.approve_all_loading) {
        return;
      }
      const consent = confirm('Are you sure?');
      if (consent) {
        if (!status_to_send) {
          return;
        }
        const data_list = [];
        for (const applicant_data of this.applicant_list) {
          var can_send = true;
          if (status_to_send == 'merit_list') {
            if (applicant_data.total_marks == 0) {
              can_send = false;
            }
          }
          if (can_send) {
            const data = {
              admission_id: this.admission_id,
              application_no: applicant_data.application_no,
              programme_id: applicant_data.programme_id,
              status: status_to_send
            };
            data_list.push(data);
          }
        }
        this.approve_all_loading = true;
        console.log(data_list);
        if (data_list.length > 0) {
          const response = await this.restService.saveApplicantsWithGivenStatusBulk(data_list);
          if (response.success) {
            alert('Successfully saved');
            this.selection_list = [];
            await this.getApplicantsWithGivenStatus();
            await this.onSelectionViewCloseClick();
          }
        }
        else {
          alert("Error! No student list to move");
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.approve_all_loading = false;
    }
  }

  getStatusDisplayText(status: string) {
    if (status == 'verified') {
      return "Selection";
    }
    return status.split('_').join(' ');
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  printMeritList() {
    const list_name = prompt('Enter List Name.');
    const data_to_download: any[] = this.getMeritListData();
    console.log(data_to_download);
    let current_date = DateHelper.toDisplayDate(new Date());
    var table_string: string = '';
    table_string +=
      '<table class="table table-bordered" style="border:2px solid black"><thead><tr>';
    //for (var i = 0; i < data_to_download.length; i++) {
    for (const [key, value] of Object.entries(data_to_download[0])) {
      table_string += '<th style="border: 2px solid black">' + key + '</th>';
    }
    //}
    table_string += '</tr></thead><tbody>';
    for (var i = 0; i < data_to_download.length; i++) {
      table_string += "<tr style='border: 2px solid black'>";
      for (const [key, value] of Object.entries(data_to_download[i])) {
        table_string += '<td style="border: 2px solid black">' + value + '</td>';
      }
      table_string += "</tr>";
    }
    table_string += '</tbody></table>';
    console.log(table_string);
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A3 landscape;} @media print { html, body { margin-right:3%; margin-left:2%; margin-top:2%; margin-bottom:3%}}</style>"
    );
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h4>Admission - ' + this.regulation_year + '</h4>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h4 class= "text-center"><b> ' + list_name + ' List - ' + this.programme_name + '</b></h4>');
    mywindow.document.write('<h4 style= "float:right"><b>' + current_date + '</b></h4>');
    mywindow.document.write(table_string);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  downloadMeritListCSV() {
    const list_name = prompt('Enter List Name.');
    const file_name = this.programme_name.toLowerCase() + list_name + '_list.csv';
    const data_to_download = this.getMeritListData();
    CSVHelper.downloadCSV(data_to_download, file_name);
  }

  getMeritListData() {
    var data_to_download: any[] = [];
    const programme_data = this.programme_list.find(x => x.programme_id == this.applicant_list[0].programme_id);
    for (let index = 0; index < this.applicant_list.length; index++) {
      const applicant = this.applicant_list[index];
      const mobile_numbers = [];
      if (applicant.mobile_number) {
        mobile_numbers.push(applicant.mobile_number);
      }
      if (applicant.mobile_number_2) {
        mobile_numbers.push(applicant.mobile_number_2);
      }
      /* if (applicant.mobile_number_3) {
        mobile_numbers.push(applicant.mobile_number_3);
      }
      if (applicant.mobile_number_4) {
        mobile_numbers.push(applicant.mobile_number_4);
      }
      if (applicant.mobile_number_5) {
        mobile_numbers.push(applicant.mobile_number_5);
      } */

      let data_item: any;
      data_item = {
        'S.NO': (index + 1),
        'APP. NO.': applicant.application_no,
        'NAME': applicant.name.toUpperCase(),
        'MOB. NO.': mobile_numbers.join(','),
        'DATE OF BIRTH': DateHelper.toDisplayDate(new Date(applicant.birth_date)),
        'GENDER': applicant.gender.toUpperCase(),
        'COMMUNITY': applicant.community_id,
        'CASTE': applicant.caste,
        'INSTITUTE': applicant.institute,
      };
      if (programme_data.programme_type_id == 'UG') {
        const hsc_academics_marks: any[] = applicant.hsc_academics.marks;
        if (applicant.programme_id == 'b8207f2f-9512-4ecd-b3f8-e78ee01dc577' || applicant.programme_id == '6e5b6273-a37e-4b93-a070-dd4c6584a726') { //BCOM PA & PHS
          for (const mark of applicant.marks) {
            data_item[mark.title.toUpperCase()] = mark.mark;
          }
        }
        else if (applicant.programme_id == 'bda96629-561a-47ba-8ea3-d69ebc138429') { //English
          data_item.TOTAL = hsc_academics_marks.find(x => x.part_type == 2)?.mark;
        }
        else {
          //Other Programmes
          var i = 1;
          var total_mark: number = 0;
          hsc_academics_marks.forEach(subject => {
            if (subject.part_type == 3) {
              data_item["M" + i] = subject.mark;
              i++;
              total_mark += parseInt(subject.mark as string);
            }
          });
          //data_item.TOTAL = applicant.total_marks;
          data_item.TOTAL = total_mark;
        }
      }

      if (programme_data.programme_type_id == 'PG') {
        const pg_marks: any[] = applicant.marks;
        //Other Programmes
        var i = 1;
        var total_mark: number = 0;
        pg_marks.forEach(semester => {
          data_item[semester.title] = semester.mark;
        });
      }

      data_item.REMARKS = applicant.remarks;
      data_to_download.push(data_item);
    }
    return data_to_download;
  }

  onCommunityFilterChange(event) {
    if (event.target.value == 'bc/bcm') {
      this.applicant_list = this.applicant_list_copy.filter(x => x.community_id == 'BC' || x.community_id == 'BCM');
    }
    else if (event.target.value == 'mbc/dnc') {
      this.applicant_list = this.applicant_list_copy.filter(x => x.community_id == 'MBC' || x.community_id == 'DNC');
    }
    else if (event.target.value == 'sc/st/sca') {
      this.applicant_list_copy.filter(x => x.community_id == 'SC' || x.community_id == 'ST' || x.community_id == 'SCA');
    }
    else {
      this.applicant_list = this.applicant_list_copy;
    }
  }

  downloadSelectionList() {
    var data_to_download: any[] = [];
    const programme_data = this.programme_list.find(x => x.programme_id == this.applicant_list[0].programme_id);
    for (let index = 0; index < this.selection_list.length; index++) {
      const applicant = this.selection_list[index];
      const mobile_numbers = [];
      if (applicant.mobile_number) {
        mobile_numbers.push(applicant.mobile_number);
      }
      if (applicant.mobile_number_2) {
        mobile_numbers.push(applicant.mobile_number_2);
      }
      if (applicant.mobile_number_3) {
        mobile_numbers.push(applicant.mobile_number_3);
      }
      if (applicant.mobile_number_4) {
        mobile_numbers.push(applicant.mobile_number_4);
      }
      if (applicant.mobile_number_5) {
        mobile_numbers.push(applicant.mobile_number_5);
      }
      let data_item: any;
      data_item = {
        'S.NO': (index + 1),
        'APP. NO.': applicant.application_no,
        'NAME': applicant.name.toUpperCase(),
        'MOB. NO.': mobile_numbers.join(', '),
      };
      data_to_download.push(data_item);
    }
    const file_name = this.programme_name.toLowerCase() + '_selection_list.csv';
    CSVHelper.downloadCSV(data_to_download, file_name);
  }


  printSelectionList() {
    const data_to_download: any[] = this.getMeritListData();
    let current_date = DateHelper.toDisplayDate(new Date());
    var table_string: string = '';
    table_string +=
      '<table class="table table-bordered"><thead><tr>' +
      '<td>S.No</td><td>Application No.</td><td>Name</td><td>Mobile No.</td></tr></thead><tbody>';
    var i = 0;
    this.selection_list.forEach(applicant => {
      const mobile_numbers = [];
      if (applicant.mobile_number) {
        mobile_numbers.push(applicant.mobile_number);
      }
      if (applicant.mobile_number_2) {
        mobile_numbers.push(applicant.mobile_number_2);
      }
      if (applicant.mobile_number_3) {
        mobile_numbers.push(applicant.mobile_number_3);
      }
      if (applicant.mobile_number_4) {
        mobile_numbers.push(applicant.mobile_number_4);
      }
      if (applicant.mobile_number_5) {
        mobile_numbers.push(applicant.mobile_number_5);
      }

      table_string += "<tr>";
      table_string += "<td>" + (i + 1) + "</td>";
      table_string += "<td>" + applicant.application_no + "</td>";
      table_string += "<td>" + applicant.name.toUpperCase() + "</td>";
      table_string += "<td>" + mobile_numbers.join(', '), + "</td>";
      table_string += "</tr>";
      i++;
    });
    table_string += '</tbody></table>';
    console.log(table_string);
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'; font-size:2em">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h5>Admission - ' + this.regulation_year + '</h5>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h6 class= "text-center"><b> Selection List - ' + this.programme_name + (this.admission_search_form.value.community != '' ? (" - " + (this.admission_search_form.value.community as string).toUpperCase().split('_').join(' ')) : '') + '</b></h6>');
    //mywindow.document.write('<h6 style= "float:right"><b>' + current_date + '</b></h6>');
    mywindow.document.write(table_string);
    mywindow.document.write('<div class="row" style="margin-top:30px"><div class="col-md-10"><b>Sivakasi,</b></div><div class="col-md-2" style="float:right"><b>Principal,</b></div></div>');
    mywindow.document.write('<div class="row"><div class="col-md-12"><b>' + current_date + '.</b></div></div>');
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }



  printStudentsSelectionList() {
    const data_to_download: any[] = this.getMeritListData();
    let current_date = DateHelper.toDisplayDate(new Date());
    var table_string: string = '';
    table_string +=
      '<table class="table table-bordered"><thead><tr>' +
      '<td>S.No</td><td>Application No.</td><td>Name</td></tr></thead><tbody>';
    var i = 0;
    this.selection_list.forEach(applicant => {
      table_string += "<tr>";
      table_string += "<td>" + (i + 1) + "</td>";
      table_string += "<td>" + applicant.application_no + "</td>";
      table_string += "<td>" + applicant.name.toUpperCase() + "</td>";
      table_string += "</tr>";
      i++;
    });
    table_string += '</tbody></table>';
    console.log(table_string);
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h5>Admission - ' + this.regulation_year + '</h5>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h6 class= "text-center"><b> Selection List - ' + this.programme_name + '</b></h6>');
    mywindow.document.write(table_string);
    mywindow.document.write('<div class="row" style="margin-top:30px"><div class="col-md-10"><b>Sivakasi,</b></div><div class="col-md-2" style="float:right"><b>Principal,</b></div></div>');
    mywindow.document.write('<div class="row"><div class="col-md-12"><b>' + current_date + '.</b></div></div>');
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  totalMarkFilterClick() {
    this.selection_list = [];
    var max_mark = this.admission_search_form.value.total_filter;
    var i = 0;
    this.applicant_list.forEach(applicant => {
      if (applicant.total_marks >= max_mark) {
        applicant.selected = true;
        this.selection_list.push(applicant);
        (document.getElementById("selection_" + i) as HTMLInputElement).checked = true;
      }
      else {
        applicant.selected = false;
        (document.getElementById("selection_" + i) as HTMLInputElement).checked = false;
      }
      i++;
    });
  }

  navigateToCertificateTracker() {
    this.router.navigate(["/admin/students/certificate-tracker"], {
      queryParams: {
        regulation_year: this.regulation_year,
        admission_id: this.admission_id,
        finance_type: this.finance_type,
        programme_id: this.programme_id,
        programme_name: this.programme_name,
        status: this.status
      }
    })
  }

  downloadGoogleMailTemplate() {
    try {
      const data_list: any[] = [];
      let i = 1;
      for (const applicant of this.applicant_list) {
        data_list.push({
          "First Name [Required]": applicant.given_name,
          "Last Name [Required]": applicant.middle_name,
          "Email Address [Required]": applicant.education_email,
          "Password [Required]": "anjac@2023",
          "Org Unit Path [Required]": "/"
        });
        i++;
      }
      CSVHelper.downloadCSV(data_list, this.programme_name + "_MAIL_LIST_" + DateHelper.convertToControlDate(new Date()) + ".csv");
    }
    catch (error) {
      alert("Error! Could not download Mail Template CSV File");
    }
  }
}
