<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal>
                <!-- <input type="file" accept=".csv" (change)="bulkUpload($event)" #fileImportInput
                    [(ngModel)]="file_upload" placeholder="ATTACH CSV FILE" style="width: 150px; color:black"> -->
                <button type="button" (click)="onAddClick()" class="btn btn-info mg-l-10"> Assign Course </button>
                <button type="button" (click)="onSemesterwiseClick()" class="btn btn-info mg-l-10"> Semester-wise Course
                    Assignment </button>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Assign Courses to Students</p>
                    </div>
                    <div class="card-body">
                        <h6>Search By</h6>
                        <div class="row ">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="roll_no_search" (change)="changeType($event)" id="flexRadioDefault1"
                                            checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Student Roll No.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="course_code_search" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Course code
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="programme_search" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Programme
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='course_code_search' || type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="filter_form.value.programme_id!='' && sections.length>1 && type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Type</label>
                                        <select formControlName="course_type" class="form-control">
                                            <option value="">All Course Type</option>
                                            <option
                                                *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="course_type.id">{{course_type.text}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2" *ngIf="type=='roll_no_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="roll_no"
                                            placeholder="Search with Student Roll No..">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='course_code_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                        <br>

                        <div class="d-flex right-content mg-b-5" style="float: right;"
                            *ngIf="type=='course_code_search'">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadFile('CSV')">Download CSV</a>
                                    <a class="dropdown-item" (click)="downloadFile('PDF')">Download PDF</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex right-content mg-b-5" style="float: right;" *ngIf="type=='programme_search'">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadProgrammeFile('PDF')">Download PDF</a>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="this.type == 'roll_no_search' && !isTableDataEmpty()">
                            <div class="col-md-2">
                                Roll No: <b>{{table_data.roll_no}}</b>
                            </div>
                            <div class="col-md-4">
                                Name: <b>{{table_data.name}}</b>
                            </div>
                            <div class="col-md-4">
                                Programme: <b>{{table_data.programme_name}}</b>
                            </div>
                            <div class="col-md-2">
                                Enrolled Year: <b>{{table_data.enrolled_year}}</b>
                            </div>
                        </div>
                        <div class="row" *ngIf="this.type == 'course_code_search' && !isTableDataEmpty()">
                            <div class="col-md-2">
                                Course Code: <b>{{table_data.course_code}}</b>
                            </div>
                            <div class="col-md-4">
                                Course Name: <b>{{table_data.course_name}}</b>
                            </div>
                            <div class="col-md-3">
                                Offered by: <b>{{table_data.programme_name}}</b>
                            </div>
                            <div class="col-md-3">
                                Part (Course Type): <b>{{table_data.part}} ({{table_data.course_type}})</b>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th>S.No</th>
                                        <th *ngIf="this.type == 'course_code_search'">Roll No</th>
                                        <th *ngIf="this.type == 'course_code_search'">Student Name</th>
                                        <th *ngIf="this.type == 'roll_no_search' || this.type=='programme_search'">
                                            Course Code</th>
                                        <th *ngIf="this.type == 'roll_no_search' || this.type=='programme_search'">
                                            Course Name</th>
                                        <th *ngIf="this.type == 'course_code_search' || this.type=='programme_search'">
                                            Programme Name</th>
                                        <th *ngIf="this.type == 'programme_search'">Part</th>
                                        <th *ngIf="this.type == 'course_code_search'">Section</th>
                                        <th>Appearance Type</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="this.type == 'roll_no_search'">
                                    <ng-container
                                        *ngFor="let group_data of table_data.group_data;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td colspan="5" class="text-center">
                                                <b>Semester: {{group_data.semester}}</b>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="let data of group_data.data; let j = index;trackBy:fromDataResolver.identify;">
                                            <td>{{j+1}}</td>
                                            <td>{{data.course_code}}</td>
                                            <td>{{data.course_name}}</td>
                                            <td>{{data.appearance_type | titlecase}}</td>
                                            <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                    <button type="button"
                                                        (click)="onEditByRollNo(table_data.enrolled_year,group_data.semester,table_data.roll_no, data)"
                                                        class="btn btn-sm btn-info mr-2">
                                                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                        <tr>
                                            <td colspan="5" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                        <tr>
                                            <td colspan="5" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                                <tbody *ngIf="this.type == 'course_code_search'">
                                    <tr
                                        *ngFor="let data of table_data.student_data; let i = index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.roll_no}}</td>
                                        <td>{{data.given_name}} {{data.middle_name}}</td>
                                        <td>{{data.programme_name}}</td>
                                        <td>{{data.section}}</td>
                                        <td>{{data.appearance_type | titlecase}}</td>
                                        <td>
                                            <div class="pr-1 mb-3 mb-xl-0">
                                                <button type="button"
                                                    (click)="onEditByCourseCode(table_data.course_code,data)"
                                                    class="btn btn-sm btn-info mr-2">
                                                    <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                        <tr>
                                            <td colspan="7" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                        <tr>
                                            <td colspan="7" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                                <tbody *ngIf="this.type == 'programme_search'">
                                    <ng-container *ngIf="!isTableDataEmpty()">
                                        <ng-container
                                            *ngFor="let student_data of table_data;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td colspan="7" class="text-center">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            Roll No: <b>{{student_data.roll_no}}</b>
                                                        </div>
                                                        <div class="col-md-6">
                                                            Name: <b>{{student_data.given_name}}
                                                                {{student_data.middle_name}}</b>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let data of student_data.data; let j = index;trackBy:fromDataResolver.identify;">
                                                <td>{{j+1}}</td>
                                                <td>{{data.course_code}}</td>
                                                <td>{{data.course_name}}</td>
                                                <td>{{data.programme_name}}</td>
                                                <td>{{data.part}}</td>
                                                <td>{{data.appearance_type | titlecase}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button"
                                                            (click)="onEditByProgramme(student_data._id, student_data.roll_no,student_data.enrolled_year,data)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="( isTableDataEmpty() || table_data.length==0) && table_data_loading">
                                        <tr>
                                            <td colspan="7" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="( isTableDataEmpty() || table_data.length==0) && !table_data_loading">
                                        <tr>
                                            <td colspan="7" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>



                    </div>
                </div>
            </div>

        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <!--<h6 class="modal-title">Student-Course Assignment Form</h6>-->
                        <h6 class="modal-title" *ngIf="!this.studentassignForm.value.id">Student-Course Assignment
                        </h6>
                        <h6 class="modal-title" *ngIf="this.studentassignForm.value.id">Edit Student-Course
                            Assignment</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="studentassignForm" class="form-horizontal">

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                        Code</label>
                                    <input type="text" formControlName="course_code" class="form-control"
                                        placeholder="Enter Course Code" (blur)="searchStaffByCourse($event)">
                                    <div *ngIf="isInvalid('course_code')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Course Code is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                        year</label>
                                    <select formControlName="enrolled_year" class="form-control">
                                        <option value="">Select Enrolment year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Semester</label>
                                    <select formControlName="semester" class="form-control">
                                        <option value="">Select Semester</option>
                                        <option
                                            *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="semester.id">{{semester.text}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('semester')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Semester is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Student Roll
                                        Nos.</label>
                                    <textarea formControlName="roll_nos"
                                        placeholder="Enter Student Roll No(s) separated by comma. (Ex: 20PA01,20PA02 etc..)"
                                        class="form-control"></textarea>
                                    <div *ngIf="isInvalid('roll_nos')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Student Roll Nos. is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Appearance Type</label>
                                    <select formControlName="appearance_type" class="form-control">
                                        <option value="">Choose Appearance Type</option>
                                        <option
                                            *ngFor="let type of appearance_types;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="type.key">{{type.value}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('appearance_type')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Appearance Type is required.</li>
                                        </ul>
                                    </div>
                                </div>


                                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Course Handling Staff</label>
                                    <select formControlName="staff_id" class="form-control">
                                        <option value="">Select Staff Name</option>
                                        <option
                                            *ngFor="let staff of course_handling_staffs;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="staff.staff_id">{{staff.staff_name}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('staff_id')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Staff Name is required.</li>
                                        </ul>
                                    </div>
                                </div> -->



                                <div class="add-btn-row" style="text-align: center;">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Add</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Add & Close</button>
                                    <ng-container
                                        *ngIf="this.studentassignForm.value._id && (this.type == 'course_code_search' || this.type == 'roll_no_search') ">
                                        <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                            *ngIf="!map_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled
                                            *ngIf="map_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </ng-container>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-semester">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <!--<h6 class="modal-title">Student-Course Assignment Form</h6>-->
                        <h6 class="modal-title" *ngIf="!this.studentassignForm.value.id">Student-Course Semester-wise
                            Assignment
                        </h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="studentsemesterForm" class="form-horizontal">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                        year</label>
                                    <select formControlName="enrolled_year" class="form-control">
                                        <option value="">Select Enrolment year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Semester</label>
                                    <select formControlName="semester" class="form-control">
                                        <option value="">Select Semester</option>
                                        <option
                                            *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="semester.id">{{semester.text}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('semester')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Semester is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Student Roll
                                        Nos.</label>
                                    <textarea formControlName="roll_nos"
                                        placeholder="Enter Student Roll No(s) separated by comma. (Ex: 20PA01,20PA02 etc..)"
                                        class="form-control"></textarea>
                                    <div *ngIf="isInvalid('roll_nos')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Student Roll Nos. is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="add-btn-row" style="text-align: center;">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveSemesterClick()"
                                        [disabled]="canDisableSemester()">Add</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveSemesterAndClose()"
                                        [disabled]="canDisableSemester()">Add & Close</button>
                                    <button class="btn btn-dark mg-r-20" (click)="resetSemesterwise()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20"
                                        (click)="onCloseSemesterwiseClick()">Close</button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>



<!--Unused filters-->
<!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->







<!-- <div class="col-lg-2" *ngIf="type=='programme_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Position</label>
                                        <select formControlName="course_position" class="form-control">
                                            <option value="">Select Course Position</option>
                                            <option
                                                *ngFor="let post of this.course_positions;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="post">
                                                {{post}}</option>
                                        </select>
                                    </div>
                                </div> -->