import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-student-leave-apply',
  templateUrl: './student-leave-apply.component.html',
  styleUrls: ['./student-leave-apply.component.scss']
})
export class StudentLeaveApplyComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  request_list_loading: boolean = false;
  save_loading: boolean = false;
  delete_loading: boolean = false;

  leaveRequests: ILeaveRequest[] = [];
  leaveRequestForm: FormGroup;
  leaveRequest: ILeaveRequest = {} as ILeaveRequest;
  title = 'Make Leave Request';
  id = '';


  constructor(private restService: AdminservicesService, private formBuilder: FormBuilder) {
    this.buildForm();
  }

  getTittle(): string {
    return this.title;
  }

  ngOnInit(): void {
    this.getStudentLeaveRequestList();
  }

  async getStudentLeaveRequestList() {
    try {
      this.request_list_loading = true;
      this.leaveRequests = [];
      const search_data: any = {
        "academic_year": "2022-2023",
      }
      const service_response = await this.restService.searchLeaveRequests(search_data);
      //replace with sub api
      if (service_response && service_response.success) {
        this.leaveRequests = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.request_list_loading = false;
    }

  }

  buildForm() {
    if (JSON.stringify(this.leaveRequest) == '{}') {
      this.leaveRequest = {
        _id: '',
        academic_year: '',
        start_date: '',
        end_date: '',
        hour_number: [],
        reason: '',
        status: 'NEW',
        attachment: []
      };
    }
    this.leaveRequestForm = this.formBuilder.group({
      _id: [this.leaveRequest._id],
      academic_year: [this.leaveRequest.academic_year],
      start_date: [this.leaveRequest.start_date, [Validators.required]],
      end_date: [this.leaveRequest.end_date, [Validators.required]],
      hour_number: [((this.leaveRequest.hour_number.length > 0) ? this.leaveRequest.hour_number.join(',') : ''), [Validators.required]],
      reason: [this.leaveRequest.reason, [Validators.required]],
      status: [this.leaveRequest.status, [Validators.required]],
      attachment: [this.leaveRequest.attachment]
    });

  }

  onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(request: ILeaveRequest): Promise<void> {
    this.leaveRequest = request;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }


  isInvalid(formControl: string): boolean {
    return this.leaveRequestForm.touched && this.leaveRequestForm.get(formControl)?.errors !== null;
  }


  canDisable(): boolean {
    return this.leaveRequestForm.status !== 'VALID';
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
    this.addItem();
  }

  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  reset(): void {
    this.leaveRequestForm.reset();
    this.leaveRequestForm.controls['start_date'].setValue(new Date().toISOString().substring(0, 16))
    this.leaveRequestForm.controls['end_date'].setValue(new Date().toISOString().substring(0, 16))
  }
  async addItem(): Promise<void> {
    try {
      var form_data = this.leaveRequestForm.value;
      console.log(form_data);
      form_data.roll_nos = (form_data.roll_nos as string).split(',');
      form_data.hour_number = (form_data.hour_number as string).split(',');
      this.save_loading = true;
      if (this.leaveRequestForm.value._id) {
        const res = await this.restService.updateLeaveRequest(this.leaveRequestForm.value._id, form_data);
        if (res) {
          alert("Leave Request updated successfully.");
        }
      }
      else {
        const res = await this.restService.createLeaveRequest(form_data);
        if (res) {
          alert("Leave Request created successfully.");
        }
      }
      this.getStudentLeaveRequestList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteODRequest(this.leaveRequestForm.get('_id')!.value);
      if (res.success) {
        alert("Leave Request removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  getDateTimeView(date_time: Date) {
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" + date_time.getFullYear() + " " +
      (date_time.getHours() > 12 ? date_time.getHours() - 12 : date_time.getHours()) + ":" +
      ((date_time.getMinutes() > 9) ? date_time.getMinutes() : ((date_time.getMinutes() == 0) ? date_time.getMinutes() + "0" : "0" + (date_time.getMinutes())));
  }

  getDateTime(date_time: Date) {
    return date_time.getFullYear() +
      "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" +
      (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate())
      + "T" +
      date_time.getHours()
      + ":" +
      ((date_time.getMinutes() > 9) ? date_time.getMinutes() : ((date_time.getMinutes() == 0) ? date_time.getMinutes() + "0" : "0" + (date_time.getMinutes())));
  }

  async uploadAttachment(e: any, form: FormGroup, key: string): Promise<void> {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadLeaveAttachment(formData);
      if (response.success && response.data && response.data.url) {
        const val = form.get(key);
        if (val) {
          var attachments: string[] = val.value;
          attachments.push(response.data.url)
          val.setValue(attachments);
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }
}

interface ILeaveRequest {
  _id?: string,
  academic_year?: string,
  start_date: string,
  end_date: string,
  hour_number: number[],
  reason: string,
  status?: string,
  attachment?: string[],
  roll_no?: string
}