<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Holiday Calendar</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Holiday Calendar</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Pick holiday from below Calendar</p>
                        <div class="col-sm-3 pl-0">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Academic Year</label>
                            <select class="form-control" [(ngModel)]="academic_year"
                                (change)="yearChange(academic_year)" placeholder="Select Academic Year">
                                <option *ngFor="let range of getYearRange()">{{range}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body">
                        <kendo-scheduler [kendoSchedulerBinding]="events"
                            [kendoSchedulerReactiveEditing]="createFormGroup" [selectedDate]="selectedDate"
                            (save)="saveHandler($event)" style="height: 600px;" (remove)="deleteHandler($event, $event)"
                            (dragStart)="$event.preventDefault()" (drag)="$event.preventDefault()"
                            (dragEnd)="$event.preventDefault()" (resizeStart)="$event.preventDefault()"
                            (resize)="$event.preventDefault()" (resizeEnd)="$event.preventDefault()" [editable]="true">
                            <kendo-scheduler-month-view [eventHeight]="30">
                            </kendo-scheduler-month-view>

                            <ng-template kendoSchedulerEditDialogTemplate autoFocusedElement=".title-input"
                                id="edit-popup" let-formGroup="formGroup" let-isNew="isNew" let-editMode="editMode">
                                <div class="k-form-inline">
                                    <div class="k-form-field">
                                        <span>Name</span>
                                        <input class="form-control title-input" placeholder="Name"
                                            [formControl]="formGroup.get('title')" />

                                    </div>
                                    <div class="k-form-field k-datetime-picker-wrapper">
                                        <span>Start Date</span>
                                        <kendo-datetimepicker [format]="format" [formControl]="formGroup.get('start')"
                                            style="width: 100%;">
                                        </kendo-datetimepicker>
                                    </div>
                                    <div class="k-form-field k-datetime-picker-wrapper">
                                        <span>End Date</span>
                                        <kendo-datetimepicker [format]="format" [min]="formGroup.get('start')?.value"
                                            [formControl]="formGroup.get('end')" style="width: 100%;">
                                        </kendo-datetimepicker>
                                    </div>
                                    <div class="k-form-field">
                                        <span>Description</span>
                                        <textarea class="form-control title-input"
                                            placeholder="Enter Holiday Description"
                                            [formControl]="formGroup.get('description')"></textarea>

                                    </div>
                                </div>
                                <div class="text-right" *ngIf="!isNew">
                                    <button class="btn btn-danger btn-md" (click)="removeHoliday()">Delete</button>
                                </div>
                            </ng-template>
                        </kendo-scheduler>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>