import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper'
import { Modal } from 'src/app/components/ModalInterface';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-template-configuration',
  templateUrl: './template-configuration.component.html',
  styleUrls: ['./template-configuration.component.scss']
})
export class TemplateConfigurationComponent implements OnInit {
  @ViewChildren('editors') public editors: any;
  template_structure: templateInterface[] = [];
  mapping_data: FormGroup = undefined;
  programe_template_id: string = '';
  template_id: string = '';
  template_data: any = {};
  update_id: string = '';
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.getReportData();
  }

  getReportData() {
    this.route.params.subscribe(async params => {
      this.programe_template_id = params['id'];
      this.template_id = params['prgTempId'];
      try {
        const template_response = await this.restService.getProgramMapping(this.template_id);
        if (template_response.data && template_response.data[0]?.template_data) {
          this.template_data = template_response.data[0];
          this.template_structure = template_response.data[0]?.template_data;
          const mapped_response = await this.restService.getProgramTemplateMapping(this.programe_template_id, this.template_id);
          if (mapped_response.data && mapped_response.data[0]) {
            this.update_id = mapped_response.data[0]?._id;
            this.generateMappingForm(mapped_response.data[0]?.mapping_data);
          }
          else {
            this.update_id = '';
            this.generateMappingForm([]);
          }
        }

      } catch (error) {
        console.error('Error loading Template settings:', error);
      }
    });
  }


  //dummy tester
  /* ngOnInit(): void {
    this.route.params.subscribe(async params => {
      const programe_template_id = params['id'];
      const template_id = params['prgTempId'];
      try {
        this.template_structure = [
          {
            "title": "Grp 1",
            "description": "grp desc",
            "file_link": "",
            "format": "Group",
            "table_data": [],
            "_id": "883cae39-8396-4684-9438-e354ca4bef66"
          },
          {
            "title": "Table 1",
            "description": "tab desc",
            "file_link": "",
            "format": "Table",
            "table_data": [
              {
                "table_data_id": "617a6b9e-1b5f-4c1a-bd76-c5289fb56b98",
                "column_name": "C1",
                "column_format": "String"
              },
              {
                "table_data_id": "0adc69c6-9d8e-4376-815a-f48056192e0c",
                "column_name": "C2",
                "column_format": "Number"
              },
              {
                "table_data_id": "8da3a402-3327-4f58-9b15-6e2fc751e88c",
                "column_name": "C3",
                "column_format": "File"
              }
            ],
            "_id": "c6cab587-1750-451f-94a4-511025847f72"
          },
          {
            "title": "List sec",
            "description": "list",
            "file_link": "",
            "format": "List",
            "table_data": [],
            "list_data": {
              "header": "List header"
            },
            "_id": "61faeee9-20f1-4d9e-a213-aacdb1105c76"
          }
        ];
        const mapping_data = [
          {
            "title": "Students profile",
            "description": "For PG students",
            "file_link": "",
            "format": "Group",
            "table_data": [],
            "section_id": "883cae39-8396-4684-9438-e354ca4bef66"
          },
          {
            "section_id": "c6cab587-1750-451f-94a4-511025847f72",
            "title": "Settings 2.1",
            "description": "settings 2.1 description",
            "format": "Table",
            "table_data": [
              [
                {
                  "table_data_id": "48db3a71-7394-4495-b7ac-245a3f7ce773",
                  "column_name": "S.no",
                  "column_format": "Number",
                  "column_data": "1"
                },
                {
                  "table_data_id": "12265b0e-2db3-476b-8154-a8c89839f6d3",
                  "column_name": "Contents",
                  "column_format": "String",
                  "column_data": "PG MSc"
                },
                {
                  "table_data_id": "5a445d55-c6aa-40a0-b37e-637ff2f32210",
                  "column_name": "Remarks",
                  "column_format": "File",
                  "column_data": ""
                }
              ],
              [
                {
                  "table_data_id": "48db3a71-7394-4495-b7ac-245a3f7ce773",
                  "column_name": "S.no",
                  "column_format": "Number",
                  "column_data": "2"
                },
                {
                  "table_data_id": "12265b0e-2db3-476b-8154-a8c89839f6d3",
                  "column_name": "Contents",
                  "column_format": "String",
                  "column_data": "UG BSc"
                },
                {
                  "table_data_id": "5a445d55-c6aa-40a0-b37e-637ff2f32210",
                  "column_name": "Remarks",
                  "column_format": "File",
                  "column_data": ""
                }
              ]
            ]
          },
          {
            "section_id": "61faeee9-20f1-4d9e-a213-aacdb1105c76",
            "title": "Settings 2",
            "description": "settings 2 description",
            "file_link": "",
            "format": "List",
            "table_data": [],
            "list_data": {
              contents: "<p><strong><u>student information</u></strong></p><ul><li><p>1</p></li><li><p>2</p></li><li><p>3</p></li></ul><p></p>",
              header: "<p><strong>Student report</strong></p><ol><li><p>Teset</p></li><li><p>1</p></li><li><p>2</p></li><li><p>3</p></li></ol>"
            }
          },
        ];

        this.generateMappingForm(mapping_data);

      } catch (error) {
        console.error('Error loading Template settings:', error);
      }
    });
  } */

  generateMappingForm(map_data: MapDataInterface[]) {
    this.mapping_data = this.formBuilder.group({
      mapping_data: this.generateMappingArray(map_data),
    });
    console.log(this.mapping_data);
  }

  generateMappingArray(map_data: MapDataInterface[]) {
    let map_arr: FormArray = this.formBuilder.array([]);
    for (var i = 0; i < this.template_structure.length; i++) {
      let template_data = this.template_structure[i];
      const existing_data = map_data.find(x => x.section_id == template_data._id);
      let map_form_group = this.formBuilder.group({
        section_id: [template_data._id],
        title: [existing_data ? existing_data.title : template_data.title],
        description: [existing_data ? existing_data.description : template_data.description],
        format: [existing_data ? existing_data.format : template_data.format],
        file_link: [existing_data ? existing_data.file_link : template_data.file_link],
      });
      if (this.template_structure[i].format == 'List') {
        map_form_group.addControl('list_data', (this.generateListForm(existing_data ? existing_data.list_data : template_data.list_data)))
      }
      if (this.template_structure[i].format == 'Table') {
        map_form_group.addControl('table_data', (this.generateTableData(existing_data ? existing_data.table_data : [], template_data._id)),)
      }
      map_arr.push(map_form_group);
    }
    return map_arr;
  }

  generateListForm(list_data: any): FormGroup {
    let list_group: FormGroup = this.formBuilder.group({
      header: list_data ? list_data.header : '',
      contents: list_data ? list_data.contents : '',
    });
    return list_group
  }

  generateTableData(table_data: TableColumn[][], section_id: string): FormArray {
    let overall_table: FormArray = this.formBuilder.array([]); //outer return array
    let table_data_arr: FormGroup[] = []//inner arrays
    let table_strucutre = this.template_structure.find(x => x._id == section_id)?.table_data;
    if (table_data.length == 0) {
      //generate one row by default
      for (var j = 0; j < table_strucutre.length; j++) {
        let col = table_strucutre[j];
        table_data_arr.push(this.formBuilder.group({
          column_name: [col.column_name],
          column_format: [col.column_format],
          column_data: [''],
          table_data_id: [col.table_data_id],
        }));
      }
      overall_table.push(this.formBuilder.array(table_data_arr));
    }
    else {
      //create an array with existing rows
      for (var i = 0; i < table_data.length; i++) {
        table_data_arr = [];
        for (var j = 0; j < table_data[i].length; j++) {
          let col = table_data[i][j];
          table_data_arr.push(this.formBuilder.group({
            column_name: [col.column_name],
            column_format: [col.column_format],
            column_data: [col.column_data],
            table_data_id: [col.table_data_id],
          }));
        }
        overall_table.push(this.formBuilder.array(table_data_arr));
      }
    }
    console.log(overall_table);
    return overall_table;
  }

  getMappingArray() {
    return this.mapping_data.get('mapping_data') as FormArray;
  }

  getTableData(sectionIndex) {
    return ((this.mapping_data.get('mapping_data') as FormArray).controls[sectionIndex].get('table_data') as FormArray).controls as FormArray[]
  }

  getTableHeader(sectionIndex) { //returns FormGroups
    return (((this.mapping_data.get('mapping_data') as FormArray).controls[sectionIndex].get('table_data') as FormArray).controls[0] as FormArray)?.controls;
  }

  addTableRow(sectionIndex) {
    let table_data_arr: FormGroup[] = []//inner arrays
    let table_strucutre = this.template_structure[sectionIndex]?.table_data;
    //generate one row by default
    for (var j = 0; j < table_strucutre.length; j++) {
      let col = table_strucutre[j];
      table_data_arr.push(this.formBuilder.group({
        column_name: [col.column_name],
        column_format: [col.column_format],
        column_data: [''],
        table_data_id: [col.table_data_id],
      }));
    }
    ((this.mapping_data.get('mapping_data') as FormArray)
      .controls[sectionIndex].get('table_data') as FormArray).push(this.formBuilder.array(table_data_arr));
  }

  onDeleteRow(sectionIndex, rowArrIndex) {
    const consent = confirm("Are you sure want to remove this row? This action is irreversible!");
    if (!consent) {
      return;
    }
    ((this.mapping_data.get('mapping_data') as FormArray)
      .controls[sectionIndex].get('table_data') as FormArray).removeAt(rowArrIndex);
  }

  async uploadFile(e: any, i: number) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadReportFile(formData);
      if (response.success && response.data) {
        ((this.mapping_data.get('mapping_data') as FormArray)
          .controls[i].get('file_link')).setValue(response.data.url);
        alert("File Uploaded Successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  async uploadColFile(e: any, i: number, rowIndex, colIndex) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadReportFile(formData);
      if (response.success && response.data) {
        ((((this.mapping_data.get('mapping_data') as FormArray)
          .controls[i].get('table_data') as FormArray).controls[rowIndex] as FormArray)
          .controls[colIndex] as FormGroup).get('column_data').setValue(response.data.url)
        alert("File Uploaded Successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  getTableFormArray(rowArr: FormArray) {
    return rowArr.controls;
  }

  async saveMapping() {
    console.log(this.mapping_data.value.mapping_data);
    const mapping_data = this.mapping_data.value.mapping_data;
    const obj = {
      mapping_data: mapping_data,
      template_id: this.template_id,
      programe_template_id: this.programe_template_id,
    }
    try {
      if (this.update_id == '') {
        const template_response = await this.restService.createProgramTemplateMapping(obj);
        if (template_response.data) {
          this.getReportData();
          alert("Report data added successfully.");
        }
      }
      else {
        const template_response = await this.restService.updateProgramTemplateMapping(this.update_id, obj);
        if (template_response.data) {
          alert("Report data updated successfully.");
        }
      }
    } catch (error) {
      console.error('Error loading Template settings:', error);
    }
  }

  onBackClicks() {
    this.router.navigate(["/admin/reports/program-template"])
  }

  downloadPDF() {
    /* this.template_data.template_name = 'NAAC Report'
    this.template_data.academic_year = '2023-24' */
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    const mapping_data: MapDataInterface[] = this.mapping_data.value.mapping_data;
    let report_html = '';
    for (var i = 0; i < mapping_data.length; i++) {
      let section = mapping_data[i];
      //render section header
      report_html += `<div class='row' style="margin-top: 10px;">
        <div class='col-md-12'>
          <p><b>${section.title}</b></p>
          <p>${section.description}</p>
        </div>
      </div>
      `;
      if (section.format == 'List') {
        const list_data = section.list_data;
        const header_div = document.createElement('div');
        header_div.innerHTML = list_data.header;
        const contents_div = document.createElement('div');
        contents_div.innerHTML = list_data.contents
        report_html +=
          `<div class='row' style='margin-top: 5px;'>
            <div class='col-md-12'>
              ${header_div.innerHTML}
            </div>
          </div>
          <div class='row' style='margin-top: 5px;'>
            <div class='col-md-12'>
              ${contents_div.innerHTML}
            </div>
          </div>`;
      }
      if (section.format == 'Table') {
        const table_data = section.table_data;
        let table_str = '';
        table_str += `<table class='table table-bordered'>`;
        table_str += `<tr>`;
        for (var h = 0; h < table_data[0].length; h++) {
          table_str += `<th>${table_data[0][h].column_name}</th>`;
        }
        table_str += `</tr>`;
        for (var row = 0; row < table_data.length; row++) {
          table_str += `<tr>`;
          for (var col = 0; col < table_data[row]?.length; col++) {
            if (table_data[row][col]?.column_data) {
              table_str += `<td>${table_data[row][col]?.column_data}</td>`
            }
            else {
              table_str += `<td>--</td>`
            }

          }
          table_str += `</tr>`;
        }
        table_str += `</table>`
        report_html += table_str;
      }
    }

    console.log(report_html);
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    mywindow.document.write('</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px; margin-top: 10px;" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h5 style = "margin-top:10px">' + this.template_data.template_name + ' - ' + this.template_data.academic_year +
      ' </h5>' +
      '</div>' +
      '</div>');
    mywindow.document.write(report_html);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  downloadDocx() {
    const mapping_data: MapDataInterface[] = this.mapping_data.value.mapping_data;
    let report_html = '';
    for (var i = 0; i < mapping_data.length; i++) {
      let section = mapping_data[i];
      //render section header
      report_html += `<div class='row' style="margin-top: 10px;">
        <div class='col-md-12'>
          <p><b>${section.title}</b></p>
          <p>${section.description}</p>
        </div>
      </div>
      `;
      if (section.format == 'List') {
        const list_data = section.list_data;
        const header_div = document.createElement('div');
        header_div.innerHTML = list_data.header;
        const contents_div = document.createElement('div');
        contents_div.innerHTML = list_data.contents
        report_html +=
          `<div class='row' style='margin-top: 5px;'>
            <div class='col-md-12'>
              ${header_div.innerHTML}
            </div>
          </div>
          <div class='row' style='margin-top: 5px;'>
            <div class='col-md-12'>
              ${contents_div.innerHTML}
            </div>
          </div>`;
      }
      if (section.format == 'Table') {
        const table_data = section.table_data;
        let table_str = '';
        table_str += `<table class='table table-bordered'>`;
        table_str += `<tr>`;
        for (var h = 0; h < table_data[0].length; h++) {
          table_str += `<th>${table_data[0][h].column_name}</th>`;
        }
        table_str += `</tr>`;
        for (var row = 0; row < table_data.length; row++) {
          table_str += `<tr>`;
          for (var col = 0; col < table_data[row]?.length; col++) {
            if (table_data[row][col]?.column_data) {
              table_str += `<td>${table_data[row][col]?.column_data}</td>`
            }
            else {
              table_str += `<td>--</td>`
            }

          }
          table_str += `</tr>`;
        }
        table_str += `</table>`
        report_html += table_str;
      }
    }
    console.log(report_html);
    let html_content = `
      <html><head>
      <link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">
      <script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>
      <script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>
      <script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>
      </head>
      <body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">
      <div class="row" style = "margin-left:50px; margin-top: 10px;" id = "print-header" >
      <div class="col-md-2" >
      <img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;">
      </div>
      <div class= "col-md-8 text-center" >
      <h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE (Autonomous) </h4>
      <h4> Sivakasi - 626 124. </h4>
      <h5 style = "margin-top:10px">${this.template_data.template_name}  - ${this.template_data.academic_year} </h5>
    `;
    var el = document.createElement('div');
    el.innerHTML = report_html;
    html_content += el.innerText;
    html_content += `</body></html>`;
    var elHtml = html_content;
    var link = document.createElement('a');
    link.setAttribute('download', 'test-doc');
    link.setAttribute('href', 'data:' + 'text/docx' + ';charset=utf-8,' + encodeURIComponent(elHtml));
    link.click();
  }

}


interface templateInterface {
  _id?: string,
  section_id?: string,
  title: string,
  description?: string,
  format: string, //list, Group, Table
  list_data?: { header?: string, contents?: string }
  table_data?: TableColumn[],
  file_link?: string
}

interface TableColumn {
  column_name: string;
  column_format: string;
  column_data?: string;
  table_data_id?: string
}

interface MapDataInterface {
  _id?: string,
  section_id?: string,
  title: string,
  description?: string,
  format: string, //list, Group, Table
  list_data?: { header: string, contents: string }
  table_data?: TableColumn[][],
  file_link?: string
}





