import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { Modal } from 'src/app/components/ModalInterface';

@Component({
  selector: 'app-programme-time-settings',
  templateUrl: './programme-time-settings.component.html',
  styleUrls: ['./programme-time-settings.component.scss']
})
export class ProgrammeTimeSettingsComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Programme - Time Settings Mapping';
  mapping_list_loading: boolean = false;
  mapping_list: SettingInterface[] = [];
  setting: SettingInterface = {} as SettingInterface;
  programme_time_setting_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  enrolled_year: number = 0;
  category_list: any[] = [];
  category_list_loading = false;
  programme_search_form: any;
  programme_list: any[] = [];
  programme_list_loading = false;
  parts: any[] = [];
  part_course_types: any[] | undefined = [];
  condition_delete_loading: boolean[] = [];
  state = '';
  course_data_list: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  semesters: any[] = [];
  template_list: any[] = [];
  template_list_loading: boolean = false;
  save_loading: boolean = false;
  delete_loading: boolean = false;
  template_map_history_list: ProgrammeMapHistoryInterface[] = [];
  bulk_render_data: Modal = {
    return_type: "FILE",
    modal_id: "programme_time_mapping_common_modal",
    modal_button_text: "Programme-Time Settings Bulk Upload",
    modal_header: "Programme-Time Mapping Upload List",
    list_name: "Programme-Time Mapping",
    csv_data: {
      columns: [
        { column_name: "programme_type_id", column_type: "required" },
        { column_name: "finance_type", column_type: "required" },
        { column_name: "enrolled_year", column_type: "required" },
        { column_name: "semester", column_type: "required" },
        { column_name: "programme_id", column_type: "required" },
        { column_name: "programme_name", column_type: "required" },
        { column_name: "section", column_type: "required" },
        { column_name: "template_name", column_type: "required" },
      ],
      row_data: ""
    },
    download_note: "Please enter template name only for all programmes. Do not modify other cell values",
    table_data: {
      column_names: ["Programme Type ID", "Finance Type", "Enrolled Year", "Semester", "Programme ID", "Programme Name", "Section", "Template Name"],
      column_values: ["ug", "sf", "2020", "2", "-default-db-id", "B.C.A.", "A", "UG Template"]
    }
  };
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      itemsShowLimit: 20,
      allowSearchFilter: true
    };
    this.semesters = this.commonEnums.getSemesterList(6);
  }
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router) {
    this.getProgrammeTypeList();
    this.buildFilterForm();
    this.buildForm();
    this.getFinalizedTemplateList();
    //this.getMappingSettingsList();
  }

  async getFinalizedTemplateList() {
    this.template_list = [];
    this.template_list_loading = true;
    try {
      const service_response = await this.restService.listFinalizedTimetableTemplates();
      if (service_response && service_response.success) {
        this.template_list = service_response.data;
        console.log(this.template_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.template_list_loading = false;
  }

  getTemplateName(template_id: string) {
    return this.template_list.find(x => x._id == template_id)?.template_name;
  }
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  buildFilterForm(): void {
    this.programme_search_form = this.formBuilder.group({
      programme_type_id: [''],
      finance_type: [''],
      enrolled_year: [''],
      semester: [0]
    });
  }


  async getMappingSettingsList() {
    this.mapping_list = [];
    try {
      if (!this.programme_search_form.value.programme_type_id) {
        alert("Please choose Programme type"); return;
      }
      if (!this.programme_search_form.value.finance_type) {
        alert("Please choose Finance type"); return;
      }
      if (!this.programme_search_form.value.enrolled_year) {
        alert("Please choose Enrollment Year"); return;
      }
      if (this.programme_search_form.value.semester == 0) {
        alert("Please choose Semester"); return;
      }
      this.mapping_list_loading = true;
      const service_response = await this.restService.getProgrammeTemplateMapList(this.programme_search_form.value);
      if (service_response && service_response.success) {
        this.mapping_list = service_response.data;
        this.bulk_render_data.csv_data.row_data = "";
        this.mapping_list.forEach(pgm => {
          this.bulk_render_data.csv_data.row_data += pgm.programme_type_id + "," + pgm.finance_type + "," + pgm.enrolled_year + "," +
            + pgm.semester + "," + pgm.programme_id + "," + pgm.programme_name + "," + pgm.section_details.section_name + "\r\n";
        });
        console.log(this.mapping_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.mapping_list_loading = false;
  }


  buildForm(): void {
    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        programme_type_id: "",
        finance_type: "",
        template_id: "",
        programme_id: "",
        section_details: "",
        enrolled_year: 0,
        programme_name: "",
        semester: 0,
      };
    }
    this.programme_time_setting_form = this.formBuilder.group({
      _id: [this.setting._id],
      template_id: [this.setting.template_id],
      programme_type_id: [this.setting.programme_type_id],
      finance_type: [this.setting.finance_type],
      programme_id: [this.setting.programme_id],
      section: [this.setting.section_details.section_name],
      enrolled_year: [this.setting.enrolled_year],
      semester: [this.setting.semester],
    });
  }

  async openModal(setting) {
    this.setting = setting;
    if (setting._id) {
      this.onEditClick(setting);
      try {
        const service_response = await this.restService.listTemplateProgrammeMapHistory(setting._id);
        if (service_response && service_response.success) {
          this.template_map_history_list = service_response.data;
          console.log(this.template_map_history_list);
        }
      } catch (error) {
        alert('Error while read data');
      }
    }
    else {
      this.onAddClick();
    }
  }
  onAddClick() {
    this.buildForm();
    this.state = "ADD";
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    this.setting_id = setting._id; //term_name
    var settings = this.mapping_list.find(x => x._id == setting._id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.state = "";
    this.parts = [];
    this.part_course_types = [];
    this.template_map_history_list = [];
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as SettingInterface;
    this.programme_time_setting_form.reset();
    this.programme_time_setting_form.get('template_id').setValue("");
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.programme_time_setting_form.value;
      //form_data.start_date = new Date(this.programme_time_setting_form.value.start_date).toISOString()
      //form_data.end_date = new Date(this.programme_time_setting_form.value.end_date).toISOString()
      console.log(form_data);
      let save_respose;
      this.save_loading = true;
      if (!form_data._id) {
        save_respose = await this.restService.createTemplateProgrammeMap(this.programme_time_setting_form.value);
        if (save_respose.success) {
          alert('Time-Programme Map Created Successfully');
        }
        else {
          alert("Failed to create Time-Programme Map")
        }
      }
      else {
        save_respose = await this.restService.updateTemplateProgrammeMap(this.programme_time_setting_form.value._id,
          this.programme_time_setting_form.value);
        if (save_respose.success) {
          alert('Time-Programme Map Updated Successfully');
        }
        else {
          alert("Failed to update Time-Programme Map")
        }
      }
      this.getMappingSettingsList(); //refresh list 
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteTemplateProgrammeMap(this.programme_time_setting_form.get('_id')!.value);
      if (res.success) {
        alert("Deleted Template-Programme mapping successfully");
        this.getMappingSettingsList();
      }
      else {
        alert("Failed to delete Template-Programme mapping");
      }

      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }
  isInvalid(formControl: string): boolean {
    return this.programme_time_setting_form.touched && this.programme_time_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.programme_time_setting_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  async downloadBulkUploadFile(e) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_render_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.programmeTemplateBulkUpload(formData);
      if (response.success && response.data && response.data.url) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_data.show_loader = false;
    }
  }

  revertToTemplate(template: ProgrammeMapHistoryInterface) {
    const consent = confirm("Are you sure want to revert to this template for this programme?");
    if (!consent) return;
    this.programme_time_setting_form.get('template_id').setValue(template.template_id);
    this.onSaveAndClose();
  }

}

interface SettingInterface {
  _id: string,
  template_id: string,
  programme_type_id: string,
  finance_type: string,
  enrolled_year: number,
  semester: number,
  programme_id: string,
  programme_name?: string,
  section_details: any,
}

interface ProgrammeMapHistoryInterface {
  template_id: string,
  template_name: string,
  start_date: string,
  end_date: string
  //template_availability *
}