<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Departments</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Department</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Departments</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Departments</p>
                    </div>

                    <div class="card-body" *ngIf="department_search_form">
                        <form [formGroup]="department_search_form">
                            <div class="row row-sm">

                                <div class="col-lg-4">

                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Finance Type</label>
                                        <select formControlName="finance_type" class="form-control" (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">All Finance Type</option>
                                            <option *ngFor="let finance_type of finance_types;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Category</label>
                                        <select formControlName="department_category_id" class="form-control" (change)="onFilterSelectChange('department_category_id',$event)">
                                            <option value="">All Department Categories</option>
                                            <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="category.department_category_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Fin. Type</th>
                                        <th>Dpt. Cat.</th>
                                        <th>Dpt. Code</th>
                                        <th>Dpt. Name</th>
                                        <th>Short Name</th>
                                        <th>Last Updated At</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="department_list.length>0">


                                        <ng-container *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="text-uppercase">{{department.finance_type_display}}</td>
                                                <td class="text-uppercase">{{department.department_category_id}}</td>
                                                <td class="text-uppercase">{{department.department_code}}</td>
                                                <td class="text-uppercase">{{department.department_name}}</td>
                                                <td class="text-uppercase">{{department.short_name}}</td>
                                                <td>{{department.updatedAt | date}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEditClick(department)">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>

                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!department_list_loading && department_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="department_list_loading && department_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="department_form">
                    <div class="modal-header">
                        <h6 class="modal-title" *ngIf="!department._id">Add New department</h6>
                        <h6 class="modal-title" *ngIf="department._id">Update department</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="department_form">

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Category</label>
                                    <select formControlName="department_category_id" class="form-control">
                                        <option value="">Choose Department Category</option>
                                        <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="category.department_category_id">{{category.name}}</option>
                                    </select>

                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Finance Type</label>
                                    <select formControlName="finance_type" class="form-control">
                                        <option value="">Choose Department Finance Type</option>
                                        <option *ngFor="let finance_type of finance_types;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Department Code</label>
                                    <input class="form-control" required="" type="text" formControlName="department_code">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Department Name</label>
                                    <input class="form-control" required="" type="text" formControlName="department_name">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Short Name</label>
                                    <input class="form-control" required="" type="text" formControlName="short_name">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description</label>
                                    <textarea class="form-control" formControlName="description"></textarea>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Started Date</label>
                                    <input class="form-control" type="date" formControlName="started_date">
                                </div>

                                <div class="form-group justify-content-end">
                                    <div class="checkbox">
                                        <div class="custom-checkbox custom-control">
                                            <input type="checkbox" class="custom-control-input" id="discontinued" formControlName="discontinued">
                                            <label for="discontinued" class="custom-control-label mt-1">Is Discontined</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="fromDataResolver.getValueFromFromGroup(this.department_form,'discontinued') == true">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Discontined Date</label>
                                    <input class="form-control" type="date" formControlName="discontinued_date">
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveClick()" *ngIf="!department_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="department_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <ng-container *ngIf="department._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()" *ngIf="!department_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="department_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </ng-container>

                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>