<app-side-bar></app-side-bar>
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid">
    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>
    <!--Table View-->
    <div class="row">
      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">{{title}}</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Manage Fees Settings List</p>
          </div>

          <div class="card-body">
            <div class="row row-sm">
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                    Type</label>
                  <select [(ngModel)]="programme_type_id" class="form-control"
                    (change)="onFilterSelectChange('programme_type_id',$event)">
                    <option value="">Select Programme Type</option>
                    <option
                      *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="programme_type.programme_type_id">{{programme_type.name}}</option>
                  </select>
                </div>
              </div>

              <!-- <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Fees
                                        Type</label>
                                    <select [(ngModel)]="fees_type" class="form-control">
                                        <option value="">Select Fees Type</option>
                                        <option
                                            *ngFor="let fees_type of fees_types;let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="fees_type.type">{{fees_type.type | titlecase}}</option>
                                    </select>
                                </div>
                            </div> -->

              <div class="col-lg-4">
                <div class="form-group">
                  <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                    *ngIf="!settings_list_loading" (click)="onSearchClick()">
                    Get Settings
                  </button>
                  <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                    *ngIf="settings_list_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </div>
              </div>
            </div>

            <div class="d-flex right-content mg-b-5" style="float: right;">
              <button type="button" class="btn btn-info btn-md" (click)="onAddClick()" *ngIf="search_btn_pressed">Add
                New</button>
            </div>


            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th style="width: 10%;">S.No</th>
                    <th style="width: 50%;">Setting</th>
                    <th style="width: 40%;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="settings_list.length>0">
                    <ng-container *ngFor="let setting of settings_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}
                        </td>
                        <td>{{setting.term_name | titlecase}}</td>
                        <td>
                          <div class="pr-1 mb-3 mb-xl-0">
                            <button type="button" (click)="onEditClick(setting)"
                              class="btn btn-sm btn-info mr-2 mg-t-2">
                              Collection Settings
                            </button>
                            <!-- <button type="button" (click)="navigateToExamSettings(setting)"
                              class="btn btn-sm btn-info mr-2 mg-t-2">
                              Fees Settings
                            </button> -->
                            <app-modal [render_data]="getBulkRenderData(i)" (csvData)="bulkUpload($event)"
                              (click)="setId(setting)"></app-modal>
                            <button type="button" (click)="navigateToFeeMap(setting)"
                              class="btn btn-sm btn-info mr-2 mg-t-2">
                              View Student-Fee Map
                            </button>
                            <!-- <button type="button" (click)="hallset()" class="btn btn-sm btn-info mr-2">
                              Hall Ticket Settings
                            </button> -->
                          </div>
                        </td>

                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!settings_list_loading && settings_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="settings_list_loading && settings_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Collection Settings Large Modal -->
    <div class="modal" id="collection-modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="collection_form">
          <div class="modal-header">
            <h6 class="modal-title">Fees Collection Settings</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="collection_form" class="form-horizontal">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                        Date</label>
                      <input class="form-control" required="" type="date" formControlName="start_date">
                      <div *ngIf="isInvalid('start_date')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Start date is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                        Date</label>
                      <input class="form-control" required="" type="date" formControlName="end_date">
                      <div *ngIf="isInvalid('end_date')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">End date is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                        Month</label>
                      <select formControlName="month" class="form-control">
                        <option value="0">Choose Month</option>
                        <option *ngFor="let month of months;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="month.val">{{month.name}}</option>
                      </select>

                      <div *ngIf="isInvalid('month')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Month is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                        Year</label>
                      <select formControlName="year" class="form-control">
                        <option value="0">Select year</option>
                        <option
                          *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="year">{{year}}</option>
                      </select>
                      <div *ngIf="isInvalid('year')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Year is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Extra
                        Fee Charges</label>
                      <input type="text" class="form-control" formControlName="extra_charge_amount"
                        placeholder="Enter Extra Fees Charges">
                      <div *ngIf="isInvalid('extra_charge_amount')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Extra Fee Charges is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Fine Rule</label>
                      <select formControlName="fine_settings_id" class="form-control">
                        <option value=''>Select Fine Rule</option>
                        <option *ngFor="let fine of fine_list;let j=index;trackBy:fromDataResolver.identify;"
                          [value]="fine._id">
                          {{fine.fine_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                    Description</label>
                  <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                    formControlName="description">
                                </textarea>
                  <div *ngIf="isInvalid('description')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Description is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="form-group">
                  <input type="checkbox" formControlName="active" class="mg-r-5">Is Active?
                  <div *ngIf="isInvalid('active')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Choose if the term is active for payment or
                        not.</li>
                    </ul>
                  </div>
                </div>

                <div class="text-center">
                  <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                  <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()" [disabled]="canDisable()">Save &
                    Close</button>
                  <button class="btn btn-danger mg-r-20" (click)="onDelete()" *ngIf="setting_id"
                    [disabled]="canDisable()">Delete</button>
                  <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                  <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->


    <!--Settings Modal-->
    <div class="modal" id="setting-modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-header">
            <h6 class="modal-title">Hall Ticket Settings</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="Hallticket_form" class="form-horizontal">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Hall Ticket Title</label>
                      <input class="form-control" required="" type="text" formControlName="title">
                      <div *ngIf="isInvalid('title')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Title is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Note</label>
                      <textarea class="form-control" required="" type="text" formControlName="note"></textarea>
                      <div *ngIf="isInvalid('note')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Note is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Controller Signature File</label>
                      <input class="form-control" required="" type="file" formControlName="controller">
                      <div *ngIf="isInvalid('controller')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required"> Controller Signature File is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Principal Signature File</label>
                      <input class="form-control" required="" type="file" formControlName="principal">
                      <div *ngIf="isInvalid('principal')">
                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                          <li class="parsley-required">Principal Signature File is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Issue date</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="radio" class="mg-r-5" (click)="get_type($event)" value="true"
                        formControlName="current_date" name="current_date"><span class="mg-r-15"> Set Generation
                        Date</span>
                      <input type="radio" class="mg-r-5" value="set" (click)="get_type($event)"
                        name="current_date"><span>Set
                        Manually</span>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="datechange">
                  <div class="col-8">
                    <input type="date" class="form-control" formControlName="date_fixing">
                  </div>
                  <div *ngIf="isInvalid('date_fixing')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Issue Date is required.</li>
                    </ul>
                  </div>
                </div>
                <br>
                <div class="form-group">
                  <input type="checkbox" formControlName="isactive" class="mg-r-5">Is Active?
                  <div *ngIf="isInvalid('isactive')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Choose if the setting is active for hall ticket generation.</li>
                    </ul>
                  </div>
                </div>

                <div class="text-center">
                  <button class="btn btn-primary mg-r-20" (click)="onSave_hall()">Save</button>
                  <button class="btn btn-info mg-r-20" (click)="onSaveAndClose_hall()">Save &
                    Close</button>
                  <button class="btn btn-dark mg-r-20" (click)="reset_hall()">Reset</button>
                  <button class="btn btn-secondary mg-r-20" (click)="onCloseClick_hall()">Close</button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Modal-->
  </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>