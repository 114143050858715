<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">




        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Registered User List</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Admissions List</p> -->
                    </div>
                    <div class="card-body" *ngIf="user_search_form">
                        <form [formGroup]="user_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select regulation
                                            year</label>
                                        <select formControlName="regulation_year" class="form-control"
                                            (change)="onRegulationYearChange()">
                                            <!-- <option value="">Select regulation year</option> -->
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Admission</label>
                                        <select formControlName="admission_id" class="form-control"
                                            (change)="onAdmissionChange()">
                                            <option value="">All admission</option>
                                            <option
                                                *ngFor="let admission of admissions_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="admission._id">{{admission.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Application
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="application_no"
                                            placeholder="Enter Application No." (keydown)="searchEnterHit($event)">
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">AADHAR Card
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="aadhar_card_no"
                                            placeholder="Enter Aadhar Card No." (keydown)="searchEnterHit($event)">
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Mobile No.</label>
                                        <input type="text" class="form-control" formControlName="mobile_number"
                                            placeholder="Enter Mobile No." (keydown)="searchEnterHit($event)">
                                        <small>Searches in all 5 mobile numbers</small>
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Name</label>
                                        <input type="text" class="form-control" formControlName="name"
                                            placeholder="Enter Name" (keydown)="searchEnterHit($event)">
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Community</label>
                                        <select class="form-control" id="community_id" name="community_id"
                                            formControlName="community_id">
                                            <option value="">All Community</option>
                                            <option
                                                *ngFor="let community of commonEnums.community_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="community">{{community}}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Caste</label>
                                        <input type="text" class="form-control" formControlName="caste"
                                            placeholder="Enter Caste" (keydown)="searchEnterHit($event)">
                                    </div>

                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Gender</label>
                                        <select class="form-control" id="gender" name="gender" formControlName="gender">
                                            <option value="">All Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="transgender">Transgender</option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-2" *ngIf="!user_search_form.value.other_states">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            State</label>
                                        <select class="form-control " id="state" name="state" formControlName="state">
                                            <option value="">Choose State</option>
                                            <option
                                                *ngFor="let india_state of commonEnums.india_states;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="india_state">
                                                {{india_state}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group mg-t-25">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="other_states"
                                                (change)="otherStateClicked($event)" formControlName="other_states">
                                            <label class="custom-control-label" for="other_states">Show Only Other
                                                States</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group mg-t-25">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="only_applied"
                                                formControlName="only_applied">
                                            <label class="custom-control-label" for="only_applied">Show Only
                                                Applied</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group mg-t-25">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="only_enrolled"
                                                formControlName="only_enrolled">
                                            <label class="custom-control-label" for="only_enrolled">Show Only
                                                Enrolled</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Filter(s)</label>
                                        <select multiple formControlName="filter_list" class="form-control">
                                            <option value="">All</option>
                                            <option value="physically_challanged">Physically disabled</option>
                                            <option value="ex_service">Ex service</option>
                                            <option value="is_applicant_married">Applicant Married</option>
                                            <option value="need_hostel">Need Hostel</option>
                                            <option value="need_bus">Need Bus</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!registered_users_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="registered_users_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                        <button type="button" class="mg-t-30 btn mr-2 search-button" title="Search"
                                            *ngIf="!registered_users_loading" (click)="onClearSearch()">
                                            Clear Search
                                        </button>




                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Reg. On</th>
                                        <th>AADHAR Card No.</th>
                                        <th>Reg. No</th>
                                        <th>Birth date</th>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Mobile Number</th>
                                        <th>State</th>

                                        <!-- <th>Community</th>
                                        <th>Caste</th>
                                        <th>Religion</th> -->
                                        <th>Applied Count</th>
                                        <th>Applied</th>
                                        <th>Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="registered_users.length>0">


                                        <ng-container
                                            *ngFor="let registered_user of registered_users;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td>{{registered_user.createdAt|date:commonEnums.date_time_format}}</td>
                                                <td>{{registered_user.aadhar_card_no}}</td>
                                                <td>{{registered_user.exam_reg_no}}</td>
                                                <td>{{registered_user.birth_date|date:commonEnums.date_format}}</td>
                                                <td class="uppercase">{{registered_user.name}}</td>
                                                <td class="uppercase">{{registered_user.gender}}</td>
                                                <td class="uppercase">


                                                    {{registered_user.mobile_number}} ,
                                                    <ng-container *ngIf="registered_user.mobile_number_2">
                                                        {{registered_user.mobile_number_2}} ,
                                                    </ng-container>
                                                    <ng-container *ngIf="registered_user.mobile_number_3">
                                                        {{registered_user.mobile_number_3}} ,
                                                    </ng-container>
                                                    <ng-container *ngIf="registered_user.mobile_number_4">
                                                        {{registered_user.mobile_number_4}} ,
                                                    </ng-container>
                                                    <ng-container *ngIf="registered_user.mobile_number_5">
                                                        {{registered_user.mobile_number_5}}
                                                    </ng-container>

                                                </td>
                                                <td>
                                                    {{registered_user.state}}
                                                </td>

                                                <!-- <td class="uppercase">{{registered_user.community_id}}</td>
                                                <td class="uppercase">{{registered_user.caste}}</td>
                                                <td class="uppercase">{{registered_user.religion}}</td> -->
                                                <td>{{(registered_user.applied?registered_user.applied.length:0)|number}}
                                                </td>
                                                <td>
                                                    <ng-container
                                                        *ngIf="registered_user.applied && registered_user.applied.length>0">
                                                        <ng-container
                                                            *ngFor="let applied of registered_user.applied;let i=index;trackBy:fromDataResolver.identify;">
                                                            Applied Time : {{applied.createdAt
                                                            |date:commonEnums.date_time_format}} <br>
                                                            Application No : {{applied.application_no}} <br>
                                                            Prg. Type : {{applied.programme_type_id}} <br>
                                                            Prg. : {{applied.programme_name}}
                                                            {{applied.finance_type=="self_finance"?' (SF)':' (R)'}} <br>
                                                            Status : {{applied.status.toUpperCase()}} <br>
                                                            App. Payment Id. : {{applied.payment_id}} <br>
                                                            <ng-container *ngIf="applied.order_id">
                                                                App. Order Id. : {{applied.order_id}} <br>
                                                            </ng-container>
                                                            <ng-container *ngIf="applied.programme_payment_id">
                                                                Prg. Payment Id.: {{applied.programme_payment_id}} <br>
                                                            </ng-container>
                                                            <ng-container *ngIf="applied.programme_order_id">
                                                                Prg. Order Id. : {{applied.programme_order_id}} <br>
                                                            </ng-container>
                                                            <hr>
                                                        </ng-container>
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-info"
                                                        (click)="onEditClick(registered_user._id)">Edit</button>
                                                </td>


                                            </tr>
                                        </ng-container>



                                    </ng-container>
                                    <ng-container *ngIf="!registered_users_loading && registered_users.length==0">
                                        <tr>
                                            <th colspan="12" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="registered_users_loading && registered_users.length==0">
                                        <tr>
                                            <th colspan="12" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>