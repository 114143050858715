import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-manage-hostel-students',
  templateUrl: './manage-hostel-students.component.html',
  styleUrls: ['./manage-hostel-students.component.scss']
})
export class ManageHostelStudentsComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }

  ngOnInit(): void {
  }

  
  url : string = "";
  
  getURL(){
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/hostel-v2/manage-students-6443e0509fce2c3c1ac7ed32?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
