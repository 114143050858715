import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-entrance-master',
  templateUrl: './entrance-master.component.html',
  styleUrls: ['./entrance-master.component.scss']
})
export class EntranceMasterComponent implements OnInit {



  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  cookieStore = CookieStore;

  // Master data
  admission_id = '';
  regulation_year = new Date().getFullYear();

  admissions_list: any[] = [];
  admissions_list_loading = false;
  admission_search_form: any;



  programme_list: any[] = [];
  programme_list_loading = false;


  // Core data
  entrance_exam_list: any[] = [];
  entrance_exam_list_loading = false;

  entrance_exam_form: any;
  entrance_exam_form_loading = false;
  entrance_exam: any;


  entrance_exam_patch_form: any;
  entrance_exam_patch_form_loading = false;
  entrance_exam_patch: any;

  // Question category
  programme_master_list: any[] = [];
  programme_master_list_loading = false;


  question_cat_form_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
      this.regulation_year = Number(data.regulation_year);

    });
  }

  ngOnInit(): void {
    this.buildAdmissionSearch();
  }

  buildAdmissionSearch(): void {
    this.admission_search_form = this.fb.group({
      admission_id: [this.admission_id],
      regulation_year: [this.regulation_year],
      finance_type: [''],
    });
    this.getCoreDatas();
    this.findEntranceExamList();
  }

  async onRegulationYearChange(): Promise<void> {
    this.admission_search_form.get('admission_id').setValue('');
    this.admission_search_form.get('finance_type').setValue('');
    this.getCoreDatas();
  }

  async onAdmissionChange(): Promise<void> {
    this.findEntranceExamList();
    this.getProgrammes();
  }


  async getProgrammes(): Promise<void> {
    try {
      if (!this.admission_search_form.value.admission_id) {
        return;
      }

      this.programme_list_loading = true;
      this.programme_list = [];
      const response = await this.restService.getProgrammesNeedsEntrance(this.admission_search_form.value.admission_id);
      if (response.success && response.data && response.data.length > 0) {
        this.programme_list = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }

  async getCoreDatas(): Promise<void> {
    try {
      const query = {
        year: this.admission_search_form.value.regulation_year
      };
      this.admissions_list = [];
      this.admissions_list_loading = true;
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
      }
      this.getProgrammes();
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  async findEntranceExamList(): Promise<void> {
    try {
      this.entrance_exam_list_loading = true;
      this.entrance_exam_list = [];
      const query = {
        admission_id: this.admission_search_form.value.admission_id
      };
      const response = await this.restService.findEntranceExamList(query);
      if (response.success && response.data) {
        this.entrance_exam_list = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.entrance_exam_list_loading = false;
    }
  }

  async onAddExamClick(): Promise<void> {
    this.buildExamForm();
    JQueryHelper.openModal('#modal-exam', { keyboard: false, backdrop: 'static' });
  }

  async onEditExamClick(entrance_exam: any): Promise<void> {
    this.entrance_exam = entrance_exam;
    this.buildExamForm();
    JQueryHelper.openModal('#modal-exam', { keyboard: false, backdrop: 'static' });
  }

  buildExamForm(): void {
    if (!this.entrance_exam) {
      this.entrance_exam = {
        _id: '',
        admission_id: this.admission_search_form.value.admission_id,
        name: '',
        programme_ids: [],
        active: true,
        description: ''
      };
    }
    this.entrance_exam_form = this.fb.group({
      _id: [this.entrance_exam._id],
      admission_id: [this.admission_search_form.value.admission_id],
      name: [this.entrance_exam.name],
      programme_ids: [this.entrance_exam.programme_ids],
      active: [this.entrance_exam.active],
      description: [this.entrance_exam.description]
    });
  }

  resetExamForm(): void {
    this.entrance_exam_form = null;
    this.entrance_exam = null;
  }

  async onExamCloseClick(): Promise<void> {
    this.resetExamForm();
    JQueryHelper.closeModal('#modal-exam');
  }

  async onExamSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure ?');
      if (!consent) {
        return;
      }
      this.entrance_exam_form_loading = true;
      const value_to_send = this.entrance_exam_form.value;
      if (!value_to_send.name) {
        alert('Please enter name');
        return;
      }
      if (!value_to_send.programme_ids || value_to_send.programme_ids.length === 0) {
        alert('Please select programmes');
        return;
      }
      const response = await this.restService.createOrUpdateEntranceExam(value_to_send);
      if (response.success) {
        alert('Saved successfully');
        await this.findEntranceExamList();
        this.onExamCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_form_loading = false;
    }
  }

  //#region patch
  async onViewPatchClick(entrance_exam: any): Promise<void> {
    this.entrance_exam = entrance_exam;
    for (const entrance_exam_data of this.entrance_exam_list) {
      entrance_exam_data.show_patch = false;
    }
    entrance_exam.show_patch = true;
  }
  async onAddPatchClick(entrance_exam: any): Promise<void> {
    this.entrance_exam = entrance_exam;
    this.buildExamPatchForm();
    JQueryHelper.openModal('#modal-patch', { keyboard: false, backdrop: 'static' });
  }

  async onEditPatchClick(entrance_exam: any, entrance_exam_patch: any): Promise<void> {
    this.entrance_exam_patch = entrance_exam_patch;
    this.entrance_exam = entrance_exam;
    this.buildExamPatchForm();
    JQueryHelper.openModal('#modal-patch', { keyboard: false, backdrop: 'static' });
  }

  buildExamPatchForm(): void {
    if (!this.entrance_exam_patch) {
      this.entrance_exam_patch = {
        _id: '',
        admission_id: this.admission_id,
        exam_id: this.entrance_exam._id,
        start_time: new Date(),
        duration: 0,
        active: true,
        description: '',
        no_of_questions: 0,
        allow_applicant_reg_until: new Date(),
        programme_ids: [],
        name: ''
      };
    }
    this.entrance_exam_patch_form = this.fb.group({
      _id: [this.entrance_exam_patch._id],
      admission_id: [this.admission_id],
      exam_id: [this.entrance_exam_patch.exam_id],
      start_time: [DateHelper.convertToControlDateTime(this.entrance_exam_patch.start_time)],
      duration: [this.entrance_exam_patch.duration],
      no_of_questions: [this.entrance_exam_patch.no_of_questions],
      allow_applicant_reg_until: [DateHelper.convertToControlDateTime(this.entrance_exam_patch.allow_applicant_reg_until)],
      active: [this.entrance_exam_patch.active],
      description: [this.entrance_exam_patch.description],
      programme_ids: [this.entrance_exam_patch.programme_ids],
      name: [this.entrance_exam_patch.name],
    });
  }

  resetExamPatchForm(): void {
    this.entrance_exam_patch_form = null;
    this.entrance_exam_patch = null;
  }

  async onExamPatchCloseClick(): Promise<void> {
    this.resetExamPatchForm();
    JQueryHelper.closeModal('#modal-patch');
  }


  private validateDate(val: string): boolean {
    if (!val) {
      return false;
    }
    try {
      const datae = new Date(val);
    } catch (error) {
      return false;
    }
    return true;
  }

  async onExamPatchSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure ?');
      if (!consent) {
        return;
      }
      this.entrance_exam_patch_form_loading = true;
      const value_to_send = this.entrance_exam_patch_form.value;
      if (!value_to_send.start_time) {
        alert('Please select start time');
        return;
      }
      if (!this.validateDate(value_to_send.start_time)) {
        alert('Please select valid start time');
        return;
      }
      value_to_send.start_time = new Date(value_to_send.start_time);
      if (!value_to_send.duration) {
        alert('Please enter duration');
        return;
      }
      if (!value_to_send.no_of_questions) {
        alert('Please enter no. of questions');
        return;
      }
      if (!this.validateDate(value_to_send.allow_applicant_reg_until)) {
        alert('Please select valid allow until time');
        return;
      }
      value_to_send.allow_applicant_reg_until = new Date(value_to_send.allow_applicant_reg_until);
      const response = await this.restService.createOrUpdateEntranceExamPatch(value_to_send);
      if (response.success) {
        alert('Saved successfully');
        await this.findEntranceExamList();
        this.onExamPatchCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_patch_form_loading = false;
    }
  }

  //#endregion

  onViewQuestonsClick(exam: any): void {
    this.router.navigate(['/admin/admissions/entrance/entrance-question'], {
      queryParams:
      {
        admission_id: this.admission_id,
        exam_id: exam._id
      }
    });
  }

  async onViewExamClick(entrance_exam: any, entrance_exam_patch: any): Promise<void> {
    this.entrance_exam_patch = entrance_exam_patch;
    this.entrance_exam = entrance_exam;

    this.router.navigate([`/admin/admissions/entrance/entrance-examhall/${this.entrance_exam.admission_id}/${this.entrance_exam._id}/${this.entrance_exam_patch._id}`], {
      queryParams: {
        exam_name: entrance_exam.name,
        closed: entrance_exam_patch.closed,
      }
    });
  }


  // question category settings
  async onQuestionCatClick(): Promise<void> {
    this.findPrgCatRuleList();
    JQueryHelper.openModal('#modal-question-cat', { keyboard: false, backdrop: 'static' });
  }

  async findPrgCatRuleList(): Promise<void> {
    try {
      const admission = this.admissions_list.find(c => c._id === this.admission_search_form.value.admission_id);

      this.programme_master_list_loading = true;
      this.programme_master_list = [];
      const response = await this.restService.findPrgCatRuleList(admission.programme_type_id,
        this.admission_search_form.value.admission_id);
      if (response.success && response.data && response.data.length > 0) {
        this.programme_master_list = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_master_list_loading = false;
    }
  }

  onViewQuestonsCatClick(programme_master: any): void {
    if (programme_master.show_details) {
      programme_master.show_details = false;
      programme_master.category_form = null;
      return;
    }
    for (const programme_master_data of this.programme_master_list) {
      programme_master_data.show_details = false;
      programme_master_data.category_form = null;
    }
    programme_master.show_details = true;
    programme_master.category_form = this.fb.group({
      categories: this.fb.array(this.buildCategoryForm(programme_master.categories))
    });
  }

  buildCategoryForm(data: any[]): any[] {
    const data_to_return = [];
    for (const data_item of data) {
      data_to_return.push(this.fb.group({
        category_id: [data_item.category_id],
        count: [data_item.count],
      }));
    }
    return data_to_return;
  }

  async onQuestionCatCloseClick(): Promise<void> {
    for (const programme_master_data of this.programme_master_list) {
      programme_master_data.show_details = false;
      programme_master_data.category_form = null;
    }
    JQueryHelper.closeModal('#modal-question-cat');
  }

  async onQuestionExamSaveClick(programe: any): Promise<void> {
    try {
      const consent = confirm('Are you sure ?');
      if (!consent) {
        return;
      }
      this.question_cat_form_loading = true;
      const category_form_data = programe.category_form.value;

      const category_list = [];
      for (const category of category_form_data.categories) {
        if (category.count > 0) {
          category_list.push(category);
        }
      }

      const value_to_send = {
        _id: programe.prg_cat_id,
        admission_id: this.admission_search_form.value.admission_id,
        programme_id: programe.programme_id,
        categories: category_list
      };
      const response = await this.restService.createOrUpdatePrgCatRule(value_to_send);
      if (response.success) {
        alert('Saved successfully');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.question_cat_form_loading = false;
    }
  }


}
