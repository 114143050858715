<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid ">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Hostel Management</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
      <div class="d-flex my-xl-auto right-content">
        <div class="dropdown">
          <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-primary" data-toggle="dropdown"
            id="dropDownMenuButton" type="button">Update<i class="fas fa-caret-down ml-1"></i></button>
          <div class="dropdown-menu tx-13">
            <a class="dropdown-item" (click)="goToIncharges()">Incharges</a>
            <a class="dropdown-item" (click)="goToFloors()">Floors</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Create hostel -->
    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="card box-shadow-0">
          <div class="card-header">
            <h4 class="card-title mb-1">Update Hostel Details</h4>
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="hostelForm" class="form-horizontal">
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Hostel Name</label>
                <input class="form-control" required="" type="text" formControlName="name">
                <div *ngIf="isNameInvalid()">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Hostel name is required.</li>
                  </ul>
                </div>

              </div>
              <div class="text-align-right">

                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canSave()">Save</button>
                <button class="btn ripple btn-secondary" (click)="onClose()" type="button">Close</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
