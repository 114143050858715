<app-side-bar></app-side-bar>

    <div class="main-content app-content">

      <app-combo-bar></app-combo-bar>
      <app-notification></app-notification>
  
      <!-- container -->
      <div class="container-fluid">
  
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
              <div class="left-content">
                  <div>
                      <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                      <p class="mg-b-0"></p>
                  </div>
              </div>
              <div class="d-flex my-xl-auto right-content">
                  <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-info" (click)="onAddClick()">Add Expense</button>
                  </div>
              </div>
          </div>
  
  
  
          <div class="row">
  
  
              <div class="col-xl-12">
                  <div class="card mg-b-20">
                      <div class="card-header pb-0">
                          <div class="d-flex justify-content-between">
                              <h4 class="card-title mg-b-0">{{title}}</h4>
  
                          </div>
                          <p class="tx-12 tx-gray-500 mb-2">Create, Update Stock Purchases</p>
                      </div>
  
                      <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                      <th>
                                        <span>S.No</span>
                                      </th>
                                      <th>
                                        <span>Date</span>
                                      </th>
                                      <th>
                                        <span>Bill No.</span>
                                      </th>
                                      <th>
                                        <span>Expense</span>
                                      </th>
                                      <th>
                                        <span>Amount</span>
                                      </th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="expenses.length>0">
                    
                    
                                        <ng-container *ngFor="let expense of expenses; let i = index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{expense.bill_date | date}}</td>
                                                <td>{{expense.bill_no}}</td>
                                                <td>{{expense.expense_name}}</td>
                                                <td>{{expense.total_price}}</td>
                                                
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                    
                                                        </button>
                                                    </div>
                                                </td>
                    
                                            </tr>
                                        </ng-container>
                    
                                    </ng-container>
                    
                                    <ng-container *ngIf="!stock_list_loading && expenses.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="stock_list_loading && expenses.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                    
                                </tbody>
                            </table>
                        </div>
                    </div> 
                  </div>
              </div>
  
  
          </div>
  
  
          <!-- Large Modal -->
          <div class="modal" id="modal-popup">
              <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content modal-content-demo" *ngIf="otherExpenseForm">
                      <div class="modal-header">
                          <h6 class="modal-title">Add Other Expenses</h6>
                          <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->
  
                      </div>
                      <div class="modal-body">
                          <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="otherExpenseForm" class="form-horizontal">


                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Bill Date</label>
                                <input class="form-control" required="" type="date" formControlName="bill_date">
                                <div *ngIf="isInvalid('bill_date')">
                                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Date is required.</li>
                                  </ul>
                                </div>
                              </div>
                            
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Bill No.</label>
                                <input class="form-control" required="" type="text" 
                                placeholder="Enter Bill No."
                                formControlName="bill_no">
                                <div *ngIf="isInvalid('bill_no')">
                                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Bill No. is required.</li>
                                  </ul>
                                </div>
                              </div>
                            
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Expense Name.</label>
                                <input class="form-control" required="" type="text" 
                                placeholder="Enter Expense Name."
                                formControlName="expense_name">
                                <div *ngIf="isInvalid('expense_name')">
                                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Expense Name is required.</li>
                                  </ul>
                                </div>
                              </div>
                            
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Expense Description.</label>
                                <textarea class="form-control" 
                                placeholder="Enter Expense Description."
                                formControlName="expense_description"></textarea>
                              </div>
                            
                              
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Expense Amount</label>
                                <input class="form-control" required="" type="text" formControlName="total_price" placeholder="Expense Amount">
                              </div>
                            
                              <div class="add-btn-row">
                                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()" [disabled]="canDisable()">Add & Close</button>
                                <button class="btn ripple btn-danger mg-r-20" (click)="onReset()" type="button">Reset</button>
                                <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                              </div>
                            </form>
                          </div>
                      </div>
  
                  </div>
              </div>
          </div>
          <!--End Large Modal -->
  
      </div>
  </div>

  <!-- /main-content -->
<app-footer></app-footer>
