import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;

  // Master data
  admission_id = "";
  regulation_year = new Date().getFullYear();
  finance_type = "";
  programme_id = "";

  admissions_list = [];
  admissions_list_loading = false;

  programme_list = [];
  all_programme_list: any[] = [];

  // Core data
  admission_search_form: any;

  applicant_list: any[] = [];
  applicant_list_loading = false;
  settlement_status: any[] = [{ id: true, name: "Settled" }, { id: false, name: "Not Settled" }];
  gateway_processed_status: any[] = [{ id: true, name: "Processed" }, { id: false, name: "Not Processed" }];
  split_status: any[] = [{ id: true, name: "Split" }, { id: false, name: "Not Split" }];
  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];
  excess_paid_list: any[] = [];
  excess_paid_list_loading: boolean = false;
  dropdownSettings: IDropdownSettings = {};
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      if (JSON.stringify(data) != '{}') {
        this.admission_id = data.admission_id;
        this.regulation_year = Number(data.regulation_year);
        this.finance_type = data.finance_type;
        this.programme_id = data.programme_id;
      }
    });

    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    this.buildAdmissionSearch();
    this.getAllProgrammeDataList();
  }

  buildAdmissionSearch(): void {
    this.admission_search_form = this.fb.group({
      admission_id: [this.admission_id],
      regulation_year: [this.regulation_year],
      finance_type: [this.finance_type],
      programme_id: [this.programme_id],
      fee_type: ['application'],
      //apply_date: [false],
      start_date: [DateHelper.convertToControlDate(new Date())],
      end_date: [DateHelper.convertToControlDate(new Date())],

      gender: [''],
      community_id: [''],
      settlement_status: ['d'],
      settlement_start_date: [DateHelper.convertToControlDate(new Date())],
      settlement_end_date: [DateHelper.convertToControlDate(new Date())],
      gateway_processed: ['d'],
      gateway_split_processed: ['d']

    });
    this.getCoreDatas();

  }

  async onRegulationYearChange(): Promise<void> {
    this.admission_search_form.get('admission_id').setValue('');
    this.admission_search_form.get('finance_type').setValue('');
    this.getCoreDatas();
    this.applicant_list = [];
  }

  async onAdmissionChange(): Promise<void> {
    this.admission_search_form.get('finance_type').setValue('');
    this.admission_search_form.get('programme_id').setValue('');
    this.programme_list = [];
    this.applicant_list = [];
  }

  async getCoreDatas(): Promise<void> {
    try {
      const query = {
        year: this.admission_search_form.value.regulation_year
      };
      this.admissions_list = [];
      this.admissions_list_loading = true;
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {

        this.admissions_list = service_response.data;
      }
      this.getProgrammeDataList();
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }
  async onFinanceTypeChange(): Promise<void> {
    this.admission_search_form.get('programme_id').setValue('');
    this.getProgrammeDataList();
    this.applicant_list = [];
  }
  async onFeeTypeChange(): Promise<void> {
    this.applicant_list = [];
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const admission: any = this.admissions_list.find((c: any) => c._id === this.admission_search_form.value.admission_id);
      if (!admission) {
        return;
      }
      const search_form_query: any = {
        finance_type: this.admission_search_form.value.finance_type,
        programme_type_id: admission.programme_type_id
      };

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }

    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
      };

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }

    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }

  async onSearchClick(): Promise<void> {
    this.getApplicantList();
  }

  async onClearSearch(): Promise<void> {
    this.applicant_list = [];
  }

  async getApplicantList(): Promise<void> {
    try {
      const search_form_query: any = {
        regulation_year: this.admission_search_form.value.regulation_year,
        fee_type: this.admission_search_form.value.fee_type
      };

      if (this.admission_search_form.value.admission_id) {
        search_form_query.admission_id = this.admission_search_form.value.admission_id;
      }
      if (this.admission_search_form.value.programme_id) {
        search_form_query.programme_id = this.admission_search_form.value.programme_id;
      }
      if (this.admission_search_form.value.finance_type) {
        search_form_query.finance_type = this.admission_search_form.value.finance_type;
      }

      if (this.admission_search_form.value.gender) {
        search_form_query.gender = this.admission_search_form.value.gender;
      }

      if (this.admission_search_form.value.community_id) {
        search_form_query.community_id = this.admission_search_form.value.community_id;
      }

      /* if (this.admission_search_form.value.apply_date) { */
      if (this.admission_search_form.value.start_date) {
        const start_date = DateHelper.getStartDate(this.admission_search_form.value.start_date);
        if (!start_date) {
          alert('Please select valid start date');
          return;
        }
        search_form_query.start_date = start_date;
      }

      if (this.admission_search_form.value.end_date) {
        const end_date = DateHelper.getEndDate(this.admission_search_form.value.end_date);
        if (!end_date) {
          alert('Please select valid end date');
          return;
        }
        search_form_query.end_date = end_date;
      }
      //}


      if (this.admission_search_form.value.settlement_status != 'd' && this.admission_search_form.value.settlement_status == true) {
        this.admission_search_form.value.settlement_status = true;
      }

      if (this.admission_search_form.value.settlement_start_date) {
        this.admission_search_form.value.settlement_start_date = new Date(this.admission_search_form.value.settlement_start_date).toISOString();
      }
      if (this.admission_search_form.value.split_end_date) {
        this.admission_search_form.value.settlement_end_date = new Date(this.admission_search_form.value.settlement_end_date).toISOString()
      }
      if (this.admission_search_form.value.gateway_processed != 'd' && this.admission_search_form.value.gateway_processed == true) {
        this.admission_search_form.value.gateway_processed = true;
      }
      if (this.admission_search_form.value.gateway_split_processed != 'd' && this.admission_search_form.value.gateway_split_processed == false) {
        this.admission_search_form.value.gateway_split_processed = false;
      }
      this.applicant_list = [];
      this.applicant_list_loading = true;
      const service_response = await this.restService.getApplicationsByFilter(search_form_query);
      if (service_response && service_response.success) {
        this.applicant_list = service_response.data;
      }

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.applicant_list_loading = false;
    }
  }


  navigateToTermWise() {
    window.open("/admin/accounts/admission-collection-report", "_blank");
  }

  async viewMultiplePayments() {
    if (!this.admission_search_form.value.admission_id) {
      alert("Please choose an admission"); return;
    }
    const admission_data = {
      admission_id: this.admission_search_form.value.admission_id
    }
    try {
      this.excess_paid_list_loading = true;
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
      const service_response = await this.restService.getExcessApplicationPayments(admission_data);
      if (service_response && service_response.success) {
        this.excess_paid_list = service_response.data;
        console.log(this.excess_paid_list);
      }
    }
    catch (error) {
      alert('Error while read data');
    }
    finally {
      this.excess_paid_list_loading = false;
    }

  }
  async onCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-popup');
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  downloadCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const application of this.applicant_list) {
      data_list.push({
        "S.No": i,
        "Paid Date": application?.payment_init_date?.substring(0, 10),
        "Programme": application.programme_name + (application.finance_type == 'self_finance' ? ' (SF)' : ' (R)'),
        "App No.": application.application_no,
        "Name": application.name,
        "Gender": application.gender,
        "Mobile No.": application.mobile_number,
        "Paid Amount": application.amount,
        "Remarks": application.remarks
      });
      i++;
    }
    CSVHelper.downloadCSV(data_list, "Admission_Fee_Report_" + DateHelper.convertToControlDate(new Date()));
  }

  downloadMeritCSV() {
    const data_list: any[] = [];
    var sorted_applicant_list: any[] = this.applicant_list;
    sorted_applicant_list.sort((a, b) => (a.total_marks < b.total_marks) ? 1 : -1);
    let i = 1;
    for (const application of sorted_applicant_list) {
      data_list.push({
        "S.No": i,
        "Programme": application.programme_name + (application.finance_type == 'self_finance' ? ' (SF)' : ' (R)'),
        "App No.": application.application_no,
        "Name": application.name,
        "Gender": application.gender,
        "Mobile No.": application.mobile_number,
        "Total Marks": application.total_marks,
        "Status": (application.status as string).split("_").join(" ").toUpperCase(),
        "Remarks": application.remarks
      });
      i++;
    }
    CSVHelper.downloadCSV(data_list, "Application_Merit_Report_" + DateHelper.convertToControlDate(new Date()));
  }


  printMeritList() {
    const list_name = prompt('Enter List Name.');
    const data_to_download: any[] = this.getMeritListData();
    let programme_name = this.getProgrammeName(this.applicant_list[0]?.programme_id);
    console.log(data_to_download);
    let current_date = DateHelper.toDisplayDate(new Date());
    var table_string: string = '';
    table_string +=
      '<table class="table table-bordered" style="border:2px solid black"><thead><tr>';
    //for (var i = 0; i < data_to_download.length; i++) {
    for (const [key, value] of Object.entries(data_to_download[0])) {
      table_string += '<th style="border: 2px solid black">' + key + '</th>';
    }
    //}
    table_string += '</tr></thead><tbody>';
    for (var i = 0; i < data_to_download.length; i++) {
      table_string += "<tr style='border: 2px solid black'>";
      for (const [key, value] of Object.entries(data_to_download[i])) {
        table_string += '<td style="border: 2px solid black">' + value + '</td>';
      }
      table_string += "</tr>";
    }
    table_string += '</tbody></table>';
    console.log(table_string);
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A3 landscape;} @media print { html, body { margin-right:3%; margin-left:2%; margin-top:2%; margin-bottom:3%}}</style>"
    );
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h4>Admission - ' + this.regulation_year + '</h4>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h4 class= "text-center"><b> ' + list_name + ' List - ' + programme_name + '</b></h4>');
    mywindow.document.write('<h4 style= "float:right"><b>' + current_date + '</b></h4>');
    mywindow.document.write(table_string);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  getMeritListData() {
    var data_to_download: any[] = [];
    const programme_data = this.programme_list.find(x => x.programme_id == this.applicant_list[0].programme_id);
    let applicant_list = this.applicant_list.sort((a, b) => (a.total_marks < b.total_marks) ? 1 : -1);
    for (let index = 0; index < applicant_list.length; index++) {
      const applicant = this.applicant_list[index];
      const mobile_numbers = [];
      if (applicant.mobile_number) {
        mobile_numbers.push(applicant.mobile_number);
      }
      if (applicant.mobile_number_2) {
        mobile_numbers.push(applicant.mobile_number_2);
      }
      /* if (applicant.mobile_number_3) {
        mobile_numbers.push(applicant.mobile_number_3);
      }
      if (applicant.mobile_number_4) {
        mobile_numbers.push(applicant.mobile_number_4);
      }
      if (applicant.mobile_number_5) {
        mobile_numbers.push(applicant.mobile_number_5);
      } */

      let data_item: any;
      let institute: string = 'N/A';
      if (applicant.programme_type_id == 'UG') {
        institute = applicant.hsc_academics?.accademic_info?.school_name;
      }
      //need to add for PG also
      data_item = {
        'S.NO': (index + 1),
        'APP. NO.': applicant.application_no,
        'NAME': applicant.name.toUpperCase(),
        'MOB. NO.': mobile_numbers.join(','),
        //'DATE OF BIRTH': DateHelper.toDisplayDate(new Date(applicant.birth_date)),
        'GENDER': applicant.gender.toUpperCase(),
        'COMMUNITY': applicant.community_id,
        'CASTE': applicant.caste,
        'INSTITUTE': institute,
      };
      if (programme_data.programme_type_id == 'UG') {
        const hsc_academics_marks: any[] = applicant.hsc_academics.marks;
        if (applicant.programme_id == 'b8207f2f-9512-4ecd-b3f8-e78ee01dc577' || applicant.programme_id == '6e5b6273-a37e-4b93-a070-dd4c6584a726') { //BCOM PA & PHS
          for (const mark of applicant.marks) {
            data_item[mark.title.toUpperCase()] = mark.mark;
          }
        }
        else if (applicant.programme_id == 'bda96629-561a-47ba-8ea3-d69ebc138429') { //English
          data_item.TOTAL = hsc_academics_marks.find(x => x.part_type == 2)?.mark;
        }
        else {
          //Other Programmes
          var i = 1;
          var total_mark: number = 0;
          hsc_academics_marks.forEach(subject => {
            if (subject.part_type == 3) {
              data_item["M" + i] = subject.mark;
              i++;
              total_mark += parseInt(subject.mark as string);
            }
          });
          //data_item.TOTAL = applicant.total_marks;
          data_item.TOTAL = total_mark;
        }
      }

      if (programme_data.programme_type_id == 'PG') {
        const pg_marks: any[] = applicant.marks;
        //Other Programmes
        var i = 1;
        var total_mark: number = 0;
        pg_marks.forEach(semester => {
          data_item[semester.title] = semester.mark;
        });
      }

      data_item.REMARKS = applicant.remarks ? applicant.remarks : '---';
      data_to_download.push(data_item);
    }
    return data_to_download;
  }
}
