<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Fee Schedule list</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <!-- <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Student</button>
                </div>
            </div> -->
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Fee Schedule list</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2"></p>
                    </div>


                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Fees Type</th>
                                        <th>Payment Term</th>
                                        <th>Cond.</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="schedule_list.length>0">


                                        <ng-container *ngFor="let schedule of schedule_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="text-uppercase">{{schedule.name}}</td>
                                                <td class="text-uppercase">{{schedule.active?"Active":"In-Active"}}</td>
                                                <td class="text-uppercase">{{schedule.fee_type}}</td>
                                                <td class="text-uppercase">{{schedule.start_date | date}} to {{schedule.end_date| date}}</td>
                                                <td class="text-uppercase">{{getCondString(schedule.cond)}}</td>

                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2" (click)="onViewFeeClick(schedule)">
                                                            <fa-icon [icon]="fonts.faEye"></fa-icon>

                                                        </button>


                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>