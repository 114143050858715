<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Set Constructive Alignment for Courses</p>
                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="component_search_form">
                            <form [formGroup]="component_search_form">
                                <div class="row row-sm">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                enrollment year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <ng-container
                                        *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal','aarc_member']) ">
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by
                                                    Staff Rollno</label>
                                                <input type="text" class="form-control" formControlName="roll_no"
                                                    placeholder="Enter Staff Roll No.">
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by
                                                    Course Code</label>
                                                <input type="text" class="form-control" formControlName="course_code"
                                                    placeholder="Enter Course Code">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="col-lg-2">
                                        <div class="form-group">

                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="course_list_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-container>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 3%;">S.No</th>
                                        <th style="width: 10%;">Course Code</th>
                                        <th>Course Name</th>
                                        <th style="width: 5%;">Part</th>
                                        <th style="width: 10%;">Part Type</th>
                                        <th style="width: 10%;">Staff Name</th>
                                        <th style="width: 10%;">Programme & Section</th>
                                        <th style="width: 10%">Virtual Section</th>
                                        <th style="width: 15%;">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let course of course_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{this.start_index + i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.course_code}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.course_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.part?course.part.split('_').join(' '):''}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.course_type?course.course_type.split('_').join(' '):''}}
                                            </td>
                                            <td class="uppercase">
                                                {{course.staff.salutation}}. {{course.staff.middle_name}}.
                                                {{course.staff.given_name}}
                                                {{course.staff.qualification}} ({{course.roll_no}})
                                            </td>
                                            <td class="uppercase">
                                                <span
                                                    *ngIf="course.class_taking_programme_id && course.class_taking_section">
                                                    {{getProgrammeName(course.class_taking_programme_id)}}
                                                    '{{course.class_taking_section}}'
                                                </span>
                                            </td>
                                            <td class="uppercase">
                                                {{course.virtual_section}}
                                            </td>
                                            <td>

                                                <div class="btn-group dropdown">
                                                    <button type="button" class="btn btn-sm btn-info"
                                                        (click)="onViewComponentsClick(course)"
                                                        *ngIf="!course.show_loading">View Components</button>
                                                    <button type="button" class="btn btn-sm btn-info" disabled
                                                        *ngIf="course.show_loading">Loading...</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="course.show_co">
                                            <tr>
                                                <td colspan="10">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width: 5%;">S.No</th>
                                                                    <th style="width: 5%;">Term</th>
                                                                    <th style="width: 35%;">Course Component</th>
                                                                    <th style="width: 5%;">Max Mark</th>
                                                                    <th style="width:30%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let component of course.component_list;let i=index;trackBy:fromDataResolver.identify;">
                                                                    <tr>
                                                                        <td>
                                                                            {{i+1}}
                                                                        </td>
                                                                        <td>
                                                                            {{component.term}}
                                                                        </td>
                                                                        <td>
                                                                            {{component.component_type}}
                                                                        </td>
                                                                        <td>
                                                                            {{component.max_mark}}
                                                                        </td>
                                                                        <td>
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                                    CO Levels</label>
                                                                                <kendo-multiselect [data]="co_ids"
                                                                                    valueField="id" textField="text"
                                                                                    [(ngModel)]="component.allowed_co_ids"
                                                                                    placeholder="Choose Required CO Level(s)"
                                                                                    class="menu_select"
                                                                                    [valuePrimitive]="true">
                                                                                </kendo-multiselect>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                                    K Levels</label>
                                                                                <kendo-multiselect [data]="k_ids"
                                                                                    valueField="id" textField="text"
                                                                                    [(ngModel)]="component.allowed_k_ids"
                                                                                    placeholder="Choose Required K Level(s)"
                                                                                    class="menu_select"
                                                                                    [valuePrimitive]="true">
                                                                                </kendo-multiselect>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container>
                                                                    <tr>
                                                                        <td colspan="5" class="text-center">
                                                                            <button
                                                                                class="btn btn-md btn-info mg-r-15 text-center"
                                                                                (click)="onSaveClick(course,component)">
                                                                                Save</button>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!course.loading && course.component_list.length==0">
                                                                    <tr>
                                                                        <th colspan="5" class="text-center">Mapping not
                                                                            found please contact OBE
                                                                            incharge
                                                                        </th>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="course.loading && course.component_list.length==0">
                                                                    <tr>
                                                                        <th colspan="5" class="text-center">Loading
                                                                            Please wait</th>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>


                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="10" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="10" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                            <ng-container *ngIf="total_records>100">
                                <ul class="pagination pull-right">
                                    <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                                                class="icon ion-ios-arrow-back"></i></a></li>

                                    <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                                                class="icon ion-ios-arrow-forward"></i></a></li>
                                </ul>
                            </ng-container>
                        </div>


                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>