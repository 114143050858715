<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Entrance Questions</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <ng-container *ngIf="entrance_exam_search_form && entrance_exam_search_form.value.category_id">

                    <div class="pr-1 mb-3 mb-xl-0">

                        <button type="button" class="btn btn-info" (click)="addNewQuestion()">Add New Question</button>

                    </div>


                </ng-container>
            </div>
        </div>


        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Admission Reports</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Admissions List</p> -->
                    </div>

                    <div class="card-body" *ngIf="entrance_exam_search_form">
                        <form [formGroup]="entrance_exam_search_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Category</label>
                                        <select formControlName="category_id" class="form-control" (change)="onCategoryChange()">
                                            <option value="">Select Category</option>
                                            <option *ngFor="let category of categories;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="category">{{category}}</option>
                                        </select>
                                    </div>
                                </div>
                                <ng-container *ngIf="entrance_exam_search_form && entrance_exam_search_form.value.category_id">
                                    <div class="col-lg-10">

                                        <div class="form-group float-right">
                                            <!-- <label class="main-content-label tx-11 tx-medium tx-gray-600">Bulk Upload Questions</label> -->
                                            <ng-container *ngIf="!entrance_exam_question_upload_loading">
                                                <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)"></app-modal>
                                                <!-- <input class="form-control" placeholder="Upload Questions" required="" type="file" accept="text/csv" (change)="addUploadQuestion($event)"> -->
                                            </ng-container>
                                            <ng-container *ngIf="entrance_exam_question_upload_loading">
                                                <span class="form-control">Uploading please wait...</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </form>
                    </div>


                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th>Question</th>
                                        <th style="width: 15%;">Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="entrance_exam_question_list.length>0">


                                        <ng-container *ngFor="let entrance_exam_question of entrance_exam_question_list;let qi=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{qi+1}}

                                                </td>
                                                <td>
                                                    <div class="panel panel-primary tabs-style-2">
                                                        <div class=" tab-menu-heading">
                                                            <div class="tabs-menu1">
                                                                <!-- Tabs -->
                                                                <ul class="nav panel-tabs main-nav-line">
                                                                    <ng-container *ngFor="let question_locale of entrance_exam_question.question_locale;let qli=index;trackBy:fromDataResolver.identify;">
                                                                        <ng-container *ngIf="question_locale.question">
                                                                            <li><a href="#a{{qi}}{{qli}}" class="nav-link " [ngClass]="{'active':question_locale.locale=='ENGLISH'}" data-toggle="tab"><b>{{question_locale.locale}}</b></a></li>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="panel-body tabs-menu-body main-content-body-right border">
                                                            <div class="tab-content">
                                                                <ng-container *ngFor="let question_locale of entrance_exam_question.question_locale;let qli=index;trackBy:fromDataResolver.identify;">
                                                                    <ng-container *ngIf="question_locale.question">
                                                                        <div class="tab-pane" [ngClass]="{'active':question_locale.locale=='ENGLISH'}" id="a{{qi}}{{qli}}">
                                                                            <div [innerHTML]="question_locale.question"></div>

                                                                            <ng-container *ngFor="let option of question_locale.options;let i=index;trackBy:fromDataResolver.identify;">
                                                                                <div>
                                                                                    {{option.option_id}}) <div [innerHTML]="option.option"></div>
                                                                                </div>



                                                                            </ng-container>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td scope="row">

                                                    <button type="button" class="btn btn-sm btn-info" (click)="onEditExamClick(entrance_exam_question)">
                                                        <fa-icon [icon]="fonts.faPen"></fa-icon>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-danger mg-l-10" (click)="onDeleteExamQuestionClick(entrance_exam_question)">
                                                        <ng-container *ngIf="entrance_exam_question.deleting">
                                                            <fa-icon [icon]="fonts.faHourglassStart"></fa-icon>
                                                        </ng-container>
                                                        <ng-container *ngIf="!entrance_exam_question.deleting">
                                                            <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                                        </ng-container>

                                                    </button>
                                                </td>




                                            </tr>


                                        </ng-container>

                                    </ng-container>
                                    <ng-container *ngIf="!entrance_exam_question_list_loading && entrance_exam_question_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="entrance_exam_question_list_loading && entrance_exam_question_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="entrance_exam_question_list.length>0">
                                        <tr>
                                            <th colspan="3" class="text-center">
                                                <button type="button" class="btn btn-sm btn-danger" (click)="onDeleteAllQuestionsClick()">
                                                    <ng-container *ngIf="!entrance_exam_question_delete_loading">
                                                        Delete All Question
                                                    </ng-container>

                                                    <ng-container *ngIf="entrance_exam_question_delete_loading">
                                                        Please wait deleting...
                                                    </ng-container>
                                                </button>
                                            </th>
                                        </tr>
                                    </ng-container>



                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

    </div>


    <!-- Large Modal -->
    <div class="modal" id="modal-question" style="overflow: scroll !important;">
        <div class="modal-dialog modal-lg big-modal" role="document">
            <div class="modal-content modal-content-demo" *ngIf="entrance_exam_question_form">
                <div class="modal-header">
                    <h6 class="modal-title" *ngIf="!entrance_exam_question._id">Add New Question</h6>
                    <h6 class="modal-title" *ngIf="entrance_exam_question._id">Update Question</h6>

                </div>
                <div class="modal-body">
                    <div class="card card-body pd-20 pd-md-40">
                        <form [formGroup]="entrance_exam_question_form">





                            <ng-container formArrayName="question_locale">
                                <div class="panel panel-primary tabs-style-2">
                                    <div class=" tab-menu-heading">
                                        <div class="tabs-menu1">
                                            <!-- Tabs -->
                                            <ul class="nav panel-tabs main-nav-line">
                                                <ng-container *ngFor="let question_locale of fromDataResolver.getFormArrayControlls(entrance_exam_question_form,'question_locale');let i=index;trackBy:fromDataResolver.identify;">
                                                    <ng-container [formGroup]='question_locale'>
                                                        <li><a href="#{{question_locale.value.locale}}" class="nav-link " [ngClass]="{'active':question_locale.value.locale=='ENGLISH'}" data-toggle="tab"><b>{{question_locale.value.locale}}</b></a></li>
                                                    </ng-container>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="panel-body tabs-menu-body main-content-body-right border">
                                        <div class="tab-content">
                                            <ng-container *ngFor="let question_locale of fromDataResolver.getFormArrayControlls(entrance_exam_question_form,'question_locale');let i=index;trackBy:fromDataResolver.identify;">
                                                <ng-container [formGroup]='question_locale'>
                                                    <div class="tab-pane" [ngClass]="{'active':question_locale.value.locale=='ENGLISH'}" id="{{question_locale.value.locale}}">
                                                        <div class="form-group">
                                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Question</label>

                                                            <!-- <angular-editor formControlName="question" [config]="config"></angular-editor> -->
                                                            <kendo-editor #editors class="question" formControlName="question" style="height: 300px;">
                                                                <kendo-toolbar>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                                                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                                                    <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-buttongroup>
                                                                        <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                                                        <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                                                    </kendo-toolbar-buttongroup>
                                                                    <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                                                    <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                                                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                                                    <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                                                    <kendo-toolbar-button text="Upload Image" (click)="openUploadImage($event,'question')">
                                                                    </kendo-toolbar-button>
                                                                </kendo-toolbar>
                                                            </kendo-editor>
                                                        </div>
                                                        <ng-container formArrayName="options">
                                                            <ng-container *ngFor="let option of fromDataResolver.getFormArrayControlls(question_locale,'options');let i=index;trackBy:fromDataResolver.identify;">
                                                                <ng-container [formGroup]='option'>
                                                                    <div class="form-group">
                                                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Option {{option.value.option_id}}</label>
                                                                        <kendo-editor #editors class="{{'option_'+option.value.option_id}}" formControlName="option" style="height: 300px;">
                                                                            <kendo-toolbar>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                                                                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                                                                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                                                                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                                                                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-buttongroup>
                                                                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                                                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                                                                                </kendo-toolbar-buttongroup>
                                                                                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                                                                <!-- <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button> -->
                                                                                <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                                                                <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                                                                <kendo-toolbar-button text="Upload Image" (click)="openUploadImage($event,'option_'+option.value.option_id)">
                                                                                </kendo-toolbar-button>
                                                                            </kendo-toolbar>
                                                                        </kendo-editor>
                                                                        <!-- <input type="text" class="form-control" formControlName="option"> -->
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>


                            </ng-container>
                            <div class="mg-b-20"></div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Correct Option</label>
                                <select formControlName="correct_option" class="form-control">
                                    <option value="">Select Option</option>
                                    <option *ngFor="let correct_option of correct_options;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="correct_option">{{correct_option}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Level</label>
                                <select formControlName="level" class="form-control">
                                    <option *ngFor="let level of levels;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="level.id">{{level.text}}</option>
                                </select>
                            </div>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onQuestionSaveClick()" *ngIf="!entrance_exam_question_form_loading">Save</button>
                                <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="entrance_exam_question_form_loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>



                                <button class="btn ripple btn-secondary" (click)="onQuestionCloseClick()" type="button">Close</button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->

    <div class="modal" id="modal-2">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo" *ngIf="editor_popup_form">
                <div class="modal-header">
                    Upload Image
                </div>
                <div class="modal-body">
                    <div class="card card-body">
                        <input class="form-control" required="" type="file" (change)="uploadImage($event,editor_popup_form)" accept="image/*">
                        <ng-container *ngIf='editor_popup_form.value.image_url'>
                            <img [style.width.px]="editor_popup_form.value.width" [style.height.px]="editor_popup_form.value.height" [src]='editor_popup_form.value.image_url' />
                        </ng-container>
                        <div class="mg-b-20"></div>
                        <div class="text-align-right">


                            <button class="btn ripple btn-secondary" (click)="onCloseImageUpload()" type="button">Close</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>