import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-admissionoverallreport',
  templateUrl: './admissionoverallreport.component.html',
  styleUrls: ['./admissionoverallreport.component.scss']
})
export class AdmissionoverallreportComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;

  // Query Value
  admission_id = '';
  admission_name = '';

  // Enums
  headers = [
    { id: 'in_registered', text: 'In Registered' },
    { id: 'in_incomplete', text: 'In In-Complete' },
    { id: 'in_applied', text: 'In Applied' },
    { id: 'in_waitinglist', text: 'In Wating Listed' },
    { id: 'in_shortlised', text: 'In Short Listed' },
    { id: 'in_shortlist_delayed', text: 'In Delayed Short List' },
    { id: 'in_verification', text: 'In Verification' },
    { id: 'in_verification_delayed', text: 'In Delayed Verification List' },
    { id: 'in_review', text: 'In Review' },
    { id: 'in_ready_for_payment', text: 'In Ready to Pay' },
    { id: 'in_enrolled', text: 'In Enrolled' },
    { id: 'in_cancelled', text: 'In Cancelled' },
    { id: 'in_rejected', text: 'In Rejected' },
  ];

  community_headers = ['BC', 'BCM', 'MBC', 'DNC', 'SC', 'SCA', 'ST', 'OC'];

  // Core data
  admissions_list: any[] = [];
  admissions_list_loading = false;


  admission: any | undefined = undefined;
  admission_save_loading = false;
  admission_delete_loading = false;

  admission_form: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(params => {
      this.admission_id = params.admission_id;
      this.admission_name = params.admission_name;
    });
  }

  ngOnInit(): void {
    this.getApplicationOverAllStatus();
  }

  //#region Master Operations


  //#endregion


  //#region Core Operations
  async getApplicationOverAllStatus(): Promise<void> {
    try {
      this.admissions_list_loading = true;
      this.admissions_list = [];
      const service_response = await this.restService.getApplicantOverallList(this.admission_id);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }


  async getEnrolledProgrameWise(): Promise<void> {
    try {
      this.admissions_list_loading = true;
      this.admissions_list = [];
      const service_response = await this.restService.getEnrolledProgrameWise(this.admission_id);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  async getCommunityWise(): Promise<void> {
    try {
      this.admissions_list_loading = true;
      this.admissions_list = [];
      const service_response = await this.restService.getCommunityWise(this.admission_id);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  async getApplicationCollectionWise(): Promise<void> {
    try {
      this.admissions_list_loading = true;
      this.admissions_list = [];
      const service_response = await this.restService.getApplicationCollectionWise(this.admission_id);
      if (service_response && service_response.success) {
        for (const data of service_response.data) {
          const mobiles: string[] = [];
          if (data.applicant.mobile_number) {
            mobiles.push(data.applicant.mobile_number);
          }
          if (data.applicant.mobile_number_2) {
            mobiles.push(data.applicant.mobile_number_2);
          }
          if (data.applicant.mobile_number_3) {
            mobiles.push(data.applicant.mobile_number_3);
          }
          if (data.applicant.mobile_number_4) {
            mobiles.push(data.applicant.mobile_number_4);
          }
          if (data.applicant.mobile_number_5) {
            mobiles.push(data.applicant.mobile_number_5);
          }
          data.applicant.mobile_numbers = mobiles.join(', ');
        }
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  async getProgrammeCollectionWise(): Promise<void> {
    try {
      this.admissions_list_loading = true;
      this.admissions_list = [];
      const service_response = await this.restService.getProgrammeCollectionWise(this.admission_id);
      if (service_response && service_response.success) {
        for (const data of service_response.data) {
          const mobiles: string[] = [];
          if (data.applicant.mobile_number) {
            mobiles.push(data.applicant.mobile_number);
          }
          if (data.applicant.mobile_number_2) {
            mobiles.push(data.applicant.mobile_number_2);
          }
          if (data.applicant.mobile_number_3) {
            mobiles.push(data.applicant.mobile_number_3);
          }
          if (data.applicant.mobile_number_4) {
            mobiles.push(data.applicant.mobile_number_4);
          }
          if (data.applicant.mobile_number_5) {
            mobiles.push(data.applicant.mobile_number_5);
          }
          data.applicant.mobile_numbers = mobiles.join(', ');
        }
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }


  async onTab(id: string): Promise<void> {
    if (id === 'tab1') {
      this.getApplicationOverAllStatus();
    } else if (id === 'tab2') {
      this.getEnrolledProgrameWise();
    } else if (id === 'tab3') {
      this.getCommunityWise();
    } else if (id === 'tab4') {
      this.getApplicationCollectionWise();
    } else if (id === 'tab5') {
      this.getProgrammeCollectionWise();
    }

  }

  download(id: string): void {
    if (id === 'tab1') {

    } else if (id === 'tab2') {

    } else if (id === 'tab3') {

    } else if (id === 'tab4') {
      const data = [];
      let i = 1;
      for (const admission of this.admissions_list) {
        data.push({
          'S.No': i,
          'PRG. Name': admission.programme_name + ' ' + (admission.finance_type === 'regular' ? '(R)' : '(SF)'),
          'App. No': admission.application_no,
          Name: admission.applicant.given_name + ' ' + admission.applicant.middle_name,
          'Mobile No(s).': admission.applicant.mobile_numbers,
          'Birth Date': admission.applicant.birth_date_display,
          Email: admission.applicant.email,
          'Transaction Id': admission.transaction_ref_no,
          Amount: admission.amount,
          Date: admission.initated_time,
        });
        i++;
      }
      CSVHelper.downloadCSV(data, 'application-collection-as-of-' + DateHelper.convertToControlDate(new Date()));
    } else if (id === 'tab5') {
      const data = [];
      let i = 1;
      for (const admission of this.admissions_list) {
        data.push({
          'S.No': i,
          'PRG. Name': admission.programme_name + ' ' + (admission.finance_type === 'regular' ? '(R)' : '(SF)'),
          'App. No': admission.application_no,
          Name: admission.applicant.given_name + ' ' + admission.applicant.middle_name,
          'Mobile No(s).': admission.applicant.mobile_numbers,
          'Birth Date': admission.applicant.birth_date_display,
          Email: admission.applicant.email,
          'Transaction Id': admission.transaction_ref_no,
          Amount: admission.amount,
          Date: admission.initated_time,
        });
        i++;
      }
      CSVHelper.downloadCSV(data, 'programme-collection-as-of-' + DateHelper.convertToControlDate(new Date()));
    }
  }

  //#endregion



}
