import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
var XLSX = require("xlsx");
@Component({
  selector: 'app-student-elective-requests',
  templateUrl: './student-elective-requests.component.html',
  styleUrls: ['./student-elective-requests.component.scss']
})
export class StudentElectiveRequestsComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  search_form: any;
  search_btn_loading: boolean = false;
  course_request_list: ICourseRequest[] = [];
  change_to_course_codes: { course_name: string, course_code: string }[] = [];
  courses_request_data: ICoursesRequestData[] = [];
  course_list_loading: boolean = false;
  programme_header: {
    programme_id: string,
    programme_name: string, has_sections: boolean, section_list: string[]
  }[] = [];
  requested_student_data: IRequestedStudentData[] = [];
  modal_mode: string = "LIST";
  requestStudentMeta: RequestStudentListMeta = {} as RequestStudentListMeta;
  student_list_loading: boolean = false;
  table_list_loading: boolean = false;
  total_row: number[] = [];
  total_count: number = 0;
  category_list: any[] = [];
  category_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  parts: any[] = [];
  part_course_types: any[] | undefined = [];
  settings_list: any[] = [];
  state = '';
  obe_course_request_master_id: string = "";
  approve_all_loading: boolean = false;
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private restService: AdminservicesService) { }

  async ngOnInit() {
    //this.buildFilterForm();
    this.getProgrammeTypeList();
    this.route.queryParams.subscribe(params => {

      if (this.obe_course_request_master_id != undefined || this.obe_course_request_master_id != '') {
        this.obe_course_request_master_id = params.obe_course_request_master_id;
      }
      this.search_form = this.fb.group({
        programme_type_id: [params.programme_type_id],
        enrolled_year: [params.enrolled_year],
        semester: [params.semester],
        obe_course_request_master_id: [params.obe_course_request_master_id],
      });
      this.getSettingsList();
      this.onSearchClick();
    });
  }

  filterChange(ev: any) {
    this.search_form.get("obe_course_request_master_id").setValue("");
    this.getSettingsList();
  }

  async getSettingsList() {
    try {
      this.settings_list = [];
      const search_data = {
        programme_type_id: this.search_form.value.programme_type_id,
        enrolled_year: this.search_form.value.enrolled_year,
        semester: this.search_form.value.semester,
      }
      const service_response = await this.restService.searchRequestMaster(search_data);
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
  }

  getCourseType() {
    return this.settings_list.find(x => x._id == this.obe_course_request_master_id)?.course_type;
  }


  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  /* async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList(this.search_form);
      if (id === 'programme_type_id' && this.search_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.search_form.value.programme_type_id);
      }
    } else if (id === 'part') {
      if (this.search_form.value.programme_type_id) {
        this.part_course_types = this.commonEnums.getCoursePartType(
          this.search_form.value.programme_type_id, this.search_form.value.part);
      }
    }
  } */

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        /* if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        } */
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }



  onSearchClick() {
    if (!this.search_form.value.programme_type_id) {
      alert("Please choose a Programme Type"); return;
    }
    if (!this.search_form.value.enrolled_year) {
      alert("Please choose an Enrollment year"); return;
    }
    if (!this.search_form.value.semester) {
      alert("Please choose a Semester"); return;
    }
    if (!this.search_form.value.obe_course_request_master_id) {
      alert("Please choose a Course"); return;
    }
    /* this.course_list_loading = true;
    this.course_request_list = [];
    this.course_request_list.push({
      enrolled_year: 2021, semester: 4, course_type: "Elective 1", is_locked: false
    },
      {
        enrolled_year: 2020, semester: 6, course_type: "Elective 3", is_locked: false
      }
    )
    this.course_list_loading = false; */
    this.manageRequest();
  }

  async manageRequest(course_type?: string) {
    try {
      this.table_list_loading = true;
      this.programme_header = [];
      this.total_row = [];
      this.change_to_course_codes = [];
      this.courses_request_data = [];
      //this.http.get("../../../../../assets/input-jsons/elective-request.json").subscribe((res: any) => {
      var search_data = {
        obe_course_request_master_id: this.search_form.value.obe_course_request_master_id
      };
      const res = await this.restService.searchStudentRequest(search_data);
      var result = res.data as ICoursesRequestData[];
      this.courses_request_data = result;

      var pgm_header: IRequestProgramme[] = this.courses_request_data[0].programme_wise_list;
      this.courses_request_data.forEach(course => {
        this.change_to_course_codes.push({ course_code: course.course_code, course_name: course.course_name });
      })
      pgm_header.forEach(pgm => {
        if (this.programme_header.length == 0) {
          this.programme_header.push({
            programme_name: pgm.programme_name,

            has_sections: false, section_list: [pgm.section], programme_id: pgm.programme_id
          });
          //programme_short_code: pgm.requested_programme_short_code,
        }
        else {
          if (this.programme_header.findIndex(x => x.programme_id == pgm.programme_id) >= 0) //programme already exists
          {
            console.log(pgm);
            var programme = this.programme_header.find(x => x.programme_id == pgm.programme_id)
            programme.has_sections = true;
            programme.section_list.push(pgm.section);
          }
          else //programme does not exist
          {
            this.programme_header.push({
              programme_name: pgm.programme_name,

              has_sections: false, section_list: [pgm.section], programme_id: pgm.programme_id
            });
            //programme_short_code: pgm.requested_programme_short_code,
          }
        }
      });
      console.log(this.programme_header);
      for (var i = 0; i < pgm_header.length; i++) {
        this.total_row[i] = 0;
        this.courses_request_data.forEach(course => {
          this.total_row[i] += course.programme_wise_list[i].requested_count;
        })
      }
      console.log(this.total_row);
      /* this.courses_request_data.forEach(course => {
        this.total_count += course.total_request_count;
      }) */
      //});
    }
    catch (error) {
      console.log(error);
    }
    finally {
      this.table_list_loading = false;
    }
  }

  /* lockCourseRequest(course_type: string, is_locked: boolean) {
    if (is_locked) return;
    const consent = confirm("Are you sure you want to lock requests for this course type? This action is irreversible!");
    if (!consent)
      return;
    this.course_request_list.find(x => x.course_type == course_type).is_locked = true;
  } */
  async onCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#request-modal-popup');
    this.modal_mode = "";
    this.total_row = [];
  }

  async viewStudentList(programme_id: string, section_name: string, course_code: string, course_name: string, offering_programme_name: string) {
    this.student_list_loading = true;
    this.modal_mode = "APPROVE";
    JQueryHelper.openModal('#request-modal-popup', { keyboard: false, backdrop: 'static' });
    this.requestStudentMeta.programme_id = programme_id; this.requestStudentMeta.course_code = course_code;
    this.requestStudentMeta.course_name = course_name; this.requestStudentMeta.offering_programme_name = offering_programme_name;
    this.requestStudentMeta.section_name = section_name;
    var student_query = {
      "obe_course_request_master_id": this.obe_course_request_master_id,
      "course_code": course_code,
      "student_programme_id": programme_id,
      "student_programme_section": section_name
    }
    try {
      let service_response = await this.restService.findRequestStudentList(student_query);
      if (service_response && service_response.success) {
        this.requested_student_data = service_response.data;
        for (const student_data of this.requested_student_data) {
          student_data.change_student_opened = false;
          student_data.change_to = "";
          if (student_data.approved) student_data.is_approve_checked = true;
          else student_data.is_approve_checked = false;
        }
        console.log(this.requested_student_data);
      }
      else {
        alert("Could not get student data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.student_list_loading = false;
    }
  }

  backToListView() {
    //make refresh api call
    this.manageRequest();
    this.requested_student_data = [];
    this.requestStudentMeta = {} as RequestStudentListMeta;
    this.modal_mode = "";
    JQueryHelper.closeModal('#request-modal-popup');
  }

  //single student approve/cancel apis -- remove
  async approveStudent(student) {
    //this.requested_student_data.find(x => x.roll_no == roll_no).is_approved = true;  
    try {
      var approve_data = {
        _id: student._id
      }
      let service_response = await this.restService.approveRequest(approve_data);
      if (service_response && service_response.success) {
        //refresh view
      }
      else {
        alert("Could not save data");
      }
      alert('Changes saved Successfully');
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
    }
    this.viewStudentList(this.requestStudentMeta.programme_id, this.requestStudentMeta.section_name,
      this.requestStudentMeta.course_code, this.requestStudentMeta.course_name,
      this.requestStudentMeta.offering_programme_name);
  }

  async cancelStudent(student) {
    //this.requested_student_data.find(x => x.roll_no == roll_no).is_approved = true;  
    try {
      var unapprove_data = {
        _id: student._id
      }
      let service_response = await this.restService.unApproveRequest(unapprove_data);
      if (service_response && service_response.success) {
        //refresh view
        this.viewStudentList(this.requestStudentMeta.programme_id, this.requestStudentMeta.section_name,
          this.requestStudentMeta.course_code, this.requestStudentMeta.course_name,
          this.requestStudentMeta.offering_programme_name);
        alert('Changes saved Successfully');
      }
      else {
        alert("Could not save data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
    }
  }

  //change-to handlers
  openChangeStudent(student: IRequestedStudentData) {
    student.change_student_opened = true;
  }
  closeChangeStudent(student: IRequestedStudentData) {
    student.change_student_opened = false;
  }
  //change button click
  async changeStudentTo(student: IRequestedStudentData) {
    if (student.course_code == student.change_to) {
      alert("Cannot change to same Course Code. Please select another Course Code.");
      return;
    }
    const consent = confirm("Are you sure want to change " + student.roll_no + " to " + student.change_to + "? This action is irreversible!");
    if (!consent) {
      student.change_to = "";
      this.requested_student_data.find(x => x.roll_no == student.roll_no).change_to = "";
      console.log(this.requested_student_data);
      return;
    }
    try {
      var course_change_data = {
        _id: student._id,
        course_code: student.change_to
      }
      console.log(course_change_data);
      let service_response = await this.restService.changeCourseRequest(course_change_data);
      if (service_response && service_response.success) {
        //refresh view
        this.viewStudentList(this.requestStudentMeta.programme_id, this.requestStudentMeta.section_name,
          this.requestStudentMeta.course_code, this.requestStudentMeta.course_name,
          this.requestStudentMeta.offering_programme_name);
        alert('Changes saved Successfully');
      }
      else {
        alert("Could not save data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
    }
  }

  //approve checkbox handlers
  allClick(ev: Event) { //select all student checkboxes
    var checkbox = (ev.target) as HTMLInputElement;
    if (checkbox.checked) //approve all clicked
    {
      this.requested_student_data.forEach(student => {
        student.is_approve_checked = true;
      })
    }
    else {
      this.requested_student_data.forEach(student => {
        student.is_approve_checked = false;
      })
    }

  }
  studentApprovedChecked(ev: Event, student: IRequestedStudentData) { //single student checkbox click event
  }

  async approveSelectedStudents() { //Approve multiple students
    const consent = confirm("Are you sure want to approve the selected students?");
    if (!consent) return;
    var approve_student_ids: { _id: string }[] = [];
    for (const student of this.requested_student_data) {
      if (student.is_approve_checked)
        approve_student_ids.push({ _id: student._id });
    }
    console.log(approve_student_ids);
    if (approve_student_ids.length == 0) {
      alert("Please select at least one student"); return;
    }
    try {
      let service_response = await this.restService.approveRequestBulk(approve_student_ids);
      if (service_response && service_response.success) {
        alert('Students approved successfully');
      }
      else {
        alert("Could not save data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.viewStudentList(this.requestStudentMeta.programme_id, this.requestStudentMeta.section_name,
        this.requestStudentMeta.course_code, this.requestStudentMeta.course_name,
        this.requestStudentMeta.offering_programme_name);
    }
  }

  async rejectSelectedStudents() { //Approve multiple students
    const consent = confirm("Are you sure want to reject the selected students?");
    if (!consent) return;
    var reject_student_ids: { _id: string }[] = [];
    for (const student of this.requested_student_data) {
      if (student.is_approve_checked)
        reject_student_ids.push({ _id: student._id });
    }
    console.log(reject_student_ids);
    if (reject_student_ids.length == 0) {
      alert("Please select at least one student"); return;
    }

    try {
      let service_response = await this.restService.unApproveRequestBulk(reject_student_ids);
      if (service_response && service_response.success) {
        alert('Students unapproved successfully');
      }
      else {
        alert("Could not save data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.viewStudentList(this.requestStudentMeta.programme_id, this.requestStudentMeta.section_name,
        this.requestStudentMeta.course_code, this.requestStudentMeta.course_name,
        this.requestStudentMeta.offering_programme_name);
    }
  }

  async approveAllStudents() { //Approve multiple students
    const consent = confirm("Are you sure want to approve all the students?");
    if (!consent) return;
    try {
      const save_data = {
        obe_course_request_master_id: this.obe_course_request_master_id
      }
      let service_response = await this.restService.approveAllStudents(save_data);
      if (service_response && service_response.success) {
        alert('All Students approved successfully');
      }
      else {
        alert("Could not save data; Contact Admin");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.getSettingsList();
      this.onSearchClick();
    }
  }

  printTable() {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("matrix-table");

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, `${this.commonEnums.getClassName(this.search_form.value.enrolled_year)}-Year-${this.getCourseType()}-MATRIX-TABLE.xlsx`);
  }

}
interface ICourseRequest {
  enrolled_year: number,
  semester: number,
  course_type: string,
  is_locked: boolean
}

interface ICoursesRequestData {
  course_code: string;
  course_name: string,
  offering_programme_name: string,
  //total_request_count: number,
  //total_approved_count: number,
  part: string,
  course_type: string,
  programme_wise_list: IRequestProgramme[] //multiple requested programmes
}

interface IRequestProgramme {
  programme_id: string,
  programme_name: string,
  //requested_programme_short_code: string,
  //section_id: string,
  section: string,
  requested_count: number,
  approved_count: number,
  //seats_allowed_count:number
}

interface IRequestedStudentData {
  roll_no: string,
  approved: boolean,
  changed: boolean,
  course_code: string,
  course_name: string,
  course_type: string,
  enrolled_year: number,
  obe_course_request_master_id: string,
  part: string,
  semester: number,
  student: { _id: string, given_name: string, middle_name: string }
  student_programme_id: string,
  student_programme_name: string
  student_programme_section: string,
  sub: string
  _id: string

  //ui 
  change_student_opened?: boolean
  change_to?: string,
  is_approve_checked?: boolean,
}

interface RequestStudentListMeta {
  programme_id: string, course_code: string, course_name: string, offering_programme_name: string;
  section_name: string;
}

interface IQueryParamSearch {
  programme_type_id: string, enrolled_year: string, semester: string, part: string, course_type: string,
  obe_course_request_master_id: string
}