<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Room-wise Attendance</h4>
            </div>
          </div>
          <form [formGroup]="attendance_data">
          <div class="card-body">
            <div class="row row-sm">
              
                
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Date & Time</label>
                  <input type="datetime-local" class="form-control" formControlName="date_time" placeholder="Enter date">
                </div>
              </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Room</label>
                    <select class="form-control" formControlName = "room_id"
                    name="room"
                      >
                      <option value="">Select Room</option>
                      <option *ngFor="let room of rooms;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="room.room_id">
                        {{room.room_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      [disabled]="canDisableAction()" (click)="onSearchClick()" data-placement="bottom"
                      data-toggle="tooltip" title="Select Hostel, Floor and Room to search">
                      Get
                    </button>
                  </div>
                </div>
              </div>
            <!-- </form> -->
          </div>
            <div class="card-body no-border-top" *ngIf="aliasesArrayControl!.length>0">
            <div class="table-responsive border-top userlist-table">
              <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                <thead>
                  <th>
                    <span>S.No </span>
                  </th>
                  <th>
                    <span>Student</span>
                  </th>
                  <th>
                    <span>Is Absent</span>
                  </th>
                </thead>
                <tbody formArrayName="attendance">
                  <tr *ngFor="let student of aliasesArrayControl; let i = index;" [formGroupName]="i">
                    <Td>{{i+1}}</Td>
                    <td>{{ student?.get('given_name')!.value }} </td>
                    <td>
                      <label class="switch">
                        <input type="checkbox" formControlName="is_absent" (change)="absentChanged($event, i)">
                        <span class="slider round"></span>
                      </label> <br>                      
                      <kendo-autocomplete
                      *ngIf="student?.get('is_absent')!.value==true"
                      [data]="absent_reasons"                                            
                      [placeholder]="'Absence Reasons'"
                      [suggest]="true"
                      formControlName="reason"                      
                    ></kendo-autocomplete>
                    </td>
                    
                  </tr>

                </tbody>
              </table>
            
            </div>
            <div class="add-btn-row">
            <button type="button" class="mg-t-30 mg-r-20 btn btn-primary mr-2 search-button" title="Save"
                      (click)="saveAttendance()" data-placement="bottom"
                      data-toggle="tooltip" title="Save Attendance">
                      Save
                    </button>
                    <button type="button" class="mg-t-30 btn btn-danger mr-2 search-button" title="Save"
                    (click)="resetAttendance()" data-placement="bottom"
                    data-toggle="tooltip" title="Resets attendance only">
                    Reset
                  </button>
                  <button type="button" class="mg-t-30 btn btn-dark mr-2 search-button" title="Clear"
                    (click)="clearAttendance()" data-placement="bottom"
                    data-toggle="tooltip" title="Resets entire form">
                    Clear
                  </button>

                  
            
              </div>
          </div>
        </form>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
