<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="addStudent()" [disabled]="canDisableAction()"
            data-placement="left" data-toggle="tooltip" title="Select Hostel, Floor and Room to search">Add
            Student</button>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Room Assets</h4>
            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Assets</p>
          </div>

          <div class="card-body" *ngIf="roomSelectionForm">
            <form [formGroup]="roomSelectionForm">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Hostel</label>
                    <select formControlName="hostelSelect" class="form-control"
                      (change)="onFilterSelectChange('hostel',$event)">
                      <option value="">Select Hostel</option>
                      <option #hostel
                        *ngFor="let templateHostel of hostels;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="templateHostel.name">
                        {{templateHostel.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Floor</label>
                    <select formControlName="floorSelect" class="form-control"
                      (change)="onFilterSelectChange('floor',$event)">
                      <option value="">Select Floor</option>
                      <option *ngFor="let templateFloor of hostel.floors;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="templateFloor.name">
                        {{templateFloor.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Room</label>
                    <select formControlName="roomSelect" class="form-control"
                      (change)="onFilterSelectChange('room',$event)">
                      <option value="">Select Room</option>
                      <option *ngFor="let templateRoom of floor.rooms;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="templateRoom.name">
                        {{templateRoom.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      [disabled]="canDisableAction()" (click)="onSearchClick()" data-placement="bottom"
                      data-toggle="tooltip" title="Select Hostel, Floor and Room to search">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body no-border-top">
            <div class="table-responsive border-top userlist-table">
              <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                <thead>
                  <th>
                    <span>Student</span>
                  </th>
                  <th>
                    <span>Total Assets</span>
                  </th>
                  <th>
                    <span>Actions</span>
                  </th>
                </thead>
                <tbody>

                  <tr *ngFor="let studentRoomMap of studentRoomMaps">
                    <td>{{ studentRoomMap?.given_name }} </td>
                    <td>{{ studentRoomMap?.asset_ids?.length }}</td>
                    <td>
                      <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEdit(studentRoomMap)">
                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                      </button>
                      <button type="button" class="btn btn-sm btn-danger mr-2" (click)="onDelete(studentRoomMap)">
                        <fa-icon [icon]="fonts.faTrash"></fa-icon>
                      </button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>


        </div>
      </div>


    </div>
  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
