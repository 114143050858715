import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-student-elective-courses',
  templateUrl: './student-elective-courses.component.html',
  styleUrls: ['./student-elective-courses.component.scss']
})
export class StudentElectiveCoursesComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  programme_course_list: any[] = [];
  programme_course_list_loading = false;
  obe_course_request_master_id: string = "";
  course_request_form: any;
  availabe_search_loader: boolean = false;
  course_request_loader: boolean = false;
  course_request_form_loading = false;
  course: any;
  title = "Elective Courses";
  roll_no: string = "";
  is_group_polling: boolean = false;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router
  ) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getSavedProgrammeCourseList();
  }


  async getSavedProgrammeCourseList(): Promise<void> {
    try {
      this.programme_course_list = [];
      this.programme_course_list_loading = true;

      const save_respose = await this.restService.checkAvailableRequest();
      if (save_respose.success) {
        this.programme_course_list = save_respose.data;
      }
      console.log(this.programme_course_list);
    } catch (error) {
      alert(
        CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read)
      );
    } finally {
      this.programme_course_list_loading = false;
    }
  }

  async getAvailableProgrammes(course: any): Promise<void> {
    //use course.obe_course_request_master_id to get available courses
    try {
      this.course = course;
      this.availabe_search_loader = true;
      this.obe_course_request_master_id = course._id;
      this.roll_no = course.roll_no;
      if (course.part == 'PART_I' || course.part == 'PART_V') {
        if (this.course.rule_list && this.course.rule_list?.length > 0) //PART I and PART V Group Polling
        {
          this.course.rule_list[0].group_data.forEach(group => {
            group.requested = false;
            group.show_loader = false;
          });
          this.is_group_polling = true;
          setTimeout(() => {
            JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
          }, 500);
          return;
        }
      }
      var search_data = {
        obe_course_request_master_id: course._id
      };
      this.obe_course_request_master_id = course._id;
      this.roll_no = course.roll_no;
      const save_respose = await this.restService.findAvailableCourses(search_data);
      if (save_respose.success) {
        this.buildCourseRequestForm(save_respose.data);
        setTimeout(() => {
          JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
        }, 500)
      }

    } catch (error) {
      alert(
        CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read)
      );
    } finally {
      this.programme_course_list_loading = false;
      this.availabe_search_loader = false;
    }
  }

  async onCloseClick(): Promise<void> {
    this.obe_course_request_master_id = ""; this.roll_no = "";
    this.is_group_polling = false;
    JQueryHelper.closeModal('#modal-popup');
    this.course_request_form = null;
  }

  buildCourseRequestForm(course: any): void {
    this.course_request_form = this.fb.group({
      _id: [course._id],
      courses_list: this.fb.array(this.buildCourseListForm(course)),
    });
    console.log(this.course_request_form);
  }

  buildCourseListForm(courses_list: any[]): any[] {
    if (!courses_list) {
      courses_list = [];
    }
    const value_to_return = [];
    for (const course of courses_list) {
      value_to_return.push(this.fb.group({
        course_code: [course.course_code],
        course_name: [course.course_name],
        requested: [course.std_requested || false],
        approved: [course.std_request_approved || false],
      }))
    }
    return value_to_return;
  }

  async onRequestButtonClick(current_course: FormGroup, current_index: number): Promise<void> {
    //api call
    const consent = confirm("Are you sure want to choose this course? Once chosen, you cannot change selected course!");
    if (!consent) return;
    var update_data = {
      "obe_course_request_master_id": this.obe_course_request_master_id,
      "roll_no": this.roll_no,
      "course_code": current_course.value.course_code,
    }
    try {
      this.course_request_loader = true;
      let service_response = await this.restService.createRequestCourse(update_data);
      if (service_response && service_response.success) {
        current_course.get('requested').setValue(false);
        this.getSavedProgrammeCourseList();
        alert('Course Request made Successfully.');
        this.onCloseClick();
      }
      else {
        alert("Error! Could not request course.");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.course_request_loader = false;
    }
  }

  async onBulkRequestClick(group) {
    const consent = confirm("Are you sure want to choose this Group of courses? Once chosen, you cannot change selected group!");
    if (!consent) return;
    group.show_loader = true;
    var update_data: any[] = [];
    group.available_courses.forEach(course_code => {
      update_data.push({
        "obe_course_request_master_id": this.obe_course_request_master_id,
        "roll_no": this.roll_no,
        "course_code": course_code,
      });
    });
    console.log(update_data);
    try {
      this.course_request_loader = true;
      let service_response = await this.restService.makeBulkPollingRequest(update_data);
      if (service_response && service_response.success) {
        group.requested = false;
        this.getSavedProgrammeCourseList();
        alert('Group Request made Successfully.');
        this.onCloseClick();
      }
      else {
        alert("Error! Could not request course.");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.course_request_loader = false;
      group.show_loader = false;
    }
  }

  async viewSyllabus(current_course: FormGroup) {
    this.onCloseClick();
    //navigate to new page and display its syllabus
    HTMLHelper.openLinkInNewTab("/students/syllabus?course_code=" + current_course.get('course_code').value);
  }

  viewCourseSyllabus(course_code: string) {
    HTMLHelper.openLinkInNewTab("/students/syllabus?course_code=" + course_code);
  }


}
