import { Component, OnInit } from '@angular/core';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { ActivatedRoute } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-view-student-exam-fee',
  templateUrl: './view-student-exam-fee.component.html',
  styleUrls: ['./view-student-exam-fee.component.scss']
})
export class ViewStudentExamFeeComponent implements OnInit {

  commonEnums = CommonEnums;
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  title = 'View Mapped Student Exam Fees';
  exam_fee_header_id: string = ""; //from route params
  programme_type_id: string = ""; //from route params
  enrolled_year: number = 0; //ngModel
  students_list_loading: boolean = false;
  students_list: StudentExamFeeInterface[] = [];
  student: StudentExamFeeInterface = {} as StudentExamFeeInterface;
  fine_loader: boolean = false;
  ngOnInit(): void {
  }
  constructor(private restService: AdminservicesService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.exam_fee_header_id = params.setting_id;
      this.programme_type_id = params.programme_type_id;
    });
  }

  onSearchClick() {
    if (this.enrolled_year != 0) {
      this.getStudentsFeeList();
    }
    else {
      alert("Please choose a valid Enrollment Year");
    }
  }

  async getStudentsFeeList() {
    this.students_list = [];
    this.students_list_loading = true;
    try {
      const search_data = {
        "exam_fee_header_id": this.exam_fee_header_id,
        "enrolled_year": this.enrolled_year
      }
      const service_response = await this.restService.getStudentExamFee(search_data);
      if (service_response && service_response.success) {
        this.students_list = service_response.data;
        console.log(this.students_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.students_list_loading = false;
  }

  viewBreakup(student: StudentExamFeeInterface) {
    this.student = student;
    JQueryHelper.openModal('#breakup-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.student = {} as StudentExamFeeInterface;
    JQueryHelper.closeModal('#breakup-modal-popup');
  }

  downloadCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const student of this.students_list) {
      data_list.push({
        "S.No": i,
        "Roll No.": student.roll_no,
        "Name": student.name,
        "Programme": student.programme_name + " '" + student.section + "'",
        "Fees": student.amount,
      });
      i++;
    }
    CSVHelper.downloadCSV(data_list, "Student_Exam_Fee_" + this.enrolled_year + "_" + DateHelper.convertToControlDate(new Date()));
  }

}

interface StudentExamFeeInterface {
  amount: number, //overall amount
  enrolled_year: number,
  exam_fee_header_id: string,
  extra_charge_amount: number //included in total amount --> need to display in breakup only
  finance_type: string,
  fine: number
  generate_hall_ticket?: boolean,
  id?: string,
  migration_flag?: string
  name: string
  programme_id: string,
  programme_name: string
  programme_type_id: string
  roll_no: string,
  section: string,
  courses_appearing_list: CourseAppearingListInterface[]
}

interface CourseAppearingListInterface {
  appearance_type: string,
  course_code: string,
  course_name: string,
  course_type?: string,
  exam_duration: number,
  fee_amount: number
  id?: string
  part: string,
  programme_type_id: string,
  updatedAt?: string,
  _id?: string,
}

