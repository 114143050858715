<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Assign Marks</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <!-- <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Component</button> -->
        </div>
      </div>
    </div>
    <div class="row">


      <div class="col-xl-12">
        <div class="alert alert-warning" role="alert">
          <button aria-label="Close" class="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
          <strong>Warning!</strong> In case, if you are not seeing the student or course details, please contact Mr.
          S. Alagu Kumar (+91 99948 47670) with manually prepared Excel sheet.
        </div>
      </div>
    </div>


    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">



          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Assign Marks</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Students marks</p>
          </div>


          <div class="card-body">
            <ng-container *ngIf="component_search_form">
              <form [formGroup]="component_search_form">
                <div class="row row-sm">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                        Type</label>
                      <select formControlName="programme_type_id" class="form-control"
                        (change)="onFilterSelectChange('programme_type_id',$event)">
                        <option value="">Select Programme Type</option>
                        <option
                          *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                          [value]="programme_type.programme_type_id">{{programme_type.name}}
                        </option>
                      </select>

                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select enrollment year</label>
                      <select formControlName="enrolled_year" class="form-control">
                        <option value="">Select enrollment year</option>
                        <option
                          *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="year">{{year}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-2" *ngIf="cookieStore.isRoleExists(['principal','coe']) ">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Offering
                        Programme</label>
                      <select formControlName="programme_id" class="form-control">
                        <option value="">All Programmes</option>
                        <option
                          *ngFor="let programme of filter_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="programme.programme_id">{{programme.programme_name}}
                          {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select semester</label>
                      <select formControlName="semester" class="form-control">
                        <option value="">Select Semester</option>
                        <option
                          *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="sem.id">{{sem.text}}</option>
                      </select>
                    </div>
                  </div>
                  <ng-container *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal']) ">
                    <div class="col-lg-2">
                      <div class="form-group">
                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by Staff Rollno</label>
                        <input type="text" class="form-control" formControlName="roll_no">
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="form-group">
                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by Course Code</label>
                        <input type="text" class="form-control" formControlName="course_code">
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-lg-2">
                    <div class="form-group">

                      <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                        *ngIf="!course_list_loading" (click)="onSearchClick()">
                        Search
                      </button>
                      <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                        *ngIf="course_list_loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>




                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
            <div class="mg-b-20"></div>
            <div class="table-responsive">
              <table class="table table-bordered mg-b-50 text-md-nowrap">
                <thead>
                  <tr>
                    <th style="width: 3%;">S.No</th>
                    <th style="width: 10%;">Course Code</th>
                    <th>Course Name</th>
                    <th style="width: 5%;">Part</th>
                    <th style="width: 10%;">Part Type</th>
                    <th style="width: 10%;">Staff Name</th>
                    <th style="width: 10%;">Programme & Section</th>
                    <th style="width: 10%">Virtual Section</th>
                    <th style="width: 15%;">Action</th>

                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let course of course_list;let i=index;trackBy:fromDataResolver.identify;">
                    <tr>
                      <td>
                        {{this.start_index + i+1}}
                      </td>
                      <td class="uppercase">
                        {{course.course_code}}
                      </td>
                      <td class="uppercase">
                        {{course.course_name}}
                      </td>
                      <td class="uppercase">
                        {{course.part?course.part.split('_').join(' '):''}}
                      </td>
                      <td class="uppercase">
                        {{course.course_type?course.course_type.split('_').join(' '):''}}
                      </td>
                      <td class="uppercase">
                        {{course.staff.salutation}}. {{course.staff.middle_name}}. {{course.staff.given_name}}
                        {{course.staff.qualification}} ({{course.roll_no}})
                      </td>
                      <td class="uppercase">
                        <span *ngIf="course.class_taking_programme_id && course.class_taking_section">
                          {{getProgrammeName(course.class_taking_programme_id)}} '{{course.class_taking_section}}'
                        </span>
                      </td>
                      <td class="uppercase">
                        {{course.virtual_section}}
                      </td>
                      <td>

                        <div class="btn-group dropdown">
                          <button type="button" class="btn btn-sm btn-info" (click)="onViewComponentsClick(course)"
                            *ngIf="!course.show_loading">View Components</button>
                          <button type="button" class="btn btn-sm btn-info" disabled
                            *ngIf="course.show_loading">Loading...</button>
                          <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right">
                            <button class="dropdown-item" type="button" (click)="onViewAssignQuestionsClick(course)">
                              Assign
                              Questions</button>
                            <!-- <ng-container *ngIf="course.part=='ELECTIVE' || course.part=='PART_I' ||
                                   course.part=='PART_II' || course.part=='PART_IV' ||
                                   course.part=='PART_V'  ||
                                   (course.part=='PART_III'
                                   &&
                                   (course.course_type=='ELECTIVE' ||
                                   course.course_type=='CORE_SPECIAL_THEORY'  ||
                                   course.course_type=='CORE_SPECIAL_PROJECT'))">
                              <button class="dropdown-item" type="button" (click)="onViewAssignStudentsClick(course)">
                                Assign
                                Students</button>
                            </ng-container> -->
                            <button class="dropdown-item" type="button" disabled="disabled">Submit to COE</button>
                            <button class="dropdown-item" type="button" (click)="onCasReportClick(course)">CAS
                              Report</button>
                            <button class="dropdown-item" type="button" (click)="onCOSReportClick(course)">Con.
                              Report</button>
                          </div>
                        </div>
                        <!-- <ng-container *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal']) ">
                          <button type="button" class="btn btn-sm btn-danger mr-2"
                            (click)="deleteStaffCourseMap(course)">Delete</button>
                        </ng-container> -->
                      </td>



                    </tr>
                    <ng-container *ngIf="course.show_co">
                      <tr>
                        <td colspan="10">
                          <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                              <thead>
                                <tr>
                                  <th style="width: 3%;">S.No</th>
                                  <th style="width: 3%;">Term</th>
                                  <th style="width: 35%;">Course Component</th>
                                  <th style="width: 3%;">Max Mark</th>
                                  <th style="width:5%">CO Level Mark Settings</th>
                                  <th style="width: 10%;">Mark Entry</th>
                                  <th style="width: 10%;">Review Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let component of course.component_list;let i=index;trackBy:fromDataResolver.identify;">
                                  <tr>
                                    <td>
                                      {{i+1}}
                                    </td>
                                    <td>
                                      {{component.term}}
                                    </td>
                                    <td>
                                      {{component.component_type}}
                                    </td>
                                    <td>
                                      {{component.max_mark}}
                                    </td>
                                    <td>
                                      <div class="pr-1 mb-3 mb-xl-0">
                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                          title="CO Level Mark Settings" (click)="onMarkLimitClick(course,component)">
                                          <ng-container *ngIf="component.loading">
                                            <fa-icon [icon]="fonts.faHourglassHalf"></fa-icon>
                                          </ng-container>
                                          <ng-container *ngIf="!component.loading">
                                            <fa-icon [icon]="fonts.faCog"></fa-icon>
                                          </ng-container>
                                        </button>
                                      </div>
                                    </td>

                                    <td>
                                      <div class="pr-1 mb-3 mb-xl-0">
                                        <button type="button" class="btn btn-sm btn-info mr-2 mg-t-2" title="Mark Entry"
                                          (click)="onAssignMarkClick(course,component)">
                                          <ng-container *ngIf="component.assign_loading">
                                            Loading...
                                          </ng-container>
                                          <ng-container *ngIf="!component.assign_loading">
                                            Mark Entry
                                          </ng-container>
                                        </button>

                                        <button type="button" class="btn btn-sm btn-info mr-2 mg-t-2"
                                          title="Download Mark Report" (click)="onDownloadMarkClick(course,component)">
                                          <ng-container *ngIf="component.download_loading">
                                            Loading...
                                          </ng-container>
                                          <ng-container *ngIf="!component.download_loading">
                                            Download Foil Card
                                          </ng-container>
                                        </button>

                                      </div>
                                    </td>
                                    <td>
                                      <div class="pr-1 mb-3 mb-xl-0"
                                        *ngIf="!component.stage || component.stage == 'STAFF'">
                                        <button type="button" class="btn btn-sm btn-info mr-2 mg-t-5"
                                          [disabled]="component.submit_loading" title="Submit for Review"
                                          (click)="submitForReview(course,component)">
                                          <ng-container *ngIf="component.submit_loading">
                                            Loading...
                                          </ng-container>
                                          <ng-container *ngIf="!component.submit_loading">
                                            Submit for Review
                                          </ng-container>
                                        </button>
                                      </div>
                                      <span *ngIf="component.stage && component.stage != 'STAFF'" style="color: green;">
                                        <b>{{component.stage}}</b>
                                      </span>
                                    </td>
                                  </tr>

                                </ng-container>
                                <ng-container *ngIf="!course.loading && course.component_list.length==0">
                                  <tr>
                                    <th colspan="5" class="text-center">Mapping not found please contact OBE incharge
                                    </th>
                                  </tr>
                                </ng-container>
                                <ng-container *ngIf="course.loading && course.component_list.length==0">
                                  <tr>
                                    <th colspan="5" class="text-center">Loading Please wait</th>
                                  </tr>
                                </ng-container>
                              </tbody>

                            </table>
                          </div>
                        </td>
                      </tr>


                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!course_list_loading && course_list.length==0">
                    <tr>
                      <th colspan="10" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="course_list_loading && course_list.length==0">
                    <tr>
                      <th colspan="10" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>
                </tbody>

              </table>
              <ng-container *ngIf="total_records>100">
                <ul class="pagination pull-right">
                  <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                        class="icon ion-ios-arrow-back"></i></a></li>

                  <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                        class="icon ion-ios-arrow-forward"></i></a></li>
                </ul>
              </ng-container>
            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-course_mark_map_form">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="course_mark_map_form">
          <div class="modal-header">
            <h6 class="modal-title">Mark Limit Settings</h6>


          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="course_mark_map_form">

                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>
                      <tr>
                        <!-- <th style="width: 3%;">S.No</th> -->
                        <th style="width: 40%;">CO Name</th>
                        <th>Max Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *ngFor="let component of fromDataResolver.getFormArrayControlls(course_mark_map_form,'limits');let i=index;trackBy:fromDataResolver.identify;">
                        <ng-container [formGroup]="component">
                          <tr>
                            <!-- <td>
                                                            {{i+1}}
                                                        </td> -->
                            <td>
                              {{'CO ' + commonEnums.rommon_letters[component.value.co_id]}}
                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" required="" type="number" formControlName="max_mark"
                                  (focusout)="onMaxMarkChange()">
                              </div>
                            </td>


                          </tr>
                        </ng-container>
                      </ng-container>
                      <tr>

                        <td>
                          Total
                        </td>
                        <td>
                          {{course_mark_map_form.value.total_max_mark}}
                        </td>


                      </tr>
                      <tr>


                        <td>
                          Maximum Total Allowed
                        </td>
                        <td>
                          {{course_mark_map_form.value.max_mark}}
                        </td>


                      </tr>

                    </tbody>

                  </table>
                </div>

                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onMarkLimitSaveClick()"
                    *ngIf="!course_mark_map_form_loading">Save</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_mark_map_form_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>


                  <button class="btn ripple btn-secondary" (click)="onMarkLimitCloseClick()"
                    type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-assignmark">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="assign_mark_map_form">
          <div class="modal-header">
            <h6 class="modal-title">Set Mark for : {{assign_mark_map_form.value.course_code}} :
              {{assign_mark_map_form.value.course_name}} - {{assign_mark_map_form.value.component_type}}
              {{assign_mark_map_form.value.term}}</h6>

            <ng-container [formGroup]="assign_mark_map_form">

            </ng-container>
            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label for="blood_group">Upload As CSV</label>
                <input class="form-control" required="" type="file" accept="text/csv"
                  (change)="bulkCourseCoCOMMark($event,assign_mark_map_form, 'course_file')">

              </div>
            </div>


          </div>
          <div class="modal-body">
            <div>
              <form [formGroup]="assign_mark_map_form">

                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>
                      <tr>
                        <th style="width: 3%;">S.No</th>
                        <th style="width: 5%;">Roll No</th>
                        <th style="width: 20%;">Name</th>
                        <ng-container
                          *ngFor="let co of fromDataResolver.getFormArrayControlls(assign_mark_map_form,'students')[0].value.course_marks;let i=index;trackBy:fromDataResolver.identify;">
                          <th style="width: 10%;">CO {{commonEnums.rommon_letters[co.co_id]}} ({{co.max_mark}})</th>
                        </ng-container>
                        <th style="width: 5%;">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *ngFor="let student of fromDataResolver.getFormArrayControlls(assign_mark_map_form,'students');let i=index;trackBy:fromDataResolver.identify;">
                        <ng-container [formGroup]="student">
                          <tr>
                            <td>
                              {{i+1}}
                            </td>
                            <td>
                              {{student.value.roll_no}}
                            </td>
                            <td>
                              {{student.value.given_name}} {{student.value.middle_name}}
                            </td>
                            <ng-container
                              *ngFor="let course_mark of fromDataResolver.getFormArrayControlls(student,'course_marks');let i=index;trackBy:fromDataResolver.identify;">
                              <ng-container [formGroup]="course_mark">
                                <td>
                                  <div class="form-group">
                                    <input class="form-control" required="" type="number" formControlName="mark"
                                      (focusout)="onMarkAssignSaveClick(student,course_mark)">
                                  </div>
                                </td>
                              </ng-container>

                            </ng-container>
                            <td>
                              <div class="form-group" style="margin-top: 10px;">
                                <b>{{student.value.total}}</b>
                              </div>
                            </td>

                          </tr>
                        </ng-container>
                      </ng-container>

                    </tbody>

                  </table>
                </div>

                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <!-- <button class="btn btn-primary mg-r-20" (click)="onMarkAssignSaveClick()" *ngIf="!course_mark_map_form_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_mark_map_form_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button> -->


                  <button class="btn ripple btn-secondary" (click)="onMarkAssignCloseClick()"
                    type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-attainmentreport">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="this.cas_report_data">
          <div class="modal-header">
            <h6 class="modal-title">Course Attainment Sheet - {{this.selected_course.course_code}} :
              {{this.selected_course.course_name}}</h6>
            <div class="float-right">
              <button class="btn btn-info btn-sm mg-r-10" (click)="downloadCASCSV()">Download as CSV</button>
            </div>
          </div>
          <div class="modal-body">
            <div>


              <div class="table-responsive">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>
                      <th colspan="3" style="text-align: right;">Mark alloted</th>
                      <ng-container
                        *ngIf="this.cas_report_data && this.cas_report_data.co_header && this.cas_report_data.co_header.length>0">
                        <ng-container
                          *ngFor="let co of this.cas_report_data.co_header;let i=index;trackBy:fromDataResolver.identify;">
                          <th style="width: 10%;">{{co.total}}</th>
                        </ng-container>
                      </ng-container>
                      <th style="width: 5%;">{{this.cas_report_data.co_header_total}}</th>
                    </tr>
                    <tr>
                      <th style="width: 3%;">S.No</th>
                      <th style="width: 5%;">Roll No</th>
                      <th style="width: 20%;">Name</th>
                      <ng-container
                        *ngIf="this.cas_report_data && this.cas_report_data.co_header && this.cas_report_data.co_header.length>0">
                        <ng-container
                          *ngFor="let co of this.cas_report_data.co_header;let i=index;trackBy:fromDataResolver.identify;">
                          <th style="width: 10%;">{{'CO ' + commonEnums.rommon_letters[co.co_id]}}</th>
                        </ng-container>
                      </ng-container>
                      <th style="width: 5%;">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let cas_report of this.cas_report_data.data;let i=index;trackBy:fromDataResolver.identify;">

                      <tr>
                        <td>
                          {{i+1}}
                        </td>
                        <td>
                          {{cas_report.roll_no}}
                        </td>
                        <td>
                          {{cas_report.given_name}} {{cas_report.middle_name}}
                        </td>
                        <ng-container
                          *ngFor="let COCOMMark of cas_report.COCOMMarks;let i=index;trackBy:fromDataResolver.identify;">

                          <td>
                            {{COCOMMark.mark}}
                          </td>


                        </ng-container>
                        <td>
                          <b>{{cas_report.COCOMMarks_Total}}</b>
                        </td>

                      </tr>

                    </ng-container>



                    <tr>
                      <td colspan="3">
                        Target(50% of the Maximum in each CO)
                      </td>

                      <ng-container
                        *ngFor="let avg_total of this.cas_report_data.avg_total;let i=index;trackBy:fromDataResolver.identify;">

                        <td>
                          {{avg_total.total}}
                        </td>


                      </ng-container>
                      <td>

                      </td>

                    </tr>
                    <tr>
                      <td colspan="3">
                        No. of Students >= the Target
                      </td>

                      <ng-container
                        *ngFor="let co_expectation of this.cas_report_data.co_expectation;let i=index;trackBy:fromDataResolver.identify;">

                        <td>
                          {{co_expectation.count}}
                        </td>


                      </ng-container>
                      <td>

                      </td>

                    </tr>
                    <tr>
                      <td colspan="3">
                        Percentage of Attainment
                      </td>

                      <ng-container
                        *ngFor="let co_expectation of this.cas_report_data.co_expectation;let i=index;trackBy:fromDataResolver.identify;">

                        <td>
                          {{co_expectation.p_of_attainment | number : '1.2-2'}}
                        </td>


                      </ng-container>
                      <td>
                        {{this.cas_report_data.co_expectation_total | number : '1.2-2'}}
                      </td>

                    </tr>
                    <tr>
                      <td colspan="9">
                        CO Attainment Level
                      </td>

                    </tr>
                    <tr>
                      <td colspan="3">
                        50-60=C, 61-70=B, 71 and Above =A
                      </td>

                      <ng-container
                        *ngFor="let co_expectation of this.cas_report_data.co_expectation;let i=index;trackBy:fromDataResolver.identify;">

                        <td>
                          {{co_expectation.level}}
                        </td>


                      </ng-container>
                      <td>

                      </td>

                    </tr>
                    <tr>
                      <td colspan="3">
                        Internal and External Attainment
                      </td>
                      <td colspan="5" style="text-align: right;">
                        60%
                      </td>
                      <td>
                        {{this.cas_report_data.internal_external_attainment.internal | number : '1.2-2'}}
                      </td>
                      <!-- <td>
                                                {{this.cas_report_data.internal_external_attainment.external | number : '1.2-2'}}
                                            </td> -->
                    </tr>
                    <tr>
                      <td colspan="3">
                        Direct Attainment 100%
                      </td>
                      <td colspan="5" style="text-align: right;">

                      </td>
                      <td>
                        {{this.cas_report_data.internal_external_attainment.total | number : '1.2-2'}}
                      </td>

                    </tr>
                    <tr>
                      <td colspan="3">
                        Convert Direct Attainment to 80%
                      </td>
                      <td colspan="5" style="text-align: right;">

                      </td>
                      <td>
                        {{this.cas_report_data.internal_external_attainment.percentage | number : '1.2-2'}}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        Indirect Attainment Course Exit Survey 20%
                      </td>
                      <td colspan="5" style="text-align: right;">

                      </td>
                      <td>

                      </td>

                    </tr>
                    <tr>
                      <td colspan="3">
                        Final Attainment of the course
                      </td>
                      <td colspan="5" style="text-align: right;">

                      </td>
                      <td>

                      </td>

                    </tr>





                  </tbody>

                </table>
              </div>

              <div class="mg-b-20"></div>
              <div class="text-align-right">


                <button class="btn ripple btn-secondary" (click)="onCasReportCloseClick()" type="button">Close</button>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-consolidatedreport">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="this.cos_report_data">
          <div class="modal-header">
            <h6 class="modal-title">Course Consolidated Report - {{this.selected_course.course_code}} :
              {{this.selected_course.course_name}}</h6>
            <div class="float-right">
              <button class="btn btn-info btn-sm mg-r-10" (click)="downloadConCSV()">Download as CSV</button>
              <button class="btn btn-info btn-sm mg-r-10" (click)="printConReport()">Print Con. Report</button>
              <button class="btn btn-info btn-sm mg-r-10" (click)="printFoilCard()">Print Foil Card</button>
              <button class="btn btn-info btn-sm" (click)="downloadFoilCard()">Download Foil Card</button>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <div class="table-responsive" id="con-report">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>
                      <th colspan="3" class="table-head"></th>
                      <ng-container *ngIf="this.cos_report_data && this.cos_report_data.length>0">
                        <ng-container
                          *ngFor="let co of this.cos_report_data[0].mark_details;let i=index;trackBy:fromDataResolver.identify;">
                          <ng-container *ngIf="co.marks.length>1">
                            <th rowspan="2" [attr.colspan]="co.marks.length+1" style="text-align: center; width: 10%;"
                              class="table-head">
                              {{co.label}}<br>
                              ({{co.marks[0].scale_to}})
                            </th>
                          </ng-container>
                          <ng-container *ngIf="co.marks.length==1">
                            <th rowspan="2" style="text-align: center; width: 10%;" class="table-head">{{co.label}}<br>
                              ({{co.marks[0].scale_to}})
                            </th>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th rowspan="2" style="width: 3%;">S.No</th>
                      <th rowspan="2" style="width: 5%;">Roll No</th>
                      <th rowspan="2" style="width: 20%;" class="text-center">Name</th>
                      <ng-container *ngIf="this.cos_report_data && this.cos_report_data.length>0">
                        <ng-container
                          *ngFor="let co of this.cos_report_data[0].mark_details;let i=index;trackBy:fromDataResolver.identify;">
                          <ng-container *ngIf="co.marks.length>1">

                            <ng-container *ngFor="let mark of co.marks;let i=index;trackBy:fromDataResolver.identify;">
                              <th style="width: 5%;" class="text-center">{{commonEnums.rommon_letters[(i+1)]}} <br>
                                ({{mark.scale_to}})
                              </th>
                            </ng-container>
                            <th rowspan="2" style="width: 5%;" class="text-center">Final<br>
                              ({{co.marks[0].scale_to}})
                            </th>
                          </ng-container>
                          <ng-container *ngIf="co.marks.length==1">
                            <th rowspan="2" style="width: 5%;" class="text-center">Final<br>
                              ({{co.marks[0].scale_to}})
                            </th>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let cos_report of this.cos_report_data;let i=index;trackBy:fromDataResolver.identify;">

                      <tr>
                        <td class="table-cell">
                          {{i+1}}
                        </td>
                        <td class="table-cell">
                          {{cos_report.roll_no}}
                        </td>
                        <td class="table-cell">
                          {{cos_report.given_name}} {{cos_report.middle_name}}
                        </td>
                        <ng-container
                          *ngFor="let COCOMMark of cos_report.mark_details;let i=index;trackBy:fromDataResolver.identify;">


                          <ng-container
                            *ngFor="let mark of COCOMMark.marks;let i=index;trackBy:fromDataResolver.identify;">
                            <td class="table-cell">
                              {{mark.mark| number : '1.2-2'}}
                            </td>
                          </ng-container>
                          <td *ngIf="COCOMMark.marks.length>1" class="table-cell">
                            {{COCOMMark.total| number : '1.2-2'}}
                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div class="mg-b-20"></div>
              <div class="text-align-right">


                <button class="btn ripple btn-secondary" (click)="onCOSReportCloseClick()" type="button">Close</button>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-assignstudents">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="assign_student_form">
          <div class="modal-header">
            <h6 class="modal-title">Assign Students To - {{this.selected_course.course_code}} :
              {{this.selected_course.course_name}}</h6>
          </div>
          <div class="modal-body">
            <div>
              <form [formGroup]="assign_student_form">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll No to assign</label>
                  <textarea class="form-control" style="height: 100px;" formControlName="roll_no"></textarea>
                </div>

                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onAssignStudentsClick()">Assign</button>
                </div>
              </form>
              <div class="mg-b-20"></div>
              <div style="font-size: larger;"> Assigned Student List : ({{this.assigned_students_list.length}} students
                assigned)</div>
              <div class="mg-b-20"></div>
              <div class="table-responsive">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>

                    <tr>
                      <th style="width: 3%;">S.No</th>
                      <th style="width: 5%;">Roll No</th>
                      <th style="width: 20%;">Name</th>
                      <th style="width: 5%;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let assigned_student of this.assigned_students_list;let i=index;trackBy:fromDataResolver.identify;">

                      <tr>
                        <td>
                          {{i+1}}
                        </td>
                        <td>
                          {{assigned_student.roll_no}}
                        </td>
                        <td>
                          {{assigned_student.given_name}} {{assigned_student.middle_name}}
                        </td>
                        <td>
                          <div class="pr-1 mb-3 mb-xl-0">
                            <button type="button" class="btn btn-sm btn-danger mr-2" title="Remove"
                              (click)="removeStudentFromCourse(assigned_student)">
                              <ng-container *ngIf="assigned_student.loading">
                                <fa-icon [icon]="fonts.faHourglassHalf"></fa-icon>
                              </ng-container>
                              <ng-container *ngIf="!assigned_student.loading">
                                <fa-icon [icon]="fonts.faTrash"></fa-icon>
                              </ng-container>

                            </button>
                          </div>
                        </td>
                      </tr>

                    </ng-container>

                    <ng-container *ngIf="!assigned_students_list_loading && assigned_students_list.length==0">
                      <tr>
                        <th colspan="5" class="text-center">No Records found</th>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="assigned_students_list_loading && assigned_students_list.length==0">
                      <tr>
                        <th colspan="5" class="text-center">Loading Please wait</th>
                      </tr>
                    </ng-container>



                  </tbody>

                </table>
              </div>

              <div class="mg-b-20"></div>
              <div class="text-align-right">


                <button class="btn ripple btn-secondary" (click)="onViewAssignStudentsCloseClick()"
                  type="button">Close</button>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-survey">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="question_mark_map_form">
          <div class="modal-header">
            <h6 class="modal-title">Survey Settings</h6>


          </div>
          <div class="modal-body">
            <div class="">
              <form [formGroup]="question_mark_map_form">

                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>
                      <tr>
                        <th style="width: 10%;">CO Name</th>
                        <th>Question</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container
                        *ngFor="let question of fromDataResolver.getFormArrayControlls(question_mark_map_form,'questions');let i=index;trackBy:fromDataResolver.identify;">
                        <ng-container [formGroup]="question">
                          <tr>
                            <td>
                              {{'CO ' + commonEnums.rommon_letters[question.value.co_id]}}
                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" required="" type="text" formControlName="question">
                              </div>
                            </td>


                          </tr>
                        </ng-container>
                      </ng-container>


                    </tbody>

                  </table>
                </div>

                <div class="mg-b-20"></div>
                <div class="row">
                  <div class="col">
                    <div class="text-align-left" *ngIf="question_mark_map_form.value.questions[0]._id">

                      <button class="btn btn-danger mg-r-20" (click)="onSurveyStatusChangeClick()"
                        *ngIf="question_mark_map_form.value.opened && !question_status_map_form_saving">Close
                        Survey</button>
                      <button class="btn btn-success mg-r-20" (click)="onSurveyStatusChangeClick()"
                        *ngIf="!question_mark_map_form.value.opened && !question_status_map_form_saving">Open
                        Survey</button>
                      <button class="btn btn-primary mg-r-20" type="button" disabled
                        *ngIf="question_status_map_form_saving">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>


                    </div>
                  </div>
                  <div class="col">
                    <div class="text-align-right">
                      <button class="btn btn-primary mg-r-20" (click)="onAssignQuestionsSaveClick()"
                        *ngIf="!question_mark_map_form_loading">Save</button>
                      <button class="btn btn-primary mg-r-20" type="button" disabled
                        *ngIf="question_mark_map_form_loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>


                      <button class="btn ripple btn-secondary" (click)="onAssignQuestionsCloseClick()"
                        type="button">Close</button>
                    </div>

                  </div>
                </div>


              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-component">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="this.download_marks.length>0">
          <div class="modal-header">
            <h6 class="modal-title">Component Report - {{this.selected_course.course_code}} :
              {{this.selected_course.course_name}} - {{this.selected_component.component_type}}
              {{this.selected_component.term}}</h6>
            <div class="float-right">
              <button class="btn btn-info btn-sm mg-r-10" (click)="downloadComponentCSV()">Download as CSV</button>
              <button class="btn btn-info btn-sm" (click)="downloadComponentPDF()">Download PDF</button>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <div class="table-responsive" id="component-report">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 5%;">S.No</th>
                      <th style="width: 10%;">Roll No</th>
                      <th style="width: 20%;" class="text-center">Name</th>
                      <ng-container
                        *ngFor="let co of download_marks[0].course_marks;let i=index;trackBy:fromDataResolver.identify;">
                        <th style="width: 10%;">CO {{commonEnums.rommon_letters[co.co_id]}}</th>
                      </ng-container>
                      <th style="width: 10%;">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let student of download_marks;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td class="table-cell">
                          {{i+1}}
                        </td>
                        <td class="table-cell">
                          {{student.roll_no}}
                        </td>
                        <td class="table-cell">
                          {{student.given_name}} {{student.middle_name}}
                        </td>
                        <ng-container
                          *ngFor="let co of student.course_marks;let j=index;trackBy:fromDataResolver.identify;">
                          <td class="table-cell">
                            {{co.mark| number : '1.2-2'}}
                          </td>
                        </ng-container>
                        <td>{{student.total_mark | number : '1.2-2'}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div class="mg-b-20"></div>
              <div class="text-align-right">


                <button class="btn ripple btn-secondary" (click)="onComponentCloseClick()" type="button">Close</button>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->



  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>