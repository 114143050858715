import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';



@Component({
  selector: 'app-assign-mark-header',
  templateUrl: './assign-mark-header.component.html',
  styleUrls: ['./assign-mark-header.component.scss']
})
export class AssignMarkHeaderComponent implements OnInit {



  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;
  // master data
  component_search_form: any;

  // core data

  course_list: any[] = [];
  course_list_loading = false;

  course_mark_map_form: any;
  course_mark_map_form_loading = false;


  // assign mark limit
  selected_course: any;
  selected_component: any;

  assign_mark_map_form: any;
  assign_mark_map_form_loading = false;
  programme_type_list: any[] = [];


  // cas report
  cas_report_data: any;
  cas_report_data_loading = false;

  // cos report
  cos_report_data: any;
  cos_report_data_loading = false;

  // assign students
  assign_student_form: any;
  assign_student_form_loading = false;

  assigned_students_list: any;
  assigned_students_list_loading: any;


  // assigng survey questions


  question_list_loading = false;

  question_mark_map_form: any;
  question_mark_map_form_loading = false;

  programme_type_list_loading = false;

  question_status_map_form_loading = false;

  question_status_map_form_saving = false;
  programme_list: any[] = [];
  programme_list_loading: boolean = false;
  filter_programme_list: any[] = [];
  filter_programme_list_loading: boolean = false;
  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;

  download_marks: any[] = [];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {

    // Load master data
    this.buildComponentSearchForm();
    this.getProgrammeTypeList();
    this.getProgrammeDataList();
    // Load core data

  }



  /// master data
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.getFilterProgrammeDataList(this.component_search_form);
    }
  }

  async getFilterProgrammeDataList(form: any): Promise<void> {
    try {
      this.filter_programme_list_loading = true;
      this.filter_programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.filter_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.filter_programme_list_loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  buildComponentSearchForm(): void {

    this.component_search_form = this.fb.group({
      programme_type_id: [''],
      enrolled_year: [''],
      programme_id: [''],
      semester: [''],
      roll_no: [''],
      course_code: [''],
    });

  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }

  async onSearchClick(): Promise<void> {
    try {
      this.course_list_loading = true;
      this.course_list = [];
      var search_query: any = {
        programme_type_id: this.component_search_form.value.programme_type_id,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        skip: this.start_index,
        take: this.take_index
      };
      if (this.component_search_form.value.programme_id) {
        search_query.programme_id = this.component_search_form.value.programme_id;
      }
      if (this.component_search_form.value.roll_no) {
        search_query.roll_no = this.component_search_form.value.roll_no;
      }
      if (this.component_search_form.value.course_code) {
        search_query.course_code = this.component_search_form.value.course_code;
      }
      //const service_response = await this.restService.getCoursesByStaff(search_query);
      const service_response = await this.restService.searchStaffCourseMap(search_query); //v2
      if (service_response && service_response.success) {
        this.course_list = service_response.data.data;
        this.total_records = service_response.data.total;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_list_loading = false;
    }
  }


  /// Core data
  async onViewComponentsClick(course: any): Promise<void> {
    console.log(course);
    if (course.show_co) {
      course.show_co = false;
    } else {
      for (const courseobj of this.course_list) {
        courseobj.show_co = false;
      }
      //component submission details
      try {
        course.show_loading = true;
        var course_data: any = {
          course_code: course.course_code,
          enrolled_year: course.enrolled_year,
          semester: course.semester,
        }
        if (course.class_taking_programme_id && course.class_taking_section) {
          course_data.programme_id = course.class_taking_programme_id;
          course_data.section = course.class_taking_section;
        }
        if (course.virtual_section) {
          course_data.virtual_section = course.virtual_section;
        }
        const service_response = await this.restService.getComponentsSubmissionDetails(course_data);
        if (service_response && service_response.success) {
          course.co_submission = service_response.data;
          console.log(service_response.data);
          this.getCoursesByStaff(course);
        }
      }
      catch (error) {
        alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
        console.log(error);
      }
      finally {
        course.show_loading = false;
      }
      course.show_co = true;
    }
  }

  async getCoursesByStaff(course: any): Promise<void> {
    try {
      if (!course.co_settings || course.co_settings.length == 0) {
        alert("OBE component not configured!, Please contact OBE incharge");
        return;
      }
      course.loading = true;
      course.component_list = JSON.parse(JSON.stringify(course.co_settings));
      course.co_submission.forEach(co => {
        course.component_list.find(x => x.component_type == co.component_type && x.term == co.term)!.stage = co.stage;
      });
      console.log(course.component_list);
      /* const search_query = {
        course_code: course.course_code,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
      };
      const service_response = await this.restService.getCoByCourse(search_query);
      //const service_response = await this.restService.searchComponent(search_query); //v2
      //need programme_type_id in course for new api
      if (service_response && service_response.success) {
        course.component_list = service_response.data;
      } */
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      course.loading = false;
    }
  }

  async deleteStaffCourseMap(course: any): Promise<void> {
    try {
      const consent = confirm('Are you sure you want to delete?');
      if (!consent) {
        return;
      }
      const service_response = await this.restService.unAssignStaffWithCourse(course._id);
      //const service_response = await this.restService.deleteStaffCourseMap(course._id); //v2
      if (service_response && service_response.success) {
        alert('Deleted Successfully');
        await this.onSearchClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }


  }



  // Mark limit
  async onMarkLimitClick(course: any, component: any): Promise<void> {

    try {
      this.selected_course = course;
      component.loading = true;
      const search_query = {
        course_code: course.course_code,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        obe_component_id: component._id
      };
      const service_response = await this.restService.getCouseCoCOM(search_query);
      //Use existing call??
      if (service_response && service_response.success) {

        this.buildMarkLimitForm(component, service_response.data);
        JQueryHelper.openModal('#modal-popup-course_mark_map_form', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      component.loading = false;
    }



  }
  buildMarkLimitForm(component: any, data: any[]): void {
    let total_max_mark = 0;
    if (data && data.length > 0) {
      for (const limit of data) {
        total_max_mark = total_max_mark + limit.max_mark;
      }
    }
    this.course_mark_map_form = this.fb.group({
      obe_component_id: [component._id],
      max_mark: [component.max_mark],
      total_max_mark: [total_max_mark],
      limits: this.fb.array(this.buildMarkLimitItemForm(component._id, data))
    });
  }

  buildMarkLimitItemForm(obe_component_id: string, data: any[]): any[] {
    const item_to_return = [];
    if (!data) {
      data = [];
    }

    const co_limit = 5;
    for (let index = 0; index < co_limit; index++) {
      const co_id = index + 1;
      const saved_co = data.find(c => c.co_id === co_id);
      let max_mark = 0;
      if (saved_co) {
        max_mark = saved_co.max_mark;
      }
      item_to_return.push(this.fb.group({
        obe_component_id: [obe_component_id],
        co_id: [co_id],
        max_mark: [max_mark],
      }));
    }
    return item_to_return;
  }

  async onMarkLimitCloseClick(): Promise<void> {
    this.resetMarkLimitForm();
    JQueryHelper.closeModal('#modal-popup-course_mark_map_form');
  }

  onMaxMarkChange(): void {
    let total_max_mark = 0;
    for (const limit of this.course_mark_map_form.value.limits) {
      total_max_mark = total_max_mark + limit.max_mark;
    }
    this.course_mark_map_form.value.total_max_mark = total_max_mark;
  }
  resetMarkLimitForm(): void {
    this.selected_course = undefined;
    this.course_mark_map_form = undefined;
  }

  async onMarkLimitSaveClick(): Promise<void> {
    try {
      this.course_mark_map_form_loading = true;
      const course_mark_map_form_data = this.course_mark_map_form.value;
      if (course_mark_map_form_data.total_max_mark !== course_mark_map_form_data.max_mark) {
        alert('Error, Sum of COs must be equal to ' + course_mark_map_form_data.max_mark);
        return;
      }
      for (const limit of course_mark_map_form_data.limits) {
        limit.course_code = this.selected_course.course_code;
        limit.enrolled_year = this.component_search_form.value.enrolled_year;
        limit.semester = this.component_search_form.value.semester;
      }
      const service_response = await this.restService.builkUpdateCourseCoCOM(course_mark_map_form_data.limits);
      //Which V2 API call to save course max co limits??
      if (service_response && service_response.success) {
        alert('Saved Successfully');
        this.onMarkLimitCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_mark_map_form_loading = false;
    }
  }

  // Mark assign
  async onAssignMarkClick(course: any, component: any, force_fill_student_list = false): Promise<void> {

    try {
      this.selected_course = course;
      this.selected_component = component;
      component.assign_loading = true;

      const mark_search_query = {
        course_code: course.course_code,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        obe_component_id: component._id
      };

      /* const mark_search_query = {
        staff_map_id: course.=,
        obe_component_id: component._id
      }; */

      const mark_service_response = await this.restService.getCouseCoCOM(mark_search_query);
      //Which V2 API call to get course mark limit??
      if (mark_service_response && mark_service_response.success
        && mark_service_response.data && mark_service_response.data.length > 0) {
        const max_mark_settings = mark_service_response.data;
        /* const search_query: any = {
          course_code: course.course_code,
          enrolled_year: this.component_search_form.value.enrolled_year,
          semester: this.component_search_form.value.semester,
          obe_component_id: component._id,
        };

        if (course.section_id) {
          search_query.section_id = course.section_id;
        }
        if (course.common_programme_id) {
          search_query.common_programme_id = course.common_programme_id;
        }
        if (course.common_section_id) {
          search_query.section_id = course.common_section_id;
        }
        search_query.force_fill_student_list = force_fill_student_list; */
        //const service_response = await this.restService.findCOCOMMarksList(search_query);
        const mark_search_query = {
          staff_map_id: course._id,
          obe_component_id: component._id
        };
        const service_response = await this.restService.searchStudentCourseMap(mark_search_query);
        //preload student mark data
        //Which V2 API call to get student mark list?

        if (service_response && service_response.success && service_response.data.data && service_response.data.data.length > 0) {
          const submission_details = service_response.data.submission_details;
          if (submission_details == null || submission_details.stage == "STAFF") {
            this.buildMarkAssignForm(max_mark_settings, course, component, service_response.data.data);
            JQueryHelper.openModal('#modal-popup-assignmark', { keyboard: false, backdrop: 'static' });
          }
          else {
            alert("Mark Submitted For Review! Please Contact OBE Member for changes.")
          }
          /* this.buildMarkAssignForm(max_mark_settings, course, component, service_response.data.data);
          JQueryHelper.openModal('#modal-popup-assignmark', { keyboard: false, backdrop: 'static' }); */
        } else {
          alert("Student not mapped to this course yet, please contact OBE incharge.")
        }

      } else {
        alert('Error, Mark Limit not set for this component. Please set that first.');
      }



    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      component.assign_loading = false;
    }



  }

  async onDownloadMarkClick(course: any, component: any, force_fill_student_list = false): Promise<void> {

    try {
      this.selected_course = course;
      this.selected_component = component;
      component.download_loading = true;
      this.download_marks = [];
      const mark_search_query = {
        course_code: course.course_code,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        obe_component_id: component._id
      };

      /* const mark_search_query = {
        staff_map_id: course.=,
        obe_component_id: component._id
      }; */

      const mark_service_response = await this.restService.getCouseCoCOM(mark_search_query);
      //Which V2 API call to get course mark limit??
      if (mark_service_response && mark_service_response.success
        && mark_service_response.data && mark_service_response.data.length > 0) {
        const max_mark_settings = mark_service_response.data;
        /* const search_query: any = {
          course_code: course.course_code,
          enrolled_year: this.component_search_form.value.enrolled_year,
          semester: this.component_search_form.value.semester,
          obe_component_id: component._id,
        };

        if (course.section_id) {
          search_query.section_id = course.section_id;
        }
        if (course.common_programme_id) {
          search_query.common_programme_id = course.common_programme_id;
        }
        if (course.common_section_id) {
          search_query.section_id = course.common_section_id;
        }
        search_query.force_fill_student_list = force_fill_student_list; */
        //const service_response = await this.restService.findCOCOMMarksList(search_query);
        const mark_search_query = {
          staff_map_id: course._id,
          obe_component_id: component._id
        };
        const service_response = await this.restService.searchStudentCourseMap(mark_search_query);
        //preload student mark data
        //Which V2 API call to get student mark list?

        if (service_response && service_response.success && service_response.data.data && service_response.data.data.length > 0) {
          this.download_marks = service_response.data.data;
          this.download_marks.forEach(student => {
            var total_mark = 0;
            student.course_marks.forEach(mark => {
              total_mark += mark.mark;
            });
            student.total_mark = total_mark;
          });
          console.log(this.download_marks);
          JQueryHelper.openModal('#modal-popup-component', { keyboard: false, backdrop: 'static' });
          /* this.buildMarkAssignForm(max_mark_settings, course, component, service_response.data.data);
          JQueryHelper.openModal('#modal-popup-assignmark', { keyboard: false, backdrop: 'static' }); */
        } else {
          alert("Student not mapped to this course yet, please contact OBE incharge.")
        }

      } else {
        alert('Error, Mark Limit not set for this component. Please set that first.');
      }



    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      component.download_loading = false;
    }



  }

  downloadComponentCSV() {
    try {
      const data_list: any[] = [];
      let i = 1; //student
      for (const data of this.download_marks) {
        var push_data: any = {
          "S.No": i,
          "Roll No": data?.roll_no,
          "Name": data?.given_name + " " + data?.middle_name,
        };
        for (const mark_header of data.course_marks) { //multiple components
          var label = "CO" + mark_header.co_id;
          push_data[label] = mark_header.mark;
        }
        push_data['Total Mark'] = data.total_mark;
        console.log(push_data);
        data_list.push(push_data);
        i++;
      }
      CSVHelper.downloadCSV(data_list, 'Component_Report_' + this.selected_course.course_code + "_" + this.selected_component.component_type + "_" + this.selected_component.term + "_" + DateHelper.convertToControlDate(new Date()));
    }
    catch (error) {
      alert("Error downloading CSV File");
      console.log(error);
    }
  }

  downloadComponentPDF() {
    let current_date = DateHelper.toDisplayDate(new Date());
    var table_string: string = '';
    table_string +=
      '<table ' +
      'class="table table-bordered">' +
      '<thead><tr><th>S.No</th><th>Roll No.</th><th>Name</th>';
    for (var i = 0; i < this.download_marks[0].course_marks.length; i++) {
      table_string += "<th> CO" + (i + 1) + "</th>"
    }
    table_string += '<th>Total Marks</th></tr></thead><tbody>';
    for (var i = 0; i < this.download_marks.length; i++) {
      table_string += '<tr>' +
        '<td>' + (i + 1) + '</td>' +
        '<td>' + this.download_marks[i].roll_no + '</td>' +
        '<td>' + this.download_marks[i].given_name + ' ' + this.download_marks[i].middle_name + '</td>';


      for (var j = 0; j < this.download_marks[i].course_marks.length; j++) {
        table_string += '<td>' + this.download_marks[i].course_marks[j].mark + '</td>';
      }
      table_string += '<td>' + this.download_marks[i].total_mark + '</td></tr>';
    }
    table_string += '</tbody></table>';

    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px; margin-top: 10px;" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h5 style = "margin-top:5px"> Mark List' +
      ' </h5>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h6 class= "text-center"><b>' + this.selected_course.course_code + ' - ' + this.selected_course.course_name + '</b></h6>');
    mywindow.document.write('<h6 class= "text-center"><b>' + this.selected_component.component_type.split('_').join(' ') + ' - ' + this.selected_component.term + '</b></h6>');
    /* mywindow.document.write('<div class="text-right" style = "margin-right:100px"><span><b>Max. Mark:' + this.selected_component.max_mark + '</b></span></div>'); */
    mywindow.document.write('<div class="row" style="margin-left: 20px"><div class="col-md-6">Max Mark:' + this.selected_component.max_mark + '</div> <div class="col-md-6"> Units Covered: </div> </div>');
    mywindow.document.write('<div class="row" style="margin-left: 20px"><div class="col-md-6">Date of conduct: </div> <div class="col-md-6"> Date of Marks submission: </div> </div>');


    mywindow.document.write('<div class="row" style="margin-left: 20px">');
    mywindow.document.write(table_string);
    mywindow.document.write('</div>');

    mywindow.document.write(
      '<div class="row" style="margin-top:50px">' +
      '<div class="col-md-4"> ' +
      '<span style="margin-left:20px"> Sivakasi, </span><br>' +
      '<span style="margin-left:20px">' + current_date + ' </span>' +
      '</div>' +
      '<div class="col-md-4"> ' +
      '<span style="margin-left:20px">  </span><br>' +
      '<span style="margin-left:20px"> Head of the department   </span>' +
      '</div>' +
      '<div class="col-md-4 text-right"> ' +
      '<span style="margin-right:100px">Internal Examiner</span><br>' +
      '<span style="margin-right:100px">(' +
      this.getStaffName(this.selected_course.staff)
      + ')</span>' +
      '</div>' +
      '</div>');
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  onComponentCloseClick() {
    JQueryHelper.closeModal('#modal-popup-component');
  }



  buildMarkAssignForm(max_mark_settings: any[], course: any, component: any, data: any[]): void {

    this.assign_mark_map_form = this.fb.group({
      course_code: [course.course_code],
      section: [course.section],
      //course_name: [course.course_name],
      obe_component_id: [component._id],
      component_type: [component.component_type],
      term: [component.term],
      students: this.fb.array(this.buildMarkAssignItemForm(max_mark_settings, course, data)),
      course_file: []
    });
  }

  buildMarkAssignItemForm(max_mark_settings: any[], course: any, data: any[]): any[] {
    const item_to_return = [];
    if (!data) {
      data = [];
    }

    for (const data_item of data) {
      let total = 0;
      for (const course_mark of data_item.course_marks) {
        total = Number((total + Number(course_mark.mark)).toFixed(2));
      }
      item_to_return.push(this.fb.group({
        sub: [data_item.sub],
        given_name: [data_item.given_name],
        middle_name: [data_item.middle_name],
        roll_no: [data_item.roll_no],
        course_marks: this.fb.array(this.buildMarkItemForm(max_mark_settings, course, data_item.sub, data_item.course_marks)),
        total: [total],
      }));
    }

    return item_to_return;
  }

  buildMarkItemForm(max_mark_settings: any[], course: any, sub: string, data: any[]): any[] {
    const item_to_return = [];
    if (!data) {
      data = [];
    }

    for (const data_item of data) {
      const co_max_settings = max_mark_settings.find(c => c.co_id === data_item.co_id);
      let max_mark = 0;
      if (co_max_settings) {
        max_mark = co_max_settings.max_mark;
      }
      item_to_return.push(this.fb.group({
        sub: [sub],
        course_code: [course.course_code],
        obe_component_id: [data_item.obe_component_id],
        co_id: [data_item.co_id],
        mark: [data_item.mark],
        max_mark: [max_mark],
        semester: [this.component_search_form.value.semester],
        enrolled_year: [this.component_search_form.value.enrolled_year],
      }));
    }

    return item_to_return;
  }

  async onMarkAssignCloseClick(): Promise<void> {
    this.resetMarkAssignForm();
    JQueryHelper.closeModal('#modal-popup-assignmark');
  }
  resetMarkAssignForm(): void {
    this.assign_mark_map_form = undefined;
    this.selected_course = undefined;
    this.selected_component = undefined;
  }

  async onMarkAssignSaveClick(student: any, course_mark_form: FormGroup): Promise<void> {
    try {
      let has_error = false;
      if (course_mark_form.value.mark > course_mark_form.value.max_mark) {

        course_mark_form.patchValue({
          mark: 0
        });
        has_error = true;
      }
      let total = 0;
      for (const course_mark of student.value.course_marks) {
        total = total + Number(course_mark.mark);
      }
      student.value.total = total;

      if (has_error && !course_mark_form.value._id) {
        return;
      }
      this.course_mark_map_form_loading = true;
      const service_response = await this.restService.createCourseCoCOMMark(course_mark_form.value);
      //Which V2 API to save student mark assignment??
      if (service_response && service_response.success) {

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_mark_map_form_loading = false;
    }
  }



  //file bulk upload
  async bulkCourseCoCOMMark(e: any, form: FormGroup, key: string): Promise<void> {

    try {
      const data = {
        "course_code": this.assign_mark_map_form.value.course_code,
        "obe_component_id": this.assign_mark_map_form.value.obe_component_id,
        "semester": this.component_search_form.value.semester,
        "enrolled_year": this.component_search_form.value.enrolled_year
      }
      const query_data = new HttpParams({ fromObject: data }).toString()

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.bulkCourseCoCOMMark(formData, query_data);
      //Which V2 API to upload new file??
      if (response.success && response.data) {
        alert("File Uploaded Successfully");
        this.onAssignMarkClick(this.selected_course, this.selected_component);
      }
    } catch (error) {

    } finally {

    }
  }


  // CAS report - attainment

  async onCasReportClick(course: any): Promise<void> {
    try {
      this.selected_course = course;
      this.cas_report_data_loading = true;
      /* const search_query: any = {
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        course_code: course.course_code,
      };
      if (course.section_id) {
        search_query.section_id = course.section_id;
      }
      if (course.common_programme_id) {
        search_query.common_programme_id = course.common_programme_id;
      }
      if (course.common_section_id) {
        search_query.common_section_id = course.common_section_id;
      }
       */
      const search_query: any = {
        "staff_map_id": course._id
      }
      //const service_response = await this.restService.getCASReport(search_query);
      const service_response = await this.restService.getAttainmentReport(search_query);
      //Which V2 API to get CAS Report
      if (service_response && service_response.success) {
        this.cas_report_data = service_response.data;
        JQueryHelper.openModal('#modal-popup-attainmentreport', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.cas_report_data_loading = false;
    }
  }

  async onCasReportCloseClick(): Promise<void> {
    this.selected_course = undefined;
    JQueryHelper.closeModal('#modal-popup-attainmentreport');
  }

  // Consolited report

  async onCOSReportClick(course: any): Promise<void> {
    try {
      this.selected_course = course;
      this.cos_report_data_loading = true;
      /* const search_query: any = {
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        course_code: course.course_code,
      };
      if (course.section_id) {
        search_query.section_id = course.section_id;
      }
      if (course.common_programme_id) {
        search_query.common_programme_id = course.common_programme_id;
      }
      if (course.common_section_id) {
        search_query.common_section_id = course.common_section_id;
      } */
      //const service_response = await this.restService.getCOSReport(search_query);
      //Which V2 API to get COS Report?
      const search_query: any = {
        "staff_map_id": course._id
      }
      const service_response = await this.restService.getConsolidatedReport(search_query);
      if (service_response && service_response.success) {
        this.cos_report_data = service_response.data;
        JQueryHelper.openModal('#modal-popup-consolidatedreport', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.cos_report_data_loading = false;
    }
  }

  async onCOSReportCloseClick(): Promise<void> {
    this.selected_course = undefined;
    JQueryHelper.closeModal('#modal-popup-consolidatedreport');
  }


  // assign students to course
  async onViewAssignStudentsClick(course: any): Promise<void> {
    this.selected_course = course;
    this.buildAssignStudentForm(course);
    this.getAssignedStudentsForCourse(course);
    JQueryHelper.openModal('#modal-popup-assignstudents', { keyboard: false, backdrop: 'static' });
  }
  //assign students for elective courses
  async getAssignedStudentsForCourse(course: any): Promise<void> {
    try {
      this.assigned_students_list_loading = true;
      const query = {
        course_code: course.course_code,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester
      };

      const service_response = await this.restService.getAssignedStudentsForCourse(query);
      //Which V2 API to get student assigned to course?
      if (service_response && service_response.success) {
        this.assigned_students_list = service_response.data;
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.assigned_students_list_loading = false;
    }
  }

  buildAssignStudentForm(course: any): void {
    this.assign_student_form = this.fb.group({
      roll_no: [''],
      course_code: [course.course_code],
    });
  }

  async onAssignStudentsClick(): Promise<void> {
    try {

      const assign_student_data = this.assign_student_form.value;

      if (!assign_student_data.roll_no) {
        alert('Please fill rollnos');
        return;
      }

      const roll_nos = assign_student_data.roll_no.split(',');
      const roll_nos_filtered = [];
      for (const roll_no of roll_nos) {
        const roll_no_d = roll_no.trim();
        if (roll_no_d) {
          roll_nos_filtered.push(roll_no_d);
        }
      }

      if (roll_nos_filtered.length === 0) {
        alert('Please fill rollnos');
        return;
      }

      this.assign_student_form_loading = true;

      const data_to_save = {
        roll_nos: roll_nos_filtered,
        course_code: assign_student_data.course_code,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester
      };

      //assign student to elective courses
      const service_response = await this.restService.assignStudentsToCourse(data_to_save);
      //Which V2 API to assign student to elective courses
      if (service_response && service_response.success) {
        alert('Saved Successfully');
        this.assign_student_form.patchValue({
          roll_no: ''
        });
        this.getAssignedStudentsForCourse(this.selected_course);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.assign_student_form_loading = false;
    }
  }


  async onViewAssignStudentsCloseClick(): Promise<void> {
    this.selected_course = undefined;
    this.assign_student_form = undefined;
    this.assigned_students_list = [];
    JQueryHelper.closeModal('#modal-popup-assignstudents');
  }

  async removeStudentFromCourse(course_student_map: any): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      course_student_map.loading = true;

      const service_response = await this.restService.removeStudentsFromCourse(course_student_map._id);
      //const service_response = await this.restService.deleteStudentCourseMap(course_student_map._id); //v2 ??
      if (service_response && service_response.success) {
        alert('Deleted Successfully');
        this.getAssignedStudentsForCourse(this.selected_course);
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      course_student_map.loading = false;
    }
  }

  // OBE Survey Questions
  async onViewAssignQuestionsClick(course: any): Promise<void> {
    this.selected_course = course;
    await this.buildAssignQuestionForm(course);
    await this.getSurveyStatusClick(course);
    JQueryHelper.openModal('#modal-popup-survey', { keyboard: false, backdrop: 'static' });
  }

  async buildAssignQuestionForm(course: any): Promise<boolean> {

    try {
      this.question_list_loading = true;


      const query: any = {
        course_code: course.course_code,
        semester: this.component_search_form.value.semester,
        enrolled_year: this.component_search_form.value.enrolled_year
      };

      const question_response = await this.restService.getOBESurveyQuestions(query);
      //Which V2 API to get survey questions
      let data: any[] = [];
      if (question_response.success && question_response.data
        && question_response.data.length > 0) {
        data = question_response.data;
      }
      this.question_mark_map_form = this.fb.group({
        opened: [false],
        questions: this.fb.array(this.buildAssignQuestionItemForm(course, data))
      });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.question_list_loading = false;
    }
    return Promise.resolve(true);
  }

  buildAssignQuestionItemForm(course: any, data: any[]): any[] {
    const item_to_return = [];
    if (!data) {
      data = [];
    }

    const co_limit = 5;
    for (let index = 0; index < co_limit; index++) {
      const co_id = index + 1;
      let saved_co = data.find(c => c.co_id === co_id);

      if (!saved_co) {
        saved_co = {};
      }
      item_to_return.push(this.fb.group({
        _id: [saved_co._id],
        co_id: [co_id],
        question: [saved_co.question],
        course_code: [course.course_code],
        semester: [this.component_search_form.value.semester],
        enrolled_year: [this.component_search_form.value.enrolled_year]
      }));
    }
    return item_to_return;
  }

  async onAssignQuestionsSaveClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure, do you want to save?');
      if (!consent) {
        return;
      }
      const assign_student_data = this.question_mark_map_form.value;
      let hasError = false;
      for (const question of assign_student_data.questions) {
        if (!question.question) {
          alert('Question can\'t be empty for CO : ' + this.commonEnums.rommon_letters[question.co_id]);
          hasError = true;
          break;
        }
      }
      if (hasError) {
        return;
      }
      this.question_mark_map_form_loading = true;
      const service_response = await this.restService.createOrUpdateSurveyQuestionsInBulk(assign_student_data.questions);
      //Which V2 API to save surevey questions
      if (service_response && service_response.success) {
        alert('Saved Successfully');
        this.onAssignQuestionsCloseClick();
      }
    } catch (error) {
      console.log(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.question_mark_map_form_loading = false;
    }
  }

  async onAssignQuestionsCloseClick(): Promise<void> {
    this.selected_course = undefined;
    this.question_mark_map_form = undefined;
    JQueryHelper.closeModal('#modal-popup-survey');
  }


  // survey status

  async getSurveyStatusClick(course: any): Promise<void> {
    try {

      const query = {
        course_code: [course.course_code],
        semester: [this.component_search_form.value.semester],
        enrolled_year: [this.component_search_form.value.enrolled_year]
      };

      this.question_status_map_form_loading = true;
      const service_response = await this.restService.isSurveyStatusOpen(query);
      //Which V2 API to check if survey is open?
      if (service_response && service_response.success) {
        this.question_mark_map_form.patchValue({
          opened: service_response.data
        });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.question_status_map_form_loading = false;
    }
  }

  async onSurveyStatusChangeClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure, do you want to change?');
      if (!consent) {
        return;
      }
      const assign_student_data = this.question_mark_map_form.value;
      let hasError = false;
      for (const question of assign_student_data.questions) {
        if (!question.question) {
          alert('Question can\'t be empty for CO : ' + this.commonEnums.rommon_letters[question.co_id]);
          hasError = true;
          break;
        }
      }
      if (hasError) {
        return;
      }

      const data_to_save = {
        course_code: assign_student_data.questions[0].course_code,
        semester: this.component_search_form.value.semester,
        enrolled_year: this.component_search_form.value.enrolled_year,
        opened: !assign_student_data.opened
      };

      this.question_status_map_form_saving = true;
      const service_response = await this.restService.chanegSurveyStatus(data_to_save);
      //Which V2 API to change survey status
      if (service_response && service_response.success) {
        alert('Changed Successfully');
        this.onAssignQuestionsCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.question_status_map_form_saving = false;
    }
  }


  downloadConCSV() {
    try {
      const data_list: any[] = [];
      console.log(this.cos_report_data);
      let i = 1; //student
      for (const data of this.cos_report_data) {
        var push_data: any = {
          "S.No": i,
          "Roll No": data?.roll_no,
          "Name": data?.given_name + " " + data?.middle_name,
        };
        for (const mark_header of data.mark_details) { //multiple components
          if (mark_header.marks.length > 0) {
            let j = 1; //component_type
            var label = mark_header.label;
            if (mark_header.marks.length > 1) { //if multiple marks exists in each component
              for (const mark of mark_header.marks) { //each mark in the component
                if ((!isNaN(mark.mark as number)) && mark.mark != undefined && mark.mark != null) {
                  push_data[label + "-" + j] = (mark.mark as number)?.toFixed(2);
                }
                else {
                  push_data[label + "-" + j] = "0.00";
                }
                j++;
              }
              //printing total mark of multiple component
              if ((!isNaN(mark_header.total as number)) && mark_header.total != undefined && mark_header.total != null) {
                push_data[label + "-Final"] = (mark_header.total as number)?.toFixed(2);
              }
              else {
                push_data[label + "-Final"] = "0.00";
              }
            }
            else { //if only one mark exist in the component; Ex: Seminar, Total
              if ((!isNaN(mark_header.total as number)) && mark_header.total != undefined && mark_header.total != null) {
                push_data[label] = (mark_header.total as number);//?.toFixed(2);
              }
              else {
                push_data[label] = "0.00";
              }
            }
          }
        }
        console.log(push_data);
        data_list.push(push_data);
        i++;
      }
      CSVHelper.downloadCSV(data_list, 'Mark_Report_' + this.cos_report_data[0]?.course_code + "_" + DateHelper.convertToControlDate(new Date()));
    }
    catch (error) {
      alert("Error downloading CSV File");
      console.log(error);
    }
  }

  printConReport() {

    var window_height = window.screen.height;
    var window_width = window.screen.width;
    let current_date = DateHelper.toDisplayDate(new Date());
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A4 landscape;} .table-cell {height:25px; padding:1 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;}</style>"
    );
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style="margin-left:20px" id="print-header">' +
      '<div class="col-md-2">' +
      '<img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt="college logo" style="width:75px !important; height:50px !important; max-width:none; float:left;"> ' +
      '</div>' +
      '<div class="col-md-8 text-center">' +
      '<h6 style="margin-bottom: 0px !important;"> AYYA NADAR JANAKI AMMAL COLLEGE ' +
      '(Autonomous)</h6>' +
      '<h6 style="margin-bottom: 0px !important;">Sivakasi - 626 124.</h6>' +
      '<b> Course Consolidated Report' +
      '</b>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<div class="text-center"><span><b>' + this.selected_course.course_code + ' - ' + this.selected_course.course_name + '</b></span></div>');
    mywindow.document.write('<div class="text-right"><span><b>Max. Mark:' + this.selected_course.max_internal_mark + '</b></span></div>');
    mywindow.document.write(document.getElementById('con-report').innerHTML);
    mywindow.document.write(
      '<div class="row" style="margin-top:30px">' +
      '<div class="col-md-6" > ' +
      '<span style="margin-left:20px"> Sivakasi, </span><br>' +
      '<span style="margin-left:20px">' + current_date + ' </span>' +
      '</div>' +
      '<div class="col-md-6 text-right"> ' +
      '<span style="margin-right:20px">Internal Examiner</span><br>' +
      '<span style="margin-right:20px">(' +
      this.getStaffName(this.selected_course?.staff)
      + ')</span>' +
      '</div>' +
      '</div>');
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  printFoilCard() {
    let current_date = DateHelper.toDisplayDate(new Date());
    var records_length = this.cos_report_data.length;
    var table_string: string = '';
    var table_total: number = 0;
    for (var table_count = 0; table_count < 120; table_count += 30) {
      table_total = 0;
      if (records_length > table_count) {
        table_string +=
          '<table style="width:20%; margin-left: 3%; border-collapse: collapse;" ' +
          'class="table table-bordered">' +
          '<thead><tr><th>Roll No.</th><th>Mark</th></tr></thead>' +
          '<tbody>';
        for (var index = table_count; index < table_count + 30; index++) {
          const mark_details: any[] = this.cos_report_data[index]?.mark_details;
          if (mark_details) {
            var total = mark_details.find(x => x.label == "INTERNAL MARKS")?.total;
            if (total == null || total == undefined || isNaN(total)) total = 0;
            table_total += total;
            table_string += '<tr>' +
              '<td style="height: 32px; padding:3; margin:0; text-align:center">' + this.cos_report_data[index].roll_no + '</td>' +
              '<td style="height: 32px; padding:3; margin:0; text-align:center">' + total + '</td></tr>';
          }
          else {
            table_string += '<tr>' +
              '<td style="height: 32px; padding:3; margin:0; text-align:center"></td>' +
              '<td style="height: 32px; padding:3; margin:0; text-align:center"></td></tr>';
          }
        }
        table_string += '<tr>' +
          '<td style="height: 32px; padding:3; margin:0; text-align:center"><b>Total</b></td>' +
          '<td style="height: 32px; padding:3; margin:0; text-align:center"><b>' + table_total + '</b></td></tr>';

        table_string += '</tbody></table>';
      }

    }

    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h5 style = "margin-top:5px"> Internal Examination - November, 2022' +
      ' </h5><h6>Foil Card</h6>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h6 class= "text-center"><b>' + this.selected_course.course_code + ' - ' + this.selected_course.course_name + '</b></h6>');
    mywindow.document.write('<h6 class= "text-right" style="margin-right:100px"><b>Max. Mark:' + this.selected_course.max_internal_mark + '</b></h6>');
    mywindow.document.write('<div class="row">');
    mywindow.document.write(table_string);
    mywindow.document.write('</div>');

    mywindow.document.write(
      '<div class="row" style="margin-top:30px">' +
      '<div class="col-md-6"> ' +
      '<span style="margin-left:20px"> Sivakasi, </span><br>' +
      '<span style="margin-left:20px">' + current_date + ' </span>' +
      '</div>' +
      '<div class="col-md-6 text-right"> ' +
      '<span style="margin-right:100px">Internal Examiner</span><br>' +
      '<span style="margin-right:100px">(' +
      this.getStaffName(this.selected_course.staff)
      + ')</span>' +
      '</div>' +
      '</div>');
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  getStaffName(staff) {
    return ((staff?.salutation != undefined) ? (staff?.salutation) : '') + " " + ((staff?.middle_name != undefined) ? staff?.middle_name : '') + " " + ((staff?.given_name != undefined) ? staff?.given_name : '')
  }

  downloadFoilCard() {
    let i = 1;
    const data_list: any[] = [];
    for (const data of this.cos_report_data) {
      const mark_details: any[] = data.mark_details;
      var total = mark_details.find(x => x.label == "INTERNAL MARKS")?.total;
      if (total == null || total == undefined || isNaN(total)) total = 0;
      var push_data: any = {
        "S.No": i,
        "Roll No": data?.roll_no,
        "Mark": total,
      };
      data_list.push(push_data);
      i++;
    }
    CSVHelper.downloadCSV(data_list, 'Foil Card_' + this.cos_report_data[0]?.course_code + "_" + DateHelper.convertToControlDate(new Date()));
  }

  async submitForReview(course, component) {
    const mark_search_query = {
      staff_map_id: course._id,
      obe_component_id: component._id
    };
    var _id: string = "";
    const service_response = await this.restService.searchStudentCourseMap(mark_search_query);
    if (service_response && service_response.success && service_response.data.data && service_response.data.data.length > 0) {
      const submission_details = service_response.data.submission_details;
      if (submission_details != null && submission_details.stage != "STAFF") {
        alert("Review request already submitted"); return;
      }
      if (submission_details != null && submission_details.stage == "STAFF") //request already created
      {
        _id = submission_details._id;
      }
    }
    console.log(course);
    console.log(component);
    var submit_data: any = {
      obe_component_id: component._id,
      course_code: course.course_code,
      component_type: component.component_type,
      term: component.term,
      semester: component.semester,
      enrolled_year: course.enrolled_year,
      stage: "OBE_MEMBER"
    }
    if (_id != "") {
      submit_data._id = _id;
    }
    if (course.virtual_section) {
      submit_data.virtual_section = course.virtual_section;
    }
    else {
      submit_data.programme_id = course.class_taking_programme_id;
      submit_data.section = course.class_taking_section;
    }
    try {
      component.submit_loading = true;
      console.log(submit_data);
      const consent = confirm("Are you sure want to submit this component for review? Once submitted you cannot make any changes to mark list!");
      if (!consent) return;
      const service_response = await this.restService.submitForReview(submit_data);
      if (service_response && service_response.success) {
        alert('Review Request Submitted Successfully');
        this.onSearchClick();
      }
      else {
        alert("Could not submit request");
      }
    }
    catch (error) {
      console.log(error);
      CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save);
    }
    finally {
      component.submit_loading = false;
    }
  }


  getClassName(enrolled_year: number, programme_id?: string, section?: string, virtual_section?: string, programme_type_id?: string) {
    if (virtual_section) {
      return "Virtual Section " + virtual_section;
    }
    else {
      var programme_name = this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
      return this.commonEnums.getClassName(enrolled_year, programme_name, section, programme_type_id);
    }
  }


  downloadCASCSV() {
    let i = 1;
    const data_list: any[] = [];
    for (const data of this.cas_report_data.data) {
      var push_data: any = {
        "S.No": i,
        "Roll No": data?.roll_no,
        "Name": data.given_name + " " + data.middle_name,
      };
      for (var j = 0; j < data.COCOMMarks.length; j++) {
        push_data['CO' + (j + 1)] = data.COCOMMarks[j].mark
      }
      push_data['Total Mark'] = data.COCOMMarks_Total;
      data_list.push(push_data);
      i++;
    }
    CSVHelper.downloadCSV(data_list, 'CAS_' + this.selected_course.course_code + "_" + DateHelper.convertToControlDate(new Date()));
  }

}
