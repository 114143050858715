<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Programme Outcome Report</p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!--Programme-->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolled year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <div class="row" *ngIf="display_card && canDisplayReport()">
                          <div class="col-md-12" style="text-align: right;">
                              <button class="btn btn-sm btn-secondary mg-r-10" (click)="downloadExcel()">Download
                                  Excel</button>
                          </div>
                        </div>

                        <!--Report Table-->
                        <ng-container *ngIf="display_card && canDisplayReport()">
                          <div class="container">
                              <div class="table-responsive" style="margin-top: 20px;">
                                  <table class="table table-bordered" id="co-attainment-report-table">
                                      <tbody>
                                          <tr>
                                              <th colspan="12" style="text-align: center;">AYYA NADAR JANAKI AMMAL
                                                  COLLEGE (Autonomous), SIVAKASI</th>
                                          </tr>
                                          <tr>
                                              <th colspan="12" style="text-align: center;">Programme:
                                                  {{report_data.programme_name}}</th>
                                          </tr>
                                          <tr>
                                              <th colspan="12" style="text-align: center;">Enrolled Year:
                                                  {{report_data.enrolled_year}}</th>
                                          </tr>
                                          <tr>
                                              <th colspan="12" style="text-align: center;">Direct Attainment of CO's</th>
                                          </tr>

                                        <tr>
                                            <th>
                                                Component & Subject code
                                            </th>
                                            <th>
                                                Title of the course
                                            </th>
                                            <th colspan="5">
                                              Final marks alloted for each
                                              CO in the exams
                                            </th>
                                            <th colspan="5">
                                              Class Average of COs secured by the
                                              Students in the Exams
                                            </th>
                                          </tr>

                                          <tr>
                                            <td colspan="2"></td>

                                            <!-- Final marks CO Levels -->
                                            <ng-container *ngFor="let co_val of co_levels">
                                              <th >{{ co_val }}</th>
                                            </ng-container>

                                            <!-- Class average CO Levels -->
                                            <ng-container *ngFor="let co_val of co_levels">
                                              <th >{{ co_val }}</th>
                                            </ng-container>
                                          </tr>

                                          <!--Report Rows-->
                                          <ng-container *ngFor="let partData of report_data?.part_wise_data; let i = index;">
                                            <ng-container *ngFor="let semData of partData?.semester_wise_data; let j = index;">
                                              <tr>
                                                <th>{{partData.part | uppercase}}</th>
                                                    <th>{{semData.semester}} Semester</th>
                                                    <ng-container *ngFor="let row of getEmptyRows(10)">
                                                        <td></td>
                                                    </ng-container>
                                              </tr>

                                              <tr *ngFor="let course of semData.course_wise_data; let k = index">
                                                <td>{{course.course_code}}</td>
                                                <td>{{course.course_name}}</td>
                                                <ng-container
                                                    *ngFor="let twm of course.total_weightage | keyvalue; let l = index;">
                                                    <td>{{ course.total_weightage[twm.key]}}</td>
                                                </ng-container>
                                                <ng-container
                                                    *ngFor="let cam of course.class_average | keyvalue; let m = index;">
                                                    <td>{{ course.class_average[cam.key]}}</td>
                                                </ng-container>
                                              </tr>
                                            </ng-container>

                                            <tr>
                                              <th colspan="2">Total</th>
                                              <ng-container *ngFor="let tcm of partData.total_cos_marks | keyvalue; let n = index;">
                                                <td>{{ partData.total_cos_marks[tcm.key] }}</td>
                                              </ng-container>
                                              <ng-container *ngFor="let tca of partData.total_class_average_marks | keyvalue; let o = index;">
                                                <td>{{ partData.total_class_average_marks[tca.key] }}</td>
                                              </ng-container>
                                            </tr>

                                            <tr>
                                              <th colspan="2">CO Marks levelled to 10</th>
                                              <ng-container *ngFor="let row of getEmptyRows(5)">
                                                <td></td>
                                              </ng-container>
                                              <ng-container *ngFor="let clm of partData.co_marks_level | keyvalue; let p = index;">
                                                <td>{{ partData.co_marks_level[clm.key] }}</td>
                                              </ng-container>
                                            </tr>
                                            <tr>
                                              <th colspan="2">Grades of the COs</th>
                                              <ng-container *ngFor="let row of getEmptyRows(5)">
                                                <td></td>
                                              </ng-container>
                                              <ng-container *ngFor="let fcg of partData.grade_of_cos | keyvalue; let q = index;">
                                                <td>{{ partData.grade_of_cos[fcg.key] }}</td>
                                              </ng-container>
                                            </tr>
                                          </ng-container>

                                          <tr>
                                            <th colspan="2">Grand total COs in all semesters</th>
                                            <ng-container *ngFor="let gtcm of report_data?.grand_total_cos_marks | keyvalue; let r = index;">
                                              <th>{{ report_data?.grand_total_cos_marks[gtcm.key] }}</th>
                                            </ng-container>
                                            <ng-container *ngFor="let gtca of report_data?.grand_total_class_average| keyvalue; let s = index;">
                                              <th>{{ report_data?.grand_total_class_average[gtca.key] }}</th>
                                            </ng-container>
                                          </tr>
                                          <tr>
                                            <th colspan="2">Final CO Marks Levelled to 10</th>
                                            <ng-container *ngFor="let row of getEmptyRows(5)">
                                              <td></td>
                                            </ng-container>
                                            <ng-container *ngFor="let fcom of report_data?.final_co_level_marks | keyvalue; let t = index;">
                                              <th>{{ report_data?.final_co_level_marks[fcom.key] }}</th>
                                            </ng-container>
                                          </tr>
                                          <tr>
                                            <th colspan="2">Final COs grade</th>
                                            <ng-container *ngFor="let row of getEmptyRows(5)">
                                              <td></td>
                                            </ng-container>
                                            <ng-container *ngFor="let fcogr of report_data?.final_cos_grade | keyvalue; let t = index;">
                                              <th>{{ report_data?.final_cos_grade[fcogr.key] }}</th>
                                            </ng-container>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>



                      </ng-container>
                      <!--Report Table End -->

                      <!-- <ng-container *ngIf="display_card && canDisplayReport() && report_data?._id">
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-md-6">
                                      <div class="form-group">
                                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Observations
                                              by OBEMC</label>
                                          <textarea class="form-control"
                                              [(ngModel)]="report_data.observations_by_obemc" rows="3" cols="10"
                                              placeholder="Enter Observations given by OBEMC"></textarea>
                                      </div>
                                  </div>

                                  <div class="col-md-6">
                                      <div class="form-group">
                                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Suggestions by
                                              OBEMC</label>
                                          <textarea class="form-control"
                                              [(ngModel)]="report_data.suggestions_by_obemc" rows="3" cols="10"
                                              placeholder="Enter Suggestions given by OBEMC"></textarea>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </ng-container> -->

                    </div>
                  </div>
              </div>
          </div>
        </div>
    </div>

<!-- /main-content -->
<app-footer></app-footer>
