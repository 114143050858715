<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Timetable & Attendance Report</p>
                    </div>


                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <button class="btn btn-info btn-sm" style="float:right"
                                    (click)="routeTo()">Attendance Report</button>
                            </div>
                        </div>
                        <div class="week mg-b-20">
                            <kendo-datepicker [weekNumber]="true" (valueChange)="dateChanged($event)"
                                calendarType="classic" [animateCalendarNavigation]="true" [value]="start_date"
                                displayFormat="dd/MM/yyyy">
                                <ng-template kendoCalendarMonthCellTemplate let-date>
                                    <span class="current_date" *ngIf="isCurrentDate(date)">{{ date.getDate() }}</span>
                                    <span [ngClass]="isDateInWeekRange(date)">{{ date.getDate() }}</span>
                                </ng-template>
                            </kendo-datepicker>
                            <label style="margin-left:20px">For week {{getDate(start_date)}} to
                                {{getDate(end_date)}}</label>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>Day</th>
                                        <th *ngFor="let head of hour_header | slice:0:max_hour">
                                            {{head.hour_name}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="timetable!.length>0">
                                        <ng-container
                                            *ngFor="let day of timetable;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td>
                                                    {{day.day}}<br>
                                                    {{getDisplayDate(i)}}<br>
                                                    <!-- {{day.no_of_hours_present}} / {{day.no_of_hours}} -->
                                                </td>
                                                <ng-container
                                                    *ngFor="let head of hour_header | slice:0:max_hour; let j=index;trackBy:fromDataResolver.identify;">
                                                    <td>
                                                        <span
                                                            class="course_code">{{getHourCourseCode(i,head.hour_number)}}</span><br>
                                                        {{getHourCourseName(i,head.hour_number)}} <br>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                {{getHourStaffId(i,head.hour_number)}}
                                                            </div>
                                                            <div class="col-md-6">
                                                                {{getHourRoomNo(i,head.hour_number)}}
                                                            </div>
                                                        </div>

                                                        <ng-container *ngIf="isAttendanceMarked(i,head.hour_number)">
                                                            <fa-icon [icon]="fonts.faCheck" style="color: green;"
                                                                class="mg-r-10"
                                                                *ngIf="canDisplayIcon(i,head.hour_number,'PRESENT')">
                                                            </fa-icon>
                                                            <fa-icon [icon]="fonts.faTimes" style="color: red;"
                                                                class="mg-r-10"
                                                                *ngIf="canDisplayIcon(i,head.hour_number,'ABSENT')">
                                                            </fa-icon>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isAttendanceMarked(i,head.hour_number)">
                                                            <span>NA</span>
                                                        </ng-container>
                                                        <span *ngIf="isOdGiven(i,head.hour_number)"
                                                            style="float: right;">OD Given</span>
                                                        <span *ngIf="isLeaveApplied(i,head.hour_number)"
                                                            style="float: right;">Leave Applied</span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!timetable_loading && timetable.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="timetable_loading && timetable.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="attendance-modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Student Attendance Report</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="report_search_form">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                                Date</label>
                                            <input type="date" class="form-control" formControlName="start_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                                                Date</label>
                                            <input type="date" class="form-control" formControlName="end_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <button class="btn btn-info mg-t-30" (click)="getAttendanceReport()"
                                            *ngIf="!report_data_loading">Get
                                            Report</button>
                                        <button class="btn btn-info mg-t-30" disabled
                                            *ngIf="report_data_loading">Loading...</button>
                                    </div>
                                </div>
                            </form>

                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th *ngFor="let day of attendance_report; let i = index;">
                                                {{day.day_date | date:commonEnums.date_format}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td *ngFor="let day of attendance_report; let i = index;"
                                                (click)="viewAttendance(day)" style="cursor:pointer">
                                                {{day.no_of_hours_present}} / {{day.no_of_hours}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="table-responsive" *ngIf="attendance_table.length>0">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Hour No.</th>
                                            <th>Course Name (Code)</th>
                                            <th>Status</th>
                                            <th>Marked By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let hour of attendance_table; let i = index;">
                                            <td>{{hour.hour_number}}</td>
                                            <td>{{hour.course_name}} ({{hour.course_code}})</td>
                                            <td>
                                                <span *ngIf="hour.is_marked">
                                                    {{hour.is_present?'Present':'Absent'}} <br>
                                                    {{hour.od_given?'(OD Given)':''}}
                                                    {{hour.leave_applied?'Leave Applied':''}}
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="hour.is_marked">
                                                    {{hour.marked_by_name}} ({{hour.marked_by_id}})
                                                </span>
                                                <span *ngIf="!hour.is_marked">
                                                    <i>Pending</i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeReportClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>



    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>