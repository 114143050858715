import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { Modal } from 'src/app/components/ModalInterface';

@Component({
  selector: 'app-hostel-fee-header',
  templateUrl: './hostel-fee-header.component.html',
  styleUrls: ['./hostel-fee-header.component.scss']
})
export class HostelFeeHeaderComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Hostel Fees Settings List';
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_type_id: string = ""; //ngModel
  months: {
    name: string, val: number
  }[] = [
      { name: "January", val: 1 }, { name: "February", val: 2 },
      { name: "March", val: 3 }, { name: "April", val: 4 },
      { name: "May", val: 5 }, { name: "June", val: 6 },
      { name: "July", val: 7 }, { name: "August", val: 8 },
      { name: "September", val: 9 }, { name: "October", val: 10 },
      { name: "November", val: 11 }, { name: "December", val: 12 },
    ]
    ;
  settings_list_loading: boolean = false;
  settings_list: SettingInterface[] = [];
  setting: SettingInterface = {} as SettingInterface;
  programme_list: any[] = [];
  collection_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  search_btn_pressed: boolean = false;
  datechange: boolean = false;
  fine_list_loading: boolean = false;
  fine_list: FineRuleInterface[] = [];
  bulk_render_data: Modal = {
    return_type: "FILE",
    file_upload_only: false,
    btn_size: "btn-sm",
    modal_id: "hostel-fee-bulk-upload",
    modal_button_text: "Student Hostel Fees Bulk Upload",
    modal_header: "Student Hostel Fees Bulk Upload",
    list_name: "Student Hostel Fees Upload",
    download_note: "",
    csv_data: {
      columns: [
        { column_name: "roll_no", column_type: "required" },
        { column_name: "name", column_type: "required" },
        { column_name: "amount", column_type: "required" },
        { column_name: "extra_charge_amount", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Roll No", "Name", "Amount", "Extra Charge Amount"],
      column_values: ["21PA02", "AAA", "1000", "50"]
    },
    show_loader: false
  };
  fee_header_id: string = '';
  ngOnInit(): void {
    /* this.getProgrammeDataList();
    this.getProgrammeTypeList(); */
    this.buildForm();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.getFeesSettingsList();
    this.getFineList();
  }

  async getFineList() //get from API
  {
    try {
      this.fine_list_loading = true;
      this.fine_list = [];
      const service_response = await this.restService.getFineList();
      if (service_response && service_response.success) {
        this.fine_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.fine_list_loading = false;
    }

  }
  //Programme Filter
  /* async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
      console.log(this.programme_list);
    } catch (error) {
      alert('Error while read data');
    }
    console.log(this.programme_list);
  }

  async onFilterSelectChange(programme_type_id: string, event: any) {

  } */

  /* onSearchClick() {
    if (this.programme_type_id != '') {
      this.getFeesSettingsList();
      this.search_btn_pressed = true;
    }
    else {
      alert("Please Choose Valid Programme Type");
    }
  } */

  async getFeesSettingsList() {
    this.settings_list = [];
    this.settings_list_loading = true;
    try {
      const service_response = await this.restService.getHostelFeesHeaderList({});
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.settings_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        term_name: "",
        id: "",
        start_date: "",
        end_date: "",
        description: "",
        extra_charge_amount: 0,
        month: "0",
        year: 0,
        active: false,
        fine_settings_id: "",
      };
    }
    this.collection_form = this.formBuilder.group({
      id: [this.setting.id],
      start_date: [this.setting.start_date != "" ? (this.setting?.start_date?.substring(0, 10)) : '', [Validators.required]],
      end_date: [this.setting.end_date != "" ? (this.setting?.end_date?.substring(0, 10)) : '', [Validators.required]],
      description: [this.setting.description, [Validators.required]],
      extra_charge_amount: [this.setting.extra_charge_amount, [Validators.required]],
      year: [this.setting.year, [Validators.required]],
      month: [this.setting.month, [Validators.required]],
      term_name: [this.setting.term_name],
      active: [this.setting.active],
      fine_settings_id: [this.setting.fine_settings_id ? this.setting.fine_settings_id : ""]
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    this.setting_id = setting.id; //term_name
    var settings = this.settings_list.find(x => x.id == setting.id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as SettingInterface;
    this.collection_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      this.collection_form.get('term_name')?.setValue(this.months.find(x => x.val == this.collection_form.get('month')?.value)?.name + " " + this.collection_form.get('year')?.value);
      var form_data = this.collection_form.value;
      form_data.start_date = new Date(this.collection_form.value.start_date).toISOString();
      form_data.end_date = new Date(this.collection_form.value.end_date).toISOString();
      //form_data.programme_type_id = this.programme_type_id;
      //form_data.fine_settings_id = "";
      //form_data.active=true;
      console.log(form_data);
      if (this.collection_form.value.id) {
        const res = await this.restService.updateHostelFeesHeader(this.collection_form.value.id, form_data);
        if (!res) {
          alert('Failed to Update Hostel Fee settings');
        }
        alert('Updated Hostel Fee Settings Successfully');
      }
      else {
        const res = await this.restService.createHostelFeesHeader(form_data);
        if (!res) {
          alert('Failed to Create Hostel Fee settings');
        }
        alert('Created Hostel Fee Settings Successfully');
      }
      this.getFeesSettingsList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    const res = await this.restService.deleteHostelFeeHeader(this.collection_form.get('id')!.value);
    if (res.success) {
      alert('Deleted Hostel Fee Settings Successfully');
      this.getFeesSettingsList();
    }
    else {
      alert('Failed to Delete Hostel Fee settings');
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.collection_form.touched && this.collection_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.collection_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  navigateToHostelFee(setting) {
    this.router.navigate(["/admin/hostel/hostel-fee-setting"], {
      queryParams: {
        setting_id: setting.id,
      }
    }); //pass setting.id
  }

  async bulkUpload(e: any) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      console.log(this.fee_header_id);
      this.bulk_render_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.uploadBulkHostelFee(formData, this.fee_header_id);
      if (response.success && response.data) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_data.show_loader = false;
      this.fee_header_id = "";
    }

  }

  setId(setting: SettingInterface) {
    console.log(setting._id);
    this.fee_header_id = setting._id;
  }

  getBulkRenderData(i: number): Modal {
    return {
      return_type: "FILE",
      file_upload_only: false,
      btn_size: "btn-sm",
      modal_id: "hostel-fee-bulk-upload-" + i,
      modal_button_text: "Student Hostel Fees Bulk Upload",
      modal_header: "Student Hostel Fees Bulk Upload",
      list_name: "Student Hostel Fees Upload",
      download_note: "",
      csv_data: {
        columns: [
          { column_name: "roll_no", column_type: "required" },
          { column_name: "name", column_type: "required" },
          { column_name: "amount", column_type: "required" },
          { column_name: "extra_charge_amount", column_type: "required" },
        ]
      },
      table_data: {
        column_names: ["Roll No", "Name", "Amount", "Extra Charge Amount"],
        column_values: ["21PA02", "AAA", "1000", "50"]
      },
      show_loader: false
    };
  }


}

interface SettingInterface {
  _id?: string,
  id?: string,
  term_name: string
  month: string,
  year: number,
  start_date: string,
  end_date: string,
  fine_settings_id?: string,
  active: boolean,
  description: string,
  extra_charge_amount: number,
}

interface FineRuleInterface {
  _id: string,
  fine_name: string,
  re_admission_fee: number,
  fine_rules: {
    day_limit: number,
    fine_amount: number,
    is_per_day: boolean
  }[],
  post_fine_rule: string
}
