import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-timetable-configuration',
  templateUrl: './timetable-configuration.component.html',
  styleUrls: ['./timetable-configuration.component.scss']
})
export class TimetableConfigurationComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Time Table Configuration';
  search_year: number = 0;

  settings_list_loading: boolean = false;
  settings_list: TimetableConfigurationInterface[] = [];
  setting: TimetableConfigurationInterface = {} as TimetableConfigurationInterface;
  save_loading: boolean = false;
  delete_loading: boolean = false;

  configuration_form: FormGroup = {} as FormGroup;
  setting_id: string = "";

  ngOnInit(): void {

  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
  }

  onSearchClick() {
    if (this.search_year != 0) {
      this.getAcademicYearSettingsList();
    }
    else {
      alert("Please Choose Valid Year");
    }
  }

  async getAcademicYearSettingsList() {
    this.settings_list = [];
    this.settings_list_loading = true;
    try {
      const service_response = await this.restService.getConfigurationList();
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.settings_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        academic_year: "",
        year_start: 0,
        year_end: 0,
        is_active: false,
        semesters: []
      };
    }
    this.configuration_form = this.formBuilder.group({
      _id: [this.setting._id],
      academic_year: [this.setting.academic_year],
      year_start: [this.setting.year_start],
      year_end: [this.setting.year_end],
      is_active: [this.setting.is_active],
      semesters: this.formBuilder.array(this.buildSemesterData(this.setting.semesters)),
    });
  }

  buildSemesterData(semester_data: SemesterConfigurationInterface[]): any {
    if (!semester_data) {
      semester_data = [];
    }
    var semester_list_array: any[] = [];
    if (semester_data && semester_data.length > 0) {
      var section_count = 0;
      for (const semester of semester_data) {
        semester_list_array.push(this.formBuilder.group({
          semester_number: [semester.semester_number, [Validators.required]],
          semester_name: [semester.semester_name, [Validators.required]],
          start_date: [DateHelper.convertToControlDate(new Date(semester.start_date)), [Validators.required]],
          end_date: [DateHelper.convertToControlDate(new Date(semester.end_date)), [Validators.required]],
          max_time_allowed: [semester.max_time_allowed, [Validators.required]],
          max_time_allowed_late_entry: [semester.max_time_allowed_late_entry, [Validators.required]]
        }));
        section_count++;
      }
    }
    const remaining_count = 1 - semester_data.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        semester_list_array.push(this.formBuilder.group({
          semester_number: [0, [Validators.required]],
          semester_name: ["", [Validators.required]],
          start_date: ["", [Validators.required]],
          end_date: ["", [Validators.required]],
          max_time_allowed: ["", [Validators.required]],
          max_time_allowed_late_entry: ["", [Validators.required]]
        }));
      }
    }
    console.log(semester_list_array);
    return semester_list_array;
  }

  get semesterList() {
    return ((this.configuration_form as FormGroup).get('semesters') as FormArray).controls;
  }

  addSemester() {
    ((this.configuration_form as FormGroup).get('semesters') as FormArray).push(this.formBuilder.group({
      semester_number: [0, [Validators.required]],
      semester_name: ["", [Validators.required]],
      start_date: ["", [Validators.required]],
      end_date: ["", [Validators.required]],
      max_time_allowed: ["", [Validators.required]],
      max_time_allowed_late_entry: ["", [Validators.required]]
    }))
  }

  deleteSemester(i: number) {
    const consent = confirm("Are you sure want to delete this semester?");
    if (!consent) {
      return;
    }
    ((this.configuration_form as FormGroup).get('semesters') as FormArray).removeAt(i);
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: TimetableConfigurationInterface) {
    this.setting_id = setting._id; //term_name
    var settings = this.settings_list.find(x => x._id == setting._id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as TimetableConfigurationInterface;
    this.configuration_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data: TimetableConfigurationInterface = this.configuration_form.value;
      form_data.academic_year = form_data.year_start + "-" + form_data.year_end;
      console.log(form_data);
      var semesters: SemesterConfigurationInterface[] = form_data.semesters;
      var i = 1;
      semesters.forEach(semester => {
        semester.semester_number = i;
        semester.start_date = new Date(semester.start_date).toISOString();
        semester.end_date = new Date(semester.end_date).toISOString();
        i++;
      })
      form_data.semesters = semesters;
      console.log(form_data);
      this.save_loading = true;
      if (this.configuration_form.value._id) {
        const res = await this.restService.updateConfiguration(this.configuration_form.value._id, form_data);
        if (!res) {
          alert("Could not update Exam Configuration");
        }
        alert("Exam Configuration Updated Successfully");
      }
      else {
        const res = await this.restService.createConfiguration(form_data);
        if (!res) {
          alert("Could not create Exam Configuration");
        }
        alert("Exam Configuration Created Successfully");
      }
      this.getAcademicYearSettingsList();
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    try {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      this.delete_loading = true;
      const res = await this.restService.deleteConfiguration(this.configuration_form.get('id')!.value);
      if (res.success) {
        alert("Exam Configuration Deleted Successfully");
        this.getAcademicYearSettingsList();
      }
      else {
        alert("Could not delete Exam Configuration");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.configuration_form.touched && this.configuration_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.configuration_form.status !== 'VALID';
  }



  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }


}

interface TimetableConfigurationInterface {
  _id?: string
  academic_year: string,
  year_start: number,
  year_end: number,
  is_active?: boolean,
  semesters: SemesterConfigurationInterface[]
}

interface SemesterConfigurationInterface {
  semester_number?: number,
  semester_name?: string,
  start_date: string,
  end_date: string,
  max_time_allowed: string,
  max_time_allowed_late_entry: string,
}

