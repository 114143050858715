<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Generate Non-Academic Holistic Attainment Programme wise
                            Scores
                        </p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!--Programme-->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="filter_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section._id">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolled year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            semester</label>
                                        <select formControlName="semester" class="form-control"
                                            (change)="onFilterSelectChange('semester',$event)">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div> -->


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 3%;" rowspan="2">S.No</th>
                                        <th style="width:7%" rowspan="2">Roll No.</th>
                                        <th style="width: 10%;" rowspan="2">Name</th>
                                        <th *ngFor="let head of table_data[0]?.scores; let i = index;"
                                            [attr.colspan]="table_data[0]?.scores[0]?.skillset_semester_wise_marks?.length + 2">
                                            {{head.skillset_name}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <ng-container *ngFor="let head of table_data[0]?.scores">
                                            <th *ngFor="let sem of head.skillset_semester_wise_marks; let j = index;">
                                                {{sem.semester}}
                                            </th>
                                            <th>
                                                Avg
                                            </th>
                                            <th>
                                                Grade
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let student of table_data;let i=index;trackBy:fromDataResolver.identify;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{student.roll_no}}
                                        </td>
                                        <td>
                                            {{student.given_name}} {{student.middle_name}}
                                        </td>
                                        <ng-container *ngFor="let skill of student.scores; let i = index;">
                                            <td *ngFor="let sem of skill.skillset_semester_wise_marks; let j = index;">
                                                <p style="text-decoration: underline; cursor: pointer;"
                                                    *ngIf="sem.semester_average_marks != 0"
                                                    (click)="viewSkillSetEvidence(skill.skillset_id, student.roll_no, sem.semester)">
                                                    {{sem.semester_average_marks}}
                                                </p>
                                                <p *ngIf="sem.semester_average_marks == 0">
                                                    0
                                                </p>
                                            </td>
                                            <td>
                                                {{skill.skillset_average_mark}}
                                            </td>
                                            <td>
                                                {{skill.skillset_grade}}
                                            </td>
                                        </ng-container>
                                    </tr>

                                    <tr *ngIf="table_data.length == 0 && table_data_loading">
                                        <td colspan="7" class="text-center"><b>Loading...</b></td>
                                    </tr>
                                    <tr *ngIf="table_data.length == 0 && !table_data_loading">
                                        <td colspan="7" class="text-center"><b>No Records found.</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-view">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="this.skillset_semester_data">
                    <div class="modal-header">
                        <h6 class="modal-title">View Evidences for this skillset</h6>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%;">S.No</th>
                                            <th>Skill</th>
                                            <th>Description</th>
                                            <th style="width: 15%;">Date</th>
                                            <th style="width: 5%;">Point</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let evidence of skillset_semester_data; let i = index;">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{evidence.skill}}
                                            </td>
                                            <td>
                                                {{evidence.description}}
                                            </td>
                                            <td>
                                                {{evidence.date | date}}
                                            </td>
                                            <td>
                                                {{evidence.point}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onModalCloseClick()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
