import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ViewChild, ViewChildren } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-internal-valuation-scheme',
  templateUrl: './internal-valuation-scheme.component.html',
  styleUrls: ['./internal-valuation-scheme.component.scss']
})
export class InternalValuationSchemeComponent implements OnInit {

  @ViewChildren('editors') public editors: any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    // defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['code']
    ]
  };
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Internal Question Paper Scheme Setting';

  //route params
  exam_id: string = "";
  question_format_id: string = "";

  question_format: IExamQuestionFormat[] = [];
  exam_setup: any;
  default_questions_settings: any[] = [];
  question_settings: any[] = [];
  question_setting_form: FormGroup = undefined;
  question_save_loading: boolean = false;
  modal_question_index: any = {};
  question_editor: any;
  question_mark: number;
  editor_popup_form: FormGroup;
  analysis_data: IAnalysis = {} as IAnalysis;
  analysis_data_loading: boolean = false;
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.exam_id = params.exam_id;
      this.question_format_id = params.question_format_id;
      if (this.question_format_id != undefined && this.exam_id != undefined) {
        this.renderData();
      }
    });
  }


  coChanged(ev, i: number, j: number, question) {
    console.log(ev.target.value);
    if (question.value.option == 'A') {
      (((this.question_setting_form.get('questions_settings') as FormArray).controls[i]).get('questions') as FormArray).controls.find(x => x.value.question_no == question.value.question_no && x.value.option == 'B').get('co_id').setValue(ev.target.value);
      //((this.question_setting_form.get('questions_settings') as FormArray).controls[i].get('questions') as FormArray).controls[j].get('co_id').setValue(ev.target.value);
    }
  }

  kChanged(ev, i: number, j: number, question) {
    if (question.value.option == 'A') {
      (((this.question_setting_form.get('questions_settings') as FormArray).controls[i]).get('questions') as FormArray).controls.find(x => x.value.question_no == question.value.question_no && x.value.option == 'B').get('k_id').setValue(ev.target.value);
    }
  }

  async renderData() {
    try {
      const service_resp = await this.restService.findInternalExamSetup(this.exam_id);
      if (service_resp && service_resp.success) {
        this.exam_setup = service_resp.data;
        console.log(this.exam_setup);
        this.default_questions_settings = JSON.parse(JSON.stringify(this.exam_setup.questions_settings));
        this.question_setting_form = this.formBuilder.group({
          questions_settings: this.formBuilder.array(this.buildSectionsSettings(this.default_questions_settings)),
        });
        console.log(this.question_setting_form.value);
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }

  buildSectionsSettings(questions_settings: any[]): any {
    if (!questions_settings) {
      questions_settings = [];
    }
    var section_list_array: any[] = [];
    if (questions_settings && questions_settings.length > 0) {
      var question_count = 0;
      for (const section of questions_settings) {
        section_list_array.push(this.formBuilder.group({
          section_no: [section.section_no, [Validators.required]],
          questions: this.formBuilder.array(this.buildQuestionSettings(section.questions)),
        }));
        question_count++;
      }
    }
    console.log(section_list_array);
    return section_list_array;
  }

  get questionSettings() {
    return (this.question_setting_form.get('questions_settings') as FormArray).controls;
  }

  buildQuestionSettings(questions: any[]): any {
    if (!questions) {
      questions = [];
    }
    var question_list_array: any[] = [];
    if (questions && questions.length > 0) {
      var question_count = 0;
      for (const question of questions) {
        question_list_array.push(this.formBuilder.group({
          _id: [question._id],
          question: [question.question, [Validators.required]],
          co_id: [question.co_id, [Validators.required]],
          k_id: [question.k_id, [Validators.required]],
          scheme_list: this.formBuilder.array(this.buildSchemeList(question.scheme_list)),
          question_no: [question.question_no],
          option: [question.option]
        }));
        question_count++;
      }
    }
    console.log(question_list_array);
    return question_list_array;
  }

  getQuestions(index: number) {
    return ((this.question_setting_form.get('questions_settings') as FormArray).controls[index].get('questions') as FormArray).controls;
  }

  buildSchemeList(scheme_list: any[]): any {
    if (!scheme_list) {
      scheme_list = [];
    }
    var scheme_key_array: any[] = [];
    if (scheme_list && scheme_list.length > 0) {
      var key_count = 0;
      for (const key of scheme_list) {
        //console.log(component)
        scheme_key_array.push(this.formBuilder.group({
          scheme_key: [key.scheme_key],
          mark: [key.mark],
        }));
        key_count++;
      }
    }
    /* const remaining_count = 1 - scheme_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        scheme_key_array.push(this.formBuilder.group({
          scheme_key: [""],
          mark: [""],
        }));
      }
    } */
    console.log(scheme_key_array);
    return scheme_key_array;
  }

  getSchemePoints(i, j) {
    return (((this.question_setting_form.get('questions_settings') as FormArray).controls[i].get('questions') as FormArray).controls[j].get('scheme_list') as FormArray).controls;
  }

  addSchemePoint(i, j) {
    var k_index = (((this.question_setting_form.get('questions_settings') as FormArray).controls[i].get('questions') as FormArray).controls[j].get('scheme_list') as FormArray).length;
    (((this.question_setting_form.get('questions_settings') as FormArray).controls[i].get('questions') as FormArray).controls[j].get('scheme_list') as FormArray).push(this.formBuilder.group({
      scheme_key: [""],
      mark: [""],
    }));
    this.modal_question_index.i = i; this.modal_question_index.j = j; this.modal_question_index.k = k_index;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  async saveSchemePoint() {
    var scheme = (((this.question_setting_form.get('questions_settings') as FormArray).
      controls[this.modal_question_index.i].get('questions') as FormArray).controls[this.modal_question_index.j].
      get('scheme_list') as FormArray).controls[this.modal_question_index.k]
    scheme.get('scheme_key').setValue(this.question_editor);
    scheme.get('mark').setValue(this.question_mark);
    await this.saveQuestionPaper();
  }

  saveAndCloseSchemePoint() {
    this.saveSchemePoint();
    this.closeSchemePoint();
  }
  editSchemePoint(i, j, k) {
    this.modal_question_index.i = i; this.modal_question_index.j = j; this.modal_question_index.k = k;
    var scheme = (((this.question_setting_form.get('questions_settings') as FormArray).
      controls[this.modal_question_index.i].get('questions') as FormArray).controls[this.modal_question_index.j].
      get('scheme_list') as FormArray).controls[this.modal_question_index.k];
    this.question_editor = scheme.get('scheme_key').value;
    this.question_mark = scheme.get('mark').value;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  deleteSchemePoint(i, j, k) {
    const consent = confirm("Are you sure want to delete this key point? This action is irreversible!");
    if (!consent) return;
    (((this.question_setting_form.get('questions_settings') as FormArray).controls[i].get('questions') as FormArray).controls[j].get('scheme_list') as FormArray).removeAt(k);
    this.saveQuestionPaper();
  }

  closeSchemePoint() {
    this.question_editor = ""; this.question_mark = 0;
    this.modal_question_index = {};
    JQueryHelper.closeModal('#modal-popup');
  }

  canDisable() {
    if (this.question_mark == 0 || !this.question_mark)
      return true;
    if (this.question_editor == "" || !this.question_editor)
      return true;
    return false;
  }


  async saveQuestionPaper() {
    try {
      var form_data: any = this.question_setting_form.value;
      console.log(form_data);
      this.question_save_loading = true;
      const service_response = await this.restService.updateInternalExamSetup(this.exam_setup._id, form_data);
      if (service_response && service_response.success) {
        alert('Valuation Scheme saved successfully');
      }
      else {
        alert("Could not save Question Paper");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
      console.log(error);
    }
    finally {
      this.question_save_loading = false;
    }
  }

  openUploadImage(event: any, editor_ref: string): void {
    let editor: EditorComponent | undefined;
    for (const result of this.editors._results) {
      if (result.element.nativeElement && result.element.nativeElement.className) {
        const class_names: string[] = result.element.nativeElement.className.split(' ');
        if (class_names.includes(editor_ref)) {
          editor = result;
          break;
        }
      }
    }
    this.editor_popup_form = this.formBuilder.group({
      image_url: [''],
      height: [''],
      width: [''],
      editor: [editor]
    });
    JQueryHelper.openModal('#modal-2', { keyboard: false, backdrop: 'static' });
  }
  async uploadImage(e: any, form: FormGroup): Promise<void> {

    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const img = new Image();

          img.src = (reader.result as string);
          img.onload = () => {
            // const image_url = form.get('image_url');
            // if (image_url) {
            //   image_url.setValue(img.src);
            // }
            // const height = form.get('height');
            // if (height) {
            //   height.setValue(img.height);
            // }
            // const width = form.get('width');
            // if (width) {
            //   width.setValue(img.width);
            // }
            if (form.value.editor) {
              form.value.editor.exec('insertImage', {
                src: img.src,
                height: img.height,
                width: img.width
              });
              JQueryHelper.closeModal('#modal-2');
            }
          };
        };

        reader.readAsDataURL(file);
      }

    } catch (error) {

    } finally {

    }
  }

  onCloseImageUpload(): void {
    JQueryHelper.closeModal('#modal-2');
  }

  openAnalysis(type: string, section_no?: number) {
    console.log(type);
    this.analysis_data.type = type;
    this.analysis_data.data = '';
    if (type == 'OUT_OF_SYLLABUS') {

    }
    else if (type == 'TYPOGRAPHICAL_ERROR') {

    }
    else if (type == 'OTHER_REMARKS') {

    }
    else {
      this.analysis_data.section_no = section_no;
    }
    JQueryHelper.openModal('#analysis-popup', { keyboard: false, backdrop: 'static' });
  }
  canDisableAnalysis() {
    if (this.analysis_data.data == '') {
      return true;
    }
    return false;
  }

  closeAnalysis() {
    JQueryHelper.closeModal('#analysis-popup');
  }

  updateAnalysis() {

  }

  updateAndCloseAnalysis() {
    this.updateAnalysis();
    this.closeAnalysis();
  }




}

interface IExamQuestionFormat {
  _id: string,
  section_no: number,
  section_name: string,
  no_of_questions: number,
  question_max_mark: number,
  question_type: string,
  no_of_questions_to_answered: number,
  co_conditions: ICoCondition[]
}

interface ICoCondition {
  _id: string
  co_id: number,
  no_of_questions: number,
}

interface IAnalysis {
  type: string,
  data: string,
  section_no?: number,
}