<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage {{part}}</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
      <!-- <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Category</button>
                </div>
            </div> -->
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <!-- <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Department Category</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Department Categories</p>
                    </div> -->

          <div class="card-body" *ngIf="part1_search_form">


            <form [formGroup]="part1_search_form">
              <div class="row row-sm">


                <div class="col-lg-2">

                  <div class="form-group">

                    <label class="main-content-label tx-11 tx-medium tx-gray-600">

                      Select Programme

                    </label>
                    <select formControlName="programme_id" class="form-control" (change)="onDropdownChange($event)">

                      <option value="">Select Programme</option>

                      <option
                        *ngFor="let part1_department of part1_department_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="part1_department.programme_id">{{part1_department.display_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select revision year</label>
                    <select formControlName="revision_year" class="form-control" (change)="onDropdownChange($event)">
                      <option value="">Select revision year</option>
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>

                <!-- <ng-container *ngIf="part != 'PART_IV' && part != 'PART_V' && part != 'SUPPORTIVE_COURSES'">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select programme category</label>
                      <select formControlName="programme_category" class="form-control"
                        (change)="onDropdownChange($event)">
                        <option value="">Select programme category</option>
                        <option
                          *ngFor="let programme_category of commonEnums.programme_categories;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="programme_category.id">{{programme_category.text}}</option>
                      </select>
                    </div>
                  </div>
                </ng-container> -->

                <div class="col-lg-2">
                  <div class="form-group">
                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!programme_schemes_loading" (click)="searchPart1Programmes()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="programme_schemes_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </div>
                </div>

              </div>
            </form>
          </div>
          <ng-container *ngIf="part1_form">
            <div class="card-body">


              <div class="table-responsive">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>
                      <th rowspan="2" class="verical-middle text-center" style="width: 3%;">S.No</th>
                      <th rowspan="2" class="verical-middle text-center" style="width: 15%;">Semester</th>
                      <ng-container *ngIf="part == 'PART_IV' || part == 'SUPPORTIVE_COURSES'">
                        <th rowspan="2" class="verical-middle text-center" style="width: 15%;">Course Type</th>
                      </ng-container>
                      <th rowspan="2" class="verical-middle text-center" style="width: 15%;">Course Code</th>
                      <th rowspan="2" class="verical-middle text-center">Course</th>
                      <th colspan="3" class="text-center">Weekly Contact Hours</th>

                      <th rowspan="2" class="verical-middle text-center" style="width: 5%;">No. Of Credits</th>
                      <th rowspan="2" class="verical-middle text-center" style="width: 5%;">Exam. Hours</th>
                      <th colspan="3" class="text-center">Marks</th>
                      <th rowspan="2" class="verical-middle text-center" style="min-width: 150px;">Action</th>
                    </tr>
                    <tr>


                      <th class="text-center" style="width: 5%;">L</th>
                      <th class="text-center" style="width: 5%;">T</th>
                      <th class="text-center" style="width: 5%;">P</th>

                      <th class="text-center" style="width: 6%;">Int.</th>
                      <th class="text-center" style="width: 6%;">Ter.</th>
                      <th class="text-center" style="width: 6%;">Total</th>
                    </tr>
                  </thead>
                  <tbody>

                    <ng-container *ngIf="part1_form.value.programme_schemes.length>0">


                      <ng-container
                        *ngFor="let programe of fromDataResolver.getFormArrayControlls(part1_form,'programme_schemes');let i=index;trackBy:fromDataResolver.identify;">
                        <ng-container [formGroup]='programe'>
                          <tr>
                            <td scope="row">
                              {{i+1}}

                            </td>
                            <td>
                              <div class="form-group">
                                <select formControlName="semester" class="form-control" style="min-width: 150px;"
                                  [attr.disabled]="isDisabled() ? '' : null">
                                  <option value="">Select Semester</option>
                                  <option
                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="sem.id">{{sem.text}}</option>
                                </select>
                              </div>
                            </td>
                            <ng-container *ngIf="part == 'PART_IV' || part == 'SUPPORTIVE_COURSES'">
                              <td>


                                <div class="form-group">
                                  <select formControlName="course_type" class="form-control" style="min-width: 150px;"
                                    [attr.disabled]="isDisabled() ? '' : null">
                                    <option value="">All Course Types</option>
                                    <option
                                      *ngFor="let course_type of course_types;let i=index;trackBy:fromDataResolver.identify;"
                                      [ngValue]="course_type.id">{{course_type.text}}</option>
                                  </select>

                                </div>

                              </td>
                            </ng-container>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="min-width: 100px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="text"
                                  formControlName="course_code">
                              </div>

                            </td>



                            <td>
                              <div class="form-group">
                                <input class="form-control" style="min-width: 300px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="text"
                                  formControlName="course_name">
                              </div>
                              <div class="form-group">

                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Staff Assignment Handled
                                  By</label>
                                <select formControlName="staff_assignment_by" class="form-control">
                                  <option value="self">My Department</option>
                                  <option value="others">Other Department</option>
                                </select>
                              </div>
                              <ng-container *ngIf="programe.value.staff_assignment_by=='self'">
                                <div class="form-group">

                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Student Grouped
                                    By</label>
                                  <select formControlName="student_grouped_by" class="form-control">
                                    <option value="department_wise">Department Wise</option>
                                    <option value="combined">Combined</option>
                                  </select>
                                </div>
                              </ng-container>

                              <div class="form-group">

                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme(s) who
                                  all can study this course</label>
                                <select multiple formControlName="programme_ids" class="form-control">
                                  <option
                                    *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="programme.programme_id">{{programme.display_name}}</option>
                                </select>
                              </div>
                              <!--
                              <ng-container *ngIf="part == 'PART_IV' || part == 'SUPPORTIVE_COURSES'">
                                <div class="form-group">

                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme(s) who
                                    all can study this course</label>
                                  <select multiple formControlName="programme_ids" class="form-control">
                                    <option
                                      *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                      [ngValue]="programme.programme_id">{{programme.display_name}}</option>
                                  </select>
                                </div>

                              </ng-container> -->
                            </td>

                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="weekly_lecture_hours">
                              </div>

                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="weekly_tutorial_hours">
                              </div>

                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="weekly_practical_hours">
                              </div>

                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="credits">
                              </div>

                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="exam_duration">
                              </div>

                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="max_internal_mark">
                              </div>

                            </td>
                            <td>
                              <div class="form-group">
                                <input class="form-control" style="width: 80px;"
                                  [attr.disabled]="isDisabled() ? '' : null" required="" type="number"
                                  formControlName="max_external_mark">
                              </div>

                            </td>
                            <th class="verical-middle">
                              <div class="form-group">
                                <label>{{getTotal(programe.value.max_internal_mark,
                                  programe.value.max_external_mark)}}</label>
                              </div>

                            </th>
                            <th>
                              <div class="form-group">
                                <div class="pr-1 mb-3 mb-xl-0">
                                  <button type="button" [attr.disabled]="isDisabled() ? '' : null"
                                    class="btn btn-sm btn-danger mr-2" title="Edit Revision"
                                    (click)="onDeleteProgrameClick(programe.value._id,i)">
                                    <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                  </button>
                                  <ng-container *ngIf="programe.value.staff_assignment_by=='self'">
                                    <button type="button"
                                      [attr.disabled]="(isDisabled()|| !programe.value._id) ? '' : null"
                                      class="btn btn-sm btn-primary mr-2" title="Assign Staffs"
                                      (click)="onCreateSectionClick(programe,i)">
                                      <fa-icon [icon]="fonts.faUsers"></fa-icon>
                                    </button>
                                  </ng-container>
                                </div>
                              </div>

                            </th>



                          </tr>
                          <tr *ngIf="programe.value.open">
                            <th colspan="14" class="text-center">

                              <div class="row">
                                <div class="col-lg-2">

                                  <div *ngIf="programe.value.regulation_year_form">
                                    <ng-container [formGroup]='programe.value.regulation_year_form'>
                                      <div class="form-group">
                                        <select formControlName="regulation_year" class="form-control"
                                          (change)="onRegulationYearChange(programe)">
                                          <option value="">Select enrollment year</option>
                                          <option
                                            *ngFor="let year of commonEnums.getNexNYears(part1_search_form.value.revision_year);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                        </select>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>

                              <div class="row" *ngIf="programe.value.section_form">

                                <ng-container *ngIf="programe.value.student_grouped_by=='department_wise'">
                                  <div class="col-8">


                                    <div class="table-responsive">
                                      <table class="table table-bordered mg-b-0 text-md-nowrap">
                                        <thead>
                                          <tr>
                                            <th style="width: 3%;">S.No</th>
                                            <th>Programme Name</th>
                                            <th>Section</th>
                                            <th style="width:15%;">Action</th>
                                          </tr>

                                        </thead>
                                        <tbody>
                                          <ng-container *ngIf="programe.value.section_form.value.sections.length>0">
                                            <ng-container
                                              *ngFor="let section of fromDataResolver.getFormArrayControlls(programe.value.section_form,'sections');let i=index;trackBy:fromDataResolver.identify;">
                                              <ng-container [formGroup]='section'>
                                                <tr>
                                                  <td>
                                                    {{i+1}}
                                                  </td>
                                                  <td class="text-left">
                                                    {{section.value.programme_name}}
                                                  </td>
                                                  <td>
                                                    {{section.value.programme_section_name}}
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-danger mr-2"
                                                          title="Delete Section"
                                                          (click)="onDeleteSectionClick(programe,section,i)">
                                                          <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                                        </button>
                                                        <ng-container *ngIf="section.value._id">
                                                          <button type="button" class="btn btn-sm btn-primary mr-2"
                                                            title="Assign Staffs"
                                                            (click)="onAssignStaffClick(programe,section,i)">
                                                            <fa-icon [icon]="fonts.faUser"></fa-icon>
                                                          </button>
                                                        </ng-container>

                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>

                                          <tr>
                                            <td colspan="4" class="text-center">

                                              <button class="btn btn-success" (click)="onSaveSection(programe)">
                                                Save Section
                                              </button>
                                            </td>
                                          </tr>

                                        </tbody>
                                      </table>
                                    </div>


                                  </div>

                                </ng-container>

                                <ng-container *ngIf="programe.value.student_grouped_by=='combined'">


                                  <div class="col">


                                    <div class="table-responsive">
                                      <table class="table table-bordered mg-b-0 text-md-nowrap">
                                        <thead>
                                          <tr>
                                            <th style="width: 3%;">S.No</th>
                                            <th>Section Name</th>
                                            <th style="width:15%;">Action</th>
                                          </tr>

                                        </thead>
                                        <tbody>
                                          <ng-container *ngIf="programe.value.section_form.value.sections.length>0">
                                            <ng-container
                                              *ngFor="let section of fromDataResolver.getFormArrayControlls(programe.value.section_form,'sections');let i=index;trackBy:fromDataResolver.identify;">
                                              <ng-container [formGroup]='section'>
                                                <tr>
                                                  <td>
                                                    {{i+1}}
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input class="form-control" type="text"
                                                        formControlName="section_name">
                                                    </div>

                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-danger mr-2"
                                                          title="Delete Section"
                                                          (click)="onDeleteSectionClick(programe,section,i)">
                                                          <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                                        </button>
                                                        <ng-container *ngIf="section.value._id">
                                                          <button type="button" class="btn btn-sm btn-primary mr-2"
                                                            title="Assign Staffs"
                                                            (click)="onAssignStaffClick(programe,section,i)">
                                                            <fa-icon [icon]="fonts.faUser"></fa-icon>
                                                          </button>
                                                        </ng-container>

                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </ng-container>
                                            </ng-container>
                                          </ng-container>
                                          <tr>
                                            <td colspan="3" class="text-center">
                                              <button class="btn-sm btn btn-primary"
                                                (click)="onAddNewSection(programe,programe.value.section_form)">
                                                Add New Section
                                              </button>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colspan="3" class="text-center">

                                              <button class="btn btn-success" (click)="onSaveSection(programe)">
                                                Save Section
                                              </button>
                                            </td>
                                          </tr>

                                        </tbody>
                                      </table>
                                    </div>


                                  </div>
                                  <div class="col"></div>
                                </ng-container>

                              </div>

                            </th>
                          </tr>
                        </ng-container>


                      </ng-container>

                    </ng-container>

                    <ng-container *ngIf="!programme_schemes_loading && part1_form.value.programme_schemes.length==0">
                      <tr>
                        <th colspan="14" class="text-center">No Records found</th>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="programme_schemes_loading && part1_form.value.programme_schemes.length==0">
                      <tr>
                        <th colspan="14" class="text-center">Loading Please wait</th>
                      </tr>
                    </ng-container>
                    <tr>
                      <th colspan="14" class="text-center">
                        <button class="btn btn-sm btn-info" [attr.disabled]="isDisabled() ? '' : null"
                          (click)="addNewCourse()">Add New Course</button>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="14" class="text-center"
                        *ngIf="!programme_schemes_loading && part1_form.value.programme_schemes.length>0">
                        <button class="btn  btn-primary" [attr.disabled]="isDisabled() ? '' : null"
                          (click)="saveCourseScheme()">Save</button>


                        <ng-container *ngIf="cookieStore.isRoleExists(['hod','staff_admin','principal'])">
                          <ng-container *ngIf="!part1_form.value.approved">
                            <button class="btn btn-success mg-l-20" (click)="approveCourseScheme()"
                              *ngIf="!programme_scheme_approval_loading">Approve</button>
                            <button class="btn btn-success mg-l-20" type="button" disabled
                              *ngIf="programme_scheme_approval_loading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                            </button>
                          </ng-container>
                          <ng-container *ngIf="part1_form.value.approved">
                            <button class="btn btn-success mg-l-20" (click)="onUpApprovalClick()"
                              *ngIf="!programme_scheme_approval_loading">Un-Approve</button>
                            <button class="btn btn-success mg-l-20" type="button" disabled
                              *ngIf="programme_scheme_approval_loading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                            </button>
                          </ng-container>
                        </ng-container>

                      </th>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>


        </div>
      </div>


    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-staffassign">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="assign_staff">
          <div class="modal-header">

            <h6 class="modal-title">Assign Staff</h6>

          </div>
          <div class="modal-body">
            <div class="">
              <div class="table-responsive">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>

                      <th style="width: 3%;">S.No</th>
                      <th style="width: 10%;">Roll No</th>
                      <th>Name</th>
                      <th>Department</th>
                      <th style="width: 15%;">Is Incharge</th>
                      <th style="width: 15%;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngFor="let staff of fromDataResolver.getFormArrayControlls(assign_staff,'staffs');let staff_index=index;trackBy:fromDataResolver.identify">
                      <ng-container [formGroup]="staff">
                        <tr>

                          <td>
                            {{staff_index+1}}
                          </td>
                          <td>
                            <div class="form-group" style="margin-bottom: 0;">
                              <input class="form-control" [attr.name]="staff.value.sub" required="" type="text"
                                formControlName="roll_no" (blur)="onRollNoChanged($event,staff,assign_staff)">
                            </div>
                          </td>
                          <td>
                            {{staff.value.name}}
                          </td>

                          <td>
                            {{staff.value.departments}}
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" [attr.id]='"p-"+staff_index'
                                [attr.name]='"p-"+staff_index' formControlName="is_primary">
                              <label class="custom-control-label" [attr.for]='"p-"+staff_index'>Is incharge</label>
                            </div>
                          </td>
                          <td>
                            <div class="pr-1 mb-3 mb-xl-0">
                              <button type="button" class="btn btn-sm btn-danger mr-2"
                                (click)="removeStaff(staff.value._id, staff,assign_staff,staff_index)">
                                <fa-icon [icon]="fonts.faTrash"></fa-icon>

                              </button>

                            </div>
                          </td>
                        </tr>
                      </ng-container>

                    </ng-container>
                    <tr>
                      <td colspan="6" class="text-center">

                        <button class="btn btn-sm btn-primary" (click)="addMoreStaff(assign_staff)">Add More
                          Staff</button>


                        <button class="btn btn-sm btn-success mg-l-20"
                          (click)="onSaveStaffAssignClick(assign_staff)">Save</button>


                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ng-container>
                <div class="mg-b-20"></div>
                <div class="text-align-right">

                  <button class="btn ripple btn-secondary" (click)="onCloseStaffAssignClick()"
                    type="button">Close</button>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
