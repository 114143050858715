import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { FormatSettings } from "@progress/kendo-angular-dateinputs";
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';



@Component({
  selector: 'app-time-settings',
  templateUrl: './time-settings.component.html',
  styleUrls: ['./time-settings.component.scss']
})
export class TimeSettingsComponent implements OnInit {
  fromDataResolver = FromDataResolver;
  timeSettingForm: FormGroup;
  //time_setting_filter_form: FormGroup;
  start_hour: any[] = [];
  no_of_hours: any[] = [];
  no_of_break_hours: any[] = [];
  duration: any[] = [];
  fonts = fonts;
  department_list: any[] = [];
  academic_year: any;
  selectedDay: any = '';
  day: any;
  timetableData: any;
  template_list: any[] = [];
  template_list_loading: boolean = false;
  popup_mode: string = '';
  public countArr: any = [];
  public isEdit: boolean = false;
  public objIndex: number = 0;
  public format: FormatSettings = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yy",
  };
  save_loading: boolean = false;
  delete_loading: boolean = false;
  view_time_table: any[] = [];
  max_hour: number = 0;
  hour_header: {
    hour_name: string,
    hour_number: number
  }[] = [{ hour_name: "I", hour_number: 1 }, { hour_name: "II", hour_number: 2 }, { hour_name: "III", hour_number: 3 },
  { hour_name: "IV", hour_number: 4 }, { hour_name: "V", hour_number: 5 }, { hour_name: "VI", hour_number: 6 },
  { hour_name: "VII", hour_number: 7 }, { hour_name: "VIII", hour_number: 8 }, { hour_name: "IX", hour_number: 9 }, { hour_name: "X", hour_number: 10 }];
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.timeSettingForm = this.fb.group({
      template_name: ['', [Validators.required]],
      _id: [''],
      days: this.fb.array([], Validators.required)
    });
  }

  setForm() {
    this.timeSettingForm = this.fb.group({
      template_name: ['', [Validators.required]],
      _id: [''],
      days: this.fb.array([], Validators.required)
    });
  }


  ngOnInit() {
    //this.addDay();
    this.getTemplateList();
    //this.getProgrammeDataList();
  }

  async getTemplateList() {
    this.template_list = [];
    this.template_list_loading = true;
    try {
      const service_response = await this.restService.listTimetableTemplates();
      if (service_response && service_response.success) {
        this.template_list = service_response.data;
        console.log(this.template_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.template_list_loading = false;
  }

  // Modal related function

  onAddClick() {
    this.popup_mode = 'edit';
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    this.setForm();
    this.addDay();
  }

  days(): FormArray {
    return this.timeSettingForm.get('days') as FormArray;
  }

  addDay() {
    this.day = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.day.forEach((data: any, index: any) => {
      this.days().push(this.newTimeSetting(data, index));
    })
  }

  newTimeSetting(date: any, startHour: any): FormGroup {
    return this.fb.group({
      day: date,
      start_hour: new Date(2021, 2, 10, 10, 0, 0),
      slot: this.fb.array([], Validators.required)
    });
  }

  AddSlot(index: any) {
    var slot = this.days().at(index).get('slot') as FormArray;
    slot.push(this.newSlot());
  }

  newSlot() {
    return this.fb.group({
      duration: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      isBreak: [false]
    })
  }
  slotArrayControl(index: any) {
    //console.log((this.days().at(index).get('slot') as FormArray).controls);
    return (this.days().at(index).get('slot') as FormArray).controls;
  }

  aliasesArrayControl(i: any) {
    this.days().at(i).get('slot') as FormArray;
    return (this.days().at(i).get('slot') as FormArray).controls;
  }

  dayChange() {
    this.day.forEach((data: any, index: any) => {
      if (data == this.selectedDay) {
        setTimeout(() => {
          this.countArr = [];
          this.slotChange(index);
        }, 100)
      }
    });
  }

  editSlot(data: any, boolValue: boolean, objIndex: number) {
    this.setValueToForm(data);
    this.isEdit = boolValue;
    this.objIndex = objIndex;
    this.getHeaderCount();
  }


  setValueToForm(data: any) {
    this.timeSettingForm.get('_id').setValue(data._id);
    this.timeSettingForm.get('template_name').setValue(data.template_name);
    this.addDay();
    if (data.day_wise_order.length) {
      data.day_wise_order.forEach((element: any, dayIndex: number) => {
        var convertedDate = element.start_time ? new Date(new Date().setHours(element.start_time.replace(/ PM| AM/gi, "").split(':')[0], element.start_time.replace(/ PM| AM/gi, "").split(':')[1], 0)) : this.days().at(dayIndex).get('start_hour')?.value;
        this.days().at(dayIndex).get('start_hour')?.patchValue(convertedDate);
        var slot = this.days().at(dayIndex).get('slot') as FormArray;
        element.hours.map((hour: any) => {
          slot.push(this.newSlotWithValue(hour));
        });
      });
    }
    this.setDayToSlot();
    this.popup_mode = 'edit';
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  newSlotWithValue(slot: any) {
    return this.fb.group({
      duration: [slot.duration, Validators.required],
      startTime: [slot.startTime, Validators.required],
      endTime: [slot.endTime, Validators.required],
      isBreak: [slot.is_break]
    })
  }

  setDayToSlot() {
    this.day.forEach((data: any, index: any) => {
      setTimeout(() => {
        this.slotChange(index);
      }, 100)
    });
  }

  slotChange(dayIndex: any) {
    let StartTime = new Date(this.days().at(dayIndex)?.value.start_hour);
    let duration = 0;
    for (let i = 0; i < this.days().at(dayIndex).value.slot.length; i++) {
      duration = this.days().at(dayIndex).value.slot[i].duration;
      if (duration > 0) {
        if (i > 0) {
          let previousEndTime = this.days().at(dayIndex).value.slot[Number(i) - 1].endTime;
          console.log(previousEndTime);
          if ((previousEndTime as string).includes('PM') && previousEndTime.replace(/ PM| AM/gi, "").split(':')[0] < 12) {
            var hour = parseInt(previousEndTime.replace(/ PM| AM/gi, "").split(':')[0]) + 12;
            console.log(hour);
            StartTime = new Date(new Date().setHours(hour, previousEndTime.replace(/ PM| AM/gi, "").split(':')[1], 0));
            console.log(StartTime);
          }
          else {
            StartTime = new Date(new Date().setHours(previousEndTime.replace(/ PM| AM/gi, "").split(':')[0], previousEndTime.replace(/ PM| AM/gi, "").split(':')[1], 0));
            console.log(StartTime);
          }
        }
        this.days().at(dayIndex).value.slot[i].startTime = new Date(StartTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        this.days().at(dayIndex).value.slot[i].endTime = new Date(StartTime.setMinutes(new Date(StartTime).getMinutes() + Number(duration))).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      }
    }
    this.getHeaderCount();
  }


  getHeaderCount() {
    this.timeSettingForm.value.days.forEach((data: any, index: any) => {
      if (data.slot.length)
        this.countArr.push(data.slot.length);
    });
    this.countArr.length = this.countArr.sort(function (a: any, b: any) { return b - a }).slice(0, 1);
  }



  removeSlot(index: any, slotIndex: any) {
    this.countArr = [];
    const value = this.days().at(index).get('slot')?.value;
    this.days().at(index).get('slot')?.setValue(
      value.slice(0, slotIndex).concat(
        value.slice(slotIndex + 1),
      ).concat(value[slotIndex]),
    );

    //this.days().at(index).get('slot').removeAt(value.length - 1);

    (this.days().at(index).get('slot') as FormArray).removeAt(value.length - 1);
    this.slotChange(index);

  }

  async deleteTemplate() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteTimetableTemplate(this.timeSettingForm.get('_id')!.value);
      if (res.success) {
        alert('Deleted Template Successfully');
        this.getTemplateList();
      }
      else {
        alert('Failed to Delete Template');
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }

  async deleteTemplateById(_id: string) {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      const res = await this.restService.deleteTimetableTemplate(_id);
      if (res.success) {
        alert('Deleted Template Successfully');
        this.getTemplateList();
      }
      else {
        alert('Failed to Delete Template');
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
    }
  }


  async save() {
    try {
      var day_wise_order: Array<any> = [];
      this.timeSettingForm.controls.days.value.map((element: any, index: number) => {
        let day: any = {};
        let overallSlots: Array<any> = [];
        let overallDuration = 0;
        element.slot.map((slot: any, slotIndex: number) => {
          let hours: any = {};
          overallDuration += Number(slot.duration);
          hours["hour_number"] = slotIndex + 1;
          hours["start_time"] = slot.startTime;
          hours["end_time"] = slot.endTime;
          hours["duration"] = Number(slot.duration);
          hours["is_break"] = slot.isBreak;
          overallSlots.push(hours);
        });
        day["day"] = element.day;
        day["start_time"] = element.slot.length === 0 ? '' : element?.slot[0].startTime;
        day["end_time"] = element.slot.length === 0 ? '' : element?.slot[element.slot.length - 1].endTime;
        //day["no_of_hours"] = Math.floor(overallDuration / 60) + '.' + overallDuration % 60;
        day["no_of_hours"] = overallSlots.length;
        day["no_of_breaks"] = element.slot.filter((slotBreak: any) => slotBreak.isBreak == true).length;
        day['hours'] = overallSlots;
        day_wise_order.push(day);
      });
      this.timetableData = {
        _id: this.timeSettingForm.value._id,
        template_name: this.timeSettingForm.value.template_name,
        day_wise_order: day_wise_order
      };
      console.log(this.timetableData);
      this.save_loading = true;
      if (!this.timeSettingForm.value._id) {
        const res = await this.restService.createTimetableTemplate(this.timetableData);
        if (!res) {
          alert('Failed to Create Timetable Template');
        }
        else {
          alert('Created Timetable template Successfully');
        }
      }
      else {
        const res = await this.restService.updateTimetableTemplate(this.timeSettingForm.value._id, this.timetableData);
        if (!res) {
          alert('Failed to Update Timetable Template');
        }
        else {
          alert('Updated Timetable template Successfully');
        }
      }
      this.getTemplateList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }
  async onCloseClick(): Promise<void> {
    this.isEdit = false;
    this.resetForm();
    this.countArr = [];
    this.popup_mode = '';
    JQueryHelper.closeModal('#modal-popup');
  }
  saveAndClose() {
    this.save();
    this.onCloseClick();
  }
  reset() {
    this.resetForm();
    this.setForm();
    this.addDay();
  }
  resetForm() {
    this.days().clear();
    this.selectedDay = '';
  }

  async finalizeTemplate(template_data) {
    const consent = confirm("Are you sure want to lock this template? This action cannot be reversed!");
    if (!consent)
      return;
    try {
      const res = await this.restService.finalizeTimetableTemplate(template_data._id);
      if (!res) {
        alert('Failed to Finalize Timetable Template');
      }
      else {
        alert('Finalized Timetable template Successfully');
      }
      this.getTemplateList();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
    }
    finally {

    }

  }

  async viewTemplate(data) {
    this.view_time_table = data.day_wise_order;
    let hours: number[] = [];
    this.view_time_table.forEach(table => {
      hours.push(table.no_of_hours);
    });
    this.max_hour = Math.max.apply(Math, hours);
    this.popup_mode = 'view';
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async closeViewTemplate() {
    this.popup_mode = '';
    JQueryHelper.closeModal('#modal-popup');
  }
}
/* department_name: this.time_setting_filter_form.get('department_name')?.value,
          start_date: this.time_setting_filter_form.get('start_date')?.value,
          semester: this.time_setting_filter_form.get('semester_type')?.value,
          enrolment_year: this.time_setting_filter_form.get('enrollment_year')?.value,
          section: this.time_setting_filter_form.get('section')?.value,
          total_hours: this.time_setting_filter_form.get('total_hours')?.value, */