<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View PAM for Programmes</p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!--Programme-->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Regulation
                                            year</label>
                                        <select formControlName="regulation_year" class="form-control">
                                            <option value="">Select Regulation year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <div class="d-flex my-xl-auto right-content" *ngIf="table_data.length > 0"
                            style="flex-direction: row-reverse;">
                            <app-modal [render_data]="pam_render_data" (csvData)="bulkUploadPAM($event)"></app-modal>
                            <button class="btn btn-info btn-md mg-r-10" (click)="openCourseAddModal()">Add
                                Course</button>
                            <button type="button" class="btn btn-info btn-md mg-r-10" (click)="onGenerateClick()"
                                title="Regenerate" *ngIf="!generate_loading">
                                Regenerate
                            </button>
                            <button class="btn btn-info btn-md mg-r-10" type="button" disabled *ngIf="generate_loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>


                        <div class="table-responsive mg-t-30">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th>S.No</th>
                                        <th>Part Type</th>
                                        <th>Course Type</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th *ngFor="let po of po_levels; let i = index;">
                                            {{po | uppercase}}
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let course of table_data; let i = index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{course.part}}</td>
                                        <td>{{course.course_type}}</td>
                                        <td>{{course.course_code}}</td>
                                        <td>{{course.course_name}}</td>
                                        <td *ngFor="let po of course.po_scores | keyvalue;">
                                            {{po.value}}
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-danger mg-l-10"
                                                (click)="deleteCourse(course.course_code)">
                                                <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="table_data.length == 0 && table_data_loading">
                                        <tr>
                                            <td colspan="13" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="table_data.length == 0 && !table_data_loading">
                                        <tr>
                                            <td colspan="13" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-add">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Add Course Code to PAM Report</h6>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" [(ngModel)]="course_code"
                                            placeholder="Enter Course Code to be added to the report">
                                    </div>
                                </div>
                            </div>
                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary btn-md" (click)="closeCourseAddModal()"
                                    type="button">Close</button>
                                <button class="btn btn-success ripple btn-md mg-l-10" (click)="addCourse()"
                                    type="button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>