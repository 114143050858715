import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {
  fromDataResolver = FromDataResolver;


  transport_routes: any[] = [];

  transport_details: any = null;

  transport_route: any;

  selected_from = '';

  selected_from_value = '';
  amount = 0;
  status = 'pending';
  valid_gender = true;


  constructor(private route: ActivatedRoute, private restService: AdminservicesService, private fb: FormBuilder) {
    JQueryHelper.toPageInit();
    /* const user_info = CookieStore.getUserInfo();
    if (user_info.gender && (user_info.gender.toLowerCase() === 'male' || user_info.gender.toLowerCase() === 'm')) {
      this.valid_gender = false;
    } */
    this.getTransportRoutes();


  }

  ngOnInit(): void {
  }

  async getTransportRoutes(): Promise<void> {

    try {
      this.transport_routes = [];
      const rest_response = await this.restService.getTransportRouteList();
      if (rest_response.success && rest_response.data) {
        this.transport_routes = rest_response.data;
      } else {
        this.transport_routes = [];
      }

      this.transport_details = null;
      this.status = 'pending';
      const transport_rest_response = await this.restService.getTransportAdmissionStatus();
      if (transport_rest_response.success && transport_rest_response.data) {
        this.transport_details = transport_rest_response.data;
        if (this.transport_details) {
          this.status = this.transport_details.status;
        }
      } else {
        this.transport_details = null;

        this.transport_route = this.fb.group({
          selected_from: [this.selected_from, Validators.required]
        });
      }





    } catch (error) {

    }



  }


  async applyForTransport(): Promise<void> {
    const consent = confirm('Are you sure do you want to apply for Transport?');
    if (consent) {
      try {

        const rest_response = await this.restService.applyForTransport({ main_role: 'student', from: this.selected_from_value });
        if (rest_response.success) {
          this.getTransportRoutes();
          alert('Successfully applied for Transport');
        }
      } catch (error) {
        alert('Error while applying for Transport');
      }

    }

  }

  async payTransportFee(): Promise<void> {
    const payment_url = await this.restService.getTransportFeePaymentUrl();
    location.href = payment_url;
  }

  onFromChange(event: any): void {
    const selected_from_index = event.target.selectedIndex;
    if (selected_from_index === 0) {
      this.amount = 0;
      this.selected_from_value = '';
    }
    else {
      const selected_from = this.transport_routes[selected_from_index - 1];
      this.amount = selected_from.amount;
      this.selected_from_value = selected_from.from;
    }

  }

}
