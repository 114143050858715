<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->


        <div class="row row-sm" *ngIf="isRoleExists(['student'])">
            <iframe [src]="getURL()" frameBorder="0"></iframe>
        </div>

        <!-- <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card" id="basic-alert">
                    <div class="card-body">
                        <div>
                            <h6 class="card-title mb-1">News From College</h6>
                            <p class="text-muted card-sub-title">Latest news from college</p>
                        </div>
                        <div class="text-wrap">
                            <div class="example">

                                <div class="alert alert-success" role="alert">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <a style="cursor: pointer;" routerLink="/student/hostel/admission"><strong>Hostel
                                            Admission
                                            is Open!</strong> Click here to view and apply for hostel.</a>

                                </div>
                                <div class="alert alert-success" role="alert">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <a style="cursor: pointer;"
                                        routerLink="/student/transport-request"><strong>Transportation
                                            (Bus Facility)
                                            Admission is Open!</strong> Click here to view and apply for
                                        transportation (Bus Facility).</a>

                                </div>

                                <div class="alert alert-success" role="alert">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <a style="cursor: pointer;" href="../../../../assets/docs/hostel_helper_new.pdf"
                                        target="_blank"><strong>Hostel Fee
                                            Helper ! </strong>Click here to view.</a>

                                </div>
                                <div class="alert alert-success" role="alert">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <a style="cursor: pointer;"
                                        href="../../../../assets/docs/transportation_helper_new.pdf"
                                        target="_blank"><strong>Transportation
                                            (Bus Facility)
                                            Fee Helper !</strong> Click here to view.</a>

                                </div>


                                <div class="alert alert-success" role="alert"
                                    *ngIf="payment_details && payment_details.length>0">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <a style="cursor: pointer;" target="_blank"
                                        routerLink="/students/mypayments"><strong>Programme Fee </strong> Click here pay
                                        programme fees</a>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>  -->

        <!-- Large Modal -->
        <div class="modal" id="extra_info">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Fill your missing details</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40" *ngIf="extrainfo_form">
                            <form [formGroup]="extrainfo_form">
                                <h6>In order to help you better , please fill the following missing fields</h6>
                                <div class="form-group" *ngIf="!this.user.middle_name && !this.user.family_name">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter your
                                        initial</label>
                                    <input class="form-control" required="" type="text" formControlName="initial">
                                </div>

                                <div class="form-group" *ngIf="!this.user.gender">
                                    <div class="row row-sm">
                                        <div class="col-sm-9">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Your
                                                Gender</label>
                                            <div class="row row-sm">
                                                <div class="col-sm-7">
                                                    <select class="form-control select2-no-search"
                                                        formControlName="gender">
                                                        <option label="Choose one">
                                                        </option>
                                                        <option value="male">
                                                            Male
                                                        </option>
                                                        <option value="female">
                                                            Female
                                                        </option>

                                                    </select>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="mg-b-20"></div>
                                <button class="btn btn-main-primary btn-block"
                                    (click)="onExtraInfoClick()">Save</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>