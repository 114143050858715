import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-hostel-request-creation',
  templateUrl: './hostel-request-creation.component.html',
  styleUrls: ['./hostel-request-creation.component.scss']
})
export class HostelRequestCreationComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }
  
  ngOnInit(): void {
  }

  url : string = "";
  
  getURL(){
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/hostel-v2/hostel-request-creation-645b33d5da60c6288bd782a7?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
