import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { Hostel } from './../hostelModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-hostel',
  templateUrl: './edit-hostel.component.html',
  styleUrls: ['./edit-hostel.component.scss']
})
export class EditHostelComponent implements OnInit {
  hostel: Hostel = {} as Hostel;
  hostelForm: FormGroup;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.hostelForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      floors: [],
      incharges: []
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.route.params.subscribe(params => {
      this.navigationService.updateNavigation('Edit Hostel', this.router.url);
      this.restService.hostelManagementService.getHostels(params.id).subscribe(hostelData => {
        if (!hostelData?.length) {
          return;
        }
        this.hostel.id = params.id;
        this.hostel = hostelData[0];
        this.hostelForm = this.formBuilder.group({
          name: [this.hostel?.name],
          floors: [this.hostel?.floors],
          incharges: [this.hostel?.incharges]
        });
      });
    });
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  goToIncharges(): void {
    this.router.navigate([`admin/hostel/hostel-management/incharges/${this.hostel.id}`]);
  }

  goToFloors(): void {
    this.router.navigate([`admin/hostel/hostel-management/floors/${this.hostel.id}`]);
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  onSave(): void {
    this.restService.hostelManagementService.updateHostel(this.hostel.id, { data: this.getFormData() }).subscribe(data => {
      console.log(data);
      this.notificationService.setNotifcation({ message: 'Updated hostel details.', type: 'Success' });
      this.onClose();
    }, this.errorHandler);
  }

  isNameInvalid(): boolean {
    return !this.getFormData().name;
  }

  canSave(): boolean {
    if (!this.getFormData().name) {
      return true;
    }
    return false;
  }

  getFormData(): any {
    return this.hostelForm.value;
  }
}
