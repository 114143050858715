import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-sent-notification',
  templateUrl: './sent-notification.component.html',
  styleUrls: ['./sent-notification.component.scss']
})
export class SentNotificationComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }

  ngOnInit(): void {
  }

  
  url : string = "";
  
  getURL(){
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/notification/sent-item-6463126d518896795a4432ab?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }


}
