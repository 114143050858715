import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-programme-exit-survey',
  templateUrl: './programme-exit-survey.component.html',
  styleUrls: ['./programme-exit-survey.component.scss']
})
export class ProgrammeExitSurveyComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  dropdownSettings: IDropdownSettings = {};
  title = 'Programme Exit Survey';
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_type_id: string = ""; //ngModel
  settings_list_loading: boolean = false;
  settings_list: ProgrammeExitSurveyInterface[] = [];
  setting: ProgrammeExitSurveyInterface = {} as ProgrammeExitSurveyInterface;
  programme_list: any[] = [];
  programme_list_loading: boolean = false;
  survey_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  component_search_form: FormGroup = undefined;
  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;
  programme_survey_list: any[] = [];
  programme_survey_list_loading = false;
  survey_data_loading: boolean = false;
  save_loading: boolean = false;
  student_response_loading: boolean = false;
  delete_loading: boolean = false;
  cookieStore = CookieStore;
  popup_mode: string = '';
  pso_levels: {
    pso_id: number, pso_text: string
  }[] =
    [
      { pso_id: 1, pso_text: "PSO1" }, { pso_id: 2, pso_text: "PSO2" }, { pso_id: 3, pso_text: "PSO3" }, { pso_id: 4, pso_text: "PSO4" },
      { pso_id: 5, pso_text: "PSO5" }, { pso_id: 6, pso_text: "PSO6" }, { pso_id: 7, pso_text: "PSO7" }
    ];
  response_options: string[] = ['STRONGLY_AGREE', 'AGREE', 'NEUTRAL', 'DISAGREE']
  response_point_map: {
    response: string, point: number
  }[] = [
      { response: 'STRONGLY_AGREE', point: 4 }, { response: 'AGREE', point: 3 },
      { response: 'NEUTRAL', point: 2 }, { response: 'DISAGREE', point: 0 }
    ];
  show_response: boolean = true;
  ngOnInit(): void {
    this.getProgrammeTypeList();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.buildComponentSearchForm();
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  buildComponentSearchForm(): void {
    this.component_search_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [''],
      programme_id: ['']
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {

    if (id === 'programme_type_id') {
      this.component_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.component_search_form);
    }
  }

  async getProgrammeDataList(component_search_form): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (component_search_form.value.programme_type_id) {
        search_form_query.programme_type_id = component_search_form.value.programme_type_id
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }


  async onSearchClick() {
    try {
      this.programme_survey_list_loading = true;
      this.programme_survey_list = [];
      const search_query = {
        programme_type_id: this.component_search_form.value.programme_type_id,
        enrolled_year: this.component_search_form.value.enrolled_year,
        programme_id: this.component_search_form.value.programme_id,
        skip: this.start_index,
        take: this.take_index
      };
      const service_response = await this.restService.getProgrammeGES(search_query); //v2
      if (service_response && service_response.success) {
        this.programme_survey_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_survey_list_loading = false;
    }

  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }


  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  async manageQuestions(course) {
    console.log(course);
    try {
      this.survey_data_loading = true;
      /* this.setting = {
        _id: "",
        programme_type_id: course.offered_by_programme_type_id,
        enrolled_year: course.enrolled_year,
        course_code: course.course_code,
        programme_id: course.class_taking_programme_id,
        section: course.class_taking_section,
        virtual_section: course.virtual_section,
        survey_questions: [], //course.survey_questions
        is_active: false, //course.is_active
        start_date: "", //course.start_date
        end_date: "" //course.end_date
      } */
      this.setting = course;
      this.buildForm();
      this.popup_mode = 'add';
      JQueryHelper.openModal('#survey-modal-popup', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.survey_data_loading = false;
    }
  }

  viewResponse() {
    this.popup_mode = 'view';
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        is_active: false,
        start_date: "",
        end_date: "",
        programme_type_id: "",
        enrolled_year: 0,
        programme_id: "",
        survey_questions: []
      };
    }
    this.survey_form = this.formBuilder.group({
      _id: [this.setting._id],
      start_date: [this.setting.start_date != "" ? (this.setting?.start_date?.substring(0, 10)) : '', [Validators.required]],
      end_date: [this.setting.end_date != "" ? (this.setting?.end_date?.substring(0, 10)) : '', [Validators.required]],
      is_active: [this.setting.is_active],
      programme_type_id: [this.setting.programme_type_id],
      enrolled_year: [this.setting.enrolled_year],
      programme_id: [this.setting.programme_id],
      survey_questions: this.formBuilder.array(this.buildSurveyQuestionForm(this.setting.survey_questions))
    });
  }

  buildSurveyQuestionForm(questions: ProgrammeExitSurveyQuestionInterface[]): any {
    if (!questions) {
      questions = [];
    }
    var questions_array: any[] = [];
    if (questions && questions.length > 0) {
      var section_count = 0;
      for (const question of questions) {
        questions_array.push(this.formBuilder.group({
          pso_id: [question.pso_id],
          question: [question.question],
          response_options: [question.response_options],
          pso_max_point: [question.pso_max_point],
        }));
        section_count++;
      }
    }
    const remaining_count = 1 - questions.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        questions_array.push(this.formBuilder.group({
          pso_id: [""],
          question: [""],
          response_options: [[]],
          pso_max_point: [0],
        }));
      }
    }
    console.log(questions_array);
    return questions_array;
  }

  get surveyQuestions() {
    return ((this.survey_form as FormGroup).get('survey_questions') as FormArray).controls;
  }

  addQuestion() {
    ((this.survey_form as FormGroup).get('survey_questions') as FormArray).push(this.formBuilder.group({
      pso_id: [""],
      question: [""],
      response_options: [[]],
      pso_max_point: [0]
    }));
  }

  deleteQuestion(index: number) {
    const consent = confirm("Are you sure want to delete this question?");
    if (!consent) return;
    ((this.survey_form as FormGroup).get('survey_questions') as FormArray).removeAt(index);
  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.popup_mode = '';
    this.show_response = true;
    JQueryHelper.closeModal('#survey-modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as ProgrammeExitSurveyInterface;
    this.survey_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.survey_form.value;
      form_data.start_date = new Date(this.survey_form.value.start_date).toISOString();
      form_data.end_date = new Date(this.survey_form.value.end_date).toISOString();
      console.log(form_data);
      var survey_questions: ProgrammeExitSurveyQuestionInterface[] = form_data.survey_questions;
      survey_questions.forEach(question => {
        var pso_max_point = 0;
        question.response_options.forEach(response => {
          var response_point = this.response_point_map.find(x => x.response == response)?.point
          if (response_point > pso_max_point) {
            pso_max_point = response_point;
          }
        });
        question.pso_max_point = pso_max_point;
      });
      this.save_loading = true;
      if (this.survey_form.value._id) {
        const res = await this.restService.updateGES(this.survey_form.value._id, form_data);
        if (res) {
          alert("Survey questions updated successfully.");
        }
      }
      else {
        const res = await this.restService.createGES(form_data);
        if (res) {
          alert("Survey questions added successfully.");
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  /* async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteExamFeeHeader(this.survey_form.get('_id')!.value);
      if (res.success) {
        alert("Survey for the course removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  } */

  isInvalid(formControl: string): boolean {
    return this.survey_form.touched && this.survey_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.survey_form.status !== 'VALID';
  }

  navigateToExamSettings(setting: any) {
    this.router.navigate(["/admin/exam/fee-setting"], {
      queryParams: {
        setting_id: setting.id,
        programme_type_id: this.programme_type_id
      }
    }); //pass setting.id
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  onBackClick() {
    this.popup_mode = 'add';
    this.show_response = true;
  }


  getClassName(enrolled_year: number, programme_id?: string, section?: string, virtual_section?: string) {
    if (virtual_section) {
      return "Virtual Section " + virtual_section;
    }
    else {
      var programme_name = this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
      return this.commonEnums.getClassName(enrolled_year, programme_name, section);
    }
  }

  async allowReSubmission(student: ProgrammeExitSurveyResponseInterface) {
    try {
      const update_data = {
        survey_id: this.survey_form.value._id,
        roll_no: student.roll_no
      };
      const res = await this.restService.allowResubmissionGES(update_data);
      if (res) {
        alert("Allowed re-submission successfully");
        student.submitted = false;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }

  }

  showResponse() {
    this.show_response = !this.show_response;
  }

  downloadResponse() {
    const data_list: any[] = [];
    let i = 1;
    for (const student of this.setting.student_response) {
      var csv_obj: any = {
        "S.No": i,
        "Roll No": student.roll_no,
        "Name": student.given_name + " " + student.middle_name,
        "Response Date": student.response_date ? (this.getDateFormat(student.response_date)) : '---'
      }
      var total_point: number = 0;
      student.survey_responses.forEach((response, index) => {
        csv_obj["PSO Level_" + (index + 1)] = response.pso_id,
          csv_obj["Question_" + (index + 1)] = response.question,
          csv_obj["Response_" + (index + 1)] = response.response,
          csv_obj["Point_" + (index + 1)] = response.point

        total_point += response.point;
      });
      csv_obj.total_point = total_point;
      csv_obj.remarks = (student.remarks ? student.remarks : '---');
      i++;
      data_list.push(csv_obj);
    }
    console.log(data_list);
    CSVHelper.downloadCSV(data_list, "Programme Exit Survey Response" + DateHelper.convertToControlDate(new Date()));
  }


  getDateFormat(date_time: any) {
    date_time = new Date(date_time);
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" + date_time.getFullYear()
  }

}

interface ProgrammeExitSurveyInterface {
  _id?: string,
  programme_type_id: string,
  enrolled_year: number,
  programme_id?: string

  is_active: boolean,
  start_date: string,
  end_date: string,
  survey_questions: ProgrammeExitSurveyQuestionInterface[],
  student_response?: ProgrammeExitSurveyResponseInterface[],
  survey_nonsubmit_count?: number,
  survey_submit_count?: number,
  survey_total_count?: number
}

interface ProgrammeExitSurveyQuestionInterface {
  pso_id: number,
  question: string,
  pso_max_point?: number,
  response_options: string[]
}

interface ProgrammeExitSurveyResponseInterface {
  _id?: string,
  roll_no: string,
  given_name: string,
  middle_name: string,
  submitted: boolean,
  remarks?: string,
  response_date: string,
  survey_responses: {
    pso_id: number,
    question: string,
    response: string,
    point: number,
    pso_max_point?: number,
  }[]
}

