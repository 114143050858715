import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-absent-report',
  templateUrl: './absent-report.component.html',
  styleUrls: ['./absent-report.component.scss']
})
export class AbsentReportComponent implements OnInit {

  fonts = fonts;
  monthly_attendance_data: StudentData[] = [];
  title = 'Students Absent Report';
  attendance_data_loading: boolean = false;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  days: Date[] = [];
  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  category_list_loading = false;
  attendance_table_filter_form: FormGroup;
  attendance_upload_form: FormGroup;
  student_list_loading: boolean = false;
  hour_numbers: number[] = [];

  mode: string = "general";
  popup_mode: string = '';
  hour_status: {
    key: string, value: string
  }[] = [
      { key: "PRESENT", value: "Present" }, { key: "ABSENT", value: "Absent" }, { key: "LEAVE", value: "Leave" },
      { key: "ON-DUTY", value: "On Duty" }
    ];
  save_loading: boolean = false;

  constructor(private adminService: AdminservicesService, private fb: FormBuilder) {
    this.attendance_table_filter_form = this.fb.group({
      filter_mode: ["month"],
      programme_type_id: [''],
      enrolled_year: [''],
      semester: [''],
      date: [''],
    });


  }
  ngOnInit(): void {
    this.getProgrammeTypeList();
  }


  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.adminService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {

  }

  async getStudentData() {
    if (!this.attendance_table_filter_form.value.programme_type_id) {
      alert("Please choose programme type"); return;
    }
    if (!this.attendance_table_filter_form.value.enrolled_year) {
      alert("Please choose Enrollment Year"); return;
    }
    if (!this.attendance_table_filter_form.value.semester) {
      alert("Please choose Semester"); return;
    }
    if (!this.attendance_table_filter_form.value.date) {
      alert("Please choose Date"); return;
    }
    try {
      this.student_list_loading = true;
      this.monthly_attendance_data = [];
      const date_wise_report_payload = {
        "enrolled_year": this.attendance_table_filter_form.value.enrolled_year,
        "semester": this.attendance_table_filter_form.value.semester,
        "absent_date": this.attendance_table_filter_form.value.date,
        "programe_type_id": this.attendance_table_filter_form.value.programme_type_id
      }
      const service_response = await this.adminService.getStudentDateWiseAttendanceReport(date_wise_report_payload);
      if (service_response && service_response.success) {
        this.monthly_attendance_data = service_response.data;

        this.monthly_attendance_data.sort((a, b) => (a.roll_no > b.roll_no) ? 1 : -1);
        console.log(this.monthly_attendance_data);
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_list_loading = false;
    }
  }

  downloadCSV() {
    if(this.monthly_attendance_data.length > 0) {
      const data_list: any[] = [];
      let i = 1;
      for (const report of this.monthly_attendance_data) {
        let absent_hrs = '';
        if(report?.hours.length == 0) {
          absent_hrs = '0';
        } else {
          for(const hr of report?.hours) {
            absent_hrs += hr.toString() + ',';
          }
        }
        data_list.push({
          "S.No": i,
          "Roll No.": report?.roll_no,
          "Name": report?.given_name + ' ' + report?.middle_name,
          "Programme name": report?.programme_name,
          "Mobile number": report?.mobile_number,
          "Absent hours": absent_hrs,
        });
        i++;
      }
      CSVHelper.downloadCSV(data_list, `${this.attendance_table_filter_form.value.date}_ON_ABSENT_REPORT` +  DateHelper.convertToControlDate(new Date()));
    } else {
      alert("Report data must not be empty"); return;
    }
  }

  async viewAbsentHours(absentHours: number[]) {
    if(absentHours != null && absentHours.length > 0) {
      this.hour_numbers = absentHours;
      JQueryHelper.openModal('#modal-popup-view', { keyboard: false, backdrop: 'static' });
    } else {
      alert("Error while read data"); return;
    }
  }

  async onModalCloseClick(): Promise<void> {
    this.hour_numbers = [];
    JQueryHelper.closeModal('#modal-popup-view');
  }

}

interface HoursAbsent {
  hour_number: number,
  status: string
}

interface StudentData {
  roll_no: string,
  finance_type: string;
  given_name: string,
  middle_name: string;
  programme_name: string,
  day: string;
  email:string;
  mobile_number: number;
  enrolled_year?: number,
  semester?: number,
  is_selected?: boolean,
  hours: number[]
}
