import { Room } from './../../../../../room-model';
import { NavigationService } from '../../../../../../../../components/navigation/navigation.service';
import { Floor } from '../../../../../floor-model';
import { Hostel, HostelBody } from '../../../../../hostelModel';
import { AdminservicesService } from '../../../../../../../../services/rest/adminservices.service';
import { NotificationService } from '../../../../../../../../components/notification/notification.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { debounceTime } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss']
})
export class EditRoomComponent implements OnInit {
  hostelId: string = '';
  floorId: string = '';
  roomId: string = '';
  hostel: Hostel = {} as Hostel;
  roomForm: FormGroup;
  floor: Floor = {} as Floor;
  fonts = fonts;
  rooms: Room[] = [];
  room: Room = {} as Room;
  searchForm: FormGroup;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.roomForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.searchForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  getRoom(roomId: string): Room {
    const selectedFloor = this.hostel.floors?.filter(floor => floor.id === this.floorId)[0];
    if (selectedFloor) {
      const selectedRoom = selectedFloor.rooms.filter(room => room.id === roomId)[0];
      if (selectedRoom) {
        return selectedRoom;
      }
    }
    return <Room>{};
  }

  buildForm(): void {
    this.room = this.getRoom(this.roomId);
    if (this.room) {
      this.roomForm = this.formBuilder.group({
        name: [this.room.name, Validators.required]
      });
    }
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.route.params.subscribe(params => {
      this.hostelId = params?.hostelId;
      this.floorId = params?.floorId;
      this.roomId = params?.roomId;
      if (!this.floorId || !this.hostelId) {
        this.notificationService.setNotifcation({ message: 'Hostel or Floor is missing', type: 'Error' });
      }
      this.navigationService.updateNavigation('Edit Room', this.router.url);
      this.restService.hostelManagementService.getHostels(this.hostelId).subscribe(hostels => {
        if (hostels) {
          this.hostel = hostels[0];
          this.buildForm();
        }
      });
    });
  }

  onSave(): void {
    const hostelBody: HostelBody = {
      data: {
        floors: [
          {
            hostel_id: this.hostelId,
            id: this.floorId,
            rooms: [
              {
                floor_id: this.floorId,
                name: this.getRoomFromForm(),
                id: this.roomId,
                incharges: []
              }
            ]
          }
        ]
      }
    };
    this.restService.hostelManagementService.updateHostel(this.hostelId, hostelBody).subscribe(hostel => {
      if (hostel) {
        this.notificationService.setNotifcation({ message: 'Added room successfully.', type: 'Success' });
        this.hostel = hostel;
      }
    });
  }

  getFormData(): any {
    return this.roomForm.value;
  }

  getRoomFromForm(): string {
    return this.getFormData().name;
  }

  canSave(): boolean {
    if (!this.getFormData().name) {
      return true;
    }
    return false;
  }

  onClose(): void {
    this.navigationService.navigateBack();
    this.notificationService.setNotifcation({});
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  onDelete(room: Room): void {
    const subURL = `${this.hostelId}?floorId=${this.floorId}&roomId=${room.id}`;
    this.restService.hostelManagementService.deleteHostel(subURL).subscribe(data => {
      if (data) {
        this.rooms = this.rooms.filter(roomData => roomData.id !== room.id);
        this.notificationService.setNotifcation({ message: 'Room deleted successfully', type: 'Success' });
      }
    }, this.errorHandler);
  }

  onClickIncharges(): void {
    this.router.navigate([`admin/hostel/hostel-management/incharges/${this.hostel.id}/${this.floorId}/${this.roomId}`]);
  }
}
