<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Mark Cum Grade Register</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Mark Cum Grade Register</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">To view the students mark & grade</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Month</label>
                                        <select formControlName="month" class="form-control">
                                            <option value="0">Select Month</option>
                                            <option
                                                *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="month.id">{{month.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Year</label>
                                        <select formControlName="year" class="form-control">
                                            <option value="0">Select year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Enrollment Year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Choose Enrollment Year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Exam</label>
                                        <select formControlName="exam_id" class="form-control"
                                            (change)="getMappedProgrammeList()">
                                            <option value="">Select Exam</option>
                                            <option
                                                *ngFor="let exam of settings_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="exam._id">{{exam.course_code}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="programme_search_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <kendo-multiselect [data]="sections" valueField="section_name"
                                            textField="section_name" formControlName="sections"
                                            placeholder="Select Section(s)">
                                        </kendo-multiselect>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="this.part_course_types && this.part_course_types?.length>0">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Type</label>
                                        <select formControlName="course_type" class="form-control">
                                            <option value="">All Course Type</option>
                                            <option
                                                *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="course_type.id">{{course_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                            Course Position</label>
                                        <select formControlName="position_no" class="form-control">
                                            <option value="0">Select Course Position</option>
                                            <option
                                                *ngFor="let course_position of this.commonEnums.getNumbers(50);let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="course_position">
                                                {{course_position}} -
                                                {{this.commonEnums.roman_letters[course_position]}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                            Appearance Type</label>
                                        <select formControlName="appearance_type" class="form-control">
                                            <option value="">Select Appearance Type</option>
                                            <option
                                                *ngFor="let type of this.appearance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="type.key">
                                                {{type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Course Code</label>
                                        <input type="text" formControlName="course_code" class="form-control"
                                            placeholder="Enter Course Code">
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mr-2 search-button" title="Search"
                                            *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row" *ngIf="course_data_list">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-info mg-r-10" (click)="printData()">Print</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="!course_data_list">
                            <div class="col-md-12 text-center">
                                <b>
                                    <ng-container *ngIf="!course_list_loading">
                                        No Records found
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading">
                                        Loading Please wait...
                                    </ng-container>
                                </b>
                            </div>
                        </div>
                        <div id="print-area" *ngIf="course_data_list">
                            <div class="row">
                                <div class="col-md-2">
                                    <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                                        alt="college logo"
                                        style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                                </div>
                                <div class="col-md-8 text-center">
                                    <h4 style=" margin-bottom: 20px !important;">AYYA NADAR JANAKI AMMAL COLLEGE
                                        (Autonomous), SIVAKASI.</h4>
                                    <h5>Terminal Examination - NOVEMBER, 2022</h5>
                                    <!--Remove it-->
                                    <h5>MARK CUM GRADE REGISTER</h5>
                                </div>
                            </div>

                            <div class="row" style="margin-top: 15px;">
                                <div class="col-md-2">
                                    <!-- <span><b>{{getMonth(course_data_list?.query?.month)}} -
                                            {{course_data_list?.query?.year}}</b></span> -->
                                    <span>November, 2022</span>
                                </div>
                                <div class="col-md-2">
                                    Class: <b>{{getProgrammeName(course_data_list?.query?.programme_id)}}</b>
                                </div>
                                <div class="col-md-2">
                                    Semester: <b>{{commonEnums.roman_letters[course_data_list?.query?.semester]}}</b>
                                </div>
                                <div class="col-md-6">
                                    Code & Title of the Course:
                                    <span><b>{{course_data_list?.courses}}</b></span>
                                </div>
                            </div>


                            <div class="table-responsive" id="print-table" style="margin-top: 10px;">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr style="text-align: center;">
                                            <th style="width: 5%;vertical-align: middle;">REGISTER NUMBER</th>
                                            <th style="width: 25%;vertical-align: middle;">NAME</th>
                                            <th style="width: 8%;vertical-align: middle;">COURSE CODE</th>
                                            <th style="vertical-align: middle;"
                                                *ngFor="let head of getValuationHeader(); let in=index;trackBy:fromDataResolver.identify;">
                                                Val - {{in + 1}}
                                            </th>
                                            <th style="vertical-align: middle;">EXT
                                                <br>({{course_data_list?.mark_data[0]?.external_max}})
                                            </th>
                                            <th style="vertical-align: middle;">INT
                                                <br>({{course_data_list?.mark_data[0]?.internal_max}})
                                            </th>
                                            <th style="vertical-align: middle;">TOTAL
                                                <br>({{course_data_list?.mark_data[0]?.total_max}})
                                            </th>
                                            <th style="vertical-align: middle;">GP</th>
                                            <th style="vertical-align: middle;">LG</th>
                                            <th style="vertical-align: middle;">STATUS</th>
                                            <th style="width: 20%; vertical-align: middle;">Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let student_data of course_data_list.mark_data;let k=index;trackBy:fromDataResolver.identify;">
                                            <td scope="row">
                                                {{student_data.roll_no}}
                                            </td>
                                            <td class="text-uppercase">
                                                {{student_data.name}}</td>
                                            <td>
                                                <span *ngIf="course_data_list?.show_course_code">
                                                    {{student_data?.course_code}}
                                                </span>
                                            </td>
                                            <td
                                                *ngFor="let valuation of student_data.valuations; let in=index;trackBy:fromDataResolver.identify;">
                                                {{valuation.mark}}
                                            </td>
                                            <td
                                                *ngFor="let valuation of getEmptyValuation(student_data.valuations); let in=index;trackBy:fromDataResolver.identify;">

                                            </td>
                                            <td>{{student_data.external}}</td>
                                            <td>{{student_data.internal}}</td>
                                            <td>{{student_data.total}}</td>
                                            <td>{{student_data.grade_point}}</td>
                                            <td>{{student_data.grade_letter}}</td>
                                            <td>{{student_data.result_status}}</td>
                                            <td>{{student_data.remarks}}</td>
                                        </tr>
                                        <!--  <tr>
                                                                            <td colspan="2">
                                                                                <div class="row text-center">
                                                                                    <div class="col-md-2">
                                
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        VERIFIED BY
                                                                                    </div>
                                                                                    <div class="col-md-3">
                                                                                        CONTROLLER OF EXAMINATIONS<br>(P.G.COURSES)
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        PRINCIPAL
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </div>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>