<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>
    <!-- row -->
    <div class="row row-sm" *ngIf="user_info">
      <ng-container [formGroup]="user_form">
        <!-- Col -->
        <div class="col-lg-12">
          <div class="card mg-b-20">
            <div class="card-body">
              <div class="pl-0">
                <div class="main-profile-overview " style="text-align: center;">
                  <div class="main-img-user profile-user">

                    <ng-container *ngIf="user_form.value.gender=='male'">
                      <img alt="" src="./assets/img/users/man-128.png">
                    </ng-container>
                    <ng-container *ngIf="user_form.value.gender=='female'">
                      <img alt="" src="./assets/img/users/women-128.png">
                    </ng-container>

                  </div>
                  <div class="justify-content-between mg-b-20">
                    <div>
                      <h5 class="main-profile-name">{{user_info.name}}</h5>
                    </div>
                  </div>

                  <!--skill bar-->
                </div><!-- main-profile-overview -->
              </div>
            </div>
          </div>

        </div>
        <!-- Col -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">AADHAR Card Information</div>
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="row">
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="blood_group">AADHAR card number<small style="color: red;"> *</small></label>
                        <input class="form-control" placeholder="AADHAR card number" type="text"
                          formControlName='aadhar_card_no'>

                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="blood_group">Upload AADHAR Card Front image<small style="color: red;"> *</small></label>
                    <input class="form-control" required="" type="file"
                      (change)="uploadImage($event,user_form, 'aadhar_card_no_file_front')" accept="image/*">
                    <ng-container *ngIf='user_form.value.aadhar_card_no_file_front'>
                      <label for="blood_group">Preview Front</label>
                      <img style="width: 400px;height: 200px;" [src]='user_form.value.aadhar_card_no_file_front' />
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="blood_group">Upload AADHAR Card Back image<small style="color: red;"> *</small></label>
                    <input class="form-control" required="" type="file"
                      (change)="uploadImage($event,user_form, 'aadhar_card_no_file_back')" accept="image/*">
                    <ng-container *ngIf='user_form.value.aadhar_card_no_file_back'>
                      <label for="blood_group">Preview Back</label>
                      <img style="width: 400px;height: 200px;" [src]='user_form.value.aadhar_card_no_file_back' />
                    </ng-container>
                  </div>
                </div>




              </div>
            </div>
          </div>


        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">Personal Information</div>
              <div class="row">

                <div class="col-md-4 col-lg-4">
                  <div class="form-group">
                    <label>Full Name <small style="color: red;">
                        *</small></label>

                    <div class="row mt-0">
                      <div class="col-3">
                        <input class="form-control" placeholder="Inital" type="text" formControlName='middle_name'>
                      </div>
                      <div class="col-9">
                        <input class="form-control" placeholder="Full Name" type="text" formControlName='given_name'>
                      </div>

                    </div>



                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="form-group">
                    <label>Date of Birth </label>
                    <input class="form-control" type="date" formControlName='birth_date'>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">

                  <div class="form-group">
                    <label>Gender </label> <br>
                    <div class="mg-t-5">
                      <div class="custom-control custom-radio" style="display: inline;">
                        <input id="male" type="radio" class="custom-control-input" value="male" name="gender"
                          formControlName="gender">
                        <label class="custom-control-label" for="male">Male</label>
                      </div>

                      <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                        <input id="female" type="radio" class="custom-control-input" value="female" name="gender"
                          formControlName="gender">
                        <label class="custom-control-label" for="female">Female</label>
                      </div>
                      <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                        <input id="transgender" type="radio" class="custom-control-input" value="transgender"
                          name="gender" formControlName="gender">
                        <label class="custom-control-label" for="transgender">Transgender</label>
                      </div>
                    </div>
                  </div>



                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="blood_group">Blood Group<small style="color: red;"> *</small></label>

                    <select class="form-control " id="blood_group" name="blood_group" formControlName="blood_group">
                      <option value="">Choose your Blood Group</option>
                      <option
                        *ngFor="let blood_group of commonEnums.blood_groups;let i=index;trackBy:formDataResolver.identify;"
                        [ngValue]="blood_group.id">
                        {{blood_group.text}}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12 col-lg-12">
                  <div class="form-group mg-t-5">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="physically_challanged"
                        name="physically_challanged" formControlName="physically_challanged">
                      <label class="custom-control-label" for="physically_challanged">Are you physically
                        disabled?</label>
                    </div>


                  </div>
                </div>
                <div class="col-md-12 col-lg-12">
                  <div class="form-group mg-t-5">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="ex_service" name="ex_service"
                        formControlName="ex_service">
                      <label class="custom-control-label" for="ex_service">Are you son/daughter
                        of Ex-Service man?</label>
                    </div>


                  </div>
                </div>
                <ng-container *ngIf="this.user_form.value.ex_service==true">
                  <br>
                  <div class="col-md-6 col-lg-6">

                    <div class="form-group">
                      <label for="ex_service_rank">Ex-Service Man Rank<small style="color: red;">
                          *</small></label>
                      <input type="text" id="ex_service_rank" name="ex_service_rank" class="form-control"
                        formControlName="ex_service_rank">

                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="ex_service_i_card_no">Ex-Service Man I-Card Number<small style="color: red;">
                          *</small></label>
                      <input type="text" id="ex_service_i_card_no" name="ex_service_i_card_no" class="form-control"
                        formControlName="ex_service_i_card_no">

                    </div>
                  </div>

                </ng-container>

                <div class="col-md-12 col-lg-12">
                  <div class="form-group mg-t-5">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="sports_certs" name="sports_certs"
                        formControlName="sports_certs">
                      <label class="custom-control-label" for="sports_certs">Have you participated in
                        Sports Event(s)
                        ?</label>
                    </div>


                  </div>
                </div>

                <div class="col-md-12 col-lg-12">
                  <div class="form-group mg-t-5">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="need_hostel" name="need_hostel"
                        formControlName="need_hostel">
                      <label class="custom-control-label" for="need_hostel">Do you need Hostel
                        Facility?</label>
                    </div>


                  </div>
                </div>

                <div class="col-md-12 col-lg-12" *ngIf="this.user_form.value.gender.toLowerCase()=='female'">
                  <div class="form-group mg-t-5">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="need_bus" name="need_bus"
                        formControlName="need_bus">
                      <label class="custom-control-label" for="need_bus">Do you need Transportation
                        Facility?</label>
                    </div>


                  </div>
                </div>
                <br>
                <div class="col-md-12 col-lg-12">
                  <div class="form-group mg-t-5">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="is_applicant_married"
                        name="is_applicant_married" formControlName="is_applicant_married">
                      <label class="custom-control-label" for="is_applicant_married">Is Applicant
                        Married?</label>
                    </div>


                  </div>
                </div>



              </div>
            </div>
          </div>


        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="main-content-label tx-13 mg-b-25">
                Conatct
              </div>
              <div class="main-profile-contact-list">
                <div class="row">
                  <div class="col-md-3 col-lg-3">
                    <label>Father's / Guardian's Mobile Number <small style="color: red;">
                        *</small></label>
                    <input class="form-control" placeholder="Mobile Number" type="text" formControlName='mobile_number'>
                  </div>
                  <div class="col-md-2 col-lg-2">
                    <label>Applicant Mobile Number <small style="color: red;">
                        *</small></label>
                    <input class="form-control" placeholder="Applicant Mobile Number" type="text"
                      formControlName='mobile_number_2'>
                  </div>
                  <div class="col-md-2 col-lg-2">
                    <label>Alternate Contact No. 2 </label>
                    <input class="form-control" placeholder="Alternate Contact No. 2" type="text"
                      formControlName='mobile_number_3'>
                  </div>
                  <div class="col-md-2 col-lg-2">
                    <label>Alternate Contact No. 3</label>
                    <input class="form-control" placeholder="Alternate Contact No. 3" type="text"
                      formControlName='mobile_number_4'>
                  </div>
                  <div class="col-md-2 col-lg-2">
                    <label>Alternate Contact No. 4</label>
                    <input class="form-control" placeholder="Alternate Contact No. 4" type="text"
                      formControlName='mobile_number_5'>
                  </div>
                </div>
                <div class="mt-3"></div>

                <div class="row">
                  <div class="col-md-3 col-lg-3">
                    <label>Email <small style="color: red;">
                        *</small></label>
                    <input class="form-control" placeholder="Email" type="text" formControlName='email'>
                  </div>

                </div>
              </div><!-- main-profile-contact-list -->
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">Nationality and Address</div>
              <p class="mb-25">Enter your communication address, This will be used to send any official letter
                from college later.</p>
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="row">
                    <div class="col-md-3 col-lg-3">
                      <div class="form-group">
                        <label for="nationality">Nationality<small style="color: red;">
                            *</small></label>
                        <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                          <input id="indian" type="radio" class="custom-control-input" value="indian" name="nationality"
                            formControlName="nationality">
                          <label class="custom-control-label" for="indian">Indian</label>
                        </div>

                        <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                          <input id="others" type="radio" class="custom-control-input" value="others" name="nationality"
                            formControlName="nationality">
                          <label class="custom-control-label" for="others">Others</label>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <ng-container formGroupName="address">
                  <div class="col-md-12 col-lg-12"
                    *ngIf="formDataResolver.getValueFromFromGroup(this.user_form,'nationality')=='others'">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                          <label for="country">Select your Country<small style="color: red;">
                              *</small></label>
                          <select class="form-control " id="country" name="country" formControlName="country">
                            <option
                              *ngFor="let country of commonEnums.country_list;let i=index;trackBy:formDataResolver.identify;"
                              [ngValue]="country">
                              {{country}}
                            </option>
                          </select>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="form-group">

                          <ng-container
                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'country')=='India'">

                            <label for="state">Select your state<small style="color: red;">
                                *</small></label>
                            <select class="form-control " id="state" name="state" formControlName="state">
                              <option
                                *ngFor="let india_state of commonEnums.india_states;let i=index;trackBy:formDataResolver.identify;"
                                [ngValue]="india_state">
                                {{india_state}}
                              </option>
                            </select>
                          </ng-container>
                          <ng-container
                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'country')!='India'">
                            <label for="state">State<small style="color: red;"> *</small></label>
                            <input type="text" id="state" name="state" class="form-control" formControlName="state"
                              appFormControlValidationMsg validationMsgId="state">
                          </ng-container>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                          <ng-container
                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'state')=='Tamil Nadu'">

                            <label for="district">Select your district<small style="color: red;">
                                *</small></label>
                            <select class="form-control " id="district" name="district" formControlName="district">
                              <option
                                *ngFor="let district_in_tamil_nadu of commonEnums.districts_in_tamil_nadu;let i=index;trackBy:formDataResolver.identify;"
                                [ngValue]="district_in_tamil_nadu">
                                {{district_in_tamil_nadu}}
                              </option>
                            </select>
                          </ng-container>
                          <ng-container
                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'state')!='Tamil Nadu'">
                            <label for="district">District<small style="color: red;"> *</small></label>
                            <input type="text" id="district" name="district" class="form-control"
                              formControlName="district">
                          </ng-container>



                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="form-group">

                          <label for="city">City<small style="color: red;"> *</small></label>
                          <input type="text" id="city" name="city" class="form-control" formControlName="city">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                          <label for="pincode">Pincode<small style="color: red;"> *</small></label>
                          <input type="text" id="pincode" name="pincode" class="form-control" formControlName="pincode">

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="form-group">
                          <label for="street">Enter the residential address <small style="color: red;">
                              *</small></label>
                          <input type="text" id="street" name="street" class="form-control" formControlName="street">
                          <small id="mobile_number_3_help_line" class="text-muted d-block">
                            Enter door number, street name and other landmarks.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>


              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">Identification Marks</div>
              <p class="mb-25">Fill your idendication mark one by one, this will be used in your
                certifficates. (Required to fill atleast one)</p>
              <div class="row">
                <div class="col-xl-12">
                  <ng-container formArrayName="identification_marks">
                    <div
                      *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form,'identification_marks'); let i=index;trackBy:formDataResolver.identify;">
                      <div class="form-group">
                        <input type="text" id="identification_marks_{{i}}" placeholder="Indendification Mark {{i+1}}"
                          name="identification_marks_{{i}}" class="form-control" [formControlName]="i">
                      </div>
                    </div>
                  </ng-container>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">Information about Parent / Guardian</div>
              <p class="mb-25">Fill up your Parent / Guardian details. This will help us for further process.</p>
              <div class="row">
                <div class="col-xl-12">

                  <div class="form-group">

                    <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                      <input id="parent" type="radio" class="custom-control-input" value="parent" name="parental_status"
                        formControlName="parental_status">
                      <label class="custom-control-label" for="parent">Parent</label>
                    </div>

                    <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                      <input id="guardian" type="radio" class="custom-control-input" value="guardian"
                        name="parental_status" formControlName="parental_status">
                      <label class="custom-control-label" for="guardian">Guardian</label>
                    </div>


                  </div>
                  <ng-container formArrayName="family_members">


                    <div class="table-wrap">
                      <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <ng-container *ngIf="this.user_form.controls.parental_status.value=='parent'">
                                <th style="width:3%">S.No.</th>

                                <th style="width:15%">Parant</th>
                              </ng-container>
                              <ng-container *ngIf="this.user_form.controls.parental_status.value=='guardian'">
                                <th style="width:15%">Guardian Relationship</th>
                              </ng-container>
                              <th style="width:20%">Name</th>
                              <ng-container *ngIf="!locationHelper.isMobile()">
                                <th style="width:20%">Occupation</th>
                                <th style="width:15%">Monthly Income (Rs.)</th>
                              </ng-container>
                              <!-- <th>Contact No</th>
                                                                                                                                            <th>Contact Email</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container
                              *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form,'family_members'); let i=index;trackBy:formDataResolver.identify;">

                              <ng-container
                                *ngIf="this.user_form.controls.parental_status.value=='parent' && (i==0 || i==1)">
                                <tr>

                                  <ng-container [formGroupName]="i">
                                    <ng-container *ngIf="this.user_form.controls.parental_status.value=='parent'">
                                      <td>
                                        {{i+1}}
                                      </td>
                                    </ng-container>
                                    <td>
                                      <div class="form-group">
                                        <ng-container *ngIf="i==0">
                                          <label for="" style="margin-top: 11px;">Father</label>
                                        </ng-container>
                                        <ng-container *ngIf="i==1">
                                          <label for="" style="margin-top: 11px;">Mother</label>
                                        </ng-container>

                                      </div>
                                    </td>
                                    <ng-container *ngIf="!locationHelper.isMobile()">
                                      <td>
                                        <div class="form-group">
                                          <input type="text" id="given_name" name="given_name" class="form-control"
                                            formControlName="given_name">
                                        </div>
                                      </td>
                                      <td>
                                        <div class="form-group">
                                          <input type="text" id="occupation" name="occupation" class="form-control"
                                            formControlName="occupation">
                                        </div>
                                      </td>
                                      <td>
                                        <div class="form-group">
                                          <input type="number" min="0" id="salary" name="salary" class="form-control"
                                            formControlName="salary">
                                        </div>
                                      </td>
                                    </ng-container>
                                    <ng-container *ngIf="locationHelper.isMobile()">
                                      <td>
                                        <div class="form-group">
                                          <label for="street">Name</label>
                                          <input type="text" id="given_name" name="given_name" class="form-control"
                                            formControlName="given_name">
                                        </div>
                                        <div class="form-group">
                                          <label for="street">Occupation</label>
                                          <input type="text" id="occupation" name="occupation" class="form-control"
                                            formControlName="occupation">
                                        </div>
                                        <div class="form-group">
                                          <label for="street">Salary</label>
                                          <input type="number" min="0" id="salary" name="salary" class="form-control"
                                            formControlName="salary">
                                        </div>
                                      </td>
                                    </ng-container>

                                  </ng-container>
                                </tr>
                              </ng-container>

                              <ng-container *ngIf="this.user_form.controls.parental_status.value=='guardian' && (i==2)">
                                <tr>

                                  <ng-container [formGroupName]="i">


                                    <td>
                                      <ng-container *ngIf="i==2">
                                        <select class="form-control " id="relationship_name" name="relationship_name"
                                          formControlName="relationship_name">
                                          <option
                                            *ngFor="let relationship_name of commonEnums.relationship_names;let i=index;trackBy:formDataResolver.identify;"
                                            [ngValue]="relationship_name">
                                            {{relationship_name}}
                                          </option>
                                        </select>
                                      </ng-container>
                                    </td>
                                    <ng-container *ngIf="!locationHelper.isMobile()">
                                      <td>
                                        <div class="form-group">
                                          <input type="text" id="given_name" name="given_name" class="form-control"
                                            formControlName="given_name">
                                        </div>
                                      </td>
                                      <td>
                                        <div class="form-group">
                                          <input type="text" id="occupation" name="occupation" class="form-control"
                                            formControlName="occupation">
                                        </div>
                                      </td>
                                      <td>
                                        <div class="form-group">
                                          <input type="number" min="0" id="salary" name="salary" class="form-control"
                                            formControlName="salary">
                                        </div>
                                      </td>
                                    </ng-container>
                                    <ng-container *ngIf="locationHelper.isMobile()">
                                      <td>
                                        <div class="form-group">
                                          <label for="street">Name</label>
                                          <input type="text" id="given_name" name="given_name" class="form-control"
                                            formControlName="given_name">
                                        </div>
                                        <div class="form-group">
                                          <label for="street">Occupation</label>
                                          <input type="text" id="occupation" name="occupation" class="form-control"
                                            formControlName="occupation">
                                        </div>
                                        <div class="form-group">
                                          <label for="street">Salary</label>
                                          <input type="number" min="0" id="salary" name="salary" class="form-control"
                                            formControlName="salary">
                                        </div>
                                      </td>
                                    </ng-container>
                                  </ng-container>
                                </tr>
                              </ng-container>

                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>



                  </ng-container>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">Community and Caste</div>
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="community_id">Select Community<small style="color: red;"> *</small></label>

                    <select class="form-control " id="community_id" name="community_id" formControlName="community_id">
                      <option value="">Choose your community</option>
                      <option
                        *ngFor="let community of commonEnums.community_list;let i=index;trackBy:formDataResolver.identify;"
                        [ngValue]="community">{{community}}
                      </option>
                    </select>


                  </div>

                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="caste">Caste<small style="color: red;"> *</small></label>
                    <input type="text" id="caste" name="caste" class="form-control" formControlName="caste">
                    <small id="mobile_number_3_help_line" class="text-muted d-block">
                      Enter as per community certifiate
                    </small>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4">
                  <div class="form-group">
                    <label for="religion">Religion<small style="color: red;"> *</small></label>
                    <select class="form-control " id="religion" name="religion" formControlName="religion">
                      <option value="">Choose your Religion</option>
                      <option
                        *ngFor="let religion of commonEnums.religions;let i=index;trackBy:formDataResolver.identify;"
                        [ngValue]="religion.id">
                        {{religion.text}}
                      </option>
                    </select>

                  </div>
                </div>
                <ng-container *ngIf="user_form.value.community_id!=='OC'">
                  <div class="col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="blood_group">Upload Community Certificate image<small style="color: red;">
                          *</small></label>
                      <input class="form-control" required="" type="file"
                        (change)="uploadImage($event,user_form, 'community_id_file')" accept="image/*">
                      <ng-container *ngIf='user_form.value.community_id_file'>
                        <label for="blood_group">Preview</label>
                        <img style="width: 400px;height: 200px;" [src]='user_form.value.community_id_file' />
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-4 main-content-label">Programme Type</div>
              <div class="row">
                <div class="col-md-12 col-lg-12">

                  <div class="form-group">
                    <label for="nationality">Would you like to Apply For<small style="color: red;">
                        *</small></label>
                    <ng-container *ngIf="locationHelper.isMobile()">
                      <br />
                    </ng-container>
                    <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                      <input id="ug" type="radio" class="custom-control-input" value="UG" name="apply_for"
                        formControlName="apply_for">
                      <label class="custom-control-label" for="ug">UG Programmes</label>
                    </div>

                    <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                      <input id="pg" type="radio" class="custom-control-input" value="PG" name="apply_for"
                        formControlName="apply_for">
                      <label class="custom-control-label" for="pg">PG Programmes</label>
                    </div>


                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
        <ng-container *ngIf="user_form.value.apply_for === 'UG'">


          <div class="col-lg-12">
            <div class="card">
              <!-- <div class="card-body">
                <div class="mb-4 main-content-label">Academic Details : SSLC </div>
                <ng-container formGroupName="sslc_academics">

                  <div class="row mg-t-30">

                    <ng-container formGroupName="accademic_info">
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">School Name<small style="color: red;">
                              *</small></label>
                          <input type="text" name="school_name" class="form-control" formControlName="school_name">

                        </div>

                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">Place of School<small style="color: red;">
                              *</small></label>
                          <input type="text" name="school_place" class="form-control" formControlName="school_place">

                        </div>

                      </div>
                      <div class="col-md-6 col-md-6">

                        <div class="form-group">
                          <label for="ex_service_rank">Examination Registration Number<small style="color: red;">
                              *</small></label>
                          <input type="text" id="exam_reg_no" name="exam_reg_no" class="form-control"
                            formControlName="exam_reg_no">
                          <small id="mobile_number_3_help_line" class="text-muted d-block">
                            Enter as per hall ticket
                          </small>
                        </div>


                      </div>

                      <div class="col-md-6 col-lg-6">

                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-6 form-group">
                              <label for="month_of_passout">Month of Passing <small style="color: red;"> *</small>
                              </label>

                              <select class="form-control custom-select" formControlName="month_of_passout">
                                <option *ngFor="let month of commonEnums.months" [ngValue]="month.id">
                                  {{month.text}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-6 form-group">
                              <label for="year_of_passout">Year of Passing <small style="color: red;"> *</small>
                              </label>
                              <select class="form-control custom-select" formControlName="year_of_passout">
                                <option *ngFor="let year of commonEnums.getLastNYears(10)" [ngValue]="year">
                                  {{year}}
                                </option>
                              </select>
                            </div>

                          </div>

                        </div>

                      </div>


                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="syllabus_type">Board <small style="color: red;"> *</small>
                          </label>
                          <select class="form-control" id="syllabus_type" name="syllabus_type"
                            (change)="onSSLCBoardChange()" formControlName="syllabus_type">
                            <option
                              *ngFor="let syllabus_type of commonEnums.sslc_syllabus_types;let i=index;trackBy:formDataResolver.identify;"
                              [ngValue]="syllabus_type">
                              {{syllabus_type}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group mg-t-5">
                          <label for="medium_of_study">Medium of instruction<small style="color: red;">
                              *</small></label>
                          <div class="row">
                            <div class="col-md-12 col-lg-12">
                              <div class="custom-control custom-radio" style="display: inline;">
                                <input id="sslc_tamil" type="radio" class="custom-control-input" value="tamil"
                                  formControlName="medium_of_study">
                                <label class="custom-control-label" for="sslc_tamil">Tamil</label>
                              </div>

                              <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                                <input id="sslc_english" type="radio" class="custom-control-input" value="english"
                                  formControlName="medium_of_study">
                                <label class="custom-control-label" for="sslc_english">English</label>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="blood_group">Upload SSLC Mark Certificate<small style="color: red;">
                              *</small></label>
                          <input class="form-control" required="" type="file"
                            (change)="uploadImage($event,user_form.controls.sslc_academics.controls.accademic_info, 'mark_statement_file')"
                            accept="image/*">
                          <ng-container
                            *ngIf='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file'>
                            <label for="blood_group">Preview</label>
                            <img style="width: 400px;height: 200px;"
                              [src]='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file' />
                          </ng-container>
                        </div>
                      </div>


                    </ng-container>






                    <div class="col-md-12 col-lg-12">
                      <label for="ex_service_rank" class="mb-4 main-content-label">Mark Details<small
                          style="color: red;">
                          *</small></label>
                      <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th width="60">S.No.</th>

                              <ng-container *ngIf="!locationHelper.isMobile()">
                                <th width="150">Subject Type</th>
                                
                                <th>Subject Name</th>

                                <th width="150">Mark / Grade</th>
                              </ng-container>
                              <ng-container *ngIf="locationHelper.isMobile()">
                                <th></th>
                              </ng-container>
                            </tr>
                          </thead>
                          <tbody>


                            <ng-container formArrayName="marks">
                              <ng-container
                                *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.sslc_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                <ng-container [formGroup]='alias'>
                                  <tr>

                                    <td>
                                      {{i+1}}
                                    </td>
                                    <ng-container *ngIf="!locationHelper.isMobile()">
                                      <td>
                                        <ng-container
                                          *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                          <select class="form-control " formControlName="subject_type">
                                            <option value="">Choose</option>
                                            <option
                                              *ngFor="let icse_subject_type of commonEnums.icse_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                              [ngValue]="icse_subject_type">
                                              {{icse_subject_type}}
                                            </option>
                                          </select>
                                        </ng-container>
                                        <ng-container
                                          *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
                                          {{alias.value.subject_type}}
                                          {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                        </ng-container>


                                      </td>
                                      
                                      <td>
                                        <ng-container
                                          *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">

                                          <ng-container *ngIf="alias.value.subject_type == 'LANGUAGE'">

                                            <ng-container *ngIf="alias.value.part_index == 1">

                                              {{alias.value.subject_name}}
                                            </ng-container>
                                            <ng-container *ngIf="alias.value.part_index == 0">

                                              <ng-container
                                                *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type === 'STATE_BOARD'">
                                                TAMIL
                                              </ng-container>


                                              <ng-container
                                                *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type !== 'STATE_BOARD'">
                                                <select class="form-control" formControlName="subject_name">
                                                  <option value="">Choose Subject</option>
                                                  <option
                                                    *ngFor="let language1_subject of commonEnums.language1_subjects;let i=index;trackBy:formDataResolver.identify;"
                                                    [ngValue]="language1_subject">
                                                    {{language1_subject}}
                                                  </option>
                                                </select>
                                              </ng-container>
                                            </ng-container>




                                          </ng-container>
                                          <ng-container *ngIf="alias.value.subject_type != 'LANGUAGE'">
                                            {{alias.value.subject_name}}
                                          </ng-container>
                                        </ng-container>
                                        <ng-container
                                          *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                          <input type="text" class="form-control" formControlName="subject_name">
                                        </ng-container>
                                      </td>


                                      <td>
                                        <input type="text" class="form-control" formControlName="mark">
                                      </td>

                                    </ng-container>
                                    <ng-container *ngIf="locationHelper.isMobile()">
                                      <td>
                                        <div class="form-group">
                                          <label>Subject Type : </label>
                                          <ng-container
                                            *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                            <select class="form-control " formControlName="subject_type">
                                              <option value="">Choose</option>
                                              <option
                                                *ngFor="let icse_subject_type of commonEnums.icse_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                [ngValue]="icse_subject_type">
                                                {{icse_subject_type}}
                                              </option>
                                            </select>
                                          </ng-container>
                                          <ng-container
                                            *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
                                            {{alias.value.subject_type}}
                                            {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                          </ng-container>
                                        </div>
                                        <div class="form-group">
                                          <label>Subject Name : </label>
                                          <ng-container
                                            *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">

                                            <ng-container *ngIf="alias.value.subject_type == 'LANGUAGE'">

                                              <ng-container *ngIf="alias.value.part_index == 1">

                                                {{alias.value.subject_name}}
                                              </ng-container>
                                              <ng-container *ngIf="alias.value.part_index == 0">

                                                <ng-container
                                                  *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type === 'STATE_BOARD'">
                                                  TAMIL
                                                </ng-container>


                                                <ng-container
                                                  *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type !== 'STATE_BOARD'">
                                                  <select class="form-control" formControlName="subject_name">
                                                    <option value="">Choose Subject</option>
                                                    <option
                                                      *ngFor="let language1_subject of commonEnums.language1_subjects;let i=index;trackBy:formDataResolver.identify;"
                                                      [ngValue]="language1_subject">
                                                      {{language1_subject}}
                                                    </option>
                                                  </select>
                                                </ng-container>
                                              </ng-container>




                                            </ng-container>
                                            <ng-container *ngIf="alias.value.subject_type != 'LANGUAGE'">
                                              {{alias.value.subject_name}}
                                            </ng-container>
                                          </ng-container>
                                          <ng-container
                                            *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                            <input type="text" class="form-control" formControlName="subject_name">
                                          </ng-container>
                                        </div>
                                        <div class="form-group">
                                          <label>Mark / Grade</label>
                                          <input type="number" class="form-control" formControlName="mark">
                                        </div>

                                      </td>
                                    </ng-container>
                                  </tr>





                                </ng-container>
                              </ng-container>




                            </ng-container>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>

                </ng-container>



              </div> -->

            </div>
          </div>

          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="mb-4 main-content-label">Academic Details : HSC </div>
                <ng-container formGroupName="hsc_academics">

                  <div class="row mg-t-30">

                    <ng-container formGroupName="accademic_info">
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">Last Studied School Name<small style="color: red;">
                              *</small></label>
                          <input type="text" name="school_name" class="form-control" formControlName="school_name">

                        </div>

                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">Place of School<small style="color: red;">
                              *</small></label>
                          <input type="text" name="school_place" class="form-control" formControlName="school_place">

                        </div>

                      </div>
                      <div class="col-md-6 col-md-6">

                        <div class="form-group">
                          <label for="ex_service_rank">Examination Registration Number<small style="color: red;">
                              *</small></label>
                          <input type="text" id="exam_reg_no" name="exam_reg_no" class="form-control"
                            formControlName="exam_reg_no">
                          <small id="mobile_number_3_help_line" class="text-muted d-block">
                            Enter as per hall ticket
                          </small>
                        </div>


                      </div>

                      <div class="col-md-6 col-lg-6">

                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-6 form-group">
                              <label for="month_of_passout">Month of Passing <small style="color: red;"> *</small>
                              </label>

                              <select class="form-control custom-select" formControlName="month_of_passout">
                                <option *ngFor="let month of commonEnums.months" [ngValue]="month.id">
                                  {{month.text}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-6 form-group">
                              <label for="year_of_passout">Year of Passing <small style="color: red;"> *</small>
                              </label>
                              <select class="form-control custom-select" formControlName="year_of_passout">
                                <option *ngFor="let year of commonEnums.getLastNYears(10)" [ngValue]="year">
                                  {{year}}
                                </option>
                              </select>
                            </div>

                          </div>

                        </div>

                      </div>


                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="syllabus_type">Board <small style="color: red;"> *</small>
                          </label>
                          <select class="form-control " id="syllabus_type" name="syllabus_type"
                            formControlName="syllabus_type" (change)="onBoardChange()">
                            <option
                              *ngFor="let syllabus_type of commonEnums.syllabus_types;let i=index;trackBy:formDataResolver.identify;"
                              [ngValue]="syllabus_type">
                              {{syllabus_type}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group mg-t-5">
                          <label for="medium_of_study">Medium of instruction<small style="color: red;">
                              *</small></label>
                          <div class="row">
                            <div class="col-md-12 col-lg-12">
                              <div class="custom-control custom-radio" style="display: inline;">
                                <input id="hsc_tamil" type="radio" class="custom-control-input" value="tamil"
                                  formControlName="medium_of_study">
                                <label class="custom-control-label" for="hsc_tamil">Tamil</label>
                              </div>

                              <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                                <input id="hsc_english" type="radio" class="custom-control-input" value="english"
                                  formControlName="medium_of_study">
                                <label class="custom-control-label" for="hsc_english">English</label>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                      <ng-container
                        *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                        <div class="col-md-12 col-lg-12">
                          <div class="form-group mg-t-5">
                            <div class="custom-control custom-checkbox">
                              <input id="vocational" type="checkbox" class="custom-control-input"
                                formControlName="vocational" (change)="onBoardChange()">
                              <label class="custom-control-label" for="vocational">Have you studied under Vocational
                                Group (For commerce students only) ?</label>
                            </div>


                          </div>
                        </div>
                      </ng-container>
                      <div class="col-md-12 col-lg-12">
                        <div class="form-group mg-t-5">
                          <div class="custom-control custom-checkbox">
                            <input id="first_graduate" type="checkbox" class="custom-control-input"
                              formControlName="first_graduate">
                            <label class="custom-control-label" for="first_graduate">Are you the First Graduate in your
                              family?</label>
                          </div>


                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12">
                        <div class="form-group mg-t-5">
                          <div class="custom-control custom-checkbox">
                            <input id="mark_verified" type="checkbox" class="custom-control-input"
                              formControlName="mark_verified">
                            <label class="custom-control-label" for="mark_verified">+12 Mark Verified?</label>
                          </div>


                        </div>
                      </div>
                      <!-- <div class="col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="blood_group">Upload HSC Mark Certificate<small style="color: red;"> *</small></label>
                                                    <input class="form-control" required="" type="file" (change)="uploadImage($event,user_form.controls.hsc_academics.controls.accademic_info, 'mark_statement_file')" accept="image/*">
                                                    <ng-container *ngIf='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file'>
                                                        <label for="blood_group">Preview</label>
                                                        <img style="width: 400px;height: 200px;" [src]='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file' />
                                                    </ng-container>
                                                </div>
                                            </div> -->

                    </ng-container>



                    <div class="col-md-12 col-lg-12">
                      <label for="ex_service_rank" class="mb-4 main-content-label">Mark Details<small
                          style="color: red;">
                          *</small></label>
                      <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th width="60">S.No.</th>
                              <ng-container *ngIf="!locationHelper.isMobile()">

                                <th width="150">Subject Type</th>
                                <!-- <th width="150">Part Type</th> -->
                                <th>Subject Name</th>
                                <!-- <th width="150">+1 Mark / Grade</th> -->
                                <th width="150">+2 Mark / Grade</th>
                              </ng-container>
                              <ng-container *ngIf="locationHelper.isMobile()">
                                <th></th>
                              </ng-container>
                            </tr>
                          </thead>
                          <tbody>


                            <ng-container formArrayName="marks">
                              <ng-container
                                *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.hsc_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                <ng-container [formGroup]='alias'>
                                  <tr>

                                    <td>
                                      {{i+1}}
                                    </td>
                                    <ng-container *ngIf="!locationHelper.isMobile()">
                                      <td>
                                        <ng-container
                                          *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                          {{alias.value.subject_type}}
                                          {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                        </ng-container>
                                        <ng-container
                                          *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                          <select class="form-control " formControlName="subject_type">
                                            <option value="">Choose</option>
                                            <option
                                              *ngFor="let cbsc_subject_type of commonEnums.cbsc_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                              [ngValue]="cbsc_subject_type">
                                              {{cbsc_subject_type}}
                                            </option>
                                          </select>
                                        </ng-container>

                                      </td>
                                      <td>
                                        <ng-container
                                          *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                          <select class="form-control " formControlName="subject_id">
                                            <option value="">Choose</option>
                                            <option
                                              *ngFor="let subject of getSchoolSubjectsByPartType(alias.value.part_type);let i=index;trackBy:formDataResolver.identify;"
                                              [ngValue]="subject.subject_id">
                                              {{subject.subject_name}}
                                            </option>
                                          </select>
                                        </ng-container>
                                        <ng-container
                                          *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                          <input class="form-control" required="" type="text"
                                            formControlName="subject_name">
                                        </ng-container>
                                      </td>


                                      <!-- <td>
                                        <input type="text" class="form-control" formControlName="mark_1">
                                      </td> -->
                                      <td>
                                        <!-- N/A -->
                                        <input type="text" class="form-control" formControlName="mark">
                                      </td>

                                    </ng-container>

                                    <ng-container *ngIf="locationHelper.isMobile()">
                                      <td>
                                        <div class="form-group">
                                          <label>Subject Type</label>
                                          <ng-container
                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                            {{alias.value.subject_type}}
                                            {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                          </ng-container>
                                          <ng-container
                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                            <select class="form-control " formControlName="subject_type">
                                              <option value="">Choose</option>
                                              <option
                                                *ngFor="let cbsc_subject_type of commonEnums.cbsc_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                [ngValue]="cbsc_subject_type">
                                                {{cbsc_subject_type}}
                                              </option>
                                            </select>
                                          </ng-container>
                                        </div>

                                        <div class="form-group">
                                          <label>Subject Name</label>
                                          <ng-container
                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                            <select class="form-control " formControlName="subject_id">
                                              <option value="">Choose</option>
                                              <option
                                                *ngFor="let subject of getSchoolSubjectsByPartType(alias.value.part_type);let i=index;trackBy:formDataResolver.identify;"
                                                [ngValue]="subject.subject_id">
                                                {{subject.subject_name}}
                                              </option>
                                            </select>
                                          </ng-container>
                                          <ng-container
                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                            <input class="form-control" required="" type="text"
                                              formControlName="subject_name">
                                          </ng-container>
                                        </div>

                                        <!-- <div class="form-group">
                                          <label>+1 Mark / Grade</label>

                                          <input type="text" class="form-control" formControlName="mark_1">

                                        </div> -->

                                        <div class="form-group">
                                          <label>+2 Mark / Grade</label>

                                          <input type="text" class="form-control" formControlName="mark">

                                        </div>



                                      </td>





                                    </ng-container>

                                  </tr>





                                </ng-container>
                              </ng-container>




                            </ng-container>
                          </tbody>
                        </table>
                      </div>

                    </div>



                  </div>

                </ng-container>



              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="user_form.value.apply_for === 'PG'">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="mb-4 main-content-label">Academic Details : Degree </div>
                <ng-container formGroupName="degree_academics">

                  <div class="row mg-t-30">

                    <ng-container formGroupName="accademic_info">
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">Name of the college/university last studied<small
                              style="color: red;">
                              *</small></label>

                          <div class="row">

                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="true" type="radio" class="custom-control-input" value="false" name="is_anjac"
                                formControlName="is_anjac">
                              <label class="custom-control-label" for="true">Others</label>
                            </div>
                            <ng-container *ngIf='user_form.value.degree_academics.accademic_info.is_anjac=="false"'>
                              <input type="text" name="school_name" class="form-control mg-l-40"
                                formControlName="school_name" placeholder="Name of the college/university last studied">
                            </ng-container>


                            <div class="custom-control custom-radio" style="display: inline; margin-left: 20px;">
                              <input id="false" type="radio" class="custom-control-input" value="true" name="is_anjac"
                                formControlName="is_anjac">
                              <label class="custom-control-label" for="false">Ayya Nadar Janaki Ammal College</label>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">Place<small style="color: red;">
                              *</small></label>
                          <input type="text" name="school_place" class="form-control" formControlName="school_place">

                        </div>

                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="ex_service_rank">Course Name<small style="color: red;">
                              *</small></label>
                          <input type="text" name="course_name" class="form-control" formControlName="course_name">

                        </div>

                      </div>
                      <div class="col-md-6 col-md-6">

                        <div class="form-group">
                          <label for="ex_service_rank">College/University Registration Number *<small
                              style="color: red;">
                              *</small></label>
                          <input type="text" id="exam_reg_no" name="exam_reg_no" class="form-control"
                            formControlName="exam_reg_no">

                        </div>


                      </div>

                      <div class="col-md-6 col-lg-6">

                        <div class="form-group">
                          <div class="row">
                            <div class="col-md-6 form-group">
                              <label for="month_of_passout">Month of Passing <small style="color: red;"> *</small>
                              </label>

                              <select class="form-control custom-select" formControlName="month_of_passout">
                                <option *ngFor="let month of commonEnums.months" [ngValue]="month.id">
                                  {{month.text}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-6 form-group">
                              <label for="year_of_passout">Year of Passing <small style="color: red;"> *</small>
                              </label>
                              <select class="form-control custom-select" formControlName="year_of_passout">
                                <option *ngFor="let year of commonEnums.getLastNYears(10)" [ngValue]="year">
                                  {{year}}
                                </option>
                              </select>
                            </div>

                          </div>

                        </div>

                      </div>




                      <div class="col-md-12 col-lg-12">
                        <div class="form-group mg-t-5">
                          <div class="custom-control custom-checkbox">
                            <input id="first_graduate" type="checkbox" class="custom-control-input"
                              formControlName="first_graduate">
                            <label class="custom-control-label" for="first_graduate">Are you the First Graduate in your
                              family?</label>
                          </div>


                        </div>
                      </div>

                    </ng-container>



                    <div class="col-md-12 col-lg-12">
                      <label for="ex_service_rank" class="mb-4 main-content-label">Mark Details<small
                          style="color: red;">
                          *</small></label>
                      <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th width="5%">S.No.</th>
                              <ng-container *ngIf="!locationHelper.isMobile()">
                                <th width="20%">Semester</th>
                                <th width="10%">No. of Subjects (Only Part III)</th>
                                <th width="10%">Total Marks</th>
                                <th width="10%">Marks Obtained</th>
                                <th width="30%">Upload Certificate</th>
                              </ng-container>
                              <ng-container *ngIf="locationHelper.isMobile()">
                                <th></th>
                              </ng-container>
                            </tr>
                          </thead>
                          <tbody>


                            <ng-container formArrayName="marks">
                              <tr
                                *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.degree_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                <ng-container [formGroupName]="i">
                                  <td>
                                    {{i+1}}
                                  </td>

                                  <ng-container *ngIf="!locationHelper.isMobile()">
                                    <td>

                                      Semester {{ commonEnums.rommon_letters[i+1]}}


                                    </td>
                                    <td>

                                      <select class="form-control custom-select" name="no_of_subjects"
                                        formControlName="no_of_subjects">
                                        <option *ngFor="let no_of_subjects of commonEnums.getNumbers(10)"
                                          [ngValue]="no_of_subjects">
                                          {{no_of_subjects}}
                                        </option>
                                      </select>



                                    </td>
                                    <td>

                                      {{formDataResolver.getValueFromFromGroup(alias,"no_of_subjects")*100}}


                                    </td>

                                    <td>
                                      <div class="form-group pt-15">

                                        <input type="number" id="mark" name="mark" min="0" class="form-control"
                                          formControlName="mark">

                                      </div>
                                    </td>
                                    <td>
                                      <div class="col-md-6 col-lg-6">
                                        <div class="form-group">
                                          <label for="blood_group">Upload Semester {{ commonEnums.rommon_letters[i+1]}}
                                            Certificate image</label>
                                          <input class="form-control" required="" type="file"
                                            (change)="uploadImage($event,alias, 'semester_file')" accept="image/*">
                                          <ng-container *ngIf='alias.value.semester_file'>
                                            <label for="blood_group">Preview</label>
                                            <img style="width: 400px;height: 200px;"
                                              [src]='alias.value.semester_file' />
                                          </ng-container>
                                        </div>
                                      </div>
                                    </td>
                                  </ng-container>
                                  <ng-container *ngIf="locationHelper.isMobile()">
                                    <td>
                                      <div class="form-group">
                                        <label>Semester</label>
                                        {{ commonEnums.rommon_letters[i+1]}}

                                      </div>
                                      <div class="form-group">
                                        <label>No. of Subjects (Only Part III)</label>
                                        <select class="form-control custom-select" name="no_of_subjects"
                                          formControlName="no_of_subjects">
                                          <option *ngFor="let no_of_subjects of commonEnums.getNumbers(10)"
                                            [ngValue]="no_of_subjects">
                                            {{no_of_subjects}}
                                          </option>
                                        </select>

                                      </div>

                                      <div class="form-group">
                                        <label>Total Mark</label>
                                        {{formDataResolver.getValueFromFromGroup(alias,"no_of_subjects")*100}}

                                      </div>
                                      <div class="form-group">
                                        <label>Mark Obtained</label>
                                        <input type="number" id="mark" name="mark" min="0" class="form-control"
                                          formControlName="mark">

                                      </div>
                                      <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                          <label for="blood_group">Upload Semester {{ commonEnums.rommon_letters[i+1]}}
                                            Certificate image</label>
                                          <input class="form-control" required="" type="file"
                                            (change)="uploadImage($event,alias, 'semester_file')" accept="image/*">
                                          <ng-container *ngIf='alias.value.semester_file'>
                                            <label for="blood_group">Preview</label>
                                            <img style="width: 400px;height: 200px;"
                                              [src]='alias.value.semester_file' />
                                          </ng-container>
                                        </div>
                                      </div>



                                    </td>

                                  </ng-container>
                                </ng-container>
                              </tr>
                            </ng-container>

                          </tbody>
                        </table>
                      </div>

                    </div>



                  </div>

                </ng-container>



              </div>

            </div>
          </div>
        </ng-container>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-footer text-center">
              <button type="submit" class="btn btn-success waves-effect waves-light"
                (click)="onProfileSaveClick()">Save</button>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- /Col -->
    </div>
    <div class="row row-sm" *ngIf="!user_info">
      <div class="col">


        <div class="alert alert-info" role="alert">
          <button aria-label="Close" class="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
          Loading Please wait...
        </div>
      </div>
    </div>
    <!-- row closed -->


  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>