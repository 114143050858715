<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Elective Course Request List</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <!-- <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Course</button>
                </div> -->
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Elective Course Request List</h4>
              <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
            </div>
            <p class="tx-12 tx-gray-500 mb-2">View and approve course request list from students</p>
          </div>

          <div class="card-body" *ngIf="programme_search_form">
            <form [formGroup]="programme_search_form">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                    <select formControlName="programme_type_id" class="form-control"
                      (change)="onFilterSelectChange('programme_type_id',$event)">
                      <option value="">Select Programme Type</option>
                      <option *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="category.programme_type_id">{{category.name}}</option>
                    </select>

                  </div>

                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme</label>
                    <select formControlName="programme_id" class="form-control"
                      (change)="onFilterSelectChange('programme_id',$event)">
                      <option value="">All Programmes</option>
                      <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programme.programme_id">{{programme.programme_name}}
                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select enrolled year</label>
                    <select formControlName="enrolled_year" class="form-control">
                      <option value="">Select enrolled year</option>
                      <option
                        *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="year">{{year}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select semester</label>
                    <select formControlName="semester" class="form-control">
                      <option value="">Select semester</option>
                      <option
                        *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="semester.id">{{semester.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!requested_course_list_loading" (click)="onSearchClick()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="requested_course_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Roll No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="requested_course_list.length>0">


                    <ng-container
                      *ngFor="let requested_course of requested_course_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}
                        </td>
                        <td class="text-uppercase">{{requested_course.roll_no}}</td>
                        <td class="text-uppercase">{{requested_course.name}}</td>

                      </tr>
                      <tr>
                        <td colspan="3">
                          <ng-container *ngIf="requested_course.request_list && requested_course.request_list.length>0">

                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Part</th>
                                  <th>Course Type</th>
                                  <th>Requested Courses</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let request_item of requested_course.request_list;let i=index;trackBy:fromDataResolver.identify;">
                                  <tr>
                                    <td>{{i+1}}</td>
                                    <td>{{request_item.part}}</td>
                                    <td>{{request_item.course_type}}</td>
                                    <td>
                                      <ng-container
                                        *ngFor="let requested_course_code of fromDataResolver.getFormArrayControlls(request_item.request_form,'requested_course_codes');let i=index;trackBy:fromDataResolver.identify;">
                                        <ng-container [formGroup]="requested_course_code">
                                          <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                              id="{{requested_course_code.value.course_code}}_od_course_request_{{i}}"
                                              name="{{requested_course_code.value.course_code}}_od_course_request_{{i}}"
                                              formControlName="approved"
                                              (change)="onCourseChangeClick(request_item.request_form,requested_course_code)">
                                            <label class="custom-control-label"
                                              for="{{requested_course_code.value.course_code}}_od_course_request_{{i}}">
                                              {{requested_course_code.value.course_code}}
                                              ({{requested_course_code.value.priority}})
                                            </label><br>
                                          </div>
                                        </ng-container>
                                      </ng-container>

                                    </td>
                                    <td>
                                      <div class="pr-1 mb-3 mb-xl-0">
                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                          (click)="onApproveClick(request_item)">
                                          <fa-icon [icon]="fonts.faCheck"></fa-icon> Approve

                                        </button>

                                        <button type="button" class="btn btn-sm btn-danger mr-2"
                                          (click)="reAllowClick(request_item)">
                                          <fa-icon [icon]="fonts.faAdjust"></fa-icon> Re-Allow

                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>

                          </ng-container>
                          <ng-container
                            *ngIf="!requested_course.request_list || requested_course.request_list.length==0">
                            No Request
                          </ng-container>
                        </td>
                      </tr>

                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!requested_course_list_loading && requested_course_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="requested_course_list_loading && requested_course_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>


        </div>
      </div>


    </div>

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
