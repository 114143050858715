import { NavigationService } from './../../../../../../components/navigation/navigation.service';
import { Room } from './../../../room-model';
import { Hostel, HostelBody } from './../../../hostelModel';
import { NotificationService } from './../../../../../../components/notification/notification.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-floors',
  templateUrl: './create-floors.component.html',
  styleUrls: ['./create-floors.component.scss']
})
export class CreateFloorsComponent implements OnInit {
  hostel: Hostel = {} as Hostel;
  floorForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private restService: AdminservicesService,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.floorForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  ngOnInit(): void {
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.route.params.subscribe(params => {
      this.navigationService.updateNavigation('Create Floor', this.router.url);
      this.restService.hostelManagementService.getHostels(params?.hostelId).subscribe((hostels: Hostel[]) => {
        if (hostels.length) {
          this.hostel = hostels[0];
        }
      }, this.errorHandler);
    });
  }

  canSave(): boolean {
    if (!this.getFormData().name) {
      return true;
    }
    return false;
  }

  getHostelFromForm(): string {
    return this.getFormData().name;
  }

  onSave(): void {
    const hostleBody: HostelBody = {
      data: {
        floors: [
          {
            name: this.getHostelFromForm(),
            hostel_id: this.hostel.id ? this.hostel.id : '',
            rooms: [],
            incharges: []
          }
        ]
      }
    };
    this.restService.hostelManagementService.updateHostel(this.hostel.id, hostleBody).subscribe(data => {
      console.log(data);

      if (data) {
        this.notificationService.setNotifcation({
          message: 'Saved Floor successfully.',
          type: 'Success'
        });
        this.onClose();
      }
    });
  }

  getFormData(): any {
    return this.floorForm.value;
  }

  buildForm(): void {

  }
}
