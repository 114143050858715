import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-staff-creation',
  templateUrl: './staff-creation.component.html',
  styleUrls: ['./staff-creation.component.scss']
})
export class StaffCreationComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;

  title: string = "User Account Creation";
  // master data
  user_search_form: any;

  // core data

  staff_list: IUserCreation[] = [];
  staff_list_loading = false;
  staff: IUserCreation = {} as IUserCreation;

  staff_form: FormGroup = undefined;

  roles: RoleInterface[] = [];
  role_list_loading: boolean = false;

  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;
  user_types: string[] = ["STAFF", "NTS", "GUEST"];

  save_loading: boolean = false;
  delete_loading: boolean = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.buildUserSearchForm();
    this.getRoles();
  }

  /// master data
  async getRoles() {
    try {
      this.role_list_loading = true;
      const response = await this.restService.getRolesList();
      if (response && response.success) {
        this.roles = response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.role_list_loading = false;
    }

  }

  buildUserSearchForm(): void {
    this.user_search_form = this.fb.group({
      user_type: [''],
      roll_no: [''],
    });
  }


  getName(name: string) {
    return name.replace('undefined', '');
  }


  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }

  async onSearchClick(): Promise<void> {
    try {
      this.staff_list_loading = true;
      this.staff_list = [];
      var search_query: any = {};
      search_query = {
        skip: this.start_index,
        limit: this.take_index
      };
      if (this.user_search_form.value.user_type) {
        search_query.user_type = this.user_search_form.value.user_type;
      }
      if (this.user_search_form.value.roll_no) {
        search_query.roll_no = this.user_search_form.value.roll_no;
      }

      const service_response = await this.restService.getStaffList(search_query);
      if (service_response && service_response.success) {
        this.staff_list = service_response.data;
        console.log(this.staff_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.staff_list_loading = false;
    }
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async editClick(staff: IUserCreation) {
    this.staff = staff;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    /* try {
      const service_response = await this.restService.getUserRoles({ email: this.staff.email });
      if (service_response && service_response.success) {
        var roles: any[] = service_response.data.roles;
        this.staff.roles = roles;
        this.staff.group_id = service_response.data.group_id;
        this.staff.sub = service_response.data.sub;
        this.buildForm();
        JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.reset();
    }
    finally {

    } */
  }

  buildForm(): void {
    if (JSON.stringify(this.staff) == '{}') {
      this.staff = {
        _id: "",

        roll_no: "",
        given_name: "",
        middle_name: "",
        family_name: "",
        personal_email: "",
        email: "",
        mobile_number: 0,
        birth_date: "",
        user_type: "",
        roles: [],
        sub: "",
        group_id: "",
        is_active: false,

        //new fields
        date_of_joining: "",
        date_of_resignation: ""

      };
    }
    this.staff_form = this.fb.group({
      _id: [this.staff._id],
      roll_no: [this.staff.roll_no, [Validators.required]],
      given_name: [this.staff.given_name, [Validators.required]],
      middle_name: [this.staff.middle_name],
      family_name: [this.staff.family_name, [Validators.required]],
      personal_email: [this.staff.personal_email, [Validators.required]],
      email: [this.staff.email, [Validators.required]],
      mobile_number: [this.staff.mobile_number, [Validators.required]],
      birth_date: [this.staff.birth_date ? (DateHelper.convertToControlDate(new Date(this.staff.birth_date))) : new Date(), [Validators.required]],
      user_type: [this.staff.user_type, [Validators.required]],
      roles: [],
      sub: [this.staff.sub],
      //group_id: [this.staff.group_id],
      is_active: [this.staff.is_active],

      date_of_joining: [this.staff.date_of_joining ? (DateHelper.convertToControlDate(new Date(this.staff.date_of_joining))) : new Date()],
      date_of_resignation: [this.staff.date_of_resignation ? (DateHelper.convertToControlDate(new Date(this.staff.date_of_resignation))) : new Date()],
    });

    if (this.staff._id) {
      var obj_list: RoleInterface[] = [];
      this.staff_list.find(x => x._id == this.staff._id)?.roles?.forEach(role => {
        if (role) {
          obj_list.push(this.roles.find(x => x.role_key == role));
        }
      });
      this.staff_form.get('roles').setValue(obj_list);
    }
  }


  isInvalid(formControl: string): boolean {
    return this.staff_form.touched && this.staff_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.staff_form.status !== 'VALID';
  }

  onSaveClick(): void {
    this.addItem();
  }

  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.staff_form.value;
      console.log(form_data);
      if (form_data.roles?.length == 0) {
        alert("At least one role must be selected for the user");
        return;
      }
      var roles: string[] = [];
      var form_data_roles: RoleInterface[] = [];
      form_data_roles = form_data.roles;
      form_data_roles.forEach(role => {
        roles.push(role.role_key);
      });
      form_data.roles = roles;
      this.save_loading = true;
      console.log(form_data);
      if (this.staff_form.value._id) {
        const res = await this.restService.updateStaff(this.staff_form.value._id, form_data);
        if (res) {
          alert("Staff data updated successfully.");
        }
      }
      else {
        const res = await this.restService.createStaff(form_data);
        if (res) {
          alert("Staff data created successfully.");
        }
      }
      this.onSearchClick();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  /*   async onDelete() {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      try {
        this.delete_loading = true;
        const res = await this.restService.deleteSkillset(this.staff_form.get('_id')!.value);
        if (res.success) {
          alert("Staff removed successfully");
        }
      }
      catch (error) {
        alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
      }
      finally {
        this.delete_loading = false;
      }
      this.onCloseClick();
    } */

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.staff = {} as IUserCreation;
    this.staff_form.reset();
    this.staff_form = undefined;
  }


}


interface IUserCreation {
  //db generated
  _id?: string,


  //user inputs
  roll_no: string;
  given_name: string,
  middle_name?: string
  family_name: string,
  personal_email: string;
  mobile_number: number,
  birth_date: string,
  user_type: string,
  roles: string[],
  sub?: string;
  group_id?: string,
  is_active: boolean,

  //new fields
  date_of_joining?: string,
  date_of_resignation?: string,

  email?: string
  password?: string, //to be generated
}


interface RoleInterface {
  id?: string,
  _id?: string,
  role_key: string,
  role_description?: string,
  role_name?: string,
  is_allowed?: boolean,
}

interface UserRoleInterface {
  email: string,
  group_id: string,
  name: string,
  roles: string[]
} 