import { ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-manage-repeat-semester',
  templateUrl: './manage-repeat-semester.component.html',
  styleUrls: ['./manage-repeat-semester.component.scss']
})
export class ManageRepeatSemesterComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_section_list: any[] = [];

  programme_search_form: any;

  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];

  students_list: IRepeatSemester[] = [];
  student_list_loading = false;
  student: IRepeatSemester = {} as IRepeatSemester;
  modal_type_update: boolean = false;
  student_form: FormGroup = undefined;
  student_programme_list: any[] = [];
  student_programme_list_loading = false;
  student_programme_section_list: any[] = [];

  save_loading: boolean = false; delete_loading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService,
    private appRef: ApplicationRef, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildFilterForm();

    // Load core data
    this.getProgrammeDataList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      to_section_id: [''],
      to_enrolled_year: [''],
      repeating_semester: ['']
    });
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    } else if (id === 'programme_id') {
      this.programme_section_list = [];
      this.programme_search_form.get('to_section_id').setValue("");
      const programme = this.programme_list.find(c => c.programme_id === this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.programme_section_list = programme.section_details;
      }
      if (programme.section_details.length == 1) {
        this.programme_search_form.get('to_section_id').setValue(programme.section_details[0]._id);
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      if (this.programme_search_form.value.programme_type_id && this.programme_search_form.value.finance_type) {

        this.programme_list_loading = true;
        this.programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.programme_search_form) {
          if (this.programme_search_form.value.programme_type_id) {
            search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
          }
          if (this.programme_search_form.value.finance_type) {
            search_form_query.finance_type = this.programme_search_form.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.programme_list = service_response.data;
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async searchFormDataList(): Promise<void> {
    try {
      if (!this.programme_search_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_search_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_search_form.value.to_enrolled_year) {
        alert('Please select Enrolled Year');
        return;
      }

      this.student_list_loading = true;
      this.students_list = [];

      const service_response = await this.restService.getRepeatSemesterStudent(this.programme_search_form.value);
      if (service_response && service_response.success) {
        this.students_list = service_response.data;
      }
      console.log(this.students_list);
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.student_list_loading = false;
    }
  }

  onAddClick() {
    this.buildForm();
    this.modal_type_update = false;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(student: any): Promise<void> {
    this.student = student;
    this.modal_type_update = true;
    this.buildForm();
    await this.getStudentProgrammeDataList();
    this.onFilterStudentSelectChange('programme_id', '');
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  buildForm(): void {
    if (JSON.stringify(this.student) == '{}') {
      this.student = {
        _id: "",
        programme_id: "",
        programme_type_id: "",
        finance_type: "",
        to_enrolled_year: 0,
        repeating_semester: "",
        to_section_id: "",
        roll_no: "",
        is_repeat: true
      };
    }
    this.student_form = this.fb.group({
      _id: [this.student._id],
      programme_id: [this.student.programme_id, [Validators.required]],
      programme_type_id: [this.student.programme_type_id, [Validators.required]],
      finance_type: [this.student.finance_type, [Validators.required]],
      to_enrolled_year: [this.student.to_enrolled_year, [Validators.required]],
      repeating_semester: [this.student.repeating_semester, [Validators.required]],
      to_section_id: [this.student.to_section_id, [Validators.required]],
      roll_no: [this.student.roll_no, [Validators.required]],
    });
  }

  async onFilterStudentSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getStudentProgrammeDataList();
    } else if (id === 'programme_id') {
      this.student_programme_section_list = [];
      this.student_form.get('to_section_id').setValue("");
      const programme = this.student_programme_list.find(c => c.programme_id === this.student_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.student_programme_section_list = programme.section_details;
      }
      if (programme.section_details.length == 1) {
        this.student_form.get('to_section_id').setValue(programme.section_details[0]._id);
      }
    }
  }

  async getStudentProgrammeDataList(): Promise<void> {
    try {
      if (this.student_form.value.programme_type_id && this.student_form.value.finance_type) {

        this.student_programme_list_loading = true;
        this.student_programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.student_form) {
          if (this.student_form.value.programme_type_id) {
            search_form_query.programme_type_id = this.student_form.value.programme_type_id;
          }
          if (this.student_form.value.finance_type) {
            search_form_query.finance_type = this.student_form.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.student_programme_list = service_response.data;
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.student_programme_list_loading = false;
    }
  }

  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.student_programme_list = []; this.student_programme_section_list = [];
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.student = {} as IRepeatSemester;
    this.student_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.student_form.value;
      console.log(form_data);
      this.save_loading = true;
      const res = await this.restService.addUpdateRepeatSemesterStudent(form_data);
      if(res && res.success) {
        alert("Repeat semester student added successfully");
      }
      this.searchFormDataList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteRepeatSemesterStudent(this.student_form.get('_id')!.value);
      if (res.success) {
        alert("Repeat Semester deleted successfully.");
        this.searchFormDataList();
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }

  isInvalid(formControl: string): boolean {
    return this.student_form.touched && this.student_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.student_form.status !== 'VALID';
  }
}

interface IRepeatSemester {
  programme_type_id?: string,
  programme_id?: string,
  finance_type?: string,
  _id: string,
  to_enrolled_year: number,
  repeating_semester: string,
  to_section_id: string,
  roll_no: string,
  is_repeat: boolean,
  student_data?: IRepeatStudent
}

interface IRepeatStudent {
  given_name: string,
  middle_name: string,
  roll_no: string,
  enrolled_year: number,
  sub: string
}
