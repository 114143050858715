import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-constructive-alignment',
  templateUrl: './constructive-alignment.component.html',
  styleUrls: ['./constructive-alignment.component.scss']
})
export class ConstructiveAlignmentComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  dropdownSettings: IDropdownSettings = {};

  title = 'Constructive Alignment';

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_type_id: string = ""; //ngModel



  programme_list: any[] = [];
  programme_list_loading: boolean = false;

  component_search_form: FormGroup = undefined;
  // pagination
  start_index = 0;
  total_records = 0;
  take_index = 500;
  course_list: any[] = [];
  course_list_loading = false;

  save_loading: boolean = false;

  cookieStore = CookieStore;
  course: any = {} as any;
  component: any = {} as any;

  exam_modes: {
    key: string, text: string
  }[] = [{
    key: "ONLINE", text: "Online Exam"
  },
  {
    key: "OFFLINE", text: "Offline Exam"
  }
    ];

  co_ids: { id: number, text: string }[] =
    [
      { id: 1, text: "CO1" }, { id: 2, text: "CO2" }, { id: 3, text: "CO3" }, { id: 4, text: "CO4" }, { id: 5, text: "CO5" }
    ];

  k_ids: { id: number, text: string }[] =
    [
      { id: 1, text: "K1" }, { id: 2, text: "K2" }, { id: 3, text: "K3" }, { id: 4, text: "K4" }, { id: 5, text: "K5" }, { id: 6, text: "K6" }
    ];


  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.getProgrammeDataList();
  }
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildComponentSearchForm();
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  buildComponentSearchForm(): void {
    this.component_search_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: [''],
      roll_no: [''],
      course_code: [''],
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }


  async onSearchClick(): Promise<void> {
    try {
      this.course_list_loading = true;
      this.course_list = [];
      const search_query = {
        programme_type_id: this.component_search_form.value.programme_type_id,
        enrolled_year: this.component_search_form.value.enrolled_year,
        semester: this.component_search_form.value.semester,
        roll_no: this.component_search_form.value.roll_no.toUpperCase(),
        course_code: this.component_search_form.value.course_code,
        skip: this.start_index,
        take: this.take_index
      };
      const service_response = await this.restService.searchConstructiveAlignment(search_query);
      if (service_response && service_response.success) {
        this.course_list = service_response.data.data;
        this.total_records = service_response.data.total;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.course_list_loading = false;
    }
  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }

  async onViewComponentsClick(course: any): Promise<void> {
    console.log(course);
    if (course.show_co) {
      course.show_co = false;
    } else {
      for (const courseobj of this.course_list) {
        courseobj.show_co = false;
      }
      //component submission details
      this.getCoursesByStaff(course);
      course.show_co = true;
    }
  }

  async getCoursesByStaff(course: any): Promise<void> {
    try {
      if (!course.co_settings || course.co_settings.length == 0) {
        alert("OBE component not configured!, Please contact OBE incharge");
        return;
      }
      course.loading = true;
      course.component_list = JSON.parse(JSON.stringify(course.co_settings));
      console.log(course.component_list);
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      course.loading = false;
    }
  }

  getProgrammeName(programme_id: string) {
    return this.programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }



  async onSaveClick(course, component): Promise<void> {
    try {

      this.save_loading = true;
      console.log(course);
      if (course.constructive_alignment_id) {
        const res = await this.restService.updateConstructiveAlignment(course.constructive_alignment_id, course);
        if (res) {
          alert("Constructive alignment updated successfully.");
        }
      }
      else {
        const res = await this.restService.createConstructiveAlignment(course);
        if (res) {
          alert("Constructive alignment added successfully.");
        }
      }
      this.onSearchClick();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }


  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }


}

