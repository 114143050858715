<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Attendance</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Attendance</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Manage Attendance</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Department Categories</p> -->
                    </div>
                    <div class="card-body" *ngIf="attendance_search_form">
                        <form [formGroup]="attendance_search_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Date</label>
                                        <input type="date" class="form-control" formControlName="attendance_date">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Hour</label>
                                        <select formControlName="attendance_hour" class="form-control">
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>


                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course Code</label>
                                        <input type="text" class="form-control" formControlName="subject_code">
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search" *ngIf="!attendance_list_loading" (click)="getSavedAttendance()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled *ngIf="attendance_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                        <button type="button" class="mg-t-30 btn mr-2 search-button" title="Search" *ngIf="!attendance_list_loading" (click)="onClearSearch()">
                                            Clear Search
                                        </button>




                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Prg. Name</th>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Mobile</th>
                                        <th>Absent?</th>
                                        <th>Absent Reason</th>
                                        <th>Attendance Updated By</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="attendance_list.length>0">


                                        <ng-container *ngFor="let attendance of attendance_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="text-uppercase">{{attendance.programme_name}} {{attendance.finance_type=="regular"?"(R)":"(SF)"}}</td>
                                                <td class="text-uppercase">{{attendance.given_name}} {{attendance.middle_name}}</td>
                                                <td class="text-uppercase">{{attendance.gender}}</td>
                                                <td>{{attendance.mobile_number}}</td>
                                                <td class="text-uppercase">{{attendance.is_absent ? 'YES':'NO'}}</td>
                                                <td class="text-uppercase">{{attendance.attendance_type}}</td>
                                                <td class="text-uppercase">{{attendance.by_given_name}} {{attendance.by_middle_name}}</td>
                                                <td>

                                                </td>


                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!attendance_list_loading && attendance_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="attendance_list_loading && attendance_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="attendance_save_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Fill Attendance</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="attendance_save_form">


                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Date</label>
                                    <input type="date" class="form-control" formControlName="attendance_date">
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Hour</label>
                                    <select formControlName="attendance_hour" class="form-control">
                                        <option value="">Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>


                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course Code</label>
                                    <input type="text" class="form-control" formControlName="subject_code">
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll Nos</label>
                                    <textarea type="text" class="form-control" formControlName="pre_roll_nos"></textarea>
                                    <small>Enter multiple roll no by comma separated value (ex: 09PA37,09PA38)</small>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveClick()" *ngIf="!attendance_save_form_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="attendance_save_form_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>

                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>