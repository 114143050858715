import { Parser } from 'json2csv';
export class CSVHelper {
    static downloadCSV(data: any, filename: string): void {
        const parser = new Parser({});
        const csv_data = parser.parse(data);
        let csvFile;
        let downloadLink;

        // CSV file
        csvFile = new Blob([csv_data], { type: 'text/csv' });

        // Download link
        downloadLink = document.createElement('a');

        // File name
        downloadLink.download = filename;

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = 'none';

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }
}
