import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';

@Component({
  selector: 'app-day-order-change',
  templateUrl: './day-order-change.component.html',
  styleUrls: ['./day-order-change.component.scss']
})
export class DayOrderChangeComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Day Order Change';
  day_order_change_list_loading: boolean = false;
  day_order_change_list: RouteSettingInterface[] = [];
  changed_day: RouteSettingInterface = {} as RouteSettingInterface;
  change_form: FormGroup = {} as FormGroup;
  day_id: string = "";
  save_loading: boolean = false;
  delete_loading: boolean = false;

  ngOnInit(): void {
    this.buildForm();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.getDayOrderChangeList();
  }

  async getDayOrderChangeList() {
    this.day_order_change_list = [];
    this.day_order_change_list_loading = true;
    try {
      const service_response = await this.restService.getDayOrderChangeList();
      if (service_response && service_response.success) {
        this.day_order_change_list = service_response.data;
        console.log(this.day_order_change_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.day_order_change_list_loading = false;
  }

  buildForm(): void {
    if (JSON.stringify(this.changed_day) == '{}') {
      this.changed_day = {
        _id: "",
        date: "",
        day: "",
        day_to: "",
      };
    }
    this.change_form = this.formBuilder.group({
      _id: [this.changed_day._id],
      date: [this.changed_day.date, [Validators.required]],
      day: [this.changed_day.day],
      day_to: [this.changed_day.day_to, [Validators.required]],
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(day: any) {
    this.day_id = day._id; //term_name
    var settings = this.day_order_change_list.find(x => x._id == day._id);
    if (settings) {
      console.log(settings);
      this.changed_day = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.day_id = "";
    this.changed_day = {} as RouteSettingInterface;
    this.change_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.change_form.value;
      form_data.date = new Date(this.change_form.value.date).toISOString();
      var day_index = new Date(this.change_form.value.date).getDay();
      if (day_index == this.commonEnums.days.find(x => x.text == this.change_form.value.day_to)?.id) {
        alert("Selected Date's day and To Day are same. Please choose another day.");
        return;
      }
      form_data.day = this.commonEnums.days.find(x => x.id == day_index)?.text;
      console.log(form_data);
      this.save_loading = true;
      if (this.change_form.value._id) {
        const res = await this.restService.updateDayOrderChange(this.change_form.value._id, form_data);
        if (!res) {
          alert('Failed to Update Day Order Change settings');
        }
        alert('Updated Day Order Change Settings Successfully');
      }
      else {
        const res = await this.restService.createDayOrderChange(form_data);
        if (!res) {
          alert('Failed to Create Day Order Change settings');
        }
        alert('Created Day Order Change Settings Successfully');
      }
      this.getDayOrderChangeList();
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteDayOrderChange(this.change_form.get('_id')!.value);
      if (res.success) {
        alert('Deleted Day Order Change Settings Successfully');
        this.getDayOrderChangeList();
      }
      else {
        alert('Failed to Delete Day Order Change settings');
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }

  isInvalid(formControl: string): boolean {
    return this.change_form.touched && this.change_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.change_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  onDownloadClick() {
    var data_list: any[] = [];
    var i = 1;
    for (const day of this.day_order_change_list) {


      data_list.push({
        "S.No": i,
        "DATE": day.date.substring(0, 10),
        "DAY": day.day,
        "TO DAY.": day.day_to
      })
      i++;
    }



    CSVHelper.downloadCSV(data_list, "day-order-change-list-" + DateHelper.convertToControlDate(new Date()))
  }


}

interface RouteSettingInterface {
  _id: string,
  date: string,
  day: string,
  day_to: string,
}