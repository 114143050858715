<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>

  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Incharges</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
    </div>

    <div class="row row-sm">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card" >
                <div class="card-header pb-0">
                  <div class="d-flex justify-content-between">
                    <h4 class="card-title mg-b-0 mb-3">Monthly Bill</h4>
                  </div>
 
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <select
                        class="form-control"
                        [(ngModel)]="year" 
                        (change)="chooseYear()"> 
                      <option value="">Select Year</option>
                      <option *ngFor="let year of years;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="year">{{year}}</option>
                  </select>
                    </div>
                    <div class="col-md-6">
                      <select
                        class="form-control"
                        [(ngModel)]="month" 
                        (change)="chooseMonth()"> 
                      <option value="">Select Month</option>
                      <option *ngFor="let month of months;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="month.month_name">{{month.month_name}}</option>
                    </select>
                    </div>
                  </div>

                <form [formGroup]="searchForm" class="form-horizontal">

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <input type="text"
                        class="form-control"
                        placeholder="Student Roll No."
                        formControlName="student_rno" 
                        >                 
                    </div>
                    <div class="col-md-6">
                      <input type="number"
                        class="form-control"
                        placeholder="Student Mobile No."
                        formControlName="student_mno" 
                        >
                    </div>
                  </div>


                  <div class="row mb-3">
                    <div class="col-md-3">
                      <select
                        class="form-control"
                        formControlName="hostel" 
                        (change)="searchChange('building',$event)"
                        > 
                      <option value="">Select Hostel</option>
                      <option *ngFor="let hostel of hostels;let i=index;trackBy:fromDataResolver.identify;" 
                      [ngValue]="hostel.building">{{hostel.building}}</option>
                  </select>
                    </div>

                    <div class="col-md-3">
                      <select
                        class="form-control"
                        formControlName="floor" 
                        (change)="searchChange('floor',$event)"
                        > 
                      <option value="">Select Floor</option>
                      <option *ngFor="let floor of floors;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="floor">{{floor}}</option>
                    </select>
                    </div>

                    <div class="col-md-3">
                      <select
                        class="form-control"
                        formControlName="room" 
                        > 
                      <option value="">Select Room</option>
                      <option *ngFor="let room of rooms;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="room">{{room}}</option>
                    </select>
                    </div>

                    <div class="col-md-3">
                      <button type="button" 
                                (click)="searchStudent()"
                                [disabled]="canDisable()"
                                class="btn btn-md btn-info mr-2">
                                    Search
                      </button>
                    </div>

                  </div>
                </form>


                </div>
      


                
                <div class="card-body">
                    <div class="table-responsive border-top userlist-table" *ngIf="month">
                      <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                        <thead>
                          <th>
                              <span>S.No</span>
                          </th>
                          <th>
                            <span>Roll No.</span>
                          </th>
                          <th>
                            <span>Name</span>
                          </th>
                          <th>
                            <span>Room</span>
                          </th>
                          <th>
                            <span>General</span>
                          </th>
                          <th>
                            <span>Extra</span>
                          </th>
                          <th>
                            <span>Other</span>
                          </th>
                          <th>
                            <span>Total</span>
                          </th>
                          <th>
                            <span>Status</span>
                          </th>
                          <th>
                            <span>TID</span>
                          </th>
                          <th>
                            <span>Action</span>
                          </th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let student of students; let i = index">
                          <td>{{i+1}}</td>
                          <td>{{student.student_data.student_rno}}</td>
                          <td>{{student.student_data.student_name}}</td>
                          <td>{{student.hostel_data.room_no }} ({{student.hostel_data.building_name}})</td>
                          <td>{{student.expense_data.general_expense }}</td>
                          <td>{{student.expense_data.extra_expense }}</td>
                          <td>{{student.expense_data.other_expense }}</td>
                          <td>{{student.expense_data.total_expense }}</td>
                          <td>{{student.payment_data.payment_status }}</td>
                          <td>{{student.payment_data.transaction_id }}</td>
                          <td>
                            <div class="pr-1 mb-3 mb-xl-0">
                                <button type="button" 
                                (click)="viewBill(student)"
                                *ngIf="student.payment_data.payment_status=='UNPAID'"
                                class="btn btn-sm btn-info mr-2">
                                    <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                </button>
                                <button type="button" 
                                (click)="viewBill(student)"
                                *ngIf="student.payment_data.payment_status=='PAID'"
                                class="btn btn-sm btn-info mr-2">
                                    <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                </button>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>


              </div>
      </div>
    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content modal-content-demo" *ngIf="bill">
                <div class="modal-header">
                    <h6 class="modal-title">Student Bill Report</h6>
                    <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->

                </div>
                <div class="modal-body">
                    <div class="card card-body pd-20 pd-md-40">

                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                Name (R.No)
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.student_data.student_name}} ({{bill.student_data.student_rno}})
                                </b>
                            </div>
                        </div>
                        
                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                Room No. (Building Name)
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.hostel_data.room_no}} ({{bill.hostel_data.building_name}})
                                </b>
                            </div>
                        </div>

                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                General Expense
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.expense_data.general_expense}}
                                </b>
                            </div>
                        </div>

                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                Extra Expense
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.expense_data.extra_expense}}
                                </b>
                            </div>
                        </div>

                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                Other Expense
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.expense_data.other_expense}}
                                </b>
                            </div>
                        </div>

                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                Total Expense
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.expense_data.total_expense}}
                                </b>
                            </div>
                        </div>
                    
                        <div class="row mg-b-20">
                            <div class="col-md-5">
                                Payment Status (Transaction ID)
                            </div>
                            <div class="col-md-7">
                                <b>
                                    {{bill.payment_data.payment_status}}
                                    <span *ngIf="bill.payment_data.payment_status=='PAID'">({{bill.payment_data.transaction_id}})</span>
                                </b>
                            </div>
                        </div>

                        <div class="add-btn-row">
                          <button class="btn btn-success mg-r-20"
                          *ngIf="bill.payment_data.payment_status=='UNPAID'"
                          (click)="clearPayment()">Make Manual Payment</button>
                          <button class="btn btn-primary mg-r-20" 
                          *ngIf="bill.payment_data.payment_status=='UNPAID'"
                          (click)="makePayment()">Make Online Payment</button>
                          <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->


  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>

