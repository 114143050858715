<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid ">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
    </div>

    <!-- Create hostel -->
    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12">
        <div class="card box-shadow-0">
          <div class="card-header">
            <h4 class="card-title mb-1">{{ getTittle() }}</h4>
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="studentRoomMapForm" class="form-horizontal">
              <div class="row row-sm">
                <!-- <div class="col-lg-9 col-md-8 mg-b-0"> -->
                <div class="form-group col-lg-9 col-md-8 mg-b-0">
                  <input class="form-control" required="" type="text" placeholder="Enter Student Email Id or Roll"
                    formControlName="studentEmail">
                  <div *ngIf="isInvalid('studentEmail')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Please enter a valid email id or roll number.</li>
                    </ul>
                  </div>
                </div>
                <!-- </div> -->
                <!-- <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2"> -->
                <div class="form-group col-lg-3 col-md-4 mg-t-10 mg-sm-t-25 no-margin-top">
                  <button class="btn btn-primary no-margin-top mg-r-20" title="Search" [disabled]="canDisableSearch()"
                    (click)="onSearchClick()" data-placement="bottom" data-toggle="tooltip"
                    title="Find student by roll no or by email id">
                    Search
                  </button>
                </div>
                <!-- </div> -->
              </div>
              <div class="form-group">
                <!-- <label class="main-content-label tx-11 tx-medium tx-gray-600">Student Details</label> -->
                <textarea readonly placeholder="Student Details" class="form-control" required=""
                  formControlName="studentDetails" row="6"></textarea>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Food Preference</label>
                <select formControlName="foodPreference" class="form-control"
                  (change)="onFilterSelectChange('foodPreference',$event)">
                  <option value="">Choose one</option>
                  <option *ngFor="let type of foodPreferences;let i=index;trackBy:fromDataResolver.identify;"
                    [ngValue]="type">{{ type }}</option>
                </select>
                <div *ngIf="isInvalid('foodPreference')">
                  <ul class="parsley-errors-list filled">
                    <li class="parsley-required">Food preference is required.</li>
                  </ul>
                </div>
              </div>

              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select From Date</label>
                <input class="form-control" required="" type="date" formControlName="from">
                <div *ngIf="isInvalid('from')">
                  <ul class="parsley-errors-list filled">
                    <li class="parsley-required">From date is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End Date</label>
                <input class="form-control" required="" type="date" formControlName="to">
                <div *ngIf="isInvalid('to')">
                  <ul class="parsley-errors-list filled">
                    <li class="parsley-required">End date is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Remarks</label>
                <textarea row="2" class="form-control" formControlName="remarks"></textarea>
              </div>
              <div class="card box-shadow-0">
                <div class="card-header">
                  <h4 class="card-title mb-1">Add Asset</h4>
                </div>
                <div class="card-body pt-0">
                  <form [formGroup]="assetForm">
                    <div class="row">
                      <div class="form-group  col-lg-9 col-md-8 mg-b-0">
                        <input class="form-control" required="" type="text" formControlName="assetId"
                          placeholder="Enter Unique Asset Id">
                      </div>
                      <div class="form-group col-lg-3 col-md-4 mg-t-10 mg-sm-t-25 no-margin-top">
                        <button class="btn btn-secondary mg-r-20" (click)="onAddAsset()"
                          [disabled]="canDisableAddAsset()">Add
                          Asset</button>
                      </div>
                    </div>
                  </form>
                  <div class="table-responsive border-top userlist-table">
                    <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                      <thead>
                        <th>
                          <span>Asset Id</span>
                        </th>
                        <th>
                          <span>Asset Type</span>
                        </th>
                        <th>
                          <span>Description</span>
                        </th>
                      </thead>
                      <tbody>

                        <tr *ngFor="let asset of assets">

                          <td>{{ asset?.asset_ref }} </td>
                          <td>{{ asset?.type }} </td>
                          <td>{{ asset?.description }} </td>
                          <td>
                            <button type="button" class="btn btn-sm btn-danger mr-2"
                              (click)="onDeleteAsset(asset?.asset_ref)">
                              <fa-icon [icon]="fonts.faTrash"></fa-icon>
                            </button>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                <button class="btn ripple btn-secondary" (click)="onClose()" type="button">Close</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
