<!-- main-sidebar -->
<div class="app-sidebar__overlay" data-toggle="sidebar"></div>
<!-- <aside class="app-sidebar sidebar-scroll" style="overflow: scroll !important;"> -->
<aside class="app-sidebar sidebar-scroll" style="overflow: scroll">
  <div class="main-sidebar-header active">
    <a class="desktop-logo logo-light active" href="/dashboard"><img src="../../assets/img/logo/side-logo.png"
        class="main-logo" alt="logo" /></a>
    <a class="desktop-logo logo-dark active" href="/dashboard"><img src="../../assets/img/logo/side-logo.png"
        class="main-logo dark-theme" alt="logo" /></a>
    <a class="logo-icon mobile-logo icon-light active" href="/dashboard"><img src="../../assets/img/logo/logo-50-50.png"
        class="logo-icon" alt="logo" /></a>
    <a class="logo-icon mobile-logo icon-dark active" href="/dashboard"><img src="../../assets/img/logo/logo-50-50.png"
        class="logo-icon dark-theme" alt="logo" /></a>
  </div>
  <div class="main-sidemenu">
    <div class="app-sidebar__user clearfix">
      <div class="dropdown user-pro-body">
        <div class="">
          <img alt="user-img" class="avatar avatar-xl brround" [src]="picture" /><span
            class="avatar-status profile-status bg-green"></span>
        </div>
        <div class="user-info">
          <h4 class="font-weight-semibold mt-3 mb-0">{{ name }}</h4>
          <span class="mb-0 text-muted">{{ email }}</span>
        </div>
      </div>
    </div>
    <ul class="side-menu">

      <!-- <ng-container  *ngIf="isRoleExists(['principal'])">
        <li class="slide">
          <a class="side-menu__item" routerLink="/admin/dashboard">
            <fa-icon [icon]="fonts.faTachometerAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Dashboard</span>
          </a>
        </li>
      </ng-container> -->

      <ng-container *ngIf="isRoleExists(['student'])">
        <li class="slide">
          <a class="side-menu__item" routerLink="/students/dashboard">
            <fa-icon [icon]="fonts.faTachometerAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Dashboard</span>
          </a>
        </li>
        <li class="slide">
          <a class="side-menu__item" routerLink="/student/attendance">
            <fa-icon [icon]="fonts.faList" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Attendance</span>
          </a>
        </li>
        <li class="slide">
          <a class="side-menu__item" routerLink="/student/time-table/view-timetable">
            <fa-icon [icon]="fonts.faTachometerAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Time Table</span>
          </a>
        </li>
        <li class="slide">
          <a class="side-menu__item" routerLink="/students/mypayments">
            <fa-icon [icon]="fonts.faMoneyBillAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Fees & Payments</span>
          </a>
        </li>
        <!-- <li class="slide">
          <a class="side-menu__item" routerLink="/students/hostel">
            <fa-icon [icon]="fonts.faHotel" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Hostel</span>
          </a>
        </li> -->
        <ng-container *ngIf="isRoleExists(['student'])">
          <li class="slide">
            <a class="side-menu__item" data-toggle="slide" href="#">
              <fa-icon [icon]="fonts.faBook" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Academics</span><i class="angle fe fe-chevron-down"></i>
            </a>
            <ul class="slide-menu">
              <li>
                <a class="slide-item" routerLink="/students/elective-courses">Elective Courses</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/student/terminal/student-submission">Terminal Exam Submission</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/students/hall-ticket">Hall Ticket</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/students/obe/evidence-upload">Non Academic Attainment</a>
              </li>
            </ul>
          </li>
        </ng-container>
        <ng-container *ngIf="isRoleExists(['student'])">
          <li class="slide">
            <a class="side-menu__item" data-toggle="slide" href="#">
              <fa-icon [icon]="fonts.faPoll" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Survey</span><i class="angle fe fe-chevron-down"></i>
            </a>
            <ul class="slide-menu">
              <li>
                <a class="slide-item" routerLink="/students/obe/course-exit-survey">Course Exit Survey</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/students/obe/programme-exit-survey">Programme Exit Survey</a>
              </li>
            </ul>
          </li>
        </ng-container>
        <ng-container *ngIf="isRoleExists(['student'])">
          <li class="slide">
            <a class="side-menu__item" routerLink="/student/transport-request">
              <fa-icon [icon]="fonts.faBus" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Transportation</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="isRoleExists(['student'])">
          <li class="slide">
            <a class="side-menu__item" data-toggle="slide" href="#">
              <fa-icon [icon]="fonts.faHotel" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Hostel</span><i class="angle fe fe-chevron-down"></i>
            </a>
            <ul class="slide-menu">
              <li>
                <a class="slide-item" routerLink="/student/hostel/admission">Hostel Admission</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/student/hostel/hostel-attendance">Hostel Attendance</a>
              </li>
            </ul>
          </li>
        </ng-container>
        <!-- <li class="slide" *ngIf="female"> -->
        <!-- <li class="slide">
          <a class="side-menu__item" routerLink="/students/transport">
            <fa-icon [icon]="fonts.faBus" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Transportation</span>
          </a>
        </li> -->


        <!-- <li class="slide">
          <a class="side-menu__item" routerLink="/students/mycourses">
            <fa-icon [icon]="fonts.faAddressBook" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">My Courses</span>
          </a>
          <a class="side-menu__item" routerLink="/students/elective-courses">
            <fa-icon [icon]="fonts.faAddressBook" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Elective Courses</span>
          </a>
        </li> -->

        <!-- <li class="slide">
          <a class="side-menu__item" routerLink="/student/time-table/view-timetable">
            <fa-icon [icon]="fonts.faCalendar" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">View Timetable</span>
          </a>
        </li> -->

        <!-- <li class="slide">
          <a class="side-menu__item" routerLink="/student/terminal/student-submission">
            <fa-icon [icon]="fonts.faPencilAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Terminal Exam Submission</span>
          </a>
        </li> -->

        <!-- <li class="slide" *ngIf="
                    isRoleExists([
                      'principal',
                      'student'
                    ])
                  ">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faBook" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">OBE</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <li>
              <a class="slide-item" routerLink="/students/obe/course-exit-survey">Course Exit Survey</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/students/obe/programme-exit-survey">Programme Exit Survey</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/students/obe/evidence-upload">Upload NA Evidence</a>
            </li>
          </ul>
        </li> -->
      </ng-container>
      <ng-container *ngIf="isRoleExists(['staff', 'accounts', 'non_staff'])">
        <li class="slide" *ngIf="
            isRoleExists([
              'staff_admin',
              'boys_hostel_admin',
              'girls_hostel_admin',
              'office_admin',
              'non_staff',
              'staff',
              'accounts'
            ])
          ">
          <a class="side-menu__item" routerLink="/staff/dashboard">
            <fa-icon [icon]="fonts.faTachometerAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Dashboard</span>
          </a>
        </li>

        <!--Admissions-->
        <ng-container *ngIf="
  isRoleExists([
    'principal',
    'admission_member',
    'admission_admin'
  ])
">
          <li class="slide">
            <a class="side-menu__item" data-toggle="slide" href="#">
              <fa-icon [icon]="fonts.faCogs" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Admissions</span><i class="angle fe fe-chevron-down"></i>
            </a>
            <ul class="slide-menu">
              <li>
                <a class="slide-item" routerLink="/admin/admissions/admission/admission-master">Admissions</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/admissions/news">Admission News</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/admissions/admission/sms-campaign">SMS Campaign</a>
              </li>
            </ul>
          </li>
        </ng-container>

        <!--Students-->
        <ng-container *ngIf="isRoleExists(['staff_admin', 'principal', 'office_admin', 'hod', 'accounts'])">
          <li class="slide">
            <a class="side-menu__item" data-toggle="slide" href="#">
              <fa-icon [icon]="fonts.faUsers" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Students</span><i class="angle fe fe-chevron-down"></i>
            </a>
            <ul class="slide-menu">
              <li>
                <a class="slide-item" routerLink="/admin/students/students">Students</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/students/search">Search Students</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/students/students-count">Students Count Report</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/staff/student-guardian-assignment">Student-Guardian
                  Assignment</a>
              </li>
              <!-- <li>
                <a class="slide-item" routerLink="/admin/students/repeat-semester">Manage Repeat Semester</a>
              </li> -->
            </ul>
          </li>





        </ng-container>

        <!--Attendance-->
        <ng-container *ngIf="
isRoleExists(['hod','staff', 'staff_admin', 'principal', 'office_admin'])
">
          <li class="slide">
            <a class="side-menu__item" data-toggle="slide" href="#">
              <fa-icon [icon]="fonts.faList" class="side-bar-icon"></fa-icon>
              <span class="side-menu__label">Attendance</span><i class="angle fe fe-chevron-down"></i>
            </a>
            <ul class="slide-menu">
              <ng-container *ngIf="
  isRoleExists([
    'principal',
    'office_admin',
    'timetable_member'
  ])
">
                <li class="sub-slide">
                  <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                    <span class="sub-side-menu__label">Timetable Settings</span>
                    <i class="sub-angle fe fe-chevron-down"></i>
                  </a>
                  <ul class="sub-slide-menu">
                    <ng-container *ngIf="
                    isRoleExists([
                      'principal',
                      'office_admin'
                    ])
                  ">
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/time-settings">Time Settings</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/programme-time-settings">Programme-Time
                          Settings</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/configuration">Time Configuration</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/holiday-calendar">Holiday Calendar</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/day-order-change">Day Order Change</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="
        isRoleExists([
           'principal',
           'office_admin',
           'timetable_member',
           'hod'
        ])
      ">
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/time-table-settings">Add Staff Time
                          Table</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/time-table/view-staff-time-table">View Staff Time
                          Table</a>
                      </li>
                    </ng-container>
                  </ul>
                </li>

              </ng-container>
              <ng-container *ngIf="
                    isRoleExists([
                      'staff',
                      'staff_admin',
                      'principal',
                      'office_admin'
                    ])
                  ">
                <li>
                  <a class="slide-item" routerLink="/staff/time-table/view-timetable">Mark Attendance</a>
                </li>
              </ng-container>
              <!-- <ng-container *ngIf="
                                  isRoleExists([
                                    'staff',
                                    'staff_admin',
                                    'principal',
                                    'office_admin'
                                  ])
                                ">
                <li>
                  <a class="slide-item" routerLink="/staff/attendance/repeat-attendance">Repeat Attendance</a>
                </li>
              </ng-container> -->
              <ng-container *ngIf="
isRoleExists([
  'principal',
  'office_admin',
  'hod'
])
">
                <li class="sub-slide">
                  <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                    <span class="sub-side-menu__label">Attendance Reports</span>
                    <i class="sub-angle fe fe-chevron-down"></i>
                  </a>
                  <ul class="sub-slide-menu">
                    <ng-container *ngIf="
                  isRoleExists([
                    'principal',
                    'office_admin'
                  ])
                ">
                      <li>
                        <a class="slide-item" routerLink="/admin/attendance/staff-not-marked-report">Hourly Report</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/attendance/staff-late-entry">Late Entry Requests</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/attendance/staff-report">Staff Report</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="
        isRoleExists([
          'principal',
          'office_admin',
          'hod'
        ])
      ">
                      <li>
                        <a class="slide-item" routerLink="/admin/attendance/attendance-report">Student Report</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/attendance/absent-report">Absent Report</a>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>


        <!-- <li class="slide">
                    <a class="side-menu__item" target="_blank" href="https://oldportal.anjaconline.org">
                        <fa-icon [icon]="fonts.faBook" class="side-bar-icon"></fa-icon>
                        <span class="side-menu__label">Old Portal</span>
                    </a>

                </li>
                <li class="slide">
                    <a class="side-menu__item" target="_blank" href="https://oldportal.anjaconline.org">
                        <fa-icon [icon]="fonts.faCalendarCheck" class="side-bar-icon"></fa-icon>
                        <span class="side-menu__label">Old Attendance</span>
                    </a>

                </li>
                <li class="slide">
                    <a class="side-menu__item" target="_blank" href="https://admission-admin.anjaconline.org">
                        <fa-icon [icon]="fonts.faUserCheck" class="side-bar-icon"></fa-icon>
                        <span class="side-menu__label">Old Admission</span>
                    </a>

                </li> -->
      </ng-container>






      <!--Examination-->
      <ng-container *ngIf="
     isRoleExists(['hod','staff', 'staff_admin', 'principal', 'coe', 'guest_user','office_admin', 'aarc_admin',
      'aarc_member', 'obe_member','obe_evaluate','obe_approve','obe_monitor'])
   ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faPencilAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Examination</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <ng-container *ngIf="
      isRoleExists([
      'principal',
      'aarc_admin',
      'aarc_member',
      'hod',
      'staff_admin',
      'staff'
      ])
    ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Settings</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                      isRoleExists([
                        'principal',
                        'aarc_admin',
                        'aarc_member'
                      ])
                    ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/components-master">OBE Components</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/courses/course-scheme">Course Scheme Setup</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/programme-pam">Programme PAM</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                  isRoleExists([
                    'principal',
                    'hod',
                    'aarc_admin',
                    'aarc_member',
                    'obe_member'
                  ])
                ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/programme-exit-survey">Programme Exit Survey</a>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="
                            isRoleExists([
                              'principal',
                              'aarc_admin',
                              'aarc_member',
                              'staff_admin',
                              'hod'
                            ])
                          ">
                    <li>
                      <a class="slide-item" routerLink="/admin/courses/course-setup">Course Setup</a>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="
                                    isRoleExists([
                                     'principal',
                                    'aarc_admin',
                                     'aarc_member'
                                     ])
    ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/elective-polling-settings"> Course Polling
                        Settings</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/elective-quantity-settings">Course Quantity
                        Settings</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
  isRoleExists([
   'principal',
  'aarc_admin',
   'aarc_member',
   'staff',
   'hod'])
">

                    <li>
                      <a class="slide-item" routerLink="/staff/obe/course-exit-survey">Course Exit Survey</a>
                    </li>
                    <ng-container *ngIf="
                  isRoleExists([
                  'principal',
                  'aarc_admin',
                  'aarc_member',
                  'hod'])
                ">
                      <li>
                        <a class="slide-item" routerLink="/admin/staff/staff-assignment">Staff Course Assignment</a>
                      </li>
                      <li>
                        <a class="slide-item" routerLink="/admin/students/student-assignment">Student Course
                          Assignment</a>
                      </li>
                    </ng-container>


                  </ng-container>
                </ul>
              </li>

            </ng-container>

            <!-- Internal-->
            <ng-container *ngIf="
            isRoleExists([
            'staff_admin', 'principal', 'office_admin', 'aarc_admin', 'aarc_member','obe_member',
            'hod',
            'coe', 'obe_evaluate', 'obe_approve', 'obe_monitor', 'staff'
            ])
          ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Internal</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li>
                    <a class="slide-item" routerLink="/admin/obe/assign-header">Assign Marks</a>
                  </li>
                  <ng-container *ngIf="
                        isRoleExists([
                          'principal',
                          'obe_member',
                          'hod',
                          'coe',
                          'aarc_admin',
                          'aarc_member'
                        ])
                      ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/review-mark">Review Marks</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                       isRoleExists([
                         'principal',
                         'hod',
                         'coe'
                       ])
                   ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/obe-member">OBE Incharges</a>
                    </li>
                  </ng-container>

                </ul>
              </li>

            </ng-container>
            <!-- External-->
            <ng-container *ngIf="
                  isRoleExists([
                  'staff_admin', 'principal', 'coe','hod','staff','guest_user'
                  ])
                ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">External</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                              isRoleExists([
                                'principal',
                                'coe'
                              ])
                            ">

                    <li>
                      <a class="slide-item" routerLink="/admin/terminal/question-format-setting">Question Format
                        Setting</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                    isRoleExists([
                      'principal',
                      'coe',
                      'staff',
                      'hod'
                    ])
                  ">
                    <li>
                      <a class="slide-item" routerLink="/admin/terminal/exam-setup">Exam Setup</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/staff/terminal/valuation">Terminal Internal Valuation</a>
                    </li>
                    <!-- <li>
                      <a class="slide-item" routerLink="/staff/terminal/deviation">Terminal Exam deviation</a>
                    </li> -->
                  </ng-container>
                  <ng-container *ngIf="
                     isRoleExists([
                       'principal',
                       'guest_user'
                     ])
                   ">
                    <li>
                      <a class="slide-item" routerLink="/staff/terminal/external-valuation">Terminal External
                        Valuation</a>
                    </li>
                  </ng-container>

                </ul>
              </li>

            </ng-container>

            <!-- External Reports-->
            <ng-container *ngIf="
                isRoleExists([
                'staff_admin', 'principal', 'office_admin', 'aarc_admin', 'aarc_member','coe','hod','staff','guest_user'
                ])
              ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">External Reports</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/course-grade-register">Mark-Grade Register</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/terminal-exam-result">Terminal Exam Result</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/statement-of-marks">Statement of Marks</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/final-foil-card">Foil Card</a>
                  </li>

                </ul>
              </li>
            </ng-container>

          </ul>
        </li>
      </ng-container>



      <!--OBE Reports-->
      <ng-container *ngIf="
           isRoleExists(['hod','staff', 'staff_admin', 'principal', 'coe','office_admin', 'aarc_admin',
            'aarc_member', 'obe_member','obe_evaluate','obe_approve','obe_monitor'])
         ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faFile" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">OBE Reports</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <!--Course wise-->
            <ng-container *ngIf="
            isRoleExists([
            'principal',
            'aarc_admin',
            'aarc_member',
            'hod',
            'staff_admin',
            'staff'
            ])
          ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Course Wise</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                            isRoleExists([
                              'principal',
                              'aarc_admin',
                              'aarc_member'
                            ])
                          ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/cas-report">CAS Report</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                                              isRoleExists([
                                                'principal',
                                                'aarc_admin',
                                                'aarc_member'
                                              ])
                                            ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/cagp-report">CAGP Report</a>
                    </li>
                  </ng-container>
                </ul>
              </li>

            </ng-container>

            <!--Programme wise-->
            <ng-container *ngIf="
                        isRoleExists([
                        'principal',
                        'aarc_admin',
                        'aarc_member',
                        'hod'
                        ])
                      ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Programme Wise</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                                        isRoleExists([
                                          'principal',
                                          'aarc_admin',
                                          'aarc_member',
                                          'hod'
                                        ])
                                      ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/programme-outcome">Programme Outcome</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                                              isRoleExists([
                                                'principal',
                                                'aarc_admin',
                                                'aarc_member'
                                              ])
                                            ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/co-attainment-report">CO Attainment Report</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                                              isRoleExists([
                                                'principal',
                                                'aarc_admin',
                                                'aarc_member'
                                              ])
                                            ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/percentage-attainment-co">Percentage attainment of
                        COs</a>
                    </li>
                  </ng-container>
                </ul>
              </li>

            </ng-container>

            <!--Holistic-->
            <ng-container *ngIf="
                        isRoleExists([
                        'principal',
                        'aarc_admin',
                        'aarc_member',
                        'hod',
                        'staff_admin',
                        'staff'
                        ])
                      ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Holistic Attainment</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                                                                   isRoleExists([
                                                                     'principal',
                                                                     'obe_member',
                                                                     'hod',
                                                                     'coe',
                                                                     'obe_evaluate', 'obe_approve', 'obe_monitor'
                                                                   ])
                                                                 ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/non-skillset-master">Skillset Master</a>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="
                                                 isRoleExists([
                                                   'principal',
                                                   'obe_member',
                                                   'hod',
                                                   'coe',
                                                   'obe_evaluate', 'obe_approve', 'obe_monitor'
                                                 ])
                                               ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/review-na-evidence">Review NA Evidence</a>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="
                                                                   isRoleExists([
                                                                     'principal',
                                                                     'obe_member',
                                                                     'hod',
                                                                     'coe',
                                                                     'obe_evaluate', 'obe_approve', 'obe_monitor'
                                                                   ])
                                                                 ">
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/na-semester-report">NA Semester Report</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/na-programme-report">NA Programme Report</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/obe/holistic-attainment">Holistic Attainment Report</a>
                    </li>
                  </ng-container>

                </ul>
              </li>

            </ng-container>




          </ul>


        </li>
      </ng-container>







      <!--OBE--
      <ng-container *ngIf="
          isRoleExists(['hod','staff', 'staff_admin', 'principal', 'aarc_admin', 'aarc_member'])
        ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faCodeBranch" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Courses</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <li>
              <a class="slide-item" routerLink="/admin/courses/manage-course">Courses</a>
            </li>
          </ul>
        </li>
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faBook" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">OBE</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <ng-container *ngIf="
                isRoleExists([
                  'principal',
                  'aarc_admin',
                  'aarc_member'
                ])
              ">

              <li>
                <a class="slide-item" routerLink="/admin/obe/components-master">OBE Components</a>
              </li>

               <li>
              <a class="slide-item" routerLink="/admin/courses/manage-course">Courses</a>
            </li>
              <li>
                <a class="slide-item" routerLink="/admin/courses/course-scheme">Course Scheme Setup</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/obe/programme-pam">Programme PAM</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                            isRoleExists([
                              'principal',
                              'aarc_admin',
                              'aarc_member',
                              'staff_admin',
                              'hod'
                            ])
                          ">
              <li>
                <a class="slide-item" routerLink="/admin/courses/course-setup">Course Setup</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/staff/staff-assignment">Staff Course Assignment</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/students/student-assignment">Student Course Assignment</a>
              </li>
            </ng-container>
            <li>
              <a class="slide-item" routerLink="/staff/obe/constructive-alignment">Constructive Alignment</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/obe/assign-header">Assign Marks</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/staff/obe/course-exit-survey">Course Exit Survey</a>
            </li>
            <ng-container *ngIf="
                isRoleExists([
                  'principal',
                  'hod',
                  'aarc_admin',
                  'aarc_member'
                ])
              ">
              <li>
                <a class="slide-item" routerLink="/admin/obe/programme-exit-survey">Programme Exit Survey</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                                        isRoleExists([
                                          'principal',
                                          'obe_member',
                                          'hod',
                                          'coe',
                                          'aarc_admin',
                                          'aarc_member'
                                        ])
                                      ">
              <li>
                <a class="slide-item" routerLink="/admin/obe/review-mark">Review Marks</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                                                    isRoleExists([
                                                      'principal',
                                                      'obe_member',
                                                      'hod',
                                                      'coe',
                                                      'obe_evaluate', 'obe_approve', 'obe_monitor'
                                                    ])
                                                  ">
              <li>
                <a class="slide-item" routerLink="/admin/obe/review-na-evidence">Review NA Evidence</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                isRoleExists([
                  'principal',
                  'hod',
                  'coe'
                ])
            ">
              <li>
                <a class="slide-item" routerLink="/admin/obe/obe-member">OBE Incharges</a>
              </li>
            </ng-container>
             <ng-container *ngIf="
              isRoleExists([
                'part_i_admin',
                'part_ii_admin',
                'part_iv_admin',
                'part_v_admin',
                'hod',
                'staff_admin',
                'principal',
                'office_admin'
              ])
            ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">UG Part Settings</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li *ngIf="
                    isRoleExists([
                      'part_i_admin',
                      'hod',
                      'staff_admin',
                      'principal',
                      'office_admin'
                    ])
                  ">
                    <a class="sub-slide-item" href="/admin/programme/ugparts?part=PART_I">Part
                      I</a>
                  </li>
                  <li *ngIf="
                    isRoleExists([
                      'part_ii_admin',
                      'hod',
                      'staff_admin',
                      'principal',
                      'office_admin'
                    ])
                  ">
                    <a class="sub-slide-item" href="/admin/programme/ugparts?part=PART_II">Part II</a>

                  </li>
                  <li *ngIf="
                    isRoleExists([
                      'part_iv_admin',
                      'hod',
                      'staff_admin',
                      'principal',
                      'office_admin'
                    ])
                  ">
                    <a class="sub-slide-item" href="/admin/programme/ugparts?part=PART_IV">Part IV</a>
                  </li>
                  <li *ngIf="
                    isRoleExists([
                      'part_v_admin',
                      'hod',
                      'staff_admin',
                      'principal',
                      'office_admin'
                    ])
                  ">
                    <a class="sub-slide-item" href="/admin/programme/ugparts?part=PART_V">Part V</a>
                  </li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">PG Part Settings</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">

                  <li *ngIf="
                isRoleExists([
                  'part_v_admin',
                  'hod',
                  'staff_admin',
                  'principal',
                  'office_admin'
                ])
              ">
                    <a class="sub-slide-item"
                      href="/admin/programme/ugparts?part=SUPPORTIVE_COURSES&prg_type=PG">Supportive Courses</a>
                  </li>
                </ul>
              </li>
            </ng-container>
            <ng-container *ngIf="
              isRoleExists([
                'hod',
                'staff_admin',
                'principal',
                'office_admin'
              ])
            ">

              <li>
                <a class="slide-item" routerLink="/admin/students/manage-elective-request">Manage Course Request</a>
              </li>
            </ng-container>


          </ul>
        </li>
      </ng-container> -->

      <!--Examination-->
      <!-- <ng-container *ngIf="
                isRoleExists(['hod','staff', 'staff_admin', 'principal', 'coe', 'student', 'guest_user'])
              ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faPencilAlt" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Examination</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <ng-container *ngIf="
                      isRoleExists([
                        'principal',
                        'coe'
                      ])
                    ">

              <li>
                <a class="slide-item" routerLink="/admin/terminal/question-format-setting">Question Format Setting</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                            isRoleExists([
                              'principal',
                              'coe',
                              'staff',
                              'hod'
                            ])
                          ">
              <li>
                <a class="slide-item" routerLink="/admin/terminal/exam-setup">Exam Setup</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/staff/terminal/valuation">Terminal Internal Valuation</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                                  isRoleExists([
                                    'principal',
                                    'student'
                                  ])
                                ">
              <li>
                <a class="slide-item" routerLink="/student/terminal/student-submission">Terminal Student Submission</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                isRoleExists([
                  'principal',
                  'guest_user'
                ])
              ">
              <li>
                <a class="slide-item" routerLink="/staff/terminal/external-valuation">Terminal External Valuation</a>
              </li>
            </ng-container>
            <ng-container *ngIf="
                                  isRoleExists([
                                    'principal',
                                    'coe'
                                  ])
                                ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Terminal Reports</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/course-grade-register">Mark-Grade Register</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/terminal-exam-result">Terminal Exam Result</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/statement-of-marks">Statement of Marks</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/terminal/final-foil-card">Foil Card</a>
                  </li>

                </ul>
              </li>

            </ng-container>

              <ng-container *ngIf="
                  isRoleExists([
                    'principal',
                    'coe',
                    'staff',
                    'hod',
                    'student'
                  ])
                ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Internal Examination</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                      isRoleExists([
                        'principal',
                        'coe',
                        'staff',
                        'hod'
                      ])
                    ">
                    <li>
                      <a class="slide-item" routerLink="/staff/internal/exam-setup">Internal Exam Setup</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/staff/internal/exam-valuation">Internal Exam Valuation</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="
                        isRoleExists([
                          'principal',
                          'student'
                        ])
                      ">
                    <li>
                      <a class="slide-item" routerLink="/student/internal/student-submission">Student Internal
                        Submission</a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container> -->




      <!--Polling
      <ng-container *ngIf="
                isRoleExists(['principal', 'aarc_admin', 'aarc_member'])
              ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faPoll" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Polling</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <li>
              <a class="slide-item" routerLink="/admin/obe/elective-polling-settings">Polling Settings</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/obe/elective-quantity-settings">Course Quantity Settings</a>
            </li>
            <<li>
              <a class="slide-item" routerLink="/admin/obe/manage-elective-request">Manage Course Requests</a>
            </li>
          </ul>
        </li>
      </ng-container>-->





      <!--Notification-->
      <ng-container *ngIf="
                isRoleExists([
                  'principal'
                ])
              ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faMobile" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Notifications</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <!-- <li>
              <a class="slide-item" routerLink="/admin/students/send-notification">Students Notification</a>
            </li> -->
            <li>
              <a class="slide-item" routerLink="/admin/notification/setting">Notification Setting</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/admissions/admission/sms-campaign">SMS Campaign</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/notification/student">Student Notification</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/notification/staff">Staff Notification</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/notification/sent-item">Notification Sent Item</a>
            </li>
          </ul>
        </li>
      </ng-container>




      <!-- <ng-container *ngIf="
          isRoleExists([
            'staff',
            'staff_admin',
            'principal',
            'office_admin',
            'accounts'
          ])
        ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faAtom" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Attendance</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <li>
              <a class="slide-item" routerLink="/admin/attendance/manage">Manage Attendance</a>
            </li>
          </ul>
        </li>
      </ng-container> -->





      <!--New Time Table-->
      <!-- <ng-container *ngIf="
                isRoleExists([
                  'staff_admin',
                  'principal',
                  'office_admin'
                ])
              ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faAtom" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">New Time Table</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <li>
              <a class="slide-item" routerLink="/admin/time-table/time-settings">Time Settings</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/time-table/programme-time-settings">Programme-Time Settings</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/time-table/time-table-settings">Staff Time Table</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/time-table/holiday-calendar">Holiday Calendar</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/time-table/day-order-change">Day Order Change</a>
            </li>
          </ul>
        </li>
      </ng-container> -->


      <!--Attendance-->
      <!-- <ng-container *ngIf="
                      isRoleExists([
                        'staff',
                        'staff_admin',
                        'principal',
                        'office_admin'
                      ])
                    ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faAtom" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">New Attendance</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <li>
              <a class="slide-item" routerLink="/staff/time-table/view-timetable">Mark Attendance</a>
            </li>
            <li *ngIf="isRoleExists([
                        'principal',
                        'office_admin'
                      ])">
              <a class="slide-item" routerLink="/admin/attendance/staff-late-entry">Staff Late Entry</a>
            </li>
          </ul>
        </li>
      </ng-container> -->

      <!--Users-->
      <ng-container *ngIf="
       isRoleExists(['staff_admin', 'principal', 'office_admin', 'hod'])
     ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faUser" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Users</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <ng-container *ngIf="
        isRoleExists([
        'staff_admin', 'principal', 'office_admin','hod'
        ])
      ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Faculties</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li><a class="slide-item" routerLink="/admin/staff/staff-creation">Staffs Creation</a></li>
                  <li><a class="slide-item" routerLink="/admin/staff/staff">Manage Staffs</a></li>
                </ul>
              </li>
            </ng-container>
          </ul>

      </ng-container>

      <!--Account-->
      <ng-container *ngIf="
          isRoleExists(['principal', 'accounts'])
        ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faCashRegister" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Accounts</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <!-- <li>
              <a class="slide-item" routerLink="/admin/students/payments/schedule-list">Fee Collection Report (OLD)</a>
            </li> -->
            <ng-container *ngIf="isRoleExists(['accounts', 'principal'])">
              <li>
                <a class="slide-item" routerLink="/admin/accounts/manage-accounts">Account Master</a>
              </li>

              <!-- <li>
                <a class="slide-item" routerLink="/admin/accounts/fee-reports">Course Fee Report</a>
              </li> -->

              <li>
                <a class="slide-item" routerLink="/admin/exam/fees-report">Fees Report</a>
              </li>

              <li>
                <a class="slide-item" routerLink="/admin/accounts/transaction-report">Transaction Report</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/accounts/settlement-report">Settlement Report</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/admissions/applicant/list">Admission Report</a>
              </li>
              <!-- <li>
                <a class="slide-item" routerLink="/admin/accounts/settlement-report">Settlement Report</a>
              </li> -->

              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Fee Settings</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <ng-container *ngIf="
                  isRoleExists([
                    'principal',
                    'accounts'
                  ])
                ">
                    <li>
                      <a class="slide-item" routerLink="/admin/exam/exam-fees-settings">Exam Fee Settings</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/programmes/programme-header">Programme Fee Settings</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/hostel/hostel-header">Hostel Fee Settings</a>
                    </li>
                    <li>
                      <a class="slide-item" routerLink="/admin/exam/fine-settings">Fine Rules</a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>

          </ul>
        </li>
      </ng-container>

      <!--Hostel-->
      <ng-container *ngIf="
      isRoleExists([
      'principal',
      'boys_hostel_admin',
      'girls_hostel_admin',
      'accounts',
      'non_staff'
      ])
    ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faHotel" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Hostel</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <ng-container *ngIf="isRoleExists([
      'principal',
      'boys_hostel_admin',
      'girls_hostel_admin',
      'accounts'])">
              <!-- <li><a class="slide-item" routerLink="/staff/hostel/admission-request">Admission Request</a>
              </li>
              <li><a class="slide-item" routerLink="/admin/hostel/hostel-management">Manage Hostels</a></li>
              <li><a class="slide-item" routerLink="/admin/hostel/asset-list">Manage Assets</a>
              </li>
              <li><a class="slide-item" routerLink="/admin/hostel/asset-management">Manage Students</a>
              </li>
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Manage Attendance</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/hostel-attendance">General</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/room-attendance">Room-wise </a></li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Manage Mess</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/manage-mess">Menu Items</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/general-menu">General Menu</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/extra-menu">Extra Menu</a></li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Manage Stock</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/stock-purchase">Stock Purchase</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/stock-usage">Stock Usage</a></li>
                </ul>
              </li> -->
              <li>
                <a class="slide-item" routerLink="/admin/hostel/hostel-creation">Create Hostel</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/hostel/hostel-Incharge">Hostel Incharges</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/hostel/requestCreation">Admission Creation</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/hostel/manageRequest">Manage Hostel Request</a>
              </li>
              <li>
                <a class="slide-item" routerLink="/admin/hostel/manage-student">Manage Hostel Students</a>
              </li>

            </ng-container>

            <ng-container *ngIf="isRoleExists([
      'principal',
      'boys_hostel_admin',
      'girls_hostel_admin',
      'accounts'])">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Attendance</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/hostel-attedance-shift">Attendance Shift</a>
                  </li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/attendance/hostel-wise">Hostel Wise
                      Attendance</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/roomwise-attedance">Room Wise Attendance</a>
                  </li>
                </ul>
              </li>
            </ng-container>

            <!-- <ng-container *ngIf="isRoleExists([
      'principal',
      'boys_hostel_admin',
      'girls_hostel_admin',
      'accounts'])">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Manage Bills</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/generate-bill">Generate Bill</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/other-expenses">Other Expenses</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/bill-report">Bill Report</a></li>
                  <li><a class="sub-slide-item" routerLink="/admin/hostel/student-bill">Student Bill</a></li>

                </ul>
              </li>
            </ng-container> -->
          </ul>
        </li>
      </ng-container>

      <!--Transportation-->
      <ng-container *ngIf="

      isRoleExists([
        'principal',
        'transportation_admin'
      ])
    ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faBus" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Transportation</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <!-- <li>
              <a class="slide-item" routerLink="/staff/transport/transport-request">Admission Request</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/staff/transport/transport-routes">Transportation Routes</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/staff/transport/transport-fees-settings">Transportation Fee
                Settings</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/transport/student-transport-history">Transportation History</a>
            </li> -->
            <li>
              <a class="slide-item" routerLink="/admin/transport/manage-transport-request">Manage Transport Request</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/transport/manage-transport-route">Manage Transport Route</a>
            </li>
            <li>
              <a class="slide-item" routerLink="/admin/transport/manage-transport-fee">Manage Transport Fees</a>
            </li>
          </ul>


        </li>
      </ng-container>

      <!--Settings-->
      <ng-container *ngIf="
      isRoleExists([
       'staff_admin', 'principal', 'office_admin', 'aarc_admin', 'aarc_member','hod'
      ])
    ">
        <li class="slide">
          <a class="side-menu__item" data-toggle="slide" href="#">
            <fa-icon [icon]="fonts.faWrench" class="side-bar-icon"></fa-icon>
            <span class="side-menu__label">Settings</span><i class="angle fe fe-chevron-down"></i>
          </a>
          <ul class="slide-menu">
            <ng-container *ngIf="
        isRoleExists([
        'staff_admin', 'principal', 'office_admin', 'hod'
        ])
      ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Department</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li>
                    <a class="slide-item" routerLink="/admin/department/department-category">Department Category</a>
                  </li>
                  <li>
                    <a class="slide-item" routerLink="/admin/department/department">Departments</a>
                  </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="
      isRoleExists([
        'principal',
        'hod',
        'staff_admin',
        'office_admin',
        'aarc_admin', 'aarc_member'
      ])
    ">
              <li class="sub-slide">
                <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
                  <span class="sub-side-menu__label">Programmes</span>
                  <i class="sub-angle fe fe-chevron-down"></i>
                </a>
                <ul class="sub-slide-menu">
                  <li>
                    <ng-container
                      *ngIf="isRoleExists(['principal', 'office_admin','aarc_admin', 'aarc_member', 'hod'])">
                  <li>
                    <a class="slide-item" routerLink="/admin/programme/programme-type">Programme Types</a>
                  </li>
            </ng-container>
            <ng-container *ngIf="
              isRoleExists([
                'hod',
                'staff_admin',
                'principal',
                'office_admin',
                'aarc_admin', 'aarc_member'
              ])
            ">
              <li>
                <a class="slide-item" routerLink="/admin/programme/programme">Programmes</a>
              </li>
            </ng-container>
        </li>
    </ul>
    </ng-container>

    <ng-container *ngIf="
      isRoleExists([
      'staff_admin', 'principal', 'office_admin', 'hod'
      ])
    ">
      <li class="sub-slide">
        <a class="sub-side-menu__item" data-toggle="sub-slide" href="#">
          <span class="sub-side-menu__label">Roles</span>
          <i class="sub-angle fe fe-chevron-down"></i>
        </a>
        <ul class="sub-slide-menu">
          <li>
            <ng-container *ngIf="isRoleExists(['principal', 'office_admin','hod','staff_admin'])">
          <li>
            <a class="slide-item" routerLink="/admin/staff/roles">Roles</a>
          </li>
    </ng-container>

    </li>
    </ul>
    </ng-container>


    </ul>
    </li>
    </ng-container>

    <!-- Reports -->
    <ng-container *ngIf="

          isRoleExists([
            'principal',
            'iqac_admin'
          ])
        ">
      <li class="slide">
        <a class="side-menu__item" data-toggle="slide" href="#">
          <fa-icon [icon]="fonts.faFileDownload" class="side-bar-icon"></fa-icon>
          <span class="side-menu__label">Overall Reports</span><i class="angle fe fe-chevron-down"></i>
        </a>
        <ul class="slide-menu">
          <li>
            <a class="slide-item" routerLink="/admin/reports/report-template-create">Report Creation</a>
          </li>
          <li>
            <a class="slide-item" routerLink="/admin/reports/program-template">Program Template</a>
          </li>
        </ul>


      </li>
    </ng-container>


    </ul>
  </div>
</aside>
<!-- main-sidebar -->
