import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-transport-routes',
  templateUrl: './transport-routes.component.html',
  styleUrls: ['./transport-routes.component.scss']
})
export class TransportRoutesComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Transport Routes List';
  routes_list_loading: boolean = false;
  routes_list: RouteSettingInterface[] = [];
  route: RouteSettingInterface = {} as RouteSettingInterface;
  route_form: FormGroup = {} as FormGroup;
  route_id: string = "";
  save_loading: boolean = false;
  delete_loading: boolean = false;

  ngOnInit(): void {
    this.buildForm();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.getRoutesList();
  }

  async getRoutesList() {
    this.routes_list = [];
    this.routes_list_loading = true;
    /* this.routes_list.push(
      {
        _id: "123",
        route_name: "Sivakasi",
        amount: 2000,
        description: "College to Sivakasi",
        active: true,
      },
      {
        _id: "1233",
        route_name: "Tirutangal",
        amount: 3000,
        description: "College to Tirutangal",
        active: true,
      },
    ); */
    try {
      const service_response = await this.restService.listTransportRoutes();
      if (service_response && service_response.success) {
        this.routes_list = service_response.data;
        console.log(this.routes_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.routes_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.route) == '{}') {
      this.route = {
        _id: "",
        route_name: "",
        amount: 0,
        active: false,
        description: ""
      };
    }
    this.route_form = this.formBuilder.group({
      _id: [this.route._id],
      route_name: [this.route.route_name],
      amount: [this.route.amount, [Validators.required]],
      description: [this.route.description, [Validators.required]],
      active: [this.route.active],
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(route: any) {
    this.route_id = route._id; //term_name
    var settings = this.routes_list.find(x => x._id == route._id);
    if (settings) {
      console.log(settings);
      this.route = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.route_id = "";
    this.route = {} as RouteSettingInterface;
    this.route_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.route_form.value;
      console.log(form_data);
      this.save_loading = true;
      if (this.route_form.value._id) {
        const res = await this.restService.updateTransportRoute(this.route_form.value._id, form_data);
        if (!res) {
          alert('Failed to Update Route settings');
        }
        alert('Updated Route Settings Successfully');
      }
      else {
        const res = await this.restService.createTransportRoute(form_data);
        if (!res) {
          alert('Failed to Create Route settings');
        }
        alert('Created Route Settings Successfully');
      }
      this.getRoutesList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteTransportRoute(this.route_form.get('_id')!.value);
      if (res.success) {
        alert('Deleted Route Settings Successfully');
        this.getRoutesList();
      }
      else {
        alert('Failed to Delete Hostel Fee settings');
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }

  isInvalid(formControl: string): boolean {
    return this.route_form.touched && this.route_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.route_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }


}

interface RouteSettingInterface {
  _id: string,
  route_name: string
  amount: number,
  description: string,
  active: boolean,
}