<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- Container -->
    <div class="container-fluid">
        <!-- breadcrump -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Template Configuration</h2>
                <p class="mg-b-0"></p>
            </div>
            <div class="d-flex right-content mg-b-5" style="float: right;margin-right: 1%;">
                <button type="button" class="btn btn-primary btn-md" style="margin-right: 5%;"
                    (click)="onBackClicks()">Back</button>
                <button type="button" class="btn btn-dark btn-md" (click)="downloadPDF()">Print</button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <!-- <div class="card-header pb-0">
                    <div class="d-flex justify-content-between">
                        <h4 class="card-title mg-b-0">Programme Template</h4>
                    </div>
                    <p class="tx-12 tx-gray-500 mb-2">Manage Programme Template</p>
                </div> -->
                    <div class="card-body">
                        <div #content class="content">
                            <form [formGroup]="program_data" class="form-horizontal" id="print-table">
                                <div formArrayName="mapping_data">
                                    <div *ngFor="let template of program_list; let i=index;" [formGroupName]="i">
                                        <h6 class="modal-title" style="text-align: center;">
                                            <ng-container
                                                *ngIf="program_data.value.mapping_data[i].format === 'Group'; else notNA">
                                                Section
                                            </ng-container>
                                            <ng-template #notNA>
                                                Section{{i}}
                                            </ng-template>
                                        </h6>

                                        <div class="form-group">
                                            <p>{{ template.title }}</p>

                                        </div>
                                        <div class="form-group">
                                            <p>{{ template.description }}</p>
                                        </div>



                                        <ng-container *ngIf="program_data.value.mapping_data[i]?.format === 'List'">
                                            <div class="form-group">
                                                <p>{{ template.list_data.header }}</p>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="program_data.value.mapping_data[i]?.format === 'List'">
                                            <div class="form-group">
                                                <label
                                                    class="main-content-label tx-11 tx-medium tx-gray-600">Content</label>
                                                <textarea id="description" class="form-control"
                                                    [formControl]="getMappingDataControls(i)"
                                                    placeholder="Enter Content"></textarea>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="program_data.value.mapping_data[i]?.format === 'Table'">
                                            <div class="table-responsive" formArrayName="table_data">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <ng-container *ngFor="let columnName of tableHeaders">
                                                                <th>{{ columnName }}</th>
                                                            </ng-container>


                                                        </tr>
                                                        <tr>
                                                            <ng-container
                                                                *ngFor="let columnName of columnFormatArray; let j = index;"
                                                                [formGroupName]="j">

                                                                <th *ngIf="columnName === 'String'">

                                                                    <input type="text" class="form-control"
                                                                        placeholder="Enter String">
                                                                </th>


                                                                <th *ngIf="columnName === 'Number'">

                                                                    <input type="text" class="form-control"
                                                                        placeholder="Enter Number">
                                                                </th>


                                                                <th *ngIf="columnName === 'File'">

                                                                    <input (change)="uploadFile($event)" type="file"
                                                                        class="form-control">
                                                                </th>
                                                            </ng-container>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div class="text-align-right">
                            <button class="btn btn-primary mg-r-20" style="margin-top: 10%;"
                                (click)="updateMapping()">Update</button>
                            <!-- <button class="btn ripple btn-secondary" style="margin-top: 10%; margin-right: 33%;" (click)="onClosedClick()"type="button">Close</button> -->
                        </div>
                        {{ program_data.value | json }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>