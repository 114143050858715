import { Component, OnInit } from '@angular/core';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-student-hallticket',
  templateUrl: './student-hallticket.component.html',
  styleUrls: ['./student-hallticket.component.scss']
})
export class StudentHallticketComponent implements OnInit {
  roll_no: string = '';
  enrolled_year: string = '';
  hall_ticket_available: boolean = true;
  loader: boolean = false;
  constructor(private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.getRollNo();
  }

  async getRollNo() {
    try {
      this.loader = true;
      var date_range_data = {
        start_date: new Date('2023/09/01'),
        end_date: new Date('2023/09/01')
      };
      console.log(date_range_data);
      const response = await this.restService.getStudentTimetable(date_range_data);
      if (response && response.success) {
        console.log(response.data.roll_no);
        this.roll_no = response.data?.roll_no;
        this.enrolled_year = response.data?.enrolled_year;
        const urlToCheck = `https://ajoybalajik11.github.io/A_24/${this.enrolled_year}/${this.roll_no}.pdf`;
        this.checkURLAvailability(urlToCheck, isAvailable => {
          this.hall_ticket_available = isAvailable;
        });
        this.loader = false;
      }
    }
    catch (err) {
      this.loader = false;
      alert("Error while loading hall ticket. Contact COE");
    }
  }

  async checkURLAvailability(url, callback) {
    fetch(url, {
      method: 'HEAD', // Use 'HEAD' to retrieve only the headers, not the entire content

    })
      .then(response => {
        if (response.status == 200) {
          callback(true); // URL is available (status 200)
        } else {
          callback(false); // URL is not available
        }
      })
      .catch(() => {
        callback(false); // An error occurred, URL is not available
      });
  }






}
