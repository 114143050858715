import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { IAddress } from 'src/app/services/rest/entity/IAddress';
import { IAddressExtended } from 'src/app/services/rest/entity/IAddressExtended';
import { IStaffDepartmentMap } from 'src/app/services/rest/entity/IStaffDepartmentMap';
import { IStaffs } from 'src/app/services/rest/entity/IStaffs';

@Component({
  selector: 'app-view-staff-timetable',
  templateUrl: './view-staff-timetable.component.html',
  styleUrls: ['./view-staff-timetable.component.scss']
})
export class ViewStaffTimetableComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  department_type_list: any[] = [];
  department_type_list_loading = false;

  department_list: any[] = [];
  department_list_loading = false;

  department_search_form: any;

  staffs_list: any[] = [];
  staffs_list_loading = false;
  staff: any = {} as any;
  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];

  staff_timetable: {
    day: string,
    hours: HourInterface[]
  }[] = [];
  staff_timetable_loading: boolean = false;
  max_hour: number = 10;
  days: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  hour_header: {
    hour_name: string,
    hour_number: number,
    hour_time?: string
  }[] = [{ hour_name: "I", hour_number: 1 }, { hour_name: "II", hour_number: 2 }, { hour_name: "III", hour_number: 3 },
  { hour_name: "IV", hour_number: 4 }, { hour_name: "V", hour_number: 5 }, { hour_name: "VI", hour_number: 6 },
  { hour_name: "VII", hour_number: 7 }, { hour_name: "VIII", hour_number: 8 }, { hour_name: "IX", hour_number: 9 }, { hour_name: "X", hour_number: 10 }];
  all_programme_list: any[] = [];
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    this.getDepartmentTypeList();
    this.buildFilterForm();
    this.getAllProgrammeDataList();
  }

  ngOnInit(): void {
  }

  async getDepartmentTypeList(): Promise<void> {
    try {
      this.department_type_list_loading = true;
      this.department_type_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.department_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read department type');
    } finally {
      this.department_type_list_loading = false;
    }
  }

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.all_programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    }
  }


  buildFilterForm(): void {
    this.department_search_form = this.fb.group({
      department_category_id: [''],
      finance_type: [''],
      department_id: [''],
      gender: [''],
      roll_no: [''],
    });
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'department_category_id' || id === 'finance_type') {
      this.getDepartmentDataList();
    }

  }

  async getDepartmentDataList(): Promise<void> {
    try {
      this.department_list_loading = true;
      this.department_list = [];
      const search_form_query: any = {

      };
      if (this.department_search_form) {
        if (this.department_search_form.value.department_category_id) {
          search_form_query.department_category_id = this.department_search_form.value.department_category_id;
        }
        if (this.department_search_form.value.finance_type) {
          search_form_query.finance_type = this.department_search_form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.department_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    await this.searchFormDataList();

  }

  async searchFormDataList(): Promise<void> {
    try {
      let valid_search = false;
      if (!this.department_search_form.value.department_id) {
        alert('Please select department');
        return;
      }
      if (this.department_search_form.value.department_id) {
        valid_search = true;
      }
      if (this.department_search_form.value.department_category_id) {
        valid_search = true;
      }
      if (this.department_search_form.value.finance_type) {
        valid_search = true;
        if (!this.department_search_form.value.department_category_id) {
          alert('Please select department type');
          return;
        }
      }

      if (!valid_search) {
        alert('Select any one filter');
        return;
      }

      this.staffs_list_loading = true;
      this.staffs_list = [];
      const search_form_query: any = {
        department_id: this.department_search_form.value.department_id
      };
      if (this.department_search_form.value.gender) {
        search_form_query.gender = this.department_search_form.value.gender;
      }
      if (this.department_search_form.value.department_category_id) {
        search_form_query.department_category_id = this.department_search_form.value.department_category_id;
      }
      if (this.department_search_form.value.roll_no) {
        search_form_query.roll_no = this.department_search_form.value.roll_no;
      }
      if (this.department_search_form.value.finance_type) {
        search_form_query.finance_type = this.department_search_form.value.finance_type;
      }
      const service_response = await this.restService.searchStaff(search_form_query);
      if (service_response && service_response.success) {
        this.staffs_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.staffs_list_loading = false;
    }
  }

  async viewTimetable(staff) {
    this.staff = staff;
    this.max_hour = 10;
    this.staff_timetable = [];
    var staff_timetable: any[] = []; //table render object
    try {
      this.staff_timetable_loading = true;
      const timetable_data = await this.restService.getStaffTimeTableById(staff.roll_no);
      if (timetable_data && timetable_data.data.length > 0) {
        var day_wise_order = timetable_data.data[0]?.day_wise_order //overall staff timetable object
        day_wise_order[0].hours.forEach(hour => {
          if (this.hour_header.find(x => x.hour_number == hour.hour_number)) {
            this.hour_header.find(x => x.hour_number == hour.hour_number).hour_time = hour.start_time + "-" + hour.end_time;
          }
        });
        var day_hours: HourInterface[] = []; //hours in a single day
        day_wise_order.forEach(day => {
          day_hours = [];
          day.hours.forEach(hour => {
            if (day_hours.filter(x => x.hour_number == hour.hour_number).length > 0) //hour number already exists in the day
            {
              if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.filter(x => x.course_data.course_code == hour.course_data.course_code).length == 0) {
                //course code doesnt exist in that hour
                var hour_data: any = {};
                hour_data = {
                  course_data: hour.course_data,
                  room_data: hour.room_data,
                  programme_data: hour.programme_data
                };
                day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.push(hour_data);
              }
              if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.filter(x => x.course_data.course_code == hour.course_data.course_code).length > 0) {
                //same course code exists for that hour with virtual section
                if (hour.programme_data.hasOwnProperty('virtual_section')) {
                  if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.
                    filter(x => x.course_data.course_code == hour.course_data.course_code && x.programme_data.virtual_section ==
                      hour.programme_data.virtual_section).length == 0) {
                    var hour_data: any = {};
                    hour_data = {
                      course_data: hour.course_data,
                      room_data: hour.room_data,
                      programme_data: hour.programme_data
                    };
                    day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.push(hour_data);
                  }
                }
                else {
                  //same course code exists for that hour without virtual section
                  if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.
                    filter(x => x.course_data.course_code == hour.course_data.course_code && x.programme_data.section ==
                      hour.programme_data.section && x.programme_data.programme_id == hour.programme_data.programme_id).length == 0) {
                    var hour_data: any = {};
                    hour_data = {
                      course_data: hour.course_data,
                      room_data: hour.room_data,
                      programme_data: hour.programme_data
                    };
                    day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.push(hour_data);
                  }
                }
              }
            }
            else {
              var hour_data_arr: any[] = [];
              hour_data_arr.push({
                course_data: hour.course_data,
                room_data: hour.room_data,
                programme_data: hour.programme_data
              });
              day_hours.push({
                hour_number: hour.hour_number,
                hour_data: hour_data_arr
              });
            }
          });
          var day_obj = {
            day: day.day,
            hours: day_hours
          } //single day's timetable object
          staff_timetable.push(day_obj);
        });
        this.days.forEach(day => {
          if (staff_timetable.filter(x => x.day == day).length == 0) //day does not exist on staff object
          {
            this.staff_timetable.push({
              day: day, hours: []
            })
          }
          else {
            this.staff_timetable.push(staff_timetable.find(x => x.day == day));
          }
        })
        console.log(this.staff_timetable);
      }
      if (timetable_data.data.length == 0) {
        this.days.forEach(day => {
          this.staff_timetable.push({
            day: day, hours: []
          });
        });
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.staff_timetable_loading = false;
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  //Staff Table View Helpers
  isStaffHourExists(i: number, hour_number: number): boolean {
    if (this.staff_timetable[i].hours.filter(x => x.hour_number == hour_number).length > 0)
      return true;
    return false;
  }

  getStaffHourData(i: number, hour_number: number) {
    return this.staff_timetable[i].hours.find(x => x.hour_number == hour_number).hour_data
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  async onCloseClick(): Promise<void> {
    this.staff = {};
    JQueryHelper.closeModal('#modal-popup');
  }


}
interface HourInterface {
  hour_number: number,
  hour_data: {
    course_data: {
      course_code: string, course_name: string, part: string,
    },
    room_data: {
      room_no: string
    },
    programme_data: {
      programme_id: string,
      section: string,
      virtual_section?: string
    }
  }[]
}