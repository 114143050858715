import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-combo-bar',
  templateUrl: './combo-bar.component.html',
  styleUrls: ['./combo-bar.component.scss']
})
export class ComboBarComponent implements OnInit {
  name: string = "";
  email: string = "";
  picture: string = "";
  constructor(private restService: AdminservicesService, private router: Router) {

    const user = CookieStore.getUserInfo();
    this.name = user.name;
    this.email = user.email;
    this.picture = user.picture;
  }

  ngOnInit(): void {
  }

  logout() {
    CookieStore.clearBearerToken();
    //location.href = this.restService.getLoginUrl();
    this.router.navigate(['/portal/logout']);
  }
}
