<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Programme Fee Collection Report</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" (click)="onConfigureProgrammeFeeClick()" class="btn btn-info">Manage Programme
            Fee</button>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Programme Fee Collection Report</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2"></p>
          </div>

          <div class="card-body" *ngIf="programme_fee_patch_form">
            <form [formGroup]="programme_fee_patch_form">
              <div class="row row-sm">
                <ng-container *ngIf="!admission_report">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollement Year</label>
                      <select formControlName="enrolled_year" class="form-control"
                        (change)="onYearChange(programme_fee_patch_form)">
                        <option value="">Select</option>
                        <option
                          *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="year">{{year}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                      <select formControlName="programme_type_id" class="form-control"
                        (change)="onProgrammeTypeChange(programme_fee_patch_form)">
                        <option value="">Select</option>
                        <option
                          *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="programme_type.programme_type_id">{{programme_type.programme_type_id}}</option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onPrgFinanceTypeChange(programme_fee_patch_form)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>

                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select programme</label>
                                        <select formControlName="programme_id" class="form-control">
                                            <option value="">Select programme</option>
                                            <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type == 'self_finance'?' (SF)': ' (R)'}}</option>
                                        </select>
                                    </div>
                                </div> -->
                <ng-container *ngIf="!admission_report">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select semester</label>
                      <select formControlName="semester" class="form-control">
                        <option value="">Select semester</option>
                        <option
                          *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="semester.id">{{semester.text}}</option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select term</label>
                                        <select formControlName="term" class="form-control">
                                            <option value="">Select term</option>
                                            <option *ngFor="let term of commonEnums.getNumbers(5);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="term">{{commonEnums.rommon_letters[term]}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Target</label>
                                        <select formControlName="fee_target_type" class="form-control">
                                            <option value="">Select Target</option>
                                            <option value="REGULAR">Regular</option>
                                            <option value="SC">SC/ST/SCA/BC(PARAYAN/PALLAN)</option>
                                        </select>
                                    </div>
                                </div> -->
                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!fee_data_loading" (click)="onSearchClick()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="fee_data_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="row row-cards row-deck" *ngIf="fee_data">
            <div class="col-sm-12 col-lg-12">
              <div class="card">

                <div class="card-body">
                  <div class="row">
                    <div class="col text-center">
                      <label class="tx-12">Total Fee</label>
                      <p class="font-weight-bold tx-20">{{fee_data.total_fee|currency:'INR':'Rs. '}}</p>
                    </div><!-- col -->
                    <div class="col border-left text-center">
                      <label class="tx-12">Total Collected</label>
                      <p class="font-weight-bold tx-20">{{fee_data.total_collected|currency:'INR':'Rs. '}}</p>
                    </div><!-- col -->
                    <div class="col border-left text-center">
                      <label class="tx-12">Total Difference</label>
                      <p class="font-weight-bold tx-20">{{fee_data.total_dif|currency:'INR':'Rs. '}}</p>
                    </div><!-- col -->
                  </div><!-- row -->
                  <!-- <div class="progress ht-20 mt-4">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary ht-20 w-50">50%</div>
                                    </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th rowspan="2" class="verical-middle text-center">S.No</th>
                    <th rowspan="2" class="verical-middle text-center">Prg. Name</th>
                    <th rowspan="2" class="verical-middle text-center">Roll No</th>
                    <ng-container *ngIf="admission_report">
                      <th rowspan="2" class="verical-middle text-center">App. No.</th>
                      <th rowspan="2" class="verical-middle text-center">Status</th>
                    </ng-container>
                    <th rowspan="2" class="verical-middle text-center">Name</th>
                    <th rowspan="2" class="verical-middle text-center">Birth Date</th>
                    <th rowspan="2" class="verical-middle text-center">Mobile No.</th>
                    <th rowspan="2" class="verical-middle text-center">Total Fee</th>
                    <th rowspan="2" class="verical-middle text-center">Total Paid</th>
                    <th rowspan="2" class="verical-middle text-center">Paid</th>
                    <ng-container *ngIf="fee_data && fee_data.collection_list.length>0">
                      <ng-container
                        *ngFor="let fee_term_detail of fee_data.collection_list[0].fee_term_detail_list;let i=index;trackBy:fromDataResolver.identify;">
                        <th colspan="7" class="text-center">Term {{ commonEnums.rommon_letters[fee_term_detail.term]}}
                        </th>

                      </ng-container>
                    </ng-container>
                    <!-- <th style="width: 15%;">Action</th> -->
                  </tr>
                  <tr>

                    <ng-container *ngIf="fee_data && fee_data.collection_list.length>0">
                      <ng-container
                        *ngFor="let fee_term_detail of fee_data.collection_list[0].fee_term_detail_list;let i=index;trackBy:fromDataResolver.identify;">
                        <!-- <th>{{fee_term_detail.term}}</th> -->
                        <th>Paid Date</th>
                        <th>Fee Amount</th>
                        <th>Paid Amount</th>
                        <th>Paid</th>
                        <ng-container *ngIf="fee_term_detail.mode == 'offline'">
                          <th>Collected by</th>
                        </ng-container>
                        <th>Remarks</th>
                        <th>Payment Ref.</th>
                      </ng-container>
                    </ng-container>
                    <!-- <th style="width: 15%;">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="fee_data && fee_data.collection_list.length>0">


                    <ng-container
                      *ngFor="let fee of fee_data.collection_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          <ng-container *ngIf="fee.name">
                            {{i+1}}
                          </ng-container>
                        </td>
                        <td>{{fee.programme_name}}</td>
                        <td>{{fee.roll_no}}</td>
                        <ng-container *ngIf="admission_report">
                          <td class="uppercase">{{fee.application_no}}</td>
                          <td class="uppercase">{{fee.status}}</td>
                        </ng-container>
                        <td>{{fee.name}}</td>
                        <td>{{fee.birth_date|date:commonEnums.date_format}}</td>
                        <td>{{fee.mobile_numbers? fee.mobile_numbers.join(" "):''}}</td>
                        <td>{{fee.total_fee}}</td>
                        <td>{{fee.total_paid}}</td>
                        <td [ngClass]="{'table-danger': !fee.paid && fee.name  }">
                          <ng-container *ngIf="fee.name">
                            {{fee.paid?'Yes':'No'}}
                          </ng-container>
                        </td>

                        <ng-container *ngIf="fee.fee_term_detail_list.length>0">
                          <ng-container
                            *ngFor="let fee_term_detail of fee.fee_term_detail_list;let i=index;trackBy:fromDataResolver.identify;">

                            <td>{{fee_term_detail.paid_date|date:commonEnums.date_format}}</td>
                            <td>{{fee_term_detail.total_fee}}</td>
                            <td>{{fee_term_detail.amount}}</td>

                            <td [ngClass]="{'table-danger': !fee_term_detail.paid && fee.name  }">
                              <ng-container *ngIf="fee.name">
                                {{fee_term_detail.paid?'Yes':'No'}}
                              </ng-container>
                            </td>


                            <ng-container *ngIf=" fee_term_detail.mode == 'offline'">
                              <td>{{fee_term_detail.collected_by}}</td>
                            </ng-container>
                            <td>{{fee_term_detail.remarks}}</td>
                            <td>{{fee_term_detail.payment_ref}}</td>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container
                    *ngIf="!fee_data_loading && (!fee_data || (fee_data && fee_data.collection_list.length==0)) ">
                    <tr>
                      <th colspan="9" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="fee_data_loading && !fee_data ">
                    <tr>
                      <th colspan="9" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>


    </div>



  </div>
</div>

<!-- /main-content -->

<!-- Large Modal -->
<div class="modal" id="modal-programme-fee">
  <div class="modal-dialog modal-lg big-modal" role="document">
    <div class="modal-content modal-content-demo" *ngIf="programme_fee_patch_form_popup">
      <div class="modal-header">
        <h6 class="modal-title">Programme Fee Settings </h6>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <form [formGroup]="programme_fee_patch_form_popup">
            <div class="row row-sm">
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollement Year</label>
                  <select formControlName="regulation_year" class="form-control"
                    (change)="onYearChange(programme_fee_patch_form_popup)">
                    <option value="">Select</option>
                    <option
                      *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="year">{{year}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                  <select formControlName="programme_type_id" class="form-control"
                    (change)="onProgrammeTypeChange(programme_fee_patch_form_popup)">
                    <option value="">Select</option>
                    <option
                      *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="programme_type.programme_type_id">{{programme_type.programme_type_id}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                  <select formControlName="finance_type" class="form-control"
                    (change)="onPrgFinanceTypeChange(programme_fee_patch_form_popup)">
                    <option value="">Select Finance Type</option>
                    <option
                      *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="finance_type.id">{{finance_type.text}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select programme</label>
                  <select formControlName="programme_id" class="form-control">
                    <option value="">Select programme</option>
                    <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type ==
                      'self_finance'?' (SF)': ' (R)'}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select semester</label>
                  <select formControlName="semester" class="form-control">
                    <option value="">Select semester</option>
                    <option
                      *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="semester.id">{{semester.text}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select term</label>
                  <select formControlName="term" class="form-control">
                    <option value="">Select term</option>
                    <option
                      *ngFor="let term of commonEnums.getNumbers(5);let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="term">{{commonEnums.rommon_letters[term]}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Target</label>
                  <select formControlName="fee_target_type" class="form-control">
                    <option value="">Select Target</option>
                    <option value="REGULAR">Regular</option>
                    <option value="SC">SC/ST/SCA/BC(PARAYAN/PALLAN)</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">

                  <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                    *ngIf="!programme_fee_settings_loading" (click)="onSearchPrgFeeClick()">
                    Search
                  </button>
                  <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                    *ngIf="programme_fee_settings_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>




                </div>
              </div>

            </div>
          </form>
        </div>

        <div *ngIf="programme_fee_settings_form">
          <form [formGroup]="programme_fee_settings_form">
            <div class="row row-sm">

              <div class="col-lg-2">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select payment mode</label>
                  <select formControlName="mode" class="form-control">
                    <option value="">Select payment mode</option>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                  </select>
                </div>
              </div>
            </div>
          </form>

          <div class="table-responsive">
            <div class="form-group">
              <label class="main-content-label tx-11 tx-medium tx-gray-600">Specify payment split</label>
            </div>
            <table class="table table-bordered mg-b-0 text-md-nowrap">
              <thead>

                <tr>
                  <th class="text-center" style="width: 5%;">S.No</th>
                  <th class="text-center">Sub Account Id</th>
                  <th class="text-center">Account No.</th>
                  <th class="text-center" style="width: 20%;">Amount</th>
                </tr>
              </thead>

              <tbody>

                <ng-container
                  *ngFor="let account of fromDataResolver.getFormArrayControlls(programme_fee_settings_form,'accounts');let i=index;trackBy:fromDataResolver.identify;">
                  <ng-container [formGroup]="account">

                    <tr>
                      <td>
                        {{i+1}}
                      </td>
                      <td class="text-uppercase">
                        {{account.value.sub_acc_id}}
                      </td>
                      <td class="text-uppercase">
                        {{account.value.account_no}}
                      </td>
                      <td>
                        <input class="form-control" type="number" formControlName="amount">
                      </td>

                    </tr>
                  </ng-container>

                </ng-container>

                <tr>
                  <th colspan="3" class="text-align-right">Total</th>
                  <th class="text-align-right">{{getTotalAmount()}}</th>
                </tr>

                <ng-container *ngIf="programme_fee_settings_loading">
                  <tr>
                    <th colspan="4" class="text-center">Loading Please wait</th>
                  </tr>
                </ng-container>
              </tbody>



            </table>
          </div>
          <div class="mg-b-20"></div>
          <div class="text-align-right">
            <button class="btn ripple btn-primary" (click)="onSaveProgrammeFormClick()" type="button">Save</button>
            <button class="btn ripple btn-secondary mg-l-20" (click)="onProgrammeFeeFormCloseClick()"
              type="button">Close</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<!--End Large Modal -->

<app-footer></app-footer>
