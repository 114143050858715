<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Admission List : {{admission_name}}</h2>
                    <p class="mg-b-0">View Overall Admissions</p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">

            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">

                    <div class="card-body">
                        <div class="panel panel-primary tabs-style-1">
                            <div class=" tab-menu-heading">
                                <div class="tabs-menu1">
                                    <!-- Tabs -->
                                    <ul class="nav panel-tabs main-nav-line">
                                        <li class="nav-item"><a href="#tab1" class="nav-link active" (click)="onTab('tab1')" data-toggle="tab">Status Wise</a></li>
                                        <li class="nav-item"><a href="#tab2" class="nav-link" (click)="onTab('tab2')" data-toggle="tab">Enrolled Wise</a></li>
                                        <li class="nav-item"><a href="#tab3" class="nav-link" (click)="onTab('tab3')" data-toggle="tab">Community Wise</a></li>
                                        <li class="nav-item"><a href="#tab4" class="nav-link" (click)="onTab('tab4')" data-toggle="tab">Application Wise Collection</a></li>
                                        <li class="nav-item"><a href="#tab5" class="nav-link" (click)="onTab('tab5')" data-toggle="tab">Programme Wise Collection</a></li>

                                    </ul>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body main-content-body-right border-top-0 border">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tab1">

                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>

                                                        <th>Programme Name</th>
                                                        <ng-container *ngFor="let val of headers;let i=index;trackBy:fromDataResolver.identify;">
                                                            <th>{{val.text}}</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="admissions_list  && admissions_list.length>0">
                                                        <ng-container *ngFor="let val of admissions_list;let i=index;trackBy:fromDataResolver.identify;">
                                                            <tr>
                                                                <td>
                                                                    {{i+1}}
                                                                </td>
                                                                <td>
                                                                    {{val.programme_name}} {{val.finance_type=="regular"?"(R)":"(SF)"}}
                                                                </td>
                                                                <ng-container *ngFor="let app of val.applications;let i=index;trackBy:fromDataResolver.identify;">
                                                                    <td>{{app.count}}</td>
                                                                </ng-container>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="!admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="15" class="text-center">No Records found</th>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="15" class="text-center">Loading Please wait</th>
                                                        </tr>
                                                    </ng-container>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="tab-pane" id="tab2">
                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>

                                                        <th>Programme Name</th>
                                                        <th>Alloted Seats</th>
                                                        <th>Filled Seats</th>
                                                        <th>Available Seats</th>
                                                        <th>Male</th>
                                                        <th>Female</th>
                                                        <th>Transgender</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="admissions_list  && admissions_list.length>0">
                                                        <ng-container *ngFor="let val of admissions_list;let i=index;trackBy:fromDataResolver.identify;">
                                                            <tr>
                                                                <td>
                                                                    {{i+1}}
                                                                </td>
                                                                <td>
                                                                    {{val.programme_name}} {{val.finance_type=="regular"?"(R)":"(SF)"}}
                                                                </td>
                                                                <td>
                                                                    {{val.max_seats}}
                                                                </td>
                                                                <td>
                                                                    {{val.filled_seats}}
                                                                </td>
                                                                <td>
                                                                    {{val.available_seats}}
                                                                </td>
                                                                <td>
                                                                    {{val.total_male}}
                                                                </td>
                                                                <td>
                                                                    {{val.total_female}}
                                                                </td>
                                                                <td>
                                                                    {{val.total_transgender}}
                                                                </td>

                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="!admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="8" class="text-center">No Records found</th>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                                        </tr>
                                                    </ng-container>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab3">
                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th colspan=2></th>


                                                        <ng-container *ngFor="let val of community_headers;let i=index;trackBy:fromDataResolver.identify;">
                                                            <th colspan="4" class="text-center">{{val}}</th>
                                                        </ng-container>
                                                    </tr>
                                                    <tr>
                                                        <th>S.No</th>

                                                        <th>Programme Name</th>
                                                        <ng-container *ngFor="let val of community_headers;let i=index;trackBy:fromDataResolver.identify;">
                                                            <th>M</th>
                                                            <th>F</th>
                                                            <th>T</th>
                                                            <th>Total</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="admissions_list  && admissions_list.length>0">
                                                        <ng-container *ngFor="let val of admissions_list;let i=index;trackBy:fromDataResolver.identify;">
                                                            <tr>
                                                                <td>
                                                                    {{i+1}}
                                                                </td>
                                                                <td>
                                                                    {{val.programme_name}} {{val.finance_type=="regular"?"(R)":"(SF)"}}
                                                                </td>
                                                                <ng-container *ngFor="let app of val.community_wise_list;let i=index;trackBy:fromDataResolver.identify;">
                                                                    <td>{{app.total_male}}</td>
                                                                    <td>{{app.total_female}}</td>
                                                                    <td>{{app.total_transgender}}</td>
                                                                    <td>{{app.total}}</td>
                                                                </ng-container>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="!admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="34" class="text-center">No Records found</th>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="34" class="text-center">Loading Please wait</th>
                                                        </tr>
                                                    </ng-container>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tab4">
                                        <div class="text-right mg-b-20">
                                            <div class="pr-1 mb-3 mb-xl-0">
                                                <button type="button" class="btn btn-info" (click)="download('tab4')">Download</button>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>

                                                        <th>PRG. Name</th>
                                                        <th>App. No.</th>
                                                        <th>Name</th>
                                                        <th>Mobile No(s).</th>
                                                        <th>Birth Date</th>
                                                        <th>Email</th>
                                                        <th>Transaction Id</th>
                                                        <th>Amount</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="admissions_list  && admissions_list.length>0">
                                                        <ng-container *ngFor="let val of admissions_list;let i=index;trackBy:fromDataResolver.identify;">
                                                            <tr>
                                                                <td>
                                                                    {{i+1}}
                                                                </td>

                                                                <td>
                                                                    {{val.programme_name}} {{val.finance_type=="regular"?"(R)":"(SF)"}}
                                                                </td>
                                                                <td>
                                                                    {{val.application_no}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.given_name}} {{val.applicant.middle_name}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.mobile_numbers}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.birth_date_display}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.email}}
                                                                </td>
                                                                <td>
                                                                    {{val.transaction_ref_no}}
                                                                </td>
                                                                <td>
                                                                    {{val.amount}}
                                                                </td>
                                                                <td>
                                                                    {{val.initated_time | date:commonEnums.date_format}}
                                                                </td>

                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="!admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="10" class="text-center">No Records found</th>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="10" class="text-center">Loading Please wait</th>
                                                        </tr>
                                                    </ng-container>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="tab-pane" id="tab5">
                                        <div class="text-right mg-b-20">
                                            <div class="pr-1 mb-3 mb-xl-0">
                                                <button type="button" class="btn btn-info" (click)="download('tab5')">Download</button>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>

                                                        <th>PRG. Name</th>
                                                        <th>App. No.</th>
                                                        <th>Name</th>
                                                        <th>Mobile No(s).</th>
                                                        <th>Birth Date</th>
                                                        <th>Email</th>
                                                        <th>Transaction Id</th>
                                                        <th>Amount</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="admissions_list  && admissions_list.length>0">
                                                        <ng-container *ngFor="let val of admissions_list;let i=index;trackBy:fromDataResolver.identify;">
                                                            <tr>
                                                                <td>
                                                                    {{i+1}}
                                                                </td>

                                                                <td>
                                                                    {{val.programme_name}} {{val.finance_type=="regular"?"(R)":"(SF)"}}
                                                                </td>
                                                                <td>
                                                                    {{val.application_no}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.given_name}} {{val.applicant.middle_name}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.mobile_numbers}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.birth_date_display}}
                                                                </td>
                                                                <td>
                                                                    {{val.applicant.email}}
                                                                </td>
                                                                <td>
                                                                    {{val.transaction_ref_no}}
                                                                </td>
                                                                <td>
                                                                    {{val.amount}}
                                                                </td>
                                                                <td>
                                                                    {{val.initated_time | date:commonEnums.date_format}}
                                                                </td>

                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>

                                                    <ng-container *ngIf="!admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="10" class="text-center">No Records found</th>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="admissions_list_loading && admissions_list.length==0">
                                                        <tr>
                                                            <th colspan="10" class="text-center">Loading Please wait</th>
                                                        </tr>
                                                    </ng-container>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>






    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>