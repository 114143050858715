<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Course Setup</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <!-- <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info mg-r-10" (click)="openFileDialog()">Bulk Upload
                        Syllabus as
                        Word
                        File</button>
                    <input type="file" accept=".docx" (change)="bulkUploadWordFile($event)" #fileImportInput
                        id="file-upload" placeholder="Bulk Upload Syllabus as Word File" hidden>
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Course</button>
                </div>
            </div> -->
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Courses</h4>
                            <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Add CO-PO Mapping, Courseware for Courses</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="offered_by_programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select regulation
                                            year</label>
                                        <select formControlName="revision_year" class="form-control">
                                            <option value="">Select regulation year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" formControlName="course_code" class="form-control"
                                            placeholder="Search with Course Code">
                                    </div>
                                </div>


                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="offered_by_finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>




                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="offered_by_programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>

                                        <th>Semester</th>
                                        <th>Part</th>
                                        <th>Course Type</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let course of course_data_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td scope="row">
                                                {{i+1}}
                                            </td>
                                            <td class="text-uppercase">{{ course.semester}}</td>
                                            <td class="text-uppercase">{{course.part}}</td>
                                            <td class="text-uppercase">{{course.course_type}}</td>
                                            <td class="text-uppercase">{{course.course_code}}</td>
                                            <td class="text-uppercase">{{course.course_name}}</td>
                                            <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                    <button type="button" class="btn btn-sm btn-info mr-2"
                                                        title="Manage CO PO mappings" (click)="onCOPOMapClick(course)">
                                                        <fa-icon [icon]="fonts.faChartLine"></fa-icon>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-info mr-2"
                                                        title="Manage Coursewares" (click)="onCoursewareClick(course)">
                                                        <fa-icon [icon]="fonts.faBookmark"></fa-icon>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-info mr-2"
                                                        title="Advanced Setting" (click)="onSettingClick(course)">
                                                        <fa-icon [icon]="fonts.faCog"></fa-icon>
                                                    </button>

                                                </div>
                                            </td>

                                        </tr>
                                    </ng-container>





                                    <ng-container *ngIf="!course_list_loading && course_data_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_data_list.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-copomap">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="course_co_po_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage CO PO Map</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="d-flex my-xl-auto right-content" style="justify-content: right;">
                                <div class="pr-1 mb-3 mb-xl-0">
                                    <!-- <button type="button" class="btn btn-info" (click)="uploadCOPOExcel()">Upload as
                                        Excel File</button> -->
                                    <app-modal [render_data]="bulk_render_data" (csvData)="bulkUpload($event)">
                                    </app-modal>
                                </div>
                            </div>
                            <form [formGroup]="course_co_po_form">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Copy Paste from MS
                                        Word or MS Excel</label>
                                    <textarea class="form-control" style="height: 200px;"
                                        formControlName="bulk_update"></textarea>
                                </div>

                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20"
                                        (click)="onExcelToJavasciptClick()">Convert</button>
                                </div>
                                <div class="mg-b-20"></div>
                                <div class="text-center" style="font-size: medium; font-weight: bold;">OR</div>
                                <div class="mg-b-20"></div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Fill CO PO Map</label>

                                </div>
                                <div class="row">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <ng-container
                                                        *ngFor="let po of  po_total;let j=index;trackBy:fromDataResolver.identify;">
                                                        <th>PO {{j+1}}</th>
                                                    </ng-container>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let co of fromDataResolver.getFormArrayControlls(course_co_po_form,'co_po_map');let i=index;trackBy:fromDataResolver.identify;">
                                                    <tr>
                                                        <th scope="row">CO
                                                            {{fromDataResolver.getValueFromFromGroup(co,"co_id")}}</th>


                                                        <ng-container
                                                            *ngFor="let po of fromDataResolver.getFormArrayControlls(co,'do_data');let j=index;trackBy:fromDataResolver.identify;">

                                                            <ng-container [formGroup]="po">
                                                                <td>
                                                                    <div class="form-group">
                                                                        <input class="form-control" required=""
                                                                            type="text" formControlName="score"
                                                                            (blur)="valueChanged($event)">
                                                                    </div>
                                                                </td>


                                                            </ng-container>
                                                        </ng-container>



                                                    </tr>
                                                </ng-container>
                                                <tr>
                                                    <td class="font-bold">Weightage</td>
                                                    <ng-container
                                                        *ngFor="let po of  po_total;let j=index;trackBy:fromDataResolver.identify;">
                                                        <td>{{po.score}}</td>
                                                    </ng-container>


                                                </tr>
                                                <tr *ngIf="this.course">
                                                    <td class="font-bold">Weighted Percentage of Course Contribution to
                                                        POs</td>
                                                    <ng-container
                                                        *ngFor="let co of this.course.co_po_map_contribution;let j=index;trackBy:fromDataResolver.identify;">
                                                        <td>{{co.contribution}}</td>
                                                    </ng-container>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onCOPOSaveClick()"
                                        *ngIf="!course_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="course_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <button class="btn ripple btn-secondary" (click)="onCOPOCloseClick()"
                                        type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Large Modal -->
        <div class="modal" id="modal-courseware">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="courseware_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Courseware</h6>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex my-xl-auto right-content" style="justify-content: right;">
                            <div class="pr-1 mb-3 mb-xl-0">
                                <button type="button" class="btn btn-info" (click)="uploadSyllabusWordFile()">Upload as
                                    Word File</button>
                            </div>
                        </div>

                        <div class="">

                            <form [formGroup]="courseware_form">
                                <div class="main-content-label mg-b-5">
                                    COURSE OUTCOMES
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;"
                                        formControlName="course_outcome"></textarea>
                                </div>

                                <ng-container *ngIf="course && course.course_type == 'PRACTICAL'">
                                    <div class="mg-b-20"></div>
                                    <div class="main-content-label mg-b-5">
                                        List of Practicals
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" style="height: 350px;"
                                            formControlName="practicals"></textarea>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="course && course.course_type != 'PRACTICAL'">
                                    <div class="mg-b-20"></div>
                                    <div class="main-content-label mg-b-5">
                                        Units and Topics
                                    </div>
                                    <div class="row">
                                        <div class="table-responsive">
                                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Unit Name</th>
                                                        <th>Content</th>
                                                        <th>No. of Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let co of fromDataResolver.getFormArrayControlls(courseware_form,'course_units');let i=index;trackBy:fromDataResolver.identify;">
                                                        <ng-container [formGroup]="co">
                                                            <tr>
                                                                <th scope="row">Unit {{i+1}}</th>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <input class="form-control" required=""
                                                                            type="text" formControlName="title">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <textarea class="form-control"
                                                                            formControlName="course_topics_string"></textarea>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control"
                                                                            required="" formControlName="no_of_hours">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Tutorials
                                </div>
                                <div class="row">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th style="width: 5%;">S.No</th>
                                                    <!-- <th>Title</th> -->
                                                    <th>Activity</th>
                                                    <th>Unit Covered</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let co of fromDataResolver.getFormArrayControlls(courseware_form,'tutorials');let i=index;trackBy:fromDataResolver.identify;">
                                                    <ng-container [formGroup]="co">
                                                        <tr>
                                                            <th scope="row">{{i+1}}</th>
                                                            <!-- <td>
                                                                <div class="form-group">
                                                                    <input class="form-control" required="" type="text" formControlName="topic">
                                                                </div>
                                                            </td> -->
                                                            <td>
                                                                <div class="form-group">
                                                                    <input class="form-control" required="" type="text"
                                                                        formControlName="activity">
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="form-group">
                                                                    <input class="form-control" required="" type="text"
                                                                        formControlName="unit_covered">
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </ng-container>
                                                </ng-container>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Text Books
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;"
                                        formControlName="text_book"></textarea>
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Reference Books
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;"
                                        formControlName="reference_book"></textarea>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="main-content-label mg-b-5">
                                    Web References
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" style="height: 150px;"
                                        formControlName="web_reference"></textarea>
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onCoursewareSaveClick()"
                                        *ngIf="!course_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="course_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <button class="btn ripple btn-secondary" (click)="onCoursewareCloseClick()"
                                        type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Setting Large Modal -->
        <div class="modal" id="modal-setting">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="course_setting_form && course">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Course Advanced Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="course_setting_form">
                                <div class="row mg-b-10">
                                    <div class="col-md-6">
                                        Course Code: <b>{{course.course_code}}</b>
                                    </div>
                                    <div class="col-md-6">
                                        Course Name: <b>{{course.course_name}}</b>
                                    </div>
                                </div>
                                <div class="row mg-b-10">
                                    <div class="col-md-6">
                                        Part: <b>{{course.part}}</b>
                                    </div>
                                    <div class="col-md-6">
                                        Course Type: <b>{{(course.course_type=='')?'--':course.course_type}}</b>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Enrollment Year</label>
                                    <select formControlName="enrolled_year" class="form-control"
                                        (change)="settingTypeChanged($event)">
                                        <option value="">Select Enrollment Year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="year">{{year}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('enrolled_year')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Enrolled Year is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Semester</label>
                                    <select formControlName="semester" class="form-control"
                                        (change)="settingTypeChanged($event)">
                                        <option value="">Select Semester</option>
                                        <option
                                            *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                            [ngValue]="semester.id">{{semester.text}}</option>
                                    </select>
                                    <div *ngIf="isInvalid('semester')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required"> Semester is required.</li>
                                        </ul>
                                    </div>
                                </div> -->

                                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">How do you want to
                                        setup the course settings?</label> <br>
                                    <input type="radio" name="setting" formControlName="setting" value="default"
                                        class="mg-r-5" (change)="settingTypeChanged($event)"> <label class="mg-r-15">Set
                                        Default Settings</label>
                                    <input type="radio" name="setting" formControlName="setting" value="previous_year"
                                        class="mg-r-5" (change)="settingTypeChanged($event)"><label class="mg-r-15"> Set
                                        Previous year's
                                        Settings</label>
                                    <input type="radio" name="setting" formControlName="setting" value="manual"
                                        class="mg-r-5" (change)="settingTypeChanged($event)"> <label class="mg-r-15">Set
                                        Manually</label>
                                </div> -->
                                <ng-container *ngIf="course_setting_form.value.enrolled_year!='' && settings_loading">
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <b>Loading...</b>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="course_setting_form.value.enrolled_year!=''">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Staff
                                            Incharge Type
                                        </label>
                                        <select formControlName="incharge_type" class="form-control">
                                            <option value="">Choose Incharge Type</option>
                                            <option *ngFor="let incharge_type of this.incharge_types;
                                                let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="incharge_type.key">
                                                {{incharge_type.value}}
                                            </option>
                                        </select>
                                        <div *ngIf="isInvalid('incharge_type')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required"> Incharge Type is required.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Student
                                            On-Boarding Type
                                        </label>
                                        <select formControlName="student_on_board_type" class="form-control">
                                            <option value="">Choose Student On-Boarding Type</option>
                                            <option *ngFor="let type of this.student_on_board_types;
                                                let i=index;trackBy:fromDataResolver.identify;" [value]="type.key">
                                                {{type.value}}
                                            </option>
                                        </select>
                                        <div *ngIf="isInvalid('student_on_board_type')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required"> Student On-Boarding Type is required.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Staff
                                            On-Boarding Type
                                        </label>
                                        <select formControlName="staff_on_bording_type" class="form-control">
                                            <option value="">Choose Staff On-Boarding Type</option>
                                            <option *ngFor="let type of this.staff_on_board_types;
                                                let i=index;trackBy:fromDataResolver.identify;" [value]="type.key">
                                                {{type.value}}
                                            </option>
                                        </select>
                                        <div *ngIf="isInvalid('staff_on_bording_type')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required"> Staff On-Boarding Type is required.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Student
                                            Sitting Arrangement Type
                                        </label>
                                        <select formControlName="student_sitting_arragement_type" class="form-control"
                                            (change)="arrangementTypeChange($event)">
                                            <option value="">Choose Sitting Arrangement Type</option>
                                            <option *ngFor="let type of this.student_sitting_arrangement_types;
                                                let i=index;trackBy:fromDataResolver.identify;" [value]="type.key">
                                                {{type.value}}
                                            </option>
                                        </select>
                                        <div *ngIf="isInvalid('student_sitting_arragement_type')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required"> Student Sitting Arrangement Type is
                                                    required.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <ng-container
                                        *ngIf="course_setting_form.value.student_sitting_arragement_type=='BASED_ON_VIRTUAL_SECTION'">
                                        <div class="table-responsive" formArrayName="virtual_sections">
                                            <table class="table table-bordered">
                                                <tr>
                                                    <th>S.No </th>
                                                    <th>Section Name</th>
                                                    <th style="width: 30%;">Action</th>
                                                </tr>
                                                <ng-container *ngFor="let section of sectionList; let i=index;"
                                                    [formGroupName]="i">
                                                    <tr>
                                                        <td>
                                                            {{i+1}}
                                                        </td>
                                                        <td>
                                                            <!-- {{section.value.section_name}} -->
                                                            <input type="text" class="form-control"
                                                                formControlName="section_id"
                                                                placeholder="Enter Virtual Section Name">
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-info mg-r-10"
                                                                (click)="viewAllowedProgrammes(section)"
                                                                *ngIf="!section.value.is_expanded">
                                                                View Allowed Programmes
                                                            </button>
                                                            <button type="button" class="btn btn-info mg-r-10"
                                                                (click)="closeAllowedProgrammes(section)"
                                                                *ngIf="section.value.is_expanded">
                                                                Close Allowed Programmes
                                                            </button>
                                                            <button type="button" class="btn btn-danger"
                                                                (click)="deleteSection(i)">
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <ng-container *ngIf="section.value.is_expanded"
                                                        formArrayName="allowed_programmes">
                                                        <tr>
                                                            <td colspan="3">
                                                                <table class="table" style="width: 100%;">
                                                                    <thead>
                                                                        <th>S.No</th>
                                                                        <th>Programme Name</th>
                                                                        <th>Section</th>
                                                                        <th>Delete</th>
                                                                    </thead>
                                                                    <ng-container
                                                                        *ngFor="let allowed_programme of getAllowedProgrammes(i);let j=index">
                                                                        <tr [formGroupName]="j">
                                                                            <td>{{j+1}}</td>
                                                                            <td>
                                                                                <select formControlName="programme_id"
                                                                                    class="form-control">
                                                                                    <option value="">All Programmes
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let programme of programme_list;let k=index;trackBy:fromDataResolver.identify;"
                                                                                        [value]="programme.programme_id">
                                                                                        {{programme.programme_name}}
                                                                                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <select formControlName="section_id"
                                                                                    class="form-control"
                                                                                    (change)="isSectionSelected($event,i,j)">
                                                                                    <option value="">Choose Section
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let section of getSections(i,j);let l=index;trackBy:fromDataResolver.identify;"
                                                                                        [value]="section.section_name">
                                                                                        {{section.section_name}}
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button"
                                                                                    class="btn btn-danger"
                                                                                    (click)="deleteAllowedProgramme(i,j)">
                                                                                    Delete
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    <tr>
                                                                        <td colspan="4" class="text-center">
                                                                            <button type="button"
                                                                                class="btn btn-primary"
                                                                                (click)="addAllowedProgramme(i)">
                                                                                Add New Programme
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                                <tr>
                                                    <td colspan="3" class="text-center">
                                                        <button type="button" class="btn btn-secondary"
                                                            (click)="addSection()">
                                                            Add New Section
                                                        </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                    </ng-container>

                                    <div class="form-group">
                                        <input type="checkbox" formControlName="exclude_from_polling"
                                            class="mg-r-5">Exclude this course from Polling?
                                        <div *ngIf="isInvalid('exclude_from_polling')">
                                            <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                <li class="parsley-required">Choose if this course should be excluded
                                                    from polling or
                                                    not.</li>
                                            </ul>
                                        </div>
                                    </div>

                                </ng-container>





                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSettingSaveClick()"
                                        [disabled]="canDisable()" *ngIf="!setting_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="setting_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Saving...
                                    </button>
                                    <button class="btn ripple btn-secondary" (click)="onSettingCloseClick()"
                                        type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>