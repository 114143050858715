import { Room } from './../../../room-model';
import { Floor } from './../../../floor-model';
import { Incharge } from './../../../incharge-model';
import { NavigationService } from './../../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../../components/notification/notification.service';
import { DateHelper } from './../../../../../../services/helpers/DateHelper';
import { Hostel, HostelBody } from './../../../hostelModel';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from './../../../../../../services/rest/adminservices.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-manage-incharges',
  templateUrl: './manage-incharges.component.html',
  styleUrls: ['./manage-incharges.component.scss']
})
export class ManageInchargesComponent implements OnInit {
  hostel: Hostel = {} as Hostel;
  id: string = '';
  floorId: string = '';
  roomId: string = '';
  inchargeId: string = '';
  title: string = 'Create Incharges';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  inchargeForm: FormGroup;
  inchargeTypeList: string[] = [
    'WARDEN', 'SUBWARDEN', 'DEPUTYWARDEN'
  ];

  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.inchargeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: [''],
      from: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      to: [DateHelper.convertToControlDate(new Date()), [Validators.required]]
    });
  }

  getTittle(): string {
    return this.title;
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  getIncharge(): Incharge {
    let incharge: Incharge = {} as Incharge;
    const floor: Floor = this.hostel.floors?.filter(myfloor => myfloor.id === this.floorId)[0] || {} as Floor;
    if (this.roomId && this.floorId) {
      const room: Room = floor.rooms.filter(myroom => myroom.id === this.roomId)[0];
      incharge = room.incharges?.filter(myincharge => myincharge.id === this.inchargeId)[0] || {} as Incharge;
    } else if (this.floorId) {
      incharge = floor?.incharges?.filter(myincharge => myincharge.id === this.inchargeId)[0] || {} as Incharge;
    } else if (this.inchargeId) {
      incharge = this.hostel.incharges?.filter(inchargeData => inchargeData.id === this.inchargeId)[0] || {} as Incharge;
    }
    return incharge;
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.route.queryParams.subscribe(params => {
      this.id = params?.hostelId;
      this.inchargeId = params?.inchargeId;
      this.floorId = params?.floorId;
      this.roomId = params?.roomId;
      if (this.inchargeId) {
        this.title = 'Edit Incharge';
      }
      this.navigationService.updateNavigation(this.title, this.router.url);
      this.restService.hostelManagementService.getHostels(this.id).subscribe(hostels => {
        this.hostel = hostels[0];
        const incharge = this.getIncharge();
        if (incharge && Object.keys(incharge).length !== 0) {
          this.inchargeForm = this.formBuilder.group({
            name: incharge.name,
            type: incharge.type,
            from: DateHelper.convertToControlDate(new Date(incharge.from)),
            to: DateHelper.convertToControlDate(new Date(incharge.to))
          });
        }
      }, this.errorHandler);
    });
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  canDisable(): boolean {
    const type = this.inchargeForm.value.type;
    if (type) {
      if (!this.inchargeTypeList.find(value => value === type)) {
        return true;
      }
    } else {
      return true;
    }
    let startDate = this.inchargeForm.value.from;
    let endDate = this.inchargeForm.value.to;
    if (startDate) {
      startDate = Date.parse(startDate);
    }
    if (endDate) {
      endDate = Date.parse(endDate);
    }
    if (!startDate || !endDate || endDate < startDate) {
      return true;
    }
    return this.inchargeForm.status !== 'VALID';
  }

  getFormData(): any {
    return this.inchargeForm.value;
  }

  isInvalid(formControl: string): boolean {
    return this.inchargeForm.touched && this.inchargeForm.get(formControl)?.errors !== null;
  }

  getInchargeFromForm(): Incharge {
    const incharge = this.getFormData();
    return {
      id: this.inchargeId,
      name: incharge.name,
      type: incharge.type,
      from: DateHelper.convertToControlDate(incharge.from),
      to: DateHelper.convertToControlDate(incharge.to)
    };
  }

  getHostelBody(): HostelBody {
    const hostelBody: HostelBody = {
      data: {
        incharges: [],
        floors: [
          {
            id: this.floorId,
            hostel_id: this.id,
            incharges: [],
            rooms: [
              {
                id: this.roomId,
                floor_id: this.floorId,
                incharges: []
              }
            ]
          }
        ]
      }
    };

    if (this.floorId && this.roomId && hostelBody.data.floors) {
      hostelBody?.data?.floors[0]?.rooms[0]?.incharges?.push(this.getInchargeFromForm());
    } else if (this.floorId && hostelBody.data.floors) {
      hostelBody.data.floors[0].rooms = [];
      hostelBody?.data?.floors[0]?.incharges?.push(this.getInchargeFromForm());
    } else {
      hostelBody.data.floors = [];
      hostelBody?.data?.incharges?.push(this.getInchargeFromForm());
    }

    return hostelBody;
  }

  onSave(): void {
    this.restService.hostelManagementService.updateHostel(this.id, this.getHostelBody()).subscribe(data => {
      console.log(data);
      this.notificationService.setNotifcation({ message: 'Saved incharges successfully', type: 'Success' });
      this.onClose();
    }, err => {
      console.error('Error saving ', err);
      this.notificationService.setNotifcation({ message: 'Failed to save incharges', type: 'Error' });
    });
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {

  }
}
