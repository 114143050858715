import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { IStudentCountSearch } from '../../students/StudentCountSearch'
import { IStudentCountSearchResponse } from '../../students/StudentCountSearchResponse'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-students-count',
  templateUrl: './students-count.component.html',
  styleUrls: ['./students-count.component.scss']
})
export class StudentsCountComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  report_search_form: FormGroup;
  report_list_loading: boolean = false;

  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  category_list_loading = false;
  parts: any[] = [];
  students_count_report: any[] = [];
  programme_filter_list: { programme_id: string, programme_display_name: string }[] = []
  dropdownSettings: IDropdownSettings = {};
  programmeDropdownSettings: IDropdownSettings = {};
  constructor(private restService: AdminservicesService, private fb: FormBuilder, private http: HttpClient, private router: Router) {
    this.report_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_ids: [''],
      enrolled_years: [''],
      gender: [''],
      community_ids: ['']
    });
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
    this.programmeDropdownSettings = {
      singleSelection: false,
      idField: 'programme_id',
      textField: 'programme_display_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.getProgrammeDataList(this.report_search_form);
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.report_search_form.get('programme_ids').setValue("");
      this.getProgrammeDataList(this.report_search_form);
      if (id === 'programme_type_id' && this.report_search_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.report_search_form.value.programme_type_id);
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = []; this.programme_filter_list = [];
      var temp = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
        this.programme_list.forEach(programme => {
          temp.push({
            programme_id: programme.programme_id,
            programme_display_name: (programme.programme_name + " " + (programme.finance_type == "regular" ? "(R)" : "(SF)"))
          })
        });
        setTimeout(() => {
          this.programme_filter_list = temp;
        }, 1000)
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async getReport() {
    const search_data: any = {};
    if (this.report_search_form.value.programme_type_id != "") {
      search_data.programme_type_id = this.report_search_form.value.programme_type_id
    }
    if (this.report_search_form.value.finance_type != "") {
      search_data.finance_type = this.report_search_form.value.finance_type
    }
    if (this.report_search_form.value.programme_ids.length > 0) {
      search_data.programme_ids = [];
      this.report_search_form.value.programme_ids.forEach(programme => {
        search_data.programme_ids.push(programme.programme_id);
      })
    }
    if (this.report_search_form.value.enrolled_years.length > 0) {
      search_data.enrolled_years = this.report_search_form.value.enrolled_years
    } else {
      alert("Please select enrollment year")
      return;
    }
    if (this.report_search_form.value.community_ids.length > 0) {
      search_data.community_ids = this.report_search_form.value.community_ids
    }
    if (this.report_search_form.value.gender != "") {
      search_data.gender = this.report_search_form.value.gender
    }
    console.log(search_data);
    const service_response = await this.restService.getStudentCount(search_data);
    if (service_response.success) {
      this.students_count_report = service_response.data;
    }
  }

  downloadCSV() {

    const data = [];
    let i = 1;


    for (const students_count of this.students_count_report) {
      for (const enrolled_year_list of students_count.enrolled_year_list) {
        for (const gender_list of enrolled_year_list.gender_list) {
          for (const community_list of gender_list.community_list) {
            const s = {
              "S.NO": i,
              "CLASS": students_count.programme_name + ' ' + (students_count.finance_type === 'regular' ? '(R)' : '(SF)'),
              "YEAR": enrolled_year_list.enrolled_year,
              "GENDER": gender_list.gender.toUpperCase(),
              "COMMUNITY": community_list.community_id,
              "COUNT": community_list.count
            }
            data.push(s)
            i++;
          }
        }
      }
    }
    CSVHelper.downloadCSV(data, 'community-wise-list-as-of-' + DateHelper.convertToControlDate(new Date()));
  }

  getProgrammeRowSpanVal(programme_type: IStudentCountSearchResponse) {
    var rowspan = 0;
    programme_type.finance_type_list.forEach(programme => {
      rowspan += programme.programme_list.length
    })
    return rowspan;
  }

  getFinanceTypeRowSpanVal(programme_type: IStudentCountSearchResponse) {
    var rowspan = 0;
    programme_type.finance_type_list.forEach(programme => {
      rowspan += programme.programme_list.length
    })
    return rowspan;
  }


}
