<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Assign Elective Courses to Students</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Assign Elective Course</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Assign elective courses to students not participated in
                            polling</p>
                    </div>

                    <div class="card-body" *ngIf="search_form">
                        <form [formGroup]="search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programmes</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2"
                                    *ngIf="this.programme_section_list && this.programme_section_list.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section_id" class="form-control"
                                            (change)="onFilterSelectChange('section_id',$event)">
                                            <option value="">All Section</option>
                                            <option
                                                *ngFor="let programme_section of programme_section_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_section._id">{{programme_section.section_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year</label>
                                        <select formControlName="enrolled_year" class="form-control"
                                            (change)="onFilterSelectChange('enrolled_year',$event)">
                                            <option value="">Choose Enrollment Year</option>
                                            <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20)"
                                                [ngValue]="enrolled_year">
                                                {{enrolled_year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!search_btn_loading" (click)="getStudentList()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="search_btn_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width:10%">S.No</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Class</th>
                                        <th style="width: 20%;">Action</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let student of unselected_student_list ;let i=index">
                                        <td scope="row">
                                            {{i+1}}
                                        </td>
                                        <td>{{student.roll_no}}</td>
                                        <td>{{student.name}}</td>
                                        <td>{{student.class}}</td>

                                        <td>
                                            <div class="pr-1 mb-3 mb-xl-0">
                                                <button type="button" (click)="assignCourse(student.roll_no)"
                                                    class="btn btn-sm btn-info mr-2">
                                                    <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="student_list_loading && unselected_student_list?.length==0">
                                        <tr>
                                            <td colspan="5" class="text-center">
                                                <b>Loading...</b>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!student_list_loading && unselected_student_list?.length==0">
                                        <tr>
                                            <td colspan="5" class="text-center">
                                                <b>No Records Found</b>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>



    <!--New Course Apply Modal-->
    <div class="modal" id="assign-modal-popup">
        <div class="modal-dialog big-modal" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-header">
                    <h6 class="modal-title">Assign Course to Student</h6>
                </div>
                <div class="card-body">
                    <h5>Assigning Student Roll No: {{assign_roll_no}}</h5>
                    <div class="table-responsive">
                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                            <thead>
                                <tr>
                                    <th style="width:10%">S.No</th>
                                    <th>Course Code</th>
                                    <th>Course Name</th>
                                    <th>Offering Department</th>
                                    <th style="width: 20%;">Assign</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let course of available_elective_courses_list ;let i=index">
                                    <td scope="row">
                                        {{i+1}}
                                    </td>
                                    <td>{{course.course_code}}</td>
                                    <td>{{course.course_name}}</td>
                                    <td>{{course.offering_programme_name}}</td>
                                    <td>
                                        <input type="radio" (click)="assignCourseToStudent($event)"
                                            name="assign_course_code" [value]="course.course_code" class="mg-r-10"
                                            [(ngModel)]="assign_course_code">Assign
                                    </td>
                                </tr>
                                <ng-container *ngIf="student_list_loading && unselected_student_list?.length==0">
                                    <tr>
                                        <td colspan="5" class="text-center">
                                            <b>Loading...</b>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="!student_list_loading && unselected_student_list?.length==0">
                                    <tr>
                                        <td colspan="5" class="text-center">
                                            <b>No Records Found</b>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <div class="mg-b-20"></div>
                    <div class="text-align-right">
                        <button type="button" class="btn btn-success mr-2" title="Save" *ngIf="!assign_save_loading"
                            (click)="saveAssignClick()">
                            Save
                        </button>
                        <button class="btn btn-success mr-2" type="button" disabled *ngIf="assign_save_loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                    </div>
                </div>



            </div>
        </div>
    </div>

</div>
<!-- /main-content -->
<app-footer></app-footer>