//Exam Fees Setting Component
import { Component, OnInit } from '@angular/core';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-fee-setting',
  templateUrl: './fee-setting.component.html',
  styleUrls: ['./fee-setting.component.scss']
})
export class FeeSettingComponent implements OnInit {

  //default variables
  commonEnums = CommonEnums;
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  title = 'Exam Fees Settings (Course Type - Fee Mapping)';

  //modal data generator variables
  course_types:
    {
      id: string, display_text: string
    }[] = [
      { id: "THEORY", display_text: "Theory" }, { id: "PRACTICAL", display_text: "Practical" },
      { id: "COMPREHENSION_VIVA", display_text: "Comprehension & Viva Voce" }, { id: "PROJECT_VIVA", display_text: "Project & Viva Voce" },
      { id: "EXTRA_OPTIONAL_CREDIT_COURSE", display_text: "Extra Optional Credit Paper" }
    ];
  no_of_hours: number[] = [2, 3, 4, 5, 6, 9];
  appearance_types: string[] = ["normal", "arrear", "repeat"];

  //setting list variables
  fee_setting_form: FormGroup = {} as FormGroup;
  setting: FeeSettingInterface = {} as FeeSettingInterface;
  settings_list_loading: boolean = false;
  settings_list: FeeSettingInterface[] = [];
  setting_id: string = "";
  programme_type_id: string = "";

  //Enabled dept variables; not used for now
  department_list: any[] = [];
  department_list_loading: boolean = false;
  years: string[] = ["I", "II", "III", "IV", "V"];
  max_year: number = 3;


  ngOnInit(): void {
    this.getFeesSettingsList();
    this.getProgrammeDataList();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.programme_type_id = params.programme_type_id;
      this.setting_id = params.setting_id;
    });
    this.buildForm();
  }
  onCourseTypeChange(event: any) {
    if (event.target.value != 'PRACTICAL') {
      this.fee_setting_form.get('hours').setValue(0);
    }
  }
  async getFeesSettingsList() //get from API
  {
    this.settings_list = [];
    this.settings_list_loading = true;
    try {
      const service_response = await this.restService.getExamCourseFeeMap({ "exam_fee_header_id": this.setting_id });
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.settings_list_loading = false;
  }

  getCourseTypeDisplayText(id: string): string {
    return this.course_types.find(x => x.id == id)?.display_text as string;
  }

  buildForm() {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        id: "",
        course_type: "",
        hours: 0,
        appearance_type: "",
        finance_type: "",
        fee_amount: 0,
      }
    }

    this.fee_setting_form = this.formBuilder.group({
      id: [this.setting.id],
      course_type: [this.setting.course_type, [Validators.required]],
      hours: [this.setting.hours],
      appearance_type: [this.setting.appearance_type, [Validators.required]],
      finance_type: [this.setting.finance_type, [Validators.required]],
      fee_amount: [this.setting.fee_amount, [Validators.required]],
    });

  }

  async onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(course: any): Promise<void> {
    this.setting = course;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  isInvalid(formControl: string): boolean {
    return this.fee_setting_form.touched && this.fee_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.fee_setting_form.status !== 'VALID';
  }

  onSave(): void {
    this.addItem();
  }

  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  reset(): void {
    this.setting = {} as FeeSettingInterface;
    this.fee_setting_form.reset();
    this.fee_setting_form.get('course_type')?.setValue(""); this.fee_setting_form.get('hours')?.setValue("");
    this.fee_setting_form.get('finance_type')?.setValue(""); this.fee_setting_form.get('appearance_type')?.setValue("");
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.fee_setting_form.value;
      if (form_data.course_type != 'PRACTICAL') {
        form_data.hours = 0;
      }
      //form_data.fee_header_id = this.setting_id; 
      form_data.exam_fee_header_id = this.setting_id;
      form_data.programme_type_id = this.programme_type_id;
      if (this.fee_setting_form!.get('id')!.value) {
        const res = await this.restService.updateExamCourseFeeMap(this.fee_setting_form.value.id, form_data);
        if (!res) {
          alert('Failed to update course fee map');
        }
        alert('Updated course fee map successfully');
      }
      else {
        const res = await this.restService.createExamCourseFeeMap(form_data);
        if (!res) {
          alert('Failed to create course fee map');
        }
        alert('Created course fee map successfully');
      }
      this.getFeesSettingsList();
      console.log(this.fee_setting_form.value);
    } catch (error) {
      alert('Failed to update exam settings');
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    const res = await this.restService.deleteExamCourseFeeMap(this.fee_setting_form.get('id')!.value);
    if (res.success) {
      alert('Deleted exam fee map successfully');
      this.getFeesSettingsList();
    }
    else {
      alert('Failed to delete exam fee map');
    }
    this.onCloseClick();
  }


  //Enabled Programmes Helper Methods;; Not used for now
  async getProgrammeDataList(): Promise<void> {
    try {
      this.department_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
        this.department_list.forEach(department => {
          department.no_of_years = 3; //remove
          //var enabled_program = this.settings_list.enabled_programmes.find(x=>x.programme_id==department.programme_id);
          /* if(enabled_program)
          {
            if(enabled_program?.enabled_all_years)
            {
              department.enabled_all_years=true;
            }
            else
            {
              department.enabled_all_years=false;
            }
            console.log(enabled_program?.enabled_years);
            department.enabled_years = enabled_program?.enabled_years;
          }
          else
          {
            department.enabled_all_years= false;
            department.enabled_years = [];
          } */
        });
        this.max_year = 3; //set based on no of semesters
      }
    } catch (error) {
      alert('Error while read data');
    }
    console.log(this.department_list);
  }

  enableAllProgrammes(ev: any) {
    this.department_list.forEach(department => {
      if (ev.target.checked) {
        department.enabled_all_years = true;
        for (var i = 0; i < department.no_of_years; i++) {
          department.enabled_years.push(this.years[i]);
        }
      }
      else {
        department.enabled_all_years = false;
        department.enabled_years = [];
      }
    });
  }

  allProgrammesChecked() {
    if (this.department_list.filter(x => x.enabled_all_years).length == this.department_list.length)
      return true;
    else
      return false;
  }

  enableAllNYears(ev: any, year: string) {
    this.department_list.forEach(department => {
      var year_index: number = department.enabled_years.indexOf(year);
      if (ev.target.checked) {
        if (year_index < 0) {
          department.enabled_years.push(year);
        }
        if (department.enabled_years.length == department.no_of_years) {
          department.enabled_all_years = true;
        }
        else {
          department.enabled_all_years = false;
        }
      }
      else {
        department.enabled_years.splice(year_index, 1);
        department.enabled_all_years = false;
      }

    });
  }

  allNYearsChecked(year: string) {
    var year_count = 0;
    this.department_list.forEach(department => {
      if (department.enabled_years.indexOf(year) >= 0) {
        year_count++;
      }
    });
    if (year_count == this.department_list.length)
      return true;
    else
      return false;
  }

  enableAllYearsInProgrammeChecked(ev: any, department: any) {
    var dept = this.department_list.find(x => x.programme_id == department.programme_id)
    dept.enabled_years = [];
    if (ev.target.checked) {
      dept.enabled_all_years = true;
      for (var i = 0; i < dept.no_of_years; i++) {
        dept.enabled_years.push(this.years[i]);
      }
    }
    else {
      dept.enabled_all_years = false;
    }
  }

  yearChecked(ev: any, department: any, year: string) {
    var dept = this.department_list.find(x => x.programme_id == department.programme_id)
    if (ev.target.checked) {
      dept.enabled_years.push(year);
      if (dept.enabled_years.length == dept.no_of_years) {
        dept.enabled_all_years = true;
      }
    }
    else {
      dept.enabled_all_years = false;
      dept.enabled_years.splice(dept.enabled_years.indexOf(year), 1);
    }
  }
  saveSettings() {

  }

}

interface FeeSettingInterface {
  id: string,
  course_type: string,
  hours?: number,
  appearance_type: string,
  finance_type: string,
  fee_amount: number,
  /* enabled_programmes: {
      programme_id: string,
      enabled_all_years:boolean,
      enabled_years: string[]
  } [] */
}


/*
    Generate enabled programmes
    var enabled_programmes : {
      programme_id: string,
    enabled_all_years:boolean,
    enabled_years: string[]
    } []= []
    this.department_list.filter(x=>{
      return x.programe_type_id==((this.fee_setting_form.value.programme_type as String).toUpperCase()) &&
      x.finance_type == this.fee_setting_form.value.finance_type }).forEach(department=>{
        enabled_programmes.push({
          programme_id: department.programme_id,
          enabled_all_years: true, enabled_years: ["I", "II", "III"]
        });
      }) */
