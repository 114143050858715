import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';

@Component({
  selector: 'app-student-hostel-attendance',
  templateUrl: './student-hostel-attendance.component.html',
  styleUrls: ['./student-hostel-attendance.component.scss']
})
export class StudentHostelAttendanceComponent implements OnInit {

  constructor(private domsanitizer : DomSanitizer) { }

  ngOnInit(): void {
  }
  
  url : string = '';

  getURL(){
    const access_token = CookieStore.getToken();
    this.url = "https://app.appsmith.com/app/hostel-v2/attendance-report-645e4288e4995a7c25a4ea5d?access_token="+access_token;
    return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
