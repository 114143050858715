import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { StockItems } from 'src/app/controllers/admins/hostel-management/manage-mess/stock-item';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { HttpClient } from '@angular/common/http';
import { isString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';


@Component({
  selector: 'app-timetable-settings',
  templateUrl: './timetable-settings.component.html',
  styleUrls: ['./timetable-settings.component.scss']
})
export class TimetableSettingsComponent implements OnInit {
  timetable_loading: boolean = false;
  staff_timetable_loading: boolean = false;
  department_list: any[] = [];
  timetable_data: TimetableDataInterface[] = [];
  timetable: TimetableDataInterface = {} as TimetableDataInterface;
  timetable_render: TimetableRenderInterface[] = [];
  department: string = "";
  time_setting_filter_form: FormGroup;
  title: string = "Staff Timetable Assignment";
  max_hour: number = 10;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  fonts = fonts;
  category_list: any[] = [];
  category_list_loading = false;
  hour_header: {
    hour_name: string,
    hour_number: number,
    hour_time?: string
  }[] = [{ hour_name: "I", hour_number: 1 }, { hour_name: "II", hour_number: 2 }, { hour_name: "III", hour_number: 3 },
  { hour_name: "IV", hour_number: 4 }, { hour_name: "V", hour_number: 5 }, { hour_name: "VI", hour_number: 6 },
  { hour_name: "VII", hour_number: 7 }, { hour_name: "VIII", hour_number: 8 }, { hour_name: "IX", hour_number: 9 }, { hour_name: "X", hour_number: 10 }];
  days: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  modal_mode: string = "view";
  mapping_mode: string = "view";
  edit_data: {
    day: string,
    day_no: number, hour_index: number
  } = { day: "", day_no: 0, hour_index: 0 };
  hour_data: any;
  hour_info_data: any[] = [];
  courseList: any[] = [];
  courseData: any[] = [];
  staffList: StaffDataInterface[] = [];
  staffData: StaffDataInterface[] = [];
  course_info: any = {};
  room_nos: string[] = ["C15", "C17", "C18", "C19", "C20", "C21", "C30", "C31", "C34", "PG Lab", "N8", "N9", "N10", "N11", "N12", "N13", "ONLINE"];
  years: string[] = ["I", "II", "III"];
  settings_list_loading: boolean = false;
  time_table_loading: boolean = false;
  map_programme_loading: boolean = false;
  staff_mapping_data: StaffMappingInterface[] = [];
  mapping_data_form: FormGroup;
  hour_map_form: FormGroup;
  mapping_data: any = undefined;
  map_sections: any[] = [];
  map_programmes: any[] = [];
  virtual_sections: any[] = [];
  sections: any[] = [];
  all_programme_list: any[] = [];
  save_loading: boolean = false;
  staff_timetable: {
    day: string,
    hours: HourInterface[]
  }[] = [];
  roomList: RoomDataInterface[] = [];
  roomData: RoomDataInterface[] = [];
  bulk_render_staff_data: Modal = {
    modal_id: "staff_mapping_common_modal",
    modal_button_text: "Staff Mapping Bulk Upload",
    modal_header: "Staff Mapping Upload List",
    list_name: "Staff Mapping",
    return_type: "FILE",
    csv_data: {
      columns: [
        { column_name: "enrolled_year", column_type: "required" },
        { column_name: "semester", column_type: "required" },
        { column_name: "staff_id", column_type: "required" },
        { column_name: "programme_name", column_type: "" },
        { column_name: "section", column_type: "" },
        { column_name: "day", column_type: "required" },
        { column_name: "hour", column_type: "required" },
        { column_name: "course_code", column_type: "required" },
        { column_name: "virtual_section", column_type: "" },
        { column_name: "room_no", column_type: "required" },
      ],
      row_data: "",
    },
    download_note: "Programme Name must be exactly same as the Programme Name given in the <a href='../../../assets/helper-csv/programme_list.xlsx'>helper file</a>.<br>For Courses with virtual section, leave Programme Name and section empty and <i>viz.</i>",
    table_data: {
      column_names: ["Enrolled Year", "Semester", "Staff ID", "Programme Name", "Section", "Day", "Hour", "Course Code", "Virtual Section", "Room No"],
      column_values: ["2020", "2", "SF007", "B.C.A. (SF)", "B", "Monday", "1", "20UAC03", "", "C31"]
    }
  };
  bulk_render_hour_data: Modal = {
    modal_id: "hour_mapping_common_modal",
    modal_button_text: "Hour Mapping Bulk Upload",
    return_type: "FILE",
    modal_header: "Hour Mapping Upload List",
    list_name: "Hour Mapping",
    csv_data: {
      columns: [
        { column_name: "programme_id", column_type: "required" },
        { column_name: "section", column_type: "required" },
        { column_name: "enrolled_year", column_type: "required" },
        { column_name: "semester", column_type: "required" },
        { column_name: "day", column_type: "required" },
        { column_name: "hour", column_type: "required" },
        { column_name: "course_code", column_type: "required" },
        { column_name: "staff_id", column_type: "required" },
        { column_name: "room_no", column_type: "required" },
      ],
      row_data: ""
    },
    table_data: {
      column_names: ["Enrolled Year", "Semester", "Day", "hour", "course_code", "staff_id", "room_no"],
      column_values: ["2020", "4", "Monday", "1", "20UAC410", "SF001,SF002", "PG Lab"]
    }
  };
  programme_map_save_loading: boolean = false;
  staff_list_loading: boolean = false;
  //[(ngModel)]="timetable.day_wise_order[edit_data.day_no].hours[edit_data.hour_index].course_code"
  constructor(private http: HttpClient, private fb: FormBuilder, private adminService: AdminservicesService) {
    this.time_setting_filter_form = this.fb.group({
      programme_type_id: ['', Validators.required],
      finance_type: ['', Validators.required],
      //programme_id: ['', Validators.required],
      //section: ["", Validators.required],
      enrolled_year: ['', Validators.required],
      semester: ["", Validators.required]
    });
    this.mapping_data_form = this.fb.group({
      enrolled_year: ["", [Validators.required]],
      semester: ["", [Validators.required]],
      course_code: ["", [Validators.required]], //user Input
      programme_id: [""], //user Input
      section: [""], //user Input
      virtual_section: [""],
      room_no: ["", [Validators.required]], //user Input
    });
    this.hour_map_form = this.fb.group({
      course_code: ["", [Validators.required]],
      staff_id: ["", [Validators.required]],
      room_no: ["", [Validators.required]]
    })
  }
  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.getAllProgrammeDataList();
    this.getRoomData();
  }
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.adminService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      /* this.time_setting_filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.time_setting_filter_form); */
    }
    if (id == "programme_id") {
      this.sections = [];
      const programme = this.department_list.find(x => x.programme_id == this.time_setting_filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.time_setting_filter_form.get('section').setValue(this.sections[0].section_name);
        }
        console.log(this.sections);
      }
    }
  }
  async getProgrammeDataList(filter_form): Promise<void> {
    try {
      this.department_list = [];
      const search_form_query: any = {
      };
      if (filter_form.value.programme_type_id) {
        search_form_query.programme_type_id = filter_form.value.programme_type_id;
      }
      if (filter_form.value.finance_type) {
        search_form_query.finance_type = filter_form.value.finance_type;
      }
      const service_response = await this.adminService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    }
    console.log(this.department_list);
  }

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.all_programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.adminService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    }
  }

  async getRoomData() {
    try {
      const response = await this.adminService.getRoomData();
      if (response && response.success) {
        this.roomList = response.data;
        console.log(this.roomList);
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }
  //dummy API calls -- needs to be replaced
  async getData() {
    var form_data = this.time_setting_filter_form.value;
    console.log(form_data);
    if (!form_data.programme_type_id) {
      alert("Please Choose Programme Type"); return;
    }
    if (!form_data.finance_type) {
      alert("Please Choose Finance Type"); return;
    }
    /* if (!form_data.programme_id) {
      alert("Please Choose Programme"); return;
    } */
    if (!form_data.enrolled_year) {
      alert("Please Choose Enrollment Year"); return;
    }
    if (!form_data.semester) {
      alert("Please Choose Semester"); return;
    }
    try {
      this.settings_list_loading = true;
      const response = await this.adminService.getTimeTableList(form_data);
      if (response && response.success) {
        this.timetable_data = response.data;
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.settings_list_loading = false;
    }
  }

  async getStaffList() {
    //get list from API
    try {
      this.staff_list_loading = true;
      /* const programme_data = await this.adminService.getProgrammeById(this.timetable.programme_id);
      if (programme_data && programme_data.data) {
        const department_id = programme_data.data.department_id;
        const department_data = await this.adminService.getDepartmentById(department_id);
        const staff_search = {
          department_category_id: department_data.department_category_id,
          finance_type: department_data.finance_type,
          department_id: department_id
        };
        const dept_staff_data = await this.adminService.searchStaff(staff_search);
        console.log(dept_staff_data);
      } */
      const staff_data = await this.adminService.getAllStaffList();
      if (staff_data && staff_data.success) {
        this.staffList = staff_data.data;
      }
      this.staffData = this.staffList;
      console.log(this.staffData);
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.staff_list_loading = false;
    }
  }

  async getCourseList() {
    this.courseList = [];
    /* var search_form_query: any = {
      offered_by_programme_type_id: this.timetable.programme_type_id,
      revision_year: 2020,
    }; */ //new search filter query
    //const service_response = await this.adminService.getCourseScheme(search_form_query);
    const service_response = await this.adminService.getAllCourseList();
    if (service_response && service_response.success) {
      this.courseList = service_response.data;
    }
    this.courseData = this.courseList;
  }


  //helpers to generate TimeTable Settings Table
  getDate(date_time: any) {
    if (isString(date_time)) {
      date_time = new Date(date_time);
    }
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" + date_time.getFullYear()
  }
  isHourExists(i: number, hour_number: number) {
    if (this.timetable.day_wise_order[i].hours.filter(x => x.hour_number == hour_number).length > 0)
      return true;
    return false;
  }
  isBreakHour(i: number, hour_number: number) {
    if (this.timetable.day_wise_order[i].hours.filter(x => x.hour_number == hour_number).length > 0) {
      return (this.timetable.day_wise_order[i].hours.find(x => x.hour_number == hour_number)?.is_break)
    }
    return true;
  }
  getEmptyHours(i: number) {
    var empty_hours = this.max_hour - (this.timetable.day_wise_order[i].no_of_hours);
    return Array(empty_hours).fill(0).map((x, i) => i);
  }
  getHourTime(i: number, hour_number: number) {
    if (this.timetable.day_wise_order[i].hours.filter(x => x.hour_number == hour_number).length > 0) {
      var hour = this.timetable.day_wise_order[i].hours.find(x => x.hour_number == hour_number);
      return hour?.start_time + "-" + hour?.end_time;
    }
    return "--";
  }
  /* getHourCourseStaff(i: number, hour_number: number) {
    if (this.timetable.day_wise_order[i].hours.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable.day_wise_order[i].hours.find(x => x.hour_number == hour_number)?.staff_name
    }
    return "--";
  }
  getHourCourseRoom(i: number, hour_number: number) {
    if (this.timetable.day_wise_order[i].hours.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable.day_wise_order[i].hours.find(x => x.hour_number == hour_number)?.room_no
    }
    return "--";
  } */

  //Time Settings Modal functions
  onEditClick(timetable: TimetableDataInterface) {
    console.log(timetable);
    this.timetable_render = [];
    this.bulk_render_hour_data.csv_data.row_data = "";;
    this.modal_mode = "view";
    this.timetable = timetable;
    this.getStaffList();
    this.timetable.day_wise_order.forEach(day => {
      var day_wise_hours: any[] = [];
      day.hours.sort((a, b) => (a.hour_number > b.hour_number) ? 1 : -1);
      day.hours.forEach(hour => {
        if (!hour.is_break) {
          if (day_wise_hours.filter(x => x.hour_number == hour.hour_number).length > 0) {//hour number already stored in the table
            if (day_wise_hours.find(x => x.hour_number == hour.hour_number)?.type == "PROGRAMME_MAP") {
              var staff_course_data = day_wise_hours.find(x => x.hour_number == hour.hour_number)?.staff_course_data;
              staff_course_data.find(x => x.course_code == hour.course_data.course_code)?.staff_id.push(hour.staff_data.staff_id);
            }
            else {
              var staff_id: string[] = [];
              staff_id.push(hour.staff_data.staff_id);
              day_wise_hours.find(x => x.hour_number == hour.hour_number)?.staff_course_data.push({
                staff_id: staff_id,
                course_code: hour.course_data.course_code,
                room_no: hour.room_data.room_no
              });
            }
          }
          else {
            var temp_hour: any = {};
            temp_hour.hour_number = hour.hour_number; temp_hour.start_time = hour.start_time;
            temp_hour.end_time = hour.end_time; temp_hour.duration = hour.duration;
            temp_hour.is_break = hour.is_break; temp_hour.type = hour.type;
            temp_hour.staff_course_data = [];
            var staff_id: string[] = [];
            staff_id.push(hour.staff_data.staff_id);
            temp_hour.staff_course_data.push({
              staff_id: staff_id,
              course_code: hour.course_data.course_code,
              room_no: hour.room_data.room_no
            });
            day_wise_hours.push(temp_hour);
          }
        }
        else //hour is break
        {
          var temp_hour: any = {};
          temp_hour.hour_number = hour.hour_number; temp_hour.start_time = hour.start_time;
          temp_hour.end_time = hour.end_time; temp_hour.duration = hour.duration;
          temp_hour.is_break = hour.is_break;
          day_wise_hours.push(temp_hour);
        }
      });
      this.timetable_render.push({
        day: day.day, start_time: day.start_time, end_time: day.end_time, no_of_breaks: day.no_of_breaks, no_of_hours: day.no_of_hours,
        hour_duration: day.hour_duration, hours: day_wise_hours
      });
    });
    console.log(this.timetable_render);
    let hours: number[] = [];
    this.timetable.day_wise_order.forEach(table => {
      hours.push(table.no_of_hours);
    });
    this.max_hour = Math.max.apply(Math, hours);
    console.log(this.max_hour);
    this.timetable_render.forEach(order => {
      order.hours.forEach(hour => {
        if (!hour.is_break)
          this.bulk_render_hour_data.csv_data.row_data += timetable.programme_id + "," + timetable.section + "," + timetable.enrolled_year + "," + timetable.semester + "," + order.day + "," + hour.hour_number + "\r\n";
      });
    })
    //console.log(this.bulk_render_hour_data.csv_data.row_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  closeClick() {
    this.timetable = {} as TimetableDataInterface;
    this.timetable_render = [];
    this.max_hour = 10;
    this.bulk_render_hour_data.csv_data.row_data = "";
    this.staffData = []; this.staffList = [];
    this.courseList = []; this.courseData = [];
    this.roomData = [];
    JQueryHelper.closeModal('#modal-popup');
  }

  viewHourData(info_data) {
    this.hour_info_data = info_data;
    this.modal_mode = "info";
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  editHourData(day: string, i: number, hour_number: number) {
    this.getStaffList();
    this.getCourseList();
    this.modal_mode = "edit";
    this.edit_data.day = day;
    this.edit_data.day_no = i;
    this.edit_data.hour_index = this.timetable_render[i].hours.findIndex(x => x.hour_number == hour_number);
    console.log(this.edit_data);
    this.hour_data = this.timetable_render[i].hours.find(x => x.hour_number == hour_number);
    if (this.hour_data.staff_course_data[0]?.course_code != undefined) {
      this.hour_map_form.get("course_code").setValue(this.hour_data.staff_course_data[0].course_code);
      this.hour_map_form.get("staff_id").setValue(this.hour_data.staff_course_data[0].staff_id);
      this.hour_map_form.get("room_no").setValue(this.hour_data.staff_course_data[0].room_no);
    }
    console.log(this.hour_data);
  }
  async saveHourData() {
    var save_obj: any[] = [];
    var to_programmes: any[] = [];
    to_programmes.push({
      programme_id: this.timetable.programme_id,
      section: this.timetable.section
    });
    console.log(this.hour_map_form.value.staff_id);
    this.hour_map_form.value.staff_id.forEach(staff => {
      console.log(staff);
      save_obj.push({
        _id: this.timetable._id,
        programme_map_id: this.timetable.programme_map_id,
        enrolled_year: this.timetable.enrolled_year,
        semester: this.timetable.semester,
        day: this.edit_data.day,
        hour_number: this.hour_data.hour_number,
        course_info: {
          course_code: this.hour_map_form.get("course_code").value,
          course_name: this.courseList.find(x => x.course_code == this.hour_map_form.get("course_code").value)?.course_name,
          part: this.courseList.find(x => x.course_code == this.hour_map_form.get("course_code").value)?.part,
        },
        staff_info: {
          staff_id: staff,
          is_primary: true,
          staff_name: this.staffList.find(x => x.roll_no == staff)?.given_name
          //add staff name from api
        },
        room_no: {
          room_no: this.hour_map_form.get("room_no").value
        },
        programme_info: to_programmes,
        type: "PROGRAMME_MAP"
      });
    });
    console.log(save_obj);
    try {
      this.save_loading = true;
      const save_response = await this.adminService.updateProgrammeMapTimeTable(save_obj);
      if (save_response && save_response.success) {
        alert("Programme Map save successfully");
        this.closeHourData();
        this.closeClick();
        this.getData();
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }
  closeHourData() {
    this.hour_map_form.reset();
    this.hour_info_data = [];
    this.modal_mode = "view";
  }



  //Mapping Modal
  onMappingClick(timetable?: TimetableDataInterface) {
    console.log(timetable);
    this.timetable = timetable;
    //Hour Header Timing Mapping
    this.timetable.day_wise_order[0].hours.forEach(hour => {
      if (this.hour_header.find(x => x.hour_number == hour.hour_number)) {
        this.hour_header.find(x => x.hour_number == hour.hour_number).hour_time = hour.start_time + "-" + hour.end_time;
      }

    });
    this.getStaffList();
    this.getCourseList();
    this.mapping_data = {};
    this.mapping_data.enrolled_year = this.timetable.enrolled_year;
    this.mapping_data.semester = this.timetable.semester;
    this.mapping_mode = "view";
    JQueryHelper.openModal('#mapping-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  mappingCloseClick() {
    this.max_hour = 10;
    this.timetable = {} as TimetableDataInterface;
    this.mapping_data = undefined;
    this.staffData = []; this.staffList = [];
    this.courseList = []; this.courseList = [];
    JQueryHelper.closeModal('#mapping-modal-popup');
  }


  async openTimeTableModal(staff_id, given_name?: string, middle_name?: string) {
    this.mapping_data.staff_id = staff_id;
    this.mapping_data.given_name = given_name; this.mapping_data.middle_name = middle_name;
    this.max_hour = 10;
    this.staff_timetable = [];
    var staff_timetable: any[] = []; //table render object
    try {
      this.time_table_loading = true;
      const timetable_data = await this.adminService.getStaffTimeTableById(staff_id);
      if (timetable_data && timetable_data.data.length > 0) {
        var day_wise_order = timetable_data.data[0]?.day_wise_order //overall staff timetable object
        var day_hours: HourInterface[] = []; //hours in a single day
        day_wise_order.forEach(day => {
          day_hours = [];
          day.hours.forEach(hour => {
            if (day_hours.filter(x => x.hour_number == hour.hour_number).length > 0) //hour number already exists in the day
            {
              if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.filter(x => x.course_data.course_code == hour.course_data.course_code).length == 0) {
                //course code doesnt exist in that hour
                var hour_data: any = {};
                hour_data = {
                  course_data: hour.course_data,
                  room_data: hour.room_data,
                  programme_data: hour.programme_data
                };
                day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.push(hour_data);
              }
              if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.filter(x => x.course_data.course_code == hour.course_data.course_code).length > 0) {
                //same course code exists for that hour with virtual section
                if (hour.programme_data.hasOwnProperty('virtual_section')) {
                  if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.
                    filter(x => x.course_data.course_code == hour.course_data.course_code && x.programme_data.virtual_section ==
                      hour.programme_data.virtual_section).length == 0) {
                    var hour_data: any = {};
                    hour_data = {
                      course_data: hour.course_data,
                      room_data: hour.room_data,
                      programme_data: hour.programme_data
                    };
                    day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.push(hour_data);
                  }
                }
                else {
                  //same course code exists for that hour without virtual section
                  if (day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.
                    filter(x => x.course_data.course_code == hour.course_data.course_code && x.programme_data.section ==
                      hour.programme_data.section && x.programme_data.programme_id == hour.programme_data.programme_id).length == 0) {
                    var hour_data: any = {};
                    hour_data = {
                      course_data: hour.course_data,
                      room_data: hour.room_data,
                      programme_data: hour.programme_data
                    };
                    day_hours.find(x => x.hour_number == hour.hour_number)?.hour_data.push(hour_data);
                  }
                }
              }
            }
            else {
              var hour_data_arr: any[] = [];
              hour_data_arr.push({
                course_data: hour.course_data,
                room_data: hour.room_data,
                programme_data: hour.programme_data
              });
              day_hours.push({
                hour_number: hour.hour_number,
                hour_data: hour_data_arr
              });
            }
          });
          var day_obj = {
            day: day.day,
            hours: day_hours
          } //single day's timetable object
          staff_timetable.push(day_obj);
        });
        this.days.forEach(day => {
          if (staff_timetable.filter(x => x.day == day).length == 0) //day does not exist on staff object
          {
            this.staff_timetable.push({
              day: day, hours: []
            })
          }
          else {
            this.staff_timetable.push(staff_timetable.find(x => x.day == day));
          }
        })
        console.log(this.staff_timetable);
      }
      if (timetable_data.data.length == 0) {
        this.days.forEach(day => {
          this.staff_timetable.push({
            day: day, hours: []
          });
        });
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.time_table_loading = false;
    }
    this.mapping_mode = "timetable";
  }

  closeTimeTableModal() {
    console.log(this.staffList);
    this.staff_timetable = [];
    this.mapping_mode = "view";
  }


  addMappingData(day: string, hour: number) {
    this.mapping_mode = "edit";
    this.mapping_data.day = day;
    this.mapping_data.hour = hour;
  }

  async checkProgramme(ev) {
    console.log(ev);
    const search_query = {
      //"programme_type_id": this.staffassignForm.value.programme_type_id,
      //"enrolled_year": this.mapping_data.enrolled_year,
      "enrolled_year": this.mapping_data_form.value.enrolled_year,
      "course_code": ev,
      //"semester": this.mapping_data.semester
      "semester": this.mapping_data_form.value.semester
    }
    this.map_programmes = []; this.virtual_sections = [];
    try {
      this.map_programme_loading = true;
      const response_data = await this.adminService.getCourseDetailsForStaffAssignment(search_query);
      if (response_data && response_data.success) {
        this.course_info = response_data.data;
        if (!this.course_info.hasOwnProperty('advanced_settings')) {
          alert("Please make course settings before assinging staff.");
          return;
        }
        if (this.course_info.advanced_settings?.virtual_sections?.length > 0) {
          this.virtual_sections = this.course_info.advanced_settings?.virtual_sections;
        }
        else {
          this.course_info.allowed_programme_ids.forEach(allowed_programme => {
            this.map_programmes.push(this.all_programme_list.find(x => x.programme_id == allowed_programme));
          });
        }
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.map_programme_loading = false;
    }
  }

  async onMapProgrammeSelectChange(event?: any): Promise<void> {
    this.map_sections = [];
    const programme = this.map_programmes.find(x => x.programme_id == this.mapping_data_form.value.programme_id);
    console.log(programme);
    if (programme && programme.section_details && programme.section_details.length > 0) {
      this.map_sections = programme.section_details;
    }
  }

  async saveMappingData() {
    var save_obj: any[] = [];
    var to_programmes: any[] = [];
    if (this.course_info.advanced_settings?.virtual_sections?.length > 0) {
      var allowed_programmes: any[] = this.virtual_sections.find(x => x.section_id == this.mapping_data_form.value.virtual_section)?.allowed_programmes;
      allowed_programmes.forEach(allowed_programme => {
        to_programmes = [];
        to_programmes.push({
          programme_id: allowed_programme.programme_id,
          section: allowed_programme.section_id,
          virtual_section: this.mapping_data_form.value.virtual_section,
        });
        save_obj.push({
          enrolled_year: this.mapping_data_form.value.enrolled_year,
          semester: this.mapping_data_form.value.semester,
          day: this.mapping_data.day,
          hour_number: this.mapping_data.hour,
          course_info: {
            course_code: this.mapping_data_form.get("course_code").value,
            course_name: this.courseList.find(x => x.course_code == this.mapping_data_form.get("course_code").value)?.course_name,
            part: this.courseList.find(x => x.course_code == this.mapping_data_form.get("course_code").value)?.part,
          },
          staff_info: {
            staff_id: this.mapping_data.staff_id,
            is_primary: true,
            staff_name: this.staffList.find(x => x.roll_no == this.mapping_data.staff_id)?.given_name
            //add staff name from api
          },
          room_no: {
            room_no: this.mapping_data_form.get("room_no").value
          },
          programme_info: to_programmes,
          type: "STAFF_MAP"
        });
      });
    }
    else {
      to_programmes.push({
        programme_id: this.mapping_data_form.value.programme_id,
        section: this.mapping_data_form.value.section
      })
      save_obj.push({
        enrolled_year: this.mapping_data_form.value.enrolled_year,
        semester: this.mapping_data_form.value.semester,
        day: this.mapping_data.day,
        hour_number: this.mapping_data.hour,
        course_info: {
          course_code: this.mapping_data_form.get("course_code").value,
          course_name: this.courseList.find(x => x.course_code == this.mapping_data_form.get("course_code").value)?.course_name,
          part: this.courseList.find(x => x.course_code == this.mapping_data_form.get("course_code").value)?.part,
        },
        staff_info: {
          staff_id: this.mapping_data.staff_id,
          is_primary: true,
          staff_name: this.staffList.find(x => x.roll_no == this.mapping_data.staff_id)?.given_name
          //add staff name from api
        },
        room_no: {
          room_no: this.mapping_data_form.get("room_no").value
        },
        programme_info: to_programmes,
        type: "STAFF_MAP"
      });
    }
    console.log(save_obj);
    try {
      this.save_loading = true;
      const save_response = await this.adminService.updateStaffMapTimeTable(save_obj);
      if (save_response && save_response.success) {
        alert("Staff Map save successfully");
        this.getData();
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
    this.closeMappingData();
  }

  closeMappingData() {
    this.openTimeTableModal(this.mapping_data.staff_id, this.mapping_data.given_name, this.mapping_data.middle_name);
    this.mapping_data_form.get("programme_id").setValue("");
    this.mapping_data_form.get("course_code").setValue("");
    this.mapping_data_form.get("section").setValue("");
    this.mapping_data_form.get("virtual_section").setValue("");
    this.mapping_data_form.get("enrolled_year").setValue("");
    this.mapping_data_form.get("semester").setValue("");
    this.mapping_data_form.get("room_no").setValue("");
    this.map_programmes = []; this.map_sections = []; this.virtual_sections = [];
    this.roomData = [];
    this.course_info = {};
    this.mapping_mode = "timetable";
  }


  async deleteMappingData(day: string, hour_number: number, course_code: string, programme_data) {
    var delete_obj: any = {
      day: day,
      hour_number: hour_number,
      course_code: course_code,
      staff_id: this.mapping_data.staff_id
    };
    if (programme_data.virtual_section) {
      delete_obj.virtual_section = programme_data.virtual_section
    }
    else {
      delete_obj.programme_id = programme_data.programme_id;
      delete_obj.section = programme_data.section;
    }
    const consent = confirm("Are you sure want to delete this mapping?");
    if (!consent) return;
    try {
      const save_response = await this.adminService.deleteStaffMapping(delete_obj);
      if (save_response && save_response.success) {
        this.getData();
        this.openTimeTableModal(this.mapping_data.staff_id, this.mapping_data.given_name, this.mapping_data.middle_name);
        alert("Staff Map Deleted successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
    }
  }

  //Staff Table View Helpers
  isStaffHourExists(i: number, hour_number: number): boolean {
    if (this.staff_timetable[i].hours.filter(x => x.hour_number == hour_number).length > 0)
      return true;
    return false;
  }

  getStaffHourData(i: number, hour_number: number) {
    return this.staff_timetable[i].hours.find(x => x.hour_number == hour_number).hour_data
  }
  //Kendo Filter functions
  handleStaffFilter(value: string) {
    this.staffData = this.staffList.filter
      ((s) => s!.given_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        || s!.roll_no.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }

  handleCourseFilter(value: string) {
    this.courseData = this.courseList.filter
      ((s) => s!.course_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        || s!.course_code.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }
  handleRoomFilter(value: string) {
    this.roomData = this.roomList.filter
      ((s) => s!.room_no.toLowerCase().indexOf(value.toLowerCase()) !== -1
        || s!.room_no.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
  }

  async bulkUploadStaffMapping(e) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_render_staff_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.adminService.bulkStaffMapTimeTable(formData);
      if (response.success && response.data) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_staff_data.show_loader = false;
    }
  }

  async bulkUploadHourMapping(e) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_render_hour_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.adminService.bulkProgrammeMapTimeTable(formData);
      if (response.success && response.data) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_hour_data.show_loader = false;
    }
  }

  getProgrammeDetails(event) {

  }

  downloadTimeTable() {
    var table_data = "";
    table_data += "<div class='table-responsive'>";
    table_data += "<table class='table table-bordered'>";
    table_data += "<thead><tr><th>Day</th>";
    for (var i = 0; i < this.hour_header.slice(0, this.max_hour).length; i++) {
      table_data += "<th>" + this.hour_header[i].hour_name + " (" + (this.hour_header[i].hour_time ? this.hour_header[i].hour_time : '') + ")</th>"
    }
    table_data += "</tr><thead><tbody>";
    for (var day = 0; day < this.staff_timetable.length; day++) {
      table_data += "<tr><td>" + this.staff_timetable[day].day + "</td>";
      //construct table data based on staff hour
      for (var j = 0; j < this.hour_header.slice(0, this.max_hour).length; j++) {
        if (this.staff_timetable[day].hours.filter(x => x.hour_number == this.hour_header[j].hour_number).length > 0)//hour exists
        {
          var hour_data = this.staff_timetable[day].hours.find(x => x.hour_number == this.hour_header[j].hour_number)?.hour_data;
          if (hour_data?.length > 0) {
            table_data += "<td>";
            for (var k = 0; k < hour_data.length; k++) {
              table_data += hour_data[k].course_data.course_name +
                "<br>(" + hour_data[k].course_data.course_code + ")<br>";
              if (hour_data[k].programme_data.virtual_section) {
                table_data += "Virtual Section - " + hour_data[k].programme_data.virtual_section;
              }
              else {
                table_data += this.getProgrammeName(hour_data[k].programme_data.programme_id) + " '" +
                  hour_data[k].programme_data.section + "'";
              }
              table_data += " " + hour_data[k].room_data.room_no + "</br>";
            }
            table_data += "</td>";

          }

        }
        else {
          table_data += "<td> --- </td>";
        }
      }
      table_data += "</tr>";
    }
    table_data += "</tbody></table>";
    table_data += "</div>";


    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    //+"<style>@page { size: A4 landscape;}</style>"
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write('<h6 class= "text-center"><b> Staff Time Table - ' + this.mapping_data.given_name + " " + this.mapping_data.middle_name + '</b></h6>');
    mywindow.document.write(table_data);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;

  }

}

//Interfaces
interface TimetableDataInterface {
  programme_type_id: string,
  programme_id: string,
  programme_name: string,
  section: string,
  enrolled_year: number,
  semester: number,
  programme_map_id: string,
  template_id: string,
  _id?: string,
  day_wise_order: DayWiseOrderInterface[]
}
interface DayWiseOrderInterface {
  day: string, //first column of the table	
  start_time: string, //start time of the day (10:00 AM) //use this to determine the min. Hour of the table to be displayed
  end_time: string, //end time of the day (04:00 PM) — use this to determine the max. Hours of the table to be displayed
  no_of_hours: number,
  hour_duration: number,
  no_of_breaks: number
  hours: DayHourInterface[]
} //multiple days in a week

interface DayHourInterface {
  hour_number: number,
  start_time: string,
  end_time: string,
  duration: number,
  is_break: boolean,
  staff_data: {
    staff_id: string,
    staff_name: string,
    is_primary: boolean
  },
  course_data: {
    course_code: string,
    course_name: string,
    part: string
  },
  room_data: {
    room_no: string
  },
  type: string,
}	//multiple hours in a day

interface TimetableRenderInterface {
  day: string, //first column of the table	
  start_time: string, //start time of the day (10:00 AM) //use this to determine the min. Hour of the table to be displayed
  end_time: string, //end time of the day (04:00 PM) — use this to determine the max. Hours of the table to be displayed
  no_of_hours: number,
  hour_duration: number,
  no_of_breaks: number
  hours: {
    hour_number: number,
    start_time: string,
    end_time: string,
    duration: number,
    is_break: boolean,
    staff_course_data: {
      course_code: string,
      staff_id: string[],
      room_no: string
    }[]
    type: string,
  }[]
}

interface StaffDataInterface {
  given_name: string, roll_no: string, staff_department: string, middle_name: string
}




interface StaffMappingInterface {
  to_department_name: string, //name of the department
  to_department_id: string,
  start_date: string,
  semester: number,
  enrolment_year: number,
  section: string,
  section_id: string,
  staff_id: string, //* user input / autofilled by other-department staff allotment
  staff_name: string, //* user input / autofilled by other-department staff allotment
  course_code: string, //* user input / autofilled by other-department staff allotment
  course_name: string, //* user input / autofilled by other-department staff allotment
  hour_data: {
    day: string,
    hour_number: number, //receive from API based on the timesettings of the given department
    room_no: string //* user input / autofilled by other-department staff allotment
  }[]
}

interface HourDataInterface {
  day: string,
  hour_number: string,
  room_no: string
}


interface HourInterface {
  hour_number: number,
  hour_data: {
    course_data: {
      course_code: string, course_name: string, part: string,
    },
    room_data: {
      room_no: string
    },
    programme_data: {
      programme_id: string,
      section: string,
      virtual_section?: string
    }
  }[]
}

interface RoomDataInterface {
  id: string,
  block_name: string,
  room_no: string
}
//enrolment year, semester, start_date, staff_id, day

//Bckup
/* getHourCourseName(i:number, hour_number:string)
  {
    if(this.timetable.day_wise_order[i].hours.filter(x=>x.hour_number==hour_number).length>0)
    {
      return this.timetable.day_wise_order[i].hours.find(x=>x.hour_number==hour_number)?.course_code as string
    }
    return "--";
  } */
