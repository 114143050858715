import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { MenuItems } from 'src/app/controllers/admins/hostel-management/manage-mess/menu-item';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
@Component({
  selector: 'app-other-expenses',
  templateUrl: './other-expenses.component.html',
  styleUrls: ['./other-expenses.component.scss']
})
export class OtherExpensesComponent implements OnInit {

  fonts = fonts;
  otherExpenseForm: FormGroup;
  title = 'Other Expenses';
  id = '';
  expenses: {
    bill_date:string,
    bill_no:string,
    expense_name:string,
    expense_description:string,
    total_price:number
  } [ ] = [ ]; 
  fromDataResolver = FromDataResolver;
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.otherExpenseForm = this.formBuilder.group({
        bill_date: [new Date().toISOString().substring(0,10), [Validators.required]],
        bill_no:['',[Validators.required]],
        expense_name:['',Validators.required],
        expense_description:[''],
        total_price: [0, Validators.required]
      });
      this.getExpenses();
    }
    stock_list_loading:boolean = false;
  onAddClick()
  {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  async onCloseClick(): Promise<void> {
    this.onReset();
    JQueryHelper.closeModal('#modal-popup');
  }
  getExpenses(){
    this.expenses.push({bill_date:"08/12/2021",bill_no:"123", expense_name:"Water Expense", total_price: 1900, expense_description:"Water bill payment"},
    {bill_date:"08/11/2021",bill_no:"223", expense_name:"Electricity Expense", total_price: 5400,"expense_description":"EB Bill payment"}
    );
  }
  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Extra Menu', url: 'admin/hostel/extra-menu' };
    this.route.params.subscribe(params => {
      this.navigationService.updateNavigation(this.title, this.router.url);
    });
  }




  getTittle(): string {
    return this.title;
  }
  onReset(): void {
    this.otherExpenseForm.reset({total_price:0,expense_name:'', bill_no:"",
    expense_description:'',
    bill_date: new Date().toISOString().substring(0,10)
   });
  }

  onSaveAndClose():void
  {
    this.onSave();
    this.onCloseClick();
  }
  isInvalid(formControl: string): boolean {
    if (formControl=='bill_no' && isNaN(this.otherExpenseForm.get(formControl)?.value)) {
      return true;
    }
    if (formControl=='total_price' && isNaN(this.otherExpenseForm.get(formControl)?.value)) {
      return true;
    }
    return this.otherExpenseForm.touched && this.otherExpenseForm.get(formControl)?.errors !== null;
  }


  canDisable(): boolean {
    if (isNaN(this.otherExpenseForm.value?.total_price) || this.otherExpenseForm.value?.total_price == 0) {
      return true;
    }
    return this.otherExpenseForm.status !== 'VALID';
  }

  getFormData(): any {
    return this.otherExpenseForm.value;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
     this.createMenuItem();
  }

  async createMenuItem(): Promise<void> {
    try {
  /*     const res = await this.restService.menuItemService.creatMenuItems(this.getMenuItemFromForm());
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
      } */

         this.expenses.unshift(this.otherExpenseForm.value);
      this.notificationService.setNotifcation({ message: 'Created menu item successfully', type: 'Success'});
      this.otherExpenseForm.reset();
      this.onReset();
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
    }
  }


}
