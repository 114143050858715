<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Assign Personal Care Guardian to Students</p>
                    </div>

                    <div class="card-body">

                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Assign
                                Staff</button>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 15%;">Start Date</th>
                                        <th style="width: 15%;">End Date</th>
                                        <th style="width: 15%;">Staff Name</th>
                                        <th style="width: 35%;">Allocated Roll No(s).</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="assignment_list.length>0">
                                        <ng-container
                                            *ngFor="let assignment of assignment_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">{{i+1}}</td>
                                                <td>{{assignment.start_date | date:commonEnums.date_format}}</td>
                                                <td>{{assignment.end_date | date:commonEnums.date_format}}</td>
                                                <td>{{assignment.given_name}} {{assignment.middle_name}}
                                                    ({{assignment.roll_no}})</td>
                                                <td>{{assignment.roll_nos}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(assignment)"
                                                            class="btn btn-sm btn-info mr-2">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!assignment_list_loading && assignment_list.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="assignment_list_loading && assignment_list.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="assignment_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Student-Guardian Assignment Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="assignment_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                                Date</label>
                                            <input class="form-control" required="" type="date"
                                                formControlName="start_date">
                                            <div *ngIf="isInvalid('start_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                                                Date</label>
                                            <input class="form-control" required="" type="date"
                                                formControlName="end_date">
                                            <div *ngIf="isInvalid('end_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Staff
                                                ID.</label>
                                            <input type="text" class="form-control" formControlName="staff_id"
                                                placeholder="Enter Staff ID.">
                                            <div *ngIf="isInvalid('staff_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Staff ID. is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                        Roll Nos.</label>
                                    <textarea id="roll_nos" class="form-control"
                                        placeholder="Enter Student Roll No(s) separated by Comma. Press Enter Key to continue previous roll no. header"
                                        (keydown)="triggerFunction($event)" formControlName="roll_nos">
                                </textarea>
                                    <div *ngIf="isInvalid('roll_nos')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Student Roll No. is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="checkbox" formControlName="is_active" class="mg-r-5">Is Active?
                                    <div *ngIf="isInvalid('is_active')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Choose if the assignment is active for or
                                                not.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">
                                        <ng-container *ngIf="!this.assignment_form.value._id && !this.save_loading">
                                            Save
                                        </ng-container>
                                        <ng-container *ngIf="!this.assignment_form.value._id && this.save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="this.assignment_form.value._id && !this.save_loading">
                                            Update
                                        </ng-container>
                                        <ng-container *ngIf="this.assignment_form.value._id && this.save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">
                                        <ng-container *ngIf="!this.assignment_form.value._id && !this.save_loading">
                                            Save & Close
                                        </ng-container>
                                        <ng-container *ngIf="!this.assignment_form.value._id && this.save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="this.assignment_form.value._id && !this.save_loading">
                                            Update & Close
                                        </ng-container>
                                        <ng-container *ngIf="this.assignment_form.value._id && this.save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="this.assignment_form.value._id && !delete_loading"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-danger mg-r-20"
                                        *ngIf="this.assignment_form.value._id && delete_loading"
                                        disabled>Deleting...</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>