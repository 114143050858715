import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { isString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
declare var $: any;


@Component({
  selector: 'app-view-timetable',
  templateUrl: './view-timetable.component.html',
  styleUrls: ['./view-timetable.component.scss']
})
export class ViewTimetableComponent implements OnInit {
  fonts = fonts;
  commonEnums = CommonEnums;
  max_hour: number = 10;
  current_week: string = "";
  hour_header: {
    hour_name: string,
    hour_number: number
  }[] = [{ hour_name: "I", hour_number: 1 }, { hour_name: "II", hour_number: 2 }, { hour_name: "III", hour_number: 3 },
  { hour_name: "IV", hour_number: 4 }, { hour_name: "V", hour_number: 5 }, { hour_name: "VI", hour_number: 6 },
  { hour_name: "VII", hour_number: 7 }, { hour_name: "VIII", hour_number: 8 }, { hour_name: "IX", hour_number: 9 }, { hour_name: "X", hour_number: 10 }]
  timetable_loading: boolean = false;
  timetable: TimetableInterface[] = [];
  student_data_loading: boolean = false;
  students_list: StudentAttendanceInterface = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
  popup_mode: string = '';
  staffList: any[] = [];
  staffData: any[] = [];
  department_type_list: any[] = [];
  department_list: any[] = [];
  department_search_form: FormGroup;
  absent_list: StudentAttendanceInterface = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
  modalData: CourseDataInterface = {} as CourseDataInterface;
  searchData: {
    staff_data: string,
    course_data: string,
    student_data: string
  } = { staff_data: "", course_data: "", student_data: "" };
  title = 'Mark Attendance';
  id = '';
  week_string: string = "";
  week_no: number = 1;
  start_date: Date = new Date();
  end_date: Date = new Date();
  chosen_day: number = 0;
  adjustment_date: string = '';
  student_save_loading: boolean = false;
  allocated_attendance_mode: boolean = false; //set this false for non-allocated (adjustment hours)
  show_advanced_search: boolean = false;
  all_programme_list: any[] = [];
  staff_list_loading: boolean = false;
  attendance_report: StaffLateEntryReportInterface[] = [];
  absent_display_list: any[] = [];
  student_absent_list_loading: boolean = false;
  temp_absent_list = [];
  reopen_data: any = {};
  reopen_request_loading: boolean = false;
  reopen_request_reason: string = '';
  adjustment_data: {
    day: string, date: string, hour_number: number, department_id?: string
  } = undefined;
  fromDataResolver = FromDataResolver;
  public format = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yy",
  };
  report_data_loading: boolean = false;
  adjustment_made_data: AdjustmentInterface[] = [];
  report_search_form: FormGroup;

  constructor(private restService: AdminservicesService, private fb: FormBuilder) {
    this.ISO8601_week_no(new Date());
    this.getAllProgrammeDataList();
    this.department_search_form = this.fb.group({
      department_category_id: [""],
      finance_type: [""],
      department_id: [""],
    });
    this.report_search_form = this.fb.group({
      start_date: [""],
      end_date: [""],
    });
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    /* let hours: number[] = [];
    this.timetable.forEach(table => {
      table.hours.forEach(hour => {
        hours.push(hour.hour_number);
      })
    });
    this.max_hour = Math.max.apply(Math, hours);
    console.log(this.max_hour); */
  }

  getTittle(): string {
    return this.title;
  }

  //date methods
  dateChanged(ev: any) {
    console.log(ev);
    this.ISO8601_week_no(ev);
  }

  isCurrentDate(date: Date) {
    //console.log(date);
    if (date == new Date()) {
      return true;
    }
    return false;
  }
  isDateInWeekRange(date: Date) {
    if (date >= this.start_date && date <= this.end_date) {
      return "week-range";
    }
    return "non-week";
  }

  async ISO8601_week_no(tdt: any) {
    //var tdt:any = new Date();
    var dayn = (tdt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    this.week_no = 1 + Math.ceil((firstThursday - tdt) / 604800000);
    this.week_string = tdt.getFullYear() + "-W" + this.week_no;
    this.start_date = new Date(this.getFirstMondayOfWeek(this.week_no));
    this.end_date = new Date(this.start_date);
    this.end_date.setDate(this.start_date.getDate() + 6);
    console.log(this.start_date, this.end_date);
    try {
      this.timetable_loading = true;
      var date_range_data = {
        start_date: (this.start_date).toISOString(),
        end_date: (this.end_date).toISOString()
      };
      console.log(date_range_data);
      const response = await this.restService.getStaffTimetable(date_range_data);
      if (response && response.success) {
        console.log(response.data);
        this.timetable = response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.timetable_loading = false;
    }
  }

  getFirstMondayOfWeek(weekNo: number): any {
    var firstMonday = new Date(new Date().getFullYear(), 0, 4, 0, 0, 0, 0);

    while (firstMonday.getDay() != 1) {
      firstMonday.setDate(firstMonday.getDate() - 1);
    }
    if (1 <= weekNo && weekNo <= 52)
      return firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));

    firstMonday.setDate(firstMonday.getDate() + 7 * (weekNo - 1));
    if (weekNo == 53 && firstMonday.getDate() >= 22 && firstMonday.getDate() <= 28)
      return firstMonday;
    return null;
  }

  weekChanged(ev: any) {
    var week: string = (ev.target.value);
    this.start_date = new Date(this.getFirstMondayOfWeek(parseInt(week.substr(week.length - 2, week.length - 1))));
    this.end_date.setDate(this.start_date.getDate() + 6);
  }

  getDate(date_time: Date) {
    if (isString(date_time)) {
      date_time = new Date(date_time);
    }
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" + date_time.getFullYear()
  }

  getDisplayDate(i: number) {
    //console.log(new Date(this.start_date.getDate() + i));
    return this.getDate(new Date(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate() + i));
  }

  //table rendering methods
  hourExists(i: number, hour_number: number) {
    if (!this.timetable[i].day_wise_order_data) //no data exists for the day
    {
      return false;
    }
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return true;
    }
    return false;
  }

  getCourses(i: number, hour_number: number) {
    return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data;
  }

  getAdjustments(i: number, hour_number: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.adjusted_data)
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.adjusted_data;
    return [];
  }

  getHourCourseTime(i: number, hour_number: number, course_index: number): string {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.start_time + " - " +
        this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.end_time;
    }
    return "--";
  }

  getHourCourseCode(i: number, hour_number: number, course_index: number): string {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.course_code
    }
    return "--";
  }
  getHourCourseName(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.course_name
    }
    return "";
  }

  getHourEnrolledYear(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.enrolled_year
    }
    return "";
  }

  getHourSemester(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.semester
    }
    return "";
  }

  getHourProgrammes(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.programme_data
    }
    return "";
  }

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.all_programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    }
  }

  getHourProgrammeName(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      if (this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index].programme_data.length == 1) {
        var programme_name = this.all_programme_list.find(x => x.programme_id == this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index].programme_data[0].programme_id)?.programme_name;
        var section = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index].programme_data[0].section
        return programme_name + " '" + section + "'";
      }
      else {
        var section = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index].programme_data[0].virtual_section
        return "Virtual Section " + section;
      }
    }
    return "";
  }

  getHourRoomNo(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.room_data.room_no
    }
    return "";
  }

  canMarkAttendance(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.
        course_data[course_index]?.can_mark_attendance;
    }
    return false;
  }

  getAdjustmentData(i: number, hour_number: number, course_index: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      return "Adjusted By " + this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.
        course_data[course_index]?.adjustment_data.adjusted_by_staff_id;
    }
    return "";
  }

  canDisplayIcon(i: number, hour_number: number, icon: string, course_index?: number) {
    if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
      var hour_data = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number).course_data[course_index]
      if (icon == 'MARKED') {
        if (hour_data.is_marked) {
          return true;
        }
        return false;
      }
      if (icon == 'NA') {
        if (!hour_data.is_marked && hour_data.re_open_request_status == 'NA') {
          return true;
        }
        return false;
      }
      if (icon == 'READY') {
        if (!hour_data.is_marked && hour_data.re_open_request_status == 'READY') {
          return true;
        }
        return false;
      }
      if (icon == 'SUBMITTED') {
        if (!hour_data.is_marked && hour_data.re_open_request_status == 'SUBMITTED') {
          return true;
        }
        return false;
      }
      if (icon == 'ACCEPTED') {
        if (!hour_data.is_marked && hour_data.re_open_request_status == 'ACCEPTED') {
          return true;
        }
        return false;
      }
      if (icon == 'ADJUSTMENT_DONE') {
        if (hour_data.is_adjusted && hour_data.is_marked) {
          return true;
        }
        return false;
      }
      if (icon == 'ABS') {
        if (hour_data.is_marked) {
          return true;
        }
        return false;
      }
      if (icon == 'ADDITIONAL_ADJUSTMENT') {
        if (!hour_data.is_marked && hour_data.re_open_request_status == 'NA') {
          return true;
        }
        return false;
      }
    }
    if (icon == 'LATE_ADJUSTMENT') {
      var given_date = new Date(this.start_date);
      given_date.setDate(this.start_date.getDate() + i);
      given_date.setHours(0, 0, 0, 0);
      var current_date: Date = new Date();
      current_date.setHours(0, 0, 0, 0);
      if (given_date.getTime() < current_date.getTime()) {
        return true;
      }
      return false;
    }
    return false;
  }

  getAbsentStudentsCount(i, hour_number, course_index) {
    return this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number).course_data[course_index].absent_students_count;
  }

  async showAbsenteesList(i, hour_number, course_index) {
    try {
      this.absent_display_list = [];
      this.student_absent_list_loading = true;
      var search_data = {
        "date": this.timetable[i].date,
        "day": this.timetable[i].day_name,
        "hour_number": hour_number,
        "enrolled_year": this.getHourEnrolledYear(i, hour_number, course_index),
        "course_code": this.getHourCourseCode(i, hour_number, course_index),
        "programme_data": this.getHourProgrammes(i, hour_number, course_index),
        "semester": this.getHourSemester(i, hour_number, course_index)
      }
      console.log(search_data);
      this.popup_mode = 'absent_list';
      JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
      const response = await this.restService.getStudentsDataForAttendance(search_data);
      if (response && response.success) {
        console.log(response.data);
        const attendance_data = response.data.attendance_data;
        attendance_data.forEach(student => {
          if (!student.is_present) {
            this.absent_display_list.push(student);
          }
        });
        console.log(this.absent_display_list);
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_absent_list_loading = false;
    }
  }

  closeAbsentList() {
    this.absent_display_list = [];
    this.popup_mode = '';
    JQueryHelper.closeModal('#attendance-modal-popup');
  }


  canMakeAdjustment(day: TimetableInterface, i: number, hour_number: number, course_index?: number) {
    if (this.timetable[i]?.day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.can_mark_adjustment)
      return true;
    this.chosen_day = i;
    //var attendance_date: Date = new Date();
    var given_date = new Date(this.start_date);
    given_date.setDate(this.start_date.getDate() + this.chosen_day);
    given_date.setHours(0, 0, 0, 0);
    var current_date: Date = new Date();
    current_date.setHours(0, 0, 0, 0);
    if (given_date.toISOString().substring(0, 10) != current_date.toISOString().substring(0, 10)) {
      return false;
    }
    if (current_date.getHours() > 17)
      return false;
    return true;
  }

  isAdjustmentMade(day: TimetableInterface, i: number, hour_number: number) {
    if (!this.timetable[i].day_wise_order_data) //no data exists for the day
    {
      return false;
    }
    if (this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.adjusted_data && this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number).adjusted_data.length > 0)
      return true;
    return false;
  }

  showAdjustmentMade(i: number, hour_number: number) {
    this.adjustment_made_data = [];
    if (this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.adjusted_data && this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number).adjusted_data.length > 0) {
      this.adjustment_made_data = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.adjusted_data;
      this.popup_mode = 'adjustment_made';
      JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
    }
  }

  closeAdjustmentMade() {
    this.popup_mode = '';
    JQueryHelper.closeModal('#attendance-modal-popup');
  }

  //attendance data
  async openAttendance(day: TimetableInterface, i: number, hour_number: number, course_index?: number) {
    this.chosen_day = i;
    //var attendance_date: Date = new Date();
    /* if (new Date(attendance_date.setDate(this.start_date.getDate() + this.chosen_day)) > new Date()) {
      alert("Cannot mark attendance for this day");
      return;
    } */
    if (this.timetable[i].day_wise_order_data && this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0
      && this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data?.length > 0) {
      this.modalData = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index];
      try {
        this.student_data_loading = true;
        var search_data = {
          "date": day.date,
          "day": day.day_name,
          "hour_number": hour_number,
          "semester": this.getHourSemester(i, hour_number, course_index),
          "enrolled_year": this.getHourEnrolledYear(i, hour_number, course_index),
          "course_code": this.getHourCourseCode(i, hour_number, course_index),
          "programme_data": this.getHourProgrammes(i, hour_number, course_index),
          "is_adjusted": false //true for adjustment mode
        }
        console.log(search_data);
        this.popup_mode = 'attendance';
        this.allocated_attendance_mode = false;
        JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
        const response = await this.restService.getStudentsDataForAttendance(search_data);
        if (response && response.success) {
          console.log(response.data);
          this.students_list = response.data;
        }
      }
      catch (error) {
        console.log(error);
        alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      }
      finally {
        this.student_data_loading = false;
      }
    }
    else {
      this.openStaffList(day, hour_number);
      this.popup_mode = 'staff_list';
      this.allocated_attendance_mode = true;
      JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
    }
  }
  openAdditionalAdjustment(day: TimetableInterface, i: number, hour_number: number, course_index?: number) {
    this.openStaffList(day, hour_number);
    this.popup_mode = 'staff_list';
    this.allocated_attendance_mode = true;
    JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  markPresent(ev: any) {
    this.students_list.attendance_data.forEach(student => {
      if (!student.od_given) {
        if (ev.target.checked) {
          student.is_present = true;
        }
        else {
          student.is_present = false;
        }
      }
    });
  }

  isChecked() {
    if (this.students_list.attendance_data.filter(x => x.is_present).length == this.students_list.attendance_data.length)
      return true;
    else
      return false;
  }

  async onCloseClick(): Promise<void> {
    this.popup_mode = '';
    this.modalData = {} as CourseDataInterface;
    this.students_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
    this.absent_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
    this.allocated_attendance_mode = false;
    this.temp_absent_list = [];
    this.resetStaffListData();
    JQueryHelper.closeModal('#attendance-modal-popup');
  }

  onVerifyClick() {
    this.absent_list.course_code = this.students_list.course_code;
    this.absent_list.attendance_data = JSON.parse(JSON.stringify(this.students_list.attendance_data.filter(x => x.is_present == false)));
    console.log(this.absent_list);
    this.popup_mode = 'verify';
    JQueryHelper.openModal('#confirm-modal-popup', { keyboard: false, backdrop: 'static' });
  }


  closeConfirmClick() {
    this.popup_mode = 'attendance';
    this.absent_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
    JQueryHelper.closeModal('#confirm-modal-popup');
  }

  removeStudentFromAbsentList(student: StudentDataInterface) {
    this.students_list.attendance_data.find(x => x.roll_no == student.roll_no)!.is_present = true;
    this.absent_list.attendance_data.splice(this.absent_list.attendance_data.findIndex(x => x.roll_no == student.roll_no), 1);
  }

  async saveAttendance() {
    this.absent_list.attendance_data.forEach(student => {
      this.students_list.attendance_data.find(x => x.roll_no == student.roll_no)!.is_present = student.is_present;
    })
    try {
      const consent = confirm("Are you sure want to save attendance?");
      if (!consent) return;
      this.student_save_loading = true;
      const response = await this.restService.CreateorUpdateStudentAttendance(this.students_list);
      if (response && response.success) {
        this.ISO8601_week_no(this.start_date);
        alert("Student Attendance stored successfully");
        this.absent_list = { course_code: "", attendance_data: [] } as StudentAttendanceInterface;
        this.onCloseClick();
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.student_save_loading = false;
    }
  }


  async openStaffList(day: TimetableInterface, hour_number: number) {
    //get list from API
    this.getDepartmentTypeList();
    this.staffList = [];
    var search_data = {
      "date": day.date,
      "day": day.day_name,
      "hour_number": hour_number
    };
    this.adjustment_data = search_data;
    this.adjustment_date = day.date;
    console.log(search_data);
  }

  async getDepartmentTypeList(): Promise<void> {
    try {
      this.department_type_list = [];
      const service_response = await this.restService.getDepartmentCategories();
      if (service_response && service_response.success) {
        this.department_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read department type');
    } finally {
    }
  }

  async getStaffList() {
    try {
      this.staff_list_loading = true;
      this.adjustment_data.department_id = this.department_search_form.value.department_id;
      console.log(this.adjustment_data);
      const response = await this.restService.getAdjustmentStaffList(this.adjustment_data);
      if (response && response.success) {
        console.log(response.data);
        this.staffList = response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.staff_list_loading = false;
    }
  }

  async openAdjustmentAttendance(staff, course) {
    try {
      this.student_data_loading = true;
      var search_data = {
        "date": this.adjustment_date,
        "day": staff.day,
        "hour_number": staff.hour_number,
        "enrolled_year": course.enrolled_year,
        "course_code": course.course_code,
        "programme_data": course.programme_data,
        "semester": course.semester,
        "is_adjusted": true, //true for adjustment mode
        "to_adjusted_data": {
          staff_id: staff.staff_id,
          staff_name: staff.staff_name
        }
        //add staff info
      }
      console.log(search_data);
      this.popup_mode = 'attendance';
      this.modalData.course_code = course.course_code; this.modalData.course_name = course.course_name;
      this.modalData.programme_data = course.programme_data;
      this.modalData.enrolled_year = course.enrolled_year;
      JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
      const response = await this.restService.getStudentsDataForAttendance(search_data);
      if (response && response.success) {
        console.log(response.data);
        this.students_list = response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_data_loading = false;
    }
  }

  backToStaffList() {
    this.popup_mode = 'staff_list';
  }

  closeStaffClick() {
    this.onCloseClick();
  }

  resetStaffListData() {
    this.staffList = [];
    this.adjustment_data = undefined;
    this.department_search_form.reset();
    this.department_search_form.get('department_category_id').setValue(""); this.department_search_form.get('finance_type').setValue("");
    this.department_search_form.get('department_id').setValue("");
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'department_category_id' || id === 'finance_type') {
      this.getDepartmentDataList();
    }
  }

  async getDepartmentDataList(): Promise<void> {
    try {
      this.department_list = [];
      const search_form_query: any = {
      };
      if (this.department_search_form) {
        if (this.department_search_form.value.department_category_id) {
          search_form_query.department_category_id = this.department_search_form.value.department_category_id;
        }
        if (this.department_search_form.value.finance_type) {
          search_form_query.finance_type = this.department_search_form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        this.department_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }

  async openReopenRequest(i: number, hour_number: number, late_entry_type: string, course_index?: number) {
    this.reopen_data = {};
    //if (this.timetable[i].day_wise_order_data?.hours?.filter(x => x.hour_number == hour_number).length > 0) {
    this.reopen_data.date = this.timetable[i].date;
    this.reopen_data.day = this.timetable[i].day_name;
    this.reopen_data.hour_number = hour_number;
    this.reopen_data.course_code = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.course_code;
    this.reopen_data.course_name = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.course_name;
    this.reopen_data.staff_id = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.staff_data.staff_id;
    this.reopen_data.staff_name = this.timetable[i].day_wise_order_data?.hours?.find(x => x.hour_number == hour_number)?.course_data[course_index]?.staff_data.staff_name;
    this.reopen_data.re_open_request_status = "SUBMITTED";
    this.reopen_data.late_entry_type = late_entry_type;
    //}
    this.popup_mode = 'late_entry_reason';
    JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async makeReopenRequest() {
    try {
      this.reopen_request_loading = true;
      this.reopen_data.reason = this.reopen_request_reason;
      const response = await this.restService.makeLateEntryRequest(this.reopen_data);
      if (response && response.success) {
        console.log(response.data);
        this.ISO8601_week_no(this.start_date);
        alert("Request made successfully");
        this.closeReopenRequest();
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.reopen_request_loading = false;
    }
  }

  async closeReopenRequest() {
    this.reopen_data = {};
    this.reopen_request_reason = '';
    this.popup_mode = '';
    JQueryHelper.closeModal('#attendance-modal-popup');
  }

  async openReportModal() {
    this.popup_mode = 'report';
    JQueryHelper.openModal('#attendance-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async getMissedHours() {
    try {
      this.report_data_loading = true;
      if (!this.report_search_form.value.start_date) {
        alert("Please choose Start Date"); return;
      }
      if (!this.report_search_form.value.end_date) {
        alert("Please choose End Date"); return;
      }
      const response = await this.restService.getStaffAttendanceReport(this.report_search_form.value);
      if (response && response.success) {
        console.log(response.data);
        this.attendance_report = response.data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.report_data_loading = false;
    }
  }


  async makeReopenRequestFromList(entry: StaffLateEntryReportInterface) {
    try {
      const response = await this.restService.makeLateEntryRequest(entry);
      if (response && response.success) {
        console.log(response.data);
        this.ISO8601_week_no(this.start_date);
        alert("Request made successfully");
        this.closeMissedHoursModal();
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_data_loading = false;
    }

  }

  closeMissedHoursModal() {
    this.attendance_report = [];
    this.popup_mode = '';
    JQueryHelper.closeModal('#attendance-modal-popup');
  }

  getProgrammeName() {
    if (this.modalData.programme_data.length == 1) {
      return this.getClassName(this.modalData.enrolled_year, this.modalData.programme_data[0].programme_id, this.modalData.programme_data[0].section);
    }
    else {
      return this.getClassName(this.modalData.enrolled_year, this.modalData.programme_data[0].programme_id, this.modalData.programme_data[0].section,
        this.modalData.programme_data[0].virtual_section
      );
    }
  }

  getProgrammeNameByList(enrolled_year, programme_data) {
    if (programme_data.length == 1) {
      return this.getClassName(enrolled_year, programme_data[0].programme_id);
    }
    else {
      return "Virtual Section " + programme_data[0].virtual_section;
    }
  }

  getClassName(enrolled_year: number, programme_id?: string, section?: string, virtual_section?: string) {
    if (virtual_section) {
      return "Virtual Section " + virtual_section;
    }
    else {
      var programme_name = this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
      return this.commonEnums.getClassName(enrolled_year, programme_name, section);
    }
  }
}
interface StudentAttendanceInterface {
  course_code: string,
  date: string,
  day: string,
  hour_number: number,
  attendance_data: StudentDataInterface[],
  programme_data?: {
    programme_id: string,
    programme_map_id: string,
    section: string,
    virtual_section?: string,
  }[],
  programme_id?: string,
  section?: string
  virtual_section?: string,
}

interface StudentDataInterface {
  given_name: string,
  is_present: boolean,
  leave_applied: boolean,
  middle_name: string,
  od_given: boolean,
  roll_no: string
}

interface TimetableInterface {
  date: string,
  day_name: string,
  day_wise_order_info: string,
  day_wise_order_data: {
    day: string,
    hours: HourInterface[]
  }
}

interface HourInterface {
  hour_number: number,
  course_data: CourseDataInterface[]
  adjusted_data?: AdjustmentInterface[] //done by me,
  can_mark_adjustment?: boolean
}

interface CourseDataInterface {
  course_code: string,
  course_name: string,
  part: string,
  start_time: string,
  end_time: string,
  duration: number,
  enrolled_year: number,
  semester: number,
  staff_data: {
    acedemic_year: string,
    is_primary: boolean,
    odd_even_semester: string,
    staff_id: string,
    staff_name: string
  },
  programme_data: {
    programme_id: string,
    programme_map_id: string,
    section: string,
    virtual_section?: string,
  }[],
  room_data: {
    room_no: string
  },
  type: string,
  can_mark_attendance: boolean,
  is_marked: boolean,
  re_open_request_status: string,
  absent_students_count?: number,
  is_adjusted?: boolean,
  adjustment_data?: { //other user
    adjusted_by_staff_id: string,
    adjusted_by_staff_sub?: string,
  }
}

interface AdjustmentInterface {
  date: string,
  day: string,
  hour_number: number,
  adjustment_data: AdjustmentDataInterface
}

interface AdjustmentDataInterface {
  enrolled_year: number,
  course_code: string,
  programme_data: {
    programme_id: string,
    programme_map_id: string,
    section: string,
    virtual_section?: string,
  }[],
  can_mark_attendance: boolean,
}

interface StaffLateEntryReportInterface {
  date: string,
  day: string,
  hour_number: number,
  course_code: string,
  course_name: string,
  re_open_request_status: string,
}