<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- Container -->
    <div class="container-fluid">
        <!-- breadcrump -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Programme Template - Mapping</h2>
                <p class="mg-b-0"></p>
            </div>
        </div>

        <!-- Table View -->

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Programme Template - Mapping</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Programme Template for Report Generation</p>
                    </div>
                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Add New</button>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Programme Name</th>
                                        <th>Template Name</th>
                                        <th style="width: 25%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let program of program_list; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ program.display_name }}</td>
                                        <td>{{ program.template_name }}</td>
                                        <td>
                                            <div style="display: flex; gap: 20px;">
                                                <button *ngIf="program.has_program_template_mapping; else elseBlock"
                                                    class="btn btn-primary btn-md"
                                                    (click)="onEditClicks(program.template_id, program._id)">
                                                    Edit Data
                                                </button>
                                                <ng-template #elseBlock>
                                                    <button class="btn btn-primary btn-md"
                                                        (click)="onAddClicks(program.template_id, program._id)">
                                                        Add Data
                                                    </button>
                                                </ng-template>
                                                <button class="btn btn-danger btn-md"
                                                    (click)="getprogramTemplateById(program._id)">Delete</button>
                                                <!-- <button class="btn btn-dark btn-md" >Print</button> -->
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Program Modal -->
<div class="modal" id="program-modal-popup">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="program_template">
            <div class="modal-header">
                <h6 class="modal-title">Program Template</h6>
            </div>
            <div class="modal-body">
                <div class="card card-body pd-20 pd-md-40">
                    <form [formGroup]="program_template" class="form-horizontal">
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                            <select formControlName="programType" class="form-control"
                                (change)="onFilterSelectChange('programme_type_id',$event)">
                                <option value="">Select Programme Type</option>
                                <option
                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="programme_type.programe_type_id">{{programme_type.name}}</option>
                            </select>
                            <div *ngIf="isInvalid('programType')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Programme Type is required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                            <select formControlName="finance_type" class="form-control"
                                (change)="onFilterSelectChange('finance_type',$event)">
                                <option value="">Select Finance Type</option>
                                <option
                                    *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="finance_type.id">{{finance_type.text}}</option>
                            </select>
                            <div *ngIf="isInvalid('finance_type')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Programme Type is required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme</label>
                            <select formControlName="programme_id" class="form-control"
                                (change)="onFilterSelectChange('programme_id',$event)">
                                <option value="">All Programmes</option>
                                <option
                                    *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                            </select>
                            <div *ngIf="isInvalid('programme_id')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Program Name is required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Template</label>
                            <select (change)="categoryChange($event)" formControlName="template_id"
                                class="form-control">
                                <option value="">Select Template</option>
                                <option *ngFor="let templates of program;let i=index;trackBy:fromDataResolver.identify;"
                                    [ngValue]="templates._id">{{ templates.template_name }}</option>
                            </select>

                            <div *ngIf="isInvalid('template_id')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Template Name is required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-center" *ngIf="!this.program_template.value._id">
                            <button class="btn btn-primary mg-r-20" (click)="addData()"
                                [disabled]="canDisable()">Save</button>

                            <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                            <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                        </div>
                        <div class="text-center" *ngIf="this.program_template.value._id">

                            <button class="btn btn-primary mg-r-20" (click)="addData()"
                                [disabled]="canDisable()">Update</button>

                            <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                            <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>