<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Terminal Exam Results</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Terminal Exam Result</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">To view the students terminal exam results</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Month</label>
                                        <select formControlName="month" class="form-control">
                                            <option value="0">Select Month</option>
                                            <option
                                                *ngFor="let month of commonEnums.months;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="month.id">{{month.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Year</label>
                                        <select formControlName="year" class="form-control">
                                            <option value="0">Select year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(10);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="programme_search_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>

                    <div class="row" *ngIf="course_data_list">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-info mg-r-10" (click)="printData()">Print</button>
                        </div>
                    </div>
                    <div class="card-body" id="print-area">
                        <div class="table-responsive" id="print-table" *ngIf="course_data_list">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <div class="row">
                                                <div class="col-md-12  text-center mg-t-10 mg-b-10">
                                                    <p class="tx-20 mg-b-10"> AYYA NADAR JANAKI AMMAL COLLEGE
                                                        (Autonomous),
                                                        SIVAKASI.</p>
                                                    <p class="tx-16"> Terminal Examination
                                                        Results - {{getMonth(course_data_list?.query?.month)}}
                                                        {{course_data_list?.query?.year}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1"></div>
                                                <div class="col-md-5 text-left">Class:
                                                    {{getProgrammeName(course_data_list?.query?.programme_id)}}
                                                    {{course_data_list?.query?.section}}</div>
                                                <div class="col-md-5 text-right">Date: {{today_date |
                                                    date:'dd/MM/yyyy' }}</div>
                                                <div class="col-md-1"></div>
                                            </div>
                                        </th>
                                    </tr>

                                    <tr style="text-align: center;">
                                        <th style="width: 5%">REG.NO</th>
                                        <th>SUBJECTS PASSED
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let student_data of course_data_list.data;let j=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td scope="row" style="width: 5%;">
                                                {{student_data.roll_no}}
                                            </td>
                                            <td class="text-uppercase">
                                                <div style="float: right;">
                                                    <span style="margin-right: 10px;"
                                                        *ngFor="let course_code of student_data.course_code_list;let k=index;trackBy:fromDataResolver.identify;">
                                                        {{course_code.course_code}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td colspan="2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>Note: <b>AA</b> - Absent &nbsp;&nbsp; <b>NP</b> - Not
                                                        Permitted
                                                        &nbsp;&nbsp; <b>NR</b> - Not
                                                        Registered &nbsp;&nbsp; <b>**</b> - withheld &nbsp;&nbsp;
                                                        <b>MP</b> -
                                                        Malpractice
                                                        &nbsp;&nbsp; - Theory (P) - Practical
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                                <ng-container *ngIf="!course_list_loading && !course_data_list">
                                    <tr>
                                        <th colspan="13" class="text-center">No Records found</th>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="course_list_loading && !course_data_list">
                                    <tr>
                                        <th colspan="13" class="text-center">Loading Please wait</th>
                                    </tr>
                                </ng-container>


                            </table>
                        </div>
                    </div>


                </div>
            </div>

        </div>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>