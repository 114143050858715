<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add Family Member</button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage your family members (parents/guardians) and create
                            account</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 20%;">Name</th>
                                        <th style="width: 20%;">Email</th>
                                        <th style="width: 10%;">Mobile No.</th>
                                        <th style="width: 15%;">Aadhaar No.</th>
                                        <th style="width: 10%;">Relationship</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let member of member_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{member.name}}
                                            </td>
                                            <td>
                                                {{member.email}}
                                            </td>
                                            <td>
                                                {{member.mobile_number}}
                                            </td>
                                            <td>
                                                {{member.aadhaar_number}}
                                                <ng-container *ngIf="member.aadhaar_url">
                                                    <br>
                                                    <a [href]="member.aadhaar_url" target="_blank">View</a>
                                                </ng-container>
                                            </td>
                                            <td>
                                                {{member.relationship}}
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-info mg-r-10"
                                                    (click)="onEditClick(member)">
                                                    <fa-icon [icon]="fonts.faPencilAlt" data-toggle="tooltip"
                                                        title="Edit Member"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!member_list_loading && member_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="member_list_loading && member_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="member_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Family Member</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="member_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Member
                                                Name</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Member Name" formControlName="name">
                                            <div *ngIf="isInvalid('name')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Member Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Email</label>
                                            <input class="form-control" type="email"
                                                placeholder="Enter Member Email, if available" formControlName="email">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Mobile
                                                Number</label>
                                            <input class="form-control" required="" type="number"
                                                formControlName="mobile_number">
                                            <div *ngIf="isInvalid('mobile_number')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Member Mobile No. is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Aadhaar
                                                Number</label>
                                            <input class="form-control" required="" type="number"
                                                formControlName="aadhaar_number">
                                            <div *ngIf="isInvalid('aadhaar_number')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Member Aadhaar No. is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload Aadhaar
                                                Scanned Copy </label>
                                            <input class="form-control" required="" type="file"
                                                (change)="uploadFile($event)">
                                            <ng-container *ngIf="member_form.value.aadhaar_url">
                                                <br>
                                                <a [href]="member_form.value.aadhaar_url" target="_blank">View Uploaded
                                                    File</a>
                                            </ng-container>
                                            <div *ngIf="isInvalid('aadhaar_url')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Aadhaar Copy is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Relationship with member </label>
                                            <select class="form-control " id="relationship_name"
                                                name="relationship_name" formControlName="relationship">
                                                <option value="">Select Relationship</option>
                                                <option
                                                    *ngFor="let relationship_name of commonEnums.relationship_names;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="relationship_name">
                                                    {{relationship_name}}
                                                </option>
                                            </select>
                                            <div *ngIf="isInvalid('relationship')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Member Relationship is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Member
                                                Occupation</label>
                                            <input class="form-control" type="text"
                                                placeholder="Enter Member Occupation, if available"
                                                formControlName="occupation">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Member
                                                Salary</label>
                                            <input class="form-control" type="number"
                                                placeholder="Enter Member Salary, if available"
                                                formControlName="monthly_income">
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.member_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.member_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.member_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.member_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.member_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.member_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.member_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.member_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.member_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.member_form.value._id && delete_loading" disabled>Deleting...</button>
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>