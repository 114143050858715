import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-account-master',
  templateUrl: './account-master.component.html',
  styleUrls: ['./account-master.component.scss']
})
export class AccountMasterComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  category_list: any[] = [];
  category_list_loading = false;
  programme_type_list: string[] = [];
  account_list: any[] = [];
  account_list_loading = false;

  account: any | undefined = undefined;
  account_save_loading = false;
  account_delete_loading = false;
  purposes: string[] = ["EXAM_FEE", "PROGRAMME_FEE", "HOSTEL_FEE"];

  account_form: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getFormDataList();
    this.getProgrammeTypeList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
        for (var i = 0; i < this.category_list.length; i++) {
          this.programme_type_list.push(this.category_list[i].programme_type_id);
        }
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async onAddClick(): Promise<void> {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(account: any): Promise<void> {
    this.account = account;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onSaveClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.account_save_loading = true;

      const account_data = this.account_form.value;
      let save_respose;
      if (account_data._id) {
        // update record
        save_respose = await this.restService.createAccount(account_data);
      } else {
        // create record
        if (!account_data.account_no) {
          alert('Please enter account no');
          return;
        }
        if (!account_data.reg_id) {
          alert('Please enter Reg. id');
          return;
        }
        if (!account_data.sub_acc_id) {
          alert('Please enter Sub Acc id');
          return;
        }
        save_respose = await this.restService.createAccount(account_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.account_save_loading = false;
    }
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      this.account_delete_loading = true;

      const account_data = this.account_form.value;
      const save_respose = await this.restService.deleteAccountById(account_data._id);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.getFormDataList();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.account_delete_loading = false;
    }
  }




  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  async getFormDataList(): Promise<void> {
    try {
      this.prepareVariablesBeforeLoad();
      const service_response = await this.restService.getAccountList();
      if (service_response && service_response.success) {
        this.account_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.prepareVariablesAfterLoad();
    }
  }


  prepareVariablesBeforeLoad(): void {
    this.account_list = [];
    this.account_list_loading = true;
  }
  prepareVariablesAfterLoad(): void {
    this.account_list_loading = false;
  }

  resetForm(): void {
    this.account_form = undefined;
    this.account = undefined;
  }
  buildForm(): void {

    if (!this.account) {
      this.account = {
        _id: '',
        reg_id: '',
        sub_acc_id: '',
        account_no: '',
        description: '',
        allowed_fee_types: [],
        allowed_programme_types: [],
        updatedAt: ''
      };
    }

    this.account_form = this.fb.group({
      _id: [this.account._id],
      reg_id: [this.account.reg_id, [Validators.required]],
      sub_acc_id: [this.account.sub_acc_id, [Validators.required]],
      account_no: [this.account.account_no, [Validators.required]],
      description: [this.account.description, [Validators.required]],
      allowed_fee_types: [this.account.allowed_fee_types, [Validators.required]],
      allowed_programme_types: [this.account.allowed_programme_types, [Validators.required]],
      updatedAt: [this.account.updatedAt, [Validators.required]],
    });
  }


}
