import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-student-od-request',
  templateUrl: './student-od-request.component.html',
  styleUrls: ['./student-od-request.component.scss']
})
export class StudentOdRequestComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  request_list_loading: boolean = false;
  save_loading: boolean = false;
  delete_loading: boolean = false;

  odRequests: IODRequest[] = [];
  odRequestForm: FormGroup;
  odRequest: IODRequest = {} as IODRequest;
  title = 'Make On-Duty Request';
  id = '';


  constructor(private restService: AdminservicesService, private formBuilder: FormBuilder) {
    this.buildForm();
  }

  getTittle(): string {
    return this.title;
  }

  ngOnInit(): void {
    this.getStudentODRequestList();
  }

  async getStudentODRequestList() {
    try {
      this.request_list_loading = true;
      this.odRequests = [];
      const search_data: any = {
        "academic_year": "2022-2023",
      }
      const service_response = await this.restService.searchODRequests(search_data);
      //replace with sub api
      if (service_response && service_response.success) {
        this.odRequests = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.request_list_loading = false;
    }

  }

  buildForm() {
    if (JSON.stringify(this.odRequest) == '{}') {
      this.odRequest = {
        _id: '',
        academic_year: '',
        start_date: '',
        end_date: '',
        hour_number: [],
        roll_nos: [],
        reason: '',
        status: 'NEW',
      };
    }
    this.odRequestForm = this.formBuilder.group({
      _id: [this.odRequest._id],
      academic_year: [this.odRequest.academic_year],
      start_date: [this.odRequest.start_date, [Validators.required]],
      end_date: [this.odRequest.end_date, [Validators.required]],
      hour_number: [((this.odRequest.hour_number.length > 0) ? this.odRequest.hour_number.join(',') : ''), [Validators.required]],
      roll_nos: [((this.odRequest.roll_nos.length > 0) ? this.odRequest.roll_nos.join(',') : ''), [Validators.required]],
      reason: [this.odRequest.reason, [Validators.required]],
      status: [this.odRequest.status, [Validators.required]],
    });

  }

  onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(request: IODRequest): Promise<void> {
    this.odRequest = request;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }


  isInvalid(formControl: string): boolean {
    return this.odRequestForm.touched && this.odRequestForm.get(formControl)?.errors !== null;
  }


  canDisable(): boolean {
    return this.odRequestForm.status !== 'VALID';
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
    this.addItem();
  }

  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  reset(): void {
    this.odRequestForm.reset();
    this.odRequestForm.controls['start_date'].setValue(new Date().toISOString().substring(0, 16))
    this.odRequestForm.controls['end_date'].setValue(new Date().toISOString().substring(0, 16))
  }
  async addItem(): Promise<void> {
    try {
      var form_data = this.odRequestForm.value;
      console.log(form_data);
      form_data.roll_nos = (form_data.roll_nos as string).split(',');
      form_data.hour_number = (form_data.hour_number as string).split(',');
      this.save_loading = true;
      if (this.odRequestForm.value._id) {
        const res = await this.restService.updateODRequest(this.odRequestForm.value._id, form_data);
        if (res) {
          alert("OD Request updated successfully.");
        }
      }
      else {
        const res = await this.restService.createODRequest(form_data);
        if (res) {
          alert("OD Request created successfully.");
        }
      }
      this.getStudentODRequestList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteODRequest(this.odRequestForm.get('_id')!.value);
      if (res.success) {
        alert("OD Request removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  getDateTimeView(date_time: Date) {
    return (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate()) + "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" + date_time.getFullYear() + " " +
      (date_time.getHours() > 12 ? date_time.getHours() - 12 : date_time.getHours()) + ":" +
      ((date_time.getMinutes() > 9) ? date_time.getMinutes() : ((date_time.getMinutes() == 0) ? date_time.getMinutes() + "0" : "0" + (date_time.getMinutes())));
  }

  getDateTime(date_time: Date) {
    return date_time.getFullYear() +
      "-" +
      ((date_time.getMonth() + 1) > 9 ? (date_time.getMonth() + 1) : "0" + (date_time.getMonth() + 1)) +
      "-" +
      (date_time.getDate() > 9 ? date_time.getDate() : "0" + date_time.getDate())
      + "T" +
      date_time.getHours()
      + ":" +
      ((date_time.getMinutes() > 9) ? date_time.getMinutes() : ((date_time.getMinutes() == 0) ? date_time.getMinutes() + "0" : "0" + (date_time.getMinutes())));
  }
}

interface IODRequest {
  _id?: string,
  academic_year?: string,
  start_date: string,
  end_date: string,
  hour_number: number[],
  roll_nos: string[],
  reason: string,
  status?: string
}