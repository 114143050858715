import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-edit-applicant',
  templateUrl: './edit-applicant.component.html',
  styleUrls: ['./edit-applicant.component.scss']
})
export class EditApplicantComponent implements OnInit {
  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;

  applicant_id = '';

  subject_list: any[] = [];


  user_info: any;
  user_form: any;

  parental_status = 'parent';
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      this.applicant_id = data.applicant_id;
    });
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getSchoolSubjects();
  }

  async getSchoolSubjects(): Promise<void> {
    try {
      const response = await this.restService.getSchoolSubjects();
      if (response.success && response.data) {
        this.subject_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  getSchoolSubjectsByPartType(part_type: number): any[] {
    return this.subject_list.filter(c => c.part_type === part_type);
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo(this.applicant_id);
      if (response.success && response.data) {
        this.user_info = response.data;
        this.buildUserForm();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  buildUserForm(): void {
    try {
      if (!this.user_info.address) {
        this.user_info.address = {};
      }
      if (!this.user_info.academics) {
        this.user_info.academics = [];
      }
      /* let sslc_academics = this.user_info.sslc_academics;
      if (!sslc_academics) {
        sslc_academics = {};
      }
      if (!sslc_academics.accademic_info) {
        sslc_academics.accademic_info = {};
      } */

      let hsc_academics = this.user_info.hsc_academics;
      if (!hsc_academics) {
        hsc_academics = {};
      }
      if (!hsc_academics.accademic_info) {
        hsc_academics.accademic_info = {};
      }

      let degree_academics = this.user_info.degree_academics;
      if (!degree_academics) {
        degree_academics = {};
      }
      if (!degree_academics.accademic_info) {
        degree_academics.accademic_info = {};
      }




      this.user_form = this.fb.group({
        _id: [this.user_info._id],
        aadhar_card_no: [this.user_info.aadhar_card_no],
        aadhar_card_no_file_front: [this.user_info.aadhar_card_no_file_front],
        aadhar_card_no_file_back: [this.user_info.aadhar_card_no_file_back],
        mobile_number: [this.user_info.mobile_number],
        mobile_number_2: [this.user_info.mobile_number_2],
        mobile_number_3: [this.user_info.mobile_number_3],
        mobile_number_4: [this.user_info.mobile_number_4],
        mobile_number_5: [this.user_info.mobile_number_5],
        email: [this.user_info.email],
        middle_name: [this.user_info.middle_name],
        given_name: [this.user_info.given_name],
        birth_date: [DateHelper.convertToControlDate(new Date(this.user_info.birth_date))],
        gender: [this.user_info.gender || ''],
        blood_group: [this.user_info.blood_group || ''],
        community_id: [this.user_info.community_id || ''],
        community_id_file: [this.user_info.community_id_file || ''],
        caste: [this.user_info.caste || ''],
        religion: [this.user_info.religion || ''],
        physically_challanged: [this.user_info.physically_challanged || false],
        ex_service: [this.user_info.ex_service || false],
        ex_service_rank: [this.user_info.ex_service_rank || ''],
        ex_service_i_card_no: [this.user_info.ex_service_i_card_no || ''],
        sports_certs: [this.user_info.sports_certs || false],
        need_hostel: [this.user_info.need_hostel || false],
        need_bus: [this.user_info.need_bus || false],
        is_applicant_married: [this.user_info.is_applicant_married || false],
        nationality: [this.user_info.nationality || 'indian'],
        address: this.fb.group({
          country: [this.user_info.address.country || 'India'],
          state: [this.user_info.address.state || 'Tamil Nadu'],
          district: [this.user_info.address.district],
          city: [this.user_info.address.city],
          pincode: [this.user_info.address.pincode],
          street: [this.user_info.address.street],
        }),
        identification_marks: this.fb.array(this.prepareIdentificationMasrs(this.user_info.identification_marks)),
        parental_status: [this.parental_status],
        family_members: this.fb.array(this.prepareFamilyInfo(this.user_info.family_members)),
        apply_for: [this.user_info.apply_for || ''],
        /* sslc_academics: this.fb.group({
          accedemic_type: ['SSLC'],
          accademic_info: this.fb.group({
            school_name: [sslc_academics.accademic_info.school_name || ''],
            school_place: [sslc_academics.accademic_info.school_place || ''],
            course_name: [sslc_academics.accademic_info.course_name || ''],
            exam_reg_no: [sslc_academics.accademic_info.exam_reg_no || ''],
            year_month_passout: [sslc_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [sslc_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [sslc_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [sslc_academics.accademic_info.medium_of_study || 'tamil'],
            syllabus_type: [sslc_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
            mark_statement_file: [sslc_academics.accademic_info.mark_statement_file || ''],
          }),
          marks: this.fb.array(this.prepareAccedamicMarkInfo('SSLC', 'STATE_BOARD', sslc_academics.marks))
        }), */
        hsc_academics: this.fb.group({
          accedemic_type: ['HSC'],
          accademic_info: this.fb.group({
            school_name: [hsc_academics.accademic_info.school_name || ''],
            school_place: [hsc_academics.accademic_info.school_place || ''],
            course_name: [hsc_academics.accademic_info.course_name || ''],
            exam_reg_no: [hsc_academics.accademic_info.exam_reg_no || ''],
            vocational: [hsc_academics.accademic_info.vocational || false],
            year_month_passout: [hsc_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [hsc_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [hsc_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [hsc_academics.accademic_info.medium_of_study || 'tamil'],
            first_graduate: [hsc_academics.accademic_info.first_graduate || false],
            mark_verified: [hsc_academics.accademic_info.mark_verified || false],
            syllabus_type: [hsc_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
            mark_statement_file: [hsc_academics.accademic_info.mark_statement_file || ''],
          }),
          marks: this.fb.array(this.prepare12thAccedamicMarkInfo('HSC',
            (hsc_academics.accademic_info.vocational || false), 'STATE_BOARD', hsc_academics.marks))
        }),
        degree_academics: this.fb.group({
          accedemic_type: ['DEGREE'],
          accademic_info: this.fb.group({
            is_anjac: [degree_academics.accademic_info.school_name === 'Ayya Nadar Janaki Ammal College' ? 'true' : 'false'],
            school_name: [degree_academics.accademic_info.school_name || ''],
            school_place: [degree_academics.accademic_info.school_place || ''],
            course_name: [degree_academics.accademic_info.course_name || ''],
            exam_reg_no: [degree_academics.accademic_info.exam_reg_no || ''],
            year_month_passout: [degree_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [degree_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [degree_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [degree_academics.accademic_info.medium_of_study || 'english'],
            first_graduate: [degree_academics.accademic_info.first_graduate || false]
          }),
          marks: this.fb.array(this.prepareDegreeAccedamicMarkInfo('DEGREE', degree_academics.marks))
        })

      });

    } catch (error) {
      console.log(error);
    }

  }

  private prepareDegreeAccedamicMarkInfo(acadamic_type: string, marks: any[]): any {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({
          sno: [mark.sno],
          semester: [mark.semester || (mark.sno + 1)],
          semester_file: [mark.semester_file || ''],
          no_of_subjects: [mark.no_of_subjects || 4],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      for (let index = 0; index < 6; index++) {
        value_to_return.push(this.fb.group({
          sno: [index],
          semester: [index + 1],
          semester_file: [''],
          no_of_subjects: [4],
          mark: [0],
        }));
      }
    }
    return value_to_return;
  }


  private prepareAccedamicMarkInfo(acadamic_type: string, syllabus_type: string, marks: any[]): any[] {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({

          sno: [mark.sno],
          subject_id: [mark.subject_id || ''],
          subject_name: [mark.subject_name || ''],
          subject_type: [mark.subject_type || ''],
          part_type: [mark.part_type || ''],
          part_index: [mark.part_index || ''],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      let subjects = [];
      if (syllabus_type === 'ICSE') {
        subjects = [
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 2,
            sno: 4
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 3,
            sno: 5
          }
        ];
      } else {
        subjects = [
          {
            subject_type: 'LANGUAGE',
            subject_name: 'TAMIL',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: 'LANGUAGE',
            subject_name: 'ENGLISH',
            part_type: 1,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: 'THEORY',
            subject_name: 'MATHEMATICS',
            part_type: 2,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: 'THEORY',
            subject_name: 'SCIENCE',
            part_type: 2,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: 'THEORY',
            subject_name: 'SOCIAL SCIENCE',
            part_type: 2,
            part_index: 2,
            sno: 4
          }
        ];
      }
      for (const subject of subjects) {
        value_to_return.push(this.fb.group({
          sno: [subject.sno],
          subject_id: [''],
          subject_name: [subject.subject_name],
          subject_type: [subject.subject_type],
          part_type: [subject.part_type],
          part_index: [subject.part_index],
          mark: [0],
        }));

      }

    }



    return value_to_return;
  }

  async onBoardChange(): Promise<void> {
    const vocational = this.user_form.value.hsc_academics.accademic_info.vocational;
    const syllabus_type = this.user_form.value.hsc_academics.accademic_info.syllabus_type;
    const values = this.prepare12thAccedamicMarkInfo('HSC', vocational, syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('hsc_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  }
  private prepare12thAccedamicMarkInfo(acadamic_type: string, vocational: boolean, syllabus_type: string, marks: any[]): any {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({

          sno: [mark.sno],
          subject_id: [mark.subject_id || ''],
          subject_name: [mark.subject_name || ''],
          subject_type: [mark.subject_type || ''],
          part_type: [mark.part_type || ''],
          part_index: [mark.part_index || ''],
          //mark_1: [mark.mark_1 || 0],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      let subjects = [];
      if (syllabus_type === 'STATE_BOARD') {
        if (vocational === true) {
          subjects = [
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 1,
              part_index: 0,
              sno: 0
            },
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 2,
              part_index: 1,
              sno: 1
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 0,
              sno: 2
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 1,
              sno: 3
            },
            {
              subject_type: 'PRACTICAL',
              subject_name: '',
              part_type: 4,
              part_index: 0,
              sno: 4
            },
            {
              subject_type: 'PRACTICAL',
              subject_name: '',
              part_type: 4,
              part_index: 1,
              sno: 5
            }
          ];
        } else {
          subjects = [
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 1,
              part_index: 0,
              sno: 0
            },
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 2,
              part_index: 1,
              sno: 1
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 0,
              sno: 2
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 1,
              sno: 3
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 3,
              sno: 4
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 4,
              sno: 5
            }
          ];
        }

      } else {
        subjects = [
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 3,
            sno: 4
          }
        ];
      }

      for (const subject of subjects) {
        value_to_return.push(this.fb.group({
          sno: [subject.sno],
          subject_id: [''],
          subject_name: [subject.subject_name],
          subject_type: [subject.subject_type],
          part_type: [subject.part_type],
          part_index: [subject.part_index],
          //mark_1: [0],
          mark: [0],
        }));

      }

    }



    return value_to_return;
  }



  /* async onSSLCBoardChange(): Promise<void> {
    const syllabus_type = this.user_form.value.sslc_academics.accademic_info.syllabus_type;
    const values = this.prepareAccedamicMarkInfo('SSLC', syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('sslc_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  } */


  private prepareIdentificationMasrs(id_marks: string[]): any[] {
    const prepared_id_marks = [];
    if (!id_marks) {
      id_marks = [];
    }
    if (id_marks && id_marks.length > 0) {
      for (const id_mark of id_marks) {
        prepared_id_marks.push(this.fb.control(id_mark));
      }
    }
    if (prepared_id_marks.length < 2) {
      const length = 2 - prepared_id_marks.length;
      for (let index = 0; index < length; index++) {
        prepared_id_marks.push(this.fb.control(''));
      }
    }
    return prepared_id_marks;
  }

  private prepareFamilyInfo(data: any[]): any[] {
    const family_infos = [];
    if (!data) {
      data = [];
    }

    const father = data.find(c => c.relationship_type.toLowerCase() === 'father');
    if (father) {
      family_infos.push(
        this.fb.group({
          id: [father.id],
          relationship_type: ['Father'],
          relationship_name: [''],
          given_name: [father.given_name || ''],
          occupation: [father.occupation || ''],
          salary: [father.salary || '', [Validators.min(1)]],
          // contact_no: [father.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [father.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Father'],
          relationship_name: [''],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]],

          // contact_no: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: ["", [Validators.email]]

        })
      );
    }
    const mother = data.find(c => c.relationship_type.toLowerCase() === 'mother');
    if (mother) {
      family_infos.push(
        this.fb.group({
          id: [mother.id],
          relationship_type: ['Mother'],
          relationship_name: [''],
          given_name: [mother.given_name || ''],
          occupation: [mother.occupation || ''],
          salary: [mother.salary || '', [Validators.min(1)]],

          // contact_no: [mother.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [mother.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Mother'],
          relationship_name: [''],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]],

          // contact_no: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: ["", [Validators.email]]

        })
      );
    }

    const guardian = data.find(c => c.relationship_type.toLowerCase() === 'guardian');
    if (guardian) {
      family_infos.push(
        this.fb.group({
          id: [guardian.id],
          relationship_type: [guardian.relationship_type],
          relationship_name: [guardian.relationship_name],
          given_name: [guardian.given_name || ''],
          occupation: [guardian.occupation || ''],
          salary: [guardian.salary || '', [Validators.min(1)]],

          // contact_no: [guardian.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [guardian.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Guardian'],
          relationship_name: ['Uncle'],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]]

        })
      );
    }


    return family_infos;


  }

  private validateMobile(mobile: string): boolean {
    if (!mobile) {
      return false;
    }
    if (mobile.trim().length === 0) {
      return false;
    }
    if (mobile.trim().length !== 10) {
      return false;
    }
    try {
      parseFloat(mobile);
    } catch (error) {
      return false;
    }

    return true;
  }

  private validateString(val: string): boolean {
    if (!val) {
      return false;
    }
    if (val.trim().length === 0) {
      return false;
    }
    return true;
  }

  private validateDate(val: string): boolean {
    if (!val) {
      return false;
    }
    try {
      const datae = new Date(val);
    } catch (error) {
      return false;
    }
    return true;
  }
  private validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async onProfileSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      const user_info = this.user_form.value;

      if (!this.validateEmail(user_info.email)) {
        alert('Please enter valid email');
        return;
      }

      if (user_info.apply_for === 'UG') {
        //const sslc_academics = user_info.sslc_academics;

        /* sslc_academics.accademic_info.year_month_passout = sslc_academics.accademic_info.year_of_passout + '-'
          + sslc_academics.accademic_info.month_of_passout.padStart(2, '0'); */


        const valid_marks = [];
        /* for (const mark of sslc_academics.marks) {
          try {

            mark.mark = parseInt(mark.mark, 10);
            if (mark.mark > 0 && mark.mark <= 100 && mark.subject_name) {
              valid_marks.push(mark);
            }
          } catch (error) {

          }
        }
        if (valid_marks.length !== sslc_academics.marks.length) {
          alert('Please enter valid SSLC marks');
          return;
        } */


        //user_info.sslc_academics = sslc_academics;


        const hsc_academics = user_info.hsc_academics;


        hsc_academics.accademic_info.year_month_passout = hsc_academics.accademic_info.year_of_passout + '-'
          + hsc_academics.accademic_info.month_of_passout.padStart(2, '0');

        const valid_marks_2 = [];
        const validate_mark = false;
        for (const mark of hsc_academics.marks) {
          let is_valid = true;
          if (validate_mark) {
            try {
              //mark.mark_1 = parseInt(mark.mark_1, 10);
              //mark.mark_1 = Math.abs(mark.mark_1);
              /* if (mark.mark_1 > 0 && mark.mark_1 <= 100) {
                is_valid = true;
              } else {
                is_valid = false;
              } */
            } catch (error) {
              is_valid = false;
            }
            if (is_valid) {

              try {
                mark.mark = parseInt(mark.mark, 10);
                mark.mark = Math.abs(mark.mark);
                if (mark.mark > 0 && mark.mark <= 100) {
                  is_valid = true;
                } else {
                  is_valid = false;
                }
              } catch (error) {
                is_valid = false;
              }


            }
          }

          if (is_valid) {
            if (mark.subject_id) {
              const subject = this.subject_list.find(c => c.subject_id === mark.subject_id);
              mark.subject_name = subject.subject_name;
            }
            if (mark.subject_name || mark.subject_id) {
              is_valid = true;
            } else {
              is_valid = false;
            }
          }
          if (is_valid) {
            valid_marks_2.push(mark);
          }

        }
        if (valid_marks_2.length !== hsc_academics.marks.length) {
          alert('Please enter valid HSC marks');
          return;
        }
        user_info.hsc_academics = hsc_academics;


      } else if (user_info.apply_for === 'PG') {
        const degree_academics = user_info.degree_academics;

        if (degree_academics.accademic_info.is_anjac === 'true') {
          degree_academics.accademic_info.school_name = 'Ayya Nadar Janaki Ammal College';
        }

        degree_academics.accademic_info.year_month_passout = degree_academics.accademic_info.year_of_passout + '-'
          + degree_academics.accademic_info.month_of_passout.padStart(2, '0');

        const valid_marks_2 = [];
        let has_error = false;
        const validate_mark = true;
        for (const mark of degree_academics.marks) {
          const max_mark = Number(mark.no_of_subjects) * 100;

          if (validate_mark) {
            try {
              mark.mark = parseInt(mark.mark, 10);
              mark.mark = Math.abs(mark.mark);
              if (mark.mark !== 0) {
                if (mark.mark > 0 && mark.mark <= max_mark) {
                  if (mark.semester <= 4) {
                    // if (!this.validateString(mark.semester_file)) {
                    //   alert('Please upload Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' mark certificate');
                    //   has_error = true;
                    //   break;
                    // }
                  }
                } else {
                  has_error = true;
                  alert('Error : In Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' Mark must be between 1 to ' + max_mark);
                  break;
                }
              }
            } catch (error) {
              has_error = true;
              alert('Error : In Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' Please enter valid mark');
              break;
            }

          }
          valid_marks_2.push(mark);

        }
        if (has_error) {
          return;
        }
        const valid_marks_2_filter = valid_marks_2.filter(c => c.mark !== 0);
        if (valid_marks_2_filter.length < 4) {
          alert('Please enter at least 4 semester marks');
          return;
        }
        user_info.degree_academics = degree_academics;
      }


      console.log(user_info);

      const respose = await this.restService.saveApplicantInfo(this.applicant_id, user_info);
      if (respose.success) {
        alert('Saved Successfully');
      }

    } catch (error) {
      console.error(error);
      alert('Error while saving Profile');
    }

  }

  async uploadImage(e: any, form: FormGroup, key: string): Promise<void> {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadFile(formData);
      if (response.success && response.data && response.data.url) {
        const val = form.get(key);
        if (val) {
          val.setValue(response.data.url);
        }

      }
    } catch (error) {

    } finally {

    }
  }


}
