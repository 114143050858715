import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-entrance-questions',
  templateUrl: './entrance-questions.component.html',
  styleUrls: ['./entrance-questions.component.scss']
})
export class EntranceQuestionsComponent implements OnInit {

  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;

  bulk_render_data: Modal = {
    return_type: "FILE",
    file_upload_only: false,
    modal_id: "ques_modal",
    modal_button_text: "Entrance Exam Question Bulk Upload",
    modal_header: "Entrance Exam Question Bulk Upload",
    list_name: "Entrance Exam Question Bulk Upload",
    csv_data: {
      columns: [
        { column_name: "Question No", column_type: "required" },
        { column_name: "Question", column_type: "required" },
        { column_name: "Option A", column_type: "required" },
        { column_name: "Option B", column_type: "required" },
        { column_name: "Option C", column_type: "required" },
        { column_name: "Option D", column_type: "required" },
        { column_name: "Correct Answer", column_type: "required" },
        { column_name: "Category", column_type: "required" },
        {column_name: "Level", column_type: "required"},
        {column_name: "Language", column_type: "required"}
        
      ]
    },
    table_data: {
      column_names: ["Question No","Question","Option A", "Option B", "Option C", "Option D", "Correct Answer", "Category","Level","Language"],
      column_values: ["1","Statement of Affairs is a statement of ", "Receipts and payment", "Profit and Loss", "Assets & Liabilities", "Expenditure and Income", "C", "PA","H","E"]
    },
    show_loader: false,
    download_note: "Valid options for Level is H, L only. H-High; L-Low; Valid options for Language is E, T only. E-English, T-Tamil"
  };


  @ViewChildren('editors') public editors: any;

  // Master data
  admission_id = '';
  exam_id = '';

  entrance_exam_search_form: any;
  entrance_exam_search_form_loading = false;

  categories = ['ALL', 'PGMAT', 'PGPHY', 'PGCHE', 'PGBOT', 'PGZOO', 'PGMB', 'PGBT', 'PGCOM', 'PGENG', 'MATHS', 'PHYSICS', 'CHEMISTRY', 'BOTANY', 'ZOOLOGY', 'MICROBIOLOGY', 'BIOTECHNOLOGY', 'COMMERCE', 'ACCOUNT', 'ECONOMICS', 'GK', 'ENGLISH', 'CA', 'CS', 'PA'];
  languages = ['ENGLISH', 'TAMIL'];
  levels = [{ id: 'L', text: 'LOW' }, { id: 'H', text: 'HIGH' }];
  correct_options = ['A', 'B', 'C', 'D'];

  // Core data
  entrance_exam_question_list: any[] = [];
  entrance_exam_question_list_loading = false;
  entrance_exam_question_delete_loading = false;

  entrance_exam_question_form: any;
  entrance_exam_question_form_loading = false;
  entrance_exam_question: any;
  entrance_exam_question_locale: any;

  entrance_exam_question_upload_loading = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    // defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['code']
    ]
  };

  editor_popup_form: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
      this.exam_id = data.exam_id;

    });
  }

  ngOnInit(): void {
    this.buildExamSearch();

  }

  buildExamSearch(): void {
    this.entrance_exam_search_form = this.fb.group({
      admission_id: [this.admission_id],
      exam_id: [this.exam_id],
      category_id: [''],
    });
  }


  async onCategoryChange(): Promise<void> {
    this.findEntranceExamQuestionList();
  }

  async findEntranceExamQuestionList(): Promise<void> {
    try {
      this.entrance_exam_question_list_loading = true;
      this.entrance_exam_question_list = [];
      const data = {
        admission_id: this.admission_id,
        exam_id: this.exam_id,
        category_id: this.entrance_exam_search_form.value.category_id
      };
      const response = await this.restService.findEntranceExamQuestionList(data);
      if (response.success) {
        this.entrance_exam_question_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.entrance_exam_question_list_loading = false;
    }
  }

  async addNewQuestion(): Promise<void> {
    this.buildNewQuestionForm();
    JQueryHelper.openModal('#modal-question', { keyboard: false, backdrop: 'static' });
  }

  async onEditExamClick(question: any): Promise<void> {
    this.entrance_exam_question = question;
    this.buildNewQuestionForm();
    JQueryHelper.openModal('#modal-question', { keyboard: false, backdrop: 'static' });
  }

  async buildNewQuestionForm(): Promise<void> {
    if (!this.entrance_exam_question) {
      let sno = 1;
      if (this.entrance_exam_question_list.length > 0) {
        const last_item = this.entrance_exam_question_list[this.entrance_exam_question_list.length - 1];
        if (last_item.sno) {
          sno = Number(last_item.sno) + 1;
        } else {
          sno = this.entrance_exam_question_list.length + 1;
        }
      }
      this.entrance_exam_question = {
        sno,
        admission_id: this.admission_id,
        exam_id: this.exam_id,
        category_id: this.entrance_exam_search_form.value.category_id,
        question_locale: [
          {
            admission_id: this.admission_id,
            exam_id: this.exam_id,
            category_id: this.entrance_exam_search_form.value.category_id,
            question_id: '',
            question: '',
            locale: this.languages[0],
            options: [
              {
                sno: 0,
                option_id: 'A',
                option: '',
                locale: this.languages[0]
              },
              {
                sno: 1,
                option_id: 'B',
                option: '',
                locale: this.languages[0]
              },
              {
                sno: 2,
                option_id: 'C',
                option: '',
                locale: this.languages[0]
              }, {
                sno: 3,
                option_id: 'D',
                option: '',
                locale: this.languages[0]
              }
            ]
          },
          {
            admission_id: this.admission_id,
            exam_id: this.exam_id,
            category_id: this.entrance_exam_search_form.value.category_id,
            question_id: '',
            question: '',
            locale: this.languages[1],
            options: [
              {
                sno: 0,
                option_id: 'A',
                option: '',
                locale: this.languages[1]
              },
              {
                sno: 1,
                option_id: 'B',
                option: '',
                locale: this.languages[1]
              },
              {
                sno: 2,
                option_id: 'C',
                option: '',
                locale: this.languages[1]
              }, {
                sno: 3,
                option_id: 'D',
                option: '',
                locale: this.languages[1]
              }
            ]
          }
        ],
        correct_option: '',
        level: this.levels[0].id,
        short_options: true
      };
    }
    this.entrance_exam_question_form = this.fb.group({
      sno: [this.entrance_exam_question.sno],
      admission_id: [this.entrance_exam_question.admission_id],
      exam_id: [this.entrance_exam_question.exam_id],
      category_id: [this.entrance_exam_question.category_id],
      correct_option: [this.entrance_exam_question.correct_option],
      level: [this.entrance_exam_question.level],
      short_options: [this.entrance_exam_question.short_options],
      question_locale: this.fb.array(this.buildQuestionLocale(this.entrance_exam_question.question_locale)),
    });

  }

  buildQuestionLocale(data: any[]): any[] {
    const value_to_return: any[] = [];
    if (!data) {
      data = [];
    }
    if (data.length < 2) {
      for (const language of this.languages) {
        if (!data.find(c => c.locale === language)) {
          data.push({
            admission_id: this.admission_id,
            exam_id: this.exam_id,
            category_id: this.entrance_exam_search_form.value.category_id,
            question_id: '',
            question: '',
            locale: language,
            options: [
              {
                sno: 0,
                option_id: 'A',
                option: '',
                locale: language
              },
              {
                sno: 1,
                option_id: 'B',
                option: '',
                locale: language
              },
              {
                sno: 2,
                option_id: 'C',
                option: '',
                locale: language
              }, {
                sno: 3,
                option_id: 'D',
                option: '',
                locale: language
              }
            ]
          });
        }
      }
    }

    data = data.sort((a, b) => {

      if (a.locale < b.locale) {
        return -1;
      }
      if (a.locale > b.locale) {
        return 1;
      }
      return 0;
    });

    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        question: [data_item.question],
        locale: [data_item.locale],
        options: this.fb.array(this.buildQuestionOptionLocale(data_item.options))
      }));
    }
    return value_to_return;
  }

  buildQuestionOptionLocale(data: any[]): any[] {
    const value_to_return: any[] = [];
    if (!data) {
      data = [];
    }
    if (data.length < 4) {

      for (const option of ['A', 'B', 'C', 'D']) {
        if (!data.find(c => c.option_id === option)) {
          data.push({
            sno: 0,
            option_id: option,
            option: '',
            locale: ''
          });
        }
      }
    }
    data = data.sort((a, b) => {

      if (a.option_id < b.option_id) {
        return -1;
      }
      if (a.option_id > b.option_id) {
        return 1;
      }
      return 0;
    });
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        sno: [data_item.sno],
        option_id: [data_item.option_id],
        option: [data_item.option],
        locale: [data_item.locale]
      }));
    }
    return value_to_return;
  }

  async onQuestionSaveClick(): Promise<void> {
    console.log(this.entrance_exam_question_form.value);
    try {
      this.entrance_exam_question_form_loading = true;
      const question_data = this.entrance_exam_question_form.value;
      question_data.category_id = this.entrance_exam_search_form.value.category_id;
      question_data.admission_id = this.admission_id;
      question_data.exam_id = this.exam_id;
      const valid_question_locale = [];
      let has_error = false;
      for (const question_locale of question_data.question_locale) {
        if (question_locale.question) {
          const valid_option_locale = [];
          let oi = 0;
          for (const option of question_locale.options) {
            option.sno = oi;
            if (option.option) {
              valid_option_locale.push(option);
            }
            oi++;
          }
          if (valid_option_locale.length === 4) {
            valid_question_locale.push(question_locale);
          } else {
            alert('Please fill valid 4 options in Language : ' + question_locale.locale);
            has_error = true;
            break;
          }
        }
      }
      if (has_error) {
        return;
      }
      question_data.question_locale = valid_question_locale;
      const response = await this.restService.createOrUpdateEntranceExamQuestion(question_data);
      if (response.success) {
        alert('Saved Successfully');
        await this.findEntranceExamQuestionList();
        this.onQuestionCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_question_form_loading = false;
    }
  }

  resetQuestionExamForm(): void {
    this.entrance_exam_question_form = null;
    this.entrance_exam_question = null;
  }

  async onQuestionCloseClick(): Promise<void> {
    this.resetQuestionExamForm();
    JQueryHelper.closeModal('#modal-question');
  }

  async addUploadQuestion(e: any): Promise<void> {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const query_data = {
        category_id: this.entrance_exam_search_form.value.category_id,
        admission_id: this.admission_id,
        exam_id: this.exam_id,
      };
      this.entrance_exam_question_upload_loading = true;
      const response = await this.restService.uploadQuestionFile(formData, query_data);
      if (response.success) {
        alert('Imported Successfully');
        await this.findEntranceExamQuestionList();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_question_upload_loading = false;
    }
  }

  async onDeleteExamQuestionClick(question: any): Promise<void> {
    try {
      if (question.deleting) {
        return;
      }
      const consent = confirm('Are you sure?');
      if (!consent) {
        return;
      }
      question.deleting = true;
      const response = await this.restService.removeExamQuestionById(question._id);
      if (response.success) {
        alert('Deleted Successfully');
        await this.findEntranceExamQuestionList();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      question.deleting = false;
    }
  }

  //#endregion

  openUploadImage(event: any, editor_ref: string): void {
    let editor: EditorComponent | undefined;
    for (const result of this.editors._results) {
      if (result.element.nativeElement && result.element.nativeElement.className) {
        const class_names: string[] = result.element.nativeElement.className.split(' ');
        if (class_names.includes(editor_ref)) {
          editor = result;
          break;
        }
      }
    }
    this.editor_popup_form = this.fb.group({
      image_url: [''],
      height: [''],
      width: [''],
      editor: [editor]
    });
    JQueryHelper.openModal('#modal-2', { keyboard: false, backdrop: 'static' });
  }
  async uploadImage(e: any, form: FormGroup): Promise<void> {

    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const img = new Image();

          img.src = (reader.result as string);
          img.onload = () => {
            // const image_url = form.get('image_url');
            // if (image_url) {
            //   image_url.setValue(img.src);
            // }
            // const height = form.get('height');
            // if (height) {
            //   height.setValue(img.height);
            // }
            // const width = form.get('width');
            // if (width) {
            //   width.setValue(img.width);
            // }
            if (form.value.editor) {
              form.value.editor.exec('insertImage', {
                src: img.src,
                height: img.height,
                width: img.width
              });
              JQueryHelper.closeModal('#modal-2');
            }
          };
        };

        reader.readAsDataURL(file);
      }

    } catch (error) {

    } finally {

    }
  }

  onCloseImageUpload(): void {
    JQueryHelper.closeModal('#modal-2');
  }

  async onDeleteAllQuestionsClick(): Promise<void> {
    try {
      if (this.entrance_exam_question_delete_loading) {
        return;
      }
      const consent = confirm('Are you sure?');
      if (!consent) {
        return;
      }
      this.entrance_exam_question_delete_loading = true;
      const data = {
        admission_id: this.admission_id,
        exam_id: this.exam_id,
        category_id: this.entrance_exam_search_form.value.category_id
      };
      const response = await this.restService.removeExamQuestionBulk(data);
      if (response.success) {
        alert('Deleted Successfully');
        this.findEntranceExamQuestionList();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.entrance_exam_question_delete_loading = false;
    }
  }

  async bulkUpload(e: any) {
    try {
      const file = e;
      console.log(file);
      var formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      const query_data = {
        category_id: this.entrance_exam_search_form.value.category_id,
        admission_id: this.admission_id,
        exam_id: this.exam_id,
      };
      this.entrance_exam_question_upload_loading = true;
      const response = await this.restService.uploadQuestionFile(formData, query_data);
      if (response.success) {
        alert('Imported Successfully');
        await this.findEntranceExamQuestionList();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.entrance_exam_question_upload_loading = false;
    }
  }
}
