import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-student-transport-request',
  templateUrl: './student-transport-request.component.html',
  styleUrls: ['./student-transport-request.component.scss']
})
export class StudentTransportRequestComponent implements OnInit {

  constructor(private domsanitizer: DomSanitizer, private adminService: AdminservicesService) { }
  load: number = 0;
  request_id: string = '';
  url: string = "";
  ngOnInit(): void {
    this.load = 1;
  }
  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.data.event_type == "redirect") {
      if (this.request_id == '') {
        console.log(event.data)
        let url = this.adminService.getBaseUrl() + "/" + event.data.url + "?access_token=" + CookieStore.getToken();
        console.log(url)
        window.location.href = url;
      }
      else {
      }
      this.request_id = event.data.request_id;
    }
  }
  getURL() {
    if (this.load == 1) {
      const access_token = CookieStore.getToken();
      this.url = "https://app.appsmith.com/app/transport/apps-students-transport-reques-6444c42a6a3ea4285a5944f4?access_token=" + access_token;
      this.load++;
      return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
    return "";
  }
}