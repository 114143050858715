import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-courses-master',
  templateUrl: './courses-master.component.html',
  styleUrls: ['./courses-master.component.scss']
})
export class CoursesMasterComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;


  co_count = 5;
  po_count = 7;

  parts: any[] = [];
  part_course_types: any[] | undefined = [];

  // Core datas

  course_search_form: any;
  course_data_list: any[] = [];

  course_list_loading = false;

  // Core data creation

  course_form: any;
  course: CourseInterface | undefined = undefined;
  course_save_loading = false;
  course_delete_loading = false;

  course_co_po_form: any;

  courseware_form: any;

  po_total: any[] = [];
  // CRUD
  state = '';
  dropdownSettings: IDropdownSettings = {};

  course_positions: number[] = [];
  public department_list: Array<any> = [];
  student_programme_list: any[] = [];
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
    this.getFilterProgrammeDataList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'programme_id',
      textField: 'programme_display_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  /// master data
  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      offered_by_programme_type_id: [''], //required
      revision_year: [0], //required
      offered_by_programme_id: [''], //optional
      semester: [''], //optional
      course_code: [''] //optional
      /* offered_by_finance_type: [''],*/
    });

  }

  async onFilterSelectChange(id: string, event?: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      if (this.state === 'ADD') {
        this.getProgrammeDataList(this.course_form);
        if (id === 'programme_type_id' && this.course_form.value.offered_by_programme_type_id) {
          this.parts = this.commonEnums.getParts(this.course_form.value.offered_by_programme_type_id);
        }
      } else {
        this.getProgrammeDataList(this.programme_search_form);
        if (id === 'programme_type_id' && this.programme_search_form.value.offered_by_programme_type_id) {
          this.parts = this.commonEnums.getParts(this.programme_search_form.value.offered_by_programme_type_id);
        }
      }
    } else if (id === 'part') {
      if (this.state === 'ADD') {
        this.getFilterProgrammeDataList();
        if (this.course_form.value.offered_by_programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(this.course_form.value.offered_by_programme_type_id,
            this.course_form.value.part);
          this.course_form.get('course_type').setValue("");
          this.course_form.get('allowed_programme_ids').setValue("");
          /* this.course_form.get('study_by_programme_type_id').setValue("");
          this.course_form.get('study_by_programme_id').setValue(""); */
        }
      } else {
        if (this.course_form.value.offered_by_programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(
            this.programme_search_form.value.offered_by_programme_type_id, this.programme_search_form.value.part);
        }
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.offered_by_programme_type_id) {
          search_form_query.programme_type_id = form.value.offered_by_programme_type_id;
        }
        if (form.value.offered_by_finance_type) {
          search_form_query.finance_type = form.value.offered_by_finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }



  async onSearchClick(): Promise<void> {
    this.searchCourse();
  }

  async searchCourse(): Promise<void> {
    try {
      if (!this.programme_search_form.value.offered_by_programme_type_id) {
        alert('Please select Programme Type');
        return;
      }
      if (!this.programme_search_form.value.revision_year) {
        alert('Please select Revision Year');
        return;
      }
      this.course_list_loading = true;
      this.course_data_list = [];
      var search_form_query: any = {
        offered_by_programme_type_id: this.programme_search_form.value.offered_by_programme_type_id,
        revision_year: this.programme_search_form.value.revision_year,
      }; //new search filter query
      //optional checks
      if (this.programme_search_form.value.course_code)
        search_form_query.course_code = this.programme_search_form.value.course_code;
      if (this.programme_search_form.value.offered_by_programme_id)
        search_form_query.offered_by_programme_id = this.programme_search_form.value.offered_by_programme_id;
      if (this.programme_search_form.value.semester)
        search_form_query.semester = this.programme_search_form.value.semester;
      const service_response = await this.restService.getCourseScheme(search_form_query);
      if (service_response && service_response.success) {
        this.course_data_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_list_loading = false;
    }

  }

  downloadAsCSV(): void {

  }


  // Core data


  buildForm(): void {
    let programme_search_form_value: any = {};
    if (this.programme_search_form) {
      programme_search_form_value = this.programme_search_form.value;
    }
    console.log(this.programme_search_form.value);
    console.log(this.course);
    if (!this.course) {
      this.course = {} as CourseInterface;
      this.course._id = '';
      this.course.offered_by_finance_type = programme_search_form_value.offered_by_finance_type || '';
      this.course.offered_by_programme_type_id = programme_search_form_value.offered_by_programme_type_id || '';
      this.course.offered_by_programme_id = programme_search_form_value.offered_by_programme_id || '';
      this.course.course_code = '';
      this.course.revision_year = 0;
      this.course.course_name = '';
      this.course.course_type = '';
      this.course.part = '';
      this.course.position_no = 0;
      this.course.allowed_programme_ids = [];
      //this.course.study_by_programme_type_id = "";
      //this.course.study_by_programme_id = "";

      //newly added fields
      //this.course.dependency_course_code = '';
      this.course.semester = programme_search_form_value.semester || "";
      this.course.exam_duration = 0;
      this.course.exam_conducted = false;
      this.course.max_internal_mark = 0;
      this.course.max_external_mark = 0;
      this.course.total_external_mark = 0;
      this.course.weekly_lecture_hours = 0;
      this.course.weekly_tutorial_hours = 0;
      this.course.weekly_practical_hours = 0;
      this.course.credits = 0;
      this.course.approved = false;
      //this.course.approved_by = "";
      this.course.is_common_course = false;
    }
    if (this.course.offered_by_programme_type_id) {
      this.parts = this.commonEnums.getParts(this.course.offered_by_programme_type_id);
    }
    var allowed_programme_data: any[] = [];
    if (this.course.allowed_programme_ids.length > 0) {
      this.course.allowed_programme_ids.forEach(programme_id => {
        if (programme_id && programme_id != null && programme_id != undefined)
          allowed_programme_data.push(this.department_list.find(x => x.programme_id == programme_id))
      })
    }
    console.log(allowed_programme_data);
    if (this.course.part) {
      this.part_course_types = this.commonEnums.getCoursePartType(this.course.offered_by_programme_type_id,
        this.course.part);
    }
    this.course_form = this.fb.group({
      _id: [this.course._id],
      offered_by_finance_type: [this.course.offered_by_finance_type],
      offered_by_programme_type_id: [this.course.offered_by_programme_type_id],
      offered_by_programme_id: [this.course.offered_by_programme_id],
      allowed_programme_ids: [allowed_programme_data],
      //study_by_programme_type_id: [this.course.study_by_programme_type_id],
      //study_by_programme_id: [this.course.study_by_programme_id],
      course_code: [this.course.course_code],
      revision_year: [this.course.revision_year],
      course_name: [this.course.course_name],
      course_type: [this.course.course_type],
      part: [this.course.part],
      position_no: [this.course.position_no],
      //dependency_course_code: [this.course.dependency_course_code],
      semester: [this.course.semester],
      exam_duration: [this.course.exam_duration],
      max_internal_mark: [this.course.max_internal_mark],
      max_external_mark: [this.course.max_external_mark],
      total_external_mark: [this.course.total_external_mark],
      weekly_lecture_hours: [this.course.weekly_lecture_hours],
      weekly_tutorial_hours: [this.course.weekly_tutorial_hours],
      weekly_practical_hours: [this.course.weekly_practical_hours],
      credits: [this.course.credits],
      exam_conducted: [this.course.exam_conducted],
      approved: [this.course.approved],
      //approved_by: [this.course.approved_by],
      is_common_course: [this.course.is_common_course],

    });

  }


  async onAddClick(): Promise<void> {
    this.state = 'ADD';
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(course: any): Promise<void> {
    console.log(course);
    this.state = 'ADD';
    this.course = course;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.course_save_loading = true;
      var course_data = this.course_form.value;
      if (!course_data.course_code) {
        alert('Please enter course code');
        return;
      }
      if (!course_data.course_name) {
        alert('Please enter course name');
        return;
      }
      if (!course_data.offered_by_programme_id) {
        alert('Please select programme');
        return;
      }
      if (!course_data.part) {
        alert('Please select part');
        return;
      }
      // TODO validate on course_type
      // if (!course_data.course_type) {
      //   alert('Please select course type');
      //   return;
      // }

      if (!course_data.revision_year) {
        alert('Please enter year');
        return;
      }
      if (course_data.part != 'PART_III' && course_data.part != 'PART_IV' &&
        course_data.part != "CORE" && course_data.part != "ELECTIVE" &&
        course_data.part != "SUPPORTIVE_COURSES" && course_data.part != "SELF_LEARNING") {
        delete course_data.course_type;
      }
      /* if (course_data.course_type != 'ALLIED' && course_data.course_type != 'ALLIED_PRACTICAL') {
        delete course_data.study_by_programme_type_id;
        delete course_data.study_by_programme_id;
      } */
      /* if (course_data.part != 'PART_IV') {
        delete course_data.dependency_course_code;
      } */
      //if (course_data.part == 'PART_I' || course_data.part == 'PART_IV' || this.course.part == 'PART_II') {
      if (course_data.allowed_programme_ids.length > 0) {
        var allowed_programme_ids: string[] = [];
        console.log(course_data.allowed_programme_ids);
        var allowed_programme_ids_obj: any[] = course_data.allowed_programme_ids;
        allowed_programme_ids_obj.forEach(programme => {
          allowed_programme_ids.push(programme.programme_id);
        });
        course_data.allowed_programme_ids = allowed_programme_ids;
      }
      else {
        delete course_data.allowed_programme_ids;
      }
      console.log(course_data);
      let save_respose;
      // create or update record
      save_respose = await this.restService.createUpdateCourse(course_data);
      if (save_respose.success) {
        await this.searchCourse();
        alert('Saved Successfully');
        //this.onCloseClick();
      }
    } catch (error) {
      console.log(error)
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.course_save_loading = false;
    }
  }
  calculateTotalExternalMark() {
    const total = this.course_form.get('max_internal_mark').value + this.course_form.get('max_external_mark').value;
    if (total > 100) {
      alert("Total External Mark cannot be greater than 100");
    }
    else {
      this.course_form.get('total_external_mark').setValue(total);
    }
  }
  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }
      this.course_delete_loading = true;
      const categgory_data = this.course_form.value;
      //const save_respose = await this.restService.deleteCourse(categgory_data.course_code);
      const save_respose = await this.restService.deleteCourseById(categgory_data._id); //--> new api
      if (save_respose.success) {
        await this.searchCourse();
        alert('Deleted Successfully');
        this.onCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.course_delete_loading = false;
    }
  }



  resetForm(): void {
    this.course_form = undefined;
    this.course = undefined;
    this.state = '';
  }
  async onCloseClick(): Promise<void> {
    this.state = '';
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  uploadCOPOExcel() {

  }

  async bulkUploadWordFile($event) {
    const file = $event.srcElement.files[0];
    const consent = confirm('Are you sure do you want to upload this syllabus file?');
    if (!consent) {
      return;
    }
    let save_respose;
    // create or update record
    //save_respose = await this.restService.coursesBulkUpload(file);
    if (save_respose.success) {
      alert('File uploaded Successfully');
    }
    else {
      alert("Could not upload file")
    }
    $event.target.value = "";
  }

  uploadSyllabusWordFile() {

  }



  async getFilterProgrammeDataList(): Promise<void> { //to render multiselect allowed programme list data
    try {
      this.department_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (this.course_form) {
        if (this.course_form.value.offered_by_programme_type_id) {
          search_form_query.programme_type_id = this.course_form.value.offered_by_programme_type_id;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        var programme_list = service_response.data;
        programme_list.forEach(programme => {
          this.department_list.push({
            programme_id: programme.programme_id,
            programme_display_name: (programme.programme_name + " " + (programme.finance_type == "regular" ? "(R)" : "(SF)"))
          })
        });
        //console.log(this.department_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
  }


  async getStudentProgrammeDataList(): Promise<void> { //to render studying student programme dropdown list
    try {
      this.student_programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      /* if (this.course_form) {
        if (this.course_form.value.study_by_programme_type_id) {
          search_form_query.programme_type_id = this.course_form.value.study_by_programme_type_id;
        }
      } */

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.student_programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }

  openFileDialog() {
    document.getElementById("file-upload").click();
  }
}

interface CourseInterface {

  _id: string;
  id: string;
  // most used
  offered_by_programme_type_id: string,
  offered_by_finance_type: string,
  offered_by_programme_id: string,

  part: string;
  allowed_programme_ids: string[], //only if PART is 1 or 4

  course_type: string;
  //study_by_programme_type_id: string, //only if course_type is ALLIED
  //study_by_programme_id: string, //only if course_type is ALLIED

  course_code: string;
  course_name: string;
  //dependency_course_code: string,
  semester: string,

  //updated existing fields
  revision_year: number;
  position_no: number,
  exam_conducted: boolean,
  exam_duration: number,
  max_internal_mark: number,
  max_external_mark: number,


  //not in api return only for view render object
  /* co_po_map: ICOPOMap[];
  co_po_map_contribution: ICOPOContribution[];
  course_details: ICoursesDetails; */

  //add controls for fields


  weekly_lecture_hours: number,
  weekly_tutorial_hours: number,
  weekly_practical_hours: number,
  credits: number,
  total_external_mark: number,
  approved: boolean,
  //approved_by: string,
  is_common_course: boolean,


  // db defaults
  migration_flag: string;
  createdAt: Date;
  updatedAt: Date;
  __ref: string;


  // ui view


}
