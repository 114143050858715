<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Programme Fee List</h2>

                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Programme Fee List</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Programme Fee List</p>
                    </div>
                    <ng-container
                        *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin'])">
                        <div class="card-body" *ngIf="programme_fee_search_form">
                            <form [formGroup]="programme_fee_search_form">
                                <div class="row row-sm">

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrollment Year</label>
                                            <select formControlName="regulation_year" class="form-control"
                                                (change)="onRegulationYearChange()" [attr.disabled]="true">
                                                <option value="">Select Enrollment Year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Admission Name</label>
                                            <select formControlName="admission_id" class="form-control"
                                                (change)="admissionNameChanged($event)" [attr.disabled]="true">
                                                <option value="">Select Admission Name</option>
                                                <option
                                                    *ngFor="let admission of admission_name_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="admission._id">{{admission.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                                Type</label>
                                            <select formControlName="finance_type" class="form-control"
                                                (change)="onFinanceTypeChange()">
                                                <option value="">Select Finance Type</option>
                                                <option
                                                    *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                programme</label>
                                            <select formControlName="programme_id" class="form-control">
                                                <option value="">Select programme</option>
                                                <option
                                                    *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                    {{programme.finance_type ==
                                                    'self_finance'?' (SF)': ' (R)'}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select semester</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                term</label>
                                            <select formControlName="term" class="form-control">
                                                <option value="">Select term</option>
                                                <option
                                                    *ngFor="let term of commonEnums.getNumbers(5);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="term">{{commonEnums.rommon_letters[term]}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Target</label>
                                            <select formControlName="fee_target_type" class="form-control">
                                                <option value="">Select Target</option>
                                                <option value="REGULAR">Regular</option>
                                                <option value="SC">SC/ST/SCA/BC(PARAYAN/PALLAN)</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!programme_fee_data_loading"
                                                (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="programme_fee_data_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>

                                        </div>
                                    </div>



                                </div>
                            </form>
                        </div>
                    </ng-container>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Programme Name</th>
                                        <th>Enrollment Year</th>
                                        <th>Semester</th>
                                        <th>Term</th>
                                        <th>Mode</th>
                                        <th style="width: 20%;">Target Type</th>
                                        <th>Fees</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="programme_fee_data">
                                        <tr>
                                            <td scope="row">
                                                1
                                            </td>
                                            <td>{{getProgrammeName(programme_fee_data.programme_id)}}</td>
                                            <td>{{programme_fee_data.regulation_year}}</td>
                                            <td>{{programme_fee_data.semester}}</td>
                                            <td>{{programme_fee_data.term}}</td>
                                            <td>{{programme_fee_data.mode | titlecase}}</td>
                                            <td>{{programme_fee_data.fee_target_type}}</td>
                                            <td>{{getTotalFees(programme_fee_data.account_splits)}}</td>
                                            <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                    <button type="button" class="btn btn-sm btn-info mr-2"
                                                        title="Edit Programme Fee" (click)="onEditClick()">
                                                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!programme_fee_data_loading && !programme_fee_data">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="programme_fee_data_loading && !programme_fee_data">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-programme-fee">
            <div class="modal-dialog modal-lg big-modal" role="document" *ngIf="programme_fee_settings_form">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Programme Fee Settings</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card-body">
                            <div *ngIf="programme_fee_settings_form">
                                <form [formGroup]="programme_fee_settings_form">
                                    <div class="row row-sm">

                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    payment
                                                    mode</label>
                                                <select formControlName="mode" class="form-control">
                                                    <option value="">Select payment mode</option>
                                                    <option value="online">Online</option>
                                                    <option value="offline">Offline</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                    Fine Rule
                                                    Name</label>
                                                <select formControlName="fine_settings_id" class="form-control">
                                                    <option value="">Select Fine Name</option>
                                                    <option
                                                        *ngFor="let fine of fine_list;let j=index;trackBy:fromDataResolver.identify;"
                                                        [value]="fine._id">
                                                        {{fine.fine_name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div class="table-responsive">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Specify payment
                                            split</label>
                                    </div>
                                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                                        <thead>

                                            <tr>
                                                <th class="text-center" style="width: 5%;">S.No</th>
                                                <th class="text-center">Sub Account Id</th>
                                                <th class="text-center">Account No.</th>
                                                <th class="text-center" style="width: 20%;">Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <ng-container
                                                *ngFor="let account of fromDataResolver.getFormArrayControlls(programme_fee_settings_form,'accounts');let i=index;trackBy:fromDataResolver.identify;">
                                                <ng-container [formGroup]="account">

                                                    <tr>
                                                        <td>
                                                            {{i+1}}
                                                        </td>
                                                        <td class="text-uppercase">
                                                            {{account.value.sub_acc_id}}
                                                        </td>
                                                        <td class="text-uppercase">
                                                            {{account.value.account_no}}
                                                        </td>
                                                        <td>
                                                            <input class="form-control" type="number"
                                                                formControlName="amount">
                                                        </td>

                                                    </tr>
                                                </ng-container>

                                            </ng-container>

                                            <tr>
                                                <th colspan="3" class="text-align-right">Total</th>
                                                <th class="text-align-right">{{getTotalAmount()}}</th>
                                            </tr>

                                            <ng-container *ngIf="programme_fee_settings_loading">
                                                <tr>
                                                    <th colspan="4" class="text-center">Loading Please wait</th>
                                                </tr>
                                            </ng-container>
                                        </tbody>



                                    </table>
                                </div>
                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn ripple btn-primary" (click)="onSaveProgrammeFormClick()"
                                        type="button" *ngIf="!programme_fee_settings_save_loading">Save</button>
                                    <button class="btn ripple btn-primary" disabled type="button"
                                        *ngIf="programme_fee_settings_save_loading">Saving...</button>
                                    <button class="btn ripple btn-secondary mg-l-20"
                                        (click)="onProgrammeFeeFormCloseClick()" type="button">Close</button>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>