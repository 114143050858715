<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Programme Types</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Type</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Programme Types</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Programme Types</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Programme Type Id</th>
                                        <th>Programme Type Name</th>
                                        <th>Short Name</th>
                                        <th>Last Updated At</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="programme_type_list.length>0">


                                        <ng-container *ngFor="let category of programme_type_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td>{{category.programme_type_id}}</td>
                                                <td>{{category.name}}</td>
                                                <td>{{category.short_name}}</td>
                                                <td>{{category.updatedAt | date}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEditClick(category)">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>

                                                        </button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!programme_type_list_loading && programme_type_list.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="programme_type_list_loading && programme_type_list.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="programme_form">
                    <div class="modal-header">
                        <h6 class="modal-title" *ngIf="!programme._id">Add New Type</h6>
                        <h6 class="modal-title" *ngIf="programme._id">Update Type</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="programme_form">

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Programme Type Id</label>
                                    <input class="form-control" required="" type="text" formControlName="programme_type_id">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Programme Name</label>
                                    <input class="form-control" required="" type="text" formControlName="name">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Programme Short Name</label>
                                    <input class="form-control" required="" type="text" formControlName="short_name">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description</label>
                                    <textarea class="form-control" formControlName="description"></textarea>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Duration In Years</label>
                                    <input class="form-control" required="" type="number" formControlName="duration">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter No. Of Semesters</label>
                                    <input class="form-control" required="" type="number" formControlName="no_of_semesters">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Max Seats</label>
                                    <input class="form-control" required="" type="number" formControlName="max_seats">
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveClick()" *ngIf="!programme_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="programme_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>

                                    <ng-container *ngIf="programme._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()" *ngIf="!programme_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="programme_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                    </ng-container>


                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>