import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-final-foil-card',
  templateUrl: './final-foil-card.component.html',
  styleUrls: ['./final-foil-card.component.scss']
})
export class FinalFoilCardComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  sections: any = [];
  parts: any[] = [];
  part_course_types: any[] | undefined = [];

  programme_search_form: any;
  settings_list: any[] = [];
  // Core datas

  course_data_list: any = undefined;
  course_list_loading = false;

  mapped_programme_list: any[] = [];
  mapped_section_list: any[] = [];
  exam_id: string = "";
  month: string = "";
  year: string = "";
  max_valuation_count = 0;


  appearance_types: { key: string, text: string }[] = [{ key: "normal", text: "Normal" }, { key: "ARREAR", text: "Arrear" },
  { key: "REPEAT", text: "Repeat Semester" }];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  getMonth(month: string) {
    return this.commonEnums.months.find(x => x.id == month)?.text;
  }
  getProgrammeName(id: string) {
    return this.programme_list.find(x => x.programme_id == id)?.programme_name;
  }
  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
    this.route.queryParams.subscribe(async (params) => {
      this.exam_id = params.exam_id;
      this.month = params.month;
      this.year = params.year;
      if (this.exam_id != undefined && this.month != undefined && this.year != undefined) {
        this.programme_search_form.get('month').setValue(parseInt(this.month));
        this.programme_search_form.get('year').setValue(parseInt(this.year));
        //await this.getExamList();
        //this.programme_search_form.get('exam_id').setValue(this.exam_id);
        //await this.getMappedProgrammeList();
      }
    });
  }

  /// master data

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      month: [0],
      year: [0],
      programme_type_id: [''],
      enrolled_year: [0],
      semester: [0],
      programme_id: [''],
      sections: [''],
      part: [''],
      course_type: [''],
      position_no: [0],
      appearance_type: [''],
      course_code: ['']

    });
  }

  async onFilterSelectChange(id: string, event?: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.getProgrammeDataList(this.programme_search_form);
      if (id === 'programme_type_id' && this.programme_search_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.programme_search_form.value.programme_type_id);
      }
      this.programme_list = []; this.sections = [];
      this.programme_search_form.get('programme_id').setValue("");
      this.programme_search_form.get('sections').setValue("");
    }
    if (id == "programme_id") {
      this.sections = [];
      this.programme_search_form.get('sections').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.programme_search_form.get('sections').setValue([this.sections[0]]);
        }
      }
    }
    if (id === 'part') {
      this.programme_search_form.get('course_type').setValue("");
      if (this.programme_search_form.value.programme_type_id) {
        this.part_course_types = this.commonEnums.getCoursePartType(
          this.programme_search_form.value.programme_type_id, this.programme_search_form.value.part);
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async getExamList() {
    if (!this.programme_search_form.value.month) {
      alert("Please select Month"); return;
    }
    if (!this.programme_search_form.value.year) {
      alert("Please select Year"); return;
    }
    var search_query = {
      year: this.programme_search_form.value.year,
      month: this.programme_search_form.value.month
    }
    this.settings_list = [];
    try {
      const service_response = await this.restService.searchExamSetup(search_query);
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async getMappedProgrammeList() {
    try {
      if (!this.programme_search_form.value.exam_id) {
        alert("Please select Exam"); return;
      }
      this.mapped_programme_list = [];
      this.mapped_section_list = [];
      const service_response = await this.restService.searchExamSetup({ exam_id: this.programme_search_form.value.exam_id });
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {

    }
  }

  programmeChanged(ev) {

  }

  async onSearchClick(): Promise<void> {
    this.searchCourse();
  }

  async searchCourse(): Promise<void> {
    try {
      if (!this.programme_search_form.value.month) {
        alert('Please select Month');
        return;
      }

      if (!this.programme_search_form.value.year) {
        alert('Please select Year');
        return;
      }

      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please select Enrollment Year');
        return;
      }

      if (!this.programme_search_form.value.semester) {
        alert('Please select Semester');
        return;
      }

      if (!this.programme_search_form.value.programme_id) {
        alert('Please select Programme');
        return;
      }

      if (!this.programme_search_form.value.part) {
        alert('Please select Part');
        return;
      }

      if (!this.programme_search_form.value.position_no) {
        alert('Please select Course Position');
        return;
      }

      if (!this.programme_search_form.value.appearance_type) {
        alert('Please select Appearance Type');
        return;
      }

      this.course_list_loading = true;
      this.course_data_list = [];
      var search_form_query: any = {
        month: this.programme_search_form.value.month,
        year: this.programme_search_form.value.year,
        enrolled_year: this.programme_search_form.value.enrolled_year,
        semester: this.programme_search_form.value.semester,
        programme_id: this.programme_search_form.value.programme_id,
        part: this.programme_search_form.value.part,
        //course_position: (this.programme_search_form.value.position_no),
        appearance_type: this.programme_search_form.value.appearance_type,
        position_no: parseInt(this.programme_search_form.value.position_no)
      }; //new search filter query

      //optional checks
      if (this.programme_search_form.value.sections) {
        var sections = [];
        for (var i = 0; i < this.programme_search_form.value.sections.length; i++) {
          sections.push(this.programme_search_form.value.sections[i].section_name)
        }
        console.log(sections);
        search_form_query.sections = sections;
      }

      if (this.programme_search_form.value.course_type) {
        search_form_query.course_type = this.programme_search_form.value.course_type
      }

      if (this.programme_search_form.value.course_code) {
        search_form_query.course_code = this.programme_search_form.value.course_code
      }

      console.log(search_form_query);

      const service_response = await this.restService.getMarkGradeRegister(search_form_query);
      if (service_response && service_response.success) {
        this.course_data_list = service_response.data;
        console.log(this.course_data_list);
        var mark_data: any[] = this.course_data_list.mark_data;
        for (var i = 0; i < mark_data.length; i++) {
          const valuations: any[] = mark_data[i].valuations;
          const valuation_length = valuations.length;
          if (valuation_length > this.max_valuation_count) {
            this.max_valuation_count = valuation_length;
          }
        }

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      console.log(error);
    } finally {
      this.course_list_loading = false;
    }

  }

  getValuationHeader() {
    return new Array(this.max_valuation_count).fill(0);
  }

  getEmptyValuation(valuations: any[]) {
    if (valuations.length < this.max_valuation_count) {
      return Array(this.max_valuation_count - valuations.length).fill(0);
    }
    return [];
  }

  printData() {
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script> " +
      "<style>@page { size: A4 landscape;} @media print { html, body { margin-right:2%; margin-left:5%; margin-top:3%; margin-bottom:3%}}</style>")
    mywindow.document.write('</head><body><div style="font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-area').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.write('<script>document.getElementById("print-table").classList.remove("table-responsive");</script>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

}

interface IMarkCumGradeResultEntity {
  courses: string[],
  mark_data: {
    external: number,
    grade_letter: string,
    grade_point: number,
    internal: number,
    name: string,
    roll_no: string,
    total: number,
    valuations: IValuationResult[];
    //elective_code?: string
    course_code: string
  }[],
  query: {
    appearance_type: string,
    course_code: string,
    course_position: string,
    course_type: string,
    enrolled_year: number,
    month: number,
    part: string,
    position_no: number,
    programme_id: string,
    sections: string[],
    semester: number
    year: number
  }[],
  show_course_code: boolean
}

interface IValuationResult {
  mark: number;
  valuation_no: number,
  diff_with_previous?: number;
}