<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Attendance Report</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
         
               <div class="col-xl-12">
                  <div class="card mg-b-20">
                    <div class="card-header pb-0">
                    </div>
          
                    <div class="card-body">
                        <form [formGroup]="report_search_form">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="start_date">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="end_date">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <button class="btn btn-info mg-t-30" (click)="getAttendanceReport()"
                                        *ngIf="!report_data_loading">Get
                                        Report</button>
                                    <button class="btn btn-info mg-t-30" disabled
                                        *ngIf="report_data_loading">Loading...</button>
                                </div>
                            </div>
                        </form> 
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th *ngFor="let day of attendance_report; let i = index;">
                                            {{day.day_date | date:commonEnums.date_format}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngFor="let day of attendance_report; let i = index;"
                                            (click)="viewAttendance(day)" style="cursor:pointer">
                                            {{day.no_of_hours_present}} / {{day.no_of_hours}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   
                  </div>
                  
                </div>
              </div>
        </div>


    </div>
<!-- /main-content -->
<app-footer></app-footer>