<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1"></h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Foil Card</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Print/Download Foil Card</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <!-- <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment
                                            Year
                                        </label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Select Enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-2">
                                                        <div class="form-group">
                                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                                                Type</label>
                                                            <select formControlName="offered_by_finance_type" class="form-control"
                                                                (change)="onFilterSelectChange('finance_type',$event)">
                                                                <option value="">Select Finance Type</option>
                                                                <option
                                                                    *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                                    [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="programme_search_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Enter Course Code">
                                    </div>
                                </div>


                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!student_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form> -->
                    </div>


                    <div class="card-body">
                        <div class="row" *ngIf="!mark_data">
                            <div class="col-md-12 text-center">
                                <span><b>
                                        <ng-container *ngIf="!mark_data_loading">
                                            No Data Found
                                        </ng-container>
                                        <ng-container *ngIf="mark_data_loading">
                                            Loading... Please wait.
                                        </ng-container>
                                    </b></span>
                            </div>
                        </div>
                        <div class="float-right" *ngIf="mark_data">
                            <button class="btn btn-info btn-sm mg-r-10" (click)="printFoilCard()">Print Foil
                                Card</button>
                        </div>
                        <div id="foil_card_content" *ngIf="mark_data">
                            <div class="row" style="margin-left:50px">
                                <div class="col-md-2">
                                    <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                                        alt="college logo"
                                        style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                                </div>
                                <div class="col-md-8 text-center">
                                    <h4 style=" margin-bottom: 0px !important;">AYYA NADAR JANAKI AMMAL COLLEGE
                                        (Autonomous)</h4>
                                    <h4>Sivakasi - 626 124.</h4>
                                    <h5 style="margin-top:5px">TERMINAL EXAMINATIONS - November, 2022
                                    </h5>
                                </div>
                            </div>
                            <div class="row" style="margin-left: 50px; margin-top:10px">
                                <div class="col-md-5">
                                    <h6 style="margin-left: 20px;">{{mark_data?.class_name}}</h6>
                                </div>
                                <div class="col-md-4">
                                    <h6 style="margin-left: 20px;">Valuation - {{mark_data?.valuation_no}}</h6>
                                </div>
                                <div class="col-md-3 text-center">
                                    <h6>Semester: {{mark_data?.semester}}</h6>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table style="width:80%; margin: 0 auto;">
                                    <tbody>
                                        <tr>
                                            <td class="text-center">Course Code</td>
                                            <td class="text-center">Title of the Course</td>
                                        </tr>
                                        <tr style="border:1.5px solid black">
                                            <td class="text-center" style="border: 1.5px solid black; padding: 10px;">
                                                <b>{{mark_data?.course_code}}</b>
                                            </td>
                                            <td class="text-center"><b>{{mark_data?.course_name}}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style="margin-top: 5px; margin-bottom: 5px;">
                                <div class="col-md-12 text-right">
                                    <b style="margin-right:40px">Maximum Marks: {{max_mark}}</b>
                                </div>
                            </div>
                            <div class="row">
                                <table style="width:20%; margin-right: 3%; margin-left: 5%; border-collapse: collapse;"
                                    class="table table-bordered" *ngIf="mark_data?.marks?.length>0">
                                    <thead>
                                        <tr>
                                            <th>Register No.</th>
                                            <th>Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of mark_data?.marks | slice:0:30">
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.roll_no}}
                                            </td>
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.mark}}
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="mark_data?.marks?.length < 30">
                                            <tr
                                                *ngFor="let row of getEmptyRows(30 - mark_data?.marks?.length); let i = index;">
                                                <td style="height: 32px; padding:0; margin:0; text-align:center"></td>
                                                <td></td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <th style="text-align: center;">Total</th>
                                            <th style="text-align: center;">{{getTotal(0,29)}}</th>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style="width:20%; margin-right: 3%; border-collapse: collapse;"
                                    class="table table-bordered" *ngIf="mark_data?.marks?.length>30">
                                    <thead>
                                        <tr>
                                            <th>Register No.</th>
                                            <th>Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of mark_data?.marks | slice:30:60">
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.roll_no}}
                                            </td>
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.mark}}
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="mark_data?.marks?.length < 60">
                                            <tr
                                                *ngFor="let row of getEmptyRows(60 - mark_data?.marks?.length); let i = index;">
                                                <td style="height: 32px; padding:0; margin:0; text-align:center"></td>
                                                <td></td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <th style="text-align: center;">Total</th>
                                            <th style="text-align: center;">{{getTotal(30,59)}}</th>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style="width:20%; margin-right: 3%; border-collapse: collapse;"
                                    class="table table-bordered" *ngIf="mark_data?.marks?.length>60">
                                    <thead>
                                        <tr>
                                            <th>Register No.</th>
                                            <th>Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of mark_data?.marks | slice:60:90">
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.roll_no}}
                                            </td>
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.mark}}
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="mark_data?.marks?.length < 90">
                                            <tr
                                                *ngFor="let row of getEmptyRows(90 - mark_data?.marks?.length); let i = index;">
                                                <td style="height: 32px; padding:0; margin:0; text-align:center"></td>
                                                <td></td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <th style="text-align: center;">Total</th>
                                            <th style="text-align: center;">{{getTotal(60,89)}}</th>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style="width:20%; border-collapse: collapse;" class="table table-bordered"
                                    *ngIf="mark_data?.marks?.length>90">
                                    <thead>
                                        <tr>
                                            <th>Register No.</th>
                                            <th>Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of mark_data?.marks | slice:90:120">
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.roll_no}}
                                            </td>
                                            <td style="height: 32px; padding:0; margin:0; text-align:center">
                                                {{student.mark}}
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="mark_data?.marks?.length < 120">
                                            <tr
                                                *ngFor="let row of getEmptyRows(120 - mark_data?.marks?.length); let i = index;">
                                                <td style="height: 32px; padding:0; margin:0; text-align:center"></td>
                                                <td></td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <th style="text-align: center;">Total</th>
                                            <th style="text-align: center;">{{getTotal(90,119)}}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="row" style="margin-top: 40px;">
                                <div class="col-md-4" style="font-weight: bold;">
                                    <span style="margin-left: 40px;">
                                        Sivakasi,
                                    </span> <br>
                                    <span style="margin-left: 40px;">
                                        {{today_date | date:'dd/MM/yyyy' }}
                                    </span>
                                </div>
                                <div class="col-md-8 text-right" style="font-weight: bold;">
                                    <span style="margin-right: 60px;">
                                        Signature of the External Examiner
                                    </span><br>
                                    <span style="margin-right: 60px;">
                                        {{mark_data?.staff_name | uppercase}}
                                    </span><br>
                                    <span style="margin-right: 60px;">
                                        (Name in BLOCK LETTERS)
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>