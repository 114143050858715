import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-hostel',
  templateUrl: './hostel.component.html',
  styleUrls: ['./hostel.component.scss']
})
export class HostelComponent implements OnInit {

  application_status = "";
  constructor(private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.getHostelApplicationStatus();
  }

  ngOnInit(): void {
  }

  async getHostelApplicationStatus() {

    try {
      const rest_response = await this.restService.getHostelAdmissionStatus();
      if (rest_response.success && rest_response.data) {
        this.application_status = rest_response.data.status;
      } else {
        this.application_status = "Not applied";
      }
    } catch (error) {

    }



  }
  async applyForHostel() {
    const consent = confirm("Are you sure do you want to apply for hostel?");
    if (consent) {
      try {
        const rest_response = await this.restService.applyForHostel({ main_role: "student" });
        if (rest_response.success) {
          this.getHostelApplicationStatus();
          alert("Successfully applied for hostel");
        }
      } catch (error) {
        alert("Error while applying for hostel");
      }

    }

  }

  async payHostelFee() {
    const payment_url = await this.restService.getHostelFeePaymentUrl();
    location.href = payment_url;
  }
}
