<app-side-bar></app-side-bar>

    <div class="main-content app-content">

      <app-combo-bar></app-combo-bar>
      <app-notification></app-notification>
  
      <!-- container -->
      <div class="container-fluid">
  
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
              <div class="left-content">
                  <div>
                      <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                      <p class="mg-b-0"></p>
                  </div>
              </div>
              <div class="d-flex my-xl-auto right-content">
                  <div class="pr-1 mb-3 mb-xl-0">
                      <button type="button" class="btn btn-info" (click)="onAddClick()">Add Extra Menu</button>
                  </div>
              </div>
          </div>
  
  
  
          <div class="row">
  
  
              <div class="col-xl-12">
                  <div class="card mg-b-20">
                      <div class="card-header pb-0">
                          <div class="d-flex justify-content-between">
                              <h4 class="card-title mg-b-0">{{title}}</h4>
  
                          </div>
                          <p class="tx-12 tx-gray-500 mb-2">Create, Update Extra Menu Bills</p>
                      </div>
  
                      <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Student Roll No.</th>
                                        <th>Purchases</th>
                                        <th>Amount</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="purchases.length>0">
                                        <ng-container *ngFor="let item of purchases;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                  
                                                </td>
                                                
                                                <td>{{item.roll_no}}</td>
                                                <td>
                                                  <tr *ngFor="let purchase of item.purchased_items; let i=index;trackBy:fromDataResolver.identify;">
                                                      <span>{{purchase.item_name}} ({{purchase.quantity}} x {{purchase.price}}) = {{purchase.total}}</span><br>
                                                  </tr>
                                              </td>
                                                <td>{{item.total_price}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2 mg-r-10"
                                                        (click)="editPurchase(item)"
                                                        >
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>

                                                        <button type="button" class="btn btn-sm btn-danger mr-2"
                                                        (click)="delete(item.purchase_id)"
                                                        >
                                                            <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                    
                                            </tr>
                                        </ng-container>
                    
                                    </ng-container>
                    
                                    <ng-container *ngIf="!stock_list_loading && purchases.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="stock_list_loading && purchases.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                    
                                </tbody>
                            </table>
                        </div>
                    </div> 
                  </div>
              </div>
  
  
          </div>
  
  
          <!-- Large Modal -->
          <div class="modal" id="modal-popup">
              <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content modal-content-demo" *ngIf="extraMenuForm">
                      <div class="modal-header">
                          <h6 class="modal-title">Extra Menu Bills</h6>
                          <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->
  
                      </div>
                      <div class="modal-body">
                          <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="extraMenuForm" class="form-horizontal">

                              <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Bill Date</label>
                                      <input class="form-control" required="" type="date" formControlName="bill_date">
                                      <div *ngIf="isInvalid('bill_date')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                          <li class="parsley-required">Date is required.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Student Roll No.</label>
                                      <input class="form-control" required="" type="text" 
                                      placeholder="Enter Roll No."
                                      #roll
                                      formControlName="roll_no">
                                      <div *ngIf="isInvalid('roll_no')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                          <li class="parsley-required">Student Roll No. is required.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Total Price</label>
                                <input class="form-control" readonly required="" type="text" formControlName="total_price">
                              </div>
                            


                              <div class="card box-shadow-0">
                                <div class="card-header">
                                  <h4 class="card-title mb-1">Add Items</h4>
                                </div>
                                <div class="card-body pt-0">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Product Name</label>
                                  <!-- <input class="form-control" required="" type="text" formControlName="item_name"> -->
                                  <kendo-autocomplete
                                  #item
                                  id="itemMaster"
                                  [data]="menuData"
                                  [valueField]="'item_name'"
                                  [filterable]="true"
                                  (filterChange)="handleFilter($event)"                                  
                                  [placeholder]="extraMenuForm.get('search_with_code')!.value?'Enter Item Code':'Enter Item Name'"
                                  [suggest]="true"
                                  formControlName="item_name"
                                  (keydown)="triggerFunction($event)"
                                >
                                <!-- <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                  <span style="color: #00F;">{{ dataItem.item_name }}</span>
                              </ng-template> -->
                                </kendo-autocomplete>
                                <div class="row mg-b-5 mg-t-5">
                                  <div class="col-md-12">
                                    <input type="checkbox" formControlName="search_with_code" (click)="switchSearchMode()"> Search with Code
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Quantity</label>
                                      <input class="form-control" 
                                      (blur)="calculateItemTotal()"
                                      (keydown)="triggerFunction($event)"
                                      (change)="calculateItemTotal()" required="" type="text" formControlName="quantity">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Price</label>
                                      <input class="form-control" (keydown)="triggerFunction($event)" required="" type="text" formControlName="item_total">
                                    </div>
                                  </div>
                                </div>
                                <div class="add-btn-row">
                                  <button class="btn btn-primary mg-r-20" (click)="addExtraItem()">Add Item</button>
                                  
                                </div>                  
                                  <div class="table-responsive border-top userlist-table" *ngIf="extraItems.length>0">
                                    <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                                      <thead>
                                        <th>
                                          <span>Item Name</span>
                                        </th>
                                        <th>
                                          <span>Price</span>
                                        </th>
                                        <th>
                                          <span>Quantity</span>
                                        </th>
                                        <th>
                                          Total
                                        </th>
                                        <th>
                                          Delete
                                        </th>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let item of extraItems; let i=index;trackBy:fromDataResolver.identify;">
                                          <td>{{item.item_name}}</td>
                                          <td>{{item.quantity}}</td>
                                          <td>{{item.price}}</td>
                                          <td>{{item.total}}</td>
                                          <td><button type="button" class="btn btn-sm btn-danger mr-2" (click)="onDelete(item)">
                                            <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                          </button></td>
                                        </tr>
                                        
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            
                              <div class="add-btn-row">
                                <button class="btn btn-success mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()" [disabled]="canDisable()">Add & Close</button>
                                <button class="btn ripple btn-danger mg-r-20" (click)="onReset()" type="button">Reset</button>
                                <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                              </div>
                              
                            </form>
                          </div>
                      </div>
  
                  </div>
              </div>
          </div>
          <!--End Large Modal -->
  
      </div>
  </div>


  
  <!-- /main-content -->
  <app-footer></app-footer>




