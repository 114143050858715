import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';



@Component({
  selector: 'app-manage-elective-request',
  templateUrl: './manage-elective-request.component.html',
  styleUrls: ['./manage-elective-request.component.scss']
})
export class ManageElectiveRequestComponent implements OnInit {


  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;



  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;


  // data
  requested_course_list: any[] = [];
  requested_course_list_loading = false;

  requested_approve_loading = false;

  requested_re_allow_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
  }




  /// master data

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      enrolled_year: [''],
      semester: ['']
    });

  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList(this.programme_search_form);
    }
  }



  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }



  async onSearchClick(): Promise<void> {
    try {

      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please select enrolled year');
        return;
      }
      if (!this.programme_search_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_search_form.value.semester) {
        alert('Please select semester');
        return;
      }

      this.requested_course_list = [];
      this.requested_course_list_loading = true;
      const query = {
        "enrolled_year": this.programme_search_form.value.enrolled_year,
        "programme_id": this.programme_search_form.value.programme_id,
        "semester": this.programme_search_form.value.semester,
        "part": "ELECTIVE"
      };
      const response = await this.restService.getAllElectiveCourseRequestList(query);
      if (response.success) {

        for (const requested_course of response.data) {
          for (const request_item of requested_course.request_list) {
            const course_list = [];
            for (const requested_course_code of request_item.requested_course_codes) {
              course_list.push(this.fb.group({
                course_code: [requested_course_code.course_code],
                priority: [requested_course_code.priority],
                approved: [requested_course_code.approved || false]
              }));
            }

            request_item.request_form = this.fb.group({
              requested_course_codes: this.fb.array(course_list)
            })
          }

        }

        this.requested_course_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.requested_course_list_loading = false;
    }
  }

  async onApproveClick(request_item: any): Promise<void> {
    try {
      const consent = confirm("Are you sure do you want to approve this course?");
      if (!consent) {
        return;
      }
      this.requested_approve_loading = true;
      const approved_course = request_item.request_form.value.requested_course_codes.find((c: any) => c.approved == true);
      if (!approved_course) {
        alert("Please select course to approve");
        return;
      }
      const data_item = {
        "_id": request_item._id,
        "approved_course_code": approved_course.course_code,
      };
      const response = await this.restService.approveElectiveCourseRequest(data_item);
      if (response.success) {
        alert('Course approved successfully');
        await this.onSearchClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.requested_approve_loading = false;
    }
  }

  async onCourseChangeClick(request_form: FormGroup, requested_course_code: any): Promise<void> {
    const selected_course_list = request_form.value.requested_course_codes.filter((c: any) => c.approved == true);
    if (selected_course_list.length > 1) {
      alert("Online one course selection allowed");
      requested_course_code.get("approved")!.setValue(false);
      return;
    }


  }

  async reAllowClick(request_item: any): Promise<void> {
    try {
      const consent = confirm("Are you sure do you want to re-allow this course request?");
      if (!consent) {
        return;
      }
      this.requested_re_allow_loading = true;
      const approved_course = request_item.request_form.value.requested_course_codes.find((c: any) => c.approved == true);
      if (!approved_course) {
        alert("Please select course to approve");
        return;
      }
      const data_item = {
        "_id": request_item._id
      };
      const response = await this.restService.reAllowElectiveCourseRequest(data_item);
      if (response.success) {
        alert('Course reallowed successfully');
        await this.onSearchClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.requested_re_allow_loading = false;
    }
  }

}
