import { NavigationService } from '../../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../../components/notification/notification.service';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
@Component({
  selector: 'app-bill-report',
  templateUrl: './bill-report.component.html',
  styleUrls: ['./bill-report.component.scss']
})
export class BillReportComponent implements OnInit {
  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  year:number;
  month:string='';
  months: {
    month_name:string,
    month_id: string
  } [] = [
    {month_name:"January", month_id:"01"},{month_name:"February", month_id:"02"},{month_name:"March", month_id:"03"},
    {month_name:"April", month_id:"04"},{month_name:"May", month_id:"05"},{month_name:"June", month_id:"06"},
    {month_name:"July", month_id:"07"},{month_name:"August", month_id:"08"},{month_name:"September", month_id:"09"},
    {month_name:"October", month_id:"10"},{month_name:"November", month_id:"11"},{month_name:"December", month_id:"12"}, 
  ];
  years= [2017, 2018, 2019, 2020, 2021]
  bill_report: {
    student_data: {student_name: string, student_rno: string, student_mno:number},
    expense_data: {general_expense:number, extra_expense:number, other_expense:number, total_expense: number},
    hostel_data: {building_name:string, floor_no: number, room_no: number},
    payment_data: {payment_status:string, transaction_id:string}
  } []  = []; 
  students: 
  {
    student_data: {student_name: string, student_rno: string, student_mno:number},
    expense_data: {general_expense:number, extra_expense:number, other_expense:number, total_expense: number},
    hostel_data: {building_name:string, floor_no: number, room_no: number},
    payment_data: {payment_status:string, transaction_id:string}
  } []  = [];
  bill: any;
  hostels : {
    building: string,
    floors: {
      floor:number,
      rooms: number[]
    }[]
  } []= [];
  rooms: number[] = [];
  floors: number [] = [];
  searchForm:FormGroup;
  constructor(private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.year = new Date().getFullYear();
      this.searchForm = this.formBuilder.group({
        hostel: ["" ],
        floor: [""],
        room: [""],
        student_rno: [""],
        student_mno: [""]
      });
      this.hostels.push({
        building: "Boys Hostel", 
        floors: [
          {floor: 1, rooms: [101, 102, 103, 104, 105, 106]},
          {floor: 2, rooms: [201, 202, 203, 204, 205, 206]},
          {floor: 3, rooms: [301, 302, 303, 304, 305, 306]}
        ]
      },
      {
        building: "Girls Hostel",
        floors: [
          {floor: 1, rooms: [101, 102, 103, 104, 105, 106]},
          {floor: 2, rooms: [201, 202, 203, 204, 205, 206]},
          {floor: 3, rooms: [301, 302, 303, 304, 305, 306]}
        ]
      })
    }
    
    chooseYear(): void
    {
      this.month = ''; 
    }
    chooseMonth():void
    {     
      this.bill_report = [];
      this.bill_report.push(
        {
          student_data: {student_name:"Ajay", student_rno:"12PA10", student_mno:98776543311},
          expense_data: {general_expense: 450, extra_expense: 50, other_expense:50, total_expense:550},
          hostel_data: {building_name:"Boys Hostel", floor_no:1, room_no: 101},
          payment_data: {payment_status: "PAID", transaction_id:"yuxooe22fg"}
        },
        {
          student_data: {student_name:"Ziva", student_rno:"12PA08", student_mno:9876543210},
          expense_data: {general_expense: 450, extra_expense: 70, other_expense:50, total_expense:570},
          hostel_data: {building_name:"Girls Hostel", floor_no:2, room_no: 202},
          payment_data: {payment_status: "UNPAID", transaction_id:""}
        },
        {
          student_data: {student_name:"Vijay", student_rno:"12PA12", student_mno:9123456780},
          expense_data: {general_expense: 450, extra_expense: 100, other_expense:50, total_expense:600},
          hostel_data: {building_name:"Boys Hostel", floor_no:3, room_no: 306},
          payment_data: {payment_status: "UNPAID", transaction_id:""}
        },
        {
          student_data: {student_name:"Ram", student_rno:"12PA13", student_mno:9865432170},
          expense_data: {general_expense: 450, extra_expense: 30, other_expense:50, total_expense:520},
          hostel_data: {building_name:"Boys Hostel", floor_no:2, room_no: 201},
          payment_data: {payment_status: "PAID", transaction_id:"tyxooe772fg"}
        },
        {
          student_data: {student_name:"Saran", student_rno:"12PA14", student_mno:9871234560},
          expense_data: {general_expense: 450, extra_expense: 80, other_expense:50, total_expense:580},
          hostel_data: {building_name:"Boys Hostel", floor_no:1, room_no: 101},
          payment_data: {payment_status: "UNPAID", transaction_id:""}
        },

      );
      this.students = this.bill_report;
    }
    
  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.setNavigation([{label: 'Student Monthly Bill', url: 'admin/hostel/student-bill' }]);

  }
  viewBill(bill:any):void{
    this.bill = bill;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#modal-popup');
    this.bill = {};
  }

  clearPayment()
  {

  }
  makePayment()
  {

  }
  searchChange(loc:string, ev:any)
  {
    if(loc=="building")
    {
      this.rooms = []; this.floors = [];
      this.searchForm.get('floor')?.setValue("");
      this.searchForm.get('room')?.setValue("");
      this.hostels.filter(x=>x.building==this.searchForm.get('hostel')?.value).forEach(building=>{
        building.floors.forEach(floor => {
          this.floors.push(floor.floor);
        });
      })
    }
    if(loc=="floor")
    {
      this.rooms = []; this.searchForm.get('room')?.setValue("");
      this.hostels.filter(x=>x.building==this.searchForm.get('hostel')?.value).forEach(building=>{
        building.floors.find(x=>x.floor==this.searchForm.get('floor')?.value)?.rooms.forEach(room=>{
          this.rooms.push(room);
        })
      })
    }
  }

  searchStudent()
  {
    
    this.students = [];
    var search_records;
    if(this.searchForm.get('student_rno')?.value)
    {
      this.students.push(this.bill_report.find(x=>x.student_data.student_rno==this.searchForm.get('student_rno')?.value) as any);
    }
    if(this.searchForm.get('student_mno')?.value)
    {
      this.students.push(this.bill_report.find(x=>x.student_data.student_mno==this.searchForm.get('student_mno')?.value) as any);
    }
    search_records = this.bill_report.filter(x=>x.hostel_data.building_name==this.searchForm.get('hostel')?.value 
    && x.hostel_data.floor_no == this.searchForm.get('floor')?.value &&
    x.hostel_data.room_no == this.searchForm.get('room')?.value)
    search_records.forEach(student=>{
        this.students.push(student);              
    });
  }

  canDisable()
  {
    return ((this.month==""?true:false) || (this.searchForm.invalid));
  }
}
