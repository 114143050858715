<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Programme Exit Survey for Programmes</p>
                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="component_search_form">
                            <form [formGroup]="component_search_form">
                                <div class="row row-sm">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control"
                                                (change)="onFilterSelectChange('programme_type_id',$event)">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                enrollment year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select enrollment year</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme</label>
                                            <select formControlName="programme_id" class="form-control">
                                                <option value="">Select Programme</option>
                                                <option
                                                    *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                    {{programme.finance_type ==
                                                    'self_finance'?' (SF)': ' (R)'}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!programme_survey_list_loading"
                                                (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="programme_survey_list_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-container>


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th>Programme Name</th>
                                        <th>Is Started</th>
                                        <th>Is Completed</th>
                                        <th style="width: 15%;">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let programme of programme_survey_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{this.start_index + i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{programme.programme_name}}
                                            </td>
                                            <td>
                                                {{((programme.survey_questions?.length>0)?'YES':'NO')}}
                                            </td>
                                            <td>
                                                <span *ngIf="programme.survey_questions?.length>0">
                                                    {{((programme.survey_total_count ==
                                                    programme.survey_submit_count)?'YES':'NO')}}
                                                </span>
                                                <span *ngIf="programme.survey_questions?.length == 0">
                                                    NO
                                                </span>
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-info mg-r-10"
                                                    (click)="manageQuestions(programme)">
                                                    <fa-icon [icon]="fonts.faPencilAlt" data-toggle="tooltip"
                                                        title="Manage Survey Questions"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!programme_survey_list_loading && programme_survey_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="programme_survey_list_loading && programme_survey_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                            <ng-container *ngIf="total_records>100">
                                <ul class="pagination pull-right">
                                    <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                                                class="icon ion-ios-arrow-back"></i></a></li>

                                    <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                                                class="icon ion-ios-arrow-forward"></i></a></li>
                                </ul>
                            </ng-container>
                        </div>


                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="survey-modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="survey_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Programme/Graduate Exit Survey</h6>
                    </div>
                    <div class="modal-body" *ngIf="popup_mode =='add' ">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row" *ngIf="setting.student_response?.length>0">
                                <div class="col-md-12">
                                    <button class="btn btn-info btn-md" (click)="viewResponse()"
                                        style="float: right;">View Responses</button>
                                </div>
                            </div>
                            <form [formGroup]="survey_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                                Date</label>
                                            <input class="form-control" required="" type="date"
                                                formControlName="start_date">
                                            <div *ngIf="isInvalid('start_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Start date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End
                                                Date</label>
                                            <input class="form-control" required="" type="date"
                                                formControlName="end_date">
                                            <div *ngIf="isInvalid('end_date')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">End date is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="table-responsive" formArrayName="survey_questions">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th style="width: 5%;">S.No</th>
                                            <th style="width: 15;">PSO Level</th>
                                            <th style="width: 40%;">Question</th>
                                            <th style="width: 30%;">Responses</th>
                                            <th style="width: 10%;">Action</th>
                                        </tr>
                                        <ng-container *ngFor="let question of surveyQuestions; let i=index;"
                                            [formGroupName]="i">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <select formControlName="pso_id" class="form-control">
                                                        <option value="">Select PSO Level</option>
                                                        <option
                                                            *ngFor="let pso_level of pso_levels;let i=index;trackBy:fromDataResolver.identify;"
                                                            [value]="pso_level.pso_id">{{pso_level.pso_text}}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea class="form-control" formControlName="question"
                                                        placeholder="Enter Survey Question" rows="5" cols="5">
                                                    </textarea>
                                                </td>
                                                <td>
                                                    <!-- <kendo-multiselect [data]="response_options"
                                                        formControlName="response_options"
                                                        placeholder="Select Allowed Responses" class="menu_select">
                                                    </kendo-multiselect> -->
                                                    <ng-multiselect-dropdown
                                                        [placeholder]="'Select Allowed Response(s)'"
                                                        [settings]="dropdownSettings" [data]="response_options"
                                                        formControlName="response_options">
                                                    </ng-multiselect-dropdown>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-danger"
                                                        (click)="deleteQuestion(i)">Delete</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td colspan="5" class="text-center">
                                                <button type="button" class="btn btn-secondary" (click)="addQuestion()">
                                                    Add Question
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>



                                <div class="form-group">
                                    <input type="checkbox" formControlName="is_active" class="mg-r-5">Is Active?
                                    <div *ngIf="isInvalid('is_active')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Choose if the survey is active for collecting
                                                responses or
                                                not.</li>
                                        </ul>
                                    </div>
                                </div>


                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.survey_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.survey_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.survey_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.survey_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.survey_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.survey_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.survey_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.survey_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <!-- <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.survey_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.survey_form.value._id && delete_loading" disabled>Deleting...</button> -->
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>

                    <div class="modal-body" *ngIf="popup_mode =='view' ">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-4">
                                    <span>Total Student Count: <b>{{setting.survey_total_count}}</b></span>
                                </div>
                                <div class="col-md-4">
                                    <span>Responded Student Count: <b>{{setting.survey_submit_count}}</b></span>
                                </div>
                                <div class="col-md-4">
                                    <span>Not-Responded Student Count: <b>{{setting.survey_nonsubmit_count}}</b></span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-right">
                                        <button class="btn btn-info btn-sm mg-r-10" (click)="showResponse()">
                                            <ng-container *ngIf="show_response">
                                                Hide Responses
                                            </ng-container>
                                            <ng-container *ngIf="!show_response">
                                                Show Responses
                                            </ng-container>
                                        </button>
                                        <button class="btn btn-info btn-sm" (click)="downloadResponse()"
                                            style="float: right;">Download Responses CSV</button>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%;">S.No</th>
                                            <th style="width: 10%">Roll No.</th>
                                            <th style="width: 15%;">Student Name</th>
                                            <th style="width: 15%;">Date</th>
                                            <th style="width: 30;" *ngIf="show_response">Responses</th>
                                            <th style="width: 15%;">Remarks</th>
                                            <th style="width: 10%;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of this.setting.student_response; let i = index;">
                                            <ng-container *ngIf="student.submitted">
                                                <td>{{i+1}}</td>
                                                <td>{{student.roll_no}}</td>
                                                <td>{{student.given_name}} {{student.middle_name}}</td>
                                                <td>{{student.response_date | date:commonEnums.date_format}}</td>
                                                <td *ngIf="show_response">
                                                    <div class="row"
                                                        *ngFor="let response of student.survey_responses; let j = index">
                                                        <div class="col-md-2" style="border: 0.5px solid black;">
                                                            {{j+1}}
                                                        </div>
                                                        <div class="col-md-2" style="border: 0.5px solid black;">
                                                            PSO{{response.pso_id}}
                                                        </div>
                                                        <div class="col-md-4" style="border: 0.5px solid black;">
                                                            {{response.question}}
                                                        </div>
                                                        <div class="col-md-4" style="border: 0.5px solid black;">
                                                            {{response.response.split('_').join(' ')}} -
                                                            {{response.point}}
                                                            Points
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{student.remarks}}
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-info mg-l-10"
                                                        (click)="allowReSubmission(student)" *ngIf="student.submitted">
                                                        <fa-icon [icon]="fonts.faUndo"></fa-icon>
                                                    </button>
                                                </td>
                                            </ng-container>
                                        </tr>

                                        <ng-container
                                            *ngIf="!student_response_loading && this.setting.student_response.length==0">
                                            <tr>
                                                <th colspan="7" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="student_response_loading && this.setting.student_response.length==0">
                                            <tr>
                                                <th colspan="7" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>

                            <p><b>Not Submitted Student List</b></p>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%;">S.No</th>
                                            <th style="width: 30%">Roll No.</th>
                                            <th style="width: 50%;">Student Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of this.setting.student_response; let i = index;">
                                            <ng-container *ngIf="!student.submitted">
                                                <td>{{i+1}}</td>
                                                <td>{{student.roll_no}}</td>
                                                <td>{{student.given_name}} {{student.middle_name}}</td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onBackClick()"
                                    type="button">Back</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>