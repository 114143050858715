<button type="button" (click)="openModal()" class="btn btn-info mr-2" [ngClass]="render_data.btn_size"
  *ngIf="!render_data.show_loader">
  {{render_data.modal_button_text}}
</button>
<button type="button" class="btn btn-md btn-info mr-2" *ngIf="render_data.show_loader">
  Uploading...
</button>

<!-- Large Modal -->
<div class="modal" [id]="render_data.modal_id">
  <div class="modal-dialog big-modal" role="document">
    <div class="modal-content modal-content-demo">
      <div class="modal-header">
        <h6 class="modal-title">{{render_data.modal_header}}</h6>
        <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->
      </div>
      <div class="modal-body">
        <div class="card card-body pd-20 pd-md-40">

          <div class="stepper d-flex flex-column mt-5 ml-2">
            <div class="d-flex mb-1">
              <div class="d-flex flex-column pr-4 align-items-center">
                <div class="rounded-circle py-2 px-3 bg-info text-white mb-1">1</div>
                <div class="line h-100"></div>
              </div>
              <div>
                <p class="text-dark"><b>Download {{render_data.list_name}} CSV file</b></p>
                <p class="lead text-muted pb-3 mt-2">
                  <!-- <button class="btn btn-md btn-light mg-r-20 mg-b-5" (click)="downloadListCSV()">
                    <fa-icon [icon]="fonts.faDownload" class="mg-r-5"></fa-icon>
                    DOWNLOAD {{render_data.list_name | uppercase}} LIST IN CSV FILE
                  </button> -->
                  <button class="btn btn-md btn-light mg-b-10" (click)="downloadBlankCSV()">
                    <fa-icon [icon]="fonts.faDownload" class="mg-r-5"></fa-icon>
                    DOWNLOAD BLANK CSV TEMPLATE
                  </button>
                </p>
              </div>
            </div>


            <div class="d-flex mb-1">
              <div class="d-flex flex-column pr-4 align-items-center">
                <div class="rounded-circle py-2 px-3 bg-info text-white mb-1">2</div>
                <div class="line h-100"></div>
              </div>
              <div class="row content">
                <p class="text-dark"><b>Add or edit {{render_data.list_name}} info in CSV template.</b></p>
                <p *ngIf="required_columns.length>=0"> &nbsp; Required fields are
                  <span *ngFor="let column of required_columns; let i=index;trackBy:fromDataResolver.identify;">
                    {{column}}
                    <span *ngIf="i<required_columns.length-1">
                      ,
                    </span>
                  </span>
                </p>
                <p *ngIf="render_data?.download_note">Note: <b><span id="download_note"></span></b></p>
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <tr class="table-active">
                      <td
                        *ngFor="let td of render_data.table_data.column_names; let i=index;trackBy:fromDataResolver.identify;">
                        {{headers[i]}}
                      </td>
                    </tr>
                    <tr>
                      <td
                        *ngFor="let td of render_data.table_data.column_names; let i=index;trackBy:fromDataResolver.identify;">
                        {{td}}
                      </td>
                    </tr>
                    <tr>
                      <td
                        *ngFor="let td of render_data.table_data.column_values; let i=index;trackBy:fromDataResolver.identify;">
                        {{td}}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>


            <div class="d-flex mb-1">
              <div class="d-flex flex-column pr-4 align-items-center">
                <div class="rounded-circle py-2 px-3 bg-info text-white mb-1">3</div>
                <div class="line h-100 d-none"></div>
              </div>
              <div>
                <p class="text-dark">Upload CSV</p>
                <p class="lead text-muted pb-3 mt-2">
                  <button class="btn btn-md btn-light mg-b-10">
                    <input type="file" accept=".csv" (change)="fileChangeListener($event)" #fileImportInput
                      [(ngModel)]="file_upload" placeholder="ATTACH CSV FILE">
                  </button>
                </p>
              </div>
            </div>
          </div>




          <div class="add-btn-row">
            <button class="btn btn-md btn-success mg-r-20" [disabled]="invalid_csv"
              (click)="onFileUpload()">Upload</button>
            <button class="btn btn-md btn-secondary" (click)="onCloseClick()">Close</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--End Large Modal -->

<!-- Large Modal -->
<div class="modal" id="{{render_data.modal_id}}preview">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content modal-content-demo">
      <div class="modal-header">
        <h6 class="modal-title">File Preview</h6>
        <!-- <h6 class="modal-title" *ngIf="category._id">Update Category</h6> -->
      </div>
      <div class="modal-body">
        <div class="card card-body pd-20 pd-md-40">

          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <tr>
                <th>S.No</th>
                <th *ngFor="let row of csvRecords[0] | keyvalue">
                  {{row.key}}
                </th>
              </tr>
              <tr *ngFor="let rows of csvRecords; let i = index;">
                <td>{{i+1}}</td>
                <td *ngFor="let row of rows | keyvalue">
                  {{row.value}}
                </td>
              </tr>
            </table>
          </div>

          <div class="add-btn-row">
            <button class="btn btn-md btn-success mg-r-20" (click)="onFileUpload()">Upload File</button>
            <button class="btn btn-md btn-secondary mg-r-20" (click)="onPreviewCloseClick()">Close Preview</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--End Large Modal -->