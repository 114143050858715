import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-parts-setting',
  templateUrl: './parts-setting.component.html',
  styleUrls: ['./parts-setting.component.scss']
})
export class PartsSettingComponent implements OnInit {
  title = 'Parts Settings';
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  fonts = fonts;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  public department_list: Array<any> = [];

  programme_search_form: any;
  part_setting_form: FormGroup;

  parts: any[] = [];

  incharge_types: { key: any, value: any }[] = [{ key: "SPECIFIC", value: "Specific Department" },
  { key: "PARENT", value: "Parent Department" }, { key: "AUTONOMOUS", value: "Autonomous" }];
  student_staff_arrange_types: { key: any, value: any }[] = [{ key: "FIXED", value: "Fixed allotment" },
  { key: "CHOICE", value: "Choice Based allotment" }];
  student_assignment_types: { key: any, value: any }[] = [{ key: "MANUAL-ADD", value: "Student manually added by Staff" },
  { key: "STUDENT-CHOICE", value: "Allow student to choose via Pooling" }];

  part_setting_list_loading: boolean = false;
  part_settings: {
    id: string,
    part: string,
    enrolled_year: number,
    semester: number,
    programme_type_id: string,
    incharge_type: string,
    programme_ids: string[],
    staff_assignment_type: string,
    student_assignment_type: string
  }[] = [];
  constructor(private restService: AdminservicesService, private formBuilder: FormBuilder, private notificationService: NotificationService) {
    this.part_setting_form = this.formBuilder.group({
      part: ["", [Validators.required]],
      programme_type_id: ["", [Validators.required]],
      enrolled_year: ["", [Validators.required]],
      semester: ["", [Validators.required]],
      incharge_type: ["", [Validators.required]],
      programme_ids: [""],
      student_assignment_type: ["", [Validators.required]],
      staff_assignment_type: ["", [Validators.required]],
      id: '',
    });

  }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }
  buildSearchForm(): void {
    this.programme_search_form = this.formBuilder.group({
      programme_type_id: [""]
    });
  }

  async onFilterSelectChange(id: string): Promise<void> {
    if (id === 'programme_type_id') {
      this.part_setting_form.controls['part'].setValue("");
      this.getProgrammeDataList(this.part_setting_form);
      if (id === 'programme_type_id' && this.part_setting_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.part_setting_form.value.programme_type_id);
      }
    }
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      this.department_list = [];
      const search_form_query: any = {
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
        this.programme_list.forEach(programme => {
          this.department_list.push({
            programme_id: programme.programme_id,
            programme_display_name: (programme.programme_name + " " + (programme.finance_type == "regular" ? "(R)" : "(SF)"))
          })
        });
        //console.log(this.department_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
  }
  isInvalid(formControl: string): boolean {
    return this.part_setting_form.touched && this.part_setting_form.get(formControl)?.errors !== null;
  }
  onSave() {
    this.savePart();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  async savePart(): Promise<void> {
    try {
      console.log(JSON.stringify(this.part_setting_form.value));
      var part = this.part_setting_form.value;
      const result: any = this.restService.createUpdatePart(part);
      if (result && result.success) {
        alert("Part Data saved successfully");
      }
      else {
        alert("Error! Could not save data");
      }
      this.reset();
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to create OD Request.', type: 'Failed' });
    }
  }
  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }
  reset(): void {
    this.part_setting_form.reset();
    Object.keys((<FormGroup>this.part_setting_form).controls).forEach(control => {
      this.part_setting_form.get(control).setValue("");
    })
  }
  canDisable(): boolean {
    return this.part_setting_form.status !== 'VALID';
  }

  typeChange(event: Event, control: string) {
    const value = (event.target as HTMLSelectElement).value;
    console.log(value);
    if (control == 'incharge_type') {
      this.part_setting_form.get("programme_ids").setValue("");
      this.part_setting_form.get("staff_assignment_type").setValue("");
      this.part_setting_form.get("student_assignment_type").setValue("");
      if (value == "PARENT") {
        this.part_setting_form.get("staff_assignment_type").setValue("FIXED");
        this.part_setting_form.get("student_assignment_type").setValue("IMPLICIT");
      }
      if (value == "SPECIFIC") {
        this.part_setting_form.get("programme_ids").setValidators([Validators.required]);
      }
      else {
        this.part_setting_form.get("programme_ids").setValidators([]);
      }
      this.part_setting_form.get("programme_ids").updateValueAndValidity();
    }
    else if (control == "staff_assignment_type") {
      this.part_setting_form.get("student_assignment_type").setValue("");
      if (value == "FIXED") {
        this.part_setting_form.get("student_assignment_type").setValue("IMPLICIT");
      }
    }

  }

  async onEditClick(part_setting: any): Promise<void> {
    this.part_setting_form.setValue(part_setting);
    this.onFilterSelectChange("programme_type_id");
    this.part_setting_form.get('part').setValue(part_setting.part);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onDelete() {
    const consent = confirm("Do you want delete this part");
    if (!consent) {
      return;
    }
    var part_id = this.part_setting_form.value.id;
    const delete_response: any = this.restService.deleteParttById(part_id);
    if (delete_response && delete_response.success) {
      alert("Part deleted succesfully");
      this.onCloseClick();
    }
    else {
      alert("Could not delete part")
    }
    //this.part_settings.splice(this.part_settings.findIndex(x => x.id == ), 1)
  }
  getPartSettingsList() {

    this.part_settings.push({
      part: 'PART_III', incharge_type: 'PARENT', staff_assignment_type: 'FIXED', id: '1',
      enrolled_year: 2021, semester: 1,
      programme_type_id: 'UG', programme_ids: ['TAMIL'], student_assignment_type: 'IMPLICIT'
    });
  }


}
