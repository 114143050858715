import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';



@Component({
  selector: 'app-admissionmaster',
  templateUrl: './admissionmaster.component.html',
  styleUrls: ['./admissionmaster.component.scss']
})
export class AdmissionmasterComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  cookieStore = CookieStore;
  // Master data
  programme_type_list: any[] = [];
  programme_type_list_loading = false;




  // Core data
  // admission
  admission_search_form: any;
  admission_search_loading: any;

  admissions_list: any[] = [];
  admissions_list_loading = false;

  admission: any | undefined = undefined;
  admission_save_loading = false;
  admission_delete_loading = false;

  admission_form: any;



  // application fee
  application_fee_fin_type_form: any;

  programme_eligibility_form: FormGroup;
  programme_eligibility_save_loading: boolean = false;

  application_fee_form: any;
  application_fee_loading: any;


  application_fee_save_loading = false;

  //account settings
  account_setting_form: FormGroup = undefined;
  account_list_loading: boolean = false;
  account_save_loading: boolean = false;
  account_list: any[] = [];
  // programme fee

  programme_fee_patch_form: any;


  programme_list: any[] = [];
  programme_list_loading = false;


  programme_fee_settings_split_form: any;

  programme_fee_settings_form: any;
  programme_fee_settings_loading: any;


  programme_fee_settings_save_loading = false;


  // programme fee

  programme_settings_fillter_form: any;

  programme_settings_form: any;



  programme_settings_list_loading = false;

  programme_settings_save_loading = false;

  scroller_form: FormGroup = undefined;
  scroller_list_loading: boolean = false;
  scroller_save_loading: boolean = false;

  subject_list: { _id?: string, subject_name: string }[] = [{ subject_name: "Commerce" }, { subject_name: "Accountancy" },
  { subject_name: "Mathematics" },
  { subject_name: "Botany" }, { subject_name: "Zoology" }, { subject_name: "Biology" }, { subject_name: "Microbiology" },
  { subject_name: "Biochemistry" }, { subject_name: "Chemistry" }, { subject_name: "Biotechnology" },
  { subject_name: "Physics" }, { subject_name: "Computer Science" }, { subject_name: "English" }]

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getMasterDatas();
    this.prepareAdmissionSearchForm();
  }

  //#region Master Operations

  async getMasterDatas(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  //#endregion


  //#region Core Operations
  prepareAdmissionSearchForm(): void {
    this.admission_search_form = this.fb.group({
      regulation_year: [new Date().getFullYear()]
    });
    this.getCoreDatas();
  }
  async onRegulationYearChange(): Promise<void> {
    this.getCoreDatas();
  }

  async getCoreDatas(): Promise<void> {
    try {
      const query = {
        year: this.admission_search_form.value.regulation_year
      };
      this.admissions_list = [];
      this.admissions_list_loading = true;
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {

    if (id === 'programme_type_id') {

    }
  }


  async onAddClick(): Promise<void> {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(admission: any): Promise<void> {
    this.admission = admission;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onSaveClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.admission_save_loading = true;

      const admission_data = this.admission_form.value;

      if (!admission_data.programme_type_id) {
        alert('Please enter programe type id');
        return;
      }

      let save_respose;
      if (admission_data._id) {
        // update record
        save_respose = await this.restService.updateAdmission(admission_data._id, admission_data);
      } else {
        // create record
        if (!admission_data.app_no_prefix) {
          alert('Please enter app no prefix');
          return;
        }

        if (!admission_data.name) {
          alert('Please enter name');
          return;
        }

        save_respose = await this.restService.createAdmission(admission_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.getCoreDatas();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admission_save_loading = false;
    }
  }

  async onDeleteClick(id: string): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      this.admission_delete_loading = true;

      const save_respose = await this.restService.deleteAdmissionById(id);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.getCoreDatas();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admission_delete_loading = false;
    }
  }




  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }


  resetForm(): void {
    this.admission_form = undefined;
    this.admission = undefined;
  }
  buildForm(): void {

    if (!this.admission) {
      this.admission = {
        _id: '',
        programme_type_id: '',
        name: '',
        app_no_prefix: '',
        year: new Date().getFullYear(),
        start_date: new Date(),
        end_date: new Date(),
        active: true,
        description: '',
        document_link: '',
        multi_course_selection_allowed: true,
        sc_discount_allowed: false
      };
    }

    this.admission_form = this.fb.group({
      _id: [this.admission._id],
      programme_type_id: [this.admission.programme_type_id, [Validators.required]],
      name: [this.admission.name, [Validators.required]],
      app_no_prefix: [this.admission.app_no_prefix, [Validators.required]],
      year: [this.admission.year, [Validators.required]],
      start_date: [DateHelper.convertToControlDate(this.admission.start_date), [Validators.required]],
      end_date: [DateHelper.convertToControlDate(this.admission.end_date), [Validators.required]],
      active: [this.admission.active, [Validators.required]],
      description: [this.admission.description, [Validators.required]],
      document_link: [this.admission.document_link, [Validators.required]],
      multi_course_selection_allowed: [this.admission.multi_course_selection_allowed, [Validators.required]],
      sc_discount_allowed: [this.admission.sc_discount_allowed, [Validators.required]],
    });
  }

  //#endregion

  //Manage Scroller Messages -- landing UI
  async manageScroller() {
    try {
      this.scroller_list_loading = true;
      const response = await this.restService.getScrollerMessages();
      if (response.success && response.data) {
        const message_data = response.data;
        console.log(message_data);
        this.scroller_form = this.fb.group({
          messages: this.fb.array(this.buildScrollerForm(message_data))
        });
      }
      JQueryHelper.openModal('#modal-scroller', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.scroller_list_loading = false;
    }
  }

  buildScrollerForm(messages: ScrollMessageInterface[]) {
    if (!messages) {
      messages = [];
    }
    var message_list_array: any[] = [];
    if (messages && messages.length > 0) {
      var message_count = 0;
      for (const message of messages) {
        //console.log(component)
        message_list_array.push(this.fb.group({
          message: [message.message],
          link: [message.link],
          is_active: [message.is_active],
          priority_no: [message.priority_no]
        }));
        message_count++;
      }
    }
    const remaining_count = 1 - messages.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        message_list_array.push(this.fb.group({
          message: [""],
          link: [""],
          is_active: [false],
          priority_no: [0]
        }));
      }
    }
    console.log(message_list_array);
    return message_list_array;
  }

  get messagesList() {
    return (this.scroller_form.get('messages') as FormArray).controls;
  }


  async uploadFile(e: any, i: number) {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadScrollerMessageFile(formData);
      if (response.success && response.data) {
        alert("File Uploaded Successfully");
        (this.scroller_form.get('messages') as FormArray).controls[i].get('link').setValue(response.data.url);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }

  moveUp(current_index) {
    if (current_index == 0) {
      alert("Cannot move upward further!")
      return;
    }
    else {
      var upIndex = current_index - 1;
      var arr: any[] = ((this.scroller_form as FormGroup).get('messages') as FormArray).value;
      var element = arr[current_index];
      arr.splice(current_index, 1);
      arr.splice(upIndex, 0, element);
    }
    this.scroller_form = this.fb.group({
      messages: this.fb.array(this.buildScrollerForm(arr))
    });
    console.log(this.scroller_form.value);
  }

  moveDown(current_index) {
    var arr: any[] = ((this.scroller_form as FormGroup).get('messages') as FormArray).value;
    if (current_index == arr.length - 1) {
      alert("Cannot move donward further");
      return;
    }
    else {
      var downIndex = current_index + 1;
      var element = arr[current_index];
      arr.splice(current_index, 1);
      arr.splice(downIndex, 0, element);
    }
    this.scroller_form = this.fb.group({
      messages: this.fb.array(this.buildScrollerForm(arr))
    });
    console.log(this.scroller_form.value);
  }

  addScrollerMessage() {
    (this.scroller_form.get('messages') as FormArray).push(this.fb.group({
      message: [""],
      link: [""],
      is_active: [false],
      priority_no: [0]
    }));
  }

  removeScrollerMessage(i: number) {
    const consent = confirm("Are you sure want to remove this message? This action is irreversible!");
    if (!consent) {
      return;
    }
    (this.scroller_form.get('messages') as FormArray).removeAt(i);
  }

  async onSaveMessagesClick() {
    var form_data: ScrollerDataInterface = this.scroller_form.value;
    var priority = 1;
    form_data.messages.forEach(message => {
      message.priority_no = priority;
      priority++;
    });
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.scroller_save_loading = true;
      const saved_respose = await this.restService.createScrollerMessages(form_data);
      if (saved_respose.success) {
        alert('Messages Saved Successfully');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.scroller_save_loading = false;
    }

  }

  onScrollerFormCloseClick() {
    this.scroller_form = undefined;
    JQueryHelper.closeModal('#modal-scroller');
  }



  //setEligibility Rules

  async onEligibilityClick(admission): Promise<void> {
    this.admission = admission;
    this.buildSettingsForm(admission);
    JQueryHelper.openModal('#modal-eligibility', { keyboard: false, backdrop: 'static' });
  }

  async onEligibilityPrgFinanceTypeChange(): Promise<void> {
    try {
      const query: any = {
        finance_type: this.programme_settings_fillter_form.value.finance_type,
        programme_type_id: this.programme_settings_fillter_form.value.programme_type_id,
        admission_id: this.admission._id,
      };
      this.programme_settings_list_loading = true;
      const response = await this.restService.getSavedAdmissionSettings(query);
      if (response.success && response.data && response.data.length > 0) {
        var programme_subject: {
          programme_type_id: string, finance_type: string,
          admission_id: string,
          programme_id: string, programme_name: string,
          subjects: {
            all: ISchoolSubjectMaster[],
            anyone: ISchoolSubjectMaster[]
          }[]
        }[] = [];
        const programmes: any[] = response.data;
        var subjects: Subject[] = []; //can contain multiple anyone and all subjects
        if (programme_subject)
          /* programmes.forEach(programme => {
            if (programme.admission_settings.length == 0) {
              console.log("admission settings is empty");
              alert("Please set programme settings for this admission");
              return;
            }
            subjects = programme.admission_settings[0]?.subjects;
            programme_subject.push({
              admission_id: programme.admission_settings[0]?.admission_id,
              programme_type_id: programme.programme_type_id,
              finance_type: programme.finance_type,
              programme_id: programme.programme_id,
              programme_name: programme.programme_name,
              subjects: subjects
            })
          }); */

          for (var i = 0; i < programmes.length; i++) {
            let programme = programmes[i];
            if (programme.admission_settings.length == 0) {
              alert("Please set programme settings for this admission");
              this.onEligibilityFormCloseClick();
              break;
            }
            subjects = programme.admission_settings[0]?.subjects;
            programme_subject.push({
              admission_id: programme.admission_settings[0]?.admission_id,
              programme_type_id: programme.programme_type_id,
              finance_type: programme.finance_type,
              programme_id: programme.programme_id,
              programme_name: programme.programme_name,
              subjects: subjects
            });
          }
        console.log(programme_subject);
        if (programme_subject?.length > 0) {
          this.programme_eligibility_form = this.fb.group({
            eligibility_rules: this.fb.array(this.buildApplicationEligibilityForm(programme_subject))
          });
        }
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_settings_list_loading = false;
    }
  }

  get eligibilityRules() {
    return (this.programme_eligibility_form.get('eligibility_rules') as FormArray).controls;
  }

  buildApplicationEligibilityForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    if (data.length > 0) {
      for (const data_item of data) {
        value_to_return.push(this.fb.group({
          admission_id: [data_item.admission_id],
          finance_type: [data_item.finance_type],
          programme_type_id: [data_item.programme_type_id],
          programme_id: [data_item.programme_id],
          programme_name: [data_item.programme_name],
          subjects: this.fb.array(this.buildSubjectsForm(data_item.subjects))
        }));
      }
    }
    else {
      value_to_return.push(this.fb.group({
        admission_id: [''],
        finance_type: [''],
        programme_type_id: [''],
        programme_id: [''],
        programme_name: [''],
        subjects: this.fb.array(this.buildSubjectsForm([]))
      }))
    }
    return value_to_return;
  }

  buildSubjectsForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    if (data.length > 0) {
      for (const data_item of data) {
        value_to_return.push(this.fb.group({
          all: [data_item.all],
          anyone: [data_item.anyone]
        }));
      }
    }
    else {
      value_to_return.push(this.fb.group({
        all: [[]],
        anyone: [[]]
      }));
    }
    return value_to_return;
  }

  getSubjectsList(i: number) {
    return ((this.programme_eligibility_form.get('eligibility_rules') as FormArray).
      controls[i].get('subjects') as FormArray).controls;
  }

  addNewRule(i) {
    ((this.programme_eligibility_form.get('eligibility_rules') as FormArray).
      controls[i].get('subjects') as FormArray).push(this.fb.group({
        all: [[]],
        anyone: [[]]
      }));
  }

  deleteRule(i, j) {
    const consent = confirm("Are you sure want to delete this rule?");
    if (!consent) return;
    ((this.programme_eligibility_form.get('eligibility_rules') as FormArray).
      controls[i].get('subjects') as FormArray).removeAt(j);
  }

  /* buildSubjectDataForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    if (data.length > 0) {
      for (const data_item of data) {
        value_to_return.push(this.fb.group({
          _id: [data_item._id],
          subject_name: [data_item.subject_name]
        }));
      }
    }
    else {
      value_to_return.push(this.fb.group({
        _id: [''],
        subject_name: ['']
      }));
    }
    return value_to_return;
  }

  getAllSubjects(i: number, j: number) {
    return (((this.programme_eligibility_form.get('eligibility_rules') as FormArray).
      controls[i].get('subjects') as FormArray).controls[j].get('all') as FormArray).controls;
  }

  getAnyOneSubjects(i: number, j: number) {
    return (((this.programme_eligibility_form.get('eligibility_rules') as FormArray).
      controls[i].get('subjects') as FormArray).controls[j].get('anyone') as FormArray).controls;
  }
 */

  async onSaveEligibilityClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.programme_eligibility_save_loading = true;
      const programme_eligibility_form_data: any[] = this.programme_eligibility_form.value.eligibility_rules;
      console.log(programme_eligibility_form_data);
      const saved_respose = await this.restService.createAdmissionSettings(programme_eligibility_form_data);
      if (saved_respose.success) {
        alert('Settings Saved Successfully');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_eligibility_save_loading = false;
    }
  }

  onEligibilityFormCloseClick() {
    this.programme_eligibility_form = undefined;
    JQueryHelper.closeModal('#modal-eligibility');
  }


  //account settings
  async onAccountsClick(admission: any): Promise<void> {
    this.admission = admission;
    this.buildApplicationFeeForm(admission);
    JQueryHelper.openModal('#modal-accounts', { keyboard: false, backdrop: 'static' });
  }

  async onAccountFinanceTypeChange(ev) {
    try {
      this.account_setting_form = undefined;
      this.account_list_loading = true;
      const query: any = {
        finance_type: this.application_fee_fin_type_form.value.finance_type,
        programme_type_id: this.admission.programme_type_id
      };
      this.programme_list_loading = true;
      this.programme_list = [];
      const resp = await this.restService.searchProgrammesLite(query);
      if (resp.success && resp.data && resp.data.length > 0) {
        this.programme_list = resp.data;
      }
      this.programme_list_loading = false;

      const filter_form = {
        finance_type: this.application_fee_fin_type_form.value.finance_type,
        _id: this.admission._id,
      }
      const response = await this.restService.getAdmissionAccountSettings(filter_form);
      if (response.success && response.data && response.data.length > 0) {
        this.buildAccountSettingForm(response.data);
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.account_list_loading = false;
    }
  }


  async buildAccountSettingForm(account_settings) {
    await this.getAccountSettingsList();
    this.account_setting_form = this.fb.group({
      account_settings: this.fb.array(this.buildAccountSettings(account_settings))
    });
  }

  async getAccountSettingsList() {
    try {
      const service_response = await this.restService.getAccountList();
      if (service_response && service_response.success) {
        this.account_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }
  }

  buildAccountSettings(account_settings: any[]) {
    if (!account_settings) {
      account_settings = [];
    }
    var account_list_array: any[] = [];
    if (account_settings && account_settings.length > 0) {
      var setting_count = 0;
      for (const account of account_settings) {
        //console.log(component)
        account_list_array.push(this.fb.group({
          finance_type: [account.finance_type],
          programme_type_id: [account.programme_type_id],
          programme_id: [account.programme_id],
          sub_acc_id: [account.sub_acc_id]
        }));
        setting_count++;
      }
    }
    const remaining_count = 1 - account_settings.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        account_list_array.push(this.fb.group({
          finance_type: [this.application_fee_fin_type_form.value.finance_type],
          programme_type_id: [this.admission.programme_type_id],
          programme_id: [""],
          sub_acc_id: [""]
        }));
      }
    }
    console.log(account_list_array);
    return account_list_array;
  }

  get accountSettings() {
    return (this.account_setting_form.get('account_settings') as FormArray).controls;
  }

  addAccountSetting() {
    (this.account_setting_form.get('account_settings') as FormArray).push(this.fb.group({
      finance_type: [this.application_fee_fin_type_form.value.finance_type],
      programme_type_id: [this.admission.programme_type_id],
      programme_id: [""],
      sub_acc_id: [""]
    }));
  }

  removeAccountSetting(i: number) {
    const consent = confirm("Are you sure want to remove this setting?");
    if (!consent) {
      return;
    }
    (this.account_setting_form.get('account_settings') as FormArray).removeAt(i);
  }

  onSaveAccountClick() {
    console.log(this.account_setting_form.value);
  }

  onAccountFormCloseClick() {
    this.application_fee_fin_type_form = undefined;
    this.account_setting_form = undefined;
    JQueryHelper.closeModal('#modal-accounts');
  }


  //#region Application Fees
  async onConfigureAdmissionFlowClick(admission: any): Promise<void> {
    this.admission = admission;
    this.buildApplicationFeeForm(admission);
    JQueryHelper.openModal('#modal-application-fee', { keyboard: false, backdrop: 'static' });
  }
  async getSavedApplicationFeeSettings(): Promise<any[]> {
    try {
      this.application_fee_loading = true;

      const response = await this.restService.getSavedApplicationFeeSettings(
        this.application_fee_fin_type_form.value.admission_id, this.application_fee_fin_type_form.value.programme_type_id,
        this.application_fee_fin_type_form.value.finance_type);
      if (response.success && response.data && response.data.length > 0) {
        return Promise.resolve(response.data);
      } else {
        return Promise.resolve([]);
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.application_fee_loading = false;
    }
    return Promise.resolve([]);

  }
  buildApplicationFeeForm(admission_data: any): void {
    this.application_fee_fin_type_form = this.fb.group({
      admission_id: [admission_data._id],
      admission_name: [admission_data.name],
      programme_type_id: [admission_data.programme_type_id],
      finance_type: [''],
      amount: [0]
    });
  }

  async onFinanceTypeChange(): Promise<void> {
    try {
      const finance_type = this.application_fee_fin_type_form.value.finance_type;
      if (!finance_type) {
        this.application_fee_form = undefined;
        return;
      }
      const programme_list = await this.getSavedApplicationFeeSettings();
      if (programme_list.length > 0) {
        this.application_fee_form = this.fb.group({
          programmes: this.fb.array(this.buildApplicationFeeItemForm(programme_list))
        });
      }


    } catch (error) {

    }
  }

  buildApplicationFeeItemForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        _id: [data_item._id],
        programme_type_id: [data_item.programme_type_id],
        finance_type: [data_item.finance_type],
        programme_category: [data_item.programme_category],
        programme_id: [data_item.programme_id],
        programme_code: [data_item.programme_code],
        programme_name: [data_item.programme_name],
        application_fee_amount: [data_item.application_fee_amount || 0],
      }));
    }
    return value_to_return;

  }
  async onApplyAmountToAllClick(): Promise<void> {
    try {
      if (!this.application_fee_fin_type_form.value.amount) {
        return;
      }
      const programmes = this.fromDataResolver.getFormArrayControlls(this.application_fee_form, 'programmes');
      for (const programme of programmes) {
        programme.patchValue({
          application_fee_amount: this.application_fee_fin_type_form.value.amount
        });
      }

    } catch (error) {

    }
  }
  async onSaveApplicationFormClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.application_fee_save_loading = true;

      const application_fee_form_data = this.application_fee_form.value;
      const programme_data_list = application_fee_form_data.programmes;

      const data_to_send_list: any[] = [];
      let has_error = false;
      for (const programme_data of programme_data_list) {

        if (!programme_data.application_fee_amount) {
          alert(`Please enter valid fee for ${programme_data.programme_name} (${this.commonEnums.getFinanceDisplayText(programme_data.finance_type)})`);
          has_error = true;
          break;
        }

        try {
          programme_data.application_fee_amount = parseFloat(programme_data.application_fee_amount);
        } catch (error) {
          alert(`Please enter valid fee for ${programme_data.programme_name} (${this.commonEnums.getFinanceDisplayText(programme_data.finance_type)})`);
          has_error = true;
          break;
        }

        if (programme_data.application_fee_amount < 0) {
          alert(`Please enter valid fee for ${programme_data.programme_name} (${this.commonEnums.getFinanceDisplayText(programme_data.finance_type)})`);
          has_error = true;
          break;
        }

        const data_to_send = {
          admission_id: this.application_fee_fin_type_form.value.admission_id,
          programme_type_id: this.application_fee_fin_type_form.value.programme_type_id,
          finance_type: this.application_fee_fin_type_form.value.finance_type,
          programme_id: programme_data.programme_id,
          amount: programme_data.application_fee_amount,
        };
        data_to_send_list.push(data_to_send);
      }
      if (has_error) {
        return;
      }


      const saved_respose = await this.restService.createApplicationFeeBulk(data_to_send_list);
      if (saved_respose.success) {
        alert('Saved Successfully');

        this.onApplicationFeeFormCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.application_fee_save_loading = false;
    }
  }

  resetApplicationFeeForm(): void {
    this.application_fee_fin_type_form = undefined;
    this.application_fee_form = undefined;
    this.admission = undefined;
  }
  async onApplicationFeeFormCloseClick(): Promise<void> {
    this.resetApplicationFeeForm();
    JQueryHelper.closeModal('#modal-application-fee');
  }

  //#endregion


  //#region Programme Form
  async onConfigureProgrammeFeeClick(admission: any): Promise<void> {
    this.admission = admission;
    this.buildProgrammeFeeForm(admission);
    JQueryHelper.openModal('#modal-programme-fee', { keyboard: false, backdrop: 'static' });
  }


  buildProgrammeFeeForm(admission_data: any): void {
    this.programme_fee_patch_form = this.fb.group({
      admission_id: [admission_data._id],
      admission_name: [admission_data.name],
      programme_type_id: [admission_data.programme_type_id],
      regulation_year: [admission_data.year],
      finance_type: [''],
      programme_id: [''],
      semester: [''],
      term: [''],
      fee_target_type: ['']
    });
  }

  async onPrgFinanceTypeChange(): Promise<void> {
    try {

      const query: any = {
        finance_type: this.programme_fee_patch_form.value.finance_type,
        programme_type_id: this.programme_fee_patch_form.value.programme_type_id
      };
      this.programme_fee_patch_form.get('programme_id').setValue('');
      this.programme_list_loading = true;
      this.programme_list = [];
      const response = await this.restService.searchProgrammesLite(query);
      if (response.success && response.data && response.data.length > 0) {
        this.programme_list = response.data;
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }


  async onSearchClick(): Promise<void> {

    try {
      if (!this.programme_fee_patch_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_fee_patch_form.value.semester) {
        alert('Please select semester');
        return;
      }
      if (!this.programme_fee_patch_form.value.term) {
        alert('Please select term');
        return;
      }
      if (!this.programme_fee_patch_form.value.fee_target_type) {
        alert('Please select fee_target_type');
        return;
      }
      this.programme_fee_settings_loading = true;

      const query: any = {
        programme_id: this.programme_fee_patch_form.value.programme_id,
        regulation_year: this.programme_fee_patch_form.value.regulation_year,
        semester: this.programme_fee_patch_form.value.semester,
        term: this.programme_fee_patch_form.value.term,
        fee_target_type: this.programme_fee_patch_form.value.fee_target_type,
        admission_id: this.programme_fee_patch_form.value.admission_id
      };
      const response = await this.restService.getSavedProgrammeFeeSettings(query);
      if (response.success && response.data) {
        this.buildProgrammeSplitSettingsForm(this.programme_fee_patch_form.value.programme_id, response.data);
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_fee_settings_loading = false;
    }


  }

  buildProgrammeSplitSettingsForm(programme_id: string, data: any): void {
    this.programme_fee_settings_form = this.fb.group({
      programme_id: [programme_id],
      mode: [data.mode || ''],
      accounts: this.fb.array(this.buildProgrammeSplitItemForm(data.account_splits))
    });
  }

  buildProgrammeSplitItemForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return: any[] = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        sub_acc_id: [data_item.sub_acc_id],
        account_no: [data_item.account_no],
        amount: [data_item.amount],
      }));
    }
    return value_to_return;
  }

  getTotalAmount(): number {
    const programme_fee_form_data = this.programme_fee_settings_form.value;
    const account_data_list = programme_fee_form_data.accounts;
    let total_amount = 0;
    let has_error = false;
    for (const account_data of account_data_list) {


      try {
        account_data.amount = parseFloat(account_data.amount);
      } catch (error) {
        alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
        has_error = true;
        break;
      }

      if (account_data.application_fee_amount < 0) {
        alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
        has_error = true;
        break;
      }

      total_amount = total_amount + account_data.amount;

    }
    if (has_error) {
      return 0;
    }
    return total_amount;
  }
  async onSaveProgrammeFormClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      if (!this.programme_fee_patch_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_fee_patch_form.value.semester) {
        alert('Please select semester');
        return;
      }
      if (!this.programme_fee_patch_form.value.term) {
        alert('Please select term');
        return;
      }
      if (!this.programme_fee_patch_form.value.fee_target_type) {
        alert('Please select fee_target_type');
        return;
      }

      this.programme_fee_settings_save_loading = true;

      const programme_fee_form_data = this.programme_fee_settings_form.value;
      const account_data_list = programme_fee_form_data.accounts;


      let has_error = false;
      let total_amount = 0;
      for (const account_data of account_data_list) {


        try {
          account_data.amount = parseFloat(account_data.amount);
        } catch (error) {
          alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
          has_error = true;
          break;
        }

        if (account_data.application_fee_amount < 0) {
          alert(`Please enter valid fee for ${account_data.sub_acc_id}`);
          has_error = true;
          break;
        }
        total_amount = total_amount + account_data.amount;

      }
      if (has_error) {
        return;
      }

      const data_to_save: any = {
        admission_id: this.admission._id,
        regulation_year: this.admission.year,
        programme_id: this.programme_fee_patch_form.value.programme_id,
        start_date: this.admission.start_date,
        end_date: this.admission.end_date,
        semester: this.programme_fee_patch_form.value.semester,
        term: this.programme_fee_patch_form.value.term,
        fee_target_type: this.programme_fee_patch_form.value.fee_target_type,
        mode: this.programme_fee_settings_form.value.mode,
        amount: total_amount,
        account_splits: account_data_list
      };


      const saved_respose = await this.restService.createProgrammeFeeBulk(data_to_save);
      if (saved_respose.success) {
        alert('Saved Successfully');
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_fee_settings_save_loading = false;
    }
  }

  resetProgrammeFeeForm(): void {
    this.programme_fee_patch_form = undefined;
    this.programme_fee_settings_form = undefined;
    this.programme_fee_settings_split_form = undefined;
    this.admission = undefined;
    this.programme_list = [];
  }
  async onProgrammeFeeFormCloseClick(): Promise<void> {
    this.resetProgrammeFeeForm();
    JQueryHelper.closeModal('#modal-programme-fee');
  }

  //#endregion

  //#region Settings
  async onSettingsClick(admission: any): Promise<void> {
    this.admission = admission;
    this.buildSettingsForm(admission);
    JQueryHelper.openModal('#modal-settings', { keyboard: false, backdrop: 'static' });
  }


  buildSettingsForm(admission_data: any): void {
    this.programme_settings_fillter_form = this.fb.group({
      admission_id: [admission_data._id],
      admission_name: [admission_data.name],
      programme_type_id: [admission_data.programme_type_id],
      regulation_year: [admission_data.year],
      finance_type: [''],
    });
  }

  async onSettingsPrgFinanceTypeChange(): Promise<void> {
    try {

      const query: any = {
        finance_type: this.programme_settings_fillter_form.value.finance_type,
        programme_type_id: this.programme_settings_fillter_form.value.programme_type_id,
        admission_id: this.admission._id,
      };
      this.programme_settings_list_loading = true;
      const response = await this.restService.getSavedAdmissionSettings(query);
      if (response.success && response.data && response.data.length > 0) {
        this.programme_settings_form = this.fb.group({
          programmes: this.fb.array(this.buildApplicationSettingForm(response.data))
        });
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_settings_list_loading = false;
    }
  }

  buildApplicationSettingForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        _id: [data_item._id],
        programme_type_id: [data_item.programme_type_id],
        finance_type: [data_item.finance_type],
        programme_category: [data_item.programme_category],
        programme_id: [data_item.programme_id],
        programme_code: [data_item.programme_code],
        programme_name: [data_item.programme_name],
        genders: [data_item.genders || ['male', 'female']],
        allowed_seats: [data_item.allowed_seats || 0],
        extra_seats: [data_item.extra_seats || 0],
        need_entrance_exam: [data_item.need_entrance_exam],
        online_class_incharge_name: [data_item.online_class_incharge_name],
        online_class_incharge_mobile: [data_item.online_class_incharge_mobile],
        online_class_code: [data_item.online_class_code],
        incharge_details: this.fb.array(this.buildInchargeForm(data_item.incharge_details))
      }));
    }
    return value_to_return;
  }

  buildInchargeForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const data_to_return = [];
    for (const data_item of data) {
      data_to_return.push(this.fb.group({
        class_code: [data_item.class_code],
        incharge_name: [data_item.incharge_name],
        incharge_mobile: [data_item.incharge_mobile],
        section: [data_item.section],
      }));
    }
    return data_to_return;
  }

  async onSaveAdmissionSettingsFormClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.programme_settings_save_loading = true;

      const programme_fee_form_data = this.programme_settings_form.value;
      const programmes_data_list = programme_fee_form_data.programmes;


      let has_error = false;

      const data_list_to_save: any[] = [];
      for (const programmes_data of programmes_data_list) {


        try {
          programmes_data.extra_seats = parseInt(programmes_data.extra_seats, 10);
        } catch (error) {
          alert(`Please enter valid extra_seats for ${programmes_data.programme_name}`);
          has_error = true;
          break;
        }

        if (programmes_data.extra_seats < 0) {
          alert(`Please enter valid extra_seats for ${programmes_data.programme_name}`);
          has_error = true;
          break;
        }

        if (!programmes_data.genders || programmes_data.genders.length === 0) {
          alert(`Please enter valid genders for ${programmes_data.programme_name}`);
          has_error = true;
          break;
        }

        const total_seats = programmes_data.allowed_seats + programmes_data.extra_seats;
        const data_to_save: any = {
          admission_id: this.admission._id,
          programme_id: programmes_data.programme_id,
          programme_type_id: programmes_data.programme_type_id,
          finance_type: programmes_data.finance_type,
          need_entrance_exam: programmes_data.need_entrance_exam,
          allowed_seats: total_seats,
          extra_seats: programmes_data.extra_seats,
          genders: programmes_data.genders,
          online_class_incharge_name: programmes_data.online_class_incharge_name,
          online_class_incharge_mobile: programmes_data.online_class_incharge_mobile,
          online_class_code: programmes_data.online_class_code,
          incharge_details: programmes_data.incharge_details
        };

        data_list_to_save.push(data_to_save);

      }
      if (has_error) {
        return;
      }

      const saved_respose = await this.restService.createAdmissionSettings(data_list_to_save);
      if (saved_respose.success) {
        alert('Saved Successfully');
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.programme_settings_save_loading = false;
    }
  }



  resetSettingsForm(): void {
    this.programme_settings_fillter_form = undefined;
    this.programme_settings_form = undefined;
    this.admission = undefined;
  }
  async onSettingsFormCloseClick(): Promise<void> {
    this.resetSettingsForm();
    JQueryHelper.closeModal('#modal-settings');
  }

  //#endregion

  //#region Reports
  async onReportsClick(admission: any): Promise<void> {
    this.admission = admission;
    if (this.admission_search_form.value.regulation_year >= 2021) {
      this.router.navigate(['/admin/admissions/reports'], {
        queryParams:
        {
          admission_id: admission._id,
          regulation_year: this.admission_search_form.value.regulation_year
        }
      });
    } else {
      this.router.navigate(['/admin/admissions/admission/admission-overall-report'],
        { queryParams: { admission_id: admission._id, admission_name: admission.name } });
    }

  }
  //#endregion

  //#region Entrance
  async onEntranceClick(admission: any): Promise<void> {
    this.router.navigate(['/admin/admissions/entrance/entrance-master'], {
      queryParams:
      {
        admission_id: admission._id,
        regulation_year: this.admission_search_form.value.regulation_year
      }
    });
  }
  //#endregion
}

interface Subject {
  all: ISchoolSubjectMaster[];
  anyone: ISchoolSubjectMaster[];
}

interface ISchoolSubjectMaster {
  subject_name: string;
  part_type: number;
  subject_type: string;
  subject_id: string;
  syllabus_type: string;
}

interface ScrollerDataInterface {
  _id: string,
  messages: ScrollMessageInterface[]
}

interface ScrollMessageInterface {
  message: string,
  link: string,
  is_active: boolean,
  priority_no: number
}