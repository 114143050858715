<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>

  <!-- container -->
  <div class="container-fluid">
    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Courses</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">My Courses</h4>
            </div>
            <p class="tx-12 tx-gray-500 mb-2">Choose and view my courses</p>
          </div>

          <div class="card-body" *ngIf="search_form">
            <form [formGroup]="search_form">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department Finance Type</label>
                    <select formControlName="semester" class="form-control">
                      <option value="">Select Semester</option>
                      <option *ngFor="
                          let semester of commonEnums.getSemesterList(6);
                          let i = index;
                          trackBy: fromDataResolver.identify
                        " [ngValue]="semester.id">
                        {{ semester.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!programme_course_list_loading" (click)="getSavedProgrammeCourseList()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="programme_course_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th rowspan="2">S.No</th>
                    <th rowspan="2">Part</th>
                    <th rowspan="2">Course Type</th>
                    <th rowspan="2">Course Code</th>
                    <th rowspan="2">Course Name</th>
                    <th colspan="3" class="text-center">
                      Weekly Contact Hours
                    </th>
                    <th rowspan="2" class="verical-middle text-center" style="width: 5%">
                      No. Of Credits
                    </th>
                    <th rowspan="2" class="verical-middle text-center" style="width: 5%">
                      Exam. Hours
                    </th>
                    <th colspan="3" class="text-center">Marks</th>
                  </tr>
                  <tr>
                    <th class="text-center" style="width: 5%">L</th>
                    <th class="text-center" style="width: 5%">T</th>
                    <th class="text-center" style="width: 5%">P</th>

                    <th class="text-center" style="width: 6%">Int.</th>
                    <th class="text-center" style="width: 6%">Ter.</th>
                    <th class="text-center" style="width: 6%">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="programme_course_list.length > 0">
                    <ng-container *ngFor="
                        let programme_course of programme_course_list;
                        let i = index;
                        trackBy: fromDataResolver.identify
                      ">
                      <tr>
                        <td colspan="13">
                          {{ programme_course.programme_name }}
                        </td>
                      </tr>

                      <ng-container *ngFor="
                          let course_map_request of programme_course.course_map_request;
                          let j = index;
                          trackBy: fromDataResolver.identify
                        ">
                        <tr>
                          <td scope="row">
                            {{ j + 1 }}
                          </td>

                          <td scope="row">
                            {{ course_map_request.programme_scheme.part }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme.course_type
                            }}
                          </td>
                          <ng-container *ngIf="course_map_request.approved">
                            <td scope="row">
                              {{ course_map_request.approved_course_code }}
                            </td>
                            <td scope="row">
                              {{ course_map_request.approved_course_name }}
                            </td>
                          </ng-container>
                          <ng-container *ngIf="course_map_request.approved == false">
                            <td scope="row">
                              <button class="btn btn-sm btn-primary"
                                (click)="getAvailableProgrammes(programme_course,course_map_request)">
                                <ng-container *ngIf="course_map_request.request_submitted">
                                  Modify Course Request
                                </ng-container>
                                <ng-container *ngIf="!course_map_request.request_submitted">
                                  Choose Courses
                                </ng-container>

                              </button>
                            </td>
                            <td scope="row"></td>
                          </ng-container>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme
                            .weekly_lecture_hours
                            }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme
                            .weekly_practical_hours
                            }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme
                            .weekly_tutorial_hours
                            }}
                          </td>
                          <td scope="row">
                            {{ course_map_request.programme_scheme.credits }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme.exam_duration
                            }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme
                            .max_internal_mark
                            }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme
                            .max_external_mark
                            }}
                          </td>
                          <td scope="row">
                            {{
                            course_map_request.programme_scheme
                            .max_internal_mark +
                            course_map_request.programme_scheme
                            .max_external_mark
                            }}
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="
                      !programme_course_list_loading &&
                      programme_course_list.length == 0
                    ">
                    <tr>
                      <th colspan="13" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="
                      programme_course_list_loading &&
                      programme_course_list.length == 0
                    ">
                    <tr>
                      <th colspan="13" class="text-center">
                        Loading Please wait
                      </th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Large Modal -->
  <!-- <div class="modal" id="modal-popup">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content modal-content-demo" *ngIf="course_request_form">
        <div class="modal-header">
          <h6 class="modal-title">Select Course</h6>

        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered mg-b-0 text-md-nowrap">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Course Code</th>
                  <th>Course Name</th>
                  <th>Action</th>

                </tr>

              </thead>
              <tbody>
                <ng-container *ngIf="course_request_form">

                  <tr>
                    <td colspan="4">
                      Course From Other Departments
                    </td>

                  </tr>
                  <ng-container *ngFor="
                  let elective_course of fromDataResolver.getFormArrayControlls(course_request_form,'other_courses_list');
                  let i = index;
                  trackBy: fromDataResolver.identify
                ">
                    <ng-container [formGroup]="elective_course">

                      <tr>
                        <td>
                          {{i+1}}
                        </td>
                        <td>
                          {{ elective_course.value.course_code }}
                        </td>
                        <td>
                          {{ elective_course.value.course_name }}
                        </td>
                        <td>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="od_course_request_{{i}}"
                              name="od_course_request_{{i}}" formControlName="requested"
                              (change)="onCheckBoxChange(elective_course)">
                            <label class="custom-control-label" for="od_course_request_{{i}}">Request</label>
                          </div>
                        </td>
                      </tr>

                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="fromDataResolver.getFormArrayControlls(course_request_form,'same_courses_list').length>0">

                    <tr>
                      <td colspan="4">
                        Course From Your Department
                      </td>
                    </tr>
                    <ng-container *ngFor="
                      let elective_course of fromDataResolver.getFormArrayControlls(course_request_form,'same_courses_list');
                      let i = index;
                      trackBy: fromDataResolver.identify
                    ">

                      <ng-container [formGroup]="elective_course">
                        <tr>
                          <td>
                            {{i+1}}
                          </td>
                          <td>
                            {{ elective_course.value.course_code }}
                          </td>
                          <td>
                            {{ elective_course.value.course_name }}
                          </td>
                          <td>
                            Requested
                            <!-- <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="yd_course_request_{{i}}"
                              name="yd_course_request_{{i}}" formControlName="requested" disabled>
                            <label class="custom-control-label" for="yd_course_request_{{i}}">Request</label>
                          </div> //End comment line here
                          </td>
                        </tr>
                      </ng-container>

                    </ng-container>

                  </ng-container>

                </ng-container>


              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body">
          <div class="mg-b-20"></div>
          <div class="text-align-right">
            <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
              *ngIf="!course_request_form_loading">Save</button>
            <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_request_form_loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
            <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Large Modal -->


  <!--New Course Apply Modal-->
  <div class="modal" id="modal-popup">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content modal-content-demo" *ngIf="course_request_form">
        <div class="modal-header">
          <h6 class="modal-title">Request Course</h6>

        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered mg-b-0 text-md-nowrap">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Course Code</th>
                  <th>Course Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                <ng-container *ngFor="
                                        let elective_course of fromDataResolver.getFormArrayControlls(course_request_form,'courses_list')
                                        let i = index; trackBy: fromDataResolver.identify">
                  <ng-container [formGroup]="elective_course">
                    <tr>
                      <td>
                        {{i+1}}
                      </td>
                      <td>
                        {{ elective_course.value.course_code }}
                      </td>
                      <td>
                        {{ elective_course.value.course_name }}
                      </td>
                      <td>
                        <button class="btn mg-r-10" (click)="onRequestButtonClick(elective_course, i)"
                          [ngClass]="(elective_course.value.requested)?'btn-success':'btn-primary'">
                          <ng-container *ngIf="elective_course.value.requested">
                            Requested
                          </ng-container>
                          <ng-container *ngIf="!elective_course.value.requested">
                            Request
                          </ng-container>
                        </button>
                        <button class="btn btn-info" (click)="viewSyllabus(elective_course)">View Syllabus</button>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body">
          <div class="mg-b-20"></div>
          <div class="text-align-right">
            <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- /main-content -->
<app-footer></app-footer>