import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener, ViewChild, ViewChildren } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-student-submission-review',
  templateUrl: './student-submission-review.component.html',
  styleUrls: ['./student-submission-review.component.scss']
})
export class StudentSubmissionReviewComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Review Student Answer Sheets';
  submission_list: any[] = [];
  submission_list_loading: boolean = false;
  file_uploading: boolean = false;
  student_file_data: any = undefined;
  file: File;
  files: any[] = [];
  preview_url: string = "";
  modal_mode: string = "";
  exam_id: string = "";
  submission_id: string = "";
  reason: string = "";
  reject_loading: boolean;
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.exam_id = params.exam_id;
      if (this.exam_id != undefined) {
        this.renderData();
      }
    });
  }

  async renderData() {
    try {
      this.submission_list_loading = true;
      const service_resp = await this.restService.searchStudentSubmission({ exam_id: this.exam_id });
      if (service_resp && service_resp.success) {
        this.submission_list = service_resp.data;
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      console.log(error);
    }
    finally {
      this.submission_list_loading = false;
    }
  }

  async openModal(e: any, submission: any, j: number): Promise<void> {
    this.submission_id = submission._id;
    this.student_file_data = {
      "exam_id": submission.exam_id,
      "roll_no": submission.roll_no,
      "stage": submission.stage,
      "reviewed_by_note": submission.reviewed_by_note,
      index: j,
    }
    //const query_data = new HttpParams({ fromObject: data }).toString()
    this.file = (e.target as HTMLInputElement).files[0];
    console.log(this.file.size);
    if (this.file.size > 1073741824) {
      alert("File size should be less than 1 GB.");
      e.target.value = "";
      return;
    }
    console.log(this.file);
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.preview_url = event.target.result;
    }
    reader.readAsDataURL(this.file);
    this.modal_mode = "UPLOAD";
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }


  async uploadFile(): Promise<void> {
    try {
      const consent = confirm("Are you sure want to submit this file?");
      if (!consent) return;
      const formData = new FormData();
      console.log(this.file);
      formData.append('file', this.file);
      this.file_uploading = true;
      const response = await this.restService.uploadAnswerFile(formData);
      if (response.success && response.data) {
        var file_url = response.data.url;
        this.files[this.student_file_data.index] = "";
        delete this.student_file_data.index;
        this.student_file_data.file_path = file_url;
        await this.updateSubmissionData();

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      console.log(error);
    } finally {
      this.file_uploading = false;
    }
  }

  async reviewFile(submission: any, stage: string) {
    try {
      this.submission_id = submission._id;
      this.student_file_data = {
        "exam_id": submission.exam_id,
        "roll_no": submission.roll_no,
        "file_path": submission.file_path,
        "stage": stage,
        "reviewed_by_note": ""
      }
      if (stage == 'REJECTED') {
        this.modal_mode = "REJECT";
        JQueryHelper.openModal('#reason-popup', { keyboard: false, backdrop: 'static' });
      }
      else {
        this.modal_mode = "";
        const consent = confirm("Are you sure want to approve this submission?");
        if (!consent) return;
        await this.updateSubmissionData();
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
      console.log(error);
    }
    finally {

    }
  }

  async updateSubmissionData() {
    const resp = await this.restService.updateStudentSubmission(this.submission_id, this.student_file_data);
    if (resp && resp.success) {
      this.renderData();
      alert("Submission Review submitted Successfully");
      if (this.modal_mode == "REJECT") {
        this.closeReasonModal();
      }
      if (this.modal_mode == "UPLOAD") {
        this.closeModal();
      }
    }
  }
  async rejectFile() {
    this.student_file_data.reviewed_by_note = this.reason;
    this.student_file_data.stage = "REJECTED";
    this.reject_loading = true;
    await this.updateSubmissionData();
    this.reject_loading = false;
  }

  async revertStage(submission: any) {
    try {
      this.submission_id = submission._id;
      this.student_file_data = {
        "exam_id": submission.exam_id,
        "roll_no": submission.roll_no,
        "file_path": submission.file_path,
        "stage": "SUBMITTED",
        "reviewed_by_note": ""
      }
      const consent = confirm("Are you sure want to cancel this status?");
      if (!consent) return;
      this.modal_mode = "";
      await this.updateSubmissionData();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.update));
      console.log(error);
    }
    finally {

    }
  }


  closeReasonModal() {
    this.student_file_data = undefined;
    this.reason = "";
    JQueryHelper.closeModal('#reason-popup');
  }

  closeModal() {
    this.files[this.student_file_data.index] = "";
    this.preview_url = "";
    this.modal_mode = "";
    this.file = null; this.student_file_data = undefined;
    JQueryHelper.closeModal('#modal-popup');
  }

  viewPreview(submission) {
    this.modal_mode = "PREVIEW";
    this.preview_url = submission.file_path;
    this.student_file_data = {
      "exam_id": submission.exam_id,
      "roll_no": submission.roll_no
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
}
