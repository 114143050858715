import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-manage-staff-assign',
  templateUrl: './manage-staff-assign.component.html',
  styleUrls: ['./manage-staff-assign.component.scss']
})
export class ManageStaffAssignComponent implements OnInit {


  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  programme_type_list: any[] = [];
  finance_types = [{ id: 'regular', text: 'AIDED' }, { id: 'self_finance', text: 'SELF FINANCE' }];


  // Query Data
  programme_id = '';
  revision_id = '';
  no_of_semesters = 0;


  // Core datas
  semester_wise_programme_scheme_form: any;

  semester_programme_data: any;
  semester_programme_data_loading: boolean = false;

  staff_assign_loading = false;

  programme_data: any;



  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    this.route.queryParams.subscribe(params => {
      this.programme_id = params.programme_id;
      this.revision_id = params.revision_id;
      this.no_of_semesters = params.no_of_semesters;
    });
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Core data
    this.getProgrammeById();

  }


  async getProgrammeById(): Promise<void> {
    try {


      const saved_items = await this.restService.getProgrammeById(this.programme_id);
      if (saved_items && saved_items.data) {
        if (!saved_items.data.section_details) {
          saved_items.data.section_details = [];
        }
        this.programme_data = saved_items.data;
        this.buildSemesterScheme();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  buildSemesterScheme(): void {
    let section_id = '';
    if (this.programme_data.section_details
      && this.programme_data.section_details.length === 1) {
      section_id = this.programme_data.section_details[0]._id;
    }
    this.semester_wise_programme_scheme_form = this.fb.group({
      enrolled_year: [''],
      section_id: [section_id]
    });
  }

  async getSavedProgrammeScheme(): Promise<void> {
    try {

      const search_item = {
        programme_id: this.programme_id,
        revision_id: this.revision_id
      };
      this.semester_programme_data_loading = true;
      const saved_items = await this.restService.searchProgrameSchemeHeaderForStaffAssignment(search_item);
      if (saved_items && saved_items.data) {
        if (!saved_items.data.programme.section_details) {
          saved_items.data.programme.section_details = [];
        }
        this.semester_programme_data = saved_items.data;
        // this.buildSemesterScheme();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.semester_programme_data_loading = false;
    }
  }


  // staff assign
  getSemesterData(semester: number): any[] {
    const programme_schemes: any[] = this.semester_programme_data.programme_schemes;
    return programme_schemes.filter(c => c.semester === semester);
  }

  getCourseDisplay(courses: any[], key: string): string {
    const val: string[] = [];
    for (const course of courses) {
      val.push(course[key]);
    }
    return val.join('/');
  }

  async onAssignButtonClick(programe_scheme: any, semester: number): Promise<void> {

    if (programe_scheme.open) {
      programe_scheme.open = false;
    } else {
      for (const programme_scheme of this.semester_programme_data.programme_schemes) {
        programme_scheme.open = false;
      }
      programe_scheme.open = true;
      this.buildSectionForm(programe_scheme, semester);
    }
  }

  async buildSectionForm(programe_scheme: any, semester: number): Promise<void> {
    try {
      let course_code = '';
      if (programe_scheme.courses
        && programe_scheme.courses.length === 1) {
        course_code = programe_scheme.courses[0].course_code;
      }
      programe_scheme.section_form = this.fb.group({
        course_code: [course_code]
      });

      this.buildSemWiseProgrammeFilterForm(programe_scheme, semester);

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }

  }


  async onCourseChange(data: any, programe_scheme: any, semester: number): Promise<void> {
    this.buildSemWiseProgrammeFilterForm(programe_scheme, semester);
  }

  async buildSemWiseProgrammeFilterForm(programe_scheme: any, semester: number): Promise<void> {
    try {

      if (programe_scheme.section_form.value.course_code) {
        let staff_info: any[] = [];
        const saved_response = await this.restService.getAssignedStaffWithCourse(
          semester, programe_scheme.section_form.value.course_code,
          this.semester_wise_programme_scheme_form.value.enrolled_year,
          this.semester_wise_programme_scheme_form.value.section_id);
        if (saved_response.success && saved_response.data && saved_response.data.length > 0) {
          staff_info = saved_response.data;
        }
        programe_scheme.staff_form = this.fb.group({
          staffs: this.fb.array(this.buildStaffForm(staff_info))
        });
      } else {
        programe_scheme.staff_form = null;
      }



    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }

  }

  async onEnrollmentYearChange(data: any): Promise<void> {
    this.semester_programme_data = null;
    // this.getSavedProgrammeScheme();
  }

  buildStaffForm(staffs: any[]): any[] {
    const staff_data: any[] = [];
    if (!staffs) {
      staffs = [];
    }
    if (staffs.length > 0) {
      for (const staff of staffs) {
        staff_data.push(this.fb.group(
          {
            _id: [staff._id],
            sub: [staff.sub],
            roll_no: [staff.roll_no],
            name: [staff.name],
            departments: [staff.departments],
            is_primary: [staff.is_primary]
          }
        ));
      }
    } else {
      staff_data.push(this.fb.group(
        {
          _id: [''],
          sub: [''],
          roll_no: [''],
          name: [''],
          departments: [''],
          is_primary: [false]
        }
      ));
    }

    return staff_data;
  }

  async onRollNoChanged(data: any, staff_form: FormGroup, staff_parent_form: FormGroup): Promise<void> {
    try {
      const roll_no = staff_form.value.roll_no;
      if (!roll_no) {
        return;
      }
      let is_primary = true;
      if (staff_parent_form.value.staffs && staff_parent_form.value.staffs.length > 1) {
        is_primary = false;
      }
      const saved_item_response = await this.restService.findStaffBuRollNo(roll_no.toUpperCase());
      if (saved_item_response.success && saved_item_response.data) {
        staff_form.patchValue({
          _id: [''],
          name: saved_item_response.data.name,
          departments: saved_item_response.data.departments,
          sub: saved_item_response.data.sub,
          is_primary
        });
      } else {
        staff_form.patchValue({
          _id: [''],
          roll_no: '',
          name: '',
          departments: '',
          sub: '',
          is_primary,
          section_id: [this.semester_wise_programme_scheme_form.value.section_id]
        });
        alert('Invalid roll no');
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  addMoreStaff(staff_parent_form: FormGroup): void {
    (staff_parent_form.get('staffs') as FormArray).push(
      this.fb.group(
        {
          _id: [''],
          sub: [''],
          roll_no: [''],
          name: [''],
          departments: [''],
          is_primary: [false],
          section_id: [this.semester_wise_programme_scheme_form.value.section_id]
        }
      )
    );
  }

  async removeStaff(id: string, staff_form: FormGroup, staff_parent_form: FormGroup
    // tslint:disable-next-line:align
    , index: number): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to remove ?');
      if (!consent) {
        return;
      }

      const sub = staff_form.value.sub;
      if (!sub) {
        (staff_parent_form.get('staffs') as FormArray).removeAt(index);
        return;
      }

      const save_response = await this.restService.unAssignStaffWithCourse(id);
      if (save_response.success) {
        alert('Saved Successfully');
        (staff_parent_form.get('staffs') as FormArray).removeAt(index);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }

  }

  async onSaveStaffAssignClick(programe_scheme: any, staff_parent_form: FormGroup,
    // tslint:disable-next-line:align
    semester: number, course_code: string): Promise<void> {

    try {
      const consent = confirm('Are you sure do you want to save ?');
      if (!consent) {
        return;
      }

      this.staff_assign_loading = true;

      const staff_parent_data = staff_parent_form.value.staffs;
      const data_items: any[] = [];
      for (const staff_parent of staff_parent_data) {
        data_items.push({
          enrolled_year: Number(this.semester_wise_programme_scheme_form.value.enrolled_year),
          semester,
          course_code,
          sub: staff_parent.sub,
          is_primary: staff_parent.is_primary,
          section_id: this.semester_wise_programme_scheme_form.value.section_id
        });
      }

      const save_response = await this.restService.assignStaffWithCourse(data_items);
      if (save_response.success) {
        alert('Saved Successfully');
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.staff_assign_loading = false;
    }

  }
}
