import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-components-master',
  templateUrl: './components-master.component.html',
  styleUrls: ['./components-master.component.scss']
})
export class ComponentsMasterComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // master data

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  parts: any[] = [];
  course_types: any[] = [];

  component_search_form: any;

  // core data

  component_list: {
    key: string, components: any[]
  }[] = [];
  component_list_loading = false;

  component_form: any;
  component_add_loading = false;
  component: any;
  result: any[] = [];
  key: string = "";
  calculation_form_search: any;
  calculation_form_search_loading = false;

  calculation_form: any;
  calculation_form_save_loading = false;
  component_delete_loading: boolean[] = [];
  expanded_component_list: any[] = [];
  terms: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }
  ngOnInit(): void {
    // Load master data
    this.buildComponentSearchForm();
    this.getProgrammeTypeList();
    // Load core data
  }
  /// master data
  buildComponentSearchForm(): void {
    this.component_search_form = this.fb.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: ['']
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.course_types = [];
      this.parts = this.commonEnums.getParts(this.component_form.value.programme_type_id);
      (this.component_form as FormGroup).get("part").setValue("");
    } else if (id === 'part') {
      this.course_types = [];
      const course_types = this.commonEnums.getCoursePartType(this.component_form.value.programme_type_id, this.component_form.value.part);
      if (course_types) {
        this.course_types = course_types;
      }
    }
  }

  async onSearchClick(): Promise<void> {
    this.getProgrammeDataList();
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.component_list_loading = true;
      this.component_list = [];
      //const service_response = await this.restService.searchComponent(this.component_search_form.value);
      const service_response = await this.restService.getComponentList(this.component_search_form.value);
      if (service_response && service_response.success) {
        this.result = service_response.data;
        console.log(this.result);
        this.result.forEach((component_header, index) => {
          this.component_list[index] = ({ key: component_header.key, components: [] })
          component_header.components.forEach((component) => {
            var comp = JSON.parse(JSON.stringify(component));
            if ((this.component_list[index].components.length == 0) || (this.component_list[index].components.filter(x => x.component_type == component.component_type).length == 0)) {
              comp.term = 1;
              comp.id = btoa(Math.random().toString()).substr(10, 5);
              this.component_list[index].components.push(comp);
            }
            else {
              this.component_list[index].components.find(x => x.component_type == component.component_type).term++;
            }
          })
        })
        console.log(this.component_list);
        console.log(this.result);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.component_list_loading = false;
    }
  }


  /// Core data

  buildComponentForm(): void {
    if (!this.component) {
      this.component = {};
      this.component._id = '';
      this.component.programme_type_id = this.component_search_form.value.programme_type_id;
      this.component.enrolled_year = this.component_search_form.value.enrolled_year;
      this.component.semester = this.component_search_form.value.semester;
      this.component.course_code = '';
      this.component.course_type = '';
      this.component.part = '';
      this.component.component_list = [];
    }
    this.component_delete_loading = [];
    this.component_form = this.fb.group({
      _id: [this.component._id],
      programme_type_id: [this.component.programme_type_id],
      enrolled_year: [this.component.enrolled_year],
      semester: [this.component.semester],
      course_code: [this.component.course_code],
      course_type: [this.component.course_type],
      part: [this.component.part],
      component_list: this.fb.array(this.buildComponentsListForm(this.component.component_list))
    });

    if (this.component_form.value.programme_type_id) {
      this.parts = this.commonEnums.getParts(this.component_form.value.programme_type_id);
    }
    /* (this.component_form.get('component_list') as FormArray).controls.forEach((control: FormGroup) => {
      if (control.get('id').value != "") {
        control.get('component_type').disable(); control.get('term').disable();
      }
    }) */
  }

  buildComponentsListForm(component_list): any {
    if (!component_list) {
      component_list = [];
    }
    var component_list_array: any[] = [];
    if (component_list && component_list.length > 0) {
      var programme_count = 0;
      for (const component of component_list) {
        //console.log(component)
        this.component_delete_loading[programme_count] = false;
        component_list_array.push(this.fb.group({
          id: [component.id],
          component_type: [component.component_type, [Validators.required]],
          term: [component.term, [Validators.required]],
          max_mark: [component.max_mark, [Validators.required]],
          scale_to: [component.scale_to, [Validators.required]],
          is_expanded: [false],
        }));
        programme_count++;
      }
    }
    const remaining_count = 1 - component_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.component_delete_loading[index] = false;
        component_list_array.push(this.fb.group({
          id: [""],
          component_type: ['', [Validators.required]],
          term: ['', [Validators.required]],
          max_mark: ['', [Validators.required]],
          scale_to: ['', [Validators.required]],
          is_expanded: [false],
        }));
      }
    }
    console.log(component_list_array);
    return component_list_array;
  }


  addMoreComponent(): void {
    this.component_delete_loading[this.component_delete_loading.length] = false;
    this.fromDataResolver.getFormArray(this.component_form, 'component_list').push(this.fb.group({
      id: [""],
      component_type: ['', [Validators.required]],
      term: ['', [Validators.required]],
      max_mark: ['', [Validators.required]],
      scale_to: ['', [Validators.required]],
      is_expanded: [false],
    }));
  }

  get componentControls() {
    return (this.component_form.get('component_list') as FormArray).controls;
  }

  onComponentChange(event: Event, index: number) {
    const element = (event.target as HTMLSelectElement);
    const component_type = element.value;
    console.log(event);
    console.log(component_type);
    /* if (this.component_form.value.component_list.filter(x => x.component_type == component_type).length > 1) {
      element.value = "";
      alert("Component Type already set.");
    } */
  }

  async onAddClick(): Promise<void> {
    this.buildComponentForm();
    JQueryHelper.openModal('#modal-popup-component', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(component: any, key: string): Promise<void> {
    this.key = key;
    this.component = {
      programme_type_id: component[0].programme_type_id,
      enrolled_year: component[0].enrolled_year,
      semester: component[0].semester,
      course_code: component[0].course_code,
      course_type: component[0].course_type,
      part: component[0].part,
      component_list: component
    };
    this.parts = this.commonEnums.getParts(this.component.programme_type_id);
    this.course_types = [];
    if (this.component.part) {
      const course_types = this.commonEnums.getCoursePartType(this.component.programme_type_id, this.component.part);
      if (course_types) {
        this.course_types = course_types;
      }
    }
    this.buildComponentForm();
    JQueryHelper.openModal('#modal-popup-component', { keyboard: false, backdrop: 'static' });
  }


  async onCloseClick(): Promise<void> {
    this.resetComponentForm();
    JQueryHelper.closeModal('#modal-popup-component');
    this.key = ""; //this.result = [];
  }

  resetComponentForm(): void {
    this.component = undefined;
    this.component_form = undefined;
  }

  expandComponents(open_component_type: string, index: number) {
    this.expanded_component_list = [];
    var control = ((this.component_form.get('component_list') as FormArray).controls[index] as FormGroup).get('is_expanded');
    if (control.value) {
      control.setValue(false);
    }
    else {
      var arr_components: any[] = this.result.find(x => x.key == this.key).components;
      arr_components.filter(x => x.component_type == open_component_type).forEach(matching_component => {
        this.expanded_component_list.push(matching_component);
      });
      control.setValue(true);
    }
    (this.component_form.get('component_list') as FormArray).controls.forEach((control: FormGroup) => {
      if (control.value.component_type != open_component_type) {
        control.value.is_expanded = false;
      }
    });
    console.log(this.expanded_component_list);
  }

  closeExpandedView(index: number) {
    this.expanded_component_list = [];
    ((this.component_form.get('component_list') as FormArray).controls[index] as FormGroup).get('is_expanded').setValue(false);
  }


  async onSaveComponentsClick(): Promise<void> {
    try {
      const component_form_data = this.component_form.value;
      if (!component_form_data.programme_type_id) {
        alert('Please select programme');
        return;
      }
      if (!component_form_data.enrolled_year) {
        alert('Please select enrolled year');
        return;
      }
      if (!component_form_data.semester) {
        alert('Please select semester');
        return;
      }

      if (!component_form_data.part) {
        alert('Please select part');
        return;
      }

      const consent = confirm("Are you sure you want to save?");
      if (!consent) {
        return;
      }
      this.component_add_loading = true;
      var save_data: ComponentInterface = {
        programme_type_id: component_form_data.programme_type_id,
        enrolled_year: component_form_data.enrolled_year,
        semester: component_form_data.semester,
        part: component_form_data.part,
        component_list: []
      };
      if (component_form_data.course_type) {
        save_data.course_type = component_form_data.course_type;
      }
      if (component_form_data.course_code) {
        save_data.course_code = component_form_data.course_code;
      }
      if (this.key == "") {  //add mode
        component_form_data.component_list.forEach(component => {
          for (var i = 1; i <= component.term; i++) {
            var save_obj: any = {
              component_type: component.component_type,
              term: CommonEnums.rommon_letters[i],
              max_mark: parseInt(component.max_mark),
              scale_to: parseFloat(component.scale_to),
            }
            save_data.component_list.push(save_obj);
          }
        });
      }
      else { //edit mode
        console.log(this.result); console.log(this.key);
        var arr_components: any[] = this.result.find(x => x.key == this.key).components;
        console.log(arr_components);
        component_form_data.component_list.forEach(form_component => {
          if (form_component.id) {
            if (arr_components.findIndex(x => x.component_type == form_component.component_type) >= 0) //update existing component
            {
              arr_components.filter(x => x.component_type == form_component.component_type).forEach(new_arr_comp => {
                new_arr_comp.max_mark = parseInt(form_component.max_mark);
                new_arr_comp.scale_to = parseFloat(form_component.scale_to);
              });
            }
          }
          else {
            if (arr_components.findIndex(x => x.component_type == form_component.component_type) >= 0) //component type already exist
            //update terms
            {
              var existing_terms = arr_components.filter(x => x.component_type == form_component.component_type).length;
              for (var i = existing_terms + 1; i <= form_component.term + existing_terms; i++) {
                var new_obj: any = {
                  component_type: form_component.component_type,
                  term: CommonEnums.rommon_letters[i],
                  max_mark: parseInt(form_component.max_mark),
                  scale_to: parseFloat(form_component.scale_to),
                }
                arr_components.push(new_obj);
              }
            }
            else //insert new component along the existing components
            {
              for (var i = 1; i <= form_component.term; i++) {
                var new_obj: any = {
                  component_type: form_component.component_type,
                  term: CommonEnums.rommon_letters[i],
                  max_mark: parseInt(form_component.max_mark),
                  scale_to: parseFloat(form_component.scale_to),
                }
                arr_components.push(new_obj);
              }
            }
          }
        });
        console.log(arr_components);
        save_data.component_list = [];
        save_data.component_list = arr_components;
      }
      console.log(save_data);
      //this.onCloseClick();

      let service_response = await this.restService.createUpdateComponent(save_data);
      if (service_response && service_response.success) {
        this.onSearchClick();
        alert('Saved Successfully');
        this.onCloseClick();
      }
      else {
        alert("Could not save data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.component_add_loading = false;
    }
  }

  async deleteComponentById(index: number, component_id: string) { //for edit view
    const consent = confirm('Are you sure do you want to delete this component?');
    if (!consent) {
      return;
    }
    this.component_delete_loading[index] = true;
    console.log(component_id);
    if (this.key != "") {
      const delete_programme_response = await this.restService.deleteComponentById(component_id);
      if (delete_programme_response && delete_programme_response.success === true) {
        this.onSearchClick();
        alert('Component Deleted Successfully');
        //this.onCloseClick();
      } else {
        alert('Error while deleting');
      }

    }
    var arr_components: any[] = this.result.find(x => x.key == this.key).components;
    arr_components.splice(arr_components.findIndex(x => x.id == component_id), 1); //remove from original array
    this.expanded_component_list.splice(index, 1); //remove from expanded view list
    this.component_delete_loading[index] = false; //stop delete button index
    this.component_delete_loading.splice(index, 1); //remove delete button index
    var term = ((this.component_form.get('component_list') as FormArray).controls[index] as FormGroup).get("term");
    term.setValue(term.value - 1);
  }

  deleteComponent(index: number) //for add view
  {
    const consent = confirm('Are you sure do you want to delete this component?');
    if (!consent) {
      return;
    }
    (this.component_form.get('component_list') as FormArray).removeAt(index);
    alert("Component deleted!");
  }

  // Calculation

  async onAddCalculaitonClick(): Promise<void> {
    this.buildCalComponentForm();
    JQueryHelper.openModal('#modal-popup-calculation', { keyboard: false, backdrop: 'static' });
  }

  buildCalComponentForm(): void {
    this.calculation_form_search = this.fb.group({
      programme_type_id: [this.component_search_form.value.programme_type_id],
      semester: [this.component_search_form.value.semester],
      enrolled_year: [this.component_search_form.value.enrolled_year],
    });
    this.onSearchCalculation(true);
  }

  async onSearchCalculation(background: boolean = false): Promise<void> {


    try {
      if (!this.calculation_form_search.value.programme_type_id) {
        if (!background) {
          alert('Please select programme type');
        }
        return;
      }
      if (!this.calculation_form_search.value.semester) {
        if (!background) {
          alert('Please select semester');
        }
        return;
      }
      if (!this.calculation_form_search.value.enrolled_year) {
        if (!background) {
          alert('Please select enrolled_year');
        }
        return;
      }
      this.calculation_form_search_loading = true;
      const service_response = await this.restService.getOBEComponentCalculation(this.calculation_form_search.value);
      if (service_response && service_response.success) {
        this.buildCalComponentDetailForm(service_response.data);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.calculation_form_search_loading = false;
    }

  }

  buildCalComponentDetailForm(data: any[]): void {
    this.calculation_form = this.fb.group({
      calculation_list: this.fb.array(this.buildCalDataList(data))
    });
  }


  buildCalDataList(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const data_to_return = [];
    for (const data_item of data) {
      data_to_return.push(this.fb.group({
        component_types: [data_item.component_types || ''],
        programme_type_id: [data_item.programme_type_id || ''],
        semester: [data_item.semester || ''],
        enrolled_year: [data_item.enrolled_year || ''],
        evaluation_type: [data_item.evaluation_type || ''],
        calculation_type: [data_item.calculation_type || ''],
      }))
    }
    return data_to_return;
  }

  resetCalculationComponentForm(): void {
    this.calculation_form = null;
  }
  async onCloseCalculaitonClick(): Promise<void> {
    this.resetCalculationComponentForm();
    JQueryHelper.closeModal('#modal-popup-calculation');
  }


  async onCalculationSaveClick(): Promise<void> {


    try {
      const consent = confirm("Are you sure you want to save?");
      if (!consent) {
        return;
      }

      this.calculation_form_save_loading = true;
      const service_response = await this.restService.createComponentBulk(this.calculation_form.value.calculation_list);
      if (service_response && service_response.success) {
        alert('Saved Successfully');
        await this.onSearchCalculation();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.calculation_form_save_loading = false;
    }

  }



}

interface ComponentInterface {
  _id?: string;
  programme_type_id: string;
  enrolled_year: number;
  semester: number;
  course_code?: string;
  course_type?: string;
  part: string;
  component_list: {
    id: string,
    component_type: string;
    term: number;
    max_mark: number;
    scale_to: number;
  }[]
}
