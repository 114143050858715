<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Student Certificate Tracker</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info mg-r-10" (click)="onPrintClick()">Print</button>
                    <button type="button" class="btn btn-info" (click)="onReportClick()">View Report</button>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Student Certificate Tracker</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage receiving of Student Certificates</p>
                    </div>


                    <!--Individual List-->
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                Programme Name: <b>{{programme_name}}</b>
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-md btn-info" (click)="saveStudentData()" *ngIf="!save_loading"
                                    style="float: right;">Update</button>
                                <button class="btn btn-md btn-info" *ngIf="save_loading" disabled
                                    style="float: right;">Updating...</button>
                            </div>
                        </div>
                        <div class="mg-b-20"></div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width:5%">App. No.</th>
                                        <th style="width: 10%;">Name</th>
                                        <th style="width: 10%;">Gender</th>
                                        <th style="width: 10%;">Mobile No.</th>
                                        <th style="width: 5%;">Community</th>
                                        <th style="width: 10%;">DOB</th>
                                        <th
                                            *ngFor="let certificate of student_data[0]?.certificate_list; let i = index;">
                                            {{certificate.certificate_name}}
                                        </th>
                                        <th style="width: 20%;">
                                            Remarks
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let student of student_data;let i=index;trackBy:fromDataResolver.identify;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{student.application_no}}
                                        </td>
                                        <td class="uppercase">
                                            {{student.name}}
                                        </td>
                                        <td>
                                            {{student.gender | titlecase}}
                                        </td>
                                        <td>
                                            {{student.mobile_number}}
                                            <ng-container *ngIf="student.mobile_number_2">
                                                <br>{{student.mobile_number_2}}
                                            </ng-container>
                                            <ng-container *ngIf="student.mobile_number_3">
                                                <br>{{student.mobile_number_3}}
                                            </ng-container>
                                        </td>
                                        <td class="uppercase">
                                            {{student.community_id}}
                                        </td>
                                        <td>
                                            {{student.birth_date | date:commonEnums.date_format}}
                                        </td>
                                        <td *ngFor="let certificate of student.certificate_list; let j = index;">
                                            <input type="checkbox" [(ngModel)]="certificate.is_received"
                                                name="{{certificate.certificate_name}}_{{j}}">
                                        </td>
                                        <td>
                                            <input type="textbox" class="form-control"
                                                [(ngModel)]="student.certificate_remarks"
                                                placeholder="Enter remarks, if any.">
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="!student_data_loading && student_data.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_data_loading && student_data.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <div class="row mg-t-20">
                            <div class="col-md-12 text-center">
                                <button class="btn btn-md btn-info" (click)="saveStudentData()"
                                    *ngIf="!save_loading">Update</button>
                                <button class="btn btn-md btn-info" *ngIf="save_loading" disabled>Updating...</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">View Report</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-info btn-sm" (click)="downloadReportCSV()"
                                        style="float:right">Download
                                        CSV</button>
                                </div>
                            </div>
                            <form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Filter Type</label>
                                            <select [(ngModel)]="report_filter" class="form-control"
                                                name="report_filter" (change)="reportTypeChange($event)">
                                                <option value="all">Select Filter Type</option>
                                                <option value="pending">Pending</option>
                                                <option value="full">No Pending</option>
                                                <option value="given">Given</option>
                                                <option value="not_given">Not Given</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%;">S.No</th>
                                            <th style="width:5%">App. No.</th>
                                            <th style="width: 15%;">Name</th>
                                            <th style="width: 20%;">Mobile No.</th>
                                            <th>Certificate List</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of filter_data; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>
                                                {{student.application_no}}
                                            </td>
                                            <td class="uppercase">
                                                {{student.name}}
                                            </td>
                                            <td>
                                                {{student.mobile_number}}
                                                <ng-container *ngIf="student.mobile_number_2">
                                                    <br>{{student.mobile_number_2}}
                                                </ng-container>
                                                <ng-container *ngIf="student.mobile_number_3">
                                                    <br>{{student.mobile_number_3}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                {{getCertificateData(student)}}
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="!filter_data_loading && filter_data.length==0">
                                            <tr>
                                                <th colspan="5" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="filter_data_loading && filter_data.length==0">
                                            <tr>
                                                <th colspan="5" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>