<app-side-bar></app-side-bar>

<!-- main-content -->
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid ">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <app-navigation></app-navigation>
      <!-- <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Incharge Management</h2>
          <p class="mg-b-0"></p>
        </div>
      </div> -->
    </div>

    <!-- Create hostel -->
    <div class="row row-sm d-flex align-items-center justify-content-center">
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="card box-shadow-0">
          <div class="card-header">
            <h4 class="card-title mb-1">{{ getTittle() }}</h4>
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="menuItemForm" class="form-horizontal">
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Menu Item Name</label>
                <input class="form-control" required="" type="text" formControlName="item_name"
                placeholder="Enter Menu Item Name"
                >
                <div *ngIf="isInvalid('item_name')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Menu item name is required.</li>
                  </ul>
                </div>
              </div>

              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Menu Item Code</label>
                <input class="form-control" required="" type="text" formControlName="item_code"
                placeholder="Enter Menu Item Code">
                <div *ngIf="isInvalid('item_code')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Menu item code is required.</li>
                  </ul>
                </div>
              </div>

              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Item Type</label>
                <select formControlName="item_type" class="form-control"
                  (change)="onFilterSelectChange('item_type',$event)">
                  <option value="">Choose one</option>
                  <option *ngFor="let type of itemType;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="type">
                    {{ type }}</option>
                </select>
                <div *ngIf="isInvalid('item_type')">
                  <ul class="parsley-errors-list filled">
                    <li class="parsley-required">Item type is required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Quantity</label>
                <input class="form-control" required="" type="text" formControlName="quantity">
                <div *ngIf="isInvalid('quantity')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Quantity is not valid. Leave default value zero, if it is not
                      applicable.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Price</label>
                <input class="form-control" required="" type="text" formControlName="price">
                <div *ngIf="isInvalid('price')">
                  <ul class="parsley-errors-list filled" id="parsley-id-9">
                    <li class="parsley-required">Price is not valid. Leave default value zero, if it is not applicable.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                <button class="btn ripple btn-secondary" (click)="onClose()" type="button">Close</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>
