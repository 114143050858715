<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1"></h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
    </div>

    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Fees & Payments</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Pay outstanding fees and view its payment status</p>
          </div>



          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <!-- <th>Programe Type</th> -->
                    <th>Programe Name</th>
                    <th>Fees Type</th>
                    <th>Term Name</th>
                    <!-- <th>Semester</th> -->
                    <!-- <th>Installment</th> -->
                    <th>Due Date</th>
                    <!-- <th>Started Date</th> -->
                    <th>Fee</th>
                    <th>Fine</th>
                    <th>Total</th>

                    <th>Status</th>
                    <th style="width: 15%;">Action</th>
                    <!-- <th>Transaction Reference(s)</th> -->

                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="payment_details && payment_details.length>0">


                    <ng-container
                      *ngFor="let payment_detail of payment_details;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <th scope="row">
                          {{i+1}}

                        </th>
                        <td class="uppercase">{{payment_detail.programme_name}}</td>
                        <td class="uppercase">{{payment_detail.fee_header_type.replace('_',' ')}}</td>
                        <td class="uppercase">{{payment_detail.term_name}}</td>
                        <td class="uppercase">
                          <ng-container *ngIf="payment_detail.due_date">
                            {{payment_detail.due_date | date:commonEnums.date_format}}
                          </ng-container>
                        </td>
                        <td>
                          <span *ngIf="payment_detail.original_amount">
                            {{payment_detail.original_amount | currency:"INR"}}
                          </span>
                          <span *ngIf="!payment_detail.original_amount">
                            {{payment_detail.amount | currency:"INR"}}
                          </span>
                        </td>
                        <td>
                          {{payment_detail.fine | currency:"INR"}}
                        </td>
                        <td class="text-center">
                          {{payment_detail.amount | currency:"INR"}}
                          <ng-container *ngIf="payment_detail.breakups && payment_detail.breakups.length>0">
                            <div class="col-sm-12">
                              <button (click)="viewBreakupsClick(payment_detail)" title="View Breakups Details"
                                class="btn btn-sm btn-info btn-block">
                                View Details</button>
                            </div>
                          </ng-container>
                        </td>

                        <td>
                          <div class="col-sm-12 uppercase" *ngIf="payment_detail.payment_completed">
                            Paid
                          </div>
                          <div class="col-sm-12 uppercase" *ngIf="!payment_detail.payment_completed">
                            Not Paid
                          </div>

                        </td>
                        <td>
                          <ng-container *ngIf="payment_detail?.fee_header_type !='HOSTEL_ADMISSION_FEE' 
                            && payment_detail?.fee_header_type !='TRANSPORTATION_ADMISSION_FEE' ">
                            <div class="col-sm-12" *ngIf="!payment_detail.payment_completed">
                              <button (click)="payCourse2Fee(payment_detail)" class="btn btn-success btn-block">
                                Pay Online</button>
                            </div>
                          </ng-container>

                          <!-- <div class="col-sm-12 mg-b-5" *ngIf="payment_detail.fee_header_type=='EXAM_FEE'">
                            <button (click)="viewTimeTable()" class="btn btn-info btn-sm">
                              View Timetable</button>
                          </div>
                          <div class="col-sm-12"
                            *ngIf="payment_detail.payment_completed && payment_detail.fee_header_type=='EXAM_FEE'">
                            <button (click)="viewHallTicket()" class="btn btn-info btn-sm">
                              Download Hall Ticket</button>
                          </div> -->
                        </td>
                        <!-- <td>
                          {{payment_detail.transaction_ids.join(", ")}}
                        </td> -->


                      </tr>
                    </ng-container>

                  </ng-container>


                  <ng-container *ngIf="!payment_details_loading && payment_details.length==0">
                    <tr>
                      <th colspan="11" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="payment_details_loading && payment_details.length==0">
                    <tr>
                      <th colspan="11" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>



                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>


    </div>


    <!-- Large Modal -->
    <div class="modal" id="exam-fee-modal-popup">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="current_selection && current_selection.breakups">
          <div class="modal-header">
            <h6 class="modal-title">Course Details</h6>
          </div>
          <div class="modal-body">

            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Course Code</th>
                    <th>Course Name</th>
                    <th>Course Type</th>
                    <th>Appearance Type <br> (Regular - R, Arrear - A)</th>
                    <!-- <th>Amount</th> -->
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="current_selection.breakups.length>0">


                    <ng-container
                      *ngFor="let breakup of current_selection.breakups;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td class="uppercase">{{breakup.course_code}}</td>
                        <td class="uppercase">{{breakup.course_name}}</td>
                        <td class="uppercase">{{breakup.course_type}}
                          <ng-container *ngIf="breakup.exam_duration>0">
                            [{{breakup.exam_duration}} hours]
                          </ng-container>

                        </td>
                        <td class="uppercase">{{breakup.appearance_type=="normal"?"R":"A"}}</td>
                        <!-- <td class="uppercase">
                          {{breakup.fee_amount | currency:"INR"}}
                        </td> -->


                      </tr>
                    </ng-container>
                    <ng-container
                      *ngIf="current_selection.breakups_extra_charges && current_selection.breakups_extra_charges.length >0">
                      <ng-container
                        *ngFor="let breakups_extra_charge of current_selection.breakups_extra_charges;let i=index;trackBy:fromDataResolver.identify;">
                        <tr>
                          <td scope="row" colspan="4" class="uppercase text-right">
                            {{breakups_extra_charge.item_name}}
                          </td>
                          <td class="uppercase">
                            {{breakups_extra_charge.amount ? breakups_extra_charge.amount :'0' | currency:"INR"}}
                          </td>

                        </tr>
                      </ng-container>
                    </ng-container>

                    <tr>
                      <td scope="row" colspan="4" class="uppercase text-right" style="font-weight: bold;">
                        Fine
                      </td>
                      <td class="uppercase" style="font-weight: bold;">
                        {{current_selection.fine ? current_selection.fine : '0' | currency:"INR"}}
                      </td>

                    </tr>




                    <tr>
                      <td scope="row" colspan="4" class="uppercase text-right" style="font-weight: bold;">
                        Total
                      </td>
                      <td class="uppercase" style="font-weight: bold;">
                        {{current_selection.amount | currency:"INR"}}
                      </td>

                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </div>
            <div class="mg-b-20"></div>
            <div class="text-align-right">

              <button class="btn ripple btn-secondary" (click)="onCloseBreakupsClick()" type="button">Close</button>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->


    <!-- Hall Ticket Large Modal -->
    <div class="modal" id="hall-ticket-modal-popup">
      <div class="modal-dialog big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="hall_ticket_details">
          <div class="modal-header">
            <h6 class="modal-title">
              Hall Ticket</h6>
          </div>
          <div class="modal-body" id="hall_ticket">
            <img src="https://ik.imagekit.io/u0nq8vk2snk/blue1_ZpmaoLVQA.png?updatedAt=1641890093387"
              id="background-img">

            <div id="hall_ticket_content">
              <div class="text-center">
                <img src="https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603"
                  alt="college logo"
                  style="width: 125px !important; height: 100px !important; max-width: none; float: left;">
                <img [src]="hall_ticket_details.image_url"
                  style="width: 125px !important; height: 100px !important; max-width: none; float: right; border: 3px solid black;">
                <h4 style=" margin-bottom: 0px !important;">AYYA NADAR JANAKI AMMAL COLLEGE, SIVAKASI</h4>
                <p><i>(An Autonomous Institution Affiliated to The Madurai Kamaraj
                    University,
                    Madurai.)</i></p>
                <h5 class="mg-t-10">TERMINAL EXAMINATIONS, {{hall_ticket_details.term | uppercase}}
                </h5>

              </div>


              <div class="row">
                <div class="col-md-12 text-center">
                  <h5>HALL TICKET</h5>
                </div>
              </div>


              <div class="row  mg-t-10 mg-b-15 text-center">
                <div class="col-md-4">
                  <p class="font-16">Name: <b>{{hall_ticket_details.name | uppercase}}</b></p>
                </div>
                <div class="col-md-4">
                  <p class="font-16">Register No: <b>{{hall_ticket_details.roll_no | uppercase}}</b></p>
                </div>
                <div class="col-md-4">
                  <p class="font-16">Class: <b>{{hall_ticket_details.class}}</b></p>
                </div>
              </div>

              <div class="row mg-t-10">
                <div class="col-md-12">
                  <h6 class="mg-l-15">Appearing for following courses</h6>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered" style="table-layout: fixed;">
                  <tbody>
                    <ng-container *ngFor="let appearing_course of hall_ticket_details.appearing_courses">
                      <tr class="text-center">
                        <td class="table-cell"><b>{{appearing_course.appearance_type}}</b></td>
                        <td class="table-cell" *ngFor="let course of appearing_course.course_list">
                          {{course.course_code}}
                        </td>
                        <td class="table-cell" *ngFor="let i of emptyCells(appearing_course.course_list.length)"></td>
                      </tr>
                      <tr class="text-center">
                        <td class="table-cell">
                          <span><b>Exam Date & Time</b></span>
                        </td>
                        <td class="table-cell" *ngFor="let course of appearing_course.course_list">{{course.date_time}}
                        </td>
                        <td class="table-cell" *ngFor="let i of emptyCells(appearing_course.course_list.length)"></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <span class="mg-t-3 mg-l-15">FN - Forenoon, AN - Afternoon</span>

              <div class="row mg-t-5 mg-b-10">
                <div class="col-md-12 text-center">
                  <span><b><i>This Hall Ticket must be produced at the time of appearing of
                        examinations</i></b></span>
                </div>
              </div>


              <div class="row">
                <div class="col-md-4 text-center">
                  <p style="margin-top: 100px;"><b>Signature of the Candidate</b></p>
                </div>
                <div class="col-md-4 text-center">
                  <img
                    src="https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs3/30382050/original/f4cad70f8a61395702ebb862fa03de58cda01ee8/convert-pen-signature-in-to-beautiful-digital-signature.png"
                    style="height: 100px; width: 150px;"><br>
                  <span><b>Principal</b></span>
                </div>
                <div class="col-md-4 text-center">
                  <img src="https://d144mzi0q5mijx.cloudfront.net/img/A/M/Amostely-Signature.png"
                    style="height: 100px; width: 150px;"><br>
                  <span><b>Controller Of Examinations</b></span><br>
                  <span><b>({{hall_ticket_details.programme_type}} Programmes)</b></span>
                </div>
              </div>

              <p>Issue Date: <b>{{hall_ticket_details.issue_date}}</b></p>
              <div class="row mg-t-10 mg-b-20">
                <div class="col-md-9">
                  <b>Note:</b>
                  <p class="mg-l-10" style="text-align: justify;">1. Any form of malpractice in the Examination
                    Hall will be
                    severely dealt with. The
                    punishment normally
                    varies from
                    cancellation of all papers for which the candidate is appearing in the current semester to the
                    expulsion
                    from the
                    college.</p>
                  <p class="mg-l-10" style="text-align: justify;">2. You are asked to verify this personalized
                    time-table
                    with
                    the
                    Department-wise time-table
                    already sent to your e-mail ID.</p>
                  <p class="mg-l-10" style="text-align: justify;">3. The Examination results will be sent to your E-mail
                    ID
                    in
                    the Second week of February.</p>
                </div>
                <div class="col-md-3 text-center">
                  <img src="https://www.emoderationskills.com/wp-content/uploads/2010/08/QR1.jpg"
                    style="height: 100px; width: 160px;"><br>
                  <span><b>Scan & Verify</b></span>
                </div>
              </div>
            </div>

          </div>

          <div class="modal-footer">
            <button class="btn ripple btn-success mg-r-5" (click)="printHallTicket()" type="button">Print</button>
            <button class="btn ripple btn-secondary mg-r-5" (click)="closeHallTicketModal()"
              type="button">Close</button>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->


    <!-- Timetable Large Modal -->
    <div class="modal" id="time-table-modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="hall_ticket_details">
          <div class="modal-header">
            <h6 class="modal-title">Exam Time Table</h6>
          </div>
          <div class="modal-body" id="time_table">
            <div class="row">
              <div class="col-md-12 text-center">
                <h5 class="mg-t-10">Terminal Examinations, {{hall_ticket_details.term}}</h5>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style="width: 10%;">S.No</th>
                    <th style="width: 20%;">Course Code</th>
                    <th style="width: 30%;">Course Name</th>
                    <th style="width: 20%;">Date(s) of Examination</th>
                  </tr>
                </thead>
                <ng-container
                  *ngFor="let appearing_course of hall_ticket_details.appearing_courses; let i=index;trackBy:fromDataResolver.identify; ">
                  <tr>
                    <td colspan="4"><b>{{appearing_course.appearance_type}} Courses</b></td>
                  </tr>
                  <tr
                    *ngFor="let course of appearing_course.course_list; let i=index;trackBy:fromDataResolver.identify; ">
                    <td>{{i+1}}</td>
                    <td>{{course.course_code}}</td>
                    <td>{{course.course_name}}</td>
                    <td>{{(course.date_time.length>0)?course.date_time.join(", "):course.date_time}}</td>
                  </tr>
                </ng-container>
              </table>
            </div>
            <span class="mg-t-3 mg-l-15">FN - Forenoon, AN - Afternoon</span>
          </div>

          <div class="modal-footer">
            <button class="btn ripple btn-secondary mg-r-5" (click)="closeTimeTableModal()" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--End Large Modal -->

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>