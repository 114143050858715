import { Component, OnInit } from '@angular/core';
import { RemoveEvent, SchedulerEvent, CreateFormGroupArgs, EditEventArgs } from '@progress/kendo-angular-scheduler';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { Modal } from 'src/app/components/ModalInterface';
import { FormatSettings } from "@progress/kendo-angular-dateinputs";
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
enum EditMode {
  Event,
  Occurrence,
  Series,
}

enum CrudOperation {
  Edit,
  Remove
}

@Component({
  selector: 'app-holiday-calendar',
  templateUrl: './holiday-calendar.component.html',
  styleUrls: ['./holiday-calendar.component.scss']
})
export class HolidayCalendarComponent implements OnInit {


  public selectedDate: Date = new Date();
  public formGroup: FormGroup;

  public events: SchedulerEvent[] = [];
  academic_year: any;

  bulk_render_data: Modal = {
    modal_button_text: "Holiday Bulk Upload",
    modal_header: "Holiday List",
    list_name: "Holiday",
    csv_data: {
      columns: [
        { column_name: "title", column_type: "required" },
        { column_name: "description", column_type: "required" },
        { column_name: "start", column_type: "required" },
        { column_name: "end", column_type: "optional" }
      ]
    },
    table_data: {
      column_names: ["Title", "Description", "Start", "End"],
      column_values: ["Car Festival", "Local holiday", "12/08/2021", "12/08/2021"]
    }
  };

  public format: FormatSettings = {
    displayFormat: "dd/MM/yyyy HH:mm",
    inputFormat: "dd/MM/yyyy HH:mm",
  };


  constructor(private formBuilder: FormBuilder,
    private holidaySettingsService: AdminservicesService) {
    this.createFormGroup = this.createFormGroup.bind(this);
    this.formGroup = this.formBuilder.group({});
  }
  ngOnInit(): void {
    //this.getFormDataList();
    this.academic_year = this.getYearRange()[0];
    this.getHolidayList();
  }

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    this.showSchedular();
    const dataItem = args.dataItem;
    if (args.isNew)
      dataItem.end = new Date(dataItem.end.setDate(dataItem.end.getDate() - 1))
    this.formGroup = this.formBuilder.group({
      isNew: args.isNew,
      title: [dataItem.title, Validators.required],
      description: [dataItem.description, Validators.required],
      start: [dataItem.start, Validators.required],
      end: [new Date(dataItem.end.setUTCHours(23, 59, 59, 999)), Validators.required],
      id: dataItem.id,
      eventId: dataItem.eventId
    });
    return this.formGroup;
  }


  public cancelHandler() {
    this.hideSchedular();
  }

  deleteHandler(event: any, { sender, dataItem }: RemoveEvent) {
    sender.openRemoveConfirmationDialog().subscribe((shouldRemove) => {
      if (shouldRemove)
        this.deleteHoliday(this.formGroup.get('id')?.value);
    });
  }

  public saveHandler({ sender, formGroup, isNew, dataItem, mode }: any): void {
    if (formGroup.valid) {
      const formValue = formGroup.value;
      let holidaySlot = {
        start_date: (formValue['start'] as Date).toISOString(),
        end_date: (formValue['end'] as Date).toISOString(),
        name: formValue['title'],
        description: formValue['description'],
        academic_year: this.academic_year,
        id: formGroup.value.id
      }
      if (!isNew) {
        this.updateHoliday(holidaySlot);
      } else {
        this.addHoliday(holidaySlot, dataItem);
      }
    }
  }

  async addHoliday(holidaySlot: Holiday, dataItem: any): Promise<any> {
    const service_response = await this.holidaySettingsService.createHoliday(holidaySlot)
    if (service_response && service_response.success) {
      this.getHolidayList();

    }
  }

  async updateHoliday(holidaySlot: Holiday): Promise<any> {
    const service_response = await this.holidaySettingsService.updateHoliday(holidaySlot)
    if (service_response && service_response.success) {
      this.getHolidayList();
    }

  }

  removeHoliday() {
    this.deleteHoliday(this.formGroup.get('id')?.value);
    this.cancelHandler();
  }

  async deleteHoliday(holidaySlotId: any): Promise<any> {
    const service_response = await this.holidaySettingsService.deleteHoliday(holidaySlotId)
    if (service_response && service_response.success) {
      this.getHolidayList();
    }

  }

  async getHolidayList(): Promise<any> {
    this.events = [];
    let demoData = {
      id: 1,
      title: 'Breakfast',
      start: new Date('2021-11-11T09:00:00'),
      end: new Date('2021-11-11T09:30:00'),
      recurrenceRule: 'FREQ=DAILY;COUNT=5;'
    };
    const service_response = await this.holidaySettingsService.getHolidays(this.academic_year);
    if (service_response && service_response.success) {
      if (service_response.data) {
        this.events = service_response.data.data.map(function (event) {
          return {
            "id": event.id,
            "title": event.name,
            "start": new Date(event.start_date),
            "end": new Date(event.end_date),
            "description": event.description
          }
        });


        // service_response.data.data.forEach((event,index)=>{
        //   eventArray.id = event.id;
        //   eventArray.title = event.name;
        //   eventArray.start = new Date(event.start_date);
        //   eventArray.end = new Date(event.end_date);
        //  // allEvents[index] = eventArray;
        //  this.events.push(eventArray);
        // });
        // this.events = service_response.data.data;
        // this.events = allEvents;

      }
    }
  }

  getEndTime() {
    return this.formGroup.get('end');
  }

  getYearRange() {
    var year = new Date().getFullYear();
    var lastyear = new Date().getFullYear() - 1;
    var range = [];
    var lastrange = [];
    var academicYear = [];
    lastrange.push(lastyear);
    range.push(year);
    for (var i = 1; i < 15; i++) {
      lastrange.push(lastyear + i);
      range.push(year + i);
      academicYear.push(lastrange[i - 1] + "-" + (lastrange[i]).toString());
    }
    return academicYear;
  }



  yearChange(year: any) {
    let selectedYear = "06-01-" + year.substring(0, 4);
    this.selectedDate = new Date(selectedYear);
    this.getHolidayList();
  }

  bulkUpload(event) {

  }

  showSchedular(): any {
    // return document.getElementsByTagName('kendo-scheduler-edit-dialog')[0].style.display = "block";
  }

  hideSchedular(): any {
    // return document.getElementsByTagName('kendo-scheduler-edit-dialog')[0].style.display = "none";
  }


}
interface Holiday {
  id?: string,
  academic_year?: string,
  name?: string,
  start_date?: any,
  end_date?: any,
  description?: string
}
