<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Repeat Attendance</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onViewReportClick()">View Report</button>
                </div>
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Attendance</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Manage Repeat Attendance</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Department Categories</p> -->
                    </div>
                    <div class="card-body" *ngIf="attendance_search_form">
                        <form [formGroup]="attendance_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="attendance_search_form.value.programme_id!='' && sections.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            To Section</label>
                                        <select formControlName="to_section_id" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select To Enrolment
                                            year</label>
                                        <select formControlName="to_enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Repeating
                                            Semester</label>
                                        <select formControlName="repeating_semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="attendance_date">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Hour</label>
                                        <select formControlName="attendance_hour" class="form-control">
                                            <option value="">Select Hour</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>


                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Roll No.</label>
                                        <input type="text" formControlName="roll_no" class="form-control"
                                            placeholder="Enter Roll No.">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!attendance_list_loading"
                                            (click)="getSavedAttendance()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="attendance_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Roll No.</th>
                                        <th>Hour Nos.</th>
                                        <th>Status</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="attendance_list.length>0">


                                        <ng-container
                                            *ngFor="let attendance of attendance_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">{{i+1}}</td>
                                                <td class="text-uppercase">{{attendance.given_name}}
                                                    {{attendance.middle_name}}</td>
                                                <td>{{attendance.roll_no}}</td>
                                                <td>{{attendance.hour}}</td>
                                                <td class="text-uppercase">{{displayAttendanceStatus(attendance)}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                                            (click)="onEditClick(attendance)">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>

                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!attendance_list_loading && attendance_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="attendance_list_loading && attendance_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="attendance_save_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Attendance</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="attendance_save_form">


                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Date</label>
                                    <input type="date" class="form-control" formControlName="attendance_date">
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Hour</label>
                                    <select formControlName="attendance_hour" class="form-control">
                                        <option value="''">Select Hour</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>


                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                        Code</label>
                                    <input type="text" class="form-control" formControlName="course_code"
                                        placeholder="Enter Course Code">
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll Nos</label>
                                    <textarea type="text" class="form-control" formControlName="roll_no"
                                        placeholder="Enter Roll Nos"></textarea>
                                    <small>Enter multiple roll nos as comma separated value (ex: 21PA01,21PA02)</small>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Status</label>
                                    <select formControlName="status" class="form-control">
                                        <option value="">Select Status</option>
                                        <option *ngFor="let status of hour_status; let i = index;" [value]="status.key">
                                            {{status.value}}
                                        </option>
                                    </select>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                        *ngIf="!attendance_save_form_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="attendance_save_form_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>

                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                        type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Report Modal -->
        <div class="modal" id="report-popup">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">View Attendance</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row row-sm">
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select [(ngModel)]="report_enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select [(ngModel)]="report_semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Roll No.</label>
                                        <input type="text" [(ngModel)]="report_roll_no" class="form-control"
                                            placeholder="Enter Roll No.">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!report_list_loading" (click)="getAttendanceReport()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="report_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>


                            </div>

                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="width: 40%;">Stat</th>
                                            <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Hours Present</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Hours Absent</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Days Present</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Days Absent</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Attendance %</td>
                                            <td></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                            <div class="text-align-right">
                                <button class="btn ripple btn-secondary" (click)="onReportCloseClick()"
                                    type="button">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
