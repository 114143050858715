<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Review Marks</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Review Marks</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Review Students' marks submitted by Staff</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2" *ngIf="cookieStore.isRoleExists(['coe', 'principal'])">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <!--  -->
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Enrollment Year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="0">Choose Enrollment Year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="0">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="cookieStore.isRoleExists(['coe', 'principal'])">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-2">
                                                        <div class="form-group">
                                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                                Exam</label>
                                                            <select formControlName="exam_id" class="form-control"
                                                                (change)="getMappedProgrammeList()">
                                                                <option value="">Select Exam</option>
                                                                <option
                                                                    *ngFor="let exam of settings_list;let i=index;trackBy:fromDataResolver.identify;"
                                                                    [ngValue]="exam._id">{{exam.course_code}}</option>
                                                            </select>
                                                        </div>
                                                    </div> -->

                                <!-- 

                                <div class="col-lg-2"
                                    *ngIf="programme_search_form.value.programme_id!='' && sections.length>1 && cookieStore.isRoleExists(['coe'])">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Select Section</option>
                                            <option
                                                *ngFor="let section of this.sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control" (change)="onFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>
                    
                                <div class="col-lg-2" *ngIf="this.part_course_types && this.part_course_types?.length>0">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Course Type</label>
                                        <select formControlName="course_type" class="form-control">
                                            <option value="">All Course Type</option>
                                            <option
                                                *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="course_type.id">{{course_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <!-- <div class="col-lg-2" *ngIf="cookieStore.isRoleExists(['coe'])">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Department</label>
                                        <select formControlName="department_id" class="form-control"
                                            (change)="onFilterSelectChange('department_id',$event)">
                                            <option value="">All Departments</option>
                                            <option
                                                *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department.department_id">{{department.department_name}}
                                                {{department.department_category_id}} -
                                                {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div> -->



                                <div class="col-lg-2" *ngIf="cookieStore.isRoleExists(['coe', 'principal'])">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                            Course Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Enter Course Code">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="cookieStore.isRoleExists(['coe', 'principal'])">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Stage</label>
                                        <select formControlName="stage" class="form-control">
                                            <option value="">Choose Stage</option>
                                            <option
                                                *ngFor="let stage of this.stages;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="stage">
                                                {{stage}}</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-info mr-2 search-button" title="Search"
                                            *ngIf="!review_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="review_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!--Individual List-->
                    <div class="card-body">
                        <div class="mg-b-20"></div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 3%;">S.No</th>
                                        <th style="width: 10%;">Course Code</th>
                                        <th>Course Name</th>
                                        <th style="width: 5%;">Part</th>
                                        <th style="width: 10%;">Course Type</th>
                                        <th style="width: 10%;">Programme Name</th>
                                        <th style="width: 10%;">Section</th>
                                        <th style="width: 10%;">Virtual Section</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let course of review_component_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                <b>{{this.start_index + i+1}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.course_code}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.course_name}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.part?course.part.split('_').join(' '):''}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.course_type?course.course_type.split('_').join(' '):''}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.programme_name}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.section}}</b>
                                            </td>
                                            <td class="uppercase">
                                                <b>{{course.virtual_section}}</b>
                                            </td>
                                            <td>
                                                <!-- <button type="button" class="btn btn-sm btn-info mr-2 mt-2"
                                                    (click)="onViewComponentsClick(course)"
                                                    *ngIf="!course.is_opened">View Components</button>
                                                <button type="button" class="btn btn-sm btn-info mr-2 mt-2"
                                                    (click)="onCloseComponentsClick(course)"
                                                    *ngIf="course.is_opened">Close Components</button> -->
                                                <ng-container
                                                    *ngIf="course.overall_submitted && cookieStore.isRoleExists(['coe', 'principal'])">
                                                    <span style="color:green" class="mt-2"><b>FINALIZED</b></span>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="!course.overall_submitted && cookieStore.isRoleExists(['coe', 'principal'])">
                                                    <button type="button" class="btn btn-sm btn-primary mr-2 mt-2"
                                                        *ngIf="!this.finalize_loading[i] && cookieStore.isRoleExists(['coe', 'principal'])"
                                                        (click)="onFinalizeClick(course,i)">Finalize
                                                        Course</button>
                                                    <button type="button" class="btn btn-sm btn-primary mr-2 mt-2"
                                                        disabled
                                                        *ngIf="this.finalize_loading[i] && cookieStore.isRoleExists(['coe', 'principal'])">Finalizing...</button>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="course.is_opened">
                                            <tr>
                                                <td colspan="9">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width: 3%;">S.No</th>
                                                                    <th>Component Type</th>
                                                                    <th style="width: 3%;">Term</th>
                                                                    <th style="width: 20%;">Created By</th>
                                                                    <th style="width: 20%;">
                                                                        Reviewed By</th>
                                                                    <th>Stage</th>
                                                                    <th style="width: 25%;">Action <br>
                                                                        <button type="button"
                                                                            class="btn btn-sm btn-success mg-r-10 mg-t-5"
                                                                            (click)="approveAll(course,i)"
                                                                            *ngIf="cookieStore.isRoleExists(['coe', 'principal']) && !course.overall_submitted">Finalize
                                                                            All</button>
                                                                        <button type="button"
                                                                            class="btn btn-sm btn-success mg-r-10 mg-t-5"
                                                                            (click)="approveAll(course,i)"
                                                                            *ngIf="cookieStore.isRoleExists(['hod']) && !course.overall_submitted">Submit
                                                                            All
                                                                            to COE</button>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let component of course.data;let j=index;trackBy:fromDataResolver.identify;">
                                                                    <td>
                                                                        {{j+1}}
                                                                    </td>
                                                                    <td>
                                                                        {{component.component_type}}
                                                                    </td>
                                                                    <td>
                                                                        {{component.term}}
                                                                    </td>
                                                                    <td class="uppercase">
                                                                        {{component.created_by_name}}
                                                                    </td>
                                                                    <td>
                                                                        {{component.reviewed_by_name}}<br>
                                                                        <u *ngIf="component.description && component.description!=''"
                                                                            (click)="openReviewDescription(component.description)"
                                                                            style="cursor: pointer;">
                                                                            Reviewer Description</u>
                                                                    </td>
                                                                    <td>{{component.stage}}</td>
                                                                    <td>
                                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                                            <ng-container
                                                                                *ngIf="component.stage!='FINALIZED'">
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-info mg-r-10 mg-t-5"
                                                                                    title="Mark Entry"
                                                                                    (click)="onReviewMarkClick(component)">
                                                                                    <ng-container
                                                                                        *ngIf="component.mark_loading">
                                                                                        Loading...
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="!component.mark_loading">
                                                                                        View Marks
                                                                                    </ng-container>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-success mg-r-10 mg-t-5"
                                                                                    (click)="getDescription(component,1)"
                                                                                    *ngIf="component.stage=='OBE_MEMBER' && cookieStore.isRoleExists(['obe_member'])">Submit
                                                                                    to
                                                                                    HOD</button>
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-success mg-r-10 mg-t-5"
                                                                                    (click)="getDescription(component,1)"
                                                                                    *ngIf="component.stage=='HOD' && cookieStore.isRoleExists(['hod'])">Submit
                                                                                    to COE</button>
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-success mg-r-10 mg-t-5"
                                                                                    (click)="getDescription(component,1)"
                                                                                    *ngIf="component.stage=='COE' && cookieStore.isRoleExists(['coe', 'principal'])">Finalize</button>
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-danger mg-r-10 mg-t-5"
                                                                                    (click)="getDescription(component,-1)">
                                                                                    Send back
                                                                                </button>
                                                                            </ng-container>
                                                                            <!-- <ng-container
                                                                                *ngIf="component.stage=='FINALIZED'">
                                                                                <b>FINALIZED</b>
                                                                            </ng-container> -->
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!review_list_loading && review_component_list.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="review_list_loading && review_component_list.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                            <ng-container *ngIf="total_records>100">
                                <ul class="pagination pull-right">
                                    <li class="page-item"><a class="page-link" (click)="onPrevClick()"><i
                                                class="icon ion-ios-arrow-back"></i></a></li>

                                    <li class="page-item"><a class="page-link" (click)="onNextClick()"><i
                                                class="icon ion-ios-arrow-forward"></i></a></li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-assignmark">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="assign_mark_map_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Review Mark for : {{assign_mark_map_form.value.course_code}} :
                            {{assign_mark_map_form.value.component_type}}
                            {{assign_mark_map_form.value.term}}</h6>
                    </div>
                    <div class="modal-body">
                        <div>
                            <form [formGroup]="assign_mark_map_form">
                                <div class="table-responsive">
                                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 3%;">S.No</th>
                                                <th style="width: 5%;">Roll No</th>
                                                <th style="width: 20%;">Name</th>
                                                <ng-container
                                                    *ngFor="let co of fromDataResolver.getFormArrayControlls(assign_mark_map_form,'students')[0].value.course_marks;let i=index;trackBy:fromDataResolver.identify;">
                                                    <th style="width: 10%;">CO {{commonEnums.rommon_letters[co.co_id]}}
                                                        ({{co.max_mark}})</th>
                                                </ng-container>
                                                <th style="width: 5%;">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let student of fromDataResolver.getFormArrayControlls(assign_mark_map_form,'students');let i=index;trackBy:fromDataResolver.identify;">
                                                <ng-container [formGroup]="student">
                                                    <tr>
                                                        <td>
                                                            {{i+1}}
                                                        </td>
                                                        <td>
                                                            {{student.value.roll_no}}
                                                        </td>
                                                        <td>
                                                            {{student.value.given_name}} {{student.value.middle_name}}
                                                        </td>
                                                        <ng-container
                                                            *ngFor="let course_mark of fromDataResolver.getFormArrayControlls(student,'course_marks');let i=index;trackBy:fromDataResolver.identify;">
                                                            <ng-container [formGroup]="course_mark">
                                                                <td>
                                                                    <div class="form-group"
                                                                        *ngIf="course_mark.value.stage=='HOD' || course_mark.value.stage=='COE'">
                                                                        <input class="form-control" required=""
                                                                            type="number" formControlName="mark"
                                                                            (focusout)="onMarkAssignSaveClick(student,course_mark)">
                                                                    </div>
                                                                    <div class="form-group"
                                                                        *ngIf="course_mark.value.stage=='OBE_MEMBER'">
                                                                        {{course_mark.value.mark}}
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                        </ng-container>
                                                        <td>
                                                            <div class="form-group" style="margin-top: 10px;">
                                                                <b>{{student.value.total}}</b>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <!-- <button class="btn btn-primary mg-r-20" (click)="onMarkAssignSaveClick()" *ngIf="!course_mark_map_form_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="course_mark_map_form_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button> -->


                                    <button class="btn ripple btn-secondary" (click)="onMarkAssignCloseClick()"
                                        type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!--Description Modal-->
        <div class="modal" id="modal-description">
            <div class="modal-dialog modal-lg modal-sm" role="document" *ngIf="component">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Move Component</h6>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description for
                                        moving the component to {{component.to_stage}} level</label>
                                    <textarea class="form-control" [(ngModel)]="description"
                                        placeholder="Ex: Errors in Mark Entry (or) Marks entered correctly"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mg-t-20">
                            <button class="btn btn-primary mg-r-20" (click)="moveComponent()"
                                *ngIf="!component.submit_loading">Submit to {{component.to_stage}}</button>
                            <button class="btn btn-primary mg-r-20" *ngIf="component.submit_loading"
                                disabled>Loading...</button>
                            <button class="btn btn-secondary mg-r-20" (click)="closeDescription()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Description Modal-->

        <!--Description Modal-->
        <div class="modal" id="modal-review-description">
            <div class="modal-dialog modal-lg modal-sm" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Reviewer Description</h6>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                Reviewer Description to move this component to this current stage:
                                <b>{{reviewer_description}}</b>
                            </div>
                        </div>
                        <div class="text-center mg-t-20">
                            <button class="btn btn-secondary mg-r-20" (click)="closeReviewDescription()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Description Modal-->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>