import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';



@Component({
  selector: 'app-non-academic-semester-report',
  templateUrl: './non-academic-semester-report.component.html',
  styleUrls: ['./non-academic-semester-report.component.scss']
})
export class NonAcademicSemesterReportComponent implements OnInit {

  title = 'Non-Academic Semester-wise Attainment';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  sections: any[] = [];
  table_data_loading: boolean = false;
  table_data: StudentSemesterNonAcademicAttainment[] = [];
  skillset_semester_data: IOBEStudentSkillSetUpload[] = []
  constructor(private formBuilder: FormBuilder, private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section: [''],
      enrolled_year: [''],
      semester: [''],

    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id == "programme_id") {
      this.sections = [];
      this.filter_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
      if (this.sections.length == 1) {
        this.filter_form.get('section').setValue(this.sections[0]._id);
      }
    }

    if (id === 'programme_type_id' || id === 'finance_type') {
      this.filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.filter_form);
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick() {
    if (!this.filter_form.value.programme_type_id) {
      alert("Please choose Programme Type"); return;
    }
    if (!this.filter_form.value.finance_type) {
      alert("Please choose Finance Type"); return;
    }
    if (!this.filter_form.value.programme_id) {
      alert("Please choose Programme Name"); return;
    }
    if (this.sections.length > 0 && !this.filter_form.value.section) {
      alert("Please choose section"); return;
    }
    if (!this.filter_form.value.enrolled_year) {
      alert("Please choose Regulation Year"); return;
    }
    if (!this.filter_form.value.semester) {
      alert("Please choose Semester"); return;
    }

    this.table_data_loading = true;
    try {

      const service_response = await this.restService.getSemesterNA(this.filter_form.value);
      if (service_response && service_response.success) {
        this.table_data = service_response.data?.students_scores;
        this.table_data_loading = false;
      }
      // this.table_data = [
      //   {
      //     roll_no: '20PA01',
      //     sub: 'asdfascsdc-afdasf-asfdds',
      //     given_name: 'AAA', middle_name: 'A',
      //     scores: [
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Interpersonal Skills",
      //         skillset_average_marks: 7
      //       },
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Core Academic / Sports & Performing Arts Skills",
      //         skillset_average_marks: 3
      //       },
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Innovation & Entrepreneurial Skills",
      //         skillset_average_marks: 6
      //       },
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Civic Skills",
      //         skillset_average_marks: 8
      //       },
      //     ]
      //   },
      //   {
      //     roll_no: '20PA02',
      //     sub: 'asdfascsdc-accasf-asfdds',
      //     given_name: 'BBB', middle_name: 'C',
      //     scores: [
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Interpersonal Skills",
      //         skillset_average_marks: 4
      //       },
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Core Academic / Sports & Performing Arts Skills",
      //         skillset_average_marks: 7
      //       },
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Innovation & Entrepreneurial Skills",
      //         skillset_average_marks: 8
      //       },
      //       {
      //         skillset_id: '1',
      //         skillset_name: "Civic Skills",
      //         skillset_average_marks: 5
      //       }
      //     ]
      //   },
      // ]
    }
    catch (error) {
      console.log(error);
      console.log(error.error.error.error_description);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      this.table_data = [];
      this.table_data_loading = false;
    }
    finally {
      this.table_data_loading = false;
    }
  }

  async viewSkillSetEvidence(skillset_id: string, roll_no: string) {
    try {
      //get sem + skillset score
      const skillset_payload = {
        enrolled_year: this.filter_form.value.enrolled_year,
        semester: this.filter_form.value.semester,
        programme_id: this.filter_form.value.programme_id,
        stage: "OBE_MONITOR",
        roll_no: roll_no,
        skillset_id: skillset_id
      };
      const service_response = await this.restService.getSkillSetUploadSearch(skillset_payload);
      if (service_response && service_response.success) {
        this.skillset_semester_data = service_response.data;
      }
      } catch (error) {
        alert('Error while read programme type');
      } finally {
        JQueryHelper.openModal('#modal-popup-view', { keyboard: false, backdrop: 'static' });
      }

    // this.skillset_semester_data = [
    //   {
    //     "_id": "dcaac3b6-ea9c-4037-9c61-7d24b5959c67",
    //     "semester": 2,
    //     "evidence_url": "https://lvfiles.blob.core.windows.net/skillsetfiles/a3927232-b318-40c1-9d64-f3f729fd39d7.png",
    //     "description": "Participated in Story Telling Competition and won 1st Place held at ANJAC",
    //     "skillset_id": "e2fe05c8-a37c-4d0b-886f-398c88bd4c70",
    //     "skill": "Appearing in Competitive Examinations",
    //     "date": "2021-10-31T00:00:00.000Z",
    //     "place": "2022-07-04",
    //     "roll_no": "20UL59",
    //     "point": 2,
    //   }
    // ]

  }

  async onModalCloseClick(): Promise<void> {
    this.skillset_semester_data = []
    JQueryHelper.closeModal('#modal-popup-view');
  }

}


//Non Academic Attainment Semester wise Report
interface NonAcademicSemesterReport {
  _id?: string,
  enrolled_year: number,
  semester: number,
  programme_type_id: string,
  finance_type: string,
  programme_id: string,
  section: string,
  students_scores: StudentSemesterNonAcademicAttainment[]
}

interface StudentSemesterNonAcademicAttainment {
  roll_no: string,
  given_name: string,
  middle_name: string,
  sub: string,
  scores: SkillSetMark[]
}

interface SkillSetMark {
  skillset_id: string,
  skillset_name: string,
  skillset_average_marks: number
}

//get List of evidences for Roll No + Semester + Skillset ID + Enrolled Year
interface IOBEStudentSkillSetUpload {
  _id?: string,
  enrolled_year?: number,
  semester: number,
  evidence_url: string,
  skillset_id: string,
  skill: string,
  description: string,
  date: string,
  place: string,
  sub?: string,
  roll_no?: string,
  point?: number
}
