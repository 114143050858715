<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">List Missing Course Assignment</p>
                    </div>
                    <div class="card-body">
                        <h6>Search By</h6>
                        <div class="row ">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="missing_staff" (change)="changeType($event)" id="flexRadioDefault1"
                                            checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Missing Staff Assignment
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            value="missing_student" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Missing Student Assignment
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio" value="student"
                                            (change)="changeType($event)" id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Missing Student
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2" *ngIf="type=='missing_staff'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department
                                            Category</label>
                                        <select formControlName="department_category_id" class="form-control"
                                            (change)="onFilterSelectChange('department_category_id',$event)">
                                            <option value="">All Department Categories</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.department_category_id">{{category.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='missing_student'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onProgrammeFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_type_id">{{programme.name}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2" *ngIf="type=='missing_staff' || type=='missing_student'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='missing_staff' || type=='missing_student'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select Enrolment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='missing_staff'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Department</label>
                                        <select formControlName="department_id" class="form-control"
                                            (change)="onFilterSelectChange('department_id',$event)">
                                            <option value="">All Departments</option>
                                            <option
                                                *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department.department_id">{{department.department_name}}
                                                {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2" *ngIf="type=='missing_student'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onProgrammeFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2"
                                    *ngIf="filter_form.value.programme_id!='' && sections.length>1 && type=='missing_student'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Section</label>
                                        <select formControlName="section" class="form-control">
                                            <option value="">Choose Section</option>
                                            <option
                                                *ngFor="let section of sections;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="section.section_name">{{section.section_name}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-lg-2" *ngIf="type=='missing_staff' || type=='missing_student'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="semester.id">{{semester.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='missing_student' || type=='missing_staff'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Part</label>
                                        <select formControlName="part" class="form-control"
                                            (change)="onProgrammeFilterSelectChange('part',$event)">
                                            <option value="">All Parts</option>
                                            <option
                                                *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="part.id">
                                                {{part.text}}</option>
                                        </select>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-2" *ngIf="type=='roll_no_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="roll_no"
                                            placeholder="Search with Student Roll No..">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='course_code_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </form>
                        <br>

                        <div class="table-responsive">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th>S.No</th>
                                        <th *ngIf="this.type == 'missing_student'">Roll No</th>
                                        <th *ngIf="this.type == 'missing_student'">Student Name</th>
                                        <th *ngIf="this.type == 'missing_staff'">Staff ID</th>
                                        <th *ngIf="this.type == 'missing_staff'">Staff Name</th>
                                        <th *ngIf="this.type == 'missing_student'">Programme Name</th>
                                        <th *ngIf="this.type == 'missing_staff'">Department Name</th>
                                        <th *ngIf="this.type == 'missing_student'">Section</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="this.type == 'missing_student'">
                                    <tr
                                        *ngFor="let data of table_data.student_data; let i = index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.roll_no}}</td>
                                        <td>{{data.given_name}} {{data.middle_name}}</td>
                                        <td>{{data.programme_name}}</td>
                                        <td>{{data.section}}</td>
                                    </tr>
                                    <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                        <tr>
                                            <td colspan="5" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                        <tr>
                                            <td colspan="5" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                                <tbody *ngIf="this.type == 'missing_staff'">
                                    <tr
                                        *ngFor="let data of table_data.staff_data; let i = index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.roll_no}}</td>
                                        <td>{{data.given_name}} {{data.middle_name}}</td>
                                        <td>{{data.department_name}}</td>
                                    </tr>
                                    <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                        <tr>
                                            <td colspan="4" class="text-center"><b>Loading...</b></td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                        <tr>
                                            <td colspan="4" class="text-center"><b>No Records found.</b></td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- /main-content -->
<app-footer></app-footer>