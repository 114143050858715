import * as cookies from 'browser-cookies';
import * as CryptoJS from 'crypto-js';

export class CookieStore {

    static access_token_key = 'UKM98';
    static role_key = 'BTBBV';
    static userinfo_key = 'UYHNsd';
    static enc_key = 'UTYMNRDSFEWVBN';
    static saveAccessToken(token: string): void {
        this.clearBearerToken();
        if (localStorage) {
            localStorage.setItem(this.access_token_key, token);
        } else {
            cookies.set(this.access_token_key, token);
        }
    }

    static getBearerToken(): string | null {
        if (localStorage && localStorage.getItem(this.access_token_key)) {
            return 'Bearer ' + localStorage.getItem(this.access_token_key);
        } else {
            const cookie = cookies.get(this.access_token_key);
            if (cookie) {
                return 'Bearer ' + cookie;
            }
            return null;
        }

    }

    static getToken(): string | null {
        if (localStorage && localStorage.getItem(this.access_token_key)) {
            return localStorage.getItem(this.access_token_key);
        } else {
            const cookie = cookies.get(this.access_token_key);
            if (cookie) {
                return cookie;
            }
            return null;
        }

    }

    static clearBearerToken(): void {
        if (localStorage) {
            localStorage.removeItem(this.access_token_key);
            localStorage.removeItem(this.userinfo_key);
            localStorage.removeItem(this.role_key);
        } else {
            cookies.set(this.access_token_key, '');
            cookies.set(this.userinfo_key, '');
            cookies.set(this.role_key, '');
        }
    }

    static encrypt(val: string): string {
        const data = btoa(val);
        return CryptoJS.AES.encrypt(data, this.enc_key).toString();
    }
    static decrypt(val: string): string {
        const data = CryptoJS.AES.decrypt(val, this.enc_key).toString(CryptoJS.enc.Utf8);
        return atob(data);
    }
    static saveUserInfo(userinfo: any): void {
        this.clearUserInfo();
        let roles: string[] = [];
        if (userinfo.groups && userinfo.groups.length > 0) {
            const portal_group = userinfo.groups.find((c: any) => c.group_id === 'anjac_colleges');
            if (portal_group && portal_group.roles && portal_group.roles.length > 0) {
                roles = portal_group.roles;
            }
        }
        if (localStorage) {
            localStorage.setItem(this.userinfo_key, this.encrypt(JSON.stringify(userinfo)));

            if (roles && roles.length > 0) {
                localStorage.setItem(this.role_key, this.encrypt(JSON.stringify(roles)));
            }
        } else {
            cookies.set(this.userinfo_key, this.encrypt(JSON.stringify(userinfo)));
            if (roles && roles.length > 0) {
                cookies.set(this.role_key, this.encrypt(JSON.stringify(roles)));
            }
        }
    }
    static getUserInfo(): any {
        if (localStorage && localStorage.getItem(this.userinfo_key)) {
            const userinfo = localStorage.getItem(this.userinfo_key);
            if (userinfo) {
                return JSON.parse(this.decrypt(userinfo));
            } else {
                return null;
            }
        } else {
            const cookie = cookies.get(this.userinfo_key);
            if (cookie) {
                return JSON.parse(this.decrypt(cookie));
            }
            return null;
        }

    }

    static clearUserInfo(): void {
        if (localStorage) {
            localStorage.removeItem(this.userinfo_key);
            localStorage.removeItem(this.role_key);
        } else {
            cookies.set(this.userinfo_key, '');
            cookies.set(this.role_key, '');
        }
    }

    static getRoles(): string[] | null {
        if (localStorage && localStorage.getItem(this.role_key)) {
            const roles = localStorage.getItem(this.role_key);
            if (roles) {
                return JSON.parse(this.decrypt(roles));
            }
            return null;
        } else {
            const cookie = cookies.get(this.role_key);
            if (cookie) {
                return JSON.parse(this.decrypt(cookie));
            }
            return null;
        }

    }

    static isRoleExists(roles_to_check: string[]): boolean {
        let present = false;
        const roles = this.getRoles();
        if (roles && roles.length > 0) {
            for (const role_to_check of roles_to_check) {
                if (roles.find(c => c === role_to_check)) {
                    present = true;
                }
            }
        }
        return present;
    }




}
