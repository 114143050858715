import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from './../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { MenuItems } from 'src/app/controllers/admins/hostel-management/manage-mess/menu-item';
import { Component, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { $ } from 'protractor';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'app-extra-menu',
  templateUrl: './extra-menu.component.html',
  styleUrls: ['./extra-menu.component.scss']
})


export class ExtraMenuComponent implements OnInit {
  @ViewChild('roll') inputEl: ElementRef <HTMLInputElement> = {} as ElementRef;
  @ViewChild('item') inputEl1: DropDownListComponent = {} as DropDownListComponent
  
  //menuItems: MenuItems = {} as MenuItems;
  fonts = fonts;
  extraMenuForm: FormGroup;
  title = 'Extra Menu';
  id = '';
  purchases: {
    bill_date: string,
    purchase_id: number,
    roll_no:string,
    total_price:number,
    purchased_items: {
      item_name: string,
      quantity:number,
      price:number,
      total:number  
    } []
  } [ ] = [ ]; 
  stock_list_loading:boolean = false;
  fromDataResolver = FromDataResolver;
  menuItems: any [] = [];//MenuItems[] = [] as MenuItems[];
  extraItems : {
    item_name: string,
    quantity:number,
    price:number,
    total:number
  } [ ] = [ ];
  menuData:any [] = [];//MenuItems[] = [] as MenuItems[];
  constructor(private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.extraMenuForm = this.formBuilder.group({
        bill_date: [new Date().toISOString().substring(0,10), Validators.required],
        roll_no:['',[Validators.required]],
        item_name:[''],
        quantity:[0, Validators.required],
        item_price:[0, Validators.required],
        item_total:[0, Validators.required],
        total_price: [0, Validators.required],
        search_with_code:[true]
      });
      this.getPurchases();
      
    }
    triggerFunction(event:any) {
      //console.log(event.key);
      if (event.ctrlKey && event.key === 'Enter') {
        this.onSave();
      }
      if(event.key==="Escape")
      {
        this.extraMenuForm.get('item_name')?.setValue("");
      }
    }
  getPurchases(){
    this.purchases.push(
      {
        bill_date: "31/08/2021",
        purchase_id: 1,
        roll_no:"12pa01", total_price: 155,
        purchased_items: [
          {item_name: "Egg",quantity:3,price:10,total:30 },
          {item_name: "Chicken Gravy",quantity:0,price:125,total:125 }
        ]
      },
      {
        bill_date: "31/08/2021",
        purchase_id: 2,
        roll_no:"12pa02", total_price: 20, 
        purchased_items: [{item_name: "Egg",quantity:2,price:10,total:20 }]
      }, 
      { 
        bill_date: "31/08/2021",
        purchase_id: 3,
        roll_no:"12pa04", total_price: 215,
        purchased_items: [{item_name: "Chicken Gravy",quantity:0,price:125,total:125 },
        {item_name: "Egg",quantity:3,price:10,total:30 },
        {item_name: "Dosa",quantity:2,price:30,total:60 }
        ]
      },
      );
  }
  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.homeURL = { label: 'Extra Menu', url: 'admin/hostel/extra-menu' };
    this.route.params.subscribe(params => {
      this.navigationService.updateNavigation(this.title, this.router.url);
    });
    this.getMenuItems();
  }
  onAddClick()
  {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    setTimeout(()=>{
      this.inputEl.nativeElement.focus();
    },0)
    
  }
  async onCloseClick(): Promise<void> {
    this.id = "";
    this.onReset();
    this.resetItems();
    JQueryHelper.closeModal('#modal-popup');
  }
  addExtraItem()
  {
    if(this.extraMenuForm.valid && this.extraMenuForm.value.item_total!=0)
    {
      this.extraMenuForm.controls['total_price'].setValue(this.extraMenuForm.controls['total_price'].value + this.extraMenuForm.value.item_total);
    this.extraItems.push({
      item_name: this.extraMenuForm.value.item_name,
      quantity: this.extraMenuForm.value.quantity,
      price:this.extraMenuForm.value.item_price,
      total: this.extraMenuForm.value.item_total
    });
    this.resetItems();
    //$("#itemMaster").focus();
    setTimeout(()=>{
      this.inputEl1.focus();
    },0)
    }
    
  }

  onDelete(item:any):void
  {
    this.extraItems.splice(this.extraItems.findIndex(x=>x.item_name==item.item_name),1);
    this.extraMenuForm.controls['total_price'].setValue(this.extraMenuForm.controls['total_price'].value - item.total);
  }
  calculateItemTotal()
  {
    var price = this.menuItems.find(x=>x.item_name == this.extraMenuForm.controls['item_name'].value)!.price;
    this.extraMenuForm.controls['item_price'].setValue(price)
    this.extraMenuForm.controls['item_total'].setValue ( this.extraMenuForm.controls['quantity'].value==0?price:this.extraMenuForm.controls['quantity'].value*price);
  }
  async getMenuItems(): Promise<void> {
    try {
      /* this.menuItems = await this.restService.menuItemService.getMenuItems();
      console.log(this.menuItems); */
      this.menuItems.push(
        {  
          id: "2d8aeab6-2714-465d-a483-7d6adbdd2e76",
          item_name: "Dosa",
          item_type: "Veg",
          item_code:1,
          price: 30,
          quantity: 1,
        },
        {
          id: "42821fa2-469c-4d72-9095-c1cb579f100f",
          item_name: "Onion Rava Dosa",
          item_type: "Veg",
          price: 50,
          quantity: 2, 
          item_code: 2
        },
        {
          id: "e682a2f1-8d53-40d4-a6ef-20024f1d3b1e",
          item_name: "Egg",
          item_type: "Non-Veg",
          price: 10,
          quantity: 1,
          item_code:11
        },
        {
          id: "7675caca-ca04-4c5c-9510-0dab74b66c39",
          item_name: "Chicken Gravy",
          item_type: "Non-Veg",
          price: 125,
          quantity: 0,
          item_code: 14
        },
        {
          id: "cc17c9eb-5dc8-4316-99a0-c501b45c1016",
          item_name: "Onion Dosa",
          item_type: "Veg",
          price: 20,
          quantity: 1,
          item_code: 25
        }
      )
      this.menuData = this.menuItems;
      
    } catch(error) {
      this.notificationService.setNotifcation({ message: 'Failed to load menu items.', type: 'Failed' });
    }
  }

  delete(id:any): void
  {
    this.purchases.splice(this.purchases.findIndex(x=>x.purchase_id==id),1);
  }

  getTittle(): string {
    return this.title;
  }
  onReset(): void {
    this.extraMenuForm.reset({
      bill_date: new Date().toISOString().substring(0,10),
      quantity:0,item_name:'',
    item_price:0,
    item_total:0,
    total_price:0 });
    this.extraItems= []; 
  }

  resetItems()
  {
    this.extraMenuForm.controls['bill_date'].setValue(new Date().toISOString().substring(0,10));
    this.extraMenuForm.controls['item_name'].setValue('');
    this.extraMenuForm.controls['quantity'].setValue(0);
    this.extraMenuForm.controls['item_price'].setValue(0);
    this.extraMenuForm.controls['item_total'].setValue(0);
  }

  isInvalid(formControl: string): boolean {
    if ((formControl === 'quantity' || formControl === 'price') && isNaN(this.extraMenuForm.get(formControl)?.value)) {
      return true;
    }
    return this.extraMenuForm.touched && this.extraMenuForm.get(formControl)?.errors !== null;
  }

  getMenuItemFromForm(): MenuItems {
    const menuItem = this.getFormData();
    return {
      id: this.id,
      item_name: menuItem.item_name,
      item_type: menuItem.item_type,
      quantity: menuItem.quantity,
      price: menuItem.price,
      //item_code: menuItem.item_code
    };
  }

  canDisable(): boolean {
    if (isNaN(this.extraMenuForm.value?.total_price) || this.extraMenuForm.value?.total_price == 0) {
      return true;
    }
    return this.extraMenuForm.status !== 'VALID';
  }

  getFormData(): any {
    return this.extraMenuForm.value;
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
  }

  onSave(): void {
     this.createMenuItem();
  }

  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }


  async createMenuItem(): Promise<void> {
    try {

      if(this.extraMenuForm.get('roll_no')!.value!="" && this.extraMenuForm.get("total_price")!.value !=0)
      {
        if(this.id != "")
        {
            var item = this.purchases.find(x=>x.roll_no==this.extraMenuForm.controls['roll_no'].value);
            item!.purchased_items = this.extraItems;
            item!.total_price= this.extraMenuForm.controls['total_price'].value;
           //item!.roll_no = this.extraMenuForm.controls['roll_no']. value;
            this.purchases.splice(this.purchases.findIndex(x=>x.roll_no==this.extraMenuForm.controls['roll_no'].value),1);
            this.purchases.unshift(item!); 
        }
        else
        { 
          this.purchases.unshift({
            bill_date:this.extraMenuForm.controls['bill_date']. value,  
            purchase_id: Math.random(),
            roll_no:this.extraMenuForm.controls['roll_no']. value, 
          total_price: this.extraMenuForm.controls['total_price'].value,
          purchased_items: this.extraItems})
        }
        this.notificationService.setNotifcation({ message: 'Created menu item successfully', type: 'Success'});
        this.onReset();
        setTimeout(()=>{
          this.inputEl.nativeElement.focus();
        },0)
      }
  /*     const res = await this.restService.menuItemService.creatMenuItems(this.getMenuItemFromForm());
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
      } */
      

    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to create menu item.', type: 'Failed'});
    }
  }

  editPurchase(item:any)
  {
      this.onAddClick();
      this.id = item.purchase_id;
      this.extraMenuForm.get('bill_date')?.setValue(item.bill_date);
      this.extraMenuForm.get('roll_no')?.setValue(item.roll_no);
      this.extraMenuForm.get('total_price')?.setValue(item.total_price);
      this.extraItems = item.purchased_items;    
  }  

  handleFilter(value:string) {    
    if(this.extraMenuForm.get('search_with_code')?.value)
    {
      this.menuData = this.menuItems.filter(s=>s.item_code == value);
    }
    else
    {
      this.menuData = this.menuItems.filter
      ((s) => s!.item_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
  }

  switchSearchMode()
  {
    this.extraMenuForm.get('search_with_code')?.setValue(!this.extraMenuForm.get('search_with_code')!.value);
  }
}
