<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Exam Fees Mapped for Students</p>
                    </div>

                    <div class="card-body">
                        <div class="row row-sm">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrolled
                                        Year</label>
                                    <select [(ngModel)]="enrolled_year" class="form-control">
                                        <option value="0">Select Enrolled Year</option>
                                        <option
                                            *ngFor="let year of commonEnums.getLastNYears(6);let i=index;trackBy:fromDataResolver.identify;"
                                            [value]="year">{{year}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                        title="Get Fees" *ngIf="!students_list_loading" (click)="onSearchClick()">
                                        Get Fees
                                    </button>
                                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                        *ngIf="students_list_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                            </div>
                        </div>



                        <div class="d-flex right-content mg-b-5" style="float: right;" *ngIf="students_list.length>0">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 15%;">Roll No.</th>
                                        <th style="width: 25%;">Name</th>
                                        <th style="width: 25%;">Class</th>
                                        <th style="width: 10%;">Amount</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="students_list.length>0">
                                        <ng-container
                                            *ngFor="let student of students_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{student.roll_no | uppercase}}
                                                </td>
                                                <td>
                                                    {{student.name}}
                                                </td>
                                                <td>
                                                    {{student.programme_name}} '{{student.section}}'
                                                </td>
                                                <td>
                                                    Rs. {{student.amount}}
                                                </td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="viewBreakup(student)"
                                                            class="btn btn-sm btn-info mr-2 mg-t-2">
                                                            View Fee Breakup
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!students_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="students_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="6" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="breakup-modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student">
                    <div class="modal-header">
                        <h6 class="modal-title">View Fees Breakup</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-6">
                                    <span>Roll No: </span>
                                    <span><b>{{student.roll_no | uppercase}}</b></span>
                                </div>
                                <div class="col-md-6">
                                    <span>Name: </span>
                                    <span><b>{{student.name | uppercase}}</b></span>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%;">S.No</th>
                                            <th style="width: 15%;">Course Code</th>
                                            <th style="width: 40%;">Course Name</th>
                                            <th style="width: 20%;">Appearance Type</th>
                                            <!-- <th style="width: 20%;">Fee</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="students_list.length>0">
                                            <ng-container
                                                *ngFor="let course of student.courses_appearing_list;let i=index;trackBy:fromDataResolver.identify;">
                                                <tr>
                                                    <td scope="row">
                                                        {{i+1}}
                                                    </td>
                                                    <td>
                                                        {{course.course_code | uppercase}}
                                                    </td>
                                                    <td>
                                                        {{course.course_name}}
                                                    </td>
                                                    <td>
                                                        {{course.appearance_type | titlecase}}
                                                    </td>
                                                    <!-- <td>
                                                        Rs. {{course.fee_amount}}
                                                    </td> -->
                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td scope="row" colspan="3" class="uppercase text-right"
                                                    style="font-weight: bold;">
                                                    Extra Charges
                                                </td>
                                                <td class="uppercase" style="font-weight: bold;">
                                                    {{student.extra_charge_amount ? student.extra_charge_amount: '0' |
                                                    currency:"INR"}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colspan="3" class="uppercase text-right"
                                                    style="font-weight: bold;">
                                                    Fine
                                                </td>
                                                <td class="uppercase" style="font-weight: bold;">
                                                    {{student.fine ? student.fine: '0' |
                                                    currency:"INR"}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colspan="3" class="uppercase text-right"
                                                    style="font-weight: bold;">
                                                    Total Amount
                                                </td>
                                                <td class="uppercase" style="font-weight: bold;">
                                                    {{student.amount | currency:"INR"}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>

                            <div class="text-center">
                                <button class="btn btn-secondary mg-t-20" (click)="onCloseClick()">Close</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>
