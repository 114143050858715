<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Dashboard</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

       

        <div class="row"  *ngIf="isRoleExists(['staff'])">
            <div class="col-lg-12 col-md-12">
                <div class="card" id="basic-alert">
                    <div class="card-body">
                        <div>
                            <h6 class="card-title mb-1">News From College</h6>
                            <p class="text-muted card-sub-title">Latest news from college</p>
                        </div>
                        <div class="text-wrap">
                            <div class="example">
                                <div class="alert alert-success" role="alert" *ngIf="isRoleExists(['staff_admin','principal','boys_hostel_admin','girls_hostel_admin'])">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <!-- <span aria-hidden="true">×</span> -->
                                    </button>
                                    <a style="cursor: pointer;" routerLink="/admin/hostel/manageRequest"><strong>Hostel Admission is Open for students!</strong> Click here to review and approve.</a>

                                </div>
                                <div class="alert alert-success" role="alert" *ngIf="isRoleExists(['staff_admin','principal','transportation_admin'])">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <!-- <span aria-hidden="true">×</span> -->
                                    </button>
                                    <a style="cursor: pointer;" routerLink="/admin/transport/manage-transport-request"><strong>Transport Admission is Open for students!</strong> Click here to review and approve.</a>

                                </div>

                                <div class="alert alert-success" role="alert">
                                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                        <!-- <span aria-hidden="true">×</span> -->
                                    </button>
                                    <a style="cursor: pointer;"><strong>New Portal is getting ready!</strong> Beloved staff members our new ANJAC LMS portal is getting ready, we need all your support to make this happen. Corresponding co-ordinators will contact you for any further help.</a>

                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>