import { NotificationEvent } from './eventType';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notication$: BehaviorSubject<NotificationEvent>;
  constructor() {
    this.notication$ = new BehaviorSubject<NotificationEvent>({});
  }

  getNotification(): BehaviorSubject<NotificationEvent> {
    return this.notication$;
  }

  setNotifcation(data: NotificationEvent): void {
    this.notication$.next(data);
  }
}
