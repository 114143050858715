<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Hostel Admission</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">


            <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
                <div class="card card-success">
                    <div class="card-header pb-0">
                        <h5 class="card-title mb-0 pb-0">College Hostel</h5>
                    </div>
                    <div class="card-body">
                        <b>Fee</b> : Rs. 10550 <br />
                        <b>Status</b> : {{application_status}}
                    </div>
                    <div class="card-footer text-center">
                        <div class="col-sm-12" *ngIf="application_status=='Not applied'">
                            <button (click)="applyForHostel()" class="btn btn-primary btn-block">Apply</button>
                        </div>
                        <div class="col-sm-12" *ngIf="application_status=='pending'">
                            Waiting for approval
                        </div>
                        <div class="col-sm-12" *ngIf="application_status=='allowed'">
                            <button (click)="payHostelFee()" class="btn btn-success btn-block">Pay Online</button>
                        </div>
                        <div class="col-sm-12" *ngIf="application_status=='payment_done'">
                            Paid and Enrolled
                        </div>
                        <div class="col-sm-12" *ngIf="application_status=='payment_failure'">
                            <button (click)="payHostelFee()" class="btn btn-success btn-block">Retry : Pay Online</button>
                        </div>
                        <div class="col-sm-12" *ngIf="application_status=='rejected'">
                            Your Application Rejected
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>