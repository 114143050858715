<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Courses Syllabus</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Syllabus & Courseware</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Course Syllabus & Coursewares</p>
                    </div>

                    <div class="card-body">
                        <h5>{{syllabus_data?.course_code}} - {{syllabus_data?.course_name}} ({{syllabus_data?.part}} -
                            {{syllabus_data?.course_type}})</h5>
                        <ng-container *ngIf="syllabus_data?.course_details">
                            <h6><u>Course Outcomes</u></h6>
                            <div class="mg-b-15" [innerText]="syllabus_data?.course_details.course_outcome"></div>
                            <h6
                                *ngIf="syllabus_data?.course_type=='THEORY' || syllabus_data?.course_type=='ELECTIVE' || syllabus_data?.part=='PART_I' || syllabus_data?.part=='PART_II' || syllabus_data?.part=='PART_IV' || syllabus_data?.part=='PART_V'">
                                <u>Course Syllabus</u>
                            </h6>
                            <ng-container *ngIf="syllabus_data?.course_details.course_units.length>0 && (syllabus_data?.course_type=='THEORY' || syllabus_data?.course_type=='ELECTIVE' || syllabus_data?.part=='PART_I' || syllabus_data?.part=='PART_II' || syllabus_data?.part=='PART_IV' ||
                                syllabus_data?.part=='PART_V')">
                                <div class="row mg-b-15"
                                    *ngFor="let unit of syllabus_data?.course_details.course_units; let i = index;">
                                    <div class="col-md-12"><b>Unit - {{i+1}}: {{unit.title}}</b></div>
                                    <div class="col-md-12" style="text-align: justify;">{{unit.course_topics_string}}
                                    </div>
                                </div>
                            </ng-container>
                            <h6 *ngIf="syllabus_data?.course_type=='PRACTICAL'"><u>List of Practicals</u></h6>
                            <div class="mg-b-15" [innerText]="syllabus_data?.course_details.practicals"
                                *ngIf="syllabus_data?.course_type=='PRACTICAL'"></div>
                            <h6><u>Tutorials</u></h6>
                            <div class="table-responsive mg-b-15">
                                <table class="table table-bordered">
                                    <tr>
                                        <th style="width: 10%;">S. No</th>
                                        <th>Unit Covered</th>
                                        <th style="width: 70%;">Activity</th>
                                    </tr>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let tutorial of syllabus_data?.course_details.tutorials; let j = index;">
                                            <tr *ngIf="tutorial.unit_covered!=''">
                                                <td>{{j+1}}</td>
                                                <td>{{tutorial.unit_covered}}</td>
                                                <td>{{tutorial.activity}}</td>
                                            </tr>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="syllabus_data?.course_details.tutorials.length==0 || syllabus_data?.course_details.tutorials[0].unit_covered=='' ">
                                            <tr>
                                                <td colspan="3" class="text-center">
                                                    No Tutorials Found
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>

                            <h6><u>Textbook(s)</u></h6>
                            <div class="mg-b-15" [innerText]="syllabus_data?.course_details.text_book"
                                *ngIf="syllabus_data?.course_details.text_book"></div>
                            <div class="mg-b-15" *ngIf="!syllabus_data?.course_details.text_book">No Text book found
                            </div>
                            <h6><u>Reference Books</u></h6>
                            <div class="mg-b-15" [innerText]="syllabus_data?.course_details.reference_book"
                                *ngIf="syllabus_data?.course_details.reference_book"></div>
                            <div class="mg-b-15" *ngIf="!syllabus_data?.course_details.reference_book">No Reference Book
                                found</div>
                            <h6><u>Web References</u></h6>
                            <div class="mg-b-15" [innerText]="syllabus_data?.course_details.web_reference"
                                *ngIf="syllabus_data?.course_details.web_reference"></div>
                            <ng-container *ngIf="!syllabus_data?.course_details.web_reference">
                                <ng-container
                                    *ngFor="let web_reference of syllabus_data?.course_details.web_references; let k = index;">
                                    <span *ngIf="web_reference.link!=''">
                                        {{web_reference.link}}
                                    </span><br>
                                </ng-container>
                                <p *ngIf="syllabus_data?.course_details.web_references.length==0 || 
                                                            syllabus_data?.course_details.web_references[0].link==''">
                                    No
                                    Web Reference Found</p>
                            </ng-container>

                        </ng-container>
                        <ng-container *ngIf="!syllabus_data?.course_details">
                            <p>No Course Data found for this course Code</p>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>
    </div>



</div>
<!-- /main-content -->
<app-footer></app-footer>