import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';

@Component({
  selector: 'app-student-certificate-master',
  templateUrl: './student-certificate-master.component.html',
  styleUrls: ['./student-certificate-master.component.scss']
})
export class StudentCertificateMasterComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Student Certificate Tracker Master';

  certificate_list_loading: boolean = false;
  certificate_list: ICertificateMaster[] = [];
  certificate: ICertificateMaster = {} as ICertificateMaster;
  certificate_form: FormGroup = {} as FormGroup;
  certificate_id: string = "";

  save_loading: boolean = false;
  delete_loading: boolean = false;
  cookieStore = CookieStore;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  ngOnInit(): void {
    this.getCertificateList();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.getProgrammeTypeList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  async getCertificateList() {
    try {
      this.certificate_list_loading = true;
      this.certificate_list = [];
      const service_response = await this.restService.getCertificateMasterList();
      if (service_response && service_response.success) {
        this.certificate_list = service_response.data;
      }
      /* this.certificate_list = [{
        certificate_name: "HSC Mark Sheet",
        description: "12th Std Mark Sheet",
        programme_type_id: "UG",
      },
      {
        certificate_name: "Community Certificate",
        description: "Community Certificate Original",
        programme_type_id: "UG",
      },
      ] */
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.certificate_list_loading = false;
    }

  }


  buildForm(): void {
    if (JSON.stringify(this.certificate) == '{}') {
      this.certificate = {
        _id: "",
        certificate_name: "",
        description: "",
        programme_type_id: ""
      };
    }
    this.certificate_form = this.formBuilder.group({
      _id: [this.certificate._id],
      certificate_name: [this.certificate.certificate_name, [Validators.required]],
      description: [this.certificate.description],
      programme_type_id: [this.certificate.programme_type_id, [Validators.required]],
    });
  }


  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onEditClick(certificate: ICertificateMaster) {
    this.certificate = certificate;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onSaveClick(): void {
    this.addItem();
  }
  onSaveAndCloseClick(): void {
    this.onSaveClick();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.certificate_id = "";
    this.certificate = {} as ICertificateMaster;
    this.certificate_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.certificate_form.value;
      console.log(form_data);
      this.save_loading = true;
      if (this.certificate_form.value._id) {
        const res = await this.restService.updateCertificateMaster(this.certificate_form.value._id, form_data);
        if (res) {
          alert("Certificate updated successfully.");
        }
      }
      else {
        const res = await this.restService.createCertificateMaster(form_data);
        if (res) {
          alert("Certificate added successfully.");
        }
      }
      this.getCertificateList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteCertificateMaster(this.certificate_form.get('_id')!.value);
      if (res.success) {
        alert("Certificate removed successfully");
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.certificate_form.touched && this.certificate_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.certificate_form.status !== 'VALID';
  }



}

interface ICertificateMaster {
  _id?: string,
  certificate_name: string;
  description: string;
  programme_type_id: string;
}
