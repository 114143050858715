import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-takesurvey',
  templateUrl: './takesurvey.component.html',
  styleUrls: ['./takesurvey.component.scss']
})
export class TakesurveyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
