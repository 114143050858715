<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Manage Students</h2>

          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Student</button>
        </div>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Manage Students</h4>
              <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="students_list.length>0"
                (click)="downloadAsCSV()">
            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Students</p>
          </div>

          <div class="card-body" *ngIf="programme_search_form">
            <form [formGroup]="programme_search_form">
              <div class="row row-sm">
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                    <select formControlName="programme_type_id" class="form-control"
                      (change)="onFilterSelectChange('programme_type_id',$event)">
                      <option value="">Select Programme Type</option>
                      <option
                        *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programme_type.programme_type_id">{{programme_type.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                    <select formControlName="finance_type" class="form-control"
                      (change)="onFilterSelectChange('finance_type',$event)">
                      <option value="">Select Finance Type</option>
                      <option
                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programmes</label>
                    <select formControlName="programme_id" class="form-control"
                      (change)="onFilterSelectChange('programme_id',$event)">
                      <option value="">All Programmes</option>
                      <option *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2" *ngIf="this.programme_section_list && this.programme_section_list.length>1">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Section</label>
                    <select formControlName="section_id" class="form-control"
                      (change)="onFilterSelectChange('section_id',$event)">
                      <option value="">All Section</option>
                      <option
                        *ngFor="let programme_section of programme_section_list;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="programme_section._id">{{programme_section.section_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Gender</label>
                    <select formControlName="gender" class="form-control"
                      (change)="onFilterSelectChange('gender',$event)">
                      <option value="">All Gender</option>
                      <option *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                        [ngValue]="gender.id">{{gender.text}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment Year</label>
                    <select formControlName="enrolled_year" class="form-control"
                      (change)="onFilterSelectChange('enrolled_year',$event)">
                      <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20)" [ngValue]="enrolled_year">
                        {{enrolled_year}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll No</label>
                    <input class="form-control" required="" type="text" formControlName="roll_no"
                      placeholder="Enter Roll No.">
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">

                    <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                      *ngIf="!student_list_loading" (click)="onSearchClick()">
                      Search
                    </button>
                    <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                      *ngIf="student_list_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>




                  </div>
                </div>

              </div>
            </form>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Roll No.</th>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Father's Name</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    <th>Last Updated At</th>
                    <th style="width: 15%;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="students_list.length>0">


                    <ng-container *ngFor="let student of students_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td class="text-uppercase">{{student.roll_no}}</td>
                        <td class="text-uppercase">{{student.given_name}} {{student.middle_name}}</td>
                        <td class="text-uppercase">{{student.gender}}</td>
                        <td class="text-uppercase">{{student?.father_name}}</td>
                        <td class="text-uppercase">{{student.mobile_number}}</td>
                        <td class="text-lowercase">{{student.email}}</td>
                        <td>{{student.updatedAt | date}}</td>
                        <td>
                          <div class="pr-1 mb-3 mb-xl-0">
                            <button type="button" class="btn btn-sm btn-info mr-2" title="Edit"
                              (click)="onEditClick(student)">
                              <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                            </button>
                            <button type="button" class="btn btn-sm btn-info mr-2" title="Courses Seed"
                              (click)="onSeedCourses(student,i)" *ngIf="!seed_loading[i]">
                              <fa-icon [icon]="fonts.faBook"></fa-icon>
                            </button>
                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                              *ngIf="seed_loading[i]">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                            </button>
                            <!-- <button type="button" class="btn btn-sm btn-primary mr-2" title="Reset Password"
                              (click)="onInitResetPasswordClick(student)">
                              <fa-icon [icon]="fonts.faPassport"></fa-icon>

                            </button> -->


                          </div>
                        </td>

                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!student_list_loading && students_list.length==0">
                    <tr>
                      <th colspan="9" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="student_list_loading && students_list.length==0">
                    <tr>
                      <th colspan="9" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="student_form && student">
          <div class="modal-header">
            <h6 class="modal-title" *ngIf="modal_mode=='ADD'">Add New Student</h6>
            <h6 class="modal-title" *ngIf="modal_mode=='EDIT'">Update Student</h6>
            <h6 class="modal-title" *ngIf="modal_mode=='BREAKUP-CHARGES'">Fees Breakup Charges</h6>
          </div>
          <div class="modal-body" *ngIf="modal_mode=='ADD' || modal_mode=='EDIT'">
            <div class="card card-body pd-20 pd-md-40">
              <div *ngIf="modal_mode=='ADD'"> <b>Search Student Data</b></div>
              <div class="row mg-b-10" *ngIf="modal_mode=='ADD'">
                <div class="col-xl-12">
                  <input type="radio" [(ngModel)]="create_search_type" name="create_search_type" value="aadhar"
                    class="mg-r-5">Search with Aadhar No.
                  <input type="radio" [(ngModel)]="create_search_type" name="create_search_type" value="rno"
                    class="mg-r-5">Search with Roll No.
                </div>
              </div>
              <div class="row mg-b-10" *ngIf="modal_mode=='ADD'">
                <div class="col-xl-3">
                  <input type="text" [(ngModel)]="create_search_input"
                    placeholder="Search student data with {{(create_search_type=='aadhar')?'Aadhar No':'Roll No'}}"
                    class="form-control">
                </div>
                <div class="col-xl-2">
                  <button type="button" class="btn btn-info" title="Search with Aadhar" *ngIf="!create_search_loading"
                    (click)="searchStudentDataWithAadhar()">
                    Search
                  </button>
                  <button class="btn btn-info" type="button" disabled *ngIf="create_search_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </div>
              </div>




              <form [formGroup]="student_form">

                <div id="accordion">
                  <div class="card">
                    <div class="card-header">
                      <a class="card-link" data-toggle="collapse" href="#collapseOne"
                        (click)="changeActiveAccordion(1)">
                        <div class="row">
                          <div class="col-md-9">
                            <b>Basic Details</b>
                          </div>
                          <div class="col-md-3 text-align-right">
                            <fa-icon [icon]="fonts.faAngleDown" *ngIf="!isActiveAccordion(1)"></fa-icon>
                            <fa-icon [icon]="fonts.faAngleUp" *ngIf="isActiveAccordion(1)"></fa-icon>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="collapseOne" class="collapse show" data-parent="#accordion">
                      <div class="card-body">
                        <!--Basic Details-->
                        <div class="row">
                          <div class="col-xl-3">
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Student Name</label>
                              <input class="form-control" required="" type="text" formControlName="given_name">
                            </div>
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Initial</label>
                              <input class="form-control" required="" type="text" formControlName="middle_name">
                            </div>

                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Birth Date</label>
                              <input class="form-control" required="" type="date" formControlName="birth_date">
                            </div>
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Contact Number</label>
                              <input class="form-control" required="" type="text" formControlName="mobile_number">
                            </div>
                          </div>
                          <div class="col-xl-3" formArrayName="additional_mobile_numbers">
                            <div
                              *ngFor="let alias of fromDataResolver.getFormArrayControlls(student_form,'additional_mobile_numbers');let i=index;trackBy:fromDataResolver.identify;">
                              <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Contact Number
                                  {{i+1}}</label>
                                <input class="form-control" required="" type="text" [formControlName]="i">
                              </div>
                            </div>

                          </div>
                          <div class="col-xl-3">

                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Email</label>
                              <input class="form-control" required="" type="text" formControlName="email">
                            </div>
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Personal Email</label>
                              <input class="form-control" required="" type="text" formControlName="personal_email">
                            </div>

                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Gender</label>
                              <select formControlName="gender" class="form-control"
                                (change)="onFilterSelectChange('gender',$event)">
                                <option value="">Select Gender</option>
                                <option
                                  *ngFor="let gender of commonEnums.genders;let i=index;trackBy:fromDataResolver.identify;"
                                  [ngValue]="gender.id">{{gender.text}}</option>
                              </select>
                            </div>


                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Blood Group</label>
                              <select formControlName="blood_group" class="form-control"
                                (change)="onFilterSelectChange('blood_group',$event)">
                                <option value="">All Blood Groups</option>
                                <option
                                  *ngFor="let blood_group of commonEnums.blood_groups;let i=index;trackBy:fromDataResolver.identify;"
                                  [ngValue]="blood_group.id">{{blood_group.text}}</option>
                              </select>
                            </div>



                          </div>
                          <div class="col-xl-3">
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Community</label>
                              <select formControlName="community_id" class="form-control"
                                (change)="onFilterSelectChange('community_id',$event)">
                                <option value="">All Community</option>
                                <option
                                  *ngFor="let community_id of commonEnums.community_list;let i=index;trackBy:fromDataResolver.identify;"
                                  [ngValue]="community_id">{{community_id}}</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Caste</label>
                              <input class="form-control" required="" type="text" formControlName="caste">
                            </div>
                            <div class="form-group">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Religion</label>
                              <select formControlName="religion" class="form-control"
                                (change)="onFilterSelectChange('religion',$event)">
                                <option value="">All Religions</option>
                                <option
                                  *ngFor="let religion of commonEnums.religions;let i=index;trackBy:fromDataResolver.identify;"
                                  [ngValue]="religion.id">
                                  {{religion.text}}</option>
                              </select>
                            </div>

                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="physically_challanged"
                                  name="physically_challanged" formControlName="physically_challanged">
                                <label class="custom-control-label" for="physically_challanged">Are you physically
                                  challenged?</label>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="ex_service" name="ex_service"
                                  formControlName="ex_service">
                                <label class="custom-control-label" for="ex_service">Are you son/daughter
                                  of Ex-Service man?</label>
                              </div>
                            </div>

                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="is_left" name="is_left"
                                  formControlName="is_left">
                                <label class="custom-control-label" for="is_left">Is Student Left?</label>
                              </div>
                            </div>

                            <div class="form-group" *ngIf="student_form.value.is_left">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Left Date</label>
                              <input class="form-control" type="date" formControlName="left_on_date">
                            </div>

                            <div class="form-group" *ngIf="student_form.value.is_left">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Left Semester</label>
                              <input class="form-control" type="number" formControlName="left_on_semester"
                                placeholder="Enter the semester in which the student left">
                            </div>


                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="is_long_absent"
                                  name="is_long_absent" formControlName="is_long_absent">
                                <label class="custom-control-label" for="is_long_absent">Is Student Long Absent?</label>
                              </div>
                            </div>

                            <div class="form-group">
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="is_lateral_entry"
                                  name="is_lateral_entry" formControlName="is_lateral_entry">
                                <label class="custom-control-label" for="is_lateral_entry">Is Lateral Entry
                                  Admission?</label>
                              </div>
                            </div>

                            <div class="form-group" *ngIf="student_form.value.is_lateral_entry">
                              <label class="main-content-label tx-11 tx-medium tx-gray-600">Lateral Entry
                                Semester</label>
                              <input class="form-control" type="text" formControlName="lateral_entry_on_semester">
                            </div>


                          </div>

                        </div>
                        <div> <b>Identification Marks</b></div>
                        <div class="row">
                          <div class="col-xl-3" formArrayName="identification_marks">
                            <div
                              *ngFor="let alias of fromDataResolver.getFormArrayControlls(student_form,'identification_marks');let i=index;trackBy:fromDataResolver.identify;">
                              <div class="form-group">
                                <!-- <label class="main-content-label tx-11 tx-medium tx-gray-600">{{i+1}}</label> -->
                                <input class="form-control" required="" type="text" [formControlName]="i">
                              </div>
                            </div>

                          </div>
                        </div>
                        <div> <b>Address</b></div>
                        <div formGroupName="address">
                          <div class="row">
                            <div class="col-xl-3">
                              <div> <b>Present Address</b></div>
                              <div formGroupName="present">
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Street</label>
                                  <input class="form-control" required="" type="text" formControlName="street">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">City</label>
                                  <input class="form-control" required="" type="text" formControlName="city">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">District</label>
                                  <input class="form-control" required="" type="text" formControlName="district">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">State</label>
                                  <input class="form-control" required="" type="text" formControlName="state">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Country</label>
                                  <input class="form-control" required="" type="text" formControlName="country">
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-3">
                              <div> <b>Communication Address</b></div>
                              <div formGroupName="communication">
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Street</label>
                                  <input class="form-control" required="" type="text" formControlName="street">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">City</label>
                                  <input class="form-control" required="" type="text" formControlName="city">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">District</label>
                                  <input class="form-control" required="" type="text" formControlName="district">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">State</label>
                                  <input class="form-control" required="" type="text" formControlName="state">
                                </div>
                                <div class="form-group">
                                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Country</label>
                                  <input class="form-control" required="" type="text" formControlName="country">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div> <b>Family Members</b></div>
                        <div class="row">
                          <div class="col-xl-12">
                            <ng-container formArrayName="family_members">
                              <div class="table-wrap">
                                <div class="table-responsive">
                                  <table class="table table-hover table-bordered mb-0">
                                    <thead>
                                      <tr>
                                        <th style="width: 50px;">S.No.</th>
                                        <th style="width: 100px;">Relationship Name</th>
                                        <th>Name</th>
                                        <th>Occupation</th>
                                        <th>Monthly Income (Rs.)</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      <ng-container
                                        *ngFor="let family_member of fromDataResolver.getFormArrayControlls(this.student_form,'family_members');let i=index;trackBy:fromDataResolver.identify;">
                                        <ng-container [formGroupName]="i">
                                          <tr>
                                            <td>
                                              {{i+1}}
                                            </td>
                                            <td>
                                              <select formControlName="relationship_type" class="form-control"
                                                (change)="onFilterSelectChange('religion',$event)">
                                                <option value="">Select</option>
                                                <option
                                                  *ngFor="let relationship_name of commonEnums.relationship_names;let i=index;trackBy:fromDataResolver.identify;"
                                                  [ngValue]="relationship_name">{{relationship_name}}</option>
                                              </select>

                                            </td>
                                            <td>
                                              <input class="form-control" required="" type="text"
                                                formControlName="given_name">
                                            </td>

                                            <td>
                                              <input class="form-control" required="" type="text"
                                                formControlName="occupation">
                                            </td>
                                            <td>
                                              <input class="form-control" required="" type="text"
                                                formControlName="salary">
                                            </td>

                                          </tr>
                                        </ng-container>
                                      </ng-container>
                                      <tr>
                                        <td colspan="5" class="text-center">
                                          <button class="btn btn-primary mg-r-20" (click)="addMoreFamilyMemebers()">Add
                                            More</button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="row" *ngIf="student._id">
                          <div class="col-md-12 mg-t-10 text-center">
                            <button class="btn btn-success mg-r-20" (click)="updateBasicDetails()"
                              *ngIf="!basic_update_loading">Update
                              Basic Details</button>
                            <button class="btn btn-success mg-r-20" type="button" disabled *ngIf="basic_update_loading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Updating...
                            </button>
                          </div>
                        </div>
                        <!--End of basic details-->
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header">
                      <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo"
                        (click)="changeActiveAccordion(2)">
                        <div class="row">
                          <div class="col-md-9">
                            <b>Programmes</b>
                          </div>
                          <div class="col-md-3 text-align-right">
                            <fa-icon [icon]="fonts.faAngleDown" *ngIf="!isActiveAccordion(2)"></fa-icon>
                            <fa-icon [icon]="fonts.faAngleUp" *ngIf="isActiveAccordion(2)"></fa-icon>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="collapseTwo" class="collapse" data-parent="#accordion">
                      <div class="card-body">
                        <!--Programme Details-->
                        <div class="row">
                          <div class="col-xl-12">
                            <ng-container formArrayName="programmes">
                              <div class="table-wrap">
                                <div class="table-responsive">
                                  <table class="table table-hover table-bordered mb-0">
                                    <thead>
                                      <tr>
                                        <th style="width: 50px;">S.No.</th>
                                        <th>Programme Type</th>
                                        <th>Finance Type</th>
                                        <th>Programme Name</th>
                                        <th>Section</th>
                                        <th>Roll No.</th>
                                        <th>Enrolled Year.</th>
                                        <th style="width:75px">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <ng-container *ngFor="let programme of programmeMapControls;let i=index;">
                                        <ng-container [formGroupName]="i">
                                          <tr
                                            *ngIf="fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].get('_id').value==''">
                                            <td>
                                              {{i+1}}
                                            </td>
                                            <td>
                                              <select formControlName="programme_type_id" class="form-control"
                                                (change)="onProgrammeFilterSelectChange('programme_type_id',$event, i)">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                  *ngFor="let programme_type of programme_type_list;let j=index;trackBy:fromDataResolver.identify;"
                                                  [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                              </select>
                                            </td>
                                            <td>
                                              <select formControlName="finance_type" class="form-control"
                                                (change)="onProgrammeFilterSelectChange('finance_type',$event, i)">
                                                <option value="">Select Finance Type</option>
                                                <option
                                                  *ngFor="let finance_type of commonEnums.finance_types;let k=index;trackBy:fromDataResolver.identify;"
                                                  [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                              </select>
                                            </td>

                                            <td>
                                              <select formControlName="programme_id" class="form-control"
                                                (change)="onProgrammeFilterSelectChange('programme_id',$event, i)">
                                                <option value="">All Programmes</option>
                                                <option
                                                  *ngFor="let programe of filter_programme_list[i];let l=index;trackBy:fromDataResolver.identify;"
                                                  [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                              </select>
                                            </td>


                                            <td class="mid-align">
                                              <select formControlName="section_id" class="form-control"
                                                (change)="onProgrammeFilterSelectChange('section_id',$event, i)"
                                                *ngIf="filter_section_list[i]?.length>1">
                                                <option value="">All Section</option>
                                                <option
                                                  *ngFor="let programme_section of filter_section_list[i];let m=index;trackBy:fromDataResolver.identify;"
                                                  [ngValue]="programme_section._id">{{programme_section.section_name}}
                                                </option>
                                              </select>
                                              <select formControlName="section_id" class="form-control"
                                                *ngIf="filter_section_list[i]?.length==1">
                                                <option [value]="filter_section_list[i][0]._id">
                                                  {{filter_section_list[i][0].section_name}}</option>
                                              </select>

                                              <span
                                                *ngIf="fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.programme_type_id==''|| 
                                                                fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].value.finance_type=='' || 
                                                                filter_programme_list[i]?.length==0 || filter_section_list[i]?.length==0">---</span>

                                            </td>
                                            <td>
                                              <input type="text" formControlName="roll_no" class="form-control"
                                                placeholder="Enter Programme Roll No.">
                                            </td>
                                            <td>
                                              <select formControlName="enrolled_year" class="form-control"
                                                (change)="onFilterSelectChange('enrolled_year',$event)">
                                                <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20)"
                                                  [ngValue]="enrolled_year">
                                                  {{enrolled_year}}</option>
                                              </select>
                                            </td>
                                            <td>
                                              <button class="btn btn-danger mg-r-20" (click)="deleteProgrammeMap(i)"
                                                *ngIf="!programme_delete_loading[i]">Delete</button>
                                              <button class="btn btn-danger mg-r-20" type="button" disabled
                                                *ngIf="programme_delete_loading[i]">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                                Deleting...
                                              </button>
                                            </td>

                                          </tr>
                                          <tr
                                            *ngIf="fromDataResolver.getFormArrayControlls(this.student_form, 'programmes')[i].get('_id').value!=''">
                                            <td>
                                              {{i+1}}
                                            </td>
                                            <td class="mid-align">
                                              {{programme.value.programme_type_id}}
                                            </td>
                                            <td class="mid-align">
                                              {{getFinanceTypeText(programme.value.finance_type)}}
                                            </td>

                                            <td class="mid-align">
                                              {{getProgrammeName(programme.value.programme_id,i)}}
                                            </td>

                                            <td class="mid-align">
                                              {{getSectionName(programme.value.section_id,i)}}
                                            </td>
                                            <td class="mid-align">
                                              {{programme.value.roll_no}}
                                            </td>
                                            <td class="mid-align">
                                              {{programme.value.enrolled_year}}
                                            </td>
                                            <td>
                                              <button class="btn btn-danger mg-r-20" (click)="deleteProgrammeMap(i)"
                                                *ngIf="!programme_delete_loading[i]">Delete</button>
                                              <button class="btn btn-danger mg-r-20" type="button" disabled
                                                *ngIf="programme_delete_loading[i]">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                                Deleting...
                                              </button>
                                            </td>

                                          </tr>
                                        </ng-container>
                                      </ng-container>
                                      <tr>
                                        <td colspan="8" class="text-center">
                                          <button class="btn btn-primary mg-r-20" (click)="addMoreProgrammes()">Add
                                            More</button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="row" *ngIf="student._id">
                          <div class="col-md-12 mg-t-10 text-center">
                            <button class="btn btn-success mg-r-20" (click)="updateProgrammeDetails()"
                              *ngIf="!programme_update_loading">Update Programmes</button>
                            <button class="btn btn-success mg-r-20" type="button" disabled
                              *ngIf="programme_update_loading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Updating...
                            </button>
                          </div>
                        </div>
                        <!--End of Programmes-->
                      </div>
                    </div>
                  </div>

                  <div class="card" *ngIf="student.fee_list?.length>0">
                    <div class="card-header">
                      <a class="collapsed card-link" id="collapse-three" data-toggle="collapse" href="#collapseThree"
                        (click)="changeActiveAccordion(3)">
                        <div class="row">
                          <div class="col-md-9">
                            <b>Fee List</b>
                          </div>
                          <div class="col-md-3 text-align-right">
                            <fa-icon [icon]="fonts.faAngleDown" *ngIf="!isActiveAccordion(3)"></fa-icon>
                            <fa-icon [icon]="fonts.faAngleUp" *ngIf="isActiveAccordion(3)"></fa-icon>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="collapseThree" class="collapse" data-parent="#accordion">
                      <!-- <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-bordered mg-b-0 text-md-nowrap">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Programe Name</th>
                                <th>Fees Type</th>
                                <th>Term Name</th>
                                <th>Due Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th style="width: 15%;">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="student.fee_list && student.fee_list.length>0">


                                <ng-container
                                  *ngFor="let payment_detail of student.fee_list;let i=index;trackBy:fromDataResolver.identify;">
                                  <tr>
                                    <th scope="row">
                                      {{i+1}}

                                    </th>
                                    <td class="uppercase">{{payment_detail.programme_name}}</td>
                                    <td class="uppercase">{{payment_detail.fee_header_type.replace('_',' ')}}</td>
                                    <td class="uppercase">{{payment_detail.term_name}}</td>
                                    <td class="uppercase">
                                      <ng-container *ngIf="payment_detail.due_date">
                                        {{payment_detail.due_date | date:commonEnums.date_format}}
                                      </ng-container>
                                    </td>
                                    <td class="text-center">
                                      {{payment_detail.amount | currency:"INR"}}
                                      <ng-container *ngIf="payment_detail.breakups && payment_detail.breakups.length>0">
                                        <div class="col-sm-12">
                                          <button (click)="viewBreakupsClick(payment_detail)"
                                            title="View Breakups Details" class="btn btn-sm btn-info btn-block">
                                            View Details</button>
                                        </div>


                                      </ng-container>
                                    </td>

                                    <td>

                                      <div class="col-sm-12 uppercase" *ngIf="payment_detail.payment_completed">
                                        Paid
                                      </div>
                                      <div class="col-sm-12 uppercase" *ngIf="!payment_detail.payment_completed">
                                        Not Paid
                                      </div>

                                    </td>
                                    <td>
                                      <div class="col-sm-12" *ngIf="!payment_detail.payment_completed">
                                        <!-- <button (click)="payCourse2Fee(payment_detail)"
                                          class="btn btn-success btn-block">
                                          Pay Online</button> 
                                        <button class="btn btn-info btn-sm mg-r-5 mg-b-10">
                                          Make Voluntary Payment</button>
                                        <app-offline-transaction [roll_no]="'20PA01'"></app-offline-transaction>
                                      </div>
                                    </td>
                                  </tr>
                                </ng-container>

                              </ng-container>


                              <ng-container *ngIf="!payment_details_loading && student?.fee_list?.length==0">
                                <tr>
                                  <th colspan="9" class="text-center">No Records found</th>
                                </tr>
                              </ng-container>
                              <ng-container *ngIf="payment_details_loading && student?.fee_list?.length==0">
                                <tr>
                                  <th colspan="9" class="text-center">Loading Please wait</th>
                                </tr>
                              </ng-container>



                            </tbody>
                          </table>
                        </div>
                      </div> -->
                      <app-offline-transaction [render_data]="fee_render_data" (breakup_data)="viewBreakups($event)">
                      </app-offline-transaction>
                    </div>
                  </div>

                </div>







                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <ng-container *ngIf="!student._id">
                    <button class="btn btn-success mg-r-20" (click)="onSaveClick()"
                      *ngIf="!student_save_loading">Save</button>
                    <button class="btn btn-success mg-r-20" type="button" disabled *ngIf="student_save_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Saving...
                    </button>
                  </ng-container>

                  <!-- <ng-container *ngIf="student._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()" *ngIf="!student_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="student_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                    </ng-container> -->


                  <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

          <div class="modal-body" *ngIf="modal_mode=='BREAKUP-CHARGES'">

            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Course Code</th>
                    <th>Course Name</th>
                    <th>Course Type</th>
                    <th>Appearance Type <br> (Regular - R, Arrear - A)</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="current_selection.breakups.length>0">


                    <ng-container
                      *ngFor="let breakup of current_selection.breakups;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td class="uppercase">{{breakup.course_code}}</td>
                        <td class="uppercase">{{breakup.course_name}}</td>
                        <td class="uppercase">{{breakup.course_type}}
                          <ng-container *ngIf="breakup.exam_duration>0">
                            [{{breakup.exam_duration}} hours]
                          </ng-container>

                        </td>
                        <td class="uppercase">{{breakup.appearance_type=="normal"?"R":"A"}}</td>
                        <td class="uppercase">
                          {{breakup.fee_amount | currency:"INR"}}
                        </td>


                      </tr>
                    </ng-container>
                    <ng-container
                      *ngIf="current_selection.breakups_extra_charges && current_selection.breakups_extra_charges.length >0">
                      <ng-container
                        *ngFor="let breakups_extra_charge of current_selection.breakups_extra_charges;let i=index;trackBy:fromDataResolver.identify;">
                        <tr>
                          <td scope="row" colspan="5" class="uppercase text-right">
                            {{breakups_extra_charge.item_name}}
                          </td>
                          <td class="uppercase">
                            {{breakups_extra_charge.amount | currency:"INR"}}
                          </td>

                        </tr>
                      </ng-container>
                    </ng-container>
                    <tr>
                      <td scope="row" colspan="5" class="uppercase text-right" style="font-weight: bold;">
                        Total
                      </td>
                      <td class="uppercase" style="font-weight: bold;">
                        {{current_selection.amount | currency:"INR"}}
                      </td>

                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </div>
            <div class="mg-b-20"></div>
            <div class="text-align-right">

              <button class="btn ripple btn-secondary" (click)="onCloseBreakupsClick()" type="button">Back</button>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-popup-resetpassword">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="change_password_form">
          <div class="modal-header">
            <h6 class="modal-title">Reset Password</h6>


          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="change_password_form">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Password</label>
                  <input class="form-control" required="" type="text" formControlName="password">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Confirm Password</label>
                  <input class="form-control" required="" type="text" formControlName="confirm_password">
                </div>



                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onFinishResetPasswordClick()"
                    *ngIf="!change_password_save_loading">Change Password</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="change_password_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>


                  <button class="btn ripple btn-secondary" (click)="onResetPassordCloseClick()"
                    type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>