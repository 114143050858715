<app-side-bar></app-side-bar>

<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Create Leave Request</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update Student Leave Requests</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead class="text-center">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Hours</th>
                                        <th>Reason</th>
                                        <th>Attachment(s)</th>
                                        <th>Status</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <ng-container *ngIf="leaveRequests.length>0">
                                        <ng-container
                                            *ngFor="let request of leaveRequests;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row" style="width: 10px;">
                                                    {{i+1}}
                                                </td>
                                                <td style="width: 10%;">{{request.start_date | date}}</td>
                                                <td style="width: 10%;">{{request.end_date | date}}</td>
                                                <td>{{request.hour_number}}</td>
                                                <td style="width: 30%;">{{request.reason}}</td>
                                                <td style="width: 10%;">{{request.status | titlecase}}</td>
                                                <td>
                                                    <p
                                                        *ngFor="let attachment of request.attachment; let j = index; trackBy:fromDataResolver.identify;">
                                                        <a [href]="attachment" target="_blank">Attachment {{j+1}}</a>
                                                    </p>
                                                </td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(request)"
                                                            class="btn btn-sm btn-info mr-2"
                                                            *ngIf="request.status=='APPROVED'" disabled="disabled">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="onEditClick(request)"
                                                            class="btn btn-sm btn-info mr-2"
                                                            *ngIf="request.status=='NEW'">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!request_list_loading && leaveRequests.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="request_list_loading && leaveRequests.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="leaveRequestForm">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Leave Request</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="leaveRequestForm" class="form-horizontal">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Start
                                        Date</label>
                                    <input class="form-control" required="" type="datetime-local"
                                        formControlName="start_date">
                                    <div *ngIf="isInvalid('start_date')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Start Date & Time is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter End Date</label>
                                    <input class="form-control" required="" type="datetime-local"
                                        formControlName="end_date">
                                    <div *ngIf="isInvalid('tend_dateo')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">End Date & Time is required.</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Hour
                                        Nos.</label>
                                    <textarea class="form-control" required="" type="text" formControlName="hour_number"
                                        placeholder="Enter hour nos. separated by commas. Ex: 2,3,4 etc.."></textarea>
                                    <div *ngIf="isInvalid('hour_number')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Leave Hour Nos. is required
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Reason for
                                        Leave Request</label>
                                    <textarea class="form-control" required="" type="text"
                                        placeholder="Enter reason for Leave" formControlName="reason"></textarea>
                                    <div *ngIf="isInvalid('reason')">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Reason for Leave request is required
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload Attachments, if
                                        any</label>
                                    <input class="form-control" type="file"
                                        (change)="uploadAttachment($event,leaveRequestForm,'attachment')"
                                        accept="image/*">
                                    <p
                                        *ngFor="let attachment of leaveRequestForm.value.attachment; let j = index; trackBy:fromDataResolver.identify;">
                                        <a [href]="attachment" target="_blank">Attachment {{j+1}}</a>
                                    </p>
                                </div>

                                <div class="add-btn-row">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">Add</button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">Add & Close</button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="this.leaveRequestForm.value.request_id"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>