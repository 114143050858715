<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Admission List</h2>
                    <p class="mg-b-0">View Overall Admissions</p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <!-- <button type="button" class="btn btn-info" (click)="onAddClick()">Create New Admission</button> -->
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">

                    <div class="card-body">
                        <div class="panel panel-primary tabs-style-1">
                            <div class=" tab-menu-heading">
                                <div class="tabs-menu1">
                                    <!-- Tabs -->
                                    <ul class="nav panel-tabs main-nav-line">
                                        <li class="nav-item"><a href="#tab1" class="nav-link active" data-toggle="tab">Included Programmes</a></li>
                                        <li class="nav-item"><a href="#tab2" class="nav-link" data-toggle="tab">All Programmes</a></li>

                                    </ul>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body main-content-body-right border-top-0 border">
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tab1">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
                                    </div>
                                    <div class="tab-pane" id="tab2">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Fin. Type</th>
                                                            <th>Prg. Type</th>
                                                            <th>Prg. Code</th>
                                                            <th>Prg. Name</th>
                                                            <th>Short Name</th>
                                                            <th>Last Updated At</th>
                                                            <th style="width: 15%;">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="programme_list.length>0">


                                                            <ng-container *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;">
                                                                <tr>
                                                                    <td scope="row">
                                                                        {{i+1}}

                                                                    </td>
                                                                    <td class="text-uppercase">{{programe.finance_type_display}}</td>
                                                                    <td class="text-uppercase">{{programe.programme_type_id}}</td>
                                                                    <td class="text-uppercase">{{programe.programme_code}}</td>
                                                                    <td class="text-uppercase">{{programe.programme_name}}</td>
                                                                    <td class="text-uppercase">{{programe.short_name}}</td>
                                                                    <td>{{programe.updatedAt | date}}</td>
                                                                    <td>
                                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                                            <button type="button" class="btn btn-sm btn-info mr-2" (click)="onEditClick(programe)">
                                                                                <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>

                                                                            </button>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </ng-container>

                                                        </ng-container>

                                                        <ng-container *ngIf="!programme_list_loading && programme_list.length==0">
                                                            <tr>
                                                                <th colspan="8" class="text-center">No Records found</th>
                                                            </tr>
                                                        </ng-container>
                                                        <ng-container *ngIf="programme_list_loading && programme_list.length==0">
                                                            <tr>
                                                                <th colspan="8" class="text-center">Loading Please wait</th>
                                                            </tr>
                                                        </ng-container>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>