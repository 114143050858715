<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

  <app-combo-bar></app-combo-bar>


  <!-- container -->
  <div class="container-fluid">

    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Admission List</h2>
          <p class="mg-b-0">View Overall Admissions</p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <ng-container
          *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
          <div class="pr-1 mb-3 mb-xl-0">
            <!-- <button type="button" class="btn btn-info mg-r-15" (click)="manageScroller()">Manage Scroll
              Messages</button> -->
            <button type="button" class="btn btn-info" (click)="onAddClick()">Create New Admission</button>

          </div>
        </ng-container>
      </div>
    </div>



    <div class="row">


      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">Admission List</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Admissions List</p>
          </div>
          <ng-container
            *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin'])">
            <div class="card-body" *ngIf="admission_search_form">
              <form [formGroup]="admission_search_form">
                <div class="row row-sm">

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select regulation year</label>
                      <select formControlName="regulation_year" class="form-control"
                        (change)="onRegulationYearChange()">
                        <option value="">Select regulation year</option>
                        <option
                          *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="year">{{year}}</option>
                      </select>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </ng-container>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Admission Name</th>
                    <th>Programme Type Id</th>
                    <th>Patch Year</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Active</th>
                    <!-- <th style="width: 20%;">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="admissions_list.length>0">


                    <ng-container
                      *ngFor="let admission of admissions_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}

                        </td>
                        <td>{{admission.name}}</td>
                        <td>{{admission.programme_type_id}}</td>
                        <td>{{admission.year}}</td>
                        <td>{{admission.start_date | date:commonEnums.date_format}}</td>
                        <td>{{admission.end_date | date:commonEnums.date_format}}</td>
                        <td>{{admission.active ?"YES":"NO"}}</td>
                      </tr>

                      <tr>
                        <td colspan="7" style="text-align: center;">
                          <div class="pr-1 mb-3 mb-xl-0">
                            <ng-container
                              *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin', 'admission_admin', 'admission_doc_verify', 'admission_member'])">

                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="Edit Admission"
                                (click)="onEditClick(admission)">
                                <fa-icon [icon]="fonts.faPencilAlt" class="mg-r-3"></fa-icon>
                                Edit Admission
                              </button>
                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="Set Application Fee"
                                (click)="onConfigureAdmissionFlowClick(admission)">
                                <fa-icon *ngIf="!admission.loading" [icon]="fonts.faMoneyCheck"
                                  class="mg-r-3"></fa-icon>
                                <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"
                                  class="mg-r-3"></fa-icon>
                                Set Application Fee
                              </button>
                              <!-- <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="Set Account Details"
                                                        (click)="onAccountsClick(admission)">
                                                        <fa-icon *ngIf="!admission.loading" [icon]="fonts.faBriefcase"></fa-icon>
                                                        <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"></fa-icon>
                                                      </button> -->
                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="Set Programme Fee"
                                (click)="onConfigureProgrammeFeeClick(admission)">
                                <fa-icon *ngIf="!admission.loading" [icon]="fonts.faMoneyBill" class="mg-r-3"></fa-icon>
                                <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"
                                  class="mg-r-3"></fa-icon>
                                Set Programme Fee
                              </button>
                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="Set Admission Flow"
                                (click)="onSettingsClick(admission)">
                                <fa-icon *ngIf="!admission.loading" [icon]="fonts.faCog" class="mg-r-3"></fa-icon>
                                <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"
                                  class="mg-r-3"></fa-icon>
                                Set Admission Flow
                              </button>
                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2"
                                title="Set Eligibility Rules" (click)="onEligibilityClick(admission)">
                                <fa-icon *ngIf="!admission.loading" [icon]="fonts.faInfo" class="mg-r-3"></fa-icon>
                                <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"
                                  class="mg-r-3"></fa-icon>
                                Set Eligibility Rules
                              </button>
                            </ng-container>
                            <ng-container
                              *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin','admission_doc_verify', 'admission_admin'])">

                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="View Reports"
                                (click)="onReportsClick(admission)">
                                <fa-icon *ngIf="!admission.loading" [icon]="fonts.faAddressBook"
                                  class="mg-r-3"></fa-icon>
                                <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"
                                  class="mg-r-3"></fa-icon>
                                View Reports
                              </button>
                            </ng-container>
                            <ng-container
                              *ngIf="cookieStore.isRoleExists(['staff_admin', 'principal', 'office_admin', 'student_admin','admission_exam_admin', 'admission_admin'])">

                              <button type="button" class="btn btn-sm btn-info mr-2 mg-b-2" title="Manage Exams"
                                (click)="onEntranceClick(admission)">
                                <fa-icon *ngIf="!admission.loading" [icon]="fonts.faGamepad" class="mg-r-3"></fa-icon>
                                <fa-icon *ngIf="admission.loading" [icon]="fonts.faHourglassHalf"
                                  class="mg-r-3"></fa-icon>
                                Manage Exam
                              </button>
                            </ng-container>


                          </div>
                        </td>
                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!admissions_list_loading && admissions_list.length==0">
                    <tr>
                      <th colspan="7" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="admissions_list_loading && admissions_list.length==0">
                    <tr>
                      <th colspan="7" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </div>


    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="admission_form">
          <div class="modal-header">
            <h6 class="modal-title" *ngIf="!admission._id">Create New Admission</h6>
            <h6 class="modal-title" *ngIf="admission._id">Update Admission</h6>

          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="admission_form">

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                  <select formControlName="programme_type_id" class="form-control"
                    (change)="onFilterSelectChange('programme_type_id',$event)">
                    <option
                      *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                      [ngValue]="programme_type.programme_type_id">{{programme_type.programme_type_id}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Admission Name</label>
                  <input class="form-control" required="" type="text" formControlName="name">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Patch Year</label>
                  <input class="form-control" required="" type="number" formControlName="year">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start Date</label>
                  <input class="form-control" required="" type="date" formControlName="start_date">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End Date</label>
                  <input class="form-control" required="" type="date" formControlName="end_date">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description</label>

                  <textarea class="form-control" formControlName="description"></textarea>
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Prochure Link</label>
                  <input class="form-control" required="" type="text" formControlName="document_link">
                </div>
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Application No Prefix</label>
                  <input class="form-control" required="" type="text" formControlName="app_no_prefix">
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="multi_course_selection_allowed"
                      name="multi_course_selection_allowed" formControlName="multi_course_selection_allowed">
                    <label class="custom-control-label" for="multi_course_selection_allowed">Applicants can buy more
                      than one programme application in same time?</label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="sc_discount_allowed"
                      name="sc_discount_allowed" formControlName="sc_discount_allowed">
                    <label class="custom-control-label" for="sc_discount_allowed">Is regular applicant can buy SC/ST
                      discount application?</label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="active" name="active"
                      formControlName="active">
                    <label class="custom-control-label" for="active">Is Active?</label>
                  </div>
                </div>


                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                    *ngIf="!admission_save_loading">Save</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="admission_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>

                  <ng-container *ngIf="admission._id">
                    <button class="btn btn-danger mg-r-20" (click)="onDeleteClick(admission._id)"
                      *ngIf="!admission_delete_loading">Delete</button>
                    <button class="btn btn-danger mg-r-20" type="button" disabled *ngIf="admission_delete_loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>

                  </ng-container>


                  <button class="btn ripple btn-secondary" (click)="onCloseClick()" type="button">Close</button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-application-fee">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="application_fee_fin_type_form">
          <div class="modal-header">
            <h6 class="modal-title">Application Fee Settings for :
              {{application_fee_fin_type_form.value.admission_name}}</h6>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <form [formGroup]="application_fee_fin_type_form">
                <div class="row row-sm">

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                      <select formControlName="finance_type" class="form-control" (change)="onFinanceTypeChange()">
                        <option value="">Select Finance Type</option>
                        <option
                          *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="finance_type.id">{{finance_type.text}}</option>
                      </select>
                    </div>
                  </div>

                </div>
              </form>
            </div>
            <ng-container *ngIf="application_fee_form">
              <div class="">
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>

                      <tr>
                        <th class="text-center" style="width: 5%;">S.No</th>
                        <th class="text-center" style="width: 5%;">Prg. Type</th>
                        <th class="text-center" style="width: 10%;">Fin. Type</th>
                        <th class="text-center" style="width: 20%;">Prg. Name</th>
                        <th class="text-center" style="width: 20%;">
                          <div class="row">
                            <div class="col" style="padding-top: 15px;">
                              Amount
                            </div>
                            <div class="col">
                              <div [formGroup]="application_fee_fin_type_form">
                                <input class="form-control" type="number" formControlName="amount">
                              </div>
                            </div>
                            <div class="col">
                              <button type="button" class="btn btn-sm btn-primary mr-2"
                                (click)="onApplyAmountToAllClick()">
                                <fa-icon [icon]="fonts.faCheck"></fa-icon> Apply For All
                              </button>
                            </div>
                          </div>

                        </th>
                      </tr>
                    </thead>

                    <tbody>

                      <ng-container
                        *ngFor="let programme of fromDataResolver.getFormArrayControlls(application_fee_form,'programmes');let i=index;trackBy:fromDataResolver.identify;">
                        <ng-container [formGroup]="programme">
                          <tr>
                            <td>
                              {{i+1}}
                            </td>
                            <td class="text-uppercase">
                              {{programme.value.programme_type_id}}
                            </td>
                            <td class="text-uppercase">
                              {{commonEnums.getFinanceDisplayText(programme.value.finance_type)}}
                            </td>
                            <td class="text-uppercase">
                              {{programme.value.programme_name }}
                            </td>

                            <td>

                              <input class="form-control" type="number" formControlName="application_fee_amount">


                            </td>

                          </tr>
                        </ng-container>
                      </ng-container>

                    </tbody>



                  </table>
                </div>
                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn btn-primary mg-r-20" (click)="onSaveApplicationFormClick()"
                    *ngIf="!application_fee_save_loading">Save</button>
                  <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="application_fee_save_loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>


                  <button class="btn ripple btn-secondary" (click)="onApplicationFeeFormCloseClick()"
                    type="button">Close</button>
                </div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-programme-fee">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="programme_fee_patch_form">
          <div class="modal-header">
            <h6 class="modal-title">Programme Fee Settings for : {{programme_fee_patch_form.value.admission_name}}</h6>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <form [formGroup]="programme_fee_patch_form">
                <div class="row row-sm">

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                      <select formControlName="finance_type" class="form-control" (change)="onPrgFinanceTypeChange()">
                        <option value="">Select Finance Type</option>
                        <option
                          *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="finance_type.id">{{finance_type.text}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select programme</label>
                      <select formControlName="programme_id" class="form-control">
                        <option value="">Select programme</option>
                        <option *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type ==
                          'self_finance'?' (SF)': ' (R)'}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select semester</label>
                      <select formControlName="semester" class="form-control">
                        <option value="">Select semester</option>
                        <option
                          *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="semester.id">{{semester.text}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select term</label>
                      <select formControlName="term" class="form-control">
                        <option value="">Select term</option>
                        <option
                          *ngFor="let term of commonEnums.getNumbers(5);let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="term">{{commonEnums.rommon_letters[term]}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Target</label>
                      <select formControlName="fee_target_type" class="form-control">
                        <option value="">Select Target</option>
                        <option value="REGULAR">Regular</option>
                        <option value="SC">SC/ST/SCA/BC(PARAYAN/PALLAN)</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">

                      <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search"
                        *ngIf="!programme_fee_settings_loading" (click)="onSearchClick()">
                        Search
                      </button>
                      <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                        *ngIf="programme_fee_settings_loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>




                    </div>
                  </div>

                </div>
              </form>
            </div>

            <div *ngIf="programme_fee_settings_form">
              <form [formGroup]="programme_fee_settings_form">
                <div class="row row-sm">

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select payment mode</label>
                      <select formControlName="mode" class="form-control">
                        <option value="">Select payment mode</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>

              <div class="table-responsive">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Specify payment split</label>
                </div>
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>

                    <tr>
                      <th class="text-center" style="width: 5%;">S.No</th>
                      <th class="text-center">Sub Account Id</th>
                      <th class="text-center">Account No.</th>
                      <th class="text-center" style="width: 20%;">Amount</th>
                    </tr>
                  </thead>

                  <tbody>

                    <ng-container
                      *ngFor="let account of fromDataResolver.getFormArrayControlls(programme_fee_settings_form,'accounts');let i=index;trackBy:fromDataResolver.identify;">
                      <ng-container [formGroup]="account">

                        <tr>
                          <td>
                            {{i+1}}
                          </td>
                          <td class="text-uppercase">
                            {{account.value.sub_acc_id}}
                          </td>
                          <td class="text-uppercase">
                            {{account.value.account_no}}
                          </td>
                          <td>
                            <input class="form-control" type="number" formControlName="amount">
                          </td>

                        </tr>
                      </ng-container>

                    </ng-container>

                    <tr>
                      <th colspan="3" class="text-align-right">Total</th>
                      <th class="text-align-right">{{getTotalAmount()}}</th>
                    </tr>

                    <ng-container *ngIf="programme_fee_settings_loading">
                      <tr>
                        <th colspan="4" class="text-center">Loading Please wait</th>
                      </tr>
                    </ng-container>
                  </tbody>



                </table>
              </div>
              <div class="mg-b-20"></div>
              <div class="text-align-right">
                <button class="btn ripple btn-primary" (click)="onSaveProgrammeFormClick()" type="button">Save</button>
                <button class="btn ripple btn-secondary mg-l-20" (click)="onProgrammeFeeFormCloseClick()"
                  type="button">Close</button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-settings">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="programme_settings_fillter_form">
          <div class="modal-header">
            <h6 class="modal-title">Programme Settings for : {{programme_settings_fillter_form.value.admission_name}}
            </h6>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <form [formGroup]="programme_settings_fillter_form">
                <div class="row row-sm">

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                      <select formControlName="finance_type" class="form-control"
                        (change)="onSettingsPrgFinanceTypeChange()">
                        <option value="">Select Finance Type</option>
                        <option
                          *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="finance_type.id">{{finance_type.text}}</option>
                      </select>
                    </div>
                  </div>

                </div>
              </form>
            </div>

            <div class="" *ngIf="programme_settings_form">
              <div class="table-responsive">
                <table class="table table-bordered mg-b-0 text-md-nowrap">
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-center" style="width: 5%;">S.No</th>
                      <th rowspan="2" class="text-center" style="width: 5%;">Prg. Type</th>
                      <th rowspan="2" class="text-center" style="width: 10%;">Fin. Type</th>
                      <th rowspan="2" class="text-center" style="width: 5%;">Prg. Name</th>
                      <th rowspan="2" class="text-center" style="width: 10%;">Allowed Genders</th>
                      <th colspan="2" class="text-center" style="width: 16%;">Seats</th>

                      <th rowspan="2" class="text-center" style="width: 5%;">Need Extrance Exam</th>



                    </tr>
                    <tr>
                      <th class="text-center" style="width: 8%;">Allowed Seats</th>
                      <th class="text-center" style="width: 8%;">Extra Seats</th>

                    </tr>
                  </thead>

                  <tbody>

                    <ng-container
                      *ngFor="let programme of fromDataResolver.getFormArrayControlls(programme_settings_form,'programmes');let i=index;trackBy:fromDataResolver.identify;">
                      <ng-container [formGroup]="programme">
                        <tr>
                          <td>
                            {{i+1}}
                          </td>
                          <td class="text-uppercase">
                            {{programme.value.programme_type_id}}
                          </td>
                          <td class="text-uppercase">
                            {{commonEnums.getFinanceDisplayText(programme.value.finance_type)}}
                          </td>
                          <td class="text-uppercase">
                            {{programme.value.programme_name }}
                          </td>
                          <td>
                            <select multiple formControlName="genders" class="form-control">
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </td>
                          <td>
                            {{programme.value.allowed_seats }}
                          </td>
                          <td>
                            <input class="form-control" type="number" formControlName="extra_seats">
                          </td>
                          <td>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" [attr.id]='"p-"+i'
                                [attr.name]='"p-"+i' formControlName="need_entrance_exam">
                              <label class="custom-control-label" [attr.for]='"p-"+i'>Enable</label>
                            </div>
                          </td>



                        </tr>
                        <tr>
                          <td colspan="8">
                            <table class="table table-striped table-bordered mg-b-0 text-md-nowrap">
                              <thead>
                                <tr>
                                  <th class="text-center" style="width: 5%;">S.No</th>
                                  <th class="text-center">Sec</th>
                                  <th class="text-center">Incharge Name</th>
                                  <th class="text-center">Incharge Mobile</th>
                                  <th class="text-center">Class Code</th>

                                </tr>

                              </thead>

                              <tbody>
                                <ng-container
                                  *ngFor="let incharge_detail of fromDataResolver.getFormArrayControlls(programme,'incharge_details');let i=index;trackBy:fromDataResolver.identify;">
                                  <ng-container [formGroup]="incharge_detail">
                                    <tr>
                                      <td>{{i+1}}</td>
                                      <td>
                                        {{incharge_detail.value.section}}
                                      </td>
                                      <td>
                                        <input class="form-control" type="text" formControlName="incharge_name">
                                      </td>
                                      <td>
                                        <input class="form-control" type="text" formControlName="incharge_mobile">
                                      </td>
                                      <td>
                                        <input class="form-control" type="text" formControlName="class_code">
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>

                    <ng-container
                      *ngIf="!programme_settings_list_loading && programme_settings_form.value.programmes.length==0">
                      <tr>
                        <th colspan="5" class="text-center">No Records found</th>
                      </tr>
                    </ng-container>
                    <ng-container
                      *ngIf="programme_settings_list_loading && programme_settings_form.value.programmes.length==0">
                      <tr>
                        <th colspan="5" class="text-center">Loading Please wait</th>
                      </tr>
                    </ng-container>
                  </tbody>



                </table>
              </div>
              <div class="mg-b-20"></div>
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSaveAdmissionSettingsFormClick()"
                  *ngIf="!programme_settings_save_loading">Save</button>
                <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="programme_settings_save_loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>

                <button class="btn ripple btn-secondary" (click)="onSettingsFormCloseClick()"
                  type="button">Close</button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

    <!-- Large Modal -->
    <div class="modal" id="modal-eligibility">
      <div class="modal-dialog big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="programme_settings_fillter_form">
          <div class="modal-header">
            <h6 class="modal-title">Admission Eligibility Rules</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <div class="card-body">
                <form [formGroup]="programme_settings_fillter_form">
                  <div class="row row-sm">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                        <select formControlName="finance_type" class="form-control"
                          (change)="onEligibilityPrgFinanceTypeChange()">
                          <option value="">Select Finance Type</option>
                          <option
                            *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                            [ngValue]="finance_type.id">{{finance_type.text}}</option>
                        </select>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              <div class="card-body" *ngIf="programme_eligibility_form">
                <form [formGroup]="programme_eligibility_form">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Programme Type</th>
                          <th>Programme Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container formArrayName="eligibility_rules">
                          <ng-container
                            *ngFor="let rule of eligibilityRules; let i = index; trackBy:fromDataResolver.identify;"
                            [formGroupName]="i">
                            <tr>
                              <td>{{i+1}}</td>
                              <td>{{rule.value.programme_type_id}}</td>
                              <td>{{rule.value.programme_name}}</td>
                            </tr>
                            <ng-container formArrayName="subjects">
                              <ng-container *ngFor="let subject of getSubjectsList(i); 
                                let j = index; trackBy:fromDataResolver.identify;" [formGroupName]="j">
                                <tr>
                                  <td colspan="2">
                                    <b>Rule {{j+1}}:</b>
                                  </td>
                                  <td>
                                    <button class="btn btn-sm btn-danger" (click)="deleteRule(i,j)"
                                      style="float: right;">Delete</button>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="3">
                                    All Subject:
                                    <kendo-multiselect [data]="subject_list" valueField="subject_name"
                                      textField="subject_name" formControlName="all"
                                      placeholder="Choose Required Subject(s)" class="menu_select"></kendo-multiselect>
                                    Anyone Subject:
                                    <kendo-multiselect [data]="subject_list" valueField="subject_name"
                                      textField="subject_name" formControlName="anyone"
                                      placeholder="Choose Least Required Subject(s)" class="menu_select">
                                    </kendo-multiselect>
                                  </td>
                                </tr>
                              </ng-container>
                              <tr>
                                <td colspan="3" class="text-center">
                                  <button class="btn btn-sm btn-primary" (click)="addNewRule(i)">Add New
                                    Rule</button>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                      </tbody>
                    </table>
                  </div>
                </form>
              </div>

              <div class="mg-b-20"></div>
              <div class="text-align-right">
                <button class="btn btn-primary mg-r-20" (click)="onSaveEligibilityClick()"
                  *ngIf="!programme_eligibility_save_loading">Save</button>
                <button class="btn btn-primary mg-r-20" type="button" disabled
                  *ngIf="programme_eligibility_save_loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
                <button class="btn ripple btn-secondary" (click)="onEligibilityFormCloseClick()"
                  type="button">Close</button>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->


    <!-- Large Modal -->
    <div class="modal" id="modal-accounts">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="application_fee_fin_type_form">
          <div class="modal-header">
            <h6 class="modal-title">Bank Account Settings</h6>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <form [formGroup]="application_fee_fin_type_form">
                <div class="row row-sm">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                      <select formControlName="finance_type" class="form-control"
                        (change)="onAccountFinanceTypeChange($event)">
                        <option value="">Select Finance Type</option>
                        <option
                          *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                          [ngValue]="finance_type.id">{{finance_type.text}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>

              <div class="row" *ngIf="account_list_loading && !account_setting_form">
                <div class="col-md-12 text-center">
                  <b>Loading...</b>
                </div>
              </div>

              <div *ngIf="account_setting_form">
                <form [formGroup]="account_setting_form">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th style="width: 10%;">S.No</th>
                          <th style="width: 10%;">Finance Type</th>
                          <th style="width: 10%;">Programme Type</th>
                          <th>Programme Name</th>
                          <th style="width: 25%;">Account</th>
                          <th style="width: 15%;">Action</th>
                        </tr>
                      </thead>
                      <tbody formArrayName="account_settings">
                        <tr *ngFor="let account of accountSettings; let i = index;" [formGroupName]="i">
                          <td>{{i+1}}</td>
                          <td>{{account.value.finance_type.split('_').join(' ') | titlecase}}</td>
                          <td>{{account.value.programme_type_id}}</td>
                          <td>
                            <select formControlName="programme_id" class="form-control">
                              <option value="">Select programme</option>
                              <option
                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                [ngValue]="programme.programme_id">{{programme.programme_name}} {{programme.finance_type
                                ==
                                'self_finance'?' (SF)': ' (R)'}}</option>
                            </select>
                          </td>
                          <td>
                            <select formControlName="sub_acc_id" class="form-control">
                              <option value="">Select Account</option>
                              <option
                                *ngFor="let account of account_list;let i=index;trackBy:fromDataResolver.identify;"
                                [value]="account.sub_acc_id">{{account.sub_acc_id}} ({{account.account_no}})</option>
                            </select>
                          </td>
                          <td>
                            <button class="btn btn-danger btn-sm" (click)="removeAccountSetting(i)">Remove</button>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6" class="text-center">
                            <button class="btn btn-md btn-primary" (click)="addAccountSetting()">Add New
                              Setting</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>


                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn ripple btn-primary" (click)="onSaveAccountClick()" type="button">Save</button>
                  <button class="btn ripple btn-secondary mg-l-20" (click)="onAccountFormCloseClick()"
                    type="button">Close</button>
                </div>
              </div>
            </div>



          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->


    <!-- Large Modal -->
    <div class="modal" id="modal-scroller">
      <div class="modal-dialog modal-lg big-modal" role="document">
        <div class="modal-content modal-content-demo" *ngIf="scroller_form">
          <div class="modal-header">
            <h6 class="modal-title">Landing Page Scroller Messages</h6>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div *ngIf="scroller_form">
                <form [formGroup]="scroller_form">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th style="width: 5%;">S.No</th>
                          <th style="width: 40%;">Message</th>
                          <th style="width: 25%;">Link</th>
                          <th style="width: 5%">Is Active</th>
                          <th style="width: 10%;">Display Priority</th>
                          <th style="width: 15%;">Action</th>
                        </tr>
                      </thead>
                      <tbody formArrayName="messages">
                        <tr *ngFor="let message of messagesList; let i = index;" [formGroupName]="i">
                          <td>{{i+1}}</td>
                          <td>
                            <textarea class="form-control" placeholder="Enter Message"
                              formControlName="message"></textarea>
                          </td>
                          <td>
                            <input class="form-control" required="" type="file" (change)="uploadFile($event,i)">
                            <p *ngIf="message.value.link">
                              <a [href]="message.value.link" target="_blank">Existing File</a>
                            </p>
                          </td>
                          <td>
                            <input type="checkbox" formControlName="is_active">
                          </td>
                          <td>
                            <button type="button" (click)="moveUp(i)" title="Move Up" class="btn btn-sm btn-info mr-2">
                              <fa-icon [icon]="fonts.faArrowUp"></fa-icon>
                            </button>
                            <button type="button" (click)="moveDown(i)" title="Move Down"
                              class="btn btn-sm btn-info mr-2">
                              <fa-icon [icon]="fonts.faArrowDown"></fa-icon>
                            </button>
                          </td>
                          <td>
                            <button class="btn btn-danger btn-sm" (click)="removeScrollerMessage(i)">Remove</button>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6" class="text-center">
                            <button class="btn btn-md btn-primary" (click)="addScrollerMessage()">Add New
                              Message</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>


                <div class="mg-b-20"></div>
                <div class="text-align-right">
                  <button class="btn ripple btn-primary" (click)="onSaveMessagesClick()" type="button">Save</button>
                  <button class="btn ripple btn-secondary mg-l-20" (click)="onScrollerFormCloseClick()"
                    type="button">Close</button>
                </div>
              </div>
            </div>



          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

  </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>