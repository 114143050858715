<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Component Master</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add Components</button>
                </div>

                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-primary" (click)="onAddCalculaitonClick()">Manage
                        Calculation</button>
                </div>


            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">



                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">OBE Components</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete OBE Components</p>
                    </div>


                    <div class="card-body">
                        <ng-container *ngIf="component_search_form">
                            <form [formGroup]="component_search_form">
                                <div class="row row-sm">

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme Type</label>
                                            <select formControlName="programme_type_id" class="form-control">
                                                <option value="">All Programme Types</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrollment Year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!component_list_loading"
                                                (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="component_list_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </ng-container>
                        <div class="mg-b-20"></div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 3%;">S.No</th>
                                        <th style="width: 10%;">Component Type</th>
                                        <th style="width: 10%;">No. of Terms</th>
                                        <th style="width: 10%;">Max Marks</th>
                                        <th style="width: 10%;">Scale To</th>
                                        <!-- <th style="width: 10%;">Allowed COs</th> -->
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let component_header of component_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td colspan="5">
                                                <b>{{component_header.key}}</b>
                                            </td>
                                            <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                    <button type="button" class="btn btn-sm btn-info mr-2"
                                                        (click)="onEditClick(component_header.components, component_header.key)">
                                                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>

                                                    </button>
                                                    <!-- <button type="button" class="btn btn-sm btn-danger mr-2"
                                                        (click)="onDeleteClick(component_header)">
                                                        <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                                    </button> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngFor="let component of component_header.components;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{component.component_type}}
                                                </td>
                                                <td>
                                                    {{component.term}}
                                                </td>
                                                <td>
                                                    {{component.max_mark}}
                                                </td>
                                                <td>
                                                    {{component.scale_to}}
                                                </td>
                                                <td>

                                                </td>
                                                <!-- <td>
                          {{this.commonEnums.numberToCOs(component.allowed_co_ids).join(", ")}}
                        </td> -->
                                            </tr>
                                        </ng-container>


                                    </ng-container>
                                    <ng-container *ngIf="!component_list_loading && component_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="component_list_loading && component_list.length==0">
                                        <tr>
                                            <th colspan="7" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>



        <!-- Large Modal -->
        <div class="modal" id="modal-popup-component">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="component_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Components</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="component_form">
                                <div class="row mg-b-20" *ngIf="key==''">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme Type</label>
                                            <select formControlName="programme_type_id" class="form-control"
                                                (change)="onFilterSelectChange('programme_type_id',$event)">
                                                <option value="">All Programme Types</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrollment Year</label>
                                            <select formControlName="enrolled_year" class="form-control"
                                                (change)="onFilterSelectChange('enrolled_year',$event)">
                                                <option value="">Select</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control"
                                                (change)="onFilterSelectChange('semester',$event)">
                                                <option value="">Select</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Part</label>
                                            <select formControlName="part" class="form-control"
                                                (change)="onFilterSelectChange('part',$event)">
                                                <option value="">All Parts</option>
                                                <option
                                                    *ngFor="let part of parts;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="part.id">
                                                    {{part.text}}</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Course
                                                Type</label>
                                            <select formControlName="course_type" class="form-control"
                                                (change)="onFilterSelectChange('course_type',$event)">
                                                <option value="">All Course Types</option>
                                                <option
                                                    *ngFor="let course_type of course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="course_type.id">{{course_type.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Course
                                                Code</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Course Code" formControlName="course_code">
                                        </div>
                                    </div>



                                </div>

                                <div class="row mg-b-10" *ngIf="key!=''">
                                    <div class="col-md-4">
                                        Programme Type: <b>{{component_form.value.programme_type_id}}</b>
                                    </div>
                                    <div class="col-md-4">
                                        Enrolled Year: <b>{{component_form.value.enrolled_year}}</b>
                                    </div>
                                    <div class="col-md-4">
                                        Semester: <b>{{component_form.value.semester}}</b>
                                    </div>
                                </div>

                                <div class="row mg-b-20" *ngIf="key!=''">
                                    <div class="col-md-4">
                                        Part: <b>{{component_form.value.part}}</b>
                                    </div>
                                    <div class="col-md-4" *ngIf="component_form.value.course_type">
                                        Course Type: <b>{{component_form.value.course_type}}</b>
                                    </div>
                                    <div class="col-md-4" *ngIf="component_form.value.course_code">
                                        Course Code: <b>{{component_form.value.course_code}}</b>
                                    </div>
                                </div>

                                <ng-container formArrayName="component_list">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 50px;">S.No.</th>
                                                        <th>Component Name</th>
                                                        <th>No. of Terms</th>
                                                        <th>Max Mark</th>
                                                        <th>Scale To</th>
                                                        <th style="width:125px">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let component of componentControls;let i=index;">
                                                        <ng-container [formGroupName]="i">
                                                            <tr>
                                                                <td>
                                                                    {{i+1}}
                                                                </td>
                                                                <td *ngIf="component.value.id==''">
                                                                    <select formControlName="component_type"
                                                                        class="form-control"
                                                                        (change)="onComponentChange($event,i)">
                                                                        <option value="">Select</option>
                                                                        <option
                                                                            *ngFor="let component_type of commonEnums.component_types;let j=index;trackBy:fromDataResolver.identify;"
                                                                            [value]="component_type.id">
                                                                            {{component_type.text}}</option>
                                                                    </select>
                                                                </td>
                                                                <td *ngIf="component.value.id!=''">
                                                                    {{component.value.component_type}}
                                                                </td>
                                                                <td *ngIf="component.value.id==''">
                                                                    <select formControlName="term" class="form-control"
                                                                        (change)="onFilterSelectChange('term',$event)">
                                                                        <option value="">Select</option>
                                                                        <option
                                                                            *ngFor="let term of terms;let k=index;trackBy:fromDataResolver.identify;"
                                                                            [ngValue]="term">
                                                                            {{term}}</option>
                                                                    </select>
                                                                </td>
                                                                <td *ngIf="component.value.id!=''">
                                                                    {{component.value.term}}
                                                                </td>

                                                                <td>
                                                                    <input type="text" formControlName="max_mark"
                                                                        class="form-control"
                                                                        placeholder="Enter Max Marks.">
                                                                </td>
                                                                <td>
                                                                    <input type="text" formControlName="scale_to"
                                                                        class="form-control"
                                                                        placeholder="Enter Marks to be scaled down.">
                                                                </td>
                                                                <td *ngIf="component.value.id!=''">
                                                                    <button class="btn btn-info btn-sm"
                                                                        (click)="expandComponents(component.value.component_type,i)"
                                                                        *ngIf="!component.value.is_expanded">View
                                                                        components</button>
                                                                    <button class="btn btn-info btn-sm"
                                                                        (click)="closeExpandedView(i)"
                                                                        *ngIf="component.value.is_expanded">Close
                                                                        components</button>
                                                                </td>
                                                                <td *ngIf="component.value.id==''">
                                                                    <button class="btn btn-danger mg-r-20"
                                                                        (click)="deleteComponent(i)">Delete</button>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="component.value.is_expanded">
                                                                <td colspan="6">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-hover table-bordered mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style="width: 50px;">S.No.</th>
                                                                                    <th>Component Name</th>
                                                                                    <th>Term</th>
                                                                                    <th>Max Mark</th>
                                                                                    <th>Scale To</th>
                                                                                    <th style="width:125px">Delete</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let expanded_component of expanded_component_list;let in=index;trackBy:fromDataResolver.identify;">
                                                                                    <td>
                                                                                        {{in+1}}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{expanded_component.component_type}}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{expanded_component.term}}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{expanded_component.max_mark}}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{expanded_component.scale_to}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button
                                                                                            class="btn btn-danger mg-r-20"
                                                                                            (click)="deleteComponentById(in, expanded_component._id)"
                                                                                            *ngIf="!component_delete_loading[in]">Delete</button>
                                                                                        <button
                                                                                            class="btn btn-danger mg-r-20"
                                                                                            type="button" disabled
                                                                                            *ngIf="component_delete_loading[in]">
                                                                                            <span
                                                                                                class="spinner-border spinner-border-sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"></span>
                                                                                            Deleting...
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                    <tr>
                                                        <td colspan="8" class="text-center">
                                                            <button class="btn btn-primary mg-r-20"
                                                                (click)="addMoreComponent()">Add
                                                                More</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="text-align-right mg-t-20">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveComponentsClick()"
                                        *ngIf="!component_add_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="component_add_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>


                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                        type="button">Close</button>
                                </div>





                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Large Modal -->
        <div class="modal" id="modal-popup-calculation">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="calculation_form_search">
                    <div class="modal-header">
                        <h6 class="modal-title">OBE calculation</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="calculation_form_search">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme Type</label>
                                            <select formControlName="programme_type_id" class="form-control">
                                                <option value="">All Programme Types</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Enrollment Year</label>
                                            <select formControlName="enrolled_year" class="form-control">
                                                <option value="">Select</option>
                                                <option
                                                    *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="year">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select</option>
                                                <option
                                                    *ngFor="let semester of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="semester.id">{{semester.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!calculation_form_search_loading"
                                                (click)="onSearchCalculation()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="calculation_form_search_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>


                                </div>

                                <div class="modal-body" *ngIf="calculation_form">
                                    <div class="mg-b-20"></div>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th style="width: 3%;">S.No</th>
                                                    <th style="width: 10%;">Evaluation Type</th>
                                                    <th style="width: 10%;">Components</th>
                                                    <th style="width: 10%;">Calculation Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let calculation of fromDataResolver.getFormArrayControlls(calculation_form,'calculation_list');let i=index;trackBy:fromDataResolver.identify;">
                                                    <ng-container [formGroup]="calculation">
                                                        <tr>
                                                            <td>
                                                                {{i+1}}
                                                            </td>

                                                            <td class="uppercase">
                                                                {{calculation.value.evaluation_type}}
                                                            </td>
                                                            <td>
                                                                <div class="form-group">

                                                                    <select multiple formControlName="component_types"
                                                                        class="form-control">
                                                                        <option
                                                                            *ngFor="let component_type of commonEnums.component_types;let i=index;trackBy:fromDataResolver.identify;"
                                                                            [ngValue]="component_type.id">
                                                                            {{component_type.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="form-group">

                                                                    <select formControlName="calculation_type"
                                                                        class="form-control">
                                                                        <option value="">Select Calculation Type
                                                                        </option>
                                                                        <option
                                                                            *ngFor="let obe_calculation of commonEnums.obe_calculation_list;let i=index;trackBy:fromDataResolver.identify;"
                                                                            [ngValue]="obe_calculation.id">
                                                                            {{obe_calculation.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                        </tr>


                                                    </ng-container>
                                                </ng-container>
                                                <!-- <ng-container
                          *ngIf="!calculation_form_search_loading && calculation_form.value.calculation_list.value.length==0">
                          <tr>
                            <th colspan="7" class="text-center">No Records found</th>
                          </tr>
                        </ng-container>
                        <ng-container
                          *ngIf="calculation_form_search_loading && calculation_form.value.calculation_list.value.length==0">
                          <tr>
                            <th colspan="7" class="text-center">Loading Please wait</th>
                          </tr>
                        </ng-container> -->
                                            </tbody>

                                        </table>
                                    </div>
                                </div>


                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onCalculationSaveClick()"
                                        *ngIf="!calculation_form_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="calculation_form_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>


                                    <button class="btn ripple btn-secondary" (click)="onCloseCalculaitonClick()"
                                        type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>