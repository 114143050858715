import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-adms-registered-users',
  templateUrl: './adms-registered-users.component.html',
  styleUrls: ['./adms-registered-users.component.scss']
})
export class AdmsRegisteredUsersComponent implements OnInit {


  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;

  // Master data
  regulation_year = new Date().getFullYear();
  admission_id = '';
  // Core data
  user_search_form: any;

  registered_users: any[] = [];
  registered_users_kendo: any;
  registered_users_loading = false;


  // admission list
  admissions_list = [];
  admissions_list_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      this.regulation_year = Number(data.regulation_year);
      this.admission_id = data.admission_id;
    });
  }

  ngOnInit(): void {
    this.buildUserSearchForm();
    this.getCoreDatas();
  }

  async onRegulationYearChange(): Promise<void> {
    this.user_search_form.get('admission_id').setValue('');
    this.getCoreDatas();
    this.registered_users = [];
  }

  async onAdmissionChange(): Promise<void> {
    this.registered_users = [];
  }

  async getCoreDatas(): Promise<void> {
    try {
      const query = {
        year: this.user_search_form.value.regulation_year
      };
      this.admissions_list = [];
      this.admissions_list_loading = true;
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }

  buildUserSearchForm(): void {
    this.user_search_form = this.fb.group({
      regulation_year: [this.regulation_year],
      admission_id: [this.admission_id],
      application_no: [''],
      aadhar_card_no: [''],
      mobile_number: [''],
      name: [''],
      community_id: [''],
      caste: [''],
      state: [''],
      only_applied: [false],
      only_enrolled: [false],
      other_states: [false],
      filter_list: [''],
      gender: ['']
    });
  }

  otherStateClicked(event) {
    let select = event.target as HTMLInputElement;
    if (select.checked) {
      this.user_search_form.get('state').setValue('');
    }
  }

  async onSearchClick(): Promise<void> {
    try {
      this.registered_users_loading = true;
      this.registered_users = [];
      const response = await this.restService.getRegisteredApplicants(this.user_search_form.value);
      if (response.success && response.data) {
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];
          element.sno = index;
        }
        this.registered_users = response.data;
        // this.registered_users_kendo = process(response.data, {});
      }

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.registered_users_loading = false;
    }
  }

  async onClearSearch(): Promise<void> {
    try {
      this.registered_users = [];
      this.buildUserSearchForm();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  searchEnterHit(event: any): void {
    if (event.keyCode === 13) {
      this.onSearchClick();
    }
  }

  onEditClick(applicant_id: string): void {
    HTMLHelper.openLinkInNewTab(`/admin/admissions/applicant/edit?applicant_id=${applicant_id}`);
  }

}
