import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
@Component({
  selector: 'app-exam-fees-settings',
  templateUrl: './exam-fees-settings.component.html',
  styleUrls: ['./exam-fees-settings.component.scss']
})
export class ExamFeesSettingsComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Exam Fees Settings List';
  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_type_id: string = ""; //ngModel
  months: {
    name: string, val: number
  }[] = [{ name: "November", val: 11 }, { name: "April", val: 4 }];
  settings_list_loading: boolean = false;
  settings_list: SettingInterface[] = [];
  setting: SettingInterface = {} as SettingInterface;
  programme_list: any[] = [];
  collection_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  search_btn_pressed: boolean = false;
  Hallticket_form: FormGroup;
  datechange: boolean = false;
  fine_list_loading: boolean = false;
  fine_list: FineRuleInterface[] = [];
  fee_header_id: string = '';
  bulk_render_data: Modal = {
    return_type: "FILE",
    file_upload_only: false,
    btn_size: 'btn-sm',
    modal_id: "exam-fees-upload",
    modal_button_text: "Upload Fees",
    modal_header: "Stuent Exam Fee Bulk Upload",
    list_name: "Exam Fees Upload",
    download_note: "SubjectList must contain Course Codes separated by comma without any spaces. ApperanceType must contain the following values only: 1 is Normal, 2 is Arrear, 3 is Repeat",
    csv_data: {
      columns: [
        { column_name: "Rollno", column_type: "required" },
        { column_name: "SubjectList", column_type: "required" },
        { column_name: "TotalFees", column_type: "required" },
        { column_name: "ApperanceType", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Rollno", "SubjectList", "TotalFees", "ApperanceType"],
      column_values: ["22UA14", "20UJK202,20UFK202,20UDC202", "995", "1"]
    },
    show_loader: false
  };

  ngOnInit(): void {
    this.getProgrammeDataList();
    this.getProgrammeTypeList();
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.hallticket();
    this.getFineList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getFineList() //get from API
  {
    try {
      this.fine_list_loading = true;
      this.fine_list = [];
      const service_response = await this.restService.getFineList();
      if (service_response && service_response.success) {
        this.fine_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.fine_list_loading = false;
    }

  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
      console.log(this.programme_list);
    } catch (error) {
      alert('Error while read data');
    }
    console.log(this.programme_list);
  }

  async onFilterSelectChange(programme_type_id: string, event: any) {

  }

  onSearchClick() {
    if (this.programme_type_id != '') {
      this.getFeesSettingsList();
      this.search_btn_pressed = true;
    }
    else {
      alert("Please Choose Valid Programme Type");
    }

  }

  async getFeesSettingsList() {
    this.settings_list = [];
    this.settings_list_loading = true;
    try {
      const service_response = await this.restService.getExamFeesHeaderList({ "programme_type_id": this.programme_type_id });
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.settings_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        term_name: "",
        id: "",
        start_date: "",
        end_date: "",
        description: "",
        extra_charge_amount: 0,
        month: "0",
        year: 0,
        active: false,
        fine_settings_id: ''
      };
    }
    this.collection_form = this.formBuilder.group({
      id: [this.setting.id],
      start_date: [this.setting.start_date != "" ? (this.setting?.start_date?.substring(0, 10)) : '', [Validators.required]],
      end_date: [this.setting.end_date != "" ? (this.setting?.end_date?.substring(0, 10)) : '', [Validators.required]],
      description: [this.setting.description, [Validators.required]],
      extra_charge_amount: [this.setting.extra_charge_amount, [Validators.required]],
      year: [this.setting.year, [Validators.required]],
      month: [this.setting.month, [Validators.required]],
      term_name: [this.setting.term_name],
      fine_settings_id: [this.setting.fine_settings_id],
      active: [this.setting.active],
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    this.setting_id = setting.id; //term_name
    var settings = this.settings_list.find(x => x.id == setting.id);
    if (settings) {
      console.log(settings);
      this.setting = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#collection-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#collection-modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.setting = {} as SettingInterface;
    this.collection_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      this.collection_form.get('term_name')?.setValue(this.months.find(x => x.val == this.collection_form.get('month')?.value)?.name + " " + this.collection_form.get('year')?.value);
      var form_data = this.collection_form.value;
      form_data.start_date = new Date(this.collection_form.value.start_date).toISOString();
      form_data.end_date = new Date(this.collection_form.value.end_date).toISOString();
      form_data.programme_type_id = this.programme_type_id;
      //form_data.fine_settings_id = "";//form_data.active=true;
      console.log(form_data);
      if (this.collection_form.value.id) {
        const res = await this.restService.updateExamFeesHeader(this.collection_form.value.id, form_data);
        if (!res) {
          this.notificationService.setNotifcation({ message: 'Failed to update exam settings', type: 'Failed' });
        }
        this.notificationService.setNotifcation({ message: 'Updated exam settings successfully', type: 'Success' });
      }
      else {
        const res = await this.restService.createExamFeesHeader(form_data);
        if (!res) {
          this.notificationService.setNotifcation({ message: 'Failed to create exam settings', type: 'Failed' });
        }
        this.notificationService.setNotifcation({ message: 'Created exam settings successfully', type: 'Success' });
      }
      this.getFeesSettingsList();
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'An Error occured', type: 'Failed' });
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    const res = await this.restService.deleteExamFeeHeader(this.collection_form.get('id')!.value);
    if (res.success) {
      this.notificationService.setNotifcation({ message: 'Deleted exam fee settings successfully', type: 'Success' });
      this.getFeesSettingsList();
    }
    else {
      this.notificationService.setNotifcation({ message: 'Failed to delete exam fee settings', type: 'Failed' });
    }

    this.onCloseClick();
  }

  isInvalid(formControl: string): boolean {
    return this.collection_form.touched && this.collection_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.collection_form.status !== 'VALID';
  }

  navigateToExamSettings(setting: any) {
    this.router.navigate(["/admin/exam/fee-setting"], {
      queryParams: {
        setting_id: setting.id,
        programme_type_id: this.programme_type_id
      }
    }); //pass setting.id
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  hallset() {
    JQueryHelper.openModal('#setting-modal-popup', { keyboard: false, backdrop: 'static' });
  }
  hallticket() {
    this.Hallticket_form = this.formBuilder.group({
      title: ['', [Validators.required]],
      note: ['', [Validators.required]],
      controller: ['', [Validators.required]],
      principal: ['', [Validators.required]],
      current_date: [false, [Validators.required]],
      date_fixing: ['', [Validators.required]],
      isactive: ['', [Validators.required]]
    })
  }

  async onCloseClick_hall(): Promise<void> {
    this.reset_hall();
    JQueryHelper.closeModal('#setting-modal-popup');
  }

  reset_hall() {
    this.Hallticket_form.reset();
  }

  get_type(eve) {
    console.log(eve.target.value);
    if (eve.target.value === 'set') {
      this.datechange = true;
    }
    if (eve.target.value === 'true') {
      this.datechange = false;
    }
  }
  onSave_hall() {
    console.log(this.Hallticket_form.value);
    this.onCloseClick();
  }
  onSaveAndClose_hall() {
    console.log(this.Hallticket_form.value);
  }

  navigateToFeeMap(setting: SettingInterface) {
    this.router.navigate(["/admin/exam/view-student-exam-fee"], {
      queryParams: {
        setting_id: setting.id,
        programme_type_id: this.programme_type_id
      }
    });
  }

  getBulkRenderData(i: number): Modal {
    return {
      return_type: "FILE",
      file_upload_only: false,
      btn_size: 'btn-sm',
      modal_id: "exam-fees-upload" + i,
      modal_button_text: "Upload Fees",
      modal_header: "Stuent Exam Fee Bulk Upload",
      list_name: "Exam Fees Upload",
      download_note: "SubjectList must contain Course Codes separated by comma without any spaces. ApperanceType must contain the following values only: 1 is Normal, 2 is Arrear, 3 is Repeat",
      csv_data: {
        columns: [
          { column_name: "Rollno", column_type: "required" },
          { column_name: "SubjectList", column_type: "required" },
          { column_name: "TotalFees", column_type: "required" },
          { column_name: "ApperanceType", column_type: "required" },
        ]
      },
      table_data: {
        column_names: ["Rollno", "SubjectList", "TotalFees", "ApperanceType"],
        column_values: ["22UA14", "20UJK202,20UFK202,20UDC202", "995", "1"]
      },
      show_loader: false
    };
  }

  setId(setting: SettingInterface) {
    console.log(setting._id);
    this.fee_header_id = setting._id;
  }

  async bulkUpload(e: any) {
    try {
      const file = e;
      console.log(file);
      var formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      console.log(this.fee_header_id);
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      this.bulk_render_data.show_loader = true;
      const response = await this.restService.uploadExamFeesBulk(formData, this.fee_header_id);
      if (response.success) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.fee_header_id = "";
      this.bulk_render_data.show_loader = false;
    }
  }
}

interface SettingInterface {
  term_name: string
  id: string,
  start_date: string,
  end_date: string,
  description: string,
  extra_charge_amount: number,
  month: string,
  year: number,
  active: boolean,
  _id?: string,
  fine_settings_id?: string
}


interface FineRuleInterface {
  _id: string,
  fine_name: string,
  re_admission_fee: number,
  fine_rules: {
    day_limit: number,
    fine_amount: number,
    is_per_day: boolean
  }[],
  post_fine_rule: string
}