import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-offline-transaction',
  templateUrl: './offline-transaction.component.html',
  styleUrls: ['./offline-transaction.component.scss']
})
export class OfflineTransactionComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  id_types: VariableSettingInterface[] = [{ id: "BANK_REF", name: "Bank Reference No." }, { id: "CHALLAN_NO", name: "Challan No." },
  { id: "CHEQUE_NO", name: "Cheque No." }];
  current_selection: any;
  payment_details: any[] = [];
  offline_student_data: any = {};
  search_rno: string = "";
  sections: any = [];
  payment_details_loading = false;
  add_transaction_form: FormGroup;
  constructor(private restService: AdminservicesService, private fb: FormBuilder, private http: HttpClient,
    private route: ActivatedRoute, private notificationService: NotificationService) {
    this.add_transaction_form = this.fb.group({
      reference_no: ['', [Validators.required]],
      id_type: ["", [Validators.required]],
      //proof:['', [Validators.required]],
      description: [''],
      signed_by: ['', [Validators.required]],
    })
  }
  @Input() render_data: ComponentRenderInterface;
  @Output() breakup_data = new EventEmitter<any>();
  ngOnInit(): void {
    this.search_rno = "";
    if (this.render_data.roll_no != undefined) {
      this.search_rno = this.render_data.roll_no;
      this.getStudentPaymentData();
    }
  }


  async getStudentPaymentData() {
    console.log(this.search_rno);
    this.add_transaction_form.reset(); this.add_transaction_form.get('id_type').setValue("");
    this.payment_details = []; this.offline_student_data = {};
    try {
      this.payment_details_loading = true;
      const service_response = await this.restService.getStudentFeeByRollNo(this.search_rno.toUpperCase());
      if (service_response && service_response.success) {
        this.payment_details = service_response.data;
        this.payment_details.forEach(detail => {
          detail.show_payment = false;
        })
        console.log(this.payment_details);
        const res = await this.restService.getStudentByRollNo((this.search_rno).toUpperCase());
        console.log(res.data[0]);
        if (res.success) {
          this.offline_student_data.given_name = res.data[0]?.given_name;
          this.offline_student_data.class_name = this.commonEnums.getClassName(res.data[0]?.enrolled_year, res.data[0]?.programme_name, res?.data[0]?.section);
        }
      }
    } catch (error) {
      console.log(error);
      alert('Error while read data');
    } finally {
      this.payment_details_loading = false;
    }
  }


  async viewBreakupsClick(payment_object: any): Promise<void> {
    if (payment_object.fee_header_type == "EXAM_FEE") {
      this.current_selection = payment_object;
      if (this.render_data.mode == "stand_alone")
        JQueryHelper.openModal('#exam-fee-modal-popup', { keyboard: false, backdrop: 'static' });
      else {
        console.log(payment_object);
        this.breakup_data.emit(payment_object);
      }
    }
  }

  async onCloseBreakupsClick() {
    if (this.current_selection.fee_header_type == "EXAM_FEE") {
      JQueryHelper.closeModal('#exam-fee-modal-popup');
      this.current_selection = null;
    }
  }

  async openCloseOfflinePayment(payment_detail: any): Promise<void> {
    this.add_transaction_form.reset(); this.add_transaction_form.get('id_type').setValue("");
    if (payment_detail.show_payment) {
      payment_detail.show_payment = false;
    } else {
      payment_detail.show_payment = true;
    }
    this.payment_details.forEach(detail => {
      if (detail.payment_init_id != payment_detail.payment_init_id) {
        detail.show_payment = false;
      }
    });
  }

  async makeOfflinePayment(payment_detail: any) {
    var form_data: any = {};
    form_data.purpose = payment_detail.fee_header_type; form_data.transaction_header_id = payment_detail.payment_init_id;
    form_data.offline_ref_no = this.add_transaction_form.value.reference_no; form_data.ref_type = this.add_transaction_form.value.id_type;
    form_data.description = this.add_transaction_form.value.description; form_data.roll_no = this.search_rno.toUpperCase();
    form_data.version = payment_detail.version;
    const res = await this.restService.offlineAdjustment(form_data);
    if (!res) {
      alert("Failed to create offline adjustment");
      this.notificationService.setNotifcation({ message: 'Failed to complete online adjustment', type: 'Failed' });
    }
    else {
      alert("Created offline adjustment successfully");
      this.notificationService.setNotifcation({ message: 'Updated online adjustment for the transaction successfully', type: 'Success' });
    }
    this.openCloseOfflinePayment(payment_detail);
    this.getStudentPaymentData();
  }

  isInvalidTransaction(formControl: string): boolean {
    return this.add_transaction_form.touched && this.add_transaction_form.get(formControl)?.errors !== null;
  }

  canDisableOfflinePayment(): boolean {
    return this.add_transaction_form.status !== 'VALID';
  }

}
interface VariableSettingInterface {
  id: any, name: string
}

interface ComponentRenderInterface {
  roll_no: string,
  mode: string
}