import { Component, OnInit } from '@angular/core';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private restService: AdminservicesService) { }

  ngOnInit(): void {
  }

  navigateToLogin() {
    location.href = this.restService.getLoginUrl();
  }
}
