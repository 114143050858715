<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

            <div class="d-flex my-xl-auto right-content">
                <app-modal [render_data]="bulk_skills_data" (csvData)="bulkUploadSkillsData($event)">
                </app-modal>
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add Skillset</button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Non-Academic Attainment Skillsets</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 40%;">Skill Name</th>
                                        <th style="width: 20%;">Max Point</th>
                                        <th style="width: 20%">Order</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let skill of skillset_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{skill.skillset_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{skill.skillset_max_point}}
                                            </td>
                                            <td class="uppercase">
                                              {{skill.order}}
                                          </td>
                                            <td>
                                                <button class="btn btn-sm btn-info mg-r-10"
                                                    (click)="onEditClick(skill)">
                                                    <fa-icon [icon]="fonts.faPencilAlt" data-toggle="tooltip"
                                                        title="Edit Skillset"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!skillset_list_loading && skillset_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="skillset_list_loading && skillset_list.length==0">
                                        <tr>
                                            <th colspan="4" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="skillset_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Non-Academic attainment Skillsets</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="skillset_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter SkillSet
                                                Name</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Skillset Name" formControlName="skillset_name">
                                            <div *ngIf="isInvalid('skillset_name')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Skillset Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Max
                                                Point</label>
                                            <input class="form-control" required="" type="number"
                                                formControlName="skillset_max_point">
                                            <div *ngIf="isInvalid('skillset_max_point')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Skillset Max Point is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                      <div class="form-group">
                                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Order</label>
                                          <input class="form-control" required="" type="number"
                                              formControlName="order">
                                          <div *ngIf="isInvalid('order')">
                                              <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                  <li class="parsley-required">Order is required.</li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>

                                    <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Applicable
                                                Enrolled Years</label>
                                            <ng-multiselect-dropdown [placeholder]="'Select Applicable Enrolled Years'"
                                                [settings]="dropdownSettings"
                                                [data]="commonEnums.getNEnrolledYears(5,5)"
                                                formControlName="applicable_enrolled_years">
                                            </ng-multiselect-dropdown>

                                        </div>
                                    </div> -->
                                </div>

                                <div class="row mg-b-10">
                                    <div class="col-md-12">
                                        <textarea rows="5" cols="10" placeholder="Enter Skills Reference"
                                            class="form-control" formControlName="reference_skills"></textarea>
                                    </div>
                                </div>


                                <div class="table-responsive" formArrayName="skills">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th style="width: 10%;">S.No</th>
                                            <th style="width: 50;">Skill Name</th>
                                            <th style="width: 25%;">Max Point</th>
                                            <th style="width: 15%;">Action</th>
                                        </tr>
                                        <ng-container *ngFor="let skill of skills; let i=index;" [formGroupName]="i">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <textarea class="form-control" formControlName="skill_name"
                                                        placeholder="Enter Skill Name/Description" rows="5" cols="5">
                                                    </textarea>
                                                </td>
                                                <td>
                                                    <input class="form-control" required="" type="number"
                                                        formControlName="max_point">
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-danger"
                                                        *ngIf="!delete_skill_loading[i]"
                                                        (click)="deleteSkill(i)">Delete</button>
                                                    <button class="btn btn-sm btn-danger"
                                                        *ngIf="delete_skill_loading[i]" disabled>Deleting...</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td colspan="5" class="text-center">
                                                <button type="button" class="btn btn-secondary" (click)="addSkill()">
                                                    Add Skill
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.skillset_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.skillset_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.skillset_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.skillset_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.skillset_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.skillset_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.skillset_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.skillset_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.skillset_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.skillset_form.value._id && delete_loading" disabled>Deleting...</button>
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>
