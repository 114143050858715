<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>

    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Course Scheme</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info mg-r-10" (click)="openFileDialog()">Bulk Upload
                        Syllabus as
                        Word
                        File</button>
                    <input type="file" accept=".docx" (change)="bulkUploadWordFile($event)" #fileImportInput
                        id="file-upload" placeholder="Bulk Upload Syllabus as Word File" hidden>
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Course</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Courses</h4>
                            <!-- <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="course_list.length>0" (click)="downloadAsCSV()"> -->
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Courses</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="offered_by_programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="category.programme_type_id">{{category.name}}</option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Revision
                                            year</label>
                                        <select formControlName="revision_year" class="form-control">
                                            <option value="0">Select Revision year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="offered_by_finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programme</label>
                                        <select formControlName="offered_by_programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" formControlName="course_code" class="form-control"
                                            placeholder="Search with Course Code">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th>Year</th>
                                        <th>Part</th>
                                        <th>Course Type</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let course of course_data_list;let i=index;trackBy:fromDataResolver.identify;">


                                        <tr>
                                            <td scope="row">
                                                {{i+1}}
                                            </td>
                                            <td class="text-uppercase">{{course.course_code}}</td>
                                            <td class="text-uppercase">{{course.course_name}}</td>
                                            <td class="text-uppercase">{{course.revision_year}}</td>
                                            <td class="text-uppercase">{{course.part}}</td>
                                            <td class="text-uppercase">{{course.course_type}}</td>
                                            <td>
                                                <div class="pr-1 mb-3 mb-xl-0">
                                                    <button type="button" class="btn btn-sm btn-info mr-2"
                                                        title="Edit Course" (click)="onEditClick(course)">
                                                        <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                    </ng-container>
                                    <ng-container *ngIf="!course_list_loading && course_data_list?.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_data_list?.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="course_form && course">
                    <div class="modal-header">
                        <h6 class="modal-title" *ngIf="!course._id">Add New Course</h6>
                        <h6 class="modal-title" *ngIf="course._id">Update Course</h6>

                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="course_form">
                                <div class="row">
                                    <ng-container>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Offering
                                                    Programme Type</label>
                                                <select formControlName="offered_by_programme_type_id"
                                                    class="form-control"
                                                    (change)="onFilterSelectChange('programme_type_id',$event)">
                                                    <option value="">Select Programme Type</option>
                                                    <option
                                                        *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="category.programme_type_id">{{category.name}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Offering
                                                    Finance Type</label>
                                                <select formControlName="offered_by_finance_type" class="form-control"
                                                    (change)="onFilterSelectChange('finance_type',$event)">
                                                    <option value="">Select Finance Type</option>
                                                    <option
                                                        *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Offering
                                                    Programme</label>
                                                <select formControlName="offered_by_programme_id" class="form-control"
                                                    (change)="onFilterSelectChange('programme_id',$event)">
                                                    <option value="">All Programmes</option>
                                                    <option
                                                        *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Part</label>
                                                <select formControlName="part" class="form-control"
                                                    (change)="onFilterSelectChange('part',$event)">
                                                    <option value="">All Parts</option>
                                                    <option
                                                        *ngFor="let part of this.parts;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="part.id">{{part.text}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!--Allowed programme Multiselect-->
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Allowed Programme
                                                    Name(s)</label>
                                                <kendo-multiselect [data]="department_list" valueField="programme_id"
                                                    textField="programme_display_name"
                                                    formControlName="allowed_programme_ids"
                                                    placeholder="Select Programme(s)">
                                                </kendo-multiselect>
                                                <!-- <ng-multiselect-dropdown [placeholder]="'Choose Allowed Programmes'"
                                                    [settings]="dropdownSettings" [data]="department_list"
                                                    formControlName="allowed_programme_ids"
                                                    (onSelect)="onItemSelect($event)"
                                                    (onSelectAll)="onSelectAll($event)">
                                                </ng-multiselect-dropdown> -->
                                            </div>
                                        </div>

                                        <div class="col-xl-6"
                                            *ngIf="this.part_course_types && this.part_course_types?.length>0">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Course Type</label>
                                                <select formControlName="course_type" class="form-control">
                                                    <option value="">All Course Type</option>
                                                    <option
                                                        *ngFor="let course_type of this.part_course_types;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="course_type.id">{{course_type.text}}</option>
                                                </select>
                                            </div>
                                        </div>


                                        <!--Study by programme type and study by programme id dropdowns-->
                                        <!-- <div class="col-xl-6"
                                            *ngIf="course_form.value.course_type=='ALLIED' || course_form.value.course_type=='ALLIED_PRACTICAL'">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Student's
                                                    Programme Type</label>
                                                <select formControlName="study_by_programme_type_id"
                                                    class="form-control" (change)="getStudentProgrammeDataList()">
                                                    <option value="">Select Programme Type</option>
                                                    <option
                                                        *ngFor="let category of category_list;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="category.programme_type_id">{{category.name}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>

                                        <div class="col-xl-6"
                                            *ngIf="course_form.value.course_type=='ALLIED' || course_form.value.course_type=='ALLIED_PRACTICAL'">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Student's
                                                    Programme</label>
                                                <select formControlName="study_by_programme_id" class="form-control">
                                                    <option value="">All Programmes</option>
                                                    <option
                                                        *ngFor="let programme of student_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="programme.programme_id">{{programme.programme_name}}
                                                        {{programme.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                                </select>
                                            </div>
                                        </div> -->


                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                    Course Position</label>
                                                <select formControlName="position_no" class="form-control">
                                                    <option value="0">Select Course Position</option>
                                                    <option
                                                        *ngFor="let course_position of this.commonEnums.getNumbers(50);let i=index;trackBy:fromDataResolver.identify;"
                                                        [value]="course_position">
                                                        {{course_position}} -
                                                        {{this.commonEnums.roman_letters[course_position]}}</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                    Revision Year</label>
                                                <select formControlName="revision_year" class="form-control">
                                                    <option value="0">Choose Revision Year</option>
                                                    <option
                                                        *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                        [ngValue]="year">{{year}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </ng-container>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                                Code</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Course Code" formControlName="course_code">
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                                Name</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Course Name" formControlName="course_name">
                                        </div>
                                    </div>

                                    <!--New fields-->
                                    <!-- <div class="col-xl-6" *ngIf="course_form.value.part=='PART_IV'">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Dependency Course
                                                Code</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Dependency Course Code"
                                                formControlName="dependency_course_code">
                                        </div>
                                    </div> -->

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter No. of
                                                Exam hours </label>
                                            <input class="form-control" required="" type="number"
                                                placeholder="Enter No. of contact hours"
                                                formControlName="exam_duration">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Internal
                                                Marks</label>
                                            <input class="form-control" required="" type="number"
                                                (change)="calculateTotalExternalMark()"
                                                placeholder="Enter Internal Marks" formControlName="max_internal_mark">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter External
                                                Marks</label>
                                            <input class="form-control" required="" type="number"
                                                (change)="calculateTotalExternalMark()"
                                                placeholder="Enter External Marks" formControlName="max_external_mark">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Total
                                                External
                                                Marks</label>
                                            <input class="form-control" required="" type="number"
                                                placeholder="Enter Total External Marks"
                                                formControlName="total_external_mark">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Weekly
                                                Lecture Hours</label>
                                            <input class="form-control" required="" type="number"
                                                placeholder="Enter Weekly Lecture Hours"
                                                formControlName="weekly_lecture_hours">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Weekly
                                                Tutorial Hours</label>
                                            <input class="form-control" required="" type="number"
                                                placeholder="Enter Weekly Tutorial Hours"
                                                formControlName="weekly_tutorial_hours">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Weekly
                                                Practical Hours</label>
                                            <input class="form-control" required="" type="number"
                                                placeholder="Enter Weekly practical hours"
                                                formControlName="weekly_practical_hours">
                                        </div>
                                    </div>

                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Credits</label>
                                            <input class="form-control" required="" type="number"
                                                placeholder="Enter Credits" formControlName="credits">
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="exam_conducted" class="mg-r-5">Is
                                            examination held yearly?
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="is_common_course" class="mg-r-5">Is
                                            Common Course?
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <input type="checkbox" formControlName="approved" class="mg-r-5">Is
                                            Approved?
                                        </div>
                                    </div>
                                </div>

                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                        *ngIf="!course_save_loading">Save</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled
                                        *ngIf="course_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                    <ng-container *ngIf="course._id">
                                        <button class="btn btn-danger mg-r-20" (click)="onDeleteClick()"
                                            *ngIf="!course_delete_loading">Delete</button>
                                        <button class="btn btn-danger mg-r-20" type="button" disabled
                                            *ngIf="course_delete_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </ng-container>

                                    <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                        type="button">Close</button>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>