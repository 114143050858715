<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Repeat Semester Students</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Student</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Repeat Semester Students</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Repeat Semester Students</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type *</option>
                                            <option
                                                *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type *</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Programmes *</label>
                                        <select formControlName="programme_id" class="form-control"
                                            (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option
                                                *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2"
                                    *ngIf="this.programme_section_list && this.programme_section_list.length>1">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            To Section</label>
                                        <select formControlName="to_section_id" class="form-control"
                                            (change)="onFilterSelectChange('to_section_id',$event)">
                                            <option value="">All Section</option>
                                            <option
                                                *ngFor="let programme_section of programme_section_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme_section._id">{{programme_section.section_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select To Enrollment
                                            Year</label>
                                        <select formControlName="to_enrolled_year" class="form-control"
                                            (change)="onFilterSelectChange('to_enrolled_year',$event)">
                                            <option value=''>Select Enrollment Year *</option>
                                            <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20)"
                                                [ngValue]="enrolled_year">
                                                {{enrolled_year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Repeating semester</label>
                                        <select formControlName="repeating_semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!student_list_loading" (click)="searchFormDataList()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Programme Name</th>
                                        <th>Section</th>
                                        <th>Enrolled Year</th>
                                        <th>To Enrolled Year</th>
                                        <th>Semester</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="students_list.length>0">
                                        <ng-container
                                            *ngFor="let student of students_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td class="text-uppercase">{{student.roll_no}}</td>
                                                <td class="text-uppercase">{{student.given_name}}
                                                    {{student.middle_name}}</td>
                                                <td class="text-uppercase">{{student?.programme_name}}</td>
                                                <td class="text-uppercase">{{student.to_section}}</td>
                                                <td class="text-lowercase">{{student.enrolled_year}}</td>
                                                <td class="text-lowercase">{{student.to_enrolled_year}}</td>
                                                <td>{{student.repeating_semester}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" class="btn btn-sm btn-info mr-2"
                                                            title="Edit" (click)="onEditClick(student)">
                                                            <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>

        <!-- Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_form && student">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Repeat Semester</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="student_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programme
                                                Type</label>
                                            <select formControlName="programme_type_id" class="form-control" [attr.disabled]="modal_type_update == true ? true : undefined"
                                                (change)="onFilterStudentSelectChange('programme_type_id',$event)">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme_type.programme_type_id">{{programme_type.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                                Type</label>
                                            <select formControlName="finance_type" class="form-control" [attr.disabled]="modal_type_update == true ? true : undefined"
                                                (change)="onFilterStudentSelectChange('finance_type',$event)">
                                                <option value="">Select Finance Type</option>
                                                <option
                                                    *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Programmes</label>
                                            <select formControlName="programme_id" class="form-control" [attr.disabled]="modal_type_update == true ? true : undefined"
                                                (change)="onFilterStudentSelectChange('programme_id',$event)">
                                                <option value="">All Programmes</option>
                                                <option
                                                    *ngFor="let programe of student_programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('programme_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Programme Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6"
                                        *ngIf="this.student_programme_section_list && this.student_programme_section_list.length>1">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select to
                                                Section</label>
                                            <select formControlName="to_section_id" class="form-control"
                                                (change)="onFilterStudentSelectChange('to_section_id',$event)">
                                                <option value="">All Section</option>
                                                <option
                                                    *ngFor="let programme_section of student_programme_section_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="programme_section._id">{{programme_section.section_name}}
                                                </option>
                                            </select>
                                            <div *ngIf="isInvalid('to_section_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Section Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select To
                                                Enrollment
                                                Year</label>
                                            <select formControlName="to_enrolled_year" class="form-control"
                                                (change)="onFilterSelectChange('to_enrolled_year',$event)">
                                                <option value="0">Select Enrolled Year</option>
                                                <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20)"
                                                    [ngValue]="enrolled_year">
                                                    {{enrolled_year}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('to_enrolled_year')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Enrollment Year in which student needs
                                                        to be placed is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Repeating semester</label>
                                            <select formControlName="repeating_semester" class="form-control" [attr.disabled]="modal_type_update == true ? true : undefined">
                                                <option value="">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('repeating_semester')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Student Repeating Semester is required.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Roll No.</label>
                                            <input type="text" class="form-control" formControlName="roll_no" [attr.disabled]="modal_type_update == true ? true : undefined"
                                                placeholder="Enter Roll No.">
                                            <div *ngIf="isInvalid('roll_no')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Student Roll No. is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-primary mg-r-20" (click)="onSave()"
                                        [disabled]="canDisable()">
                                        <ng-container *ngIf="!this.student_form.value._id && !this.save_loading">
                                            Save
                                        </ng-container>
                                        <ng-container *ngIf="!this.student_form.value._id && this.save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="this.student_form.value._id && !this.save_loading">
                                            Update
                                        </ng-container>
                                        <ng-container *ngIf="this.student_form.value._id && this.save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()"
                                        [disabled]="canDisable()">
                                        <ng-container *ngIf="!this.student_form.value._id && !this.save_loading">
                                            Save & Close
                                        </ng-container>
                                        <ng-container *ngIf="!this.student_form.value._id && this.save_loading">
                                            Saving...
                                        </ng-container>
                                        <ng-container *ngIf="this.student_form.value._id && !this.save_loading">
                                            Update & Close
                                        </ng-container>
                                        <ng-container *ngIf="this.student_form.value._id && this.save_loading">
                                            Updating...
                                        </ng-container>
                                    </button>
                                    <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                        *ngIf="this.student_form.value._id && !delete_loading"
                                        [disabled]="canDisable()">Delete</button>
                                    <button class="btn btn-danger mg-r-20"
                                        *ngIf="this.student_form.value._id && delete_loading"
                                        disabled>Deleting...</button>
                                    <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                    <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
