<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Template Filter</h2>

                    <p class="mg-b-0">{{ template_name }} filter</p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                </div>
            </div>
        </div>
        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">



                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Template filter</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">'{{ template_name }}' data using programme_wise / section_wise filter</p>
                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="component_search_form">
                            <form [formGroup]="component_search_form">
                                <div class="row row-sm">

                                  <div class="col-lg-12">
                                    <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Programme</label>
                                      <ng-multiselect-dropdown [placeholder]="'Select Programme(s)'"
                                        [settings]="programme_dropdown_settings" [data]="programme_filter_list"
                                        formControlName="programme_ids">
                                      </ng-multiselect-dropdown>
                                    </div>
                                  </div>

                                  <div class="col-lg-12">
                                    <div class="form-group">
                                      <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                        Sections</label>
                                      <ng-multiselect-dropdown [placeholder]="'Select Section(s)'"
                                        [settings]="section_dropdown_settings" [data]="section_filter_list"
                                        formControlName="template_section_ids">
                                      </ng-multiselect-dropdown>
                                    </div>
                                  </div>

                                  <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Format</label>
                                        <select formControlName="format_type" class="form-control">
                                          <option value="">Select Format Type</option>
                                            <option
                                                *ngFor="let format of format_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="format.value">{{format.id}}</option>
                                        </select>
                                    </div>
                                  </div>

                                    <div class="col-lg-2">
                                        <div class="form-group">

                                            <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                                title="Search" *ngIf="!template_filter_loading" (click)="onSearchClick()">
                                                Search
                                            </button>
                                            <button class="mg-t-30 btn btn-info mr-2 search-button" type="button"
                                                disabled *ngIf="template_filter_loading">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                    </div>


                </div>
            </div>

            <div class="col-xl-12" *ngIf="isObjectNotEmpty(template_filter_data)">
              <div class="card mg-b-20">
                <div class="card-header pb-0">
                  <div class="d-flex justify-content-between">
                      <h4 class="card-title mg-b-0">'{{ template_name | uppercase }}' data using {{ template_filter_data.format_type }}</h4>
                  </div>

                  <!-- <div class="d-flex right-content mg-b-5" style="float: right;margin-right: 1%;">
                    <button type="button" class="btn btn-info btn-md" (click)="downloadPDF(template_filter_data.format_type)">Print</button>
                  </div> -->
                </div>
                <div class="card-body">

                  <!-- Programme wise data -->
                  <ng-container *ngIf="template_filter_data.programme_wise_data.length > 0">
                    <form class="form-horizontal">
                      <div *ngFor="let pgm_data of template_filter_data.programme_wise_data;let pgm_index = index;">
                        <div class="row">
                          <div class="col-md-12">
                                <h4 class="text-center">{{ pgm_data.programme_name }}
                                  <ng-container *ngIf="pgm_data.finance_type == 'self_finance'">(SF)</ng-container>
                                  <ng-container *ngIf="pgm_data.finance_type == 'regular'">(R)</ng-container>
                                </h4>
                                <ng-container *ngFor="let section_data of pgm_data.section_mapping_data; let section_index = index;">
                                  <div class="container">

                                    <div class="row">
                                      <div class="col-md-9">
                                          <ng-container
                                                *ngIf="section_data.format === 'Group'; else notNA">
                                                Group
                                            </ng-container>
                                            <ng-template #notNA>
                                                Section
                                            </ng-template>
                                      </div>
                                    </div>

                                    <!-- Title -->
                                    <div class="form-group" *ngIf="section_data.title">
                                      <p><b>Title: {{section_data.title}}</b></p>
                                    </div>
                                    <!-- Description -->
                                    <div class="form-group" *ngIf="section_data?.description">
                                        <p>Description: {{section_data?.description}}</p>
                                    </div>

                                    <!-- File link -->
                                    <ng-container *ngIf="section_data?.file_link">
                                      <a href=`{{ section_data?.file_link }}`>File link</a>
                                    </ng-container>


                                    <!-- List -->
                                    <div *ngIf="section_data.format === 'List'">
                                      <div>
                                          <h6>List Heading: {{section_data.list_data.header}}</h6>
                                          <p>Contents: {{section_data.list_data.contents}}</p>
                                      </div>
                                    </div>


                                    <!-- Table -->

                                  <ng-container
                                      *ngIf="section_data.format === 'Table' && section_data.table_data.length > 0">
                                      <div class="table-responsive">
                                          <table class="table table-bordered">
                                              <tr>
                                                <th *ngFor="let tr of section_data.table_data[0]">
                                                  {{ tr.column_name }}
                                                </th>
                                              </tr>
                                              <tbody>
                                                <tr *ngFor="let table_data of section_data.table_data; let tableIndex=index;">
                                                  <td *ngFor="let table_td of table_data;let table_data_index = index;">
                                                    <ng-container *ngIf="table_td.column_format == 'File'"><a href=`{{ table_td.column_data }}`>{{ table_data.column_name }} file</a></ng-container>
                                                    <ng-container *ngIf="table_td.column_format != 'File'">{{ table_td.column_data }}</ng-container>
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </ng-container>


                                </div>
                              </ng-container>
                          </div>
                        </div>
                        <hr style="border:none;height:1px;background-color: black;">
                      </div>
                    </form>
                  </ng-container>

                  <!-- Section wise data -->
                  <ng-container *ngIf="template_filter_data.section_wise_data.length > 0">
                    <form class="form-horizontal">
                      <div *ngFor="let section_data of template_filter_data.section_wise_data;let section_index = index;">
                        <div class="row">
                          <div class="col-md-12">
                                <ng-container
                                *ngIf="section_data.format === 'Group'; else notNA">
                                Group
                              </ng-container>
                              <ng-template #notNA>
                                Section
                              </ng-template>
                              <div class="text-center">
                                  <!-- Title -->
                                  <div class="form-group" *ngIf="section_data.title">
                                    <p><b>Title: {{section_data.title}}</b></p>
                                  </div>
                                  <!-- Description -->
                                  <div class="form-group" *ngIf="section_data?.description">
                                      <p>Description: {{section_data?.description}}</p>
                                  </div>

                                  <!-- File link -->
                                  <ng-container *ngIf="section_data?.file_link">
                                    <a href=`{{ section_data?.file_link }}`>File link</a>
                                  </ng-container>
                              </div>
                                <ng-container *ngFor="let programme_data of section_data.programme_mapping_data; let programme_index = index;">
                                  <div class="container">

                                    <!-- Programme details -->
                                    <div class="form-group">
                                      <p><b>{{ programme_data.programme_name }}
                                      <ng-container *ngIf="programme_data.finance_type == 'self_finance'">(SF)</ng-container>
                                      <ng-container *ngIf="programme_data.finance_type == 'regular'">(R)</ng-container>
                                      </b></p>
                                    </div>



                                    <!-- List -->
                                    <div *ngIf="section_data.format === 'List'">
                                      <div>
                                          <h6>List Heading: {{programme_data.list_data.header}}</h6>
                                          <p>Contents: {{programme_data.list_data.contents}}</p>
                                      </div>
                                    </div>


                                    <!-- Table -->

                                  <ng-container
                                      *ngIf="section_data.format === 'Table' && programme_data.table_data.length > 0">
                                      <div class="table-responsive">
                                          <table class="table table-bordered">
                                              <tr>
                                                <th *ngFor="let tr of programme_data.table_data[0]">
                                                  {{ tr.column_name }}
                                                </th>
                                              </tr>
                                              <tbody>
                                                <tr *ngFor="let table_data of programme_data.table_data; let tableIndex=index;">
                                                  <td *ngFor="let table_td of table_data;let table_data_index = index;">
                                                    <ng-container *ngIf="table_td.column_format == 'File'"><a href=`{{ table_td.column_data }}`>{{ table_data.column_name }} file</a></ng-container>
                                                    <ng-container *ngIf="table_td.column_format != 'File'">{{ table_td.column_data }}</ng-container>
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </ng-container>


                                </div>
                              </ng-container>
                          </div>
                        </div>
                        <hr style="border:none;height:1px;background-color: black;">
                      </div>
                    </form>
                  </ng-container>

                </div>
              </div>
            </div>


        </div>
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>
