import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ICOPOMap } from 'src/app/services/rest/entity/ICOPOMap';
import { ICourses } from 'src/app/services/rest/entity/ICourses';
import { ICoursesBooks } from 'src/app/services/rest/entity/ICoursesBooks';
import { ICoursesDetails } from 'src/app/services/rest/entity/ICoursesDetails';
import { ICoursesTutorials } from 'src/app/services/rest/entity/ICoursesTutorials';
import { ICoursesUnits } from 'src/app/services/rest/entity/ICoursesUnits';


@Component({
  selector: 'app-manage-courses',
  templateUrl: './manage-courses.component.html',
  styleUrls: ['./manage-courses.component.scss']
})
export class ManageCoursesComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;



  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;


  co_count = 5;
  po_count = 7;

  parts: any[] = [];
  part_course_types: any[] | undefined = [];

  // Core datas

  course_search_form: any;
  course_data_list: any[] = [];

  course_list_loading = false;

  // Core data creation

  course_form: any;
  course: ICourses | undefined = undefined;
  course_save_loading = false;
  course_delete_loading = false;

  course_co_po_form: any;

  courseware_form: any;

  po_total: any[] = [];
  // CRUD
  state = '';

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();
  }




  /// master data

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      regulation_year: [''],

    });

  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {




      if (this.state === 'ADD') {
        this.getProgrammeDataList(this.course_form);
        if (id === 'programme_type_id' && this.course_form.value.programme_type_id) {
          this.parts = this.commonEnums.getParts(this.course_form.value.programme_type_id);
        }
      } else {
        this.getProgrammeDataList(this.programme_search_form);
        if (id === 'programme_type_id' && this.programme_search_form.value.programme_type_id) {
          this.parts = this.commonEnums.getParts(this.programme_search_form.value.programme_type_id);
        }
      }
    } else if (id === 'part') {
      if (this.state === 'ADD') {
        if (this.course_form.value.programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(this.course_form.value.programme_type_id,
            this.course_form.value.part);
        }
      } else {
        if (this.course_form.value.programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(
            this.programme_search_form.value.programme_type_id, this.programme_search_form.value.part);

        }
      }
    }



  }



  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }



  async onSearchClick(): Promise<void> {
    this.searchCourse();
  }

  async searchCourse(): Promise<void> {
    try {

      if (!this.programme_search_form.value.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!this.programme_search_form.value.regulation_year) {
        alert('Please select regulation year');
        return;
      }
      this.course_list_loading = true;

      this.course_data_list = [];
      const search_form_query: any = {
        programme_id: this.programme_search_form.value.programme_id,
        regulation_year: this.programme_search_form.value.regulation_year,
      };
      const service_response = await this.restService.searchCouseList(search_form_query);
      if (service_response && service_response.success) {
        this.course_data_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_list_loading = false;
    }

  }

  downloadAsCSV(): void {

  }


  // Core data


  buildForm(): void {

    let programme_search_form_value: any = {};
    if (this.programme_search_form) {
      programme_search_form_value = this.programme_search_form.value;
    }

    if (!this.course) {
      this.course = {} as ICourses;
      this.course._id = '';
      this.course.finance_type = programme_search_form_value.finance_type || '';
      this.course.programme_type_id = programme_search_form_value.programme_type_id || '';
      this.course.programme_id = programme_search_form_value.programme_id || '';

      this.course.course_code = '';
      this.course.regulation_year = new Date().getFullYear();
      this.course.course_name = '';
      this.course.course_type = '';
      this.course.part = '';

    }
    if (this.course.programme_type_id) {
      this.parts = this.commonEnums.getParts(this.course.programme_type_id);
    }

    this.course_form = this.fb.group({
      _id: [this.course._id],
      finance_type: [this.course.finance_type],
      programme_type_id: [this.course.programme_type_id],


      programme_id: [this.course.programme_id],
      course_code: [this.course.course_code],
      regulation_year: [this.course.regulation_year],
      course_name: [this.course.course_name],
      course_type: [this.course.course_type],
      part: [this.course.part],


    });
  }

  buildCOPOForm(course: ICourses): void {


    if (!course.co_po_map) {
      course.co_po_map = [];
    }



    for (let index = 0; index < this.co_count; index++) {
      const co_element = index + 1;
      const co = course.co_po_map.filter(c => c.co_id === co_element);
      if (co && co.length > 0) {


        for (let po_index = 0; po_index < this.po_count; po_index++) {
          const po_element = po_index + 1;
          if (!co.find(c => c.po_id === po_element)) {
            const co_po_map: ICOPOMap = {} as ICOPOMap;
            co_po_map.course_id = course._id;
            co_po_map.co_id = co_element;
            co_po_map.po_id = po_element;
            co_po_map.score = 0;
            course.co_po_map.push(co_po_map);
          }
        }


      } else {
        for (let po_index = 0; po_index < this.po_count; po_index++) {
          const po_element = po_index + 1;
          const co_po_map: ICOPOMap = {} as ICOPOMap;
          co_po_map.course_id = course._id;
          co_po_map.co_id = co_element;
          co_po_map.po_id = po_element;
          co_po_map.score = 0;
          course.co_po_map.push(co_po_map);
        }
      }
    }

    const map: any[] = [

    ];

    for (let index = 0; index < this.co_count; index++) {
      const co_element = index + 1;
      const co = course.co_po_map.filter(c => c.co_id === co_element).sort((a, b) => a.po_id - b.po_id);

      map.push({
        co_id: co_element,
        do_data: co
      });
    }
    console.log(JSON.stringify(map, null, 4));

    this.course_co_po_form = this.fb.group({
      _id: [course._id],
      bulk_update: [''],
      co_po_map: this.fb.array(this.addCO(map))
    });

    this.course = course;
    this.calculatePOWeightage();
  }

  valueChanged(data: any): void {
    this.calculatePOWeightage();
  }

  calculatePOWeightage(): void {
    this.po_total = [];
    const course_data = this.course_co_po_form.value;

    if (course_data.co_po_map && course_data.co_po_map.length > 0) {
      const co_po_map_to_save: any[] = [];

      for (const co_po_map of course_data.co_po_map) {
        co_po_map_to_save.push(...co_po_map.do_data);
      }

      for (let index = 0; index < this.co_count; index++) {
        const co_element = index + 1;
        if (this.course) {
          const fil = co_po_map_to_save.filter(c => c.co_id === co_element);
          for (const fill of fil) {
            const tp = this.po_total.find(c => c.id === fill.po_id);

            if (tp) {
              tp.score = tp.score + parseInt(fill.score, 0);
            } else {
              this.po_total.push({ id: fill.po_id, score: parseInt(fill.score, 0) });

            }
          }

        }
      }
      console.log(this.po_total);
    }





  }

  addCO(co: any[]): any[] {
    const f: any[] = [];
    for (const co_d of co) {
      f.push(this.fb.group({
        co_id: co_d.co_id,
        do_data: this.fb.array(this.addPO(co_d.co_id, co_d.do_data))
      }));
    }
    return f;
  }

  addPO(co_id: number, po: any[]): any[] {
    const f: any[] = [];
    for (const po_d of po) {
      f.push(this.fb.group({
        co_id,
        po_id: po_d.po_id,
        score: po_d.score
      }));
    }
    return f;
  }


  buildCoursewareForm(course: ICourses): void {
    if (!course.course_details) {
      course.course_details = {} as ICoursesDetails;
    }
    this.courseware_form = this.fb.group({
      course_id: [course._id],
      course_outcome: [course.course_details.course_outcome],
      course_units: this.fb.array(this.addUnits(course._id, course.course_details.course_units)),
      practicals: [course.course_details.practicals],
      text_book: [course.course_details.text_book],
      reference_book: [course.course_details.reference_book],
      tutorials: this.fb.array(this.addTutorials(course._id, course.course_details.tutorials)),
      web_reference: [course.course_details.web_reference]
    });
  }

  addUnits(course_id: string, units: ICoursesUnits[]): any[] {
    const f: any[] = [];
    if (!units || units.length === 0) {
      units = [];
      for (let index = 0; index < 5; index++) {
        const unit = {} as ICoursesUnits;
        unit.title = '';
        unit.course_topics_string = '';
        units.push(unit);
      }
    }
    for (const unit of units) {
      f.push(this.fb.group({
        course_id: [course_id],
        title: [unit.title],
        description: [unit.description],
        order: [unit.order],
        course_topics_string: [unit.course_topics_string]
      }));
    }
    return f;
  }

  addTextBooks(course_id: string, units: ICoursesBooks[]): any[] {
    const f: any[] = [];
    if (!units || units.length === 0) {
      units = [];
      for (let index = 0; index < 5; index++) {
        const unit = {} as ICoursesBooks;
        unit.book_name = '';
        unit.author_name = '';
        unit.version = '';
        units.push(unit);
      }
    }
    for (const unit of units) {
      f.push(this.fb.group({
        course_id: [course_id],
        book_name: [unit.book_name],
        author_name: [unit.author_name],
        version: [unit.version]
      }));
    }
    return f;
  }

  addTutorials(course_id: string, units: ICoursesTutorials[]): any[] {
    const f: any[] = [];
    if (!units || units.length === 0) {
      units = [];
      for (let index = 0; index < 5; index++) {
        const unit = {} as ICoursesTutorials;
        unit.topic = '';
        unit.activity = '';
        unit.unit_covered = '';
        units.push(unit);
      }
    }
    for (const unit of units) {
      f.push(this.fb.group({
        course_id: [course_id],
        topic: [unit.topic],
        activity: [unit.activity],
        unit_covered: [unit.unit_covered]
      }));
    }
    return f;
  }

  async onAddClick(): Promise<void> {
    this.state = 'ADD';
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(course: any): Promise<void> {
    this.state = 'ADD';
    this.course = course;
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCOPOMapClick(course: any): Promise<void> {
    this.state = 'COPO';
    this.course = course;
    try {
      const course_response = await this.restService.findCourse(course.course_code);
      if (course_response.success) {
        this.po_total = [];
        for (let index = 0; index < this.po_count; index++) {
          const po_element = index + 1;
          this.po_total.push({ id: po_element, score: 0 });
        }

        this.buildCOPOForm(course_response.data);
        JQueryHelper.openModal('#modal-copomap', { keyboard: false, backdrop: 'static' });
      }

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }

  async onCoursewareClick(course: any): Promise<void> {
    this.state = 'CW';
    this.course = course;
    try {
      const course_response = await this.restService.findCourse(course.course_code);
      if (course_response.success) {
        this.buildCoursewareForm(course_response.data);
        JQueryHelper.openModal('#modal-courseware', { keyboard: false, backdrop: 'static' });
      }

    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }

  async onSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }


      this.course_save_loading = true;

      const course_data = this.course_form.value;

      if (!course_data.course_code) {
        alert('Please enter course code');
        return;
      }
      if (!course_data.course_name) {
        alert('Please enter course name');
        return;
      }
      if (!course_data.programme_id) {
        alert('Please select programme');
        return;
      }
      if (!course_data.part) {
        alert('Please select part');
        return;
      }
      // TODO validate on course_type
      // if (!course_data.course_type) {
      //   alert('Please select course type');
      //   return;
      // }

      if (!course_data.regulation_year) {
        alert('Please enter year');
        return;
      }

      let save_respose;
      if (course_data._id) {
        // update record
        save_respose = await this.restService.updateCourse(course_data);
      } else {
        // create record
        save_respose = await this.restService.createCourse(course_data);
      }

      if (save_respose.success) {
        alert('Saved Successfully');
        await this.searchCourse();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }

  async onDeleteClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
        return;
      }

      this.course_delete_loading = true;

      const categgory_data = this.course_form.value;
      if (!categgory_data.course_code) {
        alert('Please enter course');
      }


      const save_respose = await this.restService.deleteCourse(categgory_data.course_code);


      if (save_respose.success) {
        alert('Deleted Successfully');
        await this.searchCourse();
        this.onCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_delete_loading = false;
    }
  }



  resetForm(): void {
    this.course_form = undefined;
    this.course = undefined;
    this.state = '';
  }
  async onCloseClick(): Promise<void> {
    this.state = '';
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }

  resetCOPOForm(): void {
    this.course_co_po_form = undefined;
    this.state = '';
  }
  async onCOPOCloseClick(): Promise<void> {
    this.state = '';
    this.resetCOPOForm();
    JQueryHelper.closeModal('#modal-copomap');
  }

  resetCoursewareForm(): void {
    this.courseware_form = undefined;
    this.state = '';
  }
  async onCoursewareCloseClick(): Promise<void> {
    this.state = '';
    this.resetCoursewareForm();
    JQueryHelper.closeModal('#modal-courseware');
  }


  async onCOPOSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      if (!this.course) {
        return;
      }

      this.calculatePOWeightage();
      this.course_save_loading = true;

      const course_data = this.course_co_po_form.value;

      if (!course_data.co_po_map || course_data.co_po_map.length === 0) {
        alert('Please fill mapping');
        return;
      }


      const co_po_map_to_save: any[] = [];

      for (const co_po_map of course_data.co_po_map) {
        co_po_map_to_save.push(...co_po_map.do_data);
      }
      const course_data_to_save: any = {
        _id: course_data._id,
        course_code: this.course.course_code,
        co_po_map: co_po_map_to_save
      };

      const save_respose = await this.restService.updateCourse(course_data_to_save);
      if (save_respose.success) {
        alert('Saved Successfully');
        // await this.searchCourse();
        this.onCOPOCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }

  async onCoursewareSaveClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      if (!this.course) {
        return;
      }

      this.course_save_loading = true;

      const course_data = this.courseware_form.value;

      const course_data_to_save: any = {
        _id: course_data.course_id,
        course_code: this.course.course_code,
        course_details: course_data
      };

      const save_respose = await this.restService.updateCourse(course_data_to_save);
      if (save_respose.success) {
        alert('Saved Successfully');
        // await this.searchCourse();
        this.onCoursewareCloseClick();
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.course_save_loading = false;
    }
  }


  async onExcelToJavasciptClick(): Promise<void> {
    if (this.course) {
      const val: string = this.course_co_po_form.value.bulk_update;
      const rows = val.trim().split('\n');
      const cOPOMaps: ICOPOMap[] = [];
      if (rows.length === this.co_count) {
        let i = 0;
        let has_error = false;
        for (const row of rows) {
          const co_no = (i + 1);
          const cols = row.split('\t');
          if (cols.length !== this.po_count) {
            alert('PO\' in the CO ' + co_no + ' are not matching please copy and paste correctly');
            has_error = true;
            break;
          }
          let j = 0;
          for (const col of cols) {
            const cOPOMap = {} as ICOPOMap;
            cOPOMap.course_id = this.course._id;
            cOPOMap.co_id = co_no;
            cOPOMap.po_id = (j + 1);
            cOPOMap.score = parseInt(col, 10);
            cOPOMaps.push(cOPOMap);
            j++;
          }

          i++;
        }

        if (has_error) {
          return;
        }
      } else if (rows.length === (this.co_count * this.po_count)) {
        let skip = 0;
        let take = 7;
        let i = 0;
        for (let index = 0; index < this.co_count; index++) {
          const cols = rows.slice(skip, take);
          let j = 0;
          for (const col of cols) {
            const cOPOMap = {} as ICOPOMap;
            cOPOMap.course_id = this.course._id;
            cOPOMap.co_id = i + 1;
            cOPOMap.po_id = (j + 1);
            cOPOMap.score = parseInt(col, 10);
            cOPOMaps.push(cOPOMap);
            j++;
          }
          skip = skip + this.po_count;
          take = take + this.po_count;
          i++;
        }




      } else {
        alert('CO\'s are not matching please copy and paste correctly');
        return;
      }



      this.course.co_po_map = cOPOMaps;
      this.buildCOPOForm(this.course);
      this.calculatePOWeightage();

    }
  }


}
