import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-elective-quantity-settings',
  templateUrl: './elective-quantity-settings.component.html',
  styleUrls: ['./elective-quantity-settings.component.scss']
})
export class ElectiveQuantitySettingsComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Elective Course Quantity Settings List';
  courses_list_loading: boolean = false;
  courses_list: CourseInterface[] = [];
  course: CourseInterface = {} as CourseInterface;
  quantity_setting_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  enrolled_year: number = 0;
  semester: number = 0;
  allowed_quantity: number = 0;
  category_list: any[] = [];
  category_list_loading = false;
  programme_search_form: any;
  programme_list: any[] = [];
  programme_list_loading = false;
  parts: any[] = [];
  part_course_types: any[] | undefined = [];
  state = '';
  ngOnInit(): void {
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildFilterForm();
    this.getProgrammeTypeList();
    this.buildForm();

  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  buildFilterForm(): void {
    this.programme_search_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: ['0'],
      //course_code: [""],
      part: [""],
      course_type: [""]
    });
  }
  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      if (this.state === 'ADD') {
        this.getProgrammeDataList(this.quantity_setting_form);
        if (id === 'programme_type_id' && this.quantity_setting_form.value.programme_type_id) {
          this.parts = this.commonEnums.getParts(this.quantity_setting_form.value.programme_type_id);
        }
      } else {
        this.getProgrammeDataList(this.programme_search_form);
        if (id === 'programme_type_id' && this.programme_search_form.value.programme_type_id) {
          this.parts = this.commonEnums.getParts(this.programme_search_form.value.programme_type_id);
        }
      }
    } else if (id === 'part') {
      if (this.state === 'ADD') {
        if (this.quantity_setting_form.value.programme_type_id) {
          this.quantity_setting_form.get('course_type').setValue("");
          this.part_course_types = this.commonEnums.getCoursePartType(this.quantity_setting_form.value.programme_type_id,
            this.quantity_setting_form.value.part);
        }
      } else {
        if (this.programme_search_form.value.programme_type_id) {
          this.part_course_types = this.commonEnums.getCoursePartType(
            this.programme_search_form.value.programme_type_id, this.programme_search_form.value.part);
        }
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        /* if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        } */
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  buildForm(): void {

    if (JSON.stringify(this.course) == '{}') {
      this.course = {
        id: "",
        enrolled_year: 0,
        offered_by_programme_type_id: "",
        semester: 0,
        course_code: "",
        qty: 0,
        section_data: [],
      };
    }
    this.quantity_setting_form = this.formBuilder.group({
      id: [this.course.id],
      programme_type_id: [this.course.offered_by_programme_type_id],
      enrolled_year: [this.programme_search_form.value.enrolled_year],
      semester: [this.programme_search_form.value.semester],
      course_code: [this.course.course_code],
      qty: [this.course.qty],
      section_data: this.formBuilder.array(this.buildSectionData(this.course.section_data)),
    });
  }

  buildSectionData(section_data: SectionDataInterface[]): any {
    if (!section_data) {
      section_data = [];
    }
    var section_list_array: any[] = [];
    if (section_data && section_data.length > 0) {
      var section_count = 0;
      for (const section of section_data) {
        section_list_array.push(this.formBuilder.group({
          section_name: [section.section_name, [Validators.required]],
          max_qty: [section.max_qty, [Validators.required]],
        }));
        section_count++;
      }
    }
    const remaining_count = 1 - section_data.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        section_list_array.push(this.formBuilder.group({
          section_name: ["", [Validators.required]],
          max_qty: [0, [Validators.required]],
        }));
      }
    }
    console.log(section_list_array);
    return section_list_array;
  }

  get sectionList() {
    return ((this.quantity_setting_form as FormGroup).get('section_data') as FormArray).controls;
  }

  addSection() {
    ((this.quantity_setting_form as FormGroup).get('section_data') as FormArray).push(this.formBuilder.group({
      section_name: [""],
      max_qty: [0],
    }))
  }

  deleteSection(i: number) {
    const consent = confirm("Are you sure want to delete this section?");
    if (!consent) {
      return;
    }
    ((this.quantity_setting_form as FormGroup).get('section_data') as FormArray).removeAt(i);
  }

  onSearchClick() {
    if (!this.programme_search_form.value.programme_type_id) {
      alert("Please select Programme Type"); return;
    }
    if (!this.programme_search_form.value.enrolled_year) {
      alert("Please select Enrollment Year"); return;
    }
    if (!this.programme_search_form.value.semester) {
      alert("Please select Semester"); return;
    }
    this.getCoursesList();
  }

  async getCoursesList() {
    this.courses_list = [];
    this.courses_list_loading = true;
    try {
      const service_response = await this.restService.searchRequestQuantity(this.programme_search_form.value);
      if (service_response && service_response.success) {
        this.courses_list = service_response.data;
        console.log(this.courses_list);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.courses_list_loading = false;
  }


  isInvalid(formControl: string): boolean {
    return this.quantity_setting_form.touched && this.quantity_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.quantity_setting_form.status !== 'VALID';
  }

  async onEditClick(course: any) {
    this.course = course;
    this.state = "ADD";
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.state = "";
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.allowed_quantity = 0;
    this.course = {} as CourseInterface;
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.quantity_setting_form.value;
      console.log(form_data);
      var section_data: SectionDataInterface[] = form_data.section_data;
      const uniqueValues = new Set(section_data.map(x => x.section_name));
      if (uniqueValues.size < section_data.length) {
        alert("Section Names must be unique");
        return;
      }
      var max_strength_total: number = 0;
      section_data.forEach(section => {
        max_strength_total += section.max_qty;
      });
      if (max_strength_total > form_data.qty) {
        alert("Total Student count of all sections combined must be less than or equal to the total in-take quantity of the course!");
        return;
      }

      let save_respose;
      save_respose = await this.restService.createRequestQuantity(form_data);
      if (save_respose.success) {
        await this.onSearchClick();
        alert('Saved Successfully');
      }
      this.getCoursesList(); //refresh list
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
  }
}

interface CourseInterface {
  id: string,
  //common data
  course_code: string,
  //course details
  course_name?: string,
  offered_by_programme_type_id?: string,
  offering_programme_id?: string,
  offering_programme_name?: string,
  //course_position?: string
  part?: string,
  course_type?: string,

  //setting details
  enrolled_year: number,
  semester: number,
  qty: number,
  section_data: SectionDataInterface[]

}

interface SectionDataInterface {
  section_name: string;
  max_qty: number;
}
