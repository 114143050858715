<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Students Fee Collection By Programme</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <!-- <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Student</button>
                </div>
            </div> -->
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Students</h4>
                            <input type="button" class="btn btn-sm btn-primary" value="Download" *ngIf="students_list.length>0" (click)="downloadAsCSV()">
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">View Paid, Un Paid and Payment Failure reports</p>
                    </div>

                    <div class="card-body" *ngIf="programme_search_form">
                        <form [formGroup]="programme_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme Type</label>
                                        <select formControlName="programme_type_id" class="form-control" (change)="onFilterSelectChange('programme_type_id',$event)">
                                            <option value="">Select Programme Type</option>
                                            <option *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programme_type.programme_type_id">{{programme_type.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance Type</label>
                                        <select formControlName="finance_type" class="form-control" (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programmes</label>
                                        <select formControlName="programme_id" class="form-control" (change)="onFilterSelectChange('programme_id',$event)">
                                            <option value="">All Programmes</option>
                                            <option *ngFor="let programe of programme_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="programe.programme_id">{{programe.display_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Gender</label>
                                        <select formControlName="gender" class="form-control" (change)="onFilterSelectChange('gender',$event)">
                                            <option value="">All Gender</option>
                                            <option *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="gender.id">{{gender.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Enrollment Year</label>
                                        <select formControlName="enrolled_year" class="form-control" (change)="onFilterSelectChange('enrolled_year',$event)">
                                            <option *ngFor="let enrolled_year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;" [ngValue]="enrolled_year">{{enrolled_year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Payment Status</label>
                                        <select formControlName="payment_status" class="form-control" (change)="onFilterSelectChange('payment_status',$event)">
                                            <option value="">All</option>
                                            <option *ngFor="let payment_status of payment_status_list;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="payment_status.id">{{payment_status.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Roll No</label>
                                        <input class="form-control" required="" type="text" formControlName="roll_no">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Transaction Id</label>
                                        <input class="form-control" required="" type="text" formControlName="transaction_id">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600"></label>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="active" name="active" formControlName="apply_date_filter">
                                            <label class="custom-control-label" for="active">Apply Date filter?</label>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="fromDataResolver.getValueFromFromGroup(programme_search_form,'apply_date_filter')">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Start Date</label>
                                            <input class="form-control" required="" type="date" formControlName="start_date">
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">End Date</label>
                                            <input class="form-control" required="" type="date" formControlName="end_date">
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button" title="Search" *ngIf="!student_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled *ngIf="student_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>




                                    </div>
                                </div>





                            </div>

                        </form>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Roll No.</th>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th>Term</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Transaction Id</th>
                                        <th>Paid Date</th>
                                        <th style="width: 10%;">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="students_list.length>0">


                                        <ng-container *ngFor="let student of students_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr [ngClass]="{'table-success': student.payment_status === 'payment_done','table-danger': student.payment_status === 'payment_failure' }">
                                                <td scope="row">
                                                    {{i+1}}

                                                </td>
                                                <td class="text-uppercase">{{student.roll_no}}</td>
                                                <td class="text-uppercase">{{student.given_name}} {{student.middle_name}}</td>
                                                <td class="text-uppercase">{{student.gender}}</td>
                                                <td class="text-uppercase">{{student.mobile_number}}</td>
                                                <td class="text-lowercase">{{student.email}}</td>
                                                <td>{{student.payment_term}}</td>
                                                <td>{{student.amount|currency:"INR"}}</td>

                                                <td>{{findPaymentStatusLabel(student.payment_status)}}</td>
                                                <td>{{student.payment_transaction_id}}</td>
                                                <td>{{student.payment_completed_at | date:commonEnums.date_format}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <ng-container *ngIf="student.payment_status == 'payment_done'">
                                                            <button type="button" class="btn btn-sm btn-info mr-2" title="Print Receipt" (click)="printReceipt(student)">
                                                                <fa-icon [icon]="fonts.faPrint"></fa-icon>
                                                            </button>
                                                        </ng-container>
                                                        <ng-container *ngIf="student.payment_status == 'pending' || student.payment_status == 'initiated'|| student.payment_status == 'payment_failure'">
                                                            <button type="button" class="btn btn-sm btn-info mr-2" title="Notify Student" (click)="notifyStudent(student)">
                                                                <fa-icon [icon]="fonts.faSms"></fa-icon>
                                                            </button>

                                                            <button type="button" class="btn btn-sm btn-info mr-2" title="Make Payment Corrections" (click)="onInitPaymentCorrectionClick(student)">
                                                                <fa-icon [icon]="fonts.faMoneyBillWave"></fa-icon>
                                                            </button>
                                                        </ng-container>
                                                    </div>
                                                </td>


                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="12" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="student_list_loading && students_list.length==0">
                                        <tr>
                                            <th colspan="12" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>


        <!-- Large Modal -->
        <div class="modal" id="modal-popup-payment-correction">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo" *ngIf="payment_correction_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Update Payment</h6>


                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="payment_correction_form">
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Paymentmode</label>
                                    <select formControlName="payment_mode" class="form-control" (change)="onFilterSelectChange('payment_mode',$event)">
                                        <option *ngFor="let payment_mode of payment_modes;let i=index;trackBy:fromDataResolver.identify;" [ngValue]="payment_mode.id">{{payment_mode.text}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Transaction Id</label>
                                    <input class="form-control" required="" type="text" formControlName="transaction_id">
                                </div>
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Description</label>
                                    <textarea class="form-control" required="" name="" id="" cols="30" rows="10" formControlName="description"></textarea>
                                </div>



                                <div class="mg-b-20"></div>
                                <div class="text-align-right">
                                    <button class="btn btn-primary mg-r-20" (click)="onFinishPaymentCorrectionClick()" *ngIf="!payment_correction_form_save_loading">Update</button>
                                    <button class="btn btn-primary mg-r-20" type="button" disabled *ngIf="payment_correction_form_save_loading">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>


                                    <button class="btn ripple btn-secondary" (click)="onPaymentCorrectionCloseClick()" type="button">Close</button>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->
    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>