<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Parent Registration</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row row-sm">
            <div class="col-lg-6 col-xl-3 col-md-6 col-12">
                <div class="card bg-primary-gradient text-white ">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="icon1 mt-2 text-center">
                                    <i class="fe fe-users tx-40"></i>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mt-0 text-center">
                                    <span class="text-white">Total</span>
                                    <h2 class="text-white mb-0">{{staus_count.total}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-3 col-md-6 col-12">
                <div class="card bg-success-gradient text-white">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="icon1 mt-2 text-center">
                                    <i class="fe fe-check tx-40"></i>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mt-0 text-center">
                                    <span class="text-white">Accepted</span>
                                    <h2 class="text-white mb-0">{{staus_count.accepted}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-3 col-md-6 col-12">
                <div class="card bg-warning-gradient text-white ">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="icon1 mt-2 text-center">
                                    <i class="fe fe-pause-circle tx-40"></i>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mt-0 text-center">
                                    <span class="text-white">Requested</span>
                                    <h2 class="text-white mb-0">{{staus_count.requested}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-3 col-md-6 col-12">
                <div class="card bg-danger-gradient text-white">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="icon1 mt-2 text-center">
                                    <i class="fe fe-x-circle tx-40"></i>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mt-0 text-center">
                                    <span class="text-white">Rejected</span>
                                    <h2 class="text-white mb-0">{{staus_count.rejected}}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Parent Registration Request</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Review and Approve or Reject Applications</p>
                    </div>

                    <div class="card-body" *ngIf="search_form">
                        <form [formGroup]="search_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Status</label>
                                        <select class="form-control" formControlName="status">
                                            <option label="All">
                                            </option>
                                            <option value="requested">
                                                Requested
                                            </option>
                                            <option value="accepted">
                                                Approved
                                            </option>
                                            <option value="rejected">
                                                Rejected
                                            </option>

                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!applied_list_loading"
                                            (click)="getAllParentRequestList()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="applied_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Parent Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Student Roll No</th>
                                        <th>Student Name</th>
                                        <th>Programme(s)</th>
                                        <th>Status</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="applied_list && applied_list.length>0">
                                        <ng-container
                                            *ngFor="let applied of applied_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <th scope="row">
                                                    {{i+1}}

                                                </th>
                                                <ng-container *ngIf="applied.user_info">
                                                    <td>{{applied.user_info.parent_name}}</td>
                                                    <td>{{applied.user_info.email}}</td>
                                                    <td>{{applied.user_info.mobile_number}}</td>
                                                    <td>{{applied.user_info.student_rollno}}</td>
                                                    <td>{{applied.student_info.given_name}}
                                                        {{applied.student_info.middle_name}}
                                                    </td>
                                                    <td>{{applied.student_info.programme_name}}</td>
                                                    <td>
                                                        <ng-container *ngIf="applied.status=='requested'">
                                                            Requested
                                                        </ng-container>
                                                        <ng-container *ngIf=" applied.status=='rejected'">
                                                            Rejected
                                                        </ng-container>
                                                        <ng-container *ngIf="applied.status=='accepted'">
                                                            Accepted
                                                        </ng-container>
                                                    </td>

                                                    <td>
                                                        <ng-container *ngIf="applied.status=='requested'">
                                                            <div class="btn-list">
                                                                <button class="btn btn-sm btn-success mg-t-5 mg-r-5"
                                                                    (click)="approveStatus(applied._id)">Approve</button>
                                                                <button class="btn btn-sm btn-danger mg-t-5 mg-r-5"
                                                                    (click)="rejectStatus(applied._id)">Reject</button>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="applied.status=='rejected'">
                                                            <button class="btn btn-sm btn-success"
                                                                (click)="approveStatus(applied._id)">Re-Approve</button>
                                                        </ng-container>

                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!applied_list || applied_list.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>