import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-manage-attandance',
  templateUrl: './manage-attandance.component.html',
  styleUrls: ['./manage-attandance.component.scss']
})
export class ManageAttandanceComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;

  attendance_list: any[] = [];
  attendance_list_loading = false;

  attendance_search_form: any;

  attendance_save_form: any;
  attendance_save_form_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.buildSearchForm({});
  }

  buildSearchForm(data: any): void {
    const date = data.attendance_date || new Date();
    this.attendance_search_form = this.fb.group({
      attendance_date: [DateHelper.convertToControlDate(date)],
      attendance_hour: [data.attendance_hour || ''],
      subject_code: [data.subject_code || ''],
    });
  }
  async onAddClick(): Promise<void> {
    this.buildAttendanceForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  buildAttendanceForm(): void {
    this.attendance_save_form = this.fb.group({
      attendance_date: [DateHelper.convertToControlDate(new Date())],
      attendance_hour: [''],
      subject_code: [''],
      pre_roll_nos: [''],
      roll_nos: this.fb.array([
        this.fb.group({
          roll_no: [''],
          is_absent: [true],
          attendance_type: ['absent']
        })
      ])
    });
  }

  async getSavedAttendance(): Promise<void> {
    try {

      this.attendance_list_loading = true;

      const attendance_search_form_data = this.attendance_search_form.value;

      if (!attendance_search_form_data.attendance_date) {
        alert('Please select attendance date');
        return;
      }

      if (!attendance_search_form_data.attendance_hour) {
        alert('Please select attendance hour');
        return;
      }


      if (!DateHelper.isValidDate(attendance_search_form_data.attendance_date)) {
        alert('Please select valid attendance date');
        return;
      }
      attendance_search_form_data.attendance_date = new Date(attendance_search_form_data.attendance_date);

      if (!attendance_search_form_data.subject_code || attendance_search_form_data.subject_code.trim().length === 0) {
        alert('Please enter course code');
        return;
      }
      attendance_search_form_data.subject_code = attendance_search_form_data.subject_code.toUpperCase();
      this.attendance_list = [];
      this.attendance_list_loading = true;
      const save_respose = await this.restService.getAttendanceStatusReport(attendance_search_form_data);
      if (save_respose.success && save_respose.data && save_respose.data.length > 0) {
        this.attendance_list = save_respose.data;
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.attendance_list_loading = false;
    }
  }
  async onClearSearch(): Promise<void> {
    this.buildSearchForm({});
  }


  async onSaveClick(): Promise<void> {
    try {

      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }

      this.attendance_save_form_loading = true;

      const attendance_save_form_data = this.attendance_save_form.value;

      if (!attendance_save_form_data.attendance_date) {
        alert('Please select attendance date');
        return;
      }

      if (!attendance_save_form_data.attendance_hour) {
        alert('Please select attendance hour');
        return;
      }


      if (!DateHelper.isValidDate(attendance_save_form_data.attendance_date)) {
        alert('Please select valid attendance date');
        return;
      }
      attendance_save_form_data.attendance_date = new Date(attendance_save_form_data.attendance_date);

      if (!attendance_save_form_data.subject_code || attendance_save_form_data.subject_code.trim().length === 0) {
        alert('Please enter course code');
        return;
      }
      const roll_nos = attendance_save_form_data.pre_roll_nos.trim().replace(/\n/g, ' ').replace(/,/g, ' ').split(' ');
      attendance_save_form_data.roll_nos = [];
      for (const roll_no of roll_nos) {
        attendance_save_form_data.roll_nos.push({ is_absent: true, attendance_type: 'abbsent', roll_no });
      }
      const save_respose = await this.restService.updateAttendanceStatus(attendance_save_form_data);
      if (save_respose.success) {
        alert('Saved successfully');

        this.onCloseClick();
        this.buildSearchForm({
          attendance_date: attendance_save_form_data.attendance_date,
          attendance_hour: attendance_save_form_data.attendance_hour,
          subject_code: attendance_save_form_data.subject_code
        });
        this.getSavedAttendance();
      }


    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.attendance_save_form_loading = false;
    }
  }

  // async onDeleteClick(): Promise<void> {
  //   try {

  //     const consent = confirm('Are you sure do you want to delete?');
  //     if (!consent) {
  //       return;
  //     }

  //     this.category_delete_loading = true;

  //     const categgory_data = this.category_form.value;
  //     if (!categgory_data.department_category_id) {
  //       alert('Please enter department categoryid');
  //     }


  //     const save_respose = await this.restService.deleteDepartmentCategoryById(categgory_data.department_category_id);


  //     if (save_respose.success) {
  //       alert('Deleted Successfully');
  //       await this.getFormDataList();
  //       this.onCloseClick();
  //     }


  //   } catch (error) {
  //     alert('Error while read data');
  //   } finally {
  //     this.category_delete_loading = false;
  //   }
  // }



  resetForm(): void {
    this.attendance_save_form = null;
  }
  async onCloseClick(): Promise<void> {
    this.resetForm();
    JQueryHelper.closeModal('#modal-popup');
  }



}
