import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModalComponent } from './components/modal/modal.component';
import { AccountMasterComponent } from './controllers/admins/accounts/account-master/account-master.component';
//import { CollectionReportComponent } from './controllers/admins/accounts/collection-report/collection-report.component';
import { FeeCollectionReportComponent } from './controllers/admins/accounts/fee-collection-report/fee-collection-report.component';
import { SettlementReportComponent } from './controllers/admins/accounts/settlement-report/settlement-report.component';
import { TransactionReportComponent } from './controllers/admins/accounts/transaction-report/transaction-report.component';
import { AdmissionflowsComponent } from './controllers/admins/admissions/admissionflows/admissionflows.component';
import { AdmissionmasterComponent } from './controllers/admins/admissions/admissionmaster/admissionmaster.component';
import { AdmissionoverallreportComponent } from './controllers/admins/admissions/admissionoverallreport/admissionoverallreport.component';
import { EditApplicantComponent } from './controllers/admins/admissions/v2/applicants/edit-applicant/edit-applicant.component';
import { EntranceExamhallComponent } from './controllers/admins/admissions/v2/entrance/entrance-examhall/entrance-examhall.component';
import { EntranceMasterComponent } from './controllers/admins/admissions/v2/entrance/entrance-master/entrance-master.component';
import { EntranceQuestionsComponent } from './controllers/admins/admissions/v2/entrance/entrance-questions/entrance-questions.component';
import { AdmissiondashboardComponent } from './controllers/admins/admissions/v2/reports/admissiondashboard/admissiondashboard.component';
import { ApplicationListComponent } from './controllers/admins/admissions/v2/reports/application-list/application-list.component';
import { AppliedListComponent } from './controllers/admins/admissions/v2/reports/applied-list/applied-list.component';
import { OfflineDueComponent } from './controllers/admins/admissions/v2/reports/offline-due/offline-due.component';
import { TransferReportComponent } from './controllers/admins/admissions/v2/reports/transfer-report/transfer-report.component';
import { AdmsRegisteredUsersComponent } from './controllers/admins/admissions/v2/status/adms-registered-users/adms-registered-users.component';
import { ManageAttandanceComponent } from './controllers/admins/attandance/manage-attandance/manage-attandance.component';
import { StaffLateEntryComponent } from './controllers/admins/attendance/staff-late-entry/staff-late-entry.component';
import { CourseSetupComponent } from './controllers/admins/courses/course-setup/course-setup.component';
import { CoursesMasterComponent } from './controllers/admins/courses/courses-master/courses-master.component';
import { ManageCoursesComponent } from './controllers/admins/courses/manage-courses/manage-courses.component';
import { DepartmentCategoryComponent } from './controllers/admins/department/department-category/department-category.component';
import { DepartmentsComponent } from './controllers/admins/department/departments/departments.component';
import { ExamFeesSettingsComponent } from './controllers/admins/exam/exam-fees-settings/exam-fees-settings.component';
import { FeeSettingComponent } from './controllers/admins/exam/fee-setting/fee-setting.component';
import { FeesReportComponent } from './controllers/admins/exam/fees-report/fees-report.component';
import { FeesTypeMasterComponent } from './controllers/admins/exam/fees-type-master/fees-type-master.component';
import { FineSettingsComponent } from './controllers/admins/exam/fine-settings/fine-settings.component';
import { TermMasterComponent } from './controllers/admins/exam/term-master/term-master.component';
import { AssetManagementComponent } from './controllers/admins/hostel-management/asset-management/asset-management.component';
import { RoomAssetListComponent } from './controllers/admins/hostel-management/asset-management/room-asset-list/room-asset-list.component';
import { RoomAssetComponent } from './controllers/admins/hostel-management/asset-management/room-asset/room-asset.component';
import { StudentRoomMapComponent } from './controllers/admins/hostel-management/asset-management/student-room-map/student-room-map.component';
import { CreateHostelComponent } from './controllers/admins/hostel-management/create-hostel/create-hostel.component';
import { EditHostelComponent } from './controllers/admins/hostel-management/edit-hostel/edit-hostel.component';
import { CreateFloorsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/create-floors/create-floors.component';
import { HostelFloorsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/hostel-floors.component';
import { ManageFloorsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/manage-floors/manage-floors.component';
import { EditRoomComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/manage-floors/manage-rooms/edit-room/edit-room.component';
import { ManageRoomsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/manage-floors/manage-rooms/manage-rooms.component';
import { HostelInchargesComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-incharges/hostel-incharges.component';
import { ManageInchargesComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-incharges/manage-incharges/manage-incharges.component';
import { HostelAttendanceComponent } from './controllers/admins/hostel-management/hostel-attendance/hostel-attendance.component';
import { HostelFeeHeaderComponent } from './controllers/admins/hostel-management/hostel-fee-header/hostel-fee-header.component';
import { HostelFeeSettingComponent } from './controllers/admins/hostel-management/hostel-fee-setting/hostel-fee-setting.component';
import { HostelManagementComponent } from './controllers/admins/hostel-management/hostel-management.component';
import { BillReportComponent } from './controllers/admins/hostel-management/manage-mess/bill-report/bill-report.component';
import { ExtraMenuComponent } from './controllers/admins/hostel-management/manage-mess/extra-menu/extra-menu.component';
import { GeneralMenuComponent } from './controllers/admins/hostel-management/manage-mess/general-menu/general-menu.component';
import { GenerateBillComponent } from './controllers/admins/hostel-management/manage-mess/generate-bill/generate-bill.component';
import { ManageMenuItemsComponent } from './controllers/admins/hostel-management/manage-mess/manage-menu-items/manage-menu-items.component';
import { ManageMessComponent } from './controllers/admins/hostel-management/manage-mess/manage-mess.component';
import { OtherExpensesComponent } from './controllers/admins/hostel-management/manage-mess/other-expenses/other-expenses.component';
import { StockPurchaseComponent } from './controllers/admins/hostel-management/manage-mess/stock-purchase/stock-purchase.component';
import { StockUsageComponent } from './controllers/admins/hostel-management/manage-mess/stock-usage/stock-usage.component';
import { StudentBillComponent } from './controllers/admins/hostel-management/manage-mess/student-bill/student-bill.component';
import { RoomAttendanceComponent } from './controllers/admins/hostel-management/room-attendance/room-attendance.component';
import { AssignElectiveStudentsComponent } from './controllers/admins/obe/assign-elective-students/assign-elective-students.component';
import { AssignMarkHeaderComponent } from './controllers/admins/obe/assign-mark-header/assign-mark-header.component';
import { ComponentsMasterComponent } from './controllers/admins/obe/components-master/components-master.component';
import { ElectivePollingSettingsComponent } from './controllers/admins/obe/elective-polling-settings/elective-polling-settings.component';
import { ElectiveQuantitySettingsComponent } from './controllers/admins/obe/elective-quantity-settings/elective-quantity-settings.component';
import { ManageComponentsComponent } from './controllers/admins/obe/manage-components/manage-components.component';
import { MissingAssignmentComponent } from './controllers/admins/obe/missing-assignment/missing-assignment.component';
import { ObeMemberComponent } from './controllers/admins/obe/obe-member/obe-member.component';
import { PartsSettingComponent } from './controllers/admins/obe/parts-setting/parts-setting.component';
import { ProgrammePamComponent } from './controllers/admins/obe/programme-pam/programme-pam.component';
import { ReviewMarkComponent } from './controllers/admins/obe/review-mark/review-mark.component';
import { StudentElectiveRequestsComponent } from './controllers/admins/obe/student-elective-requests/student-elective-requests.component';
import { ViewElectiveReportComponent } from './controllers/admins/obe/view-elective-report/view-elective-report.component';
import { CoursePaymentsComponent } from './controllers/admins/payments/course-payments/course-payments.component';
import { FeesScheduleListComponent } from './controllers/admins/payments/fees-schedule-list/fees-schedule-list.component';
import { ManageProgrammesComponent } from './controllers/admins/programmes/manage-programmes/manage-programmes.component';
import { ManageStaffAssignComponent } from './controllers/admins/programmes/manage-staff-assign/manage-staff-assign.component';
import { ProgrammeFeeSettingComponent } from './controllers/admins/programmes/programme-fee-setting/programme-fee-setting.component';
import { ProgrammeHeaderComponent } from './controllers/admins/programmes/programme-header/programme-header.component';
import { ProgrammeTypesComponent } from './controllers/admins/programmes/programme-types/programme-types.component';
import { ManagePartOneComponent } from './controllers/admins/programmes/ugparts/manage-part-one/manage-part-one.component';
import { ManageStaffsComponent } from './controllers/admins/staffs/manage-staffs/manage-staffs.component';
import { RolesComponent } from './controllers/admins/staffs/roles/roles.component';
import { StaffAssignmentComponent } from './controllers/admins/staffs/staff-assignment/staff-assignment.component';
import { ManageElectiveRequestComponent } from './controllers/admins/students/manage-elective-request/manage-elective-request.component';
import { ManageStudentsComponent } from './controllers/admins/students/manage-students/manage-students.component';
import { StudentAssignmentComponent } from './controllers/admins/students/student-assignment/student-assignment.component';
import { StudentsCountComponent } from './controllers/admins/students/students-count/students-count.component';
import { CoReportComponent } from './controllers/admins/terminal-exam/co-report/co-report.component';
import { CourseCumGradeRegisterComponent } from './controllers/admins/terminal-exam/course-cum-grade-register/course-cum-grade-register.component';

import { ExamSetupComponent } from './controllers/admins/terminal-exam/exam-setup/exam-setup.component';
import { FinalFoilCardComponent } from './controllers/admins/terminal-exam/final-foil-card/final-foil-card.component';
import { FoilCardComponent } from './controllers/admins/terminal-exam/foil-card/foil-card.component';
import { QuestionFormatSettingComponent } from './controllers/admins/terminal-exam/question-format-setting/question-format-setting.component';
import { QuestionSettingComponent } from './controllers/admins/terminal-exam/question-setting/question-setting.component';
import { StatementOfMarksComponent } from './controllers/admins/terminal-exam/statement-of-marks/statement-of-marks.component';
import { StudentSubmissionReviewComponent } from './controllers/admins/terminal-exam/student-submission-review/student-submission-review.component';
import { TerminalExamResultComponent } from './controllers/admins/terminal-exam/terminal-exam-result/terminal-exam-result.component';
import { DayOrderChangeComponent } from './controllers/admins/timetable/day-order-change/day-order-change.component';
import { HolidayCalendarComponent } from './controllers/admins/timetable/holiday-calendar/holiday-calendar.component';
import { ProgrammeTimeSettingsComponent } from './controllers/admins/timetable/programme-time-settings/programme-time-settings.component';

import { TimeSettingsComponent } from './controllers/admins/timetable/time-settings/time-settings.component';
import { TimetableSettingsComponent } from './controllers/admins/timetable/timetable-settings/timetable-settings.component';
import { AccessDeniedComponent } from './controllers/public/access-denied/access-denied.component';
import { TokenComponent } from './controllers/public/token/token.component';
import { UnderMaintenanceComponent } from './controllers/public/under-maintenance/under-maintenance.component';
import { ViewTimetableComponent } from './controllers/staff/attendance/view-timetable/view-timetable.component';
import { HosteladmissionComponent } from './controllers/staff/hostel/hosteladmission/hosteladmission.component';
import { StaffdashboardComponent } from './controllers/staff/staffdashboard/staffdashboard.component';
import { TerminalExamValuationComponent } from './controllers/staff/terminal-exam/terminal-exam-valuation/terminal-exam-valuation.component';
import { ValuationSchemeComponent } from './controllers/staff/terminal-exam/valuation-scheme/valuation-scheme.component';
import { TransportationadmissionComponent } from './controllers/staff/transportation/transportationadmission/transportationadmission.component';
import { DashboardComponent } from './controllers/students/dashboard/dashboard.component';
import { HostelComponent } from './controllers/students/hostel/hostel.component';
import { StudentCoursesComponent } from './controllers/students/mycourses/courses/student-courses.component';
import { MypaymentsComponent } from './controllers/students/mypayments/mypayments.component';
import { ObeFeedbackComponent } from './controllers/students/obe-feedback/obe-feedback.component';
import { StudentElectiveCoursesComponent } from './controllers/students/student-elective-courses/student-elective-courses.component';
import { StudentSubmissionComponent } from './controllers/students/terminal-exam/student-submission/student-submission.component';
import { TransportComponent } from './controllers/students/transport/transport.component';
import { ViewSyllabusComponent } from './controllers/students/view-syllabus/view-syllabus.component';
import { AuthguardGuard } from './guards/authguard.guard';

//routes to be used
//import { StaffLeaveApprovalComponent } from './controllers/admins/attendance/staff-leave-approval/staff-leave-approval.component';

import { StudentOdApprovalComponent } from './controllers/admins/attendance/student-od-approval/student-od-approval.component';
//import { ManageNtsComponent } from './controllers/admins/staffs/manage-nts/manage-nts.component';
//import { AttendanceCertificateComponent } from './controllers/admins/students/certificates/attendance-certificate/attendance-certificate.component';
//import { BonafideCertificateComponent } from './controllers/admins/students/certificates/bonafide-certificate/bonafide-certificate.component';
//import { CourseCertificateComponent } from './controllers/admins/students/certificates/course-certificate/course-certificate.component';
//import { TransferCertificateComponent } from './controllers/admins/students/certificates/transfer-certificate/transfer-certificate.component';
//import { CreateTerminalEvaluatorComponent } from './controllers/admins/terminal-exam/create-terminal-evaluator/create-terminal-evaluator.component';
//import { TerminalExamValuationsComponent } from './controllers/admins/terminal-exam/terminal-exam-valuations/terminal-exam-valuations.component';
//import { StaffLeaveApplyComponent } from './controllers/staff/attendance/staff-leave-apply/staff-leave-apply.component';
import { StudentOdRequestComponent } from './controllers/staff/attendance/student-od-request/student-od-request.component';
//import { StudentCalendarComponent } from './controllers/students/student-calendar/student-calendar.component';
import { StudentLeaveApplyComponent } from './controllers/students/student-leave-apply/student-leave-apply.component';
import { StudentLeaveApprovalComponent } from './controllers/admins/attendance/student-leave-approval/student-leave-approval.component';


//new features
import { AttendanceTableComponent } from './controllers/admins/attandance/attendance-table/attendance-table.component';
import { StaffAttendanceReportComponent } from './controllers/admins/attendance/staff-attendance-report/staff-attendance-report.component';
import { StaffNotMarkedAttendanceComponent } from './controllers/admins/attendance/staff-not-marked-attendance/staff-not-marked-attendance.component';
import { VirtualSectionComponent } from './controllers/admins/obe/virtual-section/virtual-section.component';
import { StudentTimetableComponent } from './controllers/students/student-timetable/student-timetable.component';

//added for demo
import { ManageParentRequestsComponent } from './controllers/admins/parents/manage-parent-requests/manage-parent-requests.component';
import { ParentRequestFormComponent } from './controllers/parents/parent-request-form/parent-request-form.component';
import { TransportFeesSettingsComponent } from './controllers/staff/transportation/transport-fees-settings/transport-fees-settings.component';
import { TransportRoutesComponent } from './controllers/staff/transportation/transport-routes/transport-routes.component';
import { StudentTransportHistoryComponent } from './controllers/admins/transport/student-transport-history/student-transport-history.component';
import { StudentNotificationComponent } from './controllers/admins/students/student-notification/student-notification.component';
import { TimetableConfigurationComponent } from './controllers/admins/timetable/timetable-configuration/timetable-configuration.component';
import { CourseExitSurveyComponent } from './controllers/staff/obe/course-exit-survey/course-exit-survey.component';
import { GuardianAssignmentComponent } from './controllers/admins/staffs/guardian-assignment/guardian-assignment.component';
import { AdmissionFeeReportComponent } from './controllers/admins/accounts/admission-fee-report/admission-fee-report.component';
import { ProgrammeExitSurveyComponent } from './controllers/admins/obe/programme-exit-survey/programme-exit-survey.component';
import { ExtraCurricularExitSurveyComponent } from './controllers/admins/obe/extra-curricular-exit-survey/extra-curricular-exit-survey.component';
import { StudentProgrammeExitSurveyComponent } from './controllers/students/student-programme-exit-survey/student-programme-exit-survey.component';
import { StudentExtraExitSurveyComponent } from './controllers/students/student-extra-exit-survey/student-extra-exit-survey.component';
import { NonSkillsetMasterComponent } from './controllers/admins/obe/non-skillset-master/non-skillset-master.component';
import { NonSkillsetUploadComponent } from './controllers/students/non-skillset-upload/non-skillset-upload.component';
import { ReviewNaEvidenceComponent } from './controllers/admins/obe/review-na-evidence/review-na-evidence.component';
import { StudentCertificateMasterComponent } from './controllers/admins/students/student-certificate-master/student-certificate-master.component';
import { StudentCertificateTrackerComponent } from './controllers/admins/students/student-certificate-tracker/student-certificate-tracker.component';
import { StaffCreationComponent } from './controllers/admins/staffs/staff-creation/staff-creation.component';
import { TerminalExternalValuationComponent } from './controllers/staff/terminal-exam/terminal-external-valuation/terminal-external-valuation.component';
import { InternalExamSetupComponent } from './controllers/staff/internal-exam/internal-exam-setup/internal-exam-setup.component';
import { InternalExamValuationComponent } from './controllers/staff/internal-exam/internal-exam-valuation/internal-exam-valuation.component';
import { InternalExamUploadComponent } from './controllers/students/internal-exam-upload/internal-exam-upload.component';
import { ViewStaffTimetableComponent } from './controllers/admins/attendance/view-staff-timetable/view-staff-timetable.component';
import { ManageRepeatSemesterComponent } from './controllers/admins/students/manage-repeat-semester/manage-repeat-semester.component';
import { ConstructiveAlignmentComponent } from './controllers/staff/constructive-alignment/constructive-alignment.component';
import { InternalQuestionSettingComponent } from './controllers/staff/internal-exam/internal-question-setting/internal-question-setting.component';
import { InternalValuationSchemeComponent } from './controllers/staff/internal-exam/internal-valuation-scheme/internal-valuation-scheme.component';
import { CreateParentComponent } from './controllers/students/create-parent/create-parent.component';
import { ChooseChildComponent } from './controllers/parents/choose-child/choose-child.component';
import { AddQuizQuestionsComponent } from './controllers/staff/internal-exam/add-quiz-questions/add-quiz-questions.component';
import { ViewStudentExamFeeComponent } from './controllers/admins/exam/view-student-exam-fee/view-student-exam-fee.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SmsCampaignComponent } from './controllers/admins/admissions/sms-campaign/sms-campaign.component';
import { AdmissionNewsComponent } from './controllers/admins/admissions/admission-news/admission-news.component';

//Appsmith Hostel Module
import { HostelCreationComponent } from './controllers/admins/hostel-creation/hostel-creation.component';
import { HostelInchargeComponent } from './controllers/admins/hostel-incharge/hostel-incharge.component';
import { HostelRequestCreationComponent } from './controllers/admins/hostel-request-creation/hostel-request-creation.component';
import { HostelRequestAcceptanceComponent } from './controllers/admins/hostel-request-acceptance/hostel-request-acceptance.component';
import { ManageHostelStudentsComponent } from './controllers/admins/manage-hostel-students/manage-hostel-students.component';
import { AttedanceShiftComponent } from './controllers/admins/attedance-shift/attedance-shift.component';
import { HostelAttedanceComponent } from './controllers/admins/hostel-attedance/hostel-attedance.component';
import { HostelWiseAttendanceComponent } from './controllers/admins/hostel-wise-attendance/hostel-wise-attendance.component';
import { StudentHostelAdmissionComponent } from './controllers/students/student-hostel-admission/student-hostel-admission.component';
import { StudentHostelAttendanceComponent } from './controllers/students/student-hostel-attendance/student-hostel-attendance.component';
import { StudentTransportRequestComponent } from './controllers/students/student-transport-request/student-transport-request.component';
import { ManageTransportFeesComponent } from './controllers/admins/manage-transport-fees/manage-transport-fees.component';
import { ManageTransportRequestComponent } from './controllers/admins/manage-transport-request/manage-transport-request.component';
import { ManageTransportRouteComponent } from './controllers/admins/manage-transport-route/manage-transport-route.component';
import { TemplateMasterComponent } from './controllers/admins/template-master/template-master.component';
import { StaffNotificationComponent } from './controllers/admins/staff-notification/staff-notification.component';
import { SentNotificationComponent } from './controllers/admins/sent-notification/sent-notification.component';
import { NotificationSettingComponent } from './controllers/admins/notification-setting/notification-setting.component';
import { StuNotificationComponent } from './controllers/admins/stu-notification/stu-notification.component';
import { StudentAttendanceComponent } from './controllers/students/student-attendance/student-attendance.component';
import { StudentHallticketComponent } from './controllers/students/student-hallticket/student-hallticket.component';
import { AdminDashboardComponent } from './controllers/admins/admin-dashboard/admin-dashboard.component';

import { ReportTemplateCreateComponent } from './controllers/admins/reports/report-template-create/report-template-create.component';
import { ProgramTemplateComponent } from './controllers/admins/reports/program-template/program-template.component';
import { TemplateConfigurationComponent } from './controllers/admins/reports/template-configuration/template-configuration.component';
import { TemplateConfigurationUpdateComponent } from './controllers/admins/reports/template-configuration-update/template-configuration-update.component';
import { AbsentReportComponent } from './controllers/admins/attandance/absent-report/absent-report.component';
import { StudentsSearchComponent } from './controllers/admins/students/students-search/students-search.component';
import { RepeatAttendanceComponent } from './controllers/admins/attandance/repeat-attendance/repeat-attendance.component';
import { CasReportComponent } from './controllers/admins/obe/cas-report/cas-report.component';
import { ProgrammeOutcomeComponent } from './controllers/admins/obe/programme-outcome/programme-outcome.component';
import { NonAcademicSemesterReportComponent } from './controllers/admins/obe/non-academic-semester-report/non-academic-semester-report.component';
import { NonAcademicProgrammeReportComponent } from './controllers/admins/obe/non-academic-programme-report/non-academic-programme-report.component';
import { CagpReportComponent } from './controllers/admins/obe/cagp-report/cagp-report.component';
import { HolisticReportComponent } from './controllers/admins/obe/holistic-report/holistic-report.component';
import { CoAttainmentReportComponent } from './controllers/admins/obe/co-attainment-report/co-attainment-report.component';
import { PercentageAttainmentCoReportComponent } from './controllers/admins/obe/percentage-attainment-co-report/percentage-attainment-co-report.component';
import { ReportTemplateFilterComponent } from './controllers/admins/reports/report-template-filter/report-template-filter.component';
import { TerminalExamDeviationComponent } from './controllers/staff/terminal-exam/terminal-exam-deviation/terminal-exam-deviation.component';
const routes: Routes = [
  {
    path: 'portal/handle/token',
    component: TokenComponent,
  },
  {
    path: 'portal/access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'students/dashboard',
    component: DashboardComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['student'] },
  },
  {
    path: 'students/hostel',
    component: HostelComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['student'] },
  },
  {
    path: 'staff/dashboard',
    component: StaffdashboardComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff', 'guest_user', 'non_staff'] },
  },
  {
    path: 'staff/hostel/admission-request',
    component: HosteladmissionComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'girls_hostel_admin',
        'boys_hostel_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'students/transport',
    component: TransportComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['student'] },
  },
  {
    path: 'students/mycourses',
    component: StudentCoursesComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['student'] },
  },
  {
    path: 'staff/transport/transport-request',
    component: TransportationadmissionComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'transportation_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'staff/transport/transport-routes',
    component: TransportRoutesComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'transportation_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'staff/transport/transport-fees-settings',
    component: TransportFeesSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'transportation_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'admin/transport/student-transport-history',
    component: StudentTransportHistoryComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'transportation_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'students/mypayments',
    component: MypaymentsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['student'] },
  },
  {
    path: 'students/hall-ticket',
    component: StudentHallticketComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['student'] },
  },

  //Appsmith Dashboard

  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal'
      ],
    },
  },


  //Appsmith Dashboard


  //Appsmith hostel module Routes Started

  {
    path: 'admin/hostel/hostel-creation',
    component: HostelCreationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/hostel/hostel-Incharge',
    component: HostelInchargeComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/hostel/requestCreation',
    component: HostelRequestCreationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/hostel/manageRequest',
    component: HostelRequestAcceptanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/hostel/manage-student',
    component: ManageHostelStudentsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/hostel/hostel-attedance-shift',
    component: AttedanceShiftComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/hostel/roomwise-attedance',
    component: HostelAttedanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'transportation_admin',
        'accounts',
      ],
    },
  },

  {
    path: 'admin/hostel/attendance/hostel-wise',
    component: HostelWiseAttendanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'boys_hostel_admin',
        'girls_hostel_admin',
        'accounts'
      ],
    },
  },

  {
    path: 'student/hostel/admission',
    component: StudentHostelAdmissionComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'student'
      ],
    },
  },

  {
    path: 'student/hostel/hostel-attendance',
    component: StudentHostelAttendanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'student'
      ],
    },
  },

  //Appsmith Hostel Module Routes Finished

  //Appsmith Transport Module Routes Started

  {
    path: 'student/transport-request',
    component: StudentTransportRequestComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'student'
      ],
    },
  },

  {
    path: 'admin/transport/manage-transport-request',
    component: ManageTransportRequestComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'transportation_admin',
      ],
    },
  },

  {
    path: 'admin/transport/manage-transport-route',
    component: ManageTransportRouteComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'transportation_admin',
      ],
    },
  },

  {
    path: 'admin/transport/manage-transport-fee',
    component: ManageTransportFeesComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'transportation_admin',
      ],
    },
  },

  //Appsmith Transport Module Routes Finished

  //Appsmith notification Routes Started

  {
    path: 'admin/sms-template',
    component: TemplateMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
      ],
    },
  },

  {
    path: 'admin/notification/staff',
    component: StaffNotificationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
      ],
    },
  },

  {
    path: 'admin/notification/student',
    component: StuNotificationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
      ],
    },
  },

  {
    path: 'admin/notification/sent-item',
    component: SentNotificationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
      ],
    },
  },

  {
    path: 'admin/notification/setting',
    component: NotificationSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
      ],
    },
  },



  //Appsmith notification Routes Finished
  {
    path: 'admin/department/department-category',
    component: DepartmentCategoryComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'department_admin',
      ],
    },
  },
  {
    path: 'admin/department/department',
    component: DepartmentsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'department_admin',
      ],
    },
  },
  {
    path: 'admin/hostel/hostel-management',
    component: HostelManagementComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/create',
    component: CreateHostelComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/update/:id',
    component: EditHostelComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/incharges/:hostelId',
    component: HostelInchargesComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/incharges/:hostelId/:floorId',
    component: HostelInchargesComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/incharges/:hostelId/:floorId/:roomId',
    component: HostelInchargesComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/manage-incharges',
    component: ManageInchargesComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/floors/:hostelId',
    component: HostelFloorsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/floors/create/:hostelId',
    component: CreateFloorsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/floors/edit/:hostelId/:floorId',
    component: ManageFloorsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/floors/rooms/:hostelId/:floorId',
    component: ManageRoomsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/hostel-management/floors/rooms/:hostelId/:floorId/:roomId',
    component: EditRoomComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/asset-management',
    component: AssetManagementComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/room-asset',
    component: RoomAssetComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/room-asset/:assetId',
    component: RoomAssetComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/asset-list',
    component: RoomAssetListComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/student-roommap/:roomId',
    component: StudentRoomMapComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/student-roommap/:roomId/:studentRoomMapId',
    component: StudentRoomMapComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/manage-mess',
    component: ManageMessComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/menu-item',
    component: ManageMenuItemsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/menu-item/:id',
    component: ManageMenuItemsComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] },
  },
  {
    path: 'admin/hostel/general-menu', component: GeneralMenuComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/extra-menu', component: ExtraMenuComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/stock-purchase', component: StockPurchaseComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/stock-usage', component: StockUsageComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/other-expenses', component: OtherExpensesComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/student-bill', component: StudentBillComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/generate-bill', component: GenerateBillComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/room-attendance', component: RoomAttendanceComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/hostel-attendance', component: HostelAttendanceComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/hostel/bill-report', component: BillReportComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'admin/staff/roles', component: RolesComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },
  {
    path: 'modal', component: ModalComponent, canActivate: [AuthguardGuard], data: { allowed_roles: ['staff_admin', 'principal', 'office_admin'] }
  },

  {
    path: 'admin/programme/programme-type',
    component: ProgrammeTypesComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'programme_admin',
      ],
    },
  },
  {
    path: 'admin/programme/programme',
    component: ManageProgrammesComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'programme_admin',
      ],
    },
  },
  {
    path: 'admin/programme/ugparts',
    component: ManagePartOneComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'programme_admin',
      ],
    },
  },
  {
    path: 'admin/students/students',
    component: ManageStudentsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'hod'
      ],
    },
  },
  {
    path: 'admin/students/search',
    component: StudentsSearchComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'hod'
      ],
    },
  },
  {
    path: 'admin/students/manage-elective-request',
    component: ManageElectiveRequestComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'arc_admin',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },


  {
    path: 'admin/students/payments/schedule-list',
    component: FeesScheduleListComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'admin/students/payments/programme-wise',
    component: CoursePaymentsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'accounts',
      ],
    },
  },
  {
    path: 'admin/admissions/admission/admission-master',
    component: AdmissionmasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/admission/sms-campaign',
    component: SmsCampaignComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/reports',
    component: AdmissiondashboardComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/news',
    component: AdmissionNewsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'admission_member',
        'admission_admin'
      ],
    },
  },
  {
    path: 'admin/admissions/status/registered',
    component: AdmsRegisteredUsersComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/applicant/list',
    component: ApplicationListComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/applicant/edit',
    component: EditApplicantComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/admission/admission-flow-settings',
    component: AdmissionflowsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/admission/admission-overall-report',
    component: AdmissionoverallreportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/entrance/entrance-master',
    component: EntranceMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/entrance/entrance-question',
    component: EntranceQuestionsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/entrance/entrance-examhall/:admission_id/:exam_id/:exam_patch_id',
    component: EntranceExamhallComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/reports/applicant/:status',
    component: AppliedListComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/reports/offline-due',
    component: OfflineDueComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'accounts',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/admissions/reports/transfer',
    component: TransferReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'accounts',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },

  {
    path: 'admin/attendance/manage',
    component: ManageAttandanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'admission_member',
      ],
    },
  },
  {
    path: 'admin/courses/manage-course',
    component: ManageCoursesComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/accounts/manage-accounts',
    component: AccountMasterComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['accounts', 'principal'] },
  },
  {
    path: 'admin/accounts/fee-reports',
    component: FeeCollectionReportComponent,
    canActivate: [AuthguardGuard],
    data: { allowed_roles: ['accounts', 'principal'] },
  },
  {
    path: 'common/maintenance',
    component: UnderMaintenanceComponent,
  },
  {
    path: 'admin/staff/staff',
    component: ManageStaffsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },
  /* {
    path: 'admin/staff/nts',
    component: ManageNtsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  }, */
  {
    path: 'admin/staff/assign-staff',
    component: ManageStaffAssignComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/obe/components',
    component: ManageComponentsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/obe/assign-header',
    component: AssignMarkHeaderComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },

  {
    path: 'admin/obe/cas-report',
    component: CasReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },

  {
    path: 'admin/obe/cagp-report',
    component: CagpReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },

  {
    path: 'admin/obe/holistic-attainment',
    component: HolisticReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },

  {
    path: 'admin/obe/programme-outcome',
    component: ProgrammeOutcomeComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },

  {
    path: 'admin/obe/co-attainment-report',
    component: CoAttainmentReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },

  {
    path: 'admin/obe/percentage-attainment-co',
    component: PercentageAttainmentCoReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },

  //Fees Settings Routes
  {
    path: 'admin/exam/exam-fees-settings',
    component: ExamFeesSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },
  {
    path: 'admin/exam/fee-setting',
    component: FeeSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },
  {
    path: 'admin/exam/fine-settings',
    component: FineSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },
  {
    path: 'admin/exam/fees-report',
    component: FeesReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },
  {
    path: 'admin/exam/fees-type-master',
    component: FeesTypeMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },
  {
    path: 'admin/accounts/transaction-report',
    component: TransactionReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },
  /*   {
      path: 'admin/accounts/collection-report',
      component: CollectionReportComponent,
      canActivate: [AuthguardGuard],
      data: {
        allowed_roles: [
          'principal',
          'accounts',
          'controller'
        ],
      },
    }, */
  {
    path: 'admin/accounts/settlement-report',
    component: SettlementReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },

  //OBE Feedback
  {
    path: 'students/obe/course-exit-survey',
    component: ObeFeedbackComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student_admin',
        'student'
      ],
    },
  },
  {
    path: 'admin/students/students-count',
    component: StudentsCountComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/courses/course-scheme',
    component: CoursesMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/programmes/programme-header',
    component: ProgrammeHeaderComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/components-master',
    component: ComponentsMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/parts-setting',
    component: PartsSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/staff/staff-assignment',
    component: StaffAssignmentComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/students/student-assignment',
    component: StudentAssignmentComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/elective-polling-settings',
    component: ElectivePollingSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/courses/course-setup',
    component: CourseSetupComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/obe/manage-elective-request',
    component: StudentElectiveRequestsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/view-elective-report',
    component: ViewElectiveReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/assign-elective-students',
    component: AssignElectiveStudentsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'students/syllabus',
    component: ViewSyllabusComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'student'
      ],
    },
  },
  {
    path: 'admin/obe/elective-quantity-settings',
    component: ElectiveQuantitySettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'students/elective-courses',
    component: StudentElectiveCoursesComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
        'student'
      ],
    },
  },
  {
    path: 'admin/obe/missing-assignment',
    component: MissingAssignmentComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/programme-pam',
    component: ProgrammePamComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/review-mark',
    component: ReviewMarkComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },
  {
    path: 'admin/hostel/hostel-header',
    component: HostelFeeHeaderComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/hostel/hostel-fee-setting',
    component: HostelFeeSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/question-format-setting',
    component: QuestionFormatSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
      ],
    },
  },
  {
    path: 'admin/terminal/exam-setup',
    component: ExamSetupComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'staff/terminal/valuation',
    component: TerminalExamValuationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'staff/terminal/deviation',
    component: TerminalExamDeviationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/question-setting',
    component: QuestionSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'staff/terminal/valuation-scheme',
    component: ValuationSchemeComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'student/terminal/student-submission',
    component: StudentSubmissionComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'coe',
        'student'
      ],
    },
  },
  {
    path: 'admin/terminal/student-submission-review',
    component: StudentSubmissionReviewComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/foil-card',
    component: FoilCardComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/statement-of-marks',
    component: StatementOfMarksComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/terminal-exam-result',
    component: TerminalExamResultComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/course-grade-register',
    component: CourseCumGradeRegisterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  /* {
    path: 'admin/terminal/valuations',
    component: TerminalExamValuationsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  }, */
  {
    path: 'admin/terminal/final-foil-card',
    component: FinalFoilCardComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/terminal/co-report',
    component: CoReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },
  {
    path: 'admin/obe/obe-member',
    component: ObeMemberComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'controller',
        'coe',
        'staff'
      ],
    },
  },

  //timetable settings
  {
    path: 'admin/time-table/time-settings',
    component: TimeSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'admin/time-table/programme-time-settings',
    component: ProgrammeTimeSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'admin/time-table/time-table-settings',
    component: TimetableSettingsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'staff/time-table/view-timetable',
    component: ViewTimetableComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },

  /*   {
      path: 'admin/attendance/staff-leave-approval',
      component: StaffLeaveApprovalComponent,
      canActivate: [AuthguardGuard],
      data: {
        allowed_roles: [
          'hod',
          'principal',
        ],
      },
    },



    {
      path: 'staff/attendance/staff-leave-apply',
      component: StaffLeaveApplyComponent,
      canActivate: [AuthguardGuard],
      data: {
        allowed_roles: [
          'staff',
          'hod',
          'principal',
        ],
      },
    },

     */

  {
    path: 'admin/time-table/holiday-calendar',
    component: HolidayCalendarComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'principal',
        'office_admin'
      ],
    },
  },
  {
    path: 'admin/time-table/day-order-change',
    component: DayOrderChangeComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'principal',
        'office_admin'
      ],
    },
  },
  {
    path: 'admin/attendance/staff-late-entry',
    component: StaffLateEntryComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'student/time-table/view-timetable',
    component: StudentTimetableComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    }
  },
  {
    path: 'student/attendance',
    component: StudentAttendanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    },
  },
  /* {
    path: 'admin/students/bonafide-certificate',
    component: BonafideCertificateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin'
      ],
    },
  },

  {
    path: 'admin/students/course-certificate',
    component: CourseCertificateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin'
      ],
    },
  },

  {
    path: 'admin/students/attendance-certificate',
    component: AttendanceCertificateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin'
      ],
    },
  },

  {
    path: 'admin/students/transfer-certificate',
    component: TransferCertificateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin'
      ],
    },
  },

  {
    path: 'student/time-table/student-calendar',
    component: StudentCalendarComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    },
  },

   */

  {
    path: 'admin/programmes/programme-fee-setting',
    component: ProgrammeFeeSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'office_admin',
        'staff_admin',
        'controller',
        'staff'
      ],
    },
  },
  {
    path: 'admin/parents/manage-parent-request',
    component: ManageParentRequestsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'staff_admin',
      ],
    },
  },
  {
    path: 'parents/parent-request-form',
    component: ParentRequestFormComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'staff_admin',
      ],
    },
  },
  /*{
    path: 'admin/terminal/create-terminal-evaluator',
    component: CreateTerminalEvaluatorComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'controller',
      ],
    },
  }, */

  //need to comment these two routes before deployment
  {
    path: 'admin/attendance/attendance-report',
    component: AttendanceTableComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'admin/attendance/absent-report',
    component: AbsentReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'hod',
        'office_admin',
      ],
    },
  },
  {
    path: 'staff/attendance/repeat-attendance',
    component: RepeatAttendanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'principal',
      ],
    },
  },
  {
    path: 'admin/attendance/staff-report',
    component: StaffAttendanceReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'admin/attendance/staff-not-marked-report',
    component: StaffNotMarkedAttendanceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
      ],
    },
  },
  {
    path: 'admin/obe/virtual-section-settings',
    component: VirtualSectionComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'aarc_admin',
        'aarc_member'
      ],
    },
  },
  {
    path: 'admin/students/send-notification',
    component: StudentNotificationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'hod'
      ],
    },
  },
  {
    path: 'admin/time-table/configuration',
    component: TimetableConfigurationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
      ],
    },
  },

  {
    path: 'staff/obe/course-exit-survey',
    component: CourseExitSurveyComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'obe_member',
        'staff',
        'aarc_member',
        'staff_admin'
      ],
    },
  },

  {
    path: 'admin/staff/student-guardian-assignment',
    component: GuardianAssignmentComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'staff_admin'
      ],
    },
  },

  {
    path: 'admin/accounts/admission-collection-report',
    component: AdmissionFeeReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts'
      ],
    },
  },

  {
    path: 'admin/obe/programme-exit-survey',
    component: ProgrammeExitSurveyComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'aarc_member',
        'staff_admin',
        'hod',
        'obe_member'
      ],
    },
  },

  {
    path: 'admin/obe/extra-exit-survey',
    component: ExtraCurricularExitSurveyComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'aarc_member',
        'staff_admin',
        'hod'
      ],
    },
  },

  {
    path: 'students/obe/programme-exit-survey',
    component: StudentProgrammeExitSurveyComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student_admin',
        'student'
      ],
    },
  },

  {
    path: 'students/obe/extra-exit-survey',
    component: StudentExtraExitSurveyComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student_admin',
        'student'
      ],
    },
  },

  {
    path: 'admin/obe/non-skillset-master',
    component: NonSkillsetMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'aarc_member',
      ],
    },
  },

  {
    path: 'students/obe/evidence-upload',
    component: NonSkillsetUploadComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student_admin',
        'student'
      ],
    },
  },

  {
    path: 'admin/obe/review-na-evidence',
    component: ReviewNaEvidenceComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'aarc_member',
        'hod',
        'obe_member',
        'coe'
      ],
    },
  },

  {
    path: 'admin/obe/na-semester-report',
    component: NonAcademicSemesterReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'aarc_member',
        'hod',
        'obe_member',
        'coe'
      ],
    },
  },

  {
    path: 'admin/obe/na-programme-report',
    component: NonAcademicProgrammeReportComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'aarc_member',
        'hod',
        'obe_member',
        'coe'
      ],
    },
  },

  {
    path: 'admin/students/certificate-master',
    component: StudentCertificateMasterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'student_admin',
      ],
    },
  },

  {
    path: 'admin/students/certificate-tracker',
    component: StudentCertificateTrackerComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'student_admin',
        'admission_admin',
        'admission_member'
      ],
    },
  },

  {
    path: 'admin/staff/staff-creation',
    component: StaffCreationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'staff_admin',
      ],
    },
  },

  {
    path: 'staff/terminal/external-valuation',
    component: TerminalExternalValuationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'guest_user'
      ],
    },
  },

  {
    path: 'staff/internal/exam-setup',
    component: InternalExamSetupComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'coe',
        'staff'
      ],
    },
  },

  {
    path: 'staff/internal/question-setting',
    component: InternalQuestionSettingComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'coe',
        'staff'
      ],
    },
  },

  {
    path: 'staff/internal/valuation-scheme',
    component: InternalValuationSchemeComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'coe',
        'staff'
      ],
    },
  },

  {
    path: 'staff/internal/exam-valuation',
    component: InternalExamValuationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'coe',
        'staff'
      ],
    },
  },

  {
    path: 'student/internal/student-submission',
    component: InternalExamUploadComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    },
  },

  {
    path: 'admin/time-table/view-staff-time-table',
    component: ViewStaffTimetableComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'staff_admin',
        'principal',
        'office_admin',
        'timetable_member'
      ],
    },
  },

  {
    path: 'admin/students/repeat-semester',
    component: ManageRepeatSemesterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin',
        'hod'
      ],
    },
  },

  {
    path: 'staff/obe/constructive-alignment',
    component: ConstructiveAlignmentComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'coe',
        'staff'
      ],
    },
  },

  {
    path: 'student/parent/create-parent',
    component: CreateParentComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    },
  },

  {
    path: 'parent/choose-child',
    component: ChooseChildComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    },
  },

  {
    path: 'staff/quiz/add-question',
    component: AddQuizQuestionsComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'staff',
        'hod',
        'coe'
      ],
    },
  },

  {
    path: 'staff/attendance/student-od-request',
    component: StudentOdRequestComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'staff',
        'hod',
        'principal',
        'office_admin'
      ],
    },
  },

  {
    path: 'admin/attendance/student-od-approval',
    component: StudentOdApprovalComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'office_admin'
      ],
    },
  },

  {
    path: 'student/attendance/student-leave-apply',
    component: StudentLeaveApplyComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'student'
      ],
    },
  },

  {
    path: 'admin/attendance/student-leave-approval',
    component: StudentLeaveApprovalComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'hod',
        'principal',
        'office_admin'
      ],
    },
  },


  {
    path: 'admin/exam/view-student-exam-fee',
    component: ViewStudentExamFeeComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'accounts',
        'controller'
      ],
    },
  },

  {
    path: 'admin/reports/report-template-create',
    component: ReportTemplateCreateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'staff'
      ],
    },
  },

  {
    path: 'admin/reports/report-template-filter/:templateId',
    component: ReportTemplateFilterComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'staff'
      ],
    },
  },

  {
    path: 'admin/reports/program-template',
    component: ProgramTemplateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'staff'
      ],
    },
  },

  {
    path: 'admin/reports/template-configuration/:prgTempId/:id',
    component: TemplateConfigurationComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'staff'
      ],
    }
  },

  {
    path: 'admin/reports/template-configuration-update/:prgTempId/:id',
    component: TemplateConfigurationUpdateComponent,
    canActivate: [AuthguardGuard],
    data: {
      allowed_roles: [
        'principal',
        'staff'
      ],
    }
  },

  {
    path: 'portal/logout',
    component: LogoutComponent,
  },

  {
    path: '',
    component: TokenComponent,
  },
  {
    path: '**',
    component: TokenComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
