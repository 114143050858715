import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { OfflinePaymentUpdateRequest } from 'src/app/services/rest/entity/OfflinePaymentUpdateRequest';

@Component({
  selector: 'app-transportationadmission',
  templateUrl: './transportationadmission.component.html',
  styleUrls: ['./transportationadmission.component.scss']
})
export class TransportationadmissionComponent implements OnInit {
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  applied_list_raw: any[] = [];
  applied_list: any[] = [];
  staus_count = {
    total: 0,
    applied: 0,
    approved: 0,
    rejected: 0,
    enrolled: 0,
    payment_failed: 0,
    collected_amount: 0
  };

  selected_status = '';

  //search
  search_form: any;

  applied_list_loading = false;

  // payment correction

  payment_correction_form: any;
  payment_correction_form_save_loading = false;
  payment_modes = [
    {
      id: 'ONLINE_FAILED', text: 'Online Failed'
    }, {
      id: 'OFFLINE', text: 'Offline'
    }
  ];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.buildSearchForm();
  }

  ngOnInit(): void {
  }


  buildSearchForm(): void {
    this.search_form = this.fb.group({
      status: [''],
      requested_year: [new Date().getFullYear()],
      enrolled_year: [new Date().getFullYear()],
    })
    this.getAllTransportationAppliedList();
  }


  async getAllTransportationAppliedList(): Promise<void> {

    try {
      this.applied_list = [];
      this.applied_list_raw = [];
      this.staus_count = {
        total: 0,
        applied: 0,
        approved: 0,
        rejected: 0,
        enrolled: 0,
        payment_failed: 0,
        collected_amount: 0
      };
      this.applied_list_loading = true;

      let query: any = {
        status: this.search_form.value.status,
        requested_year: this.search_form.value.requested_year,
        enrolled_year: this.search_form.value.enrolled_year,
      };

      const rest_response = await this.restService.getAllTransportAppliedList(query);
      if (rest_response.success && rest_response.data) {
        this.applied_list = rest_response.data;
        this.applied_list_raw = rest_response.data;
        this.staus_count.total = this.applied_list_raw.length;
        this.staus_count.applied = this.applied_list_raw.filter(c => c.status === 'pending').length;
        this.staus_count.approved = this.applied_list_raw.filter(c => c.status === 'allowed').length;
        const payment_finished = this.applied_list_raw.filter(c => c.status === 'payment_done');

        this.staus_count.enrolled = payment_finished.length;
        let collected_amount = 0;
        for (const payment_finis of payment_finished) {
          collected_amount = collected_amount + payment_finis.amount;
        }
        this.staus_count.collected_amount = collected_amount;
        this.staus_count.payment_failed = this.applied_list_raw.filter(c => c.status === 'payment_failure').length;
        this.staus_count.rejected = this.applied_list_raw.filter(c => c.status === 'rejected' || c.status === 'reject').length;
      } else {
        this.applied_list = [];
        this.applied_list_raw = [];
        this.staus_count = {
          total: 0,
          applied: 0,
          approved: 0,
          rejected: 0,
          enrolled: 0,
          payment_failed: 0,
          collected_amount: 0
        };
      }
    } catch (error) {

    } finally {
      this.applied_list_loading = false;
    }



  }

  async rejectStatus(_id: string): Promise<void> {
    const consent = confirm('Are you sure do you want to reject this transportation request?');
    if (consent) {
      try {
        const rest_response = await this.restService.changeTransportStudentApplicationStatus({ _id, status: 'rejected' });
        if (rest_response.success) {
          this.getAllTransportationAppliedList();
          alert('Successfully Rejected');
        }
      } catch (error) {
        alert('Error while rejecting');
      }

    }
  }
  async approveStatus(_id: string): Promise<void> {
    const consent = confirm('Are you sure do you want to approve this transportation request?');
    if (consent) {
      try {
        const rest_response = await this.restService.changeTransportStudentApplicationStatus({ _id, status: 'allowed' });
        if (rest_response.success) {
          this.getAllTransportationAppliedList();
          alert('Successfully Approved');
        }
      } catch (error) {
        alert('Error while approving');
      }

    }

  }


  onStausChange(event: any): void {
    const selected_from_index = event.target.selectedIndex;
    if (selected_from_index === 0) {
      this.applied_list = this.applied_list_raw;
      this.selected_status = '';
    } else if (selected_from_index === 1) {
      this.applied_list = this.applied_list_raw.filter(c => c.status === 'pending');
      this.selected_status = 'pending';
    } else if (selected_from_index === 2) {
      this.applied_list = this.applied_list_raw.filter(c => c.status === 'allowed');
      this.selected_status = 'allowed';
    } else if (selected_from_index === 3) {
      this.applied_list = this.applied_list_raw.filter(c => c.status === 'payment_done');
      this.selected_status = 'payment_done';
    } else if (selected_from_index === 4) {
      this.applied_list = this.applied_list_raw.filter(c => c.status === 'rejected' || c.status === 'reject');
      this.selected_status = 'rejected';
    } else if (selected_from_index === 5) {
      this.applied_list = this.applied_list_raw.filter(c => c.status === 'payment_failure');
      this.selected_status = 'payment_failure';
    }
  }



  async onFilterSelectChange(id: string, event: any): Promise<void> {

  }


  async onInitPaymentCorrectionClick(student: any): Promise<void> {
    this.buildPaymentCorrectionForm(student);
    JQueryHelper.openModal('#modal-popup-payment-correction', { keyboard: false, backdrop: 'static' });
  }

  buildPaymentCorrectionForm(student: any): void {
    let transaction_id = '';
    if (student.transaction_id) {
      transaction_id = student.transaction_id;
    }
    this.payment_correction_form = this.fb.group({
      sub: [student.sub, [Validators.required]],
      payment_mode: [this.payment_modes[0].id, [Validators.required]],
      payment_tracking_id: [student._id, [Validators.required]],
      transaction_id: [transaction_id, [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  async onPaymentCorrectionCloseClick(): Promise<void> {
    this.resetPaymentCorrectionForm();
    JQueryHelper.closeModal('#modal-popup-payment-correction');
  }
  resetPaymentCorrectionForm(): void {
    this.payment_correction_form = undefined;
  }

  async onFinishPaymentCorrectionClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to update payment?');
      if (!consent) {
        return;
      }
      this.payment_correction_form_save_loading = true;

      const offlinePaymentUpdateRequest: OfflinePaymentUpdateRequest = this.payment_correction_form.value;
      if (!offlinePaymentUpdateRequest.transaction_id || !offlinePaymentUpdateRequest.transaction_id.trim()) {
        alert('Please enter the transaction referance or transaction id');
        return;
      }
      const saved_student_response = await this.restService.handleOfflineTransportPayment(this.payment_correction_form.value);
      if (saved_student_response && saved_student_response.success === true) {
        alert('Updated Successfully');
        this.onPaymentCorrectionCloseClick();
        await this.getAllTransportationAppliedList();
      } else {
        alert('Error while Update');
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.payment_correction_form_save_loading = false;
    }
  }

  downloadCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const applied of this.applied_list) {
      var roll_nos: string = '';
      var programmme_names: string = '';
      applied.user_info.programmes.forEach(programme => {
        roll_nos += programme.roll_no + ",";
        programmme_names += programme.programme_name
      });
      data_list.push({
        "S.No": i,
        "Name": applied.user_info.given_name + " " + applied.user_info.middle_name,
        "Email": applied.user_info.email,
        "Mobile No.": applied.user_info.mobile_number,
        "Roll No.": roll_nos,
        "Programme": programmme_names,
        "From": applied.from,
        "Amount": applied.amount,
        "Status": (applied.status as string).split("_").join(" ").toUpperCase(),
        "Transaction Id": applied.transaction_id ? applied.transaction_id : (applied.unique_id ?
          applied.unique_id.split("-")[4] : "")
      });
      i++;
    }
    CSVHelper.downloadCSV(data_list, "Transport_Report_" + DateHelper.convertToControlDate(new Date()));
  }

}
