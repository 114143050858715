<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Registration</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-10">Parent Registration Form</h4>

                        </div>

                    </div>
                    <div class="col-xl-3">

                    </div>
                    <div class="col-xl-6 card card-body pd-20 pd-md-40 d-flex">

                        <form [formGroup]="register_form">
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter the Name</label>
                                <input class="form-control" required="" type="text" formControlName="parent_name"
                                    [ngClass]="{ 'is-invalid': submitted && controls.parent_name.errors }">
                                <div *ngIf="submitted && controls.parent_name.errors">
                                    <ul class="parsley-errors-list filled">
                                        <li *ngIf="controls.parent_name.errors.required" class="parsley-required">
                                            Parent
                                            Name is required.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Mobile
                                    Number</label>
                                <input class="form-control" required="" type="text" formControlName="mobile_no"
                                    [ngClass]="{ 'is-invalid': submitted && controls.mobile_no.errors }">
                                <div *ngIf="submitted && controls.mobile_no.errors">
                                    <ul class="parsley-errors-list filled">
                                        <li *ngIf="controls.mobile_no.errors.required" class="parsley-required">
                                            Mobile
                                            No is required.</li>
                                        <li *ngIf="controls.mobile_no.errors.pattern" class="parsley-required">
                                            Invalid
                                            Mobile No</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Mail Id</label>
                                <input class="form-control" type="email" formControlName="email_id"
                                    [ngClass]="{ 'is-invalid':submitted && controls.email_id.errors }">

                                <div *ngIf="submitted && controls.email_id.errors">
                                    <ul class="parsley-errors-list filled">
                                        <li *ngIf="controls.email_id.errors.email" class="parsley-required">Invalid
                                            Email Id</li>
                                    </ul>
                                </div>

                            </div>
                            <div class="form-group">
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Student Roll
                                    No</label>
                                <input class="form-control" required="" type="text" formControlName="student_rollno"
                                    (focusout)="getStudentName()"
                                    [ngClass]="{ 'is-invalid':submitted && controls.student_rollno.errors }">
                                <div *ngIf="submitted && controls.student_rollno.errors">
                                    <ul class="parsley-errors-list filled">
                                        <li *ngIf="controls.student_rollno.errors.required" class="parsley-required">
                                            Student
                                            Roll No is required.</li>
                                        <li *ngIf="controls.student_rollno.errors.maxlength" class="parsley-required">
                                            Roll No
                                            should be 6 digit</li>
                                        <li *ngIf="controls.student_rollno.errors.pattern" class="parsley-required">
                                            Invalid
                                            Roll No</li>
                                    </ul>
                                </div>
                                <label class="main-content-label tx-11 tx-medium tx-gray-600">{{student_name}}</label>
                            </div>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">

                                <button type="button" class="btn btn-primary mg-r-20" title="Save"
                                    (click)="onSaveClick()">
                                    <ng-container *ngIf="register_save_loading">
                                        Loading...
                                    </ng-container>
                                    <ng-container *ngIf="!register_save_loading">
                                        Save
                                    </ng-container>
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>


        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>