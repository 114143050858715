<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid mg-t-30">
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">View Admission Reports</h2>

                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info mg-r-10" (click)="viewMultiplePayments()">View Excess
                        Application Payments</button>

                    <button type="button" class="btn btn-info" (click)="navigateToTermWise()">View Programme Term-wise
                        Breakup</button>
                </div>
            </div>
        </div>



        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Admission Reports</h4>

                        </div>
                        <!-- <p class="tx-12 tx-gray-500 mb-2">Create, Update and Delete Admissions List</p> -->
                    </div>

                    <div class="card-body" *ngIf="admission_search_form">
                        <form [formGroup]="admission_search_form">
                            <div class="row row-sm">

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select regulation
                                            year</label>
                                        <select formControlName="regulation_year" class="form-control"
                                            (change)="onRegulationYearChange()">
                                            <option value="">Select regulation year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Admission</label>
                                        <select formControlName="admission_id" class="form-control"
                                            (change)="onAdmissionChange()">
                                            <option value="">All admission</option>
                                            <option
                                                *ngFor="let admission of admissions_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="admission._id">{{admission.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select finance
                                            type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFinanceTypeChange()">
                                            <option value="">All finance type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            programme</label>
                                        <select formControlName="programme_id" class="form-control">
                                            <option value="">All programme</option>
                                            <option
                                                *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="programme.programme_id">{{programme.programme_name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select fee
                                            type</label>
                                        <select formControlName="fee_type" class="form-control"
                                            (change)="onFeeTypeChange()">
                                            <option value="application">Application</option>
                                            <option value="programme">Programme</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-1">
                                    <div class="form-group mg-t-25">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="apply_date"
                                                formControlName="apply_date">
                                            <label class="custom-control-label" for="apply_date">Paid Date</label>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <ng-container *ngIf="admission_search_form.value.apply_date"> -->
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Payment Start
                                            date</label>
                                        <input type="date" class="form-control" formControlName="start_date">
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Payment End
                                            date</label>
                                        <input type="date" class="form-control" formControlName="end_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Gender</label>
                                        <select formControlName="gender" class="form-control">
                                            <option value="">All Gender</option>
                                            <option
                                                *ngFor="let gender of genders;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="gender.id">
                                                {{gender.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Community</label>
                                        <!-- <kendo-multiselect [data]="commonEnums.community_list" formControlName="community_ids"
                                                            placeholder="Select Community Name(s)" class="menu_select"></kendo-multiselect> -->
                                        <ng-multiselect-dropdown [placeholder]="'Select Community Name(s)'"
                                            [settings]="dropdownSettings" [data]="commonEnums.community_list"
                                            formControlName="community_id">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <!--                                 </ng-container> -->

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Amount Settlement
                                            status</label>
                                        <select formControlName="settlement_status" class="form-control">
                                            <option value="d">Select Amount Settlement Status</option>
                                            <option
                                                *ngFor="let status of settlement_status;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="status.id">
                                                {{status.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Settlement
                                            Start
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="settlement_start_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Settlement
                                            End
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="settlement_end_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Gateway
                                            Status</label>
                                        <select formControlName="gateway_processed" class="form-control">
                                            <option value="d">Select Gateway status</option>
                                            <option
                                                *ngFor="let status of gateway_processed_status;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="status.id">{{status.name}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Split
                                            Status</label>
                                        <select formControlName="gateway_split_processed" class="form-control">
                                            <option value="d">Select Split status</option>
                                            <option
                                                *ngFor="let status of split_status;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="status.id">{{status.name}}</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!applicant_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="applicant_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                        <button type="button" class="mg-t-30 btn mr-2 search-button" title="Search"
                                            *ngIf="!applicant_list_loading" (click)="onClearSearch()">
                                            Clear Search
                                        </button>




                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>


                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <div class="dropdown">
                                <button aria-expanded="false" aria-haspopup="true" class="btn ripple btn-info"
                                    data-toggle="dropdown" id="dropDownMenuButton" type="button">Download<i
                                        class="fas fa-caret-down ml-1"></i></button>
                                <div class="dropdown-menu tx-13">
                                    <a class="dropdown-item" (click)="downloadCSV()">Download CSV</a>
                                    <a class="dropdown-item" (click)="printMeritList()"
                                        *ngIf="admission_search_form.value.fee_type == 'application' ">Download
                                        Application Merit
                                        Report</a>
                                </div>

                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Prg. Type</th>
                                        <th>Prg.</th>
                                        <th>Application No.</th>
                                        <th>Name</th>
                                        <th>Mobile Number</th>
                                        <th>Gender</th>
                                        <th style="width: 10%;">Amount</th>
                                        <th>Order Id.</th>
                                        <th>Gateway Id.</th>
                                        <th>Bank Ref. Id.</th>
                                    </tr>
                                </thead>
                                <tbody>



                                    <ng-container
                                        *ngFor="let applicant of applicant_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td scope="row">
                                                {{i+1}}

                                            </td>
                                            <td class="uppercase">
                                                {{applicant.payment_init_date|date:commonEnums.date_format}}</td>
                                            <td class="uppercase">{{applicant.programme_type_id}}</td>
                                            <td class="uppercase">{{applicant.programme_name}}
                                                {{applicant.finance_type=='self_finance'?' (SF)': '(R)'}}</td>
                                            <td class="uppercase">{{applicant.application_no}}</td>
                                            <td class="uppercase">{{applicant.name}}</td>
                                            <td class="uppercase">

                                                {{applicant.mobile_number}}
                                                <ng-container *ngIf="applicant.mobile_number_2">
                                                    , {{applicant.mobile_number_2}}
                                                </ng-container>
                                                <ng-container *ngIf="applicant.mobile_number_3">
                                                    , {{applicant.mobile_number_3}}
                                                </ng-container>
                                                <ng-container *ngIf="applicant.mobile_number_4">
                                                    , {{applicant.mobile_number_4}}
                                                </ng-container>
                                                <ng-container *ngIf="applicant.mobile_number_5">
                                                    , {{applicant.mobile_number_5}}
                                                </ng-container>
                                            </td>
                                            <td class="uppercase">{{applicant.gender}}</td>
                                            <ng-container
                                                *ngIf="this.admission_search_form.value.fee_type=='application'">
                                                <td class="uppercase">{{applicant.amount|currency:'INR':''}}</td>
                                                <td>{{applicant.order_id}}</td>
                                                <td>{{applicant.gateway_id}}</td>
                                                <td>{{applicant.bank_ref_no}}</td>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="this.admission_search_form.value.fee_type=='programme'">
                                                <td class="uppercase">{{applicant.prg_amount|currency:'INR':''}}</td>
                                                <td>{{applicant.order_id}}</td>
                                                <td>{{applicant.gateway_id}}</td>
                                                <td>{{applicant.bank_ref_no}}</td>
                                            </ng-container>



                                        </tr>
                                    </ng-container>




                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>


        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Excess Application Amount Report</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            Students who have made multiple paymens for a single programme application leading to excess
                            amount deducted
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Applicant Name</th>
                                            <th>Applied Programme Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let payment of excess_paid_list; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{payment.name}}</td>
                                            <td>{{getProgrammeName(payment.programme_id)}}</td>
                                        </tr>
                                        <ng-container *ngIf="excess_paid_list.length ==0 && excess_paid_list_loading">
                                            <tr>
                                                <td colspan="3">
                                                    <b>Loading...</b>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="excess_paid_list.length ==0 && !excess_paid_list_loading">
                                            <tr>
                                                <td colspan="3">
                                                    <b>No Records Found</b>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>

                            <div class="text-center">
                                <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>