import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-fees-type-master',
  templateUrl: './fees-type-master.component.html',
  styleUrls: ['./fees-type-master.component.scss']
})
export class FeesTypeMasterComponent implements OnInit {
  fonts = fonts;
  fees_type_list_loading:boolean = false;
  fees_setting_form: FormGroup; 
  programme_type_id: string = ""; //ngModel
  title = 'Exam Fees Type Master Settings';
  fees_type_list: {
    id:string,
    type: string
    programme_type_id: string
  } [ ] = [ ];
  category_list: any[] = [];
  category_list_loading = false;
  fromDataResolver = FromDataResolver;
    ngOnInit(): void {
      this.getProgrammeTypeList();
    }
    constructor(private restService:AdminservicesService, private notificationService:NotificationService,
      private formBuilder:FormBuilder, private router:Router, private route:ActivatedRoute) { 
      this.fees_setting_form = this.formBuilder.group({
        type:['', [Validators.required]],
        programme_type_id: ['',[Validators.required]]
      });
    }
    prepareVariablesBeforeLoad()
    {
      this.fees_type_list = []; this.fees_type_list_loading=true;
    }
    prepareVariablesAfterLoad()
    {
      this.fees_type_list_loading=false;
    }  
    async getFeesTypeList() //get from API
    {
        try {
          this.prepareVariablesBeforeLoad();
          const service_response = await this.restService.getExamFeesTypeList({"programme_type_id":this.programme_type_id});
          if (service_response && service_response.success) {
            this.fees_type_list = service_response.data;
          }
        } catch (error) {
          alert('Error while read data');
        } finally {
          this.prepareVariablesAfterLoad();
        }
    }

    onFilterSelectChange(programme_type_id:string, ev:any)
    {

    }
    async getProgrammeTypeList(): Promise<void> {
      try {
        this.category_list_loading = true;
        this.category_list = [];
        const service_response = await this.restService.getProgrammeTypes();
        if (service_response && service_response.success) {
          this.category_list = service_response.data;
          console.log(this.category_list);
        }
      } catch (error) {
        alert('Error while read programme type');
      } finally {
        this.category_list_loading = false;
      }
    }
    
    async onAddClick()
    {
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    }

    async onCloseClick(): Promise<void> {
      this.reset();
      JQueryHelper.closeModal('#modal-popup');
    }
    isInvalid(formControl: string): boolean {
      return this.fees_setting_form.touched && this.fees_setting_form.get(formControl)?.errors !== null;
    }

    canDisable(): boolean {
      return this.fees_setting_form.status !== 'VALID';
    }

    onSave(): void {
      this.addItem();
      this.reset();
  }

    onSaveAndClose():void
    {
      this.onSave();
      this.onCloseClick();
    }
    reset(): void {
      this.fees_setting_form.reset();
    }


    async addItem(): Promise<void> {
    try { 
      this.fees_setting_form.get('type')?.setValue((this.fees_setting_form.get('type')?.value as string).toLowerCase());
      const res = await this.restService.createExamFeeType(this.fees_setting_form.value);
      if (!res) {
        this.notificationService.setNotifcation({ message: 'Failed to create exam fee type', type: 'Failed'});
      }
      this.getFeesTypeList();
      this.notificationService.setNotifcation({ message: 'Created exam fee type successfully', type: 'Success'});
      
    } catch (error) {
      this.notificationService.setNotifcation({ message: 'Failed to create exam fee type', type: 'Failed'});
    }
    }

    async onDeleteClick(fees:any)
    {
      const consent = confirm('Are you sure do you want to delete?');
      if (!consent) {
          return;
      }
      const res = await this.restService.deleteExamFeeType(fees.id);
      if(res.success)
      {
        this.notificationService.setNotifcation({ message: 'Deleted exam fee type successfully', type: 'Success'});
        this.getFeesTypeList();
      }
      else
      {
        this.notificationService.setNotifcation({ message: 'Failed to delete exam fee type', type: 'Failed'});
      }
    }


}
