import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { OfflinePaymentUpdateRequest } from 'src/app/services/rest/entity/OfflinePaymentUpdateRequest';


@Component({
  selector: 'app-course-payments',
  templateUrl: './course-payments.component.html',
  styleUrls: ['./course-payments.component.scss']
})
export class CoursePaymentsComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;



  // Supporting master data
  payment_status_list = [
    {
      id: 'PAID', text: 'Paid'
    }, {
      id: 'NOT_PAID', text: 'Not Paid'
    }, {
      id: 'PAYMENT_FAILED', text: 'Payment Failed'
    }
  ];


  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;

  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];

  // Query Data
  fee_schedule_id = '';

  // core data

  students_list: any[] = [];
  student_list_loading = false;


  // payment correction

  payment_correction_form: any;
  payment_correction_form_save_loading = false;
  payment_modes = [
    {
      id: 'ONLINE_FAILED', text: 'Online Failed'
    }, {
      id: 'OFFLINE', text: 'Offline'
    }
  ];


  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(params => {
      this.fee_schedule_id = params.fee_schedule_id;
    });

  }

  ngOnInit(): void {

    // Load master data
    this.getProgrammeTypeList();

    // Load core data
    this.buildFilterForm();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {

      };
      if (this.programme_search_form) {
        if (this.programme_search_form.value.programme_type_id) {
          search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
        }
        if (this.programme_search_form.value.finance_type) {
          search_form_query.finance_type = this.programme_search_form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read  programmes');
    } finally {
      this.programme_list_loading = false;
    }
  }


  /// master data

  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      programme_id: [''],
      programme_type_id: [''],
      finance_type: [''],
      enrolled_year: [2020],
      gender: [''],
      payment_status: [''],
      roll_no: [''],
      transaction_id: [''],
      start_date: [DateHelper.convertToControlDate(new Date())],
      end_date: [DateHelper.convertToControlDate(new Date())],
      apply_date_filter: [false]
    });

  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    }


  }


  async onSearchClick(): Promise<void> {
    await this.searchFormDataList();

  }




  async searchFormDataList(): Promise<void> {
    try {

      if (!this.fee_schedule_id) {
        alert('Please Select Fee Schedule');
        return;
      }
      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please Select Enrolled Year');
        return;
      }



      let valid_search = false;
      if (this.programme_search_form.value.programme_id) {
        valid_search = true;
      }
      if (this.programme_search_form.value.programme_type_id) {
        valid_search = true;
      }
      if (this.programme_search_form.value.finance_type) {
        valid_search = true;
        if (!this.programme_search_form.value.programme_type_id) {
          alert('Please select programme type');
          return;
        }
      }

      if (this.programme_search_form.value.roll_no) {
        valid_search = true;
      }
      if (this.programme_search_form.value.transaction_id) {
        valid_search = true;
      }
      if (this.programme_search_form.value.payment_status) {
        valid_search = true;
      }
      if (!valid_search) {
        alert('Select any one filter');
        return;
      }
      this.student_list_loading = true;
      this.students_list = [];
      const search_form_query: any = {
        enrolled_year: this.programme_search_form.value.enrolled_year,
        fee_schedule_id: this.fee_schedule_id
      };
      if (this.programme_search_form.value.payment_status) {
        search_form_query.payment_status = this.programme_search_form.value.payment_status;
      }
      if (this.programme_search_form.value.programme_id) {
        search_form_query.programme_id = this.programme_search_form.value.programme_id;
      }
      if (this.programme_search_form.value.gender) {
        search_form_query.gender = this.programme_search_form.value.gender;
      }
      if (this.programme_search_form.value.programme_type_id) {
        search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
      }
      if (this.programme_search_form.value.roll_no) {
        search_form_query.roll_no = this.programme_search_form.value.roll_no;
      }
      if (this.programme_search_form.value.transaction_id) {
        search_form_query.transaction_id = this.programme_search_form.value.transaction_id;
      }
      if (this.programme_search_form.value.finance_type) {
        search_form_query.finance_type = this.programme_search_form.value.finance_type;
      }
      if (this.programme_search_form.value.apply_date_filter === true) {

        const start_date = DateHelper.getStartDate(this.programme_search_form.value.start_date, 'Please select valid start date');
        if (start_date) {
          search_form_query.start_date = start_date.toISOString();
        }

        const end_date = DateHelper.getEndDate(this.programme_search_form.value.end_date, 'Please select valid end date');
        if (end_date) {
          search_form_query.end_date = end_date.toISOString();
        }

      }


      const service_response = await this.restService.searchStudentFeesReport(search_form_query);
      if (service_response && service_response.success) {
        this.students_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data student list');
    } finally {
      this.student_list_loading = false;
    }
  }

  findPaymentStatusLabel(status: string): string {
    if (status === 'initiated' || status === 'pending') {
      return 'NOT PAID';
    } else if (status === 'payment_done') {
      return 'PAID';
    } else if (status === 'payment_failure') {
      return 'NOT PAID - PAYMENT FAILED';
    }
    return status;
  }

  printReceipt(data: any): void {
    let receipt_url = this.restService.getReceiptDownloadURL();
    const token = CookieStore.getToken();
    receipt_url = receipt_url + '?fee_schedule_id=' + this.fee_schedule_id + '&sub=' + data.sub + '&enrolled_year=' +
      this.programme_search_form.value.enrolled_year + '&access_token=' + token;
    HTMLHelper.openLinkInNewTab(receipt_url);
  }

  notifyStudent(data: any): void {
    alert('This is feature is comming soon');
  }



  async onInitPaymentCorrectionClick(student: any): Promise<void> {
    this.buildPaymentCorrectionForm(student);
    JQueryHelper.openModal('#modal-popup-payment-correction', { keyboard: false, backdrop: 'static' });
  }

  buildPaymentCorrectionForm(student: any): void {
    let transaction_id = '';
    if (student.payment_transaction_id) {
      transaction_id = student.payment_transaction_id;
    }
    this.payment_correction_form = this.fb.group({
      sub: [student.sub, [Validators.required]],
      payment_mode: [this.payment_modes[0].id, [Validators.required]],
      payment_tracking_id: [student.payment_tracking_id, [Validators.required]],
      transaction_id: [transaction_id, [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  async onPaymentCorrectionCloseClick(): Promise<void> {
    this.resetPaymentCorrectionForm();
    JQueryHelper.closeModal('#modal-popup-payment-correction');
  }
  resetPaymentCorrectionForm(): void {
    this.payment_correction_form = undefined;
  }

  async onFinishPaymentCorrectionClick(): Promise<void> {
    try {
      const consent = confirm('Are you sure do you want to update payment?');
      if (!consent) {
        return;
      }
      this.payment_correction_form_save_loading = true;

      const offlinePaymentUpdateRequest: OfflinePaymentUpdateRequest = this.payment_correction_form.value;
      if (!offlinePaymentUpdateRequest.transaction_id || !offlinePaymentUpdateRequest.transaction_id.trim()) {
        alert('Please enter the transaction referance or transaction id');
        return;
      }
      const saved_student_response = await this.restService.handleOfflineCoursePayment(this.payment_correction_form.value);
      if (saved_student_response && saved_student_response.success === true) {
        alert('Updated Successfully');
        this.onPaymentCorrectionCloseClick();
        await this.searchFormDataList();
      } else {
        alert('Error while Update');
      }


    } catch (error) {
      alert('Error while read data');
    } finally {
      this.payment_correction_form_save_loading = false;
    }
  }

  downloadAsCSV(): void {
    const data_list: any[] = [];
    let i = 1;
    for (const student of this.students_list) {


      data_list.push({
        'S.No': i,
        'ROLL NO.': student.roll_no,
        NAME: student.given_name + (student.middle_name ? ' ' + student.middle_name : ''),
        GENDER: student.gender ? student.gender.toUpperCase() : 'N/A',
        'MOBILE NO.': student.mobile_number,
        EMAIL: student.email,
        TERM: student.payment_term,
        AMOUNT: student.amount,
        STATUS: this.findPaymentStatusLabel(student.payment_status),
        'TRANSACTION ID': student.payment_transaction_id,
        'PAID DATE': student.payment_completed_at,
      });
      i++;
    }



    CSVHelper.downloadCSV(data_list, 'programme-fees-collection-as-of-' + DateHelper.convertToControlDate(new Date()));
  }
}
