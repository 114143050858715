<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">Staffs Attendance Report</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <p class="tx-12 tx-gray-500 mb-2">Manage Staff Attendance</p>
                    </div>

                    <div class="card-body" *ngIf="department_search_form">
                        <form [formGroup]="department_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Department
                                            Type</label>
                                        <select formControlName="department_category_id" class="form-control"
                                            (change)="onFilterSelectChange('department_category_id',$event)">
                                            <option value="">Select Department Type</option>
                                            <option
                                                *ngFor="let department_type of department_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department_type.department_category_id">
                                                {{department_type.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Finance
                                            Type</label>
                                        <select formControlName="finance_type" class="form-control"
                                            (change)="onFilterSelectChange('finance_type',$event)">
                                            <option value="">Select Finance Type</option>
                                            <option
                                                *ngFor="let finance_type of commonEnums.finance_types;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="finance_type.id">{{finance_type.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Department</label>
                                        <select formControlName="department_id" class="form-control"
                                            (change)="searchFormDataList($event)">
                                            <option value="">All Department</option>
                                            <option
                                                *ngFor="let department of department_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="department.department_id">{{department.department_name}}
                                                {{department.finance_type=="regular"?"(R)":"(SF)"}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Roll No.</label>
                                        <kendo-autocomplete #course id="roll_no" [data]="staffData"
                                            [valueField]="'roll_no'" [filterable]="true"
                                            (filterChange)="handleStaffFilter($event)"
                                            [placeholder]="'Enter Staff Name/Code'" [suggest]="true"
                                            formControlName="staff_id" style="width: 100%;">
                                            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                                                <span>{{ dataItem.given_name }} ({{dataItem.roll_no}})</span>
                                            </ng-template>
                                        </kendo-autocomplete>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Start
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="start_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select End
                                            Date</label>
                                        <input type="date" class="form-control" formControlName="end_date">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!report_loading" (click)="getReport()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="report_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 10%;">Date</th>
                                        <th style="width: 15%;">Hour No.</th>
                                        <th style="width: 20%;">Class Name</th>
                                        <th style="width: 20%;">Course Name (Code)</th>
                                        <th style="width: 10%;">Status</th>
                                        <th style="width: 10%;">Is Adjusted</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of report_data; let i = index;">
                                        <td>{{i+1}}</td>
                                        <td>{{data.day_date | date:commonEnums.date_format}}
                                            <br> {{data.day_order}}
                                        </td>
                                        <td>{{data.hour_number}} <br>
                                            ({{data.start_time}} - {{data.end_time}})
                                        </td>
                                        <td>
                                            {{getProgrammeNameByList(data.enrolled_year, data.programme_data)}}
                                        </td>
                                        <td>{{data.course_name}}
                                            <br>({{data.course_code}})
                                        </td>
                                        <td>
                                            <span *ngIf="data.is_marked">Marked</span>
                                            <span *ngIf="!data.is_marked">Pending</span>
                                        </td>
                                        <td>
                                            <span *ngIf="data.is_adjusted">Adjusted</span>
                                            <span *ngIf="!data.is_adjusted">---</span>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-info" (click)="viewStudentAttendnace(data)">
                                                <fa-icon [icon]="fonts.faInfo"></fa-icon>
                                            </button>
                                            <button class="btn btn-sm btn-info mg-l-10" (click)="allowReEntry(data)"
                                                *ngIf="data.is_marked">
                                                <fa-icon [icon]="fonts.faUndo"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="!report_loading && report_data?.length ==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="report_loading && report_data?.length ==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Attendance Data...</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Large Modal -->
        <div class="modal" id="attendance-modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="popup_mode=='attendance'">
                    <div class="modal-header">
                        <h6 class="modal-title">Mark Attendance</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-6">
                                    Course Code: <b> {{modalData.course_code}}</b>
                                </div>
                                <div class="col-md-6">
                                    Course Code: <b>{{modalData.course_name}}</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    Class Name: <b>
                                        <!-- {{getProgrammeNameByList(modalData.enrolled_year,
                                        modalData.programme_data)}} -->
                                    </b>
                                </div>
                                <div class="col-md-6">
                                    Hour: <b>{{modalData.hour_number}}</b>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 10%;">S.No</th>
                                            <!-- <th class="text-center" style="width: 15%;">Photo</th> -->
                                            <th class="text-center" style="width: 15%;">Roll No.</th>
                                            <th class="text-center" style="width: 40%;">Name</th>
                                            <th class="text-center">
                                                <input type="checkbox" (change)="markPresent($event)"
                                                    [checked]="isChecked()"
                                                    title="Mark Present/Absent for all students">
                                                All Present
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let student of students_list.attendance_data; let i=index;trackBy:fromDataResolver.identify;">
                                            <td class="text-center">{{i+1}}</td>
                                            <!-- <td class="text-center"><img [src]="student.student_profile" width="100px"
                                                                                                height="50px"></td> -->
                                            <td class="text-center">{{student.roll_no}}</td>
                                            <td>{{student.given_name}} {{student.middle_name}}</td>
                                            <td class="text-center">
                                                <!-- <div class="is_present_checkbox">
                                                            <input type="checkbox" [(ngModel)]="student.is_present"
                                                                [attr.disabled]="(student.od_given==true)?true:null">
                                                            <p *ngIf="student!.od_given">(OD Given)</p>
                                                        </div> -->
                                                <div class="is_present_checkbox">
                                                    <label class="switch">
                                                        <input type="checkbox" [(ngModel)]="student.is_present"
                                                            [attr.disabled]="(student.od_given==true)?true:null">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <p *ngIf="student!.od_given">(OD Given)</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngIf="!student_data_loading && students_list?.attendance_data?.length ==0">
                                            <tr>
                                                <th colspan="4" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="student_data_loading && students_list?.attendance_data?.length ==0">
                                            <tr>
                                                <th colspan="4" class="text-center">Loading Students Data...</th>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="footer text-right mg-t-20">
                                <button class="btn btn-info mg-r-10" (click)="onVerifyClick()">Verify</button>
                                <button class="btn btn-secondary" (click)="onCloseClick()">Close</button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="modal-content modal-content-demo"
                    *ngIf="students_list?.attendance_data?.length>0 && popup_mode=='verify'">
                    <div class="modal-header">
                        <h6 class="modal-title">Confirm!</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-6">
                                    Course Code: <b> {{modalData.course_code}}</b>
                                </div>
                                <div class="col-md-6">
                                    Course Code: <b>{{modalData.course_name}}</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    Class Name: <b></b>
                                </div>
                                <div class="col-md-6">
                                    Hour: <b>{{students_list.hour_number}}</b>
                                </div>
                            </div>
                            Following students are marked absent for the hour. Are you sure you want to save this
                            attendance?
                            <div class="table-responsive"
                                *ngIf="absent_list!=undefined && absent_list?.attendance_data?.length>0">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th class="text-center">S.No</th>
                                            <th class="text-center">Roll No.</th>
                                            <th class="text-center">Name</th>
                                            <th class="text-center">
                                                Remove
                                            </th>
                                        </tr>
                                    </thead>
                                    <tr
                                        *ngFor="let student of absent_list!.attendance_data; let i=index;trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td>{{student.roll_no}}</td>
                                        <td>{{student.given_name}} {{student.middle_name}}</td>
                                        <td class="text-center">
                                            <!-- <div class="is_present_checkbox">
                                                        <input type="checkbox" [(ngModel)]="student.is_present">
                                                    </div> -->
                                            <fa-icon [icon]="fonts.faTimes" style="color: red; cursor: pointer;"
                                                (click)="removeStudentFromAbsentList(student)">
                                            </fa-icon>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div *ngIf="absent_list != undefined && absent_list!.attendance_data!.length==0">
                                Absentees: Nil
                            </div>
                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button class="btn btn-secondary" (click)="closeConfirmClick()">Back</button>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button class="btn btn-success" (click)="saveAttendance()"
                                        *ngIf="!student_save_loading">Save</button>
                                    <button class="btn btn-success" disabled
                                        *ngIf="student_save_loading">Saving...</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--End Large Modal -->



    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>