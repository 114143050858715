<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Request Elective Courses</p>
                    </div>


                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 15%;">Semester</th>
                                        <th style="width: 25%;">Part</th>
                                        <th style="width: 25%;">Course Type</th>
                                        <th style="width: 25%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="programme_course_list.length>0">
                                        <ng-container
                                            *ngFor="let course of programme_course_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{course.semester}}</td>
                                                <td>{{course.part}}</td>
                                                <td>{{course.course_type}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0"
                                                        *ngIf="!course.approved && !course?.course_code">
                                                        <button type="button" (click)="getAvailableProgrammes(course)"
                                                            title="Manage Request" class="btn btn-sm btn-info mr-2"
                                                            *ngIf="!availabe_search_loader">
                                                            Choose Course
                                                        </button>
                                                        <button type="button" disabled title="Manage Request"
                                                            class="btn btn-sm btn-info mr-2"
                                                            *ngIf="availabe_search_loader">
                                                            Loading...
                                                        </button>
                                                    </div>
                                                    <div *ngIf="course.approved">
                                                        <b>APPROVED!</b>
                                                    </div>
                                                    <div *ngIf="course.course_code">
                                                        <b>REQUESTED - {{course.course_code}}</b>
                                                    </div>

                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!programme_course_list_loading && programme_course_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Polling Process not started yet.</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="programme_course_list_loading && programme_course_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content modal-content-demo"
                    *ngIf="course_request_form || this.course?.rule_list?.length>0">
                    <div class="modal-header">
                        <h6 class="modal-title">Request Course</h6>

                    </div>
                    <div class="card-body" *ngIf="!is_group_polling && course_request_form">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Course Code</th>
                                        <th>Course Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <ng-container *ngFor="
                                                let elective_course of fromDataResolver.getFormArrayControlls(course_request_form,'courses_list')
                                                let i = index; trackBy: fromDataResolver.identify">
                                        <ng-container [formGroup]="elective_course">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{ elective_course.value.course_code }}
                                                </td>
                                                <td>
                                                    {{ elective_course.value.course_name }}
                                                </td>
                                                <td>
                                                    <button class="btn mg-r-10"
                                                        *ngIf="!elective_course.value.requested && !course_request_loader"
                                                        (click)="onRequestButtonClick(elective_course, i)"
                                                        [ngClass]="(elective_course.value.requested)?'btn-success':'btn-primary'">
                                                        Request
                                                    </button>
                                                    <button class="btn mg-r-10"
                                                        *ngIf="!elective_course.value.requested && course_request_loader"
                                                        disabled
                                                        [ngClass]="(elective_course.value.requested)?'btn-success':'btn-primary'">
                                                        Requesting...
                                                    </button>
                                                    <button class="btn btn-info"
                                                        (click)="viewSyllabus(elective_course)">View
                                                        Syllabus</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="is_group_polling">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 10%;">Group Name</th>
                                        <th>Available Courses</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor=" let group of this.course.rule_list[0].group_data; let i = index; trackBy: fromDataResolver.identify">
                                        <tr>
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{ group.group_name | titlecase }}
                                            </td>
                                            <td>
                                                <p *ngFor="let course_code of group.available_courses; let j = index;">
                                                    {{course_code}}
                                                    <button class="btn btn-info mg-l-15"
                                                        (click)="viewCourseSyllabus(course_code)">View Syllabus</button>
                                                </p>
                                            </td>
                                            <td>
                                                <button class="btn mg-r-10"
                                                    *ngIf="!group.requested && !group.show_loader"
                                                    (click)="onBulkRequestClick(group)"
                                                    [ngClass]="(group.requested)?'btn-success':'btn-primary'">
                                                    Request
                                                </button>
                                                <button class="btn mg-r-10"
                                                    *ngIf="!group.requested && group.show_loader" disabled
                                                    [ngClass]="(group.requested)?'btn-success':'btn-primary'">
                                                    Requesting...
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div class="card-body">
                        <div class="mg-b-20"></div>
                        <div class="text-align-right">
                            <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                type="button">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Large Modal -->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>