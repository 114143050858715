import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';

@Component({
  selector: 'app-student-transport-history',
  templateUrl: './student-transport-history.component.html',
  styleUrls: ['./student-transport-history.component.scss']
})
export class StudentTransportHistoryComponent implements OnInit {

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Student Transport History';
  student_list_loading: boolean = false;
  student_list: any[] = [];
  student: any = undefined;
  routes_list: {
    _id: string, route_name: string, amount: number,
    description: string, active: boolean
  }[] = [];
  routes_list_loading: boolean = false;
  route_id: string = '';
  popup_mode: string = '';
  sms_data: string = '';
  constructor() { }

  ngOnInit(): void {
    this.getRouteList();
  }

  async getRouteList() {
    this.routes_list = [];
    this.routes_list_loading = true;
    this.routes_list.push(
      {
        _id: "123",
        route_name: "Sivakasi",
        amount: 2000,
        description: "College to Sivakasi",
        active: true,
      },
      {
        _id: "1233",
        route_name: "Tirutangal",
        amount: 3000,
        description: "College to Tirutangal",
        active: true,
      },
    );
    /* try {
      const service_response = await this.restService.listTransportRoutes();
      if (service_response && service_response.success) {
        this.routes_list = service_response.data;
        console.log(this.routes_list);
      }
    } catch (error) {
      alert('Error while read data');
    } */
    this.routes_list_loading = false;
  }

  onSearchClick() {
    this.getStudentTransportHistory();
  }

  getStudentTransportHistory() {
    this.student_list.push({
      roll_no: '20UL34',
      name: 'Ajay Balaji K'
    },
      {
        roll_no: '20UM45',
        name: 'Vikram A'
      }
    );
  }

  viewTransportHistory(student) {
    this.popup_mode = 'history';
    this.student = student;
    JQueryHelper.openModal('#transport-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  openSMS(student) {
    this.popup_mode = 'sms';
    this.student = student;
    JQueryHelper.openModal('#transport-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  sendSMS() {

  }

  closeModal() {
    this.popup_mode = '';
    this.sms_data = '';
    JQueryHelper.closeModal('#transport-modal-popup');
  }

  viewMap() {
    JQueryHelper.openModal('#map-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  closeMap() {
    JQueryHelper.closeModal('#map-modal-popup');
  }
}
