<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Staff Attendance Late Entry Requests</p>
                    </div>
                    <div class="card-body">
                        <h6>Search By</h6>
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            name="filter_type" value="date_search" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Date
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            name="filter_type" value="roll_no_search" (change)="changeType($event)"
                                            id="flexRadioDefault1" checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Staff Roll No.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="type" type="radio"
                                            name="filter_type" value="course_code_search" (change)="changeType($event)"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            By Course code
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2" *ngIf="type=='date_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            Start Date</label>
                                        <input type="date" formControlName="start_date" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='date_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            End Date</label>
                                        <input type="date" formControlName="end_date" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='roll_no_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Roll
                                            No.</label>
                                        <input type="text" class="form-control" formControlName="roll_no"
                                            placeholder="Search with Staff Roll No..">
                                    </div>
                                </div>

                                <div class="col-lg-2" *ngIf="type=='course_code_search'">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row" *ngIf="table_data.length>0">
                            <div class="col-md-12" *ngIf="type=='date_search'">
                                <span>Date: {{filter_form.value.date}}</span>
                            </div>
                            <div class="col-md-6" *ngIf="type=='roll_no_search'">
                                <span>Staff Id: {{filter_form.value.roll_no}}</span>
                            </div>
                            <div class="col-md-6" *ngIf="type=='roll_no_search'">
                                <span>Staff Name: </span>
                            </div>
                            <div class="col-md-6" *ngIf="type=='course_code_search'">
                                <span>Course Code: {{filter_form.value.course_code}}</span>
                            </div>
                            <div class="col-md-6" *ngIf="type=='course_code_search'">
                                <span>Course Name: {{table_data[0]?.staff_name}}</span>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr style="text-align: center;">
                                        <th style="width: 5%;">S.No</th>
                                        <th *ngIf="type!='date_search'">Date</th>
                                        <th *ngIf="type!='roll_no_search'">Staff ID</th>
                                        <th *ngIf="type!='roll_no_search'">Staff Name</th>
                                        <th *ngIf="type!='course_code_search'">Course Code</th>
                                        <th *ngIf="type!='course_code_search'">Course Name</th>
                                        <th style="width: 10%;">Hour No.</th>
                                        <th style="width: 15%;">Reason.</th>
                                        <th style="width: 15%">Type</th>
                                        <th style="width: 10%;">Created at</th>
                                        <th style="width: 15%;">Reopen Status</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of table_data; let i = index; trackBy:fromDataResolver.identify;">
                                        <td>{{i+1}}</td>
                                        <td *ngIf="type!='date_search'">
                                            {{data.date | date:commonEnums.date_format}}
                                        </td>
                                        <td *ngIf="type!='roll_no_search'">
                                            {{data.staff_id}}
                                        </td>
                                        <td *ngIf="type!='roll_no_search'">
                                            {{data.staff_name}}
                                        </td>
                                        <td *ngIf="type!='course_code_search'">
                                            {{data.course_code}}
                                        </td>
                                        <td *ngIf="type!='course_code_search'">
                                            {{data.course_name}}
                                        </td>
                                        <td style="width: 10%;">
                                            {{data.hour_number}}
                                        </td>
                                        <td style="width: 15%;">
                                            {{data.reason}}
                                        </td>
                                        <td style="width: 15%;">
                                            {{data.type | titlecase}}
                                        </td>
                                        <td>
                                            {{data.createdAt | date:commonEnums.date_time_format}}
                                        </td>
                                        <td style="width: 15%;">
                                            {{data.re_open_request_status}}
                                        </td>
                                        <td style="width: 10%;">
                                            <button class="btn btn-success btn-sm"
                                                *ngIf="data.re_open_request_status == 'SUBMITTED'"
                                                (click)="approveStatus(data)">
                                                <fa-icon [icon]="fonts.faCheck"></fa-icon>
                                            </button>
                                            <button class="btn btn-danger btn-sm"
                                                *ngIf="data.re_open_request_status == 'ACCEPTED'"
                                                (click)="revertStatus(data)">
                                                <fa-icon [icon]="fonts.faUndo"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>

                                    <ng-container *ngIf="!table_data_loading && table_data.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center" *ngIf="type=='date_search'">No Records
                                                found</th>
                                            <th colspan="10" class="text-center" *ngIf="type=='roll_no_search'">No
                                                Records found</th>
                                            <th colspan="10" class="text-center" *ngIf="type=='course_code_search'">No
                                                Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="table_data_loading && table_data.length==0">
                                        <tr>
                                            <th colspan="11" class="text-center" *ngIf="type=='date_search'">Loading
                                                Please wait</th>
                                            <th colspan="10" class="text-center" *ngIf="type=='roll_no_search'">Loading
                                                Please wait</th>
                                            <th colspan="10" class="text-center" *ngIf="type=='course_code_search'">
                                                Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>