<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add Certificate</button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Certificates for Student Certificate Tracker</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 20%;">Programme Type</th>
                                        <th style="width: 30%;">Certificate Name</th>
                                        <th style="width: 20%;">Description</th>
                                        <th style="width: 20%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let certificate of certificate_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{certificate.programme_type_id}}
                                            </td>
                                            <td class="uppercase">
                                                {{certificate.certificate_name}}
                                            </td>
                                            <td class="uppercase">
                                                {{certificate.description}}
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-info mg-r-10"
                                                    (click)="onEditClick(certificate)">
                                                    <fa-icon [icon]="fonts.faPencilAlt" data-toggle="tooltip"
                                                        title="Edit Certificate"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!certificate_list_loading && certificate_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="certificate_list_loading && certificate_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Collection Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="certificate_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Manage Certificate</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="certificate_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Programme Type</label>
                                            <select formControlName="programme_type_id" class="form-control">
                                                <option value="">Select Programme Type</option>
                                                <option
                                                    *ngFor="let programme of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="programme.programme_type_id">{{programme.name}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('programme_type_id')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Programme Type is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Certificate
                                                Name</label>
                                            <input class="form-control" required="" type="text"
                                                placeholder="Enter Certificate Name" formControlName="certificate_name">
                                            <div *ngIf="isInvalid('certificate_name')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Certificate Name is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Certificate Description</label>
                                            <input class="form-control" type="text" formControlName="description">
                                        </div>
                                    </div>
                                </div>

                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.certificate_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.certificate_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.certificate_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.certificate_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.certificate_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.certificate_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.certificate_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.certificate_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.certificate_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.certificate_form.value._id && delete_loading"
                                    disabled>Deleting...</button>
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>