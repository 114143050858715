<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Report List</p>
                    </div>
                    <div class="card-body">
                        <div class="d-flex right-content mg-b-5" style="float: right;">
                            <button type="button" class="btn btn-info btn-md" (click)="onAddClick()">Add
                                New</button>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 50%;">Template Name</th>
                                        <th style="width: 50%;">Description</th>
                                        <th style="width: 50%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let reportdata of report; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ reportdata.template_name }}</td>
                                        <td>{{ reportdata.template_description }}</td>
                                        <td>
                                            <div style="display: flex; gap: 10px;">
                                                <ng-container *ngIf="!reportdata.has_programe_template">
                                                    <button class="btn btn-sm btn-info"
                                                        *ngIf="!reportdata.has_template_settings"
                                                        (click)="createTemplateSettings(reportdata._id, 'Save')">
                                                        <fa-icon [icon]="fonts.faCog" class="mg-r-3"
                                                            title="Add Settings"></fa-icon>
                                                    </button>

                                                    <button class="btn btn-sm btn-info"
                                                        *ngIf="reportdata.has_template_settings"
                                                        [disabled]="reportdata.has_programe_template"
                                                        (click)="getTemplatesetting(reportdata._id, i, 'Update')">
                                                        <fa-icon [icon]="fonts.faCog" class="mg-r-3"
                                                            title="Edit Settings"></fa-icon>
                                                    </button>
                                                </ng-container>

                                                <ng-container *ngIf="reportdata.has_programe_template">
                                                    <button class="btn btn-sm btn-info"
                                                        *ngIf="reportdata.has_template_settings"
                                                        (click)="viewTemplatesetting(reportdata._id, i, 'Update')">
                                                        <fa-icon [icon]="fonts.faInfo" class="mg-r-3"
                                                            title="View Settings"></fa-icon>
                                                    </button>
                                                </ng-container>



                                                <button class="btn btn-sm btn-primary"
                                                    (click)="getTemplateData(reportdata._id)">
                                                    <fa-icon [icon]="fonts.faPencilAlt" class="mg-r-3"
                                                        title="Edit"></fa-icon>
                                                </button>

                                                <button class="btn btn-sm btn-info"
                                                    *ngIf="reportdata?.program_details?.length > 0"
                                                    (click)="openMappedProgrammeModal(reportdata)">
                                                    <fa-icon [icon]="fonts.faList" class="mg-r-3"
                                                        title="Mapped Programmes"></fa-icon>
                                                </button>

                                                <button class="btn btn-sm btn-warning"
                                                    *ngIf="reportdata?.program_details?.length > 0"
                                                    (click)="navigateFilterPage(reportdata)">
                                                    <fa-icon [icon]="fonts.faFilter" class="mg-r-3"
                                                        title="Template Filter"></fa-icon>
                                                </button>


                                                <button class="btn btn-sm btn-danger"
                                                    [disabled]="reportdata.has_programe_template"
                                                    (click)="onDelete(reportdata._id)">
                                                    <fa-icon [icon]="fonts.faTrash" class="mg-r-3"
                                                        title="Delete"></fa-icon>
                                                </button>
                                            </div>



                                        </td>
                                    </tr>

                                    <!-- If there are no records in the report_list, show a message -->
                                    <tr *ngIf="report.length === 0 && !report_list_loading">
                                        <td colspan="4" class="text-center">No Records found</td>
                                    </tr>
                                    <tr *ngIf="report.length === 0 && report_list_loading">
                                        <td colspan="4" class="text-center">Loading please wait...</td>
                                    </tr>
                                </tbody>


                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Report Modal -->
<div class="modal" id="report-modal-popup">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="report_form">
            <div class="modal-header">
                <h6 class="modal-title">Template Creation</h6>
            </div>
            <div class="modal-body">
                <div class="card card-body pd-20 pd-md-40">
                    <form [formGroup]="report_form" class="form-horizontal">

                        <!-- Title -->
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Template
                                Name</label>
                            <input type="text" class="form-control" formControlName="template_name"
                                placeholder="Enter Template Name">
                            <div *ngIf="isInvalid('template_name')">
                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                    <li class="parsley-required">Template Name is required.</li>
                                </ul>
                            </div>
                        </div>
                        <!-- Description -->
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                Description</label>
                            <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                                formControlName="template_description">
                                    </textarea>

                        </div>
                        <!-- Academic Year -->
                        <div class="form-group">
                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Academic Year</label>
                            <input type="text" class="form-control" formControlName="academic_year"
                                placeholder="Enter Academic Year">
                        </div>

                        <div class="text-center" *ngIf="!this.report_form.value._id">
                            <!-- <ng-container *ngIf="report_save_loading"> -->
                            <button class="btn btn-primary mg-r-20" (click)="createTemplate()"
                                [disabled]="disableCreateForm()">Save</button>

                            <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                            <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                            <!-- </ng-container> -->
                        </div>
                        <div class="text-center" *ngIf="this.report_form.value._id">
                            <!-- <ng-container *ngIf="!report_save_loading"> -->
                            <button class="btn btn-primary mg-r-20" (click)="createTemplate()"
                                [disabled]="disableCreateForm()">Update</button>

                            <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                            <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                            <!-- </ng-container> -->
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Template-configuration Modal -->

<div class="modal" id="template-configuration-modal-popup">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
            <div class="modal-header">
                <h6 class="modal-title">Template Configuration</h6>
            </div>
            <div class="modal-body">
                <div class="card card-body pd-20 pd-md-40">
                    <form [formGroup]="templateForm" class="form-horizontal">
                        <div formArrayName="template_data">
                            <div *ngFor="let employee of template().controls; let templateIndex=index;"
                                [formGroupName]="templateIndex">
                                <div class="row">
                                    <div class="col-md-9">
                                        <h6 class="modal-title">
                                            <ng-container
                                                *ngIf="templateForm.value.template_data[templateIndex].format === 'Group'; else notNA">
                                                Group
                                            </ng-container>
                                            <ng-template #notNA>
                                                Section
                                            </ng-template>
                                        </h6>
                                    </div>
                                    <div class="col-md-3" style="text-align: right;">
                                        <button type="button" class="btn btn-sm btn-danger" style="margin-right: 2%;"
                                            (click)="removeSection(templateIndex)">Delete</button>
                                    </div>
                                </div>
                                <!-- Title -->
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Title</label>
                                    <input type="text" class="form-control" formControlName="title"
                                        placeholder="Enter Title Name">
                                    <div *ngIf="isSectionControlInvalid('title', templateIndex)">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Title is required.</li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- Description -->
                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                        Description</label>
                                    <textarea id="description" class="form-control" [placeholder]="'Enter Description'"
                                        formControlName="description">
                                                    </textarea>
                                </div>

                                <!-- File -->

                                <!-- <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">File</label><br>
                                    <input formControlName="file_link" type="file" (change)="uploadFile($event)">
                                </div> -->

                                <!-- <div class="form-group" >
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Current File Link</label>
                                    <input type="text" class="form-control" [value]="templateForm.get('file_link').value" readonly>
                                  </div> -->

                                <!-- Format -->

                                <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Format</label>
                                    <select formControlName="format" class="form-control">
                                        <option value="Group">Group</option>
                                        <option *ngFor="let formatOption of formatOptions" [value]="formatOption.key">{{
                                            formatOption.value }}</option>
                                    </select>
                                    <div *ngIf="isSectionControlInvalid('format', templateIndex)">
                                        <ul class="parsley-errors-list filled" id="parsley-id-9">
                                            <li class="parsley-required">Format is required.</li>
                                        </ul>
                                    </div>
                                </div>



                                <!-- List -->

                                <div *ngIf="templateForm.value.template_data[templateIndex].format === 'List'">
                                    <div formGroupName="list_data">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Header</label>
                                            <textarea id="description" class="form-control"
                                                [placeholder]="'Enter Header'" formControlName="header"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <!-- Table -->

                                <ng-container
                                    *ngIf="templateForm.value.template_data[templateIndex].format === 'Table'">
                                    <div class="table-responsive" formArrayName="table_data">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>S.No</th>
                                                <th>Column Title</th>
                                                <th>Column Format</th>

                                                <th style="width: 15%;">Action</th>
                                            </tr>
                                            <ng-container
                                                *ngFor="let skill of tables(templateIndex).controls; let tableIndex=index;"
                                                [formGroupName]="tableIndex">
                                                <tr>
                                                    <td>{{tableIndex+1}}</td>
                                                    <td>

                                                        <input type="text" class="form-control"
                                                            formControlName="column_name" placeholder="Enter Title">
                                                    </td>
                                                    <td>

                                                        <select formControlName="column_format" class="form-control">
                                                            <option value="">Select Format</option>
                                                            <option *ngFor="let formatOption of formats"
                                                                [value]="formatOption.key">{{formatOption.value}}
                                                            </option>
                                                        </select>
                                                        <!-- <input type="text" class="form-control" formControlName="value" placeholder="Enter Value"> -->
                                                    </td>

                                                    <td>
                                                        <button type="button" class="btn btn-danger"
                                                            (click)="removeColumn(templateIndex,tableIndex)">Delete</button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td colspan="5" class="text-center">
                                                    <button type="button" class="btn btn-secondary"
                                                        (click)="addTableRow(templateIndex)">Add More</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                </ng-container>
                            </div>
                        </div>

                        <div>
                            <button type="button" class="btn btn-info mg-r-10"
                                style="margin-left: 39%; margin-top: 10%;" (click)="addSection()">Add New
                                Section</button>
                        </div>
                        <div class="text-align-right">
                            <button class="btn btn-primary mg-r-20" style="margin-top: 10%; margin-right: 10%;"
                                [disabled]="disableSettingsForm()" *ngIf="!this.templateForm.value._id"
                                (click)="addTemplateSettings()">Save</button>
                            <button class="btn btn-primary mg-r-20" style="margin-top: 10%; margin-right: 10%;"
                                [disabled]="disableSettingsForm()" *ngIf="this.templateForm.value._id"
                                (click)="updateSetting()">Update</button>
                            <button class="btn ripple btn-secondary" style="margin-top: 10%; margin-right: 33%;"
                                (click)="onClosedClick()" type="button">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!--View Mapped Pgms-->
<div class="modal" id="programmes-modal-popup">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="report_form">
            <div class="modal-header">
                <h6 class="modal-title">Template-Mapped Programmes</h6>
            </div>
            <div class="modal-body">
                <div class="card card-body pd-20 pd-md-40">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="width: 10%;">S.No</th>
                                    <th style="width: 20%;">Programme Type</th>
                                    <th>Programme Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let programme of mapped_programmes; let i = index;">
                                    <td>{{i+1}}</td>
                                    <td>{{programme.programe_type_id}}</td>
                                    <td>{{programme.programme_name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="text-align-right">
                    <button class="btn ripple btn-secondary" style="margin-top: 10%; margin-right: 33%;"
                        (click)="closeMapModal()" type="button">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!--View Template Settings-->
<div class="modal" id="view-configuration-modal-popup">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo">
            <div class="modal-header">
                <h6 class="modal-title">View Template Configuration</h6>
            </div>
            <div class="modal-body">
                <div class="card card-body pd-20 pd-md-40">
                    <form [formGroup]="templateForm" class="form-horizontal">
                        <div formArrayName="template_data">
                            <div *ngFor="let employee of template().controls; let templateIndex=index;"
                                [formGroupName]="templateIndex">
                                <div class="row">
                                    <div class="col-md-9">
                                        <h6 class="modal-title">
                                            <ng-container
                                                *ngIf="templateForm.value.template_data[templateIndex].format === 'Group'; else notNA">
                                                Group
                                            </ng-container>
                                            <ng-template #notNA>
                                                Section
                                            </ng-template>
                                        </h6>
                                    </div>
                                </div>
                                <!-- Title -->
                                <div class="form-group">
                                    <p><b>Title: {{employee.value.title}}</b></p>
                                </div>
                                <!-- Description -->
                                <div class="form-group">
                                    <p>Description: {{employee.value.description}}</p>
                                </div>

                                <!-- Format -->
                                <p>Format: {{employee.value.format}}</p>
                                <!-- List -->

                                <div *ngIf="templateForm.value.template_data[templateIndex].format === 'List'">
                                    <div formGroupName="list_data">
                                        <p>List Heading: {{employee.value.list_data.header}}</p>
                                    </div>
                                </div>

                                <!-- Table -->

                                <ng-container
                                    *ngIf="templateForm.value.template_data[templateIndex].format === 'Table'">
                                    <div class="table-responsive" formArrayName="table_data">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>S.No</th>
                                                <th>Column Title</th>
                                                <th>Column Format</th>
                                            </tr>
                                            <ng-container
                                                *ngFor="let skill of tables(templateIndex).controls; let tableIndex=index;"
                                                [formGroupName]="tableIndex">
                                                <tr>
                                                    <td>{{tableIndex+1}}</td>
                                                    <td>
                                                        {{skill.value.column_name}}
                                                    </td>
                                                    <td>
                                                        {{skill.value.column_format}}
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </table>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn ripple btn-secondary" style="margin-top: 10%;"
                                (click)="onViewClosedClick()" type="button">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
