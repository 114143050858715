<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Student Internal Exam Answer Sheet Evaluation</p>
                    </div>


                    <div class="card-body" *ngIf="student_search_form">
                        <form [formGroup]="student_search_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select Programme
                                            Type</label>
                                        <select formControlName="programme_type_id" class="form-control">
                                            <option value="">Select Programme Type</option>
                                            <option
                                                *ngFor="let programme_type of programme_type_list;let i=index;trackBy:fromDataResolver.identify;"
                                                [value]="programme_type.programme_type_id">{{programme_type.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select enrollment
                                            year</label>
                                        <select formControlName="enrolled_year" class="form-control">
                                            <option value="">Select enrollment year</option>
                                            <option
                                                *ngFor="let year of commonEnums.getLastNYears(20);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                            semester</label>
                                        <select formControlName="semester" class="form-control">
                                            <option value="">Select Semester</option>
                                            <option
                                                *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                [ngValue]="sem.id">{{sem.text}}</option>
                                        </select>
                                    </div>
                                </div>

                                <ng-container *ngIf="cookieStore.isRoleExists(['coe', 'principal']) ">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Search by
                                                Course Code</label>
                                            <input type="text" class="form-control" formControlName="course_code"
                                                placeholder="Search with Course Code">
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-2">
                                    <div class="form-group">

                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!course_list_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="course_list_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th>Course Code</th>
                                        <th>Component Name</th>
                                        <th>Staff No.</th>
                                        <th>Programme Name</th>
                                        <th>Section</th>
                                        <th>Virtual Section</th>
                                        <th style="width: 15%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="course_list.length>0">
                                        <ng-container
                                            *ngFor="let course of course_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{course.course_code}}</td>
                                                <td>{{course.valuations.component_name}}</td>
                                                <td>{{course.valuations.roll_no}}</td>
                                                <td>{{getProgrammeName(course.valuations.programme_id)}}</td>
                                                <td>{{course.valuations.section}}</td>
                                                <td>{{course.valuations.virtual_section}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" (click)="displayStudents(course,i)"
                                                            class="btn btn-sm btn-info mr-2 mg-t-5"
                                                            *ngIf="!course.is_opened">
                                                            View Students
                                                        </button>
                                                        <button type="button" (click)="closeStudents(course,i)"
                                                            class="btn btn-sm btn-info mr-2 mg-t-5"
                                                            *ngIf="course.is_opened">
                                                            Close Students
                                                        </button>
                                                        <!-- <button type="button" (click)="navigateToCoReport(course)"
                                                            class="btn btn-sm btn-info mr-2 mg-t-5">
                                                            View CO Report
                                                        </button>
                                                        <button type="button" (click)="navigateToFoilCard(course)"
                                                            class="btn btn-sm btn-info mr-2 mg-t-5">
                                                            Foil Card
                                                        </button> -->
                                                        <button type="button" (click)="viewScoreSheet(course,i)" class="btn btn-sm btn-info 
                                                        mr-2 mg-t-5" *ngIf="!score_sheet_data_loading[i]">
                                                            View Score Sheet
                                                        </button>
                                                        <button type="button" disabled class="btn btn-sm btn-info 
                                                            mr-2 mg-t-5" *ngIf="score_sheet_data_loading[i]">
                                                            Loading...
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="course.is_opened">
                                                <td colspan="9">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div style="margin-bottom: 10px; float: right;">
                                                                <app-modal [render_data]="bulk_upload_mark_data"
                                                                    (csvData)="bulkUploadMarkData($event)"></app-modal>
                                                                <button class="btn btn-info btn-sm"
                                                                    (click)="getStudentData()">Refresh List</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>Roll No</th>
                                                                    <th>Name</th>
                                                                    <th style="width: 20%;">File Upload</th>
                                                                    <th style="width: 10%;">Status</th>
                                                                    <th style="width: 15%;">File Action</th>
                                                                    <th *ngFor="let head of student_data[0]?.co_items">
                                                                        CO{{head.co_id}}
                                                                    </th>
                                                                    <th>Sub Total<br>
                                                                        ({{student_data[0]?.max_total}})</th>
                                                                    <th>Total<br>
                                                                        ({{student_data[0]?.scale_to}})</th>
                                                                    <th>Final<br>
                                                                        ({{student_data[0]?.scale_to}})</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let student of student_data; let j=index;trackBy:fromDataResolver.identify;">
                                                                    <td>{{j+1}}</td>
                                                                    <td>{{student.student_roll_no}}</td>
                                                                    <td>{{student.student_name}}</td>

                                                                    <td>
                                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                                            <input class="form-control" required=""
                                                                                type="file" accept="application/pdf"
                                                                                [(ngModel)]="files[j]"
                                                                                (change)="openModal($event,student,j)">
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p *ngIf="student.submission_detail?.file_path">
                                                                            <b>{{student.submission_detail?.stage
                                                                                |
                                                                                titlecase}}</b>
                                                                        </p>
                                                                        <span
                                                                            (click)="viewPreview(student.submission_detail)"
                                                                            style="cursor: pointer;"
                                                                            *ngIf="student.submission_detail?.file_path && student.submission_detail?.file_path!=''"><u>Preview</u></span>
                                                                        <span
                                                                            *ngIf="!student.submission_detail?.file_path"
                                                                            style="color: red;"><b>Not
                                                                                submitted</b></span>
                                                                    </td>
                                                                    <td>
                                                                        <ng-container
                                                                            *ngIf="student.submission_detail?.stage =='SUBMITTED'">
                                                                            <button
                                                                                class="btn btn-success btn-sm mg-r-10"
                                                                                (click)="reviewFile(student.submission_detail,'APPROVED')">
                                                                                Approve
                                                                            </button>
                                                                            <button class="btn btn-danger btn-sm"
                                                                                (click)="reviewFile(student.submission_detail,'REJECTED')">
                                                                                Reject
                                                                            </button>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="student.submission_detail?.stage == 'APPROVED' || student.submission_detail?.stage =='REJECTED'">
                                                                            <button class="btn btn-info btn-sm"
                                                                                (click)="revertStage(student.submission_detail)">
                                                                                Change
                                                                            </button>
                                                                        </ng-container>
                                                                    </td>




                                                                    <td *ngFor="let co of student.co_items">
                                                                        {{co.mark}}
                                                                    </td>
                                                                    <!-- <td>{{student.total}}</td> -->
                                                                    <td>{{student.sub_total | number : '1.2-2'}}</td>
                                                                    <td>{{student.total_raw | number : '1.2-2' }}</td>
                                                                    <td>{{student.total}}</td>
                                                                    <td>
                                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                                            <button type="button"
                                                                                (click)="onEvaluateClick(student)"
                                                                                class="btn btn-sm btn-info mr-2">
                                                                                Evaluate
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <ng-container
                                                                    *ngIf="!student_list_loading[i] && student_data.length==0">
                                                                    <tr>
                                                                        <th colspan="10" class="text-center">No Records
                                                                            found</th>
                                                                    </tr>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="student_list_loading[i] && student_data.length==0">
                                                                    <tr>
                                                                        <th colspan="10" class="text-center">Loading
                                                                            Please wait</th>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="!course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_list.length==0">
                                        <tr>
                                            <th colspan="9" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Settings Large Modal -->
        <div class="modal" id="score-modal-popup">
            <div class="modal-dialog modal-full" role="document">
                <div class="modal-content modal-content-demo" *ngIf="score_card_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Answer Valuation</h6>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close"
                            (click)="onCloseClick()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">

                            <div class="row">
                                <div class="col-md-8"
                                    *ngIf="this.submission_detail?.file_path && this.submission_detail?.file_path!=''">
                                    <h6>Student Answer Sheet</h6>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <span>Roll No: <b>{{this.score_card.student_roll_no}}</b></span>
                                        </div>
                                        <div class="col-md-8 text-right">
                                            <span>Name: <b>{{this.score_card.student_name}}</b></span>
                                        </div>
                                    </div>
                                    <pdf-viewer [src]="this.submission_detail.file_path" [render-text]="false"
                                        [original-size]="true" [fit-to-page]="true" [show-borders]="true"
                                        [zoom]="zoom_to" style="height: 70vh;">
                                    </pdf-viewer>
                                    <div class="row mg-t-5">
                                        <div class="col-md-12 text-right">
                                            <button class="btn btn-info btn-sm mg-r-10" (click)="zoomOut()"
                                                [disabled]="(zoom_to==1)?true:null">
                                                <fa-icon [icon]="fonts.faSearchMinus"></fa-icon>
                                            </button>
                                            <button class="btn btn-info btn-sm" (click)="zoomIn()"
                                                [disabled]="(zoom_to==3)?true:null">
                                                <fa-icon [icon]="fonts.faSearchPlus"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="{{(this.submission_detail?.file_path && this.submission_detail?.file_path!='')?'col-md-4':'col-md-12'}}">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ng-container *ngIf="question_format_type == 'Section-Wise'">
                                                <h6>Valuation Score Card</h6>
                                                <form [formGroup]="score_card_form" class="form-horizontal">
                                                    <ng-container formArrayName="score_card_items">
                                                        <div class="table-responsive adjust-height">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th style="width:15%">Question No.</th>
                                                                        <th style="width:15%">CO-K Level</th>
                                                                        <th>Mark</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <ng-container
                                                                        *ngFor="let score of scoreCardItems; let i=index;"
                                                                        [formGroupName]="i">
                                                                        <tr *ngIf="score.value.display_section_header">
                                                                            <td colspan="3"><b>Section
                                                                                    {{getSectionName(score.value.section_no)}}</b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{{score.value.question_no}}.
                                                                                {{score.value.option}}</td>
                                                                            <td>CO{{score.value.co_id}} -
                                                                                K{{score.value.k_id}}</td>
                                                                            <td>
                                                                                <input type="number"
                                                                                    formControlName="mark"
                                                                                    (click)="displayScheme(score.value.section_no,score.value.question_no, score.value.option)"
                                                                                    placeholder="Enter Mark"
                                                                                    (focusout)="checkMaxMark($event,score.value.section_no)"
                                                                                    class="form-control">
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </ng-container>

                                                    <div class="text-center">
                                                        <button class="btn btn-primary" (click)="saveMark()"
                                                            *ngIf="!mark_save_loading">Save Mark</button>
                                                        <button class="btn btn-primary" *ngIf="mark_save_loading"
                                                            disabled>Loading...</button>
                                                    </div>

                                                </form>
                                            </ng-container>
                                            <!--Co Wise-->
                                            <ng-container *ngIf="question_format_type == 'Co-Wise'">
                                                <form [formGroup]="score_card_form" class="form-horizontal">
                                                    <ng-container formArrayName="score_card_items">
                                                        <div class="table-responsive adjust-height">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th style="width:30%">CO-K Level</th>
                                                                        <th>Mark</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <ng-container
                                                                        *ngFor="let score of scoreCardItems; let i=index;"
                                                                        [formGroupName]="i">
                                                                        <tr>
                                                                            <td>CO{{score.value.co_id}}</td>
                                                                            <td>
                                                                                <input type="number"
                                                                                    formControlName="mark"
                                                                                    (click)="displayAttainment(score.value.co_id)"
                                                                                    placeholder="Enter Mark"
                                                                                    (focusout)="checkMaxCoMark($event,score.value.co_id)"
                                                                                    class="form-control">
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </ng-container>

                                                    <div class="text-center">
                                                        <button class="btn btn-primary" (click)="saveMark()"
                                                            *ngIf="!mark_save_loading">Save Mark</button>
                                                        <button class="btn btn-primary" *ngIf="mark_save_loading"
                                                            disabled>Loading...</button>
                                                    </div>
                                                </form>
                                            </ng-container>
                                        </div>
                                        <div class="col-md-12" *ngIf="question_format_type == 'Section-Wise'">
                                            <h6>Scheme of Evaluation</h6>
                                            <p>Question {{this.question?.question_no}}: CO{{this.question?.co_id}} -
                                                K{{this.question?.k_id}}</p>
                                            <div [innerHTML]="this.question?.question" style="font-weight: bold;"></div>
                                            <p>Scheme Key</p>
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Key Point</th>
                                                            <th>Mark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let scheme of question.scheme_list; let i=index;">
                                                            <td>{{i+1}}</td>
                                                            <td>
                                                                <div [innerHTML]="scheme.scheme_key"></div>
                                                            </td>
                                                            <td>{{scheme.mark}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <!--Co Wise Scheme-->
                                        <div class="col-md-12" *ngIf="question_format_type == 'Co-Wise'">
                                            <h6>CO Level Attainment Description</h6>
                                            <div [innerHTML]="this.attainment_description" style="font-weight: bold;">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        
                                    </div> -->
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_file_data">
                    <div class="modal-header">
                        <h6 class="modal-title">File Preview</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <pdf-viewer [src]="this.preview_url" [render-text]="false" [original-size]="true"
                                        [fit-to-page]="true" [show-borders]="true" style="height: 600px;">
                                    </pdf-viewer>
                                </div>
                            </div>
                            <div class="row mg-t-15">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-primary mg-r-10" (click)="uploadFile()"
                                        *ngIf="modal_mode=='UPLOAD' && !file_uploading">
                                        Upload File
                                    </button>
                                    <button class="btn btn-primary mg-r-10" disabled
                                        *ngIf="modal_mode=='UPLOAD' && file_uploading ">
                                        Uploading...
                                    </button>
                                    <button class="btn btn-secondary" (click)="closeModal()">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!-- Reason Large Modal -->
        <div class="modal" id="reason-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_file_data">
                    <div class="modal-header">
                        <h6 class="modal-title">Reject Reason</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12">
                                    <textarea [(ngModel)]="reason" class="form-control"
                                        placeholder="Enter Reason for rejecting this answer sheet"></textarea>
                                </div>
                            </div>
                            <div class="row mg-t-15">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-danger mg-r-10" (click)="rejectFile()"
                                        *ngIf="!reject_loading">
                                        Reject
                                    </button>
                                    <button class="btn btn-danger mg-r-10" disabled *ngIf="reject_loading">
                                        Updating...
                                    </button>
                                    <button class="btn btn-secondary" (click)="closeReasonModal()">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->

        <!-- Score Sheet Large Modal -->
        <div class="modal" id="score-sheet-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="score_sheet_data?.length>0">
                    <div class="modal-header">
                        <h6 class="modal-title">Score Sheet</h6>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="row">
                            <div class="col-md-12 mg-b-10 text-right">
                                <button class="btn btn-sm btn-info" (click)="printScoreSheet()">Print Score
                                    Sheet</button>
                            </div>
                        </div> -->
                        <div class="card card-body pd-20 pd-md-40">
                            <div id="print-area">
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <h5>Ayya Nadar Janaki Ammal College (Autonomous), Sivakasi.</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <h6>Terminal Examination - {{modal_course_data.month}}
                                            {{modal_course_data.year}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <h6>{{modal_course_data.course_code}} - {{modal_course_data.course_name}}</h6>
                                    </div>
                                    <div class="col-md-7">
                                        <h6>
                                            Evaluation Sheet - Valuation {{modal_course_data.valuation_no}}</h6>
                                    </div>
                                </div>
                                <div class="table-responsive" id="print-table">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colspan="3" class="text-center">Student</th>
                                                <ng-container
                                                    *ngFor="let section of section_render_data; let i = index; trackBy:fromDataResolver.identify;">
                                                    <th [attr.colspan]="section.span" class="text-center">
                                                        Section-{{section.section_name}}
                                                    </th>
                                                </ng-container>
                                                <th [attr.colspan]="score_sheet_data[0]?.co_items.length"
                                                    class="text-center">Co Marks</th>
                                                <th colspan="2" class="text-center">Total Marks</th>
                                            </tr>
                                            <tr>
                                                <th rowspan="2" class="text-center" style="vertical-align: middle;">
                                                    S.No
                                                </th>
                                                <th rowspan="2" class="text-center" style="vertical-align: middle;">
                                                    Roll No.
                                                </th>
                                                <th rowspan="2" class="text-center" style="vertical-align: middle;">
                                                    Name
                                                </th>
                                                <th class="text-center"
                                                    *ngFor="let head of score_sheet_data[0]?.score_card_items; let i = index; trackBy:fromDataResolver.identify;">
                                                    {{head.question_no}}. {{head.option}}
                                                </th>
                                                <th class="text-center" rowspan="2" style="vertical-align: middle;"
                                                    *ngFor="let head of score_sheet_data[0]?.co_items; let in = index; trackBy:fromDataResolver.identify;">
                                                    CO{{head.co_id}}
                                                </th>
                                                <th rowspan="2" class="text-center" style="vertical-align: middle;">
                                                    Total
                                                </th>
                                                <th rowspan="2" class="text-center" style="vertical-align: middle;">
                                                    Final
                                                </th>
                                            </tr>
                                            <tr>
                                                <ng-container
                                                    *ngFor="let head of score_sheet_data[0]?.score_card_items; let j = index; trackBy:fromDataResolver.identify;">
                                                    <th>
                                                        CO{{head.co_id}}-K{{head.k_id}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let student of score_sheet_data; let i = index; trackBy:fromDataResolver.identify;">
                                                <td>{{i+1}}</td>
                                                <td>{{student.student_roll_no}}</td>
                                                <td>{{student.student_name}}</td>
                                                <ng-container
                                                    *ngFor="let score_card_item of student?.score_card_items; let j = index; trackBy:fromDataResolver.identify;">
                                                    <td>
                                                        {{score_card_item?.mark}}
                                                    </td>
                                                </ng-container>
                                                <ng-container
                                                    *ngFor="let co_item of student?.co_items; let k = index; trackBy:fromDataResolver.identify;">
                                                    <td>
                                                        {{co_item?.mark}}
                                                    </td>
                                                </ng-container>
                                                <td>{{student.total_raw | number : '1.2-2'}}</td>
                                                <td>{{student.total | number : '1.2-2'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="row mg-t-15">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-secondary" (click)="closeScoreSheet()">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->




    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>