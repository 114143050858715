import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CookieStore } from "../../../services/helpers/CookieStore";
@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {

  access_token: string = "";
  error: string = "";
  error_description: string = "";
  constructor(private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    CookieStore.clearBearerToken();
    this.route.queryParams.subscribe(params => {
      this.access_token = params['access_token'];
      this.error = params['error'];
      this.error_description = params['error_description'];
    });
  }

  ngOnInit(): void {
    (async () => {
      if (this.access_token) {
        CookieStore.saveAccessToken(this.access_token)
        const userinfo_response = await this.restService.getUserInfo();
        if (userinfo_response.success) {
          CookieStore.saveUserInfo(userinfo_response.data);
          if (CookieStore.isRoleExists(["student"])) {
            this.router.navigate(["/students/dashboard"])
          } else if (CookieStore.isRoleExists(["staff"])) {
            this.router.navigate(["/staff/dashboard"])
          } else if (CookieStore.isRoleExists(["accounts"])) {
            this.router.navigate(["/admin/students/payments/schedule-list"])
          } else if (CookieStore.isRoleExists(["office_admin"])) {
            this.router.navigate(["/admin/students/students"])
          }
          else if (CookieStore.isRoleExists(["guest_user"])) {
            this.router.navigate(["/staff/dashboard"])
          }

          else if (CookieStore.isRoleExists(["non_staff"])) {
            this.router.navigate(["/staff/dashboard"])
          }

        } else {
          const login_url = this.restService.getLoginUrl();
          location.href = login_url;
        }
      } else {
        const login_url = this.restService.getLoginUrl();
        location.href = login_url;
      }


    })()
  }

}
