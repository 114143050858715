<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>

            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Upload Evidence</button>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Upload File Evidence for OBE Non-Academic Attainment</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-50 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th style="width: 10%;">Semester</th>
                                        <th style="width: 20%;">File URL</th>
                                        <th style="width: 30%;">Description</th>
                                        <th style="width: 20%">Awarded Points</th>
                                        <th style="width: 10%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let evidence of evidence_list;let i=index;trackBy:fromDataResolver.identify;">
                                        <tr>
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td class="uppercase">
                                                {{evidence.semester}}
                                            </td>
                                            <td class="uppercase">
                                                <a [href]="evidence.evidence_url" target="_blank">View Evidence</a>
                                            </td>
                                            <td class="uppercase">
                                                {{evidence.description}}
                                            </td>
                                            <td>
                                                <p *ngIf="evidence.stage != 'OBE_MONITOR'">
                                                    --
                                                </p>
                                                <p *ngIf="evidence.stage == 'OBE_MONITOR'">
                                                    {{evidence.point}}
                                                </p>
                                                <p style="color:blue; text-decoration: underline; cursor: pointer;"
                                                    *ngIf="evidence.stage == 'OBE_MONITOR'"
                                                    (click)="onMoveClick(evidence)">
                                                    Apply Grievance
                                                </p>
                                            </td>
                                            <td>
                                                <button class="btn btn-sm btn-info mg-r-10"
                                                    (click)="onEditClick(evidence)">
                                                    <fa-icon [icon]="fonts.faPencilAlt" data-toggle="tooltip"
                                                        title="Edit Evidence"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!evidence_list_loading && evidence_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="evidence_list_loading && evidence_list.length==0">
                                        <tr>
                                            <th colspan="5" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Evidence Upload Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="evidence_form">
                    <div class="modal-header">
                        <h6 class="modal-title">Upload Evidence for OBE Non-Academic Attainment</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="evidence_form" class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                semester</label>
                                            <select formControlName="semester" class="form-control">
                                                <option value="0">Select Semester</option>
                                                <option
                                                    *ngFor="let sem of commonEnums.getSemesterList(6);let i=index;trackBy:fromDataResolver.identify;"
                                                    [ngValue]="sem.id">{{sem.text}}</option>
                                            </select>
                                            <div *ngIf="isInvalid('semester')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Semester is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Upload File
                                                Evidence</label>
                                            <input class="form-control" required="" type="file"
                                                (change)="uploadFile($event)">
                                            <div *ngIf="isInvalid('evidence_url')">
                                                <ul class="parsley-errors-list filled" id="parsley-id-9">
                                                    <li class="parsley-required">Evidence File is required.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Skillset Category</label>
                                            <select formControlName="skillset_id" class="form-control"
                                                (change)="filterSkillsetList($event)">
                                                <option value="">Select Skillset Name</option>
                                                <option
                                                    *ngFor="let skillset of skillset_list; let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="skillset._id">{{skillset.skillset_name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Select
                                                Skill</label>
                                            <select formControlName="skill" class="form-control">
                                                <option value="">Select Skill Name</option>
                                                <option
                                                    *ngFor="let skill of skills_list; let i=index;trackBy:fromDataResolver.identify;"
                                                    [value]="skill.skill_name">{{skill.skill_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Place</label>
                                            <input type="text" placeholder="Enter Event Place" class="form-control"
                                                formControlName="place">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose
                                                Date</label>
                                            <input type="date" placeholder="Enter Event Date" class="form-control"
                                                formControlName="date">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter
                                                Description</label>
                                            <textarea class="form-control" formControlName="description"
                                                placeholder="Enter a description about this evidence" rows="5" cols="5">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>

                            </form>


                            <div class="mg-b-20"></div>
                            <div class="text-align-right">
                                <button class="btn btn-primary mg-r-20" (click)="onSaveClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.evidence_form.value._id && !this.save_loading">
                                        Save
                                    </ng-container>
                                    <ng-container *ngIf="!this.evidence_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && !this.save_loading">
                                        Update
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndCloseClick()"
                                    [disabled]="canDisable()">
                                    <ng-container *ngIf="!this.evidence_form.value._id && !this.save_loading">
                                        Save & Close
                                    </ng-container>
                                    <ng-container *ngIf="!this.evidence_form.value._id && this.save_loading">
                                        Saving...
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && !this.save_loading">
                                        Update & Close
                                    </ng-container>
                                    <ng-container *ngIf="this.evidence_form.value._id && this.save_loading">
                                        Updating...
                                    </ng-container>
                                </button>
                                <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                                    *ngIf="this.evidence_form.value._id && !delete_loading"
                                    [disabled]="canDisable()">Delete</button>
                                <button class="btn btn-danger mg-r-20"
                                    *ngIf="this.evidence_form.value._id && delete_loading" disabled>Deleting...</button>
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn ripple btn-secondary" (click)="onCloseClick()"
                                    type="button">Close</button>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!--End Large Modal -->


        <!--Description Modal-->
        <div class="modal" id="modal-move">
            <div class="modal-dialog modal-lg modal-sm" role="document" *ngIf="move_form">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Apply for Grievance</h6>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="move_form" class="form-horizontal">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Reason to apply
                                            Grievance to the Principal *</label>
                                        <textarea class="form-control" formControlName="remarks"
                                            placeholder="Enter a valid reason for applying Grievance"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="text-center mg-t-20">
                            <button class="btn btn-primary mg-r-20" (click)="moveToClick()" *ngIf="!move_loading"
                                [disabled]="!move_form.valid">Request</button>
                            <button class="btn btn-primary mg-r-20" *ngIf="move_loading" disabled>Loading...</button>
                            <button class="btn btn-secondary mg-r-20" (click)="closeMove()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Description Modal-->

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>