import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-statement-of-marks',
  templateUrl: './statement-of-marks.component.html',
  styleUrls: ['./statement-of-marks.component.scss']
})
export class StatementOfMarksComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  sections: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;

  student_data_list: any = undefined;

  student_list_loading = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService, private http: HttpClient) { }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildFilterForm();
    this.getProgrammeDataList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      month: [0], //required
      year: [0],
      enrolled_year: [0], //required
      programme_id: [''], //optional
      section: [''],
      semester: [0], //optional
      /* offered_by_finance_type: [''],*/
    });

  }

  async onFilterSelectChange(id: string, event?: any): Promise<void> {
    /* if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList(this.programme_search_form);
    } */

    if (id == "programme_id") {
      this.sections = [];
      this.programme_search_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.programme_search_form.get('section').setValue(this.sections[0].section_name);
        }
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_search_form.get('programme_id').setValue('');
      this.programme_search_form.get('section').setValue('');
      this.programme_list = [];
      this.sections = [];
      const search_form_query: any = {

      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    this.searchCourse();
  }

  getMonth(month: string) {
    return this.commonEnums.months.find(x => x.id == month)?.text;
  }
  getProgrammeName(id: string) {
    return this.programme_list.find(x => x.programme_id == id)?.programme_name;
  }
  async searchCourse(): Promise<void> {
    try {
      if (!this.programme_search_form.value.month) {
        alert('Please select Month');
        return;
      }
      if (!this.programme_search_form.value.year) {
        alert('Please select Year');
        return;
      }
      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please select Enrollment Year');
        return;
      }
      if (!this.programme_search_form.value.programme_id) {
        alert('Please select Programme Name');
        return;
      }

      if (!this.programme_search_form.value.semester) {
        alert('Please select Programme');
        return;
      }
      this.student_list_loading = true;
      this.student_data_list = {} as any;
      var search_form_query: any = {
        month: parseInt(this.programme_search_form.value.month),
        year: this.programme_search_form.value.year,
        enrolled_year: this.programme_search_form.value.enrolled_year,
        semester: this.programme_search_form.value.semester,
        programme_id: this.programme_search_form.value.programme_id,
      };
      //optional checks
      if (this.programme_search_form.value.section)
        search_form_query.section = this.programme_search_form.value.section;


      const service_response = await this.restService.findStatementOfMark(search_form_query);
      if (service_response && service_response.success) {
        this.student_data_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.student_list_loading = false;
    }
  }

  printData() {
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script> " +
      "<style>@page { size: A4 landscape;} @media print { html, body { margin-right:5%; margin-left:2%; margin-top:2%; margin-bottom:3%} #print-table{width:90%}}</style>")
    mywindow.document.write('</head><body><div style="font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-area').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.write('<script>document.getElementById("print-table").classList.remove("table-responsive");</script>');
    //mywindow.document.write('<script>document.getElementById("print-table").style.width = "90%";</script>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }
}
interface IMarkStatement {
  term: string,
  class: string,
  programme_type_id: string,
  enrolled_year: number,
  semester: number,
  programme_name: string,
  section: string
  date: string,
  student_data: {
    roll_no: string,
    course_data: {
      course_code: string,
      course_name: string,
      internal_mark: number,
      max_internal_mark: number,
      external_mark: number,
      max_external_mark: number
      total_mark: number,
      grade_point: number,
      letter_grade: string
    }[] //multiple courses for a student
  }[] //multiple student
}