import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { HttpParams } from '@angular/common/http';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';

@Component({
  selector: 'app-student-certificate-tracker',
  templateUrl: './student-certificate-tracker.component.html',
  styleUrls: ['./student-certificate-tracker.component.scss']
})
export class StudentCertificateTrackerComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;

  status: string = '';

  admission_id = '';
  regulation_year: number = new Date().getFullYear();
  finance_type: string = '';
  programme_id: string = '';
  programme_name: string = '';
  // master data

  student_data_loading: boolean = false;
  student_data: IStudentData[] = [];
  report_filter: string = 'all';
  filter_data: IStudentData[] = [];
  filter_data_loading: boolean = false;

  save_loading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(data => {
      this.admission_id = data.admission_id;
      this.regulation_year = Number(data.regulation_year);
      this.finance_type = data.finance_type;
      this.programme_id = data.programme_id;
      this.programme_name = data.programme_name;
      this.status = data.status;
    });
  }

  ngOnInit(): void {

    // Load master data
    this.getStudentData();
  }

  /// master data

  async getStudentData() {
    try {
      this.student_data_loading = true;
      this.student_data = [];
      const search_data = {
        regulation_year: this.regulation_year,
        admission_id: this.admission_id,
        finance_type: this.finance_type,
        programme_id: this.programme_id,
        status: this.status
      }
      const service_response = await this.restService.getApplicantsWithGivenStatus(search_data);
      if (service_response && service_response.success) {
        this.student_data = service_response.data;
      }
      /* this.student_data = [{
        _id: "asdfsdf",
        application_no: "US001",
        mobile_number: "9876543210",
        mobile_number_2: "9876543211",
        mobile_number_3: "",
        name: "Ajay Balaji K",
        gender: "Male",
        community_name: "BC",
        birth_date: "1996-03-11",
        certificate_list: [{
          certificate_name: "HSC Mark Sheet",
          is_received: false
        },
        {
          certificate_name: "Community Cert.",
          is_received: true
        }
        ],
        certificate_remarks: "Comm. Cert Xerox only received",
      },
      {
        _id: "sadersdaf",
        application_no: "US002",
        mobile_number: "9876543233",
        mobile_number_2: "",
        mobile_number_3: "",
        name: "Vikram A",
        gender: "Male",
        community_name: "BC",
        birth_date: "1996-05-18",
        certificate_list: [{
          certificate_name: "HSC Mark Sheet",
          is_received: false
        },
        {
          certificate_name: "Community Cert.",
          is_received: false
        }
        ],
        certificate_remarks: "",
      }
      ] */
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.student_data_loading = false;
    }

  }

  async saveStudentData() {
    var save_obj: any[] = [];
    this.student_data.forEach(student => {
      save_obj.push({
        _id: student._id,
        application_no: student.application_no,
        admission_id: this.admission_id,
        certificate_list: student.certificate_list,
        certificate_remarks: (student.certificate_remarks ? student.certificate_remarks : '-')
      })
    });
    try {
      this.save_loading = true;
      const res = await this.restService.updateStudentCertficateTracker(save_obj);
      if (res) {
        alert("Student Certificate Data updated successfully.");
      }
      this.getStudentData();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  onPrintClick() {
    let current_date = DateHelper.toDisplayDate(new Date());
    var table_string: string = '';
    table_string +=
      '<table class="table table-bordered" style="border:2px solid black"><thead><tr>';
    table_string += "<th>S.No</th><th>App No.</th><th>Name</th><th>Gender</th><th>Mobile No.</th><th>Community</th><th>DOB</th>"
    for (var j = 0; j < this.student_data[0].certificate_list.length; j++) {
      table_string += '<th style="border: 2px solid black">' + this.student_data[0].certificate_list[j].certificate_name + '</th>';
    }
    table_string += '<th>Remarks</th></tr></thead><tbody>';
    for (var i = 0; i < this.student_data.length; i++) {
      table_string += "<tr style='border: 2px solid black'>";
      table_string += '<td style="border: 2px solid black">' + (i + 1) + '</td>';
      table_string += '<td style="border: 2px solid black">' + this.student_data[i].application_no + '</td>';
      table_string += '<td style="border: 2px solid black">' + this.student_data[i].name + '</td>';
      table_string += '<td style="border: 2px solid black">' + this.student_data[i].gender.toUpperCase() + '</td>';
      table_string += '<td style="border: 2px solid black">' + this.student_data[i].mobile_number;
      if (this.student_data[i].mobile_number_2) {
        table_string += '<br>' + this.student_data[i].mobile_number_2;
      }
      if (this.student_data[i].mobile_number_3) {
        table_string += '<br>' + this.student_data[i].mobile_number_3;
      }
      table_string += "</td>";
      table_string += '<td style="border: 2px solid black">' + this.student_data[i].community_id;
      table_string += '<td style="border: 2px solid black">' + DateHelper.toDisplayDate(new Date(this.student_data[i].birth_date));
      for (var j = 0; j < this.student_data[i].certificate_list.length; j++) {
        table_string += '<td style="border: 2px solid black; width:10%"> </td>';
      }
      table_string += "<td></td></tr>";
    }
    table_string += '</tbody></table>';
    console.log(table_string);
    var window_height = window.screen.height;
    var window_width = window.screen.width;
    var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>@page { size: A3 landscape;} @media print { html, body { margin-right:3%; margin-left:2%; margin-top:2%; margin-bottom:3%}}</style>"
    );
    mywindow.document.write('</head><body style="color: black; font-family:\'serif\'">');
    mywindow.document.write(
      '<div class="row" style = "margin-left:50px" id = "print-header" >' +
      ' <div class="col-md-2" > ' +
      '<img src = "https://lvfiles.blob.core.windows.net/1-static/photos/logo/thump/logo?v=1527754882603" alt = "college logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
      '</div>' +
      '<div class= "col-md-8 text-center" > ' +
      '<h4 style = " margin-bottom: 0px !important;" > AYYA NADAR JANAKI AMMAL COLLEGE' +
      '(Autonomous) </h4>' +
      '<h4> Sivakasi - 626 124. </h4>' +
      '<h4>Admission - 2023</h4>' +
      '</div>' +
      '</div>');
    mywindow.document.write('<h4 class= "text-center"><b> ' + 'Certificate Track List - ' + this.programme_name + '</b></h4>');
    mywindow.document.write('<h4 style= "float:right"><b>' + current_date + '</b></h4>');
    mywindow.document.write(table_string);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }



  onReportClick() {
    this.filter_data = this.student_data;
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    this.filter_data = [];
    this.report_filter = '';
    JQueryHelper.closeModal('#modal-popup');
  }


  reportTypeChange(ev: Event) {
    this.filter_data = [];
    this.filter_data_loading = true;
    if (this.report_filter == 'all') {
      this.filter_data = this.student_data;
    }
    else {
      this.student_data.forEach(student => {
        var cert_count = 0;
        student.certificate_list.forEach(certificate => {
          if (certificate.is_received) {
            cert_count++;
          }
        });

        if (cert_count == student.certificate_list.length) {
          if (this.report_filter == 'full') {
            this.filter_data.push(student);
          }
        }

        if (cert_count == 0) {
          if (this.report_filter == 'not_given') {
            this.filter_data.push(student);
          }
        }

        if (cert_count != student.certificate_list.length && cert_count != 0) {
          if (this.report_filter == 'given') {
            this.filter_data.push(student);
          }
        }

        if (cert_count != student.certificate_list.length) {
          if (this.report_filter == 'pending') {
            this.filter_data.push(student);
          }
        }
      });
    }
    this.filter_data_loading = false;
  }

  getCertificateData(student: IStudentData) {
    var cert_list: string = '';
    var cert_arr: ICertficateList[] = student.certificate_list;;
    if (this.report_filter == 'pending' || this.report_filter == 'not_given') {
      cert_arr = student.certificate_list.filter(x => x.is_received == false)
    }

    if (this.report_filter == 'full' || this.report_filter == 'given') {
      cert_arr = student.certificate_list.filter(x => x.is_received == true);
    }
    cert_arr.forEach(cert => {
      cert_list += cert.certificate_name + (cert.is_received ? '( Given)' : '( Not Given)') + " ,";
    })
    return cert_list;
  }


  downloadReportCSV() {

    var data_to_download: any[] = [];
    for (let index = 0; index < this.filter_data.length; index++) {
      const student = this.filter_data[index];
      const mobile_numbers = [];
      if (student.mobile_number) {
        mobile_numbers.push(student.mobile_number);
      }
      if (student.mobile_number_2) {
        mobile_numbers.push(student.mobile_number_2);
      }
      if (student.mobile_number_3) {
        mobile_numbers.push(student.mobile_number_3);
      }
      let data_item: any;
      data_item = {
        'S.NO': (index + 1),
        'APP. NO.': student.application_no,
        'NAME': student.name.toUpperCase(),
        'MOB. NO.': mobile_numbers.join(', '),
        'CERT. LIST': this.getCertificateData(student)
      };
      data_to_download.push(data_item);
    }
    const file_name = this.programme_name.toLowerCase() + '_' + this.report_filter + '.csv';
    CSVHelper.downloadCSV(data_to_download, file_name);

  }
}


interface IStudentData {
  _id?: string,
  application_no: string,
  mobile_number: string,
  mobile_number_2: string,
  mobile_number_3: string,
  gender: string,
  name: string,
  community_id: string,
  birth_date: string,
  certificate_list: ICertficateList[],
  certificate_remarks: string
}

interface ICertficateList {
  certificate_name: string;
  is_received: boolean
}



