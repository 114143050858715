import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-question-format-setting',
  templateUrl: './question-format-setting.component.html',
  styleUrls: ['./question-format-setting.component.scss']
})
export class QuestionFormatSettingComponent implements OnInit {

  title = 'Question Format Setting';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;

  filter_form: FormGroup;
  questionFormatSettingForm: FormGroup;

  setting_data: any[] = [];
  setting_data_loading: boolean = false;
  setting_save_loading: boolean = false;
  setting_delete_loading: boolean = false;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;
  programme_section_list: any[] = [];
  programme_name: string = "";
  sections: any = [];
  parts: any[] = [];
  course_types: any[] | undefined = [];
  question_types: { id: string, text: string }[] = [{
    id: "SINGLE", text: "Single Question"
  },
  {
    id: "EITHER_OR", text: "Either OR Question"
  }
  ];
  question_format_types: { id: string, text: string }[] = [{
    id: "Co-Wise", text: "CO Wise Format"
  },
  {
    id: "Section-Wise", text: "Section Wise Format"
  }
  ];
  co_ids: { id: number, text: string }[] =
    [
      { id: 1, text: "CO1" }, { id: 2, text: "CO2" }, { id: 3, text: "CO3" }, { id: 4, text: "CO4" }, { id: 5, text: "CO5" }
    ];
  /* add_programme_list: any[] = [];
  add_programme_list_loading = false;
  add_parts: any[] = [];
  add_course_types: any[] | undefined = []; */


  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) {

  }

  ngOnInit(): void {
    /* this.getProgrammeTypeList();
    this.buildSearchForm(); */
    this.onSearchClick();
    this.buildQuestionSettingForm({});
  }

  /* buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      programme_type_id: [''],
      enrolled_year: [''],
      semester: [''],
      programme_id: [''],
      section: [''],
      part: [''],
      course_type: [''],
      course_code: [''],
      //finance_type: [''],
    });
  } */

  /* async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }
  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  } */

  /* async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.filter_form.get('programme_id').setValue("");
      this.filter_form.get('part').setValue("");
      this.getProgrammeDataList(this.filter_form);
      if (id === 'programme_type_id' && this.filter_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.filter_form.value.programme_type_id);
      }
    }


    if (id === "programme_id") {
      this.sections = [];
      this.filter_form.get('section').setValue("");
      const programme = this.programme_list.find(x => x.programme_id == this.filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
        if (this.sections.length == 1) {
          this.filter_form.get('section').setValue(this.sections[0].section_name);
        }
      }
      //API call to get course_position list based on programme_id and semester
    }

    else if (id === 'part') {
      this.course_types = [];
      this.filter_form.get('course_type').setValue("");
      const course_types = this.commonEnums.getCoursePartType(this.filter_form.value.programme_type_id, this.filter_form.value.part);
      if (course_types) {
        this.course_types = course_types;
      }
    }
  } */

  /* async getAddProgrammeDataList(form: any): Promise<void> {
    try {
      this.add_programme_list_loading = true;
      this.add_programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.add_programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.add_programme_list_loading = false;
    }
  } */

  /* async onAddFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id') {
      this.questionFormatSettingForm.get('programme_id').setValue("");
      this.questionFormatSettingForm.get('part').setValue("");
      this.getAddProgrammeDataList(this.questionFormatSettingForm);
      if (id === 'programme_type_id' && this.questionFormatSettingForm.value.programme_type_id) {
        this.add_parts = this.commonEnums.getParts(this.questionFormatSettingForm.value.programme_type_id);
      }
    }

    else if (id === 'part') {
      this.add_course_types = [];
      this.questionFormatSettingForm.get('course_type').setValue("");
      const course_types = this.commonEnums.getCoursePartType(this.questionFormatSettingForm.value.programme_type_id, this.questionFormatSettingForm.value.part);
      if (course_types) {
        this.add_course_types = course_types;
      }
    }
  } */


  async onSearchClick() {
    this.setting_data = [];
    var search_query: any = {};
    /* if (!this.filter_form.value.programme_type_id) {
      alert("Please choose Programme Type"); return;
    }
    if (!this.filter_form.value.enrolled_year) {
      alert("Please choose valid Enrolled Year"); return;
    }
    if (!this.filter_form.value.semester) {
      alert("Please choose valid Semester"); return;
    }
    search_query.programme_type_id = this.filter_form.value.programme_type_id;
    search_query.enrolled_year = this.filter_form.value.enrolled_year;
    search_query.semester = this.filter_form.value.semester;

    if (this.filter_form.value.programme_id) {
      search_query.programme_id = this.filter_form.value.programme_id;
    }

    if (this.filter_form.value.section) {
      search_query.section = this.filter_form.value.section;
    }

    if (this.filter_form.value.part) {
      search_query.part = this.filter_form.value.part;
    }

    if (this.filter_form.value.course_type) {
      search_query.course_type = this.filter_form.value.course_type;
    }

    if (this.filter_form.value.course_code) {
      search_query.course_code = this.filter_form.value.course_code;
    } */
    console.log(search_query);
    try {
      this.setting_data_loading = true;
      const service_response = await this.restService.searchExamQuestionFormat(search_query);
      if (service_response && service_response.success) {
        console.log(service_response);
        this.setting_data = service_response.data;
      }
      else {
        alert("Error! Could not get Question Format Data");
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.setting_data_loading = false;
    }
  }


  onAddClick() {
    this.buildQuestionSettingForm({});
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  async onEditClick(setting: any): Promise<void> {
    console.log(setting);
    if (setting.programme_id) {
      const search_form_query: any = {
        is_virtual: false
      };
      if (setting.programme_type_id) {
        search_form_query.programme_type_id = setting.programme_type_id;
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        var programme_list: any[] = service_response.data;
        this.programme_name = programme_list.find(x => x._id == setting.programme_id).programme_name;
      }
    }
    var setting_obj = JSON.parse(JSON.stringify(setting));
    if (setting_obj.question_format_type == "Co-Wise") {
      const saved_co_ids = setting_obj.allowed_co_ids;
      var co_ids_obj: {
        id: number, text: string
      }[] = [];
      saved_co_ids.forEach(co => {
        co_ids_obj.push(this.co_ids.find(x => x.id == co))
      });
      console.log(co_ids_obj);
      setting_obj.allowed_co_ids = co_ids_obj;
    }
    this.buildQuestionSettingForm(setting_obj);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  onQuestionFormatChange(event) {
    if (event.target.value == 'Co-Wise') {
      this.questionFormatSettingForm.get('allowed_co_ids').setValidators([Validators.required]);
      this.questionFormatSettingForm.get('allowed_co_ids').updateValueAndValidity();
      (this.questionFormatSettingForm.get('exam_question_format') as FormArray).controls.forEach(row => {
        row.get('no_of_questions').clearValidators(); row.get('no_of_questions').updateValueAndValidity();
        row.get('question_max_mark').clearValidators(); row.get('question_max_mark').updateValueAndValidity();
        row.get('question_type').clearValidators(); row.get('question_type').updateValueAndValidity();
        row.get('no_of_questions_to_answered').clearValidators(); row.get('no_of_questions_to_answered').updateValueAndValidity();
      });
    }
    else {
      (this.questionFormatSettingForm.get('exam_question_format') as FormArray).controls.forEach(row => {
        row.get('no_of_questions').setValidators([Validators.required]); row.get('no_of_questions').updateValueAndValidity();
        row.get('question_max_mark').setValidators([Validators.required]); row.get('question_max_mark').updateValueAndValidity();
        row.get('question_type').setValidators([Validators.required]); row.get('question_type').updateValueAndValidity();
        row.get('no_of_questions_to_answered').setValidators([Validators.required]); row.get('no_of_questions_to_answered').updateValueAndValidity();
      });
      this.questionFormatSettingForm.get('allowed_co_ids').clearValidators();
      this.questionFormatSettingForm.get('allowed_co_ids').updateValueAndValidity();
    }
  }

  buildQuestionSettingForm(settings_data) {
    if (JSON.stringify(settings_data) != '{}') {
      this.questionFormatSettingForm = this.formBuilder.group({
        _id: [settings_data._id],
        /* programme_type_id: [settings_data.programme_type_id, [Validators.required]],
        enrolled_year: [settings_data.enrolled_year, [Validators.required]],
        semester: [settings_data.semester, [Validators.required]],
        programme_id: [settings_data.programme_id],
        part: [settings_data.part],
        course_type: [settings_data.course_type],
        course_code: [settings_data.course_code], */
        format_name: [settings_data.format_name, [Validators.required]],
        question_format_type: [settings_data.question_format_type, [Validators.required]],
        allowed_co_ids: [settings_data.allowed_co_ids],
        exam_question_format: this.formBuilder.array(this.buildSectionListForm(settings_data.exam_question_format))
      });
    }
    else {
      this.questionFormatSettingForm = this.formBuilder.group({
        _id: [""],
        /* programme_type_id: ['', [Validators.required]],
        enrolled_year: ['', [Validators.required]],
        semester: ['', [Validators.required]],
        programme_id: [''],
        part: [''],
        course_type: [''],
        course_code: [''], */
        format_name: [settings_data.format_name, [Validators.required]],
        question_format_type: [settings_data.question_format_type, [Validators.required]],
        exam_question_format: this.formBuilder.array(this.buildSectionListForm([])),
        allowed_co_ids: [settings_data.allowed_co_ids],
      });
    }
  }


  buildSectionListForm(section_list): any {
    if (!section_list) {
      section_list = [];
    }
    var section_list_array: any[] = [];
    if (section_list && section_list.length > 0) {
      var section_count = 0;
      for (const section of section_list) {
        //console.log(component)
        section_list_array.push(this.formBuilder.group({
          _id: [section.id],
          section_no: [section.section_no],
          section_name: [section.section_name],
          no_of_questions: [section.no_of_questions], //required
          question_max_mark: [section.question_max_mark], //required
          question_type: [section.question_type], //required
          no_of_questions_to_answered: [section.no_of_questions_to_answered], //required
          //co_conditions: this.formBuilder.array(this.buildCoConditions(section.co_conditions)),
        }));
        section_count++;
      }
    }
    const remaining_count = 1 - section_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        section_list_array.push(this.formBuilder.group({
          _id: [""],
          section_no: [""],
          section_name: [""],
          no_of_questions: [""],
          question_max_mark: [""],
          question_type: [""],
          no_of_questions_to_answered: [""],
          //co_conditions: this.formBuilder.array(this.buildCoConditions([])),
        }));
      }
    }
    return section_list_array;
  }

  buildCoConditions(co_conditions): any {
    if (!co_conditions) {
      co_conditions = [];
    }
    var co_condition_array: any[] = [];
    if (co_conditions && co_conditions.length > 0) {
      var co_count = 0;
      for (const condition of co_conditions) {
        co_condition_array.push(this.formBuilder.group({
          co_id: [condition.co_id, [Validators.required]],
          no_of_questions: [condition.no_of_questions, [Validators.required]],
        }));
        co_count++;
      }
    }
    else {
      const max_co_count = 5;
      for (let index = 1; index <= max_co_count; index++) {
        co_condition_array.push(this.formBuilder.group({
          co_id: [index, [Validators.required]],
          no_of_questions: ["0", [Validators.required]],
        }));
      }
    }
    return co_condition_array;
  }

  get sectionList() {
    return ((this.questionFormatSettingForm as FormGroup).get('exam_question_format') as FormArray).controls;
  }

  addSection() {
    ((this.questionFormatSettingForm as FormGroup).get('exam_question_format') as FormArray).push(this.formBuilder.group({
      _id: [""],
      section_no: [""],
      section_name: ["", [Validators.required]],
      no_of_questions: ["", [Validators.required]],
      question_max_mark: ["", [Validators.required]],
      question_type: ["", [Validators.required]],
      no_of_questions_to_answered: ["", [Validators.required]],
      //co_conditions: this.formBuilder.array(this.buildCoConditions([])),
    }));
  }

  deleteSection(index: number) {
    const consent = confirm("Are you sure want to delete this section? This action is irreversible!");
    if (!consent) return;
    (this.questionFormatSettingForm.get("exam_question_format") as FormArray).removeAt(index);
  }

  getCoSettings(index: number) {
    var co_settings = ((((this.questionFormatSettingForm as FormGroup).get('exam_question_format') as FormArray).controls[index]).get('co_conditions') as FormArray).controls;
    return co_settings;
  }

  checkMaxQuestions(ev: Event, index: number, type: string) {
    var elem = (ev.target as HTMLInputElement);
    if (type == 'NO_QUESTIONS') {
      (this.questionFormatSettingForm.get('exam_question_format') as FormArray).controls[index].get('no_of_questions_to_answered').setValue("");
    }
    if (type == 'ANSWER_QUESTIONS') {
      const question_to_be_answered = elem.value;
      const max_questions = (this.questionFormatSettingForm.get('exam_question_format') as FormArray).controls[index].get('no_of_questions').value;
      if (max_questions == "") {
        alert("Please fill Max Questions in this section");
        elem.value = "";
        return;
      }
      if (question_to_be_answered > max_questions) {
        alert("No. of questions to be answered cannot be greater than total number of questions in this section");
        elem.value = "";
        return;
      }
    }

  }



  canDisable(): boolean {
    return this.questionFormatSettingForm.status !== 'VALID';
  }

  reset() {
    this.questionFormatSettingForm.reset();
    /* this.questionFormatSettingForm.get('programme_type_id').setValue(""); this.questionFormatSettingForm.get('enrolled_year').setValue("");
    this.questionFormatSettingForm.get('semester').setValue(""); this.questionFormatSettingForm.get('programme_id').setValue("");
    this.questionFormatSettingForm.get('part').setValue(""); this.questionFormatSettingForm.get('course_type').setValue(""); */
    //this.add_course_types = []; this.add_parts = []; this.add_programme_list = [];
    this.programme_name = "";
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  async onSave() {
    this.saveSetting();
  }

  async onSaveAndClose() {
    this.saveSetting();
    this.onCloseClick();
  }
  async saveSetting() {
    var form_data = this.questionFormatSettingForm.value;
    var save_data: any = {};

    /* if (!form_data.programme_type_id) {
      alert("Please choose a Programme Type"); return;
    }
    if (!form_data.enrolled_year) {
      alert("Please choose an Enrolment Year"); return;
    }
    if (!form_data.semester) {
      alert("Please choose a Semester"); return;
    } */
    //Check if CO Conditions question count is less than No. of questions in that section
    /* for (var i = 0; i < sections.length; i++) {
      const max_questions = (this.questionFormatSettingForm.get('exam_question_format') as FormArray).controls[i].get('no_of_questions').value;
      var questions = 0;
      ((this.questionFormatSettingForm.get('exam_question_format') as FormArray).controls[i].get('co_conditions') as FormArray).controls.forEach((control: FormGroup) => {
        questions += parseInt(control.get('no_of_questions').value);
      })
      if (questions > max_questions) {
        alert("No of questions in CO Level cannot be greater than No. of Questions in Section " + (i + 1));
        return;
      }
    } */
    try {
      var sections: any[] = form_data.exam_question_format;
      if (form_data.question_format_type == 'Section-Wise') {
        console.log(form_data.exam_question_format);
        var exam_question_format: any[] = [];
        exam_question_format = form_data.exam_question_format;
        console.log(exam_question_format);
        for (var i = 0; i < exam_question_format.length; i++) {
          exam_question_format[i].section_no = i + 1;
        }
        save_data = {
          format_name: form_data.format_name,
          question_format_type: form_data.question_format_type,
          exam_question_format: exam_question_format,
        }
      }
      else {
        var allowed_co_ids: number[] = [];
        (form_data.allowed_co_ids).forEach(co => {
          allowed_co_ids.push(co.id);
        });
        save_data = {
          format_name: form_data.format_name,
          question_format_type: form_data.question_format_type,
          allowed_co_ids: allowed_co_ids
        }
      }

      /* if (form_data.programme_id) {
        save_data.programme_id = form_data.programme_id;
      }
      if (form_data.part) {
        save_data.part = form_data.part;
      }
      if (form_data.course_type) {
        save_data.course_type = form_data.course_type;
      }
      if (form_data.course_code) {
        save_data.course_code = form_data.course_code;
      } */
      console.log(save_data);
      if (form_data._id) { //edit mode
        this.setting_save_loading = true;
        const save_respose = await this.restService.updateExamQuestionFormat(form_data._id, save_data);
        if (save_respose.success) {
          this.onSearchClick();
          alert('Updated Successfully');
        }
        else {
          alert("Error! Could not update data");
        }
      }
      else //create mode
      {
        this.setting_save_loading = true;
        const save_respose = await this.restService.createExamQuestionFormat(save_data);
        if (save_respose.success) {
          this.onSearchClick();
          alert('Saved Successfully');
        }
        else {
          alert("Error! Could not save data");
        }
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.setting_save_loading = false;
    }
  }

  async onDelete(_id: string) {
    try {
      const consent = confirm("Are you sure want to delete this Quesiton Format Setting?");
      if (!consent) return;
      this.setting_delete_loading = true;
      const delete_format_response = await this.restService.deleteExamQuestionFormat(_id);
      if (delete_format_response && delete_format_response.success === true) {
        this.onSearchClick();
        alert('Question Format Deleted Successfully');
        this.onCloseClick();
      } else {
        alert('Error while deleting');
      }
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.setting_delete_loading = false;
    }
  }
}
