import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { OfflinePaymentUpdateRequest } from 'src/app/services/rest/entity/OfflinePaymentUpdateRequest';

@Component({
  selector: 'app-hosteladmission',
  templateUrl: './hosteladmission.component.html',
  styleUrls: ['./hosteladmission.component.scss']
})
export class HosteladmissionComponent implements OnInit {
  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  staus_count = {
    total: 0,
    applied: 0,
    approved: 0,
    rejected: 0,
    enrolled: 0,
    payment_failed: 0,
    collected_amount: 0
  }
  selected_status = ""

  // search
  search_form: any;

  applied_list_raw: any[] = [];
  applied_list: any[] = [];
  applied_list_loading = false;

  // payment correction

  payment_correction_form: any;
  payment_correction_form_save_loading: boolean = false;
  payment_modes = [
    {
      id: "ONLINE_FAILED", text: "Online Failed"
    }, {
      id: "OFFLINE", text: "Offline"
    }
  ]

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();

  }



  is_super_admin = false;
  ngOnInit(): void {
    if (CookieStore.isRoleExists(["staff_admin", "principal"])) {
      this.is_super_admin = true;
    } else {
      this.is_super_admin = false;
    }

    this.buildSearchForm();

  }

  buildSearchForm(): void {
    this.search_form = this.fb.group({
      status: [''],
      requested_year: [new Date().getFullYear()],
      enrolled_year: [new Date().getFullYear()],
    })
    this.getAllHostelAppliedList();
  }

  async getAllHostelAppliedList() {

    try {
      this.applied_list = [];
      this.applied_list_raw = [];
      this.staus_count = {
        total: 0,
        applied: 0,
        approved: 0,
        rejected: 0,
        enrolled: 0,
        payment_failed: 0,
        collected_amount: 0
      }

      let query: any = {
        status: this.search_form.value.status,
        requested_year: this.search_form.value.requested_year,
        enrolled_year: this.search_form.value.enrolled_year,
      };

      if (!this.is_super_admin) {
        if (CookieStore.isRoleExists(["boys_hostel_admin"])) {
          query.gender = "male";
        } else if (CookieStore.isRoleExists(["girls_hostel_admin"])) {
          query.gender = "female";
        }
      }

      this.applied_list_loading = true;
      const rest_response = await this.restService.getAllHostelAppliedList(query);
      if (rest_response.success && rest_response.data) {

        for (const applied of rest_response.data) {
          let roll_no = [];
          let prg_name = [];
          for (const prog of applied.user_info.programmes) {

            roll_no.push(prog.roll_no);
            prg_name.push(prog.programme_name);
          }
          applied.user_info.roll_no = roll_no.join(", ")
          applied.user_info.programme_name = prg_name.join(", ")

          var name = [applied.user_info.given_name];
          if (applied.user_info.family_name) {
            name.push(applied.user_info.family_name)
          }
          if (applied.user_info.middle_name) {
            name.push(applied.user_info.middle_name)
          }
          applied.user_info.name = name.join(" ");

          var address: string[] = [];
          if (applied.user_info.address && applied.user_info.address.communication) {
            if (applied.user_info.address.communication.street) {
              address.push(applied.user_info.address.communication.street);
            }
            if (applied.user_info.address.communication.city) {
              address.push(applied.user_info.address.communication.city);
            }
            if (applied.user_info.address.communication.district) {
              address.push(applied.user_info.address.communication.district);
            }
            if (applied.user_info.address.communication.district) {
              address.push(applied.user_info.address.communication.district);
            }
            if (applied.user_info.address.communication.pincode) {
              address.push(applied.user_info.address.communication.pincode);
            }
            if (applied.user_info.address.communication.state) {
              address.push(applied.user_info.address.communication.state);
            }
          }
          applied.user_info.address_string = address.join(", ");


        }

        this.applied_list = rest_response.data;
        this.applied_list_raw = rest_response.data;
        this.staus_count.total = this.applied_list_raw.length;
        this.staus_count.applied = this.applied_list_raw.filter(c => c.status == "pending").length;
        this.staus_count.approved = this.applied_list_raw.filter(c => c.status == "allowed").length;
        const payment_finished = this.applied_list_raw.filter(c => c.status == "payment_done");

        this.staus_count.enrolled = payment_finished.length;
        var collected_amount = 0;
        for (const payment_finis of payment_finished) {
          collected_amount = collected_amount + payment_finis.amount;
        }
        this.staus_count.collected_amount = collected_amount;
        this.staus_count.payment_failed = this.applied_list_raw.filter(c => c.status == "payment_failure").length;
        this.staus_count.rejected = this.applied_list_raw.filter(c => c.status == "rejected" || c.status == "reject").length;
      } else {
        this.applied_list = [];
        this.applied_list_raw = [];
        this.staus_count = {
          total: 0,
          applied: 0,
          approved: 0,
          rejected: 0,
          enrolled: 0,
          payment_failed: 0,
          collected_amount: 0
        }
      }
    } catch (error) {

    } finally {
      this.applied_list_loading = false;
    }



  }

  async rejectStatus(_id: string) {
    const consent = confirm("Are you sure do you want to reject this hostel request?");
    if (consent) {
      try {
        const rest_response = await this.restService.changeStudentApplicationStatus({ _id: _id, status: "rejected" });
        if (rest_response.success) {
          this.getAllHostelAppliedList();
          alert("Successfully Rejected");
        }
      } catch (error) {
        alert("Error while rejecting");
      }

    }
  }
  async approveStatus(_id: string) {
    const consent = confirm("Are you sure do you want to approve this hostel request?");
    if (consent) {
      try {
        const rest_response = await this.restService.changeStudentApplicationStatus({ _id: _id, status: "allowed" });
        if (rest_response.success) {
          this.getAllHostelAppliedList();
          alert("Successfully Approved");
        }
      } catch (error) {
        alert("Error while approving");
      }

    }

  }

  async payHostelFee() {
    const payment_url = await this.restService.getHostelFeePaymentUrl();
    location.href = payment_url;
  }

  onStausChange() {
    this.getAllHostelAppliedList();
    // const selected_from_index = event.target.selectedIndex;
    // if (selected_from_index == 0) {
    //   this.applied_list = this.applied_list_raw;
    //   this.selected_status = "";
    // } else if (selected_from_index == 1) {
    //   this.applied_list = this.applied_list_raw.filter(c => c.status == "pending");
    //   this.selected_status = "pending";
    // } else if (selected_from_index == 2) {
    //   this.applied_list = this.applied_list_raw.filter(c => c.status == "allowed");
    //   this.selected_status = "allowed";
    // } else if (selected_from_index == 3) {
    //   this.applied_list = this.applied_list_raw.filter(c => c.status == "payment_done");
    //   this.selected_status = "payment_done";
    // } else if (selected_from_index == 4) {
    //   this.applied_list = this.applied_list_raw.filter(c => c.status == "rejected" || c.status == "reject");
    //   this.selected_status = "rejected";
    // } else if (selected_from_index == 5) {
    //   this.applied_list = this.applied_list_raw.filter(c => c.status == "payment_failure");
    //   this.selected_status = "payment_failure";
    // }
  }


  async downloadAsCSV() {

    var data_list: any[] = [];
    var i = 1;
    for (const applicant of this.applied_list) {


      data_list.push({
        "S.No": i,
        "NAME": applicant.user_info.name,
        "PROGRAMME": applicant.user_info.programme_name,
        "ROLL NO.": applicant.user_info.roll_no,
        "COMMUNITY": applicant.user_info.community_id,
        "CASTE": applicant.user_info.caste,
        "ADDRESS": applicant.user_info.address_string,
        "MOB. NO.": applicant.user_info.mobile_number,
        "EMAIL": applicant.user_info.email,
        "AMOUNT": applicant.amount,
        "DATE": DateHelper.toDisplayDate(applicant.payment_completed_at),
        "STATUS": applicant.status,
        "TRANSACTION ID": applicant.transaction_id
      })
      i++;
    }



    CSVHelper.downloadCSV(data_list, "hostel-students-details-as-of-" + DateHelper.convertToControlDate(new Date()))
  }


  async onFilterSelectChange(id: string, event: any) {

  }


  async onInitPaymentCorrectionClick(student: any) {
    this.buildPaymentCorrectionForm(student);
    JQueryHelper.openModal("#modal-popup-payment-correction", { keyboard: false, backdrop: "static" });
  }

  buildPaymentCorrectionForm(student: any) {
    let transaction_id = "";
    if (student.transaction_id) {
      transaction_id = student.transaction_id;
    }
    this.payment_correction_form = this.fb.group({
      sub: [student.sub, [Validators.required]],
      payment_mode: [this.payment_modes[0].id, [Validators.required]],
      payment_tracking_id: [student._id, [Validators.required]],
      transaction_id: [transaction_id, [Validators.required]],
      description: ["", [Validators.required]],
    })
  }

  async onPaymentCorrectionCloseClick() {
    this.resetPaymentCorrectionForm();
    JQueryHelper.closeModal("#modal-popup-payment-correction")
  }
  resetPaymentCorrectionForm() {
    this.payment_correction_form = undefined;
  }

  async onFinishPaymentCorrectionClick() {
    try {
      const consent = confirm("Are you sure do you want to update payment?");
      if (!consent) {
        return;
      }
      this.payment_correction_form_save_loading = true;

      const offlinePaymentUpdateRequest: OfflinePaymentUpdateRequest = this.payment_correction_form.value;
      if (!offlinePaymentUpdateRequest.transaction_id || !offlinePaymentUpdateRequest.transaction_id.trim()) {
        alert("Please enter the transaction referance or transaction id")
        return;
      }
      const saved_student_response = await this.restService.handleOfflineHostelPayment(this.payment_correction_form.value);
      if (saved_student_response && saved_student_response.success == true) {
        alert("Updated Successfully")
        this.onPaymentCorrectionCloseClick()
        await this.getAllHostelAppliedList();
      } else {
        alert("Error while Update")
      }


    } catch (error) {
      alert("Error while read data")
    } finally {
      this.payment_correction_form_save_loading = false;
    }
  }

}
