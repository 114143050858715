import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-student-hostel-admission',
  templateUrl: './student-hostel-admission.component.html',
  styleUrls: ['./student-hostel-admission.component.scss']
})
export class StudentHostelAdmissionComponent implements OnInit {

  @HostListener('window:message', ['$event']) onPostMessage(event) {
    if (event.data.event_type == "redirect") {
      if (this.request_id == '') {
        let url = this.adminService.getBaseUrl() + "/" + event.data.url + "?access_token=" + CookieStore.getToken();
        window.location.href = url;
      }
      else {
      }
      this.request_id = event.data.request_id;
    }
  }
  load: number = 0;
  request_id: string = '';
  url: string = '';
  constructor(private domsanitizer: DomSanitizer, private adminService: AdminservicesService) {
  }
  ngOnInit(): void {
    this.load = 1;
  }
  getURL() {
    if (this.load == 1) {
      const access_token = CookieStore.getToken();
      this.url = "https://app.appsmith.com/app/hostel-v2/hostel-student-request-644506839fce2c3c1ac7f30f?access_token=" + access_token;
      this.load++;
      return this.domsanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
    return "";
  }
}