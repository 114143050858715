<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>
                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Manage Virtual Sections</p>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="filter_form">
                            <div class="row row-sm">
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Course
                                            Code</label>
                                        <input type="text" class="form-control" formControlName="course_code"
                                            placeholder="Search with Course Code.">
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <button type="button" class="mg-t-30 btn btn-info mr-2 search-button"
                                            title="Search" *ngIf="!table_data_loading" (click)="onSearchClick()">
                                            Search
                                        </button>
                                        <button class="mg-t-30 btn btn-info mr-2 search-button" type="button" disabled
                                            *ngIf="table_data_loading">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div id="print-area" *ngIf="table_data?.section_data?.length>0">
                            <p><b>Currently available Virtual Sections: <i>(Can be changed in Elective Quantity Settings
                                        Page)</i></b></p>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Virtual Section Name</th>
                                            <th>Max Strength</th>
                                            <th>Allocated Strength</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let section of table_data?.section_data; let i = index;">
                                            <td>{{i+1}}</td>
                                            <td>{{section.section_name}}</td>
                                            <td>{{section.max_qty}}</td>
                                            <td>{{getSectionQty(section.section_name)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p><b>Approved Students List</b></p>
                            <div class="table-responsive">
                                <table class="table table-bordered ">
                                    <thead>
                                        <tr style="text-align: center;">
                                            <th class="table-head">S.No</th>
                                            <th class="table-head">Roll No</th>
                                            <th class="table-head">Name</th>
                                            <th class="table-head">Virtual Section
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let programme_data of table_data?.requested_list; let i = index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td colspan="3">
                                                    {{programme_data.student_programme_name}} -
                                                    '{{programme_data.student_programme_section}}'
                                                </td>
                                                <td>
                                                    <select [(ngModel)]="programme_data.common_virtual_section"
                                                        class="form-control"
                                                        (change)="setCommonVirtualSection($event,i)">
                                                        <option value="">Select Common Virtual Section</option>
                                                        <option
                                                            *ngFor="let section of virtual_section_list;let k=index;trackBy:fromDataResolver.identify;"
                                                            [value]="section.section_name">{{section.section_name}}
                                                        </option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let data of programme_data?.student_data; let j = index;trackBy:fromDataResolver.identify;">
                                                <td class="table-cell">{{j+1}}</td>
                                                <td class="table-cell">{{data.roll_no}}</td>
                                                <td class="table-cell">{{data.given_name}}
                                                    {{data.middle_name}}</td>
                                                <td>
                                                    {{data.virtual_section}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="isTableDataEmpty() && table_data_loading">
                                            <tr>
                                                <td colspan="6" class="text-center"><b>Loading...</b></td>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="isTableDataEmpty() && !table_data_loading">
                                            <tr>
                                                <td colspan="6" class="text-center"><b>No Records found.</b></td>
                                            </tr>
                                        </ng-container>
                                    </tbody>

                                </table>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-md btn-primary" (click)="saveSettings()"
                                        *ngIf="!settings_save_loading">
                                        Save Settings
                                    </button>
                                    <button class="btn btn-md btn-primary" disabled
                                        *ngIf="settings_save_loading">Saving...</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>