import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
//import {DBFFile, FieldDescriptor} from 'dbffile';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-admission-fee-report',
  templateUrl: './admission-fee-report.component.html',
  styleUrls: ['./admission-fee-report.component.scss']
})
export class AdmissionFeeReportComponent implements OnInit {


  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  programme_search_form: FormGroup;
  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  category_list_loading = false;
  parts: any[] = [];
  department_list = [];

  transfer_list: any[] = [];
  transfer_list_loading = false;

  fees_headers: {
    id: string, term_name: string, programme_type_id: string
  }[] = []; //replace with API Call
  report_list_loading: boolean = false;
  sections: any = [];
  fees_report: FeesReportInterface[] = [];
  payment_status: any[] = [{ id: "Full", name: "Full" }, { id: "Term 1", name: "Term 1" }];
  start_index = 0;
  total_records = 0;
  take_index = 500;
  dropdownSettings: IDropdownSettings = {};
  all_programme_list: any[] = [];

  admissions_list: any[] = [];
  admissions_list_loading: boolean = false;
  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];

  constructor(private restService: AdminservicesService, private fb: FormBuilder, private http: HttpClient, private router: Router) {
    this.programme_search_form = this.fb.group({
      start_date: [''],
      end_date: [''],
      //programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      gender: [''],
      regulation_year: [''],
      admission_id: [''],
      payment: [''],
      community_ids: [''],
      staff_id: [''],
      fee_target_type: ['']

    });
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.getAllProgrammeDataList();
    //this.testWrite();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    this.getReport()
  }


  async onFilterSelectChange(id: string, event: any): Promise<void> {
    this.onResetClick();
    if (id === 'finance_type') {
      this.programme_search_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.programme_search_form);
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const admission: any = this.admissions_list.find((c: any) => c._id === this.programme_search_form.value.admission_id);
      if (form) {
        if (admission) {
          search_form_query.programme_type_id = admission.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
        console.log(this.programme_list);
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  getPaymentStatus(status) {
    return this.payment_status.find(x => x.id == status).name;
  }

  async onResetClick(): Promise<void> {
    this.start_index = 0;
    this.take_index = 500;
    this.total_records = 0;
    this.fees_report = [];
  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }
  }

  async getAdmissionIDs(event) {
    try {
      const query = {
        year: this.programme_search_form.value.regulation_year
      };
      this.admissions_list = [];
      this.admissions_list_loading = true;
      const service_response = await this.restService.searchAdmissionList(query);
      if (service_response && service_response.success) {
        this.admissions_list = service_response.data;
        this.getProgrammeDataList(this.programme_search_form);
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.admissions_list_loading = false;
    }
  }


  async getReport() {
    var form_data = this.programme_search_form.value
    if (form_data.purpose == '') {
      alert("Please choose a purpose for report generation");
      return;
    }
    console.log(form_data);
    const search_query: any = {
      //payment_collected:false,
      skip: this.start_index,
      take: this.take_index
    };

    if (!form_data.admission_id) {
      alert("Please Choose Admission");
      return;
    }

    if (!form_data.finance_type) {
      alert("Please Choose Finance Type");
      return;
    }

    if (!form_data.fee_target_type) {
      alert("Please Choose Fee Target Type");
      return;
    }

    if (!form_data.payment) {
      alert("Please Choose if Payment is Full or Term 1");
      return;
    }

    if (form_data.regulation_year) {
      search_query.regulation_year = form_data.regulation_year
    }

    if (form_data.admission_id) {
      search_query.admission_id = form_data.admission_id;
    }

    if (form_data.fee_target_type) {
      search_query.fee_target_type = form_data.fee_target_type;
    }

    if (form_data.start_date) {
      search_query.start_date = new Date(form_data.start_date).toISOString();
    }
    if (form_data.end_date) {
      search_query.end_date = new Date(form_data.end_date).toISOString()
    }
    if (form_data.programme_id) {
      search_query.programme_id = form_data.programme_id
    }
    if (form_data.finance_type) {
      search_query.finance_type = form_data.finance_type
    }

    if (form_data.gender) {
      search_query.gender = form_data.gender;
    }

    if (form_data.community_ids) {
      search_query.community_ids = form_data.community_ids;
    }

    if (form_data.payment) {
      search_query.payment = form_data.payment;
    }

    if (form_data.staff_id) {
      search_query.staff_id = form_data.staff_id;
    }


    console.log(search_query);
    this.fees_report = []; this.total_records = 0;
    this.report_list_loading = true;
    try {
      const service_response = await this.restService.getAdmissionReport(search_query);
      if (service_response && service_response.success) {
        this.fees_report = service_response.data;
        this.fees_report.sort((a, b) => (a.roll_no > b.roll_no) ? 1 : -1);
        this.total_records = service_response.data?.count;
        console.log(this.fees_report);
      }
    } catch (error) {
      alert('Error while read data');
    }
    this.report_list_loading = false;
  }


  downloadCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const fees of this.fees_report) {
      data_list.push({
        "S.No": i,
        "Paid Date": fees?.paid_date?.substring(0, 10),
        "Programme": fees.programme_name,
        "App No.": fees.app_no,
        "Roll No.": fees.roll_no,
        "Name": fees.name,
        "Mobile No.": fees.mobile_no,
        "Total Fees": fees.total_amount,
        "Term": fees.term_name,
        "Term Fees": fees.fee_amount,
        "Paid Amount": fees.paid_amount,
        "Remarks": fees.remarks
      });
      i++;
    }
    CSVHelper.downloadCSV(data_list, "Admission_Collection_" + DateHelper.convertToControlDate(new Date()));
  }


  downloadPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
      "<style>.table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('print-table').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }


  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
      };

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }

    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  async viewTransferList() {
    try {
      this.transfer_list = [];
      this.transfer_list_loading = true;
      const query: any = {
        admission_id: this.programme_search_form.value.admission_id,
        programme_id: this.programme_search_form.value.programme_id
      };

      const response = await this.restService.getTransferList('transfer', query);
      if (response.success) {
        this.transfer_list = response.data;
        JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.transfer_list_loading = false;
    }
  }

  async onCloseClick(): Promise<void> {
    this.transfer_list = [];
    JQueryHelper.closeModal('#modal-popup');
  }

}

interface FeesReportInterface {
  paid_date: string,
  programme_name: string,
  roll_no: string,
  app_no: string,
  name: string,
  birth_date: string,
  mobile_no: string,
  total_amount: number,
  term_name: string,
  fee_amount: number,
  paid_amount: number,
  remarks: string
}
