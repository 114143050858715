import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';
@Component({
  selector: 'app-exam-setup',
  templateUrl: './exam-setup.component.html',
  styleUrls: ['./exam-setup.component.scss']
})
export class ExamSetupComponent implements OnInit {

  bulk_render_data: Modal = {
    return_type: "FILE",
    modal_button_text: "Exam Setup Bulk Upload",
    modal_header: "Exam Setup Bulk Upload",
    list_name: "Exam Setup Upload",
    csv_data: {
      columns: [
        { column_name: "month", column_type: "required" },
        { column_name: "year", column_type: "required" },
        { column_name: "programme_type_id", column_type: "required" },
        { column_name: "question_format_id", column_type: "required" },
        { column_name: "course_code", column_type: "required" },
        { column_name: "exam_type", column_type: "required" },
        { column_name: "enrolled_year", column_type: "required" },
        { column_name: "semester", column_type: "required" },
        { column_name: "mode_of_exam", column_type: "required" },
        { column_name: "appearance_type", column_type: "required" },
        { column_name: "allow_students_answer_submission", column_type: "required" },
        { column_name: "start_time", column_type: "required" },
        { column_name: "end_time", column_type: "required" },
        { column_name: "submission_start_time", column_type: "required" },
        { column_name: "submission_end_time", column_type: "required" },
        { column_name: "pass_mark", column_type: "required" },
        { column_name: "pass_mark_combined", column_type: "required" },
        { column_name: "max_cond_mark", column_type: "required" },
        { column_name: "active", column_type: "required" },
        { column_name: "evaluated_in", column_type: "required" },
        { column_name: "programme_id", column_type: "required" },
        { column_name: "section", column_type: "required" },
        { column_name: "virtual_section", column_type: "required" },
        { column_name: "roll_no", column_type: "required" },
        { column_name: "valuation_no", column_type: "required" },
        { column_name: "valuation_end_time", column_type: "required" },
        { column_name: "stage", column_type: "required" },
        { column_name: "completed", column_type: "required" }
      ]
    },
    table_data: {
      column_names: ["month", "year", "programme_type_id", "question_format_id", "course_code", "exam_type", "enrolled_year", "semester",
        "mode_of_exam", "appearance_type", "allow_students_answer_submission", "start_time", "end_time", "submission_start_time", "submission_end_time",
        "pass_mark", "pass_mark_combined", "max_cond_mark", "active", "evaluated_in", "programme_id", "section", "virtual_section",
        "roll_no", "valuation_no", "valuation_end_time", "stage", "completed"
      ],
      column_values: ["May", "2022", "UG", "UG Core", "20UAC203", "ACADEMIC", "2022", "4", "OFFLINE", "NORMAL", "false", "24/05/2022, 10:00 AM", "24/05/2022, 01:00 PM", " ", " ",
        "21", "40", "1", "true", "INTERNAL", "55sdfsdfa - 321sdsfd - 324sdf", "A", " ", "SF100", "1", "24/05/2022, 04:00 PM", "OPEN", "FALSE"]
    },
    show_loader: false
  };

  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  cookieStore = CookieStore;
  title = 'Course Terminal Exam Setup';
  settings_list_loading: boolean = false;
  settings_list: IExamSetup[] = [];
  setting: IExamSetup = {} as IExamSetup;
  exam_setting_form: FormGroup = {} as FormGroup;
  setting_id: string = "";
  enrolled_year: number = 0;
  category_list: any[] = [];
  category_list_loading = false;
  programme_search_form: any;
  valuation_delete_loading: boolean[] = [];
  setting_save_loading: boolean = false;
  state = '';
  course_data_list: any[] = [];
  exam_modes: {
    key: string, text: string
  }[] = [{
    key: "ONLINE", text: "Online Exam"
  },
  {
    key: "OFFLINE", text: "Offline Exam"
  }
    ];
  appearance_types: { key: string, text: string }[] = [{ key: "normal", text: "Normal" }, { key: "ARREAR", text: "Arrear" }, { key: "REPEAT", text: "Repeat Semester" }];
  valuation_types: { key: string, text: string }[] = [{ key: "INTERNAL", text: "Internal Examiner" }, { key: "EXTERNAL", text: "External Examiner" }];
  format_setting_data: any[] = [];
  available_programme_list: any[] = [];
  avaiable_guest_programme_list:any[] = [];
  programme_list: any[] = [];
  valuation_nos: number[] = [1, 2, 3, 4, 5];
  finalize_loading: boolean[] = [];
  student_data: any[] = [];
  student_data_loading: boolean = false;
  ngOnInit(): void {

  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.buildForm();
    this.getExamFormatSettings();
    this.getProgrammeTypeList();
    this.getProgrammeDataList();
    this.buildFilterForm();
  }

  async getExamFormatSettings() {
    try {
      const service_response = await this.restService.searchExamQuestionFormat({});
      if (service_response && service_response.success) {
        console.log(service_response);
        this.format_setting_data = service_response.data;
      }
      else {
        alert("Error! Could not get Question Format Data");
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
    }
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {

    }
  }

  getProgrammeName(class_taking_programme_id: string) {
    return this.programme_list.find(x => x.programme_id == class_taking_programme_id)?.display_name;
  }
  getProgrammeDisplay(programme) {
    if (programme.virtual_section && programme.virtual_section != '') {
      return programme.virtual_section + " (" + programme.staff.given_name + " " + programme.staff.middle_name + "-" + programme.roll_no + ")";
    }
    return this.getProgrammeName(programme.class_taking_programme_id) + " - " + programme.class_taking_section + " (" + programme.staff.given_name + " " + programme.staff.middle_name + "-" + programme.roll_no + ")";
  }

  getStaffName(staff, roll_no) {
    console.log(staff.given_name + " " + staff.middle_name + "-" + roll_no);
    return staff.given_name + " " + staff.middle_name + "-" + roll_no;
  }
  buildFilterForm(): void {
    this.programme_search_form = this.formBuilder.group({
      month: [0],
      year: [0]
    });
  }


  async onSearchClick() {
    if (!this.programme_search_form.value.month) {
      alert("Please select Month"); return;
    }
    if (!this.programme_search_form.value.year) {
      alert("Please select Year"); return;
    }
    this.settings_list = [];
    this.finalize_loading = [];
    this.settings_list_loading = true;
    try {
      const search_query = {
        "month" : parseInt(this.programme_search_form.value.month),
        "year" : parseInt(this.programme_search_form.value.year)
      }
      const service_response = await this.restService.searchExamSetup(search_query);
      if (service_response && service_response.success) {
        this.settings_list = service_response.data;
        console.log(this.settings_list);
        for (var i = 0; i < this.settings_list.length; i++) {
          this.finalize_loading[i] = false;
        }
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    this.settings_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.setting) == '{}') {
      this.setting = {
        _id: "",
        programme_type_id: "",
        course_code: "",
        month: 0,
        year: 0,
        question_format_id: "",
        exam_type: "ACADEMIC",
        enrolled_year: 0,
        semester: 0,
        mode_of_exam: "",
        appearance_type: "",
        allow_students_answer_submission: false,
        start_time: new Date(),
        end_time: new Date(),
        submission_start_time: new Date(),
        submission_end_time: new Date(),
        valuations: [],
        valuation_mark_difference: 0,
        active: false,
        //closed: false,
        max_cond_mark: 0,
        pass_mark: 0,
        pass_mark_combined: 0,
      };
    }
    this.exam_setting_form = this.formBuilder.group({
      _id: [this.setting._id],
      programme_type_id: [this.setting.programme_type_id, [Validators.required]],
      exam_type: ["ACADEMIC"],
      month: [this.setting.month, [Validators.required]],
      year: [this.setting.year, [Validators.required]],
      question_format_id: [this.setting.question_format_id, [Validators.required]],
      course_code: [this.setting.course_code, [Validators.required]],
      enrolled_year: [this.setting.enrolled_year, [Validators.required]],
      semester: [this.setting.semester, [Validators.required]],
      start_time: [DateHelper.convertToControlDateTime(new Date(this.setting?.start_time)), [Validators.required]],
      end_time: [DateHelper.convertToControlDateTime(new Date(this.setting?.end_time)), [Validators.required]],
      mode_of_exam: [this.setting.mode_of_exam, [Validators.required]],
      appearance_type: [this.setting.appearance_type, [Validators.required]],
      valuation_mark_difference: [this.setting.valuation_mark_difference, [Validators.required]],
      allow_students_answer_submission: [this.setting.allow_students_answer_submission],
      submission_start_time:
        this.setting.allow_students_answer_submission ?
          [DateHelper.convertToControlDateTime(new Date(this.setting.submission_start_time))] : '',
      submission_end_time: this.setting.allow_students_answer_submission ?
        [DateHelper.convertToControlDateTime(new Date(this.setting.submission_end_time))] : '',
      active: [this.setting.active],
      //closed: [this.setting.closed],
      valuations: this.formBuilder.array(this.buildValuationsForm(this.setting.valuations)),
      pass_mark: [this.setting.pass_mark, [Validators.required]],
      max_cond_mark: [this.setting.max_cond_mark, [Validators.required]],
      pass_mark_combined: [this.setting.pass_mark_combined, [Validators.required]],
    });
  }

  buildValuationsForm(valuator_list: IExamValuationSettings[]): any {
    if (!valuator_list) {
      valuator_list = [];
    }
    var valuator_list_array: any[] = [];
    if (valuator_list && valuator_list.length > 0) {
      var valuator_count = 0;
      for (const valuator of valuator_list) {
        //console.log(component)
        this.valuation_delete_loading[valuator_count] = false;
        valuator_list_array.push(this.formBuilder.group({
          _id: [valuator._id],
          sub: [valuator.sub],
          evaluated_in: [valuator.evaluated_in, [Validators.required]],
          //roll_no: [valuator.roll_no, [Validators.required]],
          //staff_course_map_id: [valuator.staff_course_map_id, [Validators.required]],
          programme_id: [valuator.programme_id],
          section: [valuator.section],
          virtual_section: [valuator.virtual_section],
          valuation_no: [valuator.valuation_no],
          //start_time: [DateHelper.convertToControlDate(valuator.start_time), [Validators.required]],
          end_time: [DateHelper.convertToControlDate(valuator.end_time), [Validators.required]],
          staff_list: [valuator.staff_list],
          //completed: [valuator.completed],
          stage: [valuator.stage]
        }));
        if (valuator.evaluated_in == 'EXTERNAL') {
          (valuator_list_array[valuator_count] as FormGroup).addControl('roll_no', new FormControl(valuator.roll_no, [Validators.required]));
        }
        valuator_count++;
      }
    }
    const remaining_count = 1 - valuator_list.length;
    if (remaining_count && remaining_count > 0) {
      for (let index = 0; index < remaining_count; index++) {
        this.valuation_delete_loading[index] = false;
        valuator_list_array.push(this.formBuilder.group({
          _id: [""],
          sub: [""],
          evaluated_in: ["", [Validators.required]],
          //staff_course_map_id: ["", [Validators.required]],
          //roll_no: ["", [Validators.required]],
          programme_id: [""],
          section: [""],
          virtual_section: [""],
          valuation_no: [""],
          staff_list: [""],
          stage: [""],
          //start_time: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
          end_time: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
          //completed: [""],
        }));
      }
    }
    console.log(valuator_list_array);
    return valuator_list_array;
  }

  get valuatorList() {
    return ((this.exam_setting_form as FormGroup).get('valuations') as FormArray).controls;
  }

  validateDate(event, control: string) //validate end_time, sub_start_time, sub_end_time
  {
    var control_date: Date;
    var to_compare_date: Date;
    var input_element = (event.target as HTMLInputElement);
    if (control == 'end_time') {
      to_compare_date = this.exam_setting_form.get('start_time').value;
      control_date = this.exam_setting_form.get('end_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Exam End Time cannot be less than Exam Start Time");
        input_element.value = ""; return;
      }
    }
    if (control == 'submission_start_time') {
      to_compare_date = this.exam_setting_form.get('end_time').value;
      control_date = this.exam_setting_form.get('submission_start_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Submission Start Time cannot be less than Exam End Time");
        input_element.value = ""; return;
      }
    }

    if (control == 'submission_end_time') {
      to_compare_date = this.exam_setting_form.get('submission_start_time').value;
      control_date = this.exam_setting_form.get('submission_end_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Submission End Time cannot be less than Submission Start Time");
        input_element.value = ""; return;
      }
    }
  }

  validateValuationDate(event, control: string, index: number) //validate valuation_start_times, valuation_end_times
  {
    var control_date: Date;
    var to_compare_date: Date;
    var input_element = (event.target as HTMLInputElement);

    if (control == 'start_time' && index == 0) {
      control_date = (this.exam_setting_form.get('valuations') as FormArray).controls[index].get('start_time').value;
      if (this.exam_setting_form.value.allow_students_answer_submission) //compare with submission_end_time
      {
        to_compare_date = this.exam_setting_form.get('submission_end_time').value;
        if (control_date < to_compare_date || !to_compare_date) {
          alert("Valuation Start Time cannot be less than Submission End Time");
          input_element.value = ""; return;
        }
      }
      else //compare with end_time
      {
        to_compare_date = this.exam_setting_form.get('end_time').value;
        if (control_date < to_compare_date || !to_compare_date) {
          alert("Valuation Start Time cannot be less than Exam End Time");
          input_element.value = ""; return;
        }
      }
    }
    else if (control == 'start_time' && index > 0) {
      control_date = (this.exam_setting_form.get('valuations') as FormArray).controls[index].get('start_time').value;
      var staff_course_map_id = (this.exam_setting_form.get('valuations') as FormArray).controls[index].get('staff_course_map_id').value;
      if (staff_course_map_id == '') {
        alert("Please choose Programme & Section");
        input_element.value = ""; return;
      }
      to_compare_date = (this.exam_setting_form.get('valuations') as FormArray).controls.find(x => x.get('staff_course_map_id').value == staff_course_map_id).get('end_time').value;
      //to_compare_date = (this.exam_setting_form.get('valuations') as FormArray).controls[index - 1].get('end_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Valuation Start Time cannot be less than Previous Valuation End Time");
        input_element.value = ""; return;
      }
    }

    if (control == 'end_time') {
      control_date = (this.exam_setting_form.get('valuations') as FormArray).controls[index].get('end_time').value;
      to_compare_date = (this.exam_setting_form.get('valuations') as FormArray).controls[index].get('start_time').value;
      if (control_date < to_compare_date || !to_compare_date) {
        alert("Valuation End Time cannot be less than Valuation Start Time");
        input_element.value = ""; return;
      }
    }
  }

  addValuator() {
    ((this.exam_setting_form as FormGroup).get('valuations') as FormArray).push(this.formBuilder.group({
      _id: [""],
      sub: [""],
      evaluated_in: ["", [Validators.required]],
      valuation_no: [""],
      //staff_course_map_id: ["", [Validators.required]],
      programme_id: [""],
      section: [""],
      virtual_section: [""],
      staff_list: [""],
      //roll_no: ["", [Validators.required]],
      //start_time: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      end_time: [DateHelper.convertToControlDate(new Date()), [Validators.required]],
      stage: [""]
      //completed: [""],
    }));
  }

  deleteValuator(index: number) {
    const consent = confirm("Are you sure want to delete this Valuation?");
    if (!consent) return;
    ((this.exam_setting_form as FormGroup).get('valuations') as FormArray).removeAt(index);
  }

  async evaluatedInChanged(event: any, i: number) {
    this.available_programme_list[i] = [];
    var select_element = event.target as HTMLSelectElement;
    if (select_element.value == "") {
      return;
    }
    if (!this.exam_setting_form.value.course_code) {
      alert("Please enter Course Code");
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      return;
    }
    if (!this.exam_setting_form.value.enrolled_year) {
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      alert("Please enter Enrollment Year"); return;
    }
    if (!this.exam_setting_form.value.programme_type_id) {
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      alert("Please enter Programme Type"); return;
    }
    if (!this.exam_setting_form.value.semester) {
      select_element.value = "";
      (this.exam_setting_form.get("valuations") as FormArray).controls[i].get("evaluated_in").setValue("");
      alert("Please enter Semester"); return;
    }
    var search_query = {
      course_code: this.exam_setting_form.value.course_code,
      enrolled_year: this.exam_setting_form.value.enrolled_year,
      programme_type_id: this.exam_setting_form.value.programme_type_id,
      semester: this.exam_setting_form.value.semester
    };
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    if (select_element.value == 'EXTERNAL') {
      form_row.addControl('roll_no', new FormControl('', [Validators.required]));
    }
    else {
      if (form_row?.contains('roll_no')) {
        form_row?.removeControl('roll_no');
      }
    }
    //form_row.get('staff_course_map_id').setValue("");
    //form_row.get('roll_no').setValue("");
    form_row.get('staff_list').setValue("");
    form_row.get('programme_id').setValue("");
    form_row.get('section').setValue("");
    form_row.get('virtual_section').setValue("");

    try {
      const service_response = await this.restService.searchStaffCourseMap(search_query); //v2
      if (service_response && service_response.success) {
        const res_data: any[] = service_response.data.data;
        res_data.forEach(programme => {
          this.available_programme_list[i].push({
            roll_no: programme.roll_no,
            staff_course_map_id: programme._id,
            class_taking_programme_id: programme.class_taking_programme_id,
            class_taking_section: programme.class_taking_section,
            virtual_section: programme.virtual_section,
            staff: programme.staff,
            staff_name: this.getStaffName(programme.staff, programme.roll_no)
          })
        })
        console.log(this.available_programme_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {

    }
  }

  valuationChanged(event, i) {
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    var select_element = event.target as HTMLSelectElement;
    if (select_element.value == "1" || select_element.value == "2") {
      form_row.get('stage').setValue("STARTED");
    }
    else {
      form_row.get('stage').setValue("NOT_STARTED");
    }
  }

  changeValuationStage(stage: string, i) {
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    if (stage == "COMPLETED") return;
    var stages = ["NOT_STARTED", "STARTED", "COMPLETED"];
    var to_stage = stages[stages.indexOf(stage) + 1];
    form_row.get('stage').setValue(to_stage);
  }

  getMaxCond() {
    if (this.exam_setting_form.value.programme_type_id == "UG") {
      return [1, 2, 3, 4];
    }
    return [1, 2];
  }

  pgmChanged(event, i) {
    var select_element = event;
    var form_row: FormGroup = (this.exam_setting_form.get('valuations') as FormArray).controls[i] as FormGroup;
    console.log(form_row);
    var pgm = this.available_programme_list[i].find(x => x.staff_course_map_id == select_element[0]?.staff_course_map_id);
    console.log(pgm);
    /* if (form_row.get('evaluated_in').value == 'INTERNAL') {
      form_row.get('roll_no').setValue(pgm.roll_no);
    }
    else {
      form_row.get('roll_no').setValue("");
    } */
    if (pgm.virtual_section && pgm.virtual_section != '') {
      form_row.get('virtual_section').setValue(pgm.virtual_section);
      form_row.get('programme_id').setValue("");
      form_row.get('section').setValue("");
    }
    else {
      form_row.get('programme_id').setValue(pgm.class_taking_programme_id);
      form_row.get('section').setValue(pgm.class_taking_section);
      form_row.get('virtual_section').setValue("");
    }
  }

  onAddClick() {
    this.buildForm();
    this.state = "SETTING";
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(setting: any) {
    try {
      this.state = "SETTING";
      this.available_programme_list = [];
      const service_response = await this.restService.findExamSetup(setting._id);
      if (service_response && service_response.success) {
        var settings = service_response.data;
        if (settings) {
          console.log(settings);
          var search_query = {
            course_code: settings.course_code,
            enrolled_year: settings.enrolled_year,
            programme_type_id: settings.programme_type_id,
            semester: settings.semester
          };
          var available_programme_list: any[] = []
          try {
            const service_response = await this.restService.searchStaffCourseMap(search_query); //v2
            if (service_response && service_response.success) {
              const res_data: any[] = service_response.data.data;
              res_data.forEach(programme => {
                available_programme_list.push({
                  roll_no: programme.roll_no,
                  staff_course_map_id: programme._id,
                  class_taking_programme_id: programme.class_taking_programme_id,
                  class_taking_section: programme.class_taking_section,
                  virtual_section: programme.virtual_section,
                  staff: programme.staff,
                  staff_name: this.getStaffName(programme.staff, programme.roll_no)
                })
              })
              //available_programme_list = service_response.data.data;
              for (var i = 0; i < settings.valuations.length; i++) {
                this.available_programme_list[i] = available_programme_list;
              }


              console.log(this.available_programme_list);
            }
          } catch (error) {
            alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
          }
          this.setting = settings;
          this.buildForm();
          JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
        }
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async viewMarks(index: number) {
    try {
      this.state = "MARKS";
      this.student_data = [];
      var student_search_query = {
        "exam_id": this.exam_setting_form.value._id,
        "staff_map_id": (this.exam_setting_form as FormGroup).value.valuations[index].staff_list[0].staff_course_map_id,
        "valuation_id": (this.exam_setting_form as FormGroup).value.valuations[index]._id,
        "evaluated_in": (this.exam_setting_form as FormGroup).value.valuations[index].evaluated_in
      }
      this.student_data_loading = true;
      const service_response = await this.restService.findStudentScoreCard(student_search_query);
      if (service_response && service_response.success) {
        this.student_data = (service_response.data);
        console.log(this.student_data);
        JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.student_data_loading = false;
    }
  }
  closeMarks() {
    this.student_data_loading = false;
    this.state = "SETTING";
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    this.state = "";
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.setting_id = "";
    this.available_programme_list = [];
    this.setting = {} as IExamSetup;
    this.exam_setting_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.exam_setting_form.value;
      form_data.start_time = new Date(this.exam_setting_form.value.start_time).toISOString()
      form_data.end_time = new Date(this.exam_setting_form.value.end_time).toISOString()
      if (!form_data.allow_students_answer_submission) {
        delete form_data.submission_start_time;
        delete form_data.submission_end_time;
      }
      else {
        form_data.submission_start_time = new Date(this.exam_setting_form.value.submission_start_time).toISOString()
        form_data.submission_end_time = new Date(this.exam_setting_form.value.submission_end_time).toISOString()
      }
      var valuations: any[] = [];
      valuations = form_data.valuations;
      for (var i = 0; i < valuations.length; i++) {
        /* valuations[i].start_time = new Date(valuations[i].start_time).toISOString();*/
        valuations[i].end_time = new Date(valuations[i].end_time).toISOString();
        if (valuations[i].virtual_section && valuations[i].virtual_section != '') {
          delete valuations[i].programme_id;
          delete valuations[i].section;
        }
        else {
          delete valuations[i].virtual_section;
        }
        var staff_list: any[] = valuations[i].staff_list;
        var staff_obj_arr: any[] = [];
        staff_list.forEach(staff => {
          var staff_obj = {
            roll_no: staff.roll_no,
            staff_course_map_id: staff.staff_course_map_id
          };
          staff_obj_arr.push(staff_obj);
        });
        valuations[i].staff_list = staff_obj_arr;
      }
      let update_respose;
      form_data.valuations = valuations;
      console.log(form_data);
      if (!form_data._id) //create mode
      {
        this.setting_save_loading = true;
        update_respose = await this.restService.createExamSetup(form_data);
        if (update_respose.success) {
          await this.onSearchClick();
          alert('Exam Data Saved Successfully');
        }
        else {
          alert("Error! Could not create Exam Data");
        }
        this.onSearchClick();
      }
      else //edit mode
      {
        this.setting_save_loading = true;
        update_respose = await this.restService.updateExamSetup(form_data._id, form_data);
        if (update_respose.success) {
          await this.onSearchClick();
          alert('Exam Data Updated Successfully');
        }
        else {
          alert("Error! Could not Update Exam Data");
        }
        this.onSearchClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      console.log(error);
    }
    finally {
      this.setting_save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      const res = await this.restService.deleteExamSetup(this.exam_setting_form.get('_id').value);
      if (res.success) {
        alert("Deleted Exam settings successfully");
        this.onSearchClick();
      }
      else {
        alert("Failed to delete Exam settings");
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
    }
  }

  isInvalid(formControl: string): boolean {
    return this.exam_setting_form.touched && this.exam_setting_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.exam_setting_form.status !== 'VALID';
  }

  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  navigateToQuestionSetting(setting) {
    HTMLHelper.openLinkInNewTab("admin/terminal/question-setting?exam_id=" + setting._id
      + "&question_format_id=" + setting.question_format_id);
  }

  navigateValuationScheme(setting) {
    HTMLHelper.openLinkInNewTab("staff/terminal/valuation-scheme?exam_id=" + setting._id
      + "&question_format_id=" + setting.question_format_id);
  }

  navigateToSubmissionReview(setting) {
    HTMLHelper.openLinkInNewTab("admin/terminal/student-submission-review?exam_id=" + setting._id);
  }

  navigateMarkRegister(setting) {
    HTMLHelper.openLinkInNewTab("admin/terminal/course-grade-register?exam_id=" + setting._id
      + "&month=" + setting.month + "&year=" + setting.year
    );
  }

  async finalizeExam(setting, i: number) {
    if (setting.closed) {
      alert("Error! This Exam is already closed"); return;
    }
    const consent = confirm("Warning: Are you sure want to finalize and close this exam? This action is irreversible!");
    if (!consent) return;
    try //edit mode
    {
      this.finalize_loading[i] = true;
      let update_respose = await this.restService.closeExamSetup(setting._id, {});
      if (update_respose.success) {
        await this.onSearchClick();
        alert('Exam Setting Closed Successfully');
      }
      else {
        alert("Error! Could not Update Exam Data");
      }
      this.onSearchClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
      console.log(error);
    }
    finally {
      this.finalize_loading[i] = false;
    }
  }

  async bulkUpload(e: any) {
    try {
      console.log(e);
      const file = e;
      const formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      this.bulk_render_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.bulkExamSetupkUpload(formData);
      if (response.success && response.data && response.data.url) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_data.show_loader = false;
    }
  }


}

interface IExamSetup {
  _id: string;
  month: number,
  year: number,
  programme_type_id: string,
  question_format_id: string,
  course_code: string;
  exam_type: string; //???
  enrolled_year: number;
  semester: number;
  mode_of_exam: string; //online, offline
  appearance_type: string; //regular, arrear, repeat
  allow_students_answer_submission: boolean; //true, false

  start_time: Date;
  end_time: Date;
  submission_start_time?: Date;
  submission_end_time?: Date;
  valuations: IExamValuationSettings[];
  valuation_mark_difference: number;
  pass_mark?: number;
  pass_mark_combined?: number;
  max_cond_mark?: number;
  active: boolean;
  closed?: boolean;

}

export interface IExamValuationSettings {
  _id?: string;
  id?: string;
  evaluated_in: string;
  //staff_course_map_id: string,
  programme_id?: string,
  section?: string,
  virtual_section?: string,
  valuation_no: number;
  roll_no?: string;
  sub: string;
  name?: string;
  //start_time: Date;
  end_time: Date;
  stage: string,
  completed?: boolean;
  staff_list: {
    roll_no: string,
    staff_course_map_id: string
  }[]
}
