import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offered-programmes',
  templateUrl: './offered-programmes.component.html',
  styleUrls: ['./offered-programmes.component.scss']
})
export class OfferedProgrammesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
