import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-co-report',
  templateUrl: './co-report.component.html',
  styleUrls: ['./co-report.component.scss']
})
export class CoReportComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  category_list: any[] = [];
  category_list_loading = false;

  programme_list: any[] = [];
  sections: any[] = [];
  programme_list_loading = false;

  programme_search_form: any;
  today_date = new Date();
  exam_id: string = "";
  marks: any = []
  staff_course_map_id: string = "";
  evaluated_in: string = "";
  score_sheet_data: any[] = [];
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService, private http: HttpClient) {
    this.getProgrammeTypeList();
  }
  mark_data: MarkData = undefined;
  mark_data_loading: boolean = false;
  async ngOnInit() {
    await this.getProgrammeDataList();
    this.route.queryParams.subscribe(params => {
      this.exam_id = params.exam_id;
      this.staff_course_map_id = params.staff_course_map_id;
      this.evaluated_in = params.evaluated_in;
      this.mark_data = {
        term: this.getMonth(params.month) + ", " + params.year,
        semester: this.commonEnums.roman_letters[params.semester],
        class_name: params.class_name,
        course_code: params.course_code,
        course_name: params.course_name,
        max_mark: params.max_mark,
        valuation_no: this.commonEnums.roman_letters[params.valuation_no],
        staff_name: (params.staff_name as string).replace('undefined', ''),
        marks: []
      }
      if (this.exam_id != undefined && this.staff_course_map_id != undefined) {
        this.getFoilCard();
      }
    });


  }

  getMonth(month: string) {
    return this.commonEnums.months.find(x => x.id == month)?.text;
  }
  getProgrammeName(id: string) {
    return this.programme_list.find(x => x.programme_id == id)?.programme_name;
  }

  async getFoilCard() {
    try {
      this.mark_data_loading = true;
      var student_search_query = {
        "exam_id": this.exam_id,
        "staff_map_id": this.staff_course_map_id,
        "evaluated_in": this.evaluated_in
      }
      const service_response = await this.restService.findStudentScoreCard(student_search_query);
      if (service_response && service_response.success) {
        this.score_sheet_data = (service_response.data);
        for (var i = 0; i < this.score_sheet_data.length; i++) {
          this.mark_data.marks.push({
            roll_no: this.score_sheet_data[i].student_roll_no,
            name: this.score_sheet_data[i].student_name,
            co_items: this.score_sheet_data[i].co_items,
            sub_total: this.score_sheet_data[i].sub_total,
            total_raw: this.score_sheet_data[i].total_raw,
            total: this.score_sheet_data[i].total,
            scale_to: this.score_sheet_data[i].scale_to,
            max_total: this.score_sheet_data[i].max_total,
          });
        }
        console.log(this.mark_data);
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.mark_data_loading = false;
    }
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }



  /* async onFilterSelectChange(id: string, event?: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList(this.programme_search_form);
    }

    if (id == "programme_id") {
      this.sections = [];
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
    }
  } */

  async getProgrammeDataList(): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      this.sections = [];
      const search_form_query: any = {

      };

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.programme_list_loading = false;
    }
  }

  printFoilCard() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
      "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
      "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>");
    mywindow.document.write('</head><body><div style="margin-top:40px; font-family:\'serif\'">');
    mywindow.document.write(document.getElementById('foil_card_content').innerHTML);
    mywindow.document.write('</div></body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

}



interface MarkData {
  term: string,
  class_name?: string,
  semester?: string,
  course_code: string,
  course_name: string,
  max_mark: number,
  valuation_no: number,
  staff_name: string,
  marks: {
    roll_no: string,
    name: string,
    co_items: any[],
    sub_total: number //overall total with decimal point
    total_raw: number //scaled down with decimal point
    total: number, //scaled down overall
    scale_to: number,
    max_total: number
  }[]
}
