import { NavigationService } from './../../../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../../../components/notification/notification.service';
import { Floor } from './../../../floor-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Hostel } from './../../../hostelModel';
import { AdminservicesService } from './../../../../../../services/rest/adminservices.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-manage-floors',
  templateUrl: './manage-floors.component.html',
  styleUrls: ['./manage-floors.component.scss']
})
export class ManageFloorsComponent implements OnInit {
  hostelId: string = '';
  floorId: string = '';
  hostel: Hostel = {} as Hostel;
  floorForm: FormGroup;
  floor: Floor = {} as Floor;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private restService: AdminservicesService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.floorForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.route.params.subscribe(params => {
      if (!params.hostelId || !params.floorId) {
        this.notificationService.setNotifcation({ message: 'HostelId or FloorId is mising', type: 'Error' });
        return;
      }
      this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
      this.navigationService.updateNavigation('Edit Floor', this.router.url);
      this.hostelId = params.hostelId;
      this.floorId = params.floorId;
      this.restService.hostelManagementService.getHostels(this.hostelId).subscribe(hostels => {
        const floor = hostels[0].floors?.filter(flr => flr.id === this.floorId)[0];
        if (floor) {
          this.floor = floor;
          this.floorForm = this.formBuilder.group({
            name: this.floor.name
          });
        }
      });
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  goToIncharges(): void {
    this.router.navigate([`admin/hostel/hostel-management/incharges/${this.hostelId}/${this.floorId}`]);
  }

  goToRooms(): void {
    this.router.navigate([`admin/hostel/hostel-management/floors/rooms/${this.hostelId}/${this.floorId}`]);
  }

  errorHandler = (error: HttpErrorResponse): void => {
    console.log('Error ', error);
    if (error?.status && error?.statusText) {
      this.notificationService.setNotifcation({ message: `${error.status} ${error.statusText}`, type: 'Error' });
    }
  };

  onSave(): void {
    this.restService.hostelManagementService.updateHostel(this.hostel.id, { data: this.getFormData() }).subscribe(data => {
      console.log(data);
      this.notificationService.setNotifcation({ message: 'Updated hostel details.', type: 'Success' });
      this.onClose();
    }, this.errorHandler);
  }

  isNameInvalid(): boolean {
    return !this.getFormData().name;
  }

  canSave(): boolean {
    if (!this.getFormData().name) {
      return true;
    }
    return false;
  }

  getFormData(): any {
    return this.floorForm.value;
  }
}
