<app-side-bar></app-side-bar>
<div class="main-content app-content">
    <app-combo-bar></app-combo-bar>
    <app-notification></app-notification>
    <!-- container -->
    <div class="container-fluid">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!--Table View-->
        <div class="row">
            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">{{title}}</h4>

                        </div>
                        <p class="tx-12 tx-gray-500 mb-2">Upload Internal Exam Answer Sheets</p>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 5%;">S.No</th>
                                        <th style="width: 10%;">Course Code</th>
                                        <th style="width: 20%;">Course Name</th>
                                        <th style="width: 5%;">Component Name</th>
                                        <th style="width: 10%;">Submission Start Time</th>
                                        <th style="width: 10%;">Submission End Time</th>
                                        <th style="width: 25%;">File Action</th>
                                        <th style="width: 15%;">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="course_list.length>0">
                                        <ng-container
                                            *ngFor="let data of course_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <ng-container
                                                *ngFor="let course of data.data;let j=index;trackBy:fromDataResolver.identify;">
                                                <tr>
                                                    <td scope="row">
                                                        {{j+1}}
                                                    </td>
                                                    <td>{{course.course_code}}</td>
                                                    <td>{{course.course.course_name}}</td>
                                                    <td>{{course.appearance_type | titlecase}}</td>
                                                    <td>{{course.exam.submission_start_time |
                                                        date:commonEnums.date_time_format }}
                                                    </td>
                                                    <td>{{course.exam.submission_end_time |
                                                        date:commonEnums.date_time_format}}
                                                    </td>
                                                    <td>
                                                        <div class="pr-1 mb-3 mb-xl-0">
                                                            <input class="form-control" required="" type="file"
                                                                accept="application/pdf" [(ngModel)]="files[j]"
                                                                (change)="openModal($event,course,j)"
                                                                *ngIf="course.exam.submission?.stage!='APPROVED' && !isTimeExpired(course.exam.submission_end_time)">
                                                            <span (click)="viewPreview(course)" style="cursor: pointer;"
                                                                *ngIf="course.exam.submission.file_path && course.exam.submission?.file_path!=''"><u>Preview</u></span>
                                                            <span
                                                                *ngIf="isTimeExpired(course.exam.submission_end_time) && course.exam.submission?.stage!='APPROVED'"><br>
                                                                <b>Uploading Time Expired</b>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="!course.exam.submission?.stage">No file uploaded
                                                            yet</span>
                                                        {{course.exam.submission.stage}} <br>
                                                        <span *ngIf="course.exam.submission?.stage=='REJECTED'">Reason:
                                                            <b>{{course.exam.submission.reviewed_by_note}}</b></span>
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="!course_list_loading && course_list[0]?.data?.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="course_list_loading && course_list[0]?.data?.length==0">
                                        <tr>
                                            <th colspan="8" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Settings Large Modal -->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document">
                <div class="modal-content modal-content-demo" *ngIf="student_file_data">
                    <div class="modal-header">
                        <h6 class="modal-title">File Preview</h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <pdf-viewer [src]="this.preview_url" [render-text]="false" [original-size]="true"
                                        [fit-to-page]="true" [show-borders]="true" style="height: 600px;">
                                    </pdf-viewer>
                                </div>
                            </div>
                            <div class="row mg-t-15">
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-primary mg-r-10" (click)="uploadFile()"
                                        *ngIf="modal_mode=='UPLOAD' && !file_uploading">
                                        Upload File
                                    </button>
                                    <button class="btn btn-primary mg-r-10" disabled
                                        *ngIf="modal_mode=='UPLOAD' && file_uploading ">
                                        Uploading...
                                    </button>
                                    <button class="btn btn-secondary" (click)="closeModal()">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--End Large Modal -->


    </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>