import { Component, OnInit } from '@angular/core';
import { FromDataResolver } from '../../../../services/helpers/FormDataResolver';
import { AdminservicesService } from '../../../../services/rest/adminservices.service';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { JQueryHelper } from '../../../../services/helpers/JQueryHelper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';

@Component({
  selector: 'app-programme-pam',
  templateUrl: './programme-pam.component.html',
  styleUrls: ['./programme-pam.component.scss']
})
export class ProgrammePamComponent implements OnInit {
  title = 'Programme PAM';
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  fonts = fonts;
  filter_form: FormGroup;

  programme_type_list: any[] = [];
  programme_type_list_loading = false;
  programme_list: any[] = [];
  programme_list_loading = false;

  table_data_loading: boolean = false;
  generate_loading: boolean = false;
  pam_report: PAMReport = {} as PAMReport;
  table_data: MatrixCourseData[] = [];
  course_code: string = '';
  po_levels: string[] = ['po1', 'po2', 'po3', 'po4', 'po5', 'po6', 'po7'];
  pam_render_data: Modal = {
    return_type: "OBJECT",
    file_upload_only: false,
    modal_id: "pam-upload",
    modal_button_text: "PAM Scores Bulk Upload",
    modal_header: "PAM Scores Bulk Upload",
    list_name: "PAM Scores Bulk",
    csv_data: {
      columns: [
        { column_name: "course_code", column_type: "required" },
        { column_name: "po1", column_type: "required" },
        { column_name: "po2", column_type: "required" },
        { column_name: "po3", column_type: "required" },
        { column_name: "po4", column_type: "required" },
        { column_name: "po5", column_type: "required" },
        { column_name: "po6", column_type: "required" },
        { column_name: "po7", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Course Code", "PO1", "PO2", "PO3", "PO4", "PO5", "PO6", "PO7"],
      column_values: ["20UAC101", "2.3", "2.5", "2.1", "2.4", "2.2", "2.7", "2.6"]
    },
    show_loader: false,
    download_note: `Enter the result of PAM Score for each Course Code`
  };
  constructor(private formBuilder: FormBuilder, private notificationService: NotificationService, private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.buildSearchForm();
  }
  buildSearchForm(): void {
    this.filter_form = this.formBuilder.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      regulation_year: [''],
    });
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.filter_form);
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick() {
    this.table_data = [];
    if (!this.filter_form.value.programme_type_id) {
      alert("Please choose Programme Type"); return;
    }
    if (!this.filter_form.value.finance_type) {
      alert("Please choose Finance Type"); return;
    }
    if (!this.filter_form.value.programme_id) {
      alert("Please choose Programme Name"); return;
    }
    if (!this.filter_form.value.regulation_year) {
      alert("Please choose Regulation Year"); return;
    }
    this.table_data_loading = true;
    try {
      const pam_search_obj = {
        programme_id: this.filter_form.value.programme_id,
        regulation_year: this.filter_form.value.regulation_year
      }
      const service_response = await this.restService.searchPAM(pam_search_obj);
      if (service_response && service_response.success && service_response.data) {
        this.pam_report = service_response.data;
        this.table_data = this.pam_report.matrix_data;
      }
      else {
        this.onGenerateClick();
      }

    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
      this.table_data_loading = false;
    }
    finally {
      this.table_data_loading = false;
    }
  }

  async onGenerateClick() {
    this.table_data = [];
    if (!this.filter_form.value.programme_type_id) {
      alert("Please choose Programme Type"); return;
    }
    if (!this.filter_form.value.finance_type) {
      alert("Please choose Finance Type"); return;
    }
    if (!this.filter_form.value.programme_id) {
      alert("Please choose Programme Name"); return;
    }
    if (!this.filter_form.value.regulation_year) {
      alert("Please choose Regulation Year"); return;
    }
    this.table_data_loading = true;
    this.generate_loading = true;
    try {
      const pam_search_obj = {
        programme_id: this.filter_form.value.programme_id,
        regulation_year: this.filter_form.value.regulation_year
      }
      const service_response = await this.restService.generatePAM(pam_search_obj);
      if (service_response && service_response.success) {
        this.pam_report = service_response.data;
        this.table_data = this.pam_report.matrix_data;
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    finally {
      this.table_data_loading = false;
      this.generate_loading = false;
    }
  }

  async deleteCourse(course_code) {
    const consent = confirm("Are you sure want to remove this course from the PAM report? This action is irreversible!");
    if (!consent) {
      return;
    }
    const obj = {
      _id: this.pam_report._id,
      course_code: course_code,
      type: "REMOVE"
    };
    try {
      const service_response = await this.restService.addRemovePAMCourses(obj);
      if (service_response && service_response.success) {
        this.onSearchClick();
        alert("Course removed from PAM Successfully")
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.table_data_loading = false;
    }
    //update api call
  }

  async addCourse() {
    const obj = {
      _id: this.pam_report._id,
      course_code: this.course_code,
      type: "ADD"
    };
    try {
      const service_response = await this.restService.addRemovePAMCourses(obj);
      if (service_response && service_response.success) {
        this.onSearchClick();
        this.closeCourseAddModal();
        alert("Course added to PAM Successfully")
      }
    }
    catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.table_data_loading = false;
    }

    //update api call
  }

  openCourseAddModal() {
    this.course_code = '';
    JQueryHelper.openModal('#modal-popup-add', { keyboard: false, backdrop: 'static' });
  }

  async closeCourseAddModal(): Promise<void> {
    this.course_code = '';
    JQueryHelper.closeModal('#modal-popup-add');
  }

  async bulkUploadPAM(e) {
    try {
      console.log(e);
      this.pam_render_data.show_loader = true;
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.bulkPAMUpload(e, this.filter_form.value.regulation_year, this.filter_form.value.programme_id);
      if (response.success && response.data) {
        this.onSearchClick();
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.pam_render_data.show_loader = false;
    }
  }
}

interface PAMReport {
  _id: string,
  id: string,
  programme_type_id: string,
  programme_id: string,
  regulation_year: number,
  matrix_data: MatrixCourseData[]
}

interface MatrixCourseData {
  course_code: string,
  course_name: string,
  part: string,
  course_type: string,
  offered_by_programme_id?: string
  po_scores: POScore
}

interface POScore {
  po1: number,
  po2: number,
  po3: number,
  po4: number,
  po5: number,
  po6: number,
  po7: number,
  po8?: number,
  po9?: number,
  po10?: number,
  po11?: number,
  po12?: number,
}
