<!--Exam Fee Setting component-->
<app-side-bar></app-side-bar>
<div class="main-content app-content">
  <app-combo-bar></app-combo-bar>
  <app-notification></app-notification>
  <!-- container -->
  <div class="container-fluid">
    <!-- breadcrumb -->
    <div class="breadcrumb-header justify-content-between">
      <div class="left-content">
        <div>
          <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
          <p class="mg-b-0"></p>
        </div>
      </div>
      <div class="d-flex my-xl-auto right-content">
        <div class="pr-1 mb-3 mb-xl-0">
          <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Fine Rule</button>
        </div>
      </div>
    </div>
    <!--Table View-->
    <div class="row">
      <div class="col-xl-12">
        <div class="card mg-b-20">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <h4 class="card-title mg-b-0">{{title}}</h4>

            </div>
            <p class="tx-12 tx-gray-500 mb-2">Manage Fine Rules & Settings</p>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0 text-md-nowrap">
                <thead>
                  <tr>
                    <th style="width: 10%;">S.No</th>
                    <th>Fine Name</th>
                    <th style="width:15%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="fine_list.length>0">
                    <ng-container *ngFor="let fine of fine_list;let i=index;trackBy:fromDataResolver.identify;">
                      <tr>
                        <td scope="row">
                          {{i+1}}
                        </td>
                        <td>{{fine.fine_name}}</td>
                        <td>
                          <div class="pr-1 mb-3 mb-xl-0">
                            <button type="button" (click)="onEditClick(fine)" class="btn btn-sm btn-info mr-2">
                              <fa-icon [icon]="fonts.faPencilAlt"></fa-icon>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="!fine_list_loading && fine_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">No Records found</th>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="fine_list_loading && fine_list.length==0">
                    <tr>
                      <th colspan="3" class="text-center">Loading Please wait</th>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>


    <!-- Settings Large Modal -->
    <div class="modal" id="modal-popup">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content modal-content-demo" *ngIf="fine_setting_form">
          <div class="modal-header">
            <h6 class="modal-title">Manage Fine Rules</h6>
          </div>
          <div class="modal-body">
            <div class="card card-body pd-20 pd-md-40">
              <form [formGroup]="fine_setting_form" class="form-horizontal">
                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Fine Name</label>
                  <input class="form-control" placeholder="Enter Fine Name" required="" type="text"
                    formControlName="fine_name">
                  <div *ngIf="isInvalid('fine_name')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Fine Name is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Re-admission Fee</label>
                  <input class="form-control" placeholder="Enter Re-admission Fee" required="" type="number"
                    formControlName="re_admission_fee">
                  <div *ngIf="isInvalid('re_admission_fee')">
                    <ul class="parsley-errors-list filled" id="parsley-id-9">
                      <li class="parsley-required">Re-admission fee is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="card box-shadow-0">
                  <div class="card-header">
                    <h4 class="card-title mb-1">Add Rules</h4>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Day Limit</label>
                          <input class="form-control" #limit placeholder="Enter Day Limit"
                            (keydown)="triggerFunction($event)" type="number" formControlName="day_limit">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600">Fine Amount (Rs.)</label>
                          <input class="form-control" type="number" placeholder="Enter Fine Amount (Rs.)"
                            (keydown)="triggerFunction($event)" formControlName="fine_amount">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="main-content-label tx-11 tx-medium tx-gray-600 mg-r-5">Is Per Day?</label>
                          <input type="checkbox" formControlName="is_per_day">
                        </div>
                      </div>
                    </div>
                    <div class="add-btn-row">
                      <button class="btn btn-primary mg-r-20" (click)="addRule()">Add Rule</button>

                    </div>
                    <div class="table-responsive border-top userlist-table mg-t-10" *ngIf="fine_rules.length>0">
                      <table class="table card-table table-striped table-vcenter text-md-nowrap mb-0">
                        <thead>
                          <th>
                            <span>S.No</span>
                          </th>
                          <th>
                            <span>Day Limit</span>
                          </th>
                          <th>
                            <span>Fine Amount</span>
                          </th>
                          <th>
                            <span>Is Per Day</span>
                          </th>
                          <th>
                            Delete
                          </th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let fine of fine_rules; let i=index;trackBy:fromDataResolver.identify;">
                            <td>{{i+1}}</td>
                            <td>{{fine.day_limit}}</td>
                            <td>Rs. {{fine.fine_amount}}</td>
                            <td>{{fine.is_per_day?'Yes':'No'}}</td>
                            <td><button type="button" class="btn btn-sm btn-danger mr-2" (click)="onDeleteFine(i)">
                                <fa-icon [icon]="fonts.faTrash"></fa-icon>
                              </button></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>


                <div class="form-group">
                  <label class="main-content-label tx-11 tx-medium tx-gray-600">Select action to be followed after last
                    fine date ends</label>
                  <div class="form-group">
                    <span *ngFor="let rule of post_fine_rules; let i = index;trackBy:fromDataResolver.identify;">
                      <input type="radio" class="mg-r-10" name="post_fine_rule" [value]="rule.id"
                        formControlName="post_fine_rule">
                      <label class="mg-r-15">{{rule.name}}</label>
                    </span>
                  </div>

                  <div *ngIf="isInvalid('post_fine_rule')">
                    <ul class="parsley-errors-list filled">
                      <li class="parsley-required">Post Fine Rule is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="text-center">
                  <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                  <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()" [disabled]="canDisable()">Save &
                    Close</button>
                  <button class="btn btn-danger mg-r-20" (click)="onDelete()"
                    *ngIf="fine_setting_form.value._id">Delete</button>
                  <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                  <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--End Large Modal -->

  </div>
</div>



<!-- /main-content -->
<app-footer></app-footer>