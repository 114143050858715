import { NavigationService } from './../../../../components/navigation/navigation.service';
import { NotificationService } from './../../../../components/notification/notification.service';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Router } from '@angular/router';
import { Hostel } from './../hostelModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-hostel',
  templateUrl: './create-hostel.component.html',
  styleUrls: ['./create-hostel.component.scss']
})
export class CreateHostelComponent implements OnInit {
  hostel: Hostel = {} as Hostel;
  hostelForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private restService: AdminservicesService,
    private notificationService: NotificationService,
    private navigationService: NavigationService) {
    this.hostelForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      floors: [],
      incharges: []
    });
  }

  onClose(): void {
    this.navigationService.navigateBack();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    this.onClose();
  }

  ngOnInit(): void {
    this.restService.initializeServices();
    this.navigationService.homeURL = { label: 'Hostels', url: 'admin/hostel/hostel-management' };
    this.navigationService.updateNavigation('Create', this.router.url);
    this.notificationService.setNotifcation({});
  }

  canSave(): boolean {
    if (!this.getFormData().name) {
      return true;
    }
    return false;
  }

  getHostelFromForm(): string {
    return this.getFormData().name;
  }

  onSave(): void {
    this.restService.hostelManagementService.createHostel(this.getHostelFromForm()).subscribe(data => {
      console.log(data);

      if (data) {
        this.notificationService.setNotifcation({
          message: 'Saved hostel successfully.',
          type: 'Success'
        });
        this.onClose();
      }
    });
  }

  getFormData(): any {
    return this.hostelForm.value;
  }

  buildForm(): void {

  }
}
