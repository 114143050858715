import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../ModalInterface';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() render_data = {} as Modal;
  @Output() csvData = new EventEmitter<any>();
  fonts = fonts;
  required_columns: string[] = [];
  csvRecords: any[] = [];
  header = true;
  invalid_csv: boolean = true;
  file: any;
  file_upload: any;
  fromDataResolver = FromDataResolver;
  headers: string[] = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"];
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;

  constructor(private ngxCsvParser: NgxCsvParser) {

  }
  ngOnInit(): void {
    this.getRequiredColumns();
  }


  openModal(): void {
    console.log(this.render_data);
    if (!this.render_data.btn_size) {
      this.render_data.btn_size = 'btn-md';
    }
    if (this.render_data.download_note) {
      document.getElementById('download_note').innerHTML = this.render_data.download_note;
    }
    JQueryHelper.openModal('#' + this.render_data.modal_id, { keyboard: false, backdrop: 'static' });
  }

  async onCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#' + this.render_data.modal_id + "preview");
    JQueryHelper.closeModal('#' + this.render_data.modal_id);
    if (document.getElementsByClassName("modal show").length > 0)
      document.getElementsByTagName("body")[0].classList.add("modal-open");
  }

  getRequiredColumns() {
    this.render_data?.csv_data?.columns.forEach(column => {
      if (column.column_type == "required") {
        this.required_columns.push(column.column_name);
      }
    });
    console.log(this.required_columns);
  }

  downloadBlankCSV() {
    let csvContent = "data:text/csv;charset=utf-8,";
    this.render_data.csv_data.columns.forEach(column => {
      csvContent += column.column_name + ",";// + "[" + column.column_type + "],";
    })
    csvContent = csvContent.substring(0, csvContent.length - 1);
    csvContent += "\r\n";
    //this.render_data.csv_data.columns.join(",")+"\r\n";
    console.log(this.render_data.csv_data?.row_data);
    if (this.render_data.csv_data?.row_data != "" && this.render_data.csv_data?.row_data != undefined)
      csvContent += this.render_data?.csv_data?.row_data;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.render_data.modal_header + " blank template");
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  downloadListCSV() {

  }
  fileChangeListener($event: any): void {
    // Select the files from the event
    var missed_fields: string[] = [];
    this.csvRecords = [];
    var invalid_csv: boolean = false;
    var files = $event.srcElement.files;
    //var file_copy = files;
    if (this.render_data.file_upload_only) {
      this.file = files[0];
      this.invalid_csv = false;
    }
    else {
      this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
        .pipe().subscribe((result: any[] | NgxCSVParserError) => {
          console.log('Result', result);
          this.required_columns.forEach(column => {
            if (Object.keys(result[0]).indexOf(column) < 0) //key missing
            {
              missed_fields.push(column);
              invalid_csv = true;
            }
            else {
              if ((result as any[]).find(x => x[column] == "")) {
                missed_fields.push(column);
                invalid_csv = true;
              }
            }
          });
          this.invalid_csv = invalid_csv;
          if (this.invalid_csv) {
            alert("Given CSV is not valid; Required Field(s) " + missed_fields + " missing; Please check the uploaded file");
          }
          else {
            this.csvRecords = result as any[];
            this.file = files[0];
            console.log(this.file);
            this.openPreviewModal();
            alert("Given CSV File is valid. Click the upload button");
          }
          missed_fields = [];
        }, (error: NgxCSVParserError) => {
          console.log('Error', error);
        });
      //$event.target.value = "";
      this.invalid_csv = true;
    }

  }
  onFileUpload() {
    if (this.render_data.return_type && this.render_data.return_type == 'FILE') {
      console.log(this.file);
      console.log(this.file_upload);
      this.csvData.emit(this.file);
    }
    else {
      this.csvData.emit(this.csvRecords);
    }
    this.csvRecords = []; this.invalid_csv = true;
    //alert("File Uploaded Successfully");
    this.onCloseClick();
  }
  openPreviewModal(): void {
    JQueryHelper.openModal('#' + this.render_data.modal_id + "preview", { keyboard: false, backdrop: 'static' });
  }

  async onPreviewCloseClick(): Promise<void> {
    JQueryHelper.closeModal('#' + this.render_data.modal_id + "preview");
  }

}
