import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComboBarComponent } from './components/combo-bar/combo-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NgxImageZoomModule } from './components/ngx-image-zoom/ngx-image-zoom.module';
import { NotificationComponent } from './components/notification/notification.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { AccountMasterComponent } from './controllers/admins/accounts/account-master/account-master.component';
import { FeeCollectionReportComponent } from './controllers/admins/accounts/fee-collection-report/fee-collection-report.component';
import { FeeCollectionSettingsComponent } from './controllers/admins/accounts/fee-collection-settings/fee-collection-settings.component';
import { AdmissionflowsComponent } from './controllers/admins/admissions/admissionflows/admissionflows.component';
import { AdmissionmasterComponent } from './controllers/admins/admissions/admissionmaster/admissionmaster.component';
import { AdmissionoverallreportComponent } from './controllers/admins/admissions/admissionoverallreport/admissionoverallreport.component';
import { EditApplicantComponent } from './controllers/admins/admissions/v2/applicants/edit-applicant/edit-applicant.component';
import { EntranceExamhallComponent } from './controllers/admins/admissions/v2/entrance/entrance-examhall/entrance-examhall.component';
import { EntranceMasterComponent } from './controllers/admins/admissions/v2/entrance/entrance-master/entrance-master.component';
import { EntranceQuestionsComponent } from './controllers/admins/admissions/v2/entrance/entrance-questions/entrance-questions.component';
import { AdmissiondashboardComponent } from './controllers/admins/admissions/v2/reports/admissiondashboard/admissiondashboard.component';
import { ApplicationListComponent } from './controllers/admins/admissions/v2/reports/application-list/application-list.component';
import { AppliedListComponent } from './controllers/admins/admissions/v2/reports/applied-list/applied-list.component';
import { OfflineDueComponent } from './controllers/admins/admissions/v2/reports/offline-due/offline-due.component';
import { AdmsRegisteredUsersComponent } from './controllers/admins/admissions/v2/status/adms-registered-users/adms-registered-users.component';
import { ManageAttandanceComponent } from './controllers/admins/attandance/manage-attandance/manage-attandance.component';
import { ManageCoursesComponent } from './controllers/admins/courses/manage-courses/manage-courses.component';
import { DepartmentCategoryComponent } from './controllers/admins/department/department-category/department-category.component';
import { DepartmentsComponent } from './controllers/admins/department/departments/departments.component';
import { OfferedProgrammesComponent } from './controllers/admins/department/offered-programmes/offered-programmes.component';
import { AssetManagementComponent } from './controllers/admins/hostel-management/asset-management/asset-management.component';
import { RoomAssetListComponent } from './controllers/admins/hostel-management/asset-management/room-asset-list/room-asset-list.component';
import { RoomAssetComponent } from './controllers/admins/hostel-management/asset-management/room-asset/room-asset.component';
import { StudentRoomMapComponent } from './controllers/admins/hostel-management/asset-management/student-room-map/student-room-map.component';
import { CreateHostelComponent } from './controllers/admins/hostel-management/create-hostel/create-hostel.component';
import { EditHostelComponent } from './controllers/admins/hostel-management/edit-hostel/edit-hostel.component';
import { CreateFloorsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/create-floors/create-floors.component';
import { HostelFloorsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/hostel-floors.component';
import { ManageFloorsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/manage-floors/manage-floors.component';
import { EditRoomComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/manage-floors/manage-rooms/edit-room/edit-room.component';
import { ManageRoomsComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-floors/manage-floors/manage-rooms/manage-rooms.component';
import { HostelInchargesComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-incharges/hostel-incharges.component';
import { ManageInchargesComponent } from './controllers/admins/hostel-management/edit-hostel/hostel-incharges/manage-incharges/manage-incharges.component';
import { HostelManagementComponent } from './controllers/admins/hostel-management/hostel-management.component';
import { ManageMenuItemsComponent } from './controllers/admins/hostel-management/manage-mess/manage-menu-items/manage-menu-items.component';
import { ManageMessComponent } from './controllers/admins/hostel-management/manage-mess/manage-mess.component';
import { AssignMarkHeaderComponent } from './controllers/admins/obe/assign-mark-header/assign-mark-header.component';
import { ManageComponentsComponent } from './controllers/admins/obe/manage-components/manage-components.component';
import { CoursePaymentsComponent } from './controllers/admins/payments/course-payments/course-payments.component';
import { FeesScheduleListComponent } from './controllers/admins/payments/fees-schedule-list/fees-schedule-list.component';
import { ManageProgrammesComponent } from './controllers/admins/programmes/manage-programmes/manage-programmes.component';
import { ManageStaffAssignComponent } from './controllers/admins/programmes/manage-staff-assign/manage-staff-assign.component';
import { ProgrammeTypesComponent } from './controllers/admins/programmes/programme-types/programme-types.component';
import { ManagePartOneComponent } from './controllers/admins/programmes/ugparts/manage-part-one/manage-part-one.component';
import { ManageStaffsComponent } from './controllers/admins/staffs/manage-staffs/manage-staffs.component';
import { ManageStudentsComponent } from './controllers/admins/students/manage-students/manage-students.component';
import { AccessDeniedComponent } from './controllers/public/access-denied/access-denied.component';
import { TokenComponent } from './controllers/public/token/token.component';
import { UnderMaintenanceComponent } from './controllers/public/under-maintenance/under-maintenance.component';
import { HosteladmissionComponent } from './controllers/staff/hostel/hosteladmission/hosteladmission.component';
import { StaffdashboardComponent } from './controllers/staff/staffdashboard/staffdashboard.component';
import { TransportationadmissionComponent } from './controllers/staff/transportation/transportationadmission/transportationadmission.component';
import { DashboardComponent } from './controllers/students/dashboard/dashboard.component';
import { HostelComponent } from './controllers/students/hostel/hostel.component';
import { TakesurveyComponent } from './controllers/students/mycourses/survey/takesurvey/takesurvey.component';
import { MypaymentsComponent } from './controllers/students/mypayments/mypayments.component';
import { TransportComponent } from './controllers/students/transport/transport.component';
import { SafePipe } from './services/pipe/safe.pipe';
import { TransferReportComponent } from './controllers/admins/admissions/v2/reports/transfer-report/transfer-report.component';
import { ExtraMenuComponent } from './controllers/admins/hostel-management/manage-mess/extra-menu/extra-menu.component';
import { StockPurchaseComponent } from './controllers/admins/hostel-management/manage-mess/stock-purchase/stock-purchase.component';
import { StockUsageComponent } from './controllers/admins/hostel-management/manage-mess/stock-usage/stock-usage.component';
import { GeneralMenuComponent } from './controllers/admins/hostel-management/manage-mess/general-menu/general-menu.component';
import { OtherExpensesComponent } from './controllers/admins/hostel-management/manage-mess/other-expenses/other-expenses.component';
import { StudentBillComponent } from './controllers/admins/hostel-management/manage-mess/student-bill/student-bill.component';
import { RoomAttendanceComponent } from './controllers/admins/hostel-management/room-attendance/room-attendance.component';
import { GenerateBillComponent } from './controllers/admins/hostel-management/manage-mess/generate-bill/generate-bill.component';
import { HostelAttendanceComponent } from './controllers/admins/hostel-management/hostel-attendance/hostel-attendance.component';
import { BillReportComponent } from './controllers/admins/hostel-management/manage-mess/bill-report/bill-report.component';
import { RolesComponent } from './controllers/admins/staffs/roles/roles.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';

import { StudentCoursesComponent } from './controllers/students/mycourses/courses/student-courses.component';
import { ManageElectiveRequestComponent } from './controllers/admins/students/manage-elective-request/manage-elective-request.component';
import { TimeSettingsComponent } from './controllers/admins/timetable/time-settings/time-settings.component';
import { ExamFeesSettingsComponent } from './controllers/admins/exam/exam-fees-settings/exam-fees-settings.component';
import { FeeSettingComponent } from './controllers/admins/exam/fee-setting/fee-setting.component';
import { FineSettingsComponent } from './controllers/admins/exam/fine-settings/fine-settings.component';
import { FeesReportComponent } from './controllers/admins/exam/fees-report/fees-report.component';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { FeesTypeMasterComponent } from './controllers/admins/exam/fees-type-master/fees-type-master.component';
import { TermMasterComponent } from './controllers/admins/exam/term-master/term-master.component';
import { TransactionReportComponent } from './controllers/admins/accounts/transaction-report/transaction-report.component';
import { ObeFeedbackComponent } from './controllers/students/obe-feedback/obe-feedback.component';
import { StudentsCountComponent } from './controllers/admins/students/students-count/students-count.component';
import { CoursesMasterComponent } from './controllers/admins/courses/courses-master/courses-master.component';
import { ProgrammeHeaderComponent } from './controllers/admins/programmes/programme-header/programme-header.component';
import { OfflineTransactionComponent } from './controllers/admins/accounts/offline-transaction/offline-transaction.component';
import { ComponentsMasterComponent } from './controllers/admins/obe/components-master/components-master.component';
import { PartsSettingComponent } from './controllers/admins/obe/parts-setting/parts-setting.component';
import { StaffAssignmentComponent } from './controllers/admins/staffs/staff-assignment/staff-assignment.component';
import { StudentAssignmentComponent } from './controllers/admins/students/student-assignment/student-assignment.component';
import { ElectivePollingSettingsComponent } from './controllers/admins/obe/elective-polling-settings/elective-polling-settings.component';
import { CourseSetupComponent } from './controllers/admins/courses/course-setup/course-setup.component';
import { StudentElectiveRequestsComponent } from './controllers/admins/obe/student-elective-requests/student-elective-requests.component';
import { ViewSyllabusComponent } from './controllers/students/view-syllabus/view-syllabus.component';
import { AssignElectiveStudentsComponent } from './controllers/admins/obe/assign-elective-students/assign-elective-students.component';
import { ElectiveQuantitySettingsComponent } from './controllers/admins/obe/elective-quantity-settings/elective-quantity-settings.component';
import { StudentElectiveCoursesComponent } from './controllers/students/student-elective-courses/student-elective-courses.component';
import { MissingAssignmentComponent } from './controllers/admins/obe/missing-assignment/missing-assignment.component';
import { ProgrammePamComponent } from './controllers/admins/obe/programme-pam/programme-pam.component';
import { ReviewMarkComponent } from './controllers/admins/obe/review-mark/review-mark.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HostelFeeHeaderComponent } from './controllers/admins/hostel-management/hostel-fee-header/hostel-fee-header.component';
import { HttpInterceptorService } from './services/helpers/HTTPInterceptor';
import { QuestionFormatSettingComponent } from './controllers/admins/terminal-exam/question-format-setting/question-format-setting.component';
import { ExamSetupComponent } from './controllers/admins/terminal-exam/exam-setup/exam-setup.component';
import { TerminalExamValuationComponent } from './controllers/staff/terminal-exam/terminal-exam-valuation/terminal-exam-valuation.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QuestionSettingComponent } from './controllers/admins/terminal-exam/question-setting/question-setting.component';
import { ValuationSchemeComponent } from './controllers/staff/terminal-exam/valuation-scheme/valuation-scheme.component';
import { StudentSubmissionComponent } from './controllers/students/terminal-exam/student-submission/student-submission.component';
import { StudentSubmissionReviewComponent } from './controllers/admins/terminal-exam/student-submission-review/student-submission-review.component';
import { FoilCardComponent } from './controllers/admins/terminal-exam/foil-card/foil-card.component';
import { StatementOfMarksComponent } from './controllers/admins/terminal-exam/statement-of-marks/statement-of-marks.component';
import { TerminalExamResultComponent } from './controllers/admins/terminal-exam/terminal-exam-result/terminal-exam-result.component';
import { CourseCumGradeRegisterComponent } from './controllers/admins/terminal-exam/course-cum-grade-register/course-cum-grade-register.component';
import { ObeMemberComponent } from './controllers/admins/obe/obe-member/obe-member.component';
import { TimetableSettingsComponent } from './controllers/admins/timetable/timetable-settings/timetable-settings.component';
import { ViewTimetableComponent } from './controllers/staff/attendance/view-timetable/view-timetable.component';
/*import { TerminalExamValuationsComponent } from './controllers/admins/terminal-exam/terminal-exam-valuations/terminal-exam-valuations.component'; */
import { FinalFoilCardComponent } from './controllers/admins/terminal-exam/final-foil-card/final-foil-card.component';
import { CoReportComponent } from './controllers/admins/terminal-exam/co-report/co-report.component';
import { ProgrammeFeeSettingComponent } from './controllers/admins/programmes/programme-fee-setting/programme-fee-setting.component';
import { HostelFeeSettingComponent } from './controllers/admins/hostel-management/hostel-fee-setting/hostel-fee-setting.component';
import { ViewElectiveReportComponent } from './controllers/admins/obe/view-elective-report/view-elective-report.component';
//import { CollectionReportComponent } from './controllers/admins/accounts/collection-report/collection-report.component';
import { SettlementReportComponent } from './controllers/admins/accounts/settlement-report/settlement-report.component';
//import { StaffLeaveApprovalComponent } from './controllers/admins/attendance/staff-leave-approval/staff-leave-approval.component';
import { StudentLeaveApprovalComponent } from './controllers/admins/attendance/student-leave-approval/student-leave-approval.component';
import { StudentOdApprovalComponent } from './controllers/admins/attendance/student-od-approval/student-od-approval.component';
import { StudentOdRequestComponent } from './controllers/staff/attendance/student-od-request/student-od-request.component';
//import { StaffLeaveApplyComponent } from './controllers/staff/attendance/staff-leave-apply/staff-leave-apply.component';
import { StudentLeaveApplyComponent } from './controllers/students/student-leave-apply/student-leave-apply.component';
import { HolidayCalendarComponent } from './controllers/admins/timetable/holiday-calendar/holiday-calendar.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
//import { StudentCalendarComponent } from './controllers/students/student-calendar/student-calendar.component';
//import { AttendanceCertificateComponent } from './controllers/admins/students/certificates/attendance-certificate/attendance-certificate.component';
//import { CourseCertificateComponent } from './controllers/admins/students/certificates/course-certificate/course-certificate.component';
//import { TransferCertificateComponent } from './controllers/admins/students/certificates/transfer-certificate/transfer-certificate.component';
//import { BonafideCertificateComponent } from './controllers/admins/students/certificates/bonafide-certificate/bonafide-certificate.component';
import { ProgrammeTimeSettingsComponent } from './controllers/admins/timetable/programme-time-settings/programme-time-settings.component';


//import { CreateTerminalEvaluatorComponent } from './controllers/admins/terminal-exam/create-terminal-evaluator/create-terminal-evaluator.component';
//import { ManageNtsComponent } from './controllers/admins/staffs/manage-nts/manage-nts.component';

import { ManageParentRequestsComponent } from './controllers/admins/parents/manage-parent-requests/manage-parent-requests.component';
import { ParentRequestFormComponent } from './controllers/parents/parent-request-form/parent-request-form.component';
import { TransportRoutesComponent } from './controllers/staff/transportation/transport-routes/transport-routes.component';
import { TransportFeesSettingsComponent } from './controllers/staff/transportation/transport-fees-settings/transport-fees-settings.component';
import { DayOrderChangeComponent } from './controllers/admins/timetable/day-order-change/day-order-change.component';
import { StaffLateEntryComponent } from './controllers/admins/attendance/staff-late-entry/staff-late-entry.component';
import { StudentTimetableComponent } from './controllers/students/student-timetable/student-timetable.component';

//need to remove before deployment
import { StaffAttendanceReportComponent } from './controllers/admins/attendance/staff-attendance-report/staff-attendance-report.component';
import { AttendanceTableComponent } from './controllers/admins/attandance/attendance-table/attendance-table.component';
import { StaffNotMarkedAttendanceComponent } from './controllers/admins/attendance/staff-not-marked-attendance/staff-not-marked-attendance.component';
import { CourseExitSurveyComponent } from './controllers/staff/obe/course-exit-survey/course-exit-survey.component';
import { VirtualSectionComponent } from './controllers/admins/obe/virtual-section/virtual-section.component';
import { RouteManagementComponent } from './controllers/admins/transport/route-management/route-management.component';
import { StudentTransportHistoryComponent } from './controllers/admins/transport/student-transport-history/student-transport-history.component';
import { StudentNotificationComponent } from './controllers/admins/students/student-notification/student-notification.component';
import { TimetableConfigurationComponent } from './controllers/admins/timetable/timetable-configuration/timetable-configuration.component';
import { GuardianAssignmentComponent } from './controllers/admins/staffs/guardian-assignment/guardian-assignment.component';
import { AdmissionFeeReportComponent } from './controllers/admins/accounts/admission-fee-report/admission-fee-report.component';
import { ProgrammeExitSurveyComponent } from './controllers/admins/obe/programme-exit-survey/programme-exit-survey.component';
import { ExtraCurricularExitSurveyComponent } from './controllers/admins/obe/extra-curricular-exit-survey/extra-curricular-exit-survey.component';
import { StudentProgrammeExitSurveyComponent } from './controllers/students/student-programme-exit-survey/student-programme-exit-survey.component';
import { StudentExtraExitSurveyComponent } from './controllers/students/student-extra-exit-survey/student-extra-exit-survey.component';
import { NonSkillsetMasterComponent } from './controllers/admins/obe/non-skillset-master/non-skillset-master.component';
import { NonSkillsetUploadComponent } from './controllers/students/non-skillset-upload/non-skillset-upload.component';
import { ReviewNaEvidenceComponent } from './controllers/admins/obe/review-na-evidence/review-na-evidence.component';
import { StudentCertificateMasterComponent } from './controllers/admins/students/student-certificate-master/student-certificate-master.component';
import { StudentCertificateTrackerComponent } from './controllers/admins/students/student-certificate-tracker/student-certificate-tracker.component';
import { StaffCreationComponent } from './controllers/admins/staffs/staff-creation/staff-creation.component';
import { TerminalExternalValuationComponent } from './controllers/staff/terminal-exam/terminal-external-valuation/terminal-external-valuation.component';
import { InternalExamSetupComponent } from './controllers/staff/internal-exam/internal-exam-setup/internal-exam-setup.component';
import { InternalExamValuationComponent } from './controllers/staff/internal-exam/internal-exam-valuation/internal-exam-valuation.component';
import { InternalExamUploadComponent } from './controllers/students/internal-exam-upload/internal-exam-upload.component';
import { ViewStaffTimetableComponent } from './controllers/admins/attendance/view-staff-timetable/view-staff-timetable.component';
import { ManageRepeatSemesterComponent } from './controllers/admins/students/manage-repeat-semester/manage-repeat-semester.component';
import { ConstructiveAlignmentComponent } from './controllers/staff/constructive-alignment/constructive-alignment.component';
import { InternalQuestionSettingComponent } from './controllers/staff/internal-exam/internal-question-setting/internal-question-setting.component';
import { InternalValuationSchemeComponent } from './controllers/staff/internal-exam/internal-valuation-scheme/internal-valuation-scheme.component';
import { CreateParentComponent } from './controllers/students/create-parent/create-parent.component';
import { ChooseChildComponent } from './controllers/parents/choose-child/choose-child.component';
import { AddQuizQuestionsComponent } from './controllers/staff/internal-exam/add-quiz-questions/add-quiz-questions.component';
import { ViewStudentExamFeeComponent } from './controllers/admins/exam/view-student-exam-fee/view-student-exam-fee.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SmsCampaignComponent } from './controllers/admins/admissions/sms-campaign/sms-campaign.component';
import { AdmissionNewsComponent } from './controllers/admins/admissions/admission-news/admission-news.component';
import { HostelCreationComponent } from './controllers/admins/hostel-creation/hostel-creation.component';
import { HostelInchargeComponent } from './controllers/admins/hostel-incharge/hostel-incharge.component';
import { HostelRequestCreationComponent } from './controllers/admins/hostel-request-creation/hostel-request-creation.component';
import { HostelRequestAcceptanceComponent } from './controllers/admins/hostel-request-acceptance/hostel-request-acceptance.component';
import { ManageHostelStudentsComponent } from './controllers/admins/manage-hostel-students/manage-hostel-students.component';
import { AttedanceShiftComponent } from './controllers/admins/attedance-shift/attedance-shift.component';
import { HostelAttedanceComponent } from './controllers/admins/hostel-attedance/hostel-attedance.component';
import { HostelWiseAttendanceComponent } from './controllers/admins/hostel-wise-attendance/hostel-wise-attendance.component';
import { StudentHostelAdmissionComponent } from './controllers/students/student-hostel-admission/student-hostel-admission.component';
import { StudentHostelAttendanceComponent } from './controllers/students/student-hostel-attendance/student-hostel-attendance.component';
import { ManageTransportRouteComponent } from './controllers/admins/manage-transport-route/manage-transport-route.component';
import { ManageTransportFeesComponent } from './controllers/admins/manage-transport-fees/manage-transport-fees.component';
import { ManageTransportRequestComponent } from './controllers/admins/manage-transport-request/manage-transport-request.component';
import { StudentTransportRequestComponent } from './controllers/students/student-transport-request/student-transport-request.component';
import { TemplateMasterComponent } from './controllers/admins/template-master/template-master.component';
import { NotificationSettingComponent } from './controllers/admins/notification-setting/notification-setting.component';
import { SentNotificationComponent } from './controllers/admins/sent-notification/sent-notification.component';
import { StaffNotificationComponent } from './controllers/admins/staff-notification/staff-notification.component';
import { StuNotificationComponent } from './controllers/admins/stu-notification/stu-notification.component';
import { StudentAttendanceComponent } from './controllers/students/student-attendance/student-attendance.component';
import { StudentHallticketComponent } from './controllers/students/student-hallticket/student-hallticket.component';
import { AdminDashboardComponent } from './controllers/admins/admin-dashboard/admin-dashboard.component';
import { TemplateConfigurationUpdateComponent } from './controllers/admins/reports/template-configuration-update/template-configuration-update.component';
import { TemplateConfigurationComponent } from './controllers/admins/reports/template-configuration/template-configuration.component';
import { ReportTemplateCreateComponent } from './controllers/admins/reports/report-template-create/report-template-create.component';
import { ProgramTemplateComponent } from './controllers/admins/reports/program-template/program-template.component';
import { AbsentReportComponent } from './controllers/admins/attandance/absent-report/absent-report.component';
import { StudentsSearchComponent } from './controllers/admins/students/students-search/students-search.component';
import { RepeatAttendanceComponent } from './controllers/admins/attandance/repeat-attendance/repeat-attendance.component';
import { CasReportComponent } from './controllers/admins/obe/cas-report/cas-report.component';
import { ProgrammeOutcomeComponent } from './controllers/admins/obe/programme-outcome/programme-outcome.component';
import { NonAcademicSemesterReportComponent } from './controllers/admins/obe/non-academic-semester-report/non-academic-semester-report.component';
import { NonAcademicProgrammeReportComponent } from './controllers/admins/obe/non-academic-programme-report/non-academic-programme-report.component';
import { CagpReportComponent } from './controllers/admins/obe/cagp-report/cagp-report.component';
import { HolisticReportComponent } from './controllers/admins/obe/holistic-report/holistic-report.component';
import { CoAttainmentReportComponent } from './controllers/admins/obe/co-attainment-report/co-attainment-report.component';
import { PercentageAttainmentCoReportComponent } from './controllers/admins/obe/percentage-attainment-co-report/percentage-attainment-co-report.component';
import { ReportTemplateFilterComponent } from './controllers/admins/reports/report-template-filter/report-template-filter.component';
import { TerminalExamDeviationComponent } from './controllers/staff/terminal-exam/terminal-exam-deviation/terminal-exam-deviation.component';

@NgModule({
  declarations: [
    AppComponent,
    TokenComponent,
    DashboardComponent,
    SideBarComponent,
    FooterComponent,
    ComboBarComponent,
    HostelComponent,
    HosteladmissionComponent,
    StaffdashboardComponent,
    TransportComponent,
    TransportationadmissionComponent,
    AccessDeniedComponent,
    UnderMaintenanceComponent,
    MypaymentsComponent,
    DepartmentCategoryComponent,
    DepartmentsComponent,
    OfferedProgrammesComponent,
    ManageProgrammesComponent,
    ProgrammeTypesComponent,
    ManageStudentsComponent,
    CoursePaymentsComponent,
    FeesScheduleListComponent,
    AdmissionmasterComponent,
    AdmissionflowsComponent,
    AdmissionoverallreportComponent,
    ManageCoursesComponent,
    ManageStaffsComponent,
    ManageStaffAssignComponent,
    ManageComponentsComponent,
    AssignMarkHeaderComponent,
    TakesurveyComponent,
    HostelManagementComponent,
    CreateHostelComponent,
    EditHostelComponent,
    HostelInchargesComponent,
    HostelFloorsComponent,
    ManageInchargesComponent,
    NotificationComponent,
    ManagePartOneComponent,
    AccountMasterComponent,
    ManageFloorsComponent,
    CreateFloorsComponent,
    ManageRoomsComponent,
    NavigationComponent,
    EditRoomComponent,
    AdmissiondashboardComponent,
    AdmsRegisteredUsersComponent,
    EditApplicantComponent,
    ApplicationListComponent,
    EntranceMasterComponent,
    EntranceQuestionsComponent,
    SafePipe,
    AppliedListComponent,
    EntranceExamhallComponent,
    AssetManagementComponent,
    RoomAssetComponent,
    RoomAssetListComponent,
    StudentRoomMapComponent,
    ManageAttandanceComponent,
    FeeCollectionReportComponent,
    FeeCollectionSettingsComponent,
    OfflineDueComponent,
    ManageMessComponent,
    ManageMenuItemsComponent,
    TransferReportComponent,
    ExtraMenuComponent,
    StockPurchaseComponent,
    StockUsageComponent,
    GeneralMenuComponent,
    OtherExpensesComponent,
    StudentBillComponent,
    RoomAttendanceComponent,
    GenerateBillComponent,
    HostelAttendanceComponent,
    BillReportComponent,
    RolesComponent,
    ModalComponent,
    StudentCoursesComponent,
    ManageElectiveRequestComponent,
    TimeSettingsComponent,
    ExamFeesSettingsComponent,
    FeeSettingComponent,
    FineSettingsComponent,
    FeesReportComponent,
    FeesTypeMasterComponent,
    TermMasterComponent,
    TransactionReportComponent,
    ObeFeedbackComponent,
    StudentsCountComponent,
    CoursesMasterComponent,
    ProgrammeHeaderComponent,
    OfflineTransactionComponent,
    ComponentsMasterComponent,
    PartsSettingComponent,
    StaffAssignmentComponent,
    StudentAssignmentComponent,
    ElectivePollingSettingsComponent,
    CourseSetupComponent,
    StudentElectiveRequestsComponent,
    ViewSyllabusComponent,
    AssignElectiveStudentsComponent,
    ElectiveQuantitySettingsComponent,
    StudentElectiveCoursesComponent,
    MissingAssignmentComponent,
    ProgrammePamComponent,
    ReviewMarkComponent,
    HostelFeeHeaderComponent,
    QuestionFormatSettingComponent,
    ExamSetupComponent,
    TerminalExamValuationComponent,
    QuestionSettingComponent,
    ValuationSchemeComponent,
    StudentSubmissionComponent,
    StudentSubmissionReviewComponent,
    FoilCardComponent,
    StatementOfMarksComponent,
    TerminalExamResultComponent,
    CourseCumGradeRegisterComponent,
    ObeMemberComponent,
    TimetableSettingsComponent,
    ViewTimetableComponent,
    //TerminalExamValuationsComponent,
    FinalFoilCardComponent,
    CoReportComponent,
    ProgrammeFeeSettingComponent,
    HostelFeeSettingComponent,
    ViewElectiveReportComponent,
    //CollectionReportComponent,
    SettlementReportComponent,
    /* StaffLeaveApprovalComponent,



    StaffLeaveApplyComponent,*/
    HolidayCalendarComponent,
    /* StudentCalendarComponent,
    AttendanceCertificateComponent,
    CourseCertificateComponent,
    TransferCertificateComponent,
    BonafideCertificateComponent, */
    ProgrammeTimeSettingsComponent,
    ManageParentRequestsComponent,
    ParentRequestFormComponent,
    /* CreateTerminalEvaluatorComponent,
    ManageNtsComponent, */
    TransportRoutesComponent,
    TransportFeesSettingsComponent,
    DayOrderChangeComponent,
    StaffLateEntryComponent,
    StudentTimetableComponent,

    //need to remove before deployment
    StaffAttendanceReportComponent,
    AttendanceTableComponent,
    StaffNotMarkedAttendanceComponent,
    CourseExitSurveyComponent,
    VirtualSectionComponent,
    RouteManagementComponent,
    StudentTransportHistoryComponent,
    StudentNotificationComponent,
    TimetableConfigurationComponent,
    GuardianAssignmentComponent,
    AdmissionFeeReportComponent,
    ProgrammeExitSurveyComponent,
    ExtraCurricularExitSurveyComponent,
    StudentProgrammeExitSurveyComponent,
    StudentExtraExitSurveyComponent,
    NonSkillsetMasterComponent,
    NonSkillsetUploadComponent,
    ReviewNaEvidenceComponent,
    StudentCertificateMasterComponent,
    StudentCertificateTrackerComponent,
    StaffCreationComponent,
    TerminalExternalValuationComponent,
    InternalExamSetupComponent,
    InternalExamValuationComponent,
    InternalExamUploadComponent,
    ViewStaffTimetableComponent,
    ManageRepeatSemesterComponent,
    ConstructiveAlignmentComponent,
    InternalQuestionSettingComponent,
    InternalValuationSchemeComponent,
    CreateParentComponent,
    ChooseChildComponent,
    AddQuizQuestionsComponent,
    StudentOdRequestComponent,
    StudentOdApprovalComponent,
    StudentLeaveApprovalComponent,
    StudentLeaveApplyComponent,
    ViewStudentExamFeeComponent,
    LogoutComponent,
    SmsCampaignComponent,
    AdmissionNewsComponent,
    HostelCreationComponent,
    HostelInchargeComponent,
    HostelRequestCreationComponent,
    HostelRequestAcceptanceComponent,
    ManageHostelStudentsComponent,
    AttedanceShiftComponent,
    HostelAttedanceComponent,
    HostelWiseAttendanceComponent,
    StudentHostelAdmissionComponent,
    StudentHostelAttendanceComponent,
    ManageTransportRouteComponent,
    ManageTransportFeesComponent,
    ManageTransportRequestComponent,
    StudentTransportRequestComponent,
    TemplateMasterComponent,
    NotificationSettingComponent,
    SentNotificationComponent,
    StaffNotificationComponent,
    StuNotificationComponent,
    StudentAttendanceComponent,
    StudentHallticketComponent,
    AdminDashboardComponent,
    TemplateConfigurationUpdateComponent,
    TemplateConfigurationComponent,
    ReportTemplateCreateComponent,
    ProgramTemplateComponent,
    AbsentReportComponent,
    StudentsSearchComponent,
    RepeatAttendanceComponent,
    CasReportComponent,
    ProgrammeOutcomeComponent,
    NonAcademicSemesterReportComponent,
    NonAcademicProgrammeReportComponent,
    CagpReportComponent,
    HolisticReportComponent,
    CoAttainmentReportComponent,
    PercentageAttainmentCoReportComponent,
    ReportTemplateFilterComponent,
    TerminalExamDeviationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientJsonpModule,
    DropDownsModule,
    LabelModule,
    AngularEditorModule,
    NgxImageZoomModule,
    GridModule,
    EditorModule,
    NgxCsvParserModule,
    DateInputsModule,
    NgMultiSelectDropDownModule.forRoot(),
    PdfViewerModule,
    SchedulerModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
