import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper'
import { Modal } from 'src/app/components/ModalInterface';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ChangeDetectorRef } from '@angular/core'

@Component({
  selector: 'app-template-configuration-update',
  templateUrl: './template-configuration-update.component.html',
  styleUrls: ['./template-configuration-update.component.scss']
})
export class TemplateConfigurationUpdateComponent implements OnInit {

  @ViewChild('content') content: ElementRef;
  selectedRowIndex: number = 0;
  fonts = fonts;
  resData: any = [];
  selectedFile: File;
  columns: TableColumn[];
  commonEnums = CommonEnums;
  tableHeaders: string[] = [];
  columnFormatArray: string[] = [];
  selectedFormat: any;
  programme_fee_patch_form: any;
  isInputDisabled: boolean = true;
  settings_loading: boolean = false;
  section_delete_loading: boolean[] = [];
  template_delete_loading: boolean[] = [];
  fromDataResolver = FromDataResolver;
  program_template: FormGroup = {} as FormGroup;
  program_data: FormGroup = {} as FormGroup;
  template: templateInterface = {} as templateInterface;
  // program: programInterface = {} as programInterface;
  programme_type_list: any[] = [];
  programme_list: any[] = [];
  programme_type_list_loading = false;
  programme_list_loading = false;
  programme_section_list: any[] = [];

  program_list: ProgramInterface[] = [
    // {
    //   title: 'This is the default Program 1.',
    //   description: 'This is Description',
    //   fileLink: 'This is the default Program 1.',
    //   format: 'List',
    //   listData: {
    //     header: 'Header' // 'header' property is defined here
    //   },
    //   content: '',
    //   table: [
    //     {
    //       columnName: '',
    //       columnFormat: '',
    //       string:'',
    //       number:'',
    //       file:'',
    //     }
    //   ]
    // },
    // {
    //   title: 'This is the default Program 2.',
    //   description: 'This is Description',
    //   fileLink: 'This is the default Program 1.',
    //   format: 'Table',
    //   listData: {
    //     header: '' // 'header' property is defined here
    //   },
    //   content: '',
    //   table: [
    //     {
    //       columnName: 'S.No', // Example of columnName as a string
    //       columnFormat: 'String',
    //       string:'',
    //       number:'',
    //       file:'',
    //     },
    //     {
    //       columnName: 'Table', // Example of columnName as a string
    //       columnFormat: 'Number',
    //       string:'',
    //       number:'',
    //       file:'',
    //     }

    //   ]
    // }
  ];

  format: { key: any, value: any }[] = [
    { key: "Table-0", value: "Table" },
    { key: "List-1", value: "List" },
  ];
  constructor(private renderer: Renderer2, private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {

    const formDataString = localStorage.getItem('formData');

    if (formDataString) {
      const formData = JSON.parse(formDataString);
      // this.program_data.patchValue(formData);
      console.log(formData)
    } else {
      console.log('No stored form data found');
    }
    this.program_data = this.formBuilder.group({
      title: '',
      description: '',
      format: '',
      list_data: { header: '', contents: '' },

      table_data: [{
        column_name: '',
        column_format: '',
        column_data: '',
      }],
      mapping_data: this.formBuilder.array([this.newTemplate()]),
    });

    // this.buildForm();

  }

  someAsyncDataLoadingMethod() {
    // ... logic to populate tableHeaders ...
    this.cdr.detectChanges();
  }

  selectRow(index: number) {
    this.selectedRowIndex = index;
  }

  isInvalid(formControl: string): boolean {
    return this.program_template.touched && this.program_template.get(formControl)?.errors !== null;
  }

  addTableToTemplate(templateIndex: number) {
    const tablesArray = this.tables(templateIndex);
    tablesArray.push(this.newTable());
  }

  addTablesForAllTemplates() {
    const formDataString = localStorage.getItem('formData');
    const formData = JSON.parse(formDataString);

    if (formData && formData.template) {
      formData.template.forEach((templateData, templateIndex) => {
        const currentNumberOfTables = this.tables(templateIndex).length;
        const desiredNumberOfTables = templateData.table.length;

        if (desiredNumberOfTables > currentNumberOfTables) {
          const tablesToAdd = desiredNumberOfTables - currentNumberOfTables;
          for (let i = 0; i < tablesToAdd; i++) {
            this.addTableToTemplate(templateIndex);
          }
        } else if (desiredNumberOfTables < currentNumberOfTables) {
          const tablesToRemove = currentNumberOfTables - desiredNumberOfTables;
          for (let i = 0; i < tablesToRemove; i++) {
            this.tables(templateIndex).removeAt(this.tables(templateIndex).length - 1);
          }
        }

        // Patch values for each table
        const templateTables = templateData.table;
        for (let tableIndex = 0; tableIndex < templateTables.length; tableIndex++) {
          const tableFormGroup = this.tables(templateIndex).at(tableIndex);
          tableFormGroup.patchValue(templateTables[tableIndex]);
        }
      });
    }
  }

  getColumnDataControl(i: number, j: number): FormControl {
    return (this.tables(i).at(j).get('column_data') as FormControl);
  }





  newTemplate(): FormGroup {
    return this.formBuilder.group({
      _id: '',
      title: '',
      description: '',
      format: 'Group',
      file_link: '',
      list_data: this.formBuilder.group({
        header: [''],
        contents: ['']  // Initialize contents control here
      }),
      table_data: this.formBuilder.array([])
    });
  }


  getMappingDataControls(index: number): any {
    const mappingDataControl = this.program_data.get('mapping_data');

    if (mappingDataControl instanceof FormArray && mappingDataControl.length > index) {
      const selectedControl = (mappingDataControl.at(index) as FormGroup).get('list_data').get('contents');
      return selectedControl;
    }

    return null;
  }


  async uploadFile(e: any) {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      const response = await this.restService.uploadReportFile(formData);
      if (response.success && response.data) {
        alert("File Uploaded Successfully");

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {

    }
  }


  // addData(){
  //   this.program_data = this.formBuilder.group({
  //     template_id: [''],
  //     programe_template_id:[''],
  //     mapping_data: this.formBuilder.array([]),
  //   });

  //   this.route.params.subscribe(async params => {
  //     const programe_template_id = params['id'];
  //     const template_id = params['prgTempId'];
  //     console.log('Route Parameter - ID:', programe_template_id);
  //     console.log('Route Parameter - TEMPLATEID:', template_id);

  //     try {
  //       const response = await this.restService.getProgramMapping(template_id);
  //       if (response.data && response.data[0].template_data) {
  //         console.log(response.data[0].template_data);
  //         this.program_list = response.data[0].template_data;
  //         console.log("PROGRAM LIST", this.program_list);

  //         this.program_data.patchValue({
  //           template_id: template_id,
  //           programe_template_id: programe_template_id
  //         });



  //         console.log("FORM",this.program_data.value);

  //         this.program_list.forEach((templateData: ProgramInterface) => {
  //           const tableData: TableColumn[][] = Array.isArray(templateData.table_data) ? templateData.table_data : [[]];
  //           const tableFormArray = this.formBuilder.array([]);
  //           const tableHeaders: string[] = []; // Initialize an empty array for table headers
  //           const columnFormatArray: string[] = [];
  //           tableData.forEach((tableRow: TableColumn[] | any) => {
  //             // Check if tableRow is an array, if not, wrap it inside an array
  //             const rowArray = Array.isArray(tableRow) ? tableRow : [tableRow];

  //             const tableRowFormArray = this.formBuilder.array([]);

  //             rowArray.forEach((tableItem: TableColumn) => {
  //               const tableItemFormGroup = this.formBuilder.group({
  //                 column_name: [tableItem.column_name || ''],
  //                 column_format: [tableItem.column_format || ''],
  //                 column_data: [tableItem.column_data || '']
  //               });

  //               tableRowFormArray.push(tableItemFormGroup);

  //               // Add column_name to the tableHeaders array
  //               tableHeaders.push(tableItem.column_name || ''); // This adds an empty string if column_name is undefined
  //               columnFormatArray.push(tableItem.column_format || '');
  //             });

  //             tableFormArray.push(tableRowFormArray);

  //           });

  //           const templateGroup = this.formBuilder.group({

  //             title: [templateData.title || ''],
  //             description: [templateData.description || ''],
  //             file_link: [templateData.file_link || ''],
  //             format: [templateData.format || ''],
  //             list_data: this.formBuilder.group({
  //               header: [templateData.list_data?.header || ''],
  //               contents: [templateData.list_data?.contents || ''],
  //             }),
  //             table_data: tableFormArray
  //           });

  //           console.log('table_data:', tableFormArray.value);

  //           (this.program_data.get('mapping_data') as FormArray).push(templateGroup);

  //           // Now, you have the tableHeaders array containing the column names
  //           console.log('Table Headers:', tableHeaders);
  //           this.tableHeaders = tableHeaders;
  //           console.log(this.tableHeaders);
  //           this.columnFormatArray = columnFormatArray;
  //           console.log(this.columnFormatArray);
  //         });





  //         // Do something with template_setting_details here
  //       } else {
  //         console.log("Template Setting Details not found in the response.");
  //       }
  //     } catch (error) {
  //       console.error('Error occurred:', error);
  //       // Handle errors here
  //     }
  //   });
  // }


  ngOnInit(): void {

    this.program_data = this.formBuilder.group({
      _id: [''],
      template_id: [''],
      programe_template_id: [''],
      mapping_data: this.formBuilder.array([]),
    });

    this.route.params.subscribe(async params => {
      const programe_template_id = params['id'];
      const template_id = params['prgTempId'];
      console.log('Route Parameter - ID:', programe_template_id);
      console.log('Route Parameter - TEMPLATEID:', template_id);
      try {
        const response = await this.restService.getProgramTemplateMapping(programe_template_id, template_id);
        if (response.data && response.data[0]) {
          console.log(response.data[0]);
          this.program_list = response.data[0].mapping_data;
          console.log("PROGRAM LIST", this.program_list);
          this.program_data.patchValue({
            _id: response.data[0]._id,
            template_id: response.data[0].template_id,
            programe_template_id: response.data[0].programe_template_id
          });
          console.log("FORM", this.program_data.value);
          this.program_list.forEach((templateData: ProgramInterface) => {
            const tableData: TableColumn[][] = Array.isArray(templateData.table_data) ? templateData.table_data : [[]];
            const tableFormArray = this.formBuilder.array([]);
            const tableHeaders: string[] = []; // Initialize an empty array for table headers
            const columnFormatArray: string[] = [];
            tableData.forEach((tableRow: TableColumn[] | any) => {
              // Check if tableRow is an array, if not, wrap it inside an array
              const rowArray = Array.isArray(tableRow) ? tableRow : [tableRow];

              const tableRowFormArray = this.formBuilder.array([]);

              rowArray.forEach((tableItem: TableColumn) => {
                const tableItemFormGroup = this.formBuilder.group({
                  column_name: [tableItem.column_name || ''],
                  column_format: [tableItem.column_format || ''],
                  column_data: [tableItem.column_data || '']
                });

                tableRowFormArray.push(tableItemFormGroup);

                // Add column_name to the tableHeaders array
                tableHeaders.push(tableItem.column_name || ''); // This adds an empty string if column_name is undefined
                columnFormatArray.push(tableItem.column_format || '');
              });

              tableFormArray.push(tableRowFormArray);

            });

            const templateGroup = this.formBuilder.group({

              title: [templateData.title || ''],
              description: [templateData.description || ''],
              file_link: [templateData.file_link || ''],
              format: [templateData.format || ''],
              list_data: this.formBuilder.group({
                header: [templateData.list_data?.header || ''],
                contents: [templateData.list_data?.contents || ''],
              }),
              table_data: tableFormArray
            });

            console.log('table_data:', tableFormArray.value);

            (this.program_data.get('mapping_data') as FormArray).push(templateGroup);

            // Now, you have the tableHeaders array containing the column names
            console.log('Table Headers:', tableHeaders);
            this.tableHeaders = tableHeaders;
            console.log(this.tableHeaders);
            this.columnFormatArray = columnFormatArray;
            console.log(this.columnFormatArray);
          });
        } else {
          console.log("Template Setting Details not found in the response.");
        }
      } catch (error) {
        console.error('Error occurred:', error);
        // Handle errors gracefully (display error message, redirect, etc.)
      }



    });


  }


  async updateMapping() {
    const formData = this.program_data.value;
    console.log(formData)

    if (formData.mapping_data && Array.isArray(formData.mapping_data)) {
      for (let i = 0; i < formData.mapping_data.length; i++) {
        const template = formData.mapping_data[i];

        if (template.format && template.format == 'List') {
          delete template.table_data;
        }

        if (template.format && template.format == 'Table') {
          delete template.list_data;
        }
        if (template.format && template.format == 'Group') {
          delete template.list_data;
          delete template.table_data;
        }

      }
    }
    // const addTemplate = {
    //   template_id: formData.template_id,
    //   mapping_data: this.formBuilder.array([this.newTemplate()])

    // };

    // Create a new template
    try {
      const updateResponse = await this.restService.updateProgramTemplateMapping(formData._id, formData);

      if (updateResponse && updateResponse.success) {
        alert('Updated successfully.');
        this.onBackClicks();

      } else {
        console.error('Failed to update template setting.');
      }
    } catch (error) {
      console.error('Error updating template:', error);
    }



  }



  //  async addMapping() {
  //   const formData = this.program_data.value;
  //   console.log(formData)

  //   if (formData.mapping_data && Array.isArray(formData.mapping_data)) {
  //     for (let i = 0; i < formData.mapping_data.length; i++) {
  //       const template = formData.mapping_data[i];

  //       if (template.format && template.format == 'List') {
  //         delete template.table_data;
  //       }

  //       if (template.format && template.format == 'Table') {
  //         delete template.list_data;
  //       }
  //       if (template.format && template.format == 'Group') {
  //         delete template.list_data;
  //         delete template.table_data;
  //       }

  //     }
  //   }
  //   // const addTemplate = {
  //   //   template_id: formData.template_id,
  //   //   mapping_data: this.formBuilder.array([this.newTemplate()])

  //   // };

  //    // Create a new template
  //    this.restService.createProgramTemplateMapping(formData).subscribe(
  //     (result) => {
  //       this.resData = result;
  //       console.log(this.resData.data);
  //       alert('Saved Successfully');
  //       this.onBackClicks();

  //     },
  //     (error) => {
  //       console.error('Error:', error);
  //     }
  //   );


  //   }



  logTablesAndTemplates() {
    const formDataString = localStorage.getItem('formData');
    const formData = JSON.parse(formDataString);

    if (formData && formData.template) {
      for (let j = 0; j < formData.template.length; j++) {
        const templateTitle = formData.template[j].title;
        console.log(`Template ${j} - Title: ${templateTitle}`);

        const tablesArray = formData.template[j].table;

        for (let k = 0; k < tablesArray.length; k++) {
          const tableTitle = tablesArray[k].title;
          const tableValue = tablesArray[k].value;
          console.log(`  Table ${k} (Template ${j}) - Title: ${tableTitle}, Value: ${tableValue}`);
        }
      }
    } else {
      console.log('No stored form data found');
    }
  }


  addColumn() {
    const newColumnName = prompt("Enter new column name:");
    if (newColumnName) {
      this.columnFormatArray.push(newColumnName);
      this.tableHeaders.push(newColumnName);
    }
  }

  // Method to delete a column
  deleteColumn(index: number) {
    if (confirm("Are you sure you want to delete this column?")) {
      this.columnFormatArray.splice(index, 1);
      this.tableHeaders.splice(index, 1);
    }
  }


  addRow(templateIndex: number) {
    const table = this.tables(templateIndex);
    table.push(this.newTable());
  }



  deleteRow(templateIndex: number, rowIndex: number) {
    const table = this.tables(templateIndex);
    table.removeAt(rowIndex);
  }







  templates(): FormArray {
    return this.program_data.get('mapping_data') as FormArray;
  }



  addTemplate() {
    this.templates().push(this.newTemplate());

  }

  tables(i: number): FormArray {
    return this.templates()
      .at(i)
      .get('table_data') as FormArray;
  }





  newTable(): FormGroup {
    return this.formBuilder.group({
      column_name: '',
      column_format: '',
      column_data: ''


    });
  }

  private onUpload(imageFor) {
    const fd = new FormData();
    fd.append('imageFile', this.selectedFile, this.selectedFile.name);
    // this.http.post('https://localhost:3443/products/upload', fd)
    //   .subscribe(res => imageFor === 'FRONT' ? this.imgFront = res : this.imgBack = res);
  }

  onFileSelected(event, imageFor) {
    this.selectedFile = <File>event.target.files[0];
    this.onUpload(imageFor);
  }

  onBackClicks() {
    this.router.navigate(['/admin/reports/program-template']);
  }




  printForm() {

    window.print();


  }

  downloadPDF() {
    var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
    mywindow.document.write('<html><head>');
    mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" + "<style>@page { size: landscape; }</style>" +
      "<style>.table-cell {height:25px; padding:0 !important; margin: 0; text-align: center;} .table-head{padding-top:0 !important; padding-bottom:0 !important;}</style>");
    mywindow.document.write('</head><body><div style="margin-top:30px; font-family:\'serif\'">');

    var programData = this.program_data.value;
    var pageNumber = 0;
    programData.template.forEach(function (template, i) {
      mywindow.document.write('<h1 style="font-size:12px; font-weight:bold;">Section ' + (i + 1) + '</h1>');
      mywindow.document.write('<p style="font-size:12px;"> ' + template.title + '</p>');
      mywindow.document.write('<p style="font-size:12px;">' + template.description + '</p>');

      if (template.format === 'List') {
        // mywindow.document.write('<h2>List Format</h2>');
        mywindow.document.write('<p style="font-size:12px;"> ' + template.header + '</p>');
        mywindow.document.write('<p style="font-size:12px;"> ' + template.content + '</p>');
      } else if (template.format === 'Table') {
        // mywindow.document.write('<h2>Table Format</h2>');
        mywindow.document.write('<table class="table table-bordered">');
        mywindow.document.write('<tr><th style="font-size:12px;font-weight:bold;">Title</th><th style="font-size:12px;font-weight:bold;">Value</th></tr>');

        template.table.forEach(function (row, j) {
          mywindow.document.write('<tr>');
          mywindow.document.write('<td>' + row.title + '</td>');
          if (row.value === 'String') {
            mywindow.document.write('<td>' + row.string + '</td>');
          } else if (row.value === 'Number') {
            mywindow.document.write('<td>' + row.number + '</td>');
          } else if (row.value === 'File') {
            mywindow.document.write('<td>' + row.file + '</td>');
          }
          mywindow.document.write('</tr>');
        });

        mywindow.document.write('</table>');
      }
    });

    mywindow.document.write('</div></body></html>');
    mywindow.document.close();
    mywindow.focus();

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);

    return true;
  }






}



interface TableColumn {
  column_name: string;
  column_format: string;
  column_data: string;
}

interface ProgramInterface {
  tableFormArray: string;
  _id: string;
  template_id: string;
  programe_template_id: string;
  title: string;
  description: string;
  file_link: string;
  format: string;
  list_data: {
    header: string;
    contents: string;
  };
  table_data: Array<Array<TableColumn>>;
}


interface templateInterface {
  id: string,
  title: string,
  description: string,
  format: string,
  list_data: { header: string, content: string }

}
