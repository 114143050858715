import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-obe-member',
  templateUrl: './obe-member.component.html',
  styleUrls: ['./obe-member.component.scss']
})
export class ObeMemberComponent implements OnInit {

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;


  // Supporting master data

  category_list: any[] = [];
  category_list_loading = false;

  /* programme_list: any[] = [];
  programme_list_loading = false; */
  sections: any[] = [];

  all_programme_list: any[] = [];
  programme_search_form: any;
  memberassignForm: FormGroup = undefined;

  member_data_list: IOBEMember[] = [];
  member_list_loading = false;

  //modal data
  modal_programme_list: any[] = [];
  modal_sections: any[] = [];
  map_delete_loading: boolean = false;
  member_roles: any[] = [
    { id: "obe_member", key: "OBE Member" }, { id: "hod", key: "HOD" }, { id: "coe", key: "COE" }];
  public department_list: Array<any> = [];

  member_save_loading: boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService, private http: HttpClient) {
    this.memberassignForm = this.fb.group({
      _id: [''],
      enrolled_year: ['', [Validators.required]],
      roll_no: ['', [Validators.required]],
      role: ['', [Validators.required]],
      programme_type_id: [''],
      department_ids: [''],
      course_codes: ['']
    });
  }

  ngOnInit(): void {
    this.getProgrammeTypeList();
    this.getAllProgrammeDataList();
    this.buildFilterForm();
    this.getDepartmentDataList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }



  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      enrolled_year: [0], //required
    });

  }

  async getDepartmentDataList(): Promise<void> {
    try {
      this.department_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      const service_response = await this.restService.searchDepartments(search_form_query);
      if (service_response && service_response.success) {
        var dept_list: any[] = service_response.data;
        for (var i = 0; i < dept_list.length; i++) {
          this.department_list.push({
            department_id: dept_list[i].department_id,
            department_name: dept_list[i].department_name + " (" + ((dept_list[i]?.department_category_id != undefined) ? (dept_list[i]?.department_category_id + " - ") : "") + (dept_list[i].finance_type == "regular" ? "R" : "SF") + " )"
          })
        }
        console.log(this.department_list);



      }
    } catch (error) {
      alert('Error while read data');
    } finally {
    }
  }

  /* async onFilterSelectChange(id: string, event?: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList(this.programme_search_form);
    }

    if (id == "programme_id") {
      this.sections = [];
      const programme = this.programme_list.find(x => x.programme_id == this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_search_form.get('programme_id').setValue('');
      this.programme_search_form.get('section').setValue('');
      this.programme_list = [];
      this.sections = [];
      const search_form_query: any = {

      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  } */

  /*   async onModalFilterSelectChange(id: string, event?: any): Promise<void> {
      if (id === 'programme_type_id') {
        this.getModalProgrammeDataList(this.memberassignForm);
      }
  
      if (id == "programme_id") {
        this.modal_sections = [];
        this.memberassignForm.get('section').setValue('');
        const programme = this.modal_programme_list.find(x => x.programme_id == this.memberassignForm.value.programme_id);
        if (programme && programme.section_details && programme.section_details.length > 0) {
          this.modal_sections = programme.section_details;
        }
      }
    }
  
    async getModalProgrammeDataList(form: any): Promise<void> {
      try {
        this.memberassignForm.get('programme_id').setValue('');
        //this.memberassignForm.get('section').setValue('');
        this.modal_programme_list = [];
        this.modal_sections = [];
        const search_form_query: any = {
  
        };
        if (form) {
          if (form.value.programme_type_id) {
            search_form_query.programme_type_id = form.value.programme_type_id;
          }
        }
  
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.modal_programme_list = service_response.data;
        }
      } catch (error) {
        alert('Error while read data');
      } finally {
      }
    } */

  async getAllProgrammeDataList(): Promise<void> {
    try {
      this.all_programme_list = [];
      const search_form_query: any = {
      };
      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.all_programme_list = service_response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
    }
  }

  getProgrammeName(programme_id: string) {
    return this.all_programme_list.find(x => x.programme_id == programme_id)?.programme_name;
  }

  getDepartmentNames(department_ids: string[]) {
    var dept_name: string = '';
    for (var i = 0; i < department_ids.length; i++) {
      dept_name += this.department_list.find(x => x.department_id == department_ids[i])?.department_name;
      if (i != department_ids.length - 1) {
        dept_name += ", ";
      }
    }
    return dept_name;
  }
  async onSearchClick(): Promise<void> {
    this.searchMembers();
  }

  async searchMembers(): Promise<void> {
    try {
      if (!this.programme_search_form.value.enrolled_year) {
        alert('Please select Enrollment Year');
        return;
      }
      this.member_list_loading = true;
      this.member_data_list = [];
      var search_form_query: any = {
        enrolled_year: this.programme_search_form.value.enrolled_year,
      };
      const service_response = await this.restService.searchOBEMembers(search_form_query);
      if (service_response && service_response.success) {
        this.member_data_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.member_list_loading = false;
    }
  }

  onAddClick() {
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  getRole(role_id) {
    return this.member_roles.find(x => x.id == role_id)?.key;
  }

  async onEditClick(member_data: IOBEMember): Promise<void> {
    console.log(member_data);
    const edit_data: IOBEMember = {
      _id: member_data._id,
      enrolled_year: member_data.enrolled_year,
      roll_no: member_data.roll_no,
      role: member_data.role,
      programme_type_id: "",
      department_ids: [],
      course_codes: ""
    }
    if (member_data.role == 'coe') {
      edit_data.programme_type_id = member_data.programme_type_id
    }
    if (member_data.role == 'hod' || member_data.role == 'obe_member') {
      //converting string id arr into obj
      var selected_depts: string[] = member_data.department_ids;
      var selected_depts_obj: any[] = [];
      for (var i = 0; i < selected_depts.length; i++) {
        selected_depts_obj.push({
          department_id: selected_depts[i],
          department_name: this.department_list.find(x => x.department_id == selected_depts[i])?.department_name
        });
      }
      edit_data.department_ids = selected_depts_obj
    }
    if (member_data.role == 'obe_member' || member_data.role == 'hod') {
      edit_data.course_codes = member_data.course_codes.join(',');
    }
    this.memberassignForm.setValue(edit_data);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  isInvalid(formControl: any): boolean {
    return this.memberassignForm.touched && this.memberassignForm.get(formControl)?.errors !== null;
  }
  canDisable(): boolean {
    return this.memberassignForm.status !== 'VALID';
  }
  reset(): void {
    this.memberassignForm.reset();
    this.memberassignForm.controls["programme_type_id"].setValue("");
    this.memberassignForm.controls["roll_no"].setValue("");
    this.memberassignForm.controls["enrolled_year"].setValue("");
    this.memberassignForm.controls["role"].setValue("");
  }
  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  onSave() {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }
  async addItem(): Promise<void> {
    try {
      //var map_data = this.memberassignForm.value
      var map_data: any = {
        enrolled_year: this.memberassignForm.value.enrolled_year,
        roll_no: this.memberassignForm.value.roll_no,
        role: this.memberassignForm.value.role,
      }
      if (this.memberassignForm.value.role == 'coe') {
        if (this.memberassignForm.value.programme_type_id == '') {
          alert("Please choose a programme type"); return;
        }
        map_data.programme_type_id = this.memberassignForm.value.programme_type_id;
      }
      if (this.memberassignForm.value.role == 'hod' || this.memberassignForm.value.role == 'obe_member') {
        var dept_ids: string[] = [];
        //converting object into ids string arr
        for (var i = 0; i < this.memberassignForm.value.department_ids.length; i++) {
          dept_ids.push(this.memberassignForm.value.department_ids[i].department_id);
        }
        map_data.department_ids = dept_ids;
        if (dept_ids.length == 0) {
          alert("Please choose at least one department name"); return;
        }
      }
      if (this.memberassignForm.value.role == 'obe_member' || this.memberassignForm.value.role == 'hod') {
        if (this.memberassignForm.value.course_codes != '' && this.memberassignForm.value.course_codes != null && this.memberassignForm.value.course_codes != undefined) {
          map_data.course_codes = this.memberassignForm.value.course_codes.split(',');
        }
        else {
          delete map_data.course_codes;
        }
      }
      console.log(map_data);
      this.member_save_loading = true;
      let save_respose = await this.restService.createOBEMember(map_data);
      if (save_respose.success) {
        await this.onSearchClick();
        if (map_data._id) {
          alert('OBE Member updated successfully');
        }
        else {
          alert('OBE Member created successfully');
        }
      }
      else {
        alert("Could not create/update OBE Member data");
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.member_save_loading = false;
    }
  }


  async onDelete() {
    try {
      const consent = confirm('Are you sure do you want to delete OBE Member?');
      if (!consent) {
        return;
      }
      this.map_delete_loading = true;
      const save_respose = await this.restService.deleteOBEMember(this.memberassignForm.value._id);
      if (save_respose.success) {
        await this.onSearchClick();
        alert('OBE Member Deleted Successfully');
        this.onCloseClick();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    } finally {
      this.map_delete_loading = false;
    }
    this.onCloseClick();
  }

}

interface IOBEMember {
  _id: string,
  //required
  roll_no: string,
  enrolled_year: number,
  role: string,

  //conditional
  programme_type_id?: string,
  department_ids?: string[],
  course_codes?: any

  //programme_id: string,
  //semester: number,
  //section?: string,
}