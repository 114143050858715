import { ApplicationRef, ChangeDetectorRef, Component, OnInit, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
@Component({
  selector: 'app-student-notification',
  templateUrl: './student-notification.component.html',
  styleUrls: ['./student-notification.component.scss']
})
export class StudentNotificationComponent implements OnInit {

  @ViewChildren('editors') public editors: any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    // defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      // ['code']
    ]
  };

  editor_popup_form: FormGroup;

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;

  // Supporting master data

  programme_type_list: any[] = [];
  programme_type_list_loading = false;

  programme_list: any[] = [];
  programme_list_loading = false;

  programme_section_list: any[] = [];

  programme_search_form: any;

  filter_programme_list: any[] = [];
  filter_section_list: any[] = [];
  student: any = undefined;
  sms_save_loading: boolean = false;
  genders: any[] = [
    {
      id: 'male', text: 'Male'
    }, {
      id: 'female', text: 'Female'
    }, {
      id: 'transgender', text: 'Transgender'
    }];

  sms_form: FormGroup = undefined;
  mail_form: FormGroup = undefined;


  // core data

  students_list: any[] = [];
  student_list_loading = false;
  modal_mode: string = "";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private restService: AdminservicesService,
    private appRef: ApplicationRef, private changeDetector: ChangeDetectorRef) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    // Load master data
    this.getProgrammeTypeList();
    this.buildFilterForm();

    // Load core data
    this.getProgrammeDataList();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.programme_type_list_loading = true;
      this.programme_type_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.programme_type_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.programme_type_list_loading = false;
    }
  }


  buildFilterForm(): void {
    this.programme_search_form = this.fb.group({
      programme_type_id: [''],
      finance_type: [''],
      programme_id: [''],
      section_id: [''],
      enrolled_year: [2020],
      gender: [''],
      roll_no: [''],
    });

  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.getProgrammeDataList();
    } else if (id === 'programme_id') {
      this.programme_section_list = [];
      this.programme_search_form.get('section_id').setValue("");
      const programme = this.programme_list.find(c => c.programme_id === this.programme_search_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.programme_section_list = programme.section_details;
      }
      if (programme.section_details.length == 1) {
        this.programme_search_form.get('section_id').setValue(programme.section_details[0]._id);
      }
    }
  }

  async getProgrammeDataList(): Promise<void> {
    try {
      if (this.programme_search_form.value.programme_type_id && this.programme_search_form.value.finance_type) {

        this.programme_list_loading = true;
        this.programme_list = [];
        const search_form_query: any = {
          is_virtual: false,
        };
        if (this.programme_search_form) {
          if (this.programme_search_form.value.programme_type_id) {
            search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
          }
          if (this.programme_search_form.value.finance_type) {
            search_form_query.finance_type = this.programme_search_form.value.finance_type;
          }
        }
        const service_response = await this.restService.searchProgrammesLite(search_form_query);
        if (service_response && service_response.success) {
          this.programme_list = service_response.data;
        }
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onSearchClick(): Promise<void> {
    await this.searchFormDataList();

  }

  async searchFormDataList(): Promise<void> {
    try {

      if (this.programme_search_form.value.roll_no) {

      } else {
        if (!this.programme_search_form.value.programme_id) {
          alert('Please select programme');
          return;
        }
      }

      this.student_list_loading = true;
      this.students_list = [];
      const search_form_query: any = {

      };
      if (this.programme_search_form.value.roll_no) {
        search_form_query.roll_no = this.programme_search_form.value.roll_no;
      } else {
        if (this.programme_search_form.value.enrolled_year) {
          search_form_query.enrolled_year = this.programme_search_form.value.enrolled_year;
        }
        if (this.programme_search_form.value.programme_id) {
          search_form_query.programme_id = this.programme_search_form.value.programme_id;
        }
        if (this.programme_search_form.value.gender) {
          search_form_query.gender = this.programme_search_form.value.gender;
        }
        if (this.programme_search_form.value.programme_type_id) {
          search_form_query.programme_type_id = this.programme_search_form.value.programme_type_id;
        }
        if (this.programme_search_form.value.finance_type) {
          search_form_query.finance_type = this.programme_search_form.value.finance_type;
        }
        if (this.programme_search_form.value.section_id) {
          search_form_query.section_id = this.programme_search_form.value.section_id;
        }
      }

      const service_response = await this.restService.searchStudentWithProgrammes(search_form_query);
      if (service_response && service_response.success) {
        this.students_list = service_response.data;
        this.students_list.forEach(student => {
          student.is_selected = true;
        })
      }
      console.log(this.students_list);
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.student_list_loading = false;
    }
  }

  openSMS(student) {
    this.student = student;
    this.modal_mode = 'sms';
    this.buildSMSForm(student.mobile_number);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  openEmail(student) {
    this.student = student;
    this.modal_mode = 'email';
    this.buildMailForm(student.email);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  markSelected(ev: any) {
    this.students_list.forEach(student => {
      if (!student.is_selected) {
        if (ev.target.checked) {
          student.is_selected = true;
        }
        else {
          student.is_selected = false;
        }
      }
      else {
        student.is_selected = false;
      }
    });
  }

  isChecked() {
    if (this.students_list.filter(x => x.is_selected).length == this.students_list.length)
      return true;
    else
      return false;
  }

  openSelectedSMS() {
    this.modal_mode = 'sms';
    var students_mobile_nos: string[] = [];
    if (this.students_list.filter(x => x.is_selected == true).length == 0) {
      alert("Choose At least one student to send SMS");
      return;
    }
    this.students_list.filter(x => x.is_selected == true).forEach(student => {
      students_mobile_nos.push(student.mobile_number);
    })
    var bulk_mobile_string = students_mobile_nos.join(',');
    this.buildSMSForm(bulk_mobile_string);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  openSelectedEmail() {
    this.modal_mode = 'email';
    var students_email_ids: string[] = [];
    if (this.students_list.filter(x => x.is_selected == true).length == 0) {
      alert("Choose At least one student to send E-Mail");
      return;
    }
    this.students_list.filter(x => x.is_selected == true).forEach(student => {
      students_email_ids.push(student.email);
    })
    var bulk_email_id_string = students_email_ids.join(',');
    this.buildMailForm(bulk_email_id_string);
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  openBulkSMS() {
    this.modal_mode = 'sms';
    this.buildSMSForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  openBulkEmail() {
    this.modal_mode = 'email';
    this.buildMailForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async sendSMS() {
    try {
      var form_data = this.sms_form.value;
      console.log(form_data);
      this.sms_save_loading = true;
      const res = await this.restService.sendSMS(form_data);
      if (res) {
        alert("SMS sent successfully.");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.sms_save_loading = false;
    }
  }

  sendMail() {
    alert("Email Sent successfully");
  }

  buildSMSForm(to_number?: string): void {
    this.sms_form = this.fb.group({
      to_number: [to_number ? to_number : ''],
      message: [''],
    });
  }

  buildMailForm(email?: string): void {
    this.mail_form = this.fb.group({
      mail_id: [email ? email : ''],
      mail_subject: [''],
      mail_body: [''],
      mail_attachment: ['']
    });
  }


  closeModal() {
    this.modal_mode = '';
    this.student = undefined;
    this.sms_form = undefined; this.mail_form = undefined;
    JQueryHelper.closeModal('#modal-popup');
  }

  openUploadImage(event: any, editor_ref: string): void {
    let editor: EditorComponent | undefined;
    for (const result of this.editors._results) {
      if (result.element.nativeElement && result.element.nativeElement.className) {
        const class_names: string[] = result.element.nativeElement.className.split(' ');
        if (class_names.includes(editor_ref)) {
          editor = result;
          break;
        }
      }
    }
    this.editor_popup_form = this.fb.group({
      image_url: [''],
      height: [''],
      width: [''],
      editor: [editor]
    });
    JQueryHelper.openModal('#modal-2', { keyboard: false, backdrop: 'static' });
  }
  async uploadImage(e: any, form: FormGroup): Promise<void> {

    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const img = new Image();

          img.src = (reader.result as string);
          img.onload = () => {
            // const image_url = form.get('image_url');
            // if (image_url) {
            //   image_url.setValue(img.src);
            // }
            // const height = form.get('height');
            // if (height) {
            //   height.setValue(img.height);
            // }
            // const width = form.get('width');
            // if (width) {
            //   width.setValue(img.width);
            // }
            if (form.value.editor) {
              form.value.editor.exec('insertImage', {
                src: img.src,
                height: img.height,
                width: img.width
              });
              JQueryHelper.closeModal('#modal-2');
            }
          };
        };

        reader.readAsDataURL(file);
      }

    } catch (error) {

    } finally {

    }
  }

  onCloseImageUpload(): void {
    JQueryHelper.closeModal('#modal-2');
  }

}
