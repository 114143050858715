import { NavigationService } from '../../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../../components/notification/notification.service';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HttpErrorResponse } from '@angular/common/http';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.scss']
})
export class GenerateBillComponent implements OnInit {
  fonts = fonts;
  commonEnums = CommonEnums;
  fromDataResolver = FromDataResolver;
  year:number;
  month:string='';
  months: {
    month_name:string,
    month_id: string
  } [] = [
    {month_name:"January", month_id:"01"},{month_name:"February", month_id:"02"},{month_name:"March", month_id:"03"},
    {month_name:"April", month_id:"04"},{month_name:"May", month_id:"05"},{month_name:"June", month_id:"06"},
    {month_name:"July", month_id:"07"},{month_name:"August", month_id:"08"},{month_name:"September", month_id:"09"},
    {month_name:"October", month_id:"10"},{month_name:"November", month_id:"11"},{month_name:"December", month_id:"12"}, 
  ];
  student_bill: {
    month:string,
    expenses: {
      expense_name:string,
      expense_amount:number,
    }[],
    total_amount:number
  } [ ] = [ ];

  monthly_expenses: {
    expense_name:string,
    expense_amount:number
  } [] = [];
  monthly_total:number=0;
  constructor(private route: ActivatedRoute,
    private restService: AdminservicesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private navigationService: NavigationService) { 
      this.year = new Date().getFullYear();
      this.student_bill.push({month:"June", expenses:[{expense_name:"General Food", expense_amount:500},
      {expense_name:"Extra Food", expense_amount:700},{expense_name:"Other Expenses", expense_amount:150}
    ],total_amount:1350});
    this.student_bill.push({month:"July", expenses:[{expense_name:"General Food", expense_amount:600},
    {expense_name:"Extra Food", expense_amount:800},{expense_name:"Other Expenses", expense_amount:250}
  ],total_amount:1650});
  this.student_bill.push({month:"August", expenses:[{expense_name:"General Food", expense_amount:500},
  {expense_name:"Extra Food", expense_amount:800},{expense_name:"Other Expenses", expense_amount:150}
],total_amount:1450});
    }
    chooseMonth():void
    {
      this.monthly_expenses= []; this.monthly_total=0
      if(this.student_bill.findIndex(x=>x.month==this.month)>=0){
        this.student_bill.find(x=>x.month==this.month)!.expenses.forEach(element=>{
          this.monthly_expenses.push(element);
          this.monthly_total += element.expense_amount;
        })
      }
      else
      {
        this.notificationService.setNotifcation({ message: 'No data available for chosen month.', type: 'Failed' });
      }
    }
    
  ngOnInit(): void {
    this.restService.initializeServices();
    this.notificationService.setNotifcation({});
    this.navigationService.setNavigation([{label: 'Student Monthly Bill', url: 'admin/hostel/student-bill' }]);

  }


}
