<!--Exam Fee Setting component-->
<app-side-bar></app-side-bar>
    <div class="main-content app-content">
      <app-combo-bar></app-combo-bar>
      <app-notification></app-notification>
      <!-- container -->
      <div class="container-fluid">
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">{{title}}</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
            <div class="d-flex my-xl-auto right-content">
                <div class="pr-1 mb-3 mb-xl-0">
                    <button type="button" class="btn btn-info" (click)="onAddClick()">Add New Term Type</button>
                </div>
            </div>
        </div>
          <!--Table View-->
          <div class="row">
              <div class="col-xl-12">
                  <div class="card mg-b-20">
                      <div class="card-header pb-0">
                          <div class="d-flex justify-content-between">
                              <h4 class="card-title mg-b-0">{{title}}</h4>
  
                          </div>
                          <p class="tx-12 tx-gray-500 mb-2">Manage Exam Term Types</p>
                      </div>
  
                      <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th style="width: 10%;">S.No</th>
                                        <th>Term Name</th>
                                        <th style="width:15%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="term_type_list.length>0">
                                        <ng-container *ngFor="let term of term_type_list;let i=index;trackBy:fromDataResolver.identify;">
                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td>{{term.term_type_name}}</td>
                                                <td>
                                                    <div class="pr-1 mb-3 mb-xl-0">
                                                        <button type="button" 
                                                        (click)="onDeleteClick(term)"
                                                        class="btn btn-sm btn-danger mr-2">
                                                            <fa-icon [icon]="fonts.faTrash"></fa-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                    
                                    </ng-container>
                    
                                    <ng-container *ngIf="!term_type_list_loading && term_type_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="term_type_list_loading && term_type_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">Loading Please wait</th>
                                        </tr>
                                    </ng-container>
                    
                                </tbody>
                            </table>
                        </div>

                      </div> 
                  </div>
              </div>
          </div>
  
  
          <!-- Settings Large Modal -->
          <div class="modal" id="modal-popup">
              <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content modal-content-demo" *ngIf="term_setting_form">
                      <div class="modal-header">
                          <h6 class="modal-title">Create term Type</h6>
                      </div>
                      <div class="modal-body">
                          <div class="card card-body pd-20 pd-md-40">
                            <form [formGroup]="term_setting_form" class="form-horizontal">
                                  <div class="form-group">
                                    <label class="main-content-label tx-11 tx-medium tx-gray-600">Enter Term Name</label>
                                    <input class="form-control"
                                    placeholder="Enter term Name"
                                    required="" type="text" formControlName="term_type_name">
                                    <div *ngIf="isInvalid('term_type_name')">
                                      <ul class="parsley-errors-list filled" id="parsley-id-9">
                                        <li class="parsley-required">Term Name is required.</li>
                                      </ul>
                                    </div>
                                  </div>

                                
                              <div class="text-center">
                                <button class="btn btn-primary mg-r-20" (click)="onSave()" [disabled]="canDisable()">Save</button>
                                <button class="btn btn-info mg-r-20" (click)="onSaveAndClose()" [disabled]="canDisable()">Save & Close</button>
                                <button class="btn btn-dark mg-r-20" (click)="reset()">Reset</button>
                                <button class="btn btn-secondary mg-r-20" (click)="onCloseClick()">Close</button>
                              </div>
                            </form>
                          </div>
                      </div>
  
                  </div>
              </div>
          </div>
          <!--End Large Modal -->
  
      </div>
  </div>


  
  <!-- /main-content -->
<app-footer></app-footer>





  
