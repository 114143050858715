import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { NavigationService } from '../../../../components/navigation/navigation.service';
import { NotificationService } from '../../../../components/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { Component, OnInit, HostListener } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { HTMLHelper } from 'src/app/services/helpers/HTMLHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { Modal } from 'src/app/components/ModalInterface';

@Component({
  selector: 'app-guardian-assignment',
  templateUrl: './guardian-assignment.component.html',
  styleUrls: ['./guardian-assignment.component.scss']
})
export class GuardianAssignmentComponent implements OnInit {
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  title = 'Student-Staff Guardian Assignment';
  assignment_list_loading: boolean = false;
  assignment_list: AssignmentInterface[] = [];
  assignment: AssignmentInterface = {} as AssignmentInterface;
  assignment_form: FormGroup = {} as FormGroup;
  save_loading: boolean = false;
  delete_loading: boolean = false;
  ngOnInit(): void {
  }
  constructor(private restService: AdminservicesService, private notificationService: NotificationService,
    private formBuilder: FormBuilder, private router: Router) {
    this.getAssignmentList();
    this.buildForm();
  }


  async getAssignmentList() {
    this.assignment_list = [];
    this.assignment_list_loading = true;
    try {
      const service_response = await this.restService.getGuardianAssignment({});
      if (service_response && service_response.success) {
        this.assignment_list = service_response.data;
        console.log(this.assignment_list);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
    this.assignment_list_loading = false;
  }

  buildForm(): void {

    if (JSON.stringify(this.assignment) == '{}') {
      this.assignment = {
        _id: "",
        start_date: "",
        end_date: "",
        is_active: false,
        staff_id: "",
        roll_nos: [],
      };
    }
    this.assignment_form = this.formBuilder.group({
      _id: [this.assignment._id],
      start_date: [this.assignment.start_date != "" ? (this.assignment?.start_date?.substring(0, 10)) : '', [Validators.required]],
      end_date: [this.assignment.end_date != "" ? (this.assignment?.end_date?.substring(0, 10)) : '', [Validators.required]],
      is_active: [this.assignment.is_active],
      staff_id: [this.assignment.staff_id, [Validators.required]],
      roll_nos: [this.assignment.roll_nos.join(','), [Validators.required]],
    });
  }

  onAddClick() {
    this.buildForm();
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async onEditClick(assignment: AssignmentInterface) {
    var settings = this.assignment_list.find(x => x._id == assignment._id);
    if (settings) {
      console.log(settings);
      this.assignment = settings;
      this.buildForm();
    }
    JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
  }
  onSave(): void {
    this.addItem();
  }
  onSaveAndClose(): void {
    this.onSave();
    this.onCloseClick();
  }

  async onCloseClick(): Promise<void> {
    this.reset();
    JQueryHelper.closeModal('#modal-popup');
  }

  reset(): void {
    this.assignment = {} as AssignmentInterface;
    this.assignment_form.reset();
  }

  async addItem(): Promise<void> {
    try {
      var form_data = this.assignment_form.value;
      form_data.start_date = new Date(this.assignment_form.value.start_date).toISOString();
      form_data.end_date = new Date(this.assignment_form.value.end_date).toISOString();
      form_data.roll_nos = (form_data.roll_nos as string).split(",");
      console.log(form_data);
      this.save_loading = true;
      if (this.assignment_form.value._id) {
        const res = await this.restService.updateGuardianAssignment(this.assignment_form.value.id, form_data);
        if (res) {
          alert("Student-Staff Guardian Assignment updated successfully.");
        }
      }
      else {
        const res = await this.restService.createGuardianAssignment(form_data);
        if (res) {
          alert("Student-Staff Guardian Assignment created successfully.");
        }
      }
      this.getAssignmentList();
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    }
    finally {
      this.save_loading = false;
    }
  }

  async onDelete() {
    const consent = confirm('Are you sure do you want to delete?');
    if (!consent) {
      return;
    }
    try {
      this.delete_loading = true;
      const res = await this.restService.deleteExamFeeHeader(this.assignment_form.get('_id')!.value);
      if (res.success) {
        alert("Student-Staff Guardian Assignment deleted successfully.");
        this.getAssignmentList();
      }
      this.onCloseClick();
    }
    catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.delete));
    }
    finally {
      this.delete_loading = false;
    }
  }

  isInvalid(formControl: string): boolean {
    return this.assignment_form.touched && this.assignment_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.assignment_form.status !== 'VALID';
  }



  getDate(date_input: string) {
    return date_input.substring(0, 10);
  }

  triggerFunction(event: any) {
    var roll_nos: string = this.assignment_form.get('roll_nos').value;
    var prev_string = roll_nos.substring(roll_nos.length - 6).substring(0, 4);
    if (event.key === 'Enter') {
      event.preventDefault();
      this.assignment_form.get('roll_nos').setValue(roll_nos + "," + prev_string);
    }
  }


}

interface AssignmentInterface {
  _id: string,
  start_date: string,
  end_date: string,
  is_active: boolean

  staff_id: string,
  roll_nos: string[],
}

