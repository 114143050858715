import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuItems } from 'src/app/controllers/admins/hostel-management/manage-mess/menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {
  private _baseURL: string = ''
  private _headers: any;
  private menuItems: string = 'hostel/mess/menu-item';

  constructor(private httpClient: HttpClient) { }

  set url(baseURL: string) {
    this._baseURL = baseURL;
  }

  set headers(headers: any) {
    this._headers = headers;
  }

  errorHandler = (error: any): Promise<MenuItems> => {
    console.log('MenuItemService Error ', error);
    return of({} as MenuItems).toPromise();
  }

  getMenuItems(id: string = ''): Promise<MenuItems[]> {
    let url = `${this._baseURL}/${this.menuItems}/${id}`;
    return this.httpClient.get<MenuItems[]>(url, this._headers).pipe(
      map((res: any) => {
        if (Array.isArray(res.data)) {
          return res.data;
        }
        return [res.data];
      })
    ).toPromise();
  }

  creatMenuItems(menuItems: MenuItems): Promise<MenuItems> {
    return this.httpClient.post<MenuItems>(`${this._baseURL}/${this.menuItems}`, menuItems, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }

  updateMenuItems(id: string = '', data: MenuItems): Promise<MenuItems> {
    return this.httpClient.put<MenuItems>(`${this._baseURL}/${this.menuItems}/${id}`, data, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }

  deleteMenuItems(id: string = ''): Promise<boolean> {
    return this.httpClient.delete<boolean>(`${this._baseURL}/${this.menuItems}/${id}`, this._headers).pipe(
      map((res: any) => res.data)
    ).toPromise();
  }
}
