import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CSVHelper } from 'src/app/services/helpers/CSVHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { NotificationService } from '../../../../components/notification/notification.service';
import { Modal } from 'src/app/components/ModalInterface';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-settlement-report',
  templateUrl: './settlement-report.component.html',
  styleUrls: ['./settlement-report.component.scss']
})
export class SettlementReportComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {};
  bulk_render_data: Modal = {
    return_type: "FILE",
    file_upload_only: false,
    modal_id: "settlement-report",
    modal_button_text: "Settlement Report Bulk Upload",
    modal_header: "Settlement-Report Bulk Upload",
    list_name: "Settlement-Report Upload",
    csv_data: {
      columns: [
        { column_name: "sub_acc_id", column_type: "required" },
        { column_name: "order_id", column_type: "required" },
        { column_name: "payable_amount", column_type: "required" },
        { column_name: "payout_id", column_type: "required" },
        { column_name: "payment_type", column_type: "required" },
        { column_name: "invoice_ref_no", column_type: "required" },
        { column_name: "settlement_date", column_type: "required" },
      ]
    },
    table_data: {
      column_names: ["Sub Acc ID", "Order ID", "Payable Amount", "Payout ID", "Payment Type", "Invoice Ref. No.", "Settlement Date(dd-mm-YYYY)"],
      column_values: ["ANJACSELF", "2039-5792-499", "100", "800497918", "OPTUPI", "10UL00", "21-12-2022"]
    },
    show_loader: false
  };

  // common methods
  fonts = fonts;
  fromDataResolver = FromDataResolver;
  commonEnums = CommonEnums;
  report_filter_form: FormGroup;
  online_adjustment_form: FormGroup;
  add_transaction_form: FormGroup;
  show_split: boolean = true;
  programme_list: any[] = [];
  programme_list_loading = false;
  category_list: any[] = [];
  category_list_loading = false;
  parts: any[] = [];
  department_list = [];
  purposes: any[] = [{ id: "EXAM_FEE", name: "Exam Fees" },
  { id: "PROGRAMME_FEE", name: "Programme Fee" }, { id: "HOSTEL_FEE", name: "Hostel Fee" },
  { id: "HOSTEL_ADMISSION_FEE", name: "Hostel Admission Fee" },
  { id: "TRANSPORTATION_ADMISSION_FEE", name: "Transportation Admission Fee" },
  ];
  fees_headers: {
    id?: string, term_name?: string, programme_type_id?: string,
    admission_id?: string, admission_name?: string
  }[] = []; //replace with API Call
  payment_modes: VariableSettingInterface[] = [
    { id: "online", name: "ONLINE" }, { id: "offline", name: "OFFLINE" }, { id: "online_adjustment", name: "ONLINE ADJUSTMENT" }
  ]
  payment_status: VariableSettingInterface[] = [{ id: true, name: "SUCCESS" }, { id: false, name: "FAILED" }];
  split_status: VariableSettingInterface[] = [{ id: "ALL", name: "All" }, { id: true, name: "Collected" }, { id: false, name: "Not Collected" }];
  settlement_status: VariableSettingInterface[] = [{ id: "ALL", name: "All" }, { id: true, name: "Settled" }, { id: false, name: "Not Settled" }];
  id_types: VariableSettingInterface[] = [{ id: "BANK_REF", name: "Bank Reference No." }, { id: "CHALLAN_NO", name: "Challan No." },
  { id: "CHEQUE_NO", name: "Cheque No." }];
  report_list_loading: boolean = false;
  transaction_report: any[] = [];
  split_headers: string[] = [];
  max_splits_cols: number = 0;
  payment_name: string = '';
  transaction_data: string = '';
  start_index = 0;
  total_records = 0;
  take_index = 500;
  transaction_header_id: string = "";
  clg_trans_id: string = "";
  current_selection: any;
  payment_details: any[] = [];
  offline_student_data: any = {};
  search_rno: string = "";
  sections: any = [];
  payment_details_loading = false;
  roll_no: string = "";
  file_upload: any;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  csvRecords: any[] = [];
  header = true;
  required_columns: string[] = [];
  invalid_csv: boolean = true;
  file: any;
  csvRecords_loading: boolean = false;

  constructor(private restService: AdminservicesService, private fb: FormBuilder, private http: HttpClient,
    private route: ActivatedRoute, private notificationService: NotificationService) {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 25,
      allowSearchFilter: true
    };
    this.report_filter_form = this.fb.group({
      settlement_start_date: [''],
      settlement_end_date: [''],
      programme_type_id: [''],
      purpose: [''],
      fee_header_id: [''],
      finance_type: [''],
      programme_id: [''],
      enrolled_year: [''],
      section: [''],
      settlement_status: [true],
      community_ids: [''],
      /* start_date: [''],
      end_date: [''], */
      /* payment_collected: ['d'],
      split_status: ['d'], */
    });
    this.online_adjustment_form = this.fb.group({
      transaction_id: ['', [Validators.required]],
      id_type: ['', [Validators.required]],
      //proof: ['', [Validators.required]],
      description: [''],
      signed_by: ['', [Validators.required]]
    });
    this.add_transaction_form = this.fb.group({
      reference_no: ['', [Validators.required]],
      id_type: ["", [Validators.required]],
      //proof:['', [Validators.required]],
      description: [''],
      signed_by: ['', [Validators.required]],
    })
  }

  async ngOnInit() {
    // Load master data
    this.getProgrammeTypeList();
    this.route.queryParams.subscribe(params => {
      this.transaction_header_id = params.transaction_header_id;
      this.roll_no = params.roll_no;
      console.log(this.transaction_header_id);
      if (this.transaction_header_id != undefined && this.roll_no != undefined) {
        this.getReport();
      }
    });
    //this.testWrite();
  }

  async getProgrammeTypeList(): Promise<void> {
    try {
      this.category_list_loading = true;
      this.category_list = [];
      const service_response = await this.restService.getProgrammeTypes();
      if (service_response && service_response.success) {
        this.category_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read programme type');
    } finally {
      this.category_list_loading = false;
    }
  }

  async getProgrammeDataList(form: any): Promise<void> {
    try {
      this.programme_list_loading = true;
      this.programme_list = [];
      const search_form_query: any = {
        is_virtual: false
      };
      if (form) {
        if (form.value.programme_type_id) {
          search_form_query.programme_type_id = form.value.programme_type_id;
        }
        if (form.value.finance_type) {
          search_form_query.finance_type = form.value.finance_type;
        }
      }

      const service_response = await this.restService.searchProgrammesLite(search_form_query);
      if (service_response && service_response.success) {
        this.programme_list = service_response.data;
      }
    } catch (error) {
      alert('Error while read data');
    } finally {
      this.programme_list_loading = false;
    }
  }

  async onPurposeChange() {
    this.onResetClick();
    var filter_data: any = {}; this.fees_headers = []; this.report_filter_form.get('fee_header_id').setValue("");
    if (this.report_filter_form.value.purpose != '') {
      try {
        this.report_filter_form.get('fee_header_id').setValue("");
        if (this.report_filter_form.value.programme_type_id != '') {
          filter_data.programme_type_id = this.report_filter_form.value.programme_type_id;
        }
        console.log(filter_data);
        if (this.report_filter_form.value.purpose == "EXAM_FEE") {
          const service_response = await this.restService.getExamFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                id: term.id, programme_type_id: term.programme_type_id, term_name: term.term_name
              })
            })
          }
        }
        else if (this.report_filter_form.value.purpose == "PROGRAMME_FEE") {
          const service_response = await this.restService.getProgrammeFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                id: term.id, programme_type_id: term.programme_type_id, term_name: term.term_name
              })
            })
          }
        }

        else if (this.report_filter_form.value.purpose == "HOSTEL_FEE") {
          const service_response = await this.restService.getHostelFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                id: term.id, programme_type_id: term.programme_type_id, term_name: term.term_name
              })
            })
          }
        }
        else if (this.report_filter_form.value.purpose == "HOSTEL_ADMISSION_FEE") {
          const service_response = await this.restService.getHostelAdmissionFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                admission_id: term.admission_id, admission_name: term.admission_name
              })
            })
          }
        }
        else if (this.report_filter_form.value.purpose == "TRANSPORTATION_ADMISSION_FEE") {
          const service_response = await this.restService.getTransportAdmissionFeesHeaderList(filter_data);
          if (service_response.success) {
            console.log(service_response);
            service_response.data.forEach(term => {
              this.fees_headers.push({
                admission_id: term.admission_id, admission_name: term.admission_name
              })
            })
          }
        }

      } catch (error) {
        console.log(error);
        alert('Error while read data');
      }
    }
    else {
      this.fees_headers = [];
    }
  }

  async onSearchClick(): Promise<void> {
    this.getReport()
  }

  async onFilterSelectChange(id: string, event: any): Promise<void> {
    this.onResetClick();
    if (id == "programme_id") {
      this.sections = [];
      const programme = this.programme_list.find(x => x.programme_id == this.report_filter_form.value.programme_id);
      if (programme && programme.section_details && programme.section_details.length > 0) {
        this.sections = programme.section_details;
      }
    }

    if (id == 'programme_type_id') {
      if (this.report_filter_form.value.purpose != '') {
        this.onPurposeChange();
      }
    }
    if (id === 'programme_type_id' || id === 'finance_type') {
      this.report_filter_form.get('programme_id').setValue("");
      this.getProgrammeDataList(this.report_filter_form);
      if (id === 'programme_type_id' && this.report_filter_form.value.programme_type_id) {
        this.parts = this.commonEnums.getParts(this.report_filter_form.value.programme_type_id);
      }
    }
  }

  async onResetClick(): Promise<void> {
    this.start_index = 0;
    this.take_index = 500;
    this.total_records = 0;
    this.transaction_report = [];
  }

  async onPrevClick(): Promise<void> {
    if (this.start_index > 0) {
      this.start_index = this.start_index - this.take_index;
      this.onSearchClick();
    }
  }

  async onNextClick(): Promise<void> {
    const temp_count = this.start_index + this.take_index;
    if (this.total_records > temp_count) {
      this.start_index = this.start_index + this.take_index;
      this.onSearchClick();
    }

  }


  async getReport() {
    var form_data = this.report_filter_form.value
    const search_query: any = {
      skip: this.start_index,
      take: this.take_index
    };

    if (!form_data.purpose) {
      alert("Please choose purpose for report");
      return;
    }

    if (form_data.settlement_start_date) {
      search_query.settlement_start_date = new Date(form_data.settlement_start_date).toISOString();
    }
    if (form_data.settlement_end_date) {
      search_query.settlement_end_date = new Date(form_data.settlement_end_date).toISOString()
    }

    if (form_data.programme_type_id) {
      search_query.programme_type_id = form_data.programme_type_id
    }
    if (form_data.finance_type) {
      search_query.finance_type = form_data.finance_type
    }
    if (form_data.programme_id) {
      search_query.programme_id = form_data.programme_id
    }
    if (form_data.section) {
      search_query.section = form_data.section
    }
    if (form_data.enrolled_year) {
      search_query.enrolled_year = form_data.enrolled_year
    }

    if (form_data.purpose) {
      search_query.purpose = form_data.purpose
    }

    if (form_data.fee_header_id) {
      search_query.fee_header_id = form_data.fee_header_id
    }
    if (form_data.community_ids) {
      search_query.community_ids = form_data.community_ids;
    }


    if (form_data.settlement_status != 'd' && form_data.settlement_status == true) {
      search_query.settlement_status = true;
    }
    if (form_data.settlement_status != 'd' && form_data.settlement_status == false) {
      search_query.settlement_status = false;
    }

    //form_data.skip=0; form_data.take=500;
    console.log(search_query);
    this.transaction_report = []; this.total_records = 0;
    this.split_headers = [];
    this.report_list_loading = true;
    try {
      const service_response = await this.restService.searchFees(search_query);
      if (service_response && service_response.success) {
        this.transaction_report = service_response.data?.data;
        this.total_records = service_response.data?.count;
        console.log(this.transaction_report);
        if (this.transaction_report.length > 0) {
          this.transaction_report.forEach(report => {
            if (report?.split_data_list && report?.split_data_list?.length > this.max_splits_cols)
              this.max_splits_cols = report?.split_data_list?.length;
          });
          this.generateSplitHeaders(this.transaction_report[0].split_data_list);
        }
      }
    } catch (error) {
      console.log(error);
      alert('Error while read data');
    }
    this.report_list_loading = false;
  }

  /* async getClassName(year:number,programme_name:string,section:string,programme_id:string)
  {
    const res = await this.restService.getProgrammeById(programme_id);
    if(res && res.success)
    {
      console.log(res.data);
    }
    return this.commonEnums.getClassName(year,programme_name,section);
  } */

  getPaymentMode(payment_mode) {
    return this.payment_modes.find(x => x.id == payment_mode).name;
  }

  getPaymentStatus(payment_status) {
    return this.payment_status.find(x => x.id == payment_status).name;
  }

  getSplitStatus(split_status) {
    return this.split_status.find(x => x.id == split_status).name;
  }
  generateSplitHeaders(splits: any[]) {
    this.split_headers = [];
    for (var i = 0; i < splits.length; i++)
      this.split_headers.push(splits[i].sub_acc_id + " (" + splits[i].account_no + ")");
  }
  showSplit(ev: any) {

  }

  getPurpose(purpose) {
    return this.purposes.find(x => x.id == purpose).name;
  }



  async viewTransactionHistory(transaction_id: string) {
    try {
      const service_response = await this.restService.viewTransactionById(transaction_id);
      if (service_response && service_response.success) {
        if (JSON.stringify(service_response.data) != '{}') {
          this.transaction_data = JSON.stringify(service_response.data, null, 2);
        }
        else {
          this.transaction_data = "No transaction history found."
        }

        console.log(this.transaction_data);
      }
    } catch (error) {
      alert('Error while read data');
    }
    JQueryHelper.openModal('#transaction-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async closeTransactionModal(): Promise<void> {
    this.transaction_data = '';
    JQueryHelper.closeModal('#transaction-modal-popup');
  }

  openAdjustmentModal(clg_trans_id: string) {
    this.clg_trans_id = clg_trans_id;
    JQueryHelper.openModal('#adjustment-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  async closeAdjustmentModal(): Promise<void> {
    this.clg_trans_id = "";
    JQueryHelper.closeModal('#adjustment-modal-popup');
  }

  async saveAdjustmentData() {
    var form_data: any = {};
    form_data.offline_ref_no = this.online_adjustment_form.value.transaction_id;
    form_data.ref_type = this.online_adjustment_form.value.id_type;
    form_data.description = this.online_adjustment_form.value.description;
    const res = await this.restService.onlineAdjustment(this.clg_trans_id, form_data);
    if (!res) {
      alert("Failed to complete online adjustment");
      this.notificationService.setNotifcation({ message: 'Failed to complete online adjustment', type: 'Failed' });
    }
    else {
      alert("Updated online adjustment for the transaction successfully");
      this.notificationService.setNotifcation({ message: 'Updated online adjustment for the transaction successfully', type: 'Success' });
    }
    this.closeAdjustmentModal();
  }

  isInvalid(formControl: string): boolean {
    return this.online_adjustment_form.touched && this.online_adjustment_form.get(formControl)?.errors !== null;
  }
  isInvalidTransaction(formControl: string): boolean {
    return this.add_transaction_form.touched && this.add_transaction_form.get(formControl)?.errors !== null;
  }

  canDisable(): boolean {
    return this.online_adjustment_form.status !== 'VALID';
  }

  canDisableOfflinePayment(): boolean {
    return this.add_transaction_form.status !== 'VALID';
  }

  addTransaction() {
    this.search_rno = "";
    this.add_transaction_form.reset(); this.add_transaction_form.get('id_type').setValue("");
    this.payment_details = []; this.offline_student_data = {};
    JQueryHelper.openModal('#add-modal-popup', { keyboard: false, backdrop: 'static' });
  }

  closeAddModal() {
    this.search_rno = "";
    this.add_transaction_form.reset(); this.add_transaction_form.get('id_type').setValue("");
    this.payment_details = []; this.offline_student_data = {};
    JQueryHelper.closeModal('#add-modal-popup');
  }

  async getStudentPaymentData() {
    console.log(this.search_rno);
    try {
      this.payment_details_loading = true;
      const service_response = await this.restService.getStudentFeeByRollNo(this.search_rno.toUpperCase());
      if (service_response && service_response.success) {
        this.payment_details = service_response.data;
        this.payment_details.forEach(detail => {
          detail.show_payment = false;
        })
        console.log(this.payment_details);
        const res = await this.restService.getStudentByRollNo((this.search_rno).toUpperCase());
        console.log(res.data[0]);
        if (res.success) {
          this.offline_student_data.given_name = res.data[0]?.given_name;
          this.offline_student_data.class_name = this.commonEnums.getClassName(res.data[0]?.enrolled_year, res.data[0]?.programme_name, res?.data[0]?.section);
        }
      }
    } catch (error) {
      console.log(error);
      alert('Error while read data');
    } finally {
      this.payment_details_loading = false;
    }
  }


  async viewBreakupsClick(payment_object: any): Promise<void> {
    if (payment_object.fee_header_type == "EXAM_FEE") {
      this.current_selection = payment_object;
      JQueryHelper.openModal('#exam-fee-modal-popup', { keyboard: false, backdrop: 'static' });
    }
  }

  async onCloseBreakupsClick() {
    if (this.current_selection.fee_header_type == "EXAM_FEE") {
      JQueryHelper.closeModal('#exam-fee-modal-popup');
      this.current_selection = null;
    }
  }

  async openCloseOfflinePayment(payment_detail: any): Promise<void> {
    this.add_transaction_form.reset(); this.add_transaction_form.get('id_type').setValue("");
    if (payment_detail.show_payment) {
      payment_detail.show_payment = false;
    } else {
      payment_detail.show_payment = true;
    }
    this.payment_details.forEach(detail => {
      if (detail.payment_init_id != payment_detail.payment_init_id) {
        detail.show_payment = false;
      }
    });
  }

  async makeOfflinePayment(payment_detail: any) {
    var form_data: any = {};
    form_data.purpose = payment_detail.fee_header_type; form_data.transaction_header_id = payment_detail.payment_init_id;
    form_data.offline_ref_no = this.add_transaction_form.value.reference_no; form_data.ref_type = this.add_transaction_form.value.id_type;
    form_data.description = this.add_transaction_form.value.description; form_data.roll_no = this.search_rno.toUpperCase();
    form_data.version = payment_detail.version;
    const res = await this.restService.offlineAdjustment(form_data);
    if (!res) {
      alert("Failed to create offline adjustment");
      this.notificationService.setNotifcation({ message: 'Failed to complete online adjustment', type: 'Failed' });
    }
    else {
      alert("Created offline adjustment successfully");
      this.notificationService.setNotifcation({ message: 'Updated online adjustment for the transaction successfully', type: 'Success' });
    }
    this.openCloseOfflinePayment(payment_detail);
    this.getStudentPaymentData();
  }
  downloadCSV() {
    const data_list: any[] = [];
    let i = 1;
    for (const fees of this.transaction_report) {
      var push_data: any = {
        "S.No": i,
        "Roll No.": fees?.roll_no,
        "Name": fees?.name,
        "Class": this.commonEnums.getClassName(fees.year, fees.programme_name, fees.section),
        "Settlement Amount": fees?.amount,
        "Settled Amount": (fees?.hasOwnProperty('settlement_total')) ? (fees?.settlement_total) : 0,
        "Balance Amount": (fees?.hasOwnProperty('settlement_total')) ? (fees?.amount - fees?.settlement_total) : fees?.amount,
        "Settled Date": DateHelper.toDisplayDate(fees?.settlement_date),
        "Bank Trans ID": ((fees?.bank_trans_id != '' && fees?.bank_trans_id != 'null') ? fees?.bank_trans_id : "---"),
      };
      if (this.show_split) {
        for (var j = 0; j < this.split_headers.length; j++) {
          push_data[this.split_headers[j]] = fees?.split_data_list[j].amount;
        }
      }
      data_list.push(push_data);
      i++;
    }
    CSVHelper.downloadCSV(data_list, 'Transaction_Report ' + DateHelper.convertToControlDate(new Date()));
  }

  /* async  testWrite() {
    let fieldDescriptors:FieldDescriptor []= [
        { name: 'fname', type: 'C', size: 255 },
        { name: 'lname', type: 'C', size: 255 }
    ];

    let records = [
        { fname: 'Joe', lname: 'Bloggs' },
        { fname: 'Mary', lname: 'Smith' }
    ];

    let dbf = await DBFFile.create('sample.dbf', fieldDescriptors);
    console.log('DBF file created.');
    await dbf.appendRecords(records);
    console.log(`${records.length} records added.`);
} */

  downloadDBF() {

  }

  async bulkUpload(e: any) {
    try {
      const file = e;
      console.log(file);
      var formData = new FormData();
      formData.append('file', file);
      console.log(formData);
      const consent = confirm("Are you sure want to upload this file?");
      if (!consent) return;
      this.bulk_render_data.show_loader = true;
      /* setTimeout(() => {
        this.bulk_render_data.show_loader = false;
        alert("File Uploaded successfully");
      }, 5000) */
      const response = await this.restService.uploadSettlementFile(formData);
      if (response.success) {
        alert("File Uploaded successfully");
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
      this.bulk_render_data.show_loader = false;
    }
  }
}

interface FeesReportInterface {
  "payment_mode": string,
  "payment_collected": boolean,
  "split_status": boolean,
  "payment_date": string,
  "roll_no": string,
  "name": string,
  "year": number,
  "section": string,
  "programme_id": string,
  "programme_name": string,
  "purpose": string,
  "term": string,
  "amount": number,
  "clg_trans_id": string,
  "gw_trans_id": string,
  "bank_trans_id": string,
  "id": string,
  "split_data_list": any[]
}

interface PaymentHistoryInterface {
  roll_no: string,
  name: string,
  class: string,
  payment_history: {
    payment_name: string,
    amount: number,
    fine: number,
    total: number,
  }[]
}

interface VariableSettingInterface {
  id: any, name: string
}
